import type { JsonSchema7 } from '@jsonforms/core';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import type { QuestionData } from '../form-configs/question';
import { defaultQuestionData } from '../form-configs/question';
import type { SectionData } from '../form-configs/section';
import {
  initialFormData,
  initialFormSchema,
  initialFormUISchema,
} from './initSchema';
import type {
  CustomSchema,
  CustomUISchema,
  CustomUISchemaElement,
  FormBuilderAction,
} from './useFormBuilderContext';
import { FieldOptionType } from './useFormBuilderContext';
import { FormBuilderContext } from './useFormBuilderContext';

interface FormBuilderContextProviderProps {
  children: ReactNode;
}

const FormBuilderContextProvider = ({
  children,
}: FormBuilderContextProviderProps) => {
  const [formData, setFormData] = useState(initialFormData);
  const [schema, setSchema] = useState(initialFormSchema);
  const [uiSchema, setUISchema] = useState(initialFormUISchema);
  const [currentElementUISchema, setCurrentElementUISchema] = useState(
    {} as CustomUISchema | CustomUISchemaElement
  );

  const [isFormCustomisable, setIsFormCustomisable] = useState(false);
  const [isCustomising, setIsCustomising] = useState(false);

  const [parentId, setParentId] = useState('');
  const [isEditingQuestion, setIsEditingQuestion] = useState(false);
  const [formQuestionModalAction, setFormQuestionModalAction] =
    useState<FormBuilderAction | null>(null);
  const [questionData, setQuestionData] =
    useState<QuestionData>(defaultQuestionData);

  const [isFormDirty, setIsFormDirty] = useState(false);

  // INTERNAL UTILS
  const excludeRequiredPropertyFromRequiredList = ({
    propertyIds,
    uiSchemaId,
  }: {
    propertyIds: string[];
    uiSchemaId: string;
  }): string[] => {
    return (
      schema.required?.filter((fieldId) => {
        if (!propertyIds.includes(fieldId)) {
          // This ensures any required fields that are no longer in the schema are removed from the required list...
          return false;
        }

        // ...or filters out the field id that belongs to the deleted section
        return fieldId !== uiSchemaId;
      }) || []
    );
  };

  // FORM BUILDER ACTIONS
  // For a live demo of how the schema and uiSchema interact check out docs below:
  // https://jsonforms.io/examples/layouts#group

  // For a better understanding of how these actions are used, check out the docs below:
  // @risksmart-app/docs/form-builder.md

  // SECTION ACTIONS
  // For a better understanding of sections, check out the docs below:
  // @risksmart-app/docs/form-builder.md
  const addNewSection = (sectionData: SectionData) => {
    const uuid = `section_${uuidv4()}`;

    // Add new section to the UI Schema
    setUISchema({
      ...uiSchema,
      elements: [
        ...uiSchema.elements,
        {
          type: 'Group',
          label: sectionData.sectionTitle,
          id: uuid,
          elements: [],
        },
      ],
    });
  };

  const updateSection = (
    sectionData: SectionData,
    currentElementUISchema: CustomUISchema | CustomUISchemaElement
  ) => {
    // Iterate through all the sections (found in the elements array of the UI Schema)
    // and update the label of the section that matches the section being edited
    const modifiedElementsCopy = uiSchema.elements.map(
      (element: CustomUISchemaElement) => {
        if (element.id === currentElementUISchema?.id) {
          return {
            ...element,
            label: sectionData.sectionTitle,
          };
        }

        return element;
      }
    );

    // Update the UI Schema with the list of modified sections
    setUISchema({
      ...uiSchema,
      elements: modifiedElementsCopy,
    });
  };

  const deleteSection = (
    currentElementUISchema: CustomUISchema | CustomUISchemaElement
  ) => {
    if (!currentElementUISchema?.id) {
      console.log('No id found in uiSchema');

      return;
    }

    if (!schema?.properties) {
      console.log('No properties found in schema');

      return;
    }

    // Iterate through all the sections (found in the elements array of the UI Schema)
    // and delete the section that matches the section being deleted
    const modifiedUISchemaElementsCopy = uiSchema.elements.filter(
      (element: CustomUISchemaElement) =>
        element.id !== currentElementUISchema.id
    );

    // Update the UI Schema with the list of sections (excluding the deleted section)
    setUISchema({
      ...uiSchema,
      elements: modifiedUISchemaElementsCopy,
    });

    // ...THEN, update the schema to remove any properties (form fields) that belong to the deleted section
    const modifiedSchemaPropertiesCopy = Object.keys(
      schema.properties || {}
    ).reduce(
      (acc, key) => {
        if (schema?.properties === undefined) {
          return acc;
        }

        const entity = schema.properties![key] as {
          [property: string]: JsonSchema7 & { parentId?: string };
        };

        if (entity?.parentId !== currentElementUISchema.id) {
          acc![key] = schema.properties![key];
        }

        return acc;
      },
      {} as JsonSchema7['properties']
    );

    // Update the schema with the list of sections (excluding the questions from the deleted section)
    // AND removing them from the `required` list
    setSchema({
      ...schema,
      properties: { ...modifiedSchemaPropertiesCopy },
      required: excludeRequiredPropertyFromRequiredList({
        propertyIds: Object.keys(modifiedSchemaPropertiesCopy || {}),
        uiSchemaId: currentElementUISchema.id,
      }),
    });
  };

  // QUESTION ACTIONS
  // For a better understanding of questions, check out the docs below:
  // @risksmart-app/docs/form-builder.md
  const addNewQuestion = (questionData: QuestionData, parentId: string) => {
    const uuid = `question_${uuidv4()}`;

    // Add new question to the schema
    setSchema({
      ...schema,
      properties: {
        ...schema.properties,
        [uuid]: {
          ...(questionData.fieldType === FieldOptionType.MultiSelect
            ? {
                type: 'array',
                uniqueItems: true,
                minItems: questionData.isPropertyRequired ? 1 : 0,
              }
            : {
                type: 'string',
                minLength: questionData.isPropertyRequired ? 1 : 0,
              }),
          parentId,
          isCustomisable: true,
          allowAttachments: questionData.allowAttachments,
          ...(questionData.selectOptions?.length &&
            questionData.fieldType === FieldOptionType.Dropdown && {
              oneOf: questionData.selectOptions.map((option) => ({
                const: option.generatedId,
                title: option.value,
              })),
            }),
          ...(questionData.selectOptions?.length &&
            questionData.fieldType === FieldOptionType.MultiSelect && {
              items: {
                oneOf: questionData.selectOptions.map((option) => ({
                  const: option.generatedId,
                  title: option.value,
                })),
              },
            }),
        },
      },
      required: [
        ...(schema.required || []),
        ...(questionData.isPropertyRequired ? [uuid] : []),
      ],
    } as CustomSchema);

    // Iterate through all the sections (found in the elements array of the UI Schema)
    // and add the question to the section being edited
    const modifiedUISchemaElementsCopy = uiSchema.elements.map(
      (element: CustomUISchemaElement) => {
        if (element.id === parentId) {
          return {
            ...element,
            elements: [
              ...(element?.elements || []),
              {
                type: 'Control',
                label: questionData.questionTitle,
                id: uuid,
                parentId,
                scope: `#/properties/${uuid}`,
                options: {
                  fieldType: questionData.fieldType,
                  placeholder: questionData.placeholder,
                  description: questionData.description,
                },
              },
            ],
          };
        }

        return element;
      }
    );

    // Update the UI Schema with the list of modified sections
    setUISchema({
      ...uiSchema,
      elements: modifiedUISchemaElementsCopy,
    });
  };

  const updateQuestion = (
    questionData: QuestionData,
    currentElementUISchema: CustomUISchema | CustomUISchemaElement,
    parentId: string
  ) => {
    if (!currentElementUISchema?.id) {
      console.log('No id found in uiSchema');

      return;
    }

    if (!schema?.properties) {
      console.log('No properties found in schema');

      return;
    }

    // Update the required list to exclude the updated question
    const updatedRequiredList =
      schema.required?.filter(
        (fieldId) => fieldId !== currentElementUISchema.id
      ) || [];

    // Add this question to the required list if it is required
    if (questionData.isPropertyRequired) {
      updatedRequiredList.push(currentElementUISchema.id);
    }

    // Update the schema with the modified question and update required list
    setSchema({
      ...schema,
      properties: {
        ...schema.properties,
        [currentElementUISchema.id]: {
          ...(schema?.properties &&
            schema.properties[currentElementUISchema.id]),
          ...(questionData.fieldType === FieldOptionType.MultiSelect
            ? {
                type: 'array',
                uniqueItems: true,
                minItems: questionData.isPropertyRequired ? 1 : 0,
              }
            : {
                type: 'string',
                minLength: questionData.isPropertyRequired ? 1 : 0,
              }),
          allowAttachments: questionData.allowAttachments,
          ...(questionData.selectOptions?.length &&
            questionData.fieldType === FieldOptionType.Dropdown && {
              oneOf: questionData.selectOptions.map((option) => ({
                const: option.generatedId,
                title: option.value,
              })),
            }),
          ...(questionData.selectOptions?.length &&
            questionData.fieldType === FieldOptionType.MultiSelect && {
              items: {
                oneOf: questionData.selectOptions.map((option) => ({
                  const: option.generatedId,
                  title: option.value,
                })),
              },
            }),
        },
      },
      required: updatedRequiredList,
    } as CustomSchema);

    // Iterate through all the sections (found in the elements array of the UI Schema)
    const modifiedUISchemaElementsCopy = uiSchema.elements.map(
      (element: CustomUISchemaElement) => {
        if (element.id === parentId) {
          const modifiedElements = element?.elements
            ? element.elements.map((childElement: CustomUISchemaElement) => {
                // Update the question that matches the question being edited
                if (childElement.id === currentElementUISchema?.id) {
                  return {
                    ...childElement,
                    label: questionData.questionTitle,
                    options: {
                      fieldType: questionData.fieldType,
                      placeholder: questionData.placeholder,
                      description: questionData.description,
                    },
                  };
                }

                // Otherwise return the question as is
                return childElement;
              })
            : [];

          return {
            ...element,
            elements: modifiedElements,
          };
        }

        return element;
      }
    );

    // Update the UI Schema with the list of modified sections
    setUISchema({
      ...uiSchema,
      elements: modifiedUISchemaElementsCopy,
    });
  };

  const deleteQuestion = (
    currentElementUISchema: CustomUISchema | CustomUISchemaElement,
    parentId: string
  ) => {
    if (!currentElementUISchema?.id) {
      console.log('No id found in uiSchema');

      return;
    }

    if (!schema?.properties) {
      console.log('No properties found in schema');

      return;
    }

    // Iterate through all the sections (found in the elements array of the UI Schema)
    const modifiedUISchemaElementsCopy = uiSchema.elements.map(
      (element: CustomUISchemaElement) => {
        if (element.id === parentId) {
          // Filter out the question that matches the question being deleted
          const filteredElements = element?.elements
            ? element.elements.filter(
                (elementElement: CustomUISchemaElement) =>
                  elementElement.id !== currentElementUISchema.id
              )
            : [];

          return {
            ...element,
            elements: filteredElements,
          };
        }

        return element;
      }
    );

    // Update the UI Schema with the list of modified sections
    setUISchema({
      ...uiSchema,
      elements: modifiedUISchemaElementsCopy,
    });

    // Update the schema to remove the question that matches the question being deleted
    const modifiedSchemaPropertiesCopy = Object.keys(
      schema.properties || {}
    ).reduce(
      (acc, key) => {
        if (key !== currentElementUISchema.id) {
          acc![key] = schema.properties![key];
        }

        return acc;
      },
      {} as JsonSchema7['properties']
    );

    // Update the schema with the list of questions (excluding the deleted question)
    setSchema({
      ...schema,
      properties: { ...modifiedSchemaPropertiesCopy },
      required: excludeRequiredPropertyFromRequiredList({
        propertyIds: Object.keys(modifiedSchemaPropertiesCopy || {}),
        uiSchemaId: currentElementUISchema.id,
      }),
    });
  };

  return (
    <FormBuilderContext.Provider
      value={{
        formData,
        schema,
        uiSchema,
        currentElementUISchema,
        isFormCustomisable,
        isFormDirty,
        isCustomising,
        isEditingQuestion,
        formQuestionModalAction,
        questionData,
        parentId,
        setFormData,
        setSchema,
        setUISchema,
        setCurrentElementUISchema,
        setIsFormCustomisable,
        setIsFormDirty,
        setIsCustomising,
        setIsEditingQuestion,
        setFormQuestionModalAction,
        setQuestionData,
        setParentId,
        addNewSection,
        updateSection,
        deleteSection,
        addNewQuestion,
        updateQuestion,
        deleteQuestion,
      }}
    >
      {children}
    </FormBuilderContext.Provider>
  );
};

export default FormBuilderContextProvider;
