import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useGetStatelessRegisterTableProps } from 'src/pages/issues/config';

import type { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import type { GetIssuesQuery } from '@/generated/graphql';
import type { CollectionData } from '@/utils/collectionUtils';
type IssueFlatField = CollectionData<GetIssuesQuery['issue'][number]>;
interface Props {
  loading: boolean;
  records: IssueFlatField[] | undefined;
  customAttributeSchema?: CustomAttributeFields[] | null;
}

const AssessmentIssueRegister: FC<Props> = ({
  loading,
  records,
  customAttributeSchema,
}) => {
  const tableProps = useGetStatelessRegisterTableProps(
    records,
    customAttributeSchema
  );

  return <Table variant="embedded" {...tableProps} loading={loading} />;
};

export default AssessmentIssueRegister;
