import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import type { ObjectWithContributors } from 'src/rbac/Permission';
import { Permission } from 'src/rbac/Permission';
import { useHasPermission } from 'src/rbac/useHasPermission';

import DeleteModal from '@/components/DeleteModal/DeleteModal';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import type { GetAcceptancesByParentRiskIdQuery } from '@/generated/graphql';
import {
  useDeleteAcceptancesMutation,
  useGetAcceptancesByParentRiskIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import type { CollectionData } from '@/utils/collectionUtils';
import { evictField } from '@/utils/graphqlUtils';

import { useGetCollectionStatelessTableProps } from '../../../../acceptances/config';

type RiskAcceptanceFields = CollectionData<
  GetAcceptancesByParentRiskIdQuery['acceptance'][0]
>;

interface Props {
  parent: ObjectWithContributors;
}

const Tab: FC<Props> = ({ parent }) => {
  useI18NSummaryHelpContent('acceptances.help');
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'acceptances',
  });
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const navigate = useNavigate();
  const [selectedAcceptances, setSelectedAcceptances] = useState<
    RiskAcceptanceFields[]
  >([]);

  const canDeleteAcceptances = useHasPermission('delete:acceptance', parent);
  const riskId = useGetGuidParam('riskId');
  const { addNotification } = useNotifications();
  const { data, loading } = useGetAcceptancesByParentRiskIdQuery({
    variables: {
      ParentId: riskId,
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
    fetchPolicy: 'no-cache',
  });

  const [deleteAcceptances, deleteResult] = useDeleteAcceptancesMutation({
    update: (cache) => {
      evictField(cache, 'acceptance');
      evictField(cache, 'acceptance_aggregate');
    },
  });

  const handleAcceptanceOpen = () => {
    navigate('add');
  };

  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      await deleteAcceptances({
        variables: { Ids: selectedAcceptances.map((s) => s.Id) },
      });
      setSelectedAcceptances([]);
      setIsDeleteModalVisible(false);

      return true;
    },
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
  });

  const tableProps = useGetCollectionStatelessTableProps(
    data?.acceptance,
    data?.form_configuration?.[0]?.customAttributeSchema,
    {
      sortingColumn: 'ModifiedAtTimestamp',
      sortingDirection: 'desc',
    }
  );

  return (
    <>
      <Table
        {...tableProps}
        selectionType={canDeleteAcceptances ? 'multi' : undefined}
        selectedItems={selectedAcceptances}
        trackBy="Id"
        onSelectionChange={({ detail }) => {
          setSelectedAcceptances(detail.selectedItems);
        }}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Permission
                    permission="delete:acceptance"
                    parentObject={parent}
                  >
                    <Button
                      formAction="none"
                      variant="normal"
                      disabled={!selectedAcceptances.length}
                      onClick={() => setIsDeleteModalVisible(true)}
                    >
                      {t('delete')}
                    </Button>
                  </Permission>
                  <Permission
                    permission="insert:acceptance"
                    parentObject={parent}
                  >
                    <Button
                      variant="primary"
                      formAction="none"
                      onClick={handleAcceptanceOpen}
                    >
                      {st('create_button')}
                    </Button>
                  </Permission>
                </SpaceBetween>
              }
            >
              {st('tab_title')}
            </TabHeader>
          </SpaceBetween>
        }
        variant="embedded"
        loading={loading}
      />
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </>
  );
};

export default Tab;
