import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getAllContributorsCellValue,
  getAllOwnersCellValue,
} from 'src/rbac/contributorHelper';
import { notEmpty } from 'src/utilityTypes';

import { isIssueStatusOverdue } from '@/components/IssuesStatusBadge/utils';
import { Cost_Type_Enum } from '@/generated/graphql';
import useEntityInfo from '@/hooks/getEntityInfo';
import { useRating } from '@/hooks/use-rating';

import { UNRATED } from '../controls/lookupData';
import type { IssueFlatField, IssueRegisterFields } from './types';
import { calculateCostTotal } from './update/tabs/consequences/utils';

export const useLabelledFields = (
  records: IssueFlatField[] | undefined
): IssueRegisterFields[] | undefined => {
  const { t } = useTranslation(['common']);
  const issueTypes = useMemo(
    () =>
      t('issueTypes', {
        returnObjects: true,
      }),
    [t]
  );

  const status = useRating('issue_assessment_status');
  const severity = useRating('severity');

  const getEntityInfo = useEntityInfo();

  return useMemo<IssueRegisterFields[] | undefined>(() => {
    return records?.map((d) => {
      const parentTitle = d.parents
        .map((p) => {
          if (!p?.parent?.ObjectType) {
            return;
          }
          const entityInfo = getEntityInfo(p?.parent?.ObjectType);

          return {
            label: `${entityInfo.getTitle?.(p) ?? '-'} (${entityInfo.singular})`,
            url: entityInfo.url(p.parent.Id),
          };
        })
        .filter(notEmpty);
      const parentId: string | null = null;

      const issueStatus =
        d.assessment?.Status &&
        isIssueStatusOverdue({
          item: {
            Status: d.assessment?.Status,
            TargetCloseDate: d.assessment?.TargetCloseDate,
          },
        })
          ? (status.getByValue('overdue')?.label ?? '')
          : status.getLabel(d.assessment?.Status ?? null);

      return {
        ...d.assessment!,
        ...d,
        IssueTypeLabelled: d.assessment?.IssueType
          ? issueTypes[d.assessment.IssueType as keyof typeof issueTypes] //TODO get IssueType as enum
          : '-',
        SeverityLabelled:
          severity.getLabel(d.assessment?.Severity ?? null) || UNRATED.label,
        StatusLabelled: issueStatus,
        ParentTitle: parentTitle,
        ParentId: parentId,
        OpenActions: d.actions_aggregate.aggregate?.count ?? null,
        RegulationsBreached:
          d.parents
            ?.filter((parent) => parent.obligation)
            .map((parent) => parent.obligation?.Title)
            .join(', ') ?? '-',
        ModifiedByUserName: d.modifiedByUser?.FriendlyName || '-',
        CreatedByUserName: d.createdByUser?.FriendlyName || '-',
        AssessmentCreatedBy: d.assessment?.createdByUser?.FriendlyName || '-',
        AssessmentModifiedBy: d.assessment?.modifiedByUser?.FriendlyName || '-',
        Severity: d.assessment?.Severity ?? null,
        SequentialIdLabel: d.SequentialId ? `I-${d.SequentialId}` : '',
        AssessmentDepartments: d.assessment?.departments ?? null,
        CustomAttributeData: {
          ...(d.CustomAttributeData || {}),
          ...(d.assessment?.CustomAttributeData || {}),
        },
        allOwners: getAllOwnersCellValue(d),
        allContributors: getAllContributorsCellValue(d),
        Cost: calculateCostTotal(
          d.consequences ?? [],
          Cost_Type_Enum.Financial
        ),
        Hours: calculateCostTotal(d.consequences ?? [], Cost_Type_Enum.Hours),
        CustomersImpacted: calculateCostTotal(
          d.consequences ?? [],
          Cost_Type_Enum.CustomersImpacted
        ),
        CertifiedIndividual:
          d.assessment?.certifiedIndividual?.FriendlyName || '-',
        TimeToResolve:
          d.assessment?.ActualCloseDate && d.CreatedAtTimestamp
            ? dayjs(d.assessment?.ActualCloseDate).diff(
                dayjs(d.CreatedAtTimestamp),
                'days'
              )
            : null,
        TimeToReport: dayjs(d.CreatedAtTimestamp).diff(
          d.DateIdentified,
          'days'
        ),
        TimeToIdentify: dayjs(d.DateIdentified).diff(d.DateOccurred, 'days'),
        TimeSinceCreated: dayjs().diff(dayjs(d.CreatedAtTimestamp), 'days'),
      };
    });
  }, [records, issueTypes, severity, status, getEntityInfo]);
};
