import Header from '@cloudscape-design/components/header';
import type { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { IssueAssessmentFields } from 'src/pages/issues/update/forms/IssueAssessmentForm/issueAssessmentSchema';

import ControlledControlMultiSelect from '@/components/Form/ControlledControlMultiSelect';
import ControlledDatePicker from '@/components/Form/ControlledDatePicker';
import ControlledDocumentMultiselect from '@/components/Form/ControlledDocumentMultiselect';
import ControlledGroupAndUserSelect from '@/components/Form/ControlledGroupAndUserSelect';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledObligationMultiselect from '@/components/Form/ControlledObligationMultiselect';
import ControlledRadioGroup from '@/components/Form/ControlledRadioGroup';
import { ControlledBooleanRadioGroup } from '@/components/Form/ControlledRadioGroup/ControlledBooleanRadioGroup';
import { noTransform } from '@/components/Form/ControlledRadioGroup/radioGroupUtils';
import { yesNoOptions } from '@/components/Form/ControlledRadioGroup/radioGroupUtils';
import ControlledRating from '@/components/Form/ControlledRating';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import DepartmentSelector from '@/components/Form/DepartmentSelector';
import ConditionalField from '@/components/Form/Form/CustomisableForm/ConditionalField';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import FieldGroup from '@/components/Form/Form/CustomisableForm/FieldGroup';
import TagSelector from '@/components/Form/TagSelector';
import { Issue_Assessment_Status_Enum } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';

type Props = {
  readOnly?: boolean;
};

const IssueAssessmentForm: FC<Props> = ({ readOnly }) => {
  const { options } = useRating('issue_assessment_status');
  const issueStatusOptions = options
    .filter(
      (option) =>
        option.value &&
        Object.values(Issue_Assessment_Status_Enum).includes(
          option.value as Issue_Assessment_Status_Enum
        )
    )
    .map((option) => ({
      ...option,
      value: String(option.value),
    }));
  const { control } = useFormContext<IssueAssessmentFields>();
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'issueAssessment',
  });
  const { t } = useTranslation(['common']);
  const isComplianceVisibleToOrg = useIsFeatureVisibleToOrg('compliance');
  const isPolicyVisibleToOrg = useIsFeatureVisibleToOrg('policy');
  const turnOffRegulatoryBreaches = useIsFeatureVisibleToOrg(
    'turn-off-regulatory-breaches'
  );
  const issueTypes = t('issueTypes');
  const issueTypeKeys = Object.keys(issueTypes) as Array<
    keyof typeof issueTypes
  >;
  const issueTypeOptions = issueTypeKeys.map((key) => ({
    label: String(issueTypes[key]),
    value: key,
  }));
  const status = useWatch({
    control,
    name: 'Status',
  });
  const policyBreach = useWatch({
    control,
    name: 'PolicyBreach',
  });
  const regulatoryBreach = useWatch({
    control,
    name: 'RegulatoryBreach',
  });
  const issueCausedByThirdParty = useWatch({
    control,
    name: 'IssueCausedByThirdParty',
  });
  const issueCausedBySystemIssue = useWatch({
    control,
    name: 'IssueCausedBySystemIssue',
  });

  return (
    <CustomisableForm readOnly={readOnly}>
      <ControlledSelect
        key="issueType"
        filteringType="auto"
        label={st('fields.IssueType')}
        name="IssueType"
        placeholder="Select"
        control={control}
        options={issueTypeOptions}
        disabled={readOnly}
        testId="issueType"
        description={st('fields.IssueType_help')}
      />
      <ControlledRating
        key="severity"
        addEmptyOption={true}
        filteringType="none"
        label={st('fields.Severity')}
        name="Severity"
        placeholder={t('select') ?? ''}
        control={control}
        type="severity"
        disabled={readOnly}
        testId="severity"
        description={st('fields.Severity_help')}
      />
      <FieldGroup key="statusGroup">
        <ControlledRadioGroup
          key="status"
          label={t('fields.Status')}
          name="Status"
          control={control}
          transform={noTransform}
          items={issueStatusOptions}
          disabled={readOnly}
          testId="status"
          description={st('fields.Status_help')}
        />
        <ControlledDatePicker
          key="targetCloseDate"
          name="TargetCloseDate"
          label={st('fields.TargetCloseDate')}
          control={control}
          disabled={readOnly}
          testId="targetCloseDate"
          description={st('fields.TargetCloseDate_help')}
        />
        <ConditionalField
          condition={status === Issue_Assessment_Status_Enum.Closed}
          key="actualCloseDate"
        >
          <ControlledDatePicker
            key="actualCloseDate"
            name="ActualCloseDate"
            label={st('fields.ActualCloseDate')}
            control={control}
            disabled={readOnly}
            testId="actualCloseDate"
            description={st('fields.ActualCloseDate_help')}
          />
        </ConditionalField>
      </FieldGroup>
      <ControlledControlMultiSelect
        key="associatedControlId"
        control={control}
        name="AssociatedControlIds"
        renderTokens={true}
        label={st('fields.AssociatedControls')}
        disabled={readOnly}
        description={st('fields.AssociatedControls_help')}
      />

      <ControlledGroupAndUserSelect
        key="certifiedIndividual"
        control={control}
        addEmptyOption={true}
        name="CertifiedIndividual"
        label={st('fields.CertifiedIndividual')}
        disabled={readOnly}
        description={st('fields.CertifiedIndividual_help')}
        includeGroups={false}
      />
      <Header variant="h3" key="regulationHeader">
        {st('headings.regulation')}
      </Header>

      <ControlledBooleanRadioGroup
        key="regulatoryBreach"
        label={st('fields.RegulatoryBreach')}
        name="RegulatoryBreach"
        control={control}
        items={yesNoOptions}
        disabled={readOnly}
        description={st('fields.RegulatoryBreach_help')}
      />

      <ConditionalField condition={regulatoryBreach} key="regulationsBreached">
        {isComplianceVisibleToOrg && !turnOffRegulatoryBreaches ? (
          <ControlledObligationMultiselect
            control={control}
            name="RegulationsBreachedIds"
            label={st('fields.RegulationsBreached')}
            placeholder={st('fields.RegulationsBreachedIds_placeholder') ?? ''}
            description={st('fields.RegulationsBreached_help')}
            disabled={readOnly}
          />
        ) : (
          <ControlledInput
            name="RegulationsBreached"
            label={st('fields.RegulationsBreached')}
            placeholder={st('fields.RegulationsBreached_placeholder') ?? ''}
            description={st('fields.RegulationsBreached_help')}
            control={control}
            disabled={readOnly}
          />
        )}
      </ConditionalField>

      <ConditionalField condition={regulatoryBreach} key="reportable">
        <ControlledBooleanRadioGroup
          label={st('fields.Reportable')}
          name="Reportable"
          control={control}
          items={yesNoOptions}
          disabled={readOnly}
          description={st('fields.Reportable_help')}
        />
      </ConditionalField>

      <ConditionalField condition={regulatoryBreach} key="rationale">
        <ControlledTextarea
          name="Rationale"
          label={st('fields.Rationale')}
          placeholder={st('fields.Rationale_placeholder') ?? ''}
          control={control}
          disabled={readOnly}
          description={st('fields.Rationale_help')}
        />
      </ConditionalField>

      <Header variant="h3" key="thirdPartyHeader">
        {st('headings.thirdParty')}
      </Header>

      <ControlledBooleanRadioGroup
        key="issueCausedByThirdParty"
        label={st('fields.IssueCausedByThirdParty')}
        name="IssueCausedByThirdParty"
        control={control}
        items={yesNoOptions}
        disabled={readOnly}
        description={st('fields.IssueCausedByThirdParty_help')}
      />

      <ConditionalField
        key="thirdPartyResponsible"
        condition={issueCausedByThirdParty}
      >
        <ControlledInput
          name="ThirdPartyResponsible"
          label={st('fields.ThirdPartyResponsible')}
          placeholder={st('fields.ThirdPartyResponsible_placeholder') ?? ''}
          control={control}
          disabled={readOnly}
          description={st('fields.ThirdPartyResponsible_help')}
        />
      </ConditionalField>

      <Header variant="h3" key="systemHeader">
        {st('headings.system')}
      </Header>

      <ControlledBooleanRadioGroup
        key="issueCausedBySystemIssue"
        label={st('fields.IssueCausedBySystemIssue')}
        name="IssueCausedBySystemIssue"
        control={control}
        items={yesNoOptions}
        disabled={readOnly}
        description={st('fields.IssueCausedBySystemIssue_help')}
      />
      <ConditionalField
        condition={issueCausedBySystemIssue}
        key="systemResponsible"
      >
        <ControlledInput
          name="SystemResponsible"
          label={st('fields.SystemResponsible')}
          placeholder={st('fields.SystemResponsible_placeholder') ?? ''}
          description={st('fields.SystemResponsible_help')}
          control={control}
          disabled={readOnly}
        />
      </ConditionalField>
      <Header variant="h3" key="policyHeader">
        {st('headings.policy')}
      </Header>

      <ControlledBooleanRadioGroup
        key="policyBreach"
        label={st('fields.PolicyBreach')}
        name="PolicyBreach"
        control={control}
        items={yesNoOptions}
        disabled={readOnly}
        description={st('fields.PolicyBreach_help')}
      />

      <ConditionalField condition={policyBreach} key="policiesBreached">
        {isPolicyVisibleToOrg ? (
          <ControlledDocumentMultiselect
            name={'PoliciesBreachedIds'}
            label={st('fields.PoliciesBreached')}
            placeholder={st('fields.PoliciesBreached_placeholder') ?? ''}
            control={control}
            disabled={readOnly}
            description={st('fields.PoliciesBreached_help')}
          />
        ) : (
          <ControlledInput
            name="PoliciesBreached"
            label={st('fields.PoliciesBreached')}
            placeholder={st('fields.PoliciesBreached_placeholder') ?? ''}
            control={control}
            disabled={readOnly}
            description={st('fields.PoliciesBreached_help')}
          />
        )}
      </ConditionalField>
      <ConditionalField condition={policyBreach} key="policyOwner">
        <ControlledGroupAndUserSelect
          control={control}
          addEmptyOption={true}
          name="PolicyOwner"
          label={st('fields.PolicyOwner')}
          disabled={readOnly}
          includeGroups={false}
          description={st('fields.PolicyOwner_help')}
        />
      </ConditionalField>
      <ConditionalField condition={policyBreach} key="policyOwnerCommentary">
        <ControlledTextarea
          name="PolicyOwnerCommentary"
          label={st('fields.PolicyOwnerCommentary')}
          placeholder={st('fields.PolicyOwnerCommentary_placeholder') ?? ''}
          control={control}
          disabled={readOnly}
          description={st('fields.PolicyOwnerCommentary_help')}
        />
      </ConditionalField>

      <TagSelector
        name="tags"
        key="tags"
        testId="tags"
        control={control}
        disabled={readOnly}
      />
      <DepartmentSelector
        key="departments"
        name="departments"
        control={control}
        disabled={readOnly}
      />
    </CustomisableForm>
  );
};

export default IssueAssessmentForm;
