import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  bigint: { input: any; output: any; }
  float8: { input: any; output: any; }
  interval: { input: any; output: any; }
  json: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  numeric: { input: number; output: number; }
  smallint: { input: number; output: number; }
  timestamptz: { input: string; output: string; }
  uuid: { input: string; output: string; }
  _FieldSet: { input: any; output: any; }
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type DataImportStartImportOutput = {
  __typename?: 'DataImportStartImportOutput';
  message: Scalars['String']['output'];
};

export type DataImportValidateOutput = {
  __typename?: 'DataImportValidateOutput';
  message: Scalars['String']['output'];
};

export type DisconnectSlackOutput = {
  __typename?: 'DisconnectSlackOutput';
  message: Scalars['String']['output'];
};

export type GenericMutationOutput = {
  __typename?: 'GenericMutationOutput';
  affected_rows: Scalars['Int']['output'];
};

export type IdOutput = {
  __typename?: 'IdOutput';
  Id: Scalars['uuid']['output'];
};

export type InsertChildControlInput = {
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Description: Scalars['String']['input'];
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  ParentId: Scalars['uuid']['input'];
  TagTypeIds: Array<Scalars['uuid']['input']>;
  Title: Scalars['String']['input'];
  Type?: InputMaybe<Scalars['String']['input']>;
  schedule: ScheduleInput;
};

export type InsertChildDocumentAssessmentResultOutput = {
  __typename?: 'InsertChildDocumentAssessmentResultOutput';
  Ids: Array<Scalars['uuid']['output']>;
};

export type InsertChildDocumentInput = {
  AttestationGroupIds: Array<Scalars['uuid']['input']>;
  AttestationPromptText?: InputMaybe<Scalars['String']['input']>;
  AttestationTimeLimit?: InputMaybe<Scalars['interval']['input']>;
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  DocumentType: Scalars['String']['input'];
  LinkedDocumentIds: Array<Scalars['uuid']['input']>;
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  ParentDocument?: InputMaybe<Scalars['uuid']['input']>;
  Purpose?: InputMaybe<Scalars['String']['input']>;
  RequireGlobalAttestation: Scalars['Boolean']['input'];
  TagTypeIds: Array<Scalars['uuid']['input']>;
  Title: Scalars['String']['input'];
  schedule: ScheduleInput;
};

export type InsertChildIndicatorInput = {
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  LowerAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  LowerToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  TargetValueTxt?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Indicator_Type_Enum>;
  Unit?: InputMaybe<Scalars['String']['input']>;
  UpperAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  UpperToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
  schedule: ScheduleInput;
};

export type InsertChildObligationAssessmentResultOutput = {
  __typename?: 'InsertChildObligationAssessmentResultOutput';
  Ids: Array<Scalars['uuid']['output']>;
};

export type InsertChildObligationInput = {
  Adherence: Scalars['String']['input'];
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Interpretation?: InputMaybe<Scalars['String']['input']>;
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  Title: Scalars['String']['input'];
  Type: Obligation_Type_Enum;
  schedule: ScheduleInput;
};

export type InsertChildRiskAssessmentResultOutput = {
  __typename?: 'InsertChildRiskAssessmentResultOutput';
  Ids: Array<Scalars['uuid']['output']>;
};

export type InsertChildRiskInput = {
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  ParentRiskId?: InputMaybe<Scalars['uuid']['input']>;
  Status?: InputMaybe<Risk_Status_Type_Enum>;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  Tier: Scalars['Int']['input'];
  Title: Scalars['String']['input'];
  Treatment?: InputMaybe<Risk_Treatment_Type_Enum>;
  schedule: ScheduleInput;
};

export type InsertImpactRatingPairInput = {
  ImpactId: Scalars['uuid']['input'];
  Rating: Scalars['Int']['input'];
};

export type InsertInternalAuditInput = {
  BusinessArea: Scalars['String']['input'];
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  Title: Scalars['String']['input'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type LinkItemRecordOutput = {
  __typename?: 'LinkItemRecordOutput';
  RelationshipType: Scalars['String']['output'];
  Source: Scalars['uuid']['output'];
  Target: Scalars['uuid']['output'];
};

export type LinkItemsOutput = {
  __typename?: 'LinkItemsOutput';
  Links: Array<LinkItemRecordOutput>;
};

export type NotificationPreferencesOutput = {
  __typename?: 'NotificationPreferencesOutput';
  categories?: Maybe<Scalars['jsonb']['output']>;
  channel_types?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['String']['output'];
  workflows?: Maybe<Scalars['jsonb']['output']>;
};

export type RecalculateAppetitesOutput = {
  __typename?: 'RecalculateAppetitesOutput';
  message?: Maybe<Scalars['String']['output']>;
};

export type RecalculateRiskScoresOutput = {
  __typename?: 'RecalculateRiskScoresOutput';
  message?: Maybe<Scalars['String']['output']>;
};

export type ReportDataOutput = {
  __typename?: 'ReportDataOutput';
  OrgKey: Scalars['String']['output'];
};

export type RoleOutput = {
  __typename?: 'RoleOutput';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ScheduleInput = {
  Frequency?: InputMaybe<Test_Frequency_Enum>;
  ManualDueDate?: InputMaybe<Scalars['timestamptz']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TimeToCompleteUnit?: InputMaybe<Unit_Of_Time_Enum>;
  TimeToCompleteValue?: InputMaybe<Scalars['Int']['input']>;
};

export type SlackNotificationConnectionOutput = {
  __typename?: 'SlackNotificationConnectionOutput';
  connected: Scalars['Boolean']['output'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']['input']>>;
  _eq?: InputMaybe<Array<Scalars['String']['input']>>;
  _gt?: InputMaybe<Array<Scalars['String']['input']>>;
  _gte?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['String']['input']>>;
  _lte?: InputMaybe<Array<Scalars['String']['input']>>;
  _neq?: InputMaybe<Array<Scalars['String']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export type UnlinkItemsOutput = {
  __typename?: 'UnlinkItemsOutput';
  Ids?: Maybe<Array<Maybe<Scalars['uuid']['output']>>>;
};

export type UpdateApproverResponsesInput = {
  ChangeRequestId: Scalars['uuid']['input'];
  Comment: Scalars['String']['input'];
  LevelId: Scalars['uuid']['input'];
  OverrideLevel: Scalars['Boolean']['input'];
  Response: Scalars['Boolean']['input'];
};

export type UpdateChildControlInput = {
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id: Scalars['uuid']['input'];
  OriginalTimestamp: Scalars['timestamptz']['input'];
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  Title: Scalars['String']['input'];
  Type?: InputMaybe<Scalars['String']['input']>;
  schedule: ScheduleInput;
};

export type UpdateChildDocumentInput = {
  AttestationGroupIds: Array<Scalars['uuid']['input']>;
  AttestationPromptText?: InputMaybe<Scalars['String']['input']>;
  AttestationTimeLimit?: InputMaybe<Scalars['interval']['input']>;
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  DocumentType: Scalars['String']['input'];
  Id: Scalars['uuid']['input'];
  LinkedDocumentIds: Array<Scalars['uuid']['input']>;
  OriginalTimestamp: Scalars['timestamptz']['input'];
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  ParentDocument?: InputMaybe<Scalars['uuid']['input']>;
  Purpose?: InputMaybe<Scalars['String']['input']>;
  RequireGlobalAttestation: Scalars['Boolean']['input'];
  TagTypeIds: Array<Scalars['uuid']['input']>;
  Title: Scalars['String']['input'];
  schedule: ScheduleInput;
};

export type UpdateChildIndicatorInput = {
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id: Scalars['uuid']['input'];
  LowerAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  LowerToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  TargetValueTxt?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Indicator_Type_Enum>;
  Unit?: InputMaybe<Scalars['String']['input']>;
  UpperAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  UpperToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
  schedule: ScheduleInput;
};

export type UpdateChildObligationInput = {
  Adherence: Scalars['String']['input'];
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id: Scalars['uuid']['input'];
  Interpretation?: InputMaybe<Scalars['String']['input']>;
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  Title: Scalars['String']['input'];
  Type: Obligation_Type_Enum;
  schedule: ScheduleInput;
};

export type UpdateChildRiskInput = {
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id: Scalars['uuid']['input'];
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  ParentRiskId?: InputMaybe<Scalars['uuid']['input']>;
  Status?: InputMaybe<Risk_Status_Type_Enum>;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  Tier: Scalars['Int']['input'];
  Title: Scalars['String']['input'];
  Treatment?: InputMaybe<Risk_Treatment_Type_Enum>;
  schedule: ScheduleInput;
};

export type UpdateInternalAuditInput = {
  BusinessArea: Scalars['String']['input'];
  BusinessAreaId: Scalars['uuid']['input'];
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id: Scalars['uuid']['input'];
  OriginalTimestamp: Scalars['timestamptz']['input'];
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  Title: Scalars['String']['input'];
};

export type UpdateNotificationPreferencesInput = {
  categories?: InputMaybe<Scalars['jsonb']['input']>;
  channel_types?: InputMaybe<Scalars['jsonb']['input']>;
  id: Scalars['String']['input'];
  workflows?: InputMaybe<Scalars['jsonb']['input']>;
};

export type UpdateNotificationPreferencesOutput = {
  __typename?: 'UpdateNotificationPreferencesOutput';
  message: Scalars['String']['output'];
};

export type UpdateTestResultInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  DesignEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  Id: Scalars['uuid']['input'];
  OriginalTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OverallEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  ParentControlId: Scalars['uuid']['input'];
  PerformanceEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  Submitter?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TestType?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserRolesMutationOutput = {
  __typename?: 'UpdateUserRolesMutationOutput';
  roles: Array<Maybe<RoleOutput>>;
};

/** columns and relationships of "risksmart.acceptance" */
export type Acceptance = {
  __typename?: 'acceptance';
  ApprovedByUser?: Maybe<Scalars['String']['output']>;
  ApprovedByUserGroup?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  DateAcceptedFrom: Scalars['timestamptz']['output'];
  DateAcceptedTo: Scalars['timestamptz']['output'];
  Details: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  RequestedByUser?: Maybe<Scalars['String']['output']>;
  RequestedByUserGroup?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status: Acceptance_Status_Enum;
  Title: Scalars['String']['output'];
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An object relationship */
  approvedByUser?: Maybe<User>;
  /** An object relationship */
  approvedByUserGroup?: Maybe<User_Group>;
  /** An array relationship */
  changeRequests: Array<Change_Request>;
  /** An aggregate relationship */
  changeRequests_aggregate: Change_Request_Aggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  files: Array<Relation_File>;
  /** An aggregate relationship */
  files_aggregate: Relation_File_Aggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An array relationship */
  parents: Array<Acceptance_Parent>;
  /** An aggregate relationship */
  parents_aggregate: Acceptance_Parent_Aggregate;
  /** An object relationship */
  requestedByUser?: Maybe<User>;
  /** An object relationship */
  requestedByUserGroup?: Maybe<User_Group>;
};


/** columns and relationships of "risksmart.acceptance" */
export type AcceptanceCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.acceptance" */
export type AcceptanceAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.acceptance" */
export type AcceptanceChangeRequestsArgs = {
  distinct_on?: InputMaybe<Array<Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Change_Request_Order_By>>;
  where?: InputMaybe<Change_Request_Bool_Exp>;
};


/** columns and relationships of "risksmart.acceptance" */
export type AcceptanceChangeRequests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Change_Request_Order_By>>;
  where?: InputMaybe<Change_Request_Bool_Exp>;
};


/** columns and relationships of "risksmart.acceptance" */
export type AcceptanceFilesArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.acceptance" */
export type AcceptanceFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.acceptance" */
export type AcceptanceParentsArgs = {
  distinct_on?: InputMaybe<Array<Acceptance_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Acceptance_Parent_Order_By>>;
  where?: InputMaybe<Acceptance_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.acceptance" */
export type AcceptanceParents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Acceptance_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Acceptance_Parent_Order_By>>;
  where?: InputMaybe<Acceptance_Parent_Bool_Exp>;
};

/** aggregated selection of "risksmart.acceptance" */
export type Acceptance_Aggregate = {
  __typename?: 'acceptance_aggregate';
  aggregate?: Maybe<Acceptance_Aggregate_Fields>;
  nodes: Array<Acceptance>;
};

/** aggregate fields of "risksmart.acceptance" */
export type Acceptance_Aggregate_Fields = {
  __typename?: 'acceptance_aggregate_fields';
  avg?: Maybe<Acceptance_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Acceptance_Max_Fields>;
  min?: Maybe<Acceptance_Min_Fields>;
  stddev?: Maybe<Acceptance_Stddev_Fields>;
  stddev_pop?: Maybe<Acceptance_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Acceptance_Stddev_Samp_Fields>;
  sum?: Maybe<Acceptance_Sum_Fields>;
  var_pop?: Maybe<Acceptance_Var_Pop_Fields>;
  var_samp?: Maybe<Acceptance_Var_Samp_Fields>;
  variance?: Maybe<Acceptance_Variance_Fields>;
};


/** aggregate fields of "risksmart.acceptance" */
export type Acceptance_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acceptance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "risksmart.acceptance_audit" */
export type Acceptance_Audit = {
  __typename?: 'acceptance_audit';
  ApprovedByUser?: Maybe<Scalars['String']['output']>;
  ApprovedByUserGroup?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  DateAcceptedFrom: Scalars['timestamptz']['output'];
  DateAcceptedTo: Scalars['timestamptz']['output'];
  Details: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  RequestedByUser?: Maybe<Scalars['String']['output']>;
  RequestedByUserGroup?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status: Scalars['String']['output'];
  Title: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.acceptance_audit" */
export type Acceptance_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.acceptance_audit". All fields are combined with a logical 'AND'. */
export type Acceptance_Audit_Bool_Exp = {
  ApprovedByUser?: InputMaybe<String_Comparison_Exp>;
  ApprovedByUserGroup?: InputMaybe<Uuid_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  DateAcceptedFrom?: InputMaybe<Timestamptz_Comparison_Exp>;
  DateAcceptedTo?: InputMaybe<Timestamptz_Comparison_Exp>;
  Details?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  RequestedByUser?: InputMaybe<String_Comparison_Exp>;
  RequestedByUserGroup?: InputMaybe<Uuid_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Status?: InputMaybe<String_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Acceptance_Audit_Bool_Exp>>;
  _not?: InputMaybe<Acceptance_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Acceptance_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.acceptance_audit". */
export type Acceptance_Audit_Order_By = {
  ApprovedByUser?: InputMaybe<Order_By>;
  ApprovedByUserGroup?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  DateAcceptedFrom?: InputMaybe<Order_By>;
  DateAcceptedTo?: InputMaybe<Order_By>;
  Details?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  RequestedByUser?: InputMaybe<Order_By>;
  RequestedByUserGroup?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.acceptance_audit" */
export const Acceptance_Audit_Select_Column = {
  /** column name */
  ApprovedByUser: 'ApprovedByUser',
  /** column name */
  ApprovedByUserGroup: 'ApprovedByUserGroup',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  DateAcceptedFrom: 'DateAcceptedFrom',
  /** column name */
  DateAcceptedTo: 'DateAcceptedTo',
  /** column name */
  Details: 'Details',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  RequestedByUser: 'RequestedByUser',
  /** column name */
  RequestedByUserGroup: 'RequestedByUserGroup',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Status: 'Status',
  /** column name */
  Title: 'Title'
} as const;

export type Acceptance_Audit_Select_Column = typeof Acceptance_Audit_Select_Column[keyof typeof Acceptance_Audit_Select_Column];
/** Streaming cursor of the table "acceptance_audit" */
export type Acceptance_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Acceptance_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acceptance_Audit_Stream_Cursor_Value_Input = {
  ApprovedByUser?: InputMaybe<Scalars['String']['input']>;
  ApprovedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateAcceptedFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  DateAcceptedTo?: InputMaybe<Scalars['timestamptz']['input']>;
  Details?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  RequestedByUser?: InputMaybe<Scalars['String']['input']>;
  RequestedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate avg on columns */
export type Acceptance_Avg_Fields = {
  __typename?: 'acceptance_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.acceptance". All fields are combined with a logical 'AND'. */
export type Acceptance_Bool_Exp = {
  ApprovedByUser?: InputMaybe<String_Comparison_Exp>;
  ApprovedByUserGroup?: InputMaybe<Uuid_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  DateAcceptedFrom?: InputMaybe<Timestamptz_Comparison_Exp>;
  DateAcceptedTo?: InputMaybe<Timestamptz_Comparison_Exp>;
  Details?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  RequestedByUser?: InputMaybe<String_Comparison_Exp>;
  RequestedByUserGroup?: InputMaybe<Uuid_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Status?: InputMaybe<Acceptance_Status_Enum_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Acceptance_Bool_Exp>>;
  _not?: InputMaybe<Acceptance_Bool_Exp>;
  _or?: InputMaybe<Array<Acceptance_Bool_Exp>>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  approvedByUser?: InputMaybe<User_Bool_Exp>;
  approvedByUserGroup?: InputMaybe<User_Group_Bool_Exp>;
  changeRequests?: InputMaybe<Change_Request_Bool_Exp>;
  changeRequests_aggregate?: InputMaybe<Change_Request_Aggregate_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  files?: InputMaybe<Relation_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parents?: InputMaybe<Acceptance_Parent_Bool_Exp>;
  parents_aggregate?: InputMaybe<Acceptance_Parent_Aggregate_Bool_Exp>;
  requestedByUser?: InputMaybe<User_Bool_Exp>;
  requestedByUserGroup?: InputMaybe<User_Group_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.acceptance" */
export const Acceptance_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  AcceptancePkey: 'acceptance_pkey',
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxAcceptanceOrgkeySequentialid: 'idx_acceptance_orgkey_sequentialid'
} as const;

export type Acceptance_Constraint = typeof Acceptance_Constraint[keyof typeof Acceptance_Constraint];
/** input type for inserting data into table "risksmart.acceptance" */
export type Acceptance_Insert_Input = {
  ApprovedByUser?: InputMaybe<Scalars['String']['input']>;
  ApprovedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateAcceptedFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  DateAcceptedTo?: InputMaybe<Scalars['timestamptz']['input']>;
  Details?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  RequestedByUser?: InputMaybe<Scalars['String']['input']>;
  RequestedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  Status?: InputMaybe<Acceptance_Status_Enum>;
  Title?: InputMaybe<Scalars['String']['input']>;
  approvedByUserGroup?: InputMaybe<User_Group_Obj_Rel_Insert_Input>;
  changeRequests?: InputMaybe<Change_Request_Arr_Rel_Insert_Input>;
  files?: InputMaybe<Relation_File_Arr_Rel_Insert_Input>;
  parents?: InputMaybe<Acceptance_Parent_Arr_Rel_Insert_Input>;
  requestedByUserGroup?: InputMaybe<User_Group_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Acceptance_Max_Fields = {
  __typename?: 'acceptance_max_fields';
  ApprovedByUser?: Maybe<Scalars['String']['output']>;
  ApprovedByUserGroup?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DateAcceptedFrom?: Maybe<Scalars['timestamptz']['output']>;
  DateAcceptedTo?: Maybe<Scalars['timestamptz']['output']>;
  Details?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  RequestedByUser?: Maybe<Scalars['String']['output']>;
  RequestedByUserGroup?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Acceptance_Min_Fields = {
  __typename?: 'acceptance_min_fields';
  ApprovedByUser?: Maybe<Scalars['String']['output']>;
  ApprovedByUserGroup?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DateAcceptedFrom?: Maybe<Scalars['timestamptz']['output']>;
  DateAcceptedTo?: Maybe<Scalars['timestamptz']['output']>;
  Details?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  RequestedByUser?: Maybe<Scalars['String']['output']>;
  RequestedByUserGroup?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** input type for inserting object relation for remote table "risksmart.acceptance" */
export type Acceptance_Obj_Rel_Insert_Input = {
  data: Acceptance_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Acceptance_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.acceptance" */
export type Acceptance_On_Conflict = {
  constraint: Acceptance_Constraint;
  update_columns?: Array<Acceptance_Update_Column>;
  where?: InputMaybe<Acceptance_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.acceptance". */
export type Acceptance_Order_By = {
  ApprovedByUser?: InputMaybe<Order_By>;
  ApprovedByUserGroup?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  DateAcceptedFrom?: InputMaybe<Order_By>;
  DateAcceptedTo?: InputMaybe<Order_By>;
  Details?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  RequestedByUser?: InputMaybe<Order_By>;
  RequestedByUserGroup?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  approvedByUser?: InputMaybe<User_Order_By>;
  approvedByUserGroup?: InputMaybe<User_Group_Order_By>;
  changeRequests_aggregate?: InputMaybe<Change_Request_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parents_aggregate?: InputMaybe<Acceptance_Parent_Aggregate_Order_By>;
  requestedByUser?: InputMaybe<User_Order_By>;
  requestedByUserGroup?: InputMaybe<User_Group_Order_By>;
};

/** columns and relationships of "risksmart.acceptance_parent" */
export type Acceptance_Parent = {
  __typename?: 'acceptance_parent';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  /** An object relationship */
  acceptance?: Maybe<Acceptance>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parent?: Maybe<Node>;
  /** An object relationship */
  risk?: Maybe<Risk>;
};

/** aggregated selection of "risksmart.acceptance_parent" */
export type Acceptance_Parent_Aggregate = {
  __typename?: 'acceptance_parent_aggregate';
  aggregate?: Maybe<Acceptance_Parent_Aggregate_Fields>;
  nodes: Array<Acceptance_Parent>;
};

export type Acceptance_Parent_Aggregate_Bool_Exp = {
  count?: InputMaybe<Acceptance_Parent_Aggregate_Bool_Exp_Count>;
};

export type Acceptance_Parent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Acceptance_Parent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Acceptance_Parent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.acceptance_parent" */
export type Acceptance_Parent_Aggregate_Fields = {
  __typename?: 'acceptance_parent_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Acceptance_Parent_Max_Fields>;
  min?: Maybe<Acceptance_Parent_Min_Fields>;
};


/** aggregate fields of "risksmart.acceptance_parent" */
export type Acceptance_Parent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Acceptance_Parent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.acceptance_parent" */
export type Acceptance_Parent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Acceptance_Parent_Max_Order_By>;
  min?: InputMaybe<Acceptance_Parent_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.acceptance_parent" */
export type Acceptance_Parent_Arr_Rel_Insert_Input = {
  data: Array<Acceptance_Parent_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Acceptance_Parent_On_Conflict>;
};

/** columns and relationships of "risksmart.acceptance_parent_audit" */
export type Acceptance_Parent_Audit = {
  __typename?: 'acceptance_parent_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId: Scalars['uuid']['output'];
};

/** order by aggregate values of table "risksmart.acceptance_parent_audit" */
export type Acceptance_Parent_Audit_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Acceptance_Parent_Audit_Max_Order_By>;
  min?: InputMaybe<Acceptance_Parent_Audit_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.acceptance_parent_audit". All fields are combined with a logical 'AND'. */
export type Acceptance_Parent_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Acceptance_Parent_Audit_Bool_Exp>>;
  _not?: InputMaybe<Acceptance_Parent_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Acceptance_Parent_Audit_Bool_Exp>>;
};

/** order by max() on columns of table "risksmart.acceptance_parent_audit" */
export type Acceptance_Parent_Audit_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.acceptance_parent_audit" */
export type Acceptance_Parent_Audit_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "risksmart.acceptance_parent_audit". */
export type Acceptance_Parent_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.acceptance_parent_audit" */
export const Acceptance_Parent_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId'
} as const;

export type Acceptance_Parent_Audit_Select_Column = typeof Acceptance_Parent_Audit_Select_Column[keyof typeof Acceptance_Parent_Audit_Select_Column];
/** Streaming cursor of the table "acceptance_parent_audit" */
export type Acceptance_Parent_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Acceptance_Parent_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acceptance_Parent_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.acceptance_parent". All fields are combined with a logical 'AND'. */
export type Acceptance_Parent_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Acceptance_Parent_Bool_Exp>>;
  _not?: InputMaybe<Acceptance_Parent_Bool_Exp>;
  _or?: InputMaybe<Array<Acceptance_Parent_Bool_Exp>>;
  acceptance?: InputMaybe<Acceptance_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parent?: InputMaybe<Node_Bool_Exp>;
  risk?: InputMaybe<Risk_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.acceptance_parent" */
export const Acceptance_Parent_Constraint = {
  /** unique or primary key constraint on columns "ParentId", "Id" */
  AcceptanceParentPkey: 'acceptance_parent_pkey'
} as const;

export type Acceptance_Parent_Constraint = typeof Acceptance_Parent_Constraint[keyof typeof Acceptance_Parent_Constraint];
/** input type for inserting data into table "risksmart.acceptance_parent" */
export type Acceptance_Parent_Insert_Input = {
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  acceptance?: InputMaybe<Acceptance_Obj_Rel_Insert_Input>;
  risk?: InputMaybe<Risk_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Acceptance_Parent_Max_Fields = {
  __typename?: 'acceptance_parent_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.acceptance_parent" */
export type Acceptance_Parent_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Acceptance_Parent_Min_Fields = {
  __typename?: 'acceptance_parent_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.acceptance_parent" */
export type Acceptance_Parent_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.acceptance_parent" */
export type Acceptance_Parent_Mutation_Response = {
  __typename?: 'acceptance_parent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Acceptance_Parent>;
};

/** on_conflict condition type for table "risksmart.acceptance_parent" */
export type Acceptance_Parent_On_Conflict = {
  constraint: Acceptance_Parent_Constraint;
  update_columns?: Array<Acceptance_Parent_Update_Column>;
  where?: InputMaybe<Acceptance_Parent_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.acceptance_parent". */
export type Acceptance_Parent_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  acceptance?: InputMaybe<Acceptance_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parent?: InputMaybe<Node_Order_By>;
  risk?: InputMaybe<Risk_Order_By>;
};

/** select columns of table "risksmart.acceptance_parent" */
export const Acceptance_Parent_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId'
} as const;

export type Acceptance_Parent_Select_Column = typeof Acceptance_Parent_Select_Column[keyof typeof Acceptance_Parent_Select_Column];
/** Streaming cursor of the table "acceptance_parent" */
export type Acceptance_Parent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Acceptance_Parent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acceptance_Parent_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** placeholder for update columns of table "risksmart.acceptance_parent" (current role has no relevant permissions) */
export const Acceptance_Parent_Update_Column = {
  /** placeholder (do not use) */
  Placeholder: '_PLACEHOLDER'
} as const;

export type Acceptance_Parent_Update_Column = typeof Acceptance_Parent_Update_Column[keyof typeof Acceptance_Parent_Update_Column];
/** select columns of table "risksmart.acceptance" */
export const Acceptance_Select_Column = {
  /** column name */
  ApprovedByUser: 'ApprovedByUser',
  /** column name */
  ApprovedByUserGroup: 'ApprovedByUserGroup',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  DateAcceptedFrom: 'DateAcceptedFrom',
  /** column name */
  DateAcceptedTo: 'DateAcceptedTo',
  /** column name */
  Details: 'Details',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  RequestedByUser: 'RequestedByUser',
  /** column name */
  RequestedByUserGroup: 'RequestedByUserGroup',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Status: 'Status',
  /** column name */
  Title: 'Title'
} as const;

export type Acceptance_Select_Column = typeof Acceptance_Select_Column[keyof typeof Acceptance_Select_Column];
export const Acceptance_Status_Enum = {
  /** awaiting closure */
  Awaitingclosure: 'awaitingclosure',
  /** closed */
  Closed: 'closed',
  /** declined */
  Declined: 'declined',
  /** 1st line approval */
  Firstlineapproval: 'firstlineapproval',
  /** open */
  Open: 'open',
  /** pending */
  Pending: 'pending'
} as const;

export type Acceptance_Status_Enum = typeof Acceptance_Status_Enum[keyof typeof Acceptance_Status_Enum];
/** Boolean expression to compare columns of type "acceptance_status_enum". All fields are combined with logical 'AND'. */
export type Acceptance_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Acceptance_Status_Enum>;
  _in?: InputMaybe<Array<Acceptance_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Acceptance_Status_Enum>;
  _nin?: InputMaybe<Array<Acceptance_Status_Enum>>;
};

/** aggregate stddev on columns */
export type Acceptance_Stddev_Fields = {
  __typename?: 'acceptance_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Acceptance_Stddev_Pop_Fields = {
  __typename?: 'acceptance_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Acceptance_Stddev_Samp_Fields = {
  __typename?: 'acceptance_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "acceptance" */
export type Acceptance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Acceptance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acceptance_Stream_Cursor_Value_Input = {
  ApprovedByUser?: InputMaybe<Scalars['String']['input']>;
  ApprovedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateAcceptedFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  DateAcceptedTo?: InputMaybe<Scalars['timestamptz']['input']>;
  Details?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  RequestedByUser?: InputMaybe<Scalars['String']['input']>;
  RequestedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Acceptance_Status_Enum>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Acceptance_Sum_Fields = {
  __typename?: 'acceptance_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.acceptance" */
export const Acceptance_Update_Column = {
  /** column name */
  ApprovedByUser: 'ApprovedByUser',
  /** column name */
  ApprovedByUserGroup: 'ApprovedByUserGroup',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  DateAcceptedFrom: 'DateAcceptedFrom',
  /** column name */
  DateAcceptedTo: 'DateAcceptedTo',
  /** column name */
  Details: 'Details',
  /** column name */
  RequestedByUser: 'RequestedByUser',
  /** column name */
  RequestedByUserGroup: 'RequestedByUserGroup',
  /** column name */
  Status: 'Status',
  /** column name */
  Title: 'Title'
} as const;

export type Acceptance_Update_Column = typeof Acceptance_Update_Column[keyof typeof Acceptance_Update_Column];
/** aggregate var_pop on columns */
export type Acceptance_Var_Pop_Fields = {
  __typename?: 'acceptance_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Acceptance_Var_Samp_Fields = {
  __typename?: 'acceptance_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Acceptance_Variance_Fields = {
  __typename?: 'acceptance_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

export const Access_Type_Enum = {
  /** Delete */
  Delete: 'delete',
  /** Insert */
  Insert: 'insert',
  /** Read */
  Read: 'read',
  /** Update */
  Update: 'update'
} as const;

export type Access_Type_Enum = typeof Access_Type_Enum[keyof typeof Access_Type_Enum];
/** Boolean expression to compare columns of type "access_type_enum". All fields are combined with logical 'AND'. */
export type Access_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Access_Type_Enum>;
  _in?: InputMaybe<Array<Access_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Access_Type_Enum>;
  _nin?: InputMaybe<Array<Access_Type_Enum>>;
};

/** columns and relationships of "risksmart.action" */
export type Action = {
  __typename?: 'action';
  ClosedDate?: Maybe<Scalars['timestamptz']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  DateDue: Scalars['timestamptz']['output'];
  DateRaised: Scalars['timestamptz']['output'];
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Priority?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status: Action_Status_Enum;
  Title: Scalars['String']['output'];
  /** An object relationship */
  actionUpdateSummary?: Maybe<Action_Update_Summary>;
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An array relationship */
  contributorGroups: Array<Contributor_Group>;
  /** An aggregate relationship */
  contributorGroups_aggregate: Contributor_Group_Aggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: Contributor_Aggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An array relationship */
  files: Array<Relation_File>;
  /** An aggregate relationship */
  files_aggregate: Relation_File_Aggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An array relationship */
  ownerGroups: Array<Owner_Group>;
  /** An aggregate relationship */
  ownerGroups_aggregate: Owner_Group_Aggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: Owner_Aggregate;
  /** An array relationship */
  parents: Array<Action_Parent>;
  /** An aggregate relationship */
  parents_aggregate: Action_Parent_Aggregate;
  /** An array relationship */
  tags: Array<Tag>;
  /** An array relationship */
  updates: Array<Action_Update>;
  /** An aggregate relationship */
  updates_aggregate: Action_Update_Aggregate;
};


/** columns and relationships of "risksmart.action" */
export type ActionCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.action" */
export type ActionAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionContributorGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionContributorsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionContributors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionFilesArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionOwnerGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionOwnersArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionOwners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionParentsArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionParents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionTagsArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionUpdatesArgs = {
  distinct_on?: InputMaybe<Array<Action_Update_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Update_Order_By>>;
  where?: InputMaybe<Action_Update_Bool_Exp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionUpdates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Update_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Update_Order_By>>;
  where?: InputMaybe<Action_Update_Bool_Exp>;
};

/** aggregated selection of "risksmart.action" */
export type Action_Aggregate = {
  __typename?: 'action_aggregate';
  aggregate?: Maybe<Action_Aggregate_Fields>;
  nodes: Array<Action>;
};

/** aggregate fields of "risksmart.action" */
export type Action_Aggregate_Fields = {
  __typename?: 'action_aggregate_fields';
  avg?: Maybe<Action_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Action_Max_Fields>;
  min?: Maybe<Action_Min_Fields>;
  stddev?: Maybe<Action_Stddev_Fields>;
  stddev_pop?: Maybe<Action_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Action_Stddev_Samp_Fields>;
  sum?: Maybe<Action_Sum_Fields>;
  var_pop?: Maybe<Action_Var_Pop_Fields>;
  var_samp?: Maybe<Action_Var_Samp_Fields>;
  variance?: Maybe<Action_Variance_Fields>;
};


/** aggregate fields of "risksmart.action" */
export type Action_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Action_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "risksmart.action_audit" */
export type Action_Audit = {
  __typename?: 'action_audit';
  ClosedDate?: Maybe<Scalars['timestamptz']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  DateDue: Scalars['timestamptz']['output'];
  DateRaised: Scalars['timestamptz']['output'];
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Priority?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status: Scalars['String']['output'];
  Title: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.action_audit" */
export type Action_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.action_audit" */
export type Action_Audit_Aggregate = {
  __typename?: 'action_audit_aggregate';
  aggregate?: Maybe<Action_Audit_Aggregate_Fields>;
  nodes: Array<Action_Audit>;
};

/** aggregate fields of "risksmart.action_audit" */
export type Action_Audit_Aggregate_Fields = {
  __typename?: 'action_audit_aggregate_fields';
  avg?: Maybe<Action_Audit_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Action_Audit_Max_Fields>;
  min?: Maybe<Action_Audit_Min_Fields>;
  stddev?: Maybe<Action_Audit_Stddev_Fields>;
  stddev_pop?: Maybe<Action_Audit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Action_Audit_Stddev_Samp_Fields>;
  sum?: Maybe<Action_Audit_Sum_Fields>;
  var_pop?: Maybe<Action_Audit_Var_Pop_Fields>;
  var_samp?: Maybe<Action_Audit_Var_Samp_Fields>;
  variance?: Maybe<Action_Audit_Variance_Fields>;
};


/** aggregate fields of "risksmart.action_audit" */
export type Action_Audit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Action_Audit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Action_Audit_Avg_Fields = {
  __typename?: 'action_audit_avg_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.action_audit". All fields are combined with a logical 'AND'. */
export type Action_Audit_Bool_Exp = {
  ClosedDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  DateDue?: InputMaybe<Timestamptz_Comparison_Exp>;
  DateRaised?: InputMaybe<Timestamptz_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Priority?: InputMaybe<Int_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Status?: InputMaybe<String_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Action_Audit_Bool_Exp>>;
  _not?: InputMaybe<Action_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Action_Audit_Bool_Exp>>;
};

/** aggregate max on columns */
export type Action_Audit_Max_Fields = {
  __typename?: 'action_audit_max_fields';
  ClosedDate?: Maybe<Scalars['timestamptz']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DateDue?: Maybe<Scalars['timestamptz']['output']>;
  DateRaised?: Maybe<Scalars['timestamptz']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Priority?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Action_Audit_Min_Fields = {
  __typename?: 'action_audit_min_fields';
  ClosedDate?: Maybe<Scalars['timestamptz']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DateDue?: Maybe<Scalars['timestamptz']['output']>;
  DateRaised?: Maybe<Scalars['timestamptz']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Priority?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "risksmart.action_audit". */
export type Action_Audit_Order_By = {
  ClosedDate?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  DateDue?: InputMaybe<Order_By>;
  DateRaised?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Priority?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.action_audit" */
export const Action_Audit_Select_Column = {
  /** column name */
  ClosedDate: 'ClosedDate',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  DateDue: 'DateDue',
  /** column name */
  DateRaised: 'DateRaised',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Priority: 'Priority',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Status: 'Status',
  /** column name */
  Title: 'Title'
} as const;

export type Action_Audit_Select_Column = typeof Action_Audit_Select_Column[keyof typeof Action_Audit_Select_Column];
/** aggregate stddev on columns */
export type Action_Audit_Stddev_Fields = {
  __typename?: 'action_audit_stddev_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Action_Audit_Stddev_Pop_Fields = {
  __typename?: 'action_audit_stddev_pop_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Action_Audit_Stddev_Samp_Fields = {
  __typename?: 'action_audit_stddev_samp_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "action_audit" */
export type Action_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Action_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Action_Audit_Stream_Cursor_Value_Input = {
  ClosedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateDue?: InputMaybe<Scalars['timestamptz']['input']>;
  DateRaised?: InputMaybe<Scalars['timestamptz']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Priority?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Action_Audit_Sum_Fields = {
  __typename?: 'action_audit_sum_fields';
  Priority?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Action_Audit_Var_Pop_Fields = {
  __typename?: 'action_audit_var_pop_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Action_Audit_Var_Samp_Fields = {
  __typename?: 'action_audit_var_samp_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Action_Audit_Variance_Fields = {
  __typename?: 'action_audit_variance_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type Action_Avg_Fields = {
  __typename?: 'action_avg_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.action". All fields are combined with a logical 'AND'. */
export type Action_Bool_Exp = {
  ClosedDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  DateDue?: InputMaybe<Timestamptz_Comparison_Exp>;
  DateRaised?: InputMaybe<Timestamptz_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Priority?: InputMaybe<Int_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Status?: InputMaybe<Action_Status_Enum_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Action_Bool_Exp>>;
  _not?: InputMaybe<Action_Bool_Exp>;
  _or?: InputMaybe<Array<Action_Bool_Exp>>;
  actionUpdateSummary?: InputMaybe<Action_Update_Summary_Bool_Exp>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  contributorGroups?: InputMaybe<Contributor_Group_Bool_Exp>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Bool_Exp>;
  contributors?: InputMaybe<Contributor_Bool_Exp>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  departments?: InputMaybe<Department_Bool_Exp>;
  files?: InputMaybe<Relation_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  ownerGroups?: InputMaybe<Owner_Group_Bool_Exp>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Bool_Exp>;
  owners?: InputMaybe<Owner_Bool_Exp>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Bool_Exp>;
  parents?: InputMaybe<Action_Parent_Bool_Exp>;
  parents_aggregate?: InputMaybe<Action_Parent_Aggregate_Bool_Exp>;
  tags?: InputMaybe<Tag_Bool_Exp>;
  updates?: InputMaybe<Action_Update_Bool_Exp>;
  updates_aggregate?: InputMaybe<Action_Update_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.action" */
export const Action_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  ActionPkey: 'action_pkey',
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxActionOrgkeySequentialid: 'idx_action_orgkey_sequentialid'
} as const;

export type Action_Constraint = typeof Action_Constraint[keyof typeof Action_Constraint];
/** input type for inserting data into table "risksmart.action" */
export type Action_Insert_Input = {
  ClosedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateDue?: InputMaybe<Scalars['timestamptz']['input']>;
  DateRaised?: InputMaybe<Scalars['timestamptz']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Priority?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Action_Status_Enum>;
  Title?: InputMaybe<Scalars['String']['input']>;
  contributorGroups?: InputMaybe<Contributor_Group_Arr_Rel_Insert_Input>;
  contributors?: InputMaybe<Contributor_Arr_Rel_Insert_Input>;
  departments?: InputMaybe<Department_Arr_Rel_Insert_Input>;
  files?: InputMaybe<Relation_File_Arr_Rel_Insert_Input>;
  ownerGroups?: InputMaybe<Owner_Group_Arr_Rel_Insert_Input>;
  owners?: InputMaybe<Owner_Arr_Rel_Insert_Input>;
  parents?: InputMaybe<Action_Parent_Arr_Rel_Insert_Input>;
  tags?: InputMaybe<Tag_Arr_Rel_Insert_Input>;
  updates?: InputMaybe<Action_Update_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Action_Max_Fields = {
  __typename?: 'action_max_fields';
  ClosedDate?: Maybe<Scalars['timestamptz']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DateDue?: Maybe<Scalars['timestamptz']['output']>;
  DateRaised?: Maybe<Scalars['timestamptz']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Priority?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Action_Min_Fields = {
  __typename?: 'action_min_fields';
  ClosedDate?: Maybe<Scalars['timestamptz']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DateDue?: Maybe<Scalars['timestamptz']['output']>;
  DateRaised?: Maybe<Scalars['timestamptz']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Priority?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** input type for inserting object relation for remote table "risksmart.action" */
export type Action_Obj_Rel_Insert_Input = {
  data: Action_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Action_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.action" */
export type Action_On_Conflict = {
  constraint: Action_Constraint;
  update_columns?: Array<Action_Update_Column>;
  where?: InputMaybe<Action_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.action". */
export type Action_Order_By = {
  ClosedDate?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  DateDue?: InputMaybe<Order_By>;
  DateRaised?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Priority?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  actionUpdateSummary?: InputMaybe<Action_Update_Summary_Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Order_By>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  departments_aggregate?: InputMaybe<Department_Aggregate_Order_By>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Order_By>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Order_By>;
  parents_aggregate?: InputMaybe<Action_Parent_Aggregate_Order_By>;
  tags_aggregate?: InputMaybe<Tag_Aggregate_Order_By>;
  updates_aggregate?: InputMaybe<Action_Update_Aggregate_Order_By>;
};

/** columns and relationships of "risksmart.action_parent" */
export type Action_Parent = {
  __typename?: 'action_parent';
  ActionId: Scalars['uuid']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  ParentType: Parent_Type_Enum;
  /** An object relationship */
  action?: Maybe<Action>;
  /** An object relationship */
  assessment?: Maybe<Assessment>;
  /** An object relationship */
  complianceMonitoringAssessment?: Maybe<Compliance_Monitoring_Assessment>;
  /** An object relationship */
  control?: Maybe<Control>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  document?: Maybe<Document>;
  /** An object relationship */
  internalAuditEntity?: Maybe<Internal_Audit_Entity>;
  /** An object relationship */
  internalAuditReport?: Maybe<Internal_Audit_Report>;
  /** An object relationship */
  issue?: Maybe<Issue>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  obligation?: Maybe<Obligation>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parent?: Maybe<Node>;
  /** An object relationship */
  risk?: Maybe<Risk>;
  /** An object relationship */
  thirdParty?: Maybe<Third_Party>;
};

/** aggregated selection of "risksmart.action_parent" */
export type Action_Parent_Aggregate = {
  __typename?: 'action_parent_aggregate';
  aggregate?: Maybe<Action_Parent_Aggregate_Fields>;
  nodes: Array<Action_Parent>;
};

export type Action_Parent_Aggregate_Bool_Exp = {
  count?: InputMaybe<Action_Parent_Aggregate_Bool_Exp_Count>;
};

export type Action_Parent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Action_Parent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Action_Parent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.action_parent" */
export type Action_Parent_Aggregate_Fields = {
  __typename?: 'action_parent_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Action_Parent_Max_Fields>;
  min?: Maybe<Action_Parent_Min_Fields>;
};


/** aggregate fields of "risksmart.action_parent" */
export type Action_Parent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Action_Parent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.action_parent" */
export type Action_Parent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Action_Parent_Max_Order_By>;
  min?: InputMaybe<Action_Parent_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.action_parent" */
export type Action_Parent_Arr_Rel_Insert_Input = {
  data: Array<Action_Parent_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Action_Parent_On_Conflict>;
};

/** columns and relationships of "risksmart.action_parent_audit" */
export type Action_Parent_Audit = {
  __typename?: 'action_parent_audit';
  ActionId: Scalars['uuid']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId: Scalars['uuid']['output'];
  ParentType?: Maybe<Scalars['String']['output']>;
};

/** order by aggregate values of table "risksmart.action_parent_audit" */
export type Action_Parent_Audit_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Action_Parent_Audit_Max_Order_By>;
  min?: InputMaybe<Action_Parent_Audit_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.action_parent_audit". All fields are combined with a logical 'AND'. */
export type Action_Parent_Audit_Bool_Exp = {
  ActionId?: InputMaybe<Uuid_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  ParentType?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Action_Parent_Audit_Bool_Exp>>;
  _not?: InputMaybe<Action_Parent_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Action_Parent_Audit_Bool_Exp>>;
};

/** order by max() on columns of table "risksmart.action_parent_audit" */
export type Action_Parent_Audit_Max_Order_By = {
  ActionId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  ParentType?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.action_parent_audit" */
export type Action_Parent_Audit_Min_Order_By = {
  ActionId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  ParentType?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "risksmart.action_parent_audit". */
export type Action_Parent_Audit_Order_By = {
  ActionId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  ParentType?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.action_parent_audit" */
export const Action_Parent_Audit_Select_Column = {
  /** column name */
  ActionId: 'ActionId',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  ParentType: 'ParentType'
} as const;

export type Action_Parent_Audit_Select_Column = typeof Action_Parent_Audit_Select_Column[keyof typeof Action_Parent_Audit_Select_Column];
/** Streaming cursor of the table "action_parent_audit" */
export type Action_Parent_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Action_Parent_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Action_Parent_Audit_Stream_Cursor_Value_Input = {
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.action_parent". All fields are combined with a logical 'AND'. */
export type Action_Parent_Bool_Exp = {
  ActionId?: InputMaybe<Uuid_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  ParentType?: InputMaybe<Parent_Type_Enum_Comparison_Exp>;
  _and?: InputMaybe<Array<Action_Parent_Bool_Exp>>;
  _not?: InputMaybe<Action_Parent_Bool_Exp>;
  _or?: InputMaybe<Array<Action_Parent_Bool_Exp>>;
  action?: InputMaybe<Action_Bool_Exp>;
  assessment?: InputMaybe<Assessment_Bool_Exp>;
  complianceMonitoringAssessment?: InputMaybe<Compliance_Monitoring_Assessment_Bool_Exp>;
  control?: InputMaybe<Control_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  document?: InputMaybe<Document_Bool_Exp>;
  internalAuditEntity?: InputMaybe<Internal_Audit_Entity_Bool_Exp>;
  internalAuditReport?: InputMaybe<Internal_Audit_Report_Bool_Exp>;
  issue?: InputMaybe<Issue_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  obligation?: InputMaybe<Obligation_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parent?: InputMaybe<Node_Bool_Exp>;
  risk?: InputMaybe<Risk_Bool_Exp>;
  thirdParty?: InputMaybe<Third_Party_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.action_parent" */
export const Action_Parent_Constraint = {
  /** unique or primary key constraint on columns "ActionId", "ParentId" */
  ActionParentPkey: 'action_parent_pkey'
} as const;

export type Action_Parent_Constraint = typeof Action_Parent_Constraint[keyof typeof Action_Parent_Constraint];
/** input type for inserting data into table "risksmart.action_parent" */
export type Action_Parent_Insert_Input = {
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Parent_Type_Enum>;
  action?: InputMaybe<Action_Obj_Rel_Insert_Input>;
  assessment?: InputMaybe<Assessment_Obj_Rel_Insert_Input>;
  complianceMonitoringAssessment?: InputMaybe<Compliance_Monitoring_Assessment_Obj_Rel_Insert_Input>;
  control?: InputMaybe<Control_Obj_Rel_Insert_Input>;
  document?: InputMaybe<Document_Obj_Rel_Insert_Input>;
  internalAuditEntity?: InputMaybe<Internal_Audit_Entity_Obj_Rel_Insert_Input>;
  internalAuditReport?: InputMaybe<Internal_Audit_Report_Obj_Rel_Insert_Input>;
  issue?: InputMaybe<Issue_Obj_Rel_Insert_Input>;
  obligation?: InputMaybe<Obligation_Obj_Rel_Insert_Input>;
  risk?: InputMaybe<Risk_Obj_Rel_Insert_Input>;
  thirdParty?: InputMaybe<Third_Party_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Action_Parent_Max_Fields = {
  __typename?: 'action_parent_max_fields';
  ActionId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.action_parent" */
export type Action_Parent_Max_Order_By = {
  ActionId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Action_Parent_Min_Fields = {
  __typename?: 'action_parent_min_fields';
  ActionId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.action_parent" */
export type Action_Parent_Min_Order_By = {
  ActionId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.action_parent" */
export type Action_Parent_Mutation_Response = {
  __typename?: 'action_parent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Action_Parent>;
};

/** on_conflict condition type for table "risksmart.action_parent" */
export type Action_Parent_On_Conflict = {
  constraint: Action_Parent_Constraint;
  update_columns?: Array<Action_Parent_Update_Column>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.action_parent". */
export type Action_Parent_Order_By = {
  ActionId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  ParentType?: InputMaybe<Order_By>;
  action?: InputMaybe<Action_Order_By>;
  assessment?: InputMaybe<Assessment_Order_By>;
  complianceMonitoringAssessment?: InputMaybe<Compliance_Monitoring_Assessment_Order_By>;
  control?: InputMaybe<Control_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  document?: InputMaybe<Document_Order_By>;
  internalAuditEntity?: InputMaybe<Internal_Audit_Entity_Order_By>;
  internalAuditReport?: InputMaybe<Internal_Audit_Report_Order_By>;
  issue?: InputMaybe<Issue_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  obligation?: InputMaybe<Obligation_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parent?: InputMaybe<Node_Order_By>;
  risk?: InputMaybe<Risk_Order_By>;
  thirdParty?: InputMaybe<Third_Party_Order_By>;
};

/** select columns of table "risksmart.action_parent" */
export const Action_Parent_Select_Column = {
  /** column name */
  ActionId: 'ActionId',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  ParentType: 'ParentType'
} as const;

export type Action_Parent_Select_Column = typeof Action_Parent_Select_Column[keyof typeof Action_Parent_Select_Column];
/** Streaming cursor of the table "action_parent" */
export type Action_Parent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Action_Parent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Action_Parent_Stream_Cursor_Value_Input = {
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Parent_Type_Enum>;
};

/** placeholder for update columns of table "risksmart.action_parent" (current role has no relevant permissions) */
export const Action_Parent_Update_Column = {
  /** placeholder (do not use) */
  Placeholder: '_PLACEHOLDER'
} as const;

export type Action_Parent_Update_Column = typeof Action_Parent_Update_Column[keyof typeof Action_Parent_Update_Column];
/** select columns of table "risksmart.action" */
export const Action_Select_Column = {
  /** column name */
  ClosedDate: 'ClosedDate',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  DateDue: 'DateDue',
  /** column name */
  DateRaised: 'DateRaised',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Priority: 'Priority',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Status: 'Status',
  /** column name */
  Title: 'Title'
} as const;

export type Action_Select_Column = typeof Action_Select_Column[keyof typeof Action_Select_Column];
export const Action_Status_Enum = {
  /** Closed */
  Closed: 'closed',
  /** Open */
  Open: 'open',
  /** Pending */
  Pending: 'pending'
} as const;

export type Action_Status_Enum = typeof Action_Status_Enum[keyof typeof Action_Status_Enum];
/** Boolean expression to compare columns of type "action_status_enum". All fields are combined with logical 'AND'. */
export type Action_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Action_Status_Enum>;
  _in?: InputMaybe<Array<Action_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Action_Status_Enum>;
  _nin?: InputMaybe<Array<Action_Status_Enum>>;
};

/** aggregate stddev on columns */
export type Action_Stddev_Fields = {
  __typename?: 'action_stddev_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Action_Stddev_Pop_Fields = {
  __typename?: 'action_stddev_pop_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Action_Stddev_Samp_Fields = {
  __typename?: 'action_stddev_samp_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "action" */
export type Action_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Action_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Action_Stream_Cursor_Value_Input = {
  ClosedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateDue?: InputMaybe<Scalars['timestamptz']['input']>;
  DateRaised?: InputMaybe<Scalars['timestamptz']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Priority?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Action_Status_Enum>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Action_Sum_Fields = {
  __typename?: 'action_sum_fields';
  Priority?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "risksmart.action_update" */
export type Action_Update = {
  __typename?: 'action_update';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentActionId: Scalars['uuid']['output'];
  Title: Scalars['String']['output'];
  /** An object relationship */
  action?: Maybe<Action>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  files: Array<Relation_File>;
  /** An aggregate relationship */
  files_aggregate: Relation_File_Aggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
};


/** columns and relationships of "risksmart.action_update" */
export type Action_UpdateCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.action_update" */
export type Action_UpdateFilesArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.action_update" */
export type Action_UpdateFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};

/** aggregated selection of "risksmart.action_update" */
export type Action_Update_Aggregate = {
  __typename?: 'action_update_aggregate';
  aggregate?: Maybe<Action_Update_Aggregate_Fields>;
  nodes: Array<Action_Update>;
};

export type Action_Update_Aggregate_Bool_Exp = {
  count?: InputMaybe<Action_Update_Aggregate_Bool_Exp_Count>;
};

export type Action_Update_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Action_Update_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Action_Update_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.action_update" */
export type Action_Update_Aggregate_Fields = {
  __typename?: 'action_update_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Action_Update_Max_Fields>;
  min?: Maybe<Action_Update_Min_Fields>;
};


/** aggregate fields of "risksmart.action_update" */
export type Action_Update_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Action_Update_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.action_update" */
export type Action_Update_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Action_Update_Max_Order_By>;
  min?: InputMaybe<Action_Update_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Action_Update_Append_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.action_update" */
export type Action_Update_Arr_Rel_Insert_Input = {
  data: Array<Action_Update_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Action_Update_On_Conflict>;
};

/** columns and relationships of "risksmart.action_update_audit" */
export type Action_Update_Audit = {
  __typename?: 'action_update_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentActionId: Scalars['uuid']['output'];
  Title: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.action_update_audit" */
export type Action_Update_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.action_update_audit". All fields are combined with a logical 'AND'. */
export type Action_Update_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentActionId?: InputMaybe<Uuid_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Action_Update_Audit_Bool_Exp>>;
  _not?: InputMaybe<Action_Update_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Action_Update_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.action_update_audit". */
export type Action_Update_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentActionId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.action_update_audit" */
export const Action_Update_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentActionId: 'ParentActionId',
  /** column name */
  Title: 'Title'
} as const;

export type Action_Update_Audit_Select_Column = typeof Action_Update_Audit_Select_Column[keyof typeof Action_Update_Audit_Select_Column];
/** Streaming cursor of the table "action_update_audit" */
export type Action_Update_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Action_Update_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Action_Update_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentActionId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.action_update". All fields are combined with a logical 'AND'. */
export type Action_Update_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentActionId?: InputMaybe<Uuid_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Action_Update_Bool_Exp>>;
  _not?: InputMaybe<Action_Update_Bool_Exp>;
  _or?: InputMaybe<Array<Action_Update_Bool_Exp>>;
  action?: InputMaybe<Action_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  files?: InputMaybe<Relation_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
};

/** update columns of table "risksmart.action" */
export const Action_Update_Column = {
  /** column name */
  ClosedDate: 'ClosedDate',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  DateDue: 'DateDue',
  /** column name */
  DateRaised: 'DateRaised',
  /** column name */
  Description: 'Description',
  /** column name */
  Priority: 'Priority',
  /** column name */
  Status: 'Status',
  /** column name */
  Title: 'Title'
} as const;

export type Action_Update_Column = typeof Action_Update_Column[keyof typeof Action_Update_Column];
/** unique or primary key constraints on table "risksmart.action_update" */
export const Action_Update_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  ActionUpdatePkey: 'action_update_pkey'
} as const;

export type Action_Update_Constraint = typeof Action_Update_Constraint[keyof typeof Action_Update_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Action_Update_Delete_At_Path_Input = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Action_Update_Delete_Elem_Input = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Action_Update_Delete_Key_Input = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.action_update" */
export type Action_Update_Insert_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ParentActionId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  action?: InputMaybe<Action_Obj_Rel_Insert_Input>;
  files?: InputMaybe<Relation_File_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Action_Update_Max_Fields = {
  __typename?: 'action_update_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentActionId?: Maybe<Scalars['uuid']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.action_update" */
export type Action_Update_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentActionId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Action_Update_Min_Fields = {
  __typename?: 'action_update_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentActionId?: Maybe<Scalars['uuid']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.action_update" */
export type Action_Update_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentActionId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.action_update" */
export type Action_Update_Mutation_Response = {
  __typename?: 'action_update_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Action_Update>;
};

/** on_conflict condition type for table "risksmart.action_update" */
export type Action_Update_On_Conflict = {
  constraint: Action_Update_Constraint;
  update_columns?: Array<Action_Update_Update_Column>;
  where?: InputMaybe<Action_Update_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.action_update". */
export type Action_Update_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentActionId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  action?: InputMaybe<Action_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
};

/** primary key columns input for table: risksmart.action_update */
export type Action_Update_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Action_Update_Prepend_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.action_update" */
export const Action_Update_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentActionId: 'ParentActionId',
  /** column name */
  Title: 'Title'
} as const;

export type Action_Update_Select_Column = typeof Action_Update_Select_Column[keyof typeof Action_Update_Select_Column];
/** input type for updating data in table "risksmart.action_update" */
export type Action_Update_Set_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ParentActionId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "action_update" */
export type Action_Update_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Action_Update_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Action_Update_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentActionId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.action_update_summary_view" */
export type Action_Update_Summary = {
  __typename?: 'action_update_summary';
  ActionId?: Maybe<Scalars['uuid']['output']>;
  Count?: Maybe<Scalars['bigint']['output']>;
  LatestCreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  LatestDescription?: Maybe<Scalars['String']['output']>;
  LatestTitle?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "risksmart.action_update_summary_view" */
export type Action_Update_Summary_Aggregate = {
  __typename?: 'action_update_summary_aggregate';
  aggregate?: Maybe<Action_Update_Summary_Aggregate_Fields>;
  nodes: Array<Action_Update_Summary>;
};

/** aggregate fields of "risksmart.action_update_summary_view" */
export type Action_Update_Summary_Aggregate_Fields = {
  __typename?: 'action_update_summary_aggregate_fields';
  avg?: Maybe<Action_Update_Summary_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Action_Update_Summary_Max_Fields>;
  min?: Maybe<Action_Update_Summary_Min_Fields>;
  stddev?: Maybe<Action_Update_Summary_Stddev_Fields>;
  stddev_pop?: Maybe<Action_Update_Summary_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Action_Update_Summary_Stddev_Samp_Fields>;
  sum?: Maybe<Action_Update_Summary_Sum_Fields>;
  var_pop?: Maybe<Action_Update_Summary_Var_Pop_Fields>;
  var_samp?: Maybe<Action_Update_Summary_Var_Samp_Fields>;
  variance?: Maybe<Action_Update_Summary_Variance_Fields>;
};


/** aggregate fields of "risksmart.action_update_summary_view" */
export type Action_Update_Summary_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Action_Update_Summary_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Action_Update_Summary_Avg_Fields = {
  __typename?: 'action_update_summary_avg_fields';
  Count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.action_update_summary_view". All fields are combined with a logical 'AND'. */
export type Action_Update_Summary_Bool_Exp = {
  ActionId?: InputMaybe<Uuid_Comparison_Exp>;
  Count?: InputMaybe<Bigint_Comparison_Exp>;
  LatestCreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  LatestDescription?: InputMaybe<String_Comparison_Exp>;
  LatestTitle?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Action_Update_Summary_Bool_Exp>>;
  _not?: InputMaybe<Action_Update_Summary_Bool_Exp>;
  _or?: InputMaybe<Array<Action_Update_Summary_Bool_Exp>>;
};

/** aggregate max on columns */
export type Action_Update_Summary_Max_Fields = {
  __typename?: 'action_update_summary_max_fields';
  ActionId?: Maybe<Scalars['uuid']['output']>;
  Count?: Maybe<Scalars['bigint']['output']>;
  LatestCreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  LatestDescription?: Maybe<Scalars['String']['output']>;
  LatestTitle?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Action_Update_Summary_Min_Fields = {
  __typename?: 'action_update_summary_min_fields';
  ActionId?: Maybe<Scalars['uuid']['output']>;
  Count?: Maybe<Scalars['bigint']['output']>;
  LatestCreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  LatestDescription?: Maybe<Scalars['String']['output']>;
  LatestTitle?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "risksmart.action_update_summary_view". */
export type Action_Update_Summary_Order_By = {
  ActionId?: InputMaybe<Order_By>;
  Count?: InputMaybe<Order_By>;
  LatestCreatedAtTimestamp?: InputMaybe<Order_By>;
  LatestDescription?: InputMaybe<Order_By>;
  LatestTitle?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.action_update_summary_view" */
export const Action_Update_Summary_Select_Column = {
  /** column name */
  ActionId: 'ActionId',
  /** column name */
  Count: 'Count',
  /** column name */
  LatestCreatedAtTimestamp: 'LatestCreatedAtTimestamp',
  /** column name */
  LatestDescription: 'LatestDescription',
  /** column name */
  LatestTitle: 'LatestTitle'
} as const;

export type Action_Update_Summary_Select_Column = typeof Action_Update_Summary_Select_Column[keyof typeof Action_Update_Summary_Select_Column];
/** aggregate stddev on columns */
export type Action_Update_Summary_Stddev_Fields = {
  __typename?: 'action_update_summary_stddev_fields';
  Count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Action_Update_Summary_Stddev_Pop_Fields = {
  __typename?: 'action_update_summary_stddev_pop_fields';
  Count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Action_Update_Summary_Stddev_Samp_Fields = {
  __typename?: 'action_update_summary_stddev_samp_fields';
  Count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "action_update_summary" */
export type Action_Update_Summary_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Action_Update_Summary_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Action_Update_Summary_Stream_Cursor_Value_Input = {
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  Count?: InputMaybe<Scalars['bigint']['input']>;
  LatestCreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  LatestDescription?: InputMaybe<Scalars['String']['input']>;
  LatestTitle?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Action_Update_Summary_Sum_Fields = {
  __typename?: 'action_update_summary_sum_fields';
  Count?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type Action_Update_Summary_Var_Pop_Fields = {
  __typename?: 'action_update_summary_var_pop_fields';
  Count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Action_Update_Summary_Var_Samp_Fields = {
  __typename?: 'action_update_summary_var_samp_fields';
  Count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Action_Update_Summary_Variance_Fields = {
  __typename?: 'action_update_summary_variance_fields';
  Count?: Maybe<Scalars['Float']['output']>;
};

/** update columns of table "risksmart.action_update" */
export const Action_Update_Update_Column = {
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  ParentActionId: 'ParentActionId',
  /** column name */
  Title: 'Title'
} as const;

export type Action_Update_Update_Column = typeof Action_Update_Update_Column[keyof typeof Action_Update_Update_Column];
export type Action_Update_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Action_Update_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Action_Update_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Action_Update_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Action_Update_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Action_Update_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Action_Update_Set_Input>;
  /** filter the rows which have to be updated */
  where: Action_Update_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Action_Var_Pop_Fields = {
  __typename?: 'action_var_pop_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Action_Var_Samp_Fields = {
  __typename?: 'action_var_samp_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Action_Variance_Fields = {
  __typename?: 'action_variance_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.aggregation_org" */
export type Aggregation_Org = {
  __typename?: 'aggregation_org';
  Appetite?: Maybe<Appetite_Model_Enum>;
  Config?: Maybe<Scalars['jsonb']['output']>;
  OrgKey: Scalars['String']['output'];
  RiskScoringModel?: Maybe<Risk_Scoring_Model_Enum>;
  /** An object relationship */
  organisation: Auth_Organisation;
};


/** columns and relationships of "risksmart.aggregation_org" */
export type Aggregation_OrgConfigArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.aggregation_org". All fields are combined with a logical 'AND'. */
export type Aggregation_Org_Bool_Exp = {
  Appetite?: InputMaybe<Appetite_Model_Enum_Comparison_Exp>;
  Config?: InputMaybe<Jsonb_Comparison_Exp>;
  OrgKey?: InputMaybe<String_Comparison_Exp>;
  RiskScoringModel?: InputMaybe<Risk_Scoring_Model_Enum_Comparison_Exp>;
  _and?: InputMaybe<Array<Aggregation_Org_Bool_Exp>>;
  _not?: InputMaybe<Aggregation_Org_Bool_Exp>;
  _or?: InputMaybe<Array<Aggregation_Org_Bool_Exp>>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.aggregation_org". */
export type Aggregation_Org_Order_By = {
  Appetite?: InputMaybe<Order_By>;
  Config?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  RiskScoringModel?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
};

/** select columns of table "risksmart.aggregation_org" */
export const Aggregation_Org_Select_Column = {
  /** column name */
  Appetite: 'Appetite',
  /** column name */
  Config: 'Config',
  /** column name */
  OrgKey: 'OrgKey',
  /** column name */
  RiskScoringModel: 'RiskScoringModel'
} as const;

export type Aggregation_Org_Select_Column = typeof Aggregation_Org_Select_Column[keyof typeof Aggregation_Org_Select_Column];
/** Streaming cursor of the table "aggregation_org" */
export type Aggregation_Org_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Aggregation_Org_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Aggregation_Org_Stream_Cursor_Value_Input = {
  Appetite?: InputMaybe<Appetite_Model_Enum>;
  Config?: InputMaybe<Scalars['jsonb']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RiskScoringModel?: InputMaybe<Risk_Scoring_Model_Enum>;
};

/** columns and relationships of "risksmart.ancestor_contributor_view" */
export type Ancestor_Contributor = {
  __typename?: 'ancestor_contributor';
  AncestorId?: Maybe<Scalars['uuid']['output']>;
  ContributorType?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ObjectType?: Maybe<Scalars['String']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  acceptance?: Maybe<Acceptance>;
  /** An object relationship */
  action?: Maybe<Action>;
  /** An object relationship */
  appetite?: Maybe<Appetite>;
  /** An object relationship */
  assessment?: Maybe<Assessment>;
  /** An object relationship */
  control?: Maybe<Control>;
  /** An object relationship */
  dashboard?: Maybe<Dashboard>;
  /** An object relationship */
  document?: Maybe<Document>;
  /** An object relationship */
  document_assessment_result?: Maybe<Document_Assessment_Result>;
  /** An object relationship */
  impact?: Maybe<Impact>;
  /** An object relationship */
  indicator?: Maybe<Indicator>;
  /** An object relationship */
  issue?: Maybe<Issue>;
  /** An object relationship */
  node?: Maybe<Node>;
  /** An object relationship */
  obligation?: Maybe<Obligation>;
  /** An object relationship */
  obligationAssessmentResult?: Maybe<Obligation_Assessment_Result>;
  /** An object relationship */
  risk?: Maybe<Risk>;
  /** An object relationship */
  risk_assessment_result?: Maybe<Risk_Assessment_Result>;
  /** An object relationship */
  user?: Maybe<User>;
  /** An object relationship */
  user_group?: Maybe<User_Group>;
};

/** order by aggregate values of table "risksmart.ancestor_contributor_view" */
export type Ancestor_Contributor_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ancestor_Contributor_Max_Order_By>;
  min?: InputMaybe<Ancestor_Contributor_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.ancestor_contributor_view". All fields are combined with a logical 'AND'. */
export type Ancestor_Contributor_Bool_Exp = {
  AncestorId?: InputMaybe<Uuid_Comparison_Exp>;
  ContributorType?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ObjectType?: InputMaybe<String_Comparison_Exp>;
  UserGroupId?: InputMaybe<Uuid_Comparison_Exp>;
  UserId?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Ancestor_Contributor_Bool_Exp>>;
  _not?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  _or?: InputMaybe<Array<Ancestor_Contributor_Bool_Exp>>;
  acceptance?: InputMaybe<Acceptance_Bool_Exp>;
  action?: InputMaybe<Action_Bool_Exp>;
  appetite?: InputMaybe<Appetite_Bool_Exp>;
  assessment?: InputMaybe<Assessment_Bool_Exp>;
  control?: InputMaybe<Control_Bool_Exp>;
  dashboard?: InputMaybe<Dashboard_Bool_Exp>;
  document?: InputMaybe<Document_Bool_Exp>;
  document_assessment_result?: InputMaybe<Document_Assessment_Result_Bool_Exp>;
  impact?: InputMaybe<Impact_Bool_Exp>;
  indicator?: InputMaybe<Indicator_Bool_Exp>;
  issue?: InputMaybe<Issue_Bool_Exp>;
  node?: InputMaybe<Node_Bool_Exp>;
  obligation?: InputMaybe<Obligation_Bool_Exp>;
  obligationAssessmentResult?: InputMaybe<Obligation_Assessment_Result_Bool_Exp>;
  risk?: InputMaybe<Risk_Bool_Exp>;
  risk_assessment_result?: InputMaybe<Risk_Assessment_Result_Bool_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_group?: InputMaybe<User_Group_Bool_Exp>;
};

/** order by max() on columns of table "risksmart.ancestor_contributor_view" */
export type Ancestor_Contributor_Max_Order_By = {
  AncestorId?: InputMaybe<Order_By>;
  ContributorType?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ObjectType?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.ancestor_contributor_view" */
export type Ancestor_Contributor_Min_Order_By = {
  AncestorId?: InputMaybe<Order_By>;
  ContributorType?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ObjectType?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "risksmart.ancestor_contributor_view". */
export type Ancestor_Contributor_Order_By = {
  AncestorId?: InputMaybe<Order_By>;
  ContributorType?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ObjectType?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
  acceptance?: InputMaybe<Acceptance_Order_By>;
  action?: InputMaybe<Action_Order_By>;
  appetite?: InputMaybe<Appetite_Order_By>;
  assessment?: InputMaybe<Assessment_Order_By>;
  control?: InputMaybe<Control_Order_By>;
  dashboard?: InputMaybe<Dashboard_Order_By>;
  document?: InputMaybe<Document_Order_By>;
  document_assessment_result?: InputMaybe<Document_Assessment_Result_Order_By>;
  impact?: InputMaybe<Impact_Order_By>;
  indicator?: InputMaybe<Indicator_Order_By>;
  issue?: InputMaybe<Issue_Order_By>;
  node?: InputMaybe<Node_Order_By>;
  obligation?: InputMaybe<Obligation_Order_By>;
  obligationAssessmentResult?: InputMaybe<Obligation_Assessment_Result_Order_By>;
  risk?: InputMaybe<Risk_Order_By>;
  risk_assessment_result?: InputMaybe<Risk_Assessment_Result_Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_group?: InputMaybe<User_Group_Order_By>;
};

/** select columns of table "risksmart.ancestor_contributor_view" */
export const Ancestor_Contributor_Select_Column = {
  /** column name */
  AncestorId: 'AncestorId',
  /** column name */
  ContributorType: 'ContributorType',
  /** column name */
  Id: 'Id',
  /** column name */
  ObjectType: 'ObjectType',
  /** column name */
  UserGroupId: 'UserGroupId',
  /** column name */
  UserId: 'UserId'
} as const;

export type Ancestor_Contributor_Select_Column = typeof Ancestor_Contributor_Select_Column[keyof typeof Ancestor_Contributor_Select_Column];
/** Streaming cursor of the table "ancestor_contributor" */
export type Ancestor_Contributor_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ancestor_Contributor_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ancestor_Contributor_Stream_Cursor_Value_Input = {
  AncestorId?: InputMaybe<Scalars['uuid']['input']>;
  ContributorType?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ObjectType?: InputMaybe<Scalars['String']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.appetite" */
export type Appetite = {
  __typename?: 'appetite';
  AppetiteType: Appetite_Type_Enum;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  EffectiveDate?: Maybe<Scalars['timestamptz']['output']>;
  Id: Scalars['uuid']['output'];
  ImpactAppetite?: Maybe<Scalars['Int']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Int']['output']>;
  LowerAppetite?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Statement?: Maybe<Scalars['String']['output']>;
  UpperAppetite?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  files: Array<Relation_File>;
  /** An aggregate relationship */
  files_aggregate: Relation_File_Aggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An array relationship */
  parents: Array<Appetite_Parent>;
  /** An aggregate relationship */
  parents_aggregate: Appetite_Parent_Aggregate;
};


/** columns and relationships of "risksmart.appetite" */
export type AppetiteCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.appetite" */
export type AppetiteAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.appetite" */
export type AppetiteFilesArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.appetite" */
export type AppetiteFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.appetite" */
export type AppetiteParentsArgs = {
  distinct_on?: InputMaybe<Array<Appetite_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Appetite_Parent_Order_By>>;
  where?: InputMaybe<Appetite_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.appetite" */
export type AppetiteParents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appetite_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Appetite_Parent_Order_By>>;
  where?: InputMaybe<Appetite_Parent_Bool_Exp>;
};

/** aggregated selection of "risksmart.appetite" */
export type Appetite_Aggregate = {
  __typename?: 'appetite_aggregate';
  aggregate?: Maybe<Appetite_Aggregate_Fields>;
  nodes: Array<Appetite>;
};

/** aggregate fields of "risksmart.appetite" */
export type Appetite_Aggregate_Fields = {
  __typename?: 'appetite_aggregate_fields';
  avg?: Maybe<Appetite_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Appetite_Max_Fields>;
  min?: Maybe<Appetite_Min_Fields>;
  stddev?: Maybe<Appetite_Stddev_Fields>;
  stddev_pop?: Maybe<Appetite_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Appetite_Stddev_Samp_Fields>;
  sum?: Maybe<Appetite_Sum_Fields>;
  var_pop?: Maybe<Appetite_Var_Pop_Fields>;
  var_samp?: Maybe<Appetite_Var_Samp_Fields>;
  variance?: Maybe<Appetite_Variance_Fields>;
};


/** aggregate fields of "risksmart.appetite" */
export type Appetite_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Appetite_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Appetite_Append_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.appetite_audit" */
export type Appetite_Audit = {
  __typename?: 'appetite_audit';
  AppetiteType?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  EffectiveDate?: Maybe<Scalars['timestamptz']['output']>;
  Id: Scalars['uuid']['output'];
  ImpactAppetite?: Maybe<Scalars['Int']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Int']['output']>;
  LowerAppetite?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Statement: Scalars['String']['output'];
  UpperAppetite?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "risksmart.appetite_audit" */
export type Appetite_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.appetite_audit". All fields are combined with a logical 'AND'. */
export type Appetite_Audit_Bool_Exp = {
  AppetiteType?: InputMaybe<String_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  EffectiveDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ImpactAppetite?: InputMaybe<Int_Comparison_Exp>;
  LikelihoodAppetite?: InputMaybe<Int_Comparison_Exp>;
  LowerAppetite?: InputMaybe<Int_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Statement?: InputMaybe<String_Comparison_Exp>;
  UpperAppetite?: InputMaybe<Int_Comparison_Exp>;
  _and?: InputMaybe<Array<Appetite_Audit_Bool_Exp>>;
  _not?: InputMaybe<Appetite_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Appetite_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.appetite_audit". */
export type Appetite_Audit_Order_By = {
  AppetiteType?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  EffectiveDate?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ImpactAppetite?: InputMaybe<Order_By>;
  LikelihoodAppetite?: InputMaybe<Order_By>;
  LowerAppetite?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Statement?: InputMaybe<Order_By>;
  UpperAppetite?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.appetite_audit" */
export const Appetite_Audit_Select_Column = {
  /** column name */
  AppetiteType: 'AppetiteType',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  EffectiveDate: 'EffectiveDate',
  /** column name */
  Id: 'Id',
  /** column name */
  ImpactAppetite: 'ImpactAppetite',
  /** column name */
  LikelihoodAppetite: 'LikelihoodAppetite',
  /** column name */
  LowerAppetite: 'LowerAppetite',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Statement: 'Statement',
  /** column name */
  UpperAppetite: 'UpperAppetite'
} as const;

export type Appetite_Audit_Select_Column = typeof Appetite_Audit_Select_Column[keyof typeof Appetite_Audit_Select_Column];
/** Streaming cursor of the table "appetite_audit" */
export type Appetite_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Appetite_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Appetite_Audit_Stream_Cursor_Value_Input = {
  AppetiteType?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  EffectiveDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactAppetite?: InputMaybe<Scalars['Int']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['Int']['input']>;
  LowerAppetite?: InputMaybe<Scalars['Int']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Statement?: InputMaybe<Scalars['String']['input']>;
  UpperAppetite?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate avg on columns */
export type Appetite_Avg_Fields = {
  __typename?: 'appetite_avg_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  LowerAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetite?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.appetite". All fields are combined with a logical 'AND'. */
export type Appetite_Bool_Exp = {
  AppetiteType?: InputMaybe<Appetite_Type_Enum_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  EffectiveDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ImpactAppetite?: InputMaybe<Int_Comparison_Exp>;
  LikelihoodAppetite?: InputMaybe<Int_Comparison_Exp>;
  LowerAppetite?: InputMaybe<Int_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Statement?: InputMaybe<String_Comparison_Exp>;
  UpperAppetite?: InputMaybe<Int_Comparison_Exp>;
  _and?: InputMaybe<Array<Appetite_Bool_Exp>>;
  _not?: InputMaybe<Appetite_Bool_Exp>;
  _or?: InputMaybe<Array<Appetite_Bool_Exp>>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  files?: InputMaybe<Relation_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parents?: InputMaybe<Appetite_Parent_Bool_Exp>;
  parents_aggregate?: InputMaybe<Appetite_Parent_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.appetite" */
export const Appetite_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  AppetitePkey: 'appetite_pkey',
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxAppetiteOrgkeySequentialid: 'idx_appetite_orgkey_sequentialid'
} as const;

export type Appetite_Constraint = typeof Appetite_Constraint[keyof typeof Appetite_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Appetite_Delete_At_Path_Input = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Appetite_Delete_Elem_Input = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Appetite_Delete_Key_Input = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.appetite" */
export type Appetite_Inc_Input = {
  ImpactAppetite?: InputMaybe<Scalars['Int']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['Int']['input']>;
  LowerAppetite?: InputMaybe<Scalars['Int']['input']>;
  UpperAppetite?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.appetite" */
export type Appetite_Insert_Input = {
  AppetiteType?: InputMaybe<Appetite_Type_Enum>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  EffectiveDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactAppetite?: InputMaybe<Scalars['Int']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['Int']['input']>;
  LowerAppetite?: InputMaybe<Scalars['Int']['input']>;
  Statement?: InputMaybe<Scalars['String']['input']>;
  UpperAppetite?: InputMaybe<Scalars['Int']['input']>;
  files?: InputMaybe<Relation_File_Arr_Rel_Insert_Input>;
  parents?: InputMaybe<Appetite_Parent_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Appetite_Max_Fields = {
  __typename?: 'appetite_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  EffectiveDate?: Maybe<Scalars['timestamptz']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImpactAppetite?: Maybe<Scalars['Int']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Int']['output']>;
  LowerAppetite?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Statement?: Maybe<Scalars['String']['output']>;
  UpperAppetite?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Appetite_Min_Fields = {
  __typename?: 'appetite_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  EffectiveDate?: Maybe<Scalars['timestamptz']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImpactAppetite?: Maybe<Scalars['Int']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Int']['output']>;
  LowerAppetite?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Statement?: Maybe<Scalars['String']['output']>;
  UpperAppetite?: Maybe<Scalars['Int']['output']>;
};

export const Appetite_Model_Enum = {
  /** Default model */
  Default: 'default',
  /** Top down cascade */
  TopDownCascade: 'top_down_cascade'
} as const;

export type Appetite_Model_Enum = typeof Appetite_Model_Enum[keyof typeof Appetite_Model_Enum];
/** Boolean expression to compare columns of type "appetite_model_enum". All fields are combined with logical 'AND'. */
export type Appetite_Model_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Appetite_Model_Enum>;
  _in?: InputMaybe<Array<Appetite_Model_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Appetite_Model_Enum>;
  _nin?: InputMaybe<Array<Appetite_Model_Enum>>;
};

/** response of any mutation on the table "risksmart.appetite" */
export type Appetite_Mutation_Response = {
  __typename?: 'appetite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Appetite>;
};

/** input type for inserting object relation for remote table "risksmart.appetite" */
export type Appetite_Obj_Rel_Insert_Input = {
  data: Appetite_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Appetite_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.appetite" */
export type Appetite_On_Conflict = {
  constraint: Appetite_Constraint;
  update_columns?: Array<Appetite_Update_Column>;
  where?: InputMaybe<Appetite_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.appetite". */
export type Appetite_Order_By = {
  AppetiteType?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  EffectiveDate?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ImpactAppetite?: InputMaybe<Order_By>;
  LikelihoodAppetite?: InputMaybe<Order_By>;
  LowerAppetite?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Statement?: InputMaybe<Order_By>;
  UpperAppetite?: InputMaybe<Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parents_aggregate?: InputMaybe<Appetite_Parent_Aggregate_Order_By>;
};

/** columns and relationships of "risksmart.appetite_parent" */
export type Appetite_Parent = {
  __typename?: 'appetite_parent';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  /** An object relationship */
  appetite?: Maybe<Appetite>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  impact?: Maybe<Impact>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parent?: Maybe<Node>;
  /** An object relationship */
  risk?: Maybe<Risk>;
};

/** aggregated selection of "risksmart.appetite_parent" */
export type Appetite_Parent_Aggregate = {
  __typename?: 'appetite_parent_aggregate';
  aggregate?: Maybe<Appetite_Parent_Aggregate_Fields>;
  nodes: Array<Appetite_Parent>;
};

export type Appetite_Parent_Aggregate_Bool_Exp = {
  count?: InputMaybe<Appetite_Parent_Aggregate_Bool_Exp_Count>;
};

export type Appetite_Parent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Appetite_Parent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Appetite_Parent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.appetite_parent" */
export type Appetite_Parent_Aggregate_Fields = {
  __typename?: 'appetite_parent_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Appetite_Parent_Max_Fields>;
  min?: Maybe<Appetite_Parent_Min_Fields>;
};


/** aggregate fields of "risksmart.appetite_parent" */
export type Appetite_Parent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Appetite_Parent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.appetite_parent" */
export type Appetite_Parent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Appetite_Parent_Max_Order_By>;
  min?: InputMaybe<Appetite_Parent_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.appetite_parent" */
export type Appetite_Parent_Arr_Rel_Insert_Input = {
  data: Array<Appetite_Parent_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Appetite_Parent_On_Conflict>;
};

/** columns and relationships of "risksmart.appetite_parent_audit" */
export type Appetite_Parent_Audit = {
  __typename?: 'appetite_parent_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId: Scalars['uuid']['output'];
};

/** aggregated selection of "risksmart.appetite_parent_audit" */
export type Appetite_Parent_Audit_Aggregate = {
  __typename?: 'appetite_parent_audit_aggregate';
  aggregate?: Maybe<Appetite_Parent_Audit_Aggregate_Fields>;
  nodes: Array<Appetite_Parent_Audit>;
};

export type Appetite_Parent_Audit_Aggregate_Bool_Exp = {
  count?: InputMaybe<Appetite_Parent_Audit_Aggregate_Bool_Exp_Count>;
};

export type Appetite_Parent_Audit_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Appetite_Parent_Audit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Appetite_Parent_Audit_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.appetite_parent_audit" */
export type Appetite_Parent_Audit_Aggregate_Fields = {
  __typename?: 'appetite_parent_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Appetite_Parent_Audit_Max_Fields>;
  min?: Maybe<Appetite_Parent_Audit_Min_Fields>;
};


/** aggregate fields of "risksmart.appetite_parent_audit" */
export type Appetite_Parent_Audit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Appetite_Parent_Audit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.appetite_parent_audit" */
export type Appetite_Parent_Audit_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Appetite_Parent_Audit_Max_Order_By>;
  min?: InputMaybe<Appetite_Parent_Audit_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.appetite_parent_audit". All fields are combined with a logical 'AND'. */
export type Appetite_Parent_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Appetite_Parent_Audit_Bool_Exp>>;
  _not?: InputMaybe<Appetite_Parent_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Appetite_Parent_Audit_Bool_Exp>>;
};

/** aggregate max on columns */
export type Appetite_Parent_Audit_Max_Fields = {
  __typename?: 'appetite_parent_audit_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.appetite_parent_audit" */
export type Appetite_Parent_Audit_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Appetite_Parent_Audit_Min_Fields = {
  __typename?: 'appetite_parent_audit_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.appetite_parent_audit" */
export type Appetite_Parent_Audit_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "risksmart.appetite_parent_audit". */
export type Appetite_Parent_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.appetite_parent_audit" */
export const Appetite_Parent_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId'
} as const;

export type Appetite_Parent_Audit_Select_Column = typeof Appetite_Parent_Audit_Select_Column[keyof typeof Appetite_Parent_Audit_Select_Column];
/** Streaming cursor of the table "appetite_parent_audit" */
export type Appetite_Parent_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Appetite_Parent_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Appetite_Parent_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.appetite_parent". All fields are combined with a logical 'AND'. */
export type Appetite_Parent_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Appetite_Parent_Bool_Exp>>;
  _not?: InputMaybe<Appetite_Parent_Bool_Exp>;
  _or?: InputMaybe<Array<Appetite_Parent_Bool_Exp>>;
  appetite?: InputMaybe<Appetite_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  impact?: InputMaybe<Impact_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parent?: InputMaybe<Node_Bool_Exp>;
  risk?: InputMaybe<Risk_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.appetite_parent" */
export const Appetite_Parent_Constraint = {
  /** unique or primary key constraint on columns "ParentId", "Id" */
  AppetiteParentPkey: 'appetite_parent_pkey'
} as const;

export type Appetite_Parent_Constraint = typeof Appetite_Parent_Constraint[keyof typeof Appetite_Parent_Constraint];
/** input type for inserting data into table "risksmart.appetite_parent" */
export type Appetite_Parent_Insert_Input = {
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  appetite?: InputMaybe<Appetite_Obj_Rel_Insert_Input>;
  impact?: InputMaybe<Impact_Obj_Rel_Insert_Input>;
  risk?: InputMaybe<Risk_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Appetite_Parent_Max_Fields = {
  __typename?: 'appetite_parent_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.appetite_parent" */
export type Appetite_Parent_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Appetite_Parent_Min_Fields = {
  __typename?: 'appetite_parent_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.appetite_parent" */
export type Appetite_Parent_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.appetite_parent" */
export type Appetite_Parent_Mutation_Response = {
  __typename?: 'appetite_parent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Appetite_Parent>;
};

/** on_conflict condition type for table "risksmart.appetite_parent" */
export type Appetite_Parent_On_Conflict = {
  constraint: Appetite_Parent_Constraint;
  update_columns?: Array<Appetite_Parent_Update_Column>;
  where?: InputMaybe<Appetite_Parent_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.appetite_parent". */
export type Appetite_Parent_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  appetite?: InputMaybe<Appetite_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  impact?: InputMaybe<Impact_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parent?: InputMaybe<Node_Order_By>;
  risk?: InputMaybe<Risk_Order_By>;
};

/** select columns of table "risksmart.appetite_parent" */
export const Appetite_Parent_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId'
} as const;

export type Appetite_Parent_Select_Column = typeof Appetite_Parent_Select_Column[keyof typeof Appetite_Parent_Select_Column];
/** Streaming cursor of the table "appetite_parent" */
export type Appetite_Parent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Appetite_Parent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Appetite_Parent_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** placeholder for update columns of table "risksmart.appetite_parent" (current role has no relevant permissions) */
export const Appetite_Parent_Update_Column = {
  /** placeholder (do not use) */
  Placeholder: '_PLACEHOLDER'
} as const;

export type Appetite_Parent_Update_Column = typeof Appetite_Parent_Update_Column[keyof typeof Appetite_Parent_Update_Column];
/** primary key columns input for table: risksmart.appetite */
export type Appetite_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Appetite_Prepend_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.appetite" */
export const Appetite_Select_Column = {
  /** column name */
  AppetiteType: 'AppetiteType',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  EffectiveDate: 'EffectiveDate',
  /** column name */
  Id: 'Id',
  /** column name */
  ImpactAppetite: 'ImpactAppetite',
  /** column name */
  LikelihoodAppetite: 'LikelihoodAppetite',
  /** column name */
  LowerAppetite: 'LowerAppetite',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Statement: 'Statement',
  /** column name */
  UpperAppetite: 'UpperAppetite'
} as const;

export type Appetite_Select_Column = typeof Appetite_Select_Column[keyof typeof Appetite_Select_Column];
/** input type for updating data in table "risksmart.appetite" */
export type Appetite_Set_Input = {
  AppetiteType?: InputMaybe<Appetite_Type_Enum>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  EffectiveDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ImpactAppetite?: InputMaybe<Scalars['Int']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['Int']['input']>;
  LowerAppetite?: InputMaybe<Scalars['Int']['input']>;
  Statement?: InputMaybe<Scalars['String']['input']>;
  UpperAppetite?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Appetite_Stddev_Fields = {
  __typename?: 'appetite_stddev_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  LowerAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetite?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Appetite_Stddev_Pop_Fields = {
  __typename?: 'appetite_stddev_pop_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  LowerAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetite?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Appetite_Stddev_Samp_Fields = {
  __typename?: 'appetite_stddev_samp_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  LowerAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetite?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "appetite" */
export type Appetite_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Appetite_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Appetite_Stream_Cursor_Value_Input = {
  AppetiteType?: InputMaybe<Appetite_Type_Enum>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  EffectiveDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactAppetite?: InputMaybe<Scalars['Int']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['Int']['input']>;
  LowerAppetite?: InputMaybe<Scalars['Int']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Statement?: InputMaybe<Scalars['String']['input']>;
  UpperAppetite?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Appetite_Sum_Fields = {
  __typename?: 'appetite_sum_fields';
  ImpactAppetite?: Maybe<Scalars['Int']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Int']['output']>;
  LowerAppetite?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  UpperAppetite?: Maybe<Scalars['Int']['output']>;
};

export const Appetite_Type_Enum = {
  /** Impact */
  Impact: 'impact',
  /** Likelihood */
  Likelihood: 'likelihood',
  /** Risk */
  Risk: 'risk'
} as const;

export type Appetite_Type_Enum = typeof Appetite_Type_Enum[keyof typeof Appetite_Type_Enum];
/** Boolean expression to compare columns of type "appetite_type_enum". All fields are combined with logical 'AND'. */
export type Appetite_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Appetite_Type_Enum>;
  _in?: InputMaybe<Array<Appetite_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Appetite_Type_Enum>;
  _nin?: InputMaybe<Array<Appetite_Type_Enum>>;
};

/** update columns of table "risksmart.appetite" */
export const Appetite_Update_Column = {
  /** column name */
  AppetiteType: 'AppetiteType',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  EffectiveDate: 'EffectiveDate',
  /** column name */
  ImpactAppetite: 'ImpactAppetite',
  /** column name */
  LikelihoodAppetite: 'LikelihoodAppetite',
  /** column name */
  LowerAppetite: 'LowerAppetite',
  /** column name */
  Statement: 'Statement',
  /** column name */
  UpperAppetite: 'UpperAppetite'
} as const;

export type Appetite_Update_Column = typeof Appetite_Update_Column[keyof typeof Appetite_Update_Column];
export type Appetite_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Appetite_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Appetite_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Appetite_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Appetite_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Appetite_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Appetite_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Appetite_Set_Input>;
  /** filter the rows which have to be updated */
  where: Appetite_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Appetite_Var_Pop_Fields = {
  __typename?: 'appetite_var_pop_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  LowerAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetite?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Appetite_Var_Samp_Fields = {
  __typename?: 'appetite_var_samp_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  LowerAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetite?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Appetite_Variance_Fields = {
  __typename?: 'appetite_variance_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  LowerAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetite?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.approval" */
export type Approval = {
  __typename?: 'approval';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  InFlightEditRule: Approval_In_Flight_Edit_Rule_Enum;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId?: Maybe<Scalars['uuid']['output']>;
  Workflow: Scalars['String']['output'];
  /** An object relationship */
  createdBy?: Maybe<User>;
  /** An array relationship */
  levels: Array<Approval_Level>;
  /** An object relationship */
  modifiedBy?: Maybe<User>;
  /** An object relationship */
  org?: Maybe<Auth_Organisation>;
  /** An object relationship */
  parent?: Maybe<Node>;
};


/** columns and relationships of "risksmart.approval" */
export type ApprovalLevelsArgs = {
  distinct_on?: InputMaybe<Array<Approval_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Approval_Level_Order_By>>;
  where?: InputMaybe<Approval_Level_Bool_Exp>;
};

/** order by aggregate values of table "risksmart.approval" */
export type Approval_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Approval_Max_Order_By>;
  min?: InputMaybe<Approval_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.approval" */
export type Approval_Arr_Rel_Insert_Input = {
  data: Array<Approval_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Approval_On_Conflict>;
};

/** columns and relationships of "risksmart.approval_audit" */
export type Approval_Audit = {
  __typename?: 'approval_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  InFlightEditRule: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId?: Maybe<Scalars['uuid']['output']>;
  Workflow: Scalars['String']['output'];
};

/** Boolean expression to filter rows from the table "risksmart.approval_audit". All fields are combined with a logical 'AND'. */
export type Approval_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  InFlightEditRule?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  Workflow?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Approval_Audit_Bool_Exp>>;
  _not?: InputMaybe<Approval_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Approval_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.approval_audit". */
export type Approval_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  InFlightEditRule?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  Workflow?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.approval_audit" */
export const Approval_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  InFlightEditRule: 'InFlightEditRule',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  Workflow: 'Workflow'
} as const;

export type Approval_Audit_Select_Column = typeof Approval_Audit_Select_Column[keyof typeof Approval_Audit_Select_Column];
/** Streaming cursor of the table "approval_audit" */
export type Approval_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Approval_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Approval_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  InFlightEditRule?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Workflow?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.approval". All fields are combined with a logical 'AND'. */
export type Approval_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  InFlightEditRule?: InputMaybe<Approval_In_Flight_Edit_Rule_Enum_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  Workflow?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Approval_Bool_Exp>>;
  _not?: InputMaybe<Approval_Bool_Exp>;
  _or?: InputMaybe<Array<Approval_Bool_Exp>>;
  createdBy?: InputMaybe<User_Bool_Exp>;
  levels?: InputMaybe<Approval_Level_Bool_Exp>;
  modifiedBy?: InputMaybe<User_Bool_Exp>;
  org?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parent?: InputMaybe<Node_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.approval" */
export const Approval_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  ApprovalPkey: 'approval_pkey',
  /** unique or primary key constraint on columns "Workflow", "ParentId" */
  IdxApprovalParentParentType: 'idx_approval_parent_parent_type'
} as const;

export type Approval_Constraint = typeof Approval_Constraint[keyof typeof Approval_Constraint];
export const Approval_In_Flight_Edit_Rule_Enum = {
  /** Only approvers can edit */
  Approvers: 'approvers',
  /** Everyone who has access can edit */
  Everyone: 'everyone',
  /** No one can edit */
  Noone: 'noone'
} as const;

export type Approval_In_Flight_Edit_Rule_Enum = typeof Approval_In_Flight_Edit_Rule_Enum[keyof typeof Approval_In_Flight_Edit_Rule_Enum];
/** Boolean expression to compare columns of type "approval_in_flight_edit_rule_enum". All fields are combined with logical 'AND'. */
export type Approval_In_Flight_Edit_Rule_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Approval_In_Flight_Edit_Rule_Enum>;
  _in?: InputMaybe<Array<Approval_In_Flight_Edit_Rule_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Approval_In_Flight_Edit_Rule_Enum>;
  _nin?: InputMaybe<Array<Approval_In_Flight_Edit_Rule_Enum>>;
};

/** input type for inserting data into table "risksmart.approval" */
export type Approval_Insert_Input = {
  InFlightEditRule?: InputMaybe<Approval_In_Flight_Edit_Rule_Enum>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Workflow?: InputMaybe<Scalars['String']['input']>;
  levels?: InputMaybe<Approval_Level_Arr_Rel_Insert_Input>;
};

/** columns and relationships of "risksmart.approval_level" */
export type Approval_Level = {
  __typename?: 'approval_level';
  ApprovalId: Scalars['uuid']['output'];
  ApprovalRuleType: Approval_Rule_Type_Enum;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  SequenceOrder: Scalars['Int']['output'];
  /** An object relationship */
  approval?: Maybe<Approval>;
  /** An array relationship */
  approvers: Array<Approver>;
  /** An aggregate relationship */
  approvers_aggregate: Approver_Aggregate;
};


/** columns and relationships of "risksmart.approval_level" */
export type Approval_LevelApproversArgs = {
  distinct_on?: InputMaybe<Array<Approver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Approver_Order_By>>;
  where?: InputMaybe<Approver_Bool_Exp>;
};


/** columns and relationships of "risksmart.approval_level" */
export type Approval_LevelApprovers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Approver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Approver_Order_By>>;
  where?: InputMaybe<Approver_Bool_Exp>;
};

/** order by aggregate values of table "risksmart.approval_level" */
export type Approval_Level_Aggregate_Order_By = {
  avg?: InputMaybe<Approval_Level_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Approval_Level_Max_Order_By>;
  min?: InputMaybe<Approval_Level_Min_Order_By>;
  stddev?: InputMaybe<Approval_Level_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Approval_Level_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Approval_Level_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Approval_Level_Sum_Order_By>;
  var_pop?: InputMaybe<Approval_Level_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Approval_Level_Var_Samp_Order_By>;
  variance?: InputMaybe<Approval_Level_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.approval_level" */
export type Approval_Level_Arr_Rel_Insert_Input = {
  data: Array<Approval_Level_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Approval_Level_On_Conflict>;
};

/** columns and relationships of "risksmart.approval_level_audit" */
export type Approval_Level_Audit = {
  __typename?: 'approval_level_audit';
  ApprovalId: Scalars['uuid']['output'];
  ApprovalRuleType: Scalars['String']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  SequenceOrder: Scalars['Int']['output'];
};

/** Boolean expression to filter rows from the table "risksmart.approval_level_audit". All fields are combined with a logical 'AND'. */
export type Approval_Level_Audit_Bool_Exp = {
  ApprovalId?: InputMaybe<Uuid_Comparison_Exp>;
  ApprovalRuleType?: InputMaybe<String_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  SequenceOrder?: InputMaybe<Int_Comparison_Exp>;
  _and?: InputMaybe<Array<Approval_Level_Audit_Bool_Exp>>;
  _not?: InputMaybe<Approval_Level_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Approval_Level_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.approval_level_audit". */
export type Approval_Level_Audit_Order_By = {
  ApprovalId?: InputMaybe<Order_By>;
  ApprovalRuleType?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  SequenceOrder?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.approval_level_audit" */
export const Approval_Level_Audit_Select_Column = {
  /** column name */
  ApprovalId: 'ApprovalId',
  /** column name */
  ApprovalRuleType: 'ApprovalRuleType',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  SequenceOrder: 'SequenceOrder'
} as const;

export type Approval_Level_Audit_Select_Column = typeof Approval_Level_Audit_Select_Column[keyof typeof Approval_Level_Audit_Select_Column];
/** Streaming cursor of the table "approval_level_audit" */
export type Approval_Level_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Approval_Level_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Approval_Level_Audit_Stream_Cursor_Value_Input = {
  ApprovalId?: InputMaybe<Scalars['uuid']['input']>;
  ApprovalRuleType?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  SequenceOrder?: InputMaybe<Scalars['Int']['input']>;
};

/** order by avg() on columns of table "risksmart.approval_level" */
export type Approval_Level_Avg_Order_By = {
  SequenceOrder?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.approval_level". All fields are combined with a logical 'AND'. */
export type Approval_Level_Bool_Exp = {
  ApprovalId?: InputMaybe<Uuid_Comparison_Exp>;
  ApprovalRuleType?: InputMaybe<Approval_Rule_Type_Enum_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  SequenceOrder?: InputMaybe<Int_Comparison_Exp>;
  _and?: InputMaybe<Array<Approval_Level_Bool_Exp>>;
  _not?: InputMaybe<Approval_Level_Bool_Exp>;
  _or?: InputMaybe<Array<Approval_Level_Bool_Exp>>;
  approval?: InputMaybe<Approval_Bool_Exp>;
  approvers?: InputMaybe<Approver_Bool_Exp>;
  approvers_aggregate?: InputMaybe<Approver_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.approval_level" */
export const Approval_Level_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  ApprovalLevelPkey: 'approval_level_pkey',
  /** unique or primary key constraint on columns "SequenceOrder", "ApprovalId" */
  IdxApprovalLevelSeqApprovalId: 'idx_approval_level_seq_approval_id'
} as const;

export type Approval_Level_Constraint = typeof Approval_Level_Constraint[keyof typeof Approval_Level_Constraint];
/** input type for incrementing numeric columns in table "risksmart.approval_level" */
export type Approval_Level_Inc_Input = {
  SequenceOrder?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.approval_level" */
export type Approval_Level_Insert_Input = {
  ApprovalId?: InputMaybe<Scalars['uuid']['input']>;
  ApprovalRuleType?: InputMaybe<Approval_Rule_Type_Enum>;
  Description?: InputMaybe<Scalars['String']['input']>;
  SequenceOrder?: InputMaybe<Scalars['Int']['input']>;
  approval?: InputMaybe<Approval_Obj_Rel_Insert_Input>;
  approvers?: InputMaybe<Approver_Arr_Rel_Insert_Input>;
};

/** order by max() on columns of table "risksmart.approval_level" */
export type Approval_Level_Max_Order_By = {
  ApprovalId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  SequenceOrder?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.approval_level" */
export type Approval_Level_Min_Order_By = {
  ApprovalId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  SequenceOrder?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.approval_level" */
export type Approval_Level_Mutation_Response = {
  __typename?: 'approval_level_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Approval_Level>;
};

/** input type for inserting object relation for remote table "risksmart.approval_level" */
export type Approval_Level_Obj_Rel_Insert_Input = {
  data: Approval_Level_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Approval_Level_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.approval_level" */
export type Approval_Level_On_Conflict = {
  constraint: Approval_Level_Constraint;
  update_columns?: Array<Approval_Level_Update_Column>;
  where?: InputMaybe<Approval_Level_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.approval_level". */
export type Approval_Level_Order_By = {
  ApprovalId?: InputMaybe<Order_By>;
  ApprovalRuleType?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  SequenceOrder?: InputMaybe<Order_By>;
  approval?: InputMaybe<Approval_Order_By>;
  approvers_aggregate?: InputMaybe<Approver_Aggregate_Order_By>;
};

/** primary key columns input for table: risksmart.approval_level */
export type Approval_Level_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.approval_level" */
export const Approval_Level_Select_Column = {
  /** column name */
  ApprovalId: 'ApprovalId',
  /** column name */
  ApprovalRuleType: 'ApprovalRuleType',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  SequenceOrder: 'SequenceOrder'
} as const;

export type Approval_Level_Select_Column = typeof Approval_Level_Select_Column[keyof typeof Approval_Level_Select_Column];
/** input type for updating data in table "risksmart.approval_level" */
export type Approval_Level_Set_Input = {
  ApprovalId?: InputMaybe<Scalars['uuid']['input']>;
  ApprovalRuleType?: InputMaybe<Approval_Rule_Type_Enum>;
  Description?: InputMaybe<Scalars['String']['input']>;
  SequenceOrder?: InputMaybe<Scalars['Int']['input']>;
};

/** order by stddev() on columns of table "risksmart.approval_level" */
export type Approval_Level_Stddev_Order_By = {
  SequenceOrder?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "risksmart.approval_level" */
export type Approval_Level_Stddev_Pop_Order_By = {
  SequenceOrder?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "risksmart.approval_level" */
export type Approval_Level_Stddev_Samp_Order_By = {
  SequenceOrder?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "approval_level" */
export type Approval_Level_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Approval_Level_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Approval_Level_Stream_Cursor_Value_Input = {
  ApprovalId?: InputMaybe<Scalars['uuid']['input']>;
  ApprovalRuleType?: InputMaybe<Approval_Rule_Type_Enum>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  SequenceOrder?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "risksmart.approval_level" */
export type Approval_Level_Sum_Order_By = {
  SequenceOrder?: InputMaybe<Order_By>;
};

/** update columns of table "risksmart.approval_level" */
export const Approval_Level_Update_Column = {
  /** column name */
  ApprovalId: 'ApprovalId',
  /** column name */
  ApprovalRuleType: 'ApprovalRuleType',
  /** column name */
  Description: 'Description',
  /** column name */
  SequenceOrder: 'SequenceOrder'
} as const;

export type Approval_Level_Update_Column = typeof Approval_Level_Update_Column[keyof typeof Approval_Level_Update_Column];
export type Approval_Level_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Approval_Level_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Approval_Level_Set_Input>;
  /** filter the rows which have to be updated */
  where: Approval_Level_Bool_Exp;
};

/** order by var_pop() on columns of table "risksmart.approval_level" */
export type Approval_Level_Var_Pop_Order_By = {
  SequenceOrder?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "risksmart.approval_level" */
export type Approval_Level_Var_Samp_Order_By = {
  SequenceOrder?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "risksmart.approval_level" */
export type Approval_Level_Variance_Order_By = {
  SequenceOrder?: InputMaybe<Order_By>;
};

/** order by max() on columns of table "risksmart.approval" */
export type Approval_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  Workflow?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.approval" */
export type Approval_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  Workflow?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.approval" */
export type Approval_Mutation_Response = {
  __typename?: 'approval_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Approval>;
};

/** input type for inserting object relation for remote table "risksmart.approval" */
export type Approval_Obj_Rel_Insert_Input = {
  data: Approval_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Approval_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.approval" */
export type Approval_On_Conflict = {
  constraint: Approval_Constraint;
  update_columns?: Array<Approval_Update_Column>;
  where?: InputMaybe<Approval_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.approval". */
export type Approval_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  InFlightEditRule?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  Workflow?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<User_Order_By>;
  levels_aggregate?: InputMaybe<Approval_Level_Aggregate_Order_By>;
  modifiedBy?: InputMaybe<User_Order_By>;
  org?: InputMaybe<Auth_Organisation_Order_By>;
  parent?: InputMaybe<Node_Order_By>;
};

/** primary key columns input for table: risksmart.approval */
export type Approval_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

export const Approval_Rule_Type_Enum = {
  /** All approve */
  AllApprove: 'all_approve',
  /** Any one approval */
  AnyOneApprove: 'any_one_approve',
  /** Majority approves */
  MajorityApprove: 'majority_approve'
} as const;

export type Approval_Rule_Type_Enum = typeof Approval_Rule_Type_Enum[keyof typeof Approval_Rule_Type_Enum];
/** Boolean expression to compare columns of type "approval_rule_type_enum". All fields are combined with logical 'AND'. */
export type Approval_Rule_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Approval_Rule_Type_Enum>;
  _in?: InputMaybe<Array<Approval_Rule_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Approval_Rule_Type_Enum>;
  _nin?: InputMaybe<Array<Approval_Rule_Type_Enum>>;
};

/** select columns of table "risksmart.approval" */
export const Approval_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  InFlightEditRule: 'InFlightEditRule',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  Workflow: 'Workflow'
} as const;

export type Approval_Select_Column = typeof Approval_Select_Column[keyof typeof Approval_Select_Column];
/** input type for updating data in table "risksmart.approval" */
export type Approval_Set_Input = {
  InFlightEditRule?: InputMaybe<Approval_In_Flight_Edit_Rule_Enum>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Workflow?: InputMaybe<Scalars['String']['input']>;
};

export const Approval_Status_Enum = {
  /** Approved */
  Approved: 'approved',
  /** The parent node has been deleted */
  Deleted: 'deleted',
  /** failed */
  Failed: 'failed',
  /** Pending */
  Pending: 'pending',
  /** Rejected */
  Rejected: 'rejected'
} as const;

export type Approval_Status_Enum = typeof Approval_Status_Enum[keyof typeof Approval_Status_Enum];
/** Boolean expression to compare columns of type "approval_status_enum". All fields are combined with logical 'AND'. */
export type Approval_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Approval_Status_Enum>;
  _in?: InputMaybe<Array<Approval_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Approval_Status_Enum>;
  _nin?: InputMaybe<Array<Approval_Status_Enum>>;
};

/** Streaming cursor of the table "approval" */
export type Approval_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Approval_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Approval_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  InFlightEditRule?: InputMaybe<Approval_In_Flight_Edit_Rule_Enum>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Workflow?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.approval" */
export const Approval_Update_Column = {
  /** column name */
  InFlightEditRule: 'InFlightEditRule',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  Workflow: 'Workflow'
} as const;

export type Approval_Update_Column = typeof Approval_Update_Column[keyof typeof Approval_Update_Column];
export type Approval_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Approval_Set_Input>;
  /** filter the rows which have to be updated */
  where: Approval_Bool_Exp;
};

/** columns and relationships of "risksmart.approver" */
export type Approver = {
  __typename?: 'approver';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  LevelId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OwnerApprover?: Maybe<Scalars['Boolean']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  createdBy?: Maybe<User>;
  /** An object relationship */
  group?: Maybe<User_Group>;
  /** An object relationship */
  level?: Maybe<Approval_Level>;
  /** An object relationship */
  modifiedBy?: Maybe<User>;
  /** An object relationship */
  org?: Maybe<Auth_Organisation>;
  /** An array relationship */
  responses: Array<Approver_Response>;
  /** An object relationship */
  user?: Maybe<User>;
};


/** columns and relationships of "risksmart.approver" */
export type ApproverResponsesArgs = {
  distinct_on?: InputMaybe<Array<Approver_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Approver_Response_Order_By>>;
  where?: InputMaybe<Approver_Response_Bool_Exp>;
};

/** aggregated selection of "risksmart.approver" */
export type Approver_Aggregate = {
  __typename?: 'approver_aggregate';
  aggregate?: Maybe<Approver_Aggregate_Fields>;
  nodes: Array<Approver>;
};

export type Approver_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Approver_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Approver_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Approver_Aggregate_Bool_Exp_Count>;
};

export type Approver_Aggregate_Bool_Exp_Bool_And = {
  arguments: Approver_Select_Column_Approver_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Approver_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Approver_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Approver_Select_Column_Approver_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Approver_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Approver_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Approver_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Approver_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.approver" */
export type Approver_Aggregate_Fields = {
  __typename?: 'approver_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Approver_Max_Fields>;
  min?: Maybe<Approver_Min_Fields>;
};


/** aggregate fields of "risksmart.approver" */
export type Approver_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Approver_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.approver" */
export type Approver_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Approver_Max_Order_By>;
  min?: InputMaybe<Approver_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.approver" */
export type Approver_Arr_Rel_Insert_Input = {
  data: Array<Approver_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Approver_On_Conflict>;
};

/** columns and relationships of "risksmart.approver_audit" */
export type Approver_Audit = {
  __typename?: 'approver_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  LevelId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OwnerApprover?: Maybe<Scalars['Boolean']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.approver_audit". All fields are combined with a logical 'AND'. */
export type Approver_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  LevelId?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  OwnerApprover?: InputMaybe<Boolean_Comparison_Exp>;
  UserGroupId?: InputMaybe<Uuid_Comparison_Exp>;
  UserId?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Approver_Audit_Bool_Exp>>;
  _not?: InputMaybe<Approver_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Approver_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.approver_audit". */
export type Approver_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  LevelId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OwnerApprover?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.approver_audit" */
export const Approver_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  LevelId: 'LevelId',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  OwnerApprover: 'OwnerApprover',
  /** column name */
  UserGroupId: 'UserGroupId',
  /** column name */
  UserId: 'UserId'
} as const;

export type Approver_Audit_Select_Column = typeof Approver_Audit_Select_Column[keyof typeof Approver_Audit_Select_Column];
/** Streaming cursor of the table "approver_audit" */
export type Approver_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Approver_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Approver_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LevelId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OwnerApprover?: InputMaybe<Scalars['Boolean']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.approver". All fields are combined with a logical 'AND'. */
export type Approver_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  LevelId?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  OwnerApprover?: InputMaybe<Boolean_Comparison_Exp>;
  UserGroupId?: InputMaybe<Uuid_Comparison_Exp>;
  UserId?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Approver_Bool_Exp>>;
  _not?: InputMaybe<Approver_Bool_Exp>;
  _or?: InputMaybe<Array<Approver_Bool_Exp>>;
  createdBy?: InputMaybe<User_Bool_Exp>;
  group?: InputMaybe<User_Group_Bool_Exp>;
  level?: InputMaybe<Approval_Level_Bool_Exp>;
  modifiedBy?: InputMaybe<User_Bool_Exp>;
  org?: InputMaybe<Auth_Organisation_Bool_Exp>;
  responses?: InputMaybe<Approver_Response_Bool_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.approver" */
export const Approver_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  ApproverPkey: 'approver_pkey',
  /** unique or primary key constraint on columns "LevelId", "UserId" */
  IdxApproverUserLevel: 'idx_approver_user_level'
} as const;

export type Approver_Constraint = typeof Approver_Constraint[keyof typeof Approver_Constraint];
/** input type for inserting data into table "risksmart.approver" */
export type Approver_Insert_Input = {
  LevelId?: InputMaybe<Scalars['uuid']['input']>;
  OwnerApprover?: InputMaybe<Scalars['Boolean']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<User_Group_Obj_Rel_Insert_Input>;
  level?: InputMaybe<Approval_Level_Obj_Rel_Insert_Input>;
  responses?: InputMaybe<Approver_Response_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Approver_Max_Fields = {
  __typename?: 'approver_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LevelId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.approver" */
export type Approver_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  LevelId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Approver_Min_Fields = {
  __typename?: 'approver_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LevelId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.approver" */
export type Approver_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  LevelId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.approver" */
export type Approver_Mutation_Response = {
  __typename?: 'approver_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Approver>;
};

/** input type for inserting object relation for remote table "risksmart.approver" */
export type Approver_Obj_Rel_Insert_Input = {
  data: Approver_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Approver_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.approver" */
export type Approver_On_Conflict = {
  constraint: Approver_Constraint;
  update_columns?: Array<Approver_Update_Column>;
  where?: InputMaybe<Approver_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.approver". */
export type Approver_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  LevelId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OwnerApprover?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<User_Order_By>;
  group?: InputMaybe<User_Group_Order_By>;
  level?: InputMaybe<Approval_Level_Order_By>;
  modifiedBy?: InputMaybe<User_Order_By>;
  org?: InputMaybe<Auth_Organisation_Order_By>;
  responses_aggregate?: InputMaybe<Approver_Response_Aggregate_Order_By>;
  user?: InputMaybe<User_Order_By>;
};

/** primary key columns input for table: risksmart.approver */
export type Approver_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** columns and relationships of "risksmart.approver_response" */
export type Approver_Response = {
  __typename?: 'approver_response';
  Approved?: Maybe<Scalars['Boolean']['output']>;
  ApprovedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ApprovedByUser?: Maybe<Scalars['String']['output']>;
  Comment?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  /** An object relationship */
  approvedBy?: Maybe<User>;
  /** An object relationship */
  approver: Approver;
  /** An object relationship */
  changeRequest?: Maybe<Change_Request>;
};

/** order by aggregate values of table "risksmart.approver_response" */
export type Approver_Response_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Approver_Response_Max_Order_By>;
  min?: InputMaybe<Approver_Response_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.approver_response" */
export type Approver_Response_Arr_Rel_Insert_Input = {
  data: Array<Approver_Response_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Approver_Response_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risksmart.approver_response". All fields are combined with a logical 'AND'. */
export type Approver_Response_Bool_Exp = {
  Approved?: InputMaybe<Boolean_Comparison_Exp>;
  ApprovedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ApprovedByUser?: InputMaybe<String_Comparison_Exp>;
  Comment?: InputMaybe<String_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  _and?: InputMaybe<Array<Approver_Response_Bool_Exp>>;
  _not?: InputMaybe<Approver_Response_Bool_Exp>;
  _or?: InputMaybe<Array<Approver_Response_Bool_Exp>>;
  approvedBy?: InputMaybe<User_Bool_Exp>;
  approver?: InputMaybe<Approver_Bool_Exp>;
  changeRequest?: InputMaybe<Change_Request_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.approver_response" */
export const Approver_Response_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  ApproverResponsePkey: 'approver_response_pkey'
} as const;

export type Approver_Response_Constraint = typeof Approver_Response_Constraint[keyof typeof Approver_Response_Constraint];
/** input type for inserting data into table "risksmart.approver_response" */
export type Approver_Response_Insert_Input = {
  Approved?: InputMaybe<Scalars['Boolean']['input']>;
  ApproverId?: InputMaybe<Scalars['uuid']['input']>;
  ChangeRequestId?: InputMaybe<Scalars['uuid']['input']>;
  Comment?: InputMaybe<Scalars['String']['input']>;
  approver?: InputMaybe<Approver_Obj_Rel_Insert_Input>;
  changeRequest?: InputMaybe<Change_Request_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "risksmart.approver_response" */
export type Approver_Response_Max_Order_By = {
  ApprovedAtTimestamp?: InputMaybe<Order_By>;
  ApprovedByUser?: InputMaybe<Order_By>;
  Comment?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.approver_response" */
export type Approver_Response_Min_Order_By = {
  ApprovedAtTimestamp?: InputMaybe<Order_By>;
  ApprovedByUser?: InputMaybe<Order_By>;
  Comment?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "risksmart.approver_response" */
export type Approver_Response_On_Conflict = {
  constraint: Approver_Response_Constraint;
  update_columns?: Array<Approver_Response_Update_Column>;
  where?: InputMaybe<Approver_Response_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.approver_response". */
export type Approver_Response_Order_By = {
  Approved?: InputMaybe<Order_By>;
  ApprovedAtTimestamp?: InputMaybe<Order_By>;
  ApprovedByUser?: InputMaybe<Order_By>;
  Comment?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  approvedBy?: InputMaybe<User_Order_By>;
  approver?: InputMaybe<Approver_Order_By>;
  changeRequest?: InputMaybe<Change_Request_Order_By>;
};

/** select columns of table "risksmart.approver_response" */
export const Approver_Response_Select_Column = {
  /** column name */
  Approved: 'Approved',
  /** column name */
  ApprovedAtTimestamp: 'ApprovedAtTimestamp',
  /** column name */
  ApprovedByUser: 'ApprovedByUser',
  /** column name */
  Comment: 'Comment',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp'
} as const;

export type Approver_Response_Select_Column = typeof Approver_Response_Select_Column[keyof typeof Approver_Response_Select_Column];
/** update columns of table "risksmart.approver_response" */
export const Approver_Response_Update_Column = {
  /** column name */
  Approved: 'Approved',
  /** column name */
  ApprovedAtTimestamp: 'ApprovedAtTimestamp',
  /** column name */
  ApprovedByUser: 'ApprovedByUser',
  /** column name */
  Comment: 'Comment'
} as const;

export type Approver_Response_Update_Column = typeof Approver_Response_Update_Column[keyof typeof Approver_Response_Update_Column];
/** select columns of table "risksmart.approver" */
export const Approver_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  LevelId: 'LevelId',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  OwnerApprover: 'OwnerApprover',
  /** column name */
  UserGroupId: 'UserGroupId',
  /** column name */
  UserId: 'UserId'
} as const;

export type Approver_Select_Column = typeof Approver_Select_Column[keyof typeof Approver_Select_Column];
/** select "approver_aggregate_bool_exp_bool_and_arguments_columns" columns of table "risksmart.approver" */
export const Approver_Select_Column_Approver_Aggregate_Bool_Exp_Bool_And_Arguments_Columns = {
  /** column name */
  OwnerApprover: 'OwnerApprover'
} as const;

export type Approver_Select_Column_Approver_Aggregate_Bool_Exp_Bool_And_Arguments_Columns = typeof Approver_Select_Column_Approver_Aggregate_Bool_Exp_Bool_And_Arguments_Columns[keyof typeof Approver_Select_Column_Approver_Aggregate_Bool_Exp_Bool_And_Arguments_Columns];
/** select "approver_aggregate_bool_exp_bool_or_arguments_columns" columns of table "risksmart.approver" */
export const Approver_Select_Column_Approver_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns = {
  /** column name */
  OwnerApprover: 'OwnerApprover'
} as const;

export type Approver_Select_Column_Approver_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns = typeof Approver_Select_Column_Approver_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns[keyof typeof Approver_Select_Column_Approver_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns];
/** input type for updating data in table "risksmart.approver" */
export type Approver_Set_Input = {
  LevelId?: InputMaybe<Scalars['uuid']['input']>;
  OwnerApprover?: InputMaybe<Scalars['Boolean']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "approver" */
export type Approver_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Approver_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Approver_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LevelId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OwnerApprover?: InputMaybe<Scalars['Boolean']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.approver" */
export const Approver_Update_Column = {
  /** column name */
  LevelId: 'LevelId',
  /** column name */
  OwnerApprover: 'OwnerApprover',
  /** column name */
  UserGroupId: 'UserGroupId',
  /** column name */
  UserId: 'UserId'
} as const;

export type Approver_Update_Column = typeof Approver_Update_Column[keyof typeof Approver_Update_Column];
export type Approver_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Approver_Set_Input>;
  /** filter the rows which have to be updated */
  where: Approver_Bool_Exp;
};

/** columns and relationships of "risksmart.assessment" */
export type Assessment = {
  __typename?: 'assessment';
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Status: Assessment_Status_Enum;
  Summary: Scalars['String']['output'];
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title: Scalars['String']['output'];
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An array relationship */
  assessmentActions: Array<Action_Parent>;
  /** An aggregate relationship */
  assessmentActions_aggregate: Action_Parent_Aggregate;
  /** An array relationship */
  assessmentActivities: Array<Assessment_Activity>;
  /** An aggregate relationship */
  assessmentActivities_aggregate: Assessment_Activity_Aggregate;
  /** An array relationship */
  assessmentIssues: Array<Issue_Parent>;
  /** An aggregate relationship */
  assessmentIssues_aggregate: Issue_Parent_Aggregate;
  /** An array relationship */
  assessmentResults: Array<Assessment_Result_Parent>;
  /** An aggregate relationship */
  assessmentResults_aggregate: Assessment_Result_Parent_Aggregate;
  /** An object relationship */
  completedByUser?: Maybe<User>;
  /** An array relationship */
  contributorGroups: Array<Contributor_Group>;
  /** An aggregate relationship */
  contributorGroups_aggregate: Contributor_Group_Aggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: Contributor_Aggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  originatingItem?: Maybe<Node>;
  /** An array relationship */
  ownerGroups: Array<Owner_Group>;
  /** An aggregate relationship */
  ownerGroups_aggregate: Owner_Group_Aggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: Owner_Aggregate;
  /** An array relationship */
  tags: Array<Tag>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentAssessmentActionsArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentAssessmentActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentAssessmentActivitiesArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Activity_Order_By>>;
  where?: InputMaybe<Assessment_Activity_Bool_Exp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentAssessmentActivities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Activity_Order_By>>;
  where?: InputMaybe<Assessment_Activity_Bool_Exp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentAssessmentIssuesArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Order_By>>;
  where?: InputMaybe<Issue_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentAssessmentIssues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Order_By>>;
  where?: InputMaybe<Issue_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentAssessmentResultsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentAssessmentResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentContributorGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentContributorsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentContributors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentOwnerGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentOwnersArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentOwners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentTagsArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};

/** columns and relationships of "risksmart.assessment_activity" */
export type Assessment_Activity = {
  __typename?: 'assessment_activity';
  ActivityType: Assessment_Activity_Type_Enum;
  AssignedUser?: Maybe<Scalars['String']['output']>;
  CompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  Status?: Maybe<Assessment_Activity_Status_Enum>;
  Summary?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  assignedUser?: Maybe<User>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  files: Array<Relation_File>;
  /** An aggregate relationship */
  files_aggregate: Relation_File_Aggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parentAssessment?: Maybe<Assessment>;
  /** An object relationship */
  parentComplianceMonitoringAssessment?: Maybe<Compliance_Monitoring_Assessment>;
  /** An object relationship */
  parentInternalAuditReport?: Maybe<Internal_Audit_Report>;
};


/** columns and relationships of "risksmart.assessment_activity" */
export type Assessment_ActivityCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.assessment_activity" */
export type Assessment_ActivityFilesArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.assessment_activity" */
export type Assessment_ActivityFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};

/** aggregated selection of "risksmart.assessment_activity" */
export type Assessment_Activity_Aggregate = {
  __typename?: 'assessment_activity_aggregate';
  aggregate?: Maybe<Assessment_Activity_Aggregate_Fields>;
  nodes: Array<Assessment_Activity>;
};

export type Assessment_Activity_Aggregate_Bool_Exp = {
  count?: InputMaybe<Assessment_Activity_Aggregate_Bool_Exp_Count>;
};

export type Assessment_Activity_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Assessment_Activity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Assessment_Activity_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.assessment_activity" */
export type Assessment_Activity_Aggregate_Fields = {
  __typename?: 'assessment_activity_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Assessment_Activity_Max_Fields>;
  min?: Maybe<Assessment_Activity_Min_Fields>;
};


/** aggregate fields of "risksmart.assessment_activity" */
export type Assessment_Activity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Assessment_Activity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.assessment_activity" */
export type Assessment_Activity_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Assessment_Activity_Max_Order_By>;
  min?: InputMaybe<Assessment_Activity_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Assessment_Activity_Append_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.assessment_activity" */
export type Assessment_Activity_Arr_Rel_Insert_Input = {
  data: Array<Assessment_Activity_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Assessment_Activity_On_Conflict>;
};

/** columns and relationships of "risksmart.assessment_activity_audit" */
export type Assessment_Activity_Audit = {
  __typename?: 'assessment_activity_audit';
  ActivityType: Scalars['String']['output'];
  AssignedUser?: Maybe<Scalars['String']['output']>;
  CompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  Status?: Maybe<Scalars['String']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "risksmart.assessment_activity_audit" */
export type Assessment_Activity_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.assessment_activity_audit". All fields are combined with a logical 'AND'. */
export type Assessment_Activity_Audit_Bool_Exp = {
  ActivityType?: InputMaybe<String_Comparison_Exp>;
  AssignedUser?: InputMaybe<String_Comparison_Exp>;
  CompletionDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  Status?: InputMaybe<String_Comparison_Exp>;
  Summary?: InputMaybe<String_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Assessment_Activity_Audit_Bool_Exp>>;
  _not?: InputMaybe<Assessment_Activity_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Assessment_Activity_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.assessment_activity_audit". */
export type Assessment_Activity_Audit_Order_By = {
  ActivityType?: InputMaybe<Order_By>;
  AssignedUser?: InputMaybe<Order_By>;
  CompletionDate?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Summary?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.assessment_activity_audit" */
export const Assessment_Activity_Audit_Select_Column = {
  /** column name */
  ActivityType: 'ActivityType',
  /** column name */
  AssignedUser: 'AssignedUser',
  /** column name */
  CompletionDate: 'CompletionDate',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  Status: 'Status',
  /** column name */
  Summary: 'Summary',
  /** column name */
  Title: 'Title'
} as const;

export type Assessment_Activity_Audit_Select_Column = typeof Assessment_Activity_Audit_Select_Column[keyof typeof Assessment_Activity_Audit_Select_Column];
/** Streaming cursor of the table "assessment_activity_audit" */
export type Assessment_Activity_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Assessment_Activity_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Assessment_Activity_Audit_Stream_Cursor_Value_Input = {
  ActivityType?: InputMaybe<Scalars['String']['input']>;
  AssignedUser?: InputMaybe<Scalars['String']['input']>;
  CompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.assessment_activity". All fields are combined with a logical 'AND'. */
export type Assessment_Activity_Bool_Exp = {
  ActivityType?: InputMaybe<Assessment_Activity_Type_Enum_Comparison_Exp>;
  AssignedUser?: InputMaybe<String_Comparison_Exp>;
  CompletionDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  Status?: InputMaybe<Assessment_Activity_Status_Enum_Comparison_Exp>;
  Summary?: InputMaybe<String_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Assessment_Activity_Bool_Exp>>;
  _not?: InputMaybe<Assessment_Activity_Bool_Exp>;
  _or?: InputMaybe<Array<Assessment_Activity_Bool_Exp>>;
  assignedUser?: InputMaybe<User_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  files?: InputMaybe<Relation_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parentAssessment?: InputMaybe<Assessment_Bool_Exp>;
  parentComplianceMonitoringAssessment?: InputMaybe<Compliance_Monitoring_Assessment_Bool_Exp>;
  parentInternalAuditReport?: InputMaybe<Internal_Audit_Report_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.assessment_activity" */
export const Assessment_Activity_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  AssessmentActivityPkey: 'assessment_activity_pkey'
} as const;

export type Assessment_Activity_Constraint = typeof Assessment_Activity_Constraint[keyof typeof Assessment_Activity_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Assessment_Activity_Delete_At_Path_Input = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Assessment_Activity_Delete_Elem_Input = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Assessment_Activity_Delete_Key_Input = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.assessment_activity" */
export type Assessment_Activity_Insert_Input = {
  ActivityType?: InputMaybe<Assessment_Activity_Type_Enum>;
  AssignedUser?: InputMaybe<Scalars['String']['input']>;
  CompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Status?: InputMaybe<Assessment_Activity_Status_Enum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Relation_File_Arr_Rel_Insert_Input>;
  parentAssessment?: InputMaybe<Assessment_Obj_Rel_Insert_Input>;
  parentComplianceMonitoringAssessment?: InputMaybe<Compliance_Monitoring_Assessment_Obj_Rel_Insert_Input>;
  parentInternalAuditReport?: InputMaybe<Internal_Audit_Report_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Assessment_Activity_Max_Fields = {
  __typename?: 'assessment_activity_max_fields';
  AssignedUser?: Maybe<Scalars['String']['output']>;
  CompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.assessment_activity" */
export type Assessment_Activity_Max_Order_By = {
  AssignedUser?: InputMaybe<Order_By>;
  CompletionDate?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  Summary?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Assessment_Activity_Min_Fields = {
  __typename?: 'assessment_activity_min_fields';
  AssignedUser?: Maybe<Scalars['String']['output']>;
  CompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.assessment_activity" */
export type Assessment_Activity_Min_Order_By = {
  AssignedUser?: InputMaybe<Order_By>;
  CompletionDate?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  Summary?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.assessment_activity" */
export type Assessment_Activity_Mutation_Response = {
  __typename?: 'assessment_activity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Assessment_Activity>;
};

/** on_conflict condition type for table "risksmart.assessment_activity" */
export type Assessment_Activity_On_Conflict = {
  constraint: Assessment_Activity_Constraint;
  update_columns?: Array<Assessment_Activity_Update_Column>;
  where?: InputMaybe<Assessment_Activity_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.assessment_activity". */
export type Assessment_Activity_Order_By = {
  ActivityType?: InputMaybe<Order_By>;
  AssignedUser?: InputMaybe<Order_By>;
  CompletionDate?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Summary?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  assignedUser?: InputMaybe<User_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parentAssessment?: InputMaybe<Assessment_Order_By>;
  parentComplianceMonitoringAssessment?: InputMaybe<Compliance_Monitoring_Assessment_Order_By>;
  parentInternalAuditReport?: InputMaybe<Internal_Audit_Report_Order_By>;
};

/** primary key columns input for table: risksmart.assessment_activity */
export type Assessment_Activity_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Assessment_Activity_Prepend_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.assessment_activity" */
export const Assessment_Activity_Select_Column = {
  /** column name */
  ActivityType: 'ActivityType',
  /** column name */
  AssignedUser: 'AssignedUser',
  /** column name */
  CompletionDate: 'CompletionDate',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  Status: 'Status',
  /** column name */
  Summary: 'Summary',
  /** column name */
  Title: 'Title'
} as const;

export type Assessment_Activity_Select_Column = typeof Assessment_Activity_Select_Column[keyof typeof Assessment_Activity_Select_Column];
/** input type for updating data in table "risksmart.assessment_activity" */
export type Assessment_Activity_Set_Input = {
  ActivityType?: InputMaybe<Assessment_Activity_Type_Enum>;
  AssignedUser?: InputMaybe<Scalars['String']['input']>;
  CompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Status?: InputMaybe<Assessment_Activity_Status_Enum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

export const Assessment_Activity_Status_Enum = {
  /** Complete */
  Complete: 'complete',
  /** In Progress */
  Inprogress: 'inprogress',
  /** Not Started */
  Notstarted: 'notstarted'
} as const;

export type Assessment_Activity_Status_Enum = typeof Assessment_Activity_Status_Enum[keyof typeof Assessment_Activity_Status_Enum];
/** Boolean expression to compare columns of type "assessment_activity_status_enum". All fields are combined with logical 'AND'. */
export type Assessment_Activity_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Assessment_Activity_Status_Enum>;
  _in?: InputMaybe<Array<Assessment_Activity_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Assessment_Activity_Status_Enum>;
  _nin?: InputMaybe<Array<Assessment_Activity_Status_Enum>>;
};

/** Streaming cursor of the table "assessment_activity" */
export type Assessment_Activity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Assessment_Activity_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Assessment_Activity_Stream_Cursor_Value_Input = {
  ActivityType?: InputMaybe<Assessment_Activity_Type_Enum>;
  AssignedUser?: InputMaybe<Scalars['String']['input']>;
  CompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Status?: InputMaybe<Assessment_Activity_Status_Enum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

export const Assessment_Activity_Type_Enum = {
  /** Interview */
  Interview: 'interview',
  /** Meeting */
  Meeting: 'meeting',
  /** Reminder */
  Reminder: 'reminder',
  /** Review */
  Review: 'review',
  /** Task */
  Task: 'task'
} as const;

export type Assessment_Activity_Type_Enum = typeof Assessment_Activity_Type_Enum[keyof typeof Assessment_Activity_Type_Enum];
/** Boolean expression to compare columns of type "assessment_activity_type_enum". All fields are combined with logical 'AND'. */
export type Assessment_Activity_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Assessment_Activity_Type_Enum>;
  _in?: InputMaybe<Array<Assessment_Activity_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Assessment_Activity_Type_Enum>;
  _nin?: InputMaybe<Array<Assessment_Activity_Type_Enum>>;
};

/** update columns of table "risksmart.assessment_activity" */
export const Assessment_Activity_Update_Column = {
  /** column name */
  ActivityType: 'ActivityType',
  /** column name */
  AssignedUser: 'AssignedUser',
  /** column name */
  CompletionDate: 'CompletionDate',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Id: 'Id',
  /** column name */
  Status: 'Status',
  /** column name */
  Summary: 'Summary',
  /** column name */
  Title: 'Title'
} as const;

export type Assessment_Activity_Update_Column = typeof Assessment_Activity_Update_Column[keyof typeof Assessment_Activity_Update_Column];
export type Assessment_Activity_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Assessment_Activity_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Assessment_Activity_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Assessment_Activity_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Assessment_Activity_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Assessment_Activity_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Assessment_Activity_Set_Input>;
  /** filter the rows which have to be updated */
  where: Assessment_Activity_Bool_Exp;
};

/** aggregated selection of "risksmart.assessment" */
export type Assessment_Aggregate = {
  __typename?: 'assessment_aggregate';
  aggregate?: Maybe<Assessment_Aggregate_Fields>;
  nodes: Array<Assessment>;
};

/** aggregate fields of "risksmart.assessment" */
export type Assessment_Aggregate_Fields = {
  __typename?: 'assessment_aggregate_fields';
  avg?: Maybe<Assessment_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Assessment_Max_Fields>;
  min?: Maybe<Assessment_Min_Fields>;
  stddev?: Maybe<Assessment_Stddev_Fields>;
  stddev_pop?: Maybe<Assessment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Assessment_Stddev_Samp_Fields>;
  sum?: Maybe<Assessment_Sum_Fields>;
  var_pop?: Maybe<Assessment_Var_Pop_Fields>;
  var_samp?: Maybe<Assessment_Var_Samp_Fields>;
  variance?: Maybe<Assessment_Variance_Fields>;
};


/** aggregate fields of "risksmart.assessment" */
export type Assessment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Assessment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Assessment_Append_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.assessment_audit" */
export type Assessment_Audit = {
  __typename?: 'assessment_audit';
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Summary: Scalars['String']['output'];
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.assessment_audit" */
export type Assessment_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.assessment_audit" */
export type Assessment_Audit_Aggregate = {
  __typename?: 'assessment_audit_aggregate';
  aggregate?: Maybe<Assessment_Audit_Aggregate_Fields>;
  nodes: Array<Assessment_Audit>;
};

/** aggregate fields of "risksmart.assessment_audit" */
export type Assessment_Audit_Aggregate_Fields = {
  __typename?: 'assessment_audit_aggregate_fields';
  avg?: Maybe<Assessment_Audit_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Assessment_Audit_Max_Fields>;
  min?: Maybe<Assessment_Audit_Min_Fields>;
  stddev?: Maybe<Assessment_Audit_Stddev_Fields>;
  stddev_pop?: Maybe<Assessment_Audit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Assessment_Audit_Stddev_Samp_Fields>;
  sum?: Maybe<Assessment_Audit_Sum_Fields>;
  var_pop?: Maybe<Assessment_Audit_Var_Pop_Fields>;
  var_samp?: Maybe<Assessment_Audit_Var_Samp_Fields>;
  variance?: Maybe<Assessment_Audit_Variance_Fields>;
};


/** aggregate fields of "risksmart.assessment_audit" */
export type Assessment_Audit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Assessment_Audit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Assessment_Audit_Avg_Fields = {
  __typename?: 'assessment_audit_avg_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.assessment_audit". All fields are combined with a logical 'AND'. */
export type Assessment_Audit_Bool_Exp = {
  ActualCompletionDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  CompletedByUser?: InputMaybe<String_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  NextTestDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  OriginatingItemId?: InputMaybe<Uuid_Comparison_Exp>;
  Outcome?: InputMaybe<Int_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  StartDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Status?: InputMaybe<String_Comparison_Exp>;
  Summary?: InputMaybe<String_Comparison_Exp>;
  TargetCompletionDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Assessment_Audit_Bool_Exp>>;
  _not?: InputMaybe<Assessment_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Assessment_Audit_Bool_Exp>>;
};

/** aggregate max on columns */
export type Assessment_Audit_Max_Fields = {
  __typename?: 'assessment_audit_max_fields';
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Assessment_Audit_Min_Fields = {
  __typename?: 'assessment_audit_min_fields';
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "risksmart.assessment_audit". */
export type Assessment_Audit_Order_By = {
  ActualCompletionDate?: InputMaybe<Order_By>;
  CompletedByUser?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  NextTestDate?: InputMaybe<Order_By>;
  OriginatingItemId?: InputMaybe<Order_By>;
  Outcome?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  StartDate?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Summary?: InputMaybe<Order_By>;
  TargetCompletionDate?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.assessment_audit" */
export const Assessment_Audit_Select_Column = {
  /** column name */
  ActualCompletionDate: 'ActualCompletionDate',
  /** column name */
  CompletedByUser: 'CompletedByUser',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  NextTestDate: 'NextTestDate',
  /** column name */
  OriginatingItemId: 'OriginatingItemId',
  /** column name */
  Outcome: 'Outcome',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  StartDate: 'StartDate',
  /** column name */
  Status: 'Status',
  /** column name */
  Summary: 'Summary',
  /** column name */
  TargetCompletionDate: 'TargetCompletionDate',
  /** column name */
  Title: 'Title'
} as const;

export type Assessment_Audit_Select_Column = typeof Assessment_Audit_Select_Column[keyof typeof Assessment_Audit_Select_Column];
/** aggregate stddev on columns */
export type Assessment_Audit_Stddev_Fields = {
  __typename?: 'assessment_audit_stddev_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Assessment_Audit_Stddev_Pop_Fields = {
  __typename?: 'assessment_audit_stddev_pop_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Assessment_Audit_Stddev_Samp_Fields = {
  __typename?: 'assessment_audit_stddev_samp_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "assessment_audit" */
export type Assessment_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Assessment_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Assessment_Audit_Stream_Cursor_Value_Input = {
  ActualCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CompletedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OriginatingItemId?: InputMaybe<Scalars['uuid']['input']>;
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  TargetCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Assessment_Audit_Sum_Fields = {
  __typename?: 'assessment_audit_sum_fields';
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Assessment_Audit_Var_Pop_Fields = {
  __typename?: 'assessment_audit_var_pop_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Assessment_Audit_Var_Samp_Fields = {
  __typename?: 'assessment_audit_var_samp_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Assessment_Audit_Variance_Fields = {
  __typename?: 'assessment_audit_variance_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type Assessment_Avg_Fields = {
  __typename?: 'assessment_avg_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.assessment". All fields are combined with a logical 'AND'. */
export type Assessment_Bool_Exp = {
  ActualCompletionDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  CompletedByUser?: InputMaybe<String_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  NextTestDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  OriginatingItemId?: InputMaybe<Uuid_Comparison_Exp>;
  Outcome?: InputMaybe<Int_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  StartDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Status?: InputMaybe<Assessment_Status_Enum_Comparison_Exp>;
  Summary?: InputMaybe<String_Comparison_Exp>;
  TargetCompletionDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Assessment_Bool_Exp>>;
  _not?: InputMaybe<Assessment_Bool_Exp>;
  _or?: InputMaybe<Array<Assessment_Bool_Exp>>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  assessmentActions?: InputMaybe<Action_Parent_Bool_Exp>;
  assessmentActions_aggregate?: InputMaybe<Action_Parent_Aggregate_Bool_Exp>;
  assessmentActivities?: InputMaybe<Assessment_Activity_Bool_Exp>;
  assessmentActivities_aggregate?: InputMaybe<Assessment_Activity_Aggregate_Bool_Exp>;
  assessmentIssues?: InputMaybe<Issue_Parent_Bool_Exp>;
  assessmentIssues_aggregate?: InputMaybe<Issue_Parent_Aggregate_Bool_Exp>;
  assessmentResults?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
  assessmentResults_aggregate?: InputMaybe<Assessment_Result_Parent_Aggregate_Bool_Exp>;
  completedByUser?: InputMaybe<User_Bool_Exp>;
  contributorGroups?: InputMaybe<Contributor_Group_Bool_Exp>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Bool_Exp>;
  contributors?: InputMaybe<Contributor_Bool_Exp>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  departments?: InputMaybe<Department_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  originatingItem?: InputMaybe<Node_Bool_Exp>;
  ownerGroups?: InputMaybe<Owner_Group_Bool_Exp>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Bool_Exp>;
  owners?: InputMaybe<Owner_Bool_Exp>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Bool_Exp>;
  tags?: InputMaybe<Tag_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.assessment" */
export const Assessment_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  AssessmentPkey: 'assessment_pkey',
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxAssessmentOrgkeySequentialid: 'idx_assessment_orgkey_sequentialid'
} as const;

export type Assessment_Constraint = typeof Assessment_Constraint[keyof typeof Assessment_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Assessment_Delete_At_Path_Input = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Assessment_Delete_Elem_Input = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Assessment_Delete_Key_Input = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.assessment" */
export type Assessment_Inc_Input = {
  Outcome?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.assessment" */
export type Assessment_Insert_Input = {
  ActualCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CompletedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OriginatingItemId?: InputMaybe<Scalars['uuid']['input']>;
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<Assessment_Status_Enum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  TargetCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  assessmentActions?: InputMaybe<Action_Parent_Arr_Rel_Insert_Input>;
  assessmentActivities?: InputMaybe<Assessment_Activity_Arr_Rel_Insert_Input>;
  assessmentIssues?: InputMaybe<Issue_Parent_Arr_Rel_Insert_Input>;
  assessmentResults?: InputMaybe<Assessment_Result_Parent_Arr_Rel_Insert_Input>;
  contributorGroups?: InputMaybe<Contributor_Group_Arr_Rel_Insert_Input>;
  contributors?: InputMaybe<Contributor_Arr_Rel_Insert_Input>;
  departments?: InputMaybe<Department_Arr_Rel_Insert_Input>;
  ownerGroups?: InputMaybe<Owner_Group_Arr_Rel_Insert_Input>;
  owners?: InputMaybe<Owner_Arr_Rel_Insert_Input>;
  tags?: InputMaybe<Tag_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Assessment_Max_Fields = {
  __typename?: 'assessment_max_fields';
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Assessment_Min_Fields = {
  __typename?: 'assessment_min_fields';
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.assessment" */
export type Assessment_Mutation_Response = {
  __typename?: 'assessment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Assessment>;
};

/** input type for inserting object relation for remote table "risksmart.assessment" */
export type Assessment_Obj_Rel_Insert_Input = {
  data: Assessment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Assessment_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.assessment" */
export type Assessment_On_Conflict = {
  constraint: Assessment_Constraint;
  update_columns?: Array<Assessment_Update_Column>;
  where?: InputMaybe<Assessment_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.assessment". */
export type Assessment_Order_By = {
  ActualCompletionDate?: InputMaybe<Order_By>;
  CompletedByUser?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  NextTestDate?: InputMaybe<Order_By>;
  OriginatingItemId?: InputMaybe<Order_By>;
  Outcome?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  StartDate?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Summary?: InputMaybe<Order_By>;
  TargetCompletionDate?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  assessmentActions_aggregate?: InputMaybe<Action_Parent_Aggregate_Order_By>;
  assessmentActivities_aggregate?: InputMaybe<Assessment_Activity_Aggregate_Order_By>;
  assessmentIssues_aggregate?: InputMaybe<Issue_Parent_Aggregate_Order_By>;
  assessmentResults_aggregate?: InputMaybe<Assessment_Result_Parent_Aggregate_Order_By>;
  completedByUser?: InputMaybe<User_Order_By>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Order_By>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  departments_aggregate?: InputMaybe<Department_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  originatingItem?: InputMaybe<Node_Order_By>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Order_By>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Order_By>;
  tags_aggregate?: InputMaybe<Tag_Aggregate_Order_By>;
};

/** primary key columns input for table: risksmart.assessment */
export type Assessment_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Assessment_Prepend_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.assessment_result_parent" */
export type Assessment_Result_Parent = {
  __typename?: 'assessment_result_parent';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  ParentType: Parent_Type_Enum;
  ResultType: Parent_Type_Enum;
  /** An object relationship */
  assessment?: Maybe<Assessment>;
  /** An object relationship */
  assessment_result?: Maybe<Node>;
  /** An object relationship */
  complianceMonitoringAssessment?: Maybe<Compliance_Monitoring_Assessment>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  document?: Maybe<Document>;
  /** An object relationship */
  documentAssessmentResult?: Maybe<Document_Assessment_Result>;
  /** An object relationship */
  impactRating?: Maybe<Impact_Rating>;
  /** An object relationship */
  internalAuditReport?: Maybe<Internal_Audit_Report>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  node?: Maybe<Node>;
  /** An object relationship */
  obligation?: Maybe<Obligation>;
  /** An object relationship */
  obligationAssessmentResult?: Maybe<Obligation_Assessment_Result>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parent?: Maybe<Node>;
  /** An object relationship */
  risk?: Maybe<Risk>;
  /** An object relationship */
  riskAssessmentResult?: Maybe<Risk_Assessment_Result>;
  /** An object relationship */
  testResult?: Maybe<Test_Result>;
};

/** aggregated selection of "risksmart.assessment_result_parent" */
export type Assessment_Result_Parent_Aggregate = {
  __typename?: 'assessment_result_parent_aggregate';
  aggregate?: Maybe<Assessment_Result_Parent_Aggregate_Fields>;
  nodes: Array<Assessment_Result_Parent>;
};

export type Assessment_Result_Parent_Aggregate_Bool_Exp = {
  count?: InputMaybe<Assessment_Result_Parent_Aggregate_Bool_Exp_Count>;
};

export type Assessment_Result_Parent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.assessment_result_parent" */
export type Assessment_Result_Parent_Aggregate_Fields = {
  __typename?: 'assessment_result_parent_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Assessment_Result_Parent_Max_Fields>;
  min?: Maybe<Assessment_Result_Parent_Min_Fields>;
};


/** aggregate fields of "risksmart.assessment_result_parent" */
export type Assessment_Result_Parent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.assessment_result_parent" */
export type Assessment_Result_Parent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Assessment_Result_Parent_Max_Order_By>;
  min?: InputMaybe<Assessment_Result_Parent_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.assessment_result_parent" */
export type Assessment_Result_Parent_Arr_Rel_Insert_Input = {
  data: Array<Assessment_Result_Parent_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Assessment_Result_Parent_On_Conflict>;
};

/** columns and relationships of "risksmart.assessment_result_parent_audit" */
export type Assessment_Result_Parent_Audit = {
  __typename?: 'assessment_result_parent_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId: Scalars['uuid']['output'];
  ParentType: Scalars['String']['output'];
  ResultType: Scalars['String']['output'];
};

/** order by aggregate values of table "risksmart.assessment_result_parent_audit" */
export type Assessment_Result_Parent_Audit_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Assessment_Result_Parent_Audit_Max_Order_By>;
  min?: InputMaybe<Assessment_Result_Parent_Audit_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.assessment_result_parent_audit". All fields are combined with a logical 'AND'. */
export type Assessment_Result_Parent_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  ParentType?: InputMaybe<String_Comparison_Exp>;
  ResultType?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Assessment_Result_Parent_Audit_Bool_Exp>>;
  _not?: InputMaybe<Assessment_Result_Parent_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Assessment_Result_Parent_Audit_Bool_Exp>>;
};

/** order by max() on columns of table "risksmart.assessment_result_parent_audit" */
export type Assessment_Result_Parent_Audit_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  ParentType?: InputMaybe<Order_By>;
  ResultType?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.assessment_result_parent_audit" */
export type Assessment_Result_Parent_Audit_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  ParentType?: InputMaybe<Order_By>;
  ResultType?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "risksmart.assessment_result_parent_audit". */
export type Assessment_Result_Parent_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  ParentType?: InputMaybe<Order_By>;
  ResultType?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.assessment_result_parent_audit" */
export const Assessment_Result_Parent_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  ParentType: 'ParentType',
  /** column name */
  ResultType: 'ResultType'
} as const;

export type Assessment_Result_Parent_Audit_Select_Column = typeof Assessment_Result_Parent_Audit_Select_Column[keyof typeof Assessment_Result_Parent_Audit_Select_Column];
/** Streaming cursor of the table "assessment_result_parent_audit" */
export type Assessment_Result_Parent_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Assessment_Result_Parent_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Assessment_Result_Parent_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
  ResultType?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.assessment_result_parent". All fields are combined with a logical 'AND'. */
export type Assessment_Result_Parent_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  ParentType?: InputMaybe<Parent_Type_Enum_Comparison_Exp>;
  ResultType?: InputMaybe<Parent_Type_Enum_Comparison_Exp>;
  _and?: InputMaybe<Array<Assessment_Result_Parent_Bool_Exp>>;
  _not?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
  _or?: InputMaybe<Array<Assessment_Result_Parent_Bool_Exp>>;
  assessment?: InputMaybe<Assessment_Bool_Exp>;
  assessment_result?: InputMaybe<Node_Bool_Exp>;
  complianceMonitoringAssessment?: InputMaybe<Compliance_Monitoring_Assessment_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  document?: InputMaybe<Document_Bool_Exp>;
  documentAssessmentResult?: InputMaybe<Document_Assessment_Result_Bool_Exp>;
  impactRating?: InputMaybe<Impact_Rating_Bool_Exp>;
  internalAuditReport?: InputMaybe<Internal_Audit_Report_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  node?: InputMaybe<Node_Bool_Exp>;
  obligation?: InputMaybe<Obligation_Bool_Exp>;
  obligationAssessmentResult?: InputMaybe<Obligation_Assessment_Result_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parent?: InputMaybe<Node_Bool_Exp>;
  risk?: InputMaybe<Risk_Bool_Exp>;
  riskAssessmentResult?: InputMaybe<Risk_Assessment_Result_Bool_Exp>;
  testResult?: InputMaybe<Test_Result_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.assessment_result_parent" */
export const Assessment_Result_Parent_Constraint = {
  /** unique or primary key constraint on columns "ParentId", "Id" */
  AssessmentResultParentPkey: 'assessment_result_parent_pkey'
} as const;

export type Assessment_Result_Parent_Constraint = typeof Assessment_Result_Parent_Constraint[keyof typeof Assessment_Result_Parent_Constraint];
/** input type for inserting data into table "risksmart.assessment_result_parent" */
export type Assessment_Result_Parent_Insert_Input = {
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Parent_Type_Enum>;
  ResultType?: InputMaybe<Parent_Type_Enum>;
  assessment?: InputMaybe<Assessment_Obj_Rel_Insert_Input>;
  complianceMonitoringAssessment?: InputMaybe<Compliance_Monitoring_Assessment_Obj_Rel_Insert_Input>;
  document?: InputMaybe<Document_Obj_Rel_Insert_Input>;
  documentAssessmentResult?: InputMaybe<Document_Assessment_Result_Obj_Rel_Insert_Input>;
  impactRating?: InputMaybe<Impact_Rating_Obj_Rel_Insert_Input>;
  internalAuditReport?: InputMaybe<Internal_Audit_Report_Obj_Rel_Insert_Input>;
  obligation?: InputMaybe<Obligation_Obj_Rel_Insert_Input>;
  obligationAssessmentResult?: InputMaybe<Obligation_Assessment_Result_Obj_Rel_Insert_Input>;
  risk?: InputMaybe<Risk_Obj_Rel_Insert_Input>;
  riskAssessmentResult?: InputMaybe<Risk_Assessment_Result_Obj_Rel_Insert_Input>;
  testResult?: InputMaybe<Test_Result_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Assessment_Result_Parent_Max_Fields = {
  __typename?: 'assessment_result_parent_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.assessment_result_parent" */
export type Assessment_Result_Parent_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Assessment_Result_Parent_Min_Fields = {
  __typename?: 'assessment_result_parent_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.assessment_result_parent" */
export type Assessment_Result_Parent_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.assessment_result_parent" */
export type Assessment_Result_Parent_Mutation_Response = {
  __typename?: 'assessment_result_parent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Assessment_Result_Parent>;
};

/** on_conflict condition type for table "risksmart.assessment_result_parent" */
export type Assessment_Result_Parent_On_Conflict = {
  constraint: Assessment_Result_Parent_Constraint;
  update_columns?: Array<Assessment_Result_Parent_Update_Column>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.assessment_result_parent". */
export type Assessment_Result_Parent_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  ParentType?: InputMaybe<Order_By>;
  ResultType?: InputMaybe<Order_By>;
  assessment?: InputMaybe<Assessment_Order_By>;
  assessment_result?: InputMaybe<Node_Order_By>;
  complianceMonitoringAssessment?: InputMaybe<Compliance_Monitoring_Assessment_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  document?: InputMaybe<Document_Order_By>;
  documentAssessmentResult?: InputMaybe<Document_Assessment_Result_Order_By>;
  impactRating?: InputMaybe<Impact_Rating_Order_By>;
  internalAuditReport?: InputMaybe<Internal_Audit_Report_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  node?: InputMaybe<Node_Order_By>;
  obligation?: InputMaybe<Obligation_Order_By>;
  obligationAssessmentResult?: InputMaybe<Obligation_Assessment_Result_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parent?: InputMaybe<Node_Order_By>;
  risk?: InputMaybe<Risk_Order_By>;
  riskAssessmentResult?: InputMaybe<Risk_Assessment_Result_Order_By>;
  testResult?: InputMaybe<Test_Result_Order_By>;
};

/** select columns of table "risksmart.assessment_result_parent" */
export const Assessment_Result_Parent_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  ParentType: 'ParentType',
  /** column name */
  ResultType: 'ResultType'
} as const;

export type Assessment_Result_Parent_Select_Column = typeof Assessment_Result_Parent_Select_Column[keyof typeof Assessment_Result_Parent_Select_Column];
/** Streaming cursor of the table "assessment_result_parent" */
export type Assessment_Result_Parent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Assessment_Result_Parent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Assessment_Result_Parent_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Parent_Type_Enum>;
  ResultType?: InputMaybe<Parent_Type_Enum>;
};

/** placeholder for update columns of table "risksmart.assessment_result_parent" (current role has no relevant permissions) */
export const Assessment_Result_Parent_Update_Column = {
  /** placeholder (do not use) */
  Placeholder: '_PLACEHOLDER'
} as const;

export type Assessment_Result_Parent_Update_Column = typeof Assessment_Result_Parent_Update_Column[keyof typeof Assessment_Result_Parent_Update_Column];
/** select columns of table "risksmart.assessment" */
export const Assessment_Select_Column = {
  /** column name */
  ActualCompletionDate: 'ActualCompletionDate',
  /** column name */
  CompletedByUser: 'CompletedByUser',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  NextTestDate: 'NextTestDate',
  /** column name */
  OriginatingItemId: 'OriginatingItemId',
  /** column name */
  Outcome: 'Outcome',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  StartDate: 'StartDate',
  /** column name */
  Status: 'Status',
  /** column name */
  Summary: 'Summary',
  /** column name */
  TargetCompletionDate: 'TargetCompletionDate',
  /** column name */
  Title: 'Title'
} as const;

export type Assessment_Select_Column = typeof Assessment_Select_Column[keyof typeof Assessment_Select_Column];
/** input type for updating data in table "risksmart.assessment" */
export type Assessment_Set_Input = {
  ActualCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CompletedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OriginatingItemId?: InputMaybe<Scalars['uuid']['input']>;
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<Assessment_Status_Enum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  TargetCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

export const Assessment_Status_Enum = {
  /** Complete */
  Complete: 'complete',
  /** In Progress */
  Inprogress: 'inprogress',
  /** Not Started */
  Notstarted: 'notstarted'
} as const;

export type Assessment_Status_Enum = typeof Assessment_Status_Enum[keyof typeof Assessment_Status_Enum];
/** Boolean expression to compare columns of type "assessment_status_enum". All fields are combined with logical 'AND'. */
export type Assessment_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Assessment_Status_Enum>;
  _in?: InputMaybe<Array<Assessment_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Assessment_Status_Enum>;
  _nin?: InputMaybe<Array<Assessment_Status_Enum>>;
};

/** aggregate stddev on columns */
export type Assessment_Stddev_Fields = {
  __typename?: 'assessment_stddev_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Assessment_Stddev_Pop_Fields = {
  __typename?: 'assessment_stddev_pop_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Assessment_Stddev_Samp_Fields = {
  __typename?: 'assessment_stddev_samp_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "assessment" */
export type Assessment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Assessment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Assessment_Stream_Cursor_Value_Input = {
  ActualCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CompletedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OriginatingItemId?: InputMaybe<Scalars['uuid']['input']>;
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<Assessment_Status_Enum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  TargetCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Assessment_Sum_Fields = {
  __typename?: 'assessment_sum_fields';
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.assessment" */
export const Assessment_Update_Column = {
  /** column name */
  ActualCompletionDate: 'ActualCompletionDate',
  /** column name */
  CompletedByUser: 'CompletedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  NextTestDate: 'NextTestDate',
  /** column name */
  OriginatingItemId: 'OriginatingItemId',
  /** column name */
  Outcome: 'Outcome',
  /** column name */
  StartDate: 'StartDate',
  /** column name */
  Status: 'Status',
  /** column name */
  Summary: 'Summary',
  /** column name */
  TargetCompletionDate: 'TargetCompletionDate',
  /** column name */
  Title: 'Title'
} as const;

export type Assessment_Update_Column = typeof Assessment_Update_Column[keyof typeof Assessment_Update_Column];
export type Assessment_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Assessment_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Assessment_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Assessment_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Assessment_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Assessment_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Assessment_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Assessment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Assessment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Assessment_Var_Pop_Fields = {
  __typename?: 'assessment_var_pop_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Assessment_Var_Samp_Fields = {
  __typename?: 'assessment_var_samp_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Assessment_Variance_Fields = {
  __typename?: 'assessment_variance_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.attestation_config" */
export type Attestation_Config = {
  __typename?: 'attestation_config';
  AttestationTimeLimit?: Maybe<Scalars['interval']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  PromptText?: Maybe<Scalars['String']['output']>;
  RequireGlobalAttestation: Scalars['Boolean']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  groups: Array<Attestation_Group>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parent: Node;
  /** An array relationship */
  records: Array<Attestation_Record>;
  /** An aggregate relationship */
  records_aggregate: Attestation_Record_Aggregate;
  timeLimitMs?: Maybe<Scalars['bigint']['output']>;
};


/** columns and relationships of "risksmart.attestation_config" */
export type Attestation_ConfigGroupsArgs = {
  distinct_on?: InputMaybe<Array<Attestation_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attestation_Group_Order_By>>;
  where?: InputMaybe<Attestation_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.attestation_config" */
export type Attestation_ConfigRecordsArgs = {
  distinct_on?: InputMaybe<Array<Attestation_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attestation_Record_Order_By>>;
  where?: InputMaybe<Attestation_Record_Bool_Exp>;
};


/** columns and relationships of "risksmart.attestation_config" */
export type Attestation_ConfigRecords_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attestation_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attestation_Record_Order_By>>;
  where?: InputMaybe<Attestation_Record_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "risksmart.attestation_config". All fields are combined with a logical 'AND'. */
export type Attestation_Config_Bool_Exp = {
  AttestationTimeLimit?: InputMaybe<Interval_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  PromptText?: InputMaybe<String_Comparison_Exp>;
  RequireGlobalAttestation?: InputMaybe<Boolean_Comparison_Exp>;
  _and?: InputMaybe<Array<Attestation_Config_Bool_Exp>>;
  _not?: InputMaybe<Attestation_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Attestation_Config_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  groups?: InputMaybe<Attestation_Group_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parent?: InputMaybe<Node_Bool_Exp>;
  records?: InputMaybe<Attestation_Record_Bool_Exp>;
  records_aggregate?: InputMaybe<Attestation_Record_Aggregate_Bool_Exp>;
  timeLimitMs?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "risksmart.attestation_config" */
export const Attestation_Config_Constraint = {
  /** unique or primary key constraint on columns "ParentId" */
  AttestationConfigPkey: 'attestation_config_pkey'
} as const;

export type Attestation_Config_Constraint = typeof Attestation_Config_Constraint[keyof typeof Attestation_Config_Constraint];
/** input type for inserting data into table "risksmart.attestation_config" */
export type Attestation_Config_Insert_Input = {
  AttestationTimeLimit?: InputMaybe<Scalars['interval']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  PromptText?: InputMaybe<Scalars['String']['input']>;
  RequireGlobalAttestation?: InputMaybe<Scalars['Boolean']['input']>;
  groups?: InputMaybe<Attestation_Group_Arr_Rel_Insert_Input>;
  records?: InputMaybe<Attestation_Record_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "risksmart.attestation_config" */
export type Attestation_Config_Mutation_Response = {
  __typename?: 'attestation_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Attestation_Config>;
};

/** input type for inserting object relation for remote table "risksmart.attestation_config" */
export type Attestation_Config_Obj_Rel_Insert_Input = {
  data: Attestation_Config_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Attestation_Config_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.attestation_config" */
export type Attestation_Config_On_Conflict = {
  constraint: Attestation_Config_Constraint;
  update_columns?: Array<Attestation_Config_Update_Column>;
  where?: InputMaybe<Attestation_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.attestation_config". */
export type Attestation_Config_Order_By = {
  AttestationTimeLimit?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  PromptText?: InputMaybe<Order_By>;
  RequireGlobalAttestation?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  groups_aggregate?: InputMaybe<Attestation_Group_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parent?: InputMaybe<Node_Order_By>;
  records_aggregate?: InputMaybe<Attestation_Record_Aggregate_Order_By>;
  timeLimitMs?: InputMaybe<Order_By>;
};

/** primary key columns input for table: risksmart.attestation_config */
export type Attestation_Config_Pk_Columns_Input = {
  ParentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.attestation_config" */
export const Attestation_Config_Select_Column = {
  /** column name */
  AttestationTimeLimit: 'AttestationTimeLimit',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  PromptText: 'PromptText',
  /** column name */
  RequireGlobalAttestation: 'RequireGlobalAttestation'
} as const;

export type Attestation_Config_Select_Column = typeof Attestation_Config_Select_Column[keyof typeof Attestation_Config_Select_Column];
/** input type for updating data in table "risksmart.attestation_config" */
export type Attestation_Config_Set_Input = {
  AttestationTimeLimit?: InputMaybe<Scalars['interval']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  PromptText?: InputMaybe<Scalars['String']['input']>;
  RequireGlobalAttestation?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "attestation_config" */
export type Attestation_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Attestation_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attestation_Config_Stream_Cursor_Value_Input = {
  AttestationTimeLimit?: InputMaybe<Scalars['interval']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  PromptText?: InputMaybe<Scalars['String']['input']>;
  RequireGlobalAttestation?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "risksmart.attestation_config" */
export const Attestation_Config_Update_Column = {
  /** column name */
  AttestationTimeLimit: 'AttestationTimeLimit',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  PromptText: 'PromptText',
  /** column name */
  RequireGlobalAttestation: 'RequireGlobalAttestation'
} as const;

export type Attestation_Config_Update_Column = typeof Attestation_Config_Update_Column[keyof typeof Attestation_Config_Update_Column];
export type Attestation_Config_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attestation_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Attestation_Config_Bool_Exp;
};

/** columns and relationships of "risksmart.attestation_group" */
export type Attestation_Group = {
  __typename?: 'attestation_group';
  ConfigId: Scalars['uuid']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  GroupId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  /** An object relationship */
  config: Attestation_Config;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  group: User_Group;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
};

/** order by aggregate values of table "risksmart.attestation_group" */
export type Attestation_Group_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Attestation_Group_Max_Order_By>;
  min?: InputMaybe<Attestation_Group_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.attestation_group" */
export type Attestation_Group_Arr_Rel_Insert_Input = {
  data: Array<Attestation_Group_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Attestation_Group_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risksmart.attestation_group". All fields are combined with a logical 'AND'. */
export type Attestation_Group_Bool_Exp = {
  ConfigId?: InputMaybe<Uuid_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  GroupId?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Attestation_Group_Bool_Exp>>;
  _not?: InputMaybe<Attestation_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Attestation_Group_Bool_Exp>>;
  config?: InputMaybe<Attestation_Config_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  group?: InputMaybe<User_Group_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.attestation_group" */
export const Attestation_Group_Constraint = {
  /** unique or primary key constraint on columns "ConfigId", "GroupId" */
  AttestationGroupPkey: 'attestation_group_pkey'
} as const;

export type Attestation_Group_Constraint = typeof Attestation_Group_Constraint[keyof typeof Attestation_Group_Constraint];
/** input type for inserting data into table "risksmart.attestation_group" */
export type Attestation_Group_Insert_Input = {
  ConfigId?: InputMaybe<Scalars['uuid']['input']>;
  GroupId?: InputMaybe<Scalars['uuid']['input']>;
  config?: InputMaybe<Attestation_Config_Obj_Rel_Insert_Input>;
  group?: InputMaybe<User_Group_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "risksmart.attestation_group" */
export type Attestation_Group_Max_Order_By = {
  ConfigId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  GroupId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.attestation_group" */
export type Attestation_Group_Min_Order_By = {
  ConfigId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  GroupId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.attestation_group" */
export type Attestation_Group_Mutation_Response = {
  __typename?: 'attestation_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Attestation_Group>;
};

/** on_conflict condition type for table "risksmart.attestation_group" */
export type Attestation_Group_On_Conflict = {
  constraint: Attestation_Group_Constraint;
  update_columns?: Array<Attestation_Group_Update_Column>;
  where?: InputMaybe<Attestation_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.attestation_group". */
export type Attestation_Group_Order_By = {
  ConfigId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  GroupId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  config?: InputMaybe<Attestation_Config_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  group?: InputMaybe<User_Group_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
};

/** primary key columns input for table: risksmart.attestation_group */
export type Attestation_Group_Pk_Columns_Input = {
  ConfigId: Scalars['uuid']['input'];
  GroupId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.attestation_group" */
export const Attestation_Group_Select_Column = {
  /** column name */
  ConfigId: 'ConfigId',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  GroupId: 'GroupId',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser'
} as const;

export type Attestation_Group_Select_Column = typeof Attestation_Group_Select_Column[keyof typeof Attestation_Group_Select_Column];
/** input type for updating data in table "risksmart.attestation_group" */
export type Attestation_Group_Set_Input = {
  ConfigId?: InputMaybe<Scalars['uuid']['input']>;
  GroupId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "attestation_group" */
export type Attestation_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Attestation_Group_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attestation_Group_Stream_Cursor_Value_Input = {
  ConfigId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  GroupId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.attestation_group" */
export const Attestation_Group_Update_Column = {
  /** column name */
  ConfigId: 'ConfigId',
  /** column name */
  GroupId: 'GroupId'
} as const;

export type Attestation_Group_Update_Column = typeof Attestation_Group_Update_Column[keyof typeof Attestation_Group_Update_Column];
export type Attestation_Group_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attestation_Group_Set_Input>;
  /** filter the rows which have to be updated */
  where: Attestation_Group_Bool_Exp;
};

/** columns and relationships of "risksmart.attestation_record" */
export type Attestation_Record = {
  __typename?: 'attestation_record';
  Active: Scalars['Boolean']['output'];
  AttestationStatus: Attestation_Record_Status_Enum;
  AttestedAt?: Maybe<Scalars['timestamptz']['output']>;
  ConfigId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  NodeId: Scalars['uuid']['output'];
  OrgKey: Scalars['String']['output'];
  UserId: Scalars['String']['output'];
  /** An object relationship */
  config?: Maybe<Attestation_Config>;
  /** An object relationship */
  createdBy?: Maybe<User>;
  /** An object relationship */
  modifiedBy?: Maybe<User>;
  /** An object relationship */
  node: Node;
  /** An object relationship */
  user: Auth_User;
};

/** aggregated selection of "risksmart.attestation_record" */
export type Attestation_Record_Aggregate = {
  __typename?: 'attestation_record_aggregate';
  aggregate?: Maybe<Attestation_Record_Aggregate_Fields>;
  nodes: Array<Attestation_Record>;
};

export type Attestation_Record_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Attestation_Record_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Attestation_Record_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Attestation_Record_Aggregate_Bool_Exp_Count>;
};

export type Attestation_Record_Aggregate_Bool_Exp_Bool_And = {
  arguments: Attestation_Record_Select_Column_Attestation_Record_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Attestation_Record_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Attestation_Record_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Attestation_Record_Select_Column_Attestation_Record_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Attestation_Record_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Attestation_Record_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Attestation_Record_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Attestation_Record_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.attestation_record" */
export type Attestation_Record_Aggregate_Fields = {
  __typename?: 'attestation_record_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Attestation_Record_Max_Fields>;
  min?: Maybe<Attestation_Record_Min_Fields>;
};


/** aggregate fields of "risksmart.attestation_record" */
export type Attestation_Record_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Attestation_Record_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.attestation_record" */
export type Attestation_Record_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Attestation_Record_Max_Order_By>;
  min?: InputMaybe<Attestation_Record_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.attestation_record" */
export type Attestation_Record_Arr_Rel_Insert_Input = {
  data: Array<Attestation_Record_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Attestation_Record_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risksmart.attestation_record". All fields are combined with a logical 'AND'. */
export type Attestation_Record_Bool_Exp = {
  Active?: InputMaybe<Boolean_Comparison_Exp>;
  AttestationStatus?: InputMaybe<Attestation_Record_Status_Enum_Comparison_Exp>;
  AttestedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  ConfigId?: InputMaybe<Uuid_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  ExpiresAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  NodeId?: InputMaybe<Uuid_Comparison_Exp>;
  OrgKey?: InputMaybe<String_Comparison_Exp>;
  UserId?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Attestation_Record_Bool_Exp>>;
  _not?: InputMaybe<Attestation_Record_Bool_Exp>;
  _or?: InputMaybe<Array<Attestation_Record_Bool_Exp>>;
  config?: InputMaybe<Attestation_Config_Bool_Exp>;
  createdBy?: InputMaybe<User_Bool_Exp>;
  modifiedBy?: InputMaybe<User_Bool_Exp>;
  node?: InputMaybe<Node_Bool_Exp>;
  user?: InputMaybe<Auth_User_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.attestation_record" */
export const Attestation_Record_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  AttestationRecordPkey: 'attestation_record_pkey',
  /** unique or primary key constraint on columns "Active", "UserId", "NodeId" */
  IdxAttestationRecordNodeidUseridActive: 'idx_attestation_record_nodeid_userid_active'
} as const;

export type Attestation_Record_Constraint = typeof Attestation_Record_Constraint[keyof typeof Attestation_Record_Constraint];
/** input type for inserting data into table "risksmart.attestation_record" */
export type Attestation_Record_Insert_Input = {
  Active?: InputMaybe<Scalars['Boolean']['input']>;
  AttestationStatus?: InputMaybe<Attestation_Record_Status_Enum>;
  AttestedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  ConfigId?: InputMaybe<Scalars['uuid']['input']>;
  ExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  NodeId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
  config?: InputMaybe<Attestation_Config_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Attestation_Record_Max_Fields = {
  __typename?: 'attestation_record_max_fields';
  AttestedAt?: Maybe<Scalars['timestamptz']['output']>;
  ConfigId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NodeId?: Maybe<Scalars['uuid']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.attestation_record" */
export type Attestation_Record_Max_Order_By = {
  AttestedAt?: InputMaybe<Order_By>;
  ConfigId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ExpiresAt?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  NodeId?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Attestation_Record_Min_Fields = {
  __typename?: 'attestation_record_min_fields';
  AttestedAt?: Maybe<Scalars['timestamptz']['output']>;
  ConfigId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NodeId?: Maybe<Scalars['uuid']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.attestation_record" */
export type Attestation_Record_Min_Order_By = {
  AttestedAt?: InputMaybe<Order_By>;
  ConfigId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ExpiresAt?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  NodeId?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "risksmart.attestation_record" */
export type Attestation_Record_On_Conflict = {
  constraint: Attestation_Record_Constraint;
  update_columns?: Array<Attestation_Record_Update_Column>;
  where?: InputMaybe<Attestation_Record_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.attestation_record". */
export type Attestation_Record_Order_By = {
  Active?: InputMaybe<Order_By>;
  AttestationStatus?: InputMaybe<Order_By>;
  AttestedAt?: InputMaybe<Order_By>;
  ConfigId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ExpiresAt?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  NodeId?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
  config?: InputMaybe<Attestation_Config_Order_By>;
  createdBy?: InputMaybe<User_Order_By>;
  modifiedBy?: InputMaybe<User_Order_By>;
  node?: InputMaybe<Node_Order_By>;
  user?: InputMaybe<Auth_User_Order_By>;
};

/** select columns of table "risksmart.attestation_record" */
export const Attestation_Record_Select_Column = {
  /** column name */
  Active: 'Active',
  /** column name */
  AttestationStatus: 'AttestationStatus',
  /** column name */
  AttestedAt: 'AttestedAt',
  /** column name */
  ConfigId: 'ConfigId',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  ExpiresAt: 'ExpiresAt',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  NodeId: 'NodeId',
  /** column name */
  OrgKey: 'OrgKey',
  /** column name */
  UserId: 'UserId'
} as const;

export type Attestation_Record_Select_Column = typeof Attestation_Record_Select_Column[keyof typeof Attestation_Record_Select_Column];
/** select "attestation_record_aggregate_bool_exp_bool_and_arguments_columns" columns of table "risksmart.attestation_record" */
export const Attestation_Record_Select_Column_Attestation_Record_Aggregate_Bool_Exp_Bool_And_Arguments_Columns = {
  /** column name */
  Active: 'Active'
} as const;

export type Attestation_Record_Select_Column_Attestation_Record_Aggregate_Bool_Exp_Bool_And_Arguments_Columns = typeof Attestation_Record_Select_Column_Attestation_Record_Aggregate_Bool_Exp_Bool_And_Arguments_Columns[keyof typeof Attestation_Record_Select_Column_Attestation_Record_Aggregate_Bool_Exp_Bool_And_Arguments_Columns];
/** select "attestation_record_aggregate_bool_exp_bool_or_arguments_columns" columns of table "risksmart.attestation_record" */
export const Attestation_Record_Select_Column_Attestation_Record_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns = {
  /** column name */
  Active: 'Active'
} as const;

export type Attestation_Record_Select_Column_Attestation_Record_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns = typeof Attestation_Record_Select_Column_Attestation_Record_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns[keyof typeof Attestation_Record_Select_Column_Attestation_Record_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns];
export const Attestation_Record_Status_Enum = {
  /** User has attested */
  Attested: 'attested',
  /** The due date has expired */
  Expired: 'expired',
  /** Attestation is cancelled */
  NotRequired: 'not_required',
  /** Waiting for attestation */
  Pending: 'pending'
} as const;

export type Attestation_Record_Status_Enum = typeof Attestation_Record_Status_Enum[keyof typeof Attestation_Record_Status_Enum];
/** Boolean expression to compare columns of type "attestation_record_status_enum". All fields are combined with logical 'AND'. */
export type Attestation_Record_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Attestation_Record_Status_Enum>;
  _in?: InputMaybe<Array<Attestation_Record_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Attestation_Record_Status_Enum>;
  _nin?: InputMaybe<Array<Attestation_Record_Status_Enum>>;
};

/** Streaming cursor of the table "attestation_record" */
export type Attestation_Record_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Attestation_Record_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attestation_Record_Stream_Cursor_Value_Input = {
  Active?: InputMaybe<Scalars['Boolean']['input']>;
  AttestationStatus?: InputMaybe<Attestation_Record_Status_Enum>;
  AttestedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  ConfigId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NodeId?: InputMaybe<Scalars['uuid']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.attestation_record" */
export const Attestation_Record_Update_Column = {
  /** column name */
  Active: 'Active',
  /** column name */
  AttestationStatus: 'AttestationStatus',
  /** column name */
  AttestedAt: 'AttestedAt',
  /** column name */
  ConfigId: 'ConfigId',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  ExpiresAt: 'ExpiresAt',
  /** column name */
  Id: 'Id',
  /** column name */
  NodeId: 'NodeId',
  /** column name */
  OrgKey: 'OrgKey',
  /** column name */
  UserId: 'UserId'
} as const;

export type Attestation_Record_Update_Column = typeof Attestation_Record_Update_Column[keyof typeof Attestation_Record_Update_Column];
/** columns and relationships of "risksmart.audit_log_view" */
export type Audit_Log_View = {
  __typename?: 'audit_log_view';
  Action?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  Item?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ObjectType?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  PerformedByUser?: Maybe<User>;
};

/** aggregated selection of "risksmart.audit_log_view" */
export type Audit_Log_View_Aggregate = {
  __typename?: 'audit_log_view_aggregate';
  aggregate?: Maybe<Audit_Log_View_Aggregate_Fields>;
  nodes: Array<Audit_Log_View>;
};

/** aggregate fields of "risksmart.audit_log_view" */
export type Audit_Log_View_Aggregate_Fields = {
  __typename?: 'audit_log_view_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Audit_Log_View_Max_Fields>;
  min?: Maybe<Audit_Log_View_Min_Fields>;
};


/** aggregate fields of "risksmart.audit_log_view" */
export type Audit_Log_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Audit_Log_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.audit_log_view". All fields are combined with a logical 'AND'. */
export type Audit_Log_View_Bool_Exp = {
  Action?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<String_Comparison_Exp>;
  Item?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ObjectType?: InputMaybe<String_Comparison_Exp>;
  OrgKey?: InputMaybe<String_Comparison_Exp>;
  PerformedByUser?: InputMaybe<User_Bool_Exp>;
  _and?: InputMaybe<Array<Audit_Log_View_Bool_Exp>>;
  _not?: InputMaybe<Audit_Log_View_Bool_Exp>;
  _or?: InputMaybe<Array<Audit_Log_View_Bool_Exp>>;
};

/** aggregate max on columns */
export type Audit_Log_View_Max_Fields = {
  __typename?: 'audit_log_view_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  Item?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ObjectType?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Audit_Log_View_Min_Fields = {
  __typename?: 'audit_log_view_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  Item?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ObjectType?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "risksmart.audit_log_view". */
export type Audit_Log_View_Order_By = {
  Action?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Item?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ObjectType?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  PerformedByUser?: InputMaybe<User_Order_By>;
};

/** select columns of table "risksmart.audit_log_view" */
export const Audit_Log_View_Select_Column = {
  /** column name */
  Action: 'Action',
  /** column name */
  Id: 'Id',
  /** column name */
  Item: 'Item',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ObjectType: 'ObjectType',
  /** column name */
  OrgKey: 'OrgKey'
} as const;

export type Audit_Log_View_Select_Column = typeof Audit_Log_View_Select_Column[keyof typeof Audit_Log_View_Select_Column];
/** Streaming cursor of the table "audit_log_view" */
export type Audit_Log_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audit_Log_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audit_Log_View_Stream_Cursor_Value_Input = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['String']['input']>;
  Item?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ObjectType?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "auth.organisation" */
export type Auth_Organisation = {
  __typename?: 'auth_organisation';
  Meta?: Maybe<Scalars['json']['output']>;
  Name: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  /** An array relationship */
  organisationusers: Array<Auth_Organisationuser>;
};


/** columns and relationships of "auth.organisation" */
export type Auth_OrganisationMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "auth.organisation" */
export type Auth_OrganisationOrganisationusersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Organisationuser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Organisationuser_Order_By>>;
  where?: InputMaybe<Auth_Organisationuser_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "auth.organisation". All fields are combined with a logical 'AND'. */
export type Auth_Organisation_Bool_Exp = {
  Meta?: InputMaybe<Json_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  OrgKey?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Auth_Organisation_Bool_Exp>>;
  _not?: InputMaybe<Auth_Organisation_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Organisation_Bool_Exp>>;
  organisationusers?: InputMaybe<Auth_Organisationuser_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.organisation". */
export type Auth_Organisation_Order_By = {
  Meta?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  organisationusers_aggregate?: InputMaybe<Auth_Organisationuser_Aggregate_Order_By>;
};

/** select columns of table "auth.organisation" */
export const Auth_Organisation_Select_Column = {
  /** column name */
  Meta: 'Meta',
  /** column name */
  Name: 'Name',
  /** column name */
  OrgKey: 'OrgKey'
} as const;

export type Auth_Organisation_Select_Column = typeof Auth_Organisation_Select_Column[keyof typeof Auth_Organisation_Select_Column];
/** Streaming cursor of the table "auth_organisation" */
export type Auth_Organisation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Organisation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Organisation_Stream_Cursor_Value_Input = {
  Meta?: InputMaybe<Scalars['json']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "auth.organisationuser" */
export type Auth_Organisationuser = {
  __typename?: 'auth_organisationuser';
  AuthConnection?: Maybe<Scalars['String']['output']>;
  External_Id?: Maybe<Scalars['String']['output']>;
  LastSeen?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey: Scalars['String']['output'];
  RoleKey?: Maybe<Scalars['String']['output']>;
  User_Id: Scalars['String']['output'];
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  user: Auth_User;
};

/** order by aggregate values of table "auth.organisationuser" */
export type Auth_Organisationuser_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Organisationuser_Max_Order_By>;
  min?: InputMaybe<Auth_Organisationuser_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "auth.organisationuser". All fields are combined with a logical 'AND'. */
export type Auth_Organisationuser_Bool_Exp = {
  AuthConnection?: InputMaybe<String_Comparison_Exp>;
  External_Id?: InputMaybe<String_Comparison_Exp>;
  LastSeen?: InputMaybe<Timestamptz_Comparison_Exp>;
  OrgKey?: InputMaybe<String_Comparison_Exp>;
  RoleKey?: InputMaybe<String_Comparison_Exp>;
  User_Id?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Auth_Organisationuser_Bool_Exp>>;
  _not?: InputMaybe<Auth_Organisationuser_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Organisationuser_Bool_Exp>>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  user?: InputMaybe<Auth_User_Bool_Exp>;
};

/** order by max() on columns of table "auth.organisationuser" */
export type Auth_Organisationuser_Max_Order_By = {
  AuthConnection?: InputMaybe<Order_By>;
  External_Id?: InputMaybe<Order_By>;
  LastSeen?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  RoleKey?: InputMaybe<Order_By>;
  User_Id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "auth.organisationuser" */
export type Auth_Organisationuser_Min_Order_By = {
  AuthConnection?: InputMaybe<Order_By>;
  External_Id?: InputMaybe<Order_By>;
  LastSeen?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  RoleKey?: InputMaybe<Order_By>;
  User_Id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.organisationuser" */
export type Auth_Organisationuser_Mutation_Response = {
  __typename?: 'auth_organisationuser_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Organisationuser>;
};

/** Ordering options when selecting data from "auth.organisationuser". */
export type Auth_Organisationuser_Order_By = {
  AuthConnection?: InputMaybe<Order_By>;
  External_Id?: InputMaybe<Order_By>;
  LastSeen?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  RoleKey?: InputMaybe<Order_By>;
  User_Id?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  user?: InputMaybe<Auth_User_Order_By>;
};

/** primary key columns input for table: auth.organisationuser */
export type Auth_Organisationuser_Pk_Columns_Input = {
  OrgKey: Scalars['String']['input'];
  User_Id: Scalars['String']['input'];
};

/** select columns of table "auth.organisationuser" */
export const Auth_Organisationuser_Select_Column = {
  /** column name */
  AuthConnection: 'AuthConnection',
  /** column name */
  ExternalId: 'External_Id',
  /** column name */
  LastSeen: 'LastSeen',
  /** column name */
  OrgKey: 'OrgKey',
  /** column name */
  RoleKey: 'RoleKey',
  /** column name */
  UserId: 'User_Id'
} as const;

export type Auth_Organisationuser_Select_Column = typeof Auth_Organisationuser_Select_Column[keyof typeof Auth_Organisationuser_Select_Column];
/** input type for updating data in table "auth.organisationuser" */
export type Auth_Organisationuser_Set_Input = {
  RoleKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "auth_organisationuser" */
export type Auth_Organisationuser_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Organisationuser_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Organisationuser_Stream_Cursor_Value_Input = {
  AuthConnection?: InputMaybe<Scalars['String']['input']>;
  External_Id?: InputMaybe<Scalars['String']['input']>;
  LastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RoleKey?: InputMaybe<Scalars['String']['input']>;
  User_Id?: InputMaybe<Scalars['String']['input']>;
};

export type Auth_Organisationuser_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Organisationuser_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Organisationuser_Bool_Exp;
};

/** columns and relationships of "auth.user" */
export type Auth_User = {
  __typename?: 'auth_user';
  AuthConnection?: Maybe<Scalars['String']['output']>;
  AuthUser_Id?: Maybe<Scalars['String']['output']>;
  BusinessUnit_Id?: Maybe<Scalars['uuid']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CreatedOn?: Maybe<Scalars['timestamptz']['output']>;
  Department?: Maybe<Scalars['String']['output']>;
  DisplayName?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FriendlyName?: Maybe<Scalars['String']['output']>;
  Id: Scalars['String']['output'];
  IsCustomerSupport?: Maybe<Scalars['Boolean']['output']>;
  JobTitle?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LastSeen?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OfficeLocation?: Maybe<Scalars['String']['output']>;
  RoleKey?: Maybe<Scalars['String']['output']>;
  Status: User_Status_Enum;
  UserName?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  organisationusers: Array<Auth_Organisationuser>;
  /** An array relationship */
  userGroupUsers: Array<User_Group_User>;
  /** An aggregate relationship */
  userGroupUsers_aggregate: User_Group_User_Aggregate;
};


/** columns and relationships of "auth.user" */
export type Auth_UserOrganisationusersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Organisationuser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Organisationuser_Order_By>>;
  where?: InputMaybe<Auth_Organisationuser_Bool_Exp>;
};


/** columns and relationships of "auth.user" */
export type Auth_UserUserGroupUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Group_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Group_User_Order_By>>;
  where?: InputMaybe<User_Group_User_Bool_Exp>;
};


/** columns and relationships of "auth.user" */
export type Auth_UserUserGroupUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Group_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Group_User_Order_By>>;
  where?: InputMaybe<User_Group_User_Bool_Exp>;
};

/** aggregated selection of "auth.user" */
export type Auth_User_Aggregate = {
  __typename?: 'auth_user_aggregate';
  aggregate?: Maybe<Auth_User_Aggregate_Fields>;
  nodes: Array<Auth_User>;
};

/** aggregate fields of "auth.user" */
export type Auth_User_Aggregate_Fields = {
  __typename?: 'auth_user_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Auth_User_Max_Fields>;
  min?: Maybe<Auth_User_Min_Fields>;
};


/** aggregate fields of "auth.user" */
export type Auth_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.user". All fields are combined with a logical 'AND'. */
export type Auth_User_Bool_Exp = {
  AuthConnection?: InputMaybe<String_Comparison_Exp>;
  AuthUser_Id?: InputMaybe<String_Comparison_Exp>;
  BusinessUnit_Id?: InputMaybe<Uuid_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CreatedOn?: InputMaybe<Timestamptz_Comparison_Exp>;
  Department?: InputMaybe<String_Comparison_Exp>;
  DisplayName?: InputMaybe<String_Comparison_Exp>;
  Email?: InputMaybe<String_Comparison_Exp>;
  FirstName?: InputMaybe<String_Comparison_Exp>;
  FriendlyName?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<String_Comparison_Exp>;
  IsCustomerSupport?: InputMaybe<Boolean_Comparison_Exp>;
  JobTitle?: InputMaybe<String_Comparison_Exp>;
  LastName?: InputMaybe<String_Comparison_Exp>;
  LastSeen?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  OfficeLocation?: InputMaybe<String_Comparison_Exp>;
  RoleKey?: InputMaybe<String_Comparison_Exp>;
  Status?: InputMaybe<User_Status_Enum_Comparison_Exp>;
  UserName?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Auth_User_Bool_Exp>>;
  _not?: InputMaybe<Auth_User_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_User_Bool_Exp>>;
  organisationusers?: InputMaybe<Auth_Organisationuser_Bool_Exp>;
  userGroupUsers?: InputMaybe<User_Group_User_Bool_Exp>;
  userGroupUsers_aggregate?: InputMaybe<User_Group_User_Aggregate_Bool_Exp>;
};

/** aggregate max on columns */
export type Auth_User_Max_Fields = {
  __typename?: 'auth_user_max_fields';
  AuthConnection?: Maybe<Scalars['String']['output']>;
  AuthUser_Id?: Maybe<Scalars['String']['output']>;
  BusinessUnit_Id?: Maybe<Scalars['uuid']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CreatedOn?: Maybe<Scalars['timestamptz']['output']>;
  Department?: Maybe<Scalars['String']['output']>;
  DisplayName?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FriendlyName?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  JobTitle?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LastSeen?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OfficeLocation?: Maybe<Scalars['String']['output']>;
  RoleKey?: Maybe<Scalars['String']['output']>;
  UserName?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Auth_User_Min_Fields = {
  __typename?: 'auth_user_min_fields';
  AuthConnection?: Maybe<Scalars['String']['output']>;
  AuthUser_Id?: Maybe<Scalars['String']['output']>;
  BusinessUnit_Id?: Maybe<Scalars['uuid']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CreatedOn?: Maybe<Scalars['timestamptz']['output']>;
  Department?: Maybe<Scalars['String']['output']>;
  DisplayName?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FriendlyName?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  JobTitle?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LastSeen?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OfficeLocation?: Maybe<Scalars['String']['output']>;
  RoleKey?: Maybe<Scalars['String']['output']>;
  UserName?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.user" */
export type Auth_User_Mutation_Response = {
  __typename?: 'auth_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_User>;
};

/** Ordering options when selecting data from "auth.user". */
export type Auth_User_Order_By = {
  AuthConnection?: InputMaybe<Order_By>;
  AuthUser_Id?: InputMaybe<Order_By>;
  BusinessUnit_Id?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CreatedOn?: InputMaybe<Order_By>;
  Department?: InputMaybe<Order_By>;
  DisplayName?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  FirstName?: InputMaybe<Order_By>;
  FriendlyName?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  IsCustomerSupport?: InputMaybe<Order_By>;
  JobTitle?: InputMaybe<Order_By>;
  LastName?: InputMaybe<Order_By>;
  LastSeen?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OfficeLocation?: InputMaybe<Order_By>;
  RoleKey?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  UserName?: InputMaybe<Order_By>;
  organisationusers_aggregate?: InputMaybe<Auth_Organisationuser_Aggregate_Order_By>;
  userGroupUsers_aggregate?: InputMaybe<User_Group_User_Aggregate_Order_By>;
};

/** primary key columns input for table: auth.user */
export type Auth_User_Pk_Columns_Input = {
  Id: Scalars['String']['input'];
};

/** select columns of table "auth.user" */
export const Auth_User_Select_Column = {
  /** column name */
  AuthConnection: 'AuthConnection',
  /** column name */
  AuthUserId: 'AuthUser_Id',
  /** column name */
  BusinessUnitId: 'BusinessUnit_Id',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CreatedOn: 'CreatedOn',
  /** column name */
  Department: 'Department',
  /** column name */
  DisplayName: 'DisplayName',
  /** column name */
  Email: 'Email',
  /** column name */
  FirstName: 'FirstName',
  /** column name */
  FriendlyName: 'FriendlyName',
  /** column name */
  Id: 'Id',
  /** column name */
  IsCustomerSupport: 'IsCustomerSupport',
  /** column name */
  JobTitle: 'JobTitle',
  /** column name */
  LastName: 'LastName',
  /** column name */
  LastSeen: 'LastSeen',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  OfficeLocation: 'OfficeLocation',
  /** column name */
  RoleKey: 'RoleKey',
  /** column name */
  Status: 'Status',
  /** column name */
  UserName: 'UserName'
} as const;

export type Auth_User_Select_Column = typeof Auth_User_Select_Column[keyof typeof Auth_User_Select_Column];
/** input type for updating data in table "auth.user" */
export type Auth_User_Set_Input = {
  RoleKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "auth_user" */
export type Auth_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_User_Stream_Cursor_Value_Input = {
  AuthConnection?: InputMaybe<Scalars['String']['input']>;
  AuthUser_Id?: InputMaybe<Scalars['String']['input']>;
  BusinessUnit_Id?: InputMaybe<Scalars['uuid']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  Department?: InputMaybe<Scalars['String']['input']>;
  DisplayName?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  FriendlyName?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['String']['input']>;
  IsCustomerSupport?: InputMaybe<Scalars['Boolean']['input']>;
  JobTitle?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  LastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OfficeLocation?: InputMaybe<Scalars['String']['input']>;
  RoleKey?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<User_Status_Enum>;
  UserName?: InputMaybe<Scalars['String']['input']>;
};

export type Auth_User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_User_Bool_Exp;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** columns and relationships of "risksmart.business_area" */
export type Business_Area = {
  __typename?: 'business_area';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  SequentialId: Scalars['Int']['output'];
  Title: Scalars['String']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  internalAuditEntities: Array<Internal_Audit_Entity>;
  /** An aggregate relationship */
  internalAuditEntities_aggregate: Internal_Audit_Entity_Aggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
};


/** columns and relationships of "risksmart.business_area" */
export type Business_AreaInternalAuditEntitiesArgs = {
  distinct_on?: InputMaybe<Array<Internal_Audit_Entity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Internal_Audit_Entity_Order_By>>;
  where?: InputMaybe<Internal_Audit_Entity_Bool_Exp>;
};


/** columns and relationships of "risksmart.business_area" */
export type Business_AreaInternalAuditEntities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Internal_Audit_Entity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Internal_Audit_Entity_Order_By>>;
  where?: InputMaybe<Internal_Audit_Entity_Bool_Exp>;
};

/** aggregated selection of "risksmart.business_area" */
export type Business_Area_Aggregate = {
  __typename?: 'business_area_aggregate';
  aggregate?: Maybe<Business_Area_Aggregate_Fields>;
  nodes: Array<Business_Area>;
};

/** aggregate fields of "risksmart.business_area" */
export type Business_Area_Aggregate_Fields = {
  __typename?: 'business_area_aggregate_fields';
  avg?: Maybe<Business_Area_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Business_Area_Max_Fields>;
  min?: Maybe<Business_Area_Min_Fields>;
  stddev?: Maybe<Business_Area_Stddev_Fields>;
  stddev_pop?: Maybe<Business_Area_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Business_Area_Stddev_Samp_Fields>;
  sum?: Maybe<Business_Area_Sum_Fields>;
  var_pop?: Maybe<Business_Area_Var_Pop_Fields>;
  var_samp?: Maybe<Business_Area_Var_Samp_Fields>;
  variance?: Maybe<Business_Area_Variance_Fields>;
};


/** aggregate fields of "risksmart.business_area" */
export type Business_Area_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Business_Area_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Business_Area_Avg_Fields = {
  __typename?: 'business_area_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.business_area". All fields are combined with a logical 'AND'. */
export type Business_Area_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Business_Area_Bool_Exp>>;
  _not?: InputMaybe<Business_Area_Bool_Exp>;
  _or?: InputMaybe<Array<Business_Area_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  internalAuditEntities?: InputMaybe<Internal_Audit_Entity_Bool_Exp>;
  internalAuditEntities_aggregate?: InputMaybe<Internal_Audit_Entity_Aggregate_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.business_area" */
export const Business_Area_Constraint = {
  /** unique or primary key constraint on columns "OrgKey", "Title" */
  BusinessAreaOrgkeyTitle: 'business_area_orgkey_title',
  /** unique or primary key constraint on columns "Id" */
  BusinessAreaPkey: 'business_area_pkey',
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxBusinessAreaOrgkeySequentialid: 'idx_business_area_orgkey_sequentialid'
} as const;

export type Business_Area_Constraint = typeof Business_Area_Constraint[keyof typeof Business_Area_Constraint];
/** input type for inserting data into table "risksmart.business_area" */
export type Business_Area_Insert_Input = {
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  internalAuditEntities?: InputMaybe<Internal_Audit_Entity_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Business_Area_Max_Fields = {
  __typename?: 'business_area_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Business_Area_Min_Fields = {
  __typename?: 'business_area_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.business_area" */
export type Business_Area_Mutation_Response = {
  __typename?: 'business_area_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Business_Area>;
};

/** input type for inserting object relation for remote table "risksmart.business_area" */
export type Business_Area_Obj_Rel_Insert_Input = {
  data: Business_Area_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Business_Area_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.business_area" */
export type Business_Area_On_Conflict = {
  constraint: Business_Area_Constraint;
  update_columns?: Array<Business_Area_Update_Column>;
  where?: InputMaybe<Business_Area_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.business_area". */
export type Business_Area_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  internalAuditEntities_aggregate?: InputMaybe<Internal_Audit_Entity_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
};

/** primary key columns input for table: risksmart.business_area */
export type Business_Area_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.business_area" */
export const Business_Area_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Title: 'Title'
} as const;

export type Business_Area_Select_Column = typeof Business_Area_Select_Column[keyof typeof Business_Area_Select_Column];
/** input type for updating data in table "risksmart.business_area" */
export type Business_Area_Set_Input = {
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Business_Area_Stddev_Fields = {
  __typename?: 'business_area_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Business_Area_Stddev_Pop_Fields = {
  __typename?: 'business_area_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Business_Area_Stddev_Samp_Fields = {
  __typename?: 'business_area_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "business_area" */
export type Business_Area_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Business_Area_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Business_Area_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Business_Area_Sum_Fields = {
  __typename?: 'business_area_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.business_area" */
export const Business_Area_Update_Column = {
  /** column name */
  Title: 'Title'
} as const;

export type Business_Area_Update_Column = typeof Business_Area_Update_Column[keyof typeof Business_Area_Update_Column];
export type Business_Area_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Business_Area_Set_Input>;
  /** filter the rows which have to be updated */
  where: Business_Area_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Business_Area_Var_Pop_Fields = {
  __typename?: 'business_area_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Business_Area_Var_Samp_Fields = {
  __typename?: 'business_area_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Business_Area_Variance_Fields = {
  __typename?: 'business_area_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.cause" */
export type Cause = {
  __typename?: 'cause';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentIssueId: Scalars['uuid']['output'];
  Significance?: Maybe<Scalars['Int']['output']>;
  Title: Scalars['String']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  issue?: Maybe<Issue>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
};


/** columns and relationships of "risksmart.cause" */
export type CauseCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.cause" */
export type Cause_Aggregate = {
  __typename?: 'cause_aggregate';
  aggregate?: Maybe<Cause_Aggregate_Fields>;
  nodes: Array<Cause>;
};

export type Cause_Aggregate_Bool_Exp = {
  count?: InputMaybe<Cause_Aggregate_Bool_Exp_Count>;
};

export type Cause_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Cause_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Cause_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.cause" */
export type Cause_Aggregate_Fields = {
  __typename?: 'cause_aggregate_fields';
  avg?: Maybe<Cause_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Cause_Max_Fields>;
  min?: Maybe<Cause_Min_Fields>;
  stddev?: Maybe<Cause_Stddev_Fields>;
  stddev_pop?: Maybe<Cause_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cause_Stddev_Samp_Fields>;
  sum?: Maybe<Cause_Sum_Fields>;
  var_pop?: Maybe<Cause_Var_Pop_Fields>;
  var_samp?: Maybe<Cause_Var_Samp_Fields>;
  variance?: Maybe<Cause_Variance_Fields>;
};


/** aggregate fields of "risksmart.cause" */
export type Cause_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cause_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.cause" */
export type Cause_Aggregate_Order_By = {
  avg?: InputMaybe<Cause_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Cause_Max_Order_By>;
  min?: InputMaybe<Cause_Min_Order_By>;
  stddev?: InputMaybe<Cause_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Cause_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Cause_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Cause_Sum_Order_By>;
  var_pop?: InputMaybe<Cause_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Cause_Var_Samp_Order_By>;
  variance?: InputMaybe<Cause_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Cause_Append_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.cause" */
export type Cause_Arr_Rel_Insert_Input = {
  data: Array<Cause_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Cause_On_Conflict>;
};

/** columns and relationships of "risksmart.cause_audit" */
export type Cause_Audit = {
  __typename?: 'cause_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentIssueId: Scalars['uuid']['output'];
  Significance?: Maybe<Scalars['Int']['output']>;
  Title: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.cause_audit" */
export type Cause_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.cause_audit". All fields are combined with a logical 'AND'. */
export type Cause_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentIssueId?: InputMaybe<Uuid_Comparison_Exp>;
  Significance?: InputMaybe<Int_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Cause_Audit_Bool_Exp>>;
  _not?: InputMaybe<Cause_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Cause_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.cause_audit". */
export type Cause_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentIssueId?: InputMaybe<Order_By>;
  Significance?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.cause_audit" */
export const Cause_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentIssueId: 'ParentIssueId',
  /** column name */
  Significance: 'Significance',
  /** column name */
  Title: 'Title'
} as const;

export type Cause_Audit_Select_Column = typeof Cause_Audit_Select_Column[keyof typeof Cause_Audit_Select_Column];
/** Streaming cursor of the table "cause_audit" */
export type Cause_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cause_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cause_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Significance?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate avg on columns */
export type Cause_Avg_Fields = {
  __typename?: 'cause_avg_fields';
  Significance?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.cause" */
export type Cause_Avg_Order_By = {
  Significance?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.cause". All fields are combined with a logical 'AND'. */
export type Cause_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentIssueId?: InputMaybe<Uuid_Comparison_Exp>;
  Significance?: InputMaybe<Int_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Cause_Bool_Exp>>;
  _not?: InputMaybe<Cause_Bool_Exp>;
  _or?: InputMaybe<Array<Cause_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  issue?: InputMaybe<Issue_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.cause" */
export const Cause_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  CausePkey: 'cause_pkey'
} as const;

export type Cause_Constraint = typeof Cause_Constraint[keyof typeof Cause_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Cause_Delete_At_Path_Input = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Cause_Delete_Elem_Input = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Cause_Delete_Key_Input = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.cause" */
export type Cause_Inc_Input = {
  Significance?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.cause" */
export type Cause_Insert_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Significance?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  issue?: InputMaybe<Issue_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Cause_Max_Fields = {
  __typename?: 'cause_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  Significance?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.cause" */
export type Cause_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentIssueId?: InputMaybe<Order_By>;
  Significance?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Cause_Min_Fields = {
  __typename?: 'cause_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  Significance?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.cause" */
export type Cause_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentIssueId?: InputMaybe<Order_By>;
  Significance?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.cause" */
export type Cause_Mutation_Response = {
  __typename?: 'cause_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Cause>;
};

/** on_conflict condition type for table "risksmart.cause" */
export type Cause_On_Conflict = {
  constraint: Cause_Constraint;
  update_columns?: Array<Cause_Update_Column>;
  where?: InputMaybe<Cause_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.cause". */
export type Cause_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentIssueId?: InputMaybe<Order_By>;
  Significance?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  issue?: InputMaybe<Issue_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
};

/** primary key columns input for table: risksmart.cause */
export type Cause_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Cause_Prepend_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.cause" */
export const Cause_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentIssueId: 'ParentIssueId',
  /** column name */
  Significance: 'Significance',
  /** column name */
  Title: 'Title'
} as const;

export type Cause_Select_Column = typeof Cause_Select_Column[keyof typeof Cause_Select_Column];
/** input type for updating data in table "risksmart.cause" */
export type Cause_Set_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Significance?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Cause_Stddev_Fields = {
  __typename?: 'cause_stddev_fields';
  Significance?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.cause" */
export type Cause_Stddev_Order_By = {
  Significance?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cause_Stddev_Pop_Fields = {
  __typename?: 'cause_stddev_pop_fields';
  Significance?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.cause" */
export type Cause_Stddev_Pop_Order_By = {
  Significance?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cause_Stddev_Samp_Fields = {
  __typename?: 'cause_stddev_samp_fields';
  Significance?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.cause" */
export type Cause_Stddev_Samp_Order_By = {
  Significance?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "cause" */
export type Cause_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cause_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cause_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Significance?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Cause_Sum_Fields = {
  __typename?: 'cause_sum_fields';
  Significance?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.cause" */
export type Cause_Sum_Order_By = {
  Significance?: InputMaybe<Order_By>;
};

/** update columns of table "risksmart.cause" */
export const Cause_Update_Column = {
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  ParentIssueId: 'ParentIssueId',
  /** column name */
  Significance: 'Significance',
  /** column name */
  Title: 'Title'
} as const;

export type Cause_Update_Column = typeof Cause_Update_Column[keyof typeof Cause_Update_Column];
export type Cause_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Cause_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Cause_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Cause_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Cause_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Cause_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Cause_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cause_Set_Input>;
  /** filter the rows which have to be updated */
  where: Cause_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Cause_Var_Pop_Fields = {
  __typename?: 'cause_var_pop_fields';
  Significance?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.cause" */
export type Cause_Var_Pop_Order_By = {
  Significance?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cause_Var_Samp_Fields = {
  __typename?: 'cause_var_samp_fields';
  Significance?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.cause" */
export type Cause_Var_Samp_Order_By = {
  Significance?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Cause_Variance_Fields = {
  __typename?: 'cause_variance_fields';
  Significance?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.cause" */
export type Cause_Variance_Order_By = {
  Significance?: InputMaybe<Order_By>;
};

/** columns and relationships of "risksmart.change_request" */
export type Change_Request = {
  __typename?: 'change_request';
  ActionUserId: Scalars['String']['output'];
  ChangeRequestStatus: Approval_Status_Enum;
  Comment: Scalars['String']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  OverriddenAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OverriddenByUser?: Maybe<Scalars['String']['output']>;
  ParentId: Scalars['uuid']['output'];
  RequestedChanges?: Maybe<Scalars['jsonb']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Type: Scalars['String']['output'];
  /** An array relationship */
  contributors: Array<Change_Request_Contributor>;
  /** An object relationship */
  createdBy?: Maybe<User>;
  /** An object relationship */
  modifiedBy?: Maybe<User>;
  /** An object relationship */
  overriddenBy?: Maybe<User>;
  /** An object relationship */
  parent?: Maybe<Node>;
  /** An array relationship */
  parentOwnerAndContributors: Array<Ancestor_Contributor>;
  /** An array relationship */
  responses: Array<Approver_Response>;
};


/** columns and relationships of "risksmart.change_request" */
export type Change_RequestRequestedChangesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.change_request" */
export type Change_RequestContributorsArgs = {
  distinct_on?: InputMaybe<Array<Change_Request_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Change_Request_Contributor_Order_By>>;
  where?: InputMaybe<Change_Request_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.change_request" */
export type Change_RequestParentOwnerAndContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.change_request" */
export type Change_RequestResponsesArgs = {
  distinct_on?: InputMaybe<Array<Approver_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Approver_Response_Order_By>>;
  where?: InputMaybe<Approver_Response_Bool_Exp>;
};

/** aggregated selection of "risksmart.change_request" */
export type Change_Request_Aggregate = {
  __typename?: 'change_request_aggregate';
  aggregate?: Maybe<Change_Request_Aggregate_Fields>;
  nodes: Array<Change_Request>;
};

export type Change_Request_Aggregate_Bool_Exp = {
  count?: InputMaybe<Change_Request_Aggregate_Bool_Exp_Count>;
};

export type Change_Request_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Change_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Change_Request_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.change_request" */
export type Change_Request_Aggregate_Fields = {
  __typename?: 'change_request_aggregate_fields';
  avg?: Maybe<Change_Request_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Change_Request_Max_Fields>;
  min?: Maybe<Change_Request_Min_Fields>;
  stddev?: Maybe<Change_Request_Stddev_Fields>;
  stddev_pop?: Maybe<Change_Request_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Change_Request_Stddev_Samp_Fields>;
  sum?: Maybe<Change_Request_Sum_Fields>;
  var_pop?: Maybe<Change_Request_Var_Pop_Fields>;
  var_samp?: Maybe<Change_Request_Var_Samp_Fields>;
  variance?: Maybe<Change_Request_Variance_Fields>;
};


/** aggregate fields of "risksmart.change_request" */
export type Change_Request_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Change_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.change_request" */
export type Change_Request_Aggregate_Order_By = {
  avg?: InputMaybe<Change_Request_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Change_Request_Max_Order_By>;
  min?: InputMaybe<Change_Request_Min_Order_By>;
  stddev?: InputMaybe<Change_Request_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Change_Request_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Change_Request_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Change_Request_Sum_Order_By>;
  var_pop?: InputMaybe<Change_Request_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Change_Request_Var_Samp_Order_By>;
  variance?: InputMaybe<Change_Request_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.change_request" */
export type Change_Request_Arr_Rel_Insert_Input = {
  data: Array<Change_Request_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Change_Request_On_Conflict>;
};

/** columns and relationships of "risksmart.change_request_audit" */
export type Change_Request_Audit = {
  __typename?: 'change_request_audit';
  ChangeRequestStatus: Scalars['String']['output'];
  Comment: Scalars['String']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OverriddenAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OverriddenByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  RequestedChanges?: Maybe<Scalars['jsonb']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Type: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.change_request_audit" */
export type Change_Request_AuditRequestedChangesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.change_request_audit". All fields are combined with a logical 'AND'. */
export type Change_Request_Audit_Bool_Exp = {
  ChangeRequestStatus?: InputMaybe<String_Comparison_Exp>;
  Comment?: InputMaybe<String_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  OverriddenAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  OverriddenByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  RequestedChanges?: InputMaybe<Jsonb_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Type?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Change_Request_Audit_Bool_Exp>>;
  _not?: InputMaybe<Change_Request_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Change_Request_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.change_request_audit". */
export type Change_Request_Audit_Order_By = {
  ChangeRequestStatus?: InputMaybe<Order_By>;
  Comment?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OverriddenAtTimestamp?: InputMaybe<Order_By>;
  OverriddenByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  RequestedChanges?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.change_request_audit" */
export const Change_Request_Audit_Select_Column = {
  /** column name */
  ChangeRequestStatus: 'ChangeRequestStatus',
  /** column name */
  Comment: 'Comment',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  OverriddenAtTimestamp: 'OverriddenAtTimestamp',
  /** column name */
  OverriddenByUser: 'OverriddenByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  RequestedChanges: 'RequestedChanges',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Type: 'Type'
} as const;

export type Change_Request_Audit_Select_Column = typeof Change_Request_Audit_Select_Column[keyof typeof Change_Request_Audit_Select_Column];
/** Streaming cursor of the table "change_request_audit" */
export type Change_Request_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Change_Request_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Change_Request_Audit_Stream_Cursor_Value_Input = {
  ChangeRequestStatus?: InputMaybe<Scalars['String']['input']>;
  Comment?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OverriddenAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OverriddenByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  RequestedChanges?: InputMaybe<Scalars['jsonb']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate avg on columns */
export type Change_Request_Avg_Fields = {
  __typename?: 'change_request_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.change_request" */
export type Change_Request_Avg_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.change_request". All fields are combined with a logical 'AND'. */
export type Change_Request_Bool_Exp = {
  ActionUserId?: InputMaybe<String_Comparison_Exp>;
  ChangeRequestStatus?: InputMaybe<Approval_Status_Enum_Comparison_Exp>;
  Comment?: InputMaybe<String_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  OrgKey?: InputMaybe<String_Comparison_Exp>;
  OverriddenAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  OverriddenByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  RequestedChanges?: InputMaybe<Jsonb_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Type?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Change_Request_Bool_Exp>>;
  _not?: InputMaybe<Change_Request_Bool_Exp>;
  _or?: InputMaybe<Array<Change_Request_Bool_Exp>>;
  contributors?: InputMaybe<Change_Request_Contributor_Bool_Exp>;
  createdBy?: InputMaybe<User_Bool_Exp>;
  modifiedBy?: InputMaybe<User_Bool_Exp>;
  overriddenBy?: InputMaybe<User_Bool_Exp>;
  parent?: InputMaybe<Node_Bool_Exp>;
  parentOwnerAndContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  responses?: InputMaybe<Approver_Response_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.change_request" */
export const Change_Request_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  ChangeRequestPkey: 'change_request_pkey',
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxChangerequestOrgkeySequentialid: 'idx_changerequest_orgkey_sequentialid'
} as const;

export type Change_Request_Constraint = typeof Change_Request_Constraint[keyof typeof Change_Request_Constraint];
/** columns and relationships of "risksmart.change_request_contributor" */
export type Change_Request_Contributor = {
  __typename?: 'change_request_contributor';
  Id: Scalars['uuid']['output'];
  UserId: Scalars['String']['output'];
  /** An object relationship */
  changeRequest: Change_Request;
  /** An object relationship */
  user?: Maybe<User>;
};

/** order by aggregate values of table "risksmart.change_request_contributor" */
export type Change_Request_Contributor_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Change_Request_Contributor_Max_Order_By>;
  min?: InputMaybe<Change_Request_Contributor_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.change_request_contributor" */
export type Change_Request_Contributor_Arr_Rel_Insert_Input = {
  data: Array<Change_Request_Contributor_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Change_Request_Contributor_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risksmart.change_request_contributor". All fields are combined with a logical 'AND'. */
export type Change_Request_Contributor_Bool_Exp = {
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  UserId?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Change_Request_Contributor_Bool_Exp>>;
  _not?: InputMaybe<Change_Request_Contributor_Bool_Exp>;
  _or?: InputMaybe<Array<Change_Request_Contributor_Bool_Exp>>;
  changeRequest?: InputMaybe<Change_Request_Bool_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.change_request_contributor" */
export const Change_Request_Contributor_Constraint = {
  /** unique or primary key constraint on columns "ChangeRequestId", "UserId" */
  ChangeRequestContributorChangeRequestIdUserIdKey: 'change_request_contributor_ChangeRequestId_UserId_key',
  /** unique or primary key constraint on columns "Id" */
  ChangeRequestContributorPkey: 'change_request_contributor_pkey'
} as const;

export type Change_Request_Contributor_Constraint = typeof Change_Request_Contributor_Constraint[keyof typeof Change_Request_Contributor_Constraint];
/** input type for inserting data into table "risksmart.change_request_contributor" */
export type Change_Request_Contributor_Insert_Input = {
  ChangeRequestId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
  changeRequest?: InputMaybe<Change_Request_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "risksmart.change_request_contributor" */
export type Change_Request_Contributor_Max_Order_By = {
  Id?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.change_request_contributor" */
export type Change_Request_Contributor_Min_Order_By = {
  Id?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "risksmart.change_request_contributor" */
export type Change_Request_Contributor_On_Conflict = {
  constraint: Change_Request_Contributor_Constraint;
  update_columns?: Array<Change_Request_Contributor_Update_Column>;
  where?: InputMaybe<Change_Request_Contributor_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.change_request_contributor". */
export type Change_Request_Contributor_Order_By = {
  Id?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
  changeRequest?: InputMaybe<Change_Request_Order_By>;
  user?: InputMaybe<User_Order_By>;
};

/** select columns of table "risksmart.change_request_contributor" */
export const Change_Request_Contributor_Select_Column = {
  /** column name */
  Id: 'Id',
  /** column name */
  UserId: 'UserId'
} as const;

export type Change_Request_Contributor_Select_Column = typeof Change_Request_Contributor_Select_Column[keyof typeof Change_Request_Contributor_Select_Column];
/** Streaming cursor of the table "change_request_contributor" */
export type Change_Request_Contributor_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Change_Request_Contributor_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Change_Request_Contributor_Stream_Cursor_Value_Input = {
  Id?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** placeholder for update columns of table "risksmart.change_request_contributor" (current role has no relevant permissions) */
export const Change_Request_Contributor_Update_Column = {
  /** placeholder (do not use) */
  Placeholder: '_PLACEHOLDER'
} as const;

export type Change_Request_Contributor_Update_Column = typeof Change_Request_Contributor_Update_Column[keyof typeof Change_Request_Contributor_Update_Column];
/** input type for inserting data into table "risksmart.change_request" */
export type Change_Request_Insert_Input = {
  ActionUserId?: InputMaybe<Scalars['String']['input']>;
  ChangeRequestStatus?: InputMaybe<Approval_Status_Enum>;
  Comment?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  OverriddenAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OverriddenByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  RequestedChanges?: InputMaybe<Scalars['jsonb']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  contributors?: InputMaybe<Change_Request_Contributor_Arr_Rel_Insert_Input>;
  responses?: InputMaybe<Approver_Response_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Change_Request_Max_Fields = {
  __typename?: 'change_request_max_fields';
  ActionUserId?: Maybe<Scalars['String']['output']>;
  Comment?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OverriddenAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OverriddenByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.change_request" */
export type Change_Request_Max_Order_By = {
  ActionUserId?: InputMaybe<Order_By>;
  Comment?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  OverriddenAtTimestamp?: InputMaybe<Order_By>;
  OverriddenByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Change_Request_Min_Fields = {
  __typename?: 'change_request_min_fields';
  ActionUserId?: Maybe<Scalars['String']['output']>;
  Comment?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OverriddenAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OverriddenByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.change_request" */
export type Change_Request_Min_Order_By = {
  ActionUserId?: InputMaybe<Order_By>;
  Comment?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  OverriddenAtTimestamp?: InputMaybe<Order_By>;
  OverriddenByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "risksmart.change_request" */
export type Change_Request_Obj_Rel_Insert_Input = {
  data: Change_Request_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Change_Request_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.change_request" */
export type Change_Request_On_Conflict = {
  constraint: Change_Request_Constraint;
  update_columns?: Array<Change_Request_Update_Column>;
  where?: InputMaybe<Change_Request_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.change_request". */
export type Change_Request_Order_By = {
  ActionUserId?: InputMaybe<Order_By>;
  ChangeRequestStatus?: InputMaybe<Order_By>;
  Comment?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  OverriddenAtTimestamp?: InputMaybe<Order_By>;
  OverriddenByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  RequestedChanges?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  contributors_aggregate?: InputMaybe<Change_Request_Contributor_Aggregate_Order_By>;
  createdBy?: InputMaybe<User_Order_By>;
  modifiedBy?: InputMaybe<User_Order_By>;
  overriddenBy?: InputMaybe<User_Order_By>;
  parent?: InputMaybe<Node_Order_By>;
  parentOwnerAndContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  responses_aggregate?: InputMaybe<Approver_Response_Aggregate_Order_By>;
};

/** select columns of table "risksmart.change_request" */
export const Change_Request_Select_Column = {
  /** column name */
  ActionUserId: 'ActionUserId',
  /** column name */
  ChangeRequestStatus: 'ChangeRequestStatus',
  /** column name */
  Comment: 'Comment',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  OrgKey: 'OrgKey',
  /** column name */
  OverriddenAtTimestamp: 'OverriddenAtTimestamp',
  /** column name */
  OverriddenByUser: 'OverriddenByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  RequestedChanges: 'RequestedChanges',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Type: 'Type'
} as const;

export type Change_Request_Select_Column = typeof Change_Request_Select_Column[keyof typeof Change_Request_Select_Column];
/** aggregate stddev on columns */
export type Change_Request_Stddev_Fields = {
  __typename?: 'change_request_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.change_request" */
export type Change_Request_Stddev_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Change_Request_Stddev_Pop_Fields = {
  __typename?: 'change_request_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.change_request" */
export type Change_Request_Stddev_Pop_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Change_Request_Stddev_Samp_Fields = {
  __typename?: 'change_request_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.change_request" */
export type Change_Request_Stddev_Samp_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "change_request" */
export type Change_Request_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Change_Request_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Change_Request_Stream_Cursor_Value_Input = {
  ActionUserId?: InputMaybe<Scalars['String']['input']>;
  ChangeRequestStatus?: InputMaybe<Approval_Status_Enum>;
  Comment?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OverriddenAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OverriddenByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  RequestedChanges?: InputMaybe<Scalars['jsonb']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Change_Request_Sum_Fields = {
  __typename?: 'change_request_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.change_request" */
export type Change_Request_Sum_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** update columns of table "risksmart.change_request" */
export const Change_Request_Update_Column = {
  /** column name */
  ActionUserId: 'ActionUserId',
  /** column name */
  ChangeRequestStatus: 'ChangeRequestStatus',
  /** column name */
  Comment: 'Comment',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  OrgKey: 'OrgKey',
  /** column name */
  OverriddenAtTimestamp: 'OverriddenAtTimestamp',
  /** column name */
  OverriddenByUser: 'OverriddenByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  RequestedChanges: 'RequestedChanges',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Type: 'Type'
} as const;

export type Change_Request_Update_Column = typeof Change_Request_Update_Column[keyof typeof Change_Request_Update_Column];
/** aggregate var_pop on columns */
export type Change_Request_Var_Pop_Fields = {
  __typename?: 'change_request_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.change_request" */
export type Change_Request_Var_Pop_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Change_Request_Var_Samp_Fields = {
  __typename?: 'change_request_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.change_request" */
export type Change_Request_Var_Samp_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Change_Request_Variance_Fields = {
  __typename?: 'change_request_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.change_request" */
export type Change_Request_Variance_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** columns and relationships of "risksmart.comment" */
export type Comment = {
  __typename?: 'comment';
  Content: Scalars['String']['output'];
  ConversationId: Scalars['uuid']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  /** An object relationship */
  conversation?: Maybe<Conversation>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
};

/** order by aggregate values of table "risksmart.comment" */
export type Comment_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Comment_Max_Order_By>;
  min?: InputMaybe<Comment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.comment" */
export type Comment_Arr_Rel_Insert_Input = {
  data: Array<Comment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Comment_On_Conflict>;
};

/** columns and relationships of "risksmart.comment_audit" */
export type Comment_Audit = {
  __typename?: 'comment_audit';
  Content: Scalars['String']['output'];
  ConversationId: Scalars['uuid']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
};

/** Boolean expression to filter rows from the table "risksmart.comment_audit". All fields are combined with a logical 'AND'. */
export type Comment_Audit_Bool_Exp = {
  Content?: InputMaybe<String_Comparison_Exp>;
  ConversationId?: InputMaybe<Uuid_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Comment_Audit_Bool_Exp>>;
  _not?: InputMaybe<Comment_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Comment_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.comment_audit". */
export type Comment_Audit_Order_By = {
  Content?: InputMaybe<Order_By>;
  ConversationId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.comment_audit" */
export const Comment_Audit_Select_Column = {
  /** column name */
  Content: 'Content',
  /** column name */
  ConversationId: 'ConversationId',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser'
} as const;

export type Comment_Audit_Select_Column = typeof Comment_Audit_Select_Column[keyof typeof Comment_Audit_Select_Column];
/** Streaming cursor of the table "comment_audit" */
export type Comment_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Comment_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Comment_Audit_Stream_Cursor_Value_Input = {
  Content?: InputMaybe<Scalars['String']['input']>;
  ConversationId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.comment". All fields are combined with a logical 'AND'. */
export type Comment_Bool_Exp = {
  Content?: InputMaybe<String_Comparison_Exp>;
  ConversationId?: InputMaybe<Uuid_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Comment_Bool_Exp>>;
  _not?: InputMaybe<Comment_Bool_Exp>;
  _or?: InputMaybe<Array<Comment_Bool_Exp>>;
  conversation?: InputMaybe<Conversation_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.comment" */
export const Comment_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  CommentPkey: 'comment_pkey'
} as const;

export type Comment_Constraint = typeof Comment_Constraint[keyof typeof Comment_Constraint];
/** input type for inserting data into table "risksmart.comment" */
export type Comment_Insert_Input = {
  Content?: InputMaybe<Scalars['String']['input']>;
  ConversationId?: InputMaybe<Scalars['uuid']['input']>;
  conversation?: InputMaybe<Conversation_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "risksmart.comment" */
export type Comment_Max_Order_By = {
  Content?: InputMaybe<Order_By>;
  ConversationId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.comment" */
export type Comment_Min_Order_By = {
  Content?: InputMaybe<Order_By>;
  ConversationId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.comment" */
export type Comment_Mutation_Response = {
  __typename?: 'comment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Comment>;
};

/** on_conflict condition type for table "risksmart.comment" */
export type Comment_On_Conflict = {
  constraint: Comment_Constraint;
  update_columns?: Array<Comment_Update_Column>;
  where?: InputMaybe<Comment_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.comment". */
export type Comment_Order_By = {
  Content?: InputMaybe<Order_By>;
  ConversationId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  conversation?: InputMaybe<Conversation_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
};

/** primary key columns input for table: risksmart.comment */
export type Comment_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.comment" */
export const Comment_Select_Column = {
  /** column name */
  Content: 'Content',
  /** column name */
  ConversationId: 'ConversationId',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser'
} as const;

export type Comment_Select_Column = typeof Comment_Select_Column[keyof typeof Comment_Select_Column];
/** input type for updating data in table "risksmart.comment" */
export type Comment_Set_Input = {
  Content?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "comment" */
export type Comment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Comment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Comment_Stream_Cursor_Value_Input = {
  Content?: InputMaybe<Scalars['String']['input']>;
  ConversationId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.comment" */
export const Comment_Update_Column = {
  /** column name */
  Content: 'Content'
} as const;

export type Comment_Update_Column = typeof Comment_Update_Column[keyof typeof Comment_Update_Column];
export type Comment_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Comment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Comment_Bool_Exp;
};

/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_Assessment = {
  __typename?: 'compliance_monitoring_assessment';
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Status: Assessment_Status_Enum;
  Summary: Scalars['String']['output'];
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title: Scalars['String']['output'];
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An array relationship */
  assessmentActions: Array<Action_Parent>;
  /** An aggregate relationship */
  assessmentActions_aggregate: Action_Parent_Aggregate;
  /** An array relationship */
  assessmentActivities: Array<Assessment_Activity>;
  /** An aggregate relationship */
  assessmentActivities_aggregate: Assessment_Activity_Aggregate;
  /** An array relationship */
  assessmentIssues: Array<Issue_Parent>;
  /** An aggregate relationship */
  assessmentIssues_aggregate: Issue_Parent_Aggregate;
  /** An array relationship */
  assessmentResults: Array<Assessment_Result_Parent>;
  /** An aggregate relationship */
  assessmentResults_aggregate: Assessment_Result_Parent_Aggregate;
  /** An object relationship */
  completedByUser?: Maybe<User>;
  /** An array relationship */
  contributorGroups: Array<Contributor_Group>;
  /** An aggregate relationship */
  contributorGroups_aggregate: Contributor_Group_Aggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: Contributor_Aggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  originatingItem?: Maybe<Node>;
  /** An array relationship */
  ownerGroups: Array<Owner_Group>;
  /** An aggregate relationship */
  ownerGroups_aggregate: Owner_Group_Aggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: Owner_Aggregate;
  /** An array relationship */
  tags: Array<Tag>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_AssessmentCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_AssessmentAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_AssessmentAssessmentActionsArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_AssessmentAssessmentActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_AssessmentAssessmentActivitiesArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Activity_Order_By>>;
  where?: InputMaybe<Assessment_Activity_Bool_Exp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_AssessmentAssessmentActivities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Activity_Order_By>>;
  where?: InputMaybe<Assessment_Activity_Bool_Exp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_AssessmentAssessmentIssuesArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Order_By>>;
  where?: InputMaybe<Issue_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_AssessmentAssessmentIssues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Order_By>>;
  where?: InputMaybe<Issue_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_AssessmentAssessmentResultsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_AssessmentAssessmentResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_AssessmentContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_AssessmentContributorGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_AssessmentContributorsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_AssessmentContributors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_AssessmentDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_AssessmentOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_AssessmentOwnerGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_AssessmentOwnersArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_AssessmentOwners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_AssessmentTagsArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};

/** aggregated selection of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_Assessment_Aggregate = {
  __typename?: 'compliance_monitoring_assessment_aggregate';
  aggregate?: Maybe<Compliance_Monitoring_Assessment_Aggregate_Fields>;
  nodes: Array<Compliance_Monitoring_Assessment>;
};

/** aggregate fields of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_Assessment_Aggregate_Fields = {
  __typename?: 'compliance_monitoring_assessment_aggregate_fields';
  avg?: Maybe<Compliance_Monitoring_Assessment_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Compliance_Monitoring_Assessment_Max_Fields>;
  min?: Maybe<Compliance_Monitoring_Assessment_Min_Fields>;
  stddev?: Maybe<Compliance_Monitoring_Assessment_Stddev_Fields>;
  stddev_pop?: Maybe<Compliance_Monitoring_Assessment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Compliance_Monitoring_Assessment_Stddev_Samp_Fields>;
  sum?: Maybe<Compliance_Monitoring_Assessment_Sum_Fields>;
  var_pop?: Maybe<Compliance_Monitoring_Assessment_Var_Pop_Fields>;
  var_samp?: Maybe<Compliance_Monitoring_Assessment_Var_Samp_Fields>;
  variance?: Maybe<Compliance_Monitoring_Assessment_Variance_Fields>;
};


/** aggregate fields of "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_Assessment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Compliance_Monitoring_Assessment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Compliance_Monitoring_Assessment_Append_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Compliance_Monitoring_Assessment_Avg_Fields = {
  __typename?: 'compliance_monitoring_assessment_avg_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.compliance_monitoring_assessment". All fields are combined with a logical 'AND'. */
export type Compliance_Monitoring_Assessment_Bool_Exp = {
  ActualCompletionDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  CompletedByUser?: InputMaybe<String_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  NextTestDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  OriginatingItemId?: InputMaybe<Uuid_Comparison_Exp>;
  Outcome?: InputMaybe<Int_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  StartDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Status?: InputMaybe<Assessment_Status_Enum_Comparison_Exp>;
  Summary?: InputMaybe<String_Comparison_Exp>;
  TargetCompletionDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Compliance_Monitoring_Assessment_Bool_Exp>>;
  _not?: InputMaybe<Compliance_Monitoring_Assessment_Bool_Exp>;
  _or?: InputMaybe<Array<Compliance_Monitoring_Assessment_Bool_Exp>>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  assessmentActions?: InputMaybe<Action_Parent_Bool_Exp>;
  assessmentActions_aggregate?: InputMaybe<Action_Parent_Aggregate_Bool_Exp>;
  assessmentActivities?: InputMaybe<Assessment_Activity_Bool_Exp>;
  assessmentActivities_aggregate?: InputMaybe<Assessment_Activity_Aggregate_Bool_Exp>;
  assessmentIssues?: InputMaybe<Issue_Parent_Bool_Exp>;
  assessmentIssues_aggregate?: InputMaybe<Issue_Parent_Aggregate_Bool_Exp>;
  assessmentResults?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
  assessmentResults_aggregate?: InputMaybe<Assessment_Result_Parent_Aggregate_Bool_Exp>;
  completedByUser?: InputMaybe<User_Bool_Exp>;
  contributorGroups?: InputMaybe<Contributor_Group_Bool_Exp>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Bool_Exp>;
  contributors?: InputMaybe<Contributor_Bool_Exp>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  departments?: InputMaybe<Department_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  originatingItem?: InputMaybe<Node_Bool_Exp>;
  ownerGroups?: InputMaybe<Owner_Group_Bool_Exp>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Bool_Exp>;
  owners?: InputMaybe<Owner_Bool_Exp>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Bool_Exp>;
  tags?: InputMaybe<Tag_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.compliance_monitoring_assessment" */
export const Compliance_Monitoring_Assessment_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  ComplianceMonitoringAssessmentPkey: 'compliance_monitoring_assessment_pkey',
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxComplianceMonitoringAssessmentOrgkeySequentialid: 'idx_compliance_monitoring_assessment_orgkey_sequentialid'
} as const;

export type Compliance_Monitoring_Assessment_Constraint = typeof Compliance_Monitoring_Assessment_Constraint[keyof typeof Compliance_Monitoring_Assessment_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Compliance_Monitoring_Assessment_Delete_At_Path_Input = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Compliance_Monitoring_Assessment_Delete_Elem_Input = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Compliance_Monitoring_Assessment_Delete_Key_Input = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_Assessment_Inc_Input = {
  Outcome?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_Assessment_Insert_Input = {
  ActualCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CompletedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OriginatingItemId?: InputMaybe<Scalars['uuid']['input']>;
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<Assessment_Status_Enum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  TargetCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  assessmentActions?: InputMaybe<Action_Parent_Arr_Rel_Insert_Input>;
  assessmentActivities?: InputMaybe<Assessment_Activity_Arr_Rel_Insert_Input>;
  assessmentIssues?: InputMaybe<Issue_Parent_Arr_Rel_Insert_Input>;
  assessmentResults?: InputMaybe<Assessment_Result_Parent_Arr_Rel_Insert_Input>;
  contributorGroups?: InputMaybe<Contributor_Group_Arr_Rel_Insert_Input>;
  contributors?: InputMaybe<Contributor_Arr_Rel_Insert_Input>;
  departments?: InputMaybe<Department_Arr_Rel_Insert_Input>;
  ownerGroups?: InputMaybe<Owner_Group_Arr_Rel_Insert_Input>;
  owners?: InputMaybe<Owner_Arr_Rel_Insert_Input>;
  tags?: InputMaybe<Tag_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Compliance_Monitoring_Assessment_Max_Fields = {
  __typename?: 'compliance_monitoring_assessment_max_fields';
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Compliance_Monitoring_Assessment_Min_Fields = {
  __typename?: 'compliance_monitoring_assessment_min_fields';
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_Assessment_Mutation_Response = {
  __typename?: 'compliance_monitoring_assessment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Compliance_Monitoring_Assessment>;
};

/** input type for inserting object relation for remote table "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_Assessment_Obj_Rel_Insert_Input = {
  data: Compliance_Monitoring_Assessment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Compliance_Monitoring_Assessment_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_Assessment_On_Conflict = {
  constraint: Compliance_Monitoring_Assessment_Constraint;
  update_columns?: Array<Compliance_Monitoring_Assessment_Update_Column>;
  where?: InputMaybe<Compliance_Monitoring_Assessment_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.compliance_monitoring_assessment". */
export type Compliance_Monitoring_Assessment_Order_By = {
  ActualCompletionDate?: InputMaybe<Order_By>;
  CompletedByUser?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  NextTestDate?: InputMaybe<Order_By>;
  OriginatingItemId?: InputMaybe<Order_By>;
  Outcome?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  StartDate?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Summary?: InputMaybe<Order_By>;
  TargetCompletionDate?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  assessmentActions_aggregate?: InputMaybe<Action_Parent_Aggregate_Order_By>;
  assessmentActivities_aggregate?: InputMaybe<Assessment_Activity_Aggregate_Order_By>;
  assessmentIssues_aggregate?: InputMaybe<Issue_Parent_Aggregate_Order_By>;
  assessmentResults_aggregate?: InputMaybe<Assessment_Result_Parent_Aggregate_Order_By>;
  completedByUser?: InputMaybe<User_Order_By>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Order_By>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  departments_aggregate?: InputMaybe<Department_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  originatingItem?: InputMaybe<Node_Order_By>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Order_By>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Order_By>;
  tags_aggregate?: InputMaybe<Tag_Aggregate_Order_By>;
};

/** primary key columns input for table: risksmart.compliance_monitoring_assessment */
export type Compliance_Monitoring_Assessment_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Compliance_Monitoring_Assessment_Prepend_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.compliance_monitoring_assessment" */
export const Compliance_Monitoring_Assessment_Select_Column = {
  /** column name */
  ActualCompletionDate: 'ActualCompletionDate',
  /** column name */
  CompletedByUser: 'CompletedByUser',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  NextTestDate: 'NextTestDate',
  /** column name */
  OriginatingItemId: 'OriginatingItemId',
  /** column name */
  Outcome: 'Outcome',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  StartDate: 'StartDate',
  /** column name */
  Status: 'Status',
  /** column name */
  Summary: 'Summary',
  /** column name */
  TargetCompletionDate: 'TargetCompletionDate',
  /** column name */
  Title: 'Title'
} as const;

export type Compliance_Monitoring_Assessment_Select_Column = typeof Compliance_Monitoring_Assessment_Select_Column[keyof typeof Compliance_Monitoring_Assessment_Select_Column];
/** input type for updating data in table "risksmart.compliance_monitoring_assessment" */
export type Compliance_Monitoring_Assessment_Set_Input = {
  ActualCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CompletedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OriginatingItemId?: InputMaybe<Scalars['uuid']['input']>;
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<Assessment_Status_Enum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  TargetCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Compliance_Monitoring_Assessment_Stddev_Fields = {
  __typename?: 'compliance_monitoring_assessment_stddev_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Compliance_Monitoring_Assessment_Stddev_Pop_Fields = {
  __typename?: 'compliance_monitoring_assessment_stddev_pop_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Compliance_Monitoring_Assessment_Stddev_Samp_Fields = {
  __typename?: 'compliance_monitoring_assessment_stddev_samp_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "compliance_monitoring_assessment" */
export type Compliance_Monitoring_Assessment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Compliance_Monitoring_Assessment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Compliance_Monitoring_Assessment_Stream_Cursor_Value_Input = {
  ActualCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CompletedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OriginatingItemId?: InputMaybe<Scalars['uuid']['input']>;
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<Assessment_Status_Enum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  TargetCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Compliance_Monitoring_Assessment_Sum_Fields = {
  __typename?: 'compliance_monitoring_assessment_sum_fields';
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.compliance_monitoring_assessment" */
export const Compliance_Monitoring_Assessment_Update_Column = {
  /** column name */
  ActualCompletionDate: 'ActualCompletionDate',
  /** column name */
  CompletedByUser: 'CompletedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  NextTestDate: 'NextTestDate',
  /** column name */
  OriginatingItemId: 'OriginatingItemId',
  /** column name */
  Outcome: 'Outcome',
  /** column name */
  StartDate: 'StartDate',
  /** column name */
  Status: 'Status',
  /** column name */
  Summary: 'Summary',
  /** column name */
  TargetCompletionDate: 'TargetCompletionDate',
  /** column name */
  Title: 'Title'
} as const;

export type Compliance_Monitoring_Assessment_Update_Column = typeof Compliance_Monitoring_Assessment_Update_Column[keyof typeof Compliance_Monitoring_Assessment_Update_Column];
export type Compliance_Monitoring_Assessment_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Compliance_Monitoring_Assessment_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Compliance_Monitoring_Assessment_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Compliance_Monitoring_Assessment_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Compliance_Monitoring_Assessment_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Compliance_Monitoring_Assessment_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Compliance_Monitoring_Assessment_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Compliance_Monitoring_Assessment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Compliance_Monitoring_Assessment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Compliance_Monitoring_Assessment_Var_Pop_Fields = {
  __typename?: 'compliance_monitoring_assessment_var_pop_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Compliance_Monitoring_Assessment_Var_Samp_Fields = {
  __typename?: 'compliance_monitoring_assessment_var_samp_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Compliance_Monitoring_Assessment_Variance_Fields = {
  __typename?: 'compliance_monitoring_assessment_variance_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.consequence" */
export type Consequence = {
  __typename?: 'consequence';
  CostType: Cost_Type_Enum;
  CostValue: Scalars['numeric']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Criticality?: Maybe<Scalars['Int']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentIssueId: Scalars['uuid']['output'];
  Title: Scalars['String']['output'];
  Type?: Maybe<Consequence_Type_Enum>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  issue?: Maybe<Issue>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
};


/** columns and relationships of "risksmart.consequence" */
export type ConsequenceCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.consequence" */
export type Consequence_Aggregate = {
  __typename?: 'consequence_aggregate';
  aggregate?: Maybe<Consequence_Aggregate_Fields>;
  nodes: Array<Consequence>;
};

export type Consequence_Aggregate_Bool_Exp = {
  count?: InputMaybe<Consequence_Aggregate_Bool_Exp_Count>;
};

export type Consequence_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Consequence_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Consequence_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.consequence" */
export type Consequence_Aggregate_Fields = {
  __typename?: 'consequence_aggregate_fields';
  avg?: Maybe<Consequence_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Consequence_Max_Fields>;
  min?: Maybe<Consequence_Min_Fields>;
  stddev?: Maybe<Consequence_Stddev_Fields>;
  stddev_pop?: Maybe<Consequence_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Consequence_Stddev_Samp_Fields>;
  sum?: Maybe<Consequence_Sum_Fields>;
  var_pop?: Maybe<Consequence_Var_Pop_Fields>;
  var_samp?: Maybe<Consequence_Var_Samp_Fields>;
  variance?: Maybe<Consequence_Variance_Fields>;
};


/** aggregate fields of "risksmart.consequence" */
export type Consequence_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Consequence_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.consequence" */
export type Consequence_Aggregate_Order_By = {
  avg?: InputMaybe<Consequence_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Consequence_Max_Order_By>;
  min?: InputMaybe<Consequence_Min_Order_By>;
  stddev?: InputMaybe<Consequence_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Consequence_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Consequence_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Consequence_Sum_Order_By>;
  var_pop?: InputMaybe<Consequence_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Consequence_Var_Samp_Order_By>;
  variance?: InputMaybe<Consequence_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Consequence_Append_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.consequence" */
export type Consequence_Arr_Rel_Insert_Input = {
  data: Array<Consequence_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Consequence_On_Conflict>;
};

/** columns and relationships of "risksmart.consequence_audit" */
export type Consequence_Audit = {
  __typename?: 'consequence_audit';
  CostType: Scalars['String']['output'];
  CostValue: Scalars['numeric']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Criticality?: Maybe<Scalars['Int']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentIssueId: Scalars['uuid']['output'];
  Title: Scalars['String']['output'];
  Type?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "risksmart.consequence_audit" */
export type Consequence_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.consequence_audit" */
export type Consequence_Audit_Aggregate = {
  __typename?: 'consequence_audit_aggregate';
  aggregate?: Maybe<Consequence_Audit_Aggregate_Fields>;
  nodes: Array<Consequence_Audit>;
};

/** aggregate fields of "risksmart.consequence_audit" */
export type Consequence_Audit_Aggregate_Fields = {
  __typename?: 'consequence_audit_aggregate_fields';
  avg?: Maybe<Consequence_Audit_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Consequence_Audit_Max_Fields>;
  min?: Maybe<Consequence_Audit_Min_Fields>;
  stddev?: Maybe<Consequence_Audit_Stddev_Fields>;
  stddev_pop?: Maybe<Consequence_Audit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Consequence_Audit_Stddev_Samp_Fields>;
  sum?: Maybe<Consequence_Audit_Sum_Fields>;
  var_pop?: Maybe<Consequence_Audit_Var_Pop_Fields>;
  var_samp?: Maybe<Consequence_Audit_Var_Samp_Fields>;
  variance?: Maybe<Consequence_Audit_Variance_Fields>;
};


/** aggregate fields of "risksmart.consequence_audit" */
export type Consequence_Audit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Consequence_Audit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Consequence_Audit_Avg_Fields = {
  __typename?: 'consequence_audit_avg_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.consequence_audit". All fields are combined with a logical 'AND'. */
export type Consequence_Audit_Bool_Exp = {
  CostType?: InputMaybe<String_Comparison_Exp>;
  CostValue?: InputMaybe<Numeric_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Criticality?: InputMaybe<Int_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentIssueId?: InputMaybe<Uuid_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  Type?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Consequence_Audit_Bool_Exp>>;
  _not?: InputMaybe<Consequence_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Consequence_Audit_Bool_Exp>>;
};

/** aggregate max on columns */
export type Consequence_Audit_Max_Fields = {
  __typename?: 'consequence_audit_max_fields';
  CostType?: Maybe<Scalars['String']['output']>;
  CostValue?: Maybe<Scalars['numeric']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Criticality?: Maybe<Scalars['Int']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Consequence_Audit_Min_Fields = {
  __typename?: 'consequence_audit_min_fields';
  CostType?: Maybe<Scalars['String']['output']>;
  CostValue?: Maybe<Scalars['numeric']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Criticality?: Maybe<Scalars['Int']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "risksmart.consequence_audit". */
export type Consequence_Audit_Order_By = {
  CostType?: InputMaybe<Order_By>;
  CostValue?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Criticality?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentIssueId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.consequence_audit" */
export const Consequence_Audit_Select_Column = {
  /** column name */
  CostType: 'CostType',
  /** column name */
  CostValue: 'CostValue',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Criticality: 'Criticality',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentIssueId: 'ParentIssueId',
  /** column name */
  Title: 'Title',
  /** column name */
  Type: 'Type'
} as const;

export type Consequence_Audit_Select_Column = typeof Consequence_Audit_Select_Column[keyof typeof Consequence_Audit_Select_Column];
/** aggregate stddev on columns */
export type Consequence_Audit_Stddev_Fields = {
  __typename?: 'consequence_audit_stddev_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Consequence_Audit_Stddev_Pop_Fields = {
  __typename?: 'consequence_audit_stddev_pop_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Consequence_Audit_Stddev_Samp_Fields = {
  __typename?: 'consequence_audit_stddev_samp_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "consequence_audit" */
export type Consequence_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Consequence_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Consequence_Audit_Stream_Cursor_Value_Input = {
  CostType?: InputMaybe<Scalars['String']['input']>;
  CostValue?: InputMaybe<Scalars['numeric']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Criticality?: InputMaybe<Scalars['Int']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Consequence_Audit_Sum_Fields = {
  __typename?: 'consequence_audit_sum_fields';
  CostValue?: Maybe<Scalars['numeric']['output']>;
  Criticality?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Consequence_Audit_Var_Pop_Fields = {
  __typename?: 'consequence_audit_var_pop_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Consequence_Audit_Var_Samp_Fields = {
  __typename?: 'consequence_audit_var_samp_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Consequence_Audit_Variance_Fields = {
  __typename?: 'consequence_audit_variance_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type Consequence_Avg_Fields = {
  __typename?: 'consequence_avg_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.consequence" */
export type Consequence_Avg_Order_By = {
  CostValue?: InputMaybe<Order_By>;
  Criticality?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.consequence". All fields are combined with a logical 'AND'. */
export type Consequence_Bool_Exp = {
  CostType?: InputMaybe<Cost_Type_Enum_Comparison_Exp>;
  CostValue?: InputMaybe<Numeric_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Criticality?: InputMaybe<Int_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentIssueId?: InputMaybe<Uuid_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  Type?: InputMaybe<Consequence_Type_Enum_Comparison_Exp>;
  _and?: InputMaybe<Array<Consequence_Bool_Exp>>;
  _not?: InputMaybe<Consequence_Bool_Exp>;
  _or?: InputMaybe<Array<Consequence_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  issue?: InputMaybe<Issue_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.consequence" */
export const Consequence_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  ConsequencePkey: 'consequence_pkey'
} as const;

export type Consequence_Constraint = typeof Consequence_Constraint[keyof typeof Consequence_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Consequence_Delete_At_Path_Input = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Consequence_Delete_Elem_Input = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Consequence_Delete_Key_Input = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.consequence" */
export type Consequence_Inc_Input = {
  CostValue?: InputMaybe<Scalars['numeric']['input']>;
  Criticality?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.consequence" */
export type Consequence_Insert_Input = {
  CostType?: InputMaybe<Cost_Type_Enum>;
  CostValue?: InputMaybe<Scalars['numeric']['input']>;
  Criticality?: InputMaybe<Scalars['Int']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Consequence_Type_Enum>;
  issue?: InputMaybe<Issue_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Consequence_Max_Fields = {
  __typename?: 'consequence_max_fields';
  CostValue?: Maybe<Scalars['numeric']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Criticality?: Maybe<Scalars['Int']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.consequence" */
export type Consequence_Max_Order_By = {
  CostValue?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Criticality?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentIssueId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Consequence_Min_Fields = {
  __typename?: 'consequence_min_fields';
  CostValue?: Maybe<Scalars['numeric']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Criticality?: Maybe<Scalars['Int']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.consequence" */
export type Consequence_Min_Order_By = {
  CostValue?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Criticality?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentIssueId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.consequence" */
export type Consequence_Mutation_Response = {
  __typename?: 'consequence_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Consequence>;
};

/** on_conflict condition type for table "risksmart.consequence" */
export type Consequence_On_Conflict = {
  constraint: Consequence_Constraint;
  update_columns?: Array<Consequence_Update_Column>;
  where?: InputMaybe<Consequence_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.consequence". */
export type Consequence_Order_By = {
  CostType?: InputMaybe<Order_By>;
  CostValue?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Criticality?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentIssueId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  issue?: InputMaybe<Issue_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
};

/** primary key columns input for table: risksmart.consequence */
export type Consequence_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Consequence_Prepend_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.consequence" */
export const Consequence_Select_Column = {
  /** column name */
  CostType: 'CostType',
  /** column name */
  CostValue: 'CostValue',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Criticality: 'Criticality',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentIssueId: 'ParentIssueId',
  /** column name */
  Title: 'Title',
  /** column name */
  Type: 'Type'
} as const;

export type Consequence_Select_Column = typeof Consequence_Select_Column[keyof typeof Consequence_Select_Column];
/** input type for updating data in table "risksmart.consequence" */
export type Consequence_Set_Input = {
  CostType?: InputMaybe<Cost_Type_Enum>;
  CostValue?: InputMaybe<Scalars['numeric']['input']>;
  Criticality?: InputMaybe<Scalars['Int']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Consequence_Type_Enum>;
};

/** aggregate stddev on columns */
export type Consequence_Stddev_Fields = {
  __typename?: 'consequence_stddev_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.consequence" */
export type Consequence_Stddev_Order_By = {
  CostValue?: InputMaybe<Order_By>;
  Criticality?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Consequence_Stddev_Pop_Fields = {
  __typename?: 'consequence_stddev_pop_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.consequence" */
export type Consequence_Stddev_Pop_Order_By = {
  CostValue?: InputMaybe<Order_By>;
  Criticality?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Consequence_Stddev_Samp_Fields = {
  __typename?: 'consequence_stddev_samp_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.consequence" */
export type Consequence_Stddev_Samp_Order_By = {
  CostValue?: InputMaybe<Order_By>;
  Criticality?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "consequence" */
export type Consequence_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Consequence_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Consequence_Stream_Cursor_Value_Input = {
  CostType?: InputMaybe<Cost_Type_Enum>;
  CostValue?: InputMaybe<Scalars['numeric']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Criticality?: InputMaybe<Scalars['Int']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Consequence_Type_Enum>;
};

/** aggregate sum on columns */
export type Consequence_Sum_Fields = {
  __typename?: 'consequence_sum_fields';
  CostValue?: Maybe<Scalars['numeric']['output']>;
  Criticality?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.consequence" */
export type Consequence_Sum_Order_By = {
  CostValue?: InputMaybe<Order_By>;
  Criticality?: InputMaybe<Order_By>;
};

export const Consequence_Type_Enum = {
  /** Customer */
  Customer: 'customer',
  /** Financial */
  Financial: 'financial',
  /** Legal & Regulatory */
  LegalAndRegulatory: 'legal_and_regulatory',
  /** Operational */
  Operational: 'operational',
  /** Reputational */
  Reputational: 'reputational'
} as const;

export type Consequence_Type_Enum = typeof Consequence_Type_Enum[keyof typeof Consequence_Type_Enum];
/** Boolean expression to compare columns of type "consequence_type_enum". All fields are combined with logical 'AND'. */
export type Consequence_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Consequence_Type_Enum>;
  _in?: InputMaybe<Array<Consequence_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Consequence_Type_Enum>;
  _nin?: InputMaybe<Array<Consequence_Type_Enum>>;
};

/** update columns of table "risksmart.consequence" */
export const Consequence_Update_Column = {
  /** column name */
  CostType: 'CostType',
  /** column name */
  CostValue: 'CostValue',
  /** column name */
  Criticality: 'Criticality',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  ParentIssueId: 'ParentIssueId',
  /** column name */
  Title: 'Title',
  /** column name */
  Type: 'Type'
} as const;

export type Consequence_Update_Column = typeof Consequence_Update_Column[keyof typeof Consequence_Update_Column];
export type Consequence_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Consequence_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Consequence_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Consequence_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Consequence_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Consequence_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Consequence_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Consequence_Set_Input>;
  /** filter the rows which have to be updated */
  where: Consequence_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Consequence_Var_Pop_Fields = {
  __typename?: 'consequence_var_pop_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.consequence" */
export type Consequence_Var_Pop_Order_By = {
  CostValue?: InputMaybe<Order_By>;
  Criticality?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Consequence_Var_Samp_Fields = {
  __typename?: 'consequence_var_samp_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.consequence" */
export type Consequence_Var_Samp_Order_By = {
  CostValue?: InputMaybe<Order_By>;
  Criticality?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Consequence_Variance_Fields = {
  __typename?: 'consequence_variance_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.consequence" */
export type Consequence_Variance_Order_By = {
  CostValue?: InputMaybe<Order_By>;
  Criticality?: InputMaybe<Order_By>;
};

/** columns and relationships of "risksmart.contributor" */
export type Contributor = {
  __typename?: 'contributor';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  UserId: Scalars['String']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parentAction?: Maybe<Action>;
  /** An object relationship */
  parentAssessment?: Maybe<Assessment>;
  /** An object relationship */
  parentInternalAuditEntity?: Maybe<Internal_Audit_Entity>;
  /** An object relationship */
  parentInternalAuditReport?: Maybe<Internal_Audit_Report>;
  /** An object relationship */
  parentIssue?: Maybe<Issue>;
  /** An object relationship */
  parentRisk?: Maybe<Risk>;
  /** An object relationship */
  parentThirdParty?: Maybe<Third_Party>;
  /** An object relationship */
  user?: Maybe<User>;
};

/** aggregated selection of "risksmart.contributor" */
export type Contributor_Aggregate = {
  __typename?: 'contributor_aggregate';
  aggregate?: Maybe<Contributor_Aggregate_Fields>;
  nodes: Array<Contributor>;
};

export type Contributor_Aggregate_Bool_Exp = {
  count?: InputMaybe<Contributor_Aggregate_Bool_Exp_Count>;
};

export type Contributor_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Contributor_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Contributor_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.contributor" */
export type Contributor_Aggregate_Fields = {
  __typename?: 'contributor_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Contributor_Max_Fields>;
  min?: Maybe<Contributor_Min_Fields>;
};


/** aggregate fields of "risksmart.contributor" */
export type Contributor_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contributor_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.contributor" */
export type Contributor_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contributor_Max_Order_By>;
  min?: InputMaybe<Contributor_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.contributor" */
export type Contributor_Arr_Rel_Insert_Input = {
  data: Array<Contributor_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Contributor_On_Conflict>;
};

/** columns and relationships of "risksmart.contributor_audit" */
export type Contributor_Audit = {
  __typename?: 'contributor_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId: Scalars['uuid']['output'];
  UserId: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.contributor_audit" */
export type Contributor_Audit_Aggregate = {
  __typename?: 'contributor_audit_aggregate';
  aggregate?: Maybe<Contributor_Audit_Aggregate_Fields>;
  nodes: Array<Contributor_Audit>;
};

export type Contributor_Audit_Aggregate_Bool_Exp = {
  count?: InputMaybe<Contributor_Audit_Aggregate_Bool_Exp_Count>;
};

export type Contributor_Audit_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Contributor_Audit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Contributor_Audit_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.contributor_audit" */
export type Contributor_Audit_Aggregate_Fields = {
  __typename?: 'contributor_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Contributor_Audit_Max_Fields>;
  min?: Maybe<Contributor_Audit_Min_Fields>;
};


/** aggregate fields of "risksmart.contributor_audit" */
export type Contributor_Audit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contributor_Audit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.contributor_audit" */
export type Contributor_Audit_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contributor_Audit_Max_Order_By>;
  min?: InputMaybe<Contributor_Audit_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.contributor_audit". All fields are combined with a logical 'AND'. */
export type Contributor_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  UserId?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Contributor_Audit_Bool_Exp>>;
  _not?: InputMaybe<Contributor_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Contributor_Audit_Bool_Exp>>;
};

/** aggregate max on columns */
export type Contributor_Audit_Max_Fields = {
  __typename?: 'contributor_audit_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.contributor_audit" */
export type Contributor_Audit_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contributor_Audit_Min_Fields = {
  __typename?: 'contributor_audit_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.contributor_audit" */
export type Contributor_Audit_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "risksmart.contributor_audit". */
export type Contributor_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.contributor_audit" */
export const Contributor_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  UserId: 'UserId'
} as const;

export type Contributor_Audit_Select_Column = typeof Contributor_Audit_Select_Column[keyof typeof Contributor_Audit_Select_Column];
/** Boolean expression to filter rows from the table "risksmart.contributor". All fields are combined with a logical 'AND'. */
export type Contributor_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  UserId?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Contributor_Bool_Exp>>;
  _not?: InputMaybe<Contributor_Bool_Exp>;
  _or?: InputMaybe<Array<Contributor_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parentAction?: InputMaybe<Action_Bool_Exp>;
  parentAssessment?: InputMaybe<Assessment_Bool_Exp>;
  parentInternalAuditEntity?: InputMaybe<Internal_Audit_Entity_Bool_Exp>;
  parentInternalAuditReport?: InputMaybe<Internal_Audit_Report_Bool_Exp>;
  parentIssue?: InputMaybe<Issue_Bool_Exp>;
  parentRisk?: InputMaybe<Risk_Bool_Exp>;
  parentThirdParty?: InputMaybe<Third_Party_Bool_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.contributor" */
export const Contributor_Constraint = {
  /** unique or primary key constraint on columns "ParentId", "UserId" */
  ContributorPkey: 'contributor_pkey'
} as const;

export type Contributor_Constraint = typeof Contributor_Constraint[keyof typeof Contributor_Constraint];
/** columns and relationships of "risksmart.contributor_group" */
export type Contributor_Group = {
  __typename?: 'contributor_group';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  UserGroupId: Scalars['uuid']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  group?: Maybe<User_Group>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parentAction?: Maybe<Action>;
  /** An object relationship */
  parentAssessment?: Maybe<Assessment>;
  /** An object relationship */
  parentInternalAuditEntity?: Maybe<Internal_Audit_Entity>;
  /** An object relationship */
  parentInternalAuditReport?: Maybe<Internal_Audit_Report>;
  /** An object relationship */
  parentIssue?: Maybe<Issue>;
  /** An object relationship */
  parentRisk?: Maybe<Risk>;
  /** An object relationship */
  parentThirdParty?: Maybe<Third_Party>;
};

/** aggregated selection of "risksmart.contributor_group" */
export type Contributor_Group_Aggregate = {
  __typename?: 'contributor_group_aggregate';
  aggregate?: Maybe<Contributor_Group_Aggregate_Fields>;
  nodes: Array<Contributor_Group>;
};

export type Contributor_Group_Aggregate_Bool_Exp = {
  count?: InputMaybe<Contributor_Group_Aggregate_Bool_Exp_Count>;
};

export type Contributor_Group_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Contributor_Group_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.contributor_group" */
export type Contributor_Group_Aggregate_Fields = {
  __typename?: 'contributor_group_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Contributor_Group_Max_Fields>;
  min?: Maybe<Contributor_Group_Min_Fields>;
};


/** aggregate fields of "risksmart.contributor_group" */
export type Contributor_Group_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.contributor_group" */
export type Contributor_Group_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contributor_Group_Max_Order_By>;
  min?: InputMaybe<Contributor_Group_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.contributor_group" */
export type Contributor_Group_Arr_Rel_Insert_Input = {
  data: Array<Contributor_Group_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Contributor_Group_On_Conflict>;
};

/** columns and relationships of "risksmart.contributor_group_audit" */
export type Contributor_Group_Audit = {
  __typename?: 'contributor_group_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId: Scalars['uuid']['output'];
  UserGroupId: Scalars['uuid']['output'];
};

/** aggregated selection of "risksmart.contributor_group_audit" */
export type Contributor_Group_Audit_Aggregate = {
  __typename?: 'contributor_group_audit_aggregate';
  aggregate?: Maybe<Contributor_Group_Audit_Aggregate_Fields>;
  nodes: Array<Contributor_Group_Audit>;
};

export type Contributor_Group_Audit_Aggregate_Bool_Exp = {
  count?: InputMaybe<Contributor_Group_Audit_Aggregate_Bool_Exp_Count>;
};

export type Contributor_Group_Audit_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Contributor_Group_Audit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Contributor_Group_Audit_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.contributor_group_audit" */
export type Contributor_Group_Audit_Aggregate_Fields = {
  __typename?: 'contributor_group_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Contributor_Group_Audit_Max_Fields>;
  min?: Maybe<Contributor_Group_Audit_Min_Fields>;
};


/** aggregate fields of "risksmart.contributor_group_audit" */
export type Contributor_Group_Audit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contributor_Group_Audit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.contributor_group_audit" */
export type Contributor_Group_Audit_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contributor_Group_Audit_Max_Order_By>;
  min?: InputMaybe<Contributor_Group_Audit_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.contributor_group_audit". All fields are combined with a logical 'AND'. */
export type Contributor_Group_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  UserGroupId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Contributor_Group_Audit_Bool_Exp>>;
  _not?: InputMaybe<Contributor_Group_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Contributor_Group_Audit_Bool_Exp>>;
};

/** aggregate max on columns */
export type Contributor_Group_Audit_Max_Fields = {
  __typename?: 'contributor_group_audit_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.contributor_group_audit" */
export type Contributor_Group_Audit_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contributor_Group_Audit_Min_Fields = {
  __typename?: 'contributor_group_audit_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.contributor_group_audit" */
export type Contributor_Group_Audit_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "risksmart.contributor_group_audit". */
export type Contributor_Group_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.contributor_group_audit" */
export const Contributor_Group_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  UserGroupId: 'UserGroupId'
} as const;

export type Contributor_Group_Audit_Select_Column = typeof Contributor_Group_Audit_Select_Column[keyof typeof Contributor_Group_Audit_Select_Column];
/** Boolean expression to filter rows from the table "risksmart.contributor_group". All fields are combined with a logical 'AND'. */
export type Contributor_Group_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  UserGroupId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Contributor_Group_Bool_Exp>>;
  _not?: InputMaybe<Contributor_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Contributor_Group_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  group?: InputMaybe<User_Group_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parentAction?: InputMaybe<Action_Bool_Exp>;
  parentAssessment?: InputMaybe<Assessment_Bool_Exp>;
  parentInternalAuditEntity?: InputMaybe<Internal_Audit_Entity_Bool_Exp>;
  parentInternalAuditReport?: InputMaybe<Internal_Audit_Report_Bool_Exp>;
  parentIssue?: InputMaybe<Issue_Bool_Exp>;
  parentRisk?: InputMaybe<Risk_Bool_Exp>;
  parentThirdParty?: InputMaybe<Third_Party_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.contributor_group" */
export const Contributor_Group_Constraint = {
  /** unique or primary key constraint on columns "ParentId", "UserGroupId" */
  ContributorGroupPkey: 'contributor_group_pkey'
} as const;

export type Contributor_Group_Constraint = typeof Contributor_Group_Constraint[keyof typeof Contributor_Group_Constraint];
/** input type for inserting data into table "risksmart.contributor_group" */
export type Contributor_Group_Insert_Input = {
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  group?: InputMaybe<User_Group_Obj_Rel_Insert_Input>;
  parentAction?: InputMaybe<Action_Obj_Rel_Insert_Input>;
  parentAssessment?: InputMaybe<Assessment_Obj_Rel_Insert_Input>;
  parentInternalAuditEntity?: InputMaybe<Internal_Audit_Entity_Obj_Rel_Insert_Input>;
  parentInternalAuditReport?: InputMaybe<Internal_Audit_Report_Obj_Rel_Insert_Input>;
  parentIssue?: InputMaybe<Issue_Obj_Rel_Insert_Input>;
  parentRisk?: InputMaybe<Risk_Obj_Rel_Insert_Input>;
  parentThirdParty?: InputMaybe<Third_Party_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Contributor_Group_Max_Fields = {
  __typename?: 'contributor_group_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.contributor_group" */
export type Contributor_Group_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contributor_Group_Min_Fields = {
  __typename?: 'contributor_group_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.contributor_group" */
export type Contributor_Group_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.contributor_group" */
export type Contributor_Group_Mutation_Response = {
  __typename?: 'contributor_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Contributor_Group>;
};

/** on_conflict condition type for table "risksmart.contributor_group" */
export type Contributor_Group_On_Conflict = {
  constraint: Contributor_Group_Constraint;
  update_columns?: Array<Contributor_Group_Update_Column>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.contributor_group". */
export type Contributor_Group_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  group?: InputMaybe<User_Group_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parentAction?: InputMaybe<Action_Order_By>;
  parentAssessment?: InputMaybe<Assessment_Order_By>;
  parentInternalAuditEntity?: InputMaybe<Internal_Audit_Entity_Order_By>;
  parentInternalAuditReport?: InputMaybe<Internal_Audit_Report_Order_By>;
  parentIssue?: InputMaybe<Issue_Order_By>;
  parentRisk?: InputMaybe<Risk_Order_By>;
  parentThirdParty?: InputMaybe<Third_Party_Order_By>;
};

/** select columns of table "risksmart.contributor_group" */
export const Contributor_Group_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  UserGroupId: 'UserGroupId'
} as const;

export type Contributor_Group_Select_Column = typeof Contributor_Group_Select_Column[keyof typeof Contributor_Group_Select_Column];
/** placeholder for update columns of table "risksmart.contributor_group" (current role has no relevant permissions) */
export const Contributor_Group_Update_Column = {
  /** placeholder (do not use) */
  Placeholder: '_PLACEHOLDER'
} as const;

export type Contributor_Group_Update_Column = typeof Contributor_Group_Update_Column[keyof typeof Contributor_Group_Update_Column];
/** input type for inserting data into table "risksmart.contributor" */
export type Contributor_Insert_Input = {
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
  parentAction?: InputMaybe<Action_Obj_Rel_Insert_Input>;
  parentAssessment?: InputMaybe<Assessment_Obj_Rel_Insert_Input>;
  parentInternalAuditEntity?: InputMaybe<Internal_Audit_Entity_Obj_Rel_Insert_Input>;
  parentInternalAuditReport?: InputMaybe<Internal_Audit_Report_Obj_Rel_Insert_Input>;
  parentIssue?: InputMaybe<Issue_Obj_Rel_Insert_Input>;
  parentRisk?: InputMaybe<Risk_Obj_Rel_Insert_Input>;
  parentThirdParty?: InputMaybe<Third_Party_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Contributor_Max_Fields = {
  __typename?: 'contributor_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.contributor" */
export type Contributor_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contributor_Min_Fields = {
  __typename?: 'contributor_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.contributor" */
export type Contributor_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.contributor" */
export type Contributor_Mutation_Response = {
  __typename?: 'contributor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Contributor>;
};

/** on_conflict condition type for table "risksmart.contributor" */
export type Contributor_On_Conflict = {
  constraint: Contributor_Constraint;
  update_columns?: Array<Contributor_Update_Column>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.contributor". */
export type Contributor_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parentAction?: InputMaybe<Action_Order_By>;
  parentAssessment?: InputMaybe<Assessment_Order_By>;
  parentInternalAuditEntity?: InputMaybe<Internal_Audit_Entity_Order_By>;
  parentInternalAuditReport?: InputMaybe<Internal_Audit_Report_Order_By>;
  parentIssue?: InputMaybe<Issue_Order_By>;
  parentRisk?: InputMaybe<Risk_Order_By>;
  parentThirdParty?: InputMaybe<Third_Party_Order_By>;
  user?: InputMaybe<User_Order_By>;
};

/** select columns of table "risksmart.contributor" */
export const Contributor_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  UserId: 'UserId'
} as const;

export type Contributor_Select_Column = typeof Contributor_Select_Column[keyof typeof Contributor_Select_Column];
export const Contributor_Type_Enum = {
  /** Any */
  Any: 'any',
  /** Contributor */
  Contributor: 'contributor',
  /** Owner */
  Owner: 'owner'
} as const;

export type Contributor_Type_Enum = typeof Contributor_Type_Enum[keyof typeof Contributor_Type_Enum];
/** Boolean expression to compare columns of type "contributor_type_enum". All fields are combined with logical 'AND'. */
export type Contributor_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Contributor_Type_Enum>;
  _in?: InputMaybe<Array<Contributor_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Contributor_Type_Enum>;
  _nin?: InputMaybe<Array<Contributor_Type_Enum>>;
};

/** placeholder for update columns of table "risksmart.contributor" (current role has no relevant permissions) */
export const Contributor_Update_Column = {
  /** placeholder (do not use) */
  Placeholder: '_PLACEHOLDER'
} as const;

export type Contributor_Update_Column = typeof Contributor_Update_Column[keyof typeof Contributor_Update_Column];
/** columns and relationships of "risksmart.control" */
export type Control = {
  __typename?: 'control';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title: Scalars['String']['output'];
  Type?: Maybe<Control_Type_Enum>;
  /** An array relationship */
  actions: Array<Action_Parent>;
  /** An aggregate relationship */
  actions_aggregate: Action_Parent_Aggregate;
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An array relationship */
  contributorGroups: Array<Contributor_Group>;
  /** An aggregate relationship */
  contributorGroups_aggregate: Contributor_Group_Aggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: Contributor_Aggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An array relationship */
  indicators: Array<Indicator_Parent>;
  /** An aggregate relationship */
  indicators_aggregate: Indicator_Parent_Aggregate;
  /** An array relationship */
  issues: Array<Issue_Parent>;
  /** An aggregate relationship */
  issues_aggregate: Issue_Parent_Aggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An array relationship */
  ownerGroups: Array<Owner_Group>;
  /** An aggregate relationship */
  ownerGroups_aggregate: Owner_Group_Aggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: Owner_Aggregate;
  /** An array relationship */
  parents: Array<Control_Parent>;
  /** An aggregate relationship */
  parents_aggregate: Control_Parent_Aggregate;
  /** An object relationship */
  schedule?: Maybe<Schedule>;
  /** An object relationship */
  scheduleState?: Maybe<Schedule_State>;
  /** An array relationship */
  tags: Array<Tag>;
  /** An array relationship */
  testResults: Array<Test_Result>;
  /** An aggregate relationship */
  testResults_aggregate: Test_Result_Aggregate;
};


/** columns and relationships of "risksmart.control" */
export type ControlCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.control" */
export type ControlActionsArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlContributorGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlContributorsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlContributors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlIndicatorsArgs = {
  distinct_on?: InputMaybe<Array<Indicator_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Indicator_Parent_Order_By>>;
  where?: InputMaybe<Indicator_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlIndicators_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Indicator_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Indicator_Parent_Order_By>>;
  where?: InputMaybe<Indicator_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlIssuesArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Order_By>>;
  where?: InputMaybe<Issue_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlIssues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Order_By>>;
  where?: InputMaybe<Issue_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlOwnerGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlOwnersArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlOwners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlParentsArgs = {
  distinct_on?: InputMaybe<Array<Control_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Parent_Order_By>>;
  where?: InputMaybe<Control_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlParents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Parent_Order_By>>;
  where?: InputMaybe<Control_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlTagsArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlTestResultsArgs = {
  distinct_on?: InputMaybe<Array<Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Test_Result_Order_By>>;
  where?: InputMaybe<Test_Result_Bool_Exp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlTestResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Test_Result_Order_By>>;
  where?: InputMaybe<Test_Result_Bool_Exp>;
};

/** aggregated selection of "risksmart.control" */
export type Control_Aggregate = {
  __typename?: 'control_aggregate';
  aggregate?: Maybe<Control_Aggregate_Fields>;
  nodes: Array<Control>;
};

/** aggregate fields of "risksmart.control" */
export type Control_Aggregate_Fields = {
  __typename?: 'control_aggregate_fields';
  avg?: Maybe<Control_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Control_Max_Fields>;
  min?: Maybe<Control_Min_Fields>;
  stddev?: Maybe<Control_Stddev_Fields>;
  stddev_pop?: Maybe<Control_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Control_Stddev_Samp_Fields>;
  sum?: Maybe<Control_Sum_Fields>;
  var_pop?: Maybe<Control_Var_Pop_Fields>;
  var_samp?: Maybe<Control_Var_Samp_Fields>;
  variance?: Maybe<Control_Variance_Fields>;
};


/** aggregate fields of "risksmart.control" */
export type Control_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Control_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "risksmart.control_audit" */
export type Control_Audit = {
  __typename?: 'control_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title: Scalars['String']['output'];
  Type?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  actionAudits: Array<Action_Parent_Audit>;
  /** An array relationship */
  contributorAudits: Array<Contributor_Audit>;
  /** An aggregate relationship */
  contributorAudits_aggregate: Contributor_Audit_Aggregate;
  /** An array relationship */
  contributorGroupAudits: Array<Contributor_Group_Audit>;
  /** An aggregate relationship */
  contributorGroupAudits_aggregate: Contributor_Group_Audit_Aggregate;
  /** An array relationship */
  departmentAudits: Array<Department_Audit>;
  /** An array relationship */
  indicatorAudits: Array<Risksmart_Indicator_Parent_Audit>;
  /** An array relationship */
  issueAudits: Array<Issue_Parent_Audit>;
  /** An array relationship */
  ownerAudits: Array<Owner_Audit>;
  /** An array relationship */
  ownerGroupAudits: Array<Owner_Group_Audit>;
  /** An array relationship */
  testResultAudits: Array<Test_Result_Audit>;
};


/** columns and relationships of "risksmart.control_audit" */
export type Control_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.control_audit" */
export type Control_AuditActionAuditsArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Audit_Order_By>>;
  where?: InputMaybe<Action_Parent_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type Control_AuditContributorAuditsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Audit_Order_By>>;
  where?: InputMaybe<Contributor_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type Control_AuditContributorAudits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Audit_Order_By>>;
  where?: InputMaybe<Contributor_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type Control_AuditContributorGroupAuditsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Audit_Order_By>>;
  where?: InputMaybe<Contributor_Group_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type Control_AuditContributorGroupAudits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Audit_Order_By>>;
  where?: InputMaybe<Contributor_Group_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type Control_AuditDepartmentAuditsArgs = {
  distinct_on?: InputMaybe<Array<Department_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Audit_Order_By>>;
  where?: InputMaybe<Department_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type Control_AuditIndicatorAuditsArgs = {
  distinct_on?: InputMaybe<Array<Risksmart_Indicator_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risksmart_Indicator_Parent_Audit_Order_By>>;
  where?: InputMaybe<Risksmart_Indicator_Parent_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type Control_AuditIssueAuditsArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Audit_Order_By>>;
  where?: InputMaybe<Issue_Parent_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type Control_AuditOwnerAuditsArgs = {
  distinct_on?: InputMaybe<Array<Owner_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Audit_Order_By>>;
  where?: InputMaybe<Owner_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type Control_AuditOwnerGroupAuditsArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Audit_Order_By>>;
  where?: InputMaybe<Owner_Group_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type Control_AuditTestResultAuditsArgs = {
  distinct_on?: InputMaybe<Array<Test_Result_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Test_Result_Audit_Order_By>>;
  where?: InputMaybe<Test_Result_Audit_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "risksmart.control_audit". All fields are combined with a logical 'AND'. */
export type Control_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  Type?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Control_Audit_Bool_Exp>>;
  _not?: InputMaybe<Control_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Audit_Bool_Exp>>;
  actionAudits?: InputMaybe<Action_Parent_Audit_Bool_Exp>;
  contributorAudits?: InputMaybe<Contributor_Audit_Bool_Exp>;
  contributorAudits_aggregate?: InputMaybe<Contributor_Audit_Aggregate_Bool_Exp>;
  contributorGroupAudits?: InputMaybe<Contributor_Group_Audit_Bool_Exp>;
  contributorGroupAudits_aggregate?: InputMaybe<Contributor_Group_Audit_Aggregate_Bool_Exp>;
  departmentAudits?: InputMaybe<Department_Audit_Bool_Exp>;
  indicatorAudits?: InputMaybe<Risksmart_Indicator_Parent_Audit_Bool_Exp>;
  issueAudits?: InputMaybe<Issue_Parent_Audit_Bool_Exp>;
  ownerAudits?: InputMaybe<Owner_Audit_Bool_Exp>;
  ownerGroupAudits?: InputMaybe<Owner_Group_Audit_Bool_Exp>;
  testResultAudits?: InputMaybe<Test_Result_Audit_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.control_audit". */
export type Control_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  actionAudits_aggregate?: InputMaybe<Action_Parent_Audit_Aggregate_Order_By>;
  contributorAudits_aggregate?: InputMaybe<Contributor_Audit_Aggregate_Order_By>;
  contributorGroupAudits_aggregate?: InputMaybe<Contributor_Group_Audit_Aggregate_Order_By>;
  departmentAudits_aggregate?: InputMaybe<Department_Audit_Aggregate_Order_By>;
  indicatorAudits_aggregate?: InputMaybe<Risksmart_Indicator_Parent_Audit_Aggregate_Order_By>;
  issueAudits_aggregate?: InputMaybe<Issue_Parent_Audit_Aggregate_Order_By>;
  ownerAudits_aggregate?: InputMaybe<Owner_Audit_Aggregate_Order_By>;
  ownerGroupAudits_aggregate?: InputMaybe<Owner_Group_Audit_Aggregate_Order_By>;
  testResultAudits_aggregate?: InputMaybe<Test_Result_Audit_Aggregate_Order_By>;
};

/** select columns of table "risksmart.control_audit" */
export const Control_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Title: 'Title',
  /** column name */
  Type: 'Type'
} as const;

export type Control_Audit_Select_Column = typeof Control_Audit_Select_Column[keyof typeof Control_Audit_Select_Column];
/** Streaming cursor of the table "control_audit" */
export type Control_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Control_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Control_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate avg on columns */
export type Control_Avg_Fields = {
  __typename?: 'control_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.control". All fields are combined with a logical 'AND'. */
export type Control_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  Type?: InputMaybe<Control_Type_Enum_Comparison_Exp>;
  _and?: InputMaybe<Array<Control_Bool_Exp>>;
  _not?: InputMaybe<Control_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Bool_Exp>>;
  actions?: InputMaybe<Action_Parent_Bool_Exp>;
  actions_aggregate?: InputMaybe<Action_Parent_Aggregate_Bool_Exp>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  contributorGroups?: InputMaybe<Contributor_Group_Bool_Exp>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Bool_Exp>;
  contributors?: InputMaybe<Contributor_Bool_Exp>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  departments?: InputMaybe<Department_Bool_Exp>;
  indicators?: InputMaybe<Indicator_Parent_Bool_Exp>;
  indicators_aggregate?: InputMaybe<Indicator_Parent_Aggregate_Bool_Exp>;
  issues?: InputMaybe<Issue_Parent_Bool_Exp>;
  issues_aggregate?: InputMaybe<Issue_Parent_Aggregate_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  ownerGroups?: InputMaybe<Owner_Group_Bool_Exp>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Bool_Exp>;
  owners?: InputMaybe<Owner_Bool_Exp>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Bool_Exp>;
  parents?: InputMaybe<Control_Parent_Bool_Exp>;
  parents_aggregate?: InputMaybe<Control_Parent_Aggregate_Bool_Exp>;
  schedule?: InputMaybe<Schedule_Bool_Exp>;
  scheduleState?: InputMaybe<Schedule_State_Bool_Exp>;
  tags?: InputMaybe<Tag_Bool_Exp>;
  testResults?: InputMaybe<Test_Result_Bool_Exp>;
  testResults_aggregate?: InputMaybe<Test_Result_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.control" */
export const Control_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  ControlPkey: 'control_pkey',
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxControlOrgkeySequentialid: 'idx_control_orgkey_sequentialid'
} as const;

export type Control_Constraint = typeof Control_Constraint[keyof typeof Control_Constraint];
/** columns and relationships of "risksmart.control_group" */
export type Control_Group = {
  __typename?: 'control_group';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Owner: Scalars['String']['output'];
  Title: Scalars['String']['output'];
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An array relationship */
  controls: Array<Control_Parent>;
  /** An aggregate relationship */
  controls_aggregate: Control_Parent_Aggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  owner?: Maybe<User>;
};


/** columns and relationships of "risksmart.control_group" */
export type Control_GroupCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.control_group" */
export type Control_GroupAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.control_group" */
export type Control_GroupControlsArgs = {
  distinct_on?: InputMaybe<Array<Control_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Parent_Order_By>>;
  where?: InputMaybe<Control_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.control_group" */
export type Control_GroupControls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Parent_Order_By>>;
  where?: InputMaybe<Control_Parent_Bool_Exp>;
};

/** aggregated selection of "risksmart.control_group" */
export type Control_Group_Aggregate = {
  __typename?: 'control_group_aggregate';
  aggregate?: Maybe<Control_Group_Aggregate_Fields>;
  nodes: Array<Control_Group>;
};

/** aggregate fields of "risksmart.control_group" */
export type Control_Group_Aggregate_Fields = {
  __typename?: 'control_group_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Control_Group_Max_Fields>;
  min?: Maybe<Control_Group_Min_Fields>;
};


/** aggregate fields of "risksmart.control_group" */
export type Control_Group_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Control_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Control_Group_Append_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.control_group_audit" */
export type Control_Group_Audit = {
  __typename?: 'control_group_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Owner: Scalars['String']['output'];
  Title: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.control_group_audit" */
export type Control_Group_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.control_group_audit". All fields are combined with a logical 'AND'. */
export type Control_Group_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Owner?: InputMaybe<String_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Control_Group_Audit_Bool_Exp>>;
  _not?: InputMaybe<Control_Group_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Group_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.control_group_audit". */
export type Control_Group_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Owner?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.control_group_audit" */
export const Control_Group_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Owner: 'Owner',
  /** column name */
  Title: 'Title'
} as const;

export type Control_Group_Audit_Select_Column = typeof Control_Group_Audit_Select_Column[keyof typeof Control_Group_Audit_Select_Column];
/** Streaming cursor of the table "control_group_audit" */
export type Control_Group_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Control_Group_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Control_Group_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Owner?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.control_group". All fields are combined with a logical 'AND'. */
export type Control_Group_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Owner?: InputMaybe<String_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Control_Group_Bool_Exp>>;
  _not?: InputMaybe<Control_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Group_Bool_Exp>>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  controls?: InputMaybe<Control_Parent_Bool_Exp>;
  controls_aggregate?: InputMaybe<Control_Parent_Aggregate_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  owner?: InputMaybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.control_group" */
export const Control_Group_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  ControlGroupPkey: 'control_group_pkey',
  /** unique or primary key constraint on columns "OrgKey", "Title" */
  IxControlGroupOrgkeyTitle: 'ix_control_group_orgkey_title'
} as const;

export type Control_Group_Constraint = typeof Control_Group_Constraint[keyof typeof Control_Group_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Control_Group_Delete_At_Path_Input = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Control_Group_Delete_Elem_Input = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Control_Group_Delete_Key_Input = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.control_group" */
export type Control_Group_Insert_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Owner?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  controls?: InputMaybe<Control_Parent_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Control_Group_Max_Fields = {
  __typename?: 'control_group_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Owner?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Control_Group_Min_Fields = {
  __typename?: 'control_group_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Owner?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.control_group" */
export type Control_Group_Mutation_Response = {
  __typename?: 'control_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Group>;
};

/** input type for inserting object relation for remote table "risksmart.control_group" */
export type Control_Group_Obj_Rel_Insert_Input = {
  data: Control_Group_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Group_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.control_group" */
export type Control_Group_On_Conflict = {
  constraint: Control_Group_Constraint;
  update_columns?: Array<Control_Group_Update_Column>;
  where?: InputMaybe<Control_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.control_group". */
export type Control_Group_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Owner?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  controls_aggregate?: InputMaybe<Control_Parent_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  owner?: InputMaybe<User_Order_By>;
};

/** primary key columns input for table: risksmart.control_group */
export type Control_Group_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Control_Group_Prepend_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.control_group" */
export const Control_Group_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Owner: 'Owner',
  /** column name */
  Title: 'Title'
} as const;

export type Control_Group_Select_Column = typeof Control_Group_Select_Column[keyof typeof Control_Group_Select_Column];
/** input type for updating data in table "risksmart.control_group" */
export type Control_Group_Set_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Owner?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "control_group" */
export type Control_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Control_Group_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Control_Group_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Owner?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.control_group" */
export const Control_Group_Update_Column = {
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Owner: 'Owner',
  /** column name */
  Title: 'Title'
} as const;

export type Control_Group_Update_Column = typeof Control_Group_Update_Column[keyof typeof Control_Group_Update_Column];
export type Control_Group_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Control_Group_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Control_Group_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Control_Group_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Control_Group_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Control_Group_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Control_Group_Set_Input>;
  /** filter the rows which have to be updated */
  where: Control_Group_Bool_Exp;
};

/** input type for inserting data into table "risksmart.control" */
export type Control_Insert_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Control_Type_Enum>;
  actions?: InputMaybe<Action_Parent_Arr_Rel_Insert_Input>;
  contributorGroups?: InputMaybe<Contributor_Group_Arr_Rel_Insert_Input>;
  contributors?: InputMaybe<Contributor_Arr_Rel_Insert_Input>;
  departments?: InputMaybe<Department_Arr_Rel_Insert_Input>;
  indicators?: InputMaybe<Indicator_Parent_Arr_Rel_Insert_Input>;
  issues?: InputMaybe<Issue_Parent_Arr_Rel_Insert_Input>;
  ownerGroups?: InputMaybe<Owner_Group_Arr_Rel_Insert_Input>;
  owners?: InputMaybe<Owner_Arr_Rel_Insert_Input>;
  parents?: InputMaybe<Control_Parent_Arr_Rel_Insert_Input>;
  schedule?: InputMaybe<Schedule_Obj_Rel_Insert_Input>;
  tags?: InputMaybe<Tag_Arr_Rel_Insert_Input>;
  testResults?: InputMaybe<Test_Result_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Control_Max_Fields = {
  __typename?: 'control_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Control_Min_Fields = {
  __typename?: 'control_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.control" */
export type Control_Mutation_Response = {
  __typename?: 'control_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control>;
};

/** input type for inserting object relation for remote table "risksmart.control" */
export type Control_Obj_Rel_Insert_Input = {
  data: Control_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.control" */
export type Control_On_Conflict = {
  constraint: Control_Constraint;
  update_columns?: Array<Control_Update_Column>;
  where?: InputMaybe<Control_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.control". */
export type Control_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  actions_aggregate?: InputMaybe<Action_Parent_Aggregate_Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Order_By>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  departments_aggregate?: InputMaybe<Department_Aggregate_Order_By>;
  indicators_aggregate?: InputMaybe<Indicator_Parent_Aggregate_Order_By>;
  issues_aggregate?: InputMaybe<Issue_Parent_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Order_By>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Order_By>;
  parents_aggregate?: InputMaybe<Control_Parent_Aggregate_Order_By>;
  schedule?: InputMaybe<Schedule_Order_By>;
  scheduleState?: InputMaybe<Schedule_State_Order_By>;
  tags_aggregate?: InputMaybe<Tag_Aggregate_Order_By>;
  testResults_aggregate?: InputMaybe<Test_Result_Aggregate_Order_By>;
};

/** columns and relationships of "risksmart.control_parent" */
export type Control_Parent = {
  __typename?: 'control_parent';
  ControlId: Scalars['uuid']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  /** An object relationship */
  control?: Maybe<Control>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  group?: Maybe<Control_Group>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  obligation?: Maybe<Obligation>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parent?: Maybe<Node>;
  /** An object relationship */
  risk?: Maybe<Risk>;
  /** An object relationship */
  thirdParty?: Maybe<Third_Party>;
};

/** aggregated selection of "risksmart.control_parent" */
export type Control_Parent_Aggregate = {
  __typename?: 'control_parent_aggregate';
  aggregate?: Maybe<Control_Parent_Aggregate_Fields>;
  nodes: Array<Control_Parent>;
};

export type Control_Parent_Aggregate_Bool_Exp = {
  count?: InputMaybe<Control_Parent_Aggregate_Bool_Exp_Count>;
};

export type Control_Parent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Control_Parent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Control_Parent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.control_parent" */
export type Control_Parent_Aggregate_Fields = {
  __typename?: 'control_parent_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Control_Parent_Max_Fields>;
  min?: Maybe<Control_Parent_Min_Fields>;
};


/** aggregate fields of "risksmart.control_parent" */
export type Control_Parent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Control_Parent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.control_parent" */
export type Control_Parent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Control_Parent_Max_Order_By>;
  min?: InputMaybe<Control_Parent_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.control_parent" */
export type Control_Parent_Arr_Rel_Insert_Input = {
  data: Array<Control_Parent_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Parent_On_Conflict>;
};

/** columns and relationships of "risksmart.control_parent_audit" */
export type Control_Parent_Audit = {
  __typename?: 'control_parent_audit';
  ControlId: Scalars['uuid']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId: Scalars['uuid']['output'];
};

/** order by aggregate values of table "risksmart.control_parent_audit" */
export type Control_Parent_Audit_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Control_Parent_Audit_Max_Order_By>;
  min?: InputMaybe<Control_Parent_Audit_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.control_parent_audit". All fields are combined with a logical 'AND'. */
export type Control_Parent_Audit_Bool_Exp = {
  ControlId?: InputMaybe<Uuid_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Control_Parent_Audit_Bool_Exp>>;
  _not?: InputMaybe<Control_Parent_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Parent_Audit_Bool_Exp>>;
};

/** order by max() on columns of table "risksmart.control_parent_audit" */
export type Control_Parent_Audit_Max_Order_By = {
  ControlId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.control_parent_audit" */
export type Control_Parent_Audit_Min_Order_By = {
  ControlId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "risksmart.control_parent_audit". */
export type Control_Parent_Audit_Order_By = {
  ControlId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.control_parent_audit" */
export const Control_Parent_Audit_Select_Column = {
  /** column name */
  ControlId: 'ControlId',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId'
} as const;

export type Control_Parent_Audit_Select_Column = typeof Control_Parent_Audit_Select_Column[keyof typeof Control_Parent_Audit_Select_Column];
/** Streaming cursor of the table "control_parent_audit" */
export type Control_Parent_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Control_Parent_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Control_Parent_Audit_Stream_Cursor_Value_Input = {
  ControlId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.control_parent". All fields are combined with a logical 'AND'. */
export type Control_Parent_Bool_Exp = {
  ControlId?: InputMaybe<Uuid_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Control_Parent_Bool_Exp>>;
  _not?: InputMaybe<Control_Parent_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Parent_Bool_Exp>>;
  control?: InputMaybe<Control_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  group?: InputMaybe<Control_Group_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  obligation?: InputMaybe<Obligation_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parent?: InputMaybe<Node_Bool_Exp>;
  risk?: InputMaybe<Risk_Bool_Exp>;
  thirdParty?: InputMaybe<Third_Party_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.control_parent" */
export const Control_Parent_Constraint = {
  /** unique or primary key constraint on columns "ParentId", "ControlId" */
  ControlParentPkey: 'control_parent_pkey'
} as const;

export type Control_Parent_Constraint = typeof Control_Parent_Constraint[keyof typeof Control_Parent_Constraint];
/** input type for inserting data into table "risksmart.control_parent" */
export type Control_Parent_Insert_Input = {
  ControlId?: InputMaybe<Scalars['uuid']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  control?: InputMaybe<Control_Obj_Rel_Insert_Input>;
  group?: InputMaybe<Control_Group_Obj_Rel_Insert_Input>;
  obligation?: InputMaybe<Obligation_Obj_Rel_Insert_Input>;
  risk?: InputMaybe<Risk_Obj_Rel_Insert_Input>;
  thirdParty?: InputMaybe<Third_Party_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Control_Parent_Max_Fields = {
  __typename?: 'control_parent_max_fields';
  ControlId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.control_parent" */
export type Control_Parent_Max_Order_By = {
  ControlId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Control_Parent_Min_Fields = {
  __typename?: 'control_parent_min_fields';
  ControlId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.control_parent" */
export type Control_Parent_Min_Order_By = {
  ControlId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.control_parent" */
export type Control_Parent_Mutation_Response = {
  __typename?: 'control_parent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Parent>;
};

/** on_conflict condition type for table "risksmart.control_parent" */
export type Control_Parent_On_Conflict = {
  constraint: Control_Parent_Constraint;
  update_columns?: Array<Control_Parent_Update_Column>;
  where?: InputMaybe<Control_Parent_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.control_parent". */
export type Control_Parent_Order_By = {
  ControlId?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  control?: InputMaybe<Control_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  group?: InputMaybe<Control_Group_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  obligation?: InputMaybe<Obligation_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parent?: InputMaybe<Node_Order_By>;
  risk?: InputMaybe<Risk_Order_By>;
  thirdParty?: InputMaybe<Third_Party_Order_By>;
};

/** select columns of table "risksmart.control_parent" */
export const Control_Parent_Select_Column = {
  /** column name */
  ControlId: 'ControlId',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId'
} as const;

export type Control_Parent_Select_Column = typeof Control_Parent_Select_Column[keyof typeof Control_Parent_Select_Column];
/** Streaming cursor of the table "control_parent" */
export type Control_Parent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Control_Parent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Control_Parent_Stream_Cursor_Value_Input = {
  ControlId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** placeholder for update columns of table "risksmart.control_parent" (current role has no relevant permissions) */
export const Control_Parent_Update_Column = {
  /** placeholder (do not use) */
  Placeholder: '_PLACEHOLDER'
} as const;

export type Control_Parent_Update_Column = typeof Control_Parent_Update_Column[keyof typeof Control_Parent_Update_Column];
/** select columns of table "risksmart.control" */
export const Control_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Title: 'Title',
  /** column name */
  Type: 'Type'
} as const;

export type Control_Select_Column = typeof Control_Select_Column[keyof typeof Control_Select_Column];
/** aggregate stddev on columns */
export type Control_Stddev_Fields = {
  __typename?: 'control_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Control_Stddev_Pop_Fields = {
  __typename?: 'control_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Control_Stddev_Samp_Fields = {
  __typename?: 'control_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "control" */
export type Control_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Control_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Control_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Control_Type_Enum>;
};

/** aggregate sum on columns */
export type Control_Sum_Fields = {
  __typename?: 'control_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

export const Control_Type_Enum = {
  /** Corrective */
  Corrective: 'Corrective',
  /** Detective */
  Detective: 'Detective',
  /** Directive */
  Directive: 'Directive',
  /** Preventive */
  Preventive: 'Preventive'
} as const;

export type Control_Type_Enum = typeof Control_Type_Enum[keyof typeof Control_Type_Enum];
/** Boolean expression to compare columns of type "control_type_enum". All fields are combined with logical 'AND'. */
export type Control_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Control_Type_Enum>;
  _in?: InputMaybe<Array<Control_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Control_Type_Enum>;
  _nin?: InputMaybe<Array<Control_Type_Enum>>;
};

/** update columns of table "risksmart.control" */
export const Control_Update_Column = {
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Title: 'Title',
  /** column name */
  Type: 'Type'
} as const;

export type Control_Update_Column = typeof Control_Update_Column[keyof typeof Control_Update_Column];
/** aggregate var_pop on columns */
export type Control_Var_Pop_Fields = {
  __typename?: 'control_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Control_Var_Samp_Fields = {
  __typename?: 'control_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Control_Variance_Fields = {
  __typename?: 'control_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.conversation" */
export type Conversation = {
  __typename?: 'conversation';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  IsResolved?: Maybe<Scalars['Boolean']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  comments: Array<Comment>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parent?: Maybe<Node>;
};


/** columns and relationships of "risksmart.conversation" */
export type ConversationCommentsArgs = {
  distinct_on?: InputMaybe<Array<Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Order_By>>;
  where?: InputMaybe<Comment_Bool_Exp>;
};

/** columns and relationships of "risksmart.conversation_audit" */
export type Conversation_Audit = {
  __typename?: 'conversation_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  IsResolved?: Maybe<Scalars['Boolean']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.conversation_audit". All fields are combined with a logical 'AND'. */
export type Conversation_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  IsResolved?: InputMaybe<Boolean_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Conversation_Audit_Bool_Exp>>;
  _not?: InputMaybe<Conversation_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Conversation_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.conversation_audit". */
export type Conversation_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  IsResolved?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.conversation_audit" */
export const Conversation_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  IsResolved: 'IsResolved',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId'
} as const;

export type Conversation_Audit_Select_Column = typeof Conversation_Audit_Select_Column[keyof typeof Conversation_Audit_Select_Column];
/** Streaming cursor of the table "conversation_audit" */
export type Conversation_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Conversation_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Conversation_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IsResolved?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.conversation". All fields are combined with a logical 'AND'. */
export type Conversation_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  IsResolved?: InputMaybe<Boolean_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Conversation_Bool_Exp>>;
  _not?: InputMaybe<Conversation_Bool_Exp>;
  _or?: InputMaybe<Array<Conversation_Bool_Exp>>;
  comments?: InputMaybe<Comment_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parent?: InputMaybe<Node_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.conversation" */
export const Conversation_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  ConversationPkey: 'conversation_pkey'
} as const;

export type Conversation_Constraint = typeof Conversation_Constraint[keyof typeof Conversation_Constraint];
/** input type for inserting data into table "risksmart.conversation" */
export type Conversation_Insert_Input = {
  IsResolved?: InputMaybe<Scalars['Boolean']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  comments?: InputMaybe<Comment_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "risksmart.conversation" */
export type Conversation_Mutation_Response = {
  __typename?: 'conversation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Conversation>;
};

/** input type for inserting object relation for remote table "risksmart.conversation" */
export type Conversation_Obj_Rel_Insert_Input = {
  data: Conversation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Conversation_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.conversation" */
export type Conversation_On_Conflict = {
  constraint: Conversation_Constraint;
  update_columns?: Array<Conversation_Update_Column>;
  where?: InputMaybe<Conversation_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.conversation". */
export type Conversation_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  IsResolved?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  comments_aggregate?: InputMaybe<Comment_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parent?: InputMaybe<Node_Order_By>;
};

/** primary key columns input for table: risksmart.conversation */
export type Conversation_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.conversation" */
export const Conversation_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  IsResolved: 'IsResolved',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId'
} as const;

export type Conversation_Select_Column = typeof Conversation_Select_Column[keyof typeof Conversation_Select_Column];
/** input type for updating data in table "risksmart.conversation" */
export type Conversation_Set_Input = {
  IsResolved?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "conversation" */
export type Conversation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Conversation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Conversation_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IsResolved?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.conversation" */
export const Conversation_Update_Column = {
  /** column name */
  IsResolved: 'IsResolved'
} as const;

export type Conversation_Update_Column = typeof Conversation_Update_Column[keyof typeof Conversation_Update_Column];
export type Conversation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Conversation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Conversation_Bool_Exp;
};

export const Cost_Type_Enum = {
  /** Customers impacted */
  CustomersImpacted: 'customers_impacted',
  /** Financial */
  Financial: 'financial',
  /** Hours */
  Hours: 'hours',
  /** Number */
  Number: 'number'
} as const;

export type Cost_Type_Enum = typeof Cost_Type_Enum[keyof typeof Cost_Type_Enum];
/** Boolean expression to compare columns of type "cost_type_enum". All fields are combined with logical 'AND'. */
export type Cost_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Cost_Type_Enum>;
  _in?: InputMaybe<Array<Cost_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Cost_Type_Enum>;
  _nin?: InputMaybe<Array<Cost_Type_Enum>>;
};

/** ordering argument of a cursor */
export const Cursor_Ordering = {
  /** ascending ordering of the cursor */
  Asc: 'ASC',
  /** descending ordering of the cursor */
  Desc: 'DESC'
} as const;

export type Cursor_Ordering = typeof Cursor_Ordering[keyof typeof Cursor_Ordering];
/** columns and relationships of "risksmart.custom_attribute_schema" */
export type Custom_Attribute_Schema = {
  __typename?: 'custom_attribute_schema';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Schema: Scalars['jsonb']['output'];
  Title: Scalars['String']['output'];
  UiSchema: Scalars['jsonb']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  form?: Maybe<Form_Configuration>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
};


/** columns and relationships of "risksmart.custom_attribute_schema" */
export type Custom_Attribute_SchemaSchemaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.custom_attribute_schema" */
export type Custom_Attribute_SchemaUiSchemaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Custom_Attribute_Schema_Append_Input = {
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  UiSchema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.custom_attribute_schema_audit" */
export type Custom_Attribute_Schema_Audit = {
  __typename?: 'custom_attribute_schema_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Schema: Scalars['jsonb']['output'];
  Title: Scalars['String']['output'];
  UiSchema: Scalars['jsonb']['output'];
};


/** columns and relationships of "risksmart.custom_attribute_schema_audit" */
export type Custom_Attribute_Schema_AuditSchemaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.custom_attribute_schema_audit" */
export type Custom_Attribute_Schema_AuditUiSchemaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.custom_attribute_schema_audit". All fields are combined with a logical 'AND'. */
export type Custom_Attribute_Schema_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Schema?: InputMaybe<Jsonb_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  UiSchema?: InputMaybe<Jsonb_Comparison_Exp>;
  _and?: InputMaybe<Array<Custom_Attribute_Schema_Audit_Bool_Exp>>;
  _not?: InputMaybe<Custom_Attribute_Schema_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Custom_Attribute_Schema_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.custom_attribute_schema_audit". */
export type Custom_Attribute_Schema_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Schema?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  UiSchema?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.custom_attribute_schema_audit" */
export const Custom_Attribute_Schema_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Schema: 'Schema',
  /** column name */
  Title: 'Title',
  /** column name */
  UiSchema: 'UiSchema'
} as const;

export type Custom_Attribute_Schema_Audit_Select_Column = typeof Custom_Attribute_Schema_Audit_Select_Column[keyof typeof Custom_Attribute_Schema_Audit_Select_Column];
/** Streaming cursor of the table "custom_attribute_schema_audit" */
export type Custom_Attribute_Schema_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Custom_Attribute_Schema_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Custom_Attribute_Schema_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  UiSchema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.custom_attribute_schema". All fields are combined with a logical 'AND'. */
export type Custom_Attribute_Schema_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Schema?: InputMaybe<Jsonb_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  UiSchema?: InputMaybe<Jsonb_Comparison_Exp>;
  _and?: InputMaybe<Array<Custom_Attribute_Schema_Bool_Exp>>;
  _not?: InputMaybe<Custom_Attribute_Schema_Bool_Exp>;
  _or?: InputMaybe<Array<Custom_Attribute_Schema_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  form?: InputMaybe<Form_Configuration_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.custom_attribute_schema" */
export const Custom_Attribute_Schema_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  CustomAttributeSchemaPkey: 'custom_attribute_schema_pkey'
} as const;

export type Custom_Attribute_Schema_Constraint = typeof Custom_Attribute_Schema_Constraint[keyof typeof Custom_Attribute_Schema_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Custom_Attribute_Schema_Delete_At_Path_Input = {
  Schema?: InputMaybe<Array<Scalars['String']['input']>>;
  UiSchema?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Custom_Attribute_Schema_Delete_Elem_Input = {
  Schema?: InputMaybe<Scalars['Int']['input']>;
  UiSchema?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Custom_Attribute_Schema_Delete_Key_Input = {
  Schema?: InputMaybe<Scalars['String']['input']>;
  UiSchema?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.custom_attribute_schema" */
export type Custom_Attribute_Schema_Insert_Input = {
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  UiSchema?: InputMaybe<Scalars['jsonb']['input']>;
  form?: InputMaybe<Form_Configuration_Obj_Rel_Insert_Input>;
};

/** response of any mutation on the table "risksmart.custom_attribute_schema" */
export type Custom_Attribute_Schema_Mutation_Response = {
  __typename?: 'custom_attribute_schema_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Custom_Attribute_Schema>;
};

/** input type for inserting object relation for remote table "risksmart.custom_attribute_schema" */
export type Custom_Attribute_Schema_Obj_Rel_Insert_Input = {
  data: Custom_Attribute_Schema_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Custom_Attribute_Schema_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.custom_attribute_schema" */
export type Custom_Attribute_Schema_On_Conflict = {
  constraint: Custom_Attribute_Schema_Constraint;
  update_columns?: Array<Custom_Attribute_Schema_Update_Column>;
  where?: InputMaybe<Custom_Attribute_Schema_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.custom_attribute_schema". */
export type Custom_Attribute_Schema_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Schema?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  UiSchema?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  form?: InputMaybe<Form_Configuration_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
};

/** primary key columns input for table: risksmart.custom_attribute_schema */
export type Custom_Attribute_Schema_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Custom_Attribute_Schema_Prepend_Input = {
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  UiSchema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.custom_attribute_schema" */
export const Custom_Attribute_Schema_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Schema: 'Schema',
  /** column name */
  Title: 'Title',
  /** column name */
  UiSchema: 'UiSchema'
} as const;

export type Custom_Attribute_Schema_Select_Column = typeof Custom_Attribute_Schema_Select_Column[keyof typeof Custom_Attribute_Schema_Select_Column];
/** input type for updating data in table "risksmart.custom_attribute_schema" */
export type Custom_Attribute_Schema_Set_Input = {
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  UiSchema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "custom_attribute_schema" */
export type Custom_Attribute_Schema_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Custom_Attribute_Schema_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Custom_Attribute_Schema_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  UiSchema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "risksmart.custom_attribute_schema" */
export const Custom_Attribute_Schema_Update_Column = {
  /** column name */
  Schema: 'Schema',
  /** column name */
  Title: 'Title',
  /** column name */
  UiSchema: 'UiSchema'
} as const;

export type Custom_Attribute_Schema_Update_Column = typeof Custom_Attribute_Schema_Update_Column[keyof typeof Custom_Attribute_Schema_Update_Column];
export type Custom_Attribute_Schema_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Custom_Attribute_Schema_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Custom_Attribute_Schema_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Custom_Attribute_Schema_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Custom_Attribute_Schema_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Custom_Attribute_Schema_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Custom_Attribute_Schema_Set_Input>;
  /** filter the rows which have to be updated */
  where: Custom_Attribute_Schema_Bool_Exp;
};

/** columns and relationships of "risksmart.custom_ribbon" */
export type Custom_Ribbon = {
  __typename?: 'custom_ribbon';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Filters: Scalars['jsonb']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentType: Parent_Type_Enum;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
};


/** columns and relationships of "risksmart.custom_ribbon" */
export type Custom_RibbonFiltersArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Custom_Ribbon_Append_Input = {
  Filters?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.custom_ribbon_audit" */
export type Custom_Ribbon_Audit = {
  __typename?: 'custom_ribbon_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Filters: Scalars['jsonb']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentType: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.custom_ribbon_audit" */
export type Custom_Ribbon_AuditFiltersArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.custom_ribbon_audit". All fields are combined with a logical 'AND'. */
export type Custom_Ribbon_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Filters?: InputMaybe<Jsonb_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentType?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Custom_Ribbon_Audit_Bool_Exp>>;
  _not?: InputMaybe<Custom_Ribbon_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Custom_Ribbon_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.custom_ribbon_audit". */
export type Custom_Ribbon_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Filters?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentType?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.custom_ribbon_audit" */
export const Custom_Ribbon_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Filters: 'Filters',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentType: 'ParentType'
} as const;

export type Custom_Ribbon_Audit_Select_Column = typeof Custom_Ribbon_Audit_Select_Column[keyof typeof Custom_Ribbon_Audit_Select_Column];
/** Streaming cursor of the table "custom_ribbon_audit" */
export type Custom_Ribbon_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Custom_Ribbon_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Custom_Ribbon_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Filters?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.custom_ribbon". All fields are combined with a logical 'AND'. */
export type Custom_Ribbon_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Filters?: InputMaybe<Jsonb_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentType?: InputMaybe<Parent_Type_Enum_Comparison_Exp>;
  _and?: InputMaybe<Array<Custom_Ribbon_Bool_Exp>>;
  _not?: InputMaybe<Custom_Ribbon_Bool_Exp>;
  _or?: InputMaybe<Array<Custom_Ribbon_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.custom_ribbon" */
export const Custom_Ribbon_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  CustomRibbonPkey: 'custom_ribbon_pkey',
  /** unique or primary key constraint on columns "OrgKey", "ParentType" */
  IdxCustomribbonOrgkeyParenttype: 'idx_customribbon_orgkey_parenttype'
} as const;

export type Custom_Ribbon_Constraint = typeof Custom_Ribbon_Constraint[keyof typeof Custom_Ribbon_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Custom_Ribbon_Delete_At_Path_Input = {
  Filters?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Custom_Ribbon_Delete_Elem_Input = {
  Filters?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Custom_Ribbon_Delete_Key_Input = {
  Filters?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.custom_ribbon" */
export type Custom_Ribbon_Insert_Input = {
  Filters?: InputMaybe<Scalars['jsonb']['input']>;
  ParentType?: InputMaybe<Parent_Type_Enum>;
};

/** response of any mutation on the table "risksmart.custom_ribbon" */
export type Custom_Ribbon_Mutation_Response = {
  __typename?: 'custom_ribbon_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Custom_Ribbon>;
};

/** on_conflict condition type for table "risksmart.custom_ribbon" */
export type Custom_Ribbon_On_Conflict = {
  constraint: Custom_Ribbon_Constraint;
  update_columns?: Array<Custom_Ribbon_Update_Column>;
  where?: InputMaybe<Custom_Ribbon_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.custom_ribbon". */
export type Custom_Ribbon_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Filters?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentType?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
};

/** primary key columns input for table: risksmart.custom_ribbon */
export type Custom_Ribbon_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Custom_Ribbon_Prepend_Input = {
  Filters?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.custom_ribbon" */
export const Custom_Ribbon_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Filters: 'Filters',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentType: 'ParentType'
} as const;

export type Custom_Ribbon_Select_Column = typeof Custom_Ribbon_Select_Column[keyof typeof Custom_Ribbon_Select_Column];
/** input type for updating data in table "risksmart.custom_ribbon" */
export type Custom_Ribbon_Set_Input = {
  Filters?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "custom_ribbon" */
export type Custom_Ribbon_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Custom_Ribbon_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Custom_Ribbon_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Filters?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentType?: InputMaybe<Parent_Type_Enum>;
};

/** update columns of table "risksmart.custom_ribbon" */
export const Custom_Ribbon_Update_Column = {
  /** column name */
  Filters: 'Filters'
} as const;

export type Custom_Ribbon_Update_Column = typeof Custom_Ribbon_Update_Column[keyof typeof Custom_Ribbon_Update_Column];
export type Custom_Ribbon_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Custom_Ribbon_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Custom_Ribbon_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Custom_Ribbon_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Custom_Ribbon_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Custom_Ribbon_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Custom_Ribbon_Set_Input>;
  /** filter the rows which have to be updated */
  where: Custom_Ribbon_Bool_Exp;
};

/** Saved dashboards */
export type Dashboard = {
  __typename?: 'dashboard';
  Content?: Maybe<Scalars['jsonb']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  Name: Scalars['String']['output'];
  Sharing: Dashboard_Sharing_Type_Enum;
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An array relationship */
  contributorGroups: Array<Contributor_Group>;
  /** An aggregate relationship */
  contributorGroups_aggregate: Contributor_Group_Aggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: Contributor_Aggregate;
  /** An array relationship */
  ownerGroups: Array<Owner_Group>;
  /** An aggregate relationship */
  ownerGroups_aggregate: Owner_Group_Aggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: Owner_Aggregate;
};


/** Saved dashboards */
export type DashboardContentArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** Saved dashboards */
export type DashboardAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** Saved dashboards */
export type DashboardContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** Saved dashboards */
export type DashboardContributorGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** Saved dashboards */
export type DashboardContributorsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** Saved dashboards */
export type DashboardContributors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** Saved dashboards */
export type DashboardOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** Saved dashboards */
export type DashboardOwnerGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** Saved dashboards */
export type DashboardOwnersArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** Saved dashboards */
export type DashboardOwners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};

/** columns and relationships of "risksmart.dashboard_audit" */
export type Dashboard_Audit = {
  __typename?: 'dashboard_audit';
  Content?: Maybe<Scalars['jsonb']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  Sharing?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "risksmart.dashboard_audit" */
export type Dashboard_AuditContentArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.dashboard_audit". All fields are combined with a logical 'AND'. */
export type Dashboard_Audit_Bool_Exp = {
  Content?: InputMaybe<Jsonb_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  Sharing?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Dashboard_Audit_Bool_Exp>>;
  _not?: InputMaybe<Dashboard_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Dashboard_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.dashboard_audit". */
export type Dashboard_Audit_Order_By = {
  Content?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  Sharing?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.dashboard_audit" */
export const Dashboard_Audit_Select_Column = {
  /** column name */
  Content: 'Content',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Name: 'Name',
  /** column name */
  Sharing: 'Sharing'
} as const;

export type Dashboard_Audit_Select_Column = typeof Dashboard_Audit_Select_Column[keyof typeof Dashboard_Audit_Select_Column];
/** Streaming cursor of the table "dashboard_audit" */
export type Dashboard_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dashboard_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dashboard_Audit_Stream_Cursor_Value_Input = {
  Content?: InputMaybe<Scalars['jsonb']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  Sharing?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.dashboard". All fields are combined with a logical 'AND'. */
export type Dashboard_Bool_Exp = {
  Content?: InputMaybe<Jsonb_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  Sharing?: InputMaybe<Dashboard_Sharing_Type_Enum_Comparison_Exp>;
  _and?: InputMaybe<Array<Dashboard_Bool_Exp>>;
  _not?: InputMaybe<Dashboard_Bool_Exp>;
  _or?: InputMaybe<Array<Dashboard_Bool_Exp>>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  contributorGroups?: InputMaybe<Contributor_Group_Bool_Exp>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Bool_Exp>;
  contributors?: InputMaybe<Contributor_Bool_Exp>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Bool_Exp>;
  ownerGroups?: InputMaybe<Owner_Group_Bool_Exp>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Bool_Exp>;
  owners?: InputMaybe<Owner_Bool_Exp>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.dashboard" */
export const Dashboard_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  DashboardPkey: 'dashboard_pkey'
} as const;

export type Dashboard_Constraint = typeof Dashboard_Constraint[keyof typeof Dashboard_Constraint];
/** input type for inserting data into table "risksmart.dashboard" */
export type Dashboard_Insert_Input = {
  Content?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  Sharing?: InputMaybe<Dashboard_Sharing_Type_Enum>;
  contributorGroups?: InputMaybe<Contributor_Group_Arr_Rel_Insert_Input>;
  contributors?: InputMaybe<Contributor_Arr_Rel_Insert_Input>;
  ownerGroups?: InputMaybe<Owner_Group_Arr_Rel_Insert_Input>;
  owners?: InputMaybe<Owner_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "risksmart.dashboard" */
export type Dashboard_Mutation_Response = {
  __typename?: 'dashboard_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Dashboard>;
};

/** input type for inserting object relation for remote table "risksmart.dashboard" */
export type Dashboard_Obj_Rel_Insert_Input = {
  data: Dashboard_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Dashboard_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.dashboard" */
export type Dashboard_On_Conflict = {
  constraint: Dashboard_Constraint;
  update_columns?: Array<Dashboard_Update_Column>;
  where?: InputMaybe<Dashboard_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.dashboard". */
export type Dashboard_Order_By = {
  Content?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  Sharing?: InputMaybe<Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Order_By>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Order_By>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Order_By>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Order_By>;
};

/** select columns of table "risksmart.dashboard" */
export const Dashboard_Select_Column = {
  /** column name */
  Content: 'Content',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  Name: 'Name',
  /** column name */
  Sharing: 'Sharing'
} as const;

export type Dashboard_Select_Column = typeof Dashboard_Select_Column[keyof typeof Dashboard_Select_Column];
export const Dashboard_Sharing_Type_Enum = {
  /** Custom */
  Custom: 'custom',
  /** Organisation */
  Organisation: 'organisation',
  /** Private */
  UserOnly: 'user_only'
} as const;

export type Dashboard_Sharing_Type_Enum = typeof Dashboard_Sharing_Type_Enum[keyof typeof Dashboard_Sharing_Type_Enum];
export const Dashboard_Sharing_Type_Enum_Action = {
  /** Custom */
  Custom: 'custom',
  /** Organisation */
  Organisation: 'organisation',
  /** Private */
  UserOnly: 'user_only'
} as const;

export type Dashboard_Sharing_Type_Enum_Action = typeof Dashboard_Sharing_Type_Enum_Action[keyof typeof Dashboard_Sharing_Type_Enum_Action];
/** Boolean expression to compare columns of type "dashboard_sharing_type_enum". All fields are combined with logical 'AND'. */
export type Dashboard_Sharing_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Dashboard_Sharing_Type_Enum>;
  _in?: InputMaybe<Array<Dashboard_Sharing_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Dashboard_Sharing_Type_Enum>;
  _nin?: InputMaybe<Array<Dashboard_Sharing_Type_Enum>>;
};

/** Streaming cursor of the table "dashboard" */
export type Dashboard_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dashboard_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dashboard_Stream_Cursor_Value_Input = {
  Content?: InputMaybe<Scalars['jsonb']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  Sharing?: InputMaybe<Dashboard_Sharing_Type_Enum>;
};

/** update columns of table "risksmart.dashboard" */
export const Dashboard_Update_Column = {
  /** column name */
  Content: 'Content',
  /** column name */
  Description: 'Description',
  /** column name */
  Name: 'Name',
  /** column name */
  Sharing: 'Sharing'
} as const;

export type Dashboard_Update_Column = typeof Dashboard_Update_Column[keyof typeof Dashboard_Update_Column];
/** columns and relationships of "risksmart.data_import" */
export type Data_Import = {
  __typename?: 'data_import';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Status: Data_Import_Status_Enum;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  files: Array<Relation_File>;
  /** An aggregate relationship */
  files_aggregate: Relation_File_Aggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
};


/** columns and relationships of "risksmart.data_import" */
export type Data_ImportFilesArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.data_import" */
export type Data_ImportFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};

/** aggregated selection of "risksmart.data_import" */
export type Data_Import_Aggregate = {
  __typename?: 'data_import_aggregate';
  aggregate?: Maybe<Data_Import_Aggregate_Fields>;
  nodes: Array<Data_Import>;
};

/** aggregate fields of "risksmart.data_import" */
export type Data_Import_Aggregate_Fields = {
  __typename?: 'data_import_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Data_Import_Max_Fields>;
  min?: Maybe<Data_Import_Min_Fields>;
};


/** aggregate fields of "risksmart.data_import" */
export type Data_Import_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Data_Import_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.data_import". All fields are combined with a logical 'AND'. */
export type Data_Import_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Status?: InputMaybe<Data_Import_Status_Enum_Comparison_Exp>;
  _and?: InputMaybe<Array<Data_Import_Bool_Exp>>;
  _not?: InputMaybe<Data_Import_Bool_Exp>;
  _or?: InputMaybe<Array<Data_Import_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  files?: InputMaybe<Relation_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.data_import" */
export const Data_Import_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  DataImportPkey: 'data_import_pkey'
} as const;

export type Data_Import_Constraint = typeof Data_Import_Constraint[keyof typeof Data_Import_Constraint];
/** columns and relationships of "risksmart.data_import_error" */
export type Data_Import_Error = {
  __typename?: 'data_import_error';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  DataImportId?: Maybe<Scalars['uuid']['output']>;
  Id: Scalars['uuid']['output'];
  ImportObject: Scalars['String']['output'];
  Message: Scalars['String']['output'];
  RowNumber: Scalars['Int']['output'];
};

/** aggregated selection of "risksmart.data_import_error" */
export type Data_Import_Error_Aggregate = {
  __typename?: 'data_import_error_aggregate';
  aggregate?: Maybe<Data_Import_Error_Aggregate_Fields>;
  nodes: Array<Data_Import_Error>;
};

/** aggregate fields of "risksmart.data_import_error" */
export type Data_Import_Error_Aggregate_Fields = {
  __typename?: 'data_import_error_aggregate_fields';
  avg?: Maybe<Data_Import_Error_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Data_Import_Error_Max_Fields>;
  min?: Maybe<Data_Import_Error_Min_Fields>;
  stddev?: Maybe<Data_Import_Error_Stddev_Fields>;
  stddev_pop?: Maybe<Data_Import_Error_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Data_Import_Error_Stddev_Samp_Fields>;
  sum?: Maybe<Data_Import_Error_Sum_Fields>;
  var_pop?: Maybe<Data_Import_Error_Var_Pop_Fields>;
  var_samp?: Maybe<Data_Import_Error_Var_Samp_Fields>;
  variance?: Maybe<Data_Import_Error_Variance_Fields>;
};


/** aggregate fields of "risksmart.data_import_error" */
export type Data_Import_Error_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Data_Import_Error_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Data_Import_Error_Avg_Fields = {
  __typename?: 'data_import_error_avg_fields';
  RowNumber?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.data_import_error". All fields are combined with a logical 'AND'. */
export type Data_Import_Error_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  DataImportId?: InputMaybe<Uuid_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ImportObject?: InputMaybe<String_Comparison_Exp>;
  Message?: InputMaybe<String_Comparison_Exp>;
  RowNumber?: InputMaybe<Int_Comparison_Exp>;
  _and?: InputMaybe<Array<Data_Import_Error_Bool_Exp>>;
  _not?: InputMaybe<Data_Import_Error_Bool_Exp>;
  _or?: InputMaybe<Array<Data_Import_Error_Bool_Exp>>;
};

/** unique or primary key constraints on table "risksmart.data_import_error" */
export const Data_Import_Error_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  DataImportErrorPkey: 'data_import_error_pkey'
} as const;

export type Data_Import_Error_Constraint = typeof Data_Import_Error_Constraint[keyof typeof Data_Import_Error_Constraint];
/** input type for inserting data into table "risksmart.data_import_error" */
export type Data_Import_Error_Insert_Input = {
  DataImportId?: InputMaybe<Scalars['uuid']['input']>;
  ImportObject?: InputMaybe<Scalars['String']['input']>;
  Message?: InputMaybe<Scalars['String']['input']>;
  RowNumber?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Data_Import_Error_Max_Fields = {
  __typename?: 'data_import_error_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DataImportId?: Maybe<Scalars['uuid']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImportObject?: Maybe<Scalars['String']['output']>;
  Message?: Maybe<Scalars['String']['output']>;
  RowNumber?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Data_Import_Error_Min_Fields = {
  __typename?: 'data_import_error_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DataImportId?: Maybe<Scalars['uuid']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImportObject?: Maybe<Scalars['String']['output']>;
  Message?: Maybe<Scalars['String']['output']>;
  RowNumber?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "risksmart.data_import_error" */
export type Data_Import_Error_Mutation_Response = {
  __typename?: 'data_import_error_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Data_Import_Error>;
};

/** on_conflict condition type for table "risksmart.data_import_error" */
export type Data_Import_Error_On_Conflict = {
  constraint: Data_Import_Error_Constraint;
  update_columns?: Array<Data_Import_Error_Update_Column>;
  where?: InputMaybe<Data_Import_Error_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.data_import_error". */
export type Data_Import_Error_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  DataImportId?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ImportObject?: InputMaybe<Order_By>;
  Message?: InputMaybe<Order_By>;
  RowNumber?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.data_import_error" */
export const Data_Import_Error_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  DataImportId: 'DataImportId',
  /** column name */
  Id: 'Id',
  /** column name */
  ImportObject: 'ImportObject',
  /** column name */
  Message: 'Message',
  /** column name */
  RowNumber: 'RowNumber'
} as const;

export type Data_Import_Error_Select_Column = typeof Data_Import_Error_Select_Column[keyof typeof Data_Import_Error_Select_Column];
/** aggregate stddev on columns */
export type Data_Import_Error_Stddev_Fields = {
  __typename?: 'data_import_error_stddev_fields';
  RowNumber?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Data_Import_Error_Stddev_Pop_Fields = {
  __typename?: 'data_import_error_stddev_pop_fields';
  RowNumber?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Data_Import_Error_Stddev_Samp_Fields = {
  __typename?: 'data_import_error_stddev_samp_fields';
  RowNumber?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "data_import_error" */
export type Data_Import_Error_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Data_Import_Error_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Data_Import_Error_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DataImportId?: InputMaybe<Scalars['uuid']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImportObject?: InputMaybe<Scalars['String']['input']>;
  Message?: InputMaybe<Scalars['String']['input']>;
  RowNumber?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Data_Import_Error_Sum_Fields = {
  __typename?: 'data_import_error_sum_fields';
  RowNumber?: Maybe<Scalars['Int']['output']>;
};

/** placeholder for update columns of table "risksmart.data_import_error" (current role has no relevant permissions) */
export const Data_Import_Error_Update_Column = {
  /** placeholder (do not use) */
  Placeholder: '_PLACEHOLDER'
} as const;

export type Data_Import_Error_Update_Column = typeof Data_Import_Error_Update_Column[keyof typeof Data_Import_Error_Update_Column];
/** aggregate var_pop on columns */
export type Data_Import_Error_Var_Pop_Fields = {
  __typename?: 'data_import_error_var_pop_fields';
  RowNumber?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Data_Import_Error_Var_Samp_Fields = {
  __typename?: 'data_import_error_var_samp_fields';
  RowNumber?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Data_Import_Error_Variance_Fields = {
  __typename?: 'data_import_error_variance_fields';
  RowNumber?: Maybe<Scalars['Float']['output']>;
};

/** input type for inserting data into table "risksmart.data_import" */
export type Data_Import_Insert_Input = {
  files?: InputMaybe<Relation_File_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Data_Import_Max_Fields = {
  __typename?: 'data_import_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Data_Import_Min_Fields = {
  __typename?: 'data_import_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.data_import" */
export type Data_Import_Mutation_Response = {
  __typename?: 'data_import_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Data_Import>;
};

/** on_conflict condition type for table "risksmart.data_import" */
export type Data_Import_On_Conflict = {
  constraint: Data_Import_Constraint;
  update_columns?: Array<Data_Import_Update_Column>;
  where?: InputMaybe<Data_Import_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.data_import". */
export type Data_Import_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
};

/** select columns of table "risksmart.data_import" */
export const Data_Import_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Status: 'Status'
} as const;

export type Data_Import_Select_Column = typeof Data_Import_Select_Column[keyof typeof Data_Import_Select_Column];
export const Data_Import_Status_Enum = {
  /** Complete */
  Complete: 'complete',
  /** Failed */
  Failed: 'failed',
  /** Importing */
  Importing: 'importing',
  /** Initiating import */
  InitiatingImport: 'initiatingImport',
  /** Not Started */
  Notstarted: 'notstarted',
  /** Valid */
  Valid: 'valid',
  /** Validating */
  Validating: 'validating'
} as const;

export type Data_Import_Status_Enum = typeof Data_Import_Status_Enum[keyof typeof Data_Import_Status_Enum];
/** Boolean expression to compare columns of type "data_import_status_enum". All fields are combined with logical 'AND'. */
export type Data_Import_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Data_Import_Status_Enum>;
  _in?: InputMaybe<Array<Data_Import_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Data_Import_Status_Enum>;
  _nin?: InputMaybe<Array<Data_Import_Status_Enum>>;
};

/** Streaming cursor of the table "data_import" */
export type Data_Import_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Data_Import_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Data_Import_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Data_Import_Status_Enum>;
};

/** update columns of table "risksmart.data_import" */
export const Data_Import_Update_Column = {
  /** column name */
  Status: 'Status'
} as const;

export type Data_Import_Update_Column = typeof Data_Import_Update_Column[keyof typeof Data_Import_Update_Column];
/** columns and relationships of "risksmart.department" */
export type Department = {
  __typename?: 'department';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DepartmentTypeId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parent?: Maybe<Node>;
  /** An object relationship */
  parentAction?: Maybe<Action>;
  /** An object relationship */
  parentAssessment?: Maybe<Assessment>;
  /** An object relationship */
  parentIssue?: Maybe<Issue>;
  /** An object relationship */
  parentThirdParty?: Maybe<Third_Party>;
  /** An object relationship */
  type?: Maybe<Department_Type>;
};

/** order by aggregate values of table "risksmart.department" */
export type Department_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Department_Max_Order_By>;
  min?: InputMaybe<Department_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.department" */
export type Department_Arr_Rel_Insert_Input = {
  data: Array<Department_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Department_On_Conflict>;
};

/** columns and relationships of "risksmart.department_audit" */
export type Department_Audit = {
  __typename?: 'department_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DepartmentTypeId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId: Scalars['uuid']['output'];
};

/** order by aggregate values of table "risksmart.department_audit" */
export type Department_Audit_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Department_Audit_Max_Order_By>;
  min?: InputMaybe<Department_Audit_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.department_audit". All fields are combined with a logical 'AND'. */
export type Department_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  DepartmentTypeId?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Department_Audit_Bool_Exp>>;
  _not?: InputMaybe<Department_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Department_Audit_Bool_Exp>>;
};

/** order by max() on columns of table "risksmart.department_audit" */
export type Department_Audit_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  DepartmentTypeId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.department_audit" */
export type Department_Audit_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  DepartmentTypeId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "risksmart.department_audit". */
export type Department_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  DepartmentTypeId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.department_audit" */
export const Department_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  DepartmentTypeId: 'DepartmentTypeId',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId'
} as const;

export type Department_Audit_Select_Column = typeof Department_Audit_Select_Column[keyof typeof Department_Audit_Select_Column];
/** Streaming cursor of the table "department_audit" */
export type Department_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Department_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Department_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DepartmentTypeId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.department". All fields are combined with a logical 'AND'. */
export type Department_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  DepartmentTypeId?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Department_Bool_Exp>>;
  _not?: InputMaybe<Department_Bool_Exp>;
  _or?: InputMaybe<Array<Department_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parent?: InputMaybe<Node_Bool_Exp>;
  parentAction?: InputMaybe<Action_Bool_Exp>;
  parentAssessment?: InputMaybe<Assessment_Bool_Exp>;
  parentIssue?: InputMaybe<Issue_Bool_Exp>;
  parentThirdParty?: InputMaybe<Third_Party_Bool_Exp>;
  type?: InputMaybe<Department_Type_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.department" */
export const Department_Constraint = {
  /** unique or primary key constraint on columns "ParentId", "DepartmentTypeId" */
  DepartmentPkey: 'department_pkey'
} as const;

export type Department_Constraint = typeof Department_Constraint[keyof typeof Department_Constraint];
/** input type for inserting data into table "risksmart.department" */
export type Department_Insert_Input = {
  DepartmentTypeId?: InputMaybe<Scalars['uuid']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  parentAction?: InputMaybe<Action_Obj_Rel_Insert_Input>;
  parentAssessment?: InputMaybe<Assessment_Obj_Rel_Insert_Input>;
  parentIssue?: InputMaybe<Issue_Obj_Rel_Insert_Input>;
  parentThirdParty?: InputMaybe<Third_Party_Obj_Rel_Insert_Input>;
  type?: InputMaybe<Department_Type_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "risksmart.department" */
export type Department_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  DepartmentTypeId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.department" */
export type Department_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  DepartmentTypeId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.department" */
export type Department_Mutation_Response = {
  __typename?: 'department_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Department>;
};

/** on_conflict condition type for table "risksmart.department" */
export type Department_On_Conflict = {
  constraint: Department_Constraint;
  update_columns?: Array<Department_Update_Column>;
  where?: InputMaybe<Department_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.department". */
export type Department_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  DepartmentTypeId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parent?: InputMaybe<Node_Order_By>;
  parentAction?: InputMaybe<Action_Order_By>;
  parentAssessment?: InputMaybe<Assessment_Order_By>;
  parentIssue?: InputMaybe<Issue_Order_By>;
  parentThirdParty?: InputMaybe<Third_Party_Order_By>;
  type?: InputMaybe<Department_Type_Order_By>;
};

/** select columns of table "risksmart.department" */
export const Department_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  DepartmentTypeId: 'DepartmentTypeId',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId'
} as const;

export type Department_Select_Column = typeof Department_Select_Column[keyof typeof Department_Select_Column];
/** columns and relationships of "risksmart.department_type" */
export type Department_Type = {
  __typename?: 'department_type';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DepartmentTypeGroupId?: Maybe<Scalars['uuid']['output']>;
  DepartmentTypeId: Scalars['uuid']['output'];
  Description?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  department_type_group?: Maybe<Department_Type_Group>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
};

/** order by aggregate values of table "risksmart.department_type" */
export type Department_Type_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Department_Type_Max_Order_By>;
  min?: InputMaybe<Department_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.department_type" */
export type Department_Type_Arr_Rel_Insert_Input = {
  data: Array<Department_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Department_Type_On_Conflict>;
};

/** columns and relationships of "risksmart.department_type_audit" */
export type Department_Type_Audit = {
  __typename?: 'department_type_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DepartmentTypeGroupId?: Maybe<Scalars['uuid']['output']>;
  DepartmentTypeId: Scalars['uuid']['output'];
  Description?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name: Scalars['String']['output'];
};

/** Boolean expression to filter rows from the table "risksmart.department_type_audit". All fields are combined with a logical 'AND'. */
export type Department_Type_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  DepartmentTypeGroupId?: InputMaybe<Uuid_Comparison_Exp>;
  DepartmentTypeId?: InputMaybe<Uuid_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Department_Type_Audit_Bool_Exp>>;
  _not?: InputMaybe<Department_Type_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Department_Type_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.department_type_audit". */
export type Department_Type_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  DepartmentTypeGroupId?: InputMaybe<Order_By>;
  DepartmentTypeId?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.department_type_audit" */
export const Department_Type_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  DepartmentTypeGroupId: 'DepartmentTypeGroupId',
  /** column name */
  DepartmentTypeId: 'DepartmentTypeId',
  /** column name */
  Description: 'Description',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Name: 'Name'
} as const;

export type Department_Type_Audit_Select_Column = typeof Department_Type_Audit_Select_Column[keyof typeof Department_Type_Audit_Select_Column];
/** Streaming cursor of the table "department_type_audit" */
export type Department_Type_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Department_Type_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Department_Type_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DepartmentTypeGroupId?: InputMaybe<Scalars['uuid']['input']>;
  DepartmentTypeId?: InputMaybe<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.department_type". All fields are combined with a logical 'AND'. */
export type Department_Type_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  DepartmentTypeGroupId?: InputMaybe<Uuid_Comparison_Exp>;
  DepartmentTypeId?: InputMaybe<Uuid_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Department_Type_Bool_Exp>>;
  _not?: InputMaybe<Department_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Department_Type_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  department_type_group?: InputMaybe<Department_Type_Group_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.department_type" */
export const Department_Type_Constraint = {
  /** unique or primary key constraint on columns "DepartmentTypeId" */
  DepartmentTypePkey: 'department_type_pkey',
  /** unique or primary key constraint on columns "Name", "OrgKey" */
  IxDepartmentTypeOrgkeyTitle: 'ix_department_type_orgkey_title'
} as const;

export type Department_Type_Constraint = typeof Department_Type_Constraint[keyof typeof Department_Type_Constraint];
/** columns and relationships of "risksmart.department_type_group" */
export type Department_Type_Group = {
  __typename?: 'department_type_group';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  /** An array relationship */
  department_types: Array<Department_Type>;
};


/** columns and relationships of "risksmart.department_type_group" */
export type Department_Type_GroupDepartment_TypesArgs = {
  distinct_on?: InputMaybe<Array<Department_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Type_Order_By>>;
  where?: InputMaybe<Department_Type_Bool_Exp>;
};

/** columns and relationships of "risksmart.department_type_group_audit" */
export type Department_Type_Group_Audit = {
  __typename?: 'department_type_group_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name: Scalars['String']['output'];
};

/** Boolean expression to filter rows from the table "risksmart.department_type_group_audit". All fields are combined with a logical 'AND'. */
export type Department_Type_Group_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Department_Type_Group_Audit_Bool_Exp>>;
  _not?: InputMaybe<Department_Type_Group_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Department_Type_Group_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.department_type_group_audit". */
export type Department_Type_Group_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.department_type_group_audit" */
export const Department_Type_Group_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Name: 'Name'
} as const;

export type Department_Type_Group_Audit_Select_Column = typeof Department_Type_Group_Audit_Select_Column[keyof typeof Department_Type_Group_Audit_Select_Column];
/** Streaming cursor of the table "department_type_group_audit" */
export type Department_Type_Group_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Department_Type_Group_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Department_Type_Group_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.department_type_group". All fields are combined with a logical 'AND'. */
export type Department_Type_Group_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Department_Type_Group_Bool_Exp>>;
  _not?: InputMaybe<Department_Type_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Department_Type_Group_Bool_Exp>>;
  department_types?: InputMaybe<Department_Type_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.department_type_group" */
export const Department_Type_Group_Constraint = {
  /** unique or primary key constraint on columns "Name", "OrgKey" */
  DepartmentTypeGroupPkey: 'DepartmentTypeGroup_pkey',
  /** unique or primary key constraint on columns "Id" */
  DepartmentTypeGroupIdKey: 'department_type_group_Id_key'
} as const;

export type Department_Type_Group_Constraint = typeof Department_Type_Group_Constraint[keyof typeof Department_Type_Group_Constraint];
/** input type for inserting data into table "risksmart.department_type_group" */
export type Department_Type_Group_Insert_Input = {
  Name?: InputMaybe<Scalars['String']['input']>;
  department_types?: InputMaybe<Department_Type_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "risksmart.department_type_group" */
export type Department_Type_Group_Mutation_Response = {
  __typename?: 'department_type_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Department_Type_Group>;
};

/** input type for inserting object relation for remote table "risksmart.department_type_group" */
export type Department_Type_Group_Obj_Rel_Insert_Input = {
  data: Department_Type_Group_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Department_Type_Group_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.department_type_group" */
export type Department_Type_Group_On_Conflict = {
  constraint: Department_Type_Group_Constraint;
  update_columns?: Array<Department_Type_Group_Update_Column>;
  where?: InputMaybe<Department_Type_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.department_type_group". */
export type Department_Type_Group_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  department_types_aggregate?: InputMaybe<Department_Type_Aggregate_Order_By>;
};

/** select columns of table "risksmart.department_type_group" */
export const Department_Type_Group_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Name: 'Name'
} as const;

export type Department_Type_Group_Select_Column = typeof Department_Type_Group_Select_Column[keyof typeof Department_Type_Group_Select_Column];
/** input type for updating data in table "risksmart.department_type_group" */
export type Department_Type_Group_Set_Input = {
  Name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "department_type_group" */
export type Department_Type_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Department_Type_Group_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Department_Type_Group_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.department_type_group" */
export const Department_Type_Group_Update_Column = {
  /** column name */
  Name: 'Name'
} as const;

export type Department_Type_Group_Update_Column = typeof Department_Type_Group_Update_Column[keyof typeof Department_Type_Group_Update_Column];
export type Department_Type_Group_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Department_Type_Group_Set_Input>;
  /** filter the rows which have to be updated */
  where: Department_Type_Group_Bool_Exp;
};

/** input type for inserting data into table "risksmart.department_type" */
export type Department_Type_Insert_Input = {
  DepartmentTypeGroupId?: InputMaybe<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  department_type_group?: InputMaybe<Department_Type_Group_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "risksmart.department_type" */
export type Department_Type_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  DepartmentTypeGroupId?: InputMaybe<Order_By>;
  DepartmentTypeId?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.department_type" */
export type Department_Type_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  DepartmentTypeGroupId?: InputMaybe<Order_By>;
  DepartmentTypeId?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.department_type" */
export type Department_Type_Mutation_Response = {
  __typename?: 'department_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Department_Type>;
};

/** input type for inserting object relation for remote table "risksmart.department_type" */
export type Department_Type_Obj_Rel_Insert_Input = {
  data: Department_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Department_Type_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.department_type" */
export type Department_Type_On_Conflict = {
  constraint: Department_Type_Constraint;
  update_columns?: Array<Department_Type_Update_Column>;
  where?: InputMaybe<Department_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.department_type". */
export type Department_Type_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  DepartmentTypeGroupId?: InputMaybe<Order_By>;
  DepartmentTypeId?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  department_type_group?: InputMaybe<Department_Type_Group_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
};

/** primary key columns input for table: risksmart.department_type */
export type Department_Type_Pk_Columns_Input = {
  DepartmentTypeId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.department_type" */
export const Department_Type_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  DepartmentTypeGroupId: 'DepartmentTypeGroupId',
  /** column name */
  DepartmentTypeId: 'DepartmentTypeId',
  /** column name */
  Description: 'Description',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Name: 'Name'
} as const;

export type Department_Type_Select_Column = typeof Department_Type_Select_Column[keyof typeof Department_Type_Select_Column];
/** input type for updating data in table "risksmart.department_type" */
export type Department_Type_Set_Input = {
  DepartmentTypeGroupId?: InputMaybe<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "department_type" */
export type Department_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Department_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Department_Type_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DepartmentTypeGroupId?: InputMaybe<Scalars['uuid']['input']>;
  DepartmentTypeId?: InputMaybe<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.department_type" */
export const Department_Type_Update_Column = {
  /** column name */
  DepartmentTypeGroupId: 'DepartmentTypeGroupId',
  /** column name */
  Description: 'Description',
  /** column name */
  Name: 'Name'
} as const;

export type Department_Type_Update_Column = typeof Department_Type_Update_Column[keyof typeof Department_Type_Update_Column];
export type Department_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Department_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Department_Type_Bool_Exp;
};

/** placeholder for update columns of table "risksmart.department" (current role has no relevant permissions) */
export const Department_Update_Column = {
  /** placeholder (do not use) */
  Placeholder: '_PLACEHOLDER'
} as const;

export type Department_Update_Column = typeof Department_Update_Column[keyof typeof Department_Update_Column];
/** columns and relationships of "risksmart.document" */
export type Document = {
  __typename?: 'document';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  DocumentType: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentDocument?: Maybe<Scalars['uuid']['output']>;
  Purpose?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title: Scalars['String']['output'];
  /** An array relationship */
  actions: Array<Action_Parent>;
  /** An aggregate relationship */
  actions_aggregate: Action_Parent_Aggregate;
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An array relationship */
  approvals: Array<Approval>;
  /** An array relationship */
  assessmentResults: Array<Assessment_Result_Parent>;
  /** An aggregate relationship */
  assessmentResults_aggregate: Assessment_Result_Parent_Aggregate;
  /** An object relationship */
  attestationConfig?: Maybe<Attestation_Config>;
  /** An array relationship */
  contributorGroups: Array<Contributor_Group>;
  /** An aggregate relationship */
  contributorGroups_aggregate: Contributor_Group_Aggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: Contributor_Aggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An array relationship */
  documentFiles: Array<Document_File>;
  /** An array relationship */
  issues: Array<Issue_Parent>;
  /** An aggregate relationship */
  issues_aggregate: Issue_Parent_Aggregate;
  /** An array relationship */
  linkedDocuments: Array<Document_Linked_Document>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An array relationship */
  ownerGroups: Array<Owner_Group>;
  /** An aggregate relationship */
  ownerGroups_aggregate: Owner_Group_Aggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: Owner_Aggregate;
  /** An object relationship */
  parent?: Maybe<Document>;
  /** An object relationship */
  schedule?: Maybe<Schedule>;
  /** An object relationship */
  scheduleState?: Maybe<Schedule_State>;
  /** An array relationship */
  tags: Array<Tag>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentActionsArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentApprovalsArgs = {
  distinct_on?: InputMaybe<Array<Approval_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Approval_Order_By>>;
  where?: InputMaybe<Approval_Bool_Exp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentAssessmentResultsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentAssessmentResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentContributorGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentContributorsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentContributors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentDocumentFilesArgs = {
  distinct_on?: InputMaybe<Array<Document_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_File_Order_By>>;
  where?: InputMaybe<Document_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentIssuesArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Order_By>>;
  where?: InputMaybe<Issue_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentIssues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Order_By>>;
  where?: InputMaybe<Issue_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentLinkedDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Document_Linked_Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Linked_Document_Order_By>>;
  where?: InputMaybe<Document_Linked_Document_Bool_Exp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentOwnerGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentOwnersArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentOwners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentTagsArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};

/** columns and relationships of "risksmart.document_assessment_result" */
export type Document_Assessment_Result = {
  __typename?: 'document_assessment_result';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser: Scalars['String']['output'];
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType: Scalars['String']['output'];
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  files: Array<Relation_File>;
  /** An aggregate relationship */
  files_aggregate: Relation_File_Aggregate;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An array relationship */
  parents: Array<Assessment_Result_Parent>;
  /** An aggregate relationship */
  parents_aggregate: Assessment_Result_Parent_Aggregate;
};


/** columns and relationships of "risksmart.document_assessment_result" */
export type Document_Assessment_ResultCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.document_assessment_result" */
export type Document_Assessment_ResultAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.document_assessment_result" */
export type Document_Assessment_ResultFilesArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.document_assessment_result" */
export type Document_Assessment_ResultFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.document_assessment_result" */
export type Document_Assessment_ResultParentsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.document_assessment_result" */
export type Document_Assessment_ResultParents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};

/** aggregated selection of "risksmart.document_assessment_result" */
export type Document_Assessment_Result_Aggregate = {
  __typename?: 'document_assessment_result_aggregate';
  aggregate?: Maybe<Document_Assessment_Result_Aggregate_Fields>;
  nodes: Array<Document_Assessment_Result>;
};

/** aggregate fields of "risksmart.document_assessment_result" */
export type Document_Assessment_Result_Aggregate_Fields = {
  __typename?: 'document_assessment_result_aggregate_fields';
  avg?: Maybe<Document_Assessment_Result_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Document_Assessment_Result_Max_Fields>;
  min?: Maybe<Document_Assessment_Result_Min_Fields>;
  stddev?: Maybe<Document_Assessment_Result_Stddev_Fields>;
  stddev_pop?: Maybe<Document_Assessment_Result_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Document_Assessment_Result_Stddev_Samp_Fields>;
  sum?: Maybe<Document_Assessment_Result_Sum_Fields>;
  var_pop?: Maybe<Document_Assessment_Result_Var_Pop_Fields>;
  var_samp?: Maybe<Document_Assessment_Result_Var_Samp_Fields>;
  variance?: Maybe<Document_Assessment_Result_Variance_Fields>;
};


/** aggregate fields of "risksmart.document_assessment_result" */
export type Document_Assessment_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Document_Assessment_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Document_Assessment_Result_Append_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.document_assessment_result_audit" */
export type Document_Assessment_Result_Audit = {
  __typename?: 'document_assessment_result_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "risksmart.document_assessment_result_audit" */
export type Document_Assessment_Result_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.document_assessment_result_audit" */
export type Document_Assessment_Result_Audit_Aggregate = {
  __typename?: 'document_assessment_result_audit_aggregate';
  aggregate?: Maybe<Document_Assessment_Result_Audit_Aggregate_Fields>;
  nodes: Array<Document_Assessment_Result_Audit>;
};

/** aggregate fields of "risksmart.document_assessment_result_audit" */
export type Document_Assessment_Result_Audit_Aggregate_Fields = {
  __typename?: 'document_assessment_result_audit_aggregate_fields';
  avg?: Maybe<Document_Assessment_Result_Audit_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Document_Assessment_Result_Audit_Max_Fields>;
  min?: Maybe<Document_Assessment_Result_Audit_Min_Fields>;
  stddev?: Maybe<Document_Assessment_Result_Audit_Stddev_Fields>;
  stddev_pop?: Maybe<Document_Assessment_Result_Audit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Document_Assessment_Result_Audit_Stddev_Samp_Fields>;
  sum?: Maybe<Document_Assessment_Result_Audit_Sum_Fields>;
  var_pop?: Maybe<Document_Assessment_Result_Audit_Var_Pop_Fields>;
  var_samp?: Maybe<Document_Assessment_Result_Audit_Var_Samp_Fields>;
  variance?: Maybe<Document_Assessment_Result_Audit_Variance_Fields>;
};


/** aggregate fields of "risksmart.document_assessment_result_audit" */
export type Document_Assessment_Result_Audit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Document_Assessment_Result_Audit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Document_Assessment_Result_Audit_Avg_Fields = {
  __typename?: 'document_assessment_result_audit_avg_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.document_assessment_result_audit". All fields are combined with a logical 'AND'. */
export type Document_Assessment_Result_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Rating?: InputMaybe<Int_Comparison_Exp>;
  RatingType?: InputMaybe<String_Comparison_Exp>;
  Rationale?: InputMaybe<String_Comparison_Exp>;
  TestDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  _and?: InputMaybe<Array<Document_Assessment_Result_Audit_Bool_Exp>>;
  _not?: InputMaybe<Document_Assessment_Result_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Document_Assessment_Result_Audit_Bool_Exp>>;
};

/** aggregate max on columns */
export type Document_Assessment_Result_Audit_Max_Fields = {
  __typename?: 'document_assessment_result_audit_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Document_Assessment_Result_Audit_Min_Fields = {
  __typename?: 'document_assessment_result_audit_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** Ordering options when selecting data from "risksmart.document_assessment_result_audit". */
export type Document_Assessment_Result_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  RatingType?: InputMaybe<Order_By>;
  Rationale?: InputMaybe<Order_By>;
  TestDate?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.document_assessment_result_audit" */
export const Document_Assessment_Result_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Rating: 'Rating',
  /** column name */
  RatingType: 'RatingType',
  /** column name */
  Rationale: 'Rationale',
  /** column name */
  TestDate: 'TestDate'
} as const;

export type Document_Assessment_Result_Audit_Select_Column = typeof Document_Assessment_Result_Audit_Select_Column[keyof typeof Document_Assessment_Result_Audit_Select_Column];
/** aggregate stddev on columns */
export type Document_Assessment_Result_Audit_Stddev_Fields = {
  __typename?: 'document_assessment_result_audit_stddev_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Document_Assessment_Result_Audit_Stddev_Pop_Fields = {
  __typename?: 'document_assessment_result_audit_stddev_pop_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Document_Assessment_Result_Audit_Stddev_Samp_Fields = {
  __typename?: 'document_assessment_result_audit_stddev_samp_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "document_assessment_result_audit" */
export type Document_Assessment_Result_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Document_Assessment_Result_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_Assessment_Result_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Document_Assessment_Result_Audit_Sum_Fields = {
  __typename?: 'document_assessment_result_audit_sum_fields';
  Rating?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Document_Assessment_Result_Audit_Var_Pop_Fields = {
  __typename?: 'document_assessment_result_audit_var_pop_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Document_Assessment_Result_Audit_Var_Samp_Fields = {
  __typename?: 'document_assessment_result_audit_var_samp_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Document_Assessment_Result_Audit_Variance_Fields = {
  __typename?: 'document_assessment_result_audit_variance_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type Document_Assessment_Result_Avg_Fields = {
  __typename?: 'document_assessment_result_avg_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.document_assessment_result". All fields are combined with a logical 'AND'. */
export type Document_Assessment_Result_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Rating?: InputMaybe<Int_Comparison_Exp>;
  RatingType?: InputMaybe<String_Comparison_Exp>;
  Rationale?: InputMaybe<String_Comparison_Exp>;
  TestDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  _and?: InputMaybe<Array<Document_Assessment_Result_Bool_Exp>>;
  _not?: InputMaybe<Document_Assessment_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Document_Assessment_Result_Bool_Exp>>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  files?: InputMaybe<Relation_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parents?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
  parents_aggregate?: InputMaybe<Assessment_Result_Parent_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.document_assessment_result" */
export const Document_Assessment_Result_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  DocumentAssessmentResultPkey: 'document_assessment_result_pkey'
} as const;

export type Document_Assessment_Result_Constraint = typeof Document_Assessment_Result_Constraint[keyof typeof Document_Assessment_Result_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Document_Assessment_Result_Delete_At_Path_Input = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Document_Assessment_Result_Delete_Elem_Input = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Document_Assessment_Result_Delete_Key_Input = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.document_assessment_result" */
export type Document_Assessment_Result_Inc_Input = {
  Rating?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.document_assessment_result" */
export type Document_Assessment_Result_Insert_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  files?: InputMaybe<Relation_File_Arr_Rel_Insert_Input>;
  parents?: InputMaybe<Assessment_Result_Parent_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Document_Assessment_Result_Max_Fields = {
  __typename?: 'document_assessment_result_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Document_Assessment_Result_Min_Fields = {
  __typename?: 'document_assessment_result_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "risksmart.document_assessment_result" */
export type Document_Assessment_Result_Mutation_Response = {
  __typename?: 'document_assessment_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Document_Assessment_Result>;
};

/** input type for inserting object relation for remote table "risksmart.document_assessment_result" */
export type Document_Assessment_Result_Obj_Rel_Insert_Input = {
  data: Document_Assessment_Result_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Document_Assessment_Result_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.document_assessment_result" */
export type Document_Assessment_Result_On_Conflict = {
  constraint: Document_Assessment_Result_Constraint;
  update_columns?: Array<Document_Assessment_Result_Update_Column>;
  where?: InputMaybe<Document_Assessment_Result_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.document_assessment_result". */
export type Document_Assessment_Result_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  RatingType?: InputMaybe<Order_By>;
  Rationale?: InputMaybe<Order_By>;
  TestDate?: InputMaybe<Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parents_aggregate?: InputMaybe<Assessment_Result_Parent_Aggregate_Order_By>;
};

/** primary key columns input for table: risksmart.document_assessment_result */
export type Document_Assessment_Result_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Document_Assessment_Result_Prepend_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.document_assessment_result" */
export const Document_Assessment_Result_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Rating: 'Rating',
  /** column name */
  RatingType: 'RatingType',
  /** column name */
  Rationale: 'Rationale',
  /** column name */
  TestDate: 'TestDate'
} as const;

export type Document_Assessment_Result_Select_Column = typeof Document_Assessment_Result_Select_Column[keyof typeof Document_Assessment_Result_Select_Column];
/** input type for updating data in table "risksmart.document_assessment_result" */
export type Document_Assessment_Result_Set_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Document_Assessment_Result_Stddev_Fields = {
  __typename?: 'document_assessment_result_stddev_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Document_Assessment_Result_Stddev_Pop_Fields = {
  __typename?: 'document_assessment_result_stddev_pop_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Document_Assessment_Result_Stddev_Samp_Fields = {
  __typename?: 'document_assessment_result_stddev_samp_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "document_assessment_result" */
export type Document_Assessment_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Document_Assessment_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_Assessment_Result_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Document_Assessment_Result_Sum_Fields = {
  __typename?: 'document_assessment_result_sum_fields';
  Rating?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.document_assessment_result" */
export const Document_Assessment_Result_Update_Column = {
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Rating: 'Rating',
  /** column name */
  RatingType: 'RatingType',
  /** column name */
  Rationale: 'Rationale',
  /** column name */
  TestDate: 'TestDate'
} as const;

export type Document_Assessment_Result_Update_Column = typeof Document_Assessment_Result_Update_Column[keyof typeof Document_Assessment_Result_Update_Column];
export type Document_Assessment_Result_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Document_Assessment_Result_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Document_Assessment_Result_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Document_Assessment_Result_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Document_Assessment_Result_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Document_Assessment_Result_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Document_Assessment_Result_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Document_Assessment_Result_Set_Input>;
  /** filter the rows which have to be updated */
  where: Document_Assessment_Result_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Document_Assessment_Result_Var_Pop_Fields = {
  __typename?: 'document_assessment_result_var_pop_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Document_Assessment_Result_Var_Samp_Fields = {
  __typename?: 'document_assessment_result_var_samp_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Document_Assessment_Result_Variance_Fields = {
  __typename?: 'document_assessment_result_variance_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.document_audit" */
export type Document_Audit = {
  __typename?: 'document_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  DocumentType: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentDocument?: Maybe<Scalars['uuid']['output']>;
  Purpose?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.document_audit" */
export type Document_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.document_audit". All fields are combined with a logical 'AND'. */
export type Document_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  DocumentType?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentDocument?: InputMaybe<Uuid_Comparison_Exp>;
  Purpose?: InputMaybe<String_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Document_Audit_Bool_Exp>>;
  _not?: InputMaybe<Document_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Document_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.document_audit". */
export type Document_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  DocumentType?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentDocument?: InputMaybe<Order_By>;
  Purpose?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.document_audit" */
export const Document_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  DocumentType: 'DocumentType',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentDocument: 'ParentDocument',
  /** column name */
  Purpose: 'Purpose',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Title: 'Title'
} as const;

export type Document_Audit_Select_Column = typeof Document_Audit_Select_Column[keyof typeof Document_Audit_Select_Column];
/** Streaming cursor of the table "document_audit" */
export type Document_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Document_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DocumentType?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentDocument?: InputMaybe<Scalars['uuid']['input']>;
  Purpose?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.document". All fields are combined with a logical 'AND'. */
export type Document_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  DocumentType?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentDocument?: InputMaybe<Uuid_Comparison_Exp>;
  Purpose?: InputMaybe<String_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Document_Bool_Exp>>;
  _not?: InputMaybe<Document_Bool_Exp>;
  _or?: InputMaybe<Array<Document_Bool_Exp>>;
  actions?: InputMaybe<Action_Parent_Bool_Exp>;
  actions_aggregate?: InputMaybe<Action_Parent_Aggregate_Bool_Exp>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  approvals?: InputMaybe<Approval_Bool_Exp>;
  assessmentResults?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
  assessmentResults_aggregate?: InputMaybe<Assessment_Result_Parent_Aggregate_Bool_Exp>;
  attestationConfig?: InputMaybe<Attestation_Config_Bool_Exp>;
  contributorGroups?: InputMaybe<Contributor_Group_Bool_Exp>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Bool_Exp>;
  contributors?: InputMaybe<Contributor_Bool_Exp>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  departments?: InputMaybe<Department_Bool_Exp>;
  documentFiles?: InputMaybe<Document_File_Bool_Exp>;
  issues?: InputMaybe<Issue_Parent_Bool_Exp>;
  issues_aggregate?: InputMaybe<Issue_Parent_Aggregate_Bool_Exp>;
  linkedDocuments?: InputMaybe<Document_Linked_Document_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  ownerGroups?: InputMaybe<Owner_Group_Bool_Exp>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Bool_Exp>;
  owners?: InputMaybe<Owner_Bool_Exp>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Bool_Exp>;
  parent?: InputMaybe<Document_Bool_Exp>;
  schedule?: InputMaybe<Schedule_Bool_Exp>;
  scheduleState?: InputMaybe<Schedule_State_Bool_Exp>;
  tags?: InputMaybe<Tag_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.document" */
export const Document_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  DocumentPkey: 'document_pkey',
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxDocumentOrgkeySequentialid: 'idx_document_orgkey_sequentialid'
} as const;

export type Document_Constraint = typeof Document_Constraint[keyof typeof Document_Constraint];
/** columns and relationships of "risksmart.document_file" */
export type Document_File = {
  __typename?: 'document_file';
  Content?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  FileId?: Maybe<Scalars['uuid']['output']>;
  Id: Scalars['uuid']['output'];
  Link?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  NextReviewDate?: Maybe<Scalars['timestamptz']['output']>;
  ParentDocumentId: Scalars['uuid']['output'];
  PublishedDate?: Maybe<Scalars['timestamptz']['output']>;
  ReasonForReview?: Maybe<Scalars['String']['output']>;
  ReviewDate?: Maybe<Scalars['timestamptz']['output']>;
  ReviewedBy?: Maybe<Scalars['String']['output']>;
  Status: Version_Status_Enum;
  Summary?: Maybe<Scalars['String']['output']>;
  Type: Document_File_Type_Enum;
  Version: Scalars['String']['output'];
  /** An array relationship */
  attestations: Array<Attestation_Record>;
  /** An aggregate relationship */
  attestations_aggregate: Attestation_Record_Aggregate;
  /** An array relationship */
  changeRequests: Array<Change_Request>;
  /** An aggregate relationship */
  changeRequests_aggregate: Change_Request_Aggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  file?: Maybe<File>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  parent?: Maybe<Document>;
  /** An object relationship */
  reviewedBy?: Maybe<User>;
};


/** columns and relationships of "risksmart.document_file" */
export type Document_FileCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.document_file" */
export type Document_FileAttestationsArgs = {
  distinct_on?: InputMaybe<Array<Attestation_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attestation_Record_Order_By>>;
  where?: InputMaybe<Attestation_Record_Bool_Exp>;
};


/** columns and relationships of "risksmart.document_file" */
export type Document_FileAttestations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attestation_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attestation_Record_Order_By>>;
  where?: InputMaybe<Attestation_Record_Bool_Exp>;
};


/** columns and relationships of "risksmart.document_file" */
export type Document_FileChangeRequestsArgs = {
  distinct_on?: InputMaybe<Array<Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Change_Request_Order_By>>;
  where?: InputMaybe<Change_Request_Bool_Exp>;
};


/** columns and relationships of "risksmart.document_file" */
export type Document_FileChangeRequests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Change_Request_Order_By>>;
  where?: InputMaybe<Change_Request_Bool_Exp>;
};

/** order by aggregate values of table "risksmart.document_file" */
export type Document_File_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Document_File_Max_Order_By>;
  min?: InputMaybe<Document_File_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.document_file" */
export type Document_File_Arr_Rel_Insert_Input = {
  data: Array<Document_File_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Document_File_On_Conflict>;
};

/** columns and relationships of "risksmart.document_file_audit" */
export type Document_File_Audit = {
  __typename?: 'document_file_audit';
  Content?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  FileId?: Maybe<Scalars['uuid']['output']>;
  Id: Scalars['uuid']['output'];
  Link?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  NextReviewDate?: Maybe<Scalars['timestamptz']['output']>;
  ParentDocumentId: Scalars['uuid']['output'];
  PublishedDate?: Maybe<Scalars['timestamptz']['output']>;
  ReasonForReview?: Maybe<Scalars['String']['output']>;
  ReviewDate?: Maybe<Scalars['timestamptz']['output']>;
  ReviewedBy?: Maybe<Scalars['String']['output']>;
  Status: Scalars['String']['output'];
  Summary?: Maybe<Scalars['String']['output']>;
  Type: Scalars['String']['output'];
  Version: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.document_file_audit" */
export type Document_File_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.document_file_audit". All fields are combined with a logical 'AND'. */
export type Document_File_Audit_Bool_Exp = {
  Content?: InputMaybe<String_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  FileId?: InputMaybe<Uuid_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  Link?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  NextReviewDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  ParentDocumentId?: InputMaybe<Uuid_Comparison_Exp>;
  PublishedDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  ReasonForReview?: InputMaybe<String_Comparison_Exp>;
  ReviewDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  ReviewedBy?: InputMaybe<String_Comparison_Exp>;
  Status?: InputMaybe<String_Comparison_Exp>;
  Summary?: InputMaybe<String_Comparison_Exp>;
  Type?: InputMaybe<String_Comparison_Exp>;
  Version?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Document_File_Audit_Bool_Exp>>;
  _not?: InputMaybe<Document_File_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Document_File_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.document_file_audit". */
export type Document_File_Audit_Order_By = {
  Content?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  FileId?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Link?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  NextReviewDate?: InputMaybe<Order_By>;
  ParentDocumentId?: InputMaybe<Order_By>;
  PublishedDate?: InputMaybe<Order_By>;
  ReasonForReview?: InputMaybe<Order_By>;
  ReviewDate?: InputMaybe<Order_By>;
  ReviewedBy?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Summary?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  Version?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.document_file_audit" */
export const Document_File_Audit_Select_Column = {
  /** column name */
  Content: 'Content',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  FileId: 'FileId',
  /** column name */
  Id: 'Id',
  /** column name */
  Link: 'Link',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  NextReviewDate: 'NextReviewDate',
  /** column name */
  ParentDocumentId: 'ParentDocumentId',
  /** column name */
  PublishedDate: 'PublishedDate',
  /** column name */
  ReasonForReview: 'ReasonForReview',
  /** column name */
  ReviewDate: 'ReviewDate',
  /** column name */
  ReviewedBy: 'ReviewedBy',
  /** column name */
  Status: 'Status',
  /** column name */
  Summary: 'Summary',
  /** column name */
  Type: 'Type',
  /** column name */
  Version: 'Version'
} as const;

export type Document_File_Audit_Select_Column = typeof Document_File_Audit_Select_Column[keyof typeof Document_File_Audit_Select_Column];
/** Streaming cursor of the table "document_file_audit" */
export type Document_File_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Document_File_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_File_Audit_Stream_Cursor_Value_Input = {
  Content?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  FileId?: InputMaybe<Scalars['uuid']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Link?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ParentDocumentId?: InputMaybe<Scalars['uuid']['input']>;
  PublishedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReasonForReview?: InputMaybe<Scalars['String']['input']>;
  ReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReviewedBy?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  Version?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.document_file". All fields are combined with a logical 'AND'. */
export type Document_File_Bool_Exp = {
  Content?: InputMaybe<String_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  FileId?: InputMaybe<Uuid_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  Link?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  NextReviewDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  ParentDocumentId?: InputMaybe<Uuid_Comparison_Exp>;
  PublishedDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  ReasonForReview?: InputMaybe<String_Comparison_Exp>;
  ReviewDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  ReviewedBy?: InputMaybe<String_Comparison_Exp>;
  Status?: InputMaybe<Version_Status_Enum_Comparison_Exp>;
  Summary?: InputMaybe<String_Comparison_Exp>;
  Type?: InputMaybe<Document_File_Type_Enum_Comparison_Exp>;
  Version?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Document_File_Bool_Exp>>;
  _not?: InputMaybe<Document_File_Bool_Exp>;
  _or?: InputMaybe<Array<Document_File_Bool_Exp>>;
  attestations?: InputMaybe<Attestation_Record_Bool_Exp>;
  attestations_aggregate?: InputMaybe<Attestation_Record_Aggregate_Bool_Exp>;
  changeRequests?: InputMaybe<Change_Request_Bool_Exp>;
  changeRequests_aggregate?: InputMaybe<Change_Request_Aggregate_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  file?: InputMaybe<File_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  parent?: InputMaybe<Document_Bool_Exp>;
  reviewedBy?: InputMaybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.document_file" */
export const Document_File_Constraint = {
  /** unique or primary key constraint on columns "FileId" */
  DocumentFileFileId: 'document_file_fileId',
  /** unique or primary key constraint on columns "Id" */
  DocumentFilePkey: 'document_file_pkey'
} as const;

export type Document_File_Constraint = typeof Document_File_Constraint[keyof typeof Document_File_Constraint];
/** input type for inserting data into table "risksmart.document_file" */
export type Document_File_Insert_Input = {
  Content?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  FileId?: InputMaybe<Scalars['uuid']['input']>;
  Link?: InputMaybe<Scalars['String']['input']>;
  NextReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ParentDocumentId?: InputMaybe<Scalars['uuid']['input']>;
  ReasonForReview?: InputMaybe<Scalars['String']['input']>;
  ReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReviewedBy?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Version_Status_Enum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Document_File_Type_Enum>;
  Version?: InputMaybe<Scalars['String']['input']>;
  attestations?: InputMaybe<Attestation_Record_Arr_Rel_Insert_Input>;
  changeRequests?: InputMaybe<Change_Request_Arr_Rel_Insert_Input>;
  file?: InputMaybe<File_Obj_Rel_Insert_Input>;
  parent?: InputMaybe<Document_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "risksmart.document_file" */
export type Document_File_Max_Order_By = {
  Content?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  FileId?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Link?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  NextReviewDate?: InputMaybe<Order_By>;
  ParentDocumentId?: InputMaybe<Order_By>;
  PublishedDate?: InputMaybe<Order_By>;
  ReasonForReview?: InputMaybe<Order_By>;
  ReviewDate?: InputMaybe<Order_By>;
  ReviewedBy?: InputMaybe<Order_By>;
  Summary?: InputMaybe<Order_By>;
  Version?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.document_file" */
export type Document_File_Min_Order_By = {
  Content?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  FileId?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Link?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  NextReviewDate?: InputMaybe<Order_By>;
  ParentDocumentId?: InputMaybe<Order_By>;
  PublishedDate?: InputMaybe<Order_By>;
  ReasonForReview?: InputMaybe<Order_By>;
  ReviewDate?: InputMaybe<Order_By>;
  ReviewedBy?: InputMaybe<Order_By>;
  Summary?: InputMaybe<Order_By>;
  Version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.document_file" */
export type Document_File_Mutation_Response = {
  __typename?: 'document_file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Document_File>;
};

/** input type for inserting object relation for remote table "risksmart.document_file" */
export type Document_File_Obj_Rel_Insert_Input = {
  data: Document_File_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Document_File_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.document_file" */
export type Document_File_On_Conflict = {
  constraint: Document_File_Constraint;
  update_columns?: Array<Document_File_Update_Column>;
  where?: InputMaybe<Document_File_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.document_file". */
export type Document_File_Order_By = {
  Content?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  FileId?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Link?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  NextReviewDate?: InputMaybe<Order_By>;
  ParentDocumentId?: InputMaybe<Order_By>;
  PublishedDate?: InputMaybe<Order_By>;
  ReasonForReview?: InputMaybe<Order_By>;
  ReviewDate?: InputMaybe<Order_By>;
  ReviewedBy?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Summary?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  Version?: InputMaybe<Order_By>;
  attestations_aggregate?: InputMaybe<Attestation_Record_Aggregate_Order_By>;
  changeRequests_aggregate?: InputMaybe<Change_Request_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  file?: InputMaybe<File_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  parent?: InputMaybe<Document_Order_By>;
  reviewedBy?: InputMaybe<User_Order_By>;
};

/** select columns of table "risksmart.document_file" */
export const Document_File_Select_Column = {
  /** column name */
  Content: 'Content',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  FileId: 'FileId',
  /** column name */
  Id: 'Id',
  /** column name */
  Link: 'Link',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  NextReviewDate: 'NextReviewDate',
  /** column name */
  ParentDocumentId: 'ParentDocumentId',
  /** column name */
  PublishedDate: 'PublishedDate',
  /** column name */
  ReasonForReview: 'ReasonForReview',
  /** column name */
  ReviewDate: 'ReviewDate',
  /** column name */
  ReviewedBy: 'ReviewedBy',
  /** column name */
  Status: 'Status',
  /** column name */
  Summary: 'Summary',
  /** column name */
  Type: 'Type',
  /** column name */
  Version: 'Version'
} as const;

export type Document_File_Select_Column = typeof Document_File_Select_Column[keyof typeof Document_File_Select_Column];
/** Streaming cursor of the table "document_file" */
export type Document_File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Document_File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_File_Stream_Cursor_Value_Input = {
  Content?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  FileId?: InputMaybe<Scalars['uuid']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Link?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ParentDocumentId?: InputMaybe<Scalars['uuid']['input']>;
  PublishedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReasonForReview?: InputMaybe<Scalars['String']['input']>;
  ReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReviewedBy?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Version_Status_Enum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Document_File_Type_Enum>;
  Version?: InputMaybe<Scalars['String']['input']>;
};

export const Document_File_Type_Enum = {
  /** File */
  File: 'file',
  /** Html */
  Html: 'html',
  /** Link */
  Link: 'link'
} as const;

export type Document_File_Type_Enum = typeof Document_File_Type_Enum[keyof typeof Document_File_Type_Enum];
/** Boolean expression to compare columns of type "document_file_type_enum". All fields are combined with logical 'AND'. */
export type Document_File_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Document_File_Type_Enum>;
  _in?: InputMaybe<Array<Document_File_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Document_File_Type_Enum>;
  _nin?: InputMaybe<Array<Document_File_Type_Enum>>;
};

/** update columns of table "risksmart.document_file" */
export const Document_File_Update_Column = {
  /** column name */
  Content: 'Content',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  FileId: 'FileId',
  /** column name */
  Link: 'Link',
  /** column name */
  NextReviewDate: 'NextReviewDate',
  /** column name */
  PublishedDate: 'PublishedDate',
  /** column name */
  ReasonForReview: 'ReasonForReview',
  /** column name */
  ReviewDate: 'ReviewDate',
  /** column name */
  ReviewedBy: 'ReviewedBy',
  /** column name */
  Status: 'Status',
  /** column name */
  Summary: 'Summary',
  /** column name */
  Type: 'Type',
  /** column name */
  Version: 'Version'
} as const;

export type Document_File_Update_Column = typeof Document_File_Update_Column[keyof typeof Document_File_Update_Column];
/** input type for inserting data into table "risksmart.document" */
export type Document_Insert_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DocumentType?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ParentDocument?: InputMaybe<Scalars['uuid']['input']>;
  Purpose?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  actions?: InputMaybe<Action_Parent_Arr_Rel_Insert_Input>;
  approvals?: InputMaybe<Approval_Arr_Rel_Insert_Input>;
  assessmentResults?: InputMaybe<Assessment_Result_Parent_Arr_Rel_Insert_Input>;
  attestationConfig?: InputMaybe<Attestation_Config_Obj_Rel_Insert_Input>;
  contributorGroups?: InputMaybe<Contributor_Group_Arr_Rel_Insert_Input>;
  contributors?: InputMaybe<Contributor_Arr_Rel_Insert_Input>;
  departments?: InputMaybe<Department_Arr_Rel_Insert_Input>;
  documentFiles?: InputMaybe<Document_File_Arr_Rel_Insert_Input>;
  issues?: InputMaybe<Issue_Parent_Arr_Rel_Insert_Input>;
  linkedDocuments?: InputMaybe<Document_Linked_Document_Arr_Rel_Insert_Input>;
  ownerGroups?: InputMaybe<Owner_Group_Arr_Rel_Insert_Input>;
  owners?: InputMaybe<Owner_Arr_Rel_Insert_Input>;
  parent?: InputMaybe<Document_Obj_Rel_Insert_Input>;
  schedule?: InputMaybe<Schedule_Obj_Rel_Insert_Input>;
  tags?: InputMaybe<Tag_Arr_Rel_Insert_Input>;
};

/** columns and relationships of "risksmart.document_linked_document" */
export type Document_Linked_Document = {
  __typename?: 'document_linked_document';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  DocumentId: Scalars['uuid']['output'];
  LinkedDocumentId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  /** An object relationship */
  child?: Maybe<Document>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parent?: Maybe<Document>;
};

/** order by aggregate values of table "risksmart.document_linked_document" */
export type Document_Linked_Document_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Document_Linked_Document_Max_Order_By>;
  min?: InputMaybe<Document_Linked_Document_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.document_linked_document" */
export type Document_Linked_Document_Arr_Rel_Insert_Input = {
  data: Array<Document_Linked_Document_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Document_Linked_Document_On_Conflict>;
};

/** columns and relationships of "risksmart.document_linked_document_audit" */
export type Document_Linked_Document_Audit = {
  __typename?: 'document_linked_document_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  DocumentId: Scalars['uuid']['output'];
  LinkedDocumentId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
};

/** Boolean expression to filter rows from the table "risksmart.document_linked_document_audit". All fields are combined with a logical 'AND'. */
export type Document_Linked_Document_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  DocumentId?: InputMaybe<Uuid_Comparison_Exp>;
  LinkedDocumentId?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Document_Linked_Document_Audit_Bool_Exp>>;
  _not?: InputMaybe<Document_Linked_Document_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Document_Linked_Document_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.document_linked_document_audit". */
export type Document_Linked_Document_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  DocumentId?: InputMaybe<Order_By>;
  LinkedDocumentId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.document_linked_document_audit" */
export const Document_Linked_Document_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  DocumentId: 'DocumentId',
  /** column name */
  LinkedDocumentId: 'LinkedDocumentId',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser'
} as const;

export type Document_Linked_Document_Audit_Select_Column = typeof Document_Linked_Document_Audit_Select_Column[keyof typeof Document_Linked_Document_Audit_Select_Column];
/** Streaming cursor of the table "document_linked_document_audit" */
export type Document_Linked_Document_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Document_Linked_Document_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_Linked_Document_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DocumentId?: InputMaybe<Scalars['uuid']['input']>;
  LinkedDocumentId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.document_linked_document". All fields are combined with a logical 'AND'. */
export type Document_Linked_Document_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  DocumentId?: InputMaybe<Uuid_Comparison_Exp>;
  LinkedDocumentId?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Document_Linked_Document_Bool_Exp>>;
  _not?: InputMaybe<Document_Linked_Document_Bool_Exp>;
  _or?: InputMaybe<Array<Document_Linked_Document_Bool_Exp>>;
  child?: InputMaybe<Document_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parent?: InputMaybe<Document_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.document_linked_document" */
export const Document_Linked_Document_Constraint = {
  /** unique or primary key constraint on columns "DocumentId", "LinkedDocumentId" */
  DocumentLinkedDocumentPkey: 'document_linked_document_pkey'
} as const;

export type Document_Linked_Document_Constraint = typeof Document_Linked_Document_Constraint[keyof typeof Document_Linked_Document_Constraint];
/** input type for inserting data into table "risksmart.document_linked_document" */
export type Document_Linked_Document_Insert_Input = {
  DocumentId?: InputMaybe<Scalars['uuid']['input']>;
  LinkedDocumentId?: InputMaybe<Scalars['uuid']['input']>;
  child?: InputMaybe<Document_Obj_Rel_Insert_Input>;
  parent?: InputMaybe<Document_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "risksmart.document_linked_document" */
export type Document_Linked_Document_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  DocumentId?: InputMaybe<Order_By>;
  LinkedDocumentId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.document_linked_document" */
export type Document_Linked_Document_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  DocumentId?: InputMaybe<Order_By>;
  LinkedDocumentId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.document_linked_document" */
export type Document_Linked_Document_Mutation_Response = {
  __typename?: 'document_linked_document_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Document_Linked_Document>;
};

/** on_conflict condition type for table "risksmart.document_linked_document" */
export type Document_Linked_Document_On_Conflict = {
  constraint: Document_Linked_Document_Constraint;
  update_columns?: Array<Document_Linked_Document_Update_Column>;
  where?: InputMaybe<Document_Linked_Document_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.document_linked_document". */
export type Document_Linked_Document_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  DocumentId?: InputMaybe<Order_By>;
  LinkedDocumentId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  child?: InputMaybe<Document_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parent?: InputMaybe<Document_Order_By>;
};

/** select columns of table "risksmart.document_linked_document" */
export const Document_Linked_Document_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  DocumentId: 'DocumentId',
  /** column name */
  LinkedDocumentId: 'LinkedDocumentId',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser'
} as const;

export type Document_Linked_Document_Select_Column = typeof Document_Linked_Document_Select_Column[keyof typeof Document_Linked_Document_Select_Column];
/** Streaming cursor of the table "document_linked_document" */
export type Document_Linked_Document_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Document_Linked_Document_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_Linked_Document_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DocumentId?: InputMaybe<Scalars['uuid']['input']>;
  LinkedDocumentId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
};

/** placeholder for update columns of table "risksmart.document_linked_document" (current role has no relevant permissions) */
export const Document_Linked_Document_Update_Column = {
  /** placeholder (do not use) */
  Placeholder: '_PLACEHOLDER'
} as const;

export type Document_Linked_Document_Update_Column = typeof Document_Linked_Document_Update_Column[keyof typeof Document_Linked_Document_Update_Column];
/** input type for inserting object relation for remote table "risksmart.document" */
export type Document_Obj_Rel_Insert_Input = {
  data: Document_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Document_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.document" */
export type Document_On_Conflict = {
  constraint: Document_Constraint;
  update_columns?: Array<Document_Update_Column>;
  where?: InputMaybe<Document_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.document". */
export type Document_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  DocumentType?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentDocument?: InputMaybe<Order_By>;
  Purpose?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  actions_aggregate?: InputMaybe<Action_Parent_Aggregate_Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  approvals_aggregate?: InputMaybe<Approval_Aggregate_Order_By>;
  assessmentResults_aggregate?: InputMaybe<Assessment_Result_Parent_Aggregate_Order_By>;
  attestationConfig?: InputMaybe<Attestation_Config_Order_By>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Order_By>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  departments_aggregate?: InputMaybe<Department_Aggregate_Order_By>;
  documentFiles_aggregate?: InputMaybe<Document_File_Aggregate_Order_By>;
  issues_aggregate?: InputMaybe<Issue_Parent_Aggregate_Order_By>;
  linkedDocuments_aggregate?: InputMaybe<Document_Linked_Document_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Order_By>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Order_By>;
  parent?: InputMaybe<Document_Order_By>;
  schedule?: InputMaybe<Schedule_Order_By>;
  scheduleState?: InputMaybe<Schedule_State_Order_By>;
  tags_aggregate?: InputMaybe<Tag_Aggregate_Order_By>;
};

/** select columns of table "risksmart.document" */
export const Document_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  DocumentType: 'DocumentType',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentDocument: 'ParentDocument',
  /** column name */
  Purpose: 'Purpose',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Title: 'Title'
} as const;

export type Document_Select_Column = typeof Document_Select_Column[keyof typeof Document_Select_Column];
/** Streaming cursor of the table "document" */
export type Document_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Document_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DocumentType?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentDocument?: InputMaybe<Scalars['uuid']['input']>;
  Purpose?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.document" */
export const Document_Update_Column = {
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  DocumentType: 'DocumentType',
  /** column name */
  ParentDocument: 'ParentDocument',
  /** column name */
  Purpose: 'Purpose',
  /** column name */
  Title: 'Title'
} as const;

export type Document_Update_Column = typeof Document_Update_Column[keyof typeof Document_Update_Column];
/** columns and relationships of "risksmart.file" */
export type File = {
  __typename?: 'file';
  ContentType: Scalars['String']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FileName: Scalars['String']['output'];
  FileSize: Scalars['Int']['output'];
  Id: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  documentFile?: Maybe<Document_File>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An array relationship */
  relationFile: Array<Relation_File>;
  /** An aggregate relationship */
  relationFile_aggregate: Relation_File_Aggregate;
};


/** columns and relationships of "risksmart.file" */
export type FileMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.file" */
export type FileRelationFileArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.file" */
export type FileRelationFile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};

/** aggregated selection of "risksmart.file" */
export type File_Aggregate = {
  __typename?: 'file_aggregate';
  aggregate?: Maybe<File_Aggregate_Fields>;
  nodes: Array<File>;
};

/** aggregate fields of "risksmart.file" */
export type File_Aggregate_Fields = {
  __typename?: 'file_aggregate_fields';
  avg?: Maybe<File_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<File_Max_Fields>;
  min?: Maybe<File_Min_Fields>;
  stddev?: Maybe<File_Stddev_Fields>;
  stddev_pop?: Maybe<File_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<File_Stddev_Samp_Fields>;
  sum?: Maybe<File_Sum_Fields>;
  var_pop?: Maybe<File_Var_Pop_Fields>;
  var_samp?: Maybe<File_Var_Samp_Fields>;
  variance?: Maybe<File_Variance_Fields>;
};


/** aggregate fields of "risksmart.file" */
export type File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "risksmart.file_audit" */
export type File_Audit = {
  __typename?: 'file_audit';
  ContentType: Scalars['String']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FileName: Scalars['String']['output'];
  FileSize: Scalars['Int']['output'];
  Id: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "risksmart.file_audit" */
export type File_AuditMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.file_audit". All fields are combined with a logical 'AND'. */
export type File_Audit_Bool_Exp = {
  ContentType?: InputMaybe<String_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  FileName?: InputMaybe<String_Comparison_Exp>;
  FileSize?: InputMaybe<Int_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  Meta?: InputMaybe<Json_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<File_Audit_Bool_Exp>>;
  _not?: InputMaybe<File_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<File_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.file_audit". */
export type File_Audit_Order_By = {
  ContentType?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  FileName?: InputMaybe<Order_By>;
  FileSize?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Meta?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.file_audit" */
export const File_Audit_Select_Column = {
  /** column name */
  ContentType: 'ContentType',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  FileName: 'FileName',
  /** column name */
  FileSize: 'FileSize',
  /** column name */
  Id: 'Id',
  /** column name */
  Meta: 'Meta',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser'
} as const;

export type File_Audit_Select_Column = typeof File_Audit_Select_Column[keyof typeof File_Audit_Select_Column];
/** Streaming cursor of the table "file_audit" */
export type File_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: File_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type File_Audit_Stream_Cursor_Value_Input = {
  ContentType?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FileName?: InputMaybe<Scalars['String']['input']>;
  FileSize?: InputMaybe<Scalars['Int']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate avg on columns */
export type File_Avg_Fields = {
  __typename?: 'file_avg_fields';
  FileSize?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.file". All fields are combined with a logical 'AND'. */
export type File_Bool_Exp = {
  ContentType?: InputMaybe<String_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  FileName?: InputMaybe<String_Comparison_Exp>;
  FileSize?: InputMaybe<Int_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  Meta?: InputMaybe<Json_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<File_Bool_Exp>>;
  _not?: InputMaybe<File_Bool_Exp>;
  _or?: InputMaybe<Array<File_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  documentFile?: InputMaybe<Document_File_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  relationFile?: InputMaybe<Relation_File_Bool_Exp>;
  relationFile_aggregate?: InputMaybe<Relation_File_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.file" */
export const File_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  FilePkey: 'file_pkey'
} as const;

export type File_Constraint = typeof File_Constraint[keyof typeof File_Constraint];
/** input type for inserting data into table "risksmart.file" */
export type File_Insert_Input = {
  ContentType?: InputMaybe<Scalars['String']['input']>;
  FileName?: InputMaybe<Scalars['String']['input']>;
  FileSize?: InputMaybe<Scalars['Int']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  documentFile?: InputMaybe<Document_File_Obj_Rel_Insert_Input>;
  relationFile?: InputMaybe<Relation_File_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type File_Max_Fields = {
  __typename?: 'file_max_fields';
  ContentType?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FileName?: Maybe<Scalars['String']['output']>;
  FileSize?: Maybe<Scalars['Int']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type File_Min_Fields = {
  __typename?: 'file_min_fields';
  ContentType?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FileName?: Maybe<Scalars['String']['output']>;
  FileSize?: Maybe<Scalars['Int']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.file" */
export type File_Mutation_Response = {
  __typename?: 'file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<File>;
};

/** input type for inserting object relation for remote table "risksmart.file" */
export type File_Obj_Rel_Insert_Input = {
  data: File_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<File_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.file" */
export type File_On_Conflict = {
  constraint: File_Constraint;
  update_columns?: Array<File_Update_Column>;
  where?: InputMaybe<File_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.file". */
export type File_Order_By = {
  ContentType?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  FileName?: InputMaybe<Order_By>;
  FileSize?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Meta?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  documentFile?: InputMaybe<Document_File_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  relationFile_aggregate?: InputMaybe<Relation_File_Aggregate_Order_By>;
};

/** select columns of table "risksmart.file" */
export const File_Select_Column = {
  /** column name */
  ContentType: 'ContentType',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  FileName: 'FileName',
  /** column name */
  FileSize: 'FileSize',
  /** column name */
  Id: 'Id',
  /** column name */
  Meta: 'Meta',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser'
} as const;

export type File_Select_Column = typeof File_Select_Column[keyof typeof File_Select_Column];
/** aggregate stddev on columns */
export type File_Stddev_Fields = {
  __typename?: 'file_stddev_fields';
  FileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type File_Stddev_Pop_Fields = {
  __typename?: 'file_stddev_pop_fields';
  FileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type File_Stddev_Samp_Fields = {
  __typename?: 'file_stddev_samp_fields';
  FileSize?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "file" */
export type File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type File_Stream_Cursor_Value_Input = {
  ContentType?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FileName?: InputMaybe<Scalars['String']['input']>;
  FileSize?: InputMaybe<Scalars['Int']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type File_Sum_Fields = {
  __typename?: 'file_sum_fields';
  FileSize?: Maybe<Scalars['Int']['output']>;
};

/** placeholder for update columns of table "risksmart.file" (current role has no relevant permissions) */
export const File_Update_Column = {
  /** placeholder (do not use) */
  Placeholder: '_PLACEHOLDER'
} as const;

export type File_Update_Column = typeof File_Update_Column[keyof typeof File_Update_Column];
/** aggregate var_pop on columns */
export type File_Var_Pop_Fields = {
  __typename?: 'file_var_pop_fields';
  FileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type File_Var_Samp_Fields = {
  __typename?: 'file_var_samp_fields';
  FileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type File_Variance_Fields = {
  __typename?: 'file_variance_fields';
  FileSize?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
};

/** columns and relationships of "risksmart.form_configuration" */
export type Form_Configuration = {
  __typename?: 'form_configuration';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeSchemaId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentType: Parent_Type_Enum;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  customAttributeSchema?: Maybe<Custom_Attribute_Schema>;
  /** An array relationship */
  fields_config: Array<Form_Field_Configuration>;
  /** An array relationship */
  fields_ordering: Array<Form_Field_Ordering>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
};


/** columns and relationships of "risksmart.form_configuration" */
export type Form_ConfigurationFields_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Form_Field_Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Form_Field_Configuration_Order_By>>;
  where?: InputMaybe<Form_Field_Configuration_Bool_Exp>;
};


/** columns and relationships of "risksmart.form_configuration" */
export type Form_ConfigurationFields_OrderingArgs = {
  distinct_on?: InputMaybe<Array<Form_Field_Ordering_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Form_Field_Ordering_Order_By>>;
  where?: InputMaybe<Form_Field_Ordering_Bool_Exp>;
};

/** columns and relationships of "risksmart.form_configuration_audit" */
export type Form_Configuration_Audit = {
  __typename?: 'form_configuration_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeSchemaId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentType: Scalars['String']['output'];
};

/** Boolean expression to filter rows from the table "risksmart.form_configuration_audit". All fields are combined with a logical 'AND'. */
export type Form_Configuration_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeSchemaId?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentType?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Form_Configuration_Audit_Bool_Exp>>;
  _not?: InputMaybe<Form_Configuration_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Form_Configuration_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.form_configuration_audit". */
export type Form_Configuration_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeSchemaId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentType?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.form_configuration_audit" */
export const Form_Configuration_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeSchemaId: 'CustomAttributeSchemaId',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentType: 'ParentType'
} as const;

export type Form_Configuration_Audit_Select_Column = typeof Form_Configuration_Audit_Select_Column[keyof typeof Form_Configuration_Audit_Select_Column];
/** Streaming cursor of the table "form_configuration_audit" */
export type Form_Configuration_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Form_Configuration_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Form_Configuration_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeSchemaId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.form_configuration". All fields are combined with a logical 'AND'. */
export type Form_Configuration_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeSchemaId?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentType?: InputMaybe<Parent_Type_Enum_Comparison_Exp>;
  _and?: InputMaybe<Array<Form_Configuration_Bool_Exp>>;
  _not?: InputMaybe<Form_Configuration_Bool_Exp>;
  _or?: InputMaybe<Array<Form_Configuration_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  customAttributeSchema?: InputMaybe<Custom_Attribute_Schema_Bool_Exp>;
  fields_config?: InputMaybe<Form_Field_Configuration_Bool_Exp>;
  fields_ordering?: InputMaybe<Form_Field_Ordering_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.form_configuration" */
export const Form_Configuration_Constraint = {
  /** unique or primary key constraint on columns "OrgKey", "ParentType" */
  FormConfigurationPkey: 'form_configuration_pkey'
} as const;

export type Form_Configuration_Constraint = typeof Form_Configuration_Constraint[keyof typeof Form_Configuration_Constraint];
/** input type for inserting data into table "risksmart.form_configuration" */
export type Form_Configuration_Insert_Input = {
  CustomAttributeSchemaId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Parent_Type_Enum>;
  customAttributeSchema?: InputMaybe<Custom_Attribute_Schema_Obj_Rel_Insert_Input>;
  fields_config?: InputMaybe<Form_Field_Configuration_Arr_Rel_Insert_Input>;
  fields_ordering?: InputMaybe<Form_Field_Ordering_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "risksmart.form_configuration" */
export type Form_Configuration_Mutation_Response = {
  __typename?: 'form_configuration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Form_Configuration>;
};

/** input type for inserting object relation for remote table "risksmart.form_configuration" */
export type Form_Configuration_Obj_Rel_Insert_Input = {
  data: Form_Configuration_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Form_Configuration_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.form_configuration" */
export type Form_Configuration_On_Conflict = {
  constraint: Form_Configuration_Constraint;
  update_columns?: Array<Form_Configuration_Update_Column>;
  where?: InputMaybe<Form_Configuration_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.form_configuration". */
export type Form_Configuration_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeSchemaId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentType?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  customAttributeSchema?: InputMaybe<Custom_Attribute_Schema_Order_By>;
  fields_config_aggregate?: InputMaybe<Form_Field_Configuration_Aggregate_Order_By>;
  fields_ordering_aggregate?: InputMaybe<Form_Field_Ordering_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
};

/** select columns of table "risksmart.form_configuration" */
export const Form_Configuration_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeSchemaId: 'CustomAttributeSchemaId',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentType: 'ParentType'
} as const;

export type Form_Configuration_Select_Column = typeof Form_Configuration_Select_Column[keyof typeof Form_Configuration_Select_Column];
/** input type for updating data in table "risksmart.form_configuration" */
export type Form_Configuration_Set_Input = {
  CustomAttributeSchemaId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Parent_Type_Enum>;
};

/** Streaming cursor of the table "form_configuration" */
export type Form_Configuration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Form_Configuration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Form_Configuration_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeSchemaId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentType?: InputMaybe<Parent_Type_Enum>;
};

/** update columns of table "risksmart.form_configuration" */
export const Form_Configuration_Update_Column = {
  /** column name */
  CustomAttributeSchemaId: 'CustomAttributeSchemaId',
  /** column name */
  ParentType: 'ParentType'
} as const;

export type Form_Configuration_Update_Column = typeof Form_Configuration_Update_Column[keyof typeof Form_Configuration_Update_Column];
export type Form_Configuration_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Form_Configuration_Set_Input>;
  /** filter the rows which have to be updated */
  where: Form_Configuration_Bool_Exp;
};

/** columns and relationships of "risksmart.form_field_configuration" */
export type Form_Field_Configuration = {
  __typename?: 'form_field_configuration';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  DefaultValue?: Maybe<Scalars['String']['output']>;
  FieldId: Scalars['String']['output'];
  FormConfigurationParentType: Parent_Type_Enum;
  Hidden: Scalars['Boolean']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ReadOnly: Scalars['Boolean']['output'];
  Required: Scalars['Boolean']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  form?: Maybe<Form_Configuration>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
};

/** order by aggregate values of table "risksmart.form_field_configuration" */
export type Form_Field_Configuration_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Form_Field_Configuration_Max_Order_By>;
  min?: InputMaybe<Form_Field_Configuration_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.form_field_configuration" */
export type Form_Field_Configuration_Arr_Rel_Insert_Input = {
  data: Array<Form_Field_Configuration_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Form_Field_Configuration_On_Conflict>;
};

/** columns and relationships of "risksmart.form_field_configuration_audit" */
export type Form_Field_Configuration_Audit = {
  __typename?: 'form_field_configuration_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  FieldId: Scalars['String']['output'];
  FormConfigurationParentType: Scalars['String']['output'];
  Hidden: Scalars['Boolean']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ReadOnly: Scalars['Boolean']['output'];
  Required: Scalars['Boolean']['output'];
};

/** Boolean expression to filter rows from the table "risksmart.form_field_configuration_audit". All fields are combined with a logical 'AND'. */
export type Form_Field_Configuration_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  FieldId?: InputMaybe<String_Comparison_Exp>;
  FormConfigurationParentType?: InputMaybe<String_Comparison_Exp>;
  Hidden?: InputMaybe<Boolean_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ReadOnly?: InputMaybe<Boolean_Comparison_Exp>;
  Required?: InputMaybe<Boolean_Comparison_Exp>;
  _and?: InputMaybe<Array<Form_Field_Configuration_Audit_Bool_Exp>>;
  _not?: InputMaybe<Form_Field_Configuration_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Form_Field_Configuration_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.form_field_configuration_audit". */
export type Form_Field_Configuration_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  FieldId?: InputMaybe<Order_By>;
  FormConfigurationParentType?: InputMaybe<Order_By>;
  Hidden?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ReadOnly?: InputMaybe<Order_By>;
  Required?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.form_field_configuration_audit" */
export const Form_Field_Configuration_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  FieldId: 'FieldId',
  /** column name */
  FormConfigurationParentType: 'FormConfigurationParentType',
  /** column name */
  Hidden: 'Hidden',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ReadOnly: 'ReadOnly',
  /** column name */
  Required: 'Required'
} as const;

export type Form_Field_Configuration_Audit_Select_Column = typeof Form_Field_Configuration_Audit_Select_Column[keyof typeof Form_Field_Configuration_Audit_Select_Column];
/** Streaming cursor of the table "form_field_configuration_audit" */
export type Form_Field_Configuration_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Form_Field_Configuration_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Form_Field_Configuration_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FieldId?: InputMaybe<Scalars['String']['input']>;
  FormConfigurationParentType?: InputMaybe<Scalars['String']['input']>;
  Hidden?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ReadOnly?: InputMaybe<Scalars['Boolean']['input']>;
  Required?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.form_field_configuration". All fields are combined with a logical 'AND'. */
export type Form_Field_Configuration_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  DefaultValue?: InputMaybe<String_Comparison_Exp>;
  FieldId?: InputMaybe<String_Comparison_Exp>;
  FormConfigurationParentType?: InputMaybe<Parent_Type_Enum_Comparison_Exp>;
  Hidden?: InputMaybe<Boolean_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ReadOnly?: InputMaybe<Boolean_Comparison_Exp>;
  Required?: InputMaybe<Boolean_Comparison_Exp>;
  _and?: InputMaybe<Array<Form_Field_Configuration_Bool_Exp>>;
  _not?: InputMaybe<Form_Field_Configuration_Bool_Exp>;
  _or?: InputMaybe<Array<Form_Field_Configuration_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  form?: InputMaybe<Form_Configuration_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.form_field_configuration" */
export const Form_Field_Configuration_Constraint = {
  /** unique or primary key constraint on columns "FormConfigurationParentType", "OrgKey", "FieldId" */
  FormFieldConfigurationPkey1: 'form_field_configuration_pkey1'
} as const;

export type Form_Field_Configuration_Constraint = typeof Form_Field_Configuration_Constraint[keyof typeof Form_Field_Configuration_Constraint];
/** input type for inserting data into table "risksmart.form_field_configuration" */
export type Form_Field_Configuration_Insert_Input = {
  DefaultValue?: InputMaybe<Scalars['String']['input']>;
  FieldId?: InputMaybe<Scalars['String']['input']>;
  FormConfigurationParentType?: InputMaybe<Parent_Type_Enum>;
  Hidden?: InputMaybe<Scalars['Boolean']['input']>;
  ReadOnly?: InputMaybe<Scalars['Boolean']['input']>;
  Required?: InputMaybe<Scalars['Boolean']['input']>;
  form?: InputMaybe<Form_Configuration_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "risksmart.form_field_configuration" */
export type Form_Field_Configuration_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  DefaultValue?: InputMaybe<Order_By>;
  FieldId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.form_field_configuration" */
export type Form_Field_Configuration_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  DefaultValue?: InputMaybe<Order_By>;
  FieldId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.form_field_configuration" */
export type Form_Field_Configuration_Mutation_Response = {
  __typename?: 'form_field_configuration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Form_Field_Configuration>;
};

/** on_conflict condition type for table "risksmart.form_field_configuration" */
export type Form_Field_Configuration_On_Conflict = {
  constraint: Form_Field_Configuration_Constraint;
  update_columns?: Array<Form_Field_Configuration_Update_Column>;
  where?: InputMaybe<Form_Field_Configuration_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.form_field_configuration". */
export type Form_Field_Configuration_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  DefaultValue?: InputMaybe<Order_By>;
  FieldId?: InputMaybe<Order_By>;
  FormConfigurationParentType?: InputMaybe<Order_By>;
  Hidden?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ReadOnly?: InputMaybe<Order_By>;
  Required?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  form?: InputMaybe<Form_Configuration_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
};

/** select columns of table "risksmart.form_field_configuration" */
export const Form_Field_Configuration_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  DefaultValue: 'DefaultValue',
  /** column name */
  FieldId: 'FieldId',
  /** column name */
  FormConfigurationParentType: 'FormConfigurationParentType',
  /** column name */
  Hidden: 'Hidden',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ReadOnly: 'ReadOnly',
  /** column name */
  Required: 'Required'
} as const;

export type Form_Field_Configuration_Select_Column = typeof Form_Field_Configuration_Select_Column[keyof typeof Form_Field_Configuration_Select_Column];
/** input type for updating data in table "risksmart.form_field_configuration" */
export type Form_Field_Configuration_Set_Input = {
  DefaultValue?: InputMaybe<Scalars['String']['input']>;
  FieldId?: InputMaybe<Scalars['String']['input']>;
  FormConfigurationParentType?: InputMaybe<Parent_Type_Enum>;
  Hidden?: InputMaybe<Scalars['Boolean']['input']>;
  ReadOnly?: InputMaybe<Scalars['Boolean']['input']>;
  Required?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "form_field_configuration" */
export type Form_Field_Configuration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Form_Field_Configuration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Form_Field_Configuration_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DefaultValue?: InputMaybe<Scalars['String']['input']>;
  FieldId?: InputMaybe<Scalars['String']['input']>;
  FormConfigurationParentType?: InputMaybe<Parent_Type_Enum>;
  Hidden?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ReadOnly?: InputMaybe<Scalars['Boolean']['input']>;
  Required?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "risksmart.form_field_configuration" */
export const Form_Field_Configuration_Update_Column = {
  /** column name */
  DefaultValue: 'DefaultValue',
  /** column name */
  FieldId: 'FieldId',
  /** column name */
  FormConfigurationParentType: 'FormConfigurationParentType',
  /** column name */
  Hidden: 'Hidden',
  /** column name */
  ReadOnly: 'ReadOnly',
  /** column name */
  Required: 'Required'
} as const;

export type Form_Field_Configuration_Update_Column = typeof Form_Field_Configuration_Update_Column[keyof typeof Form_Field_Configuration_Update_Column];
export type Form_Field_Configuration_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Form_Field_Configuration_Set_Input>;
  /** filter the rows which have to be updated */
  where: Form_Field_Configuration_Bool_Exp;
};

/** columns and relationships of "risksmart.form_field_ordering" */
export type Form_Field_Ordering = {
  __typename?: 'form_field_ordering';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  FieldId: Scalars['String']['output'];
  FormConfigurationParentType: Parent_Type_Enum;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Position: Scalars['Int']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  form?: Maybe<Form_Configuration>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
};

/** order by aggregate values of table "risksmart.form_field_ordering" */
export type Form_Field_Ordering_Aggregate_Order_By = {
  avg?: InputMaybe<Form_Field_Ordering_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Form_Field_Ordering_Max_Order_By>;
  min?: InputMaybe<Form_Field_Ordering_Min_Order_By>;
  stddev?: InputMaybe<Form_Field_Ordering_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Form_Field_Ordering_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Form_Field_Ordering_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Form_Field_Ordering_Sum_Order_By>;
  var_pop?: InputMaybe<Form_Field_Ordering_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Form_Field_Ordering_Var_Samp_Order_By>;
  variance?: InputMaybe<Form_Field_Ordering_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.form_field_ordering" */
export type Form_Field_Ordering_Arr_Rel_Insert_Input = {
  data: Array<Form_Field_Ordering_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Form_Field_Ordering_On_Conflict>;
};

/** columns and relationships of "risksmart.form_field_ordering_audit" */
export type Form_Field_Ordering_Audit = {
  __typename?: 'form_field_ordering_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  FieldId: Scalars['String']['output'];
  FormConfigurationParentType: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Position: Scalars['Int']['output'];
};

/** Boolean expression to filter rows from the table "risksmart.form_field_ordering_audit". All fields are combined with a logical 'AND'. */
export type Form_Field_Ordering_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  FieldId?: InputMaybe<String_Comparison_Exp>;
  FormConfigurationParentType?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Position?: InputMaybe<Int_Comparison_Exp>;
  _and?: InputMaybe<Array<Form_Field_Ordering_Audit_Bool_Exp>>;
  _not?: InputMaybe<Form_Field_Ordering_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Form_Field_Ordering_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.form_field_ordering_audit". */
export type Form_Field_Ordering_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  FieldId?: InputMaybe<Order_By>;
  FormConfigurationParentType?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Position?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.form_field_ordering_audit" */
export const Form_Field_Ordering_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  FieldId: 'FieldId',
  /** column name */
  FormConfigurationParentType: 'FormConfigurationParentType',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Position: 'Position'
} as const;

export type Form_Field_Ordering_Audit_Select_Column = typeof Form_Field_Ordering_Audit_Select_Column[keyof typeof Form_Field_Ordering_Audit_Select_Column];
/** Streaming cursor of the table "form_field_ordering_audit" */
export type Form_Field_Ordering_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Form_Field_Ordering_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Form_Field_Ordering_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FieldId?: InputMaybe<Scalars['String']['input']>;
  FormConfigurationParentType?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Position?: InputMaybe<Scalars['Int']['input']>;
};

/** order by avg() on columns of table "risksmart.form_field_ordering" */
export type Form_Field_Ordering_Avg_Order_By = {
  Position?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.form_field_ordering". All fields are combined with a logical 'AND'. */
export type Form_Field_Ordering_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  FieldId?: InputMaybe<String_Comparison_Exp>;
  FormConfigurationParentType?: InputMaybe<Parent_Type_Enum_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Position?: InputMaybe<Int_Comparison_Exp>;
  _and?: InputMaybe<Array<Form_Field_Ordering_Bool_Exp>>;
  _not?: InputMaybe<Form_Field_Ordering_Bool_Exp>;
  _or?: InputMaybe<Array<Form_Field_Ordering_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  form?: InputMaybe<Form_Configuration_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.form_field_ordering" */
export const Form_Field_Ordering_Constraint = {
  /** unique or primary key constraint on columns "FormConfigurationParentType", "OrgKey", "FieldId" */
  FormFieldConfigurationPkey: 'form_field_configuration_pkey'
} as const;

export type Form_Field_Ordering_Constraint = typeof Form_Field_Ordering_Constraint[keyof typeof Form_Field_Ordering_Constraint];
/** input type for incrementing numeric columns in table "risksmart.form_field_ordering" */
export type Form_Field_Ordering_Inc_Input = {
  Position?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.form_field_ordering" */
export type Form_Field_Ordering_Insert_Input = {
  FieldId?: InputMaybe<Scalars['String']['input']>;
  FormConfigurationParentType?: InputMaybe<Parent_Type_Enum>;
  Position?: InputMaybe<Scalars['Int']['input']>;
  form?: InputMaybe<Form_Configuration_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "risksmart.form_field_ordering" */
export type Form_Field_Ordering_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  FieldId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Position?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.form_field_ordering" */
export type Form_Field_Ordering_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  FieldId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Position?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.form_field_ordering" */
export type Form_Field_Ordering_Mutation_Response = {
  __typename?: 'form_field_ordering_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Form_Field_Ordering>;
};

/** on_conflict condition type for table "risksmart.form_field_ordering" */
export type Form_Field_Ordering_On_Conflict = {
  constraint: Form_Field_Ordering_Constraint;
  update_columns?: Array<Form_Field_Ordering_Update_Column>;
  where?: InputMaybe<Form_Field_Ordering_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.form_field_ordering". */
export type Form_Field_Ordering_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  FieldId?: InputMaybe<Order_By>;
  FormConfigurationParentType?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Position?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  form?: InputMaybe<Form_Configuration_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
};

/** select columns of table "risksmart.form_field_ordering" */
export const Form_Field_Ordering_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  FieldId: 'FieldId',
  /** column name */
  FormConfigurationParentType: 'FormConfigurationParentType',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Position: 'Position'
} as const;

export type Form_Field_Ordering_Select_Column = typeof Form_Field_Ordering_Select_Column[keyof typeof Form_Field_Ordering_Select_Column];
/** input type for updating data in table "risksmart.form_field_ordering" */
export type Form_Field_Ordering_Set_Input = {
  FieldId?: InputMaybe<Scalars['String']['input']>;
  FormConfigurationParentType?: InputMaybe<Parent_Type_Enum>;
  Position?: InputMaybe<Scalars['Int']['input']>;
};

/** order by stddev() on columns of table "risksmart.form_field_ordering" */
export type Form_Field_Ordering_Stddev_Order_By = {
  Position?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "risksmart.form_field_ordering" */
export type Form_Field_Ordering_Stddev_Pop_Order_By = {
  Position?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "risksmart.form_field_ordering" */
export type Form_Field_Ordering_Stddev_Samp_Order_By = {
  Position?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "form_field_ordering" */
export type Form_Field_Ordering_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Form_Field_Ordering_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Form_Field_Ordering_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FieldId?: InputMaybe<Scalars['String']['input']>;
  FormConfigurationParentType?: InputMaybe<Parent_Type_Enum>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Position?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "risksmart.form_field_ordering" */
export type Form_Field_Ordering_Sum_Order_By = {
  Position?: InputMaybe<Order_By>;
};

/** update columns of table "risksmart.form_field_ordering" */
export const Form_Field_Ordering_Update_Column = {
  /** column name */
  FieldId: 'FieldId',
  /** column name */
  FormConfigurationParentType: 'FormConfigurationParentType',
  /** column name */
  Position: 'Position'
} as const;

export type Form_Field_Ordering_Update_Column = typeof Form_Field_Ordering_Update_Column[keyof typeof Form_Field_Ordering_Update_Column];
export type Form_Field_Ordering_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Form_Field_Ordering_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Form_Field_Ordering_Set_Input>;
  /** filter the rows which have to be updated */
  where: Form_Field_Ordering_Bool_Exp;
};

/** order by var_pop() on columns of table "risksmart.form_field_ordering" */
export type Form_Field_Ordering_Var_Pop_Order_By = {
  Position?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "risksmart.form_field_ordering" */
export type Form_Field_Ordering_Var_Samp_Order_By = {
  Position?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "risksmart.form_field_ordering" */
export type Form_Field_Ordering_Variance_Order_By = {
  Position?: InputMaybe<Order_By>;
};

/** columns and relationships of "risksmart.impact" */
export type Impact = {
  __typename?: 'impact';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  LikelihoodAppetite?: Maybe<Scalars['smallint']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  RatingGuidance?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  SequentialId: Scalars['Int']['output'];
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An array relationship */
  appetites: Array<Appetite_Parent>;
  /** An aggregate relationship */
  appetites_aggregate: Appetite_Parent_Aggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An array relationship */
  ownerGroups: Array<Owner_Group>;
  /** An aggregate relationship */
  ownerGroups_aggregate: Owner_Group_Aggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: Owner_Aggregate;
  /** An array relationship */
  parents: Array<Impact_Parent>;
  /** An aggregate relationship */
  parents_aggregate: Impact_Parent_Aggregate;
  /** An array relationship */
  ratings: Array<Impact_Rating>;
  /** An aggregate relationship */
  ratings_aggregate: Impact_Rating_Aggregate;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactAppetitesArgs = {
  distinct_on?: InputMaybe<Array<Appetite_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Appetite_Parent_Order_By>>;
  where?: InputMaybe<Appetite_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactAppetites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appetite_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Appetite_Parent_Order_By>>;
  where?: InputMaybe<Appetite_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactOwnerGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactOwnersArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactOwners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactParentsArgs = {
  distinct_on?: InputMaybe<Array<Impact_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Parent_Order_By>>;
  where?: InputMaybe<Impact_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactParents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Impact_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Parent_Order_By>>;
  where?: InputMaybe<Impact_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactRatingsArgs = {
  distinct_on?: InputMaybe<Array<Impact_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Rating_Order_By>>;
  where?: InputMaybe<Impact_Rating_Bool_Exp>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactRatings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Impact_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Rating_Order_By>>;
  where?: InputMaybe<Impact_Rating_Bool_Exp>;
};

/** aggregated selection of "risksmart.impact" */
export type Impact_Aggregate = {
  __typename?: 'impact_aggregate';
  aggregate?: Maybe<Impact_Aggregate_Fields>;
  nodes: Array<Impact>;
};

/** aggregate fields of "risksmart.impact" */
export type Impact_Aggregate_Fields = {
  __typename?: 'impact_aggregate_fields';
  avg?: Maybe<Impact_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Impact_Max_Fields>;
  min?: Maybe<Impact_Min_Fields>;
  stddev?: Maybe<Impact_Stddev_Fields>;
  stddev_pop?: Maybe<Impact_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Impact_Stddev_Samp_Fields>;
  sum?: Maybe<Impact_Sum_Fields>;
  var_pop?: Maybe<Impact_Var_Pop_Fields>;
  var_samp?: Maybe<Impact_Var_Samp_Fields>;
  variance?: Maybe<Impact_Variance_Fields>;
};


/** aggregate fields of "risksmart.impact" */
export type Impact_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Impact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Impact_Append_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.impact_audit" */
export type Impact_Audit = {
  __typename?: 'impact_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  LikelihoodAppetite?: Maybe<Scalars['smallint']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  RatingGuidance?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  SequentialId: Scalars['Int']['output'];
};


/** columns and relationships of "risksmart.impact_audit" */
export type Impact_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.impact_audit". All fields are combined with a logical 'AND'. */
export type Impact_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  LikelihoodAppetite?: InputMaybe<Smallint_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  RatingGuidance?: InputMaybe<String_Comparison_Exp>;
  Rationale?: InputMaybe<String_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  _and?: InputMaybe<Array<Impact_Audit_Bool_Exp>>;
  _not?: InputMaybe<Impact_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Impact_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.impact_audit". */
export type Impact_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  LikelihoodAppetite?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  RatingGuidance?: InputMaybe<Order_By>;
  Rationale?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.impact_audit" */
export const Impact_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Id: 'Id',
  /** column name */
  LikelihoodAppetite: 'LikelihoodAppetite',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Name: 'Name',
  /** column name */
  RatingGuidance: 'RatingGuidance',
  /** column name */
  Rationale: 'Rationale',
  /** column name */
  SequentialId: 'SequentialId'
} as const;

export type Impact_Audit_Select_Column = typeof Impact_Audit_Select_Column[keyof typeof Impact_Audit_Select_Column];
/** Streaming cursor of the table "impact_audit" */
export type Impact_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Impact_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Impact_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['smallint']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  RatingGuidance?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate avg on columns */
export type Impact_Avg_Fields = {
  __typename?: 'impact_avg_fields';
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.impact". All fields are combined with a logical 'AND'. */
export type Impact_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  LikelihoodAppetite?: InputMaybe<Smallint_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  RatingGuidance?: InputMaybe<String_Comparison_Exp>;
  Rationale?: InputMaybe<String_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  _and?: InputMaybe<Array<Impact_Bool_Exp>>;
  _not?: InputMaybe<Impact_Bool_Exp>;
  _or?: InputMaybe<Array<Impact_Bool_Exp>>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  appetites?: InputMaybe<Appetite_Parent_Bool_Exp>;
  appetites_aggregate?: InputMaybe<Appetite_Parent_Aggregate_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  ownerGroups?: InputMaybe<Owner_Group_Bool_Exp>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Bool_Exp>;
  owners?: InputMaybe<Owner_Bool_Exp>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Bool_Exp>;
  parents?: InputMaybe<Impact_Parent_Bool_Exp>;
  parents_aggregate?: InputMaybe<Impact_Parent_Aggregate_Bool_Exp>;
  ratings?: InputMaybe<Impact_Rating_Bool_Exp>;
  ratings_aggregate?: InputMaybe<Impact_Rating_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.impact" */
export const Impact_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  ImpactPkey: 'impact_pkey'
} as const;

export type Impact_Constraint = typeof Impact_Constraint[keyof typeof Impact_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Impact_Delete_At_Path_Input = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Impact_Delete_Elem_Input = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Impact_Delete_Key_Input = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.impact" */
export type Impact_Inc_Input = {
  LikelihoodAppetite?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "risksmart.impact" */
export type Impact_Insert_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['smallint']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  RatingGuidance?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  appetites?: InputMaybe<Appetite_Parent_Arr_Rel_Insert_Input>;
  ownerGroups?: InputMaybe<Owner_Group_Arr_Rel_Insert_Input>;
  owners?: InputMaybe<Owner_Arr_Rel_Insert_Input>;
  parents?: InputMaybe<Impact_Parent_Arr_Rel_Insert_Input>;
  ratings?: InputMaybe<Impact_Rating_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Impact_Max_Fields = {
  __typename?: 'impact_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['smallint']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  RatingGuidance?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Impact_Min_Fields = {
  __typename?: 'impact_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['smallint']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  RatingGuidance?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "risksmart.impact" */
export type Impact_Mutation_Response = {
  __typename?: 'impact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Impact>;
};

/** input type for inserting object relation for remote table "risksmart.impact" */
export type Impact_Obj_Rel_Insert_Input = {
  data: Impact_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Impact_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.impact" */
export type Impact_On_Conflict = {
  constraint: Impact_Constraint;
  update_columns?: Array<Impact_Update_Column>;
  where?: InputMaybe<Impact_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.impact". */
export type Impact_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  LikelihoodAppetite?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  RatingGuidance?: InputMaybe<Order_By>;
  Rationale?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  appetites_aggregate?: InputMaybe<Appetite_Parent_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Order_By>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Order_By>;
  parents_aggregate?: InputMaybe<Impact_Parent_Aggregate_Order_By>;
  ratings_aggregate?: InputMaybe<Impact_Rating_Aggregate_Order_By>;
};

/** columns and relationships of "risksmart.impact_parent" */
export type Impact_Parent = {
  __typename?: 'impact_parent';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ImpactId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  impact?: Maybe<Impact>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parent?: Maybe<Node>;
};

/** aggregated selection of "risksmart.impact_parent" */
export type Impact_Parent_Aggregate = {
  __typename?: 'impact_parent_aggregate';
  aggregate?: Maybe<Impact_Parent_Aggregate_Fields>;
  nodes: Array<Impact_Parent>;
};

export type Impact_Parent_Aggregate_Bool_Exp = {
  count?: InputMaybe<Impact_Parent_Aggregate_Bool_Exp_Count>;
};

export type Impact_Parent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Impact_Parent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Impact_Parent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.impact_parent" */
export type Impact_Parent_Aggregate_Fields = {
  __typename?: 'impact_parent_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Impact_Parent_Max_Fields>;
  min?: Maybe<Impact_Parent_Min_Fields>;
};


/** aggregate fields of "risksmart.impact_parent" */
export type Impact_Parent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Impact_Parent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.impact_parent" */
export type Impact_Parent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Impact_Parent_Max_Order_By>;
  min?: InputMaybe<Impact_Parent_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.impact_parent" */
export type Impact_Parent_Arr_Rel_Insert_Input = {
  data: Array<Impact_Parent_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Impact_Parent_On_Conflict>;
};

/** columns and relationships of "risksmart.impact_parent_audit" */
export type Impact_Parent_Audit = {
  __typename?: 'impact_parent_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ImpactId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
};

/** Boolean expression to filter rows from the table "risksmart.impact_parent_audit". All fields are combined with a logical 'AND'. */
export type Impact_Parent_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  ImpactId?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Impact_Parent_Audit_Bool_Exp>>;
  _not?: InputMaybe<Impact_Parent_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Impact_Parent_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.impact_parent_audit". */
export type Impact_Parent_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ImpactId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.impact_parent_audit" */
export const Impact_Parent_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  ImpactId: 'ImpactId',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId'
} as const;

export type Impact_Parent_Audit_Select_Column = typeof Impact_Parent_Audit_Select_Column[keyof typeof Impact_Parent_Audit_Select_Column];
/** Streaming cursor of the table "impact_parent_audit" */
export type Impact_Parent_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Impact_Parent_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Impact_Parent_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ImpactId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.impact_parent". All fields are combined with a logical 'AND'. */
export type Impact_Parent_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  ImpactId?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Impact_Parent_Bool_Exp>>;
  _not?: InputMaybe<Impact_Parent_Bool_Exp>;
  _or?: InputMaybe<Array<Impact_Parent_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  impact?: InputMaybe<Impact_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parent?: InputMaybe<Node_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.impact_parent" */
export const Impact_Parent_Constraint = {
  /** unique or primary key constraint on columns "ImpactId", "ParentId" */
  ImpactParentPkey: 'impact_parent_pkey'
} as const;

export type Impact_Parent_Constraint = typeof Impact_Parent_Constraint[keyof typeof Impact_Parent_Constraint];
/** input type for inserting data into table "risksmart.impact_parent" */
export type Impact_Parent_Insert_Input = {
  ImpactId?: InputMaybe<Scalars['uuid']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  impact?: InputMaybe<Impact_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Impact_Parent_Max_Fields = {
  __typename?: 'impact_parent_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ImpactId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.impact_parent" */
export type Impact_Parent_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ImpactId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Impact_Parent_Min_Fields = {
  __typename?: 'impact_parent_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ImpactId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.impact_parent" */
export type Impact_Parent_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ImpactId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "risksmart.impact_parent" */
export type Impact_Parent_On_Conflict = {
  constraint: Impact_Parent_Constraint;
  update_columns?: Array<Impact_Parent_Update_Column>;
  where?: InputMaybe<Impact_Parent_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.impact_parent". */
export type Impact_Parent_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ImpactId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  impact?: InputMaybe<Impact_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parent?: InputMaybe<Node_Order_By>;
};

/** select columns of table "risksmart.impact_parent" */
export const Impact_Parent_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  ImpactId: 'ImpactId',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId'
} as const;

export type Impact_Parent_Select_Column = typeof Impact_Parent_Select_Column[keyof typeof Impact_Parent_Select_Column];
/** Streaming cursor of the table "impact_parent" */
export type Impact_Parent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Impact_Parent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Impact_Parent_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ImpactId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** placeholder for update columns of table "risksmart.impact_parent" (current role has no relevant permissions) */
export const Impact_Parent_Update_Column = {
  /** placeholder (do not use) */
  Placeholder: '_PLACEHOLDER'
} as const;

export type Impact_Parent_Update_Column = typeof Impact_Parent_Update_Column[keyof typeof Impact_Parent_Update_Column];
/** primary key columns input for table: risksmart.impact */
export type Impact_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Impact_Prepend_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.impact_rating" */
export type Impact_Rating = {
  __typename?: 'impact_rating';
  CompletedBy?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ImpactId: Scalars['uuid']['output'];
  Likelihood?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  RatedItemId: Scalars['uuid']['output'];
  Rating: Scalars['smallint']['output'];
  RatingType: Scalars['String']['output'];
  SequentialId: Scalars['Int']['output'];
  TestDate: Scalars['timestamptz']['output'];
  /** An array relationship */
  assessmentParents: Array<Assessment_Result_Parent>;
  /** An aggregate relationship */
  assessmentParents_aggregate: Assessment_Result_Parent_Aggregate;
  /** An object relationship */
  completedBy?: Maybe<User>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  impact: Impact;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  ratedItem: Node;
};


/** columns and relationships of "risksmart.impact_rating" */
export type Impact_RatingCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.impact_rating" */
export type Impact_RatingAssessmentParentsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.impact_rating" */
export type Impact_RatingAssessmentParents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};

/** aggregated selection of "risksmart.impact_rating" */
export type Impact_Rating_Aggregate = {
  __typename?: 'impact_rating_aggregate';
  aggregate?: Maybe<Impact_Rating_Aggregate_Fields>;
  nodes: Array<Impact_Rating>;
};

export type Impact_Rating_Aggregate_Bool_Exp = {
  count?: InputMaybe<Impact_Rating_Aggregate_Bool_Exp_Count>;
};

export type Impact_Rating_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Impact_Rating_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Impact_Rating_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.impact_rating" */
export type Impact_Rating_Aggregate_Fields = {
  __typename?: 'impact_rating_aggregate_fields';
  avg?: Maybe<Impact_Rating_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Impact_Rating_Max_Fields>;
  min?: Maybe<Impact_Rating_Min_Fields>;
  stddev?: Maybe<Impact_Rating_Stddev_Fields>;
  stddev_pop?: Maybe<Impact_Rating_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Impact_Rating_Stddev_Samp_Fields>;
  sum?: Maybe<Impact_Rating_Sum_Fields>;
  var_pop?: Maybe<Impact_Rating_Var_Pop_Fields>;
  var_samp?: Maybe<Impact_Rating_Var_Samp_Fields>;
  variance?: Maybe<Impact_Rating_Variance_Fields>;
};


/** aggregate fields of "risksmart.impact_rating" */
export type Impact_Rating_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Impact_Rating_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.impact_rating" */
export type Impact_Rating_Aggregate_Order_By = {
  avg?: InputMaybe<Impact_Rating_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Impact_Rating_Max_Order_By>;
  min?: InputMaybe<Impact_Rating_Min_Order_By>;
  stddev?: InputMaybe<Impact_Rating_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Impact_Rating_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Impact_Rating_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Impact_Rating_Sum_Order_By>;
  var_pop?: InputMaybe<Impact_Rating_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Impact_Rating_Var_Samp_Order_By>;
  variance?: InputMaybe<Impact_Rating_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.impact_rating" */
export type Impact_Rating_Arr_Rel_Insert_Input = {
  data: Array<Impact_Rating_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Impact_Rating_On_Conflict>;
};

/** columns and relationships of "risksmart.impact_rating_audit" */
export type Impact_Rating_Audit = {
  __typename?: 'impact_rating_audit';
  CompletedBy?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ImpactId: Scalars['uuid']['output'];
  Likelihood?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  RatedItemId: Scalars['uuid']['output'];
  Rating: Scalars['smallint']['output'];
  RatingType?: Maybe<Scalars['String']['output']>;
  SequentialId: Scalars['Int']['output'];
  TestDate: Scalars['timestamptz']['output'];
};


/** columns and relationships of "risksmart.impact_rating_audit" */
export type Impact_Rating_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "risksmart.impact_rating_audit" */
export type Impact_Rating_Audit_Aggregate_Order_By = {
  avg?: InputMaybe<Impact_Rating_Audit_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Impact_Rating_Audit_Max_Order_By>;
  min?: InputMaybe<Impact_Rating_Audit_Min_Order_By>;
  stddev?: InputMaybe<Impact_Rating_Audit_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Impact_Rating_Audit_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Impact_Rating_Audit_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Impact_Rating_Audit_Sum_Order_By>;
  var_pop?: InputMaybe<Impact_Rating_Audit_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Impact_Rating_Audit_Var_Samp_Order_By>;
  variance?: InputMaybe<Impact_Rating_Audit_Variance_Order_By>;
};

/** order by avg() on columns of table "risksmart.impact_rating_audit" */
export type Impact_Rating_Audit_Avg_Order_By = {
  Likelihood?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.impact_rating_audit". All fields are combined with a logical 'AND'. */
export type Impact_Rating_Audit_Bool_Exp = {
  CompletedBy?: InputMaybe<String_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ImpactId?: InputMaybe<Uuid_Comparison_Exp>;
  Likelihood?: InputMaybe<Int_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  RatedItemId?: InputMaybe<Uuid_Comparison_Exp>;
  Rating?: InputMaybe<Smallint_Comparison_Exp>;
  RatingType?: InputMaybe<String_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  TestDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  _and?: InputMaybe<Array<Impact_Rating_Audit_Bool_Exp>>;
  _not?: InputMaybe<Impact_Rating_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Impact_Rating_Audit_Bool_Exp>>;
};

/** order by max() on columns of table "risksmart.impact_rating_audit" */
export type Impact_Rating_Audit_Max_Order_By = {
  CompletedBy?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ImpactId?: InputMaybe<Order_By>;
  Likelihood?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  RatedItemId?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  RatingType?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  TestDate?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.impact_rating_audit" */
export type Impact_Rating_Audit_Min_Order_By = {
  CompletedBy?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ImpactId?: InputMaybe<Order_By>;
  Likelihood?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  RatedItemId?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  RatingType?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  TestDate?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "risksmart.impact_rating_audit". */
export type Impact_Rating_Audit_Order_By = {
  CompletedBy?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ImpactId?: InputMaybe<Order_By>;
  Likelihood?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  RatedItemId?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  RatingType?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  TestDate?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.impact_rating_audit" */
export const Impact_Rating_Audit_Select_Column = {
  /** column name */
  CompletedBy: 'CompletedBy',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Id: 'Id',
  /** column name */
  ImpactId: 'ImpactId',
  /** column name */
  Likelihood: 'Likelihood',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  RatedItemId: 'RatedItemId',
  /** column name */
  Rating: 'Rating',
  /** column name */
  RatingType: 'RatingType',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  TestDate: 'TestDate'
} as const;

export type Impact_Rating_Audit_Select_Column = typeof Impact_Rating_Audit_Select_Column[keyof typeof Impact_Rating_Audit_Select_Column];
/** order by stddev() on columns of table "risksmart.impact_rating_audit" */
export type Impact_Rating_Audit_Stddev_Order_By = {
  Likelihood?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "risksmart.impact_rating_audit" */
export type Impact_Rating_Audit_Stddev_Pop_Order_By = {
  Likelihood?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "risksmart.impact_rating_audit" */
export type Impact_Rating_Audit_Stddev_Samp_Order_By = {
  Likelihood?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "impact_rating_audit" */
export type Impact_Rating_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Impact_Rating_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Impact_Rating_Audit_Stream_Cursor_Value_Input = {
  CompletedBy?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactId?: InputMaybe<Scalars['uuid']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  RatedItemId?: InputMaybe<Scalars['uuid']['input']>;
  Rating?: InputMaybe<Scalars['smallint']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** order by sum() on columns of table "risksmart.impact_rating_audit" */
export type Impact_Rating_Audit_Sum_Order_By = {
  Likelihood?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "risksmart.impact_rating_audit" */
export type Impact_Rating_Audit_Var_Pop_Order_By = {
  Likelihood?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "risksmart.impact_rating_audit" */
export type Impact_Rating_Audit_Var_Samp_Order_By = {
  Likelihood?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "risksmart.impact_rating_audit" */
export type Impact_Rating_Audit_Variance_Order_By = {
  Likelihood?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate avg on columns */
export type Impact_Rating_Avg_Fields = {
  __typename?: 'impact_rating_avg_fields';
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.impact_rating" */
export type Impact_Rating_Avg_Order_By = {
  Likelihood?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.impact_rating". All fields are combined with a logical 'AND'. */
export type Impact_Rating_Bool_Exp = {
  CompletedBy?: InputMaybe<String_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ImpactId?: InputMaybe<Uuid_Comparison_Exp>;
  Likelihood?: InputMaybe<Int_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  RatedItemId?: InputMaybe<Uuid_Comparison_Exp>;
  Rating?: InputMaybe<Smallint_Comparison_Exp>;
  RatingType?: InputMaybe<String_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  TestDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  _and?: InputMaybe<Array<Impact_Rating_Bool_Exp>>;
  _not?: InputMaybe<Impact_Rating_Bool_Exp>;
  _or?: InputMaybe<Array<Impact_Rating_Bool_Exp>>;
  assessmentParents?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
  assessmentParents_aggregate?: InputMaybe<Assessment_Result_Parent_Aggregate_Bool_Exp>;
  completedBy?: InputMaybe<User_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  impact?: InputMaybe<Impact_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  ratedItem?: InputMaybe<Node_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.impact_rating" */
export const Impact_Rating_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  ImpactRatingPkey: 'impact_rating_pkey'
} as const;

export type Impact_Rating_Constraint = typeof Impact_Rating_Constraint[keyof typeof Impact_Rating_Constraint];
/** input type for inserting data into table "risksmart.impact_rating" */
export type Impact_Rating_Insert_Input = {
  CompletedBy?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  ImpactId?: InputMaybe<Scalars['uuid']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  RatedItemId?: InputMaybe<Scalars['uuid']['input']>;
  Rating?: InputMaybe<Scalars['smallint']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  assessmentParents?: InputMaybe<Assessment_Result_Parent_Arr_Rel_Insert_Input>;
  impact?: InputMaybe<Impact_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Impact_Rating_Max_Fields = {
  __typename?: 'impact_rating_max_fields';
  CompletedBy?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImpactId?: Maybe<Scalars['uuid']['output']>;
  Likelihood?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  RatedItemId?: Maybe<Scalars['uuid']['output']>;
  Rating?: Maybe<Scalars['smallint']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "risksmart.impact_rating" */
export type Impact_Rating_Max_Order_By = {
  CompletedBy?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ImpactId?: InputMaybe<Order_By>;
  Likelihood?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  RatedItemId?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  RatingType?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  TestDate?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Impact_Rating_Min_Fields = {
  __typename?: 'impact_rating_min_fields';
  CompletedBy?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImpactId?: Maybe<Scalars['uuid']['output']>;
  Likelihood?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  RatedItemId?: Maybe<Scalars['uuid']['output']>;
  Rating?: Maybe<Scalars['smallint']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "risksmart.impact_rating" */
export type Impact_Rating_Min_Order_By = {
  CompletedBy?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ImpactId?: InputMaybe<Order_By>;
  Likelihood?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  RatedItemId?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  RatingType?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  TestDate?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.impact_rating" */
export type Impact_Rating_Mutation_Response = {
  __typename?: 'impact_rating_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Impact_Rating>;
};

/** input type for inserting object relation for remote table "risksmart.impact_rating" */
export type Impact_Rating_Obj_Rel_Insert_Input = {
  data: Impact_Rating_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Impact_Rating_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.impact_rating" */
export type Impact_Rating_On_Conflict = {
  constraint: Impact_Rating_Constraint;
  update_columns?: Array<Impact_Rating_Update_Column>;
  where?: InputMaybe<Impact_Rating_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.impact_rating". */
export type Impact_Rating_Order_By = {
  CompletedBy?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ImpactId?: InputMaybe<Order_By>;
  Likelihood?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  RatedItemId?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  RatingType?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  TestDate?: InputMaybe<Order_By>;
  assessmentParents_aggregate?: InputMaybe<Assessment_Result_Parent_Aggregate_Order_By>;
  completedBy?: InputMaybe<User_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  impact?: InputMaybe<Impact_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  ratedItem?: InputMaybe<Node_Order_By>;
};

/** select columns of table "risksmart.impact_rating" */
export const Impact_Rating_Select_Column = {
  /** column name */
  CompletedBy: 'CompletedBy',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Id: 'Id',
  /** column name */
  ImpactId: 'ImpactId',
  /** column name */
  Likelihood: 'Likelihood',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  RatedItemId: 'RatedItemId',
  /** column name */
  Rating: 'Rating',
  /** column name */
  RatingType: 'RatingType',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  TestDate: 'TestDate'
} as const;

export type Impact_Rating_Select_Column = typeof Impact_Rating_Select_Column[keyof typeof Impact_Rating_Select_Column];
/** aggregate stddev on columns */
export type Impact_Rating_Stddev_Fields = {
  __typename?: 'impact_rating_stddev_fields';
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.impact_rating" */
export type Impact_Rating_Stddev_Order_By = {
  Likelihood?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Impact_Rating_Stddev_Pop_Fields = {
  __typename?: 'impact_rating_stddev_pop_fields';
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.impact_rating" */
export type Impact_Rating_Stddev_Pop_Order_By = {
  Likelihood?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Impact_Rating_Stddev_Samp_Fields = {
  __typename?: 'impact_rating_stddev_samp_fields';
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.impact_rating" */
export type Impact_Rating_Stddev_Samp_Order_By = {
  Likelihood?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "impact_rating" */
export type Impact_Rating_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Impact_Rating_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Impact_Rating_Stream_Cursor_Value_Input = {
  CompletedBy?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactId?: InputMaybe<Scalars['uuid']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  RatedItemId?: InputMaybe<Scalars['uuid']['input']>;
  Rating?: InputMaybe<Scalars['smallint']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Impact_Rating_Sum_Fields = {
  __typename?: 'impact_rating_sum_fields';
  Likelihood?: Maybe<Scalars['Int']['output']>;
  Rating?: Maybe<Scalars['smallint']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.impact_rating" */
export type Impact_Rating_Sum_Order_By = {
  Likelihood?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** placeholder for update columns of table "risksmart.impact_rating" (current role has no relevant permissions) */
export const Impact_Rating_Update_Column = {
  /** placeholder (do not use) */
  Placeholder: '_PLACEHOLDER'
} as const;

export type Impact_Rating_Update_Column = typeof Impact_Rating_Update_Column[keyof typeof Impact_Rating_Update_Column];
/** aggregate var_pop on columns */
export type Impact_Rating_Var_Pop_Fields = {
  __typename?: 'impact_rating_var_pop_fields';
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.impact_rating" */
export type Impact_Rating_Var_Pop_Order_By = {
  Likelihood?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Impact_Rating_Var_Samp_Fields = {
  __typename?: 'impact_rating_var_samp_fields';
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.impact_rating" */
export type Impact_Rating_Var_Samp_Order_By = {
  Likelihood?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Impact_Rating_Variance_Fields = {
  __typename?: 'impact_rating_variance_fields';
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.impact_rating" */
export type Impact_Rating_Variance_Order_By = {
  Likelihood?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.impact" */
export const Impact_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Id: 'Id',
  /** column name */
  LikelihoodAppetite: 'LikelihoodAppetite',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Name: 'Name',
  /** column name */
  RatingGuidance: 'RatingGuidance',
  /** column name */
  Rationale: 'Rationale',
  /** column name */
  SequentialId: 'SequentialId'
} as const;

export type Impact_Select_Column = typeof Impact_Select_Column[keyof typeof Impact_Select_Column];
/** input type for updating data in table "risksmart.impact" */
export type Impact_Set_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['smallint']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  RatingGuidance?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Impact_Stddev_Fields = {
  __typename?: 'impact_stddev_fields';
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Impact_Stddev_Pop_Fields = {
  __typename?: 'impact_stddev_pop_fields';
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Impact_Stddev_Samp_Fields = {
  __typename?: 'impact_stddev_samp_fields';
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "impact" */
export type Impact_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Impact_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Impact_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['smallint']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  RatingGuidance?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Impact_Sum_Fields = {
  __typename?: 'impact_sum_fields';
  LikelihoodAppetite?: Maybe<Scalars['smallint']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.impact" */
export const Impact_Update_Column = {
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  LikelihoodAppetite: 'LikelihoodAppetite',
  /** column name */
  Name: 'Name',
  /** column name */
  RatingGuidance: 'RatingGuidance',
  /** column name */
  Rationale: 'Rationale'
} as const;

export type Impact_Update_Column = typeof Impact_Update_Column[keyof typeof Impact_Update_Column];
export type Impact_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Impact_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Impact_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Impact_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Impact_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Impact_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Impact_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Impact_Set_Input>;
  /** filter the rows which have to be updated */
  where: Impact_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Impact_Var_Pop_Fields = {
  __typename?: 'impact_var_pop_fields';
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Impact_Var_Samp_Fields = {
  __typename?: 'impact_var_samp_fields';
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Impact_Variance_Fields = {
  __typename?: 'impact_variance_fields';
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.indicator" */
export type Indicator = {
  __typename?: 'indicator';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  LowerAppetiteNum?: Maybe<Scalars['numeric']['output']>;
  LowerToleranceNum?: Maybe<Scalars['numeric']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TargetValueTxt?: Maybe<Scalars['String']['output']>;
  Title: Scalars['String']['output'];
  Type: Indicator_Type_Enum;
  Unit?: Maybe<Scalars['String']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['numeric']['output']>;
  UpperToleranceNum?: Maybe<Scalars['numeric']['output']>;
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An array relationship */
  contributorGroups: Array<Contributor_Group>;
  /** An aggregate relationship */
  contributorGroups_aggregate: Contributor_Group_Aggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: Contributor_Aggregate;
  /** An object relationship */
  createdBy?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An array relationship */
  files: Array<Relation_File>;
  /** An aggregate relationship */
  files_aggregate: Relation_File_Aggregate;
  /** An object relationship */
  indicator_type: Indicator_Type;
  /** An object relationship */
  modifiedBy?: Maybe<User>;
  /** An object relationship */
  org?: Maybe<Auth_Organisation>;
  /** An array relationship */
  ownerGroups: Array<Owner_Group>;
  /** An aggregate relationship */
  ownerGroups_aggregate: Owner_Group_Aggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: Owner_Aggregate;
  /** An array relationship */
  parents: Array<Indicator_Parent>;
  /** An aggregate relationship */
  parents_aggregate: Indicator_Parent_Aggregate;
  /** An array relationship */
  results: Array<Indicator_Result>;
  /** An object relationship */
  schedule?: Maybe<Schedule>;
  /** An object relationship */
  scheduleState?: Maybe<Schedule_State>;
  /** An array relationship */
  tags: Array<Tag>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorContributorGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorContributors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorFilesArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorOwnerGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorOwnersArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorOwners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorParentsArgs = {
  distinct_on?: InputMaybe<Array<Indicator_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Indicator_Parent_Order_By>>;
  where?: InputMaybe<Indicator_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorParents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Indicator_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Indicator_Parent_Order_By>>;
  where?: InputMaybe<Indicator_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorResultsArgs = {
  distinct_on?: InputMaybe<Array<Indicator_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Indicator_Result_Order_By>>;
  where?: InputMaybe<Indicator_Result_Bool_Exp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorTagsArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};

/** order by aggregate values of table "risksmart.indicator" */
export type Indicator_Aggregate_Order_By = {
  avg?: InputMaybe<Indicator_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Indicator_Max_Order_By>;
  min?: InputMaybe<Indicator_Min_Order_By>;
  stddev?: InputMaybe<Indicator_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Indicator_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Indicator_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Indicator_Sum_Order_By>;
  var_pop?: InputMaybe<Indicator_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Indicator_Var_Samp_Order_By>;
  variance?: InputMaybe<Indicator_Variance_Order_By>;
};

/** columns and relationships of "risksmart.indicator_audit" */
export type Indicator_Audit = {
  __typename?: 'indicator_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  LowerAppetiteNum?: Maybe<Scalars['numeric']['output']>;
  LowerToleranceNum?: Maybe<Scalars['numeric']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TargetValueTxt?: Maybe<Scalars['String']['output']>;
  Title: Scalars['String']['output'];
  Type: Scalars['String']['output'];
  Unit?: Maybe<Scalars['String']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['numeric']['output']>;
  UpperToleranceNum?: Maybe<Scalars['numeric']['output']>;
};


/** columns and relationships of "risksmart.indicator_audit" */
export type Indicator_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.indicator_audit". All fields are combined with a logical 'AND'. */
export type Indicator_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  LowerAppetiteNum?: InputMaybe<Numeric_Comparison_Exp>;
  LowerToleranceNum?: InputMaybe<Numeric_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  TargetValueTxt?: InputMaybe<String_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  Type?: InputMaybe<String_Comparison_Exp>;
  Unit?: InputMaybe<String_Comparison_Exp>;
  UpperAppetiteNum?: InputMaybe<Numeric_Comparison_Exp>;
  UpperToleranceNum?: InputMaybe<Numeric_Comparison_Exp>;
  _and?: InputMaybe<Array<Indicator_Audit_Bool_Exp>>;
  _not?: InputMaybe<Indicator_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Indicator_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.indicator_audit". */
export type Indicator_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  LowerAppetiteNum?: InputMaybe<Order_By>;
  LowerToleranceNum?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  TargetValueTxt?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  Unit?: InputMaybe<Order_By>;
  UpperAppetiteNum?: InputMaybe<Order_By>;
  UpperToleranceNum?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.indicator_audit" */
export const Indicator_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  LowerAppetiteNum: 'LowerAppetiteNum',
  /** column name */
  LowerToleranceNum: 'LowerToleranceNum',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  TargetValueTxt: 'TargetValueTxt',
  /** column name */
  Title: 'Title',
  /** column name */
  Type: 'Type',
  /** column name */
  Unit: 'Unit',
  /** column name */
  UpperAppetiteNum: 'UpperAppetiteNum',
  /** column name */
  UpperToleranceNum: 'UpperToleranceNum'
} as const;

export type Indicator_Audit_Select_Column = typeof Indicator_Audit_Select_Column[keyof typeof Indicator_Audit_Select_Column];
/** Streaming cursor of the table "indicator_audit" */
export type Indicator_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Indicator_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Indicator_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LowerAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  LowerToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TargetValueTxt?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  Unit?: InputMaybe<Scalars['String']['input']>;
  UpperAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  UpperToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
};

/** order by avg() on columns of table "risksmart.indicator" */
export type Indicator_Avg_Order_By = {
  LowerAppetiteNum?: InputMaybe<Order_By>;
  LowerToleranceNum?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  UpperAppetiteNum?: InputMaybe<Order_By>;
  UpperToleranceNum?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.indicator". All fields are combined with a logical 'AND'. */
export type Indicator_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  LowerAppetiteNum?: InputMaybe<Numeric_Comparison_Exp>;
  LowerToleranceNum?: InputMaybe<Numeric_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  TargetValueTxt?: InputMaybe<String_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  Type?: InputMaybe<Indicator_Type_Enum_Comparison_Exp>;
  Unit?: InputMaybe<String_Comparison_Exp>;
  UpperAppetiteNum?: InputMaybe<Numeric_Comparison_Exp>;
  UpperToleranceNum?: InputMaybe<Numeric_Comparison_Exp>;
  _and?: InputMaybe<Array<Indicator_Bool_Exp>>;
  _not?: InputMaybe<Indicator_Bool_Exp>;
  _or?: InputMaybe<Array<Indicator_Bool_Exp>>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  contributorGroups?: InputMaybe<Contributor_Group_Bool_Exp>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Bool_Exp>;
  contributors?: InputMaybe<Contributor_Bool_Exp>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Bool_Exp>;
  createdBy?: InputMaybe<User_Bool_Exp>;
  departments?: InputMaybe<Department_Bool_Exp>;
  files?: InputMaybe<Relation_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Bool_Exp>;
  indicator_type?: InputMaybe<Indicator_Type_Bool_Exp>;
  modifiedBy?: InputMaybe<User_Bool_Exp>;
  org?: InputMaybe<Auth_Organisation_Bool_Exp>;
  ownerGroups?: InputMaybe<Owner_Group_Bool_Exp>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Bool_Exp>;
  owners?: InputMaybe<Owner_Bool_Exp>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Bool_Exp>;
  parents?: InputMaybe<Indicator_Parent_Bool_Exp>;
  parents_aggregate?: InputMaybe<Indicator_Parent_Aggregate_Bool_Exp>;
  results?: InputMaybe<Indicator_Result_Bool_Exp>;
  schedule?: InputMaybe<Schedule_Bool_Exp>;
  scheduleState?: InputMaybe<Schedule_State_Bool_Exp>;
  tags?: InputMaybe<Tag_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.indicator" */
export const Indicator_Constraint = {
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxIndicatorOrgkeySequentialid: 'idx_indicator_orgkey_sequentialid',
  /** unique or primary key constraint on columns "Id" */
  IndicatorPkey: 'indicator_pkey'
} as const;

export type Indicator_Constraint = typeof Indicator_Constraint[keyof typeof Indicator_Constraint];
/** input type for inserting data into table "risksmart.indicator" */
export type Indicator_Insert_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LowerAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  LowerToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
  TargetValueTxt?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Indicator_Type_Enum>;
  Unit?: InputMaybe<Scalars['String']['input']>;
  UpperAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  UpperToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
  contributorGroups?: InputMaybe<Contributor_Group_Arr_Rel_Insert_Input>;
  contributors?: InputMaybe<Contributor_Arr_Rel_Insert_Input>;
  departments?: InputMaybe<Department_Arr_Rel_Insert_Input>;
  files?: InputMaybe<Relation_File_Arr_Rel_Insert_Input>;
  ownerGroups?: InputMaybe<Owner_Group_Arr_Rel_Insert_Input>;
  owners?: InputMaybe<Owner_Arr_Rel_Insert_Input>;
  parents?: InputMaybe<Indicator_Parent_Arr_Rel_Insert_Input>;
  results?: InputMaybe<Indicator_Result_Arr_Rel_Insert_Input>;
  schedule?: InputMaybe<Schedule_Obj_Rel_Insert_Input>;
  tags?: InputMaybe<Tag_Arr_Rel_Insert_Input>;
};

/** order by max() on columns of table "risksmart.indicator" */
export type Indicator_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  LowerAppetiteNum?: InputMaybe<Order_By>;
  LowerToleranceNum?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  TargetValueTxt?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  Unit?: InputMaybe<Order_By>;
  UpperAppetiteNum?: InputMaybe<Order_By>;
  UpperToleranceNum?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.indicator" */
export type Indicator_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  LowerAppetiteNum?: InputMaybe<Order_By>;
  LowerToleranceNum?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  TargetValueTxt?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  Unit?: InputMaybe<Order_By>;
  UpperAppetiteNum?: InputMaybe<Order_By>;
  UpperToleranceNum?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.indicator" */
export type Indicator_Mutation_Response = {
  __typename?: 'indicator_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Indicator>;
};

/** input type for inserting object relation for remote table "risksmart.indicator" */
export type Indicator_Obj_Rel_Insert_Input = {
  data: Indicator_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Indicator_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.indicator" */
export type Indicator_On_Conflict = {
  constraint: Indicator_Constraint;
  update_columns?: Array<Indicator_Update_Column>;
  where?: InputMaybe<Indicator_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.indicator". */
export type Indicator_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  LowerAppetiteNum?: InputMaybe<Order_By>;
  LowerToleranceNum?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  TargetValueTxt?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  Unit?: InputMaybe<Order_By>;
  UpperAppetiteNum?: InputMaybe<Order_By>;
  UpperToleranceNum?: InputMaybe<Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Order_By>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Order_By>;
  createdBy?: InputMaybe<User_Order_By>;
  departments_aggregate?: InputMaybe<Department_Aggregate_Order_By>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Order_By>;
  indicator_type?: InputMaybe<Indicator_Type_Order_By>;
  modifiedBy?: InputMaybe<User_Order_By>;
  org?: InputMaybe<Auth_Organisation_Order_By>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Order_By>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Order_By>;
  parents_aggregate?: InputMaybe<Indicator_Parent_Aggregate_Order_By>;
  results_aggregate?: InputMaybe<Indicator_Result_Aggregate_Order_By>;
  schedule?: InputMaybe<Schedule_Order_By>;
  scheduleState?: InputMaybe<Schedule_State_Order_By>;
  tags_aggregate?: InputMaybe<Tag_Aggregate_Order_By>;
};

/** columns and relationships of "risksmart.indicator_parent" */
export type Indicator_Parent = {
  __typename?: 'indicator_parent';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  IndicatorId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  /** An object relationship */
  control?: Maybe<Control>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  indicator?: Maybe<Indicator>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parent?: Maybe<Node>;
  /** An object relationship */
  risk?: Maybe<Risk>;
};

/** aggregated selection of "risksmart.indicator_parent" */
export type Indicator_Parent_Aggregate = {
  __typename?: 'indicator_parent_aggregate';
  aggregate?: Maybe<Indicator_Parent_Aggregate_Fields>;
  nodes: Array<Indicator_Parent>;
};

export type Indicator_Parent_Aggregate_Bool_Exp = {
  count?: InputMaybe<Indicator_Parent_Aggregate_Bool_Exp_Count>;
};

export type Indicator_Parent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Indicator_Parent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Indicator_Parent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.indicator_parent" */
export type Indicator_Parent_Aggregate_Fields = {
  __typename?: 'indicator_parent_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Indicator_Parent_Max_Fields>;
  min?: Maybe<Indicator_Parent_Min_Fields>;
};


/** aggregate fields of "risksmart.indicator_parent" */
export type Indicator_Parent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Indicator_Parent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.indicator_parent" */
export type Indicator_Parent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Indicator_Parent_Max_Order_By>;
  min?: InputMaybe<Indicator_Parent_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.indicator_parent" */
export type Indicator_Parent_Arr_Rel_Insert_Input = {
  data: Array<Indicator_Parent_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Indicator_Parent_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risksmart.indicator_parent". All fields are combined with a logical 'AND'. */
export type Indicator_Parent_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  IndicatorId?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Indicator_Parent_Bool_Exp>>;
  _not?: InputMaybe<Indicator_Parent_Bool_Exp>;
  _or?: InputMaybe<Array<Indicator_Parent_Bool_Exp>>;
  control?: InputMaybe<Control_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  indicator?: InputMaybe<Indicator_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parent?: InputMaybe<Node_Bool_Exp>;
  risk?: InputMaybe<Risk_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.indicator_parent" */
export const Indicator_Parent_Constraint = {
  /** unique or primary key constraint on columns "ParentId", "IndicatorId" */
  IndicatorParentPkey: 'indicator_parent_pkey'
} as const;

export type Indicator_Parent_Constraint = typeof Indicator_Parent_Constraint[keyof typeof Indicator_Parent_Constraint];
/** input type for inserting data into table "risksmart.indicator_parent" */
export type Indicator_Parent_Insert_Input = {
  IndicatorId?: InputMaybe<Scalars['uuid']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  control?: InputMaybe<Control_Obj_Rel_Insert_Input>;
  indicator?: InputMaybe<Indicator_Obj_Rel_Insert_Input>;
  risk?: InputMaybe<Risk_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Indicator_Parent_Max_Fields = {
  __typename?: 'indicator_parent_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  IndicatorId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.indicator_parent" */
export type Indicator_Parent_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  IndicatorId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Indicator_Parent_Min_Fields = {
  __typename?: 'indicator_parent_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  IndicatorId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.indicator_parent" */
export type Indicator_Parent_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  IndicatorId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.indicator_parent" */
export type Indicator_Parent_Mutation_Response = {
  __typename?: 'indicator_parent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Indicator_Parent>;
};

/** on_conflict condition type for table "risksmart.indicator_parent" */
export type Indicator_Parent_On_Conflict = {
  constraint: Indicator_Parent_Constraint;
  update_columns?: Array<Indicator_Parent_Update_Column>;
  where?: InputMaybe<Indicator_Parent_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.indicator_parent". */
export type Indicator_Parent_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  IndicatorId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  control?: InputMaybe<Control_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  indicator?: InputMaybe<Indicator_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parent?: InputMaybe<Node_Order_By>;
  risk?: InputMaybe<Risk_Order_By>;
};

/** select columns of table "risksmart.indicator_parent" */
export const Indicator_Parent_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  IndicatorId: 'IndicatorId',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId'
} as const;

export type Indicator_Parent_Select_Column = typeof Indicator_Parent_Select_Column[keyof typeof Indicator_Parent_Select_Column];
/** Streaming cursor of the table "indicator_parent" */
export type Indicator_Parent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Indicator_Parent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Indicator_Parent_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  IndicatorId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** placeholder for update columns of table "risksmart.indicator_parent" (current role has no relevant permissions) */
export const Indicator_Parent_Update_Column = {
  /** placeholder (do not use) */
  Placeholder: '_PLACEHOLDER'
} as const;

export type Indicator_Parent_Update_Column = typeof Indicator_Parent_Update_Column[keyof typeof Indicator_Parent_Update_Column];
/** columns and relationships of "risksmart.indicator_result" */
export type Indicator_Result = {
  __typename?: 'indicator_result';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  IndicatorId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ResultDate: Scalars['timestamptz']['output'];
  TargetValueNum?: Maybe<Scalars['numeric']['output']>;
  TargetValueTxt?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  createdBy?: Maybe<User>;
  /** An array relationship */
  files: Array<Relation_File>;
  /** An aggregate relationship */
  files_aggregate: Relation_File_Aggregate;
  /** An object relationship */
  modifiedBy?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parent?: Maybe<Indicator>;
};


/** columns and relationships of "risksmart.indicator_result" */
export type Indicator_ResultCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.indicator_result" */
export type Indicator_ResultFilesArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.indicator_result" */
export type Indicator_ResultFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};

/** order by aggregate values of table "risksmart.indicator_result" */
export type Indicator_Result_Aggregate_Order_By = {
  avg?: InputMaybe<Indicator_Result_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Indicator_Result_Max_Order_By>;
  min?: InputMaybe<Indicator_Result_Min_Order_By>;
  stddev?: InputMaybe<Indicator_Result_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Indicator_Result_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Indicator_Result_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Indicator_Result_Sum_Order_By>;
  var_pop?: InputMaybe<Indicator_Result_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Indicator_Result_Var_Samp_Order_By>;
  variance?: InputMaybe<Indicator_Result_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Indicator_Result_Append_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.indicator_result" */
export type Indicator_Result_Arr_Rel_Insert_Input = {
  data: Array<Indicator_Result_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Indicator_Result_On_Conflict>;
};

/** columns and relationships of "risksmart.indicator_result_audit" */
export type Indicator_Result_Audit = {
  __typename?: 'indicator_result_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  IndicatorId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ResultDate: Scalars['timestamptz']['output'];
  TargetValueNum?: Maybe<Scalars['numeric']['output']>;
  TargetValueTxt?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "risksmart.indicator_result_audit" */
export type Indicator_Result_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.indicator_result_audit". All fields are combined with a logical 'AND'. */
export type Indicator_Result_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  IndicatorId?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  OrgKey?: InputMaybe<String_Comparison_Exp>;
  ResultDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  TargetValueNum?: InputMaybe<Numeric_Comparison_Exp>;
  TargetValueTxt?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Indicator_Result_Audit_Bool_Exp>>;
  _not?: InputMaybe<Indicator_Result_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Indicator_Result_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.indicator_result_audit". */
export type Indicator_Result_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  IndicatorId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  ResultDate?: InputMaybe<Order_By>;
  TargetValueNum?: InputMaybe<Order_By>;
  TargetValueTxt?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.indicator_result_audit" */
export const Indicator_Result_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  IndicatorId: 'IndicatorId',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  OrgKey: 'OrgKey',
  /** column name */
  ResultDate: 'ResultDate',
  /** column name */
  TargetValueNum: 'TargetValueNum',
  /** column name */
  TargetValueTxt: 'TargetValueTxt'
} as const;

export type Indicator_Result_Audit_Select_Column = typeof Indicator_Result_Audit_Select_Column[keyof typeof Indicator_Result_Audit_Select_Column];
/** Streaming cursor of the table "indicator_result_audit" */
export type Indicator_Result_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Indicator_Result_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Indicator_Result_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IndicatorId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ResultDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TargetValueNum?: InputMaybe<Scalars['numeric']['input']>;
  TargetValueTxt?: InputMaybe<Scalars['String']['input']>;
};

/** order by avg() on columns of table "risksmart.indicator_result" */
export type Indicator_Result_Avg_Order_By = {
  TargetValueNum?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.indicator_result". All fields are combined with a logical 'AND'. */
export type Indicator_Result_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  IndicatorId?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  OrgKey?: InputMaybe<String_Comparison_Exp>;
  ResultDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  TargetValueNum?: InputMaybe<Numeric_Comparison_Exp>;
  TargetValueTxt?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Indicator_Result_Bool_Exp>>;
  _not?: InputMaybe<Indicator_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Indicator_Result_Bool_Exp>>;
  createdBy?: InputMaybe<User_Bool_Exp>;
  files?: InputMaybe<Relation_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Bool_Exp>;
  modifiedBy?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parent?: InputMaybe<Indicator_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.indicator_result" */
export const Indicator_Result_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  IndicatorResultPkey: 'indicator_result_pkey'
} as const;

export type Indicator_Result_Constraint = typeof Indicator_Result_Constraint[keyof typeof Indicator_Result_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Indicator_Result_Delete_At_Path_Input = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Indicator_Result_Delete_Elem_Input = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Indicator_Result_Delete_Key_Input = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.indicator_result" */
export type Indicator_Result_Inc_Input = {
  TargetValueNum?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "risksmart.indicator_result" */
export type Indicator_Result_Insert_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  IndicatorId?: InputMaybe<Scalars['uuid']['input']>;
  ResultDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TargetValueNum?: InputMaybe<Scalars['numeric']['input']>;
  TargetValueTxt?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Relation_File_Arr_Rel_Insert_Input>;
  parent?: InputMaybe<Indicator_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "risksmart.indicator_result" */
export type Indicator_Result_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  IndicatorId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  ResultDate?: InputMaybe<Order_By>;
  TargetValueNum?: InputMaybe<Order_By>;
  TargetValueTxt?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.indicator_result" */
export type Indicator_Result_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  IndicatorId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  ResultDate?: InputMaybe<Order_By>;
  TargetValueNum?: InputMaybe<Order_By>;
  TargetValueTxt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.indicator_result" */
export type Indicator_Result_Mutation_Response = {
  __typename?: 'indicator_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Indicator_Result>;
};

/** on_conflict condition type for table "risksmart.indicator_result" */
export type Indicator_Result_On_Conflict = {
  constraint: Indicator_Result_Constraint;
  update_columns?: Array<Indicator_Result_Update_Column>;
  where?: InputMaybe<Indicator_Result_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.indicator_result". */
export type Indicator_Result_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  IndicatorId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  ResultDate?: InputMaybe<Order_By>;
  TargetValueNum?: InputMaybe<Order_By>;
  TargetValueTxt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<User_Order_By>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Order_By>;
  modifiedBy?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parent?: InputMaybe<Indicator_Order_By>;
};

/** primary key columns input for table: risksmart.indicator_result */
export type Indicator_Result_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Indicator_Result_Prepend_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.indicator_result" */
export const Indicator_Result_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  IndicatorId: 'IndicatorId',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  OrgKey: 'OrgKey',
  /** column name */
  ResultDate: 'ResultDate',
  /** column name */
  TargetValueNum: 'TargetValueNum',
  /** column name */
  TargetValueTxt: 'TargetValueTxt'
} as const;

export type Indicator_Result_Select_Column = typeof Indicator_Result_Select_Column[keyof typeof Indicator_Result_Select_Column];
/** input type for updating data in table "risksmart.indicator_result" */
export type Indicator_Result_Set_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  IndicatorId?: InputMaybe<Scalars['uuid']['input']>;
  ResultDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TargetValueNum?: InputMaybe<Scalars['numeric']['input']>;
  TargetValueTxt?: InputMaybe<Scalars['String']['input']>;
};

/** order by stddev() on columns of table "risksmart.indicator_result" */
export type Indicator_Result_Stddev_Order_By = {
  TargetValueNum?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "risksmart.indicator_result" */
export type Indicator_Result_Stddev_Pop_Order_By = {
  TargetValueNum?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "risksmart.indicator_result" */
export type Indicator_Result_Stddev_Samp_Order_By = {
  TargetValueNum?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "indicator_result" */
export type Indicator_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Indicator_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Indicator_Result_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IndicatorId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ResultDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TargetValueNum?: InputMaybe<Scalars['numeric']['input']>;
  TargetValueTxt?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "risksmart.indicator_result" */
export type Indicator_Result_Sum_Order_By = {
  TargetValueNum?: InputMaybe<Order_By>;
};

/** update columns of table "risksmart.indicator_result" */
export const Indicator_Result_Update_Column = {
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  IndicatorId: 'IndicatorId',
  /** column name */
  ResultDate: 'ResultDate',
  /** column name */
  TargetValueNum: 'TargetValueNum',
  /** column name */
  TargetValueTxt: 'TargetValueTxt'
} as const;

export type Indicator_Result_Update_Column = typeof Indicator_Result_Update_Column[keyof typeof Indicator_Result_Update_Column];
export type Indicator_Result_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Indicator_Result_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Indicator_Result_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Indicator_Result_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Indicator_Result_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Indicator_Result_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Indicator_Result_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Indicator_Result_Set_Input>;
  /** filter the rows which have to be updated */
  where: Indicator_Result_Bool_Exp;
};

/** order by var_pop() on columns of table "risksmart.indicator_result" */
export type Indicator_Result_Var_Pop_Order_By = {
  TargetValueNum?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "risksmart.indicator_result" */
export type Indicator_Result_Var_Samp_Order_By = {
  TargetValueNum?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "risksmart.indicator_result" */
export type Indicator_Result_Variance_Order_By = {
  TargetValueNum?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.indicator" */
export const Indicator_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  LowerAppetiteNum: 'LowerAppetiteNum',
  /** column name */
  LowerToleranceNum: 'LowerToleranceNum',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  TargetValueTxt: 'TargetValueTxt',
  /** column name */
  Title: 'Title',
  /** column name */
  Type: 'Type',
  /** column name */
  Unit: 'Unit',
  /** column name */
  UpperAppetiteNum: 'UpperAppetiteNum',
  /** column name */
  UpperToleranceNum: 'UpperToleranceNum'
} as const;

export type Indicator_Select_Column = typeof Indicator_Select_Column[keyof typeof Indicator_Select_Column];
/** order by stddev() on columns of table "risksmart.indicator" */
export type Indicator_Stddev_Order_By = {
  LowerAppetiteNum?: InputMaybe<Order_By>;
  LowerToleranceNum?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  UpperAppetiteNum?: InputMaybe<Order_By>;
  UpperToleranceNum?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "risksmart.indicator" */
export type Indicator_Stddev_Pop_Order_By = {
  LowerAppetiteNum?: InputMaybe<Order_By>;
  LowerToleranceNum?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  UpperAppetiteNum?: InputMaybe<Order_By>;
  UpperToleranceNum?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "risksmart.indicator" */
export type Indicator_Stddev_Samp_Order_By = {
  LowerAppetiteNum?: InputMaybe<Order_By>;
  LowerToleranceNum?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  UpperAppetiteNum?: InputMaybe<Order_By>;
  UpperToleranceNum?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "indicator" */
export type Indicator_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Indicator_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Indicator_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LowerAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  LowerToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TargetValueTxt?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Indicator_Type_Enum>;
  Unit?: InputMaybe<Scalars['String']['input']>;
  UpperAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  UpperToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
};

/** order by sum() on columns of table "risksmart.indicator" */
export type Indicator_Sum_Order_By = {
  LowerAppetiteNum?: InputMaybe<Order_By>;
  LowerToleranceNum?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  UpperAppetiteNum?: InputMaybe<Order_By>;
  UpperToleranceNum?: InputMaybe<Order_By>;
};

/** columns and relationships of "risksmart.indicator_type" */
export type Indicator_Type = {
  __typename?: 'indicator_type';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
  /** An array relationship */
  indicator_type: Array<Indicator>;
};


/** columns and relationships of "risksmart.indicator_type" */
export type Indicator_TypeIndicator_TypeArgs = {
  distinct_on?: InputMaybe<Array<Indicator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Indicator_Order_By>>;
  where?: InputMaybe<Indicator_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "risksmart.indicator_type". All fields are combined with a logical 'AND'. */
export type Indicator_Type_Bool_Exp = {
  Comment?: InputMaybe<String_Comparison_Exp>;
  Value?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Indicator_Type_Bool_Exp>>;
  _not?: InputMaybe<Indicator_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Indicator_Type_Bool_Exp>>;
  indicator_type?: InputMaybe<Indicator_Bool_Exp>;
};

export const Indicator_Type_Enum = {
  /** True or false indicator type */
  Boolean: 'boolean',
  /**  Number indicator type */
  Number: 'number',
  /** Text indicator type */
  Text: 'text'
} as const;

export type Indicator_Type_Enum = typeof Indicator_Type_Enum[keyof typeof Indicator_Type_Enum];
/** Boolean expression to compare columns of type "indicator_type_enum". All fields are combined with logical 'AND'. */
export type Indicator_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Indicator_Type_Enum>;
  _in?: InputMaybe<Array<Indicator_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Indicator_Type_Enum>;
  _nin?: InputMaybe<Array<Indicator_Type_Enum>>;
};

/** Ordering options when selecting data from "risksmart.indicator_type". */
export type Indicator_Type_Order_By = {
  Comment?: InputMaybe<Order_By>;
  Value?: InputMaybe<Order_By>;
  indicator_type_aggregate?: InputMaybe<Indicator_Aggregate_Order_By>;
};

/** select columns of table "risksmart.indicator_type" */
export const Indicator_Type_Select_Column = {
  /** column name */
  Comment: 'Comment',
  /** column name */
  Value: 'Value'
} as const;

export type Indicator_Type_Select_Column = typeof Indicator_Type_Select_Column[keyof typeof Indicator_Type_Select_Column];
/** Streaming cursor of the table "indicator_type" */
export type Indicator_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Indicator_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Indicator_Type_Stream_Cursor_Value_Input = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.indicator" */
export const Indicator_Update_Column = {
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  LowerAppetiteNum: 'LowerAppetiteNum',
  /** column name */
  LowerToleranceNum: 'LowerToleranceNum',
  /** column name */
  TargetValueTxt: 'TargetValueTxt',
  /** column name */
  Title: 'Title',
  /** column name */
  Unit: 'Unit',
  /** column name */
  UpperAppetiteNum: 'UpperAppetiteNum',
  /** column name */
  UpperToleranceNum: 'UpperToleranceNum'
} as const;

export type Indicator_Update_Column = typeof Indicator_Update_Column[keyof typeof Indicator_Update_Column];
/** order by var_pop() on columns of table "risksmart.indicator" */
export type Indicator_Var_Pop_Order_By = {
  LowerAppetiteNum?: InputMaybe<Order_By>;
  LowerToleranceNum?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  UpperAppetiteNum?: InputMaybe<Order_By>;
  UpperToleranceNum?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "risksmart.indicator" */
export type Indicator_Var_Samp_Order_By = {
  LowerAppetiteNum?: InputMaybe<Order_By>;
  LowerToleranceNum?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  UpperAppetiteNum?: InputMaybe<Order_By>;
  UpperToleranceNum?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "risksmart.indicator" */
export type Indicator_Variance_Order_By = {
  LowerAppetiteNum?: InputMaybe<Order_By>;
  LowerToleranceNum?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  UpperAppetiteNum?: InputMaybe<Order_By>;
  UpperToleranceNum?: InputMaybe<Order_By>;
};

export type InsertChildImpactRatingOutput = {
  __typename?: 'insertChildImpactRatingOutput';
  Ids: Array<Scalars['uuid']['output']>;
};

export type InsertControlTestResultOutput = {
  __typename?: 'insertControlTestResultOutput';
  Ids: Array<Scalars['uuid']['output']>;
};

/** columns and relationships of "risksmart.internal_audit_entity" */
export type Internal_Audit_Entity = {
  __typename?: 'internal_audit_entity';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title: Scalars['String']['output'];
  /** An array relationship */
  actions: Array<Action_Parent>;
  /** An aggregate relationship */
  actions_aggregate: Action_Parent_Aggregate;
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An object relationship */
  businessArea?: Maybe<Business_Area>;
  /** An array relationship */
  contributorGroups: Array<Contributor_Group>;
  /** An aggregate relationship */
  contributorGroups_aggregate: Contributor_Group_Aggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: Contributor_Aggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An array relationship */
  internalAuditReports: Array<Internal_Audit_Report>;
  /** An aggregate relationship */
  internalAuditReports_aggregate: Internal_Audit_Report_Aggregate;
  /** An array relationship */
  issues: Array<Issue_Parent>;
  /** An aggregate relationship */
  issues_aggregate: Issue_Parent_Aggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An array relationship */
  ownerGroups: Array<Owner_Group>;
  /** An aggregate relationship */
  ownerGroups_aggregate: Owner_Group_Aggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: Owner_Aggregate;
  /** An array relationship */
  tags: Array<Tag>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type Internal_Audit_EntityCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type Internal_Audit_EntityActionsArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type Internal_Audit_EntityActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type Internal_Audit_EntityAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type Internal_Audit_EntityContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type Internal_Audit_EntityContributorGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type Internal_Audit_EntityContributorsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type Internal_Audit_EntityContributors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type Internal_Audit_EntityDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type Internal_Audit_EntityInternalAuditReportsArgs = {
  distinct_on?: InputMaybe<Array<Internal_Audit_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Internal_Audit_Report_Order_By>>;
  where?: InputMaybe<Internal_Audit_Report_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type Internal_Audit_EntityInternalAuditReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Internal_Audit_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Internal_Audit_Report_Order_By>>;
  where?: InputMaybe<Internal_Audit_Report_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type Internal_Audit_EntityIssuesArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Order_By>>;
  where?: InputMaybe<Issue_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type Internal_Audit_EntityIssues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Order_By>>;
  where?: InputMaybe<Issue_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type Internal_Audit_EntityOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type Internal_Audit_EntityOwnerGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type Internal_Audit_EntityOwnersArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type Internal_Audit_EntityOwners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type Internal_Audit_EntityTagsArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};

/** aggregated selection of "risksmart.internal_audit_entity" */
export type Internal_Audit_Entity_Aggregate = {
  __typename?: 'internal_audit_entity_aggregate';
  aggregate?: Maybe<Internal_Audit_Entity_Aggregate_Fields>;
  nodes: Array<Internal_Audit_Entity>;
};

export type Internal_Audit_Entity_Aggregate_Bool_Exp = {
  count?: InputMaybe<Internal_Audit_Entity_Aggregate_Bool_Exp_Count>;
};

export type Internal_Audit_Entity_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Internal_Audit_Entity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Internal_Audit_Entity_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.internal_audit_entity" */
export type Internal_Audit_Entity_Aggregate_Fields = {
  __typename?: 'internal_audit_entity_aggregate_fields';
  avg?: Maybe<Internal_Audit_Entity_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Internal_Audit_Entity_Max_Fields>;
  min?: Maybe<Internal_Audit_Entity_Min_Fields>;
  stddev?: Maybe<Internal_Audit_Entity_Stddev_Fields>;
  stddev_pop?: Maybe<Internal_Audit_Entity_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Internal_Audit_Entity_Stddev_Samp_Fields>;
  sum?: Maybe<Internal_Audit_Entity_Sum_Fields>;
  var_pop?: Maybe<Internal_Audit_Entity_Var_Pop_Fields>;
  var_samp?: Maybe<Internal_Audit_Entity_Var_Samp_Fields>;
  variance?: Maybe<Internal_Audit_Entity_Variance_Fields>;
};


/** aggregate fields of "risksmart.internal_audit_entity" */
export type Internal_Audit_Entity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Internal_Audit_Entity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.internal_audit_entity" */
export type Internal_Audit_Entity_Aggregate_Order_By = {
  avg?: InputMaybe<Internal_Audit_Entity_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Internal_Audit_Entity_Max_Order_By>;
  min?: InputMaybe<Internal_Audit_Entity_Min_Order_By>;
  stddev?: InputMaybe<Internal_Audit_Entity_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Internal_Audit_Entity_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Internal_Audit_Entity_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Internal_Audit_Entity_Sum_Order_By>;
  var_pop?: InputMaybe<Internal_Audit_Entity_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Internal_Audit_Entity_Var_Samp_Order_By>;
  variance?: InputMaybe<Internal_Audit_Entity_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.internal_audit_entity" */
export type Internal_Audit_Entity_Arr_Rel_Insert_Input = {
  data: Array<Internal_Audit_Entity_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Internal_Audit_Entity_On_Conflict>;
};

/** aggregate avg on columns */
export type Internal_Audit_Entity_Avg_Fields = {
  __typename?: 'internal_audit_entity_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.internal_audit_entity" */
export type Internal_Audit_Entity_Avg_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.internal_audit_entity". All fields are combined with a logical 'AND'. */
export type Internal_Audit_Entity_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Internal_Audit_Entity_Bool_Exp>>;
  _not?: InputMaybe<Internal_Audit_Entity_Bool_Exp>;
  _or?: InputMaybe<Array<Internal_Audit_Entity_Bool_Exp>>;
  actions?: InputMaybe<Action_Parent_Bool_Exp>;
  actions_aggregate?: InputMaybe<Action_Parent_Aggregate_Bool_Exp>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  businessArea?: InputMaybe<Business_Area_Bool_Exp>;
  contributorGroups?: InputMaybe<Contributor_Group_Bool_Exp>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Bool_Exp>;
  contributors?: InputMaybe<Contributor_Bool_Exp>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  departments?: InputMaybe<Department_Bool_Exp>;
  internalAuditReports?: InputMaybe<Internal_Audit_Report_Bool_Exp>;
  internalAuditReports_aggregate?: InputMaybe<Internal_Audit_Report_Aggregate_Bool_Exp>;
  issues?: InputMaybe<Issue_Parent_Bool_Exp>;
  issues_aggregate?: InputMaybe<Issue_Parent_Aggregate_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  ownerGroups?: InputMaybe<Owner_Group_Bool_Exp>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Bool_Exp>;
  owners?: InputMaybe<Owner_Bool_Exp>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Bool_Exp>;
  tags?: InputMaybe<Tag_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.internal_audit_entity" */
export const Internal_Audit_Entity_Constraint = {
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxInternalAuditEntityOrgkeySequentialid: 'idx_internal_audit_entity_orgkey_sequentialid',
  /** unique or primary key constraint on columns "Id" */
  InternalAuditEntityPkey: 'internal_audit_entity_pkey'
} as const;

export type Internal_Audit_Entity_Constraint = typeof Internal_Audit_Entity_Constraint[keyof typeof Internal_Audit_Entity_Constraint];
/** input type for inserting data into table "risksmart.internal_audit_entity" */
export type Internal_Audit_Entity_Insert_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  actions?: InputMaybe<Action_Parent_Arr_Rel_Insert_Input>;
  businessArea?: InputMaybe<Business_Area_Obj_Rel_Insert_Input>;
  contributorGroups?: InputMaybe<Contributor_Group_Arr_Rel_Insert_Input>;
  contributors?: InputMaybe<Contributor_Arr_Rel_Insert_Input>;
  departments?: InputMaybe<Department_Arr_Rel_Insert_Input>;
  internalAuditReports?: InputMaybe<Internal_Audit_Report_Arr_Rel_Insert_Input>;
  issues?: InputMaybe<Issue_Parent_Arr_Rel_Insert_Input>;
  ownerGroups?: InputMaybe<Owner_Group_Arr_Rel_Insert_Input>;
  owners?: InputMaybe<Owner_Arr_Rel_Insert_Input>;
  tags?: InputMaybe<Tag_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Internal_Audit_Entity_Max_Fields = {
  __typename?: 'internal_audit_entity_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.internal_audit_entity" */
export type Internal_Audit_Entity_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Internal_Audit_Entity_Min_Fields = {
  __typename?: 'internal_audit_entity_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.internal_audit_entity" */
export type Internal_Audit_Entity_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.internal_audit_entity" */
export type Internal_Audit_Entity_Mutation_Response = {
  __typename?: 'internal_audit_entity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Internal_Audit_Entity>;
};

/** input type for inserting object relation for remote table "risksmart.internal_audit_entity" */
export type Internal_Audit_Entity_Obj_Rel_Insert_Input = {
  data: Internal_Audit_Entity_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Internal_Audit_Entity_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.internal_audit_entity" */
export type Internal_Audit_Entity_On_Conflict = {
  constraint: Internal_Audit_Entity_Constraint;
  update_columns?: Array<Internal_Audit_Entity_Update_Column>;
  where?: InputMaybe<Internal_Audit_Entity_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.internal_audit_entity". */
export type Internal_Audit_Entity_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  actions_aggregate?: InputMaybe<Action_Parent_Aggregate_Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  businessArea?: InputMaybe<Business_Area_Order_By>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Order_By>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  departments_aggregate?: InputMaybe<Department_Aggregate_Order_By>;
  internalAuditReports_aggregate?: InputMaybe<Internal_Audit_Report_Aggregate_Order_By>;
  issues_aggregate?: InputMaybe<Issue_Parent_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Order_By>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Order_By>;
  tags_aggregate?: InputMaybe<Tag_Aggregate_Order_By>;
};

/** select columns of table "risksmart.internal_audit_entity" */
export const Internal_Audit_Entity_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Title: 'Title'
} as const;

export type Internal_Audit_Entity_Select_Column = typeof Internal_Audit_Entity_Select_Column[keyof typeof Internal_Audit_Entity_Select_Column];
/** aggregate stddev on columns */
export type Internal_Audit_Entity_Stddev_Fields = {
  __typename?: 'internal_audit_entity_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.internal_audit_entity" */
export type Internal_Audit_Entity_Stddev_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Internal_Audit_Entity_Stddev_Pop_Fields = {
  __typename?: 'internal_audit_entity_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.internal_audit_entity" */
export type Internal_Audit_Entity_Stddev_Pop_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Internal_Audit_Entity_Stddev_Samp_Fields = {
  __typename?: 'internal_audit_entity_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.internal_audit_entity" */
export type Internal_Audit_Entity_Stddev_Samp_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "internal_audit_entity" */
export type Internal_Audit_Entity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Internal_Audit_Entity_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Internal_Audit_Entity_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Internal_Audit_Entity_Sum_Fields = {
  __typename?: 'internal_audit_entity_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.internal_audit_entity" */
export type Internal_Audit_Entity_Sum_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** update columns of table "risksmart.internal_audit_entity" */
export const Internal_Audit_Entity_Update_Column = {
  /** column name */
  BusinessAreaId: 'BusinessAreaId',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Title: 'Title'
} as const;

export type Internal_Audit_Entity_Update_Column = typeof Internal_Audit_Entity_Update_Column[keyof typeof Internal_Audit_Entity_Update_Column];
/** aggregate var_pop on columns */
export type Internal_Audit_Entity_Var_Pop_Fields = {
  __typename?: 'internal_audit_entity_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.internal_audit_entity" */
export type Internal_Audit_Entity_Var_Pop_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Internal_Audit_Entity_Var_Samp_Fields = {
  __typename?: 'internal_audit_entity_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.internal_audit_entity" */
export type Internal_Audit_Entity_Var_Samp_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Internal_Audit_Entity_Variance_Fields = {
  __typename?: 'internal_audit_entity_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.internal_audit_entity" */
export type Internal_Audit_Entity_Variance_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** columns and relationships of "risksmart.internal_audit_report" */
export type Internal_Audit_Report = {
  __typename?: 'internal_audit_report';
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Status: Assessment_Status_Enum;
  Summary: Scalars['String']['output'];
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title: Scalars['String']['output'];
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An array relationship */
  assessmentActions: Array<Action_Parent>;
  /** An aggregate relationship */
  assessmentActions_aggregate: Action_Parent_Aggregate;
  /** An array relationship */
  assessmentActivities: Array<Assessment_Activity>;
  /** An aggregate relationship */
  assessmentActivities_aggregate: Assessment_Activity_Aggregate;
  /** An array relationship */
  assessmentIssues: Array<Issue_Parent>;
  /** An aggregate relationship */
  assessmentIssues_aggregate: Issue_Parent_Aggregate;
  /** An array relationship */
  assessmentResults: Array<Assessment_Result_Parent>;
  /** An aggregate relationship */
  assessmentResults_aggregate: Assessment_Result_Parent_Aggregate;
  /** An object relationship */
  completedByUser?: Maybe<User>;
  /** An array relationship */
  contributorGroups: Array<Contributor_Group>;
  /** An aggregate relationship */
  contributorGroups_aggregate: Contributor_Group_Aggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: Contributor_Aggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  originatingItem?: Maybe<Node>;
  /** An array relationship */
  ownerGroups: Array<Owner_Group>;
  /** An aggregate relationship */
  ownerGroups_aggregate: Owner_Group_Aggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: Owner_Aggregate;
  /** An array relationship */
  tags: Array<Tag>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type Internal_Audit_ReportCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type Internal_Audit_ReportAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type Internal_Audit_ReportAssessmentActionsArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type Internal_Audit_ReportAssessmentActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type Internal_Audit_ReportAssessmentActivitiesArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Activity_Order_By>>;
  where?: InputMaybe<Assessment_Activity_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type Internal_Audit_ReportAssessmentActivities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Activity_Order_By>>;
  where?: InputMaybe<Assessment_Activity_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type Internal_Audit_ReportAssessmentIssuesArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Order_By>>;
  where?: InputMaybe<Issue_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type Internal_Audit_ReportAssessmentIssues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Order_By>>;
  where?: InputMaybe<Issue_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type Internal_Audit_ReportAssessmentResultsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type Internal_Audit_ReportAssessmentResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type Internal_Audit_ReportContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type Internal_Audit_ReportContributorGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type Internal_Audit_ReportContributorsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type Internal_Audit_ReportContributors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type Internal_Audit_ReportDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type Internal_Audit_ReportOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type Internal_Audit_ReportOwnerGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type Internal_Audit_ReportOwnersArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type Internal_Audit_ReportOwners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type Internal_Audit_ReportTagsArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};

/** aggregated selection of "risksmart.internal_audit_report" */
export type Internal_Audit_Report_Aggregate = {
  __typename?: 'internal_audit_report_aggregate';
  aggregate?: Maybe<Internal_Audit_Report_Aggregate_Fields>;
  nodes: Array<Internal_Audit_Report>;
};

export type Internal_Audit_Report_Aggregate_Bool_Exp = {
  count?: InputMaybe<Internal_Audit_Report_Aggregate_Bool_Exp_Count>;
};

export type Internal_Audit_Report_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Internal_Audit_Report_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Internal_Audit_Report_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.internal_audit_report" */
export type Internal_Audit_Report_Aggregate_Fields = {
  __typename?: 'internal_audit_report_aggregate_fields';
  avg?: Maybe<Internal_Audit_Report_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Internal_Audit_Report_Max_Fields>;
  min?: Maybe<Internal_Audit_Report_Min_Fields>;
  stddev?: Maybe<Internal_Audit_Report_Stddev_Fields>;
  stddev_pop?: Maybe<Internal_Audit_Report_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Internal_Audit_Report_Stddev_Samp_Fields>;
  sum?: Maybe<Internal_Audit_Report_Sum_Fields>;
  var_pop?: Maybe<Internal_Audit_Report_Var_Pop_Fields>;
  var_samp?: Maybe<Internal_Audit_Report_Var_Samp_Fields>;
  variance?: Maybe<Internal_Audit_Report_Variance_Fields>;
};


/** aggregate fields of "risksmart.internal_audit_report" */
export type Internal_Audit_Report_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Internal_Audit_Report_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.internal_audit_report" */
export type Internal_Audit_Report_Aggregate_Order_By = {
  avg?: InputMaybe<Internal_Audit_Report_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Internal_Audit_Report_Max_Order_By>;
  min?: InputMaybe<Internal_Audit_Report_Min_Order_By>;
  stddev?: InputMaybe<Internal_Audit_Report_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Internal_Audit_Report_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Internal_Audit_Report_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Internal_Audit_Report_Sum_Order_By>;
  var_pop?: InputMaybe<Internal_Audit_Report_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Internal_Audit_Report_Var_Samp_Order_By>;
  variance?: InputMaybe<Internal_Audit_Report_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Internal_Audit_Report_Append_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.internal_audit_report" */
export type Internal_Audit_Report_Arr_Rel_Insert_Input = {
  data: Array<Internal_Audit_Report_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Internal_Audit_Report_On_Conflict>;
};

/** aggregate avg on columns */
export type Internal_Audit_Report_Avg_Fields = {
  __typename?: 'internal_audit_report_avg_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.internal_audit_report" */
export type Internal_Audit_Report_Avg_Order_By = {
  Outcome?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.internal_audit_report". All fields are combined with a logical 'AND'. */
export type Internal_Audit_Report_Bool_Exp = {
  ActualCompletionDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  CompletedByUser?: InputMaybe<String_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  NextTestDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  OriginatingItemId?: InputMaybe<Uuid_Comparison_Exp>;
  Outcome?: InputMaybe<Int_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  StartDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Status?: InputMaybe<Assessment_Status_Enum_Comparison_Exp>;
  Summary?: InputMaybe<String_Comparison_Exp>;
  TargetCompletionDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Internal_Audit_Report_Bool_Exp>>;
  _not?: InputMaybe<Internal_Audit_Report_Bool_Exp>;
  _or?: InputMaybe<Array<Internal_Audit_Report_Bool_Exp>>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  assessmentActions?: InputMaybe<Action_Parent_Bool_Exp>;
  assessmentActions_aggregate?: InputMaybe<Action_Parent_Aggregate_Bool_Exp>;
  assessmentActivities?: InputMaybe<Assessment_Activity_Bool_Exp>;
  assessmentActivities_aggregate?: InputMaybe<Assessment_Activity_Aggregate_Bool_Exp>;
  assessmentIssues?: InputMaybe<Issue_Parent_Bool_Exp>;
  assessmentIssues_aggregate?: InputMaybe<Issue_Parent_Aggregate_Bool_Exp>;
  assessmentResults?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
  assessmentResults_aggregate?: InputMaybe<Assessment_Result_Parent_Aggregate_Bool_Exp>;
  completedByUser?: InputMaybe<User_Bool_Exp>;
  contributorGroups?: InputMaybe<Contributor_Group_Bool_Exp>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Bool_Exp>;
  contributors?: InputMaybe<Contributor_Bool_Exp>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  departments?: InputMaybe<Department_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  originatingItem?: InputMaybe<Node_Bool_Exp>;
  ownerGroups?: InputMaybe<Owner_Group_Bool_Exp>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Bool_Exp>;
  owners?: InputMaybe<Owner_Bool_Exp>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Bool_Exp>;
  tags?: InputMaybe<Tag_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.internal_audit_report" */
export const Internal_Audit_Report_Constraint = {
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxInternalAuditReportOrgkeySequentialid: 'idx_internal_audit_report_orgkey_sequentialid',
  /** unique or primary key constraint on columns "Id" */
  InternalAuditReportPkey: 'internal_audit_report_pkey'
} as const;

export type Internal_Audit_Report_Constraint = typeof Internal_Audit_Report_Constraint[keyof typeof Internal_Audit_Report_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Internal_Audit_Report_Delete_At_Path_Input = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Internal_Audit_Report_Delete_Elem_Input = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Internal_Audit_Report_Delete_Key_Input = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.internal_audit_report" */
export type Internal_Audit_Report_Inc_Input = {
  Outcome?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.internal_audit_report" */
export type Internal_Audit_Report_Insert_Input = {
  ActualCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CompletedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OriginatingItemId?: InputMaybe<Scalars['uuid']['input']>;
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<Assessment_Status_Enum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  TargetCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  assessmentActions?: InputMaybe<Action_Parent_Arr_Rel_Insert_Input>;
  assessmentActivities?: InputMaybe<Assessment_Activity_Arr_Rel_Insert_Input>;
  assessmentIssues?: InputMaybe<Issue_Parent_Arr_Rel_Insert_Input>;
  assessmentResults?: InputMaybe<Assessment_Result_Parent_Arr_Rel_Insert_Input>;
  contributorGroups?: InputMaybe<Contributor_Group_Arr_Rel_Insert_Input>;
  contributors?: InputMaybe<Contributor_Arr_Rel_Insert_Input>;
  departments?: InputMaybe<Department_Arr_Rel_Insert_Input>;
  ownerGroups?: InputMaybe<Owner_Group_Arr_Rel_Insert_Input>;
  owners?: InputMaybe<Owner_Arr_Rel_Insert_Input>;
  tags?: InputMaybe<Tag_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Internal_Audit_Report_Max_Fields = {
  __typename?: 'internal_audit_report_max_fields';
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.internal_audit_report" */
export type Internal_Audit_Report_Max_Order_By = {
  ActualCompletionDate?: InputMaybe<Order_By>;
  CompletedByUser?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  NextTestDate?: InputMaybe<Order_By>;
  OriginatingItemId?: InputMaybe<Order_By>;
  Outcome?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  StartDate?: InputMaybe<Order_By>;
  Summary?: InputMaybe<Order_By>;
  TargetCompletionDate?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Internal_Audit_Report_Min_Fields = {
  __typename?: 'internal_audit_report_min_fields';
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.internal_audit_report" */
export type Internal_Audit_Report_Min_Order_By = {
  ActualCompletionDate?: InputMaybe<Order_By>;
  CompletedByUser?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  NextTestDate?: InputMaybe<Order_By>;
  OriginatingItemId?: InputMaybe<Order_By>;
  Outcome?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  StartDate?: InputMaybe<Order_By>;
  Summary?: InputMaybe<Order_By>;
  TargetCompletionDate?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.internal_audit_report" */
export type Internal_Audit_Report_Mutation_Response = {
  __typename?: 'internal_audit_report_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Internal_Audit_Report>;
};

/** input type for inserting object relation for remote table "risksmart.internal_audit_report" */
export type Internal_Audit_Report_Obj_Rel_Insert_Input = {
  data: Internal_Audit_Report_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Internal_Audit_Report_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.internal_audit_report" */
export type Internal_Audit_Report_On_Conflict = {
  constraint: Internal_Audit_Report_Constraint;
  update_columns?: Array<Internal_Audit_Report_Update_Column>;
  where?: InputMaybe<Internal_Audit_Report_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.internal_audit_report". */
export type Internal_Audit_Report_Order_By = {
  ActualCompletionDate?: InputMaybe<Order_By>;
  CompletedByUser?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  NextTestDate?: InputMaybe<Order_By>;
  OriginatingItemId?: InputMaybe<Order_By>;
  Outcome?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  StartDate?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Summary?: InputMaybe<Order_By>;
  TargetCompletionDate?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  assessmentActions_aggregate?: InputMaybe<Action_Parent_Aggregate_Order_By>;
  assessmentActivities_aggregate?: InputMaybe<Assessment_Activity_Aggregate_Order_By>;
  assessmentIssues_aggregate?: InputMaybe<Issue_Parent_Aggregate_Order_By>;
  assessmentResults_aggregate?: InputMaybe<Assessment_Result_Parent_Aggregate_Order_By>;
  completedByUser?: InputMaybe<User_Order_By>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Order_By>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  departments_aggregate?: InputMaybe<Department_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  originatingItem?: InputMaybe<Node_Order_By>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Order_By>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Order_By>;
  tags_aggregate?: InputMaybe<Tag_Aggregate_Order_By>;
};

/** primary key columns input for table: risksmart.internal_audit_report */
export type Internal_Audit_Report_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Internal_Audit_Report_Prepend_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.internal_audit_report" */
export const Internal_Audit_Report_Select_Column = {
  /** column name */
  ActualCompletionDate: 'ActualCompletionDate',
  /** column name */
  CompletedByUser: 'CompletedByUser',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  NextTestDate: 'NextTestDate',
  /** column name */
  OriginatingItemId: 'OriginatingItemId',
  /** column name */
  Outcome: 'Outcome',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  StartDate: 'StartDate',
  /** column name */
  Status: 'Status',
  /** column name */
  Summary: 'Summary',
  /** column name */
  TargetCompletionDate: 'TargetCompletionDate',
  /** column name */
  Title: 'Title'
} as const;

export type Internal_Audit_Report_Select_Column = typeof Internal_Audit_Report_Select_Column[keyof typeof Internal_Audit_Report_Select_Column];
/** input type for updating data in table "risksmart.internal_audit_report" */
export type Internal_Audit_Report_Set_Input = {
  ActualCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CompletedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OriginatingItemId?: InputMaybe<Scalars['uuid']['input']>;
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<Assessment_Status_Enum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  TargetCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Internal_Audit_Report_Stddev_Fields = {
  __typename?: 'internal_audit_report_stddev_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.internal_audit_report" */
export type Internal_Audit_Report_Stddev_Order_By = {
  Outcome?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Internal_Audit_Report_Stddev_Pop_Fields = {
  __typename?: 'internal_audit_report_stddev_pop_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.internal_audit_report" */
export type Internal_Audit_Report_Stddev_Pop_Order_By = {
  Outcome?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Internal_Audit_Report_Stddev_Samp_Fields = {
  __typename?: 'internal_audit_report_stddev_samp_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.internal_audit_report" */
export type Internal_Audit_Report_Stddev_Samp_Order_By = {
  Outcome?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "internal_audit_report" */
export type Internal_Audit_Report_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Internal_Audit_Report_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Internal_Audit_Report_Stream_Cursor_Value_Input = {
  ActualCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CompletedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OriginatingItemId?: InputMaybe<Scalars['uuid']['input']>;
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<Assessment_Status_Enum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  TargetCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Internal_Audit_Report_Sum_Fields = {
  __typename?: 'internal_audit_report_sum_fields';
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.internal_audit_report" */
export type Internal_Audit_Report_Sum_Order_By = {
  Outcome?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** update columns of table "risksmart.internal_audit_report" */
export const Internal_Audit_Report_Update_Column = {
  /** column name */
  ActualCompletionDate: 'ActualCompletionDate',
  /** column name */
  CompletedByUser: 'CompletedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  NextTestDate: 'NextTestDate',
  /** column name */
  OriginatingItemId: 'OriginatingItemId',
  /** column name */
  Outcome: 'Outcome',
  /** column name */
  StartDate: 'StartDate',
  /** column name */
  Status: 'Status',
  /** column name */
  Summary: 'Summary',
  /** column name */
  TargetCompletionDate: 'TargetCompletionDate',
  /** column name */
  Title: 'Title'
} as const;

export type Internal_Audit_Report_Update_Column = typeof Internal_Audit_Report_Update_Column[keyof typeof Internal_Audit_Report_Update_Column];
export type Internal_Audit_Report_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Internal_Audit_Report_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Internal_Audit_Report_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Internal_Audit_Report_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Internal_Audit_Report_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Internal_Audit_Report_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Internal_Audit_Report_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Internal_Audit_Report_Set_Input>;
  /** filter the rows which have to be updated */
  where: Internal_Audit_Report_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Internal_Audit_Report_Var_Pop_Fields = {
  __typename?: 'internal_audit_report_var_pop_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.internal_audit_report" */
export type Internal_Audit_Report_Var_Pop_Order_By = {
  Outcome?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Internal_Audit_Report_Var_Samp_Fields = {
  __typename?: 'internal_audit_report_var_samp_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.internal_audit_report" */
export type Internal_Audit_Report_Var_Samp_Order_By = {
  Outcome?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Internal_Audit_Report_Variance_Fields = {
  __typename?: 'internal_audit_report_variance_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.internal_audit_report" */
export type Internal_Audit_Report_Variance_Order_By = {
  Outcome?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "interval". All fields are combined with logical 'AND'. */
export type Interval_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['interval']['input']>;
  _gt?: InputMaybe<Scalars['interval']['input']>;
  _gte?: InputMaybe<Scalars['interval']['input']>;
  _in?: InputMaybe<Array<Scalars['interval']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['interval']['input']>;
  _lte?: InputMaybe<Scalars['interval']['input']>;
  _neq?: InputMaybe<Scalars['interval']['input']>;
  _nin?: InputMaybe<Array<Scalars['interval']['input']>>;
};

/** columns and relationships of "risksmart.issue" */
export type Issue = {
  __typename?: 'issue';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  DateIdentified: Scalars['timestamptz']['output'];
  DateOccurred: Scalars['timestamptz']['output'];
  Details: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ImpactsCustomer?: Maybe<Scalars['Boolean']['output']>;
  IsExternalIssue?: Maybe<Scalars['Boolean']['output']>;
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  RaisedAtTimestamp: Scalars['timestamptz']['output'];
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title: Scalars['String']['output'];
  /** An array relationship */
  actions: Array<Action_Parent>;
  /** An aggregate relationship */
  actions_aggregate: Action_Parent_Aggregate;
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An object relationship */
  assessment?: Maybe<Issue_Assessment>;
  /** An array relationship */
  causes: Array<Cause>;
  /** An aggregate relationship */
  causes_aggregate: Cause_Aggregate;
  /** An array relationship */
  consequences: Array<Consequence>;
  /** An aggregate relationship */
  consequences_aggregate: Consequence_Aggregate;
  /** An array relationship */
  contributorGroups: Array<Contributor_Group>;
  /** An aggregate relationship */
  contributorGroups_aggregate: Contributor_Group_Aggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: Contributor_Aggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An array relationship */
  files: Array<Relation_File>;
  /** An aggregate relationship */
  files_aggregate: Relation_File_Aggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An array relationship */
  ownerGroups: Array<Owner_Group>;
  /** An aggregate relationship */
  ownerGroups_aggregate: Owner_Group_Aggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: Owner_Aggregate;
  /** An array relationship */
  parents: Array<Issue_Parent>;
  /** An aggregate relationship */
  parents_aggregate: Issue_Parent_Aggregate;
  /** An array relationship */
  tags: Array<Tag>;
  /** An array relationship */
  updates: Array<Issue_Update>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueActionsArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueCausesArgs = {
  distinct_on?: InputMaybe<Array<Cause_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Cause_Order_By>>;
  where?: InputMaybe<Cause_Bool_Exp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueCauses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cause_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Cause_Order_By>>;
  where?: InputMaybe<Cause_Bool_Exp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueConsequencesArgs = {
  distinct_on?: InputMaybe<Array<Consequence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Consequence_Order_By>>;
  where?: InputMaybe<Consequence_Bool_Exp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueConsequences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consequence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Consequence_Order_By>>;
  where?: InputMaybe<Consequence_Bool_Exp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueContributorGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueContributorsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueContributors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueFilesArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueOwnerGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueOwnersArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueOwners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueParentsArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Order_By>>;
  where?: InputMaybe<Issue_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueParents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Order_By>>;
  where?: InputMaybe<Issue_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueTagsArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueUpdatesArgs = {
  distinct_on?: InputMaybe<Array<Issue_Update_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Update_Order_By>>;
  where?: InputMaybe<Issue_Update_Bool_Exp>;
};

/** aggregated selection of "risksmart.issue" */
export type Issue_Aggregate = {
  __typename?: 'issue_aggregate';
  aggregate?: Maybe<Issue_Aggregate_Fields>;
  nodes: Array<Issue>;
};

/** aggregate fields of "risksmart.issue" */
export type Issue_Aggregate_Fields = {
  __typename?: 'issue_aggregate_fields';
  avg?: Maybe<Issue_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Issue_Max_Fields>;
  min?: Maybe<Issue_Min_Fields>;
  stddev?: Maybe<Issue_Stddev_Fields>;
  stddev_pop?: Maybe<Issue_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Issue_Stddev_Samp_Fields>;
  sum?: Maybe<Issue_Sum_Fields>;
  var_pop?: Maybe<Issue_Var_Pop_Fields>;
  var_samp?: Maybe<Issue_Var_Samp_Fields>;
  variance?: Maybe<Issue_Variance_Fields>;
};


/** aggregate fields of "risksmart.issue" */
export type Issue_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Issue_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Issue_Append_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.issue_assessment" */
export type Issue_Assessment = {
  __typename?: 'issue_assessment';
  ActualCloseDate?: Maybe<Scalars['timestamptz']['output']>;
  CertifiedIndividual?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  IssueCausedBySystemIssue?: Maybe<Scalars['Boolean']['output']>;
  IssueCausedByThirdParty?: Maybe<Scalars['Boolean']['output']>;
  IssueType?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentIssueId: Scalars['uuid']['output'];
  PoliciesBreached?: Maybe<Scalars['String']['output']>;
  PolicyBreach?: Maybe<Scalars['Boolean']['output']>;
  PolicyOwner?: Maybe<Scalars['String']['output']>;
  PolicyOwnerCommentary?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  RegulationsBreached?: Maybe<Scalars['String']['output']>;
  RegulatoryBreach?: Maybe<Scalars['Boolean']['output']>;
  Reportable?: Maybe<Scalars['Boolean']['output']>;
  Severity?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Issue_Assessment_Status_Enum>;
  SystemResponsible?: Maybe<Scalars['String']['output']>;
  TargetCloseDate?: Maybe<Scalars['timestamptz']['output']>;
  ThirdPartyResponsible?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  certifiedIndividual?: Maybe<User>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parent?: Maybe<Issue>;
  /** An object relationship */
  policyOwner?: Maybe<User>;
};


/** columns and relationships of "risksmart.issue_assessment" */
export type Issue_AssessmentCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.issue_assessment" */
export type Issue_AssessmentDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};

/** aggregated selection of "risksmart.issue_assessment" */
export type Issue_Assessment_Aggregate = {
  __typename?: 'issue_assessment_aggregate';
  aggregate?: Maybe<Issue_Assessment_Aggregate_Fields>;
  nodes: Array<Issue_Assessment>;
};

/** aggregate fields of "risksmart.issue_assessment" */
export type Issue_Assessment_Aggregate_Fields = {
  __typename?: 'issue_assessment_aggregate_fields';
  avg?: Maybe<Issue_Assessment_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Issue_Assessment_Max_Fields>;
  min?: Maybe<Issue_Assessment_Min_Fields>;
  stddev?: Maybe<Issue_Assessment_Stddev_Fields>;
  stddev_pop?: Maybe<Issue_Assessment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Issue_Assessment_Stddev_Samp_Fields>;
  sum?: Maybe<Issue_Assessment_Sum_Fields>;
  var_pop?: Maybe<Issue_Assessment_Var_Pop_Fields>;
  var_samp?: Maybe<Issue_Assessment_Var_Samp_Fields>;
  variance?: Maybe<Issue_Assessment_Variance_Fields>;
};


/** aggregate fields of "risksmart.issue_assessment" */
export type Issue_Assessment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Issue_Assessment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "risksmart.issue_assessment_audit" */
export type Issue_Assessment_Audit = {
  __typename?: 'issue_assessment_audit';
  Action?: Maybe<Scalars['String']['output']>;
  ActualCloseDate?: Maybe<Scalars['timestamptz']['output']>;
  CertifiedIndividual?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  IssueCausedBySystemIssue?: Maybe<Scalars['Boolean']['output']>;
  IssueCausedByThirdParty?: Maybe<Scalars['Boolean']['output']>;
  IssueType?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentIssueId: Scalars['uuid']['output'];
  PoliciesBreached?: Maybe<Scalars['String']['output']>;
  PolicyBreach?: Maybe<Scalars['Boolean']['output']>;
  PolicyOwner?: Maybe<Scalars['String']['output']>;
  PolicyOwnerCommentary?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  RegulationsBreached?: Maybe<Scalars['String']['output']>;
  RegulatoryBreach?: Maybe<Scalars['Boolean']['output']>;
  Reportable?: Maybe<Scalars['Boolean']['output']>;
  Severity?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  SystemResponsible?: Maybe<Scalars['String']['output']>;
  TargetCloseDate?: Maybe<Scalars['timestamptz']['output']>;
  ThirdPartyResponsible?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "risksmart.issue_assessment_audit" */
export type Issue_Assessment_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.issue_assessment_audit". All fields are combined with a logical 'AND'. */
export type Issue_Assessment_Audit_Bool_Exp = {
  Action?: InputMaybe<String_Comparison_Exp>;
  ActualCloseDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  CertifiedIndividual?: InputMaybe<String_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  IssueCausedBySystemIssue?: InputMaybe<Boolean_Comparison_Exp>;
  IssueCausedByThirdParty?: InputMaybe<Boolean_Comparison_Exp>;
  IssueType?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentIssueId?: InputMaybe<Uuid_Comparison_Exp>;
  PoliciesBreached?: InputMaybe<String_Comparison_Exp>;
  PolicyBreach?: InputMaybe<Boolean_Comparison_Exp>;
  PolicyOwner?: InputMaybe<String_Comparison_Exp>;
  PolicyOwnerCommentary?: InputMaybe<String_Comparison_Exp>;
  Rationale?: InputMaybe<String_Comparison_Exp>;
  RegulationsBreached?: InputMaybe<String_Comparison_Exp>;
  RegulatoryBreach?: InputMaybe<Boolean_Comparison_Exp>;
  Reportable?: InputMaybe<Boolean_Comparison_Exp>;
  Severity?: InputMaybe<Int_Comparison_Exp>;
  Status?: InputMaybe<String_Comparison_Exp>;
  SystemResponsible?: InputMaybe<String_Comparison_Exp>;
  TargetCloseDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  ThirdPartyResponsible?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Issue_Assessment_Audit_Bool_Exp>>;
  _not?: InputMaybe<Issue_Assessment_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Issue_Assessment_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.issue_assessment_audit". */
export type Issue_Assessment_Audit_Order_By = {
  Action?: InputMaybe<Order_By>;
  ActualCloseDate?: InputMaybe<Order_By>;
  CertifiedIndividual?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  IssueCausedBySystemIssue?: InputMaybe<Order_By>;
  IssueCausedByThirdParty?: InputMaybe<Order_By>;
  IssueType?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentIssueId?: InputMaybe<Order_By>;
  PoliciesBreached?: InputMaybe<Order_By>;
  PolicyBreach?: InputMaybe<Order_By>;
  PolicyOwner?: InputMaybe<Order_By>;
  PolicyOwnerCommentary?: InputMaybe<Order_By>;
  Rationale?: InputMaybe<Order_By>;
  RegulationsBreached?: InputMaybe<Order_By>;
  RegulatoryBreach?: InputMaybe<Order_By>;
  Reportable?: InputMaybe<Order_By>;
  Severity?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  SystemResponsible?: InputMaybe<Order_By>;
  TargetCloseDate?: InputMaybe<Order_By>;
  ThirdPartyResponsible?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.issue_assessment_audit" */
export const Issue_Assessment_Audit_Select_Column = {
  /** column name */
  Action: 'Action',
  /** column name */
  ActualCloseDate: 'ActualCloseDate',
  /** column name */
  CertifiedIndividual: 'CertifiedIndividual',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Id: 'Id',
  /** column name */
  IssueCausedBySystemIssue: 'IssueCausedBySystemIssue',
  /** column name */
  IssueCausedByThirdParty: 'IssueCausedByThirdParty',
  /** column name */
  IssueType: 'IssueType',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentIssueId: 'ParentIssueId',
  /** column name */
  PoliciesBreached: 'PoliciesBreached',
  /** column name */
  PolicyBreach: 'PolicyBreach',
  /** column name */
  PolicyOwner: 'PolicyOwner',
  /** column name */
  PolicyOwnerCommentary: 'PolicyOwnerCommentary',
  /** column name */
  Rationale: 'Rationale',
  /** column name */
  RegulationsBreached: 'RegulationsBreached',
  /** column name */
  RegulatoryBreach: 'RegulatoryBreach',
  /** column name */
  Reportable: 'Reportable',
  /** column name */
  Severity: 'Severity',
  /** column name */
  Status: 'Status',
  /** column name */
  SystemResponsible: 'SystemResponsible',
  /** column name */
  TargetCloseDate: 'TargetCloseDate',
  /** column name */
  ThirdPartyResponsible: 'ThirdPartyResponsible'
} as const;

export type Issue_Assessment_Audit_Select_Column = typeof Issue_Assessment_Audit_Select_Column[keyof typeof Issue_Assessment_Audit_Select_Column];
/** Streaming cursor of the table "issue_assessment_audit" */
export type Issue_Assessment_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Issue_Assessment_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Issue_Assessment_Audit_Stream_Cursor_Value_Input = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActualCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CertifiedIndividual?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IssueCausedBySystemIssue?: InputMaybe<Scalars['Boolean']['input']>;
  IssueCausedByThirdParty?: InputMaybe<Scalars['Boolean']['input']>;
  IssueType?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  PoliciesBreached?: InputMaybe<Scalars['String']['input']>;
  PolicyBreach?: InputMaybe<Scalars['Boolean']['input']>;
  PolicyOwner?: InputMaybe<Scalars['String']['input']>;
  PolicyOwnerCommentary?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  RegulationsBreached?: InputMaybe<Scalars['String']['input']>;
  RegulatoryBreach?: InputMaybe<Scalars['Boolean']['input']>;
  Reportable?: InputMaybe<Scalars['Boolean']['input']>;
  Severity?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  SystemResponsible?: InputMaybe<Scalars['String']['input']>;
  TargetCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ThirdPartyResponsible?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate avg on columns */
export type Issue_Assessment_Avg_Fields = {
  __typename?: 'issue_assessment_avg_fields';
  Severity?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.issue_assessment". All fields are combined with a logical 'AND'. */
export type Issue_Assessment_Bool_Exp = {
  ActualCloseDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  CertifiedIndividual?: InputMaybe<String_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  IssueCausedBySystemIssue?: InputMaybe<Boolean_Comparison_Exp>;
  IssueCausedByThirdParty?: InputMaybe<Boolean_Comparison_Exp>;
  IssueType?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentIssueId?: InputMaybe<Uuid_Comparison_Exp>;
  PoliciesBreached?: InputMaybe<String_Comparison_Exp>;
  PolicyBreach?: InputMaybe<Boolean_Comparison_Exp>;
  PolicyOwner?: InputMaybe<String_Comparison_Exp>;
  PolicyOwnerCommentary?: InputMaybe<String_Comparison_Exp>;
  Rationale?: InputMaybe<String_Comparison_Exp>;
  RegulationsBreached?: InputMaybe<String_Comparison_Exp>;
  RegulatoryBreach?: InputMaybe<Boolean_Comparison_Exp>;
  Reportable?: InputMaybe<Boolean_Comparison_Exp>;
  Severity?: InputMaybe<Int_Comparison_Exp>;
  Status?: InputMaybe<Issue_Assessment_Status_Enum_Comparison_Exp>;
  SystemResponsible?: InputMaybe<String_Comparison_Exp>;
  TargetCloseDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  ThirdPartyResponsible?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Issue_Assessment_Bool_Exp>>;
  _not?: InputMaybe<Issue_Assessment_Bool_Exp>;
  _or?: InputMaybe<Array<Issue_Assessment_Bool_Exp>>;
  certifiedIndividual?: InputMaybe<User_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  departments?: InputMaybe<Department_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parent?: InputMaybe<Issue_Bool_Exp>;
  policyOwner?: InputMaybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.issue_assessment" */
export const Issue_Assessment_Constraint = {
  /** unique or primary key constraint on columns "ParentIssueId" */
  IdxIssueAssessmentParentIssueId: 'idx_issueAssessment_parentIssueId',
  /** unique or primary key constraint on columns "Id" */
  IssueAssessmentPkey: 'issue_assessment_pkey'
} as const;

export type Issue_Assessment_Constraint = typeof Issue_Assessment_Constraint[keyof typeof Issue_Assessment_Constraint];
/** input type for inserting data into table "risksmart.issue_assessment" */
export type Issue_Assessment_Insert_Input = {
  ActualCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CertifiedIndividual?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IssueCausedBySystemIssue?: InputMaybe<Scalars['Boolean']['input']>;
  IssueCausedByThirdParty?: InputMaybe<Scalars['Boolean']['input']>;
  IssueType?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  PoliciesBreached?: InputMaybe<Scalars['String']['input']>;
  PolicyBreach?: InputMaybe<Scalars['Boolean']['input']>;
  PolicyOwner?: InputMaybe<Scalars['String']['input']>;
  PolicyOwnerCommentary?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  RegulationsBreached?: InputMaybe<Scalars['String']['input']>;
  RegulatoryBreach?: InputMaybe<Scalars['Boolean']['input']>;
  Reportable?: InputMaybe<Scalars['Boolean']['input']>;
  Severity?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Issue_Assessment_Status_Enum>;
  SystemResponsible?: InputMaybe<Scalars['String']['input']>;
  TargetCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ThirdPartyResponsible?: InputMaybe<Scalars['String']['input']>;
  departments?: InputMaybe<Department_Arr_Rel_Insert_Input>;
  parent?: InputMaybe<Issue_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Issue_Assessment_Max_Fields = {
  __typename?: 'issue_assessment_max_fields';
  ActualCloseDate?: Maybe<Scalars['timestamptz']['output']>;
  CertifiedIndividual?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  IssueType?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  PoliciesBreached?: Maybe<Scalars['String']['output']>;
  PolicyOwner?: Maybe<Scalars['String']['output']>;
  PolicyOwnerCommentary?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  RegulationsBreached?: Maybe<Scalars['String']['output']>;
  Severity?: Maybe<Scalars['Int']['output']>;
  SystemResponsible?: Maybe<Scalars['String']['output']>;
  TargetCloseDate?: Maybe<Scalars['timestamptz']['output']>;
  ThirdPartyResponsible?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Issue_Assessment_Min_Fields = {
  __typename?: 'issue_assessment_min_fields';
  ActualCloseDate?: Maybe<Scalars['timestamptz']['output']>;
  CertifiedIndividual?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  IssueType?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  PoliciesBreached?: Maybe<Scalars['String']['output']>;
  PolicyOwner?: Maybe<Scalars['String']['output']>;
  PolicyOwnerCommentary?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  RegulationsBreached?: Maybe<Scalars['String']['output']>;
  Severity?: Maybe<Scalars['Int']['output']>;
  SystemResponsible?: Maybe<Scalars['String']['output']>;
  TargetCloseDate?: Maybe<Scalars['timestamptz']['output']>;
  ThirdPartyResponsible?: Maybe<Scalars['String']['output']>;
};

/** input type for inserting object relation for remote table "risksmart.issue_assessment" */
export type Issue_Assessment_Obj_Rel_Insert_Input = {
  data: Issue_Assessment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Issue_Assessment_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.issue_assessment" */
export type Issue_Assessment_On_Conflict = {
  constraint: Issue_Assessment_Constraint;
  update_columns?: Array<Issue_Assessment_Update_Column>;
  where?: InputMaybe<Issue_Assessment_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.issue_assessment". */
export type Issue_Assessment_Order_By = {
  ActualCloseDate?: InputMaybe<Order_By>;
  CertifiedIndividual?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  IssueCausedBySystemIssue?: InputMaybe<Order_By>;
  IssueCausedByThirdParty?: InputMaybe<Order_By>;
  IssueType?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentIssueId?: InputMaybe<Order_By>;
  PoliciesBreached?: InputMaybe<Order_By>;
  PolicyBreach?: InputMaybe<Order_By>;
  PolicyOwner?: InputMaybe<Order_By>;
  PolicyOwnerCommentary?: InputMaybe<Order_By>;
  Rationale?: InputMaybe<Order_By>;
  RegulationsBreached?: InputMaybe<Order_By>;
  RegulatoryBreach?: InputMaybe<Order_By>;
  Reportable?: InputMaybe<Order_By>;
  Severity?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  SystemResponsible?: InputMaybe<Order_By>;
  TargetCloseDate?: InputMaybe<Order_By>;
  ThirdPartyResponsible?: InputMaybe<Order_By>;
  certifiedIndividual?: InputMaybe<User_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  departments_aggregate?: InputMaybe<Department_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parent?: InputMaybe<Issue_Order_By>;
  policyOwner?: InputMaybe<User_Order_By>;
};

/** select columns of table "risksmart.issue_assessment" */
export const Issue_Assessment_Select_Column = {
  /** column name */
  ActualCloseDate: 'ActualCloseDate',
  /** column name */
  CertifiedIndividual: 'CertifiedIndividual',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Id: 'Id',
  /** column name */
  IssueCausedBySystemIssue: 'IssueCausedBySystemIssue',
  /** column name */
  IssueCausedByThirdParty: 'IssueCausedByThirdParty',
  /** column name */
  IssueType: 'IssueType',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentIssueId: 'ParentIssueId',
  /** column name */
  PoliciesBreached: 'PoliciesBreached',
  /** column name */
  PolicyBreach: 'PolicyBreach',
  /** column name */
  PolicyOwner: 'PolicyOwner',
  /** column name */
  PolicyOwnerCommentary: 'PolicyOwnerCommentary',
  /** column name */
  Rationale: 'Rationale',
  /** column name */
  RegulationsBreached: 'RegulationsBreached',
  /** column name */
  RegulatoryBreach: 'RegulatoryBreach',
  /** column name */
  Reportable: 'Reportable',
  /** column name */
  Severity: 'Severity',
  /** column name */
  Status: 'Status',
  /** column name */
  SystemResponsible: 'SystemResponsible',
  /** column name */
  TargetCloseDate: 'TargetCloseDate',
  /** column name */
  ThirdPartyResponsible: 'ThirdPartyResponsible'
} as const;

export type Issue_Assessment_Select_Column = typeof Issue_Assessment_Select_Column[keyof typeof Issue_Assessment_Select_Column];
export const Issue_Assessment_Status_Enum = {
  /** awaiting closure */
  Awaitingclosure: 'awaitingclosure',
  /** Closed */
  Closed: 'closed',
  /** Declined */
  Declined: 'declined',
  /** 1st line approval */
  Firstlineapproval: 'firstlineapproval',
  /** Open */
  Open: 'open',
  /** Pending */
  Pending: 'pending'
} as const;

export type Issue_Assessment_Status_Enum = typeof Issue_Assessment_Status_Enum[keyof typeof Issue_Assessment_Status_Enum];
/** Boolean expression to compare columns of type "issue_assessment_status_enum". All fields are combined with logical 'AND'. */
export type Issue_Assessment_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Issue_Assessment_Status_Enum>;
  _in?: InputMaybe<Array<Issue_Assessment_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Issue_Assessment_Status_Enum>;
  _nin?: InputMaybe<Array<Issue_Assessment_Status_Enum>>;
};

/** aggregate stddev on columns */
export type Issue_Assessment_Stddev_Fields = {
  __typename?: 'issue_assessment_stddev_fields';
  Severity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Issue_Assessment_Stddev_Pop_Fields = {
  __typename?: 'issue_assessment_stddev_pop_fields';
  Severity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Issue_Assessment_Stddev_Samp_Fields = {
  __typename?: 'issue_assessment_stddev_samp_fields';
  Severity?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "issue_assessment" */
export type Issue_Assessment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Issue_Assessment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Issue_Assessment_Stream_Cursor_Value_Input = {
  ActualCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CertifiedIndividual?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IssueCausedBySystemIssue?: InputMaybe<Scalars['Boolean']['input']>;
  IssueCausedByThirdParty?: InputMaybe<Scalars['Boolean']['input']>;
  IssueType?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  PoliciesBreached?: InputMaybe<Scalars['String']['input']>;
  PolicyBreach?: InputMaybe<Scalars['Boolean']['input']>;
  PolicyOwner?: InputMaybe<Scalars['String']['input']>;
  PolicyOwnerCommentary?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  RegulationsBreached?: InputMaybe<Scalars['String']['input']>;
  RegulatoryBreach?: InputMaybe<Scalars['Boolean']['input']>;
  Reportable?: InputMaybe<Scalars['Boolean']['input']>;
  Severity?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Issue_Assessment_Status_Enum>;
  SystemResponsible?: InputMaybe<Scalars['String']['input']>;
  TargetCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ThirdPartyResponsible?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Issue_Assessment_Sum_Fields = {
  __typename?: 'issue_assessment_sum_fields';
  Severity?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.issue_assessment" */
export const Issue_Assessment_Update_Column = {
  /** column name */
  ActualCloseDate: 'ActualCloseDate',
  /** column name */
  CertifiedIndividual: 'CertifiedIndividual',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  IssueCausedBySystemIssue: 'IssueCausedBySystemIssue',
  /** column name */
  IssueCausedByThirdParty: 'IssueCausedByThirdParty',
  /** column name */
  IssueType: 'IssueType',
  /** column name */
  PoliciesBreached: 'PoliciesBreached',
  /** column name */
  PolicyBreach: 'PolicyBreach',
  /** column name */
  PolicyOwner: 'PolicyOwner',
  /** column name */
  PolicyOwnerCommentary: 'PolicyOwnerCommentary',
  /** column name */
  Rationale: 'Rationale',
  /** column name */
  RegulationsBreached: 'RegulationsBreached',
  /** column name */
  RegulatoryBreach: 'RegulatoryBreach',
  /** column name */
  Reportable: 'Reportable',
  /** column name */
  Severity: 'Severity',
  /** column name */
  Status: 'Status',
  /** column name */
  SystemResponsible: 'SystemResponsible',
  /** column name */
  TargetCloseDate: 'TargetCloseDate',
  /** column name */
  ThirdPartyResponsible: 'ThirdPartyResponsible'
} as const;

export type Issue_Assessment_Update_Column = typeof Issue_Assessment_Update_Column[keyof typeof Issue_Assessment_Update_Column];
/** aggregate var_pop on columns */
export type Issue_Assessment_Var_Pop_Fields = {
  __typename?: 'issue_assessment_var_pop_fields';
  Severity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Issue_Assessment_Var_Samp_Fields = {
  __typename?: 'issue_assessment_var_samp_fields';
  Severity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Issue_Assessment_Variance_Fields = {
  __typename?: 'issue_assessment_variance_fields';
  Severity?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.issue_audit" */
export type Issue_Audit = {
  __typename?: 'issue_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  DateIdentified: Scalars['timestamptz']['output'];
  DateOccurred: Scalars['timestamptz']['output'];
  Details: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ImpactsCustomer?: Maybe<Scalars['Boolean']['output']>;
  IsExternalIssue?: Maybe<Scalars['Boolean']['output']>;
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  RaisedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.issue_audit" */
export type Issue_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.issue_audit" */
export type Issue_AuditMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.issue_audit". All fields are combined with a logical 'AND'. */
export type Issue_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  DateIdentified?: InputMaybe<Timestamptz_Comparison_Exp>;
  DateOccurred?: InputMaybe<Timestamptz_Comparison_Exp>;
  Details?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ImpactsCustomer?: InputMaybe<Boolean_Comparison_Exp>;
  IsExternalIssue?: InputMaybe<Boolean_Comparison_Exp>;
  Meta?: InputMaybe<Json_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  RaisedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Issue_Audit_Bool_Exp>>;
  _not?: InputMaybe<Issue_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Issue_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.issue_audit". */
export type Issue_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  DateIdentified?: InputMaybe<Order_By>;
  DateOccurred?: InputMaybe<Order_By>;
  Details?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ImpactsCustomer?: InputMaybe<Order_By>;
  IsExternalIssue?: InputMaybe<Order_By>;
  Meta?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  RaisedAtTimestamp?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.issue_audit" */
export const Issue_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  DateIdentified: 'DateIdentified',
  /** column name */
  DateOccurred: 'DateOccurred',
  /** column name */
  Details: 'Details',
  /** column name */
  Id: 'Id',
  /** column name */
  ImpactsCustomer: 'ImpactsCustomer',
  /** column name */
  IsExternalIssue: 'IsExternalIssue',
  /** column name */
  Meta: 'Meta',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  RaisedAtTimestamp: 'RaisedAtTimestamp',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Title: 'Title'
} as const;

export type Issue_Audit_Select_Column = typeof Issue_Audit_Select_Column[keyof typeof Issue_Audit_Select_Column];
/** Streaming cursor of the table "issue_audit" */
export type Issue_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Issue_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Issue_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateIdentified?: InputMaybe<Scalars['timestamptz']['input']>;
  DateOccurred?: InputMaybe<Scalars['timestamptz']['input']>;
  Details?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactsCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  IsExternalIssue?: InputMaybe<Scalars['Boolean']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  RaisedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate avg on columns */
export type Issue_Avg_Fields = {
  __typename?: 'issue_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.issue". All fields are combined with a logical 'AND'. */
export type Issue_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  DateIdentified?: InputMaybe<Timestamptz_Comparison_Exp>;
  DateOccurred?: InputMaybe<Timestamptz_Comparison_Exp>;
  Details?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ImpactsCustomer?: InputMaybe<Boolean_Comparison_Exp>;
  IsExternalIssue?: InputMaybe<Boolean_Comparison_Exp>;
  Meta?: InputMaybe<Json_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  RaisedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Issue_Bool_Exp>>;
  _not?: InputMaybe<Issue_Bool_Exp>;
  _or?: InputMaybe<Array<Issue_Bool_Exp>>;
  actions?: InputMaybe<Action_Parent_Bool_Exp>;
  actions_aggregate?: InputMaybe<Action_Parent_Aggregate_Bool_Exp>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  assessment?: InputMaybe<Issue_Assessment_Bool_Exp>;
  causes?: InputMaybe<Cause_Bool_Exp>;
  causes_aggregate?: InputMaybe<Cause_Aggregate_Bool_Exp>;
  consequences?: InputMaybe<Consequence_Bool_Exp>;
  consequences_aggregate?: InputMaybe<Consequence_Aggregate_Bool_Exp>;
  contributorGroups?: InputMaybe<Contributor_Group_Bool_Exp>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Bool_Exp>;
  contributors?: InputMaybe<Contributor_Bool_Exp>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  departments?: InputMaybe<Department_Bool_Exp>;
  files?: InputMaybe<Relation_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  ownerGroups?: InputMaybe<Owner_Group_Bool_Exp>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Bool_Exp>;
  owners?: InputMaybe<Owner_Bool_Exp>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Bool_Exp>;
  parents?: InputMaybe<Issue_Parent_Bool_Exp>;
  parents_aggregate?: InputMaybe<Issue_Parent_Aggregate_Bool_Exp>;
  tags?: InputMaybe<Tag_Bool_Exp>;
  updates?: InputMaybe<Issue_Update_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.issue" */
export const Issue_Constraint = {
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxIssueOrgkeySequentialid: 'idx_issue_orgkey_sequentialid',
  /** unique or primary key constraint on columns "Id" */
  IssuePkey: 'issue_pkey'
} as const;

export type Issue_Constraint = typeof Issue_Constraint[keyof typeof Issue_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Issue_Delete_At_Path_Input = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Issue_Delete_Elem_Input = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Issue_Delete_Key_Input = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.issue" */
export type Issue_Insert_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateIdentified?: InputMaybe<Scalars['timestamptz']['input']>;
  DateOccurred?: InputMaybe<Scalars['timestamptz']['input']>;
  Details?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactsCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  IsExternalIssue?: InputMaybe<Scalars['Boolean']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  RaisedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  actions?: InputMaybe<Action_Parent_Arr_Rel_Insert_Input>;
  assessment?: InputMaybe<Issue_Assessment_Obj_Rel_Insert_Input>;
  causes?: InputMaybe<Cause_Arr_Rel_Insert_Input>;
  consequences?: InputMaybe<Consequence_Arr_Rel_Insert_Input>;
  contributorGroups?: InputMaybe<Contributor_Group_Arr_Rel_Insert_Input>;
  contributors?: InputMaybe<Contributor_Arr_Rel_Insert_Input>;
  departments?: InputMaybe<Department_Arr_Rel_Insert_Input>;
  files?: InputMaybe<Relation_File_Arr_Rel_Insert_Input>;
  ownerGroups?: InputMaybe<Owner_Group_Arr_Rel_Insert_Input>;
  owners?: InputMaybe<Owner_Arr_Rel_Insert_Input>;
  parents?: InputMaybe<Issue_Parent_Arr_Rel_Insert_Input>;
  tags?: InputMaybe<Tag_Arr_Rel_Insert_Input>;
  updates?: InputMaybe<Issue_Update_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Issue_Max_Fields = {
  __typename?: 'issue_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DateIdentified?: Maybe<Scalars['timestamptz']['output']>;
  DateOccurred?: Maybe<Scalars['timestamptz']['output']>;
  Details?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  RaisedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Issue_Min_Fields = {
  __typename?: 'issue_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DateIdentified?: Maybe<Scalars['timestamptz']['output']>;
  DateOccurred?: Maybe<Scalars['timestamptz']['output']>;
  Details?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  RaisedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.issue" */
export type Issue_Mutation_Response = {
  __typename?: 'issue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Issue>;
};

/** input type for inserting object relation for remote table "risksmart.issue" */
export type Issue_Obj_Rel_Insert_Input = {
  data: Issue_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Issue_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.issue" */
export type Issue_On_Conflict = {
  constraint: Issue_Constraint;
  update_columns?: Array<Issue_Update_Column>;
  where?: InputMaybe<Issue_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.issue". */
export type Issue_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  DateIdentified?: InputMaybe<Order_By>;
  DateOccurred?: InputMaybe<Order_By>;
  Details?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ImpactsCustomer?: InputMaybe<Order_By>;
  IsExternalIssue?: InputMaybe<Order_By>;
  Meta?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  RaisedAtTimestamp?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  actions_aggregate?: InputMaybe<Action_Parent_Aggregate_Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  assessment?: InputMaybe<Issue_Assessment_Order_By>;
  causes_aggregate?: InputMaybe<Cause_Aggregate_Order_By>;
  consequences_aggregate?: InputMaybe<Consequence_Aggregate_Order_By>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Order_By>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  departments_aggregate?: InputMaybe<Department_Aggregate_Order_By>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Order_By>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Order_By>;
  parents_aggregate?: InputMaybe<Issue_Parent_Aggregate_Order_By>;
  tags_aggregate?: InputMaybe<Tag_Aggregate_Order_By>;
  updates_aggregate?: InputMaybe<Issue_Update_Aggregate_Order_By>;
};

/** columns and relationships of "risksmart.issue_parent" */
export type Issue_Parent = {
  __typename?: 'issue_parent';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  IssueId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  ParentType: Parent_Type_Enum;
  /** An object relationship */
  assessment?: Maybe<Assessment>;
  /** An object relationship */
  complianceMonitoringAssessment?: Maybe<Compliance_Monitoring_Assessment>;
  /** An object relationship */
  control?: Maybe<Control>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  document?: Maybe<Document>;
  /** An object relationship */
  internalAuditEntity?: Maybe<Internal_Audit_Entity>;
  /** An object relationship */
  internalAuditReport?: Maybe<Internal_Audit_Report>;
  /** An object relationship */
  issue?: Maybe<Issue>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  obligation?: Maybe<Obligation>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parent?: Maybe<Node>;
  /** An object relationship */
  thirdParty?: Maybe<Third_Party>;
};

/** aggregated selection of "risksmart.issue_parent" */
export type Issue_Parent_Aggregate = {
  __typename?: 'issue_parent_aggregate';
  aggregate?: Maybe<Issue_Parent_Aggregate_Fields>;
  nodes: Array<Issue_Parent>;
};

export type Issue_Parent_Aggregate_Bool_Exp = {
  count?: InputMaybe<Issue_Parent_Aggregate_Bool_Exp_Count>;
};

export type Issue_Parent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Issue_Parent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Issue_Parent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.issue_parent" */
export type Issue_Parent_Aggregate_Fields = {
  __typename?: 'issue_parent_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Issue_Parent_Max_Fields>;
  min?: Maybe<Issue_Parent_Min_Fields>;
};


/** aggregate fields of "risksmart.issue_parent" */
export type Issue_Parent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Issue_Parent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.issue_parent" */
export type Issue_Parent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Issue_Parent_Max_Order_By>;
  min?: InputMaybe<Issue_Parent_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.issue_parent" */
export type Issue_Parent_Arr_Rel_Insert_Input = {
  data: Array<Issue_Parent_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Issue_Parent_On_Conflict>;
};

/** columns and relationships of "risksmart.issue_parent_audit" */
export type Issue_Parent_Audit = {
  __typename?: 'issue_parent_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  IssueId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId: Scalars['uuid']['output'];
  ParentType?: Maybe<Scalars['String']['output']>;
};

/** order by aggregate values of table "risksmart.issue_parent_audit" */
export type Issue_Parent_Audit_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Issue_Parent_Audit_Max_Order_By>;
  min?: InputMaybe<Issue_Parent_Audit_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.issue_parent_audit". All fields are combined with a logical 'AND'. */
export type Issue_Parent_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  IssueId?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  ParentType?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Issue_Parent_Audit_Bool_Exp>>;
  _not?: InputMaybe<Issue_Parent_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Issue_Parent_Audit_Bool_Exp>>;
};

/** order by max() on columns of table "risksmart.issue_parent_audit" */
export type Issue_Parent_Audit_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  IssueId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  ParentType?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.issue_parent_audit" */
export type Issue_Parent_Audit_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  IssueId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  ParentType?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "risksmart.issue_parent_audit". */
export type Issue_Parent_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  IssueId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  ParentType?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.issue_parent_audit" */
export const Issue_Parent_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  IssueId: 'IssueId',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  ParentType: 'ParentType'
} as const;

export type Issue_Parent_Audit_Select_Column = typeof Issue_Parent_Audit_Select_Column[keyof typeof Issue_Parent_Audit_Select_Column];
/** Streaming cursor of the table "issue_parent_audit" */
export type Issue_Parent_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Issue_Parent_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Issue_Parent_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  IssueId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.issue_parent". All fields are combined with a logical 'AND'. */
export type Issue_Parent_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  IssueId?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  ParentType?: InputMaybe<Parent_Type_Enum_Comparison_Exp>;
  _and?: InputMaybe<Array<Issue_Parent_Bool_Exp>>;
  _not?: InputMaybe<Issue_Parent_Bool_Exp>;
  _or?: InputMaybe<Array<Issue_Parent_Bool_Exp>>;
  assessment?: InputMaybe<Assessment_Bool_Exp>;
  complianceMonitoringAssessment?: InputMaybe<Compliance_Monitoring_Assessment_Bool_Exp>;
  control?: InputMaybe<Control_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  document?: InputMaybe<Document_Bool_Exp>;
  internalAuditEntity?: InputMaybe<Internal_Audit_Entity_Bool_Exp>;
  internalAuditReport?: InputMaybe<Internal_Audit_Report_Bool_Exp>;
  issue?: InputMaybe<Issue_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  obligation?: InputMaybe<Obligation_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parent?: InputMaybe<Node_Bool_Exp>;
  thirdParty?: InputMaybe<Third_Party_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.issue_parent" */
export const Issue_Parent_Constraint = {
  /** unique or primary key constraint on columns "ParentId", "IssueId" */
  IssueParentPkey: 'issue_parent_pkey'
} as const;

export type Issue_Parent_Constraint = typeof Issue_Parent_Constraint[keyof typeof Issue_Parent_Constraint];
/** input type for inserting data into table "risksmart.issue_parent" */
export type Issue_Parent_Insert_Input = {
  IssueId?: InputMaybe<Scalars['uuid']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Parent_Type_Enum>;
  assessment?: InputMaybe<Assessment_Obj_Rel_Insert_Input>;
  complianceMonitoringAssessment?: InputMaybe<Compliance_Monitoring_Assessment_Obj_Rel_Insert_Input>;
  control?: InputMaybe<Control_Obj_Rel_Insert_Input>;
  document?: InputMaybe<Document_Obj_Rel_Insert_Input>;
  internalAuditEntity?: InputMaybe<Internal_Audit_Entity_Obj_Rel_Insert_Input>;
  internalAuditReport?: InputMaybe<Internal_Audit_Report_Obj_Rel_Insert_Input>;
  issue?: InputMaybe<Issue_Obj_Rel_Insert_Input>;
  obligation?: InputMaybe<Obligation_Obj_Rel_Insert_Input>;
  thirdParty?: InputMaybe<Third_Party_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Issue_Parent_Max_Fields = {
  __typename?: 'issue_parent_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  IssueId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.issue_parent" */
export type Issue_Parent_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  IssueId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Issue_Parent_Min_Fields = {
  __typename?: 'issue_parent_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  IssueId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.issue_parent" */
export type Issue_Parent_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  IssueId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "risksmart.issue_parent" */
export type Issue_Parent_On_Conflict = {
  constraint: Issue_Parent_Constraint;
  update_columns?: Array<Issue_Parent_Update_Column>;
  where?: InputMaybe<Issue_Parent_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.issue_parent". */
export type Issue_Parent_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  IssueId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  ParentType?: InputMaybe<Order_By>;
  assessment?: InputMaybe<Assessment_Order_By>;
  complianceMonitoringAssessment?: InputMaybe<Compliance_Monitoring_Assessment_Order_By>;
  control?: InputMaybe<Control_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  document?: InputMaybe<Document_Order_By>;
  internalAuditEntity?: InputMaybe<Internal_Audit_Entity_Order_By>;
  internalAuditReport?: InputMaybe<Internal_Audit_Report_Order_By>;
  issue?: InputMaybe<Issue_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  obligation?: InputMaybe<Obligation_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parent?: InputMaybe<Node_Order_By>;
  thirdParty?: InputMaybe<Third_Party_Order_By>;
};

/** select columns of table "risksmart.issue_parent" */
export const Issue_Parent_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  IssueId: 'IssueId',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  ParentType: 'ParentType'
} as const;

export type Issue_Parent_Select_Column = typeof Issue_Parent_Select_Column[keyof typeof Issue_Parent_Select_Column];
/** Streaming cursor of the table "issue_parent" */
export type Issue_Parent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Issue_Parent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Issue_Parent_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  IssueId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Parent_Type_Enum>;
};

/** placeholder for update columns of table "risksmart.issue_parent" (current role has no relevant permissions) */
export const Issue_Parent_Update_Column = {
  /** placeholder (do not use) */
  Placeholder: '_PLACEHOLDER'
} as const;

export type Issue_Parent_Update_Column = typeof Issue_Parent_Update_Column[keyof typeof Issue_Parent_Update_Column];
/** primary key columns input for table: risksmart.issue */
export type Issue_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Issue_Prepend_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.issue" */
export const Issue_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  DateIdentified: 'DateIdentified',
  /** column name */
  DateOccurred: 'DateOccurred',
  /** column name */
  Details: 'Details',
  /** column name */
  Id: 'Id',
  /** column name */
  ImpactsCustomer: 'ImpactsCustomer',
  /** column name */
  IsExternalIssue: 'IsExternalIssue',
  /** column name */
  Meta: 'Meta',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  RaisedAtTimestamp: 'RaisedAtTimestamp',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Title: 'Title'
} as const;

export type Issue_Select_Column = typeof Issue_Select_Column[keyof typeof Issue_Select_Column];
/** input type for updating data in table "risksmart.issue" */
export type Issue_Set_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateIdentified?: InputMaybe<Scalars['timestamptz']['input']>;
  DateOccurred?: InputMaybe<Scalars['timestamptz']['input']>;
  Details?: InputMaybe<Scalars['String']['input']>;
  ImpactsCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  IsExternalIssue?: InputMaybe<Scalars['Boolean']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Issue_Stddev_Fields = {
  __typename?: 'issue_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Issue_Stddev_Pop_Fields = {
  __typename?: 'issue_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Issue_Stddev_Samp_Fields = {
  __typename?: 'issue_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "issue" */
export type Issue_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Issue_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Issue_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateIdentified?: InputMaybe<Scalars['timestamptz']['input']>;
  DateOccurred?: InputMaybe<Scalars['timestamptz']['input']>;
  Details?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactsCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  IsExternalIssue?: InputMaybe<Scalars['Boolean']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  RaisedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Issue_Sum_Fields = {
  __typename?: 'issue_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "risksmart.issue_update" */
export type Issue_Update = {
  __typename?: 'issue_update';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentIssueId: Scalars['uuid']['output'];
  Title: Scalars['String']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  files: Array<Relation_File>;
  /** An aggregate relationship */
  files_aggregate: Relation_File_Aggregate;
  /** An object relationship */
  issue?: Maybe<Issue>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
};


/** columns and relationships of "risksmart.issue_update" */
export type Issue_UpdateCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.issue_update" */
export type Issue_UpdateFilesArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.issue_update" */
export type Issue_UpdateFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};

/** order by aggregate values of table "risksmart.issue_update" */
export type Issue_Update_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Issue_Update_Max_Order_By>;
  min?: InputMaybe<Issue_Update_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Issue_Update_Append_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.issue_update" */
export type Issue_Update_Arr_Rel_Insert_Input = {
  data: Array<Issue_Update_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Issue_Update_On_Conflict>;
};

/** columns and relationships of "risksmart.issue_update_audit" */
export type Issue_Update_Audit = {
  __typename?: 'issue_update_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentIssueId: Scalars['uuid']['output'];
  Title: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.issue_update_audit" */
export type Issue_Update_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.issue_update_audit". All fields are combined with a logical 'AND'. */
export type Issue_Update_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentIssueId?: InputMaybe<Uuid_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Issue_Update_Audit_Bool_Exp>>;
  _not?: InputMaybe<Issue_Update_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Issue_Update_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.issue_update_audit". */
export type Issue_Update_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentIssueId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.issue_update_audit" */
export const Issue_Update_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentIssueId: 'ParentIssueId',
  /** column name */
  Title: 'Title'
} as const;

export type Issue_Update_Audit_Select_Column = typeof Issue_Update_Audit_Select_Column[keyof typeof Issue_Update_Audit_Select_Column];
/** Streaming cursor of the table "issue_update_audit" */
export type Issue_Update_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Issue_Update_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Issue_Update_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.issue_update". All fields are combined with a logical 'AND'. */
export type Issue_Update_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentIssueId?: InputMaybe<Uuid_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Issue_Update_Bool_Exp>>;
  _not?: InputMaybe<Issue_Update_Bool_Exp>;
  _or?: InputMaybe<Array<Issue_Update_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  files?: InputMaybe<Relation_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Bool_Exp>;
  issue?: InputMaybe<Issue_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
};

/** update columns of table "risksmart.issue" */
export const Issue_Update_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  DateIdentified: 'DateIdentified',
  /** column name */
  DateOccurred: 'DateOccurred',
  /** column name */
  Details: 'Details',
  /** column name */
  ImpactsCustomer: 'ImpactsCustomer',
  /** column name */
  IsExternalIssue: 'IsExternalIssue',
  /** column name */
  Meta: 'Meta',
  /** column name */
  Title: 'Title'
} as const;

export type Issue_Update_Column = typeof Issue_Update_Column[keyof typeof Issue_Update_Column];
/** unique or primary key constraints on table "risksmart.issue_update" */
export const Issue_Update_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  IssueUpdatePkey: 'issue_update_pkey'
} as const;

export type Issue_Update_Constraint = typeof Issue_Update_Constraint[keyof typeof Issue_Update_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Issue_Update_Delete_At_Path_Input = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Issue_Update_Delete_Elem_Input = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Issue_Update_Delete_Key_Input = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.issue_update" */
export type Issue_Update_Insert_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Relation_File_Arr_Rel_Insert_Input>;
  issue?: InputMaybe<Issue_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "risksmart.issue_update" */
export type Issue_Update_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentIssueId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.issue_update" */
export type Issue_Update_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentIssueId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.issue_update" */
export type Issue_Update_Mutation_Response = {
  __typename?: 'issue_update_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Issue_Update>;
};

/** input type for inserting object relation for remote table "risksmart.issue_update" */
export type Issue_Update_Obj_Rel_Insert_Input = {
  data: Issue_Update_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Issue_Update_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.issue_update" */
export type Issue_Update_On_Conflict = {
  constraint: Issue_Update_Constraint;
  update_columns?: Array<Issue_Update_Update_Column>;
  where?: InputMaybe<Issue_Update_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.issue_update". */
export type Issue_Update_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentIssueId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Order_By>;
  issue?: InputMaybe<Issue_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
};

/** primary key columns input for table: risksmart.issue_update */
export type Issue_Update_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Issue_Update_Prepend_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.issue_update" */
export const Issue_Update_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentIssueId: 'ParentIssueId',
  /** column name */
  Title: 'Title'
} as const;

export type Issue_Update_Select_Column = typeof Issue_Update_Select_Column[keyof typeof Issue_Update_Select_Column];
/** input type for updating data in table "risksmart.issue_update" */
export type Issue_Update_Set_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "issue_update" */
export type Issue_Update_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Issue_Update_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Issue_Update_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.issue_update" */
export const Issue_Update_Update_Column = {
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ParentIssueId: 'ParentIssueId',
  /** column name */
  Title: 'Title'
} as const;

export type Issue_Update_Update_Column = typeof Issue_Update_Update_Column[keyof typeof Issue_Update_Update_Column];
export type Issue_Update_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Issue_Update_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Issue_Update_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Issue_Update_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Issue_Update_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Issue_Update_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Issue_Update_Set_Input>;
  /** filter the rows which have to be updated */
  where: Issue_Update_Bool_Exp;
};

export type Issue_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Issue_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Issue_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Issue_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Issue_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Issue_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Issue_Set_Input>;
  /** filter the rows which have to be updated */
  where: Issue_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Issue_Var_Pop_Fields = {
  __typename?: 'issue_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Issue_Var_Samp_Fields = {
  __typename?: 'issue_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Issue_Variance_Fields = {
  __typename?: 'issue_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']['input']>;
  _gt?: InputMaybe<Scalars['json']['input']>;
  _gte?: InputMaybe<Scalars['json']['input']>;
  _in?: InputMaybe<Array<Scalars['json']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['json']['input']>;
  _lte?: InputMaybe<Scalars['json']['input']>;
  _neq?: InputMaybe<Scalars['json']['input']>;
  _nin?: InputMaybe<Array<Scalars['json']['input']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** columns and relationships of "risksmart.linked_item" */
export type Linked_Item = {
  __typename?: 'linked_item';
  Id: Scalars['uuid']['output'];
  RelationshipType?: Maybe<Scalars['String']['output']>;
  Source: Scalars['uuid']['output'];
  Target: Scalars['uuid']['output'];
  /** An object relationship */
  source_acceptance?: Maybe<Acceptance>;
  /** An object relationship */
  source_action?: Maybe<Action>;
  /** An object relationship */
  source_appetite?: Maybe<Appetite>;
  /** An object relationship */
  source_assessment?: Maybe<Assessment>;
  /** An object relationship */
  source_assessment_activity?: Maybe<Assessment_Activity>;
  /** An object relationship */
  source_cause?: Maybe<Cause>;
  /** An object relationship */
  source_consequence?: Maybe<Consequence>;
  /** An object relationship */
  source_control?: Maybe<Control>;
  /** An object relationship */
  source_control_group?: Maybe<Control_Group>;
  /** An object relationship */
  source_document?: Maybe<Document>;
  /** An object relationship */
  source_impact?: Maybe<Impact>;
  /** An object relationship */
  source_indicator?: Maybe<Indicator>;
  /** An object relationship */
  source_internal_audit_entity?: Maybe<Internal_Audit_Entity>;
  /** An object relationship */
  source_internal_audit_report?: Maybe<Internal_Audit_Report>;
  /** An object relationship */
  source_issue?: Maybe<Issue>;
  /** An object relationship */
  source_obligation?: Maybe<Obligation>;
  /** An object relationship */
  source_risk?: Maybe<Risk>;
  /** An object relationship */
  source_third_party?: Maybe<Third_Party>;
  /** An object relationship */
  target_acceptance?: Maybe<Acceptance>;
  /** An object relationship */
  target_action?: Maybe<Action>;
  /** An object relationship */
  target_action_update?: Maybe<Action_Update>;
  /** An object relationship */
  target_appetite?: Maybe<Appetite>;
  /** An object relationship */
  target_assessment?: Maybe<Assessment>;
  /** An object relationship */
  target_assessment_activity?: Maybe<Assessment_Activity>;
  /** An object relationship */
  target_cause?: Maybe<Cause>;
  /** An object relationship */
  target_consequence?: Maybe<Consequence>;
  /** An object relationship */
  target_control?: Maybe<Control>;
  /** An object relationship */
  target_control_group?: Maybe<Control_Group>;
  /** An object relationship */
  target_document?: Maybe<Document>;
  /** An object relationship */
  target_impact?: Maybe<Impact>;
  /** An object relationship */
  target_impact_rating?: Maybe<Impact_Rating>;
  /** An object relationship */
  target_indicator?: Maybe<Indicator>;
  /** An object relationship */
  target_internal_audit_entity?: Maybe<Internal_Audit_Entity>;
  /** An object relationship */
  target_internal_audit_report?: Maybe<Internal_Audit_Report>;
  /** An object relationship */
  target_issue?: Maybe<Issue>;
  /** An object relationship */
  target_issue_update?: Maybe<Issue_Update>;
  /** An object relationship */
  target_obligation?: Maybe<Obligation>;
  /** An object relationship */
  target_obligation_impact?: Maybe<Obligation_Impact>;
  /** An object relationship */
  target_risk?: Maybe<Risk>;
  /** An object relationship */
  target_test_result?: Maybe<Test_Result>;
  /** An object relationship */
  target_third_party?: Maybe<Third_Party>;
};

/** Boolean expression to filter rows from the table "risksmart.linked_item". All fields are combined with a logical 'AND'. */
export type Linked_Item_Bool_Exp = {
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  RelationshipType?: InputMaybe<String_Comparison_Exp>;
  Source?: InputMaybe<Uuid_Comparison_Exp>;
  Target?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Linked_Item_Bool_Exp>>;
  _not?: InputMaybe<Linked_Item_Bool_Exp>;
  _or?: InputMaybe<Array<Linked_Item_Bool_Exp>>;
  source_acceptance?: InputMaybe<Acceptance_Bool_Exp>;
  source_action?: InputMaybe<Action_Bool_Exp>;
  source_appetite?: InputMaybe<Appetite_Bool_Exp>;
  source_assessment?: InputMaybe<Assessment_Bool_Exp>;
  source_assessment_activity?: InputMaybe<Assessment_Activity_Bool_Exp>;
  source_cause?: InputMaybe<Cause_Bool_Exp>;
  source_consequence?: InputMaybe<Consequence_Bool_Exp>;
  source_control?: InputMaybe<Control_Bool_Exp>;
  source_control_group?: InputMaybe<Control_Group_Bool_Exp>;
  source_document?: InputMaybe<Document_Bool_Exp>;
  source_impact?: InputMaybe<Impact_Bool_Exp>;
  source_indicator?: InputMaybe<Indicator_Bool_Exp>;
  source_internal_audit_entity?: InputMaybe<Internal_Audit_Entity_Bool_Exp>;
  source_internal_audit_report?: InputMaybe<Internal_Audit_Report_Bool_Exp>;
  source_issue?: InputMaybe<Issue_Bool_Exp>;
  source_obligation?: InputMaybe<Obligation_Bool_Exp>;
  source_risk?: InputMaybe<Risk_Bool_Exp>;
  source_third_party?: InputMaybe<Third_Party_Bool_Exp>;
  target_acceptance?: InputMaybe<Acceptance_Bool_Exp>;
  target_action?: InputMaybe<Action_Bool_Exp>;
  target_action_update?: InputMaybe<Action_Update_Bool_Exp>;
  target_appetite?: InputMaybe<Appetite_Bool_Exp>;
  target_assessment?: InputMaybe<Assessment_Bool_Exp>;
  target_assessment_activity?: InputMaybe<Assessment_Activity_Bool_Exp>;
  target_cause?: InputMaybe<Cause_Bool_Exp>;
  target_consequence?: InputMaybe<Consequence_Bool_Exp>;
  target_control?: InputMaybe<Control_Bool_Exp>;
  target_control_group?: InputMaybe<Control_Group_Bool_Exp>;
  target_document?: InputMaybe<Document_Bool_Exp>;
  target_impact?: InputMaybe<Impact_Bool_Exp>;
  target_impact_rating?: InputMaybe<Impact_Rating_Bool_Exp>;
  target_indicator?: InputMaybe<Indicator_Bool_Exp>;
  target_internal_audit_entity?: InputMaybe<Internal_Audit_Entity_Bool_Exp>;
  target_internal_audit_report?: InputMaybe<Internal_Audit_Report_Bool_Exp>;
  target_issue?: InputMaybe<Issue_Bool_Exp>;
  target_issue_update?: InputMaybe<Issue_Update_Bool_Exp>;
  target_obligation?: InputMaybe<Obligation_Bool_Exp>;
  target_obligation_impact?: InputMaybe<Obligation_Impact_Bool_Exp>;
  target_risk?: InputMaybe<Risk_Bool_Exp>;
  target_test_result?: InputMaybe<Test_Result_Bool_Exp>;
  target_third_party?: InputMaybe<Third_Party_Bool_Exp>;
};

/** response of any mutation on the table "risksmart.linked_item" */
export type Linked_Item_Mutation_Response = {
  __typename?: 'linked_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Linked_Item>;
};

/** Ordering options when selecting data from "risksmart.linked_item". */
export type Linked_Item_Order_By = {
  Id?: InputMaybe<Order_By>;
  RelationshipType?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  Target?: InputMaybe<Order_By>;
  source_acceptance?: InputMaybe<Acceptance_Order_By>;
  source_action?: InputMaybe<Action_Order_By>;
  source_appetite?: InputMaybe<Appetite_Order_By>;
  source_assessment?: InputMaybe<Assessment_Order_By>;
  source_assessment_activity?: InputMaybe<Assessment_Activity_Order_By>;
  source_cause?: InputMaybe<Cause_Order_By>;
  source_consequence?: InputMaybe<Consequence_Order_By>;
  source_control?: InputMaybe<Control_Order_By>;
  source_control_group?: InputMaybe<Control_Group_Order_By>;
  source_document?: InputMaybe<Document_Order_By>;
  source_impact?: InputMaybe<Impact_Order_By>;
  source_indicator?: InputMaybe<Indicator_Order_By>;
  source_internal_audit_entity?: InputMaybe<Internal_Audit_Entity_Order_By>;
  source_internal_audit_report?: InputMaybe<Internal_Audit_Report_Order_By>;
  source_issue?: InputMaybe<Issue_Order_By>;
  source_obligation?: InputMaybe<Obligation_Order_By>;
  source_risk?: InputMaybe<Risk_Order_By>;
  source_third_party?: InputMaybe<Third_Party_Order_By>;
  target_acceptance?: InputMaybe<Acceptance_Order_By>;
  target_action?: InputMaybe<Action_Order_By>;
  target_action_update?: InputMaybe<Action_Update_Order_By>;
  target_appetite?: InputMaybe<Appetite_Order_By>;
  target_assessment?: InputMaybe<Assessment_Order_By>;
  target_assessment_activity?: InputMaybe<Assessment_Activity_Order_By>;
  target_cause?: InputMaybe<Cause_Order_By>;
  target_consequence?: InputMaybe<Consequence_Order_By>;
  target_control?: InputMaybe<Control_Order_By>;
  target_control_group?: InputMaybe<Control_Group_Order_By>;
  target_document?: InputMaybe<Document_Order_By>;
  target_impact?: InputMaybe<Impact_Order_By>;
  target_impact_rating?: InputMaybe<Impact_Rating_Order_By>;
  target_indicator?: InputMaybe<Indicator_Order_By>;
  target_internal_audit_entity?: InputMaybe<Internal_Audit_Entity_Order_By>;
  target_internal_audit_report?: InputMaybe<Internal_Audit_Report_Order_By>;
  target_issue?: InputMaybe<Issue_Order_By>;
  target_issue_update?: InputMaybe<Issue_Update_Order_By>;
  target_obligation?: InputMaybe<Obligation_Order_By>;
  target_obligation_impact?: InputMaybe<Obligation_Impact_Order_By>;
  target_risk?: InputMaybe<Risk_Order_By>;
  target_test_result?: InputMaybe<Test_Result_Order_By>;
  target_third_party?: InputMaybe<Third_Party_Order_By>;
};

/** select columns of table "risksmart.linked_item" */
export const Linked_Item_Select_Column = {
  /** column name */
  Id: 'Id',
  /** column name */
  RelationshipType: 'RelationshipType',
  /** column name */
  Source: 'Source',
  /** column name */
  Target: 'Target'
} as const;

export type Linked_Item_Select_Column = typeof Linked_Item_Select_Column[keyof typeof Linked_Item_Select_Column];
/** Streaming cursor of the table "linked_item" */
export type Linked_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Linked_Item_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Linked_Item_Stream_Cursor_Value_Input = {
  Id?: InputMaybe<Scalars['uuid']['input']>;
  RelationshipType?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['uuid']['input']>;
  Target?: InputMaybe<Scalars['uuid']['input']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  attestRecord: GenericMutationOutput;
  dataImportStartImport?: Maybe<DataImportStartImportOutput>;
  dataImportValidate?: Maybe<DataImportValidateOutput>;
  deleteAcceptancesById: GenericMutationOutput;
  deleteActionsById: GenericMutationOutput;
  deleteControlsById: GenericMutationOutput;
  deleteDocumentById: GenericMutationOutput;
  deleteIssuesById: GenericMutationOutput;
  deleteRiskById: GenericMutationOutput;
  deleteUserGroups: GenericMutationOutput;
  /** delete data from the table: "risksmart.acceptance_parent" */
  delete_acceptance_parent?: Maybe<Acceptance_Parent_Mutation_Response>;
  /** delete single row from the table: "risksmart.acceptance_parent" */
  delete_acceptance_parent_by_pk?: Maybe<Acceptance_Parent>;
  /** delete data from the table: "risksmart.action_parent" */
  delete_action_parent?: Maybe<Action_Parent_Mutation_Response>;
  /** delete single row from the table: "risksmart.action_parent" */
  delete_action_parent_by_pk?: Maybe<Action_Parent>;
  /** delete data from the table: "risksmart.action_update" */
  delete_action_update?: Maybe<Action_Update_Mutation_Response>;
  /** delete single row from the table: "risksmart.action_update" */
  delete_action_update_by_pk?: Maybe<Action_Update>;
  /** delete data from the table: "risksmart.appetite" */
  delete_appetite?: Maybe<Appetite_Mutation_Response>;
  /** delete single row from the table: "risksmart.appetite" */
  delete_appetite_by_pk?: Maybe<Appetite>;
  /** delete data from the table: "risksmart.appetite_parent" */
  delete_appetite_parent?: Maybe<Appetite_Parent_Mutation_Response>;
  /** delete single row from the table: "risksmart.appetite_parent" */
  delete_appetite_parent_by_pk?: Maybe<Appetite_Parent>;
  /** delete data from the table: "risksmart.approval" */
  delete_approval?: Maybe<Approval_Mutation_Response>;
  /** delete single row from the table: "risksmart.approval" */
  delete_approval_by_pk?: Maybe<Approval>;
  /** delete data from the table: "risksmart.approval_level" */
  delete_approval_level?: Maybe<Approval_Level_Mutation_Response>;
  /** delete single row from the table: "risksmart.approval_level" */
  delete_approval_level_by_pk?: Maybe<Approval_Level>;
  /** delete data from the table: "risksmart.approver" */
  delete_approver?: Maybe<Approver_Mutation_Response>;
  /** delete single row from the table: "risksmart.approver" */
  delete_approver_by_pk?: Maybe<Approver>;
  /** delete data from the table: "risksmart.assessment" */
  delete_assessment?: Maybe<Assessment_Mutation_Response>;
  /** delete data from the table: "risksmart.assessment_activity" */
  delete_assessment_activity?: Maybe<Assessment_Activity_Mutation_Response>;
  /** delete single row from the table: "risksmart.assessment_activity" */
  delete_assessment_activity_by_pk?: Maybe<Assessment_Activity>;
  /** delete single row from the table: "risksmart.assessment" */
  delete_assessment_by_pk?: Maybe<Assessment>;
  /** delete data from the table: "risksmart.assessment_result_parent" */
  delete_assessment_result_parent?: Maybe<Assessment_Result_Parent_Mutation_Response>;
  /** delete single row from the table: "risksmart.assessment_result_parent" */
  delete_assessment_result_parent_by_pk?: Maybe<Assessment_Result_Parent>;
  /** delete data from the table: "risksmart.attestation_config" */
  delete_attestation_config?: Maybe<Attestation_Config_Mutation_Response>;
  /** delete single row from the table: "risksmart.attestation_config" */
  delete_attestation_config_by_pk?: Maybe<Attestation_Config>;
  /** delete data from the table: "risksmart.attestation_group" */
  delete_attestation_group?: Maybe<Attestation_Group_Mutation_Response>;
  /** delete single row from the table: "risksmart.attestation_group" */
  delete_attestation_group_by_pk?: Maybe<Attestation_Group>;
  /** delete data from the table: "risksmart.business_area" */
  delete_business_area?: Maybe<Business_Area_Mutation_Response>;
  /** delete single row from the table: "risksmart.business_area" */
  delete_business_area_by_pk?: Maybe<Business_Area>;
  /** delete data from the table: "risksmart.cause" */
  delete_cause?: Maybe<Cause_Mutation_Response>;
  /** delete single row from the table: "risksmart.cause" */
  delete_cause_by_pk?: Maybe<Cause>;
  /** delete data from the table: "risksmart.comment" */
  delete_comment?: Maybe<Comment_Mutation_Response>;
  /** delete single row from the table: "risksmart.comment" */
  delete_comment_by_pk?: Maybe<Comment>;
  /** delete data from the table: "risksmart.compliance_monitoring_assessment" */
  delete_compliance_monitoring_assessment?: Maybe<Compliance_Monitoring_Assessment_Mutation_Response>;
  /** delete single row from the table: "risksmart.compliance_monitoring_assessment" */
  delete_compliance_monitoring_assessment_by_pk?: Maybe<Compliance_Monitoring_Assessment>;
  /** delete data from the table: "risksmart.consequence" */
  delete_consequence?: Maybe<Consequence_Mutation_Response>;
  /** delete single row from the table: "risksmart.consequence" */
  delete_consequence_by_pk?: Maybe<Consequence>;
  /** delete data from the table: "risksmart.contributor" */
  delete_contributor?: Maybe<Contributor_Mutation_Response>;
  /** delete single row from the table: "risksmart.contributor" */
  delete_contributor_by_pk?: Maybe<Contributor>;
  /** delete data from the table: "risksmart.contributor_group" */
  delete_contributor_group?: Maybe<Contributor_Group_Mutation_Response>;
  /** delete single row from the table: "risksmart.contributor_group" */
  delete_contributor_group_by_pk?: Maybe<Contributor_Group>;
  /** delete data from the table: "risksmart.control_group" */
  delete_control_group?: Maybe<Control_Group_Mutation_Response>;
  /** delete single row from the table: "risksmart.control_group" */
  delete_control_group_by_pk?: Maybe<Control_Group>;
  /** delete data from the table: "risksmart.control_parent" */
  delete_control_parent?: Maybe<Control_Parent_Mutation_Response>;
  /** delete single row from the table: "risksmart.control_parent" */
  delete_control_parent_by_pk?: Maybe<Control_Parent>;
  /** delete data from the table: "risksmart.conversation" */
  delete_conversation?: Maybe<Conversation_Mutation_Response>;
  /** delete single row from the table: "risksmart.conversation" */
  delete_conversation_by_pk?: Maybe<Conversation>;
  /** delete data from the table: "risksmart.custom_attribute_schema" */
  delete_custom_attribute_schema?: Maybe<Custom_Attribute_Schema_Mutation_Response>;
  /** delete single row from the table: "risksmart.custom_attribute_schema" */
  delete_custom_attribute_schema_by_pk?: Maybe<Custom_Attribute_Schema>;
  /** delete data from the table: "risksmart.dashboard" */
  delete_dashboard?: Maybe<Dashboard_Mutation_Response>;
  /** delete single row from the table: "risksmart.dashboard" */
  delete_dashboard_by_pk?: Maybe<Dashboard>;
  /** delete data from the table: "risksmart.data_import" */
  delete_data_import?: Maybe<Data_Import_Mutation_Response>;
  /** delete single row from the table: "risksmart.data_import" */
  delete_data_import_by_pk?: Maybe<Data_Import>;
  /** delete data from the table: "risksmart.data_import_error" */
  delete_data_import_error?: Maybe<Data_Import_Error_Mutation_Response>;
  /** delete single row from the table: "risksmart.data_import_error" */
  delete_data_import_error_by_pk?: Maybe<Data_Import_Error>;
  /** delete data from the table: "risksmart.department" */
  delete_department?: Maybe<Department_Mutation_Response>;
  /** delete single row from the table: "risksmart.department" */
  delete_department_by_pk?: Maybe<Department>;
  /** delete data from the table: "risksmart.department_type" */
  delete_department_type?: Maybe<Department_Type_Mutation_Response>;
  /** delete single row from the table: "risksmart.department_type" */
  delete_department_type_by_pk?: Maybe<Department_Type>;
  /** delete data from the table: "risksmart.document_assessment_result" */
  delete_document_assessment_result?: Maybe<Document_Assessment_Result_Mutation_Response>;
  /** delete single row from the table: "risksmart.document_assessment_result" */
  delete_document_assessment_result_by_pk?: Maybe<Document_Assessment_Result>;
  /** delete data from the table: "risksmart.document_file" */
  delete_document_file?: Maybe<Document_File_Mutation_Response>;
  /** delete single row from the table: "risksmart.document_file" */
  delete_document_file_by_pk?: Maybe<Document_File>;
  /** delete data from the table: "risksmart.document_linked_document" */
  delete_document_linked_document?: Maybe<Document_Linked_Document_Mutation_Response>;
  /** delete single row from the table: "risksmart.document_linked_document" */
  delete_document_linked_document_by_pk?: Maybe<Document_Linked_Document>;
  /** delete data from the table: "risksmart.file" */
  delete_file?: Maybe<File_Mutation_Response>;
  /** delete single row from the table: "risksmart.file" */
  delete_file_by_pk?: Maybe<File>;
  /** delete data from the table: "risksmart.form_configuration" */
  delete_form_configuration?: Maybe<Form_Configuration_Mutation_Response>;
  /** delete data from the table: "risksmart.form_field_configuration" */
  delete_form_field_configuration?: Maybe<Form_Field_Configuration_Mutation_Response>;
  /** delete data from the table: "risksmart.form_field_ordering" */
  delete_form_field_ordering?: Maybe<Form_Field_Ordering_Mutation_Response>;
  /** delete data from the table: "risksmart.impact" */
  delete_impact?: Maybe<Impact_Mutation_Response>;
  /** delete single row from the table: "risksmart.impact" */
  delete_impact_by_pk?: Maybe<Impact>;
  /** delete data from the table: "risksmart.impact_rating" */
  delete_impact_rating?: Maybe<Impact_Rating_Mutation_Response>;
  /** delete single row from the table: "risksmart.impact_rating" */
  delete_impact_rating_by_pk?: Maybe<Impact_Rating>;
  /** delete data from the table: "risksmart.indicator" */
  delete_indicator?: Maybe<Indicator_Mutation_Response>;
  /** delete single row from the table: "risksmart.indicator" */
  delete_indicator_by_pk?: Maybe<Indicator>;
  /** delete data from the table: "risksmart.indicator_parent" */
  delete_indicator_parent?: Maybe<Indicator_Parent_Mutation_Response>;
  /** delete single row from the table: "risksmart.indicator_parent" */
  delete_indicator_parent_by_pk?: Maybe<Indicator_Parent>;
  /** delete data from the table: "risksmart.indicator_result" */
  delete_indicator_result?: Maybe<Indicator_Result_Mutation_Response>;
  /** delete single row from the table: "risksmart.indicator_result" */
  delete_indicator_result_by_pk?: Maybe<Indicator_Result>;
  /** delete data from the table: "risksmart.internal_audit_entity" */
  delete_internal_audit_entity?: Maybe<Internal_Audit_Entity_Mutation_Response>;
  /** delete single row from the table: "risksmart.internal_audit_entity" */
  delete_internal_audit_entity_by_pk?: Maybe<Internal_Audit_Entity>;
  /** delete data from the table: "risksmart.internal_audit_report" */
  delete_internal_audit_report?: Maybe<Internal_Audit_Report_Mutation_Response>;
  /** delete single row from the table: "risksmart.internal_audit_report" */
  delete_internal_audit_report_by_pk?: Maybe<Internal_Audit_Report>;
  /** delete data from the table: "risksmart.issue_update" */
  delete_issue_update?: Maybe<Issue_Update_Mutation_Response>;
  /** delete single row from the table: "risksmart.issue_update" */
  delete_issue_update_by_pk?: Maybe<Issue_Update>;
  /** delete data from the table: "risksmart.linked_item" */
  delete_linked_item?: Maybe<Linked_Item_Mutation_Response>;
  /** delete single row from the table: "risksmart.linked_item" */
  delete_linked_item_by_pk?: Maybe<Linked_Item>;
  /** delete data from the table: "risksmart.obligation" */
  delete_obligation?: Maybe<Obligation_Mutation_Response>;
  /** delete data from the table: "risksmart.obligation_assessment_result" */
  delete_obligation_assessment_result?: Maybe<Obligation_Assessment_Result_Mutation_Response>;
  /** delete single row from the table: "risksmart.obligation_assessment_result" */
  delete_obligation_assessment_result_by_pk?: Maybe<Obligation_Assessment_Result>;
  /** delete single row from the table: "risksmart.obligation" */
  delete_obligation_by_pk?: Maybe<Obligation>;
  /** delete data from the table: "risksmart.obligation_impact" */
  delete_obligation_impact?: Maybe<Obligation_Impact_Mutation_Response>;
  /** delete single row from the table: "risksmart.obligation_impact" */
  delete_obligation_impact_by_pk?: Maybe<Obligation_Impact>;
  /** delete data from the table: "risksmart.owner" */
  delete_owner?: Maybe<Owner_Mutation_Response>;
  /** delete single row from the table: "risksmart.owner" */
  delete_owner_by_pk?: Maybe<Owner>;
  /** delete data from the table: "risksmart.owner_group" */
  delete_owner_group?: Maybe<Owner_Group_Mutation_Response>;
  /** delete single row from the table: "risksmart.owner_group" */
  delete_owner_group_by_pk?: Maybe<Owner_Group>;
  /** delete data from the table: "risksmart.questionnaire_invite" */
  delete_questionnaire_invite?: Maybe<Questionnaire_Invite_Mutation_Response>;
  /** delete single row from the table: "risksmart.questionnaire_invite" */
  delete_questionnaire_invite_by_pk?: Maybe<Questionnaire_Invite>;
  /** delete data from the table: "risksmart.questionnaire_template" */
  delete_questionnaire_template?: Maybe<Questionnaire_Template_Mutation_Response>;
  /** delete single row from the table: "risksmart.questionnaire_template" */
  delete_questionnaire_template_by_pk?: Maybe<Questionnaire_Template>;
  /** delete data from the table: "risksmart.questionnaire_template_version" */
  delete_questionnaire_template_version?: Maybe<Questionnaire_Template_Version_Mutation_Response>;
  /** delete single row from the table: "risksmart.questionnaire_template_version" */
  delete_questionnaire_template_version_by_pk?: Maybe<Questionnaire_Template_Version>;
  /** delete data from the table: "risksmart.relation_file" */
  delete_relation_file?: Maybe<Relation_File_Mutation_Response>;
  /** delete single row from the table: "risksmart.relation_file" */
  delete_relation_file_by_pk?: Maybe<Relation_File>;
  /** delete data from the table: "risksmart.risk_assessment_result" */
  delete_risk_assessment_result?: Maybe<Risk_Assessment_Result_Mutation_Response>;
  /** delete single row from the table: "risksmart.risk_assessment_result" */
  delete_risk_assessment_result_by_pk?: Maybe<Risk_Assessment_Result>;
  /** delete data from the table: "risksmart.tag" */
  delete_tag?: Maybe<Tag_Mutation_Response>;
  /** delete single row from the table: "risksmart.tag" */
  delete_tag_by_pk?: Maybe<Tag>;
  /** delete data from the table: "risksmart.tag_type" */
  delete_tag_type?: Maybe<Tag_Type_Mutation_Response>;
  /** delete single row from the table: "risksmart.tag_type" */
  delete_tag_type_by_pk?: Maybe<Tag_Type>;
  /** delete data from the table: "risksmart.taxonomy" */
  delete_taxonomy?: Maybe<Taxonomy_Mutation_Response>;
  /** delete single row from the table: "risksmart.taxonomy" */
  delete_taxonomy_by_pk?: Maybe<Taxonomy>;
  /** delete data from the table: "risksmart.taxonomy_org" */
  delete_taxonomy_org?: Maybe<Taxonomy_Org_Mutation_Response>;
  /** delete single row from the table: "risksmart.taxonomy_org" */
  delete_taxonomy_org_by_pk?: Maybe<Taxonomy_Org>;
  /** delete data from the table: "risksmart.test_result" */
  delete_test_result?: Maybe<Test_Result_Mutation_Response>;
  /** delete single row from the table: "risksmart.test_result" */
  delete_test_result_by_pk?: Maybe<Test_Result>;
  /** delete data from the table: "risksmart.third_party" */
  delete_third_party?: Maybe<Third_Party_Mutation_Response>;
  /** delete single row from the table: "risksmart.third_party" */
  delete_third_party_by_pk?: Maybe<Third_Party>;
  /** delete data from the table: "risksmart.third_party_response" */
  delete_third_party_response?: Maybe<Third_Party_Response_Mutation_Response>;
  /** delete single row from the table: "risksmart.third_party_response" */
  delete_third_party_response_by_pk?: Maybe<Third_Party_Response>;
  /** delete data from the table: "risksmart.user_group_user" */
  delete_user_group_user?: Maybe<User_Group_User_Mutation_Response>;
  /** delete single row from the table: "risksmart.user_group_user" */
  delete_user_group_user_by_pk?: Maybe<User_Group_User>;
  disconnectSlack: DisconnectSlackOutput;
  insertAssessmentActivityWithLinkedItems?: Maybe<IdOutput>;
  insertChildAcceptance?: Maybe<IdOutput>;
  insertChildAction?: Maybe<IdOutput>;
  insertChildAppetite?: Maybe<IdOutput>;
  insertChildControl?: Maybe<IdOutput>;
  insertChildDashboard?: Maybe<IdOutput>;
  /** insertChildDocument */
  insertChildDocument?: Maybe<IdOutput>;
  insertChildDocumentAssessmentResult?: Maybe<InsertChildDocumentAssessmentResultOutput>;
  insertChildImpact?: Maybe<IdOutput>;
  insertChildImpactRating?: Maybe<InsertChildImpactRatingOutput>;
  insertChildIndicator?: Maybe<IdOutput>;
  insertChildIssue?: Maybe<IdOutput>;
  insertChildIssueAssessment?: Maybe<IdOutput>;
  /** insertChildObligation */
  insertChildObligation?: Maybe<IdOutput>;
  insertChildObligationAssessmentResult?: Maybe<InsertChildObligationAssessmentResultOutput>;
  /** insertChildRisk */
  insertChildRisk?: Maybe<IdOutput>;
  insertChildRiskAssessmentResult?: Maybe<InsertChildRiskAssessmentResultOutput>;
  insertControlTestResult?: Maybe<InsertControlTestResultOutput>;
  insertDocumentVersion?: Maybe<IdOutput>;
  insertInternalAudit?: Maybe<IdOutput>;
  insertQuestionnaireInvites: GenericMutationOutput;
  insertReferenceUser?: Maybe<IdOutput>;
  /** insert data into the table: "risksmart.action_update" */
  insert_action_update?: Maybe<Action_Update_Mutation_Response>;
  /** insert a single row into the table: "risksmart.action_update" */
  insert_action_update_one?: Maybe<Action_Update>;
  /** insert data into the table: "risksmart.approval" */
  insert_approval?: Maybe<Approval_Mutation_Response>;
  /** insert data into the table: "risksmart.approval_level" */
  insert_approval_level?: Maybe<Approval_Level_Mutation_Response>;
  /** insert a single row into the table: "risksmart.approval_level" */
  insert_approval_level_one?: Maybe<Approval_Level>;
  /** insert a single row into the table: "risksmart.approval" */
  insert_approval_one?: Maybe<Approval>;
  /** insert data into the table: "risksmart.approver" */
  insert_approver?: Maybe<Approver_Mutation_Response>;
  /** insert a single row into the table: "risksmart.approver" */
  insert_approver_one?: Maybe<Approver>;
  /** insert data into the table: "risksmart.assessment" */
  insert_assessment?: Maybe<Assessment_Mutation_Response>;
  /** insert data into the table: "risksmart.assessment_activity" */
  insert_assessment_activity?: Maybe<Assessment_Activity_Mutation_Response>;
  /** insert a single row into the table: "risksmart.assessment_activity" */
  insert_assessment_activity_one?: Maybe<Assessment_Activity>;
  /** insert a single row into the table: "risksmart.assessment" */
  insert_assessment_one?: Maybe<Assessment>;
  /** insert data into the table: "risksmart.attestation_config" */
  insert_attestation_config?: Maybe<Attestation_Config_Mutation_Response>;
  /** insert a single row into the table: "risksmart.attestation_config" */
  insert_attestation_config_one?: Maybe<Attestation_Config>;
  /** insert data into the table: "risksmart.attestation_group" */
  insert_attestation_group?: Maybe<Attestation_Group_Mutation_Response>;
  /** insert a single row into the table: "risksmart.attestation_group" */
  insert_attestation_group_one?: Maybe<Attestation_Group>;
  /** insert data into the table: "risksmart.business_area" */
  insert_business_area?: Maybe<Business_Area_Mutation_Response>;
  /** insert a single row into the table: "risksmart.business_area" */
  insert_business_area_one?: Maybe<Business_Area>;
  /** insert data into the table: "risksmart.cause" */
  insert_cause?: Maybe<Cause_Mutation_Response>;
  /** insert a single row into the table: "risksmart.cause" */
  insert_cause_one?: Maybe<Cause>;
  /** insert data into the table: "risksmart.comment" */
  insert_comment?: Maybe<Comment_Mutation_Response>;
  /** insert a single row into the table: "risksmart.comment" */
  insert_comment_one?: Maybe<Comment>;
  /** insert data into the table: "risksmart.compliance_monitoring_assessment" */
  insert_compliance_monitoring_assessment?: Maybe<Compliance_Monitoring_Assessment_Mutation_Response>;
  /** insert a single row into the table: "risksmart.compliance_monitoring_assessment" */
  insert_compliance_monitoring_assessment_one?: Maybe<Compliance_Monitoring_Assessment>;
  /** insert data into the table: "risksmart.consequence" */
  insert_consequence?: Maybe<Consequence_Mutation_Response>;
  /** insert a single row into the table: "risksmart.consequence" */
  insert_consequence_one?: Maybe<Consequence>;
  /** insert data into the table: "risksmart.contributor" */
  insert_contributor?: Maybe<Contributor_Mutation_Response>;
  /** insert data into the table: "risksmart.contributor_group" */
  insert_contributor_group?: Maybe<Contributor_Group_Mutation_Response>;
  /** insert a single row into the table: "risksmart.contributor_group" */
  insert_contributor_group_one?: Maybe<Contributor_Group>;
  /** insert a single row into the table: "risksmart.contributor" */
  insert_contributor_one?: Maybe<Contributor>;
  /** insert data into the table: "risksmart.control" */
  insert_control?: Maybe<Control_Mutation_Response>;
  /** insert data into the table: "risksmart.control_group" */
  insert_control_group?: Maybe<Control_Group_Mutation_Response>;
  /** insert a single row into the table: "risksmart.control_group" */
  insert_control_group_one?: Maybe<Control_Group>;
  /** insert a single row into the table: "risksmart.control" */
  insert_control_one?: Maybe<Control>;
  /** insert data into the table: "risksmart.control_parent" */
  insert_control_parent?: Maybe<Control_Parent_Mutation_Response>;
  /** insert a single row into the table: "risksmart.control_parent" */
  insert_control_parent_one?: Maybe<Control_Parent>;
  /** insert data into the table: "risksmart.conversation" */
  insert_conversation?: Maybe<Conversation_Mutation_Response>;
  /** insert a single row into the table: "risksmart.conversation" */
  insert_conversation_one?: Maybe<Conversation>;
  /** insert data into the table: "risksmart.custom_attribute_schema" */
  insert_custom_attribute_schema?: Maybe<Custom_Attribute_Schema_Mutation_Response>;
  /** insert a single row into the table: "risksmart.custom_attribute_schema" */
  insert_custom_attribute_schema_one?: Maybe<Custom_Attribute_Schema>;
  /** insert data into the table: "risksmart.custom_ribbon" */
  insert_custom_ribbon?: Maybe<Custom_Ribbon_Mutation_Response>;
  /** insert a single row into the table: "risksmart.custom_ribbon" */
  insert_custom_ribbon_one?: Maybe<Custom_Ribbon>;
  /** insert data into the table: "risksmart.data_import" */
  insert_data_import?: Maybe<Data_Import_Mutation_Response>;
  /** insert data into the table: "risksmart.data_import_error" */
  insert_data_import_error?: Maybe<Data_Import_Error_Mutation_Response>;
  /** insert a single row into the table: "risksmart.data_import_error" */
  insert_data_import_error_one?: Maybe<Data_Import_Error>;
  /** insert a single row into the table: "risksmart.data_import" */
  insert_data_import_one?: Maybe<Data_Import>;
  /** insert data into the table: "risksmart.department" */
  insert_department?: Maybe<Department_Mutation_Response>;
  /** insert a single row into the table: "risksmart.department" */
  insert_department_one?: Maybe<Department>;
  /** insert data into the table: "risksmart.department_type" */
  insert_department_type?: Maybe<Department_Type_Mutation_Response>;
  /** insert data into the table: "risksmart.department_type_group" */
  insert_department_type_group?: Maybe<Department_Type_Group_Mutation_Response>;
  /** insert a single row into the table: "risksmart.department_type_group" */
  insert_department_type_group_one?: Maybe<Department_Type_Group>;
  /** insert a single row into the table: "risksmart.department_type" */
  insert_department_type_one?: Maybe<Department_Type>;
  /** insert data into the table: "risksmart.document_linked_document" */
  insert_document_linked_document?: Maybe<Document_Linked_Document_Mutation_Response>;
  /** insert a single row into the table: "risksmart.document_linked_document" */
  insert_document_linked_document_one?: Maybe<Document_Linked_Document>;
  /** insert data into the table: "risksmart.file" */
  insert_file?: Maybe<File_Mutation_Response>;
  /** insert a single row into the table: "risksmart.file" */
  insert_file_one?: Maybe<File>;
  /** insert data into the table: "risksmart.form_configuration" */
  insert_form_configuration?: Maybe<Form_Configuration_Mutation_Response>;
  /** insert a single row into the table: "risksmart.form_configuration" */
  insert_form_configuration_one?: Maybe<Form_Configuration>;
  /** insert data into the table: "risksmart.form_field_configuration" */
  insert_form_field_configuration?: Maybe<Form_Field_Configuration_Mutation_Response>;
  /** insert a single row into the table: "risksmart.form_field_configuration" */
  insert_form_field_configuration_one?: Maybe<Form_Field_Configuration>;
  /** insert data into the table: "risksmart.form_field_ordering" */
  insert_form_field_ordering?: Maybe<Form_Field_Ordering_Mutation_Response>;
  /** insert a single row into the table: "risksmart.form_field_ordering" */
  insert_form_field_ordering_one?: Maybe<Form_Field_Ordering>;
  /** insert data into the table: "risksmart.indicator_parent" */
  insert_indicator_parent?: Maybe<Indicator_Parent_Mutation_Response>;
  /** insert a single row into the table: "risksmart.indicator_parent" */
  insert_indicator_parent_one?: Maybe<Indicator_Parent>;
  /** insert data into the table: "risksmart.indicator_result" */
  insert_indicator_result?: Maybe<Indicator_Result_Mutation_Response>;
  /** insert a single row into the table: "risksmart.indicator_result" */
  insert_indicator_result_one?: Maybe<Indicator_Result>;
  /** insert data into the table: "risksmart.internal_audit_report" */
  insert_internal_audit_report?: Maybe<Internal_Audit_Report_Mutation_Response>;
  /** insert a single row into the table: "risksmart.internal_audit_report" */
  insert_internal_audit_report_one?: Maybe<Internal_Audit_Report>;
  /** insert data into the table: "risksmart.issue" */
  insert_issue?: Maybe<Issue_Mutation_Response>;
  /** insert a single row into the table: "risksmart.issue" */
  insert_issue_one?: Maybe<Issue>;
  /** insert data into the table: "risksmart.issue_update" */
  insert_issue_update?: Maybe<Issue_Update_Mutation_Response>;
  /** insert a single row into the table: "risksmart.issue_update" */
  insert_issue_update_one?: Maybe<Issue_Update>;
  /** insert data into the table: "risksmart.obligation_impact" */
  insert_obligation_impact?: Maybe<Obligation_Impact_Mutation_Response>;
  /** insert a single row into the table: "risksmart.obligation_impact" */
  insert_obligation_impact_one?: Maybe<Obligation_Impact>;
  /** insert data into the table: "risksmart.owner" */
  insert_owner?: Maybe<Owner_Mutation_Response>;
  /** insert data into the table: "risksmart.owner_group" */
  insert_owner_group?: Maybe<Owner_Group_Mutation_Response>;
  /** insert a single row into the table: "risksmart.owner_group" */
  insert_owner_group_one?: Maybe<Owner_Group>;
  /** insert a single row into the table: "risksmart.owner" */
  insert_owner_one?: Maybe<Owner>;
  /** insert data into the table: "risksmart.questionnaire_invite" */
  insert_questionnaire_invite?: Maybe<Questionnaire_Invite_Mutation_Response>;
  /** insert a single row into the table: "risksmart.questionnaire_invite" */
  insert_questionnaire_invite_one?: Maybe<Questionnaire_Invite>;
  /** insert data into the table: "risksmart.questionnaire_template" */
  insert_questionnaire_template?: Maybe<Questionnaire_Template_Mutation_Response>;
  /** insert a single row into the table: "risksmart.questionnaire_template" */
  insert_questionnaire_template_one?: Maybe<Questionnaire_Template>;
  /** insert data into the table: "risksmart.questionnaire_template_version" */
  insert_questionnaire_template_version?: Maybe<Questionnaire_Template_Version_Mutation_Response>;
  /** insert a single row into the table: "risksmart.questionnaire_template_version" */
  insert_questionnaire_template_version_one?: Maybe<Questionnaire_Template_Version>;
  /** insert data into the table: "risksmart.relation_file" */
  insert_relation_file?: Maybe<Relation_File_Mutation_Response>;
  /** insert a single row into the table: "risksmart.relation_file" */
  insert_relation_file_one?: Maybe<Relation_File>;
  /** insert data into the table: "risksmart.tag" */
  insert_tag?: Maybe<Tag_Mutation_Response>;
  /** insert a single row into the table: "risksmart.tag" */
  insert_tag_one?: Maybe<Tag>;
  /** insert data into the table: "risksmart.tag_type" */
  insert_tag_type?: Maybe<Tag_Type_Mutation_Response>;
  /** insert data into the table: "risksmart.tag_type_group" */
  insert_tag_type_group?: Maybe<Tag_Type_Group_Mutation_Response>;
  /** insert a single row into the table: "risksmart.tag_type_group" */
  insert_tag_type_group_one?: Maybe<Tag_Type_Group>;
  /** insert a single row into the table: "risksmart.tag_type" */
  insert_tag_type_one?: Maybe<Tag_Type>;
  /** insert data into the table: "risksmart.taxonomy" */
  insert_taxonomy?: Maybe<Taxonomy_Mutation_Response>;
  /** insert a single row into the table: "risksmart.taxonomy" */
  insert_taxonomy_one?: Maybe<Taxonomy>;
  /** insert data into the table: "risksmart.taxonomy_org" */
  insert_taxonomy_org?: Maybe<Taxonomy_Org_Mutation_Response>;
  /** insert a single row into the table: "risksmart.taxonomy_org" */
  insert_taxonomy_org_one?: Maybe<Taxonomy_Org>;
  /** insert data into the table: "risksmart.third_party" */
  insert_third_party?: Maybe<Third_Party_Mutation_Response>;
  /** insert a single row into the table: "risksmart.third_party" */
  insert_third_party_one?: Maybe<Third_Party>;
  /** insert data into the table: "risksmart.third_party_response" */
  insert_third_party_response?: Maybe<Third_Party_Response_Mutation_Response>;
  /** insert a single row into the table: "risksmart.third_party_response" */
  insert_third_party_response_one?: Maybe<Third_Party_Response>;
  /** insert data into the table: "risksmart.user_group" */
  insert_user_group?: Maybe<User_Group_Mutation_Response>;
  /** insert a single row into the table: "risksmart.user_group" */
  insert_user_group_one?: Maybe<User_Group>;
  /** insert data into the table: "risksmart.user_group_user" */
  insert_user_group_user?: Maybe<User_Group_User_Mutation_Response>;
  /** insert a single row into the table: "risksmart.user_group_user" */
  insert_user_group_user_one?: Maybe<User_Group_User>;
  /** insert data into the table: "risksmart.user_search_preferences" */
  insert_user_search_preferences?: Maybe<User_Search_Preferences_Mutation_Response>;
  /** insert a single row into the table: "risksmart.user_search_preferences" */
  insert_user_search_preferences_one?: Maybe<User_Search_Preferences>;
  /** insert data into the table: "risksmart.user_table_preferences" */
  insert_user_table_preferences?: Maybe<User_Table_Preferences_Mutation_Response>;
  /** insert a single row into the table: "risksmart.user_table_preferences" */
  insert_user_table_preferences_one?: Maybe<User_Table_Preferences>;
  linkItems?: Maybe<LinkItemsOutput>;
  overrideChangeRequest: IdOutput;
  publishQuestionnaireTemplateVersion: GenericMutationOutput;
  recalculateAppetites: RecalculateAppetitesOutput;
  recalculateRiskScores: RecalculateRiskScoresOutput;
  recallQuestionnaireInvites: GenericMutationOutput;
  unlinkItems?: Maybe<UnlinkItemsOutput>;
  updateApproverResponses?: Maybe<IdOutput>;
  updateAssessmentActivityWithLinkedItems?: Maybe<IdOutput>;
  updateChildAcceptance: GenericMutationOutput;
  updateChildAction?: Maybe<GenericMutationOutput>;
  updateChildControl?: Maybe<GenericMutationOutput>;
  updateChildDashboard?: Maybe<IdOutput>;
  updateChildDocument?: Maybe<IdOutput>;
  updateChildIndicator?: Maybe<IdOutput>;
  updateChildIssueAssessment?: Maybe<IdOutput>;
  updateChildObligation?: Maybe<IdOutput>;
  updateChildRisk: IdOutput;
  updateChildRiskAssessmentResult?: Maybe<GenericMutationOutput>;
  updateDocumentVersion?: Maybe<GenericMutationOutput>;
  updateInternalAudit?: Maybe<IdOutput>;
  updateNotificationPreferences: UpdateNotificationPreferencesOutput;
  updateTestResultApi?: Maybe<IdOutput>;
  /** update data of the table: "risksmart.action_update" */
  update_action_update?: Maybe<Action_Update_Mutation_Response>;
  /** update single row of the table: "risksmart.action_update" */
  update_action_update_by_pk?: Maybe<Action_Update>;
  /** update multiples rows of table: "risksmart.action_update" */
  update_action_update_many?: Maybe<Array<Maybe<Action_Update_Mutation_Response>>>;
  /** update data of the table: "risksmart.appetite" */
  update_appetite?: Maybe<Appetite_Mutation_Response>;
  /** update single row of the table: "risksmart.appetite" */
  update_appetite_by_pk?: Maybe<Appetite>;
  /** update multiples rows of table: "risksmart.appetite" */
  update_appetite_many?: Maybe<Array<Maybe<Appetite_Mutation_Response>>>;
  /** update data of the table: "risksmart.approval" */
  update_approval?: Maybe<Approval_Mutation_Response>;
  /** update single row of the table: "risksmart.approval" */
  update_approval_by_pk?: Maybe<Approval>;
  /** update data of the table: "risksmart.approval_level" */
  update_approval_level?: Maybe<Approval_Level_Mutation_Response>;
  /** update single row of the table: "risksmart.approval_level" */
  update_approval_level_by_pk?: Maybe<Approval_Level>;
  /** update multiples rows of table: "risksmart.approval_level" */
  update_approval_level_many?: Maybe<Array<Maybe<Approval_Level_Mutation_Response>>>;
  /** update multiples rows of table: "risksmart.approval" */
  update_approval_many?: Maybe<Array<Maybe<Approval_Mutation_Response>>>;
  /** update data of the table: "risksmart.approver" */
  update_approver?: Maybe<Approver_Mutation_Response>;
  /** update single row of the table: "risksmart.approver" */
  update_approver_by_pk?: Maybe<Approver>;
  /** update multiples rows of table: "risksmart.approver" */
  update_approver_many?: Maybe<Array<Maybe<Approver_Mutation_Response>>>;
  /** update data of the table: "risksmart.assessment" */
  update_assessment?: Maybe<Assessment_Mutation_Response>;
  /** update data of the table: "risksmart.assessment_activity" */
  update_assessment_activity?: Maybe<Assessment_Activity_Mutation_Response>;
  /** update single row of the table: "risksmart.assessment_activity" */
  update_assessment_activity_by_pk?: Maybe<Assessment_Activity>;
  /** update multiples rows of table: "risksmart.assessment_activity" */
  update_assessment_activity_many?: Maybe<Array<Maybe<Assessment_Activity_Mutation_Response>>>;
  /** update single row of the table: "risksmart.assessment" */
  update_assessment_by_pk?: Maybe<Assessment>;
  /** update multiples rows of table: "risksmart.assessment" */
  update_assessment_many?: Maybe<Array<Maybe<Assessment_Mutation_Response>>>;
  /** update data of the table: "risksmart.attestation_config" */
  update_attestation_config?: Maybe<Attestation_Config_Mutation_Response>;
  /** update single row of the table: "risksmart.attestation_config" */
  update_attestation_config_by_pk?: Maybe<Attestation_Config>;
  /** update multiples rows of table: "risksmart.attestation_config" */
  update_attestation_config_many?: Maybe<Array<Maybe<Attestation_Config_Mutation_Response>>>;
  /** update data of the table: "risksmart.attestation_group" */
  update_attestation_group?: Maybe<Attestation_Group_Mutation_Response>;
  /** update single row of the table: "risksmart.attestation_group" */
  update_attestation_group_by_pk?: Maybe<Attestation_Group>;
  /** update multiples rows of table: "risksmart.attestation_group" */
  update_attestation_group_many?: Maybe<Array<Maybe<Attestation_Group_Mutation_Response>>>;
  /** update data of the table: "auth.organisationuser" */
  update_auth_organisationuser?: Maybe<Auth_Organisationuser_Mutation_Response>;
  /** update single row of the table: "auth.organisationuser" */
  update_auth_organisationuser_by_pk?: Maybe<Auth_Organisationuser>;
  /** update multiples rows of table: "auth.organisationuser" */
  update_auth_organisationuser_many?: Maybe<Array<Maybe<Auth_Organisationuser_Mutation_Response>>>;
  /** update data of the table: "auth.user" */
  update_auth_user?: Maybe<Auth_User_Mutation_Response>;
  /** update single row of the table: "auth.user" */
  update_auth_user_by_pk?: Maybe<Auth_User>;
  /** update multiples rows of table: "auth.user" */
  update_auth_user_many?: Maybe<Array<Maybe<Auth_User_Mutation_Response>>>;
  /** update data of the table: "risksmart.business_area" */
  update_business_area?: Maybe<Business_Area_Mutation_Response>;
  /** update single row of the table: "risksmart.business_area" */
  update_business_area_by_pk?: Maybe<Business_Area>;
  /** update multiples rows of table: "risksmart.business_area" */
  update_business_area_many?: Maybe<Array<Maybe<Business_Area_Mutation_Response>>>;
  /** update data of the table: "risksmart.cause" */
  update_cause?: Maybe<Cause_Mutation_Response>;
  /** update single row of the table: "risksmart.cause" */
  update_cause_by_pk?: Maybe<Cause>;
  /** update multiples rows of table: "risksmart.cause" */
  update_cause_many?: Maybe<Array<Maybe<Cause_Mutation_Response>>>;
  /** update data of the table: "risksmart.comment" */
  update_comment?: Maybe<Comment_Mutation_Response>;
  /** update single row of the table: "risksmart.comment" */
  update_comment_by_pk?: Maybe<Comment>;
  /** update multiples rows of table: "risksmart.comment" */
  update_comment_many?: Maybe<Array<Maybe<Comment_Mutation_Response>>>;
  /** update data of the table: "risksmart.compliance_monitoring_assessment" */
  update_compliance_monitoring_assessment?: Maybe<Compliance_Monitoring_Assessment_Mutation_Response>;
  /** update single row of the table: "risksmart.compliance_monitoring_assessment" */
  update_compliance_monitoring_assessment_by_pk?: Maybe<Compliance_Monitoring_Assessment>;
  /** update multiples rows of table: "risksmart.compliance_monitoring_assessment" */
  update_compliance_monitoring_assessment_many?: Maybe<Array<Maybe<Compliance_Monitoring_Assessment_Mutation_Response>>>;
  /** update data of the table: "risksmart.consequence" */
  update_consequence?: Maybe<Consequence_Mutation_Response>;
  /** update single row of the table: "risksmart.consequence" */
  update_consequence_by_pk?: Maybe<Consequence>;
  /** update multiples rows of table: "risksmart.consequence" */
  update_consequence_many?: Maybe<Array<Maybe<Consequence_Mutation_Response>>>;
  /** update data of the table: "risksmart.control_group" */
  update_control_group?: Maybe<Control_Group_Mutation_Response>;
  /** update single row of the table: "risksmart.control_group" */
  update_control_group_by_pk?: Maybe<Control_Group>;
  /** update multiples rows of table: "risksmart.control_group" */
  update_control_group_many?: Maybe<Array<Maybe<Control_Group_Mutation_Response>>>;
  /** update data of the table: "risksmart.conversation" */
  update_conversation?: Maybe<Conversation_Mutation_Response>;
  /** update single row of the table: "risksmart.conversation" */
  update_conversation_by_pk?: Maybe<Conversation>;
  /** update multiples rows of table: "risksmart.conversation" */
  update_conversation_many?: Maybe<Array<Maybe<Conversation_Mutation_Response>>>;
  /** update data of the table: "risksmart.custom_attribute_schema" */
  update_custom_attribute_schema?: Maybe<Custom_Attribute_Schema_Mutation_Response>;
  /** update single row of the table: "risksmart.custom_attribute_schema" */
  update_custom_attribute_schema_by_pk?: Maybe<Custom_Attribute_Schema>;
  /** update multiples rows of table: "risksmart.custom_attribute_schema" */
  update_custom_attribute_schema_many?: Maybe<Array<Maybe<Custom_Attribute_Schema_Mutation_Response>>>;
  /** update data of the table: "risksmart.custom_ribbon" */
  update_custom_ribbon?: Maybe<Custom_Ribbon_Mutation_Response>;
  /** update single row of the table: "risksmart.custom_ribbon" */
  update_custom_ribbon_by_pk?: Maybe<Custom_Ribbon>;
  /** update multiples rows of table: "risksmart.custom_ribbon" */
  update_custom_ribbon_many?: Maybe<Array<Maybe<Custom_Ribbon_Mutation_Response>>>;
  /** update data of the table: "risksmart.department_type" */
  update_department_type?: Maybe<Department_Type_Mutation_Response>;
  /** update single row of the table: "risksmart.department_type" */
  update_department_type_by_pk?: Maybe<Department_Type>;
  /** update data of the table: "risksmart.department_type_group" */
  update_department_type_group?: Maybe<Department_Type_Group_Mutation_Response>;
  /** update multiples rows of table: "risksmart.department_type_group" */
  update_department_type_group_many?: Maybe<Array<Maybe<Department_Type_Group_Mutation_Response>>>;
  /** update multiples rows of table: "risksmart.department_type" */
  update_department_type_many?: Maybe<Array<Maybe<Department_Type_Mutation_Response>>>;
  /** update data of the table: "risksmart.document_assessment_result" */
  update_document_assessment_result?: Maybe<Document_Assessment_Result_Mutation_Response>;
  /** update single row of the table: "risksmart.document_assessment_result" */
  update_document_assessment_result_by_pk?: Maybe<Document_Assessment_Result>;
  /** update multiples rows of table: "risksmart.document_assessment_result" */
  update_document_assessment_result_many?: Maybe<Array<Maybe<Document_Assessment_Result_Mutation_Response>>>;
  /** update data of the table: "risksmart.form_configuration" */
  update_form_configuration?: Maybe<Form_Configuration_Mutation_Response>;
  /** update multiples rows of table: "risksmart.form_configuration" */
  update_form_configuration_many?: Maybe<Array<Maybe<Form_Configuration_Mutation_Response>>>;
  /** update data of the table: "risksmart.form_field_configuration" */
  update_form_field_configuration?: Maybe<Form_Field_Configuration_Mutation_Response>;
  /** update multiples rows of table: "risksmart.form_field_configuration" */
  update_form_field_configuration_many?: Maybe<Array<Maybe<Form_Field_Configuration_Mutation_Response>>>;
  /** update data of the table: "risksmart.form_field_ordering" */
  update_form_field_ordering?: Maybe<Form_Field_Ordering_Mutation_Response>;
  /** update multiples rows of table: "risksmart.form_field_ordering" */
  update_form_field_ordering_many?: Maybe<Array<Maybe<Form_Field_Ordering_Mutation_Response>>>;
  /** update data of the table: "risksmart.impact" */
  update_impact?: Maybe<Impact_Mutation_Response>;
  /** update single row of the table: "risksmart.impact" */
  update_impact_by_pk?: Maybe<Impact>;
  /** update multiples rows of table: "risksmart.impact" */
  update_impact_many?: Maybe<Array<Maybe<Impact_Mutation_Response>>>;
  /** update data of the table: "risksmart.indicator_result" */
  update_indicator_result?: Maybe<Indicator_Result_Mutation_Response>;
  /** update single row of the table: "risksmart.indicator_result" */
  update_indicator_result_by_pk?: Maybe<Indicator_Result>;
  /** update multiples rows of table: "risksmart.indicator_result" */
  update_indicator_result_many?: Maybe<Array<Maybe<Indicator_Result_Mutation_Response>>>;
  /** update data of the table: "risksmart.internal_audit_report" */
  update_internal_audit_report?: Maybe<Internal_Audit_Report_Mutation_Response>;
  /** update single row of the table: "risksmart.internal_audit_report" */
  update_internal_audit_report_by_pk?: Maybe<Internal_Audit_Report>;
  /** update multiples rows of table: "risksmart.internal_audit_report" */
  update_internal_audit_report_many?: Maybe<Array<Maybe<Internal_Audit_Report_Mutation_Response>>>;
  /** update data of the table: "risksmart.issue" */
  update_issue?: Maybe<Issue_Mutation_Response>;
  /** update single row of the table: "risksmart.issue" */
  update_issue_by_pk?: Maybe<Issue>;
  /** update multiples rows of table: "risksmart.issue" */
  update_issue_many?: Maybe<Array<Maybe<Issue_Mutation_Response>>>;
  /** update data of the table: "risksmart.issue_update" */
  update_issue_update?: Maybe<Issue_Update_Mutation_Response>;
  /** update single row of the table: "risksmart.issue_update" */
  update_issue_update_by_pk?: Maybe<Issue_Update>;
  /** update multiples rows of table: "risksmart.issue_update" */
  update_issue_update_many?: Maybe<Array<Maybe<Issue_Update_Mutation_Response>>>;
  /** update data of the table: "risksmart.obligation_assessment_result" */
  update_obligation_assessment_result?: Maybe<Obligation_Assessment_Result_Mutation_Response>;
  /** update single row of the table: "risksmart.obligation_assessment_result" */
  update_obligation_assessment_result_by_pk?: Maybe<Obligation_Assessment_Result>;
  /** update multiples rows of table: "risksmart.obligation_assessment_result" */
  update_obligation_assessment_result_many?: Maybe<Array<Maybe<Obligation_Assessment_Result_Mutation_Response>>>;
  /** update data of the table: "risksmart.obligation_impact" */
  update_obligation_impact?: Maybe<Obligation_Impact_Mutation_Response>;
  /** update single row of the table: "risksmart.obligation_impact" */
  update_obligation_impact_by_pk?: Maybe<Obligation_Impact>;
  /** update multiples rows of table: "risksmart.obligation_impact" */
  update_obligation_impact_many?: Maybe<Array<Maybe<Obligation_Impact_Mutation_Response>>>;
  /** update data of the table: "risksmart.questionnaire_template" */
  update_questionnaire_template?: Maybe<Questionnaire_Template_Mutation_Response>;
  /** update single row of the table: "risksmart.questionnaire_template" */
  update_questionnaire_template_by_pk?: Maybe<Questionnaire_Template>;
  /** update multiples rows of table: "risksmart.questionnaire_template" */
  update_questionnaire_template_many?: Maybe<Array<Maybe<Questionnaire_Template_Mutation_Response>>>;
  /** update data of the table: "risksmart.questionnaire_template_version" */
  update_questionnaire_template_version?: Maybe<Questionnaire_Template_Version_Mutation_Response>;
  /** update single row of the table: "risksmart.questionnaire_template_version" */
  update_questionnaire_template_version_by_pk?: Maybe<Questionnaire_Template_Version>;
  /** update multiples rows of table: "risksmart.questionnaire_template_version" */
  update_questionnaire_template_version_many?: Maybe<Array<Maybe<Questionnaire_Template_Version_Mutation_Response>>>;
  /** update data of the table: "risksmart.tag_type" */
  update_tag_type?: Maybe<Tag_Type_Mutation_Response>;
  /** update single row of the table: "risksmart.tag_type" */
  update_tag_type_by_pk?: Maybe<Tag_Type>;
  /** update data of the table: "risksmart.tag_type_group" */
  update_tag_type_group?: Maybe<Tag_Type_Group_Mutation_Response>;
  /** update multiples rows of table: "risksmart.tag_type_group" */
  update_tag_type_group_many?: Maybe<Array<Maybe<Tag_Type_Group_Mutation_Response>>>;
  /** update multiples rows of table: "risksmart.tag_type" */
  update_tag_type_many?: Maybe<Array<Maybe<Tag_Type_Mutation_Response>>>;
  /** update data of the table: "risksmart.taxonomy" */
  update_taxonomy?: Maybe<Taxonomy_Mutation_Response>;
  /** update single row of the table: "risksmart.taxonomy" */
  update_taxonomy_by_pk?: Maybe<Taxonomy>;
  /** update multiples rows of table: "risksmart.taxonomy" */
  update_taxonomy_many?: Maybe<Array<Maybe<Taxonomy_Mutation_Response>>>;
  /** update data of the table: "risksmart.third_party" */
  update_third_party?: Maybe<Third_Party_Mutation_Response>;
  /** update single row of the table: "risksmart.third_party" */
  update_third_party_by_pk?: Maybe<Third_Party>;
  /** update multiples rows of table: "risksmart.third_party" */
  update_third_party_many?: Maybe<Array<Maybe<Third_Party_Mutation_Response>>>;
  /** update data of the table: "risksmart.third_party_response" */
  update_third_party_response?: Maybe<Third_Party_Response_Mutation_Response>;
  /** update single row of the table: "risksmart.third_party_response" */
  update_third_party_response_by_pk?: Maybe<Third_Party_Response>;
  /** update multiples rows of table: "risksmart.third_party_response" */
  update_third_party_response_many?: Maybe<Array<Maybe<Third_Party_Response_Mutation_Response>>>;
  /** update data of the table: "risksmart.user_group" */
  update_user_group?: Maybe<User_Group_Mutation_Response>;
  /** update single row of the table: "risksmart.user_group" */
  update_user_group_by_pk?: Maybe<User_Group>;
  /** update multiples rows of table: "risksmart.user_group" */
  update_user_group_many?: Maybe<Array<Maybe<User_Group_Mutation_Response>>>;
  update_user_roles: UpdateUserRolesMutationOutput;
  /** update data of the table: "risksmart.user_search_preferences" */
  update_user_search_preferences?: Maybe<User_Search_Preferences_Mutation_Response>;
  /** update multiples rows of table: "risksmart.user_search_preferences" */
  update_user_search_preferences_many?: Maybe<Array<Maybe<User_Search_Preferences_Mutation_Response>>>;
  /** update data of the table: "risksmart.user_table_preferences" */
  update_user_table_preferences?: Maybe<User_Table_Preferences_Mutation_Response>;
  /** update multiples rows of table: "risksmart.user_table_preferences" */
  update_user_table_preferences_many?: Maybe<Array<Maybe<User_Table_Preferences_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootAttestRecordArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDataImportStartImportArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDataImportValidateArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteAcceptancesByIdArgs = {
  Ids: Array<Scalars['uuid']['input']>;
};


/** mutation root */
export type Mutation_RootDeleteActionsByIdArgs = {
  Ids: Array<Scalars['uuid']['input']>;
};


/** mutation root */
export type Mutation_RootDeleteControlsByIdArgs = {
  Ids: Array<Scalars['uuid']['input']>;
};


/** mutation root */
export type Mutation_RootDeleteDocumentByIdArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteIssuesByIdArgs = {
  Ids: Array<Scalars['uuid']['input']>;
};


/** mutation root */
export type Mutation_RootDeleteRiskByIdArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteUserGroupsArgs = {
  Ids: Array<Scalars['uuid']['input']>;
};


/** mutation root */
export type Mutation_RootDelete_Acceptance_ParentArgs = {
  where: Acceptance_Parent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Acceptance_Parent_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Action_ParentArgs = {
  where: Action_Parent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Action_Parent_By_PkArgs = {
  ActionId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Action_UpdateArgs = {
  where: Action_Update_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Action_Update_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_AppetiteArgs = {
  where: Appetite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Appetite_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Appetite_ParentArgs = {
  where: Appetite_Parent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Appetite_Parent_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ApprovalArgs = {
  where: Approval_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Approval_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Approval_LevelArgs = {
  where: Approval_Level_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Approval_Level_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ApproverArgs = {
  where: Approver_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Approver_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_AssessmentArgs = {
  where: Assessment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Assessment_ActivityArgs = {
  where: Assessment_Activity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Assessment_Activity_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Assessment_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Assessment_Result_ParentArgs = {
  where: Assessment_Result_Parent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Assessment_Result_Parent_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Attestation_ConfigArgs = {
  where: Attestation_Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Attestation_Config_By_PkArgs = {
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Attestation_GroupArgs = {
  where: Attestation_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Attestation_Group_By_PkArgs = {
  ConfigId: Scalars['uuid']['input'];
  GroupId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Business_AreaArgs = {
  where: Business_Area_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Business_Area_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CauseArgs = {
  where: Cause_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Cause_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CommentArgs = {
  where: Comment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Comment_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Monitoring_AssessmentArgs = {
  where: Compliance_Monitoring_Assessment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Monitoring_Assessment_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ConsequenceArgs = {
  where: Consequence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Consequence_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ContributorArgs = {
  where: Contributor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contributor_By_PkArgs = {
  ParentId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Contributor_GroupArgs = {
  where: Contributor_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contributor_Group_By_PkArgs = {
  ParentId: Scalars['uuid']['input'];
  UserGroupId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Control_GroupArgs = {
  where: Control_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Control_Group_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Control_ParentArgs = {
  where: Control_Parent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Control_Parent_By_PkArgs = {
  ControlId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ConversationArgs = {
  where: Conversation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Conversation_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Custom_Attribute_SchemaArgs = {
  where: Custom_Attribute_Schema_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Custom_Attribute_Schema_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_DashboardArgs = {
  where: Dashboard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dashboard_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Data_ImportArgs = {
  where: Data_Import_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Data_Import_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Data_Import_ErrorArgs = {
  where: Data_Import_Error_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Data_Import_Error_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_DepartmentArgs = {
  where: Department_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Department_By_PkArgs = {
  DepartmentTypeId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Department_TypeArgs = {
  where: Department_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Department_Type_By_PkArgs = {
  DepartmentTypeId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Document_Assessment_ResultArgs = {
  where: Document_Assessment_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Document_Assessment_Result_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Document_FileArgs = {
  where: Document_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Document_File_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Document_Linked_DocumentArgs = {
  where: Document_Linked_Document_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Document_Linked_Document_By_PkArgs = {
  DocumentId: Scalars['uuid']['input'];
  LinkedDocumentId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FileArgs = {
  where: File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_File_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Form_ConfigurationArgs = {
  where: Form_Configuration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Field_ConfigurationArgs = {
  where: Form_Field_Configuration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Field_OrderingArgs = {
  where: Form_Field_Ordering_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ImpactArgs = {
  where: Impact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Impact_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Impact_RatingArgs = {
  where: Impact_Rating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Impact_Rating_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_IndicatorArgs = {
  where: Indicator_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Indicator_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Indicator_ParentArgs = {
  where: Indicator_Parent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Indicator_Parent_By_PkArgs = {
  IndicatorId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Indicator_ResultArgs = {
  where: Indicator_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Indicator_Result_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Internal_Audit_EntityArgs = {
  where: Internal_Audit_Entity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Internal_Audit_Entity_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Internal_Audit_ReportArgs = {
  where: Internal_Audit_Report_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Internal_Audit_Report_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Issue_UpdateArgs = {
  where: Issue_Update_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Issue_Update_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Linked_ItemArgs = {
  where: Linked_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Linked_Item_By_PkArgs = {
  Source: Scalars['uuid']['input'];
  Target: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ObligationArgs = {
  where: Obligation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Obligation_Assessment_ResultArgs = {
  where: Obligation_Assessment_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Obligation_Assessment_Result_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Obligation_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Obligation_ImpactArgs = {
  where: Obligation_Impact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Obligation_Impact_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_OwnerArgs = {
  where: Owner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Owner_By_PkArgs = {
  ParentId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Owner_GroupArgs = {
  where: Owner_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Owner_Group_By_PkArgs = {
  ParentId: Scalars['uuid']['input'];
  UserGroupId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Questionnaire_InviteArgs = {
  where: Questionnaire_Invite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questionnaire_Invite_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Questionnaire_TemplateArgs = {
  where: Questionnaire_Template_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questionnaire_Template_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Questionnaire_Template_VersionArgs = {
  where: Questionnaire_Template_Version_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questionnaire_Template_Version_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Relation_FileArgs = {
  where: Relation_File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Relation_File_By_PkArgs = {
  FileId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Risk_Assessment_ResultArgs = {
  where: Risk_Assessment_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Risk_Assessment_Result_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_TagArgs = {
  where: Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tag_By_PkArgs = {
  ParentId: Scalars['uuid']['input'];
  TagTypeId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tag_TypeArgs = {
  where: Tag_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tag_Type_By_PkArgs = {
  TagTypeId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_TaxonomyArgs = {
  where: Taxonomy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Taxonomy_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Taxonomy_OrgArgs = {
  where: Taxonomy_Org_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Taxonomy_Org_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Test_ResultArgs = {
  where: Test_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Test_Result_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Third_PartyArgs = {
  where: Third_Party_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Third_Party_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Third_Party_ResponseArgs = {
  where: Third_Party_Response_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Third_Party_Response_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_Group_UserArgs = {
  where: User_Group_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Group_User_By_PkArgs = {
  UserGroupId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootInsertAssessmentActivityWithLinkedItemsArgs = {
  ActivityType: Assessment_Activity_Type_Enum;
  AssignedUser?: InputMaybe<Scalars['String']['input']>;
  CompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  LinkedItemIds: Array<Scalars['uuid']['input']>;
  ParentId: Scalars['uuid']['input'];
  Status: Assessment_Activity_Status_Enum;
  Summary: Scalars['String']['input'];
  Title: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootInsertChildAcceptanceArgs = {
  ApprovedByUser?: InputMaybe<Scalars['String']['input']>;
  ApprovedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateAcceptedFrom: Scalars['timestamptz']['input'];
  DateAcceptedTo: Scalars['timestamptz']['input'];
  Details?: InputMaybe<Scalars['String']['input']>;
  ParentId: Scalars['uuid']['input'];
  RequestedByUser?: InputMaybe<Scalars['String']['input']>;
  RequestedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  Status?: InputMaybe<Acceptance_Status_Enum>;
  Title?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootInsertChildActionArgs = {
  ClosedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateDue: Scalars['timestamptz']['input'];
  DateRaised: Scalars['timestamptz']['input'];
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Priority?: InputMaybe<Scalars['Int']['input']>;
  Status: Action_Status_Enum;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  Title: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootInsertChildAppetiteArgs = {
  AppetiteType?: InputMaybe<Appetite_Type_Enum>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  EffectiveDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ImpactAppetite?: InputMaybe<Scalars['Int']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['Int']['input']>;
  LowerAppetite?: InputMaybe<Scalars['Int']['input']>;
  ParentIds: Array<Scalars['uuid']['input']>;
  Statement?: InputMaybe<Scalars['String']['input']>;
  UpperAppetite?: InputMaybe<Scalars['Int']['input']>;
};


/** mutation root */
export type Mutation_RootInsertChildControlArgs = {
  object?: InputMaybe<InsertChildControlInput>;
};


/** mutation root */
export type Mutation_RootInsertChildDashboardArgs = {
  Content: Scalars['jsonb']['input'];
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Name: Scalars['String']['input'];
  Sharing: Dashboard_Sharing_Type_Enum_Action;
};


/** mutation root */
export type Mutation_RootInsertChildDocumentArgs = {
  object?: InputMaybe<InsertChildDocumentInput>;
};


/** mutation root */
export type Mutation_RootInsertChildDocumentAssessmentResultArgs = {
  AssessmentId?: InputMaybe<Scalars['uuid']['input']>;
  ComplianceMonitoringAssessmentId?: InputMaybe<Scalars['uuid']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DocumentIds: Array<Scalars['uuid']['input']>;
  InternalAuditReportId?: InputMaybe<Scalars['uuid']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};


/** mutation root */
export type Mutation_RootInsertChildImpactArgs = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['smallint']['input']>;
  Name: Scalars['String']['input'];
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  RatingGuidance?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootInsertChildImpactRatingArgs = {
  AssessmentId?: InputMaybe<Scalars['uuid']['input']>;
  CompletedBy?: InputMaybe<Scalars['String']['input']>;
  ComplianceMonitoringAssessmentId?: InputMaybe<Scalars['uuid']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  InternalAuditReportId?: InputMaybe<Scalars['uuid']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  RatedItemId: Scalars['uuid']['input'];
  Ratings: Array<InsertImpactRatingPairInput>;
  TestDate: Scalars['timestamptz']['input'];
};


/** mutation root */
export type Mutation_RootInsertChildIndicatorArgs = {
  object?: InputMaybe<InsertChildIndicatorInput>;
};


/** mutation root */
export type Mutation_RootInsertChildIssueArgs = {
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateIdentified: Scalars['timestamptz']['input'];
  DateOccurred: Scalars['timestamptz']['input'];
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Details: Scalars['String']['input'];
  ImpactsCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  IsExternalIssue?: InputMaybe<Scalars['Boolean']['input']>;
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  Title: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootInsertChildIssueAssessmentArgs = {
  ActualCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  AssociatedControlIds: Array<Scalars['uuid']['input']>;
  CertifiedIndividual?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  IssueCausedBySystemIssue?: InputMaybe<Scalars['Boolean']['input']>;
  IssueCausedByThirdParty?: InputMaybe<Scalars['Boolean']['input']>;
  IssueType?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId: Scalars['uuid']['input'];
  PoliciesBreached?: InputMaybe<Scalars['String']['input']>;
  PoliciesBreachedIds: Array<Scalars['uuid']['input']>;
  PolicyBreach?: InputMaybe<Scalars['Boolean']['input']>;
  PolicyOwner?: InputMaybe<Scalars['String']['input']>;
  PolicyOwnerCommentary?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  RegulationsBreached?: InputMaybe<Scalars['String']['input']>;
  RegulationsBreachedIds: Array<Scalars['uuid']['input']>;
  RegulatoryBreach?: InputMaybe<Scalars['Boolean']['input']>;
  Reportable?: InputMaybe<Scalars['Boolean']['input']>;
  Severity?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Issue_Assessment_Status_Enum>;
  SystemResponsible?: InputMaybe<Scalars['String']['input']>;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  TargetCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ThirdPartyResponsible?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootInsertChildObligationArgs = {
  object?: InputMaybe<InsertChildObligationInput>;
};


/** mutation root */
export type Mutation_RootInsertChildObligationAssessmentResultArgs = {
  AssessmentId?: InputMaybe<Scalars['uuid']['input']>;
  ComplianceMonitoringAssessmentId?: InputMaybe<Scalars['uuid']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  InternalAuditReportId?: InputMaybe<Scalars['uuid']['input']>;
  ObligationIds: Array<Scalars['uuid']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};


/** mutation root */
export type Mutation_RootInsertChildRiskArgs = {
  object?: InputMaybe<InsertChildRiskInput>;
};


/** mutation root */
export type Mutation_RootInsertChildRiskAssessmentResultArgs = {
  AssessmentId?: InputMaybe<Scalars['uuid']['input']>;
  ComplianceMonitoringAssessmentId?: InputMaybe<Scalars['uuid']['input']>;
  ControlType?: InputMaybe<Risk_Assessment_Result_Control_Type_Enum>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Impact?: InputMaybe<Scalars['Int']['input']>;
  InternalAuditReportId?: InputMaybe<Scalars['uuid']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  RiskIds: Array<Scalars['uuid']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};


/** mutation root */
export type Mutation_RootInsertControlTestResultArgs = {
  AssessmentId?: InputMaybe<Scalars['uuid']['input']>;
  ComplianceMonitoringAssessmentId?: InputMaybe<Scalars['uuid']['input']>;
  ControlIds: Array<Scalars['uuid']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  DesignEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  InternalAuditReportId?: InputMaybe<Scalars['uuid']['input']>;
  OverallEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  PerformanceEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  Submitter?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TestType?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootInsertDocumentVersionArgs = {
  Content?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  FileId?: InputMaybe<Scalars['uuid']['input']>;
  Link?: InputMaybe<Scalars['String']['input']>;
  NextReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ParentDocumentId: Scalars['uuid']['input'];
  ReasonForReview?: InputMaybe<Scalars['String']['input']>;
  ReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReviewedBy?: InputMaybe<Scalars['String']['input']>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Document_File_Type_Enum>;
  Version?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootInsertInternalAuditArgs = {
  Input?: InputMaybe<InsertInternalAuditInput>;
};


/** mutation root */
export type Mutation_RootInsertQuestionnaireInvitesArgs = {
  Message?: InputMaybe<Scalars['String']['input']>;
  QuestionnaireTemplateVersionIds: Array<Scalars['uuid']['input']>;
  ThirdPartyId: Scalars['uuid']['input'];
  UserEmails: Array<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootInsertReferenceUserArgs = {
  Email: Scalars['String']['input'];
  UserName?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootInsert_Action_UpdateArgs = {
  objects: Array<Action_Update_Insert_Input>;
  on_conflict?: InputMaybe<Action_Update_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Action_Update_OneArgs = {
  object: Action_Update_Insert_Input;
  on_conflict?: InputMaybe<Action_Update_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ApprovalArgs = {
  objects: Array<Approval_Insert_Input>;
  on_conflict?: InputMaybe<Approval_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Approval_LevelArgs = {
  objects: Array<Approval_Level_Insert_Input>;
  on_conflict?: InputMaybe<Approval_Level_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Approval_Level_OneArgs = {
  object: Approval_Level_Insert_Input;
  on_conflict?: InputMaybe<Approval_Level_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Approval_OneArgs = {
  object: Approval_Insert_Input;
  on_conflict?: InputMaybe<Approval_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ApproverArgs = {
  objects: Array<Approver_Insert_Input>;
  on_conflict?: InputMaybe<Approver_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Approver_OneArgs = {
  object: Approver_Insert_Input;
  on_conflict?: InputMaybe<Approver_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AssessmentArgs = {
  objects: Array<Assessment_Insert_Input>;
  on_conflict?: InputMaybe<Assessment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Assessment_ActivityArgs = {
  objects: Array<Assessment_Activity_Insert_Input>;
  on_conflict?: InputMaybe<Assessment_Activity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Assessment_Activity_OneArgs = {
  object: Assessment_Activity_Insert_Input;
  on_conflict?: InputMaybe<Assessment_Activity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Assessment_OneArgs = {
  object: Assessment_Insert_Input;
  on_conflict?: InputMaybe<Assessment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Attestation_ConfigArgs = {
  objects: Array<Attestation_Config_Insert_Input>;
  on_conflict?: InputMaybe<Attestation_Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Attestation_Config_OneArgs = {
  object: Attestation_Config_Insert_Input;
  on_conflict?: InputMaybe<Attestation_Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Attestation_GroupArgs = {
  objects: Array<Attestation_Group_Insert_Input>;
  on_conflict?: InputMaybe<Attestation_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Attestation_Group_OneArgs = {
  object: Attestation_Group_Insert_Input;
  on_conflict?: InputMaybe<Attestation_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Business_AreaArgs = {
  objects: Array<Business_Area_Insert_Input>;
  on_conflict?: InputMaybe<Business_Area_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Business_Area_OneArgs = {
  object: Business_Area_Insert_Input;
  on_conflict?: InputMaybe<Business_Area_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CauseArgs = {
  objects: Array<Cause_Insert_Input>;
  on_conflict?: InputMaybe<Cause_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cause_OneArgs = {
  object: Cause_Insert_Input;
  on_conflict?: InputMaybe<Cause_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CommentArgs = {
  objects: Array<Comment_Insert_Input>;
  on_conflict?: InputMaybe<Comment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comment_OneArgs = {
  object: Comment_Insert_Input;
  on_conflict?: InputMaybe<Comment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Monitoring_AssessmentArgs = {
  objects: Array<Compliance_Monitoring_Assessment_Insert_Input>;
  on_conflict?: InputMaybe<Compliance_Monitoring_Assessment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Monitoring_Assessment_OneArgs = {
  object: Compliance_Monitoring_Assessment_Insert_Input;
  on_conflict?: InputMaybe<Compliance_Monitoring_Assessment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ConsequenceArgs = {
  objects: Array<Consequence_Insert_Input>;
  on_conflict?: InputMaybe<Consequence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Consequence_OneArgs = {
  object: Consequence_Insert_Input;
  on_conflict?: InputMaybe<Consequence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContributorArgs = {
  objects: Array<Contributor_Insert_Input>;
  on_conflict?: InputMaybe<Contributor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contributor_GroupArgs = {
  objects: Array<Contributor_Group_Insert_Input>;
  on_conflict?: InputMaybe<Contributor_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contributor_Group_OneArgs = {
  object: Contributor_Group_Insert_Input;
  on_conflict?: InputMaybe<Contributor_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contributor_OneArgs = {
  object: Contributor_Insert_Input;
  on_conflict?: InputMaybe<Contributor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ControlArgs = {
  objects: Array<Control_Insert_Input>;
  on_conflict?: InputMaybe<Control_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Control_GroupArgs = {
  objects: Array<Control_Group_Insert_Input>;
  on_conflict?: InputMaybe<Control_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Control_Group_OneArgs = {
  object: Control_Group_Insert_Input;
  on_conflict?: InputMaybe<Control_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Control_OneArgs = {
  object: Control_Insert_Input;
  on_conflict?: InputMaybe<Control_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Control_ParentArgs = {
  objects: Array<Control_Parent_Insert_Input>;
  on_conflict?: InputMaybe<Control_Parent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Control_Parent_OneArgs = {
  object: Control_Parent_Insert_Input;
  on_conflict?: InputMaybe<Control_Parent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ConversationArgs = {
  objects: Array<Conversation_Insert_Input>;
  on_conflict?: InputMaybe<Conversation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Conversation_OneArgs = {
  object: Conversation_Insert_Input;
  on_conflict?: InputMaybe<Conversation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Custom_Attribute_SchemaArgs = {
  objects: Array<Custom_Attribute_Schema_Insert_Input>;
  on_conflict?: InputMaybe<Custom_Attribute_Schema_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Custom_Attribute_Schema_OneArgs = {
  object: Custom_Attribute_Schema_Insert_Input;
  on_conflict?: InputMaybe<Custom_Attribute_Schema_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Custom_RibbonArgs = {
  objects: Array<Custom_Ribbon_Insert_Input>;
  on_conflict?: InputMaybe<Custom_Ribbon_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Custom_Ribbon_OneArgs = {
  object: Custom_Ribbon_Insert_Input;
  on_conflict?: InputMaybe<Custom_Ribbon_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Data_ImportArgs = {
  objects: Array<Data_Import_Insert_Input>;
  on_conflict?: InputMaybe<Data_Import_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Data_Import_ErrorArgs = {
  objects: Array<Data_Import_Error_Insert_Input>;
  on_conflict?: InputMaybe<Data_Import_Error_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Data_Import_Error_OneArgs = {
  object: Data_Import_Error_Insert_Input;
  on_conflict?: InputMaybe<Data_Import_Error_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Data_Import_OneArgs = {
  object: Data_Import_Insert_Input;
  on_conflict?: InputMaybe<Data_Import_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DepartmentArgs = {
  objects: Array<Department_Insert_Input>;
  on_conflict?: InputMaybe<Department_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Department_OneArgs = {
  object: Department_Insert_Input;
  on_conflict?: InputMaybe<Department_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Department_TypeArgs = {
  objects: Array<Department_Type_Insert_Input>;
  on_conflict?: InputMaybe<Department_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Department_Type_GroupArgs = {
  objects: Array<Department_Type_Group_Insert_Input>;
  on_conflict?: InputMaybe<Department_Type_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Department_Type_Group_OneArgs = {
  object: Department_Type_Group_Insert_Input;
  on_conflict?: InputMaybe<Department_Type_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Department_Type_OneArgs = {
  object: Department_Type_Insert_Input;
  on_conflict?: InputMaybe<Department_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Document_Linked_DocumentArgs = {
  objects: Array<Document_Linked_Document_Insert_Input>;
  on_conflict?: InputMaybe<Document_Linked_Document_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Document_Linked_Document_OneArgs = {
  object: Document_Linked_Document_Insert_Input;
  on_conflict?: InputMaybe<Document_Linked_Document_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FileArgs = {
  objects: Array<File_Insert_Input>;
  on_conflict?: InputMaybe<File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_OneArgs = {
  object: File_Insert_Input;
  on_conflict?: InputMaybe<File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_ConfigurationArgs = {
  objects: Array<Form_Configuration_Insert_Input>;
  on_conflict?: InputMaybe<Form_Configuration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Configuration_OneArgs = {
  object: Form_Configuration_Insert_Input;
  on_conflict?: InputMaybe<Form_Configuration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Field_ConfigurationArgs = {
  objects: Array<Form_Field_Configuration_Insert_Input>;
  on_conflict?: InputMaybe<Form_Field_Configuration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Field_Configuration_OneArgs = {
  object: Form_Field_Configuration_Insert_Input;
  on_conflict?: InputMaybe<Form_Field_Configuration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Field_OrderingArgs = {
  objects: Array<Form_Field_Ordering_Insert_Input>;
  on_conflict?: InputMaybe<Form_Field_Ordering_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Field_Ordering_OneArgs = {
  object: Form_Field_Ordering_Insert_Input;
  on_conflict?: InputMaybe<Form_Field_Ordering_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Indicator_ParentArgs = {
  objects: Array<Indicator_Parent_Insert_Input>;
  on_conflict?: InputMaybe<Indicator_Parent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Indicator_Parent_OneArgs = {
  object: Indicator_Parent_Insert_Input;
  on_conflict?: InputMaybe<Indicator_Parent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Indicator_ResultArgs = {
  objects: Array<Indicator_Result_Insert_Input>;
  on_conflict?: InputMaybe<Indicator_Result_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Indicator_Result_OneArgs = {
  object: Indicator_Result_Insert_Input;
  on_conflict?: InputMaybe<Indicator_Result_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Internal_Audit_ReportArgs = {
  objects: Array<Internal_Audit_Report_Insert_Input>;
  on_conflict?: InputMaybe<Internal_Audit_Report_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Internal_Audit_Report_OneArgs = {
  object: Internal_Audit_Report_Insert_Input;
  on_conflict?: InputMaybe<Internal_Audit_Report_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IssueArgs = {
  objects: Array<Issue_Insert_Input>;
  on_conflict?: InputMaybe<Issue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Issue_OneArgs = {
  object: Issue_Insert_Input;
  on_conflict?: InputMaybe<Issue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Issue_UpdateArgs = {
  objects: Array<Issue_Update_Insert_Input>;
  on_conflict?: InputMaybe<Issue_Update_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Issue_Update_OneArgs = {
  object: Issue_Update_Insert_Input;
  on_conflict?: InputMaybe<Issue_Update_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Obligation_ImpactArgs = {
  objects: Array<Obligation_Impact_Insert_Input>;
  on_conflict?: InputMaybe<Obligation_Impact_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Obligation_Impact_OneArgs = {
  object: Obligation_Impact_Insert_Input;
  on_conflict?: InputMaybe<Obligation_Impact_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OwnerArgs = {
  objects: Array<Owner_Insert_Input>;
  on_conflict?: InputMaybe<Owner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Owner_GroupArgs = {
  objects: Array<Owner_Group_Insert_Input>;
  on_conflict?: InputMaybe<Owner_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Owner_Group_OneArgs = {
  object: Owner_Group_Insert_Input;
  on_conflict?: InputMaybe<Owner_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Owner_OneArgs = {
  object: Owner_Insert_Input;
  on_conflict?: InputMaybe<Owner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questionnaire_InviteArgs = {
  objects: Array<Questionnaire_Invite_Insert_Input>;
  on_conflict?: InputMaybe<Questionnaire_Invite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questionnaire_Invite_OneArgs = {
  object: Questionnaire_Invite_Insert_Input;
  on_conflict?: InputMaybe<Questionnaire_Invite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questionnaire_TemplateArgs = {
  objects: Array<Questionnaire_Template_Insert_Input>;
  on_conflict?: InputMaybe<Questionnaire_Template_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questionnaire_Template_OneArgs = {
  object: Questionnaire_Template_Insert_Input;
  on_conflict?: InputMaybe<Questionnaire_Template_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questionnaire_Template_VersionArgs = {
  objects: Array<Questionnaire_Template_Version_Insert_Input>;
  on_conflict?: InputMaybe<Questionnaire_Template_Version_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questionnaire_Template_Version_OneArgs = {
  object: Questionnaire_Template_Version_Insert_Input;
  on_conflict?: InputMaybe<Questionnaire_Template_Version_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Relation_FileArgs = {
  objects: Array<Relation_File_Insert_Input>;
  on_conflict?: InputMaybe<Relation_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Relation_File_OneArgs = {
  object: Relation_File_Insert_Input;
  on_conflict?: InputMaybe<Relation_File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TagArgs = {
  objects: Array<Tag_Insert_Input>;
  on_conflict?: InputMaybe<Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tag_OneArgs = {
  object: Tag_Insert_Input;
  on_conflict?: InputMaybe<Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tag_TypeArgs = {
  objects: Array<Tag_Type_Insert_Input>;
  on_conflict?: InputMaybe<Tag_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tag_Type_GroupArgs = {
  objects: Array<Tag_Type_Group_Insert_Input>;
  on_conflict?: InputMaybe<Tag_Type_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tag_Type_Group_OneArgs = {
  object: Tag_Type_Group_Insert_Input;
  on_conflict?: InputMaybe<Tag_Type_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tag_Type_OneArgs = {
  object: Tag_Type_Insert_Input;
  on_conflict?: InputMaybe<Tag_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TaxonomyArgs = {
  objects: Array<Taxonomy_Insert_Input>;
  on_conflict?: InputMaybe<Taxonomy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Taxonomy_OneArgs = {
  object: Taxonomy_Insert_Input;
  on_conflict?: InputMaybe<Taxonomy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Taxonomy_OrgArgs = {
  objects: Array<Taxonomy_Org_Insert_Input>;
  on_conflict?: InputMaybe<Taxonomy_Org_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Taxonomy_Org_OneArgs = {
  object: Taxonomy_Org_Insert_Input;
  on_conflict?: InputMaybe<Taxonomy_Org_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Third_PartyArgs = {
  objects: Array<Third_Party_Insert_Input>;
  on_conflict?: InputMaybe<Third_Party_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Third_Party_OneArgs = {
  object: Third_Party_Insert_Input;
  on_conflict?: InputMaybe<Third_Party_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Third_Party_ResponseArgs = {
  objects: Array<Third_Party_Response_Insert_Input>;
  on_conflict?: InputMaybe<Third_Party_Response_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Third_Party_Response_OneArgs = {
  object: Third_Party_Response_Insert_Input;
  on_conflict?: InputMaybe<Third_Party_Response_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_GroupArgs = {
  objects: Array<User_Group_Insert_Input>;
  on_conflict?: InputMaybe<User_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Group_OneArgs = {
  object: User_Group_Insert_Input;
  on_conflict?: InputMaybe<User_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Group_UserArgs = {
  objects: Array<User_Group_User_Insert_Input>;
  on_conflict?: InputMaybe<User_Group_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Group_User_OneArgs = {
  object: User_Group_User_Insert_Input;
  on_conflict?: InputMaybe<User_Group_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Search_PreferencesArgs = {
  objects: Array<User_Search_Preferences_Insert_Input>;
  on_conflict?: InputMaybe<User_Search_Preferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Search_Preferences_OneArgs = {
  object: User_Search_Preferences_Insert_Input;
  on_conflict?: InputMaybe<User_Search_Preferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Table_PreferencesArgs = {
  objects: Array<User_Table_Preferences_Insert_Input>;
  on_conflict?: InputMaybe<User_Table_Preferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Table_Preferences_OneArgs = {
  object: User_Table_Preferences_Insert_Input;
  on_conflict?: InputMaybe<User_Table_Preferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLinkItemsArgs = {
  Source?: InputMaybe<Scalars['uuid']['input']>;
  Targets: Array<Scalars['uuid']['input']>;
};


/** mutation root */
export type Mutation_RootOverrideChangeRequestArgs = {
  Approved: Scalars['Boolean']['input'];
  Id: Scalars['uuid']['input'];
  Rationale: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootPublishQuestionnaireTemplateVersionArgs = {
  QuestionnaireTemplateId: Scalars['uuid']['input'];
  QuestionnaireTemplateVersionId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootRecalculateRiskScoresArgs = {
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootRecallQuestionnaireInvitesArgs = {
  Reason?: InputMaybe<Scalars['String']['input']>;
  ResponseIds?: InputMaybe<Array<Scalars['uuid']['input']>>;
  ShareWithRespondents?: InputMaybe<Scalars['Boolean']['input']>;
  ThirdPartyId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootUnlinkItemsArgs = {
  Ids: Array<Scalars['uuid']['input']>;
};


/** mutation root */
export type Mutation_RootUpdateApproverResponsesArgs = {
  input: UpdateApproverResponsesInput;
};


/** mutation root */
export type Mutation_RootUpdateAssessmentActivityWithLinkedItemsArgs = {
  ActivityType: Assessment_Activity_Type_Enum;
  AssignedUser?: InputMaybe<Scalars['String']['input']>;
  CompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id: Scalars['uuid']['input'];
  LinkedItemIds: Array<Scalars['uuid']['input']>;
  OriginalTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  Status: Assessment_Activity_Status_Enum;
  Summary: Scalars['String']['input'];
  Title: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdateChildAcceptanceArgs = {
  ApprovedByUser?: InputMaybe<Scalars['String']['input']>;
  ApprovedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateAcceptedFrom: Scalars['timestamptz']['input'];
  DateAcceptedTo: Scalars['timestamptz']['input'];
  Details?: InputMaybe<Scalars['String']['input']>;
  Id: Scalars['uuid']['input'];
  LatestModifiedAtTimestamp: Scalars['timestamptz']['input'];
  RequestedByUser?: InputMaybe<Scalars['String']['input']>;
  RequestedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  Status?: InputMaybe<Acceptance_Status_Enum>;
  Title?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootUpdateChildActionArgs = {
  ClosedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateDue: Scalars['timestamptz']['input'];
  DateRaised: Scalars['timestamptz']['input'];
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id: Scalars['uuid']['input'];
  OriginalTimestamp: Scalars['timestamptz']['input'];
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  Priority?: InputMaybe<Scalars['Int']['input']>;
  Status: Action_Status_Enum;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  Title: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdateChildControlArgs = {
  object?: InputMaybe<UpdateChildControlInput>;
};


/** mutation root */
export type Mutation_RootUpdateChildDashboardArgs = {
  Content: Scalars['jsonb']['input'];
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id: Scalars['uuid']['input'];
  Name: Scalars['String']['input'];
  Sharing: Dashboard_Sharing_Type_Enum_Action;
};


/** mutation root */
export type Mutation_RootUpdateChildDocumentArgs = {
  object?: InputMaybe<UpdateChildDocumentInput>;
};


/** mutation root */
export type Mutation_RootUpdateChildIndicatorArgs = {
  object?: InputMaybe<UpdateChildIndicatorInput>;
};


/** mutation root */
export type Mutation_RootUpdateChildIssueAssessmentArgs = {
  ActualCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  AssociatedControlIds: Array<Scalars['uuid']['input']>;
  CertifiedIndividual?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Id: Scalars['uuid']['input'];
  IssueCausedBySystemIssue?: InputMaybe<Scalars['Boolean']['input']>;
  IssueCausedByThirdParty?: InputMaybe<Scalars['Boolean']['input']>;
  IssueType?: InputMaybe<Scalars['String']['input']>;
  OriginalTimestamp: Scalars['timestamptz']['input'];
  PoliciesBreached?: InputMaybe<Scalars['String']['input']>;
  PoliciesBreachedIds: Array<Scalars['uuid']['input']>;
  PolicyBreach?: InputMaybe<Scalars['Boolean']['input']>;
  PolicyOwner?: InputMaybe<Scalars['String']['input']>;
  PolicyOwnerCommentary?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  RegulationsBreached?: InputMaybe<Scalars['String']['input']>;
  RegulationsBreachedIds: Array<Scalars['uuid']['input']>;
  RegulatoryBreach?: InputMaybe<Scalars['Boolean']['input']>;
  Reportable?: InputMaybe<Scalars['Boolean']['input']>;
  Severity?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Issue_Assessment_Status_Enum>;
  SystemResponsible?: InputMaybe<Scalars['String']['input']>;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  TargetCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ThirdPartyResponsible?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type Mutation_RootUpdateChildObligationArgs = {
  object?: InputMaybe<UpdateChildObligationInput>;
};


/** mutation root */
export type Mutation_RootUpdateChildRiskArgs = {
  object?: InputMaybe<UpdateChildRiskInput>;
};


/** mutation root */
export type Mutation_RootUpdateChildRiskAssessmentResultArgs = {
  AssessmentId?: InputMaybe<Scalars['uuid']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id: Scalars['uuid']['input'];
  Impact?: InputMaybe<Scalars['Int']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};


/** mutation root */
export type Mutation_RootUpdateDocumentVersionArgs = {
  Content?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  FileId?: InputMaybe<Scalars['uuid']['input']>;
  Id: Scalars['uuid']['input'];
  LatestModifiedAtTimestamp: Scalars['timestamptz']['input'];
  Link?: InputMaybe<Scalars['String']['input']>;
  NextReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReasonForReview?: InputMaybe<Scalars['String']['input']>;
  ReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReviewedBy?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Version_Status_Enum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Type: Document_File_Type_Enum;
  Version: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdateInternalAuditArgs = {
  Input?: InputMaybe<UpdateInternalAuditInput>;
};


/** mutation root */
export type Mutation_RootUpdateNotificationPreferencesArgs = {
  preferenceSet: UpdateNotificationPreferencesInput;
};


/** mutation root */
export type Mutation_RootUpdateTestResultApiArgs = {
  object?: InputMaybe<UpdateTestResultInput>;
};


/** mutation root */
export type Mutation_RootUpdate_Action_UpdateArgs = {
  _append?: InputMaybe<Action_Update_Append_Input>;
  _delete_at_path?: InputMaybe<Action_Update_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Action_Update_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Action_Update_Delete_Key_Input>;
  _prepend?: InputMaybe<Action_Update_Prepend_Input>;
  _set?: InputMaybe<Action_Update_Set_Input>;
  where: Action_Update_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Action_Update_By_PkArgs = {
  _append?: InputMaybe<Action_Update_Append_Input>;
  _delete_at_path?: InputMaybe<Action_Update_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Action_Update_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Action_Update_Delete_Key_Input>;
  _prepend?: InputMaybe<Action_Update_Prepend_Input>;
  _set?: InputMaybe<Action_Update_Set_Input>;
  pk_columns: Action_Update_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Action_Update_ManyArgs = {
  updates: Array<Action_Update_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AppetiteArgs = {
  _append?: InputMaybe<Appetite_Append_Input>;
  _delete_at_path?: InputMaybe<Appetite_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Appetite_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Appetite_Delete_Key_Input>;
  _inc?: InputMaybe<Appetite_Inc_Input>;
  _prepend?: InputMaybe<Appetite_Prepend_Input>;
  _set?: InputMaybe<Appetite_Set_Input>;
  where: Appetite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Appetite_By_PkArgs = {
  _append?: InputMaybe<Appetite_Append_Input>;
  _delete_at_path?: InputMaybe<Appetite_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Appetite_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Appetite_Delete_Key_Input>;
  _inc?: InputMaybe<Appetite_Inc_Input>;
  _prepend?: InputMaybe<Appetite_Prepend_Input>;
  _set?: InputMaybe<Appetite_Set_Input>;
  pk_columns: Appetite_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Appetite_ManyArgs = {
  updates: Array<Appetite_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ApprovalArgs = {
  _set?: InputMaybe<Approval_Set_Input>;
  where: Approval_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Approval_By_PkArgs = {
  _set?: InputMaybe<Approval_Set_Input>;
  pk_columns: Approval_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Approval_LevelArgs = {
  _inc?: InputMaybe<Approval_Level_Inc_Input>;
  _set?: InputMaybe<Approval_Level_Set_Input>;
  where: Approval_Level_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Approval_Level_By_PkArgs = {
  _inc?: InputMaybe<Approval_Level_Inc_Input>;
  _set?: InputMaybe<Approval_Level_Set_Input>;
  pk_columns: Approval_Level_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Approval_Level_ManyArgs = {
  updates: Array<Approval_Level_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Approval_ManyArgs = {
  updates: Array<Approval_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ApproverArgs = {
  _set?: InputMaybe<Approver_Set_Input>;
  where: Approver_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Approver_By_PkArgs = {
  _set?: InputMaybe<Approver_Set_Input>;
  pk_columns: Approver_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Approver_ManyArgs = {
  updates: Array<Approver_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AssessmentArgs = {
  _append?: InputMaybe<Assessment_Append_Input>;
  _delete_at_path?: InputMaybe<Assessment_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Assessment_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Assessment_Delete_Key_Input>;
  _inc?: InputMaybe<Assessment_Inc_Input>;
  _prepend?: InputMaybe<Assessment_Prepend_Input>;
  _set?: InputMaybe<Assessment_Set_Input>;
  where: Assessment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Assessment_ActivityArgs = {
  _append?: InputMaybe<Assessment_Activity_Append_Input>;
  _delete_at_path?: InputMaybe<Assessment_Activity_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Assessment_Activity_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Assessment_Activity_Delete_Key_Input>;
  _prepend?: InputMaybe<Assessment_Activity_Prepend_Input>;
  _set?: InputMaybe<Assessment_Activity_Set_Input>;
  where: Assessment_Activity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Assessment_Activity_By_PkArgs = {
  _append?: InputMaybe<Assessment_Activity_Append_Input>;
  _delete_at_path?: InputMaybe<Assessment_Activity_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Assessment_Activity_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Assessment_Activity_Delete_Key_Input>;
  _prepend?: InputMaybe<Assessment_Activity_Prepend_Input>;
  _set?: InputMaybe<Assessment_Activity_Set_Input>;
  pk_columns: Assessment_Activity_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Assessment_Activity_ManyArgs = {
  updates: Array<Assessment_Activity_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Assessment_By_PkArgs = {
  _append?: InputMaybe<Assessment_Append_Input>;
  _delete_at_path?: InputMaybe<Assessment_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Assessment_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Assessment_Delete_Key_Input>;
  _inc?: InputMaybe<Assessment_Inc_Input>;
  _prepend?: InputMaybe<Assessment_Prepend_Input>;
  _set?: InputMaybe<Assessment_Set_Input>;
  pk_columns: Assessment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Assessment_ManyArgs = {
  updates: Array<Assessment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Attestation_ConfigArgs = {
  _set?: InputMaybe<Attestation_Config_Set_Input>;
  where: Attestation_Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Attestation_Config_By_PkArgs = {
  _set?: InputMaybe<Attestation_Config_Set_Input>;
  pk_columns: Attestation_Config_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Attestation_Config_ManyArgs = {
  updates: Array<Attestation_Config_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Attestation_GroupArgs = {
  _set?: InputMaybe<Attestation_Group_Set_Input>;
  where: Attestation_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Attestation_Group_By_PkArgs = {
  _set?: InputMaybe<Attestation_Group_Set_Input>;
  pk_columns: Attestation_Group_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Attestation_Group_ManyArgs = {
  updates: Array<Attestation_Group_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_OrganisationuserArgs = {
  _set?: InputMaybe<Auth_Organisationuser_Set_Input>;
  where: Auth_Organisationuser_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Organisationuser_By_PkArgs = {
  _set?: InputMaybe<Auth_Organisationuser_Set_Input>;
  pk_columns: Auth_Organisationuser_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Organisationuser_ManyArgs = {
  updates: Array<Auth_Organisationuser_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_UserArgs = {
  _set?: InputMaybe<Auth_User_Set_Input>;
  where: Auth_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_User_By_PkArgs = {
  _set?: InputMaybe<Auth_User_Set_Input>;
  pk_columns: Auth_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_User_ManyArgs = {
  updates: Array<Auth_User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Business_AreaArgs = {
  _set?: InputMaybe<Business_Area_Set_Input>;
  where: Business_Area_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Business_Area_By_PkArgs = {
  _set?: InputMaybe<Business_Area_Set_Input>;
  pk_columns: Business_Area_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Business_Area_ManyArgs = {
  updates: Array<Business_Area_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CauseArgs = {
  _append?: InputMaybe<Cause_Append_Input>;
  _delete_at_path?: InputMaybe<Cause_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Cause_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Cause_Delete_Key_Input>;
  _inc?: InputMaybe<Cause_Inc_Input>;
  _prepend?: InputMaybe<Cause_Prepend_Input>;
  _set?: InputMaybe<Cause_Set_Input>;
  where: Cause_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Cause_By_PkArgs = {
  _append?: InputMaybe<Cause_Append_Input>;
  _delete_at_path?: InputMaybe<Cause_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Cause_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Cause_Delete_Key_Input>;
  _inc?: InputMaybe<Cause_Inc_Input>;
  _prepend?: InputMaybe<Cause_Prepend_Input>;
  _set?: InputMaybe<Cause_Set_Input>;
  pk_columns: Cause_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Cause_ManyArgs = {
  updates: Array<Cause_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CommentArgs = {
  _set?: InputMaybe<Comment_Set_Input>;
  where: Comment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_By_PkArgs = {
  _set?: InputMaybe<Comment_Set_Input>;
  pk_columns: Comment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_ManyArgs = {
  updates: Array<Comment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Monitoring_AssessmentArgs = {
  _append?: InputMaybe<Compliance_Monitoring_Assessment_Append_Input>;
  _delete_at_path?: InputMaybe<Compliance_Monitoring_Assessment_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Compliance_Monitoring_Assessment_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Compliance_Monitoring_Assessment_Delete_Key_Input>;
  _inc?: InputMaybe<Compliance_Monitoring_Assessment_Inc_Input>;
  _prepend?: InputMaybe<Compliance_Monitoring_Assessment_Prepend_Input>;
  _set?: InputMaybe<Compliance_Monitoring_Assessment_Set_Input>;
  where: Compliance_Monitoring_Assessment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Monitoring_Assessment_By_PkArgs = {
  _append?: InputMaybe<Compliance_Monitoring_Assessment_Append_Input>;
  _delete_at_path?: InputMaybe<Compliance_Monitoring_Assessment_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Compliance_Monitoring_Assessment_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Compliance_Monitoring_Assessment_Delete_Key_Input>;
  _inc?: InputMaybe<Compliance_Monitoring_Assessment_Inc_Input>;
  _prepend?: InputMaybe<Compliance_Monitoring_Assessment_Prepend_Input>;
  _set?: InputMaybe<Compliance_Monitoring_Assessment_Set_Input>;
  pk_columns: Compliance_Monitoring_Assessment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Monitoring_Assessment_ManyArgs = {
  updates: Array<Compliance_Monitoring_Assessment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ConsequenceArgs = {
  _append?: InputMaybe<Consequence_Append_Input>;
  _delete_at_path?: InputMaybe<Consequence_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Consequence_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Consequence_Delete_Key_Input>;
  _inc?: InputMaybe<Consequence_Inc_Input>;
  _prepend?: InputMaybe<Consequence_Prepend_Input>;
  _set?: InputMaybe<Consequence_Set_Input>;
  where: Consequence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Consequence_By_PkArgs = {
  _append?: InputMaybe<Consequence_Append_Input>;
  _delete_at_path?: InputMaybe<Consequence_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Consequence_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Consequence_Delete_Key_Input>;
  _inc?: InputMaybe<Consequence_Inc_Input>;
  _prepend?: InputMaybe<Consequence_Prepend_Input>;
  _set?: InputMaybe<Consequence_Set_Input>;
  pk_columns: Consequence_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Consequence_ManyArgs = {
  updates: Array<Consequence_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Control_GroupArgs = {
  _append?: InputMaybe<Control_Group_Append_Input>;
  _delete_at_path?: InputMaybe<Control_Group_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Control_Group_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Control_Group_Delete_Key_Input>;
  _prepend?: InputMaybe<Control_Group_Prepend_Input>;
  _set?: InputMaybe<Control_Group_Set_Input>;
  where: Control_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Control_Group_By_PkArgs = {
  _append?: InputMaybe<Control_Group_Append_Input>;
  _delete_at_path?: InputMaybe<Control_Group_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Control_Group_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Control_Group_Delete_Key_Input>;
  _prepend?: InputMaybe<Control_Group_Prepend_Input>;
  _set?: InputMaybe<Control_Group_Set_Input>;
  pk_columns: Control_Group_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Control_Group_ManyArgs = {
  updates: Array<Control_Group_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ConversationArgs = {
  _set?: InputMaybe<Conversation_Set_Input>;
  where: Conversation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Conversation_By_PkArgs = {
  _set?: InputMaybe<Conversation_Set_Input>;
  pk_columns: Conversation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Conversation_ManyArgs = {
  updates: Array<Conversation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Custom_Attribute_SchemaArgs = {
  _append?: InputMaybe<Custom_Attribute_Schema_Append_Input>;
  _delete_at_path?: InputMaybe<Custom_Attribute_Schema_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Custom_Attribute_Schema_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Custom_Attribute_Schema_Delete_Key_Input>;
  _prepend?: InputMaybe<Custom_Attribute_Schema_Prepend_Input>;
  _set?: InputMaybe<Custom_Attribute_Schema_Set_Input>;
  where: Custom_Attribute_Schema_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Custom_Attribute_Schema_By_PkArgs = {
  _append?: InputMaybe<Custom_Attribute_Schema_Append_Input>;
  _delete_at_path?: InputMaybe<Custom_Attribute_Schema_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Custom_Attribute_Schema_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Custom_Attribute_Schema_Delete_Key_Input>;
  _prepend?: InputMaybe<Custom_Attribute_Schema_Prepend_Input>;
  _set?: InputMaybe<Custom_Attribute_Schema_Set_Input>;
  pk_columns: Custom_Attribute_Schema_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Custom_Attribute_Schema_ManyArgs = {
  updates: Array<Custom_Attribute_Schema_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Custom_RibbonArgs = {
  _append?: InputMaybe<Custom_Ribbon_Append_Input>;
  _delete_at_path?: InputMaybe<Custom_Ribbon_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Custom_Ribbon_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Custom_Ribbon_Delete_Key_Input>;
  _prepend?: InputMaybe<Custom_Ribbon_Prepend_Input>;
  _set?: InputMaybe<Custom_Ribbon_Set_Input>;
  where: Custom_Ribbon_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Custom_Ribbon_By_PkArgs = {
  _append?: InputMaybe<Custom_Ribbon_Append_Input>;
  _delete_at_path?: InputMaybe<Custom_Ribbon_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Custom_Ribbon_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Custom_Ribbon_Delete_Key_Input>;
  _prepend?: InputMaybe<Custom_Ribbon_Prepend_Input>;
  _set?: InputMaybe<Custom_Ribbon_Set_Input>;
  pk_columns: Custom_Ribbon_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Custom_Ribbon_ManyArgs = {
  updates: Array<Custom_Ribbon_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Department_TypeArgs = {
  _set?: InputMaybe<Department_Type_Set_Input>;
  where: Department_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Department_Type_By_PkArgs = {
  _set?: InputMaybe<Department_Type_Set_Input>;
  pk_columns: Department_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Department_Type_GroupArgs = {
  _set?: InputMaybe<Department_Type_Group_Set_Input>;
  where: Department_Type_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Department_Type_Group_ManyArgs = {
  updates: Array<Department_Type_Group_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Department_Type_ManyArgs = {
  updates: Array<Department_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Document_Assessment_ResultArgs = {
  _append?: InputMaybe<Document_Assessment_Result_Append_Input>;
  _delete_at_path?: InputMaybe<Document_Assessment_Result_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Document_Assessment_Result_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Document_Assessment_Result_Delete_Key_Input>;
  _inc?: InputMaybe<Document_Assessment_Result_Inc_Input>;
  _prepend?: InputMaybe<Document_Assessment_Result_Prepend_Input>;
  _set?: InputMaybe<Document_Assessment_Result_Set_Input>;
  where: Document_Assessment_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Document_Assessment_Result_By_PkArgs = {
  _append?: InputMaybe<Document_Assessment_Result_Append_Input>;
  _delete_at_path?: InputMaybe<Document_Assessment_Result_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Document_Assessment_Result_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Document_Assessment_Result_Delete_Key_Input>;
  _inc?: InputMaybe<Document_Assessment_Result_Inc_Input>;
  _prepend?: InputMaybe<Document_Assessment_Result_Prepend_Input>;
  _set?: InputMaybe<Document_Assessment_Result_Set_Input>;
  pk_columns: Document_Assessment_Result_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Document_Assessment_Result_ManyArgs = {
  updates: Array<Document_Assessment_Result_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Form_ConfigurationArgs = {
  _set?: InputMaybe<Form_Configuration_Set_Input>;
  where: Form_Configuration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Configuration_ManyArgs = {
  updates: Array<Form_Configuration_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Field_ConfigurationArgs = {
  _set?: InputMaybe<Form_Field_Configuration_Set_Input>;
  where: Form_Field_Configuration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Field_Configuration_ManyArgs = {
  updates: Array<Form_Field_Configuration_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Field_OrderingArgs = {
  _inc?: InputMaybe<Form_Field_Ordering_Inc_Input>;
  _set?: InputMaybe<Form_Field_Ordering_Set_Input>;
  where: Form_Field_Ordering_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Field_Ordering_ManyArgs = {
  updates: Array<Form_Field_Ordering_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ImpactArgs = {
  _append?: InputMaybe<Impact_Append_Input>;
  _delete_at_path?: InputMaybe<Impact_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Impact_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Impact_Delete_Key_Input>;
  _inc?: InputMaybe<Impact_Inc_Input>;
  _prepend?: InputMaybe<Impact_Prepend_Input>;
  _set?: InputMaybe<Impact_Set_Input>;
  where: Impact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Impact_By_PkArgs = {
  _append?: InputMaybe<Impact_Append_Input>;
  _delete_at_path?: InputMaybe<Impact_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Impact_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Impact_Delete_Key_Input>;
  _inc?: InputMaybe<Impact_Inc_Input>;
  _prepend?: InputMaybe<Impact_Prepend_Input>;
  _set?: InputMaybe<Impact_Set_Input>;
  pk_columns: Impact_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Impact_ManyArgs = {
  updates: Array<Impact_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Indicator_ResultArgs = {
  _append?: InputMaybe<Indicator_Result_Append_Input>;
  _delete_at_path?: InputMaybe<Indicator_Result_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Indicator_Result_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Indicator_Result_Delete_Key_Input>;
  _inc?: InputMaybe<Indicator_Result_Inc_Input>;
  _prepend?: InputMaybe<Indicator_Result_Prepend_Input>;
  _set?: InputMaybe<Indicator_Result_Set_Input>;
  where: Indicator_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Indicator_Result_By_PkArgs = {
  _append?: InputMaybe<Indicator_Result_Append_Input>;
  _delete_at_path?: InputMaybe<Indicator_Result_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Indicator_Result_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Indicator_Result_Delete_Key_Input>;
  _inc?: InputMaybe<Indicator_Result_Inc_Input>;
  _prepend?: InputMaybe<Indicator_Result_Prepend_Input>;
  _set?: InputMaybe<Indicator_Result_Set_Input>;
  pk_columns: Indicator_Result_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Indicator_Result_ManyArgs = {
  updates: Array<Indicator_Result_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Internal_Audit_ReportArgs = {
  _append?: InputMaybe<Internal_Audit_Report_Append_Input>;
  _delete_at_path?: InputMaybe<Internal_Audit_Report_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Internal_Audit_Report_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Internal_Audit_Report_Delete_Key_Input>;
  _inc?: InputMaybe<Internal_Audit_Report_Inc_Input>;
  _prepend?: InputMaybe<Internal_Audit_Report_Prepend_Input>;
  _set?: InputMaybe<Internal_Audit_Report_Set_Input>;
  where: Internal_Audit_Report_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Internal_Audit_Report_By_PkArgs = {
  _append?: InputMaybe<Internal_Audit_Report_Append_Input>;
  _delete_at_path?: InputMaybe<Internal_Audit_Report_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Internal_Audit_Report_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Internal_Audit_Report_Delete_Key_Input>;
  _inc?: InputMaybe<Internal_Audit_Report_Inc_Input>;
  _prepend?: InputMaybe<Internal_Audit_Report_Prepend_Input>;
  _set?: InputMaybe<Internal_Audit_Report_Set_Input>;
  pk_columns: Internal_Audit_Report_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Internal_Audit_Report_ManyArgs = {
  updates: Array<Internal_Audit_Report_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_IssueArgs = {
  _append?: InputMaybe<Issue_Append_Input>;
  _delete_at_path?: InputMaybe<Issue_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Issue_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Issue_Delete_Key_Input>;
  _prepend?: InputMaybe<Issue_Prepend_Input>;
  _set?: InputMaybe<Issue_Set_Input>;
  where: Issue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Issue_By_PkArgs = {
  _append?: InputMaybe<Issue_Append_Input>;
  _delete_at_path?: InputMaybe<Issue_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Issue_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Issue_Delete_Key_Input>;
  _prepend?: InputMaybe<Issue_Prepend_Input>;
  _set?: InputMaybe<Issue_Set_Input>;
  pk_columns: Issue_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Issue_ManyArgs = {
  updates: Array<Issue_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Issue_UpdateArgs = {
  _append?: InputMaybe<Issue_Update_Append_Input>;
  _delete_at_path?: InputMaybe<Issue_Update_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Issue_Update_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Issue_Update_Delete_Key_Input>;
  _prepend?: InputMaybe<Issue_Update_Prepend_Input>;
  _set?: InputMaybe<Issue_Update_Set_Input>;
  where: Issue_Update_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Issue_Update_By_PkArgs = {
  _append?: InputMaybe<Issue_Update_Append_Input>;
  _delete_at_path?: InputMaybe<Issue_Update_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Issue_Update_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Issue_Update_Delete_Key_Input>;
  _prepend?: InputMaybe<Issue_Update_Prepend_Input>;
  _set?: InputMaybe<Issue_Update_Set_Input>;
  pk_columns: Issue_Update_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Issue_Update_ManyArgs = {
  updates: Array<Issue_Update_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Obligation_Assessment_ResultArgs = {
  _append?: InputMaybe<Obligation_Assessment_Result_Append_Input>;
  _delete_at_path?: InputMaybe<Obligation_Assessment_Result_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Obligation_Assessment_Result_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Obligation_Assessment_Result_Delete_Key_Input>;
  _inc?: InputMaybe<Obligation_Assessment_Result_Inc_Input>;
  _prepend?: InputMaybe<Obligation_Assessment_Result_Prepend_Input>;
  _set?: InputMaybe<Obligation_Assessment_Result_Set_Input>;
  where: Obligation_Assessment_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Obligation_Assessment_Result_By_PkArgs = {
  _append?: InputMaybe<Obligation_Assessment_Result_Append_Input>;
  _delete_at_path?: InputMaybe<Obligation_Assessment_Result_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Obligation_Assessment_Result_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Obligation_Assessment_Result_Delete_Key_Input>;
  _inc?: InputMaybe<Obligation_Assessment_Result_Inc_Input>;
  _prepend?: InputMaybe<Obligation_Assessment_Result_Prepend_Input>;
  _set?: InputMaybe<Obligation_Assessment_Result_Set_Input>;
  pk_columns: Obligation_Assessment_Result_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Obligation_Assessment_Result_ManyArgs = {
  updates: Array<Obligation_Assessment_Result_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Obligation_ImpactArgs = {
  _append?: InputMaybe<Obligation_Impact_Append_Input>;
  _delete_at_path?: InputMaybe<Obligation_Impact_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Obligation_Impact_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Obligation_Impact_Delete_Key_Input>;
  _inc?: InputMaybe<Obligation_Impact_Inc_Input>;
  _prepend?: InputMaybe<Obligation_Impact_Prepend_Input>;
  _set?: InputMaybe<Obligation_Impact_Set_Input>;
  where: Obligation_Impact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Obligation_Impact_By_PkArgs = {
  _append?: InputMaybe<Obligation_Impact_Append_Input>;
  _delete_at_path?: InputMaybe<Obligation_Impact_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Obligation_Impact_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Obligation_Impact_Delete_Key_Input>;
  _inc?: InputMaybe<Obligation_Impact_Inc_Input>;
  _prepend?: InputMaybe<Obligation_Impact_Prepend_Input>;
  _set?: InputMaybe<Obligation_Impact_Set_Input>;
  pk_columns: Obligation_Impact_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Obligation_Impact_ManyArgs = {
  updates: Array<Obligation_Impact_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaire_TemplateArgs = {
  _append?: InputMaybe<Questionnaire_Template_Append_Input>;
  _delete_at_path?: InputMaybe<Questionnaire_Template_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Questionnaire_Template_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Questionnaire_Template_Delete_Key_Input>;
  _prepend?: InputMaybe<Questionnaire_Template_Prepend_Input>;
  _set?: InputMaybe<Questionnaire_Template_Set_Input>;
  where: Questionnaire_Template_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaire_Template_By_PkArgs = {
  _append?: InputMaybe<Questionnaire_Template_Append_Input>;
  _delete_at_path?: InputMaybe<Questionnaire_Template_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Questionnaire_Template_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Questionnaire_Template_Delete_Key_Input>;
  _prepend?: InputMaybe<Questionnaire_Template_Prepend_Input>;
  _set?: InputMaybe<Questionnaire_Template_Set_Input>;
  pk_columns: Questionnaire_Template_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaire_Template_ManyArgs = {
  updates: Array<Questionnaire_Template_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaire_Template_VersionArgs = {
  _append?: InputMaybe<Questionnaire_Template_Version_Append_Input>;
  _delete_at_path?: InputMaybe<Questionnaire_Template_Version_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Questionnaire_Template_Version_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Questionnaire_Template_Version_Delete_Key_Input>;
  _prepend?: InputMaybe<Questionnaire_Template_Version_Prepend_Input>;
  _set?: InputMaybe<Questionnaire_Template_Version_Set_Input>;
  where: Questionnaire_Template_Version_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaire_Template_Version_By_PkArgs = {
  _append?: InputMaybe<Questionnaire_Template_Version_Append_Input>;
  _delete_at_path?: InputMaybe<Questionnaire_Template_Version_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Questionnaire_Template_Version_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Questionnaire_Template_Version_Delete_Key_Input>;
  _prepend?: InputMaybe<Questionnaire_Template_Version_Prepend_Input>;
  _set?: InputMaybe<Questionnaire_Template_Version_Set_Input>;
  pk_columns: Questionnaire_Template_Version_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaire_Template_Version_ManyArgs = {
  updates: Array<Questionnaire_Template_Version_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tag_TypeArgs = {
  _set?: InputMaybe<Tag_Type_Set_Input>;
  where: Tag_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tag_Type_By_PkArgs = {
  _set?: InputMaybe<Tag_Type_Set_Input>;
  pk_columns: Tag_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tag_Type_GroupArgs = {
  _set?: InputMaybe<Tag_Type_Group_Set_Input>;
  where: Tag_Type_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tag_Type_Group_ManyArgs = {
  updates: Array<Tag_Type_Group_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tag_Type_ManyArgs = {
  updates: Array<Tag_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TaxonomyArgs = {
  _append?: InputMaybe<Taxonomy_Append_Input>;
  _delete_at_path?: InputMaybe<Taxonomy_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Taxonomy_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Taxonomy_Delete_Key_Input>;
  _prepend?: InputMaybe<Taxonomy_Prepend_Input>;
  _set?: InputMaybe<Taxonomy_Set_Input>;
  where: Taxonomy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Taxonomy_By_PkArgs = {
  _append?: InputMaybe<Taxonomy_Append_Input>;
  _delete_at_path?: InputMaybe<Taxonomy_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Taxonomy_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Taxonomy_Delete_Key_Input>;
  _prepend?: InputMaybe<Taxonomy_Prepend_Input>;
  _set?: InputMaybe<Taxonomy_Set_Input>;
  pk_columns: Taxonomy_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Taxonomy_ManyArgs = {
  updates: Array<Taxonomy_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Third_PartyArgs = {
  _append?: InputMaybe<Third_Party_Append_Input>;
  _delete_at_path?: InputMaybe<Third_Party_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Third_Party_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Third_Party_Delete_Key_Input>;
  _inc?: InputMaybe<Third_Party_Inc_Input>;
  _prepend?: InputMaybe<Third_Party_Prepend_Input>;
  _set?: InputMaybe<Third_Party_Set_Input>;
  where: Third_Party_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Third_Party_By_PkArgs = {
  _append?: InputMaybe<Third_Party_Append_Input>;
  _delete_at_path?: InputMaybe<Third_Party_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Third_Party_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Third_Party_Delete_Key_Input>;
  _inc?: InputMaybe<Third_Party_Inc_Input>;
  _prepend?: InputMaybe<Third_Party_Prepend_Input>;
  _set?: InputMaybe<Third_Party_Set_Input>;
  pk_columns: Third_Party_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Third_Party_ManyArgs = {
  updates: Array<Third_Party_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Third_Party_ResponseArgs = {
  _append?: InputMaybe<Third_Party_Response_Append_Input>;
  _delete_at_path?: InputMaybe<Third_Party_Response_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Third_Party_Response_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Third_Party_Response_Delete_Key_Input>;
  _prepend?: InputMaybe<Third_Party_Response_Prepend_Input>;
  _set?: InputMaybe<Third_Party_Response_Set_Input>;
  where: Third_Party_Response_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Third_Party_Response_By_PkArgs = {
  _append?: InputMaybe<Third_Party_Response_Append_Input>;
  _delete_at_path?: InputMaybe<Third_Party_Response_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Third_Party_Response_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Third_Party_Response_Delete_Key_Input>;
  _prepend?: InputMaybe<Third_Party_Response_Prepend_Input>;
  _set?: InputMaybe<Third_Party_Response_Set_Input>;
  pk_columns: Third_Party_Response_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Third_Party_Response_ManyArgs = {
  updates: Array<Third_Party_Response_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_GroupArgs = {
  _set?: InputMaybe<User_Group_Set_Input>;
  where: User_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Group_By_PkArgs = {
  _set?: InputMaybe<User_Group_Set_Input>;
  pk_columns: User_Group_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Group_ManyArgs = {
  updates: Array<User_Group_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_RolesArgs = {
  roleIds: Array<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdate_User_Search_PreferencesArgs = {
  _set?: InputMaybe<User_Search_Preferences_Set_Input>;
  where: User_Search_Preferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Search_Preferences_ManyArgs = {
  updates: Array<User_Search_Preferences_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Table_PreferencesArgs = {
  _append?: InputMaybe<User_Table_Preferences_Append_Input>;
  _delete_at_path?: InputMaybe<User_Table_Preferences_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Table_Preferences_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Table_Preferences_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Table_Preferences_Prepend_Input>;
  _set?: InputMaybe<User_Table_Preferences_Set_Input>;
  where: User_Table_Preferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Table_Preferences_ManyArgs = {
  updates: Array<User_Table_Preferences_Updates>;
};

/** columns and relationships of "risksmart.node" */
export type Node = {
  __typename?: 'node';
  Id: Scalars['uuid']['output'];
  ObjectType: Parent_Type_Enum;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  acceptance?: Maybe<Acceptance>;
  /** An object relationship */
  action?: Maybe<Action>;
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An object relationship */
  control?: Maybe<Control>;
  /** An object relationship */
  document?: Maybe<Document>;
  /** An object relationship */
  documentFile?: Maybe<Document_File>;
  /** An object relationship */
  indicator?: Maybe<Indicator>;
  /** An object relationship */
  issue?: Maybe<Issue>;
  /** An object relationship */
  issue_assessment?: Maybe<Issue_Assessment>;
  /** An object relationship */
  obligation?: Maybe<Obligation>;
  /** An object relationship */
  risk?: Maybe<Risk>;
  /** An object relationship */
  thirdParty?: Maybe<Third_Party>;
};


/** columns and relationships of "risksmart.node" */
export type NodeAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};

/** aggregated selection of "risksmart.node" */
export type Node_Aggregate = {
  __typename?: 'node_aggregate';
  aggregate?: Maybe<Node_Aggregate_Fields>;
  nodes: Array<Node>;
};

/** aggregate fields of "risksmart.node" */
export type Node_Aggregate_Fields = {
  __typename?: 'node_aggregate_fields';
  avg?: Maybe<Node_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Node_Max_Fields>;
  min?: Maybe<Node_Min_Fields>;
  stddev?: Maybe<Node_Stddev_Fields>;
  stddev_pop?: Maybe<Node_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Node_Stddev_Samp_Fields>;
  sum?: Maybe<Node_Sum_Fields>;
  var_pop?: Maybe<Node_Var_Pop_Fields>;
  var_samp?: Maybe<Node_Var_Samp_Fields>;
  variance?: Maybe<Node_Variance_Fields>;
};


/** aggregate fields of "risksmart.node" */
export type Node_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Node_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Node_Avg_Fields = {
  __typename?: 'node_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.node". All fields are combined with a logical 'AND'. */
export type Node_Bool_Exp = {
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ObjectType?: InputMaybe<Parent_Type_Enum_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  _and?: InputMaybe<Array<Node_Bool_Exp>>;
  _not?: InputMaybe<Node_Bool_Exp>;
  _or?: InputMaybe<Array<Node_Bool_Exp>>;
  acceptance?: InputMaybe<Acceptance_Bool_Exp>;
  action?: InputMaybe<Action_Bool_Exp>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  control?: InputMaybe<Control_Bool_Exp>;
  document?: InputMaybe<Document_Bool_Exp>;
  documentFile?: InputMaybe<Document_File_Bool_Exp>;
  indicator?: InputMaybe<Indicator_Bool_Exp>;
  issue?: InputMaybe<Issue_Bool_Exp>;
  issue_assessment?: InputMaybe<Issue_Assessment_Bool_Exp>;
  obligation?: InputMaybe<Obligation_Bool_Exp>;
  risk?: InputMaybe<Risk_Bool_Exp>;
  thirdParty?: InputMaybe<Third_Party_Bool_Exp>;
};

/** aggregate max on columns */
export type Node_Max_Fields = {
  __typename?: 'node_max_fields';
  Id?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Node_Min_Fields = {
  __typename?: 'node_min_fields';
  Id?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "risksmart.node". */
export type Node_Order_By = {
  Id?: InputMaybe<Order_By>;
  ObjectType?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  acceptance?: InputMaybe<Acceptance_Order_By>;
  action?: InputMaybe<Action_Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  control?: InputMaybe<Control_Order_By>;
  document?: InputMaybe<Document_Order_By>;
  documentFile?: InputMaybe<Document_File_Order_By>;
  indicator?: InputMaybe<Indicator_Order_By>;
  issue?: InputMaybe<Issue_Order_By>;
  issue_assessment?: InputMaybe<Issue_Assessment_Order_By>;
  obligation?: InputMaybe<Obligation_Order_By>;
  risk?: InputMaybe<Risk_Order_By>;
  thirdParty?: InputMaybe<Third_Party_Order_By>;
};

/** select columns of table "risksmart.node" */
export const Node_Select_Column = {
  /** column name */
  Id: 'Id',
  /** column name */
  ObjectType: 'ObjectType',
  /** column name */
  SequentialId: 'SequentialId'
} as const;

export type Node_Select_Column = typeof Node_Select_Column[keyof typeof Node_Select_Column];
/** aggregate stddev on columns */
export type Node_Stddev_Fields = {
  __typename?: 'node_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Node_Stddev_Pop_Fields = {
  __typename?: 'node_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Node_Stddev_Samp_Fields = {
  __typename?: 'node_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "node" */
export type Node_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Node_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Node_Stream_Cursor_Value_Input = {
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ObjectType?: InputMaybe<Parent_Type_Enum>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Node_Sum_Fields = {
  __typename?: 'node_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Node_Var_Pop_Fields = {
  __typename?: 'node_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Node_Var_Samp_Fields = {
  __typename?: 'node_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Node_Variance_Fields = {
  __typename?: 'node_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** columns and relationships of "risksmart.obligation" */
export type Obligation = {
  __typename?: 'obligation';
  Adherence: Scalars['String']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  Interpretation?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title: Scalars['String']['output'];
  Type: Obligation_Type_Enum;
  /** An array relationship */
  actions: Array<Action_Parent>;
  /** An aggregate relationship */
  actions_aggregate: Action_Parent_Aggregate;
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An array relationship */
  assessmentResults: Array<Assessment_Result_Parent>;
  /** An aggregate relationship */
  assessmentResults_aggregate: Assessment_Result_Parent_Aggregate;
  /** An array relationship */
  contributorGroups: Array<Contributor_Group>;
  /** An aggregate relationship */
  contributorGroups_aggregate: Contributor_Group_Aggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: Contributor_Aggregate;
  /** An array relationship */
  controls: Array<Control_Parent>;
  /** An aggregate relationship */
  controls_aggregate: Control_Parent_Aggregate;
  /** An object relationship */
  createdBy?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An array relationship */
  impacts: Array<Obligation_Impact>;
  /** An array relationship */
  issues: Array<Issue_Parent>;
  /** An aggregate relationship */
  issues_aggregate: Issue_Parent_Aggregate;
  /** An object relationship */
  modifiedBy?: Maybe<User>;
  /** An object relationship */
  obligation_type: Obligation_Type;
  /** An object relationship */
  org?: Maybe<Auth_Organisation>;
  /** An array relationship */
  ownerGroups: Array<Owner_Group>;
  /** An aggregate relationship */
  ownerGroups_aggregate: Owner_Group_Aggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: Owner_Aggregate;
  /** An object relationship */
  parent?: Maybe<Obligation>;
  /** An object relationship */
  parentNode?: Maybe<Node>;
  /** An object relationship */
  schedule?: Maybe<Schedule>;
  /** An object relationship */
  scheduleState?: Maybe<Schedule_State>;
  /** An array relationship */
  tags: Array<Tag>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationActionsArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationAssessmentResultsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationAssessmentResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationContributorGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationContributorsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationContributors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationControlsArgs = {
  distinct_on?: InputMaybe<Array<Control_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Parent_Order_By>>;
  where?: InputMaybe<Control_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationControls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Parent_Order_By>>;
  where?: InputMaybe<Control_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationImpactsArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Impact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Impact_Order_By>>;
  where?: InputMaybe<Obligation_Impact_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationIssuesArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Order_By>>;
  where?: InputMaybe<Issue_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationIssues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Order_By>>;
  where?: InputMaybe<Issue_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationOwnerGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationOwnersArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationOwners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationTagsArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};

/** aggregated selection of "risksmart.obligation" */
export type Obligation_Aggregate = {
  __typename?: 'obligation_aggregate';
  aggregate?: Maybe<Obligation_Aggregate_Fields>;
  nodes: Array<Obligation>;
};

export type Obligation_Aggregate_Bool_Exp = {
  count?: InputMaybe<Obligation_Aggregate_Bool_Exp_Count>;
};

export type Obligation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Obligation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Obligation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.obligation" */
export type Obligation_Aggregate_Fields = {
  __typename?: 'obligation_aggregate_fields';
  avg?: Maybe<Obligation_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Obligation_Max_Fields>;
  min?: Maybe<Obligation_Min_Fields>;
  stddev?: Maybe<Obligation_Stddev_Fields>;
  stddev_pop?: Maybe<Obligation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Obligation_Stddev_Samp_Fields>;
  sum?: Maybe<Obligation_Sum_Fields>;
  var_pop?: Maybe<Obligation_Var_Pop_Fields>;
  var_samp?: Maybe<Obligation_Var_Samp_Fields>;
  variance?: Maybe<Obligation_Variance_Fields>;
};


/** aggregate fields of "risksmart.obligation" */
export type Obligation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Obligation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.obligation" */
export type Obligation_Aggregate_Order_By = {
  avg?: InputMaybe<Obligation_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Obligation_Max_Order_By>;
  min?: InputMaybe<Obligation_Min_Order_By>;
  stddev?: InputMaybe<Obligation_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Obligation_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Obligation_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Obligation_Sum_Order_By>;
  var_pop?: InputMaybe<Obligation_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Obligation_Var_Samp_Order_By>;
  variance?: InputMaybe<Obligation_Variance_Order_By>;
};

/** columns and relationships of "risksmart.obligation_assessment_result" */
export type Obligation_Assessment_Result = {
  __typename?: 'obligation_assessment_result';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser: Scalars['String']['output'];
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType: Scalars['String']['output'];
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  files: Array<Relation_File>;
  /** An aggregate relationship */
  files_aggregate: Relation_File_Aggregate;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An array relationship */
  parents: Array<Assessment_Result_Parent>;
  /** An aggregate relationship */
  parents_aggregate: Assessment_Result_Parent_Aggregate;
};


/** columns and relationships of "risksmart.obligation_assessment_result" */
export type Obligation_Assessment_ResultCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.obligation_assessment_result" */
export type Obligation_Assessment_ResultAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation_assessment_result" */
export type Obligation_Assessment_ResultFilesArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation_assessment_result" */
export type Obligation_Assessment_ResultFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation_assessment_result" */
export type Obligation_Assessment_ResultParentsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation_assessment_result" */
export type Obligation_Assessment_ResultParents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};

/** aggregated selection of "risksmart.obligation_assessment_result" */
export type Obligation_Assessment_Result_Aggregate = {
  __typename?: 'obligation_assessment_result_aggregate';
  aggregate?: Maybe<Obligation_Assessment_Result_Aggregate_Fields>;
  nodes: Array<Obligation_Assessment_Result>;
};

/** aggregate fields of "risksmart.obligation_assessment_result" */
export type Obligation_Assessment_Result_Aggregate_Fields = {
  __typename?: 'obligation_assessment_result_aggregate_fields';
  avg?: Maybe<Obligation_Assessment_Result_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Obligation_Assessment_Result_Max_Fields>;
  min?: Maybe<Obligation_Assessment_Result_Min_Fields>;
  stddev?: Maybe<Obligation_Assessment_Result_Stddev_Fields>;
  stddev_pop?: Maybe<Obligation_Assessment_Result_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Obligation_Assessment_Result_Stddev_Samp_Fields>;
  sum?: Maybe<Obligation_Assessment_Result_Sum_Fields>;
  var_pop?: Maybe<Obligation_Assessment_Result_Var_Pop_Fields>;
  var_samp?: Maybe<Obligation_Assessment_Result_Var_Samp_Fields>;
  variance?: Maybe<Obligation_Assessment_Result_Variance_Fields>;
};


/** aggregate fields of "risksmart.obligation_assessment_result" */
export type Obligation_Assessment_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Obligation_Assessment_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Obligation_Assessment_Result_Append_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.obligation_assessment_result_audit" */
export type Obligation_Assessment_Result_Audit = {
  __typename?: 'obligation_assessment_result_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "risksmart.obligation_assessment_result_audit" */
export type Obligation_Assessment_Result_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.obligation_assessment_result_audit". All fields are combined with a logical 'AND'. */
export type Obligation_Assessment_Result_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Rating?: InputMaybe<Int_Comparison_Exp>;
  RatingType?: InputMaybe<String_Comparison_Exp>;
  Rationale?: InputMaybe<String_Comparison_Exp>;
  TestDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  _and?: InputMaybe<Array<Obligation_Assessment_Result_Audit_Bool_Exp>>;
  _not?: InputMaybe<Obligation_Assessment_Result_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Obligation_Assessment_Result_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.obligation_assessment_result_audit". */
export type Obligation_Assessment_Result_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  RatingType?: InputMaybe<Order_By>;
  Rationale?: InputMaybe<Order_By>;
  TestDate?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.obligation_assessment_result_audit" */
export const Obligation_Assessment_Result_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Rating: 'Rating',
  /** column name */
  RatingType: 'RatingType',
  /** column name */
  Rationale: 'Rationale',
  /** column name */
  TestDate: 'TestDate'
} as const;

export type Obligation_Assessment_Result_Audit_Select_Column = typeof Obligation_Assessment_Result_Audit_Select_Column[keyof typeof Obligation_Assessment_Result_Audit_Select_Column];
/** Streaming cursor of the table "obligation_assessment_result_audit" */
export type Obligation_Assessment_Result_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Obligation_Assessment_Result_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Obligation_Assessment_Result_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate avg on columns */
export type Obligation_Assessment_Result_Avg_Fields = {
  __typename?: 'obligation_assessment_result_avg_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.obligation_assessment_result". All fields are combined with a logical 'AND'. */
export type Obligation_Assessment_Result_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Rating?: InputMaybe<Int_Comparison_Exp>;
  RatingType?: InputMaybe<String_Comparison_Exp>;
  Rationale?: InputMaybe<String_Comparison_Exp>;
  TestDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  _and?: InputMaybe<Array<Obligation_Assessment_Result_Bool_Exp>>;
  _not?: InputMaybe<Obligation_Assessment_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Obligation_Assessment_Result_Bool_Exp>>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  files?: InputMaybe<Relation_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parents?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
  parents_aggregate?: InputMaybe<Assessment_Result_Parent_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.obligation_assessment_result" */
export const Obligation_Assessment_Result_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  ObligationAssessmentResultPkey: 'obligation_assessment_result_pkey'
} as const;

export type Obligation_Assessment_Result_Constraint = typeof Obligation_Assessment_Result_Constraint[keyof typeof Obligation_Assessment_Result_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Obligation_Assessment_Result_Delete_At_Path_Input = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Obligation_Assessment_Result_Delete_Elem_Input = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Obligation_Assessment_Result_Delete_Key_Input = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.obligation_assessment_result" */
export type Obligation_Assessment_Result_Inc_Input = {
  Rating?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.obligation_assessment_result" */
export type Obligation_Assessment_Result_Insert_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  files?: InputMaybe<Relation_File_Arr_Rel_Insert_Input>;
  parents?: InputMaybe<Assessment_Result_Parent_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Obligation_Assessment_Result_Max_Fields = {
  __typename?: 'obligation_assessment_result_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Obligation_Assessment_Result_Min_Fields = {
  __typename?: 'obligation_assessment_result_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "risksmart.obligation_assessment_result" */
export type Obligation_Assessment_Result_Mutation_Response = {
  __typename?: 'obligation_assessment_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Obligation_Assessment_Result>;
};

/** input type for inserting object relation for remote table "risksmart.obligation_assessment_result" */
export type Obligation_Assessment_Result_Obj_Rel_Insert_Input = {
  data: Obligation_Assessment_Result_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Obligation_Assessment_Result_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.obligation_assessment_result" */
export type Obligation_Assessment_Result_On_Conflict = {
  constraint: Obligation_Assessment_Result_Constraint;
  update_columns?: Array<Obligation_Assessment_Result_Update_Column>;
  where?: InputMaybe<Obligation_Assessment_Result_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.obligation_assessment_result". */
export type Obligation_Assessment_Result_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  RatingType?: InputMaybe<Order_By>;
  Rationale?: InputMaybe<Order_By>;
  TestDate?: InputMaybe<Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parents_aggregate?: InputMaybe<Assessment_Result_Parent_Aggregate_Order_By>;
};

/** primary key columns input for table: risksmart.obligation_assessment_result */
export type Obligation_Assessment_Result_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Obligation_Assessment_Result_Prepend_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.obligation_assessment_result" */
export const Obligation_Assessment_Result_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Rating: 'Rating',
  /** column name */
  RatingType: 'RatingType',
  /** column name */
  Rationale: 'Rationale',
  /** column name */
  TestDate: 'TestDate'
} as const;

export type Obligation_Assessment_Result_Select_Column = typeof Obligation_Assessment_Result_Select_Column[keyof typeof Obligation_Assessment_Result_Select_Column];
/** input type for updating data in table "risksmart.obligation_assessment_result" */
export type Obligation_Assessment_Result_Set_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Obligation_Assessment_Result_Stddev_Fields = {
  __typename?: 'obligation_assessment_result_stddev_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Obligation_Assessment_Result_Stddev_Pop_Fields = {
  __typename?: 'obligation_assessment_result_stddev_pop_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Obligation_Assessment_Result_Stddev_Samp_Fields = {
  __typename?: 'obligation_assessment_result_stddev_samp_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "obligation_assessment_result" */
export type Obligation_Assessment_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Obligation_Assessment_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Obligation_Assessment_Result_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Obligation_Assessment_Result_Sum_Fields = {
  __typename?: 'obligation_assessment_result_sum_fields';
  Rating?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.obligation_assessment_result" */
export const Obligation_Assessment_Result_Update_Column = {
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Rating: 'Rating',
  /** column name */
  Rationale: 'Rationale',
  /** column name */
  TestDate: 'TestDate'
} as const;

export type Obligation_Assessment_Result_Update_Column = typeof Obligation_Assessment_Result_Update_Column[keyof typeof Obligation_Assessment_Result_Update_Column];
export type Obligation_Assessment_Result_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Obligation_Assessment_Result_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Obligation_Assessment_Result_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Obligation_Assessment_Result_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Obligation_Assessment_Result_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Obligation_Assessment_Result_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Obligation_Assessment_Result_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Obligation_Assessment_Result_Set_Input>;
  /** filter the rows which have to be updated */
  where: Obligation_Assessment_Result_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Obligation_Assessment_Result_Var_Pop_Fields = {
  __typename?: 'obligation_assessment_result_var_pop_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Obligation_Assessment_Result_Var_Samp_Fields = {
  __typename?: 'obligation_assessment_result_var_samp_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Obligation_Assessment_Result_Variance_Fields = {
  __typename?: 'obligation_assessment_result_variance_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.obligation_assessment_status" */
export type Obligation_Assessment_Status = {
  __typename?: 'obligation_assessment_status';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** Boolean expression to filter rows from the table "risksmart.obligation_assessment_status". All fields are combined with a logical 'AND'. */
export type Obligation_Assessment_Status_Bool_Exp = {
  Comment?: InputMaybe<String_Comparison_Exp>;
  Value?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Obligation_Assessment_Status_Bool_Exp>>;
  _not?: InputMaybe<Obligation_Assessment_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Obligation_Assessment_Status_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.obligation_assessment_status". */
export type Obligation_Assessment_Status_Order_By = {
  Comment?: InputMaybe<Order_By>;
  Value?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.obligation_assessment_status" */
export const Obligation_Assessment_Status_Select_Column = {
  /** column name */
  Comment: 'Comment',
  /** column name */
  Value: 'Value'
} as const;

export type Obligation_Assessment_Status_Select_Column = typeof Obligation_Assessment_Status_Select_Column[keyof typeof Obligation_Assessment_Status_Select_Column];
/** Streaming cursor of the table "obligation_assessment_status" */
export type Obligation_Assessment_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Obligation_Assessment_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Obligation_Assessment_Status_Stream_Cursor_Value_Input = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.obligation_audit" */
export type Obligation_Audit = {
  __typename?: 'obligation_audit';
  Adherence?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  Interpretation?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title: Scalars['String']['output'];
  Type: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.obligation_audit" */
export type Obligation_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.obligation_audit" */
export type Obligation_Audit_Aggregate = {
  __typename?: 'obligation_audit_aggregate';
  aggregate?: Maybe<Obligation_Audit_Aggregate_Fields>;
  nodes: Array<Obligation_Audit>;
};

/** aggregate fields of "risksmart.obligation_audit" */
export type Obligation_Audit_Aggregate_Fields = {
  __typename?: 'obligation_audit_aggregate_fields';
  avg?: Maybe<Obligation_Audit_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Obligation_Audit_Max_Fields>;
  min?: Maybe<Obligation_Audit_Min_Fields>;
  stddev?: Maybe<Obligation_Audit_Stddev_Fields>;
  stddev_pop?: Maybe<Obligation_Audit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Obligation_Audit_Stddev_Samp_Fields>;
  sum?: Maybe<Obligation_Audit_Sum_Fields>;
  var_pop?: Maybe<Obligation_Audit_Var_Pop_Fields>;
  var_samp?: Maybe<Obligation_Audit_Var_Samp_Fields>;
  variance?: Maybe<Obligation_Audit_Variance_Fields>;
};


/** aggregate fields of "risksmart.obligation_audit" */
export type Obligation_Audit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Obligation_Audit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Obligation_Audit_Avg_Fields = {
  __typename?: 'obligation_audit_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.obligation_audit". All fields are combined with a logical 'AND'. */
export type Obligation_Audit_Bool_Exp = {
  Adherence?: InputMaybe<String_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  Interpretation?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  OrgKey?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  Type?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Obligation_Audit_Bool_Exp>>;
  _not?: InputMaybe<Obligation_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Obligation_Audit_Bool_Exp>>;
};

/** aggregate max on columns */
export type Obligation_Audit_Max_Fields = {
  __typename?: 'obligation_audit_max_fields';
  Adherence?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Interpretation?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Obligation_Audit_Min_Fields = {
  __typename?: 'obligation_audit_min_fields';
  Adherence?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Interpretation?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "risksmart.obligation_audit". */
export type Obligation_Audit_Order_By = {
  Adherence?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Interpretation?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.obligation_audit" */
export const Obligation_Audit_Select_Column = {
  /** column name */
  Adherence: 'Adherence',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  Interpretation: 'Interpretation',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  OrgKey: 'OrgKey',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Title: 'Title',
  /** column name */
  Type: 'Type'
} as const;

export type Obligation_Audit_Select_Column = typeof Obligation_Audit_Select_Column[keyof typeof Obligation_Audit_Select_Column];
/** aggregate stddev on columns */
export type Obligation_Audit_Stddev_Fields = {
  __typename?: 'obligation_audit_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Obligation_Audit_Stddev_Pop_Fields = {
  __typename?: 'obligation_audit_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Obligation_Audit_Stddev_Samp_Fields = {
  __typename?: 'obligation_audit_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "obligation_audit" */
export type Obligation_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Obligation_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Obligation_Audit_Stream_Cursor_Value_Input = {
  Adherence?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Interpretation?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Obligation_Audit_Sum_Fields = {
  __typename?: 'obligation_audit_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Obligation_Audit_Var_Pop_Fields = {
  __typename?: 'obligation_audit_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Obligation_Audit_Var_Samp_Fields = {
  __typename?: 'obligation_audit_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Obligation_Audit_Variance_Fields = {
  __typename?: 'obligation_audit_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type Obligation_Avg_Fields = {
  __typename?: 'obligation_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.obligation" */
export type Obligation_Avg_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.obligation". All fields are combined with a logical 'AND'. */
export type Obligation_Bool_Exp = {
  Adherence?: InputMaybe<String_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  Interpretation?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  OrgKey?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  Type?: InputMaybe<Obligation_Type_Enum_Comparison_Exp>;
  _and?: InputMaybe<Array<Obligation_Bool_Exp>>;
  _not?: InputMaybe<Obligation_Bool_Exp>;
  _or?: InputMaybe<Array<Obligation_Bool_Exp>>;
  actions?: InputMaybe<Action_Parent_Bool_Exp>;
  actions_aggregate?: InputMaybe<Action_Parent_Aggregate_Bool_Exp>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  assessmentResults?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
  assessmentResults_aggregate?: InputMaybe<Assessment_Result_Parent_Aggregate_Bool_Exp>;
  contributorGroups?: InputMaybe<Contributor_Group_Bool_Exp>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Bool_Exp>;
  contributors?: InputMaybe<Contributor_Bool_Exp>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Bool_Exp>;
  controls?: InputMaybe<Control_Parent_Bool_Exp>;
  controls_aggregate?: InputMaybe<Control_Parent_Aggregate_Bool_Exp>;
  createdBy?: InputMaybe<User_Bool_Exp>;
  departments?: InputMaybe<Department_Bool_Exp>;
  impacts?: InputMaybe<Obligation_Impact_Bool_Exp>;
  issues?: InputMaybe<Issue_Parent_Bool_Exp>;
  issues_aggregate?: InputMaybe<Issue_Parent_Aggregate_Bool_Exp>;
  modifiedBy?: InputMaybe<User_Bool_Exp>;
  obligation_type?: InputMaybe<Obligation_Type_Bool_Exp>;
  org?: InputMaybe<Auth_Organisation_Bool_Exp>;
  ownerGroups?: InputMaybe<Owner_Group_Bool_Exp>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Bool_Exp>;
  owners?: InputMaybe<Owner_Bool_Exp>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Bool_Exp>;
  parent?: InputMaybe<Obligation_Bool_Exp>;
  parentNode?: InputMaybe<Node_Bool_Exp>;
  schedule?: InputMaybe<Schedule_Bool_Exp>;
  scheduleState?: InputMaybe<Schedule_State_Bool_Exp>;
  tags?: InputMaybe<Tag_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.obligation" */
export const Obligation_Constraint = {
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxObligationOrgkeySequentialid: 'idx_obligation_orgkey_sequentialid',
  /** unique or primary key constraint on columns "Id" */
  ObligationPkey: 'obligation_pkey'
} as const;

export type Obligation_Constraint = typeof Obligation_Constraint[keyof typeof Obligation_Constraint];
/** columns and relationships of "risksmart.obligation_impact" */
export type Obligation_Impact = {
  __typename?: 'obligation_impact';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ImpactRating: Scalars['smallint']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentObligationId: Scalars['uuid']['output'];
  /** An object relationship */
  createdBy?: Maybe<User>;
  /** An object relationship */
  modifiedBy?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parent?: Maybe<Obligation>;
};


/** columns and relationships of "risksmart.obligation_impact" */
export type Obligation_ImpactCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "risksmart.obligation_impact" */
export type Obligation_Impact_Aggregate_Order_By = {
  avg?: InputMaybe<Obligation_Impact_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Obligation_Impact_Max_Order_By>;
  min?: InputMaybe<Obligation_Impact_Min_Order_By>;
  stddev?: InputMaybe<Obligation_Impact_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Obligation_Impact_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Obligation_Impact_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Obligation_Impact_Sum_Order_By>;
  var_pop?: InputMaybe<Obligation_Impact_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Obligation_Impact_Var_Samp_Order_By>;
  variance?: InputMaybe<Obligation_Impact_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Obligation_Impact_Append_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.obligation_impact" */
export type Obligation_Impact_Arr_Rel_Insert_Input = {
  data: Array<Obligation_Impact_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Obligation_Impact_On_Conflict>;
};

/** columns and relationships of "risksmart.obligation_impact_audit" */
export type Obligation_Impact_Audit = {
  __typename?: 'obligation_impact_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ImpactRating: Scalars['smallint']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentObligationId: Scalars['uuid']['output'];
};


/** columns and relationships of "risksmart.obligation_impact_audit" */
export type Obligation_Impact_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.obligation_impact_audit". All fields are combined with a logical 'AND'. */
export type Obligation_Impact_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ImpactRating?: InputMaybe<Smallint_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  OrgKey?: InputMaybe<String_Comparison_Exp>;
  ParentObligationId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Obligation_Impact_Audit_Bool_Exp>>;
  _not?: InputMaybe<Obligation_Impact_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Obligation_Impact_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.obligation_impact_audit". */
export type Obligation_Impact_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ImpactRating?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  ParentObligationId?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.obligation_impact_audit" */
export const Obligation_Impact_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ImpactRating: 'ImpactRating',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  OrgKey: 'OrgKey',
  /** column name */
  ParentObligationId: 'ParentObligationId'
} as const;

export type Obligation_Impact_Audit_Select_Column = typeof Obligation_Impact_Audit_Select_Column[keyof typeof Obligation_Impact_Audit_Select_Column];
/** Streaming cursor of the table "obligation_impact_audit" */
export type Obligation_Impact_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Obligation_Impact_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Obligation_Impact_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactRating?: InputMaybe<Scalars['smallint']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentObligationId?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by avg() on columns of table "risksmart.obligation_impact" */
export type Obligation_Impact_Avg_Order_By = {
  ImpactRating?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.obligation_impact". All fields are combined with a logical 'AND'. */
export type Obligation_Impact_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ImpactRating?: InputMaybe<Smallint_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  OrgKey?: InputMaybe<String_Comparison_Exp>;
  ParentObligationId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Obligation_Impact_Bool_Exp>>;
  _not?: InputMaybe<Obligation_Impact_Bool_Exp>;
  _or?: InputMaybe<Array<Obligation_Impact_Bool_Exp>>;
  createdBy?: InputMaybe<User_Bool_Exp>;
  modifiedBy?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parent?: InputMaybe<Obligation_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.obligation_impact" */
export const Obligation_Impact_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  ObligationImpactPkey: 'obligation_impact_pkey'
} as const;

export type Obligation_Impact_Constraint = typeof Obligation_Impact_Constraint[keyof typeof Obligation_Impact_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Obligation_Impact_Delete_At_Path_Input = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Obligation_Impact_Delete_Elem_Input = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Obligation_Impact_Delete_Key_Input = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.obligation_impact" */
export type Obligation_Impact_Inc_Input = {
  ImpactRating?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "risksmart.obligation_impact" */
export type Obligation_Impact_Insert_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ImpactRating?: InputMaybe<Scalars['smallint']['input']>;
  ParentObligationId?: InputMaybe<Scalars['uuid']['input']>;
  parent?: InputMaybe<Obligation_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "risksmart.obligation_impact" */
export type Obligation_Impact_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ImpactRating?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  ParentObligationId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.obligation_impact" */
export type Obligation_Impact_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ImpactRating?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  ParentObligationId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.obligation_impact" */
export type Obligation_Impact_Mutation_Response = {
  __typename?: 'obligation_impact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Obligation_Impact>;
};

/** on_conflict condition type for table "risksmart.obligation_impact" */
export type Obligation_Impact_On_Conflict = {
  constraint: Obligation_Impact_Constraint;
  update_columns?: Array<Obligation_Impact_Update_Column>;
  where?: InputMaybe<Obligation_Impact_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.obligation_impact". */
export type Obligation_Impact_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ImpactRating?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  ParentObligationId?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<User_Order_By>;
  modifiedBy?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parent?: InputMaybe<Obligation_Order_By>;
};

/** primary key columns input for table: risksmart.obligation_impact */
export type Obligation_Impact_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Obligation_Impact_Prepend_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.obligation_impact" */
export const Obligation_Impact_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ImpactRating: 'ImpactRating',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  OrgKey: 'OrgKey',
  /** column name */
  ParentObligationId: 'ParentObligationId'
} as const;

export type Obligation_Impact_Select_Column = typeof Obligation_Impact_Select_Column[keyof typeof Obligation_Impact_Select_Column];
/** input type for updating data in table "risksmart.obligation_impact" */
export type Obligation_Impact_Set_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactRating?: InputMaybe<Scalars['smallint']['input']>;
  ParentObligationId?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by stddev() on columns of table "risksmart.obligation_impact" */
export type Obligation_Impact_Stddev_Order_By = {
  ImpactRating?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "risksmart.obligation_impact" */
export type Obligation_Impact_Stddev_Pop_Order_By = {
  ImpactRating?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "risksmart.obligation_impact" */
export type Obligation_Impact_Stddev_Samp_Order_By = {
  ImpactRating?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "obligation_impact" */
export type Obligation_Impact_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Obligation_Impact_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Obligation_Impact_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactRating?: InputMaybe<Scalars['smallint']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentObligationId?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by sum() on columns of table "risksmart.obligation_impact" */
export type Obligation_Impact_Sum_Order_By = {
  ImpactRating?: InputMaybe<Order_By>;
};

/** update columns of table "risksmart.obligation_impact" */
export const Obligation_Impact_Update_Column = {
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ImpactRating: 'ImpactRating',
  /** column name */
  ParentObligationId: 'ParentObligationId'
} as const;

export type Obligation_Impact_Update_Column = typeof Obligation_Impact_Update_Column[keyof typeof Obligation_Impact_Update_Column];
export type Obligation_Impact_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Obligation_Impact_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Obligation_Impact_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Obligation_Impact_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Obligation_Impact_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Obligation_Impact_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Obligation_Impact_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Obligation_Impact_Set_Input>;
  /** filter the rows which have to be updated */
  where: Obligation_Impact_Bool_Exp;
};

/** order by var_pop() on columns of table "risksmart.obligation_impact" */
export type Obligation_Impact_Var_Pop_Order_By = {
  ImpactRating?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "risksmart.obligation_impact" */
export type Obligation_Impact_Var_Samp_Order_By = {
  ImpactRating?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "risksmart.obligation_impact" */
export type Obligation_Impact_Variance_Order_By = {
  ImpactRating?: InputMaybe<Order_By>;
};

/** input type for inserting data into table "risksmart.obligation" */
export type Obligation_Insert_Input = {
  Adherence?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Interpretation?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Obligation_Type_Enum>;
  actions?: InputMaybe<Action_Parent_Arr_Rel_Insert_Input>;
  assessmentResults?: InputMaybe<Assessment_Result_Parent_Arr_Rel_Insert_Input>;
  contributorGroups?: InputMaybe<Contributor_Group_Arr_Rel_Insert_Input>;
  contributors?: InputMaybe<Contributor_Arr_Rel_Insert_Input>;
  controls?: InputMaybe<Control_Parent_Arr_Rel_Insert_Input>;
  departments?: InputMaybe<Department_Arr_Rel_Insert_Input>;
  impacts?: InputMaybe<Obligation_Impact_Arr_Rel_Insert_Input>;
  issues?: InputMaybe<Issue_Parent_Arr_Rel_Insert_Input>;
  ownerGroups?: InputMaybe<Owner_Group_Arr_Rel_Insert_Input>;
  owners?: InputMaybe<Owner_Arr_Rel_Insert_Input>;
  parent?: InputMaybe<Obligation_Obj_Rel_Insert_Input>;
  schedule?: InputMaybe<Schedule_Obj_Rel_Insert_Input>;
  tags?: InputMaybe<Tag_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Obligation_Max_Fields = {
  __typename?: 'obligation_max_fields';
  Adherence?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Interpretation?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.obligation" */
export type Obligation_Max_Order_By = {
  Adherence?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Interpretation?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Obligation_Min_Fields = {
  __typename?: 'obligation_min_fields';
  Adherence?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Interpretation?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.obligation" */
export type Obligation_Min_Order_By = {
  Adherence?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Interpretation?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.obligation" */
export type Obligation_Mutation_Response = {
  __typename?: 'obligation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Obligation>;
};

/** input type for inserting object relation for remote table "risksmart.obligation" */
export type Obligation_Obj_Rel_Insert_Input = {
  data: Obligation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Obligation_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.obligation" */
export type Obligation_On_Conflict = {
  constraint: Obligation_Constraint;
  update_columns?: Array<Obligation_Update_Column>;
  where?: InputMaybe<Obligation_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.obligation". */
export type Obligation_Order_By = {
  Adherence?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Interpretation?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  actions_aggregate?: InputMaybe<Action_Parent_Aggregate_Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  assessmentResults_aggregate?: InputMaybe<Assessment_Result_Parent_Aggregate_Order_By>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Order_By>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Order_By>;
  controls_aggregate?: InputMaybe<Control_Parent_Aggregate_Order_By>;
  createdBy?: InputMaybe<User_Order_By>;
  departments_aggregate?: InputMaybe<Department_Aggregate_Order_By>;
  impacts_aggregate?: InputMaybe<Obligation_Impact_Aggregate_Order_By>;
  issues_aggregate?: InputMaybe<Issue_Parent_Aggregate_Order_By>;
  modifiedBy?: InputMaybe<User_Order_By>;
  obligation_type?: InputMaybe<Obligation_Type_Order_By>;
  org?: InputMaybe<Auth_Organisation_Order_By>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Order_By>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Order_By>;
  parent?: InputMaybe<Obligation_Order_By>;
  parentNode?: InputMaybe<Node_Order_By>;
  schedule?: InputMaybe<Schedule_Order_By>;
  scheduleState?: InputMaybe<Schedule_State_Order_By>;
  tags_aggregate?: InputMaybe<Tag_Aggregate_Order_By>;
};

/** select columns of table "risksmart.obligation" */
export const Obligation_Select_Column = {
  /** column name */
  Adherence: 'Adherence',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  Interpretation: 'Interpretation',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  OrgKey: 'OrgKey',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Title: 'Title',
  /** column name */
  Type: 'Type'
} as const;

export type Obligation_Select_Column = typeof Obligation_Select_Column[keyof typeof Obligation_Select_Column];
/** aggregate stddev on columns */
export type Obligation_Stddev_Fields = {
  __typename?: 'obligation_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.obligation" */
export type Obligation_Stddev_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Obligation_Stddev_Pop_Fields = {
  __typename?: 'obligation_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.obligation" */
export type Obligation_Stddev_Pop_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Obligation_Stddev_Samp_Fields = {
  __typename?: 'obligation_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.obligation" */
export type Obligation_Stddev_Samp_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "obligation" */
export type Obligation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Obligation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Obligation_Stream_Cursor_Value_Input = {
  Adherence?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Interpretation?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Obligation_Type_Enum>;
};

/** aggregate sum on columns */
export type Obligation_Sum_Fields = {
  __typename?: 'obligation_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.obligation" */
export type Obligation_Sum_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** columns and relationships of "risksmart.obligation_type" */
export type Obligation_Type = {
  __typename?: 'obligation_type';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
  /** An array relationship */
  obligations: Array<Obligation>;
  /** An aggregate relationship */
  obligations_aggregate: Obligation_Aggregate;
};


/** columns and relationships of "risksmart.obligation_type" */
export type Obligation_TypeObligationsArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Order_By>>;
  where?: InputMaybe<Obligation_Bool_Exp>;
};


/** columns and relationships of "risksmart.obligation_type" */
export type Obligation_TypeObligations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Order_By>>;
  where?: InputMaybe<Obligation_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "risksmart.obligation_type". All fields are combined with a logical 'AND'. */
export type Obligation_Type_Bool_Exp = {
  Comment?: InputMaybe<String_Comparison_Exp>;
  Value?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Obligation_Type_Bool_Exp>>;
  _not?: InputMaybe<Obligation_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Obligation_Type_Bool_Exp>>;
  obligations?: InputMaybe<Obligation_Bool_Exp>;
  obligations_aggregate?: InputMaybe<Obligation_Aggregate_Bool_Exp>;
};

export const Obligation_Type_Enum = {
  /** Chapter */
  Chapter: 'chapter',
  /** Rule */
  Rule: 'rule',
  /** High-level Standard */
  Standard: 'standard'
} as const;

export type Obligation_Type_Enum = typeof Obligation_Type_Enum[keyof typeof Obligation_Type_Enum];
/** Boolean expression to compare columns of type "obligation_type_enum". All fields are combined with logical 'AND'. */
export type Obligation_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Obligation_Type_Enum>;
  _in?: InputMaybe<Array<Obligation_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Obligation_Type_Enum>;
  _nin?: InputMaybe<Array<Obligation_Type_Enum>>;
};

/** Ordering options when selecting data from "risksmart.obligation_type". */
export type Obligation_Type_Order_By = {
  Comment?: InputMaybe<Order_By>;
  Value?: InputMaybe<Order_By>;
  obligations_aggregate?: InputMaybe<Obligation_Aggregate_Order_By>;
};

/** select columns of table "risksmart.obligation_type" */
export const Obligation_Type_Select_Column = {
  /** column name */
  Comment: 'Comment',
  /** column name */
  Value: 'Value'
} as const;

export type Obligation_Type_Select_Column = typeof Obligation_Type_Select_Column[keyof typeof Obligation_Type_Select_Column];
/** Streaming cursor of the table "obligation_type" */
export type Obligation_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Obligation_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Obligation_Type_Stream_Cursor_Value_Input = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.obligation" */
export const Obligation_Update_Column = {
  /** column name */
  Adherence: 'Adherence',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Interpretation: 'Interpretation',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  Title: 'Title',
  /** column name */
  Type: 'Type'
} as const;

export type Obligation_Update_Column = typeof Obligation_Update_Column[keyof typeof Obligation_Update_Column];
/** aggregate var_pop on columns */
export type Obligation_Var_Pop_Fields = {
  __typename?: 'obligation_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.obligation" */
export type Obligation_Var_Pop_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Obligation_Var_Samp_Fields = {
  __typename?: 'obligation_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.obligation" */
export type Obligation_Var_Samp_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Obligation_Variance_Fields = {
  __typename?: 'obligation_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.obligation" */
export type Obligation_Variance_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
};

/** column ordering options */
export const Order_By = {
  /** in ascending order, nulls last */
  Asc: 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst: 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast: 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc: 'desc',
  /** in descending order, nulls first */
  DescNullsFirst: 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast: 'desc_nulls_last'
} as const;

export type Order_By = typeof Order_By[keyof typeof Order_By];
/** columns and relationships of "risksmart.owner" */
export type Owner = {
  __typename?: 'owner';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  UserId: Scalars['String']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parentAction?: Maybe<Action>;
  /** An object relationship */
  parentAssessment?: Maybe<Assessment>;
  /** An object relationship */
  parentDashboard?: Maybe<Dashboard>;
  /** An object relationship */
  parentInternalAuditEntity?: Maybe<Internal_Audit_Entity>;
  /** An object relationship */
  parentInternalAuditReport?: Maybe<Internal_Audit_Report>;
  /** An object relationship */
  parentIssue?: Maybe<Issue>;
  /** An object relationship */
  parentRisk?: Maybe<Risk>;
  /** An object relationship */
  parentThirdParty?: Maybe<Third_Party>;
  /** An object relationship */
  user?: Maybe<User>;
};

/** aggregated selection of "risksmart.owner" */
export type Owner_Aggregate = {
  __typename?: 'owner_aggregate';
  aggregate?: Maybe<Owner_Aggregate_Fields>;
  nodes: Array<Owner>;
};

export type Owner_Aggregate_Bool_Exp = {
  count?: InputMaybe<Owner_Aggregate_Bool_Exp_Count>;
};

export type Owner_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Owner_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Owner_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.owner" */
export type Owner_Aggregate_Fields = {
  __typename?: 'owner_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Owner_Max_Fields>;
  min?: Maybe<Owner_Min_Fields>;
};


/** aggregate fields of "risksmart.owner" */
export type Owner_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Owner_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.owner" */
export type Owner_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Owner_Max_Order_By>;
  min?: InputMaybe<Owner_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.owner" */
export type Owner_Arr_Rel_Insert_Input = {
  data: Array<Owner_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Owner_On_Conflict>;
};

/** columns and relationships of "risksmart.owner_audit" */
export type Owner_Audit = {
  __typename?: 'owner_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId: Scalars['uuid']['output'];
  UserId: Scalars['String']['output'];
};

/** order by aggregate values of table "risksmart.owner_audit" */
export type Owner_Audit_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Owner_Audit_Max_Order_By>;
  min?: InputMaybe<Owner_Audit_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.owner_audit". All fields are combined with a logical 'AND'. */
export type Owner_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  UserId?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Owner_Audit_Bool_Exp>>;
  _not?: InputMaybe<Owner_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Owner_Audit_Bool_Exp>>;
};

/** order by max() on columns of table "risksmart.owner_audit" */
export type Owner_Audit_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.owner_audit" */
export type Owner_Audit_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "risksmart.owner_audit". */
export type Owner_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.owner_audit" */
export const Owner_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  UserId: 'UserId'
} as const;

export type Owner_Audit_Select_Column = typeof Owner_Audit_Select_Column[keyof typeof Owner_Audit_Select_Column];
/** Streaming cursor of the table "owner_audit" */
export type Owner_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Owner_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Owner_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.owner". All fields are combined with a logical 'AND'. */
export type Owner_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  UserId?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Owner_Bool_Exp>>;
  _not?: InputMaybe<Owner_Bool_Exp>;
  _or?: InputMaybe<Array<Owner_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parentAction?: InputMaybe<Action_Bool_Exp>;
  parentAssessment?: InputMaybe<Assessment_Bool_Exp>;
  parentDashboard?: InputMaybe<Dashboard_Bool_Exp>;
  parentInternalAuditEntity?: InputMaybe<Internal_Audit_Entity_Bool_Exp>;
  parentInternalAuditReport?: InputMaybe<Internal_Audit_Report_Bool_Exp>;
  parentIssue?: InputMaybe<Issue_Bool_Exp>;
  parentRisk?: InputMaybe<Risk_Bool_Exp>;
  parentThirdParty?: InputMaybe<Third_Party_Bool_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.owner" */
export const Owner_Constraint = {
  /** unique or primary key constraint on columns "ParentId", "UserId" */
  OwnerPkey: 'owner_pkey'
} as const;

export type Owner_Constraint = typeof Owner_Constraint[keyof typeof Owner_Constraint];
/** columns and relationships of "risksmart.owner_group" */
export type Owner_Group = {
  __typename?: 'owner_group';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  UserGroupId: Scalars['uuid']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  group?: Maybe<User_Group>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parentAction?: Maybe<Action>;
  /** An object relationship */
  parentAssessment?: Maybe<Assessment>;
  /** An object relationship */
  parentInternalAuditEntity?: Maybe<Internal_Audit_Entity>;
  /** An object relationship */
  parentInternalAuditReport?: Maybe<Internal_Audit_Report>;
  /** An object relationship */
  parentIssue?: Maybe<Issue>;
  /** An object relationship */
  parentRisk?: Maybe<Risk>;
  /** An object relationship */
  parentThirdParty?: Maybe<Third_Party>;
};

/** aggregated selection of "risksmart.owner_group" */
export type Owner_Group_Aggregate = {
  __typename?: 'owner_group_aggregate';
  aggregate?: Maybe<Owner_Group_Aggregate_Fields>;
  nodes: Array<Owner_Group>;
};

export type Owner_Group_Aggregate_Bool_Exp = {
  count?: InputMaybe<Owner_Group_Aggregate_Bool_Exp_Count>;
};

export type Owner_Group_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Owner_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Owner_Group_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.owner_group" */
export type Owner_Group_Aggregate_Fields = {
  __typename?: 'owner_group_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Owner_Group_Max_Fields>;
  min?: Maybe<Owner_Group_Min_Fields>;
};


/** aggregate fields of "risksmart.owner_group" */
export type Owner_Group_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Owner_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.owner_group" */
export type Owner_Group_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Owner_Group_Max_Order_By>;
  min?: InputMaybe<Owner_Group_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.owner_group" */
export type Owner_Group_Arr_Rel_Insert_Input = {
  data: Array<Owner_Group_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Owner_Group_On_Conflict>;
};

/** columns and relationships of "risksmart.owner_group_audit" */
export type Owner_Group_Audit = {
  __typename?: 'owner_group_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId: Scalars['uuid']['output'];
  UserGroupId: Scalars['uuid']['output'];
};

/** order by aggregate values of table "risksmart.owner_group_audit" */
export type Owner_Group_Audit_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Owner_Group_Audit_Max_Order_By>;
  min?: InputMaybe<Owner_Group_Audit_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.owner_group_audit". All fields are combined with a logical 'AND'. */
export type Owner_Group_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  UserGroupId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Owner_Group_Audit_Bool_Exp>>;
  _not?: InputMaybe<Owner_Group_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Owner_Group_Audit_Bool_Exp>>;
};

/** order by max() on columns of table "risksmart.owner_group_audit" */
export type Owner_Group_Audit_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.owner_group_audit" */
export type Owner_Group_Audit_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "risksmart.owner_group_audit". */
export type Owner_Group_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.owner_group_audit" */
export const Owner_Group_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  UserGroupId: 'UserGroupId'
} as const;

export type Owner_Group_Audit_Select_Column = typeof Owner_Group_Audit_Select_Column[keyof typeof Owner_Group_Audit_Select_Column];
/** Streaming cursor of the table "owner_group_audit" */
export type Owner_Group_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Owner_Group_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Owner_Group_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.owner_group". All fields are combined with a logical 'AND'. */
export type Owner_Group_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  UserGroupId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Owner_Group_Bool_Exp>>;
  _not?: InputMaybe<Owner_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Owner_Group_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  group?: InputMaybe<User_Group_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parentAction?: InputMaybe<Action_Bool_Exp>;
  parentAssessment?: InputMaybe<Assessment_Bool_Exp>;
  parentInternalAuditEntity?: InputMaybe<Internal_Audit_Entity_Bool_Exp>;
  parentInternalAuditReport?: InputMaybe<Internal_Audit_Report_Bool_Exp>;
  parentIssue?: InputMaybe<Issue_Bool_Exp>;
  parentRisk?: InputMaybe<Risk_Bool_Exp>;
  parentThirdParty?: InputMaybe<Third_Party_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.owner_group" */
export const Owner_Group_Constraint = {
  /** unique or primary key constraint on columns "ParentId", "UserGroupId" */
  OwnerGroupPkey: 'owner_group_pkey'
} as const;

export type Owner_Group_Constraint = typeof Owner_Group_Constraint[keyof typeof Owner_Group_Constraint];
/** input type for inserting data into table "risksmart.owner_group" */
export type Owner_Group_Insert_Input = {
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  group?: InputMaybe<User_Group_Obj_Rel_Insert_Input>;
  parentAction?: InputMaybe<Action_Obj_Rel_Insert_Input>;
  parentAssessment?: InputMaybe<Assessment_Obj_Rel_Insert_Input>;
  parentInternalAuditEntity?: InputMaybe<Internal_Audit_Entity_Obj_Rel_Insert_Input>;
  parentInternalAuditReport?: InputMaybe<Internal_Audit_Report_Obj_Rel_Insert_Input>;
  parentIssue?: InputMaybe<Issue_Obj_Rel_Insert_Input>;
  parentRisk?: InputMaybe<Risk_Obj_Rel_Insert_Input>;
  parentThirdParty?: InputMaybe<Third_Party_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Owner_Group_Max_Fields = {
  __typename?: 'owner_group_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.owner_group" */
export type Owner_Group_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Owner_Group_Min_Fields = {
  __typename?: 'owner_group_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.owner_group" */
export type Owner_Group_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.owner_group" */
export type Owner_Group_Mutation_Response = {
  __typename?: 'owner_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Owner_Group>;
};

/** on_conflict condition type for table "risksmart.owner_group" */
export type Owner_Group_On_Conflict = {
  constraint: Owner_Group_Constraint;
  update_columns?: Array<Owner_Group_Update_Column>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.owner_group". */
export type Owner_Group_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  group?: InputMaybe<User_Group_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parentAction?: InputMaybe<Action_Order_By>;
  parentAssessment?: InputMaybe<Assessment_Order_By>;
  parentInternalAuditEntity?: InputMaybe<Internal_Audit_Entity_Order_By>;
  parentInternalAuditReport?: InputMaybe<Internal_Audit_Report_Order_By>;
  parentIssue?: InputMaybe<Issue_Order_By>;
  parentRisk?: InputMaybe<Risk_Order_By>;
  parentThirdParty?: InputMaybe<Third_Party_Order_By>;
};

/** select columns of table "risksmart.owner_group" */
export const Owner_Group_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  UserGroupId: 'UserGroupId'
} as const;

export type Owner_Group_Select_Column = typeof Owner_Group_Select_Column[keyof typeof Owner_Group_Select_Column];
/** placeholder for update columns of table "risksmart.owner_group" (current role has no relevant permissions) */
export const Owner_Group_Update_Column = {
  /** placeholder (do not use) */
  Placeholder: '_PLACEHOLDER'
} as const;

export type Owner_Group_Update_Column = typeof Owner_Group_Update_Column[keyof typeof Owner_Group_Update_Column];
/** input type for inserting data into table "risksmart.owner" */
export type Owner_Insert_Input = {
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
  parentAction?: InputMaybe<Action_Obj_Rel_Insert_Input>;
  parentAssessment?: InputMaybe<Assessment_Obj_Rel_Insert_Input>;
  parentDashboard?: InputMaybe<Dashboard_Obj_Rel_Insert_Input>;
  parentInternalAuditEntity?: InputMaybe<Internal_Audit_Entity_Obj_Rel_Insert_Input>;
  parentInternalAuditReport?: InputMaybe<Internal_Audit_Report_Obj_Rel_Insert_Input>;
  parentIssue?: InputMaybe<Issue_Obj_Rel_Insert_Input>;
  parentRisk?: InputMaybe<Risk_Obj_Rel_Insert_Input>;
  parentThirdParty?: InputMaybe<Third_Party_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Owner_Max_Fields = {
  __typename?: 'owner_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.owner" */
export type Owner_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Owner_Min_Fields = {
  __typename?: 'owner_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.owner" */
export type Owner_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.owner" */
export type Owner_Mutation_Response = {
  __typename?: 'owner_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Owner>;
};

/** on_conflict condition type for table "risksmart.owner" */
export type Owner_On_Conflict = {
  constraint: Owner_Constraint;
  update_columns?: Array<Owner_Update_Column>;
  where?: InputMaybe<Owner_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.owner". */
export type Owner_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parentAction?: InputMaybe<Action_Order_By>;
  parentAssessment?: InputMaybe<Assessment_Order_By>;
  parentDashboard?: InputMaybe<Dashboard_Order_By>;
  parentInternalAuditEntity?: InputMaybe<Internal_Audit_Entity_Order_By>;
  parentInternalAuditReport?: InputMaybe<Internal_Audit_Report_Order_By>;
  parentIssue?: InputMaybe<Issue_Order_By>;
  parentRisk?: InputMaybe<Risk_Order_By>;
  parentThirdParty?: InputMaybe<Third_Party_Order_By>;
  user?: InputMaybe<User_Order_By>;
};

/** select columns of table "risksmart.owner" */
export const Owner_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  UserId: 'UserId'
} as const;

export type Owner_Select_Column = typeof Owner_Select_Column[keyof typeof Owner_Select_Column];
/** placeholder for update columns of table "risksmart.owner" (current role has no relevant permissions) */
export const Owner_Update_Column = {
  /** placeholder (do not use) */
  Placeholder: '_PLACEHOLDER'
} as const;

export type Owner_Update_Column = typeof Owner_Update_Column[keyof typeof Owner_Update_Column];
export const Parent_Type_Enum = {
  /** Acceptance */
  Acceptance: 'acceptance',
  /** Action */
  Action: 'action',
  /** Action Update */
  ActionUpdate: 'action_update',
  /** Appetite */
  Appetite: 'appetite',
  /** Approval result */
  ApprovalResult: 'approval_result',
  /** Assessment */
  Assessment: 'assessment',
  /** Assessment Activity */
  AssessmentActivity: 'assessment_activity',
  /** Attestation Record */
  AttestationRecord: 'attestation_record',
  /** Audit entities */
  Audit: 'audit',
  /** Business Area */
  BusinessArea: 'business_area',
  /** Cause */
  Cause: 'cause',
  /** Change Request */
  ChangeRequest: 'change_request',
  /** Compliance Monitoring Assessment */
  ComplianceMonitoringAssessment: 'compliance_monitoring_assessment',
  /** Consequence */
  Consequence: 'consequence',
  /** Control */
  Control: 'control',
  /** Control Group */
  ControlGroup: 'control_group',
  /** Controlled Risk Assessment Result */
  ControlledRiskAssessmentResult: 'controlled_risk_assessment_result',
  /** Conversation */
  Conversation: 'conversation',
  /** Custom attribute schema */
  CustomAttributeSchema: 'custom_attribute_schema',
  /** Custom Ribbon Item */
  CustomRibbon: 'custom_ribbon',
  /** Dashboard */
  Dashboard: 'dashboard',
  /** Data Import */
  DataImport: 'data_import',
  /** Document */
  Document: 'document',
  /** Document Assessment */
  DocumentAssessment: 'document_assessment',
  /** Document Assessment Result */
  DocumentAssessmentResult: 'document_assessment_result',
  /** Document version */
  DocumentFile: 'document_file',
  /** Impact */
  Impact: 'impact',
  /** Impact Parent */
  ImpactParent: 'impact_parent',
  /** Impact rating */
  ImpactRating: 'impact_rating',
  /** Indicator */
  Indicator: 'indicator',
  /** Indicator Result */
  IndicatorResult: 'indicator_result',
  /** Internal Audit */
  InternalAuditEntity: 'internal_audit_entity',
  /** Internal Audit Report */
  InternalAuditReport: 'internal_audit_report',
  /** Internal Audit Controlled Risk Assessment Result */
  InternalAuditReportControlledRiskAssessmentResult: 'internal_audit_report_controlled_risk_assessment_result',
  /** Internal Audit Document Assessment Result */
  InternalAuditReportDocumentAssessmentResult: 'internal_audit_report_document_assessment_result',
  /** Internal Audit Obligation Assessment Result */
  InternalAuditReportObligationAssessmentResult: 'internal_audit_report_obligation_assessment_result',
  /** Internal Audit Test Result */
  InternalAuditReportTestResult: 'internal_audit_report_test_result',
  /** Internal Audit Uncontrolled Risk Assessment Result */
  InternalAuditReportUncontrolledRiskAssessmentResult: 'internal_audit_report_uncontrolled_risk_assessment_result',
  /** Issue */
  Issue: 'issue',
  /** Issue Assessment */
  IssueAssessment: 'issue_assessment',
  /** Issue assessment audit */
  IssueAssessmentAudit: 'issue_assessment_audit',
  /** Issue Update */
  IssueUpdate: 'issue_update',
  /** Linked item */
  LinkedItem: 'linked_item',
  /** Multiple datasource reporting */
  MultiReporting: 'multi_reporting',
  /** My items */
  MyItems: 'my_items',
  /** Notification */
  Notification: 'notification',
  /** Obligation */
  Obligation: 'obligation',
  /** Obligation Assessment */
  ObligationAssessment: 'obligation_assessment',
  /** Obligation Assessment Result */
  ObligationAssessmentResult: 'obligation_assessment_result',
  /** Obligation Impact */
  ObligationImpact: 'obligation_impact',
  /** Organisation shared dashboards */
  OrganisationDashboard: 'organisation_dashboard',
  /** Public issue form */
  PublicIssueForm: 'public_issue_form',
  /** Public policies */
  PublicPolicies: 'public_policies',
  /** Questionnaire Template */
  QuestionnaireTemplate: 'questionnaire_template',
  /** Questionnaire Template Version */
  QuestionnaireTemplateVersion: 'questionnaire_template_version',
  /** Reports */
  Report: 'report',
  /** Risk */
  Risk: 'risk',
  /** Risk assessment */
  RiskAssessment: 'risk_assessment',
  /** Risk Assessment Result */
  RiskAssessmentResult: 'risk_assessment_result',
  /** Risk Controlled Assessment */
  RiskControlledAssessment: 'risk_controlled_assessment',
  /** Tier 1 risk */
  RiskTier_1: 'risk_tier_1',
  /** Risk Uncontrolled Assessment */
  RiskUncontrolledAssessment: 'risk_uncontrolled_assessment',
  /** Second Line Controlled Risk Assessment Result */
  SecondLineAssessmentControlledRiskAssessmentResult: 'second_line_assessment_controlled_risk_assessment_result',
  /** Second Line Document Assessment Result */
  SecondLineAssessmentDocumentAssessmentResult: 'second_line_assessment_document_assessment_result',
  /** Second Line Obligation Assessment Result */
  SecondLineAssessmentObligationAssessmentResult: 'second_line_assessment_obligation_assessment_result',
  /** Second Line Test Result */
  SecondLineAssessmentTestResult: 'second_line_assessment_test_result',
  /** Second Line Uncontrolled Risk Assessment Result */
  SecondLineAssessmentUncontrolledRiskAssessmentResult: 'second_line_assessment_uncontrolled_risk_assessment_result',
  /** Settings */
  Settings: 'settings',
  /** Taxonomy */
  Taxonomy: 'taxonomy',
  /** Test Result */
  TestResult: 'test_result',
  /** Third Party */
  ThirdParty: 'third_party',
  /** Third Party Response */
  ThirdPartyResponse: 'third_party_response',
  /** Uncontrolled Risk Assessment Result */
  UncontrolledRiskAssessmentResult: 'uncontrolled_risk_assessment_result'
} as const;

export type Parent_Type_Enum = typeof Parent_Type_Enum[keyof typeof Parent_Type_Enum];
/** Boolean expression to compare columns of type "parent_type_enum". All fields are combined with logical 'AND'. */
export type Parent_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Parent_Type_Enum>;
  _in?: InputMaybe<Array<Parent_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Parent_Type_Enum>;
  _nin?: InputMaybe<Array<Parent_Type_Enum>>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "risksmart.acceptance" */
  acceptance: Array<Acceptance>;
  /** fetch aggregated fields from the table: "risksmart.acceptance" */
  acceptance_aggregate: Acceptance_Aggregate;
  /** fetch data from the table: "risksmart.acceptance_audit" */
  acceptance_audit: Array<Acceptance_Audit>;
  /** fetch data from the table: "risksmart.acceptance_audit" using primary key columns */
  acceptance_audit_by_pk?: Maybe<Acceptance_Audit>;
  /** fetch data from the table: "risksmart.acceptance" using primary key columns */
  acceptance_by_pk?: Maybe<Acceptance>;
  /** fetch data from the table: "risksmart.acceptance_parent" */
  acceptance_parent: Array<Acceptance_Parent>;
  /** fetch aggregated fields from the table: "risksmart.acceptance_parent" */
  acceptance_parent_aggregate: Acceptance_Parent_Aggregate;
  /** fetch data from the table: "risksmart.acceptance_parent_audit" */
  acceptance_parent_audit: Array<Acceptance_Parent_Audit>;
  /** fetch data from the table: "risksmart.acceptance_parent_audit" using primary key columns */
  acceptance_parent_audit_by_pk?: Maybe<Acceptance_Parent_Audit>;
  /** fetch data from the table: "risksmart.acceptance_parent" using primary key columns */
  acceptance_parent_by_pk?: Maybe<Acceptance_Parent>;
  /** fetch data from the table: "risksmart.action" */
  action: Array<Action>;
  /** fetch aggregated fields from the table: "risksmart.action" */
  action_aggregate: Action_Aggregate;
  /** fetch data from the table: "risksmart.action_audit" */
  action_audit: Array<Action_Audit>;
  /** fetch aggregated fields from the table: "risksmart.action_audit" */
  action_audit_aggregate: Action_Audit_Aggregate;
  /** fetch data from the table: "risksmart.action_audit" using primary key columns */
  action_audit_by_pk?: Maybe<Action_Audit>;
  /** fetch data from the table: "risksmart.action" using primary key columns */
  action_by_pk?: Maybe<Action>;
  /** fetch data from the table: "risksmart.action_parent" */
  action_parent: Array<Action_Parent>;
  /** fetch aggregated fields from the table: "risksmart.action_parent" */
  action_parent_aggregate: Action_Parent_Aggregate;
  /** fetch data from the table: "risksmart.action_parent_audit" */
  action_parent_audit: Array<Action_Parent_Audit>;
  /** fetch data from the table: "risksmart.action_parent_audit" using primary key columns */
  action_parent_audit_by_pk?: Maybe<Action_Parent_Audit>;
  /** fetch data from the table: "risksmart.action_parent" using primary key columns */
  action_parent_by_pk?: Maybe<Action_Parent>;
  /** fetch data from the table: "risksmart.action_update" */
  action_update: Array<Action_Update>;
  /** fetch aggregated fields from the table: "risksmart.action_update" */
  action_update_aggregate: Action_Update_Aggregate;
  /** fetch data from the table: "risksmart.action_update_audit" */
  action_update_audit: Array<Action_Update_Audit>;
  /** fetch data from the table: "risksmart.action_update_audit" using primary key columns */
  action_update_audit_by_pk?: Maybe<Action_Update_Audit>;
  /** fetch data from the table: "risksmart.action_update" using primary key columns */
  action_update_by_pk?: Maybe<Action_Update>;
  /** fetch data from the table: "risksmart.action_update_summary_view" */
  action_update_summary: Array<Action_Update_Summary>;
  /** fetch aggregated fields from the table: "risksmart.action_update_summary_view" */
  action_update_summary_aggregate: Action_Update_Summary_Aggregate;
  /** fetch data from the table: "risksmart.aggregation_org" */
  aggregation_org: Array<Aggregation_Org>;
  /** fetch data from the table: "risksmart.aggregation_org" using primary key columns */
  aggregation_org_by_pk?: Maybe<Aggregation_Org>;
  /** fetch data from the table: "risksmart.ancestor_contributor_view" */
  ancestor_contributor: Array<Ancestor_Contributor>;
  /** fetch data from the table: "risksmart.appetite" */
  appetite: Array<Appetite>;
  /** fetch aggregated fields from the table: "risksmart.appetite" */
  appetite_aggregate: Appetite_Aggregate;
  /** fetch data from the table: "risksmart.appetite_audit" */
  appetite_audit: Array<Appetite_Audit>;
  /** fetch data from the table: "risksmart.appetite_audit" using primary key columns */
  appetite_audit_by_pk?: Maybe<Appetite_Audit>;
  /** fetch data from the table: "risksmart.appetite" using primary key columns */
  appetite_by_pk?: Maybe<Appetite>;
  /** fetch data from the table: "risksmart.appetite_parent" */
  appetite_parent: Array<Appetite_Parent>;
  /** fetch aggregated fields from the table: "risksmart.appetite_parent" */
  appetite_parent_aggregate: Appetite_Parent_Aggregate;
  /** fetch data from the table: "risksmart.appetite_parent_audit" */
  appetite_parent_audit: Array<Appetite_Parent_Audit>;
  /** fetch aggregated fields from the table: "risksmart.appetite_parent_audit" */
  appetite_parent_audit_aggregate: Appetite_Parent_Audit_Aggregate;
  /** fetch data from the table: "risksmart.appetite_parent_audit" using primary key columns */
  appetite_parent_audit_by_pk?: Maybe<Appetite_Parent_Audit>;
  /** fetch data from the table: "risksmart.appetite_parent" using primary key columns */
  appetite_parent_by_pk?: Maybe<Appetite_Parent>;
  /** fetch data from the table: "risksmart.approval" */
  approval: Array<Approval>;
  /** fetch data from the table: "risksmart.approval_audit" */
  approval_audit: Array<Approval_Audit>;
  /** fetch data from the table: "risksmart.approval_audit" using primary key columns */
  approval_audit_by_pk?: Maybe<Approval_Audit>;
  /** fetch data from the table: "risksmart.approval" using primary key columns */
  approval_by_pk?: Maybe<Approval>;
  /** fetch data from the table: "risksmart.approval_level" */
  approval_level: Array<Approval_Level>;
  /** fetch data from the table: "risksmart.approval_level_audit" */
  approval_level_audit: Array<Approval_Level_Audit>;
  /** fetch data from the table: "risksmart.approval_level_audit" using primary key columns */
  approval_level_audit_by_pk?: Maybe<Approval_Level_Audit>;
  /** fetch data from the table: "risksmart.approval_level" using primary key columns */
  approval_level_by_pk?: Maybe<Approval_Level>;
  /** fetch data from the table: "risksmart.approver" */
  approver: Array<Approver>;
  /** fetch aggregated fields from the table: "risksmart.approver" */
  approver_aggregate: Approver_Aggregate;
  /** fetch data from the table: "risksmart.approver_audit" */
  approver_audit: Array<Approver_Audit>;
  /** fetch data from the table: "risksmart.approver_audit" using primary key columns */
  approver_audit_by_pk?: Maybe<Approver_Audit>;
  /** fetch data from the table: "risksmart.approver" using primary key columns */
  approver_by_pk?: Maybe<Approver>;
  /** fetch data from the table: "risksmart.assessment" */
  assessment: Array<Assessment>;
  /** fetch data from the table: "risksmart.assessment_activity" */
  assessment_activity: Array<Assessment_Activity>;
  /** fetch aggregated fields from the table: "risksmart.assessment_activity" */
  assessment_activity_aggregate: Assessment_Activity_Aggregate;
  /** fetch data from the table: "risksmart.assessment_activity_audit" */
  assessment_activity_audit: Array<Assessment_Activity_Audit>;
  /** fetch data from the table: "risksmart.assessment_activity_audit" using primary key columns */
  assessment_activity_audit_by_pk?: Maybe<Assessment_Activity_Audit>;
  /** fetch data from the table: "risksmart.assessment_activity" using primary key columns */
  assessment_activity_by_pk?: Maybe<Assessment_Activity>;
  /** fetch aggregated fields from the table: "risksmart.assessment" */
  assessment_aggregate: Assessment_Aggregate;
  /** fetch data from the table: "risksmart.assessment_audit" */
  assessment_audit: Array<Assessment_Audit>;
  /** fetch aggregated fields from the table: "risksmart.assessment_audit" */
  assessment_audit_aggregate: Assessment_Audit_Aggregate;
  /** fetch data from the table: "risksmart.assessment_audit" using primary key columns */
  assessment_audit_by_pk?: Maybe<Assessment_Audit>;
  /** fetch data from the table: "risksmart.assessment" using primary key columns */
  assessment_by_pk?: Maybe<Assessment>;
  /** fetch data from the table: "risksmart.assessment_result_parent" */
  assessment_result_parent: Array<Assessment_Result_Parent>;
  /** fetch aggregated fields from the table: "risksmart.assessment_result_parent" */
  assessment_result_parent_aggregate: Assessment_Result_Parent_Aggregate;
  /** fetch data from the table: "risksmart.assessment_result_parent_audit" */
  assessment_result_parent_audit: Array<Assessment_Result_Parent_Audit>;
  /** fetch data from the table: "risksmart.assessment_result_parent_audit" using primary key columns */
  assessment_result_parent_audit_by_pk?: Maybe<Assessment_Result_Parent_Audit>;
  /** fetch data from the table: "risksmart.assessment_result_parent" using primary key columns */
  assessment_result_parent_by_pk?: Maybe<Assessment_Result_Parent>;
  /** fetch data from the table: "risksmart.attestation_config" */
  attestation_config: Array<Attestation_Config>;
  /** fetch data from the table: "risksmart.attestation_config" using primary key columns */
  attestation_config_by_pk?: Maybe<Attestation_Config>;
  /** fetch data from the table: "risksmart.attestation_group" */
  attestation_group: Array<Attestation_Group>;
  /** fetch data from the table: "risksmart.attestation_group" using primary key columns */
  attestation_group_by_pk?: Maybe<Attestation_Group>;
  /** fetch data from the table: "risksmart.attestation_record" */
  attestation_record: Array<Attestation_Record>;
  /** fetch aggregated fields from the table: "risksmart.attestation_record" */
  attestation_record_aggregate: Attestation_Record_Aggregate;
  /** fetch data from the table: "risksmart.attestation_record" using primary key columns */
  attestation_record_by_pk?: Maybe<Attestation_Record>;
  /** fetch data from the table: "risksmart.audit_log_view" */
  audit_log_view: Array<Audit_Log_View>;
  /** fetch aggregated fields from the table: "risksmart.audit_log_view" */
  audit_log_view_aggregate: Audit_Log_View_Aggregate;
  /** fetch data from the table: "auth.organisation" */
  auth_organisation: Array<Auth_Organisation>;
  /** fetch data from the table: "auth.organisation" using primary key columns */
  auth_organisation_by_pk?: Maybe<Auth_Organisation>;
  /** fetch data from the table: "auth.organisationuser" */
  auth_organisationuser: Array<Auth_Organisationuser>;
  /** fetch data from the table: "auth.organisationuser" using primary key columns */
  auth_organisationuser_by_pk?: Maybe<Auth_Organisationuser>;
  /** fetch data from the table: "auth.user" */
  auth_user: Array<Auth_User>;
  /** fetch aggregated fields from the table: "auth.user" */
  auth_user_aggregate: Auth_User_Aggregate;
  /** fetch data from the table: "auth.user" using primary key columns */
  auth_user_by_pk?: Maybe<Auth_User>;
  available_roles: Array<RoleOutput>;
  /** fetch data from the table: "risksmart.business_area" */
  business_area: Array<Business_Area>;
  /** fetch aggregated fields from the table: "risksmart.business_area" */
  business_area_aggregate: Business_Area_Aggregate;
  /** fetch data from the table: "risksmart.business_area" using primary key columns */
  business_area_by_pk?: Maybe<Business_Area>;
  /** fetch data from the table: "risksmart.cause" */
  cause: Array<Cause>;
  /** fetch aggregated fields from the table: "risksmart.cause" */
  cause_aggregate: Cause_Aggregate;
  /** fetch data from the table: "risksmart.cause_audit" */
  cause_audit: Array<Cause_Audit>;
  /** fetch data from the table: "risksmart.cause_audit" using primary key columns */
  cause_audit_by_pk?: Maybe<Cause_Audit>;
  /** fetch data from the table: "risksmart.cause" using primary key columns */
  cause_by_pk?: Maybe<Cause>;
  /** fetch data from the table: "risksmart.change_request" */
  change_request: Array<Change_Request>;
  /** fetch aggregated fields from the table: "risksmart.change_request" */
  change_request_aggregate: Change_Request_Aggregate;
  /** fetch data from the table: "risksmart.change_request_audit" */
  change_request_audit: Array<Change_Request_Audit>;
  /** fetch data from the table: "risksmart.change_request_audit" using primary key columns */
  change_request_audit_by_pk?: Maybe<Change_Request_Audit>;
  /** fetch data from the table: "risksmart.change_request" using primary key columns */
  change_request_by_pk?: Maybe<Change_Request>;
  /** fetch data from the table: "risksmart.change_request_contributor" */
  change_request_contributor: Array<Change_Request_Contributor>;
  /** fetch data from the table: "risksmart.change_request_contributor" using primary key columns */
  change_request_contributor_by_pk?: Maybe<Change_Request_Contributor>;
  /** fetch data from the table: "risksmart.comment" */
  comment: Array<Comment>;
  /** fetch data from the table: "risksmart.comment_audit" */
  comment_audit: Array<Comment_Audit>;
  /** fetch data from the table: "risksmart.comment_audit" using primary key columns */
  comment_audit_by_pk?: Maybe<Comment_Audit>;
  /** fetch data from the table: "risksmart.comment" using primary key columns */
  comment_by_pk?: Maybe<Comment>;
  /** fetch data from the table: "risksmart.compliance_monitoring_assessment" */
  compliance_monitoring_assessment: Array<Compliance_Monitoring_Assessment>;
  /** fetch aggregated fields from the table: "risksmart.compliance_monitoring_assessment" */
  compliance_monitoring_assessment_aggregate: Compliance_Monitoring_Assessment_Aggregate;
  /** fetch data from the table: "risksmart.compliance_monitoring_assessment" using primary key columns */
  compliance_monitoring_assessment_by_pk?: Maybe<Compliance_Monitoring_Assessment>;
  /** fetch data from the table: "risksmart.consequence" */
  consequence: Array<Consequence>;
  /** fetch aggregated fields from the table: "risksmart.consequence" */
  consequence_aggregate: Consequence_Aggregate;
  /** fetch data from the table: "risksmart.consequence_audit" */
  consequence_audit: Array<Consequence_Audit>;
  /** fetch aggregated fields from the table: "risksmart.consequence_audit" */
  consequence_audit_aggregate: Consequence_Audit_Aggregate;
  /** fetch data from the table: "risksmart.consequence_audit" using primary key columns */
  consequence_audit_by_pk?: Maybe<Consequence_Audit>;
  /** fetch data from the table: "risksmart.consequence" using primary key columns */
  consequence_by_pk?: Maybe<Consequence>;
  /** fetch data from the table: "risksmart.control" */
  control: Array<Control>;
  /** fetch aggregated fields from the table: "risksmart.control" */
  control_aggregate: Control_Aggregate;
  /** fetch data from the table: "risksmart.control_audit" */
  control_audit: Array<Control_Audit>;
  /** fetch data from the table: "risksmart.control_audit" using primary key columns */
  control_audit_by_pk?: Maybe<Control_Audit>;
  /** fetch data from the table: "risksmart.control" using primary key columns */
  control_by_pk?: Maybe<Control>;
  /** fetch data from the table: "risksmart.control_group" */
  control_group: Array<Control_Group>;
  /** fetch aggregated fields from the table: "risksmart.control_group" */
  control_group_aggregate: Control_Group_Aggregate;
  /** fetch data from the table: "risksmart.control_group_audit" */
  control_group_audit: Array<Control_Group_Audit>;
  /** fetch data from the table: "risksmart.control_group_audit" using primary key columns */
  control_group_audit_by_pk?: Maybe<Control_Group_Audit>;
  /** fetch data from the table: "risksmart.control_group" using primary key columns */
  control_group_by_pk?: Maybe<Control_Group>;
  /** fetch data from the table: "risksmart.control_parent" */
  control_parent: Array<Control_Parent>;
  /** fetch aggregated fields from the table: "risksmart.control_parent" */
  control_parent_aggregate: Control_Parent_Aggregate;
  /** fetch data from the table: "risksmart.control_parent_audit" */
  control_parent_audit: Array<Control_Parent_Audit>;
  /** fetch data from the table: "risksmart.control_parent_audit" using primary key columns */
  control_parent_audit_by_pk?: Maybe<Control_Parent_Audit>;
  /** fetch data from the table: "risksmart.control_parent" using primary key columns */
  control_parent_by_pk?: Maybe<Control_Parent>;
  /** fetch data from the table: "risksmart.conversation" */
  conversation: Array<Conversation>;
  /** fetch data from the table: "risksmart.conversation_audit" */
  conversation_audit: Array<Conversation_Audit>;
  /** fetch data from the table: "risksmart.conversation_audit" using primary key columns */
  conversation_audit_by_pk?: Maybe<Conversation_Audit>;
  /** fetch data from the table: "risksmart.conversation" using primary key columns */
  conversation_by_pk?: Maybe<Conversation>;
  /** fetch data from the table: "risksmart.custom_attribute_schema" */
  custom_attribute_schema: Array<Custom_Attribute_Schema>;
  /** fetch data from the table: "risksmart.custom_attribute_schema_audit" */
  custom_attribute_schema_audit: Array<Custom_Attribute_Schema_Audit>;
  /** fetch data from the table: "risksmart.custom_attribute_schema_audit" using primary key columns */
  custom_attribute_schema_audit_by_pk?: Maybe<Custom_Attribute_Schema_Audit>;
  /** fetch data from the table: "risksmart.custom_attribute_schema" using primary key columns */
  custom_attribute_schema_by_pk?: Maybe<Custom_Attribute_Schema>;
  /** fetch data from the table: "risksmart.custom_ribbon" */
  custom_ribbon: Array<Custom_Ribbon>;
  /** fetch data from the table: "risksmart.custom_ribbon_audit" */
  custom_ribbon_audit: Array<Custom_Ribbon_Audit>;
  /** fetch data from the table: "risksmart.custom_ribbon_audit" using primary key columns */
  custom_ribbon_audit_by_pk?: Maybe<Custom_Ribbon_Audit>;
  /** fetch data from the table: "risksmart.custom_ribbon" using primary key columns */
  custom_ribbon_by_pk?: Maybe<Custom_Ribbon>;
  /** fetch data from the table: "risksmart.dashboard" */
  dashboard: Array<Dashboard>;
  /** fetch data from the table: "risksmart.dashboard_audit" */
  dashboard_audit: Array<Dashboard_Audit>;
  /** fetch data from the table: "risksmart.dashboard_audit" using primary key columns */
  dashboard_audit_by_pk?: Maybe<Dashboard_Audit>;
  /** fetch data from the table: "risksmart.dashboard" using primary key columns */
  dashboard_by_pk?: Maybe<Dashboard>;
  /** fetch data from the table: "risksmart.data_import" */
  data_import: Array<Data_Import>;
  /** fetch aggregated fields from the table: "risksmart.data_import" */
  data_import_aggregate: Data_Import_Aggregate;
  /** fetch data from the table: "risksmart.data_import" using primary key columns */
  data_import_by_pk?: Maybe<Data_Import>;
  /** fetch data from the table: "risksmart.data_import_error" */
  data_import_error: Array<Data_Import_Error>;
  /** fetch aggregated fields from the table: "risksmart.data_import_error" */
  data_import_error_aggregate: Data_Import_Error_Aggregate;
  /** fetch data from the table: "risksmart.data_import_error" using primary key columns */
  data_import_error_by_pk?: Maybe<Data_Import_Error>;
  /** fetch data from the table: "risksmart.department_audit" */
  department_audit: Array<Department_Audit>;
  /** fetch data from the table: "risksmart.department_audit" using primary key columns */
  department_audit_by_pk?: Maybe<Department_Audit>;
  /** fetch data from the table: "risksmart.department_type" */
  department_type: Array<Department_Type>;
  /** fetch data from the table: "risksmart.department_type_audit" */
  department_type_audit: Array<Department_Type_Audit>;
  /** fetch data from the table: "risksmart.department_type_audit" using primary key columns */
  department_type_audit_by_pk?: Maybe<Department_Type_Audit>;
  /** fetch data from the table: "risksmart.department_type" using primary key columns */
  department_type_by_pk?: Maybe<Department_Type>;
  /** fetch data from the table: "risksmart.department_type_group" */
  department_type_group: Array<Department_Type_Group>;
  /** fetch data from the table: "risksmart.department_type_group_audit" */
  department_type_group_audit: Array<Department_Type_Group_Audit>;
  /** fetch data from the table: "risksmart.department_type_group_audit" using primary key columns */
  department_type_group_audit_by_pk?: Maybe<Department_Type_Group_Audit>;
  /** fetch data from the table: "risksmart.document" */
  document: Array<Document>;
  /** fetch data from the table: "risksmart.document_assessment_result" */
  document_assessment_result: Array<Document_Assessment_Result>;
  /** fetch aggregated fields from the table: "risksmart.document_assessment_result" */
  document_assessment_result_aggregate: Document_Assessment_Result_Aggregate;
  /** fetch data from the table: "risksmart.document_assessment_result_audit" */
  document_assessment_result_audit: Array<Document_Assessment_Result_Audit>;
  /** fetch aggregated fields from the table: "risksmart.document_assessment_result_audit" */
  document_assessment_result_audit_aggregate: Document_Assessment_Result_Audit_Aggregate;
  /** fetch data from the table: "risksmart.document_assessment_result_audit" using primary key columns */
  document_assessment_result_audit_by_pk?: Maybe<Document_Assessment_Result_Audit>;
  /** fetch data from the table: "risksmart.document_assessment_result" using primary key columns */
  document_assessment_result_by_pk?: Maybe<Document_Assessment_Result>;
  /** fetch data from the table: "risksmart.document_audit" */
  document_audit: Array<Document_Audit>;
  /** fetch data from the table: "risksmart.document_audit" using primary key columns */
  document_audit_by_pk?: Maybe<Document_Audit>;
  /** fetch data from the table: "risksmart.document" using primary key columns */
  document_by_pk?: Maybe<Document>;
  /** fetch data from the table: "risksmart.document_file" */
  document_file: Array<Document_File>;
  /** fetch data from the table: "risksmart.document_file_audit" */
  document_file_audit: Array<Document_File_Audit>;
  /** fetch data from the table: "risksmart.document_file_audit" using primary key columns */
  document_file_audit_by_pk?: Maybe<Document_File_Audit>;
  /** fetch data from the table: "risksmart.document_file" using primary key columns */
  document_file_by_pk?: Maybe<Document_File>;
  /** fetch data from the table: "risksmart.document_linked_document" */
  document_linked_document: Array<Document_Linked_Document>;
  /** fetch data from the table: "risksmart.document_linked_document_audit" */
  document_linked_document_audit: Array<Document_Linked_Document_Audit>;
  /** fetch data from the table: "risksmart.document_linked_document_audit" using primary key columns */
  document_linked_document_audit_by_pk?: Maybe<Document_Linked_Document_Audit>;
  /** fetch data from the table: "risksmart.document_linked_document" using primary key columns */
  document_linked_document_by_pk?: Maybe<Document_Linked_Document>;
  /** fetch data from the table: "risksmart.file" */
  file: Array<File>;
  /** fetch aggregated fields from the table: "risksmart.file" */
  file_aggregate: File_Aggregate;
  /** fetch data from the table: "risksmart.file_audit" */
  file_audit: Array<File_Audit>;
  /** fetch data from the table: "risksmart.file_audit" using primary key columns */
  file_audit_by_pk?: Maybe<File_Audit>;
  /** fetch data from the table: "risksmart.file" using primary key columns */
  file_by_pk?: Maybe<File>;
  /** fetch data from the table: "risksmart.form_configuration" */
  form_configuration: Array<Form_Configuration>;
  /** fetch data from the table: "risksmart.form_configuration_audit" */
  form_configuration_audit: Array<Form_Configuration_Audit>;
  /** fetch data from the table: "risksmart.form_configuration_audit" using primary key columns */
  form_configuration_audit_by_pk?: Maybe<Form_Configuration_Audit>;
  /** fetch data from the table: "risksmart.form_field_configuration" */
  form_field_configuration: Array<Form_Field_Configuration>;
  /** fetch data from the table: "risksmart.form_field_configuration_audit" */
  form_field_configuration_audit: Array<Form_Field_Configuration_Audit>;
  /** fetch data from the table: "risksmart.form_field_configuration_audit" using primary key columns */
  form_field_configuration_audit_by_pk?: Maybe<Form_Field_Configuration_Audit>;
  /** fetch data from the table: "risksmart.form_field_ordering" */
  form_field_ordering: Array<Form_Field_Ordering>;
  /** fetch data from the table: "risksmart.form_field_ordering_audit" */
  form_field_ordering_audit: Array<Form_Field_Ordering_Audit>;
  /** fetch data from the table: "risksmart.form_field_ordering_audit" using primary key columns */
  form_field_ordering_audit_by_pk?: Maybe<Form_Field_Ordering_Audit>;
  /** fetch data from the table: "risksmart.impact" */
  impact: Array<Impact>;
  /** fetch aggregated fields from the table: "risksmart.impact" */
  impact_aggregate: Impact_Aggregate;
  /** fetch data from the table: "risksmart.impact_audit" */
  impact_audit: Array<Impact_Audit>;
  /** fetch data from the table: "risksmart.impact_audit" using primary key columns */
  impact_audit_by_pk?: Maybe<Impact_Audit>;
  /** fetch data from the table: "risksmart.impact" using primary key columns */
  impact_by_pk?: Maybe<Impact>;
  /** fetch data from the table: "risksmart.impact_parent" */
  impact_parent: Array<Impact_Parent>;
  /** fetch aggregated fields from the table: "risksmart.impact_parent" */
  impact_parent_aggregate: Impact_Parent_Aggregate;
  /** fetch data from the table: "risksmart.impact_parent_audit" */
  impact_parent_audit: Array<Impact_Parent_Audit>;
  /** fetch data from the table: "risksmart.impact_parent_audit" using primary key columns */
  impact_parent_audit_by_pk?: Maybe<Impact_Parent_Audit>;
  /** fetch data from the table: "risksmart.impact_parent" using primary key columns */
  impact_parent_by_pk?: Maybe<Impact_Parent>;
  /** fetch data from the table: "risksmart.impact_rating" */
  impact_rating: Array<Impact_Rating>;
  /** fetch aggregated fields from the table: "risksmart.impact_rating" */
  impact_rating_aggregate: Impact_Rating_Aggregate;
  /** fetch data from the table: "risksmart.impact_rating_audit" */
  impact_rating_audit: Array<Impact_Rating_Audit>;
  /** fetch data from the table: "risksmart.impact_rating_audit" using primary key columns */
  impact_rating_audit_by_pk?: Maybe<Impact_Rating_Audit>;
  /** fetch data from the table: "risksmart.impact_rating" using primary key columns */
  impact_rating_by_pk?: Maybe<Impact_Rating>;
  /** fetch data from the table: "risksmart.indicator" */
  indicator: Array<Indicator>;
  /** fetch data from the table: "risksmart.indicator_audit" */
  indicator_audit: Array<Indicator_Audit>;
  /** fetch data from the table: "risksmart.indicator_audit" using primary key columns */
  indicator_audit_by_pk?: Maybe<Indicator_Audit>;
  /** fetch data from the table: "risksmart.indicator" using primary key columns */
  indicator_by_pk?: Maybe<Indicator>;
  /** fetch data from the table: "risksmart.indicator_parent" */
  indicator_parent: Array<Indicator_Parent>;
  /** fetch aggregated fields from the table: "risksmart.indicator_parent" */
  indicator_parent_aggregate: Indicator_Parent_Aggregate;
  /** fetch data from the table: "risksmart.indicator_parent" using primary key columns */
  indicator_parent_by_pk?: Maybe<Indicator_Parent>;
  /** fetch data from the table: "risksmart.indicator_result" */
  indicator_result: Array<Indicator_Result>;
  /** fetch data from the table: "risksmart.indicator_result_audit" */
  indicator_result_audit: Array<Indicator_Result_Audit>;
  /** fetch data from the table: "risksmart.indicator_result_audit" using primary key columns */
  indicator_result_audit_by_pk?: Maybe<Indicator_Result_Audit>;
  /** fetch data from the table: "risksmart.indicator_result" using primary key columns */
  indicator_result_by_pk?: Maybe<Indicator_Result>;
  /** fetch data from the table: "risksmart.indicator_type" */
  indicator_type: Array<Indicator_Type>;
  /** fetch data from the table: "risksmart.indicator_type" using primary key columns */
  indicator_type_by_pk?: Maybe<Indicator_Type>;
  /** fetch data from the table: "risksmart.internal_audit_entity" */
  internal_audit_entity: Array<Internal_Audit_Entity>;
  /** fetch aggregated fields from the table: "risksmart.internal_audit_entity" */
  internal_audit_entity_aggregate: Internal_Audit_Entity_Aggregate;
  /** fetch data from the table: "risksmart.internal_audit_entity" using primary key columns */
  internal_audit_entity_by_pk?: Maybe<Internal_Audit_Entity>;
  /** fetch data from the table: "risksmart.internal_audit_report" */
  internal_audit_report: Array<Internal_Audit_Report>;
  /** fetch aggregated fields from the table: "risksmart.internal_audit_report" */
  internal_audit_report_aggregate: Internal_Audit_Report_Aggregate;
  /** fetch data from the table: "risksmart.internal_audit_report" using primary key columns */
  internal_audit_report_by_pk?: Maybe<Internal_Audit_Report>;
  /** fetch data from the table: "risksmart.issue" */
  issue: Array<Issue>;
  /** fetch aggregated fields from the table: "risksmart.issue" */
  issue_aggregate: Issue_Aggregate;
  /** fetch data from the table: "risksmart.issue_assessment" */
  issue_assessment: Array<Issue_Assessment>;
  /** fetch aggregated fields from the table: "risksmart.issue_assessment" */
  issue_assessment_aggregate: Issue_Assessment_Aggregate;
  /** fetch data from the table: "risksmart.issue_assessment_audit" */
  issue_assessment_audit: Array<Issue_Assessment_Audit>;
  /** fetch data from the table: "risksmart.issue_assessment_audit" using primary key columns */
  issue_assessment_audit_by_pk?: Maybe<Issue_Assessment_Audit>;
  /** fetch data from the table: "risksmart.issue_assessment" using primary key columns */
  issue_assessment_by_pk?: Maybe<Issue_Assessment>;
  /** fetch data from the table: "risksmart.issue_audit" */
  issue_audit: Array<Issue_Audit>;
  /** fetch data from the table: "risksmart.issue_audit" using primary key columns */
  issue_audit_by_pk?: Maybe<Issue_Audit>;
  /** fetch data from the table: "risksmart.issue" using primary key columns */
  issue_by_pk?: Maybe<Issue>;
  /** fetch data from the table: "risksmart.issue_parent" */
  issue_parent: Array<Issue_Parent>;
  /** fetch aggregated fields from the table: "risksmart.issue_parent" */
  issue_parent_aggregate: Issue_Parent_Aggregate;
  /** fetch data from the table: "risksmart.issue_parent_audit" */
  issue_parent_audit: Array<Issue_Parent_Audit>;
  /** fetch data from the table: "risksmart.issue_parent_audit" using primary key columns */
  issue_parent_audit_by_pk?: Maybe<Issue_Parent_Audit>;
  /** fetch data from the table: "risksmart.issue_parent" using primary key columns */
  issue_parent_by_pk?: Maybe<Issue_Parent>;
  /** fetch data from the table: "risksmart.issue_update" */
  issue_update: Array<Issue_Update>;
  /** fetch data from the table: "risksmart.issue_update_audit" */
  issue_update_audit: Array<Issue_Update_Audit>;
  /** fetch data from the table: "risksmart.issue_update_audit" using primary key columns */
  issue_update_audit_by_pk?: Maybe<Issue_Update_Audit>;
  /** fetch data from the table: "risksmart.issue_update" using primary key columns */
  issue_update_by_pk?: Maybe<Issue_Update>;
  /** fetch data from the table: "risksmart.linked_item" */
  linked_item: Array<Linked_Item>;
  /** fetch data from the table: "risksmart.linked_item" using primary key columns */
  linked_item_by_pk?: Maybe<Linked_Item>;
  /** fetch data from the table: "risksmart.node" */
  node: Array<Node>;
  /** fetch aggregated fields from the table: "risksmart.node" */
  node_aggregate: Node_Aggregate;
  /** fetch data from the table: "risksmart.node" using primary key columns */
  node_by_pk?: Maybe<Node>;
  notificationPreferences: NotificationPreferencesOutput;
  /** fetch data from the table: "risksmart.obligation" */
  obligation: Array<Obligation>;
  /** fetch aggregated fields from the table: "risksmart.obligation" */
  obligation_aggregate: Obligation_Aggregate;
  /** fetch data from the table: "risksmart.obligation_assessment_result" */
  obligation_assessment_result: Array<Obligation_Assessment_Result>;
  /** fetch aggregated fields from the table: "risksmart.obligation_assessment_result" */
  obligation_assessment_result_aggregate: Obligation_Assessment_Result_Aggregate;
  /** fetch data from the table: "risksmart.obligation_assessment_result_audit" */
  obligation_assessment_result_audit: Array<Obligation_Assessment_Result_Audit>;
  /** fetch data from the table: "risksmart.obligation_assessment_result_audit" using primary key columns */
  obligation_assessment_result_audit_by_pk?: Maybe<Obligation_Assessment_Result_Audit>;
  /** fetch data from the table: "risksmart.obligation_assessment_result" using primary key columns */
  obligation_assessment_result_by_pk?: Maybe<Obligation_Assessment_Result>;
  /** fetch data from the table: "risksmart.obligation_assessment_status" */
  obligation_assessment_status: Array<Obligation_Assessment_Status>;
  /** fetch data from the table: "risksmart.obligation_assessment_status" using primary key columns */
  obligation_assessment_status_by_pk?: Maybe<Obligation_Assessment_Status>;
  /** fetch data from the table: "risksmart.obligation_audit" */
  obligation_audit: Array<Obligation_Audit>;
  /** fetch aggregated fields from the table: "risksmart.obligation_audit" */
  obligation_audit_aggregate: Obligation_Audit_Aggregate;
  /** fetch data from the table: "risksmart.obligation_audit" using primary key columns */
  obligation_audit_by_pk?: Maybe<Obligation_Audit>;
  /** fetch data from the table: "risksmart.obligation" using primary key columns */
  obligation_by_pk?: Maybe<Obligation>;
  /** fetch data from the table: "risksmart.obligation_impact" */
  obligation_impact: Array<Obligation_Impact>;
  /** fetch data from the table: "risksmart.obligation_impact_audit" */
  obligation_impact_audit: Array<Obligation_Impact_Audit>;
  /** fetch data from the table: "risksmart.obligation_impact_audit" using primary key columns */
  obligation_impact_audit_by_pk?: Maybe<Obligation_Impact_Audit>;
  /** fetch data from the table: "risksmart.obligation_impact" using primary key columns */
  obligation_impact_by_pk?: Maybe<Obligation_Impact>;
  /** fetch data from the table: "risksmart.obligation_type" */
  obligation_type: Array<Obligation_Type>;
  /** fetch data from the table: "risksmart.obligation_type" using primary key columns */
  obligation_type_by_pk?: Maybe<Obligation_Type>;
  /** fetch data from the table: "risksmart.owner_audit" */
  owner_audit: Array<Owner_Audit>;
  /** fetch data from the table: "risksmart.owner_audit" using primary key columns */
  owner_audit_by_pk?: Maybe<Owner_Audit>;
  /** fetch data from the table: "risksmart.owner_group_audit" */
  owner_group_audit: Array<Owner_Group_Audit>;
  /** fetch data from the table: "risksmart.owner_group_audit" using primary key columns */
  owner_group_audit_by_pk?: Maybe<Owner_Group_Audit>;
  /** fetch data from the table: "risksmart.questionnaire_invite" */
  questionnaire_invite: Array<Questionnaire_Invite>;
  /** fetch data from the table: "risksmart.questionnaire_invite" using primary key columns */
  questionnaire_invite_by_pk?: Maybe<Questionnaire_Invite>;
  /** fetch data from the table: "risksmart.questionnaire_template" */
  questionnaire_template: Array<Questionnaire_Template>;
  /** fetch data from the table: "risksmart.questionnaire_template" using primary key columns */
  questionnaire_template_by_pk?: Maybe<Questionnaire_Template>;
  /** fetch data from the table: "risksmart.questionnaire_template_version" */
  questionnaire_template_version: Array<Questionnaire_Template_Version>;
  /** fetch data from the table: "risksmart.questionnaire_template_version" using primary key columns */
  questionnaire_template_version_by_pk?: Maybe<Questionnaire_Template_Version>;
  /** fetch data from the table: "risksmart.questionnaire_template_version_status" */
  questionnaire_template_version_status: Array<Questionnaire_Template_Version_Status>;
  /** fetch data from the table: "risksmart.questionnaire_template_version_status" using primary key columns */
  questionnaire_template_version_status_by_pk?: Maybe<Questionnaire_Template_Version_Status>;
  /** fetch data from the table: "risksmart.relation_file" */
  relation_file: Array<Relation_File>;
  /** fetch aggregated fields from the table: "risksmart.relation_file" */
  relation_file_aggregate: Relation_File_Aggregate;
  /** fetch data from the table: "risksmart.relation_file_audit" */
  relation_file_audit: Array<Relation_File_Audit>;
  /** fetch data from the table: "risksmart.relation_file" using primary key columns */
  relation_file_by_pk?: Maybe<Relation_File>;
  reportingData?: Maybe<Array<ReportDataOutput>>;
  /** fetch data from the table: "risksmart.risk" */
  risk: Array<Risk>;
  /** fetch aggregated fields from the table: "risksmart.risk" */
  risk_aggregate: Risk_Aggregate;
  /** fetch data from the table: "risksmart.risk_assessment_result" */
  risk_assessment_result: Array<Risk_Assessment_Result>;
  /** fetch aggregated fields from the table: "risksmart.risk_assessment_result" */
  risk_assessment_result_aggregate: Risk_Assessment_Result_Aggregate;
  /** fetch data from the table: "risksmart.risk_assessment_result_audit" */
  risk_assessment_result_audit: Array<Risk_Assessment_Result_Audit>;
  /** fetch data from the table: "risksmart.risk_assessment_result_audit" using primary key columns */
  risk_assessment_result_audit_by_pk?: Maybe<Risk_Assessment_Result_Audit>;
  /** fetch data from the table: "risksmart.risk_assessment_result" using primary key columns */
  risk_assessment_result_by_pk?: Maybe<Risk_Assessment_Result>;
  /** fetch data from the table: "risksmart.risk_audit" */
  risk_audit: Array<Risk_Audit>;
  /** fetch aggregated fields from the table: "risksmart.risk_audit" */
  risk_audit_aggregate: Risk_Audit_Aggregate;
  /** fetch data from the table: "risksmart.risk_audit" using primary key columns */
  risk_audit_by_pk?: Maybe<Risk_Audit>;
  /** fetch data from the table: "risksmart.risk" using primary key columns */
  risk_by_pk?: Maybe<Risk>;
  /** fetch data from the table: "risksmart.risk_score" */
  risk_score: Array<Risk_Score>;
  /** fetch aggregated fields from the table: "risksmart.risk_score" */
  risk_score_aggregate: Risk_Score_Aggregate;
  /** fetch data from the table: "risksmart.risk_score" using primary key columns */
  risk_score_by_pk?: Maybe<Risk_Score>;
  /** fetch data from the table: "risksmart.indicator_parent_audit" */
  risksmart_indicator_parent_audit: Array<Risksmart_Indicator_Parent_Audit>;
  /** fetch data from the table: "risksmart.indicator_parent_audit" using primary key columns */
  risksmart_indicator_parent_audit_by_pk?: Maybe<Risksmart_Indicator_Parent_Audit>;
  /** fetch data from the table: "risksmart.linked_item_audit" */
  risksmart_linked_item_audit: Array<Risksmart_Linked_Item_Audit>;
  /** fetch data from the table: "risksmart.linked_item_audit" using primary key columns */
  risksmart_linked_item_audit_by_pk?: Maybe<Risksmart_Linked_Item_Audit>;
  /** fetch data from the table: "risksmart.role_access" */
  role_access: Array<Role_Access>;
  slackNotificationConnection: SlackNotificationConnectionOutput;
  /** fetch data from the table: "risksmart.tag_type" */
  tag_type: Array<Tag_Type>;
  /** fetch data from the table: "risksmart.tag_type_audit" */
  tag_type_audit: Array<Tag_Type_Audit>;
  /** fetch data from the table: "risksmart.tag_type_audit" using primary key columns */
  tag_type_audit_by_pk?: Maybe<Tag_Type_Audit>;
  /** fetch data from the table: "risksmart.tag_type" using primary key columns */
  tag_type_by_pk?: Maybe<Tag_Type>;
  /** fetch data from the table: "risksmart.tag_type_group" */
  tag_type_group: Array<Tag_Type_Group>;
  /** fetch data from the table: "risksmart.tag_type_group_audit" */
  tag_type_group_audit: Array<Tag_Type_Group_Audit>;
  /** fetch data from the table: "risksmart.tag_type_group_audit" using primary key columns */
  tag_type_group_audit_by_pk?: Maybe<Tag_Type_Group_Audit>;
  /** fetch data from the table: "risksmart.taxonomy" */
  taxonomy: Array<Taxonomy>;
  /** fetch aggregated fields from the table: "risksmart.taxonomy" */
  taxonomy_aggregate: Taxonomy_Aggregate;
  /** fetch data from the table: "risksmart.taxonomy_audit" */
  taxonomy_audit: Array<Taxonomy_Audit>;
  /** fetch aggregated fields from the table: "risksmart.taxonomy_audit" */
  taxonomy_audit_aggregate: Taxonomy_Audit_Aggregate;
  /** fetch data from the table: "risksmart.taxonomy_audit" using primary key columns */
  taxonomy_audit_by_pk?: Maybe<Taxonomy_Audit>;
  /** fetch data from the table: "risksmart.taxonomy" using primary key columns */
  taxonomy_by_pk?: Maybe<Taxonomy>;
  /** fetch data from the table: "risksmart.taxonomy_org" */
  taxonomy_org: Array<Taxonomy_Org>;
  /** fetch aggregated fields from the table: "risksmart.taxonomy_org" */
  taxonomy_org_aggregate: Taxonomy_Org_Aggregate;
  /** fetch data from the table: "risksmart.taxonomy_org" using primary key columns */
  taxonomy_org_by_pk?: Maybe<Taxonomy_Org>;
  /** fetch data from the table: "risksmart.test_result" */
  test_result: Array<Test_Result>;
  /** fetch aggregated fields from the table: "risksmart.test_result" */
  test_result_aggregate: Test_Result_Aggregate;
  /** fetch data from the table: "risksmart.test_result_audit" */
  test_result_audit: Array<Test_Result_Audit>;
  /** fetch data from the table: "risksmart.test_result_audit" using primary key columns */
  test_result_audit_by_pk?: Maybe<Test_Result_Audit>;
  /** fetch data from the table: "risksmart.test_result" using primary key columns */
  test_result_by_pk?: Maybe<Test_Result>;
  /** fetch data from the table: "risksmart.third_party" */
  third_party: Array<Third_Party>;
  /** fetch data from the table: "risksmart.third_party" using primary key columns */
  third_party_by_pk?: Maybe<Third_Party>;
  /** fetch data from the table: "risksmart.third_party_response" */
  third_party_response: Array<Third_Party_Response>;
  /** fetch data from the table: "risksmart.third_party_response" using primary key columns */
  third_party_response_by_pk?: Maybe<Third_Party_Response>;
  /** fetch data from the table: "risksmart.third_party_response_status" */
  third_party_response_status: Array<Third_Party_Response_Status>;
  /** fetch data from the table: "risksmart.third_party_response_status" using primary key columns */
  third_party_response_status_by_pk?: Maybe<Third_Party_Response_Status>;
  /** fetch data from the table: "risksmart.user_view_active" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "risksmart.user_view_active" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "risksmart.user_group" */
  user_group: Array<User_Group>;
  /** fetch data from the table: "risksmart.user_group_audit" */
  user_group_audit: Array<User_Group_Audit>;
  /** fetch data from the table: "risksmart.user_group_audit" using primary key columns */
  user_group_audit_by_pk?: Maybe<User_Group_Audit>;
  /** fetch data from the table: "risksmart.user_group" using primary key columns */
  user_group_by_pk?: Maybe<User_Group>;
  /** fetch data from the table: "risksmart.user_group_user" */
  user_group_user: Array<User_Group_User>;
  /** fetch aggregated fields from the table: "risksmart.user_group_user" */
  user_group_user_aggregate: User_Group_User_Aggregate;
  /** fetch data from the table: "risksmart.user_group_user_audit" */
  user_group_user_audit: Array<User_Group_User_Audit>;
  /** fetch data from the table: "risksmart.user_group_user_audit" using primary key columns */
  user_group_user_audit_by_pk?: Maybe<User_Group_User_Audit>;
  /** fetch data from the table: "risksmart.user_group_user" using primary key columns */
  user_group_user_by_pk?: Maybe<User_Group_User>;
  /** fetch data from the table: "risksmart.user_search_preferences" */
  user_search_preferences: Array<User_Search_Preferences>;
  /** fetch data from the table: "risksmart.user_search_preferences_audit" */
  user_search_preferences_audit: Array<User_Search_Preferences_Audit>;
  /** fetch data from the table: "risksmart.user_table_preferences" */
  user_table_preferences: Array<User_Table_Preferences>;
};


export type Query_RootAcceptanceArgs = {
  distinct_on?: InputMaybe<Array<Acceptance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Acceptance_Order_By>>;
  where?: InputMaybe<Acceptance_Bool_Exp>;
};


export type Query_RootAcceptance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Acceptance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Acceptance_Order_By>>;
  where?: InputMaybe<Acceptance_Bool_Exp>;
};


export type Query_RootAcceptance_AuditArgs = {
  distinct_on?: InputMaybe<Array<Acceptance_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Acceptance_Audit_Order_By>>;
  where?: InputMaybe<Acceptance_Audit_Bool_Exp>;
};


export type Query_RootAcceptance_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootAcceptance_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootAcceptance_ParentArgs = {
  distinct_on?: InputMaybe<Array<Acceptance_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Acceptance_Parent_Order_By>>;
  where?: InputMaybe<Acceptance_Parent_Bool_Exp>;
};


export type Query_RootAcceptance_Parent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Acceptance_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Acceptance_Parent_Order_By>>;
  where?: InputMaybe<Acceptance_Parent_Bool_Exp>;
};


export type Query_RootAcceptance_Parent_AuditArgs = {
  distinct_on?: InputMaybe<Array<Acceptance_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Acceptance_Parent_Audit_Order_By>>;
  where?: InputMaybe<Acceptance_Parent_Audit_Bool_Exp>;
};


export type Query_RootAcceptance_Parent_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Query_RootAcceptance_Parent_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Query_RootActionArgs = {
  distinct_on?: InputMaybe<Array<Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Order_By>>;
  where?: InputMaybe<Action_Bool_Exp>;
};


export type Query_RootAction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Order_By>>;
  where?: InputMaybe<Action_Bool_Exp>;
};


export type Query_RootAction_AuditArgs = {
  distinct_on?: InputMaybe<Array<Action_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Audit_Order_By>>;
  where?: InputMaybe<Action_Audit_Bool_Exp>;
};


export type Query_RootAction_Audit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Audit_Order_By>>;
  where?: InputMaybe<Action_Audit_Bool_Exp>;
};


export type Query_RootAction_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootAction_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootAction_ParentArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


export type Query_RootAction_Parent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


export type Query_RootAction_Parent_AuditArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Audit_Order_By>>;
  where?: InputMaybe<Action_Parent_Audit_Bool_Exp>;
};


export type Query_RootAction_Parent_Audit_By_PkArgs = {
  ActionId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Query_RootAction_Parent_By_PkArgs = {
  ActionId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Query_RootAction_UpdateArgs = {
  distinct_on?: InputMaybe<Array<Action_Update_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Update_Order_By>>;
  where?: InputMaybe<Action_Update_Bool_Exp>;
};


export type Query_RootAction_Update_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Update_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Update_Order_By>>;
  where?: InputMaybe<Action_Update_Bool_Exp>;
};


export type Query_RootAction_Update_AuditArgs = {
  distinct_on?: InputMaybe<Array<Action_Update_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Update_Audit_Order_By>>;
  where?: InputMaybe<Action_Update_Audit_Bool_Exp>;
};


export type Query_RootAction_Update_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootAction_Update_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootAction_Update_SummaryArgs = {
  distinct_on?: InputMaybe<Array<Action_Update_Summary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Update_Summary_Order_By>>;
  where?: InputMaybe<Action_Update_Summary_Bool_Exp>;
};


export type Query_RootAction_Update_Summary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Update_Summary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Update_Summary_Order_By>>;
  where?: InputMaybe<Action_Update_Summary_Bool_Exp>;
};


export type Query_RootAggregation_OrgArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Org_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Aggregation_Org_Order_By>>;
  where?: InputMaybe<Aggregation_Org_Bool_Exp>;
};


export type Query_RootAggregation_Org_By_PkArgs = {
  OrgKey: Scalars['String']['input'];
};


export type Query_RootAncestor_ContributorArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


export type Query_RootAppetiteArgs = {
  distinct_on?: InputMaybe<Array<Appetite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Appetite_Order_By>>;
  where?: InputMaybe<Appetite_Bool_Exp>;
};


export type Query_RootAppetite_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appetite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Appetite_Order_By>>;
  where?: InputMaybe<Appetite_Bool_Exp>;
};


export type Query_RootAppetite_AuditArgs = {
  distinct_on?: InputMaybe<Array<Appetite_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Appetite_Audit_Order_By>>;
  where?: InputMaybe<Appetite_Audit_Bool_Exp>;
};


export type Query_RootAppetite_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootAppetite_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootAppetite_ParentArgs = {
  distinct_on?: InputMaybe<Array<Appetite_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Appetite_Parent_Order_By>>;
  where?: InputMaybe<Appetite_Parent_Bool_Exp>;
};


export type Query_RootAppetite_Parent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appetite_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Appetite_Parent_Order_By>>;
  where?: InputMaybe<Appetite_Parent_Bool_Exp>;
};


export type Query_RootAppetite_Parent_AuditArgs = {
  distinct_on?: InputMaybe<Array<Appetite_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Appetite_Parent_Audit_Order_By>>;
  where?: InputMaybe<Appetite_Parent_Audit_Bool_Exp>;
};


export type Query_RootAppetite_Parent_Audit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appetite_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Appetite_Parent_Audit_Order_By>>;
  where?: InputMaybe<Appetite_Parent_Audit_Bool_Exp>;
};


export type Query_RootAppetite_Parent_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Query_RootAppetite_Parent_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Query_RootApprovalArgs = {
  distinct_on?: InputMaybe<Array<Approval_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Approval_Order_By>>;
  where?: InputMaybe<Approval_Bool_Exp>;
};


export type Query_RootApproval_AuditArgs = {
  distinct_on?: InputMaybe<Array<Approval_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Approval_Audit_Order_By>>;
  where?: InputMaybe<Approval_Audit_Bool_Exp>;
};


export type Query_RootApproval_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootApproval_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootApproval_LevelArgs = {
  distinct_on?: InputMaybe<Array<Approval_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Approval_Level_Order_By>>;
  where?: InputMaybe<Approval_Level_Bool_Exp>;
};


export type Query_RootApproval_Level_AuditArgs = {
  distinct_on?: InputMaybe<Array<Approval_Level_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Approval_Level_Audit_Order_By>>;
  where?: InputMaybe<Approval_Level_Audit_Bool_Exp>;
};


export type Query_RootApproval_Level_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootApproval_Level_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootApproverArgs = {
  distinct_on?: InputMaybe<Array<Approver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Approver_Order_By>>;
  where?: InputMaybe<Approver_Bool_Exp>;
};


export type Query_RootApprover_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Approver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Approver_Order_By>>;
  where?: InputMaybe<Approver_Bool_Exp>;
};


export type Query_RootApprover_AuditArgs = {
  distinct_on?: InputMaybe<Array<Approver_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Approver_Audit_Order_By>>;
  where?: InputMaybe<Approver_Audit_Bool_Exp>;
};


export type Query_RootApprover_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootApprover_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootAssessmentArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Order_By>>;
  where?: InputMaybe<Assessment_Bool_Exp>;
};


export type Query_RootAssessment_ActivityArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Activity_Order_By>>;
  where?: InputMaybe<Assessment_Activity_Bool_Exp>;
};


export type Query_RootAssessment_Activity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Activity_Order_By>>;
  where?: InputMaybe<Assessment_Activity_Bool_Exp>;
};


export type Query_RootAssessment_Activity_AuditArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Activity_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Activity_Audit_Order_By>>;
  where?: InputMaybe<Assessment_Activity_Audit_Bool_Exp>;
};


export type Query_RootAssessment_Activity_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootAssessment_Activity_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootAssessment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Order_By>>;
  where?: InputMaybe<Assessment_Bool_Exp>;
};


export type Query_RootAssessment_AuditArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Audit_Order_By>>;
  where?: InputMaybe<Assessment_Audit_Bool_Exp>;
};


export type Query_RootAssessment_Audit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Audit_Order_By>>;
  where?: InputMaybe<Assessment_Audit_Bool_Exp>;
};


export type Query_RootAssessment_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootAssessment_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootAssessment_Result_ParentArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


export type Query_RootAssessment_Result_Parent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


export type Query_RootAssessment_Result_Parent_AuditArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Audit_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Audit_Bool_Exp>;
};


export type Query_RootAssessment_Result_Parent_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Query_RootAssessment_Result_Parent_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Query_RootAttestation_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Attestation_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attestation_Config_Order_By>>;
  where?: InputMaybe<Attestation_Config_Bool_Exp>;
};


export type Query_RootAttestation_Config_By_PkArgs = {
  ParentId: Scalars['uuid']['input'];
};


export type Query_RootAttestation_GroupArgs = {
  distinct_on?: InputMaybe<Array<Attestation_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attestation_Group_Order_By>>;
  where?: InputMaybe<Attestation_Group_Bool_Exp>;
};


export type Query_RootAttestation_Group_By_PkArgs = {
  ConfigId: Scalars['uuid']['input'];
  GroupId: Scalars['uuid']['input'];
};


export type Query_RootAttestation_RecordArgs = {
  distinct_on?: InputMaybe<Array<Attestation_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attestation_Record_Order_By>>;
  where?: InputMaybe<Attestation_Record_Bool_Exp>;
};


export type Query_RootAttestation_Record_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attestation_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attestation_Record_Order_By>>;
  where?: InputMaybe<Attestation_Record_Bool_Exp>;
};


export type Query_RootAttestation_Record_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootAudit_Log_ViewArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Log_View_Order_By>>;
  where?: InputMaybe<Audit_Log_View_Bool_Exp>;
};


export type Query_RootAudit_Log_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Log_View_Order_By>>;
  where?: InputMaybe<Audit_Log_View_Bool_Exp>;
};


export type Query_RootAuth_OrganisationArgs = {
  distinct_on?: InputMaybe<Array<Auth_Organisation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Organisation_Order_By>>;
  where?: InputMaybe<Auth_Organisation_Bool_Exp>;
};


export type Query_RootAuth_Organisation_By_PkArgs = {
  OrgKey: Scalars['String']['input'];
};


export type Query_RootAuth_OrganisationuserArgs = {
  distinct_on?: InputMaybe<Array<Auth_Organisationuser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Organisationuser_Order_By>>;
  where?: InputMaybe<Auth_Organisationuser_Bool_Exp>;
};


export type Query_RootAuth_Organisationuser_By_PkArgs = {
  OrgKey: Scalars['String']['input'];
  User_Id: Scalars['String']['input'];
};


export type Query_RootAuth_UserArgs = {
  distinct_on?: InputMaybe<Array<Auth_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_User_Order_By>>;
  where?: InputMaybe<Auth_User_Bool_Exp>;
};


export type Query_RootAuth_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_User_Order_By>>;
  where?: InputMaybe<Auth_User_Bool_Exp>;
};


export type Query_RootAuth_User_By_PkArgs = {
  Id: Scalars['String']['input'];
};


export type Query_RootBusiness_AreaArgs = {
  distinct_on?: InputMaybe<Array<Business_Area_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Business_Area_Order_By>>;
  where?: InputMaybe<Business_Area_Bool_Exp>;
};


export type Query_RootBusiness_Area_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Business_Area_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Business_Area_Order_By>>;
  where?: InputMaybe<Business_Area_Bool_Exp>;
};


export type Query_RootBusiness_Area_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootCauseArgs = {
  distinct_on?: InputMaybe<Array<Cause_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Cause_Order_By>>;
  where?: InputMaybe<Cause_Bool_Exp>;
};


export type Query_RootCause_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cause_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Cause_Order_By>>;
  where?: InputMaybe<Cause_Bool_Exp>;
};


export type Query_RootCause_AuditArgs = {
  distinct_on?: InputMaybe<Array<Cause_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Cause_Audit_Order_By>>;
  where?: InputMaybe<Cause_Audit_Bool_Exp>;
};


export type Query_RootCause_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootCause_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootChange_RequestArgs = {
  distinct_on?: InputMaybe<Array<Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Change_Request_Order_By>>;
  where?: InputMaybe<Change_Request_Bool_Exp>;
};


export type Query_RootChange_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Change_Request_Order_By>>;
  where?: InputMaybe<Change_Request_Bool_Exp>;
};


export type Query_RootChange_Request_AuditArgs = {
  distinct_on?: InputMaybe<Array<Change_Request_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Change_Request_Audit_Order_By>>;
  where?: InputMaybe<Change_Request_Audit_Bool_Exp>;
};


export type Query_RootChange_Request_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootChange_Request_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootChange_Request_ContributorArgs = {
  distinct_on?: InputMaybe<Array<Change_Request_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Change_Request_Contributor_Order_By>>;
  where?: InputMaybe<Change_Request_Contributor_Bool_Exp>;
};


export type Query_RootChange_Request_Contributor_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootCommentArgs = {
  distinct_on?: InputMaybe<Array<Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Order_By>>;
  where?: InputMaybe<Comment_Bool_Exp>;
};


export type Query_RootComment_AuditArgs = {
  distinct_on?: InputMaybe<Array<Comment_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Audit_Order_By>>;
  where?: InputMaybe<Comment_Audit_Bool_Exp>;
};


export type Query_RootComment_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootComment_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootCompliance_Monitoring_AssessmentArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Monitoring_Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Compliance_Monitoring_Assessment_Order_By>>;
  where?: InputMaybe<Compliance_Monitoring_Assessment_Bool_Exp>;
};


export type Query_RootCompliance_Monitoring_Assessment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Monitoring_Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Compliance_Monitoring_Assessment_Order_By>>;
  where?: InputMaybe<Compliance_Monitoring_Assessment_Bool_Exp>;
};


export type Query_RootCompliance_Monitoring_Assessment_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootConsequenceArgs = {
  distinct_on?: InputMaybe<Array<Consequence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Consequence_Order_By>>;
  where?: InputMaybe<Consequence_Bool_Exp>;
};


export type Query_RootConsequence_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consequence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Consequence_Order_By>>;
  where?: InputMaybe<Consequence_Bool_Exp>;
};


export type Query_RootConsequence_AuditArgs = {
  distinct_on?: InputMaybe<Array<Consequence_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Consequence_Audit_Order_By>>;
  where?: InputMaybe<Consequence_Audit_Bool_Exp>;
};


export type Query_RootConsequence_Audit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consequence_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Consequence_Audit_Order_By>>;
  where?: InputMaybe<Consequence_Audit_Bool_Exp>;
};


export type Query_RootConsequence_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootConsequence_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootControlArgs = {
  distinct_on?: InputMaybe<Array<Control_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Order_By>>;
  where?: InputMaybe<Control_Bool_Exp>;
};


export type Query_RootControl_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Order_By>>;
  where?: InputMaybe<Control_Bool_Exp>;
};


export type Query_RootControl_AuditArgs = {
  distinct_on?: InputMaybe<Array<Control_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Audit_Order_By>>;
  where?: InputMaybe<Control_Audit_Bool_Exp>;
};


export type Query_RootControl_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootControl_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootControl_GroupArgs = {
  distinct_on?: InputMaybe<Array<Control_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Group_Order_By>>;
  where?: InputMaybe<Control_Group_Bool_Exp>;
};


export type Query_RootControl_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Group_Order_By>>;
  where?: InputMaybe<Control_Group_Bool_Exp>;
};


export type Query_RootControl_Group_AuditArgs = {
  distinct_on?: InputMaybe<Array<Control_Group_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Group_Audit_Order_By>>;
  where?: InputMaybe<Control_Group_Audit_Bool_Exp>;
};


export type Query_RootControl_Group_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootControl_Group_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootControl_ParentArgs = {
  distinct_on?: InputMaybe<Array<Control_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Parent_Order_By>>;
  where?: InputMaybe<Control_Parent_Bool_Exp>;
};


export type Query_RootControl_Parent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Parent_Order_By>>;
  where?: InputMaybe<Control_Parent_Bool_Exp>;
};


export type Query_RootControl_Parent_AuditArgs = {
  distinct_on?: InputMaybe<Array<Control_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Parent_Audit_Order_By>>;
  where?: InputMaybe<Control_Parent_Audit_Bool_Exp>;
};


export type Query_RootControl_Parent_Audit_By_PkArgs = {
  ControlId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Query_RootControl_Parent_By_PkArgs = {
  ControlId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Query_RootConversationArgs = {
  distinct_on?: InputMaybe<Array<Conversation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Conversation_Order_By>>;
  where?: InputMaybe<Conversation_Bool_Exp>;
};


export type Query_RootConversation_AuditArgs = {
  distinct_on?: InputMaybe<Array<Conversation_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Conversation_Audit_Order_By>>;
  where?: InputMaybe<Conversation_Audit_Bool_Exp>;
};


export type Query_RootConversation_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootConversation_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootCustom_Attribute_SchemaArgs = {
  distinct_on?: InputMaybe<Array<Custom_Attribute_Schema_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Custom_Attribute_Schema_Order_By>>;
  where?: InputMaybe<Custom_Attribute_Schema_Bool_Exp>;
};


export type Query_RootCustom_Attribute_Schema_AuditArgs = {
  distinct_on?: InputMaybe<Array<Custom_Attribute_Schema_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Custom_Attribute_Schema_Audit_Order_By>>;
  where?: InputMaybe<Custom_Attribute_Schema_Audit_Bool_Exp>;
};


export type Query_RootCustom_Attribute_Schema_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootCustom_Attribute_Schema_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootCustom_RibbonArgs = {
  distinct_on?: InputMaybe<Array<Custom_Ribbon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Custom_Ribbon_Order_By>>;
  where?: InputMaybe<Custom_Ribbon_Bool_Exp>;
};


export type Query_RootCustom_Ribbon_AuditArgs = {
  distinct_on?: InputMaybe<Array<Custom_Ribbon_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Custom_Ribbon_Audit_Order_By>>;
  where?: InputMaybe<Custom_Ribbon_Audit_Bool_Exp>;
};


export type Query_RootCustom_Ribbon_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootCustom_Ribbon_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootDashboardArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dashboard_Order_By>>;
  where?: InputMaybe<Dashboard_Bool_Exp>;
};


export type Query_RootDashboard_AuditArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dashboard_Audit_Order_By>>;
  where?: InputMaybe<Dashboard_Audit_Bool_Exp>;
};


export type Query_RootDashboard_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootDashboard_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootData_ImportArgs = {
  distinct_on?: InputMaybe<Array<Data_Import_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Data_Import_Order_By>>;
  where?: InputMaybe<Data_Import_Bool_Exp>;
};


export type Query_RootData_Import_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Data_Import_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Data_Import_Order_By>>;
  where?: InputMaybe<Data_Import_Bool_Exp>;
};


export type Query_RootData_Import_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootData_Import_ErrorArgs = {
  distinct_on?: InputMaybe<Array<Data_Import_Error_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Data_Import_Error_Order_By>>;
  where?: InputMaybe<Data_Import_Error_Bool_Exp>;
};


export type Query_RootData_Import_Error_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Data_Import_Error_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Data_Import_Error_Order_By>>;
  where?: InputMaybe<Data_Import_Error_Bool_Exp>;
};


export type Query_RootData_Import_Error_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootDepartment_AuditArgs = {
  distinct_on?: InputMaybe<Array<Department_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Audit_Order_By>>;
  where?: InputMaybe<Department_Audit_Bool_Exp>;
};


export type Query_RootDepartment_Audit_By_PkArgs = {
  DepartmentTypeId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Query_RootDepartment_TypeArgs = {
  distinct_on?: InputMaybe<Array<Department_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Type_Order_By>>;
  where?: InputMaybe<Department_Type_Bool_Exp>;
};


export type Query_RootDepartment_Type_AuditArgs = {
  distinct_on?: InputMaybe<Array<Department_Type_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Type_Audit_Order_By>>;
  where?: InputMaybe<Department_Type_Audit_Bool_Exp>;
};


export type Query_RootDepartment_Type_Audit_By_PkArgs = {
  DepartmentTypeId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootDepartment_Type_By_PkArgs = {
  DepartmentTypeId: Scalars['uuid']['input'];
};


export type Query_RootDepartment_Type_GroupArgs = {
  distinct_on?: InputMaybe<Array<Department_Type_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Type_Group_Order_By>>;
  where?: InputMaybe<Department_Type_Group_Bool_Exp>;
};


export type Query_RootDepartment_Type_Group_AuditArgs = {
  distinct_on?: InputMaybe<Array<Department_Type_Group_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Type_Group_Audit_Order_By>>;
  where?: InputMaybe<Department_Type_Group_Audit_Bool_Exp>;
};


export type Query_RootDepartment_Type_Group_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootDocumentArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


export type Query_RootDocument_Assessment_ResultArgs = {
  distinct_on?: InputMaybe<Array<Document_Assessment_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Assessment_Result_Order_By>>;
  where?: InputMaybe<Document_Assessment_Result_Bool_Exp>;
};


export type Query_RootDocument_Assessment_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Assessment_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Assessment_Result_Order_By>>;
  where?: InputMaybe<Document_Assessment_Result_Bool_Exp>;
};


export type Query_RootDocument_Assessment_Result_AuditArgs = {
  distinct_on?: InputMaybe<Array<Document_Assessment_Result_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Assessment_Result_Audit_Order_By>>;
  where?: InputMaybe<Document_Assessment_Result_Audit_Bool_Exp>;
};


export type Query_RootDocument_Assessment_Result_Audit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Assessment_Result_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Assessment_Result_Audit_Order_By>>;
  where?: InputMaybe<Document_Assessment_Result_Audit_Bool_Exp>;
};


export type Query_RootDocument_Assessment_Result_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootDocument_Assessment_Result_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootDocument_AuditArgs = {
  distinct_on?: InputMaybe<Array<Document_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Audit_Order_By>>;
  where?: InputMaybe<Document_Audit_Bool_Exp>;
};


export type Query_RootDocument_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootDocument_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootDocument_FileArgs = {
  distinct_on?: InputMaybe<Array<Document_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_File_Order_By>>;
  where?: InputMaybe<Document_File_Bool_Exp>;
};


export type Query_RootDocument_File_AuditArgs = {
  distinct_on?: InputMaybe<Array<Document_File_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_File_Audit_Order_By>>;
  where?: InputMaybe<Document_File_Audit_Bool_Exp>;
};


export type Query_RootDocument_File_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootDocument_File_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootDocument_Linked_DocumentArgs = {
  distinct_on?: InputMaybe<Array<Document_Linked_Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Linked_Document_Order_By>>;
  where?: InputMaybe<Document_Linked_Document_Bool_Exp>;
};


export type Query_RootDocument_Linked_Document_AuditArgs = {
  distinct_on?: InputMaybe<Array<Document_Linked_Document_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Linked_Document_Audit_Order_By>>;
  where?: InputMaybe<Document_Linked_Document_Audit_Bool_Exp>;
};


export type Query_RootDocument_Linked_Document_Audit_By_PkArgs = {
  DocumentId: Scalars['uuid']['input'];
  LinkedDocumentId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootDocument_Linked_Document_By_PkArgs = {
  DocumentId: Scalars['uuid']['input'];
  LinkedDocumentId: Scalars['uuid']['input'];
};


export type Query_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Query_RootFile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Query_RootFile_AuditArgs = {
  distinct_on?: InputMaybe<Array<File_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<File_Audit_Order_By>>;
  where?: InputMaybe<File_Audit_Bool_Exp>;
};


export type Query_RootFile_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootFile_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootForm_ConfigurationArgs = {
  distinct_on?: InputMaybe<Array<Form_Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Form_Configuration_Order_By>>;
  where?: InputMaybe<Form_Configuration_Bool_Exp>;
};


export type Query_RootForm_Configuration_AuditArgs = {
  distinct_on?: InputMaybe<Array<Form_Configuration_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Form_Configuration_Audit_Order_By>>;
  where?: InputMaybe<Form_Configuration_Audit_Bool_Exp>;
};


export type Query_RootForm_Configuration_Audit_By_PkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentType: Scalars['String']['input'];
};


export type Query_RootForm_Field_ConfigurationArgs = {
  distinct_on?: InputMaybe<Array<Form_Field_Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Form_Field_Configuration_Order_By>>;
  where?: InputMaybe<Form_Field_Configuration_Bool_Exp>;
};


export type Query_RootForm_Field_Configuration_AuditArgs = {
  distinct_on?: InputMaybe<Array<Form_Field_Configuration_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Form_Field_Configuration_Audit_Order_By>>;
  where?: InputMaybe<Form_Field_Configuration_Audit_Bool_Exp>;
};


export type Query_RootForm_Field_Configuration_Audit_By_PkArgs = {
  FieldId: Scalars['String']['input'];
  FormConfigurationParentType: Scalars['String']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootForm_Field_OrderingArgs = {
  distinct_on?: InputMaybe<Array<Form_Field_Ordering_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Form_Field_Ordering_Order_By>>;
  where?: InputMaybe<Form_Field_Ordering_Bool_Exp>;
};


export type Query_RootForm_Field_Ordering_AuditArgs = {
  distinct_on?: InputMaybe<Array<Form_Field_Ordering_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Form_Field_Ordering_Audit_Order_By>>;
  where?: InputMaybe<Form_Field_Ordering_Audit_Bool_Exp>;
};


export type Query_RootForm_Field_Ordering_Audit_By_PkArgs = {
  FieldId: Scalars['String']['input'];
  FormConfigurationParentType: Scalars['String']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootImpactArgs = {
  distinct_on?: InputMaybe<Array<Impact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Order_By>>;
  where?: InputMaybe<Impact_Bool_Exp>;
};


export type Query_RootImpact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Impact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Order_By>>;
  where?: InputMaybe<Impact_Bool_Exp>;
};


export type Query_RootImpact_AuditArgs = {
  distinct_on?: InputMaybe<Array<Impact_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Audit_Order_By>>;
  where?: InputMaybe<Impact_Audit_Bool_Exp>;
};


export type Query_RootImpact_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootImpact_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootImpact_ParentArgs = {
  distinct_on?: InputMaybe<Array<Impact_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Parent_Order_By>>;
  where?: InputMaybe<Impact_Parent_Bool_Exp>;
};


export type Query_RootImpact_Parent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Impact_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Parent_Order_By>>;
  where?: InputMaybe<Impact_Parent_Bool_Exp>;
};


export type Query_RootImpact_Parent_AuditArgs = {
  distinct_on?: InputMaybe<Array<Impact_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Parent_Audit_Order_By>>;
  where?: InputMaybe<Impact_Parent_Audit_Bool_Exp>;
};


export type Query_RootImpact_Parent_Audit_By_PkArgs = {
  ImpactId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Query_RootImpact_Parent_By_PkArgs = {
  ImpactId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Query_RootImpact_RatingArgs = {
  distinct_on?: InputMaybe<Array<Impact_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Rating_Order_By>>;
  where?: InputMaybe<Impact_Rating_Bool_Exp>;
};


export type Query_RootImpact_Rating_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Impact_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Rating_Order_By>>;
  where?: InputMaybe<Impact_Rating_Bool_Exp>;
};


export type Query_RootImpact_Rating_AuditArgs = {
  distinct_on?: InputMaybe<Array<Impact_Rating_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Rating_Audit_Order_By>>;
  where?: InputMaybe<Impact_Rating_Audit_Bool_Exp>;
};


export type Query_RootImpact_Rating_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootImpact_Rating_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootIndicatorArgs = {
  distinct_on?: InputMaybe<Array<Indicator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Indicator_Order_By>>;
  where?: InputMaybe<Indicator_Bool_Exp>;
};


export type Query_RootIndicator_AuditArgs = {
  distinct_on?: InputMaybe<Array<Indicator_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Indicator_Audit_Order_By>>;
  where?: InputMaybe<Indicator_Audit_Bool_Exp>;
};


export type Query_RootIndicator_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootIndicator_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootIndicator_ParentArgs = {
  distinct_on?: InputMaybe<Array<Indicator_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Indicator_Parent_Order_By>>;
  where?: InputMaybe<Indicator_Parent_Bool_Exp>;
};


export type Query_RootIndicator_Parent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Indicator_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Indicator_Parent_Order_By>>;
  where?: InputMaybe<Indicator_Parent_Bool_Exp>;
};


export type Query_RootIndicator_Parent_By_PkArgs = {
  IndicatorId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Query_RootIndicator_ResultArgs = {
  distinct_on?: InputMaybe<Array<Indicator_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Indicator_Result_Order_By>>;
  where?: InputMaybe<Indicator_Result_Bool_Exp>;
};


export type Query_RootIndicator_Result_AuditArgs = {
  distinct_on?: InputMaybe<Array<Indicator_Result_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Indicator_Result_Audit_Order_By>>;
  where?: InputMaybe<Indicator_Result_Audit_Bool_Exp>;
};


export type Query_RootIndicator_Result_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootIndicator_Result_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootIndicator_TypeArgs = {
  distinct_on?: InputMaybe<Array<Indicator_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Indicator_Type_Order_By>>;
  where?: InputMaybe<Indicator_Type_Bool_Exp>;
};


export type Query_RootIndicator_Type_By_PkArgs = {
  Value: Scalars['String']['input'];
};


export type Query_RootInternal_Audit_EntityArgs = {
  distinct_on?: InputMaybe<Array<Internal_Audit_Entity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Internal_Audit_Entity_Order_By>>;
  where?: InputMaybe<Internal_Audit_Entity_Bool_Exp>;
};


export type Query_RootInternal_Audit_Entity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Internal_Audit_Entity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Internal_Audit_Entity_Order_By>>;
  where?: InputMaybe<Internal_Audit_Entity_Bool_Exp>;
};


export type Query_RootInternal_Audit_Entity_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootInternal_Audit_ReportArgs = {
  distinct_on?: InputMaybe<Array<Internal_Audit_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Internal_Audit_Report_Order_By>>;
  where?: InputMaybe<Internal_Audit_Report_Bool_Exp>;
};


export type Query_RootInternal_Audit_Report_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Internal_Audit_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Internal_Audit_Report_Order_By>>;
  where?: InputMaybe<Internal_Audit_Report_Bool_Exp>;
};


export type Query_RootInternal_Audit_Report_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootIssueArgs = {
  distinct_on?: InputMaybe<Array<Issue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Order_By>>;
  where?: InputMaybe<Issue_Bool_Exp>;
};


export type Query_RootIssue_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Issue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Order_By>>;
  where?: InputMaybe<Issue_Bool_Exp>;
};


export type Query_RootIssue_AssessmentArgs = {
  distinct_on?: InputMaybe<Array<Issue_Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Assessment_Order_By>>;
  where?: InputMaybe<Issue_Assessment_Bool_Exp>;
};


export type Query_RootIssue_Assessment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Issue_Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Assessment_Order_By>>;
  where?: InputMaybe<Issue_Assessment_Bool_Exp>;
};


export type Query_RootIssue_Assessment_AuditArgs = {
  distinct_on?: InputMaybe<Array<Issue_Assessment_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Assessment_Audit_Order_By>>;
  where?: InputMaybe<Issue_Assessment_Audit_Bool_Exp>;
};


export type Query_RootIssue_Assessment_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootIssue_Assessment_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootIssue_AuditArgs = {
  distinct_on?: InputMaybe<Array<Issue_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Audit_Order_By>>;
  where?: InputMaybe<Issue_Audit_Bool_Exp>;
};


export type Query_RootIssue_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootIssue_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootIssue_ParentArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Order_By>>;
  where?: InputMaybe<Issue_Parent_Bool_Exp>;
};


export type Query_RootIssue_Parent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Order_By>>;
  where?: InputMaybe<Issue_Parent_Bool_Exp>;
};


export type Query_RootIssue_Parent_AuditArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Audit_Order_By>>;
  where?: InputMaybe<Issue_Parent_Audit_Bool_Exp>;
};


export type Query_RootIssue_Parent_Audit_By_PkArgs = {
  IssueId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Query_RootIssue_Parent_By_PkArgs = {
  IssueId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Query_RootIssue_UpdateArgs = {
  distinct_on?: InputMaybe<Array<Issue_Update_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Update_Order_By>>;
  where?: InputMaybe<Issue_Update_Bool_Exp>;
};


export type Query_RootIssue_Update_AuditArgs = {
  distinct_on?: InputMaybe<Array<Issue_Update_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Update_Audit_Order_By>>;
  where?: InputMaybe<Issue_Update_Audit_Bool_Exp>;
};


export type Query_RootIssue_Update_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootIssue_Update_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootLinked_ItemArgs = {
  distinct_on?: InputMaybe<Array<Linked_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Linked_Item_Order_By>>;
  where?: InputMaybe<Linked_Item_Bool_Exp>;
};


export type Query_RootLinked_Item_By_PkArgs = {
  Source: Scalars['uuid']['input'];
  Target: Scalars['uuid']['input'];
};


export type Query_RootNodeArgs = {
  distinct_on?: InputMaybe<Array<Node_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Node_Order_By>>;
  where?: InputMaybe<Node_Bool_Exp>;
};


export type Query_RootNode_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Node_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Node_Order_By>>;
  where?: InputMaybe<Node_Bool_Exp>;
};


export type Query_RootNode_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootObligationArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Order_By>>;
  where?: InputMaybe<Obligation_Bool_Exp>;
};


export type Query_RootObligation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Order_By>>;
  where?: InputMaybe<Obligation_Bool_Exp>;
};


export type Query_RootObligation_Assessment_ResultArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Assessment_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Assessment_Result_Order_By>>;
  where?: InputMaybe<Obligation_Assessment_Result_Bool_Exp>;
};


export type Query_RootObligation_Assessment_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Assessment_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Assessment_Result_Order_By>>;
  where?: InputMaybe<Obligation_Assessment_Result_Bool_Exp>;
};


export type Query_RootObligation_Assessment_Result_AuditArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Assessment_Result_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Assessment_Result_Audit_Order_By>>;
  where?: InputMaybe<Obligation_Assessment_Result_Audit_Bool_Exp>;
};


export type Query_RootObligation_Assessment_Result_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootObligation_Assessment_Result_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootObligation_Assessment_StatusArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Assessment_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Assessment_Status_Order_By>>;
  where?: InputMaybe<Obligation_Assessment_Status_Bool_Exp>;
};


export type Query_RootObligation_Assessment_Status_By_PkArgs = {
  Value: Scalars['String']['input'];
};


export type Query_RootObligation_AuditArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Audit_Order_By>>;
  where?: InputMaybe<Obligation_Audit_Bool_Exp>;
};


export type Query_RootObligation_Audit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Audit_Order_By>>;
  where?: InputMaybe<Obligation_Audit_Bool_Exp>;
};


export type Query_RootObligation_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootObligation_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootObligation_ImpactArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Impact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Impact_Order_By>>;
  where?: InputMaybe<Obligation_Impact_Bool_Exp>;
};


export type Query_RootObligation_Impact_AuditArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Impact_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Impact_Audit_Order_By>>;
  where?: InputMaybe<Obligation_Impact_Audit_Bool_Exp>;
};


export type Query_RootObligation_Impact_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootObligation_Impact_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootObligation_TypeArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Type_Order_By>>;
  where?: InputMaybe<Obligation_Type_Bool_Exp>;
};


export type Query_RootObligation_Type_By_PkArgs = {
  Value: Scalars['String']['input'];
};


export type Query_RootOwner_AuditArgs = {
  distinct_on?: InputMaybe<Array<Owner_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Audit_Order_By>>;
  where?: InputMaybe<Owner_Audit_Bool_Exp>;
};


export type Query_RootOwner_Audit_By_PkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


export type Query_RootOwner_Group_AuditArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Audit_Order_By>>;
  where?: InputMaybe<Owner_Group_Audit_Bool_Exp>;
};


export type Query_RootOwner_Group_Audit_By_PkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  UserGroupId: Scalars['uuid']['input'];
};


export type Query_RootQuestionnaire_InviteArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Invite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Invite_Order_By>>;
  where?: InputMaybe<Questionnaire_Invite_Bool_Exp>;
};


export type Query_RootQuestionnaire_Invite_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootQuestionnaire_TemplateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Template_Order_By>>;
  where?: InputMaybe<Questionnaire_Template_Bool_Exp>;
};


export type Query_RootQuestionnaire_Template_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootQuestionnaire_Template_VersionArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Template_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Template_Version_Order_By>>;
  where?: InputMaybe<Questionnaire_Template_Version_Bool_Exp>;
};


export type Query_RootQuestionnaire_Template_Version_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootQuestionnaire_Template_Version_StatusArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Template_Version_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Template_Version_Status_Order_By>>;
  where?: InputMaybe<Questionnaire_Template_Version_Status_Bool_Exp>;
};


export type Query_RootQuestionnaire_Template_Version_Status_By_PkArgs = {
  Value: Scalars['String']['input'];
};


export type Query_RootRelation_FileArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


export type Query_RootRelation_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


export type Query_RootRelation_File_AuditArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Audit_Order_By>>;
  where?: InputMaybe<Relation_File_Audit_Bool_Exp>;
};


export type Query_RootRelation_File_By_PkArgs = {
  FileId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Query_RootRiskArgs = {
  distinct_on?: InputMaybe<Array<Risk_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Order_By>>;
  where?: InputMaybe<Risk_Bool_Exp>;
};


export type Query_RootRisk_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Order_By>>;
  where?: InputMaybe<Risk_Bool_Exp>;
};


export type Query_RootRisk_Assessment_ResultArgs = {
  distinct_on?: InputMaybe<Array<Risk_Assessment_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Assessment_Result_Order_By>>;
  where?: InputMaybe<Risk_Assessment_Result_Bool_Exp>;
};


export type Query_RootRisk_Assessment_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Assessment_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Assessment_Result_Order_By>>;
  where?: InputMaybe<Risk_Assessment_Result_Bool_Exp>;
};


export type Query_RootRisk_Assessment_Result_AuditArgs = {
  distinct_on?: InputMaybe<Array<Risk_Assessment_Result_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Assessment_Result_Audit_Order_By>>;
  where?: InputMaybe<Risk_Assessment_Result_Audit_Bool_Exp>;
};


export type Query_RootRisk_Assessment_Result_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootRisk_Assessment_Result_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootRisk_AuditArgs = {
  distinct_on?: InputMaybe<Array<Risk_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Audit_Order_By>>;
  where?: InputMaybe<Risk_Audit_Bool_Exp>;
};


export type Query_RootRisk_Audit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Audit_Order_By>>;
  where?: InputMaybe<Risk_Audit_Bool_Exp>;
};


export type Query_RootRisk_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootRisk_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootRisk_ScoreArgs = {
  distinct_on?: InputMaybe<Array<Risk_Score_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Score_Order_By>>;
  where?: InputMaybe<Risk_Score_Bool_Exp>;
};


export type Query_RootRisk_Score_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Score_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Score_Order_By>>;
  where?: InputMaybe<Risk_Score_Bool_Exp>;
};


export type Query_RootRisk_Score_By_PkArgs = {
  RiskId: Scalars['uuid']['input'];
};


export type Query_RootRisksmart_Indicator_Parent_AuditArgs = {
  distinct_on?: InputMaybe<Array<Risksmart_Indicator_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risksmart_Indicator_Parent_Audit_Order_By>>;
  where?: InputMaybe<Risksmart_Indicator_Parent_Audit_Bool_Exp>;
};


export type Query_RootRisksmart_Indicator_Parent_Audit_By_PkArgs = {
  IndicatorId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Query_RootRisksmart_Linked_Item_AuditArgs = {
  distinct_on?: InputMaybe<Array<Risksmart_Linked_Item_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risksmart_Linked_Item_Audit_Order_By>>;
  where?: InputMaybe<Risksmart_Linked_Item_Audit_Bool_Exp>;
};


export type Query_RootRisksmart_Linked_Item_Audit_By_PkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  Source: Scalars['uuid']['input'];
  Target: Scalars['uuid']['input'];
};


export type Query_RootRole_AccessArgs = {
  distinct_on?: InputMaybe<Array<Role_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Access_Order_By>>;
  where?: InputMaybe<Role_Access_Bool_Exp>;
};


export type Query_RootTag_TypeArgs = {
  distinct_on?: InputMaybe<Array<Tag_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tag_Type_Order_By>>;
  where?: InputMaybe<Tag_Type_Bool_Exp>;
};


export type Query_RootTag_Type_AuditArgs = {
  distinct_on?: InputMaybe<Array<Tag_Type_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tag_Type_Audit_Order_By>>;
  where?: InputMaybe<Tag_Type_Audit_Bool_Exp>;
};


export type Query_RootTag_Type_Audit_By_PkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  TagTypeId: Scalars['uuid']['input'];
};


export type Query_RootTag_Type_By_PkArgs = {
  TagTypeId: Scalars['uuid']['input'];
};


export type Query_RootTag_Type_GroupArgs = {
  distinct_on?: InputMaybe<Array<Tag_Type_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tag_Type_Group_Order_By>>;
  where?: InputMaybe<Tag_Type_Group_Bool_Exp>;
};


export type Query_RootTag_Type_Group_AuditArgs = {
  distinct_on?: InputMaybe<Array<Tag_Type_Group_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tag_Type_Group_Audit_Order_By>>;
  where?: InputMaybe<Tag_Type_Group_Audit_Bool_Exp>;
};


export type Query_RootTag_Type_Group_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootTaxonomyArgs = {
  distinct_on?: InputMaybe<Array<Taxonomy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taxonomy_Order_By>>;
  where?: InputMaybe<Taxonomy_Bool_Exp>;
};


export type Query_RootTaxonomy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Taxonomy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taxonomy_Order_By>>;
  where?: InputMaybe<Taxonomy_Bool_Exp>;
};


export type Query_RootTaxonomy_AuditArgs = {
  distinct_on?: InputMaybe<Array<Taxonomy_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taxonomy_Audit_Order_By>>;
  where?: InputMaybe<Taxonomy_Audit_Bool_Exp>;
};


export type Query_RootTaxonomy_Audit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Taxonomy_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taxonomy_Audit_Order_By>>;
  where?: InputMaybe<Taxonomy_Audit_Bool_Exp>;
};


export type Query_RootTaxonomy_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootTaxonomy_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootTaxonomy_OrgArgs = {
  distinct_on?: InputMaybe<Array<Taxonomy_Org_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taxonomy_Org_Order_By>>;
  where?: InputMaybe<Taxonomy_Org_Bool_Exp>;
};


export type Query_RootTaxonomy_Org_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Taxonomy_Org_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taxonomy_Org_Order_By>>;
  where?: InputMaybe<Taxonomy_Org_Bool_Exp>;
};


export type Query_RootTaxonomy_Org_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootTest_ResultArgs = {
  distinct_on?: InputMaybe<Array<Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Test_Result_Order_By>>;
  where?: InputMaybe<Test_Result_Bool_Exp>;
};


export type Query_RootTest_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Test_Result_Order_By>>;
  where?: InputMaybe<Test_Result_Bool_Exp>;
};


export type Query_RootTest_Result_AuditArgs = {
  distinct_on?: InputMaybe<Array<Test_Result_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Test_Result_Audit_Order_By>>;
  where?: InputMaybe<Test_Result_Audit_Bool_Exp>;
};


export type Query_RootTest_Result_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootTest_Result_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootThird_PartyArgs = {
  distinct_on?: InputMaybe<Array<Third_Party_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Third_Party_Order_By>>;
  where?: InputMaybe<Third_Party_Bool_Exp>;
};


export type Query_RootThird_Party_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootThird_Party_ResponseArgs = {
  distinct_on?: InputMaybe<Array<Third_Party_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Third_Party_Response_Order_By>>;
  where?: InputMaybe<Third_Party_Response_Bool_Exp>;
};


export type Query_RootThird_Party_Response_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootThird_Party_Response_StatusArgs = {
  distinct_on?: InputMaybe<Array<Third_Party_Response_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Third_Party_Response_Status_Order_By>>;
  where?: InputMaybe<Third_Party_Response_Status_Bool_Exp>;
};


export type Query_RootThird_Party_Response_Status_By_PkArgs = {
  Value: Scalars['String']['input'];
};


export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_GroupArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Group_Order_By>>;
  where?: InputMaybe<User_Group_Bool_Exp>;
};


export type Query_RootUser_Group_AuditArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Group_Audit_Order_By>>;
  where?: InputMaybe<User_Group_Audit_Bool_Exp>;
};


export type Query_RootUser_Group_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Query_RootUser_Group_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Query_RootUser_Group_UserArgs = {
  distinct_on?: InputMaybe<Array<User_Group_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Group_User_Order_By>>;
  where?: InputMaybe<User_Group_User_Bool_Exp>;
};


export type Query_RootUser_Group_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Group_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Group_User_Order_By>>;
  where?: InputMaybe<User_Group_User_Bool_Exp>;
};


export type Query_RootUser_Group_User_AuditArgs = {
  distinct_on?: InputMaybe<Array<User_Group_User_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Group_User_Audit_Order_By>>;
  where?: InputMaybe<User_Group_User_Audit_Bool_Exp>;
};


export type Query_RootUser_Group_User_Audit_By_PkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  UserGroupId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


export type Query_RootUser_Group_User_By_PkArgs = {
  UserGroupId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


export type Query_RootUser_Search_PreferencesArgs = {
  distinct_on?: InputMaybe<Array<User_Search_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Search_Preferences_Order_By>>;
  where?: InputMaybe<User_Search_Preferences_Bool_Exp>;
};


export type Query_RootUser_Search_Preferences_AuditArgs = {
  distinct_on?: InputMaybe<Array<User_Search_Preferences_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Search_Preferences_Audit_Order_By>>;
  where?: InputMaybe<User_Search_Preferences_Audit_Bool_Exp>;
};


export type Query_RootUser_Table_PreferencesArgs = {
  distinct_on?: InputMaybe<Array<User_Table_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Table_Preferences_Order_By>>;
  where?: InputMaybe<User_Table_Preferences_Bool_Exp>;
};

/** columns and relationships of "risksmart.questionnaire_invite" */
export type Questionnaire_Invite = {
  __typename?: 'questionnaire_invite';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  Message?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  QuestionnaireTemplateVersionId: Scalars['uuid']['output'];
  ThirdPartyId: Scalars['uuid']['output'];
  UserEmail: Scalars['String']['output'];
  UserId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parent: Third_Party_Response;
  /** An object relationship */
  questionnaireTemplateVersion: Questionnaire_Template_Version;
  /** An object relationship */
  thirdParty: Third_Party;
  /** An object relationship */
  user?: Maybe<Auth_User>;
};

/** order by aggregate values of table "risksmart.questionnaire_invite" */
export type Questionnaire_Invite_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questionnaire_Invite_Max_Order_By>;
  min?: InputMaybe<Questionnaire_Invite_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.questionnaire_invite" */
export type Questionnaire_Invite_Arr_Rel_Insert_Input = {
  data: Array<Questionnaire_Invite_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Questionnaire_Invite_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risksmart.questionnaire_invite". All fields are combined with a logical 'AND'. */
export type Questionnaire_Invite_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  Message?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  QuestionnaireTemplateVersionId?: InputMaybe<Uuid_Comparison_Exp>;
  ThirdPartyId?: InputMaybe<Uuid_Comparison_Exp>;
  UserEmail?: InputMaybe<String_Comparison_Exp>;
  UserId?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Questionnaire_Invite_Bool_Exp>>;
  _not?: InputMaybe<Questionnaire_Invite_Bool_Exp>;
  _or?: InputMaybe<Array<Questionnaire_Invite_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parent?: InputMaybe<Third_Party_Response_Bool_Exp>;
  questionnaireTemplateVersion?: InputMaybe<Questionnaire_Template_Version_Bool_Exp>;
  thirdParty?: InputMaybe<Third_Party_Bool_Exp>;
  user?: InputMaybe<Auth_User_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.questionnaire_invite" */
export const Questionnaire_Invite_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  QuestionnaireInvitePkey: 'questionnaire_invite_pkey'
} as const;

export type Questionnaire_Invite_Constraint = typeof Questionnaire_Invite_Constraint[keyof typeof Questionnaire_Invite_Constraint];
/** input type for inserting data into table "risksmart.questionnaire_invite" */
export type Questionnaire_Invite_Insert_Input = {
  Message?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  QuestionnaireTemplateVersionId?: InputMaybe<Scalars['uuid']['input']>;
  ThirdPartyId?: InputMaybe<Scalars['uuid']['input']>;
  UserEmail?: InputMaybe<Scalars['String']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Third_Party_Response_Obj_Rel_Insert_Input>;
  questionnaireTemplateVersion?: InputMaybe<Questionnaire_Template_Version_Obj_Rel_Insert_Input>;
  thirdParty?: InputMaybe<Third_Party_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "risksmart.questionnaire_invite" */
export type Questionnaire_Invite_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Message?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  QuestionnaireTemplateVersionId?: InputMaybe<Order_By>;
  ThirdPartyId?: InputMaybe<Order_By>;
  UserEmail?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.questionnaire_invite" */
export type Questionnaire_Invite_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Message?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  QuestionnaireTemplateVersionId?: InputMaybe<Order_By>;
  ThirdPartyId?: InputMaybe<Order_By>;
  UserEmail?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.questionnaire_invite" */
export type Questionnaire_Invite_Mutation_Response = {
  __typename?: 'questionnaire_invite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Questionnaire_Invite>;
};

/** on_conflict condition type for table "risksmart.questionnaire_invite" */
export type Questionnaire_Invite_On_Conflict = {
  constraint: Questionnaire_Invite_Constraint;
  update_columns?: Array<Questionnaire_Invite_Update_Column>;
  where?: InputMaybe<Questionnaire_Invite_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.questionnaire_invite". */
export type Questionnaire_Invite_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Message?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  QuestionnaireTemplateVersionId?: InputMaybe<Order_By>;
  ThirdPartyId?: InputMaybe<Order_By>;
  UserEmail?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parent?: InputMaybe<Third_Party_Response_Order_By>;
  questionnaireTemplateVersion?: InputMaybe<Questionnaire_Template_Version_Order_By>;
  thirdParty?: InputMaybe<Third_Party_Order_By>;
  user?: InputMaybe<Auth_User_Order_By>;
};

/** select columns of table "risksmart.questionnaire_invite" */
export const Questionnaire_Invite_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  Message: 'Message',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  QuestionnaireTemplateVersionId: 'QuestionnaireTemplateVersionId',
  /** column name */
  ThirdPartyId: 'ThirdPartyId',
  /** column name */
  UserEmail: 'UserEmail',
  /** column name */
  UserId: 'UserId'
} as const;

export type Questionnaire_Invite_Select_Column = typeof Questionnaire_Invite_Select_Column[keyof typeof Questionnaire_Invite_Select_Column];
/** Streaming cursor of the table "questionnaire_invite" */
export type Questionnaire_Invite_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questionnaire_Invite_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questionnaire_Invite_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Message?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  QuestionnaireTemplateVersionId?: InputMaybe<Scalars['uuid']['input']>;
  ThirdPartyId?: InputMaybe<Scalars['uuid']['input']>;
  UserEmail?: InputMaybe<Scalars['String']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.questionnaire_invite" */
export const Questionnaire_Invite_Update_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  Message: 'Message',
  /** column name */
  OrgKey: 'OrgKey',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  QuestionnaireTemplateVersionId: 'QuestionnaireTemplateVersionId',
  /** column name */
  ThirdPartyId: 'ThirdPartyId',
  /** column name */
  UserEmail: 'UserEmail',
  /** column name */
  UserId: 'UserId'
} as const;

export type Questionnaire_Invite_Update_Column = typeof Questionnaire_Invite_Update_Column[keyof typeof Questionnaire_Invite_Update_Column];
/** columns and relationships of "risksmart.questionnaire_template" */
export type Questionnaire_Template = {
  __typename?: 'questionnaire_template';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Title: Scalars['String']['output'];
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An array relationship */
  contributorGroups: Array<Contributor_Group>;
  /** An aggregate relationship */
  contributorGroups_aggregate: Contributor_Group_Aggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: Contributor_Aggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An array relationship */
  ownerGroups: Array<Owner_Group>;
  /** An aggregate relationship */
  ownerGroups_aggregate: Owner_Group_Aggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: Owner_Aggregate;
  /** An array relationship */
  tags: Array<Tag>;
  /** An array relationship */
  versions: Array<Questionnaire_Template_Version>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type Questionnaire_TemplateCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type Questionnaire_TemplateAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type Questionnaire_TemplateContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type Questionnaire_TemplateContributorGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type Questionnaire_TemplateContributorsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type Questionnaire_TemplateContributors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type Questionnaire_TemplateDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type Questionnaire_TemplateOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type Questionnaire_TemplateOwnerGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type Questionnaire_TemplateOwnersArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type Questionnaire_TemplateOwners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type Questionnaire_TemplateTagsArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type Questionnaire_TemplateVersionsArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Template_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Template_Version_Order_By>>;
  where?: InputMaybe<Questionnaire_Template_Version_Bool_Exp>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Questionnaire_Template_Append_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.questionnaire_template". All fields are combined with a logical 'AND'. */
export type Questionnaire_Template_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Questionnaire_Template_Bool_Exp>>;
  _not?: InputMaybe<Questionnaire_Template_Bool_Exp>;
  _or?: InputMaybe<Array<Questionnaire_Template_Bool_Exp>>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  contributorGroups?: InputMaybe<Contributor_Group_Bool_Exp>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Bool_Exp>;
  contributors?: InputMaybe<Contributor_Bool_Exp>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  departments?: InputMaybe<Department_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  ownerGroups?: InputMaybe<Owner_Group_Bool_Exp>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Bool_Exp>;
  owners?: InputMaybe<Owner_Bool_Exp>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Bool_Exp>;
  tags?: InputMaybe<Tag_Bool_Exp>;
  versions?: InputMaybe<Questionnaire_Template_Version_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.questionnaire_template" */
export const Questionnaire_Template_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  QuestionnaireTemplatePkey: 'questionnaire_template_pkey'
} as const;

export type Questionnaire_Template_Constraint = typeof Questionnaire_Template_Constraint[keyof typeof Questionnaire_Template_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Questionnaire_Template_Delete_At_Path_Input = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Questionnaire_Template_Delete_Elem_Input = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Questionnaire_Template_Delete_Key_Input = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.questionnaire_template" */
export type Questionnaire_Template_Insert_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  contributorGroups?: InputMaybe<Contributor_Group_Arr_Rel_Insert_Input>;
  contributors?: InputMaybe<Contributor_Arr_Rel_Insert_Input>;
  departments?: InputMaybe<Department_Arr_Rel_Insert_Input>;
  ownerGroups?: InputMaybe<Owner_Group_Arr_Rel_Insert_Input>;
  owners?: InputMaybe<Owner_Arr_Rel_Insert_Input>;
  tags?: InputMaybe<Tag_Arr_Rel_Insert_Input>;
  versions?: InputMaybe<Questionnaire_Template_Version_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "risksmart.questionnaire_template" */
export type Questionnaire_Template_Mutation_Response = {
  __typename?: 'questionnaire_template_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Questionnaire_Template>;
};

/** input type for inserting object relation for remote table "risksmart.questionnaire_template" */
export type Questionnaire_Template_Obj_Rel_Insert_Input = {
  data: Questionnaire_Template_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Questionnaire_Template_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.questionnaire_template" */
export type Questionnaire_Template_On_Conflict = {
  constraint: Questionnaire_Template_Constraint;
  update_columns?: Array<Questionnaire_Template_Update_Column>;
  where?: InputMaybe<Questionnaire_Template_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.questionnaire_template". */
export type Questionnaire_Template_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Order_By>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  departments_aggregate?: InputMaybe<Department_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Order_By>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Order_By>;
  tags_aggregate?: InputMaybe<Tag_Aggregate_Order_By>;
  versions_aggregate?: InputMaybe<Questionnaire_Template_Version_Aggregate_Order_By>;
};

/** primary key columns input for table: risksmart.questionnaire_template */
export type Questionnaire_Template_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Questionnaire_Template_Prepend_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.questionnaire_template" */
export const Questionnaire_Template_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Title: 'Title'
} as const;

export type Questionnaire_Template_Select_Column = typeof Questionnaire_Template_Select_Column[keyof typeof Questionnaire_Template_Select_Column];
/** input type for updating data in table "risksmart.questionnaire_template" */
export type Questionnaire_Template_Set_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "questionnaire_template" */
export type Questionnaire_Template_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questionnaire_Template_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questionnaire_Template_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.questionnaire_template" */
export const Questionnaire_Template_Update_Column = {
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Title: 'Title'
} as const;

export type Questionnaire_Template_Update_Column = typeof Questionnaire_Template_Update_Column[keyof typeof Questionnaire_Template_Update_Column];
export type Questionnaire_Template_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Questionnaire_Template_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Questionnaire_Template_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Questionnaire_Template_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Questionnaire_Template_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Questionnaire_Template_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questionnaire_Template_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questionnaire_Template_Bool_Exp;
};

/** columns and relationships of "risksmart.questionnaire_template_version" */
export type Questionnaire_Template_Version = {
  __typename?: 'questionnaire_template_version';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  Schema: Scalars['jsonb']['output'];
  Status: Questionnaire_Template_Version_Status_Enum;
  UISchema: Scalars['jsonb']['output'];
  Version: Scalars['String']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  invitations: Array<Questionnaire_Invite>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  parent?: Maybe<Questionnaire_Template>;
};


/** columns and relationships of "risksmart.questionnaire_template_version" */
export type Questionnaire_Template_VersionCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.questionnaire_template_version" */
export type Questionnaire_Template_VersionSchemaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.questionnaire_template_version" */
export type Questionnaire_Template_VersionUiSchemaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.questionnaire_template_version" */
export type Questionnaire_Template_VersionInvitationsArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Invite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Invite_Order_By>>;
  where?: InputMaybe<Questionnaire_Invite_Bool_Exp>;
};

/** order by aggregate values of table "risksmart.questionnaire_template_version" */
export type Questionnaire_Template_Version_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questionnaire_Template_Version_Max_Order_By>;
  min?: InputMaybe<Questionnaire_Template_Version_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Questionnaire_Template_Version_Append_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  UISchema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.questionnaire_template_version" */
export type Questionnaire_Template_Version_Arr_Rel_Insert_Input = {
  data: Array<Questionnaire_Template_Version_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Questionnaire_Template_Version_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risksmart.questionnaire_template_version". All fields are combined with a logical 'AND'. */
export type Questionnaire_Template_Version_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  Schema?: InputMaybe<Jsonb_Comparison_Exp>;
  Status?: InputMaybe<Questionnaire_Template_Version_Status_Enum_Comparison_Exp>;
  UISchema?: InputMaybe<Jsonb_Comparison_Exp>;
  Version?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Questionnaire_Template_Version_Bool_Exp>>;
  _not?: InputMaybe<Questionnaire_Template_Version_Bool_Exp>;
  _or?: InputMaybe<Array<Questionnaire_Template_Version_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  invitations?: InputMaybe<Questionnaire_Invite_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  parent?: InputMaybe<Questionnaire_Template_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.questionnaire_template_version" */
export const Questionnaire_Template_Version_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  QuestionnaireTemplateVersionPkey: 'questionnaire_template_version_pkey'
} as const;

export type Questionnaire_Template_Version_Constraint = typeof Questionnaire_Template_Version_Constraint[keyof typeof Questionnaire_Template_Version_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Questionnaire_Template_Version_Delete_At_Path_Input = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
  Schema?: InputMaybe<Array<Scalars['String']['input']>>;
  UISchema?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Questionnaire_Template_Version_Delete_Elem_Input = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
  Schema?: InputMaybe<Scalars['Int']['input']>;
  UISchema?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Questionnaire_Template_Version_Delete_Key_Input = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
  Schema?: InputMaybe<Scalars['String']['input']>;
  UISchema?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.questionnaire_template_version" */
export type Questionnaire_Template_Version_Insert_Input = {
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  Status?: InputMaybe<Questionnaire_Template_Version_Status_Enum>;
  UISchema?: InputMaybe<Scalars['jsonb']['input']>;
  Version?: InputMaybe<Scalars['String']['input']>;
  invitations?: InputMaybe<Questionnaire_Invite_Arr_Rel_Insert_Input>;
  parent?: InputMaybe<Questionnaire_Template_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "risksmart.questionnaire_template_version" */
export type Questionnaire_Template_Version_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  Version?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.questionnaire_template_version" */
export type Questionnaire_Template_Version_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  Version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.questionnaire_template_version" */
export type Questionnaire_Template_Version_Mutation_Response = {
  __typename?: 'questionnaire_template_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Questionnaire_Template_Version>;
};

/** input type for inserting object relation for remote table "risksmart.questionnaire_template_version" */
export type Questionnaire_Template_Version_Obj_Rel_Insert_Input = {
  data: Questionnaire_Template_Version_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Questionnaire_Template_Version_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.questionnaire_template_version" */
export type Questionnaire_Template_Version_On_Conflict = {
  constraint: Questionnaire_Template_Version_Constraint;
  update_columns?: Array<Questionnaire_Template_Version_Update_Column>;
  where?: InputMaybe<Questionnaire_Template_Version_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.questionnaire_template_version". */
export type Questionnaire_Template_Version_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  Schema?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  UISchema?: InputMaybe<Order_By>;
  Version?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  invitations_aggregate?: InputMaybe<Questionnaire_Invite_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  parent?: InputMaybe<Questionnaire_Template_Order_By>;
};

/** primary key columns input for table: risksmart.questionnaire_template_version */
export type Questionnaire_Template_Version_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Questionnaire_Template_Version_Prepend_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  UISchema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.questionnaire_template_version" */
export const Questionnaire_Template_Version_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  Schema: 'Schema',
  /** column name */
  Status: 'Status',
  /** column name */
  UiSchema: 'UISchema',
  /** column name */
  Version: 'Version'
} as const;

export type Questionnaire_Template_Version_Select_Column = typeof Questionnaire_Template_Version_Select_Column[keyof typeof Questionnaire_Template_Version_Select_Column];
/** input type for updating data in table "risksmart.questionnaire_template_version" */
export type Questionnaire_Template_Version_Set_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  Status?: InputMaybe<Questionnaire_Template_Version_Status_Enum>;
  UISchema?: InputMaybe<Scalars['jsonb']['input']>;
  Version?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.questionnaire_template_version_status" */
export type Questionnaire_Template_Version_Status = {
  __typename?: 'questionnaire_template_version_status';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
  /** An array relationship */
  questionnaire_template_versions: Array<Questionnaire_Template_Version>;
};


/** columns and relationships of "risksmart.questionnaire_template_version_status" */
export type Questionnaire_Template_Version_StatusQuestionnaire_Template_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Template_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Template_Version_Order_By>>;
  where?: InputMaybe<Questionnaire_Template_Version_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "risksmart.questionnaire_template_version_status". All fields are combined with a logical 'AND'. */
export type Questionnaire_Template_Version_Status_Bool_Exp = {
  Comment?: InputMaybe<String_Comparison_Exp>;
  Value?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Questionnaire_Template_Version_Status_Bool_Exp>>;
  _not?: InputMaybe<Questionnaire_Template_Version_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Questionnaire_Template_Version_Status_Bool_Exp>>;
  questionnaire_template_versions?: InputMaybe<Questionnaire_Template_Version_Bool_Exp>;
};

export const Questionnaire_Template_Version_Status_Enum = {
  /** Archived */
  Archived: 'archived',
  /** Draft */
  Draft: 'draft',
  /** Published */
  Published: 'published'
} as const;

export type Questionnaire_Template_Version_Status_Enum = typeof Questionnaire_Template_Version_Status_Enum[keyof typeof Questionnaire_Template_Version_Status_Enum];
/** Boolean expression to compare columns of type "questionnaire_template_version_status_enum". All fields are combined with logical 'AND'. */
export type Questionnaire_Template_Version_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Questionnaire_Template_Version_Status_Enum>;
  _in?: InputMaybe<Array<Questionnaire_Template_Version_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Questionnaire_Template_Version_Status_Enum>;
  _nin?: InputMaybe<Array<Questionnaire_Template_Version_Status_Enum>>;
};

/** Ordering options when selecting data from "risksmart.questionnaire_template_version_status". */
export type Questionnaire_Template_Version_Status_Order_By = {
  Comment?: InputMaybe<Order_By>;
  Value?: InputMaybe<Order_By>;
  questionnaire_template_versions_aggregate?: InputMaybe<Questionnaire_Template_Version_Aggregate_Order_By>;
};

/** select columns of table "risksmart.questionnaire_template_version_status" */
export const Questionnaire_Template_Version_Status_Select_Column = {
  /** column name */
  Comment: 'Comment',
  /** column name */
  Value: 'Value'
} as const;

export type Questionnaire_Template_Version_Status_Select_Column = typeof Questionnaire_Template_Version_Status_Select_Column[keyof typeof Questionnaire_Template_Version_Status_Select_Column];
/** Streaming cursor of the table "questionnaire_template_version_status" */
export type Questionnaire_Template_Version_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questionnaire_Template_Version_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questionnaire_Template_Version_Status_Stream_Cursor_Value_Input = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "questionnaire_template_version" */
export type Questionnaire_Template_Version_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questionnaire_Template_Version_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questionnaire_Template_Version_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  Status?: InputMaybe<Questionnaire_Template_Version_Status_Enum>;
  UISchema?: InputMaybe<Scalars['jsonb']['input']>;
  Version?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.questionnaire_template_version" */
export const Questionnaire_Template_Version_Update_Column = {
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Schema: 'Schema',
  /** column name */
  Status: 'Status',
  /** column name */
  UiSchema: 'UISchema',
  /** column name */
  Version: 'Version'
} as const;

export type Questionnaire_Template_Version_Update_Column = typeof Questionnaire_Template_Version_Update_Column[keyof typeof Questionnaire_Template_Version_Update_Column];
export type Questionnaire_Template_Version_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Questionnaire_Template_Version_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Questionnaire_Template_Version_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Questionnaire_Template_Version_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Questionnaire_Template_Version_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Questionnaire_Template_Version_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questionnaire_Template_Version_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questionnaire_Template_Version_Bool_Exp;
};

/** columns and relationships of "risksmart.relation_file" */
export type Relation_File = {
  __typename?: 'relation_file';
  FileId: Scalars['uuid']['output'];
  ParentId: Scalars['uuid']['output'];
  /** An object relationship */
  acceptance?: Maybe<Acceptance>;
  /** An object relationship */
  action?: Maybe<Action>;
  /** An object relationship */
  appetite?: Maybe<Appetite>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  document?: Maybe<Document>;
  /** An object relationship */
  file?: Maybe<File>;
  /** An object relationship */
  issue?: Maybe<Issue>;
  /** An object relationship */
  issueUpdate?: Maybe<Issue_Update>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  riskAssessmentResult?: Maybe<Risk_Assessment_Result>;
  /** An object relationship */
  testResult?: Maybe<Test_Result>;
  /** An object relationship */
  thirdParty?: Maybe<Third_Party>;
  /** An object relationship */
  thirdPartyResponse?: Maybe<Third_Party_Response>;
};

/** aggregated selection of "risksmart.relation_file" */
export type Relation_File_Aggregate = {
  __typename?: 'relation_file_aggregate';
  aggregate?: Maybe<Relation_File_Aggregate_Fields>;
  nodes: Array<Relation_File>;
};

export type Relation_File_Aggregate_Bool_Exp = {
  count?: InputMaybe<Relation_File_Aggregate_Bool_Exp_Count>;
};

export type Relation_File_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Relation_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Relation_File_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.relation_file" */
export type Relation_File_Aggregate_Fields = {
  __typename?: 'relation_file_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Relation_File_Max_Fields>;
  min?: Maybe<Relation_File_Min_Fields>;
};


/** aggregate fields of "risksmart.relation_file" */
export type Relation_File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Relation_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.relation_file" */
export type Relation_File_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Relation_File_Max_Order_By>;
  min?: InputMaybe<Relation_File_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.relation_file" */
export type Relation_File_Arr_Rel_Insert_Input = {
  data: Array<Relation_File_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Relation_File_On_Conflict>;
};

/** columns and relationships of "risksmart.relation_file_audit" */
export type Relation_File_Audit = {
  __typename?: 'relation_file_audit';
  FileId: Scalars['uuid']['output'];
  ParentId: Scalars['uuid']['output'];
};

/** Boolean expression to filter rows from the table "risksmart.relation_file_audit". All fields are combined with a logical 'AND'. */
export type Relation_File_Audit_Bool_Exp = {
  FileId?: InputMaybe<Uuid_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Relation_File_Audit_Bool_Exp>>;
  _not?: InputMaybe<Relation_File_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Relation_File_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.relation_file_audit". */
export type Relation_File_Audit_Order_By = {
  FileId?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.relation_file_audit" */
export const Relation_File_Audit_Select_Column = {
  /** column name */
  FileId: 'FileId',
  /** column name */
  ParentId: 'ParentId'
} as const;

export type Relation_File_Audit_Select_Column = typeof Relation_File_Audit_Select_Column[keyof typeof Relation_File_Audit_Select_Column];
/** Streaming cursor of the table "relation_file_audit" */
export type Relation_File_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Relation_File_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Relation_File_Audit_Stream_Cursor_Value_Input = {
  FileId?: InputMaybe<Scalars['uuid']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.relation_file". All fields are combined with a logical 'AND'. */
export type Relation_File_Bool_Exp = {
  FileId?: InputMaybe<Uuid_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Relation_File_Bool_Exp>>;
  _not?: InputMaybe<Relation_File_Bool_Exp>;
  _or?: InputMaybe<Array<Relation_File_Bool_Exp>>;
  acceptance?: InputMaybe<Acceptance_Bool_Exp>;
  action?: InputMaybe<Action_Bool_Exp>;
  appetite?: InputMaybe<Appetite_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  document?: InputMaybe<Document_Bool_Exp>;
  file?: InputMaybe<File_Bool_Exp>;
  issue?: InputMaybe<Issue_Bool_Exp>;
  issueUpdate?: InputMaybe<Issue_Update_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  riskAssessmentResult?: InputMaybe<Risk_Assessment_Result_Bool_Exp>;
  testResult?: InputMaybe<Test_Result_Bool_Exp>;
  thirdParty?: InputMaybe<Third_Party_Bool_Exp>;
  thirdPartyResponse?: InputMaybe<Third_Party_Response_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.relation_file" */
export const Relation_File_Constraint = {
  /** unique or primary key constraint on columns "FileId", "ParentId" */
  RelationFilePkey: 'relation_file_pkey'
} as const;

export type Relation_File_Constraint = typeof Relation_File_Constraint[keyof typeof Relation_File_Constraint];
/** input type for inserting data into table "risksmart.relation_file" */
export type Relation_File_Insert_Input = {
  FileId?: InputMaybe<Scalars['uuid']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Parent_Type_Enum>;
  acceptance?: InputMaybe<Acceptance_Obj_Rel_Insert_Input>;
  action?: InputMaybe<Action_Obj_Rel_Insert_Input>;
  appetite?: InputMaybe<Appetite_Obj_Rel_Insert_Input>;
  document?: InputMaybe<Document_Obj_Rel_Insert_Input>;
  file?: InputMaybe<File_Obj_Rel_Insert_Input>;
  issue?: InputMaybe<Issue_Obj_Rel_Insert_Input>;
  issueUpdate?: InputMaybe<Issue_Update_Obj_Rel_Insert_Input>;
  riskAssessmentResult?: InputMaybe<Risk_Assessment_Result_Obj_Rel_Insert_Input>;
  testResult?: InputMaybe<Test_Result_Obj_Rel_Insert_Input>;
  thirdParty?: InputMaybe<Third_Party_Obj_Rel_Insert_Input>;
  thirdPartyResponse?: InputMaybe<Third_Party_Response_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Relation_File_Max_Fields = {
  __typename?: 'relation_file_max_fields';
  FileId?: Maybe<Scalars['uuid']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.relation_file" */
export type Relation_File_Max_Order_By = {
  FileId?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Relation_File_Min_Fields = {
  __typename?: 'relation_file_min_fields';
  FileId?: Maybe<Scalars['uuid']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.relation_file" */
export type Relation_File_Min_Order_By = {
  FileId?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.relation_file" */
export type Relation_File_Mutation_Response = {
  __typename?: 'relation_file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Relation_File>;
};

/** on_conflict condition type for table "risksmart.relation_file" */
export type Relation_File_On_Conflict = {
  constraint: Relation_File_Constraint;
  update_columns?: Array<Relation_File_Update_Column>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.relation_file". */
export type Relation_File_Order_By = {
  FileId?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  acceptance?: InputMaybe<Acceptance_Order_By>;
  action?: InputMaybe<Action_Order_By>;
  appetite?: InputMaybe<Appetite_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  document?: InputMaybe<Document_Order_By>;
  file?: InputMaybe<File_Order_By>;
  issue?: InputMaybe<Issue_Order_By>;
  issueUpdate?: InputMaybe<Issue_Update_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  riskAssessmentResult?: InputMaybe<Risk_Assessment_Result_Order_By>;
  testResult?: InputMaybe<Test_Result_Order_By>;
  thirdParty?: InputMaybe<Third_Party_Order_By>;
  thirdPartyResponse?: InputMaybe<Third_Party_Response_Order_By>;
};

/** select columns of table "risksmart.relation_file" */
export const Relation_File_Select_Column = {
  /** column name */
  FileId: 'FileId',
  /** column name */
  ParentId: 'ParentId'
} as const;

export type Relation_File_Select_Column = typeof Relation_File_Select_Column[keyof typeof Relation_File_Select_Column];
/** Streaming cursor of the table "relation_file" */
export type Relation_File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Relation_File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Relation_File_Stream_Cursor_Value_Input = {
  FileId?: InputMaybe<Scalars['uuid']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** placeholder for update columns of table "risksmart.relation_file" (current role has no relevant permissions) */
export const Relation_File_Update_Column = {
  /** placeholder (do not use) */
  Placeholder: '_PLACEHOLDER'
} as const;

export type Relation_File_Update_Column = typeof Relation_File_Update_Column[keyof typeof Relation_File_Update_Column];
/** columns and relationships of "risksmart.risk" */
export type Risk = {
  __typename?: 'risk';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentRiskId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Risk_Status_Type_Enum>;
  Tier: Scalars['Int']['output'];
  Title: Scalars['String']['output'];
  Treatment?: Maybe<Risk_Treatment_Type_Enum>;
  /** An array relationship */
  acceptances: Array<Acceptance_Parent>;
  /** An aggregate relationship */
  acceptances_aggregate: Acceptance_Parent_Aggregate;
  /** An array relationship */
  actions: Array<Action_Parent>;
  /** An aggregate relationship */
  actions_aggregate: Action_Parent_Aggregate;
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An array relationship */
  appetites: Array<Appetite_Parent>;
  /** An aggregate relationship */
  appetites_aggregate: Appetite_Parent_Aggregate;
  /** An array relationship */
  assessmentResults: Array<Assessment_Result_Parent>;
  /** An aggregate relationship */
  assessmentResults_aggregate: Assessment_Result_Parent_Aggregate;
  /** An array relationship */
  childRisks: Array<Risk>;
  /** An aggregate relationship */
  childRisks_aggregate: Risk_Aggregate;
  /** An array relationship */
  contributorGroups: Array<Contributor_Group>;
  /** An aggregate relationship */
  contributorGroups_aggregate: Contributor_Group_Aggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: Contributor_Aggregate;
  /** An array relationship */
  controls: Array<Control_Parent>;
  /** An aggregate relationship */
  controls_aggregate: Control_Parent_Aggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An array relationship */
  impactRatings: Array<Impact_Rating>;
  /** An aggregate relationship */
  impactRatings_aggregate: Impact_Rating_Aggregate;
  /** An array relationship */
  indicators: Array<Indicator_Parent>;
  /** An aggregate relationship */
  indicators_aggregate: Indicator_Parent_Aggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An array relationship */
  ownerGroups: Array<Owner_Group>;
  /** An aggregate relationship */
  ownerGroups_aggregate: Owner_Group_Aggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: Owner_Aggregate;
  /** An object relationship */
  parent?: Maybe<Risk>;
  /** An object relationship */
  parentNode?: Maybe<Node>;
  /** An object relationship */
  riskScore?: Maybe<Risk_Score>;
  /** An object relationship */
  schedule?: Maybe<Schedule>;
  /** An object relationship */
  scheduleState?: Maybe<Schedule_State>;
  /** An array relationship */
  tags: Array<Tag>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskAcceptancesArgs = {
  distinct_on?: InputMaybe<Array<Acceptance_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Acceptance_Parent_Order_By>>;
  where?: InputMaybe<Acceptance_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskAcceptances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Acceptance_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Acceptance_Parent_Order_By>>;
  where?: InputMaybe<Acceptance_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskActionsArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskAppetitesArgs = {
  distinct_on?: InputMaybe<Array<Appetite_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Appetite_Parent_Order_By>>;
  where?: InputMaybe<Appetite_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskAppetites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appetite_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Appetite_Parent_Order_By>>;
  where?: InputMaybe<Appetite_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskAssessmentResultsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskAssessmentResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskChildRisksArgs = {
  distinct_on?: InputMaybe<Array<Risk_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Order_By>>;
  where?: InputMaybe<Risk_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskChildRisks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Order_By>>;
  where?: InputMaybe<Risk_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskContributorGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskContributorsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskContributors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskControlsArgs = {
  distinct_on?: InputMaybe<Array<Control_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Parent_Order_By>>;
  where?: InputMaybe<Control_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskControls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Parent_Order_By>>;
  where?: InputMaybe<Control_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskImpactRatingsArgs = {
  distinct_on?: InputMaybe<Array<Impact_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Rating_Order_By>>;
  where?: InputMaybe<Impact_Rating_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskImpactRatings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Impact_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Rating_Order_By>>;
  where?: InputMaybe<Impact_Rating_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskIndicatorsArgs = {
  distinct_on?: InputMaybe<Array<Indicator_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Indicator_Parent_Order_By>>;
  where?: InputMaybe<Indicator_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskIndicators_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Indicator_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Indicator_Parent_Order_By>>;
  where?: InputMaybe<Indicator_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskOwnerGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskOwnersArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskOwners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskTagsArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};

/** aggregated selection of "risksmart.risk" */
export type Risk_Aggregate = {
  __typename?: 'risk_aggregate';
  aggregate?: Maybe<Risk_Aggregate_Fields>;
  nodes: Array<Risk>;
};

export type Risk_Aggregate_Bool_Exp = {
  count?: InputMaybe<Risk_Aggregate_Bool_Exp_Count>;
};

export type Risk_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Risk_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risk_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.risk" */
export type Risk_Aggregate_Fields = {
  __typename?: 'risk_aggregate_fields';
  avg?: Maybe<Risk_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Risk_Max_Fields>;
  min?: Maybe<Risk_Min_Fields>;
  stddev?: Maybe<Risk_Stddev_Fields>;
  stddev_pop?: Maybe<Risk_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Risk_Stddev_Samp_Fields>;
  sum?: Maybe<Risk_Sum_Fields>;
  var_pop?: Maybe<Risk_Var_Pop_Fields>;
  var_samp?: Maybe<Risk_Var_Samp_Fields>;
  variance?: Maybe<Risk_Variance_Fields>;
};


/** aggregate fields of "risksmart.risk" */
export type Risk_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Risk_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.risk" */
export type Risk_Aggregate_Order_By = {
  avg?: InputMaybe<Risk_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Risk_Max_Order_By>;
  min?: InputMaybe<Risk_Min_Order_By>;
  stddev?: InputMaybe<Risk_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Risk_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Risk_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Risk_Sum_Order_By>;
  var_pop?: InputMaybe<Risk_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Risk_Var_Samp_Order_By>;
  variance?: InputMaybe<Risk_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.risk" */
export type Risk_Arr_Rel_Insert_Input = {
  data: Array<Risk_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_On_Conflict>;
};

/** columns and relationships of "risksmart.risk_assessment_result" */
export type Risk_Assessment_Result = {
  __typename?: 'risk_assessment_result';
  ControlType: Risk_Assessment_Result_Control_Type_Enum;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  Impact?: Maybe<Scalars['Int']['output']>;
  Likelihood?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser: Scalars['String']['output'];
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType: Scalars['String']['output'];
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  files: Array<Relation_File>;
  /** An aggregate relationship */
  files_aggregate: Relation_File_Aggregate;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An array relationship */
  parents: Array<Assessment_Result_Parent>;
  /** An aggregate relationship */
  parents_aggregate: Assessment_Result_Parent_Aggregate;
};


/** columns and relationships of "risksmart.risk_assessment_result" */
export type Risk_Assessment_ResultCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.risk_assessment_result" */
export type Risk_Assessment_ResultAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk_assessment_result" */
export type Risk_Assessment_ResultFilesArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk_assessment_result" */
export type Risk_Assessment_ResultFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk_assessment_result" */
export type Risk_Assessment_ResultParentsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk_assessment_result" */
export type Risk_Assessment_ResultParents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};

/** aggregated selection of "risksmart.risk_assessment_result" */
export type Risk_Assessment_Result_Aggregate = {
  __typename?: 'risk_assessment_result_aggregate';
  aggregate?: Maybe<Risk_Assessment_Result_Aggregate_Fields>;
  nodes: Array<Risk_Assessment_Result>;
};

/** aggregate fields of "risksmart.risk_assessment_result" */
export type Risk_Assessment_Result_Aggregate_Fields = {
  __typename?: 'risk_assessment_result_aggregate_fields';
  avg?: Maybe<Risk_Assessment_Result_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Risk_Assessment_Result_Max_Fields>;
  min?: Maybe<Risk_Assessment_Result_Min_Fields>;
  stddev?: Maybe<Risk_Assessment_Result_Stddev_Fields>;
  stddev_pop?: Maybe<Risk_Assessment_Result_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Risk_Assessment_Result_Stddev_Samp_Fields>;
  sum?: Maybe<Risk_Assessment_Result_Sum_Fields>;
  var_pop?: Maybe<Risk_Assessment_Result_Var_Pop_Fields>;
  var_samp?: Maybe<Risk_Assessment_Result_Var_Samp_Fields>;
  variance?: Maybe<Risk_Assessment_Result_Variance_Fields>;
};


/** aggregate fields of "risksmart.risk_assessment_result" */
export type Risk_Assessment_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Risk_Assessment_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "risksmart.risk_assessment_result_audit" */
export type Risk_Assessment_Result_Audit = {
  __typename?: 'risk_assessment_result_audit';
  ControlType: Scalars['String']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  Impact?: Maybe<Scalars['Int']['output']>;
  Likelihood?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "risksmart.risk_assessment_result_audit" */
export type Risk_Assessment_Result_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.risk_assessment_result_audit". All fields are combined with a logical 'AND'. */
export type Risk_Assessment_Result_Audit_Bool_Exp = {
  ControlType?: InputMaybe<String_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  Impact?: InputMaybe<Int_Comparison_Exp>;
  Likelihood?: InputMaybe<Int_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Rating?: InputMaybe<Int_Comparison_Exp>;
  RatingType?: InputMaybe<String_Comparison_Exp>;
  Rationale?: InputMaybe<String_Comparison_Exp>;
  TestDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  _and?: InputMaybe<Array<Risk_Assessment_Result_Audit_Bool_Exp>>;
  _not?: InputMaybe<Risk_Assessment_Result_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Assessment_Result_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.risk_assessment_result_audit". */
export type Risk_Assessment_Result_Audit_Order_By = {
  ControlType?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Impact?: InputMaybe<Order_By>;
  Likelihood?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  RatingType?: InputMaybe<Order_By>;
  Rationale?: InputMaybe<Order_By>;
  TestDate?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.risk_assessment_result_audit" */
export const Risk_Assessment_Result_Audit_Select_Column = {
  /** column name */
  ControlType: 'ControlType',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Id: 'Id',
  /** column name */
  Impact: 'Impact',
  /** column name */
  Likelihood: 'Likelihood',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Rating: 'Rating',
  /** column name */
  RatingType: 'RatingType',
  /** column name */
  Rationale: 'Rationale',
  /** column name */
  TestDate: 'TestDate'
} as const;

export type Risk_Assessment_Result_Audit_Select_Column = typeof Risk_Assessment_Result_Audit_Select_Column[keyof typeof Risk_Assessment_Result_Audit_Select_Column];
/** Streaming cursor of the table "risk_assessment_result_audit" */
export type Risk_Assessment_Result_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risk_Assessment_Result_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risk_Assessment_Result_Audit_Stream_Cursor_Value_Input = {
  ControlType?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Impact?: InputMaybe<Scalars['Int']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate avg on columns */
export type Risk_Assessment_Result_Avg_Fields = {
  __typename?: 'risk_assessment_result_avg_fields';
  Impact?: Maybe<Scalars['Float']['output']>;
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.risk_assessment_result". All fields are combined with a logical 'AND'. */
export type Risk_Assessment_Result_Bool_Exp = {
  ControlType?: InputMaybe<Risk_Assessment_Result_Control_Type_Enum_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  Impact?: InputMaybe<Int_Comparison_Exp>;
  Likelihood?: InputMaybe<Int_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Rating?: InputMaybe<Int_Comparison_Exp>;
  RatingType?: InputMaybe<String_Comparison_Exp>;
  Rationale?: InputMaybe<String_Comparison_Exp>;
  TestDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  _and?: InputMaybe<Array<Risk_Assessment_Result_Bool_Exp>>;
  _not?: InputMaybe<Risk_Assessment_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Assessment_Result_Bool_Exp>>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  files?: InputMaybe<Relation_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parents?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
  parents_aggregate?: InputMaybe<Assessment_Result_Parent_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.risk_assessment_result" */
export const Risk_Assessment_Result_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  RiskAssessmentResultPkey: 'risk_assessment_result_pkey'
} as const;

export type Risk_Assessment_Result_Constraint = typeof Risk_Assessment_Result_Constraint[keyof typeof Risk_Assessment_Result_Constraint];
export const Risk_Assessment_Result_Control_Type_Enum = {
  /** Controlled */
  Controlled: 'Controlled',
  /** Uncontrolled */
  Uncontrolled: 'Uncontrolled'
} as const;

export type Risk_Assessment_Result_Control_Type_Enum = typeof Risk_Assessment_Result_Control_Type_Enum[keyof typeof Risk_Assessment_Result_Control_Type_Enum];
/** Boolean expression to compare columns of type "risk_assessment_result_control_type_enum". All fields are combined with logical 'AND'. */
export type Risk_Assessment_Result_Control_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Risk_Assessment_Result_Control_Type_Enum>;
  _in?: InputMaybe<Array<Risk_Assessment_Result_Control_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Risk_Assessment_Result_Control_Type_Enum>;
  _nin?: InputMaybe<Array<Risk_Assessment_Result_Control_Type_Enum>>;
};

/** input type for inserting data into table "risksmart.risk_assessment_result" */
export type Risk_Assessment_Result_Insert_Input = {
  ControlType?: InputMaybe<Risk_Assessment_Result_Control_Type_Enum>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Impact?: InputMaybe<Scalars['Int']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  files?: InputMaybe<Relation_File_Arr_Rel_Insert_Input>;
  parents?: InputMaybe<Assessment_Result_Parent_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Risk_Assessment_Result_Max_Fields = {
  __typename?: 'risk_assessment_result_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Impact?: Maybe<Scalars['Int']['output']>;
  Likelihood?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Risk_Assessment_Result_Min_Fields = {
  __typename?: 'risk_assessment_result_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Impact?: Maybe<Scalars['Int']['output']>;
  Likelihood?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "risksmart.risk_assessment_result" */
export type Risk_Assessment_Result_Mutation_Response = {
  __typename?: 'risk_assessment_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk_Assessment_Result>;
};

/** input type for inserting object relation for remote table "risksmart.risk_assessment_result" */
export type Risk_Assessment_Result_Obj_Rel_Insert_Input = {
  data: Risk_Assessment_Result_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Assessment_Result_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.risk_assessment_result" */
export type Risk_Assessment_Result_On_Conflict = {
  constraint: Risk_Assessment_Result_Constraint;
  update_columns?: Array<Risk_Assessment_Result_Update_Column>;
  where?: InputMaybe<Risk_Assessment_Result_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.risk_assessment_result". */
export type Risk_Assessment_Result_Order_By = {
  ControlType?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Impact?: InputMaybe<Order_By>;
  Likelihood?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  RatingType?: InputMaybe<Order_By>;
  Rationale?: InputMaybe<Order_By>;
  TestDate?: InputMaybe<Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parents_aggregate?: InputMaybe<Assessment_Result_Parent_Aggregate_Order_By>;
};

/** select columns of table "risksmart.risk_assessment_result" */
export const Risk_Assessment_Result_Select_Column = {
  /** column name */
  ControlType: 'ControlType',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Id: 'Id',
  /** column name */
  Impact: 'Impact',
  /** column name */
  Likelihood: 'Likelihood',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Rating: 'Rating',
  /** column name */
  RatingType: 'RatingType',
  /** column name */
  Rationale: 'Rationale',
  /** column name */
  TestDate: 'TestDate'
} as const;

export type Risk_Assessment_Result_Select_Column = typeof Risk_Assessment_Result_Select_Column[keyof typeof Risk_Assessment_Result_Select_Column];
/** aggregate stddev on columns */
export type Risk_Assessment_Result_Stddev_Fields = {
  __typename?: 'risk_assessment_result_stddev_fields';
  Impact?: Maybe<Scalars['Float']['output']>;
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Risk_Assessment_Result_Stddev_Pop_Fields = {
  __typename?: 'risk_assessment_result_stddev_pop_fields';
  Impact?: Maybe<Scalars['Float']['output']>;
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Risk_Assessment_Result_Stddev_Samp_Fields = {
  __typename?: 'risk_assessment_result_stddev_samp_fields';
  Impact?: Maybe<Scalars['Float']['output']>;
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "risk_assessment_result" */
export type Risk_Assessment_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risk_Assessment_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risk_Assessment_Result_Stream_Cursor_Value_Input = {
  ControlType?: InputMaybe<Risk_Assessment_Result_Control_Type_Enum>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Impact?: InputMaybe<Scalars['Int']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Risk_Assessment_Result_Sum_Fields = {
  __typename?: 'risk_assessment_result_sum_fields';
  Impact?: Maybe<Scalars['Int']['output']>;
  Likelihood?: Maybe<Scalars['Int']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.risk_assessment_result" */
export const Risk_Assessment_Result_Update_Column = {
  /** column name */
  ControlType: 'ControlType',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Impact: 'Impact',
  /** column name */
  Likelihood: 'Likelihood',
  /** column name */
  Rating: 'Rating',
  /** column name */
  RatingType: 'RatingType',
  /** column name */
  Rationale: 'Rationale',
  /** column name */
  TestDate: 'TestDate'
} as const;

export type Risk_Assessment_Result_Update_Column = typeof Risk_Assessment_Result_Update_Column[keyof typeof Risk_Assessment_Result_Update_Column];
/** aggregate var_pop on columns */
export type Risk_Assessment_Result_Var_Pop_Fields = {
  __typename?: 'risk_assessment_result_var_pop_fields';
  Impact?: Maybe<Scalars['Float']['output']>;
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Risk_Assessment_Result_Var_Samp_Fields = {
  __typename?: 'risk_assessment_result_var_samp_fields';
  Impact?: Maybe<Scalars['Float']['output']>;
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Risk_Assessment_Result_Variance_Fields = {
  __typename?: 'risk_assessment_result_variance_fields';
  Impact?: Maybe<Scalars['Float']['output']>;
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.risk_audit" */
export type Risk_Audit = {
  __typename?: 'risk_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentRiskId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Tier: Scalars['Int']['output'];
  Title: Scalars['String']['output'];
  Treatment?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  acceptanceAudits: Array<Acceptance_Parent_Audit>;
  /** An array relationship */
  actionAudits: Array<Action_Parent_Audit>;
  /** An array relationship */
  appetiteAudits: Array<Appetite_Parent_Audit>;
  /** An aggregate relationship */
  appetiteAudits_aggregate: Appetite_Parent_Audit_Aggregate;
  /** An array relationship */
  assessmentResultAudits: Array<Assessment_Result_Parent_Audit>;
  /** An array relationship */
  contributorAudits: Array<Contributor_Audit>;
  /** An aggregate relationship */
  contributorAudits_aggregate: Contributor_Audit_Aggregate;
  /** An array relationship */
  contributorGroupAudits: Array<Contributor_Group_Audit>;
  /** An aggregate relationship */
  contributorGroupAudits_aggregate: Contributor_Group_Audit_Aggregate;
  /** An array relationship */
  controlAudits: Array<Control_Parent_Audit>;
  /** An array relationship */
  departmentAudits: Array<Department_Audit>;
  /** An array relationship */
  impactRatingAudits: Array<Impact_Rating_Audit>;
  /** An array relationship */
  indicatorAudits: Array<Risksmart_Indicator_Parent_Audit>;
  /** An array relationship */
  ownerAudits: Array<Owner_Audit>;
  /** An array relationship */
  ownerGroupAudits: Array<Owner_Group_Audit>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type Risk_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type Risk_AuditAcceptanceAuditsArgs = {
  distinct_on?: InputMaybe<Array<Acceptance_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Acceptance_Parent_Audit_Order_By>>;
  where?: InputMaybe<Acceptance_Parent_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type Risk_AuditActionAuditsArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Audit_Order_By>>;
  where?: InputMaybe<Action_Parent_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type Risk_AuditAppetiteAuditsArgs = {
  distinct_on?: InputMaybe<Array<Appetite_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Appetite_Parent_Audit_Order_By>>;
  where?: InputMaybe<Appetite_Parent_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type Risk_AuditAppetiteAudits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appetite_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Appetite_Parent_Audit_Order_By>>;
  where?: InputMaybe<Appetite_Parent_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type Risk_AuditAssessmentResultAuditsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Audit_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type Risk_AuditContributorAuditsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Audit_Order_By>>;
  where?: InputMaybe<Contributor_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type Risk_AuditContributorAudits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Audit_Order_By>>;
  where?: InputMaybe<Contributor_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type Risk_AuditContributorGroupAuditsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Audit_Order_By>>;
  where?: InputMaybe<Contributor_Group_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type Risk_AuditContributorGroupAudits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Audit_Order_By>>;
  where?: InputMaybe<Contributor_Group_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type Risk_AuditControlAuditsArgs = {
  distinct_on?: InputMaybe<Array<Control_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Parent_Audit_Order_By>>;
  where?: InputMaybe<Control_Parent_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type Risk_AuditDepartmentAuditsArgs = {
  distinct_on?: InputMaybe<Array<Department_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Audit_Order_By>>;
  where?: InputMaybe<Department_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type Risk_AuditImpactRatingAuditsArgs = {
  distinct_on?: InputMaybe<Array<Impact_Rating_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Rating_Audit_Order_By>>;
  where?: InputMaybe<Impact_Rating_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type Risk_AuditIndicatorAuditsArgs = {
  distinct_on?: InputMaybe<Array<Risksmart_Indicator_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risksmart_Indicator_Parent_Audit_Order_By>>;
  where?: InputMaybe<Risksmart_Indicator_Parent_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type Risk_AuditOwnerAuditsArgs = {
  distinct_on?: InputMaybe<Array<Owner_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Audit_Order_By>>;
  where?: InputMaybe<Owner_Audit_Bool_Exp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type Risk_AuditOwnerGroupAuditsArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Audit_Order_By>>;
  where?: InputMaybe<Owner_Group_Audit_Bool_Exp>;
};

/** aggregated selection of "risksmart.risk_audit" */
export type Risk_Audit_Aggregate = {
  __typename?: 'risk_audit_aggregate';
  aggregate?: Maybe<Risk_Audit_Aggregate_Fields>;
  nodes: Array<Risk_Audit>;
};

/** aggregate fields of "risksmart.risk_audit" */
export type Risk_Audit_Aggregate_Fields = {
  __typename?: 'risk_audit_aggregate_fields';
  avg?: Maybe<Risk_Audit_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Risk_Audit_Max_Fields>;
  min?: Maybe<Risk_Audit_Min_Fields>;
  stddev?: Maybe<Risk_Audit_Stddev_Fields>;
  stddev_pop?: Maybe<Risk_Audit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Risk_Audit_Stddev_Samp_Fields>;
  sum?: Maybe<Risk_Audit_Sum_Fields>;
  var_pop?: Maybe<Risk_Audit_Var_Pop_Fields>;
  var_samp?: Maybe<Risk_Audit_Var_Samp_Fields>;
  variance?: Maybe<Risk_Audit_Variance_Fields>;
};


/** aggregate fields of "risksmart.risk_audit" */
export type Risk_Audit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Risk_Audit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Risk_Audit_Avg_Fields = {
  __typename?: 'risk_audit_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.risk_audit". All fields are combined with a logical 'AND'. */
export type Risk_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentRiskId?: InputMaybe<Uuid_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Status?: InputMaybe<String_Comparison_Exp>;
  Tier?: InputMaybe<Int_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  Treatment?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Risk_Audit_Bool_Exp>>;
  _not?: InputMaybe<Risk_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Audit_Bool_Exp>>;
  acceptanceAudits?: InputMaybe<Acceptance_Parent_Audit_Bool_Exp>;
  actionAudits?: InputMaybe<Action_Parent_Audit_Bool_Exp>;
  appetiteAudits?: InputMaybe<Appetite_Parent_Audit_Bool_Exp>;
  appetiteAudits_aggregate?: InputMaybe<Appetite_Parent_Audit_Aggregate_Bool_Exp>;
  assessmentResultAudits?: InputMaybe<Assessment_Result_Parent_Audit_Bool_Exp>;
  contributorAudits?: InputMaybe<Contributor_Audit_Bool_Exp>;
  contributorAudits_aggregate?: InputMaybe<Contributor_Audit_Aggregate_Bool_Exp>;
  contributorGroupAudits?: InputMaybe<Contributor_Group_Audit_Bool_Exp>;
  contributorGroupAudits_aggregate?: InputMaybe<Contributor_Group_Audit_Aggregate_Bool_Exp>;
  controlAudits?: InputMaybe<Control_Parent_Audit_Bool_Exp>;
  departmentAudits?: InputMaybe<Department_Audit_Bool_Exp>;
  impactRatingAudits?: InputMaybe<Impact_Rating_Audit_Bool_Exp>;
  indicatorAudits?: InputMaybe<Risksmart_Indicator_Parent_Audit_Bool_Exp>;
  ownerAudits?: InputMaybe<Owner_Audit_Bool_Exp>;
  ownerGroupAudits?: InputMaybe<Owner_Group_Audit_Bool_Exp>;
};

/** aggregate max on columns */
export type Risk_Audit_Max_Fields = {
  __typename?: 'risk_audit_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentRiskId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Tier?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  Treatment?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Risk_Audit_Min_Fields = {
  __typename?: 'risk_audit_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentRiskId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Tier?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  Treatment?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "risksmart.risk_audit". */
export type Risk_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentRiskId?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Tier?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  Treatment?: InputMaybe<Order_By>;
  acceptanceAudits_aggregate?: InputMaybe<Acceptance_Parent_Audit_Aggregate_Order_By>;
  actionAudits_aggregate?: InputMaybe<Action_Parent_Audit_Aggregate_Order_By>;
  appetiteAudits_aggregate?: InputMaybe<Appetite_Parent_Audit_Aggregate_Order_By>;
  assessmentResultAudits_aggregate?: InputMaybe<Assessment_Result_Parent_Audit_Aggregate_Order_By>;
  contributorAudits_aggregate?: InputMaybe<Contributor_Audit_Aggregate_Order_By>;
  contributorGroupAudits_aggregate?: InputMaybe<Contributor_Group_Audit_Aggregate_Order_By>;
  controlAudits_aggregate?: InputMaybe<Control_Parent_Audit_Aggregate_Order_By>;
  departmentAudits_aggregate?: InputMaybe<Department_Audit_Aggregate_Order_By>;
  impactRatingAudits_aggregate?: InputMaybe<Impact_Rating_Audit_Aggregate_Order_By>;
  indicatorAudits_aggregate?: InputMaybe<Risksmart_Indicator_Parent_Audit_Aggregate_Order_By>;
  ownerAudits_aggregate?: InputMaybe<Owner_Audit_Aggregate_Order_By>;
  ownerGroupAudits_aggregate?: InputMaybe<Owner_Group_Audit_Aggregate_Order_By>;
};

/** select columns of table "risksmart.risk_audit" */
export const Risk_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentRiskId: 'ParentRiskId',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Status: 'Status',
  /** column name */
  Tier: 'Tier',
  /** column name */
  Title: 'Title',
  /** column name */
  Treatment: 'Treatment'
} as const;

export type Risk_Audit_Select_Column = typeof Risk_Audit_Select_Column[keyof typeof Risk_Audit_Select_Column];
/** aggregate stddev on columns */
export type Risk_Audit_Stddev_Fields = {
  __typename?: 'risk_audit_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Risk_Audit_Stddev_Pop_Fields = {
  __typename?: 'risk_audit_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Risk_Audit_Stddev_Samp_Fields = {
  __typename?: 'risk_audit_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "risk_audit" */
export type Risk_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risk_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risk_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentRiskId?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Tier?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Treatment?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Risk_Audit_Sum_Fields = {
  __typename?: 'risk_audit_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Tier?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Risk_Audit_Var_Pop_Fields = {
  __typename?: 'risk_audit_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Risk_Audit_Var_Samp_Fields = {
  __typename?: 'risk_audit_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Risk_Audit_Variance_Fields = {
  __typename?: 'risk_audit_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type Risk_Avg_Fields = {
  __typename?: 'risk_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.risk" */
export type Risk_Avg_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
  Tier?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.risk". All fields are combined with a logical 'AND'. */
export type Risk_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentRiskId?: InputMaybe<Uuid_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Status?: InputMaybe<Risk_Status_Type_Enum_Comparison_Exp>;
  Tier?: InputMaybe<Int_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  Treatment?: InputMaybe<Risk_Treatment_Type_Enum_Comparison_Exp>;
  _and?: InputMaybe<Array<Risk_Bool_Exp>>;
  _not?: InputMaybe<Risk_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Bool_Exp>>;
  acceptances?: InputMaybe<Acceptance_Parent_Bool_Exp>;
  acceptances_aggregate?: InputMaybe<Acceptance_Parent_Aggregate_Bool_Exp>;
  actions?: InputMaybe<Action_Parent_Bool_Exp>;
  actions_aggregate?: InputMaybe<Action_Parent_Aggregate_Bool_Exp>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  appetites?: InputMaybe<Appetite_Parent_Bool_Exp>;
  appetites_aggregate?: InputMaybe<Appetite_Parent_Aggregate_Bool_Exp>;
  assessmentResults?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
  assessmentResults_aggregate?: InputMaybe<Assessment_Result_Parent_Aggregate_Bool_Exp>;
  childRisks?: InputMaybe<Risk_Bool_Exp>;
  childRisks_aggregate?: InputMaybe<Risk_Aggregate_Bool_Exp>;
  contributorGroups?: InputMaybe<Contributor_Group_Bool_Exp>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Bool_Exp>;
  contributors?: InputMaybe<Contributor_Bool_Exp>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Bool_Exp>;
  controls?: InputMaybe<Control_Parent_Bool_Exp>;
  controls_aggregate?: InputMaybe<Control_Parent_Aggregate_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  departments?: InputMaybe<Department_Bool_Exp>;
  impactRatings?: InputMaybe<Impact_Rating_Bool_Exp>;
  impactRatings_aggregate?: InputMaybe<Impact_Rating_Aggregate_Bool_Exp>;
  indicators?: InputMaybe<Indicator_Parent_Bool_Exp>;
  indicators_aggregate?: InputMaybe<Indicator_Parent_Aggregate_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  ownerGroups?: InputMaybe<Owner_Group_Bool_Exp>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Bool_Exp>;
  owners?: InputMaybe<Owner_Bool_Exp>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Bool_Exp>;
  parent?: InputMaybe<Risk_Bool_Exp>;
  parentNode?: InputMaybe<Node_Bool_Exp>;
  riskScore?: InputMaybe<Risk_Score_Bool_Exp>;
  schedule?: InputMaybe<Schedule_Bool_Exp>;
  scheduleState?: InputMaybe<Schedule_State_Bool_Exp>;
  tags?: InputMaybe<Tag_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.risk" */
export const Risk_Constraint = {
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxRiskOrgkeySequentialid: 'idx_risk_orgkey_sequentialid',
  /** unique or primary key constraint on columns "Id" */
  RiskPkey: 'risk_pkey'
} as const;

export type Risk_Constraint = typeof Risk_Constraint[keyof typeof Risk_Constraint];
/** input type for inserting data into table "risksmart.risk" */
export type Risk_Insert_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ParentRiskId?: InputMaybe<Scalars['uuid']['input']>;
  Status?: InputMaybe<Risk_Status_Type_Enum>;
  Tier?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Treatment?: InputMaybe<Risk_Treatment_Type_Enum>;
  acceptances?: InputMaybe<Acceptance_Parent_Arr_Rel_Insert_Input>;
  actions?: InputMaybe<Action_Parent_Arr_Rel_Insert_Input>;
  appetites?: InputMaybe<Appetite_Parent_Arr_Rel_Insert_Input>;
  assessmentResults?: InputMaybe<Assessment_Result_Parent_Arr_Rel_Insert_Input>;
  childRisks?: InputMaybe<Risk_Arr_Rel_Insert_Input>;
  contributorGroups?: InputMaybe<Contributor_Group_Arr_Rel_Insert_Input>;
  contributors?: InputMaybe<Contributor_Arr_Rel_Insert_Input>;
  controls?: InputMaybe<Control_Parent_Arr_Rel_Insert_Input>;
  departments?: InputMaybe<Department_Arr_Rel_Insert_Input>;
  impactRatings?: InputMaybe<Impact_Rating_Arr_Rel_Insert_Input>;
  indicators?: InputMaybe<Indicator_Parent_Arr_Rel_Insert_Input>;
  ownerGroups?: InputMaybe<Owner_Group_Arr_Rel_Insert_Input>;
  owners?: InputMaybe<Owner_Arr_Rel_Insert_Input>;
  parent?: InputMaybe<Risk_Obj_Rel_Insert_Input>;
  riskScore?: InputMaybe<Risk_Score_Obj_Rel_Insert_Input>;
  schedule?: InputMaybe<Schedule_Obj_Rel_Insert_Input>;
  tags?: InputMaybe<Tag_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Risk_Max_Fields = {
  __typename?: 'risk_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentRiskId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Tier?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.risk" */
export type Risk_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentRiskId?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Tier?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Risk_Min_Fields = {
  __typename?: 'risk_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentRiskId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Tier?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.risk" */
export type Risk_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentRiskId?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Tier?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "risksmart.risk" */
export type Risk_Obj_Rel_Insert_Input = {
  data: Risk_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.risk" */
export type Risk_On_Conflict = {
  constraint: Risk_Constraint;
  update_columns?: Array<Risk_Update_Column>;
  where?: InputMaybe<Risk_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.risk". */
export type Risk_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentRiskId?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Tier?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  Treatment?: InputMaybe<Order_By>;
  acceptances_aggregate?: InputMaybe<Acceptance_Parent_Aggregate_Order_By>;
  actions_aggregate?: InputMaybe<Action_Parent_Aggregate_Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  appetites_aggregate?: InputMaybe<Appetite_Parent_Aggregate_Order_By>;
  assessmentResults_aggregate?: InputMaybe<Assessment_Result_Parent_Aggregate_Order_By>;
  childRisks_aggregate?: InputMaybe<Risk_Aggregate_Order_By>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Order_By>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Order_By>;
  controls_aggregate?: InputMaybe<Control_Parent_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  departments_aggregate?: InputMaybe<Department_Aggregate_Order_By>;
  impactRatings_aggregate?: InputMaybe<Impact_Rating_Aggregate_Order_By>;
  indicators_aggregate?: InputMaybe<Indicator_Parent_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Order_By>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Order_By>;
  parent?: InputMaybe<Risk_Order_By>;
  parentNode?: InputMaybe<Node_Order_By>;
  riskScore?: InputMaybe<Risk_Score_Order_By>;
  schedule?: InputMaybe<Schedule_Order_By>;
  scheduleState?: InputMaybe<Schedule_State_Order_By>;
  tags_aggregate?: InputMaybe<Tag_Aggregate_Order_By>;
};

/** columns and relationships of "risksmart.risk_score" */
export type Risk_Score = {
  __typename?: 'risk_score';
  InherentScore?: Maybe<Scalars['float8']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  OrgKey: Scalars['String']['output'];
  ResidualScore?: Maybe<Scalars['float8']['output']>;
  RiskId: Scalars['uuid']['output'];
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  risk?: Maybe<Risk>;
};

/** aggregated selection of "risksmart.risk_score" */
export type Risk_Score_Aggregate = {
  __typename?: 'risk_score_aggregate';
  aggregate?: Maybe<Risk_Score_Aggregate_Fields>;
  nodes: Array<Risk_Score>;
};

/** aggregate fields of "risksmart.risk_score" */
export type Risk_Score_Aggregate_Fields = {
  __typename?: 'risk_score_aggregate_fields';
  avg?: Maybe<Risk_Score_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Risk_Score_Max_Fields>;
  min?: Maybe<Risk_Score_Min_Fields>;
  stddev?: Maybe<Risk_Score_Stddev_Fields>;
  stddev_pop?: Maybe<Risk_Score_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Risk_Score_Stddev_Samp_Fields>;
  sum?: Maybe<Risk_Score_Sum_Fields>;
  var_pop?: Maybe<Risk_Score_Var_Pop_Fields>;
  var_samp?: Maybe<Risk_Score_Var_Samp_Fields>;
  variance?: Maybe<Risk_Score_Variance_Fields>;
};


/** aggregate fields of "risksmart.risk_score" */
export type Risk_Score_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Risk_Score_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Risk_Score_Avg_Fields = {
  __typename?: 'risk_score_avg_fields';
  InherentScore?: Maybe<Scalars['Float']['output']>;
  ResidualScore?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.risk_score". All fields are combined with a logical 'AND'. */
export type Risk_Score_Bool_Exp = {
  InherentScore?: InputMaybe<Float8_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  OrgKey?: InputMaybe<String_Comparison_Exp>;
  ResidualScore?: InputMaybe<Float8_Comparison_Exp>;
  RiskId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Risk_Score_Bool_Exp>>;
  _not?: InputMaybe<Risk_Score_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Score_Bool_Exp>>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  risk?: InputMaybe<Risk_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.risk_score" */
export const Risk_Score_Constraint = {
  /** unique or primary key constraint on columns "RiskId" */
  RiskScorePkey: 'risk_score_pkey'
} as const;

export type Risk_Score_Constraint = typeof Risk_Score_Constraint[keyof typeof Risk_Score_Constraint];
/** input type for inserting data into table "risksmart.risk_score" */
export type Risk_Score_Insert_Input = {
  InherentScore?: InputMaybe<Scalars['float8']['input']>;
  ResidualScore?: InputMaybe<Scalars['float8']['input']>;
  RiskId?: InputMaybe<Scalars['uuid']['input']>;
  risk?: InputMaybe<Risk_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Risk_Score_Max_Fields = {
  __typename?: 'risk_score_max_fields';
  InherentScore?: Maybe<Scalars['float8']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ResidualScore?: Maybe<Scalars['float8']['output']>;
  RiskId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Risk_Score_Min_Fields = {
  __typename?: 'risk_score_min_fields';
  InherentScore?: Maybe<Scalars['float8']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ResidualScore?: Maybe<Scalars['float8']['output']>;
  RiskId?: Maybe<Scalars['uuid']['output']>;
};

/** input type for inserting object relation for remote table "risksmart.risk_score" */
export type Risk_Score_Obj_Rel_Insert_Input = {
  data: Risk_Score_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Score_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.risk_score" */
export type Risk_Score_On_Conflict = {
  constraint: Risk_Score_Constraint;
  update_columns?: Array<Risk_Score_Update_Column>;
  where?: InputMaybe<Risk_Score_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.risk_score". */
export type Risk_Score_Order_By = {
  InherentScore?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  ResidualScore?: InputMaybe<Order_By>;
  RiskId?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  risk?: InputMaybe<Risk_Order_By>;
};

/** select columns of table "risksmart.risk_score" */
export const Risk_Score_Select_Column = {
  /** column name */
  InherentScore: 'InherentScore',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  OrgKey: 'OrgKey',
  /** column name */
  ResidualScore: 'ResidualScore',
  /** column name */
  RiskId: 'RiskId'
} as const;

export type Risk_Score_Select_Column = typeof Risk_Score_Select_Column[keyof typeof Risk_Score_Select_Column];
/** aggregate stddev on columns */
export type Risk_Score_Stddev_Fields = {
  __typename?: 'risk_score_stddev_fields';
  InherentScore?: Maybe<Scalars['Float']['output']>;
  ResidualScore?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Risk_Score_Stddev_Pop_Fields = {
  __typename?: 'risk_score_stddev_pop_fields';
  InherentScore?: Maybe<Scalars['Float']['output']>;
  ResidualScore?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Risk_Score_Stddev_Samp_Fields = {
  __typename?: 'risk_score_stddev_samp_fields';
  InherentScore?: Maybe<Scalars['Float']['output']>;
  ResidualScore?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "risk_score" */
export type Risk_Score_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risk_Score_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risk_Score_Stream_Cursor_Value_Input = {
  InherentScore?: InputMaybe<Scalars['float8']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ResidualScore?: InputMaybe<Scalars['float8']['input']>;
  RiskId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type Risk_Score_Sum_Fields = {
  __typename?: 'risk_score_sum_fields';
  InherentScore?: Maybe<Scalars['float8']['output']>;
  ResidualScore?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "risksmart.risk_score" */
export const Risk_Score_Update_Column = {
  /** column name */
  InherentScore: 'InherentScore',
  /** column name */
  ResidualScore: 'ResidualScore',
  /** column name */
  RiskId: 'RiskId'
} as const;

export type Risk_Score_Update_Column = typeof Risk_Score_Update_Column[keyof typeof Risk_Score_Update_Column];
/** aggregate var_pop on columns */
export type Risk_Score_Var_Pop_Fields = {
  __typename?: 'risk_score_var_pop_fields';
  InherentScore?: Maybe<Scalars['Float']['output']>;
  ResidualScore?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Risk_Score_Var_Samp_Fields = {
  __typename?: 'risk_score_var_samp_fields';
  InherentScore?: Maybe<Scalars['Float']['output']>;
  ResidualScore?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Risk_Score_Variance_Fields = {
  __typename?: 'risk_score_variance_fields';
  InherentScore?: Maybe<Scalars['Float']['output']>;
  ResidualScore?: Maybe<Scalars['Float']['output']>;
};

export const Risk_Scoring_Model_Enum = {
  /** Control effectiveness averages */
  ControlEffectivenessAverages: 'control_effectiveness_averages',
  /** Default model */
  Default: 'default',
  /** Number of controls with gaps */
  NumberOfControlsWithGaps: 'number_of_controls_with_gaps'
} as const;

export type Risk_Scoring_Model_Enum = typeof Risk_Scoring_Model_Enum[keyof typeof Risk_Scoring_Model_Enum];
/** Boolean expression to compare columns of type "risk_scoring_model_enum". All fields are combined with logical 'AND'. */
export type Risk_Scoring_Model_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Risk_Scoring_Model_Enum>;
  _in?: InputMaybe<Array<Risk_Scoring_Model_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Risk_Scoring_Model_Enum>;
  _nin?: InputMaybe<Array<Risk_Scoring_Model_Enum>>;
};

/** select columns of table "risksmart.risk" */
export const Risk_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentRiskId: 'ParentRiskId',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Status: 'Status',
  /** column name */
  Tier: 'Tier',
  /** column name */
  Title: 'Title',
  /** column name */
  Treatment: 'Treatment'
} as const;

export type Risk_Select_Column = typeof Risk_Select_Column[keyof typeof Risk_Select_Column];
export const Risk_Status_Type_Enum = {
  /** Active */
  Active: 'active',
  /** Emerging */
  Emerging: 'emerging',
  /** Monitored */
  Monitored: 'monitored',
  /** Retired */
  Retired: 'retired'
} as const;

export type Risk_Status_Type_Enum = typeof Risk_Status_Type_Enum[keyof typeof Risk_Status_Type_Enum];
/** Boolean expression to compare columns of type "risk_status_type_enum". All fields are combined with logical 'AND'. */
export type Risk_Status_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Risk_Status_Type_Enum>;
  _in?: InputMaybe<Array<Risk_Status_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Risk_Status_Type_Enum>;
  _nin?: InputMaybe<Array<Risk_Status_Type_Enum>>;
};

/** aggregate stddev on columns */
export type Risk_Stddev_Fields = {
  __typename?: 'risk_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.risk" */
export type Risk_Stddev_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
  Tier?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Risk_Stddev_Pop_Fields = {
  __typename?: 'risk_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.risk" */
export type Risk_Stddev_Pop_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
  Tier?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Risk_Stddev_Samp_Fields = {
  __typename?: 'risk_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.risk" */
export type Risk_Stddev_Samp_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
  Tier?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "risk" */
export type Risk_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risk_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risk_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentRiskId?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Risk_Status_Type_Enum>;
  Tier?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Treatment?: InputMaybe<Risk_Treatment_Type_Enum>;
};

/** aggregate sum on columns */
export type Risk_Sum_Fields = {
  __typename?: 'risk_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Tier?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.risk" */
export type Risk_Sum_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
  Tier?: InputMaybe<Order_By>;
};

export const Risk_Treatment_Type_Enum = {
  /** Terminate */
  Terminate: 'terminate',
  /** Tolerate */
  Tolerate: 'tolerate',
  /** Transfer */
  Transfer: 'transfer',
  /** Treat */
  Treat: 'treat'
} as const;

export type Risk_Treatment_Type_Enum = typeof Risk_Treatment_Type_Enum[keyof typeof Risk_Treatment_Type_Enum];
/** Boolean expression to compare columns of type "risk_treatment_type_enum". All fields are combined with logical 'AND'. */
export type Risk_Treatment_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Risk_Treatment_Type_Enum>;
  _in?: InputMaybe<Array<Risk_Treatment_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Risk_Treatment_Type_Enum>;
  _nin?: InputMaybe<Array<Risk_Treatment_Type_Enum>>;
};

/** update columns of table "risksmart.risk" */
export const Risk_Update_Column = {
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  ParentRiskId: 'ParentRiskId',
  /** column name */
  Status: 'Status',
  /** column name */
  Tier: 'Tier',
  /** column name */
  Title: 'Title',
  /** column name */
  Treatment: 'Treatment'
} as const;

export type Risk_Update_Column = typeof Risk_Update_Column[keyof typeof Risk_Update_Column];
/** aggregate var_pop on columns */
export type Risk_Var_Pop_Fields = {
  __typename?: 'risk_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.risk" */
export type Risk_Var_Pop_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
  Tier?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Risk_Var_Samp_Fields = {
  __typename?: 'risk_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.risk" */
export type Risk_Var_Samp_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
  Tier?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Risk_Variance_Fields = {
  __typename?: 'risk_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.risk" */
export type Risk_Variance_Order_By = {
  SequentialId?: InputMaybe<Order_By>;
  Tier?: InputMaybe<Order_By>;
};

/** columns and relationships of "risksmart.indicator_parent_audit" */
export type Risksmart_Indicator_Parent_Audit = {
  __typename?: 'risksmart_indicator_parent_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  IndicatorId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ParentId: Scalars['uuid']['output'];
};

/** order by aggregate values of table "risksmart.indicator_parent_audit" */
export type Risksmart_Indicator_Parent_Audit_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Risksmart_Indicator_Parent_Audit_Max_Order_By>;
  min?: InputMaybe<Risksmart_Indicator_Parent_Audit_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.indicator_parent_audit". All fields are combined with a logical 'AND'. */
export type Risksmart_Indicator_Parent_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  IndicatorId?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Risksmart_Indicator_Parent_Audit_Bool_Exp>>;
  _not?: InputMaybe<Risksmart_Indicator_Parent_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Risksmart_Indicator_Parent_Audit_Bool_Exp>>;
};

/** order by max() on columns of table "risksmart.indicator_parent_audit" */
export type Risksmart_Indicator_Parent_Audit_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  IndicatorId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.indicator_parent_audit" */
export type Risksmart_Indicator_Parent_Audit_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  IndicatorId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "risksmart.indicator_parent_audit". */
export type Risksmart_Indicator_Parent_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  IndicatorId?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.indicator_parent_audit" */
export const Risksmart_Indicator_Parent_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  IndicatorId: 'IndicatorId',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId'
} as const;

export type Risksmart_Indicator_Parent_Audit_Select_Column = typeof Risksmart_Indicator_Parent_Audit_Select_Column[keyof typeof Risksmart_Indicator_Parent_Audit_Select_Column];
/** Streaming cursor of the table "risksmart_indicator_parent_audit" */
export type Risksmart_Indicator_Parent_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risksmart_Indicator_Parent_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risksmart_Indicator_Parent_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  IndicatorId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "risksmart.linked_item_audit" */
export type Risksmart_Linked_Item_Audit = {
  __typename?: 'risksmart_linked_item_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  RelationshipType?: Maybe<Scalars['String']['output']>;
  Source: Scalars['uuid']['output'];
  Target: Scalars['uuid']['output'];
};

/** Boolean expression to filter rows from the table "risksmart.linked_item_audit". All fields are combined with a logical 'AND'. */
export type Risksmart_Linked_Item_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  RelationshipType?: InputMaybe<String_Comparison_Exp>;
  Source?: InputMaybe<Uuid_Comparison_Exp>;
  Target?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Risksmart_Linked_Item_Audit_Bool_Exp>>;
  _not?: InputMaybe<Risksmart_Linked_Item_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Risksmart_Linked_Item_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.linked_item_audit". */
export type Risksmart_Linked_Item_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  RelationshipType?: InputMaybe<Order_By>;
  Source?: InputMaybe<Order_By>;
  Target?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.linked_item_audit" */
export const Risksmart_Linked_Item_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  RelationshipType: 'RelationshipType',
  /** column name */
  Source: 'Source',
  /** column name */
  Target: 'Target'
} as const;

export type Risksmart_Linked_Item_Audit_Select_Column = typeof Risksmart_Linked_Item_Audit_Select_Column[keyof typeof Risksmart_Linked_Item_Audit_Select_Column];
/** Streaming cursor of the table "risksmart_linked_item_audit" */
export type Risksmart_Linked_Item_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risksmart_Linked_Item_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risksmart_Linked_Item_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  RelationshipType?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['uuid']['input']>;
  Target?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "risksmart.role_access" */
export type Role_Access = {
  __typename?: 'role_access';
  AccessType: Access_Type_Enum;
  ContributorType: Contributor_Type_Enum;
  ObjectType: Parent_Type_Enum;
};

/** Boolean expression to filter rows from the table "risksmart.role_access". All fields are combined with a logical 'AND'. */
export type Role_Access_Bool_Exp = {
  AccessType?: InputMaybe<Access_Type_Enum_Comparison_Exp>;
  ContributorType?: InputMaybe<Contributor_Type_Enum_Comparison_Exp>;
  ObjectType?: InputMaybe<Parent_Type_Enum_Comparison_Exp>;
  _and?: InputMaybe<Array<Role_Access_Bool_Exp>>;
  _not?: InputMaybe<Role_Access_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Access_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.role_access". */
export type Role_Access_Order_By = {
  AccessType?: InputMaybe<Order_By>;
  ContributorType?: InputMaybe<Order_By>;
  ObjectType?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.role_access" */
export const Role_Access_Select_Column = {
  /** column name */
  AccessType: 'AccessType',
  /** column name */
  ContributorType: 'ContributorType',
  /** column name */
  ObjectType: 'ObjectType'
} as const;

export type Role_Access_Select_Column = typeof Role_Access_Select_Column[keyof typeof Role_Access_Select_Column];
/** Streaming cursor of the table "role_access" */
export type Role_Access_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Access_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Access_Stream_Cursor_Value_Input = {
  AccessType?: InputMaybe<Access_Type_Enum>;
  ContributorType?: InputMaybe<Contributor_Type_Enum>;
  ObjectType?: InputMaybe<Parent_Type_Enum>;
};

/** columns and relationships of "risksmart.schedule" */
export type Schedule = {
  __typename?: 'schedule';
  Frequency?: Maybe<Test_Frequency_Enum>;
  Id: Scalars['uuid']['output'];
  ManualDueDate?: Maybe<Scalars['timestamptz']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  TimeToCompleteUnit?: Maybe<Unit_Of_Time_Enum>;
  TimeToCompleteValue?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.schedule". All fields are combined with a logical 'AND'. */
export type Schedule_Bool_Exp = {
  Frequency?: InputMaybe<Test_Frequency_Enum_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ManualDueDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  StartDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  TimeToCompleteUnit?: InputMaybe<Unit_Of_Time_Enum_Comparison_Exp>;
  TimeToCompleteValue?: InputMaybe<Int_Comparison_Exp>;
  _and?: InputMaybe<Array<Schedule_Bool_Exp>>;
  _not?: InputMaybe<Schedule_Bool_Exp>;
  _or?: InputMaybe<Array<Schedule_Bool_Exp>>;
};

/** unique or primary key constraints on table "risksmart.schedule" */
export const Schedule_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  SchedulePkey: 'schedule_pkey'
} as const;

export type Schedule_Constraint = typeof Schedule_Constraint[keyof typeof Schedule_Constraint];
/** input type for inserting data into table "risksmart.schedule" */
export type Schedule_Insert_Input = {
  Frequency?: InputMaybe<Test_Frequency_Enum>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ManualDueDate?: InputMaybe<Scalars['timestamptz']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TimeToCompleteUnit?: InputMaybe<Unit_Of_Time_Enum>;
  TimeToCompleteValue?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting object relation for remote table "risksmart.schedule" */
export type Schedule_Obj_Rel_Insert_Input = {
  data: Schedule_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Schedule_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.schedule" */
export type Schedule_On_Conflict = {
  constraint: Schedule_Constraint;
  update_columns?: Array<Schedule_Update_Column>;
  where?: InputMaybe<Schedule_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.schedule". */
export type Schedule_Order_By = {
  Frequency?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ManualDueDate?: InputMaybe<Order_By>;
  StartDate?: InputMaybe<Order_By>;
  TimeToCompleteUnit?: InputMaybe<Order_By>;
  TimeToCompleteValue?: InputMaybe<Order_By>;
};

/** columns and relationships of "risksmart.schedule_state" */
export type Schedule_State = {
  __typename?: 'schedule_state';
  DueDate?: Maybe<Scalars['timestamptz']['output']>;
  Id: Scalars['uuid']['output'];
  LatestDate?: Maybe<Scalars['timestamptz']['output']>;
  OverdueDate?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  parent?: Maybe<Node>;
  /** An object relationship */
  schedule?: Maybe<Schedule>;
};

/** Boolean expression to filter rows from the table "risksmart.schedule_state". All fields are combined with a logical 'AND'. */
export type Schedule_State_Bool_Exp = {
  DueDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  LatestDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  OverdueDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  _and?: InputMaybe<Array<Schedule_State_Bool_Exp>>;
  _not?: InputMaybe<Schedule_State_Bool_Exp>;
  _or?: InputMaybe<Array<Schedule_State_Bool_Exp>>;
  parent?: InputMaybe<Node_Bool_Exp>;
  schedule?: InputMaybe<Schedule_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.schedule_state". */
export type Schedule_State_Order_By = {
  DueDate?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  LatestDate?: InputMaybe<Order_By>;
  OverdueDate?: InputMaybe<Order_By>;
  parent?: InputMaybe<Node_Order_By>;
  schedule?: InputMaybe<Schedule_Order_By>;
};

/** update columns of table "risksmart.schedule" */
export const Schedule_Update_Column = {
  /** column name */
  Frequency: 'Frequency',
  /** column name */
  ManualDueDate: 'ManualDueDate',
  /** column name */
  StartDate: 'StartDate',
  /** column name */
  TimeToCompleteUnit: 'TimeToCompleteUnit',
  /** column name */
  TimeToCompleteValue: 'TimeToCompleteValue'
} as const;

export type Schedule_Update_Column = typeof Schedule_Update_Column[keyof typeof Schedule_Update_Column];
/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']['input']>;
  _gt?: InputMaybe<Scalars['smallint']['input']>;
  _gte?: InputMaybe<Scalars['smallint']['input']>;
  _in?: InputMaybe<Array<Scalars['smallint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['smallint']['input']>;
  _lte?: InputMaybe<Scalars['smallint']['input']>;
  _neq?: InputMaybe<Scalars['smallint']['input']>;
  _nin?: InputMaybe<Array<Scalars['smallint']['input']>>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "risksmart.acceptance" */
  acceptance: Array<Acceptance>;
  /** fetch aggregated fields from the table: "risksmart.acceptance" */
  acceptance_aggregate: Acceptance_Aggregate;
  /** fetch data from the table: "risksmart.acceptance_audit" */
  acceptance_audit: Array<Acceptance_Audit>;
  /** fetch data from the table: "risksmart.acceptance_audit" using primary key columns */
  acceptance_audit_by_pk?: Maybe<Acceptance_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.acceptance_audit" */
  acceptance_audit_stream: Array<Acceptance_Audit>;
  /** fetch data from the table: "risksmart.acceptance" using primary key columns */
  acceptance_by_pk?: Maybe<Acceptance>;
  /** fetch data from the table: "risksmart.acceptance_parent" */
  acceptance_parent: Array<Acceptance_Parent>;
  /** fetch aggregated fields from the table: "risksmart.acceptance_parent" */
  acceptance_parent_aggregate: Acceptance_Parent_Aggregate;
  /** fetch data from the table: "risksmart.acceptance_parent_audit" */
  acceptance_parent_audit: Array<Acceptance_Parent_Audit>;
  /** fetch data from the table: "risksmart.acceptance_parent_audit" using primary key columns */
  acceptance_parent_audit_by_pk?: Maybe<Acceptance_Parent_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.acceptance_parent_audit" */
  acceptance_parent_audit_stream: Array<Acceptance_Parent_Audit>;
  /** fetch data from the table: "risksmart.acceptance_parent" using primary key columns */
  acceptance_parent_by_pk?: Maybe<Acceptance_Parent>;
  /** fetch data from the table in a streaming manner: "risksmart.acceptance_parent" */
  acceptance_parent_stream: Array<Acceptance_Parent>;
  /** fetch data from the table in a streaming manner: "risksmart.acceptance" */
  acceptance_stream: Array<Acceptance>;
  /** fetch data from the table: "risksmart.action" */
  action: Array<Action>;
  /** fetch aggregated fields from the table: "risksmart.action" */
  action_aggregate: Action_Aggregate;
  /** fetch data from the table: "risksmart.action_audit" */
  action_audit: Array<Action_Audit>;
  /** fetch aggregated fields from the table: "risksmart.action_audit" */
  action_audit_aggregate: Action_Audit_Aggregate;
  /** fetch data from the table: "risksmart.action_audit" using primary key columns */
  action_audit_by_pk?: Maybe<Action_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.action_audit" */
  action_audit_stream: Array<Action_Audit>;
  /** fetch data from the table: "risksmart.action" using primary key columns */
  action_by_pk?: Maybe<Action>;
  /** fetch data from the table: "risksmart.action_parent" */
  action_parent: Array<Action_Parent>;
  /** fetch aggregated fields from the table: "risksmart.action_parent" */
  action_parent_aggregate: Action_Parent_Aggregate;
  /** fetch data from the table: "risksmart.action_parent_audit" */
  action_parent_audit: Array<Action_Parent_Audit>;
  /** fetch data from the table: "risksmart.action_parent_audit" using primary key columns */
  action_parent_audit_by_pk?: Maybe<Action_Parent_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.action_parent_audit" */
  action_parent_audit_stream: Array<Action_Parent_Audit>;
  /** fetch data from the table: "risksmart.action_parent" using primary key columns */
  action_parent_by_pk?: Maybe<Action_Parent>;
  /** fetch data from the table in a streaming manner: "risksmart.action_parent" */
  action_parent_stream: Array<Action_Parent>;
  /** fetch data from the table in a streaming manner: "risksmart.action" */
  action_stream: Array<Action>;
  /** fetch data from the table: "risksmart.action_update" */
  action_update: Array<Action_Update>;
  /** fetch aggregated fields from the table: "risksmart.action_update" */
  action_update_aggregate: Action_Update_Aggregate;
  /** fetch data from the table: "risksmart.action_update_audit" */
  action_update_audit: Array<Action_Update_Audit>;
  /** fetch data from the table: "risksmart.action_update_audit" using primary key columns */
  action_update_audit_by_pk?: Maybe<Action_Update_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.action_update_audit" */
  action_update_audit_stream: Array<Action_Update_Audit>;
  /** fetch data from the table: "risksmart.action_update" using primary key columns */
  action_update_by_pk?: Maybe<Action_Update>;
  /** fetch data from the table in a streaming manner: "risksmart.action_update" */
  action_update_stream: Array<Action_Update>;
  /** fetch data from the table: "risksmart.action_update_summary_view" */
  action_update_summary: Array<Action_Update_Summary>;
  /** fetch aggregated fields from the table: "risksmart.action_update_summary_view" */
  action_update_summary_aggregate: Action_Update_Summary_Aggregate;
  /** fetch data from the table in a streaming manner: "risksmart.action_update_summary_view" */
  action_update_summary_stream: Array<Action_Update_Summary>;
  /** fetch data from the table: "risksmart.aggregation_org" */
  aggregation_org: Array<Aggregation_Org>;
  /** fetch data from the table: "risksmart.aggregation_org" using primary key columns */
  aggregation_org_by_pk?: Maybe<Aggregation_Org>;
  /** fetch data from the table in a streaming manner: "risksmart.aggregation_org" */
  aggregation_org_stream: Array<Aggregation_Org>;
  /** fetch data from the table: "risksmart.ancestor_contributor_view" */
  ancestor_contributor: Array<Ancestor_Contributor>;
  /** fetch data from the table in a streaming manner: "risksmart.ancestor_contributor_view" */
  ancestor_contributor_stream: Array<Ancestor_Contributor>;
  /** fetch data from the table: "risksmart.appetite" */
  appetite: Array<Appetite>;
  /** fetch aggregated fields from the table: "risksmart.appetite" */
  appetite_aggregate: Appetite_Aggregate;
  /** fetch data from the table: "risksmart.appetite_audit" */
  appetite_audit: Array<Appetite_Audit>;
  /** fetch data from the table: "risksmart.appetite_audit" using primary key columns */
  appetite_audit_by_pk?: Maybe<Appetite_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.appetite_audit" */
  appetite_audit_stream: Array<Appetite_Audit>;
  /** fetch data from the table: "risksmart.appetite" using primary key columns */
  appetite_by_pk?: Maybe<Appetite>;
  /** fetch data from the table: "risksmart.appetite_parent" */
  appetite_parent: Array<Appetite_Parent>;
  /** fetch aggregated fields from the table: "risksmart.appetite_parent" */
  appetite_parent_aggregate: Appetite_Parent_Aggregate;
  /** fetch data from the table: "risksmart.appetite_parent_audit" */
  appetite_parent_audit: Array<Appetite_Parent_Audit>;
  /** fetch aggregated fields from the table: "risksmart.appetite_parent_audit" */
  appetite_parent_audit_aggregate: Appetite_Parent_Audit_Aggregate;
  /** fetch data from the table: "risksmart.appetite_parent_audit" using primary key columns */
  appetite_parent_audit_by_pk?: Maybe<Appetite_Parent_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.appetite_parent_audit" */
  appetite_parent_audit_stream: Array<Appetite_Parent_Audit>;
  /** fetch data from the table: "risksmart.appetite_parent" using primary key columns */
  appetite_parent_by_pk?: Maybe<Appetite_Parent>;
  /** fetch data from the table in a streaming manner: "risksmart.appetite_parent" */
  appetite_parent_stream: Array<Appetite_Parent>;
  /** fetch data from the table in a streaming manner: "risksmart.appetite" */
  appetite_stream: Array<Appetite>;
  /** fetch data from the table: "risksmart.approval" */
  approval: Array<Approval>;
  /** fetch data from the table: "risksmart.approval_audit" */
  approval_audit: Array<Approval_Audit>;
  /** fetch data from the table: "risksmart.approval_audit" using primary key columns */
  approval_audit_by_pk?: Maybe<Approval_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.approval_audit" */
  approval_audit_stream: Array<Approval_Audit>;
  /** fetch data from the table: "risksmart.approval" using primary key columns */
  approval_by_pk?: Maybe<Approval>;
  /** fetch data from the table: "risksmart.approval_level" */
  approval_level: Array<Approval_Level>;
  /** fetch data from the table: "risksmart.approval_level_audit" */
  approval_level_audit: Array<Approval_Level_Audit>;
  /** fetch data from the table: "risksmart.approval_level_audit" using primary key columns */
  approval_level_audit_by_pk?: Maybe<Approval_Level_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.approval_level_audit" */
  approval_level_audit_stream: Array<Approval_Level_Audit>;
  /** fetch data from the table: "risksmart.approval_level" using primary key columns */
  approval_level_by_pk?: Maybe<Approval_Level>;
  /** fetch data from the table in a streaming manner: "risksmart.approval_level" */
  approval_level_stream: Array<Approval_Level>;
  /** fetch data from the table in a streaming manner: "risksmart.approval" */
  approval_stream: Array<Approval>;
  /** fetch data from the table: "risksmart.approver" */
  approver: Array<Approver>;
  /** fetch aggregated fields from the table: "risksmart.approver" */
  approver_aggregate: Approver_Aggregate;
  /** fetch data from the table: "risksmart.approver_audit" */
  approver_audit: Array<Approver_Audit>;
  /** fetch data from the table: "risksmart.approver_audit" using primary key columns */
  approver_audit_by_pk?: Maybe<Approver_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.approver_audit" */
  approver_audit_stream: Array<Approver_Audit>;
  /** fetch data from the table: "risksmart.approver" using primary key columns */
  approver_by_pk?: Maybe<Approver>;
  /** fetch data from the table in a streaming manner: "risksmart.approver" */
  approver_stream: Array<Approver>;
  /** fetch data from the table: "risksmart.assessment" */
  assessment: Array<Assessment>;
  /** fetch data from the table: "risksmart.assessment_activity" */
  assessment_activity: Array<Assessment_Activity>;
  /** fetch aggregated fields from the table: "risksmart.assessment_activity" */
  assessment_activity_aggregate: Assessment_Activity_Aggregate;
  /** fetch data from the table: "risksmart.assessment_activity_audit" */
  assessment_activity_audit: Array<Assessment_Activity_Audit>;
  /** fetch data from the table: "risksmart.assessment_activity_audit" using primary key columns */
  assessment_activity_audit_by_pk?: Maybe<Assessment_Activity_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.assessment_activity_audit" */
  assessment_activity_audit_stream: Array<Assessment_Activity_Audit>;
  /** fetch data from the table: "risksmart.assessment_activity" using primary key columns */
  assessment_activity_by_pk?: Maybe<Assessment_Activity>;
  /** fetch data from the table in a streaming manner: "risksmart.assessment_activity" */
  assessment_activity_stream: Array<Assessment_Activity>;
  /** fetch aggregated fields from the table: "risksmart.assessment" */
  assessment_aggregate: Assessment_Aggregate;
  /** fetch data from the table: "risksmart.assessment_audit" */
  assessment_audit: Array<Assessment_Audit>;
  /** fetch aggregated fields from the table: "risksmart.assessment_audit" */
  assessment_audit_aggregate: Assessment_Audit_Aggregate;
  /** fetch data from the table: "risksmart.assessment_audit" using primary key columns */
  assessment_audit_by_pk?: Maybe<Assessment_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.assessment_audit" */
  assessment_audit_stream: Array<Assessment_Audit>;
  /** fetch data from the table: "risksmart.assessment" using primary key columns */
  assessment_by_pk?: Maybe<Assessment>;
  /** fetch data from the table: "risksmart.assessment_result_parent" */
  assessment_result_parent: Array<Assessment_Result_Parent>;
  /** fetch aggregated fields from the table: "risksmart.assessment_result_parent" */
  assessment_result_parent_aggregate: Assessment_Result_Parent_Aggregate;
  /** fetch data from the table: "risksmart.assessment_result_parent_audit" */
  assessment_result_parent_audit: Array<Assessment_Result_Parent_Audit>;
  /** fetch data from the table: "risksmart.assessment_result_parent_audit" using primary key columns */
  assessment_result_parent_audit_by_pk?: Maybe<Assessment_Result_Parent_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.assessment_result_parent_audit" */
  assessment_result_parent_audit_stream: Array<Assessment_Result_Parent_Audit>;
  /** fetch data from the table: "risksmart.assessment_result_parent" using primary key columns */
  assessment_result_parent_by_pk?: Maybe<Assessment_Result_Parent>;
  /** fetch data from the table in a streaming manner: "risksmart.assessment_result_parent" */
  assessment_result_parent_stream: Array<Assessment_Result_Parent>;
  /** fetch data from the table in a streaming manner: "risksmart.assessment" */
  assessment_stream: Array<Assessment>;
  /** fetch data from the table: "risksmart.attestation_config" */
  attestation_config: Array<Attestation_Config>;
  /** fetch data from the table: "risksmart.attestation_config" using primary key columns */
  attestation_config_by_pk?: Maybe<Attestation_Config>;
  /** fetch data from the table in a streaming manner: "risksmart.attestation_config" */
  attestation_config_stream: Array<Attestation_Config>;
  /** fetch data from the table: "risksmart.attestation_group" */
  attestation_group: Array<Attestation_Group>;
  /** fetch data from the table: "risksmart.attestation_group" using primary key columns */
  attestation_group_by_pk?: Maybe<Attestation_Group>;
  /** fetch data from the table in a streaming manner: "risksmart.attestation_group" */
  attestation_group_stream: Array<Attestation_Group>;
  /** fetch data from the table: "risksmart.attestation_record" */
  attestation_record: Array<Attestation_Record>;
  /** fetch aggregated fields from the table: "risksmart.attestation_record" */
  attestation_record_aggregate: Attestation_Record_Aggregate;
  /** fetch data from the table: "risksmart.attestation_record" using primary key columns */
  attestation_record_by_pk?: Maybe<Attestation_Record>;
  /** fetch data from the table in a streaming manner: "risksmart.attestation_record" */
  attestation_record_stream: Array<Attestation_Record>;
  /** fetch data from the table: "risksmart.audit_log_view" */
  audit_log_view: Array<Audit_Log_View>;
  /** fetch aggregated fields from the table: "risksmart.audit_log_view" */
  audit_log_view_aggregate: Audit_Log_View_Aggregate;
  /** fetch data from the table in a streaming manner: "risksmart.audit_log_view" */
  audit_log_view_stream: Array<Audit_Log_View>;
  /** fetch data from the table: "auth.organisation" */
  auth_organisation: Array<Auth_Organisation>;
  /** fetch data from the table: "auth.organisation" using primary key columns */
  auth_organisation_by_pk?: Maybe<Auth_Organisation>;
  /** fetch data from the table in a streaming manner: "auth.organisation" */
  auth_organisation_stream: Array<Auth_Organisation>;
  /** fetch data from the table: "auth.organisationuser" */
  auth_organisationuser: Array<Auth_Organisationuser>;
  /** fetch data from the table: "auth.organisationuser" using primary key columns */
  auth_organisationuser_by_pk?: Maybe<Auth_Organisationuser>;
  /** fetch data from the table in a streaming manner: "auth.organisationuser" */
  auth_organisationuser_stream: Array<Auth_Organisationuser>;
  /** fetch data from the table: "auth.user" */
  auth_user: Array<Auth_User>;
  /** fetch aggregated fields from the table: "auth.user" */
  auth_user_aggregate: Auth_User_Aggregate;
  /** fetch data from the table: "auth.user" using primary key columns */
  auth_user_by_pk?: Maybe<Auth_User>;
  /** fetch data from the table in a streaming manner: "auth.user" */
  auth_user_stream: Array<Auth_User>;
  /** fetch data from the table: "risksmart.business_area" */
  business_area: Array<Business_Area>;
  /** fetch aggregated fields from the table: "risksmart.business_area" */
  business_area_aggregate: Business_Area_Aggregate;
  /** fetch data from the table: "risksmart.business_area" using primary key columns */
  business_area_by_pk?: Maybe<Business_Area>;
  /** fetch data from the table in a streaming manner: "risksmart.business_area" */
  business_area_stream: Array<Business_Area>;
  /** fetch data from the table: "risksmart.cause" */
  cause: Array<Cause>;
  /** fetch aggregated fields from the table: "risksmart.cause" */
  cause_aggregate: Cause_Aggregate;
  /** fetch data from the table: "risksmart.cause_audit" */
  cause_audit: Array<Cause_Audit>;
  /** fetch data from the table: "risksmart.cause_audit" using primary key columns */
  cause_audit_by_pk?: Maybe<Cause_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.cause_audit" */
  cause_audit_stream: Array<Cause_Audit>;
  /** fetch data from the table: "risksmart.cause" using primary key columns */
  cause_by_pk?: Maybe<Cause>;
  /** fetch data from the table in a streaming manner: "risksmart.cause" */
  cause_stream: Array<Cause>;
  /** fetch data from the table: "risksmart.change_request" */
  change_request: Array<Change_Request>;
  /** fetch aggregated fields from the table: "risksmart.change_request" */
  change_request_aggregate: Change_Request_Aggregate;
  /** fetch data from the table: "risksmart.change_request_audit" */
  change_request_audit: Array<Change_Request_Audit>;
  /** fetch data from the table: "risksmart.change_request_audit" using primary key columns */
  change_request_audit_by_pk?: Maybe<Change_Request_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.change_request_audit" */
  change_request_audit_stream: Array<Change_Request_Audit>;
  /** fetch data from the table: "risksmart.change_request" using primary key columns */
  change_request_by_pk?: Maybe<Change_Request>;
  /** fetch data from the table: "risksmart.change_request_contributor" */
  change_request_contributor: Array<Change_Request_Contributor>;
  /** fetch data from the table: "risksmart.change_request_contributor" using primary key columns */
  change_request_contributor_by_pk?: Maybe<Change_Request_Contributor>;
  /** fetch data from the table in a streaming manner: "risksmart.change_request_contributor" */
  change_request_contributor_stream: Array<Change_Request_Contributor>;
  /** fetch data from the table in a streaming manner: "risksmart.change_request" */
  change_request_stream: Array<Change_Request>;
  /** fetch data from the table: "risksmart.comment" */
  comment: Array<Comment>;
  /** fetch data from the table: "risksmart.comment_audit" */
  comment_audit: Array<Comment_Audit>;
  /** fetch data from the table: "risksmart.comment_audit" using primary key columns */
  comment_audit_by_pk?: Maybe<Comment_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.comment_audit" */
  comment_audit_stream: Array<Comment_Audit>;
  /** fetch data from the table: "risksmart.comment" using primary key columns */
  comment_by_pk?: Maybe<Comment>;
  /** fetch data from the table in a streaming manner: "risksmart.comment" */
  comment_stream: Array<Comment>;
  /** fetch data from the table: "risksmart.compliance_monitoring_assessment" */
  compliance_monitoring_assessment: Array<Compliance_Monitoring_Assessment>;
  /** fetch aggregated fields from the table: "risksmart.compliance_monitoring_assessment" */
  compliance_monitoring_assessment_aggregate: Compliance_Monitoring_Assessment_Aggregate;
  /** fetch data from the table: "risksmart.compliance_monitoring_assessment" using primary key columns */
  compliance_monitoring_assessment_by_pk?: Maybe<Compliance_Monitoring_Assessment>;
  /** fetch data from the table in a streaming manner: "risksmart.compliance_monitoring_assessment" */
  compliance_monitoring_assessment_stream: Array<Compliance_Monitoring_Assessment>;
  /** fetch data from the table: "risksmart.consequence" */
  consequence: Array<Consequence>;
  /** fetch aggregated fields from the table: "risksmart.consequence" */
  consequence_aggregate: Consequence_Aggregate;
  /** fetch data from the table: "risksmart.consequence_audit" */
  consequence_audit: Array<Consequence_Audit>;
  /** fetch aggregated fields from the table: "risksmart.consequence_audit" */
  consequence_audit_aggregate: Consequence_Audit_Aggregate;
  /** fetch data from the table: "risksmart.consequence_audit" using primary key columns */
  consequence_audit_by_pk?: Maybe<Consequence_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.consequence_audit" */
  consequence_audit_stream: Array<Consequence_Audit>;
  /** fetch data from the table: "risksmart.consequence" using primary key columns */
  consequence_by_pk?: Maybe<Consequence>;
  /** fetch data from the table in a streaming manner: "risksmart.consequence" */
  consequence_stream: Array<Consequence>;
  /** fetch data from the table: "risksmart.control" */
  control: Array<Control>;
  /** fetch aggregated fields from the table: "risksmart.control" */
  control_aggregate: Control_Aggregate;
  /** fetch data from the table: "risksmart.control_audit" */
  control_audit: Array<Control_Audit>;
  /** fetch data from the table: "risksmart.control_audit" using primary key columns */
  control_audit_by_pk?: Maybe<Control_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.control_audit" */
  control_audit_stream: Array<Control_Audit>;
  /** fetch data from the table: "risksmart.control" using primary key columns */
  control_by_pk?: Maybe<Control>;
  /** fetch data from the table: "risksmart.control_group" */
  control_group: Array<Control_Group>;
  /** fetch aggregated fields from the table: "risksmart.control_group" */
  control_group_aggregate: Control_Group_Aggregate;
  /** fetch data from the table: "risksmart.control_group_audit" */
  control_group_audit: Array<Control_Group_Audit>;
  /** fetch data from the table: "risksmart.control_group_audit" using primary key columns */
  control_group_audit_by_pk?: Maybe<Control_Group_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.control_group_audit" */
  control_group_audit_stream: Array<Control_Group_Audit>;
  /** fetch data from the table: "risksmart.control_group" using primary key columns */
  control_group_by_pk?: Maybe<Control_Group>;
  /** fetch data from the table in a streaming manner: "risksmart.control_group" */
  control_group_stream: Array<Control_Group>;
  /** fetch data from the table: "risksmart.control_parent" */
  control_parent: Array<Control_Parent>;
  /** fetch aggregated fields from the table: "risksmart.control_parent" */
  control_parent_aggregate: Control_Parent_Aggregate;
  /** fetch data from the table: "risksmart.control_parent_audit" */
  control_parent_audit: Array<Control_Parent_Audit>;
  /** fetch data from the table: "risksmart.control_parent_audit" using primary key columns */
  control_parent_audit_by_pk?: Maybe<Control_Parent_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.control_parent_audit" */
  control_parent_audit_stream: Array<Control_Parent_Audit>;
  /** fetch data from the table: "risksmart.control_parent" using primary key columns */
  control_parent_by_pk?: Maybe<Control_Parent>;
  /** fetch data from the table in a streaming manner: "risksmart.control_parent" */
  control_parent_stream: Array<Control_Parent>;
  /** fetch data from the table in a streaming manner: "risksmart.control" */
  control_stream: Array<Control>;
  /** fetch data from the table: "risksmart.conversation" */
  conversation: Array<Conversation>;
  /** fetch data from the table: "risksmart.conversation_audit" */
  conversation_audit: Array<Conversation_Audit>;
  /** fetch data from the table: "risksmart.conversation_audit" using primary key columns */
  conversation_audit_by_pk?: Maybe<Conversation_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.conversation_audit" */
  conversation_audit_stream: Array<Conversation_Audit>;
  /** fetch data from the table: "risksmart.conversation" using primary key columns */
  conversation_by_pk?: Maybe<Conversation>;
  /** fetch data from the table in a streaming manner: "risksmart.conversation" */
  conversation_stream: Array<Conversation>;
  /** fetch data from the table: "risksmart.custom_attribute_schema" */
  custom_attribute_schema: Array<Custom_Attribute_Schema>;
  /** fetch data from the table: "risksmart.custom_attribute_schema_audit" */
  custom_attribute_schema_audit: Array<Custom_Attribute_Schema_Audit>;
  /** fetch data from the table: "risksmart.custom_attribute_schema_audit" using primary key columns */
  custom_attribute_schema_audit_by_pk?: Maybe<Custom_Attribute_Schema_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.custom_attribute_schema_audit" */
  custom_attribute_schema_audit_stream: Array<Custom_Attribute_Schema_Audit>;
  /** fetch data from the table: "risksmart.custom_attribute_schema" using primary key columns */
  custom_attribute_schema_by_pk?: Maybe<Custom_Attribute_Schema>;
  /** fetch data from the table in a streaming manner: "risksmart.custom_attribute_schema" */
  custom_attribute_schema_stream: Array<Custom_Attribute_Schema>;
  /** fetch data from the table: "risksmart.custom_ribbon" */
  custom_ribbon: Array<Custom_Ribbon>;
  /** fetch data from the table: "risksmart.custom_ribbon_audit" */
  custom_ribbon_audit: Array<Custom_Ribbon_Audit>;
  /** fetch data from the table: "risksmart.custom_ribbon_audit" using primary key columns */
  custom_ribbon_audit_by_pk?: Maybe<Custom_Ribbon_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.custom_ribbon_audit" */
  custom_ribbon_audit_stream: Array<Custom_Ribbon_Audit>;
  /** fetch data from the table: "risksmart.custom_ribbon" using primary key columns */
  custom_ribbon_by_pk?: Maybe<Custom_Ribbon>;
  /** fetch data from the table in a streaming manner: "risksmart.custom_ribbon" */
  custom_ribbon_stream: Array<Custom_Ribbon>;
  /** fetch data from the table: "risksmart.dashboard" */
  dashboard: Array<Dashboard>;
  /** fetch data from the table: "risksmart.dashboard_audit" */
  dashboard_audit: Array<Dashboard_Audit>;
  /** fetch data from the table: "risksmart.dashboard_audit" using primary key columns */
  dashboard_audit_by_pk?: Maybe<Dashboard_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.dashboard_audit" */
  dashboard_audit_stream: Array<Dashboard_Audit>;
  /** fetch data from the table: "risksmart.dashboard" using primary key columns */
  dashboard_by_pk?: Maybe<Dashboard>;
  /** fetch data from the table in a streaming manner: "risksmart.dashboard" */
  dashboard_stream: Array<Dashboard>;
  /** fetch data from the table: "risksmart.data_import" */
  data_import: Array<Data_Import>;
  /** fetch aggregated fields from the table: "risksmart.data_import" */
  data_import_aggregate: Data_Import_Aggregate;
  /** fetch data from the table: "risksmart.data_import" using primary key columns */
  data_import_by_pk?: Maybe<Data_Import>;
  /** fetch data from the table: "risksmart.data_import_error" */
  data_import_error: Array<Data_Import_Error>;
  /** fetch aggregated fields from the table: "risksmart.data_import_error" */
  data_import_error_aggregate: Data_Import_Error_Aggregate;
  /** fetch data from the table: "risksmart.data_import_error" using primary key columns */
  data_import_error_by_pk?: Maybe<Data_Import_Error>;
  /** fetch data from the table in a streaming manner: "risksmart.data_import_error" */
  data_import_error_stream: Array<Data_Import_Error>;
  /** fetch data from the table in a streaming manner: "risksmart.data_import" */
  data_import_stream: Array<Data_Import>;
  /** fetch data from the table: "risksmart.department_audit" */
  department_audit: Array<Department_Audit>;
  /** fetch data from the table: "risksmart.department_audit" using primary key columns */
  department_audit_by_pk?: Maybe<Department_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.department_audit" */
  department_audit_stream: Array<Department_Audit>;
  /** fetch data from the table: "risksmart.department_type" */
  department_type: Array<Department_Type>;
  /** fetch data from the table: "risksmart.department_type_audit" */
  department_type_audit: Array<Department_Type_Audit>;
  /** fetch data from the table: "risksmart.department_type_audit" using primary key columns */
  department_type_audit_by_pk?: Maybe<Department_Type_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.department_type_audit" */
  department_type_audit_stream: Array<Department_Type_Audit>;
  /** fetch data from the table: "risksmart.department_type" using primary key columns */
  department_type_by_pk?: Maybe<Department_Type>;
  /** fetch data from the table: "risksmart.department_type_group" */
  department_type_group: Array<Department_Type_Group>;
  /** fetch data from the table: "risksmart.department_type_group_audit" */
  department_type_group_audit: Array<Department_Type_Group_Audit>;
  /** fetch data from the table: "risksmart.department_type_group_audit" using primary key columns */
  department_type_group_audit_by_pk?: Maybe<Department_Type_Group_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.department_type_group_audit" */
  department_type_group_audit_stream: Array<Department_Type_Group_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.department_type_group" */
  department_type_group_stream: Array<Department_Type_Group>;
  /** fetch data from the table in a streaming manner: "risksmart.department_type" */
  department_type_stream: Array<Department_Type>;
  /** fetch data from the table: "risksmart.document" */
  document: Array<Document>;
  /** fetch data from the table: "risksmart.document_assessment_result" */
  document_assessment_result: Array<Document_Assessment_Result>;
  /** fetch aggregated fields from the table: "risksmart.document_assessment_result" */
  document_assessment_result_aggregate: Document_Assessment_Result_Aggregate;
  /** fetch data from the table: "risksmart.document_assessment_result_audit" */
  document_assessment_result_audit: Array<Document_Assessment_Result_Audit>;
  /** fetch aggregated fields from the table: "risksmart.document_assessment_result_audit" */
  document_assessment_result_audit_aggregate: Document_Assessment_Result_Audit_Aggregate;
  /** fetch data from the table: "risksmart.document_assessment_result_audit" using primary key columns */
  document_assessment_result_audit_by_pk?: Maybe<Document_Assessment_Result_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.document_assessment_result_audit" */
  document_assessment_result_audit_stream: Array<Document_Assessment_Result_Audit>;
  /** fetch data from the table: "risksmart.document_assessment_result" using primary key columns */
  document_assessment_result_by_pk?: Maybe<Document_Assessment_Result>;
  /** fetch data from the table in a streaming manner: "risksmart.document_assessment_result" */
  document_assessment_result_stream: Array<Document_Assessment_Result>;
  /** fetch data from the table: "risksmart.document_audit" */
  document_audit: Array<Document_Audit>;
  /** fetch data from the table: "risksmart.document_audit" using primary key columns */
  document_audit_by_pk?: Maybe<Document_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.document_audit" */
  document_audit_stream: Array<Document_Audit>;
  /** fetch data from the table: "risksmart.document" using primary key columns */
  document_by_pk?: Maybe<Document>;
  /** fetch data from the table: "risksmart.document_file" */
  document_file: Array<Document_File>;
  /** fetch data from the table: "risksmart.document_file_audit" */
  document_file_audit: Array<Document_File_Audit>;
  /** fetch data from the table: "risksmart.document_file_audit" using primary key columns */
  document_file_audit_by_pk?: Maybe<Document_File_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.document_file_audit" */
  document_file_audit_stream: Array<Document_File_Audit>;
  /** fetch data from the table: "risksmart.document_file" using primary key columns */
  document_file_by_pk?: Maybe<Document_File>;
  /** fetch data from the table in a streaming manner: "risksmart.document_file" */
  document_file_stream: Array<Document_File>;
  /** fetch data from the table: "risksmart.document_linked_document" */
  document_linked_document: Array<Document_Linked_Document>;
  /** fetch data from the table: "risksmart.document_linked_document_audit" */
  document_linked_document_audit: Array<Document_Linked_Document_Audit>;
  /** fetch data from the table: "risksmart.document_linked_document_audit" using primary key columns */
  document_linked_document_audit_by_pk?: Maybe<Document_Linked_Document_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.document_linked_document_audit" */
  document_linked_document_audit_stream: Array<Document_Linked_Document_Audit>;
  /** fetch data from the table: "risksmart.document_linked_document" using primary key columns */
  document_linked_document_by_pk?: Maybe<Document_Linked_Document>;
  /** fetch data from the table in a streaming manner: "risksmart.document_linked_document" */
  document_linked_document_stream: Array<Document_Linked_Document>;
  /** fetch data from the table in a streaming manner: "risksmart.document" */
  document_stream: Array<Document>;
  /** fetch data from the table: "risksmart.file" */
  file: Array<File>;
  /** fetch aggregated fields from the table: "risksmart.file" */
  file_aggregate: File_Aggregate;
  /** fetch data from the table: "risksmart.file_audit" */
  file_audit: Array<File_Audit>;
  /** fetch data from the table: "risksmart.file_audit" using primary key columns */
  file_audit_by_pk?: Maybe<File_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.file_audit" */
  file_audit_stream: Array<File_Audit>;
  /** fetch data from the table: "risksmart.file" using primary key columns */
  file_by_pk?: Maybe<File>;
  /** fetch data from the table in a streaming manner: "risksmart.file" */
  file_stream: Array<File>;
  /** fetch data from the table: "risksmart.form_configuration" */
  form_configuration: Array<Form_Configuration>;
  /** fetch data from the table: "risksmart.form_configuration_audit" */
  form_configuration_audit: Array<Form_Configuration_Audit>;
  /** fetch data from the table: "risksmart.form_configuration_audit" using primary key columns */
  form_configuration_audit_by_pk?: Maybe<Form_Configuration_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.form_configuration_audit" */
  form_configuration_audit_stream: Array<Form_Configuration_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.form_configuration" */
  form_configuration_stream: Array<Form_Configuration>;
  /** fetch data from the table: "risksmart.form_field_configuration" */
  form_field_configuration: Array<Form_Field_Configuration>;
  /** fetch data from the table: "risksmart.form_field_configuration_audit" */
  form_field_configuration_audit: Array<Form_Field_Configuration_Audit>;
  /** fetch data from the table: "risksmart.form_field_configuration_audit" using primary key columns */
  form_field_configuration_audit_by_pk?: Maybe<Form_Field_Configuration_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.form_field_configuration_audit" */
  form_field_configuration_audit_stream: Array<Form_Field_Configuration_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.form_field_configuration" */
  form_field_configuration_stream: Array<Form_Field_Configuration>;
  /** fetch data from the table: "risksmart.form_field_ordering" */
  form_field_ordering: Array<Form_Field_Ordering>;
  /** fetch data from the table: "risksmart.form_field_ordering_audit" */
  form_field_ordering_audit: Array<Form_Field_Ordering_Audit>;
  /** fetch data from the table: "risksmart.form_field_ordering_audit" using primary key columns */
  form_field_ordering_audit_by_pk?: Maybe<Form_Field_Ordering_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.form_field_ordering_audit" */
  form_field_ordering_audit_stream: Array<Form_Field_Ordering_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.form_field_ordering" */
  form_field_ordering_stream: Array<Form_Field_Ordering>;
  /** fetch data from the table: "risksmart.impact" */
  impact: Array<Impact>;
  /** fetch aggregated fields from the table: "risksmart.impact" */
  impact_aggregate: Impact_Aggregate;
  /** fetch data from the table: "risksmart.impact_audit" */
  impact_audit: Array<Impact_Audit>;
  /** fetch data from the table: "risksmart.impact_audit" using primary key columns */
  impact_audit_by_pk?: Maybe<Impact_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.impact_audit" */
  impact_audit_stream: Array<Impact_Audit>;
  /** fetch data from the table: "risksmart.impact" using primary key columns */
  impact_by_pk?: Maybe<Impact>;
  /** fetch data from the table: "risksmart.impact_parent" */
  impact_parent: Array<Impact_Parent>;
  /** fetch aggregated fields from the table: "risksmart.impact_parent" */
  impact_parent_aggregate: Impact_Parent_Aggregate;
  /** fetch data from the table: "risksmart.impact_parent_audit" */
  impact_parent_audit: Array<Impact_Parent_Audit>;
  /** fetch data from the table: "risksmart.impact_parent_audit" using primary key columns */
  impact_parent_audit_by_pk?: Maybe<Impact_Parent_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.impact_parent_audit" */
  impact_parent_audit_stream: Array<Impact_Parent_Audit>;
  /** fetch data from the table: "risksmart.impact_parent" using primary key columns */
  impact_parent_by_pk?: Maybe<Impact_Parent>;
  /** fetch data from the table in a streaming manner: "risksmart.impact_parent" */
  impact_parent_stream: Array<Impact_Parent>;
  /** fetch data from the table: "risksmart.impact_rating" */
  impact_rating: Array<Impact_Rating>;
  /** fetch aggregated fields from the table: "risksmart.impact_rating" */
  impact_rating_aggregate: Impact_Rating_Aggregate;
  /** fetch data from the table: "risksmart.impact_rating_audit" */
  impact_rating_audit: Array<Impact_Rating_Audit>;
  /** fetch data from the table: "risksmart.impact_rating_audit" using primary key columns */
  impact_rating_audit_by_pk?: Maybe<Impact_Rating_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.impact_rating_audit" */
  impact_rating_audit_stream: Array<Impact_Rating_Audit>;
  /** fetch data from the table: "risksmart.impact_rating" using primary key columns */
  impact_rating_by_pk?: Maybe<Impact_Rating>;
  /** fetch data from the table in a streaming manner: "risksmart.impact_rating" */
  impact_rating_stream: Array<Impact_Rating>;
  /** fetch data from the table in a streaming manner: "risksmart.impact" */
  impact_stream: Array<Impact>;
  /** fetch data from the table: "risksmart.indicator" */
  indicator: Array<Indicator>;
  /** fetch data from the table: "risksmart.indicator_audit" */
  indicator_audit: Array<Indicator_Audit>;
  /** fetch data from the table: "risksmart.indicator_audit" using primary key columns */
  indicator_audit_by_pk?: Maybe<Indicator_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.indicator_audit" */
  indicator_audit_stream: Array<Indicator_Audit>;
  /** fetch data from the table: "risksmart.indicator" using primary key columns */
  indicator_by_pk?: Maybe<Indicator>;
  /** fetch data from the table: "risksmart.indicator_parent" */
  indicator_parent: Array<Indicator_Parent>;
  /** fetch aggregated fields from the table: "risksmart.indicator_parent" */
  indicator_parent_aggregate: Indicator_Parent_Aggregate;
  /** fetch data from the table: "risksmart.indicator_parent" using primary key columns */
  indicator_parent_by_pk?: Maybe<Indicator_Parent>;
  /** fetch data from the table in a streaming manner: "risksmart.indicator_parent" */
  indicator_parent_stream: Array<Indicator_Parent>;
  /** fetch data from the table: "risksmart.indicator_result" */
  indicator_result: Array<Indicator_Result>;
  /** fetch data from the table: "risksmart.indicator_result_audit" */
  indicator_result_audit: Array<Indicator_Result_Audit>;
  /** fetch data from the table: "risksmart.indicator_result_audit" using primary key columns */
  indicator_result_audit_by_pk?: Maybe<Indicator_Result_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.indicator_result_audit" */
  indicator_result_audit_stream: Array<Indicator_Result_Audit>;
  /** fetch data from the table: "risksmart.indicator_result" using primary key columns */
  indicator_result_by_pk?: Maybe<Indicator_Result>;
  /** fetch data from the table in a streaming manner: "risksmart.indicator_result" */
  indicator_result_stream: Array<Indicator_Result>;
  /** fetch data from the table in a streaming manner: "risksmart.indicator" */
  indicator_stream: Array<Indicator>;
  /** fetch data from the table: "risksmart.indicator_type" */
  indicator_type: Array<Indicator_Type>;
  /** fetch data from the table: "risksmart.indicator_type" using primary key columns */
  indicator_type_by_pk?: Maybe<Indicator_Type>;
  /** fetch data from the table in a streaming manner: "risksmart.indicator_type" */
  indicator_type_stream: Array<Indicator_Type>;
  /** fetch data from the table: "risksmart.internal_audit_entity" */
  internal_audit_entity: Array<Internal_Audit_Entity>;
  /** fetch aggregated fields from the table: "risksmart.internal_audit_entity" */
  internal_audit_entity_aggregate: Internal_Audit_Entity_Aggregate;
  /** fetch data from the table: "risksmart.internal_audit_entity" using primary key columns */
  internal_audit_entity_by_pk?: Maybe<Internal_Audit_Entity>;
  /** fetch data from the table in a streaming manner: "risksmart.internal_audit_entity" */
  internal_audit_entity_stream: Array<Internal_Audit_Entity>;
  /** fetch data from the table: "risksmart.internal_audit_report" */
  internal_audit_report: Array<Internal_Audit_Report>;
  /** fetch aggregated fields from the table: "risksmart.internal_audit_report" */
  internal_audit_report_aggregate: Internal_Audit_Report_Aggregate;
  /** fetch data from the table: "risksmart.internal_audit_report" using primary key columns */
  internal_audit_report_by_pk?: Maybe<Internal_Audit_Report>;
  /** fetch data from the table in a streaming manner: "risksmart.internal_audit_report" */
  internal_audit_report_stream: Array<Internal_Audit_Report>;
  /** fetch data from the table: "risksmart.issue" */
  issue: Array<Issue>;
  /** fetch aggregated fields from the table: "risksmart.issue" */
  issue_aggregate: Issue_Aggregate;
  /** fetch data from the table: "risksmart.issue_assessment" */
  issue_assessment: Array<Issue_Assessment>;
  /** fetch aggregated fields from the table: "risksmart.issue_assessment" */
  issue_assessment_aggregate: Issue_Assessment_Aggregate;
  /** fetch data from the table: "risksmart.issue_assessment_audit" */
  issue_assessment_audit: Array<Issue_Assessment_Audit>;
  /** fetch data from the table: "risksmart.issue_assessment_audit" using primary key columns */
  issue_assessment_audit_by_pk?: Maybe<Issue_Assessment_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.issue_assessment_audit" */
  issue_assessment_audit_stream: Array<Issue_Assessment_Audit>;
  /** fetch data from the table: "risksmart.issue_assessment" using primary key columns */
  issue_assessment_by_pk?: Maybe<Issue_Assessment>;
  /** fetch data from the table in a streaming manner: "risksmart.issue_assessment" */
  issue_assessment_stream: Array<Issue_Assessment>;
  /** fetch data from the table: "risksmart.issue_audit" */
  issue_audit: Array<Issue_Audit>;
  /** fetch data from the table: "risksmart.issue_audit" using primary key columns */
  issue_audit_by_pk?: Maybe<Issue_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.issue_audit" */
  issue_audit_stream: Array<Issue_Audit>;
  /** fetch data from the table: "risksmart.issue" using primary key columns */
  issue_by_pk?: Maybe<Issue>;
  /** fetch data from the table: "risksmart.issue_parent" */
  issue_parent: Array<Issue_Parent>;
  /** fetch aggregated fields from the table: "risksmart.issue_parent" */
  issue_parent_aggregate: Issue_Parent_Aggregate;
  /** fetch data from the table: "risksmart.issue_parent_audit" */
  issue_parent_audit: Array<Issue_Parent_Audit>;
  /** fetch data from the table: "risksmart.issue_parent_audit" using primary key columns */
  issue_parent_audit_by_pk?: Maybe<Issue_Parent_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.issue_parent_audit" */
  issue_parent_audit_stream: Array<Issue_Parent_Audit>;
  /** fetch data from the table: "risksmart.issue_parent" using primary key columns */
  issue_parent_by_pk?: Maybe<Issue_Parent>;
  /** fetch data from the table in a streaming manner: "risksmart.issue_parent" */
  issue_parent_stream: Array<Issue_Parent>;
  /** fetch data from the table in a streaming manner: "risksmart.issue" */
  issue_stream: Array<Issue>;
  /** fetch data from the table: "risksmart.issue_update" */
  issue_update: Array<Issue_Update>;
  /** fetch data from the table: "risksmart.issue_update_audit" */
  issue_update_audit: Array<Issue_Update_Audit>;
  /** fetch data from the table: "risksmart.issue_update_audit" using primary key columns */
  issue_update_audit_by_pk?: Maybe<Issue_Update_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.issue_update_audit" */
  issue_update_audit_stream: Array<Issue_Update_Audit>;
  /** fetch data from the table: "risksmart.issue_update" using primary key columns */
  issue_update_by_pk?: Maybe<Issue_Update>;
  /** fetch data from the table in a streaming manner: "risksmart.issue_update" */
  issue_update_stream: Array<Issue_Update>;
  /** fetch data from the table: "risksmart.linked_item" */
  linked_item: Array<Linked_Item>;
  /** fetch data from the table: "risksmart.linked_item" using primary key columns */
  linked_item_by_pk?: Maybe<Linked_Item>;
  /** fetch data from the table in a streaming manner: "risksmart.linked_item" */
  linked_item_stream: Array<Linked_Item>;
  /** fetch data from the table: "risksmart.node" */
  node: Array<Node>;
  /** fetch aggregated fields from the table: "risksmart.node" */
  node_aggregate: Node_Aggregate;
  /** fetch data from the table: "risksmart.node" using primary key columns */
  node_by_pk?: Maybe<Node>;
  /** fetch data from the table in a streaming manner: "risksmart.node" */
  node_stream: Array<Node>;
  /** fetch data from the table: "risksmart.obligation" */
  obligation: Array<Obligation>;
  /** fetch aggregated fields from the table: "risksmart.obligation" */
  obligation_aggregate: Obligation_Aggregate;
  /** fetch data from the table: "risksmart.obligation_assessment_result" */
  obligation_assessment_result: Array<Obligation_Assessment_Result>;
  /** fetch aggregated fields from the table: "risksmart.obligation_assessment_result" */
  obligation_assessment_result_aggregate: Obligation_Assessment_Result_Aggregate;
  /** fetch data from the table: "risksmart.obligation_assessment_result_audit" */
  obligation_assessment_result_audit: Array<Obligation_Assessment_Result_Audit>;
  /** fetch data from the table: "risksmart.obligation_assessment_result_audit" using primary key columns */
  obligation_assessment_result_audit_by_pk?: Maybe<Obligation_Assessment_Result_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.obligation_assessment_result_audit" */
  obligation_assessment_result_audit_stream: Array<Obligation_Assessment_Result_Audit>;
  /** fetch data from the table: "risksmart.obligation_assessment_result" using primary key columns */
  obligation_assessment_result_by_pk?: Maybe<Obligation_Assessment_Result>;
  /** fetch data from the table in a streaming manner: "risksmart.obligation_assessment_result" */
  obligation_assessment_result_stream: Array<Obligation_Assessment_Result>;
  /** fetch data from the table: "risksmart.obligation_assessment_status" */
  obligation_assessment_status: Array<Obligation_Assessment_Status>;
  /** fetch data from the table: "risksmart.obligation_assessment_status" using primary key columns */
  obligation_assessment_status_by_pk?: Maybe<Obligation_Assessment_Status>;
  /** fetch data from the table in a streaming manner: "risksmart.obligation_assessment_status" */
  obligation_assessment_status_stream: Array<Obligation_Assessment_Status>;
  /** fetch data from the table: "risksmart.obligation_audit" */
  obligation_audit: Array<Obligation_Audit>;
  /** fetch aggregated fields from the table: "risksmart.obligation_audit" */
  obligation_audit_aggregate: Obligation_Audit_Aggregate;
  /** fetch data from the table: "risksmart.obligation_audit" using primary key columns */
  obligation_audit_by_pk?: Maybe<Obligation_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.obligation_audit" */
  obligation_audit_stream: Array<Obligation_Audit>;
  /** fetch data from the table: "risksmart.obligation" using primary key columns */
  obligation_by_pk?: Maybe<Obligation>;
  /** fetch data from the table: "risksmart.obligation_impact" */
  obligation_impact: Array<Obligation_Impact>;
  /** fetch data from the table: "risksmart.obligation_impact_audit" */
  obligation_impact_audit: Array<Obligation_Impact_Audit>;
  /** fetch data from the table: "risksmart.obligation_impact_audit" using primary key columns */
  obligation_impact_audit_by_pk?: Maybe<Obligation_Impact_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.obligation_impact_audit" */
  obligation_impact_audit_stream: Array<Obligation_Impact_Audit>;
  /** fetch data from the table: "risksmart.obligation_impact" using primary key columns */
  obligation_impact_by_pk?: Maybe<Obligation_Impact>;
  /** fetch data from the table in a streaming manner: "risksmart.obligation_impact" */
  obligation_impact_stream: Array<Obligation_Impact>;
  /** fetch data from the table in a streaming manner: "risksmart.obligation" */
  obligation_stream: Array<Obligation>;
  /** fetch data from the table: "risksmart.obligation_type" */
  obligation_type: Array<Obligation_Type>;
  /** fetch data from the table: "risksmart.obligation_type" using primary key columns */
  obligation_type_by_pk?: Maybe<Obligation_Type>;
  /** fetch data from the table in a streaming manner: "risksmart.obligation_type" */
  obligation_type_stream: Array<Obligation_Type>;
  /** fetch data from the table: "risksmart.owner_audit" */
  owner_audit: Array<Owner_Audit>;
  /** fetch data from the table: "risksmart.owner_audit" using primary key columns */
  owner_audit_by_pk?: Maybe<Owner_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.owner_audit" */
  owner_audit_stream: Array<Owner_Audit>;
  /** fetch data from the table: "risksmart.owner_group_audit" */
  owner_group_audit: Array<Owner_Group_Audit>;
  /** fetch data from the table: "risksmart.owner_group_audit" using primary key columns */
  owner_group_audit_by_pk?: Maybe<Owner_Group_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.owner_group_audit" */
  owner_group_audit_stream: Array<Owner_Group_Audit>;
  /** fetch data from the table: "risksmart.questionnaire_invite" */
  questionnaire_invite: Array<Questionnaire_Invite>;
  /** fetch data from the table: "risksmart.questionnaire_invite" using primary key columns */
  questionnaire_invite_by_pk?: Maybe<Questionnaire_Invite>;
  /** fetch data from the table in a streaming manner: "risksmart.questionnaire_invite" */
  questionnaire_invite_stream: Array<Questionnaire_Invite>;
  /** fetch data from the table: "risksmart.questionnaire_template" */
  questionnaire_template: Array<Questionnaire_Template>;
  /** fetch data from the table: "risksmart.questionnaire_template" using primary key columns */
  questionnaire_template_by_pk?: Maybe<Questionnaire_Template>;
  /** fetch data from the table in a streaming manner: "risksmart.questionnaire_template" */
  questionnaire_template_stream: Array<Questionnaire_Template>;
  /** fetch data from the table: "risksmart.questionnaire_template_version" */
  questionnaire_template_version: Array<Questionnaire_Template_Version>;
  /** fetch data from the table: "risksmart.questionnaire_template_version" using primary key columns */
  questionnaire_template_version_by_pk?: Maybe<Questionnaire_Template_Version>;
  /** fetch data from the table: "risksmart.questionnaire_template_version_status" */
  questionnaire_template_version_status: Array<Questionnaire_Template_Version_Status>;
  /** fetch data from the table: "risksmart.questionnaire_template_version_status" using primary key columns */
  questionnaire_template_version_status_by_pk?: Maybe<Questionnaire_Template_Version_Status>;
  /** fetch data from the table in a streaming manner: "risksmart.questionnaire_template_version_status" */
  questionnaire_template_version_status_stream: Array<Questionnaire_Template_Version_Status>;
  /** fetch data from the table in a streaming manner: "risksmart.questionnaire_template_version" */
  questionnaire_template_version_stream: Array<Questionnaire_Template_Version>;
  /** fetch data from the table: "risksmart.relation_file" */
  relation_file: Array<Relation_File>;
  /** fetch aggregated fields from the table: "risksmart.relation_file" */
  relation_file_aggregate: Relation_File_Aggregate;
  /** fetch data from the table: "risksmart.relation_file_audit" */
  relation_file_audit: Array<Relation_File_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.relation_file_audit" */
  relation_file_audit_stream: Array<Relation_File_Audit>;
  /** fetch data from the table: "risksmart.relation_file" using primary key columns */
  relation_file_by_pk?: Maybe<Relation_File>;
  /** fetch data from the table in a streaming manner: "risksmart.relation_file" */
  relation_file_stream: Array<Relation_File>;
  /** fetch data from the table: "risksmart.risk" */
  risk: Array<Risk>;
  /** fetch aggregated fields from the table: "risksmart.risk" */
  risk_aggregate: Risk_Aggregate;
  /** fetch data from the table: "risksmart.risk_assessment_result" */
  risk_assessment_result: Array<Risk_Assessment_Result>;
  /** fetch aggregated fields from the table: "risksmart.risk_assessment_result" */
  risk_assessment_result_aggregate: Risk_Assessment_Result_Aggregate;
  /** fetch data from the table: "risksmart.risk_assessment_result_audit" */
  risk_assessment_result_audit: Array<Risk_Assessment_Result_Audit>;
  /** fetch data from the table: "risksmart.risk_assessment_result_audit" using primary key columns */
  risk_assessment_result_audit_by_pk?: Maybe<Risk_Assessment_Result_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.risk_assessment_result_audit" */
  risk_assessment_result_audit_stream: Array<Risk_Assessment_Result_Audit>;
  /** fetch data from the table: "risksmart.risk_assessment_result" using primary key columns */
  risk_assessment_result_by_pk?: Maybe<Risk_Assessment_Result>;
  /** fetch data from the table in a streaming manner: "risksmart.risk_assessment_result" */
  risk_assessment_result_stream: Array<Risk_Assessment_Result>;
  /** fetch data from the table: "risksmart.risk_audit" */
  risk_audit: Array<Risk_Audit>;
  /** fetch aggregated fields from the table: "risksmart.risk_audit" */
  risk_audit_aggregate: Risk_Audit_Aggregate;
  /** fetch data from the table: "risksmart.risk_audit" using primary key columns */
  risk_audit_by_pk?: Maybe<Risk_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.risk_audit" */
  risk_audit_stream: Array<Risk_Audit>;
  /** fetch data from the table: "risksmart.risk" using primary key columns */
  risk_by_pk?: Maybe<Risk>;
  /** fetch data from the table: "risksmart.risk_score" */
  risk_score: Array<Risk_Score>;
  /** fetch aggregated fields from the table: "risksmart.risk_score" */
  risk_score_aggregate: Risk_Score_Aggregate;
  /** fetch data from the table: "risksmart.risk_score" using primary key columns */
  risk_score_by_pk?: Maybe<Risk_Score>;
  /** fetch data from the table in a streaming manner: "risksmart.risk_score" */
  risk_score_stream: Array<Risk_Score>;
  /** fetch data from the table in a streaming manner: "risksmart.risk" */
  risk_stream: Array<Risk>;
  /** fetch data from the table: "risksmart.indicator_parent_audit" */
  risksmart_indicator_parent_audit: Array<Risksmart_Indicator_Parent_Audit>;
  /** fetch data from the table: "risksmart.indicator_parent_audit" using primary key columns */
  risksmart_indicator_parent_audit_by_pk?: Maybe<Risksmart_Indicator_Parent_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.indicator_parent_audit" */
  risksmart_indicator_parent_audit_stream: Array<Risksmart_Indicator_Parent_Audit>;
  /** fetch data from the table: "risksmart.linked_item_audit" */
  risksmart_linked_item_audit: Array<Risksmart_Linked_Item_Audit>;
  /** fetch data from the table: "risksmart.linked_item_audit" using primary key columns */
  risksmart_linked_item_audit_by_pk?: Maybe<Risksmart_Linked_Item_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.linked_item_audit" */
  risksmart_linked_item_audit_stream: Array<Risksmart_Linked_Item_Audit>;
  /** fetch data from the table: "risksmart.role_access" */
  role_access: Array<Role_Access>;
  /** fetch data from the table in a streaming manner: "risksmart.role_access" */
  role_access_stream: Array<Role_Access>;
  /** fetch data from the table: "risksmart.tag_type" */
  tag_type: Array<Tag_Type>;
  /** fetch data from the table: "risksmart.tag_type_audit" */
  tag_type_audit: Array<Tag_Type_Audit>;
  /** fetch data from the table: "risksmart.tag_type_audit" using primary key columns */
  tag_type_audit_by_pk?: Maybe<Tag_Type_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.tag_type_audit" */
  tag_type_audit_stream: Array<Tag_Type_Audit>;
  /** fetch data from the table: "risksmart.tag_type" using primary key columns */
  tag_type_by_pk?: Maybe<Tag_Type>;
  /** fetch data from the table: "risksmart.tag_type_group" */
  tag_type_group: Array<Tag_Type_Group>;
  /** fetch data from the table: "risksmart.tag_type_group_audit" */
  tag_type_group_audit: Array<Tag_Type_Group_Audit>;
  /** fetch data from the table: "risksmart.tag_type_group_audit" using primary key columns */
  tag_type_group_audit_by_pk?: Maybe<Tag_Type_Group_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.tag_type_group_audit" */
  tag_type_group_audit_stream: Array<Tag_Type_Group_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.tag_type_group" */
  tag_type_group_stream: Array<Tag_Type_Group>;
  /** fetch data from the table in a streaming manner: "risksmart.tag_type" */
  tag_type_stream: Array<Tag_Type>;
  /** fetch data from the table: "risksmart.taxonomy" */
  taxonomy: Array<Taxonomy>;
  /** fetch aggregated fields from the table: "risksmart.taxonomy" */
  taxonomy_aggregate: Taxonomy_Aggregate;
  /** fetch data from the table: "risksmart.taxonomy_audit" */
  taxonomy_audit: Array<Taxonomy_Audit>;
  /** fetch aggregated fields from the table: "risksmart.taxonomy_audit" */
  taxonomy_audit_aggregate: Taxonomy_Audit_Aggregate;
  /** fetch data from the table: "risksmart.taxonomy_audit" using primary key columns */
  taxonomy_audit_by_pk?: Maybe<Taxonomy_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.taxonomy_audit" */
  taxonomy_audit_stream: Array<Taxonomy_Audit>;
  /** fetch data from the table: "risksmart.taxonomy" using primary key columns */
  taxonomy_by_pk?: Maybe<Taxonomy>;
  /** fetch data from the table: "risksmart.taxonomy_org" */
  taxonomy_org: Array<Taxonomy_Org>;
  /** fetch aggregated fields from the table: "risksmart.taxonomy_org" */
  taxonomy_org_aggregate: Taxonomy_Org_Aggregate;
  /** fetch data from the table: "risksmart.taxonomy_org" using primary key columns */
  taxonomy_org_by_pk?: Maybe<Taxonomy_Org>;
  /** fetch data from the table in a streaming manner: "risksmart.taxonomy_org" */
  taxonomy_org_stream: Array<Taxonomy_Org>;
  /** fetch data from the table in a streaming manner: "risksmart.taxonomy" */
  taxonomy_stream: Array<Taxonomy>;
  /** fetch data from the table: "risksmart.test_result" */
  test_result: Array<Test_Result>;
  /** fetch aggregated fields from the table: "risksmart.test_result" */
  test_result_aggregate: Test_Result_Aggregate;
  /** fetch data from the table: "risksmart.test_result_audit" */
  test_result_audit: Array<Test_Result_Audit>;
  /** fetch data from the table: "risksmart.test_result_audit" using primary key columns */
  test_result_audit_by_pk?: Maybe<Test_Result_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.test_result_audit" */
  test_result_audit_stream: Array<Test_Result_Audit>;
  /** fetch data from the table: "risksmart.test_result" using primary key columns */
  test_result_by_pk?: Maybe<Test_Result>;
  /** fetch data from the table in a streaming manner: "risksmart.test_result" */
  test_result_stream: Array<Test_Result>;
  /** fetch data from the table: "risksmart.third_party" */
  third_party: Array<Third_Party>;
  /** fetch data from the table: "risksmart.third_party" using primary key columns */
  third_party_by_pk?: Maybe<Third_Party>;
  /** fetch data from the table: "risksmart.third_party_response" */
  third_party_response: Array<Third_Party_Response>;
  /** fetch data from the table: "risksmart.third_party_response" using primary key columns */
  third_party_response_by_pk?: Maybe<Third_Party_Response>;
  /** fetch data from the table: "risksmart.third_party_response_status" */
  third_party_response_status: Array<Third_Party_Response_Status>;
  /** fetch data from the table: "risksmart.third_party_response_status" using primary key columns */
  third_party_response_status_by_pk?: Maybe<Third_Party_Response_Status>;
  /** fetch data from the table in a streaming manner: "risksmart.third_party_response_status" */
  third_party_response_status_stream: Array<Third_Party_Response_Status>;
  /** fetch data from the table in a streaming manner: "risksmart.third_party_response" */
  third_party_response_stream: Array<Third_Party_Response>;
  /** fetch data from the table in a streaming manner: "risksmart.third_party" */
  third_party_stream: Array<Third_Party>;
  /** fetch data from the table: "risksmart.user_view_active" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "risksmart.user_view_active" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "risksmart.user_group" */
  user_group: Array<User_Group>;
  /** fetch data from the table: "risksmart.user_group_audit" */
  user_group_audit: Array<User_Group_Audit>;
  /** fetch data from the table: "risksmart.user_group_audit" using primary key columns */
  user_group_audit_by_pk?: Maybe<User_Group_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.user_group_audit" */
  user_group_audit_stream: Array<User_Group_Audit>;
  /** fetch data from the table: "risksmart.user_group" using primary key columns */
  user_group_by_pk?: Maybe<User_Group>;
  /** fetch data from the table in a streaming manner: "risksmart.user_group" */
  user_group_stream: Array<User_Group>;
  /** fetch data from the table: "risksmart.user_group_user" */
  user_group_user: Array<User_Group_User>;
  /** fetch aggregated fields from the table: "risksmart.user_group_user" */
  user_group_user_aggregate: User_Group_User_Aggregate;
  /** fetch data from the table: "risksmart.user_group_user_audit" */
  user_group_user_audit: Array<User_Group_User_Audit>;
  /** fetch data from the table: "risksmart.user_group_user_audit" using primary key columns */
  user_group_user_audit_by_pk?: Maybe<User_Group_User_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.user_group_user_audit" */
  user_group_user_audit_stream: Array<User_Group_User_Audit>;
  /** fetch data from the table: "risksmart.user_group_user" using primary key columns */
  user_group_user_by_pk?: Maybe<User_Group_User>;
  /** fetch data from the table in a streaming manner: "risksmart.user_group_user" */
  user_group_user_stream: Array<User_Group_User>;
  /** fetch data from the table: "risksmart.user_search_preferences" */
  user_search_preferences: Array<User_Search_Preferences>;
  /** fetch data from the table: "risksmart.user_search_preferences_audit" */
  user_search_preferences_audit: Array<User_Search_Preferences_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.user_search_preferences_audit" */
  user_search_preferences_audit_stream: Array<User_Search_Preferences_Audit>;
  /** fetch data from the table in a streaming manner: "risksmart.user_search_preferences" */
  user_search_preferences_stream: Array<User_Search_Preferences>;
  /** fetch data from the table in a streaming manner: "risksmart.user_view_active" */
  user_stream: Array<User>;
  /** fetch data from the table: "risksmart.user_table_preferences" */
  user_table_preferences: Array<User_Table_Preferences>;
  /** fetch data from the table in a streaming manner: "risksmart.user_table_preferences" */
  user_table_preferences_stream: Array<User_Table_Preferences>;
};


export type Subscription_RootAcceptanceArgs = {
  distinct_on?: InputMaybe<Array<Acceptance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Acceptance_Order_By>>;
  where?: InputMaybe<Acceptance_Bool_Exp>;
};


export type Subscription_RootAcceptance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Acceptance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Acceptance_Order_By>>;
  where?: InputMaybe<Acceptance_Bool_Exp>;
};


export type Subscription_RootAcceptance_AuditArgs = {
  distinct_on?: InputMaybe<Array<Acceptance_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Acceptance_Audit_Order_By>>;
  where?: InputMaybe<Acceptance_Audit_Bool_Exp>;
};


export type Subscription_RootAcceptance_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootAcceptance_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Acceptance_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Acceptance_Audit_Bool_Exp>;
};


export type Subscription_RootAcceptance_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootAcceptance_ParentArgs = {
  distinct_on?: InputMaybe<Array<Acceptance_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Acceptance_Parent_Order_By>>;
  where?: InputMaybe<Acceptance_Parent_Bool_Exp>;
};


export type Subscription_RootAcceptance_Parent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Acceptance_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Acceptance_Parent_Order_By>>;
  where?: InputMaybe<Acceptance_Parent_Bool_Exp>;
};


export type Subscription_RootAcceptance_Parent_AuditArgs = {
  distinct_on?: InputMaybe<Array<Acceptance_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Acceptance_Parent_Audit_Order_By>>;
  where?: InputMaybe<Acceptance_Parent_Audit_Bool_Exp>;
};


export type Subscription_RootAcceptance_Parent_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Subscription_RootAcceptance_Parent_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Acceptance_Parent_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Acceptance_Parent_Audit_Bool_Exp>;
};


export type Subscription_RootAcceptance_Parent_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Subscription_RootAcceptance_Parent_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Acceptance_Parent_Stream_Cursor_Input>>;
  where?: InputMaybe<Acceptance_Parent_Bool_Exp>;
};


export type Subscription_RootAcceptance_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Acceptance_Stream_Cursor_Input>>;
  where?: InputMaybe<Acceptance_Bool_Exp>;
};


export type Subscription_RootActionArgs = {
  distinct_on?: InputMaybe<Array<Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Order_By>>;
  where?: InputMaybe<Action_Bool_Exp>;
};


export type Subscription_RootAction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Order_By>>;
  where?: InputMaybe<Action_Bool_Exp>;
};


export type Subscription_RootAction_AuditArgs = {
  distinct_on?: InputMaybe<Array<Action_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Audit_Order_By>>;
  where?: InputMaybe<Action_Audit_Bool_Exp>;
};


export type Subscription_RootAction_Audit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Audit_Order_By>>;
  where?: InputMaybe<Action_Audit_Bool_Exp>;
};


export type Subscription_RootAction_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootAction_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Action_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Action_Audit_Bool_Exp>;
};


export type Subscription_RootAction_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootAction_ParentArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


export type Subscription_RootAction_Parent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Order_By>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


export type Subscription_RootAction_Parent_AuditArgs = {
  distinct_on?: InputMaybe<Array<Action_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Parent_Audit_Order_By>>;
  where?: InputMaybe<Action_Parent_Audit_Bool_Exp>;
};


export type Subscription_RootAction_Parent_Audit_By_PkArgs = {
  ActionId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Subscription_RootAction_Parent_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Action_Parent_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Action_Parent_Audit_Bool_Exp>;
};


export type Subscription_RootAction_Parent_By_PkArgs = {
  ActionId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Subscription_RootAction_Parent_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Action_Parent_Stream_Cursor_Input>>;
  where?: InputMaybe<Action_Parent_Bool_Exp>;
};


export type Subscription_RootAction_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Action_Stream_Cursor_Input>>;
  where?: InputMaybe<Action_Bool_Exp>;
};


export type Subscription_RootAction_UpdateArgs = {
  distinct_on?: InputMaybe<Array<Action_Update_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Update_Order_By>>;
  where?: InputMaybe<Action_Update_Bool_Exp>;
};


export type Subscription_RootAction_Update_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Update_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Update_Order_By>>;
  where?: InputMaybe<Action_Update_Bool_Exp>;
};


export type Subscription_RootAction_Update_AuditArgs = {
  distinct_on?: InputMaybe<Array<Action_Update_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Update_Audit_Order_By>>;
  where?: InputMaybe<Action_Update_Audit_Bool_Exp>;
};


export type Subscription_RootAction_Update_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootAction_Update_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Action_Update_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Action_Update_Audit_Bool_Exp>;
};


export type Subscription_RootAction_Update_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootAction_Update_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Action_Update_Stream_Cursor_Input>>;
  where?: InputMaybe<Action_Update_Bool_Exp>;
};


export type Subscription_RootAction_Update_SummaryArgs = {
  distinct_on?: InputMaybe<Array<Action_Update_Summary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Update_Summary_Order_By>>;
  where?: InputMaybe<Action_Update_Summary_Bool_Exp>;
};


export type Subscription_RootAction_Update_Summary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Update_Summary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Update_Summary_Order_By>>;
  where?: InputMaybe<Action_Update_Summary_Bool_Exp>;
};


export type Subscription_RootAction_Update_Summary_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Action_Update_Summary_Stream_Cursor_Input>>;
  where?: InputMaybe<Action_Update_Summary_Bool_Exp>;
};


export type Subscription_RootAggregation_OrgArgs = {
  distinct_on?: InputMaybe<Array<Aggregation_Org_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Aggregation_Org_Order_By>>;
  where?: InputMaybe<Aggregation_Org_Bool_Exp>;
};


export type Subscription_RootAggregation_Org_By_PkArgs = {
  OrgKey: Scalars['String']['input'];
};


export type Subscription_RootAggregation_Org_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Aggregation_Org_Stream_Cursor_Input>>;
  where?: InputMaybe<Aggregation_Org_Bool_Exp>;
};


export type Subscription_RootAncestor_ContributorArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


export type Subscription_RootAncestor_Contributor_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Ancestor_Contributor_Stream_Cursor_Input>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


export type Subscription_RootAppetiteArgs = {
  distinct_on?: InputMaybe<Array<Appetite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Appetite_Order_By>>;
  where?: InputMaybe<Appetite_Bool_Exp>;
};


export type Subscription_RootAppetite_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appetite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Appetite_Order_By>>;
  where?: InputMaybe<Appetite_Bool_Exp>;
};


export type Subscription_RootAppetite_AuditArgs = {
  distinct_on?: InputMaybe<Array<Appetite_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Appetite_Audit_Order_By>>;
  where?: InputMaybe<Appetite_Audit_Bool_Exp>;
};


export type Subscription_RootAppetite_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootAppetite_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Appetite_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Appetite_Audit_Bool_Exp>;
};


export type Subscription_RootAppetite_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootAppetite_ParentArgs = {
  distinct_on?: InputMaybe<Array<Appetite_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Appetite_Parent_Order_By>>;
  where?: InputMaybe<Appetite_Parent_Bool_Exp>;
};


export type Subscription_RootAppetite_Parent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appetite_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Appetite_Parent_Order_By>>;
  where?: InputMaybe<Appetite_Parent_Bool_Exp>;
};


export type Subscription_RootAppetite_Parent_AuditArgs = {
  distinct_on?: InputMaybe<Array<Appetite_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Appetite_Parent_Audit_Order_By>>;
  where?: InputMaybe<Appetite_Parent_Audit_Bool_Exp>;
};


export type Subscription_RootAppetite_Parent_Audit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appetite_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Appetite_Parent_Audit_Order_By>>;
  where?: InputMaybe<Appetite_Parent_Audit_Bool_Exp>;
};


export type Subscription_RootAppetite_Parent_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Subscription_RootAppetite_Parent_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Appetite_Parent_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Appetite_Parent_Audit_Bool_Exp>;
};


export type Subscription_RootAppetite_Parent_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Subscription_RootAppetite_Parent_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Appetite_Parent_Stream_Cursor_Input>>;
  where?: InputMaybe<Appetite_Parent_Bool_Exp>;
};


export type Subscription_RootAppetite_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Appetite_Stream_Cursor_Input>>;
  where?: InputMaybe<Appetite_Bool_Exp>;
};


export type Subscription_RootApprovalArgs = {
  distinct_on?: InputMaybe<Array<Approval_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Approval_Order_By>>;
  where?: InputMaybe<Approval_Bool_Exp>;
};


export type Subscription_RootApproval_AuditArgs = {
  distinct_on?: InputMaybe<Array<Approval_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Approval_Audit_Order_By>>;
  where?: InputMaybe<Approval_Audit_Bool_Exp>;
};


export type Subscription_RootApproval_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootApproval_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Approval_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Approval_Audit_Bool_Exp>;
};


export type Subscription_RootApproval_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootApproval_LevelArgs = {
  distinct_on?: InputMaybe<Array<Approval_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Approval_Level_Order_By>>;
  where?: InputMaybe<Approval_Level_Bool_Exp>;
};


export type Subscription_RootApproval_Level_AuditArgs = {
  distinct_on?: InputMaybe<Array<Approval_Level_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Approval_Level_Audit_Order_By>>;
  where?: InputMaybe<Approval_Level_Audit_Bool_Exp>;
};


export type Subscription_RootApproval_Level_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootApproval_Level_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Approval_Level_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Approval_Level_Audit_Bool_Exp>;
};


export type Subscription_RootApproval_Level_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootApproval_Level_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Approval_Level_Stream_Cursor_Input>>;
  where?: InputMaybe<Approval_Level_Bool_Exp>;
};


export type Subscription_RootApproval_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Approval_Stream_Cursor_Input>>;
  where?: InputMaybe<Approval_Bool_Exp>;
};


export type Subscription_RootApproverArgs = {
  distinct_on?: InputMaybe<Array<Approver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Approver_Order_By>>;
  where?: InputMaybe<Approver_Bool_Exp>;
};


export type Subscription_RootApprover_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Approver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Approver_Order_By>>;
  where?: InputMaybe<Approver_Bool_Exp>;
};


export type Subscription_RootApprover_AuditArgs = {
  distinct_on?: InputMaybe<Array<Approver_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Approver_Audit_Order_By>>;
  where?: InputMaybe<Approver_Audit_Bool_Exp>;
};


export type Subscription_RootApprover_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootApprover_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Approver_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Approver_Audit_Bool_Exp>;
};


export type Subscription_RootApprover_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootApprover_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Approver_Stream_Cursor_Input>>;
  where?: InputMaybe<Approver_Bool_Exp>;
};


export type Subscription_RootAssessmentArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Order_By>>;
  where?: InputMaybe<Assessment_Bool_Exp>;
};


export type Subscription_RootAssessment_ActivityArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Activity_Order_By>>;
  where?: InputMaybe<Assessment_Activity_Bool_Exp>;
};


export type Subscription_RootAssessment_Activity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Activity_Order_By>>;
  where?: InputMaybe<Assessment_Activity_Bool_Exp>;
};


export type Subscription_RootAssessment_Activity_AuditArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Activity_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Activity_Audit_Order_By>>;
  where?: InputMaybe<Assessment_Activity_Audit_Bool_Exp>;
};


export type Subscription_RootAssessment_Activity_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootAssessment_Activity_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Assessment_Activity_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Assessment_Activity_Audit_Bool_Exp>;
};


export type Subscription_RootAssessment_Activity_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootAssessment_Activity_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Assessment_Activity_Stream_Cursor_Input>>;
  where?: InputMaybe<Assessment_Activity_Bool_Exp>;
};


export type Subscription_RootAssessment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Order_By>>;
  where?: InputMaybe<Assessment_Bool_Exp>;
};


export type Subscription_RootAssessment_AuditArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Audit_Order_By>>;
  where?: InputMaybe<Assessment_Audit_Bool_Exp>;
};


export type Subscription_RootAssessment_Audit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Audit_Order_By>>;
  where?: InputMaybe<Assessment_Audit_Bool_Exp>;
};


export type Subscription_RootAssessment_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootAssessment_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Assessment_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Assessment_Audit_Bool_Exp>;
};


export type Subscription_RootAssessment_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootAssessment_Result_ParentArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


export type Subscription_RootAssessment_Result_Parent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


export type Subscription_RootAssessment_Result_Parent_AuditArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Audit_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Audit_Bool_Exp>;
};


export type Subscription_RootAssessment_Result_Parent_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Subscription_RootAssessment_Result_Parent_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Assessment_Result_Parent_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Assessment_Result_Parent_Audit_Bool_Exp>;
};


export type Subscription_RootAssessment_Result_Parent_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Subscription_RootAssessment_Result_Parent_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Assessment_Result_Parent_Stream_Cursor_Input>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


export type Subscription_RootAssessment_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Assessment_Stream_Cursor_Input>>;
  where?: InputMaybe<Assessment_Bool_Exp>;
};


export type Subscription_RootAttestation_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Attestation_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attestation_Config_Order_By>>;
  where?: InputMaybe<Attestation_Config_Bool_Exp>;
};


export type Subscription_RootAttestation_Config_By_PkArgs = {
  ParentId: Scalars['uuid']['input'];
};


export type Subscription_RootAttestation_Config_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Attestation_Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Attestation_Config_Bool_Exp>;
};


export type Subscription_RootAttestation_GroupArgs = {
  distinct_on?: InputMaybe<Array<Attestation_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attestation_Group_Order_By>>;
  where?: InputMaybe<Attestation_Group_Bool_Exp>;
};


export type Subscription_RootAttestation_Group_By_PkArgs = {
  ConfigId: Scalars['uuid']['input'];
  GroupId: Scalars['uuid']['input'];
};


export type Subscription_RootAttestation_Group_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Attestation_Group_Stream_Cursor_Input>>;
  where?: InputMaybe<Attestation_Group_Bool_Exp>;
};


export type Subscription_RootAttestation_RecordArgs = {
  distinct_on?: InputMaybe<Array<Attestation_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attestation_Record_Order_By>>;
  where?: InputMaybe<Attestation_Record_Bool_Exp>;
};


export type Subscription_RootAttestation_Record_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attestation_Record_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attestation_Record_Order_By>>;
  where?: InputMaybe<Attestation_Record_Bool_Exp>;
};


export type Subscription_RootAttestation_Record_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootAttestation_Record_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Attestation_Record_Stream_Cursor_Input>>;
  where?: InputMaybe<Attestation_Record_Bool_Exp>;
};


export type Subscription_RootAudit_Log_ViewArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Log_View_Order_By>>;
  where?: InputMaybe<Audit_Log_View_Bool_Exp>;
};


export type Subscription_RootAudit_Log_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Log_View_Order_By>>;
  where?: InputMaybe<Audit_Log_View_Bool_Exp>;
};


export type Subscription_RootAudit_Log_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Audit_Log_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Audit_Log_View_Bool_Exp>;
};


export type Subscription_RootAuth_OrganisationArgs = {
  distinct_on?: InputMaybe<Array<Auth_Organisation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Organisation_Order_By>>;
  where?: InputMaybe<Auth_Organisation_Bool_Exp>;
};


export type Subscription_RootAuth_Organisation_By_PkArgs = {
  OrgKey: Scalars['String']['input'];
};


export type Subscription_RootAuth_Organisation_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Auth_Organisation_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Organisation_Bool_Exp>;
};


export type Subscription_RootAuth_OrganisationuserArgs = {
  distinct_on?: InputMaybe<Array<Auth_Organisationuser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Organisationuser_Order_By>>;
  where?: InputMaybe<Auth_Organisationuser_Bool_Exp>;
};


export type Subscription_RootAuth_Organisationuser_By_PkArgs = {
  OrgKey: Scalars['String']['input'];
  User_Id: Scalars['String']['input'];
};


export type Subscription_RootAuth_Organisationuser_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Auth_Organisationuser_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Organisationuser_Bool_Exp>;
};


export type Subscription_RootAuth_UserArgs = {
  distinct_on?: InputMaybe<Array<Auth_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_User_Order_By>>;
  where?: InputMaybe<Auth_User_Bool_Exp>;
};


export type Subscription_RootAuth_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_User_Order_By>>;
  where?: InputMaybe<Auth_User_Bool_Exp>;
};


export type Subscription_RootAuth_User_By_PkArgs = {
  Id: Scalars['String']['input'];
};


export type Subscription_RootAuth_User_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Auth_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_User_Bool_Exp>;
};


export type Subscription_RootBusiness_AreaArgs = {
  distinct_on?: InputMaybe<Array<Business_Area_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Business_Area_Order_By>>;
  where?: InputMaybe<Business_Area_Bool_Exp>;
};


export type Subscription_RootBusiness_Area_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Business_Area_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Business_Area_Order_By>>;
  where?: InputMaybe<Business_Area_Bool_Exp>;
};


export type Subscription_RootBusiness_Area_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootBusiness_Area_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Business_Area_Stream_Cursor_Input>>;
  where?: InputMaybe<Business_Area_Bool_Exp>;
};


export type Subscription_RootCauseArgs = {
  distinct_on?: InputMaybe<Array<Cause_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Cause_Order_By>>;
  where?: InputMaybe<Cause_Bool_Exp>;
};


export type Subscription_RootCause_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cause_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Cause_Order_By>>;
  where?: InputMaybe<Cause_Bool_Exp>;
};


export type Subscription_RootCause_AuditArgs = {
  distinct_on?: InputMaybe<Array<Cause_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Cause_Audit_Order_By>>;
  where?: InputMaybe<Cause_Audit_Bool_Exp>;
};


export type Subscription_RootCause_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootCause_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Cause_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Cause_Audit_Bool_Exp>;
};


export type Subscription_RootCause_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootCause_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Cause_Stream_Cursor_Input>>;
  where?: InputMaybe<Cause_Bool_Exp>;
};


export type Subscription_RootChange_RequestArgs = {
  distinct_on?: InputMaybe<Array<Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Change_Request_Order_By>>;
  where?: InputMaybe<Change_Request_Bool_Exp>;
};


export type Subscription_RootChange_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Change_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Change_Request_Order_By>>;
  where?: InputMaybe<Change_Request_Bool_Exp>;
};


export type Subscription_RootChange_Request_AuditArgs = {
  distinct_on?: InputMaybe<Array<Change_Request_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Change_Request_Audit_Order_By>>;
  where?: InputMaybe<Change_Request_Audit_Bool_Exp>;
};


export type Subscription_RootChange_Request_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootChange_Request_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Change_Request_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Change_Request_Audit_Bool_Exp>;
};


export type Subscription_RootChange_Request_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootChange_Request_ContributorArgs = {
  distinct_on?: InputMaybe<Array<Change_Request_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Change_Request_Contributor_Order_By>>;
  where?: InputMaybe<Change_Request_Contributor_Bool_Exp>;
};


export type Subscription_RootChange_Request_Contributor_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootChange_Request_Contributor_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Change_Request_Contributor_Stream_Cursor_Input>>;
  where?: InputMaybe<Change_Request_Contributor_Bool_Exp>;
};


export type Subscription_RootChange_Request_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Change_Request_Stream_Cursor_Input>>;
  where?: InputMaybe<Change_Request_Bool_Exp>;
};


export type Subscription_RootCommentArgs = {
  distinct_on?: InputMaybe<Array<Comment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Order_By>>;
  where?: InputMaybe<Comment_Bool_Exp>;
};


export type Subscription_RootComment_AuditArgs = {
  distinct_on?: InputMaybe<Array<Comment_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Audit_Order_By>>;
  where?: InputMaybe<Comment_Audit_Bool_Exp>;
};


export type Subscription_RootComment_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootComment_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Comment_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Comment_Audit_Bool_Exp>;
};


export type Subscription_RootComment_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootComment_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Comment_Stream_Cursor_Input>>;
  where?: InputMaybe<Comment_Bool_Exp>;
};


export type Subscription_RootCompliance_Monitoring_AssessmentArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Monitoring_Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Compliance_Monitoring_Assessment_Order_By>>;
  where?: InputMaybe<Compliance_Monitoring_Assessment_Bool_Exp>;
};


export type Subscription_RootCompliance_Monitoring_Assessment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Monitoring_Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Compliance_Monitoring_Assessment_Order_By>>;
  where?: InputMaybe<Compliance_Monitoring_Assessment_Bool_Exp>;
};


export type Subscription_RootCompliance_Monitoring_Assessment_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootCompliance_Monitoring_Assessment_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Compliance_Monitoring_Assessment_Stream_Cursor_Input>>;
  where?: InputMaybe<Compliance_Monitoring_Assessment_Bool_Exp>;
};


export type Subscription_RootConsequenceArgs = {
  distinct_on?: InputMaybe<Array<Consequence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Consequence_Order_By>>;
  where?: InputMaybe<Consequence_Bool_Exp>;
};


export type Subscription_RootConsequence_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consequence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Consequence_Order_By>>;
  where?: InputMaybe<Consequence_Bool_Exp>;
};


export type Subscription_RootConsequence_AuditArgs = {
  distinct_on?: InputMaybe<Array<Consequence_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Consequence_Audit_Order_By>>;
  where?: InputMaybe<Consequence_Audit_Bool_Exp>;
};


export type Subscription_RootConsequence_Audit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consequence_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Consequence_Audit_Order_By>>;
  where?: InputMaybe<Consequence_Audit_Bool_Exp>;
};


export type Subscription_RootConsequence_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootConsequence_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Consequence_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Consequence_Audit_Bool_Exp>;
};


export type Subscription_RootConsequence_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootConsequence_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Consequence_Stream_Cursor_Input>>;
  where?: InputMaybe<Consequence_Bool_Exp>;
};


export type Subscription_RootControlArgs = {
  distinct_on?: InputMaybe<Array<Control_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Order_By>>;
  where?: InputMaybe<Control_Bool_Exp>;
};


export type Subscription_RootControl_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Order_By>>;
  where?: InputMaybe<Control_Bool_Exp>;
};


export type Subscription_RootControl_AuditArgs = {
  distinct_on?: InputMaybe<Array<Control_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Audit_Order_By>>;
  where?: InputMaybe<Control_Audit_Bool_Exp>;
};


export type Subscription_RootControl_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootControl_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Control_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Control_Audit_Bool_Exp>;
};


export type Subscription_RootControl_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootControl_GroupArgs = {
  distinct_on?: InputMaybe<Array<Control_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Group_Order_By>>;
  where?: InputMaybe<Control_Group_Bool_Exp>;
};


export type Subscription_RootControl_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Group_Order_By>>;
  where?: InputMaybe<Control_Group_Bool_Exp>;
};


export type Subscription_RootControl_Group_AuditArgs = {
  distinct_on?: InputMaybe<Array<Control_Group_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Group_Audit_Order_By>>;
  where?: InputMaybe<Control_Group_Audit_Bool_Exp>;
};


export type Subscription_RootControl_Group_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootControl_Group_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Control_Group_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Control_Group_Audit_Bool_Exp>;
};


export type Subscription_RootControl_Group_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootControl_Group_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Control_Group_Stream_Cursor_Input>>;
  where?: InputMaybe<Control_Group_Bool_Exp>;
};


export type Subscription_RootControl_ParentArgs = {
  distinct_on?: InputMaybe<Array<Control_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Parent_Order_By>>;
  where?: InputMaybe<Control_Parent_Bool_Exp>;
};


export type Subscription_RootControl_Parent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Parent_Order_By>>;
  where?: InputMaybe<Control_Parent_Bool_Exp>;
};


export type Subscription_RootControl_Parent_AuditArgs = {
  distinct_on?: InputMaybe<Array<Control_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Parent_Audit_Order_By>>;
  where?: InputMaybe<Control_Parent_Audit_Bool_Exp>;
};


export type Subscription_RootControl_Parent_Audit_By_PkArgs = {
  ControlId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Subscription_RootControl_Parent_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Control_Parent_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Control_Parent_Audit_Bool_Exp>;
};


export type Subscription_RootControl_Parent_By_PkArgs = {
  ControlId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Subscription_RootControl_Parent_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Control_Parent_Stream_Cursor_Input>>;
  where?: InputMaybe<Control_Parent_Bool_Exp>;
};


export type Subscription_RootControl_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Control_Stream_Cursor_Input>>;
  where?: InputMaybe<Control_Bool_Exp>;
};


export type Subscription_RootConversationArgs = {
  distinct_on?: InputMaybe<Array<Conversation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Conversation_Order_By>>;
  where?: InputMaybe<Conversation_Bool_Exp>;
};


export type Subscription_RootConversation_AuditArgs = {
  distinct_on?: InputMaybe<Array<Conversation_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Conversation_Audit_Order_By>>;
  where?: InputMaybe<Conversation_Audit_Bool_Exp>;
};


export type Subscription_RootConversation_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootConversation_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Conversation_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Conversation_Audit_Bool_Exp>;
};


export type Subscription_RootConversation_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootConversation_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Conversation_Stream_Cursor_Input>>;
  where?: InputMaybe<Conversation_Bool_Exp>;
};


export type Subscription_RootCustom_Attribute_SchemaArgs = {
  distinct_on?: InputMaybe<Array<Custom_Attribute_Schema_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Custom_Attribute_Schema_Order_By>>;
  where?: InputMaybe<Custom_Attribute_Schema_Bool_Exp>;
};


export type Subscription_RootCustom_Attribute_Schema_AuditArgs = {
  distinct_on?: InputMaybe<Array<Custom_Attribute_Schema_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Custom_Attribute_Schema_Audit_Order_By>>;
  where?: InputMaybe<Custom_Attribute_Schema_Audit_Bool_Exp>;
};


export type Subscription_RootCustom_Attribute_Schema_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootCustom_Attribute_Schema_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Custom_Attribute_Schema_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Custom_Attribute_Schema_Audit_Bool_Exp>;
};


export type Subscription_RootCustom_Attribute_Schema_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootCustom_Attribute_Schema_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Custom_Attribute_Schema_Stream_Cursor_Input>>;
  where?: InputMaybe<Custom_Attribute_Schema_Bool_Exp>;
};


export type Subscription_RootCustom_RibbonArgs = {
  distinct_on?: InputMaybe<Array<Custom_Ribbon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Custom_Ribbon_Order_By>>;
  where?: InputMaybe<Custom_Ribbon_Bool_Exp>;
};


export type Subscription_RootCustom_Ribbon_AuditArgs = {
  distinct_on?: InputMaybe<Array<Custom_Ribbon_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Custom_Ribbon_Audit_Order_By>>;
  where?: InputMaybe<Custom_Ribbon_Audit_Bool_Exp>;
};


export type Subscription_RootCustom_Ribbon_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootCustom_Ribbon_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Custom_Ribbon_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Custom_Ribbon_Audit_Bool_Exp>;
};


export type Subscription_RootCustom_Ribbon_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootCustom_Ribbon_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Custom_Ribbon_Stream_Cursor_Input>>;
  where?: InputMaybe<Custom_Ribbon_Bool_Exp>;
};


export type Subscription_RootDashboardArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dashboard_Order_By>>;
  where?: InputMaybe<Dashboard_Bool_Exp>;
};


export type Subscription_RootDashboard_AuditArgs = {
  distinct_on?: InputMaybe<Array<Dashboard_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dashboard_Audit_Order_By>>;
  where?: InputMaybe<Dashboard_Audit_Bool_Exp>;
};


export type Subscription_RootDashboard_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootDashboard_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Dashboard_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Dashboard_Audit_Bool_Exp>;
};


export type Subscription_RootDashboard_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootDashboard_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Dashboard_Stream_Cursor_Input>>;
  where?: InputMaybe<Dashboard_Bool_Exp>;
};


export type Subscription_RootData_ImportArgs = {
  distinct_on?: InputMaybe<Array<Data_Import_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Data_Import_Order_By>>;
  where?: InputMaybe<Data_Import_Bool_Exp>;
};


export type Subscription_RootData_Import_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Data_Import_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Data_Import_Order_By>>;
  where?: InputMaybe<Data_Import_Bool_Exp>;
};


export type Subscription_RootData_Import_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootData_Import_ErrorArgs = {
  distinct_on?: InputMaybe<Array<Data_Import_Error_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Data_Import_Error_Order_By>>;
  where?: InputMaybe<Data_Import_Error_Bool_Exp>;
};


export type Subscription_RootData_Import_Error_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Data_Import_Error_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Data_Import_Error_Order_By>>;
  where?: InputMaybe<Data_Import_Error_Bool_Exp>;
};


export type Subscription_RootData_Import_Error_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootData_Import_Error_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Data_Import_Error_Stream_Cursor_Input>>;
  where?: InputMaybe<Data_Import_Error_Bool_Exp>;
};


export type Subscription_RootData_Import_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Data_Import_Stream_Cursor_Input>>;
  where?: InputMaybe<Data_Import_Bool_Exp>;
};


export type Subscription_RootDepartment_AuditArgs = {
  distinct_on?: InputMaybe<Array<Department_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Audit_Order_By>>;
  where?: InputMaybe<Department_Audit_Bool_Exp>;
};


export type Subscription_RootDepartment_Audit_By_PkArgs = {
  DepartmentTypeId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Subscription_RootDepartment_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Department_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Department_Audit_Bool_Exp>;
};


export type Subscription_RootDepartment_TypeArgs = {
  distinct_on?: InputMaybe<Array<Department_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Type_Order_By>>;
  where?: InputMaybe<Department_Type_Bool_Exp>;
};


export type Subscription_RootDepartment_Type_AuditArgs = {
  distinct_on?: InputMaybe<Array<Department_Type_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Type_Audit_Order_By>>;
  where?: InputMaybe<Department_Type_Audit_Bool_Exp>;
};


export type Subscription_RootDepartment_Type_Audit_By_PkArgs = {
  DepartmentTypeId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootDepartment_Type_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Department_Type_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Department_Type_Audit_Bool_Exp>;
};


export type Subscription_RootDepartment_Type_By_PkArgs = {
  DepartmentTypeId: Scalars['uuid']['input'];
};


export type Subscription_RootDepartment_Type_GroupArgs = {
  distinct_on?: InputMaybe<Array<Department_Type_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Type_Group_Order_By>>;
  where?: InputMaybe<Department_Type_Group_Bool_Exp>;
};


export type Subscription_RootDepartment_Type_Group_AuditArgs = {
  distinct_on?: InputMaybe<Array<Department_Type_Group_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Type_Group_Audit_Order_By>>;
  where?: InputMaybe<Department_Type_Group_Audit_Bool_Exp>;
};


export type Subscription_RootDepartment_Type_Group_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootDepartment_Type_Group_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Department_Type_Group_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Department_Type_Group_Audit_Bool_Exp>;
};


export type Subscription_RootDepartment_Type_Group_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Department_Type_Group_Stream_Cursor_Input>>;
  where?: InputMaybe<Department_Type_Group_Bool_Exp>;
};


export type Subscription_RootDepartment_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Department_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Department_Type_Bool_Exp>;
};


export type Subscription_RootDocumentArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


export type Subscription_RootDocument_Assessment_ResultArgs = {
  distinct_on?: InputMaybe<Array<Document_Assessment_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Assessment_Result_Order_By>>;
  where?: InputMaybe<Document_Assessment_Result_Bool_Exp>;
};


export type Subscription_RootDocument_Assessment_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Assessment_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Assessment_Result_Order_By>>;
  where?: InputMaybe<Document_Assessment_Result_Bool_Exp>;
};


export type Subscription_RootDocument_Assessment_Result_AuditArgs = {
  distinct_on?: InputMaybe<Array<Document_Assessment_Result_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Assessment_Result_Audit_Order_By>>;
  where?: InputMaybe<Document_Assessment_Result_Audit_Bool_Exp>;
};


export type Subscription_RootDocument_Assessment_Result_Audit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Assessment_Result_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Assessment_Result_Audit_Order_By>>;
  where?: InputMaybe<Document_Assessment_Result_Audit_Bool_Exp>;
};


export type Subscription_RootDocument_Assessment_Result_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootDocument_Assessment_Result_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Document_Assessment_Result_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Document_Assessment_Result_Audit_Bool_Exp>;
};


export type Subscription_RootDocument_Assessment_Result_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootDocument_Assessment_Result_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Document_Assessment_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Document_Assessment_Result_Bool_Exp>;
};


export type Subscription_RootDocument_AuditArgs = {
  distinct_on?: InputMaybe<Array<Document_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Audit_Order_By>>;
  where?: InputMaybe<Document_Audit_Bool_Exp>;
};


export type Subscription_RootDocument_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootDocument_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Document_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Document_Audit_Bool_Exp>;
};


export type Subscription_RootDocument_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootDocument_FileArgs = {
  distinct_on?: InputMaybe<Array<Document_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_File_Order_By>>;
  where?: InputMaybe<Document_File_Bool_Exp>;
};


export type Subscription_RootDocument_File_AuditArgs = {
  distinct_on?: InputMaybe<Array<Document_File_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_File_Audit_Order_By>>;
  where?: InputMaybe<Document_File_Audit_Bool_Exp>;
};


export type Subscription_RootDocument_File_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootDocument_File_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Document_File_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Document_File_Audit_Bool_Exp>;
};


export type Subscription_RootDocument_File_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootDocument_File_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Document_File_Stream_Cursor_Input>>;
  where?: InputMaybe<Document_File_Bool_Exp>;
};


export type Subscription_RootDocument_Linked_DocumentArgs = {
  distinct_on?: InputMaybe<Array<Document_Linked_Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Linked_Document_Order_By>>;
  where?: InputMaybe<Document_Linked_Document_Bool_Exp>;
};


export type Subscription_RootDocument_Linked_Document_AuditArgs = {
  distinct_on?: InputMaybe<Array<Document_Linked_Document_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Document_Linked_Document_Audit_Order_By>>;
  where?: InputMaybe<Document_Linked_Document_Audit_Bool_Exp>;
};


export type Subscription_RootDocument_Linked_Document_Audit_By_PkArgs = {
  DocumentId: Scalars['uuid']['input'];
  LinkedDocumentId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootDocument_Linked_Document_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Document_Linked_Document_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Document_Linked_Document_Audit_Bool_Exp>;
};


export type Subscription_RootDocument_Linked_Document_By_PkArgs = {
  DocumentId: Scalars['uuid']['input'];
  LinkedDocumentId: Scalars['uuid']['input'];
};


export type Subscription_RootDocument_Linked_Document_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Document_Linked_Document_Stream_Cursor_Input>>;
  where?: InputMaybe<Document_Linked_Document_Bool_Exp>;
};


export type Subscription_RootDocument_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Document_Stream_Cursor_Input>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


export type Subscription_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Subscription_RootFile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Subscription_RootFile_AuditArgs = {
  distinct_on?: InputMaybe<Array<File_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<File_Audit_Order_By>>;
  where?: InputMaybe<File_Audit_Bool_Exp>;
};


export type Subscription_RootFile_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootFile_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<File_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<File_Audit_Bool_Exp>;
};


export type Subscription_RootFile_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootFile_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<File_Stream_Cursor_Input>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Subscription_RootForm_ConfigurationArgs = {
  distinct_on?: InputMaybe<Array<Form_Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Form_Configuration_Order_By>>;
  where?: InputMaybe<Form_Configuration_Bool_Exp>;
};


export type Subscription_RootForm_Configuration_AuditArgs = {
  distinct_on?: InputMaybe<Array<Form_Configuration_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Form_Configuration_Audit_Order_By>>;
  where?: InputMaybe<Form_Configuration_Audit_Bool_Exp>;
};


export type Subscription_RootForm_Configuration_Audit_By_PkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentType: Scalars['String']['input'];
};


export type Subscription_RootForm_Configuration_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Form_Configuration_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Form_Configuration_Audit_Bool_Exp>;
};


export type Subscription_RootForm_Configuration_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Form_Configuration_Stream_Cursor_Input>>;
  where?: InputMaybe<Form_Configuration_Bool_Exp>;
};


export type Subscription_RootForm_Field_ConfigurationArgs = {
  distinct_on?: InputMaybe<Array<Form_Field_Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Form_Field_Configuration_Order_By>>;
  where?: InputMaybe<Form_Field_Configuration_Bool_Exp>;
};


export type Subscription_RootForm_Field_Configuration_AuditArgs = {
  distinct_on?: InputMaybe<Array<Form_Field_Configuration_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Form_Field_Configuration_Audit_Order_By>>;
  where?: InputMaybe<Form_Field_Configuration_Audit_Bool_Exp>;
};


export type Subscription_RootForm_Field_Configuration_Audit_By_PkArgs = {
  FieldId: Scalars['String']['input'];
  FormConfigurationParentType: Scalars['String']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootForm_Field_Configuration_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Form_Field_Configuration_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Form_Field_Configuration_Audit_Bool_Exp>;
};


export type Subscription_RootForm_Field_Configuration_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Form_Field_Configuration_Stream_Cursor_Input>>;
  where?: InputMaybe<Form_Field_Configuration_Bool_Exp>;
};


export type Subscription_RootForm_Field_OrderingArgs = {
  distinct_on?: InputMaybe<Array<Form_Field_Ordering_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Form_Field_Ordering_Order_By>>;
  where?: InputMaybe<Form_Field_Ordering_Bool_Exp>;
};


export type Subscription_RootForm_Field_Ordering_AuditArgs = {
  distinct_on?: InputMaybe<Array<Form_Field_Ordering_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Form_Field_Ordering_Audit_Order_By>>;
  where?: InputMaybe<Form_Field_Ordering_Audit_Bool_Exp>;
};


export type Subscription_RootForm_Field_Ordering_Audit_By_PkArgs = {
  FieldId: Scalars['String']['input'];
  FormConfigurationParentType: Scalars['String']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootForm_Field_Ordering_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Form_Field_Ordering_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Form_Field_Ordering_Audit_Bool_Exp>;
};


export type Subscription_RootForm_Field_Ordering_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Form_Field_Ordering_Stream_Cursor_Input>>;
  where?: InputMaybe<Form_Field_Ordering_Bool_Exp>;
};


export type Subscription_RootImpactArgs = {
  distinct_on?: InputMaybe<Array<Impact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Order_By>>;
  where?: InputMaybe<Impact_Bool_Exp>;
};


export type Subscription_RootImpact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Impact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Order_By>>;
  where?: InputMaybe<Impact_Bool_Exp>;
};


export type Subscription_RootImpact_AuditArgs = {
  distinct_on?: InputMaybe<Array<Impact_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Audit_Order_By>>;
  where?: InputMaybe<Impact_Audit_Bool_Exp>;
};


export type Subscription_RootImpact_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootImpact_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Impact_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Impact_Audit_Bool_Exp>;
};


export type Subscription_RootImpact_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootImpact_ParentArgs = {
  distinct_on?: InputMaybe<Array<Impact_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Parent_Order_By>>;
  where?: InputMaybe<Impact_Parent_Bool_Exp>;
};


export type Subscription_RootImpact_Parent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Impact_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Parent_Order_By>>;
  where?: InputMaybe<Impact_Parent_Bool_Exp>;
};


export type Subscription_RootImpact_Parent_AuditArgs = {
  distinct_on?: InputMaybe<Array<Impact_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Parent_Audit_Order_By>>;
  where?: InputMaybe<Impact_Parent_Audit_Bool_Exp>;
};


export type Subscription_RootImpact_Parent_Audit_By_PkArgs = {
  ImpactId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Subscription_RootImpact_Parent_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Impact_Parent_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Impact_Parent_Audit_Bool_Exp>;
};


export type Subscription_RootImpact_Parent_By_PkArgs = {
  ImpactId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Subscription_RootImpact_Parent_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Impact_Parent_Stream_Cursor_Input>>;
  where?: InputMaybe<Impact_Parent_Bool_Exp>;
};


export type Subscription_RootImpact_RatingArgs = {
  distinct_on?: InputMaybe<Array<Impact_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Rating_Order_By>>;
  where?: InputMaybe<Impact_Rating_Bool_Exp>;
};


export type Subscription_RootImpact_Rating_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Impact_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Rating_Order_By>>;
  where?: InputMaybe<Impact_Rating_Bool_Exp>;
};


export type Subscription_RootImpact_Rating_AuditArgs = {
  distinct_on?: InputMaybe<Array<Impact_Rating_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Impact_Rating_Audit_Order_By>>;
  where?: InputMaybe<Impact_Rating_Audit_Bool_Exp>;
};


export type Subscription_RootImpact_Rating_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootImpact_Rating_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Impact_Rating_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Impact_Rating_Audit_Bool_Exp>;
};


export type Subscription_RootImpact_Rating_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootImpact_Rating_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Impact_Rating_Stream_Cursor_Input>>;
  where?: InputMaybe<Impact_Rating_Bool_Exp>;
};


export type Subscription_RootImpact_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Impact_Stream_Cursor_Input>>;
  where?: InputMaybe<Impact_Bool_Exp>;
};


export type Subscription_RootIndicatorArgs = {
  distinct_on?: InputMaybe<Array<Indicator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Indicator_Order_By>>;
  where?: InputMaybe<Indicator_Bool_Exp>;
};


export type Subscription_RootIndicator_AuditArgs = {
  distinct_on?: InputMaybe<Array<Indicator_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Indicator_Audit_Order_By>>;
  where?: InputMaybe<Indicator_Audit_Bool_Exp>;
};


export type Subscription_RootIndicator_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootIndicator_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Indicator_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Indicator_Audit_Bool_Exp>;
};


export type Subscription_RootIndicator_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootIndicator_ParentArgs = {
  distinct_on?: InputMaybe<Array<Indicator_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Indicator_Parent_Order_By>>;
  where?: InputMaybe<Indicator_Parent_Bool_Exp>;
};


export type Subscription_RootIndicator_Parent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Indicator_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Indicator_Parent_Order_By>>;
  where?: InputMaybe<Indicator_Parent_Bool_Exp>;
};


export type Subscription_RootIndicator_Parent_By_PkArgs = {
  IndicatorId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Subscription_RootIndicator_Parent_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Indicator_Parent_Stream_Cursor_Input>>;
  where?: InputMaybe<Indicator_Parent_Bool_Exp>;
};


export type Subscription_RootIndicator_ResultArgs = {
  distinct_on?: InputMaybe<Array<Indicator_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Indicator_Result_Order_By>>;
  where?: InputMaybe<Indicator_Result_Bool_Exp>;
};


export type Subscription_RootIndicator_Result_AuditArgs = {
  distinct_on?: InputMaybe<Array<Indicator_Result_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Indicator_Result_Audit_Order_By>>;
  where?: InputMaybe<Indicator_Result_Audit_Bool_Exp>;
};


export type Subscription_RootIndicator_Result_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootIndicator_Result_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Indicator_Result_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Indicator_Result_Audit_Bool_Exp>;
};


export type Subscription_RootIndicator_Result_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootIndicator_Result_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Indicator_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Indicator_Result_Bool_Exp>;
};


export type Subscription_RootIndicator_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Indicator_Stream_Cursor_Input>>;
  where?: InputMaybe<Indicator_Bool_Exp>;
};


export type Subscription_RootIndicator_TypeArgs = {
  distinct_on?: InputMaybe<Array<Indicator_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Indicator_Type_Order_By>>;
  where?: InputMaybe<Indicator_Type_Bool_Exp>;
};


export type Subscription_RootIndicator_Type_By_PkArgs = {
  Value: Scalars['String']['input'];
};


export type Subscription_RootIndicator_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Indicator_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Indicator_Type_Bool_Exp>;
};


export type Subscription_RootInternal_Audit_EntityArgs = {
  distinct_on?: InputMaybe<Array<Internal_Audit_Entity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Internal_Audit_Entity_Order_By>>;
  where?: InputMaybe<Internal_Audit_Entity_Bool_Exp>;
};


export type Subscription_RootInternal_Audit_Entity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Internal_Audit_Entity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Internal_Audit_Entity_Order_By>>;
  where?: InputMaybe<Internal_Audit_Entity_Bool_Exp>;
};


export type Subscription_RootInternal_Audit_Entity_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootInternal_Audit_Entity_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Internal_Audit_Entity_Stream_Cursor_Input>>;
  where?: InputMaybe<Internal_Audit_Entity_Bool_Exp>;
};


export type Subscription_RootInternal_Audit_ReportArgs = {
  distinct_on?: InputMaybe<Array<Internal_Audit_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Internal_Audit_Report_Order_By>>;
  where?: InputMaybe<Internal_Audit_Report_Bool_Exp>;
};


export type Subscription_RootInternal_Audit_Report_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Internal_Audit_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Internal_Audit_Report_Order_By>>;
  where?: InputMaybe<Internal_Audit_Report_Bool_Exp>;
};


export type Subscription_RootInternal_Audit_Report_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootInternal_Audit_Report_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Internal_Audit_Report_Stream_Cursor_Input>>;
  where?: InputMaybe<Internal_Audit_Report_Bool_Exp>;
};


export type Subscription_RootIssueArgs = {
  distinct_on?: InputMaybe<Array<Issue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Order_By>>;
  where?: InputMaybe<Issue_Bool_Exp>;
};


export type Subscription_RootIssue_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Issue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Order_By>>;
  where?: InputMaybe<Issue_Bool_Exp>;
};


export type Subscription_RootIssue_AssessmentArgs = {
  distinct_on?: InputMaybe<Array<Issue_Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Assessment_Order_By>>;
  where?: InputMaybe<Issue_Assessment_Bool_Exp>;
};


export type Subscription_RootIssue_Assessment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Issue_Assessment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Assessment_Order_By>>;
  where?: InputMaybe<Issue_Assessment_Bool_Exp>;
};


export type Subscription_RootIssue_Assessment_AuditArgs = {
  distinct_on?: InputMaybe<Array<Issue_Assessment_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Assessment_Audit_Order_By>>;
  where?: InputMaybe<Issue_Assessment_Audit_Bool_Exp>;
};


export type Subscription_RootIssue_Assessment_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootIssue_Assessment_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Issue_Assessment_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Issue_Assessment_Audit_Bool_Exp>;
};


export type Subscription_RootIssue_Assessment_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootIssue_Assessment_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Issue_Assessment_Stream_Cursor_Input>>;
  where?: InputMaybe<Issue_Assessment_Bool_Exp>;
};


export type Subscription_RootIssue_AuditArgs = {
  distinct_on?: InputMaybe<Array<Issue_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Audit_Order_By>>;
  where?: InputMaybe<Issue_Audit_Bool_Exp>;
};


export type Subscription_RootIssue_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootIssue_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Issue_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Issue_Audit_Bool_Exp>;
};


export type Subscription_RootIssue_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootIssue_ParentArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Order_By>>;
  where?: InputMaybe<Issue_Parent_Bool_Exp>;
};


export type Subscription_RootIssue_Parent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Order_By>>;
  where?: InputMaybe<Issue_Parent_Bool_Exp>;
};


export type Subscription_RootIssue_Parent_AuditArgs = {
  distinct_on?: InputMaybe<Array<Issue_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Parent_Audit_Order_By>>;
  where?: InputMaybe<Issue_Parent_Audit_Bool_Exp>;
};


export type Subscription_RootIssue_Parent_Audit_By_PkArgs = {
  IssueId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Subscription_RootIssue_Parent_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Issue_Parent_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Issue_Parent_Audit_Bool_Exp>;
};


export type Subscription_RootIssue_Parent_By_PkArgs = {
  IssueId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Subscription_RootIssue_Parent_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Issue_Parent_Stream_Cursor_Input>>;
  where?: InputMaybe<Issue_Parent_Bool_Exp>;
};


export type Subscription_RootIssue_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Issue_Stream_Cursor_Input>>;
  where?: InputMaybe<Issue_Bool_Exp>;
};


export type Subscription_RootIssue_UpdateArgs = {
  distinct_on?: InputMaybe<Array<Issue_Update_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Update_Order_By>>;
  where?: InputMaybe<Issue_Update_Bool_Exp>;
};


export type Subscription_RootIssue_Update_AuditArgs = {
  distinct_on?: InputMaybe<Array<Issue_Update_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Issue_Update_Audit_Order_By>>;
  where?: InputMaybe<Issue_Update_Audit_Bool_Exp>;
};


export type Subscription_RootIssue_Update_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootIssue_Update_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Issue_Update_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Issue_Update_Audit_Bool_Exp>;
};


export type Subscription_RootIssue_Update_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootIssue_Update_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Issue_Update_Stream_Cursor_Input>>;
  where?: InputMaybe<Issue_Update_Bool_Exp>;
};


export type Subscription_RootLinked_ItemArgs = {
  distinct_on?: InputMaybe<Array<Linked_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Linked_Item_Order_By>>;
  where?: InputMaybe<Linked_Item_Bool_Exp>;
};


export type Subscription_RootLinked_Item_By_PkArgs = {
  Source: Scalars['uuid']['input'];
  Target: Scalars['uuid']['input'];
};


export type Subscription_RootLinked_Item_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Linked_Item_Stream_Cursor_Input>>;
  where?: InputMaybe<Linked_Item_Bool_Exp>;
};


export type Subscription_RootNodeArgs = {
  distinct_on?: InputMaybe<Array<Node_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Node_Order_By>>;
  where?: InputMaybe<Node_Bool_Exp>;
};


export type Subscription_RootNode_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Node_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Node_Order_By>>;
  where?: InputMaybe<Node_Bool_Exp>;
};


export type Subscription_RootNode_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootNode_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Node_Stream_Cursor_Input>>;
  where?: InputMaybe<Node_Bool_Exp>;
};


export type Subscription_RootObligationArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Order_By>>;
  where?: InputMaybe<Obligation_Bool_Exp>;
};


export type Subscription_RootObligation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Order_By>>;
  where?: InputMaybe<Obligation_Bool_Exp>;
};


export type Subscription_RootObligation_Assessment_ResultArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Assessment_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Assessment_Result_Order_By>>;
  where?: InputMaybe<Obligation_Assessment_Result_Bool_Exp>;
};


export type Subscription_RootObligation_Assessment_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Assessment_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Assessment_Result_Order_By>>;
  where?: InputMaybe<Obligation_Assessment_Result_Bool_Exp>;
};


export type Subscription_RootObligation_Assessment_Result_AuditArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Assessment_Result_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Assessment_Result_Audit_Order_By>>;
  where?: InputMaybe<Obligation_Assessment_Result_Audit_Bool_Exp>;
};


export type Subscription_RootObligation_Assessment_Result_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootObligation_Assessment_Result_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Obligation_Assessment_Result_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Obligation_Assessment_Result_Audit_Bool_Exp>;
};


export type Subscription_RootObligation_Assessment_Result_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootObligation_Assessment_Result_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Obligation_Assessment_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Obligation_Assessment_Result_Bool_Exp>;
};


export type Subscription_RootObligation_Assessment_StatusArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Assessment_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Assessment_Status_Order_By>>;
  where?: InputMaybe<Obligation_Assessment_Status_Bool_Exp>;
};


export type Subscription_RootObligation_Assessment_Status_By_PkArgs = {
  Value: Scalars['String']['input'];
};


export type Subscription_RootObligation_Assessment_Status_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Obligation_Assessment_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Obligation_Assessment_Status_Bool_Exp>;
};


export type Subscription_RootObligation_AuditArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Audit_Order_By>>;
  where?: InputMaybe<Obligation_Audit_Bool_Exp>;
};


export type Subscription_RootObligation_Audit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Audit_Order_By>>;
  where?: InputMaybe<Obligation_Audit_Bool_Exp>;
};


export type Subscription_RootObligation_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootObligation_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Obligation_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Obligation_Audit_Bool_Exp>;
};


export type Subscription_RootObligation_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootObligation_ImpactArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Impact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Impact_Order_By>>;
  where?: InputMaybe<Obligation_Impact_Bool_Exp>;
};


export type Subscription_RootObligation_Impact_AuditArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Impact_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Impact_Audit_Order_By>>;
  where?: InputMaybe<Obligation_Impact_Audit_Bool_Exp>;
};


export type Subscription_RootObligation_Impact_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootObligation_Impact_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Obligation_Impact_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Obligation_Impact_Audit_Bool_Exp>;
};


export type Subscription_RootObligation_Impact_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootObligation_Impact_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Obligation_Impact_Stream_Cursor_Input>>;
  where?: InputMaybe<Obligation_Impact_Bool_Exp>;
};


export type Subscription_RootObligation_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Obligation_Stream_Cursor_Input>>;
  where?: InputMaybe<Obligation_Bool_Exp>;
};


export type Subscription_RootObligation_TypeArgs = {
  distinct_on?: InputMaybe<Array<Obligation_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Obligation_Type_Order_By>>;
  where?: InputMaybe<Obligation_Type_Bool_Exp>;
};


export type Subscription_RootObligation_Type_By_PkArgs = {
  Value: Scalars['String']['input'];
};


export type Subscription_RootObligation_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Obligation_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Obligation_Type_Bool_Exp>;
};


export type Subscription_RootOwner_AuditArgs = {
  distinct_on?: InputMaybe<Array<Owner_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Audit_Order_By>>;
  where?: InputMaybe<Owner_Audit_Bool_Exp>;
};


export type Subscription_RootOwner_Audit_By_PkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


export type Subscription_RootOwner_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Owner_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Owner_Audit_Bool_Exp>;
};


export type Subscription_RootOwner_Group_AuditArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Audit_Order_By>>;
  where?: InputMaybe<Owner_Group_Audit_Bool_Exp>;
};


export type Subscription_RootOwner_Group_Audit_By_PkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  UserGroupId: Scalars['uuid']['input'];
};


export type Subscription_RootOwner_Group_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Owner_Group_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Owner_Group_Audit_Bool_Exp>;
};


export type Subscription_RootQuestionnaire_InviteArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Invite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Invite_Order_By>>;
  where?: InputMaybe<Questionnaire_Invite_Bool_Exp>;
};


export type Subscription_RootQuestionnaire_Invite_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootQuestionnaire_Invite_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Questionnaire_Invite_Stream_Cursor_Input>>;
  where?: InputMaybe<Questionnaire_Invite_Bool_Exp>;
};


export type Subscription_RootQuestionnaire_TemplateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Template_Order_By>>;
  where?: InputMaybe<Questionnaire_Template_Bool_Exp>;
};


export type Subscription_RootQuestionnaire_Template_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootQuestionnaire_Template_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Questionnaire_Template_Stream_Cursor_Input>>;
  where?: InputMaybe<Questionnaire_Template_Bool_Exp>;
};


export type Subscription_RootQuestionnaire_Template_VersionArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Template_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Template_Version_Order_By>>;
  where?: InputMaybe<Questionnaire_Template_Version_Bool_Exp>;
};


export type Subscription_RootQuestionnaire_Template_Version_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootQuestionnaire_Template_Version_StatusArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Template_Version_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Template_Version_Status_Order_By>>;
  where?: InputMaybe<Questionnaire_Template_Version_Status_Bool_Exp>;
};


export type Subscription_RootQuestionnaire_Template_Version_Status_By_PkArgs = {
  Value: Scalars['String']['input'];
};


export type Subscription_RootQuestionnaire_Template_Version_Status_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Questionnaire_Template_Version_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Questionnaire_Template_Version_Status_Bool_Exp>;
};


export type Subscription_RootQuestionnaire_Template_Version_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Questionnaire_Template_Version_Stream_Cursor_Input>>;
  where?: InputMaybe<Questionnaire_Template_Version_Bool_Exp>;
};


export type Subscription_RootRelation_FileArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


export type Subscription_RootRelation_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


export type Subscription_RootRelation_File_AuditArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Audit_Order_By>>;
  where?: InputMaybe<Relation_File_Audit_Bool_Exp>;
};


export type Subscription_RootRelation_File_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Relation_File_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Relation_File_Audit_Bool_Exp>;
};


export type Subscription_RootRelation_File_By_PkArgs = {
  FileId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Subscription_RootRelation_File_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Relation_File_Stream_Cursor_Input>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


export type Subscription_RootRiskArgs = {
  distinct_on?: InputMaybe<Array<Risk_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Order_By>>;
  where?: InputMaybe<Risk_Bool_Exp>;
};


export type Subscription_RootRisk_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Order_By>>;
  where?: InputMaybe<Risk_Bool_Exp>;
};


export type Subscription_RootRisk_Assessment_ResultArgs = {
  distinct_on?: InputMaybe<Array<Risk_Assessment_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Assessment_Result_Order_By>>;
  where?: InputMaybe<Risk_Assessment_Result_Bool_Exp>;
};


export type Subscription_RootRisk_Assessment_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Assessment_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Assessment_Result_Order_By>>;
  where?: InputMaybe<Risk_Assessment_Result_Bool_Exp>;
};


export type Subscription_RootRisk_Assessment_Result_AuditArgs = {
  distinct_on?: InputMaybe<Array<Risk_Assessment_Result_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Assessment_Result_Audit_Order_By>>;
  where?: InputMaybe<Risk_Assessment_Result_Audit_Bool_Exp>;
};


export type Subscription_RootRisk_Assessment_Result_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootRisk_Assessment_Result_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risk_Assessment_Result_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Risk_Assessment_Result_Audit_Bool_Exp>;
};


export type Subscription_RootRisk_Assessment_Result_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootRisk_Assessment_Result_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risk_Assessment_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Risk_Assessment_Result_Bool_Exp>;
};


export type Subscription_RootRisk_AuditArgs = {
  distinct_on?: InputMaybe<Array<Risk_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Audit_Order_By>>;
  where?: InputMaybe<Risk_Audit_Bool_Exp>;
};


export type Subscription_RootRisk_Audit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Audit_Order_By>>;
  where?: InputMaybe<Risk_Audit_Bool_Exp>;
};


export type Subscription_RootRisk_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootRisk_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risk_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Risk_Audit_Bool_Exp>;
};


export type Subscription_RootRisk_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootRisk_ScoreArgs = {
  distinct_on?: InputMaybe<Array<Risk_Score_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Score_Order_By>>;
  where?: InputMaybe<Risk_Score_Bool_Exp>;
};


export type Subscription_RootRisk_Score_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Score_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Score_Order_By>>;
  where?: InputMaybe<Risk_Score_Bool_Exp>;
};


export type Subscription_RootRisk_Score_By_PkArgs = {
  RiskId: Scalars['uuid']['input'];
};


export type Subscription_RootRisk_Score_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risk_Score_Stream_Cursor_Input>>;
  where?: InputMaybe<Risk_Score_Bool_Exp>;
};


export type Subscription_RootRisk_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risk_Stream_Cursor_Input>>;
  where?: InputMaybe<Risk_Bool_Exp>;
};


export type Subscription_RootRisksmart_Indicator_Parent_AuditArgs = {
  distinct_on?: InputMaybe<Array<Risksmart_Indicator_Parent_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risksmart_Indicator_Parent_Audit_Order_By>>;
  where?: InputMaybe<Risksmart_Indicator_Parent_Audit_Bool_Exp>;
};


export type Subscription_RootRisksmart_Indicator_Parent_Audit_By_PkArgs = {
  IndicatorId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type Subscription_RootRisksmart_Indicator_Parent_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risksmart_Indicator_Parent_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Risksmart_Indicator_Parent_Audit_Bool_Exp>;
};


export type Subscription_RootRisksmart_Linked_Item_AuditArgs = {
  distinct_on?: InputMaybe<Array<Risksmart_Linked_Item_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risksmart_Linked_Item_Audit_Order_By>>;
  where?: InputMaybe<Risksmart_Linked_Item_Audit_Bool_Exp>;
};


export type Subscription_RootRisksmart_Linked_Item_Audit_By_PkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  Source: Scalars['uuid']['input'];
  Target: Scalars['uuid']['input'];
};


export type Subscription_RootRisksmart_Linked_Item_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risksmart_Linked_Item_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Risksmart_Linked_Item_Audit_Bool_Exp>;
};


export type Subscription_RootRole_AccessArgs = {
  distinct_on?: InputMaybe<Array<Role_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Access_Order_By>>;
  where?: InputMaybe<Role_Access_Bool_Exp>;
};


export type Subscription_RootRole_Access_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Role_Access_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Access_Bool_Exp>;
};


export type Subscription_RootTag_TypeArgs = {
  distinct_on?: InputMaybe<Array<Tag_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tag_Type_Order_By>>;
  where?: InputMaybe<Tag_Type_Bool_Exp>;
};


export type Subscription_RootTag_Type_AuditArgs = {
  distinct_on?: InputMaybe<Array<Tag_Type_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tag_Type_Audit_Order_By>>;
  where?: InputMaybe<Tag_Type_Audit_Bool_Exp>;
};


export type Subscription_RootTag_Type_Audit_By_PkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  TagTypeId: Scalars['uuid']['input'];
};


export type Subscription_RootTag_Type_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tag_Type_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Tag_Type_Audit_Bool_Exp>;
};


export type Subscription_RootTag_Type_By_PkArgs = {
  TagTypeId: Scalars['uuid']['input'];
};


export type Subscription_RootTag_Type_GroupArgs = {
  distinct_on?: InputMaybe<Array<Tag_Type_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tag_Type_Group_Order_By>>;
  where?: InputMaybe<Tag_Type_Group_Bool_Exp>;
};


export type Subscription_RootTag_Type_Group_AuditArgs = {
  distinct_on?: InputMaybe<Array<Tag_Type_Group_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tag_Type_Group_Audit_Order_By>>;
  where?: InputMaybe<Tag_Type_Group_Audit_Bool_Exp>;
};


export type Subscription_RootTag_Type_Group_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootTag_Type_Group_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tag_Type_Group_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Tag_Type_Group_Audit_Bool_Exp>;
};


export type Subscription_RootTag_Type_Group_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tag_Type_Group_Stream_Cursor_Input>>;
  where?: InputMaybe<Tag_Type_Group_Bool_Exp>;
};


export type Subscription_RootTag_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tag_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Tag_Type_Bool_Exp>;
};


export type Subscription_RootTaxonomyArgs = {
  distinct_on?: InputMaybe<Array<Taxonomy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taxonomy_Order_By>>;
  where?: InputMaybe<Taxonomy_Bool_Exp>;
};


export type Subscription_RootTaxonomy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Taxonomy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taxonomy_Order_By>>;
  where?: InputMaybe<Taxonomy_Bool_Exp>;
};


export type Subscription_RootTaxonomy_AuditArgs = {
  distinct_on?: InputMaybe<Array<Taxonomy_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taxonomy_Audit_Order_By>>;
  where?: InputMaybe<Taxonomy_Audit_Bool_Exp>;
};


export type Subscription_RootTaxonomy_Audit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Taxonomy_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taxonomy_Audit_Order_By>>;
  where?: InputMaybe<Taxonomy_Audit_Bool_Exp>;
};


export type Subscription_RootTaxonomy_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootTaxonomy_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Taxonomy_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Taxonomy_Audit_Bool_Exp>;
};


export type Subscription_RootTaxonomy_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootTaxonomy_OrgArgs = {
  distinct_on?: InputMaybe<Array<Taxonomy_Org_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taxonomy_Org_Order_By>>;
  where?: InputMaybe<Taxonomy_Org_Bool_Exp>;
};


export type Subscription_RootTaxonomy_Org_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Taxonomy_Org_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taxonomy_Org_Order_By>>;
  where?: InputMaybe<Taxonomy_Org_Bool_Exp>;
};


export type Subscription_RootTaxonomy_Org_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootTaxonomy_Org_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Taxonomy_Org_Stream_Cursor_Input>>;
  where?: InputMaybe<Taxonomy_Org_Bool_Exp>;
};


export type Subscription_RootTaxonomy_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Taxonomy_Stream_Cursor_Input>>;
  where?: InputMaybe<Taxonomy_Bool_Exp>;
};


export type Subscription_RootTest_ResultArgs = {
  distinct_on?: InputMaybe<Array<Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Test_Result_Order_By>>;
  where?: InputMaybe<Test_Result_Bool_Exp>;
};


export type Subscription_RootTest_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Test_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Test_Result_Order_By>>;
  where?: InputMaybe<Test_Result_Bool_Exp>;
};


export type Subscription_RootTest_Result_AuditArgs = {
  distinct_on?: InputMaybe<Array<Test_Result_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Test_Result_Audit_Order_By>>;
  where?: InputMaybe<Test_Result_Audit_Bool_Exp>;
};


export type Subscription_RootTest_Result_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootTest_Result_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Test_Result_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Test_Result_Audit_Bool_Exp>;
};


export type Subscription_RootTest_Result_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootTest_Result_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Test_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Test_Result_Bool_Exp>;
};


export type Subscription_RootThird_PartyArgs = {
  distinct_on?: InputMaybe<Array<Third_Party_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Third_Party_Order_By>>;
  where?: InputMaybe<Third_Party_Bool_Exp>;
};


export type Subscription_RootThird_Party_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootThird_Party_ResponseArgs = {
  distinct_on?: InputMaybe<Array<Third_Party_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Third_Party_Response_Order_By>>;
  where?: InputMaybe<Third_Party_Response_Bool_Exp>;
};


export type Subscription_RootThird_Party_Response_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootThird_Party_Response_StatusArgs = {
  distinct_on?: InputMaybe<Array<Third_Party_Response_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Third_Party_Response_Status_Order_By>>;
  where?: InputMaybe<Third_Party_Response_Status_Bool_Exp>;
};


export type Subscription_RootThird_Party_Response_Status_By_PkArgs = {
  Value: Scalars['String']['input'];
};


export type Subscription_RootThird_Party_Response_Status_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Third_Party_Response_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Third_Party_Response_Status_Bool_Exp>;
};


export type Subscription_RootThird_Party_Response_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Third_Party_Response_Stream_Cursor_Input>>;
  where?: InputMaybe<Third_Party_Response_Bool_Exp>;
};


export type Subscription_RootThird_Party_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Third_Party_Stream_Cursor_Input>>;
  where?: InputMaybe<Third_Party_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_GroupArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Group_Order_By>>;
  where?: InputMaybe<User_Group_Bool_Exp>;
};


export type Subscription_RootUser_Group_AuditArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Group_Audit_Order_By>>;
  where?: InputMaybe<User_Group_Audit_Bool_Exp>;
};


export type Subscription_RootUser_Group_Audit_By_PkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type Subscription_RootUser_Group_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Group_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Group_Audit_Bool_Exp>;
};


export type Subscription_RootUser_Group_By_PkArgs = {
  Id: Scalars['uuid']['input'];
};


export type Subscription_RootUser_Group_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Group_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Group_Bool_Exp>;
};


export type Subscription_RootUser_Group_UserArgs = {
  distinct_on?: InputMaybe<Array<User_Group_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Group_User_Order_By>>;
  where?: InputMaybe<User_Group_User_Bool_Exp>;
};


export type Subscription_RootUser_Group_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Group_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Group_User_Order_By>>;
  where?: InputMaybe<User_Group_User_Bool_Exp>;
};


export type Subscription_RootUser_Group_User_AuditArgs = {
  distinct_on?: InputMaybe<Array<User_Group_User_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Group_User_Audit_Order_By>>;
  where?: InputMaybe<User_Group_User_Audit_Bool_Exp>;
};


export type Subscription_RootUser_Group_User_Audit_By_PkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  UserGroupId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


export type Subscription_RootUser_Group_User_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Group_User_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Group_User_Audit_Bool_Exp>;
};


export type Subscription_RootUser_Group_User_By_PkArgs = {
  UserGroupId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


export type Subscription_RootUser_Group_User_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Group_User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Group_User_Bool_Exp>;
};


export type Subscription_RootUser_Search_PreferencesArgs = {
  distinct_on?: InputMaybe<Array<User_Search_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Search_Preferences_Order_By>>;
  where?: InputMaybe<User_Search_Preferences_Bool_Exp>;
};


export type Subscription_RootUser_Search_Preferences_AuditArgs = {
  distinct_on?: InputMaybe<Array<User_Search_Preferences_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Search_Preferences_Audit_Order_By>>;
  where?: InputMaybe<User_Search_Preferences_Audit_Bool_Exp>;
};


export type Subscription_RootUser_Search_Preferences_Audit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Search_Preferences_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Search_Preferences_Audit_Bool_Exp>;
};


export type Subscription_RootUser_Search_Preferences_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Search_Preferences_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Search_Preferences_Bool_Exp>;
};


export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_Table_PreferencesArgs = {
  distinct_on?: InputMaybe<Array<User_Table_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Table_Preferences_Order_By>>;
  where?: InputMaybe<User_Table_Preferences_Bool_Exp>;
};


export type Subscription_RootUser_Table_Preferences_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Table_Preferences_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Table_Preferences_Bool_Exp>;
};

/** columns and relationships of "risksmart.tag" */
export type Tag = {
  __typename?: 'tag';
  ParentId: Scalars['uuid']['output'];
  TagTypeId: Scalars['uuid']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parent?: Maybe<Node>;
  /** An object relationship */
  parentAction?: Maybe<Action>;
  /** An object relationship */
  parentAssessment?: Maybe<Assessment>;
  /** An object relationship */
  parentIssue?: Maybe<Issue>;
  /** An object relationship */
  parentThirdParty?: Maybe<Third_Party>;
  /** An object relationship */
  type?: Maybe<Tag_Type>;
};

/** order by aggregate values of table "risksmart.tag" */
export type Tag_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tag_Max_Order_By>;
  min?: InputMaybe<Tag_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.tag" */
export type Tag_Arr_Rel_Insert_Input = {
  data: Array<Tag_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tag_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risksmart.tag". All fields are combined with a logical 'AND'. */
export type Tag_Bool_Exp = {
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  TagTypeId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Tag_Bool_Exp>>;
  _not?: InputMaybe<Tag_Bool_Exp>;
  _or?: InputMaybe<Array<Tag_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parent?: InputMaybe<Node_Bool_Exp>;
  parentAction?: InputMaybe<Action_Bool_Exp>;
  parentAssessment?: InputMaybe<Assessment_Bool_Exp>;
  parentIssue?: InputMaybe<Issue_Bool_Exp>;
  parentThirdParty?: InputMaybe<Third_Party_Bool_Exp>;
  type?: InputMaybe<Tag_Type_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.tag" */
export const Tag_Constraint = {
  /** unique or primary key constraint on columns "TagTypeId", "ParentId" */
  TagPkey: 'tag_pkey'
} as const;

export type Tag_Constraint = typeof Tag_Constraint[keyof typeof Tag_Constraint];
/** input type for inserting data into table "risksmart.tag" */
export type Tag_Insert_Input = {
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  TagTypeId?: InputMaybe<Scalars['uuid']['input']>;
  parentAction?: InputMaybe<Action_Obj_Rel_Insert_Input>;
  parentAssessment?: InputMaybe<Assessment_Obj_Rel_Insert_Input>;
  parentIssue?: InputMaybe<Issue_Obj_Rel_Insert_Input>;
  parentThirdParty?: InputMaybe<Third_Party_Obj_Rel_Insert_Input>;
  type?: InputMaybe<Tag_Type_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "risksmart.tag" */
export type Tag_Max_Order_By = {
  ParentId?: InputMaybe<Order_By>;
  TagTypeId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.tag" */
export type Tag_Min_Order_By = {
  ParentId?: InputMaybe<Order_By>;
  TagTypeId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.tag" */
export type Tag_Mutation_Response = {
  __typename?: 'tag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tag>;
};

/** on_conflict condition type for table "risksmart.tag" */
export type Tag_On_Conflict = {
  constraint: Tag_Constraint;
  update_columns?: Array<Tag_Update_Column>;
  where?: InputMaybe<Tag_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.tag". */
export type Tag_Order_By = {
  ParentId?: InputMaybe<Order_By>;
  TagTypeId?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parent?: InputMaybe<Node_Order_By>;
  parentAction?: InputMaybe<Action_Order_By>;
  parentAssessment?: InputMaybe<Assessment_Order_By>;
  parentIssue?: InputMaybe<Issue_Order_By>;
  parentThirdParty?: InputMaybe<Third_Party_Order_By>;
  type?: InputMaybe<Tag_Type_Order_By>;
};

/** select columns of table "risksmart.tag" */
export const Tag_Select_Column = {
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  TagTypeId: 'TagTypeId'
} as const;

export type Tag_Select_Column = typeof Tag_Select_Column[keyof typeof Tag_Select_Column];
/** columns and relationships of "risksmart.tag_type" */
export type Tag_Type = {
  __typename?: 'tag_type';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  TagTypeGroupId?: Maybe<Scalars['uuid']['output']>;
  TagTypeId: Scalars['uuid']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  tag_type_group?: Maybe<Tag_Type_Group>;
};

/** order by aggregate values of table "risksmart.tag_type" */
export type Tag_Type_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tag_Type_Max_Order_By>;
  min?: InputMaybe<Tag_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.tag_type" */
export type Tag_Type_Arr_Rel_Insert_Input = {
  data: Array<Tag_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tag_Type_On_Conflict>;
};

/** columns and relationships of "risksmart.tag_type_audit" */
export type Tag_Type_Audit = {
  __typename?: 'tag_type_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name: Scalars['String']['output'];
  TagTypeGroupId?: Maybe<Scalars['uuid']['output']>;
  TagTypeId: Scalars['uuid']['output'];
};

/** Boolean expression to filter rows from the table "risksmart.tag_type_audit". All fields are combined with a logical 'AND'. */
export type Tag_Type_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  TagTypeGroupId?: InputMaybe<Uuid_Comparison_Exp>;
  TagTypeId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Tag_Type_Audit_Bool_Exp>>;
  _not?: InputMaybe<Tag_Type_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Tag_Type_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.tag_type_audit". */
export type Tag_Type_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  TagTypeGroupId?: InputMaybe<Order_By>;
  TagTypeId?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.tag_type_audit" */
export const Tag_Type_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Description: 'Description',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Name: 'Name',
  /** column name */
  TagTypeGroupId: 'TagTypeGroupId',
  /** column name */
  TagTypeId: 'TagTypeId'
} as const;

export type Tag_Type_Audit_Select_Column = typeof Tag_Type_Audit_Select_Column[keyof typeof Tag_Type_Audit_Select_Column];
/** Streaming cursor of the table "tag_type_audit" */
export type Tag_Type_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tag_Type_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tag_Type_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  TagTypeGroupId?: InputMaybe<Scalars['uuid']['input']>;
  TagTypeId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.tag_type". All fields are combined with a logical 'AND'. */
export type Tag_Type_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  TagTypeGroupId?: InputMaybe<Uuid_Comparison_Exp>;
  TagTypeId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Tag_Type_Bool_Exp>>;
  _not?: InputMaybe<Tag_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Tag_Type_Bool_Exp>>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  tag_type_group?: InputMaybe<Tag_Type_Group_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.tag_type" */
export const Tag_Type_Constraint = {
  /** unique or primary key constraint on columns "Name", "OrgKey" */
  IxTagTypeOrgkeyTitle: 'ix_tag_type_orgkey_title',
  /** unique or primary key constraint on columns "TagTypeId" */
  TagTypePkey: 'tag_type_pkey'
} as const;

export type Tag_Type_Constraint = typeof Tag_Type_Constraint[keyof typeof Tag_Type_Constraint];
/** columns and relationships of "risksmart.tag_type_group" */
export type Tag_Type_Group = {
  __typename?: 'tag_type_group';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  /** An array relationship */
  tag_types: Array<Tag_Type>;
};


/** columns and relationships of "risksmart.tag_type_group" */
export type Tag_Type_GroupTag_TypesArgs = {
  distinct_on?: InputMaybe<Array<Tag_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tag_Type_Order_By>>;
  where?: InputMaybe<Tag_Type_Bool_Exp>;
};

/** columns and relationships of "risksmart.tag_type_group_audit" */
export type Tag_Type_Group_Audit = {
  __typename?: 'tag_type_group_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name: Scalars['String']['output'];
};

/** Boolean expression to filter rows from the table "risksmart.tag_type_group_audit". All fields are combined with a logical 'AND'. */
export type Tag_Type_Group_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Tag_Type_Group_Audit_Bool_Exp>>;
  _not?: InputMaybe<Tag_Type_Group_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Tag_Type_Group_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.tag_type_group_audit". */
export type Tag_Type_Group_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.tag_type_group_audit" */
export const Tag_Type_Group_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Name: 'Name'
} as const;

export type Tag_Type_Group_Audit_Select_Column = typeof Tag_Type_Group_Audit_Select_Column[keyof typeof Tag_Type_Group_Audit_Select_Column];
/** Streaming cursor of the table "tag_type_group_audit" */
export type Tag_Type_Group_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tag_Type_Group_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tag_Type_Group_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.tag_type_group". All fields are combined with a logical 'AND'. */
export type Tag_Type_Group_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Tag_Type_Group_Bool_Exp>>;
  _not?: InputMaybe<Tag_Type_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Tag_Type_Group_Bool_Exp>>;
  tag_types?: InputMaybe<Tag_Type_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.tag_type_group" */
export const Tag_Type_Group_Constraint = {
  /** unique or primary key constraint on columns "Name", "OrgKey" */
  TagTypeGroupPkey: 'TagTypeGroup_pkey',
  /** unique or primary key constraint on columns "Id" */
  TagTypeGroupIdKey: 'tag_type_group_Id_key'
} as const;

export type Tag_Type_Group_Constraint = typeof Tag_Type_Group_Constraint[keyof typeof Tag_Type_Group_Constraint];
/** input type for inserting data into table "risksmart.tag_type_group" */
export type Tag_Type_Group_Insert_Input = {
  Name?: InputMaybe<Scalars['String']['input']>;
  tag_types?: InputMaybe<Tag_Type_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "risksmart.tag_type_group" */
export type Tag_Type_Group_Mutation_Response = {
  __typename?: 'tag_type_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tag_Type_Group>;
};

/** input type for inserting object relation for remote table "risksmart.tag_type_group" */
export type Tag_Type_Group_Obj_Rel_Insert_Input = {
  data: Tag_Type_Group_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tag_Type_Group_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.tag_type_group" */
export type Tag_Type_Group_On_Conflict = {
  constraint: Tag_Type_Group_Constraint;
  update_columns?: Array<Tag_Type_Group_Update_Column>;
  where?: InputMaybe<Tag_Type_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.tag_type_group". */
export type Tag_Type_Group_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  tag_types_aggregate?: InputMaybe<Tag_Type_Aggregate_Order_By>;
};

/** select columns of table "risksmart.tag_type_group" */
export const Tag_Type_Group_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Name: 'Name'
} as const;

export type Tag_Type_Group_Select_Column = typeof Tag_Type_Group_Select_Column[keyof typeof Tag_Type_Group_Select_Column];
/** input type for updating data in table "risksmart.tag_type_group" */
export type Tag_Type_Group_Set_Input = {
  Name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tag_type_group" */
export type Tag_Type_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tag_Type_Group_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tag_Type_Group_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.tag_type_group" */
export const Tag_Type_Group_Update_Column = {
  /** column name */
  Name: 'Name'
} as const;

export type Tag_Type_Group_Update_Column = typeof Tag_Type_Group_Update_Column[keyof typeof Tag_Type_Group_Update_Column];
export type Tag_Type_Group_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tag_Type_Group_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tag_Type_Group_Bool_Exp;
};

/** input type for inserting data into table "risksmart.tag_type" */
export type Tag_Type_Insert_Input = {
  Description?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  TagTypeGroupId?: InputMaybe<Scalars['uuid']['input']>;
  tag_type_group?: InputMaybe<Tag_Type_Group_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "risksmart.tag_type" */
export type Tag_Type_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  TagTypeGroupId?: InputMaybe<Order_By>;
  TagTypeId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.tag_type" */
export type Tag_Type_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  TagTypeGroupId?: InputMaybe<Order_By>;
  TagTypeId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.tag_type" */
export type Tag_Type_Mutation_Response = {
  __typename?: 'tag_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tag_Type>;
};

/** input type for inserting object relation for remote table "risksmart.tag_type" */
export type Tag_Type_Obj_Rel_Insert_Input = {
  data: Tag_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tag_Type_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.tag_type" */
export type Tag_Type_On_Conflict = {
  constraint: Tag_Type_Constraint;
  update_columns?: Array<Tag_Type_Update_Column>;
  where?: InputMaybe<Tag_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.tag_type". */
export type Tag_Type_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  TagTypeGroupId?: InputMaybe<Order_By>;
  TagTypeId?: InputMaybe<Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  tag_type_group?: InputMaybe<Tag_Type_Group_Order_By>;
};

/** primary key columns input for table: risksmart.tag_type */
export type Tag_Type_Pk_Columns_Input = {
  TagTypeId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.tag_type" */
export const Tag_Type_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Description: 'Description',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Name: 'Name',
  /** column name */
  TagTypeGroupId: 'TagTypeGroupId',
  /** column name */
  TagTypeId: 'TagTypeId'
} as const;

export type Tag_Type_Select_Column = typeof Tag_Type_Select_Column[keyof typeof Tag_Type_Select_Column];
/** input type for updating data in table "risksmart.tag_type" */
export type Tag_Type_Set_Input = {
  Description?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  TagTypeGroupId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "tag_type" */
export type Tag_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tag_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tag_Type_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  TagTypeGroupId?: InputMaybe<Scalars['uuid']['input']>;
  TagTypeId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.tag_type" */
export const Tag_Type_Update_Column = {
  /** column name */
  Description: 'Description',
  /** column name */
  Name: 'Name',
  /** column name */
  TagTypeGroupId: 'TagTypeGroupId'
} as const;

export type Tag_Type_Update_Column = typeof Tag_Type_Update_Column[keyof typeof Tag_Type_Update_Column];
export type Tag_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tag_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tag_Type_Bool_Exp;
};

/** placeholder for update columns of table "risksmart.tag" (current role has no relevant permissions) */
export const Tag_Update_Column = {
  /** placeholder (do not use) */
  Placeholder: '_PLACEHOLDER'
} as const;

export type Tag_Update_Column = typeof Tag_Update_Column[keyof typeof Tag_Update_Column];
/** columns and relationships of "risksmart.taxonomy" */
export type Taxonomy = {
  __typename?: 'taxonomy';
  Common: Scalars['jsonb']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  Library: Scalars['jsonb']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Rating: Scalars['jsonb']['output'];
  Taxonomy: Scalars['jsonb']['output'];
  /** An array relationship */
  organisations: Array<Taxonomy_Org>;
  /** An aggregate relationship */
  organisations_aggregate: Taxonomy_Org_Aggregate;
};


/** columns and relationships of "risksmart.taxonomy" */
export type TaxonomyCommonArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.taxonomy" */
export type TaxonomyLibraryArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.taxonomy" */
export type TaxonomyRatingArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.taxonomy" */
export type TaxonomyTaxonomyArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.taxonomy" */
export type TaxonomyOrganisationsArgs = {
  distinct_on?: InputMaybe<Array<Taxonomy_Org_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taxonomy_Org_Order_By>>;
  where?: InputMaybe<Taxonomy_Org_Bool_Exp>;
};


/** columns and relationships of "risksmart.taxonomy" */
export type TaxonomyOrganisations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Taxonomy_Org_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taxonomy_Org_Order_By>>;
  where?: InputMaybe<Taxonomy_Org_Bool_Exp>;
};

/** aggregated selection of "risksmart.taxonomy" */
export type Taxonomy_Aggregate = {
  __typename?: 'taxonomy_aggregate';
  aggregate?: Maybe<Taxonomy_Aggregate_Fields>;
  nodes: Array<Taxonomy>;
};

/** aggregate fields of "risksmart.taxonomy" */
export type Taxonomy_Aggregate_Fields = {
  __typename?: 'taxonomy_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Taxonomy_Max_Fields>;
  min?: Maybe<Taxonomy_Min_Fields>;
};


/** aggregate fields of "risksmart.taxonomy" */
export type Taxonomy_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Taxonomy_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Taxonomy_Append_Input = {
  Common?: InputMaybe<Scalars['jsonb']['input']>;
  Library?: InputMaybe<Scalars['jsonb']['input']>;
  Rating?: InputMaybe<Scalars['jsonb']['input']>;
  Taxonomy?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.taxonomy_audit" */
export type Taxonomy_Audit = {
  __typename?: 'taxonomy_audit';
  Common: Scalars['jsonb']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  Library: Scalars['jsonb']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Rating: Scalars['jsonb']['output'];
  Taxonomy: Scalars['jsonb']['output'];
  /** An array relationship */
  organisations: Array<Taxonomy_Org>;
  /** An aggregate relationship */
  organisations_aggregate: Taxonomy_Org_Aggregate;
};


/** columns and relationships of "risksmart.taxonomy_audit" */
export type Taxonomy_AuditCommonArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.taxonomy_audit" */
export type Taxonomy_AuditLibraryArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.taxonomy_audit" */
export type Taxonomy_AuditRatingArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.taxonomy_audit" */
export type Taxonomy_AuditTaxonomyArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.taxonomy_audit" */
export type Taxonomy_AuditOrganisationsArgs = {
  distinct_on?: InputMaybe<Array<Taxonomy_Org_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taxonomy_Org_Order_By>>;
  where?: InputMaybe<Taxonomy_Org_Bool_Exp>;
};


/** columns and relationships of "risksmart.taxonomy_audit" */
export type Taxonomy_AuditOrganisations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Taxonomy_Org_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taxonomy_Org_Order_By>>;
  where?: InputMaybe<Taxonomy_Org_Bool_Exp>;
};

/** aggregated selection of "risksmart.taxonomy_audit" */
export type Taxonomy_Audit_Aggregate = {
  __typename?: 'taxonomy_audit_aggregate';
  aggregate?: Maybe<Taxonomy_Audit_Aggregate_Fields>;
  nodes: Array<Taxonomy_Audit>;
};

/** aggregate fields of "risksmart.taxonomy_audit" */
export type Taxonomy_Audit_Aggregate_Fields = {
  __typename?: 'taxonomy_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Taxonomy_Audit_Max_Fields>;
  min?: Maybe<Taxonomy_Audit_Min_Fields>;
};


/** aggregate fields of "risksmart.taxonomy_audit" */
export type Taxonomy_Audit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Taxonomy_Audit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.taxonomy_audit". All fields are combined with a logical 'AND'. */
export type Taxonomy_Audit_Bool_Exp = {
  Common?: InputMaybe<Jsonb_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  Library?: InputMaybe<Jsonb_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Rating?: InputMaybe<Jsonb_Comparison_Exp>;
  Taxonomy?: InputMaybe<Jsonb_Comparison_Exp>;
  _and?: InputMaybe<Array<Taxonomy_Audit_Bool_Exp>>;
  _not?: InputMaybe<Taxonomy_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Taxonomy_Audit_Bool_Exp>>;
  organisations?: InputMaybe<Taxonomy_Org_Bool_Exp>;
  organisations_aggregate?: InputMaybe<Taxonomy_Org_Aggregate_Bool_Exp>;
};

/** aggregate max on columns */
export type Taxonomy_Audit_Max_Fields = {
  __typename?: 'taxonomy_audit_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Taxonomy_Audit_Min_Fields = {
  __typename?: 'taxonomy_audit_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "risksmart.taxonomy_audit". */
export type Taxonomy_Audit_Order_By = {
  Common?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Library?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  Taxonomy?: InputMaybe<Order_By>;
  organisations_aggregate?: InputMaybe<Taxonomy_Org_Aggregate_Order_By>;
};

/** select columns of table "risksmart.taxonomy_audit" */
export const Taxonomy_Audit_Select_Column = {
  /** column name */
  Common: 'Common',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  Library: 'Library',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Rating: 'Rating',
  /** column name */
  Taxonomy: 'Taxonomy'
} as const;

export type Taxonomy_Audit_Select_Column = typeof Taxonomy_Audit_Select_Column[keyof typeof Taxonomy_Audit_Select_Column];
/** Streaming cursor of the table "taxonomy_audit" */
export type Taxonomy_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Taxonomy_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Taxonomy_Audit_Stream_Cursor_Value_Input = {
  Common?: InputMaybe<Scalars['jsonb']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Library?: InputMaybe<Scalars['jsonb']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['jsonb']['input']>;
  Taxonomy?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.taxonomy". All fields are combined with a logical 'AND'. */
export type Taxonomy_Bool_Exp = {
  Common?: InputMaybe<Jsonb_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  Library?: InputMaybe<Jsonb_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Rating?: InputMaybe<Jsonb_Comparison_Exp>;
  Taxonomy?: InputMaybe<Jsonb_Comparison_Exp>;
  _and?: InputMaybe<Array<Taxonomy_Bool_Exp>>;
  _not?: InputMaybe<Taxonomy_Bool_Exp>;
  _or?: InputMaybe<Array<Taxonomy_Bool_Exp>>;
  organisations?: InputMaybe<Taxonomy_Org_Bool_Exp>;
  organisations_aggregate?: InputMaybe<Taxonomy_Org_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.taxonomy" */
export const Taxonomy_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  TaxonomyPkey: 'taxonomy_pkey'
} as const;

export type Taxonomy_Constraint = typeof Taxonomy_Constraint[keyof typeof Taxonomy_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Taxonomy_Delete_At_Path_Input = {
  Common?: InputMaybe<Array<Scalars['String']['input']>>;
  Library?: InputMaybe<Array<Scalars['String']['input']>>;
  Rating?: InputMaybe<Array<Scalars['String']['input']>>;
  Taxonomy?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Taxonomy_Delete_Elem_Input = {
  Common?: InputMaybe<Scalars['Int']['input']>;
  Library?: InputMaybe<Scalars['Int']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  Taxonomy?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Taxonomy_Delete_Key_Input = {
  Common?: InputMaybe<Scalars['String']['input']>;
  Library?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['String']['input']>;
  Taxonomy?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.taxonomy" */
export type Taxonomy_Insert_Input = {
  Common?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Library?: InputMaybe<Scalars['jsonb']['input']>;
  Rating?: InputMaybe<Scalars['jsonb']['input']>;
  Taxonomy?: InputMaybe<Scalars['jsonb']['input']>;
  organisations?: InputMaybe<Taxonomy_Org_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Taxonomy_Max_Fields = {
  __typename?: 'taxonomy_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Taxonomy_Min_Fields = {
  __typename?: 'taxonomy_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.taxonomy" */
export type Taxonomy_Mutation_Response = {
  __typename?: 'taxonomy_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Taxonomy>;
};

/** input type for inserting object relation for remote table "risksmart.taxonomy" */
export type Taxonomy_Obj_Rel_Insert_Input = {
  data: Taxonomy_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Taxonomy_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.taxonomy" */
export type Taxonomy_On_Conflict = {
  constraint: Taxonomy_Constraint;
  update_columns?: Array<Taxonomy_Update_Column>;
  where?: InputMaybe<Taxonomy_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.taxonomy". */
export type Taxonomy_Order_By = {
  Common?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Library?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Rating?: InputMaybe<Order_By>;
  Taxonomy?: InputMaybe<Order_By>;
  organisations_aggregate?: InputMaybe<Taxonomy_Org_Aggregate_Order_By>;
};

/** columns and relationships of "risksmart.taxonomy_org" */
export type Taxonomy_Org = {
  __typename?: 'taxonomy_org';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  Locale: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  OrgName: Scalars['String']['output'];
  TaxonomyId: Scalars['uuid']['output'];
  /** An object relationship */
  taxonomy: Taxonomy;
};

/** aggregated selection of "risksmart.taxonomy_org" */
export type Taxonomy_Org_Aggregate = {
  __typename?: 'taxonomy_org_aggregate';
  aggregate?: Maybe<Taxonomy_Org_Aggregate_Fields>;
  nodes: Array<Taxonomy_Org>;
};

export type Taxonomy_Org_Aggregate_Bool_Exp = {
  count?: InputMaybe<Taxonomy_Org_Aggregate_Bool_Exp_Count>;
};

export type Taxonomy_Org_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Taxonomy_Org_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Taxonomy_Org_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.taxonomy_org" */
export type Taxonomy_Org_Aggregate_Fields = {
  __typename?: 'taxonomy_org_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Taxonomy_Org_Max_Fields>;
  min?: Maybe<Taxonomy_Org_Min_Fields>;
};


/** aggregate fields of "risksmart.taxonomy_org" */
export type Taxonomy_Org_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Taxonomy_Org_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.taxonomy_org" */
export type Taxonomy_Org_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Taxonomy_Org_Max_Order_By>;
  min?: InputMaybe<Taxonomy_Org_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.taxonomy_org" */
export type Taxonomy_Org_Arr_Rel_Insert_Input = {
  data: Array<Taxonomy_Org_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Taxonomy_Org_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risksmart.taxonomy_org". All fields are combined with a logical 'AND'. */
export type Taxonomy_Org_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  Locale?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  OrgKey?: InputMaybe<String_Comparison_Exp>;
  OrgName?: InputMaybe<String_Comparison_Exp>;
  TaxonomyId?: InputMaybe<Uuid_Comparison_Exp>;
  _and?: InputMaybe<Array<Taxonomy_Org_Bool_Exp>>;
  _not?: InputMaybe<Taxonomy_Org_Bool_Exp>;
  _or?: InputMaybe<Array<Taxonomy_Org_Bool_Exp>>;
  taxonomy?: InputMaybe<Taxonomy_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.taxonomy_org" */
export const Taxonomy_Org_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  TaxonomyOrgPkey: 'taxonomy_org_pkey'
} as const;

export type Taxonomy_Org_Constraint = typeof Taxonomy_Org_Constraint[keyof typeof Taxonomy_Org_Constraint];
/** input type for inserting data into table "risksmart.taxonomy_org" */
export type Taxonomy_Org_Insert_Input = {
  Locale?: InputMaybe<Scalars['String']['input']>;
  OrgName?: InputMaybe<Scalars['String']['input']>;
  TaxonomyId?: InputMaybe<Scalars['uuid']['input']>;
  taxonomy?: InputMaybe<Taxonomy_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Taxonomy_Org_Max_Fields = {
  __typename?: 'taxonomy_org_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Locale?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OrgName?: Maybe<Scalars['String']['output']>;
  TaxonomyId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.taxonomy_org" */
export type Taxonomy_Org_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Locale?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  OrgName?: InputMaybe<Order_By>;
  TaxonomyId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Taxonomy_Org_Min_Fields = {
  __typename?: 'taxonomy_org_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Locale?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OrgName?: Maybe<Scalars['String']['output']>;
  TaxonomyId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.taxonomy_org" */
export type Taxonomy_Org_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Locale?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  OrgName?: InputMaybe<Order_By>;
  TaxonomyId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.taxonomy_org" */
export type Taxonomy_Org_Mutation_Response = {
  __typename?: 'taxonomy_org_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Taxonomy_Org>;
};

/** on_conflict condition type for table "risksmart.taxonomy_org" */
export type Taxonomy_Org_On_Conflict = {
  constraint: Taxonomy_Org_Constraint;
  update_columns?: Array<Taxonomy_Org_Update_Column>;
  where?: InputMaybe<Taxonomy_Org_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.taxonomy_org". */
export type Taxonomy_Org_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  Locale?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OrgKey?: InputMaybe<Order_By>;
  OrgName?: InputMaybe<Order_By>;
  TaxonomyId?: InputMaybe<Order_By>;
  taxonomy?: InputMaybe<Taxonomy_Order_By>;
};

/** select columns of table "risksmart.taxonomy_org" */
export const Taxonomy_Org_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Id: 'Id',
  /** column name */
  Locale: 'Locale',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  OrgKey: 'OrgKey',
  /** column name */
  OrgName: 'OrgName',
  /** column name */
  TaxonomyId: 'TaxonomyId'
} as const;

export type Taxonomy_Org_Select_Column = typeof Taxonomy_Org_Select_Column[keyof typeof Taxonomy_Org_Select_Column];
/** Streaming cursor of the table "taxonomy_org" */
export type Taxonomy_Org_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Taxonomy_Org_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Taxonomy_Org_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Locale?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OrgName?: InputMaybe<Scalars['String']['input']>;
  TaxonomyId?: InputMaybe<Scalars['uuid']['input']>;
};

/** placeholder for update columns of table "risksmart.taxonomy_org" (current role has no relevant permissions) */
export const Taxonomy_Org_Update_Column = {
  /** placeholder (do not use) */
  Placeholder: '_PLACEHOLDER'
} as const;

export type Taxonomy_Org_Update_Column = typeof Taxonomy_Org_Update_Column[keyof typeof Taxonomy_Org_Update_Column];
/** primary key columns input for table: risksmart.taxonomy */
export type Taxonomy_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Taxonomy_Prepend_Input = {
  Common?: InputMaybe<Scalars['jsonb']['input']>;
  Library?: InputMaybe<Scalars['jsonb']['input']>;
  Rating?: InputMaybe<Scalars['jsonb']['input']>;
  Taxonomy?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.taxonomy" */
export const Taxonomy_Select_Column = {
  /** column name */
  Common: 'Common',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  Library: 'Library',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Rating: 'Rating',
  /** column name */
  Taxonomy: 'Taxonomy'
} as const;

export type Taxonomy_Select_Column = typeof Taxonomy_Select_Column[keyof typeof Taxonomy_Select_Column];
/** input type for updating data in table "risksmart.taxonomy" */
export type Taxonomy_Set_Input = {
  Common?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Library?: InputMaybe<Scalars['jsonb']['input']>;
  Rating?: InputMaybe<Scalars['jsonb']['input']>;
  Taxonomy?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "taxonomy" */
export type Taxonomy_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Taxonomy_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Taxonomy_Stream_Cursor_Value_Input = {
  Common?: InputMaybe<Scalars['jsonb']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Library?: InputMaybe<Scalars['jsonb']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['jsonb']['input']>;
  Taxonomy?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "risksmart.taxonomy" */
export const Taxonomy_Update_Column = {
  /** column name */
  Common: 'Common',
  /** column name */
  Description: 'Description',
  /** column name */
  Library: 'Library',
  /** column name */
  Rating: 'Rating',
  /** column name */
  Taxonomy: 'Taxonomy'
} as const;

export type Taxonomy_Update_Column = typeof Taxonomy_Update_Column[keyof typeof Taxonomy_Update_Column];
export type Taxonomy_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Taxonomy_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Taxonomy_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Taxonomy_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Taxonomy_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Taxonomy_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Taxonomy_Set_Input>;
  /** filter the rows which have to be updated */
  where: Taxonomy_Bool_Exp;
};

export const Test_Frequency_Enum = {
  /** Ad Hoc */
  Adhoc: 'adhoc',
  /** Annually */
  Annually: 'annually',
  /** Bi-Annually */
  Biannually: 'biannually',
  /**  Daily */
  Daily: 'daily',
  /** Fortnightly */
  Fortnightly: 'fortnightly',
  /** Four Weekly */
  Fourweekly: 'fourweekly',
  /** Monthly */
  Monthly: 'monthly',
  /** Quarterly */
  Quarterly: 'quarterly',
  /** Weekly */
  Weekly: 'weekly'
} as const;

export type Test_Frequency_Enum = typeof Test_Frequency_Enum[keyof typeof Test_Frequency_Enum];
/** Boolean expression to compare columns of type "test_frequency_enum". All fields are combined with logical 'AND'. */
export type Test_Frequency_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Test_Frequency_Enum>;
  _in?: InputMaybe<Array<Test_Frequency_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Test_Frequency_Enum>;
  _nin?: InputMaybe<Array<Test_Frequency_Enum>>;
};

/** columns and relationships of "risksmart.test_result" */
export type Test_Result = {
  __typename?: 'test_result';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  DesignEffectiveness?: Maybe<Scalars['Int']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OverallEffectiveness?: Maybe<Scalars['Int']['output']>;
  ParentControlId: Scalars['uuid']['output'];
  PerformanceEffectiveness?: Maybe<Scalars['Int']['output']>;
  RatingType: Scalars['String']['output'];
  SequentialId: Scalars['Int']['output'];
  Submitter: Scalars['String']['output'];
  TestDate: Scalars['timestamptz']['output'];
  TestType?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  assessmentParents: Array<Assessment_Result_Parent>;
  /** An aggregate relationship */
  assessmentParents_aggregate: Assessment_Result_Parent_Aggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  files: Array<Relation_File>;
  /** An aggregate relationship */
  files_aggregate: Relation_File_Aggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An object relationship */
  parent?: Maybe<Control>;
  /** An object relationship */
  submitter?: Maybe<User>;
};


/** columns and relationships of "risksmart.test_result" */
export type Test_ResultCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.test_result" */
export type Test_ResultAssessmentParentsArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.test_result" */
export type Test_ResultAssessmentParents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assessment_Result_Parent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Assessment_Result_Parent_Order_By>>;
  where?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
};


/** columns and relationships of "risksmart.test_result" */
export type Test_ResultFilesArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.test_result" */
export type Test_ResultFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};

/** aggregated selection of "risksmart.test_result" */
export type Test_Result_Aggregate = {
  __typename?: 'test_result_aggregate';
  aggregate?: Maybe<Test_Result_Aggregate_Fields>;
  nodes: Array<Test_Result>;
};

export type Test_Result_Aggregate_Bool_Exp = {
  count?: InputMaybe<Test_Result_Aggregate_Bool_Exp_Count>;
};

export type Test_Result_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Test_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Test_Result_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.test_result" */
export type Test_Result_Aggregate_Fields = {
  __typename?: 'test_result_aggregate_fields';
  avg?: Maybe<Test_Result_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Test_Result_Max_Fields>;
  min?: Maybe<Test_Result_Min_Fields>;
  stddev?: Maybe<Test_Result_Stddev_Fields>;
  stddev_pop?: Maybe<Test_Result_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Test_Result_Stddev_Samp_Fields>;
  sum?: Maybe<Test_Result_Sum_Fields>;
  var_pop?: Maybe<Test_Result_Var_Pop_Fields>;
  var_samp?: Maybe<Test_Result_Var_Samp_Fields>;
  variance?: Maybe<Test_Result_Variance_Fields>;
};


/** aggregate fields of "risksmart.test_result" */
export type Test_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Test_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.test_result" */
export type Test_Result_Aggregate_Order_By = {
  avg?: InputMaybe<Test_Result_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Test_Result_Max_Order_By>;
  min?: InputMaybe<Test_Result_Min_Order_By>;
  stddev?: InputMaybe<Test_Result_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Test_Result_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Test_Result_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Test_Result_Sum_Order_By>;
  var_pop?: InputMaybe<Test_Result_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Test_Result_Var_Samp_Order_By>;
  variance?: InputMaybe<Test_Result_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.test_result" */
export type Test_Result_Arr_Rel_Insert_Input = {
  data: Array<Test_Result_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Test_Result_On_Conflict>;
};

/** columns and relationships of "risksmart.test_result_audit" */
export type Test_Result_Audit = {
  __typename?: 'test_result_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  DesignEffectiveness?: Maybe<Scalars['Int']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Int']['output']>;
  ParentControlId: Scalars['uuid']['output'];
  PerformanceEffectiveness?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Submitter: Scalars['String']['output'];
  TestDate: Scalars['timestamptz']['output'];
  TestType?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "risksmart.test_result_audit" */
export type Test_Result_AuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "risksmart.test_result_audit" */
export type Test_Result_Audit_Aggregate_Order_By = {
  avg?: InputMaybe<Test_Result_Audit_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Test_Result_Audit_Max_Order_By>;
  min?: InputMaybe<Test_Result_Audit_Min_Order_By>;
  stddev?: InputMaybe<Test_Result_Audit_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Test_Result_Audit_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Test_Result_Audit_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Test_Result_Audit_Sum_Order_By>;
  var_pop?: InputMaybe<Test_Result_Audit_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Test_Result_Audit_Var_Samp_Order_By>;
  variance?: InputMaybe<Test_Result_Audit_Variance_Order_By>;
};

/** order by avg() on columns of table "risksmart.test_result_audit" */
export type Test_Result_Audit_Avg_Order_By = {
  DesignEffectiveness?: InputMaybe<Order_By>;
  OverallEffectiveness?: InputMaybe<Order_By>;
  PerformanceEffectiveness?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.test_result_audit". All fields are combined with a logical 'AND'. */
export type Test_Result_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  DesignEffectiveness?: InputMaybe<Int_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  OverallEffectiveness?: InputMaybe<Int_Comparison_Exp>;
  ParentControlId?: InputMaybe<Uuid_Comparison_Exp>;
  PerformanceEffectiveness?: InputMaybe<Int_Comparison_Exp>;
  RatingType?: InputMaybe<String_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Submitter?: InputMaybe<String_Comparison_Exp>;
  TestDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  TestType?: InputMaybe<String_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Test_Result_Audit_Bool_Exp>>;
  _not?: InputMaybe<Test_Result_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Test_Result_Audit_Bool_Exp>>;
};

/** order by max() on columns of table "risksmart.test_result_audit" */
export type Test_Result_Audit_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  DesignEffectiveness?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OverallEffectiveness?: InputMaybe<Order_By>;
  ParentControlId?: InputMaybe<Order_By>;
  PerformanceEffectiveness?: InputMaybe<Order_By>;
  RatingType?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Submitter?: InputMaybe<Order_By>;
  TestDate?: InputMaybe<Order_By>;
  TestType?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risksmart.test_result_audit" */
export type Test_Result_Audit_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  DesignEffectiveness?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OverallEffectiveness?: InputMaybe<Order_By>;
  ParentControlId?: InputMaybe<Order_By>;
  PerformanceEffectiveness?: InputMaybe<Order_By>;
  RatingType?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Submitter?: InputMaybe<Order_By>;
  TestDate?: InputMaybe<Order_By>;
  TestType?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "risksmart.test_result_audit". */
export type Test_Result_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  DesignEffectiveness?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OverallEffectiveness?: InputMaybe<Order_By>;
  ParentControlId?: InputMaybe<Order_By>;
  PerformanceEffectiveness?: InputMaybe<Order_By>;
  RatingType?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Submitter?: InputMaybe<Order_By>;
  TestDate?: InputMaybe<Order_By>;
  TestType?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.test_result_audit" */
export const Test_Result_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  DesignEffectiveness: 'DesignEffectiveness',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  OverallEffectiveness: 'OverallEffectiveness',
  /** column name */
  ParentControlId: 'ParentControlId',
  /** column name */
  PerformanceEffectiveness: 'PerformanceEffectiveness',
  /** column name */
  RatingType: 'RatingType',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Submitter: 'Submitter',
  /** column name */
  TestDate: 'TestDate',
  /** column name */
  TestType: 'TestType',
  /** column name */
  Title: 'Title'
} as const;

export type Test_Result_Audit_Select_Column = typeof Test_Result_Audit_Select_Column[keyof typeof Test_Result_Audit_Select_Column];
/** order by stddev() on columns of table "risksmart.test_result_audit" */
export type Test_Result_Audit_Stddev_Order_By = {
  DesignEffectiveness?: InputMaybe<Order_By>;
  OverallEffectiveness?: InputMaybe<Order_By>;
  PerformanceEffectiveness?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "risksmart.test_result_audit" */
export type Test_Result_Audit_Stddev_Pop_Order_By = {
  DesignEffectiveness?: InputMaybe<Order_By>;
  OverallEffectiveness?: InputMaybe<Order_By>;
  PerformanceEffectiveness?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "risksmart.test_result_audit" */
export type Test_Result_Audit_Stddev_Samp_Order_By = {
  DesignEffectiveness?: InputMaybe<Order_By>;
  OverallEffectiveness?: InputMaybe<Order_By>;
  PerformanceEffectiveness?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "test_result_audit" */
export type Test_Result_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Test_Result_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Test_Result_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  DesignEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OverallEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  ParentControlId?: InputMaybe<Scalars['uuid']['input']>;
  PerformanceEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Submitter?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TestType?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "risksmart.test_result_audit" */
export type Test_Result_Audit_Sum_Order_By = {
  DesignEffectiveness?: InputMaybe<Order_By>;
  OverallEffectiveness?: InputMaybe<Order_By>;
  PerformanceEffectiveness?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "risksmart.test_result_audit" */
export type Test_Result_Audit_Var_Pop_Order_By = {
  DesignEffectiveness?: InputMaybe<Order_By>;
  OverallEffectiveness?: InputMaybe<Order_By>;
  PerformanceEffectiveness?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "risksmart.test_result_audit" */
export type Test_Result_Audit_Var_Samp_Order_By = {
  DesignEffectiveness?: InputMaybe<Order_By>;
  OverallEffectiveness?: InputMaybe<Order_By>;
  PerformanceEffectiveness?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "risksmart.test_result_audit" */
export type Test_Result_Audit_Variance_Order_By = {
  DesignEffectiveness?: InputMaybe<Order_By>;
  OverallEffectiveness?: InputMaybe<Order_By>;
  PerformanceEffectiveness?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate avg on columns */
export type Test_Result_Avg_Fields = {
  __typename?: 'test_result_avg_fields';
  DesignEffectiveness?: Maybe<Scalars['Float']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Float']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.test_result" */
export type Test_Result_Avg_Order_By = {
  DesignEffectiveness?: InputMaybe<Order_By>;
  OverallEffectiveness?: InputMaybe<Order_By>;
  PerformanceEffectiveness?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "risksmart.test_result". All fields are combined with a logical 'AND'. */
export type Test_Result_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  DesignEffectiveness?: InputMaybe<Int_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  OverallEffectiveness?: InputMaybe<Int_Comparison_Exp>;
  ParentControlId?: InputMaybe<Uuid_Comparison_Exp>;
  PerformanceEffectiveness?: InputMaybe<Int_Comparison_Exp>;
  RatingType?: InputMaybe<String_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Submitter?: InputMaybe<String_Comparison_Exp>;
  TestDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  TestType?: InputMaybe<String_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Test_Result_Bool_Exp>>;
  _not?: InputMaybe<Test_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Test_Result_Bool_Exp>>;
  assessmentParents?: InputMaybe<Assessment_Result_Parent_Bool_Exp>;
  assessmentParents_aggregate?: InputMaybe<Assessment_Result_Parent_Aggregate_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  files?: InputMaybe<Relation_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  parent?: InputMaybe<Control_Bool_Exp>;
  submitter?: InputMaybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.test_result" */
export const Test_Result_Constraint = {
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxTestResultOrgkeySequentialid: 'idx_test_result_orgkey_sequentialid',
  /** unique or primary key constraint on columns "Id" */
  TestResultPkey: 'test_result_pkey'
} as const;

export type Test_Result_Constraint = typeof Test_Result_Constraint[keyof typeof Test_Result_Constraint];
/** input type for inserting data into table "risksmart.test_result" */
export type Test_Result_Insert_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  DesignEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  OverallEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  ParentControlId?: InputMaybe<Scalars['uuid']['input']>;
  PerformanceEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Submitter?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TestType?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  assessmentParents?: InputMaybe<Assessment_Result_Parent_Arr_Rel_Insert_Input>;
  files?: InputMaybe<Relation_File_Arr_Rel_Insert_Input>;
  parent?: InputMaybe<Control_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Test_Result_Max_Fields = {
  __typename?: 'test_result_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  DesignEffectiveness?: Maybe<Scalars['Int']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Int']['output']>;
  ParentControlId?: Maybe<Scalars['uuid']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Submitter?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
  TestType?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.test_result" */
export type Test_Result_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  DesignEffectiveness?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OverallEffectiveness?: InputMaybe<Order_By>;
  ParentControlId?: InputMaybe<Order_By>;
  PerformanceEffectiveness?: InputMaybe<Order_By>;
  RatingType?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Submitter?: InputMaybe<Order_By>;
  TestDate?: InputMaybe<Order_By>;
  TestType?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Test_Result_Min_Fields = {
  __typename?: 'test_result_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  DesignEffectiveness?: Maybe<Scalars['Int']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Int']['output']>;
  ParentControlId?: Maybe<Scalars['uuid']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Submitter?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
  TestType?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.test_result" */
export type Test_Result_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  DesignEffectiveness?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OverallEffectiveness?: InputMaybe<Order_By>;
  ParentControlId?: InputMaybe<Order_By>;
  PerformanceEffectiveness?: InputMaybe<Order_By>;
  RatingType?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Submitter?: InputMaybe<Order_By>;
  TestDate?: InputMaybe<Order_By>;
  TestType?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.test_result" */
export type Test_Result_Mutation_Response = {
  __typename?: 'test_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Test_Result>;
};

/** input type for inserting object relation for remote table "risksmart.test_result" */
export type Test_Result_Obj_Rel_Insert_Input = {
  data: Test_Result_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Test_Result_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.test_result" */
export type Test_Result_On_Conflict = {
  constraint: Test_Result_Constraint;
  update_columns?: Array<Test_Result_Update_Column>;
  where?: InputMaybe<Test_Result_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.test_result". */
export type Test_Result_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  DesignEffectiveness?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  OverallEffectiveness?: InputMaybe<Order_By>;
  ParentControlId?: InputMaybe<Order_By>;
  PerformanceEffectiveness?: InputMaybe<Order_By>;
  RatingType?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Submitter?: InputMaybe<Order_By>;
  TestDate?: InputMaybe<Order_By>;
  TestType?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  assessmentParents_aggregate?: InputMaybe<Assessment_Result_Parent_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  parent?: InputMaybe<Control_Order_By>;
  submitter?: InputMaybe<User_Order_By>;
};

/** select columns of table "risksmart.test_result" */
export const Test_Result_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  DesignEffectiveness: 'DesignEffectiveness',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  OverallEffectiveness: 'OverallEffectiveness',
  /** column name */
  ParentControlId: 'ParentControlId',
  /** column name */
  PerformanceEffectiveness: 'PerformanceEffectiveness',
  /** column name */
  RatingType: 'RatingType',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Submitter: 'Submitter',
  /** column name */
  TestDate: 'TestDate',
  /** column name */
  TestType: 'TestType',
  /** column name */
  Title: 'Title'
} as const;

export type Test_Result_Select_Column = typeof Test_Result_Select_Column[keyof typeof Test_Result_Select_Column];
/** aggregate stddev on columns */
export type Test_Result_Stddev_Fields = {
  __typename?: 'test_result_stddev_fields';
  DesignEffectiveness?: Maybe<Scalars['Float']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Float']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.test_result" */
export type Test_Result_Stddev_Order_By = {
  DesignEffectiveness?: InputMaybe<Order_By>;
  OverallEffectiveness?: InputMaybe<Order_By>;
  PerformanceEffectiveness?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Test_Result_Stddev_Pop_Fields = {
  __typename?: 'test_result_stddev_pop_fields';
  DesignEffectiveness?: Maybe<Scalars['Float']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Float']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.test_result" */
export type Test_Result_Stddev_Pop_Order_By = {
  DesignEffectiveness?: InputMaybe<Order_By>;
  OverallEffectiveness?: InputMaybe<Order_By>;
  PerformanceEffectiveness?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Test_Result_Stddev_Samp_Fields = {
  __typename?: 'test_result_stddev_samp_fields';
  DesignEffectiveness?: Maybe<Scalars['Float']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Float']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.test_result" */
export type Test_Result_Stddev_Samp_Order_By = {
  DesignEffectiveness?: InputMaybe<Order_By>;
  OverallEffectiveness?: InputMaybe<Order_By>;
  PerformanceEffectiveness?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "test_result" */
export type Test_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Test_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Test_Result_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  DesignEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OverallEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  ParentControlId?: InputMaybe<Scalars['uuid']['input']>;
  PerformanceEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Submitter?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TestType?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Test_Result_Sum_Fields = {
  __typename?: 'test_result_sum_fields';
  DesignEffectiveness?: Maybe<Scalars['Int']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Int']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.test_result" */
export type Test_Result_Sum_Order_By = {
  DesignEffectiveness?: InputMaybe<Order_By>;
  OverallEffectiveness?: InputMaybe<Order_By>;
  PerformanceEffectiveness?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** update columns of table "risksmart.test_result" */
export const Test_Result_Update_Column = {
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  DesignEffectiveness: 'DesignEffectiveness',
  /** column name */
  OverallEffectiveness: 'OverallEffectiveness',
  /** column name */
  ParentControlId: 'ParentControlId',
  /** column name */
  PerformanceEffectiveness: 'PerformanceEffectiveness',
  /** column name */
  Submitter: 'Submitter',
  /** column name */
  TestDate: 'TestDate',
  /** column name */
  TestType: 'TestType',
  /** column name */
  Title: 'Title'
} as const;

export type Test_Result_Update_Column = typeof Test_Result_Update_Column[keyof typeof Test_Result_Update_Column];
/** aggregate var_pop on columns */
export type Test_Result_Var_Pop_Fields = {
  __typename?: 'test_result_var_pop_fields';
  DesignEffectiveness?: Maybe<Scalars['Float']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Float']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.test_result" */
export type Test_Result_Var_Pop_Order_By = {
  DesignEffectiveness?: InputMaybe<Order_By>;
  OverallEffectiveness?: InputMaybe<Order_By>;
  PerformanceEffectiveness?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Test_Result_Var_Samp_Fields = {
  __typename?: 'test_result_var_samp_fields';
  DesignEffectiveness?: Maybe<Scalars['Float']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Float']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.test_result" */
export type Test_Result_Var_Samp_Order_By = {
  DesignEffectiveness?: InputMaybe<Order_By>;
  OverallEffectiveness?: InputMaybe<Order_By>;
  PerformanceEffectiveness?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Test_Result_Variance_Fields = {
  __typename?: 'test_result_variance_fields';
  DesignEffectiveness?: Maybe<Scalars['Float']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Float']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.test_result" */
export type Test_Result_Variance_Order_By = {
  DesignEffectiveness?: InputMaybe<Order_By>;
  OverallEffectiveness?: InputMaybe<Order_By>;
  PerformanceEffectiveness?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
};

/** columns and relationships of "risksmart.third_party" */
export type Third_Party = {
  __typename?: 'third_party';
  Address?: Maybe<Scalars['String']['output']>;
  CityTown?: Maybe<Scalars['String']['output']>;
  CompaniesHouseNumber?: Maybe<Scalars['String']['output']>;
  CompanyDomain?: Maybe<Scalars['String']['output']>;
  CompanyName: Scalars['String']['output'];
  ContactEmail?: Maybe<Scalars['String']['output']>;
  ContactName?: Maybe<Scalars['String']['output']>;
  Country?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Criticality: Scalars['Int']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Postcode?: Maybe<Scalars['String']['output']>;
  PrimaryContactName?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status: Third_Party_Status_Enum;
  Title: Scalars['String']['output'];
  Type: Third_Party_Type_Enum;
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An array relationship */
  contributorGroups: Array<Contributor_Group>;
  /** An aggregate relationship */
  contributorGroups_aggregate: Contributor_Group_Aggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: Contributor_Aggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An array relationship */
  files: Array<Relation_File>;
  /** An aggregate relationship */
  files_aggregate: Relation_File_Aggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An array relationship */
  ownerGroups: Array<Owner_Group>;
  /** An aggregate relationship */
  ownerGroups_aggregate: Owner_Group_Aggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: Owner_Aggregate;
  /** An array relationship */
  tags: Array<Tag>;
};


/** columns and relationships of "risksmart.third_party" */
export type Third_PartyCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.third_party" */
export type Third_PartyAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party" */
export type Third_PartyContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party" */
export type Third_PartyContributorGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party" */
export type Third_PartyContributorsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party" */
export type Third_PartyContributors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party" */
export type Third_PartyDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Department_Order_By>>;
  where?: InputMaybe<Department_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party" */
export type Third_PartyFilesArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party" */
export type Third_PartyFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party" */
export type Third_PartyOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party" */
export type Third_PartyOwnerGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party" */
export type Third_PartyOwnersArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party" */
export type Third_PartyOwners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party" */
export type Third_PartyTagsArgs = {
  distinct_on?: InputMaybe<Array<Tag_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tag_Order_By>>;
  where?: InputMaybe<Tag_Bool_Exp>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Third_Party_Append_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.third_party". All fields are combined with a logical 'AND'. */
export type Third_Party_Bool_Exp = {
  Address?: InputMaybe<String_Comparison_Exp>;
  CityTown?: InputMaybe<String_Comparison_Exp>;
  CompaniesHouseNumber?: InputMaybe<String_Comparison_Exp>;
  CompanyDomain?: InputMaybe<String_Comparison_Exp>;
  CompanyName?: InputMaybe<String_Comparison_Exp>;
  ContactEmail?: InputMaybe<String_Comparison_Exp>;
  ContactName?: InputMaybe<String_Comparison_Exp>;
  Country?: InputMaybe<String_Comparison_Exp>;
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Criticality?: InputMaybe<Int_Comparison_Exp>;
  CustomAttributeData?: InputMaybe<Jsonb_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Postcode?: InputMaybe<String_Comparison_Exp>;
  PrimaryContactName?: InputMaybe<String_Comparison_Exp>;
  SequentialId?: InputMaybe<Int_Comparison_Exp>;
  Status?: InputMaybe<Third_Party_Status_Enum_Comparison_Exp>;
  Title?: InputMaybe<String_Comparison_Exp>;
  Type?: InputMaybe<Third_Party_Type_Enum_Comparison_Exp>;
  _and?: InputMaybe<Array<Third_Party_Bool_Exp>>;
  _not?: InputMaybe<Third_Party_Bool_Exp>;
  _or?: InputMaybe<Array<Third_Party_Bool_Exp>>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  contributorGroups?: InputMaybe<Contributor_Group_Bool_Exp>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Bool_Exp>;
  contributors?: InputMaybe<Contributor_Bool_Exp>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  departments?: InputMaybe<Department_Bool_Exp>;
  files?: InputMaybe<Relation_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  ownerGroups?: InputMaybe<Owner_Group_Bool_Exp>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Bool_Exp>;
  owners?: InputMaybe<Owner_Bool_Exp>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Bool_Exp>;
  tags?: InputMaybe<Tag_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.third_party" */
export const Third_Party_Constraint = {
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxThirdPartyOrgkeySequentialid: 'idx_third_party_orgkey_sequentialid',
  /** unique or primary key constraint on columns "Id" */
  ThirdPartyPkey: 'third_party_pkey'
} as const;

export type Third_Party_Constraint = typeof Third_Party_Constraint[keyof typeof Third_Party_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Third_Party_Delete_At_Path_Input = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Third_Party_Delete_Elem_Input = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Third_Party_Delete_Key_Input = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.third_party" */
export type Third_Party_Inc_Input = {
  Criticality?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.third_party" */
export type Third_Party_Insert_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  CityTown?: InputMaybe<Scalars['String']['input']>;
  CompaniesHouseNumber?: InputMaybe<Scalars['String']['input']>;
  CompanyDomain?: InputMaybe<Scalars['String']['input']>;
  CompanyName?: InputMaybe<Scalars['String']['input']>;
  ContactEmail?: InputMaybe<Scalars['String']['input']>;
  ContactName?: InputMaybe<Scalars['String']['input']>;
  Country?: InputMaybe<Scalars['String']['input']>;
  Criticality?: InputMaybe<Scalars['Int']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Postcode?: InputMaybe<Scalars['String']['input']>;
  PrimaryContactName?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Third_Party_Status_Enum>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Third_Party_Type_Enum>;
  contributorGroups?: InputMaybe<Contributor_Group_Arr_Rel_Insert_Input>;
  contributors?: InputMaybe<Contributor_Arr_Rel_Insert_Input>;
  departments?: InputMaybe<Department_Arr_Rel_Insert_Input>;
  files?: InputMaybe<Relation_File_Arr_Rel_Insert_Input>;
  ownerGroups?: InputMaybe<Owner_Group_Arr_Rel_Insert_Input>;
  owners?: InputMaybe<Owner_Arr_Rel_Insert_Input>;
  tags?: InputMaybe<Tag_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "risksmart.third_party" */
export type Third_Party_Mutation_Response = {
  __typename?: 'third_party_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Third_Party>;
};

/** input type for inserting object relation for remote table "risksmart.third_party" */
export type Third_Party_Obj_Rel_Insert_Input = {
  data: Third_Party_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Third_Party_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.third_party" */
export type Third_Party_On_Conflict = {
  constraint: Third_Party_Constraint;
  update_columns?: Array<Third_Party_Update_Column>;
  where?: InputMaybe<Third_Party_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.third_party". */
export type Third_Party_Order_By = {
  Address?: InputMaybe<Order_By>;
  CityTown?: InputMaybe<Order_By>;
  CompaniesHouseNumber?: InputMaybe<Order_By>;
  CompanyDomain?: InputMaybe<Order_By>;
  CompanyName?: InputMaybe<Order_By>;
  ContactEmail?: InputMaybe<Order_By>;
  ContactName?: InputMaybe<Order_By>;
  Country?: InputMaybe<Order_By>;
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Criticality?: InputMaybe<Order_By>;
  CustomAttributeData?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Postcode?: InputMaybe<Order_By>;
  PrimaryContactName?: InputMaybe<Order_By>;
  SequentialId?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  Title?: InputMaybe<Order_By>;
  Type?: InputMaybe<Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Order_By>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  departments_aggregate?: InputMaybe<Department_Aggregate_Order_By>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Order_By>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Order_By>;
  tags_aggregate?: InputMaybe<Tag_Aggregate_Order_By>;
};

/** primary key columns input for table: risksmart.third_party */
export type Third_Party_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Third_Party_Prepend_Input = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.third_party_response" */
export type Third_Party_Response = {
  __typename?: 'third_party_response';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  QuestionnaireTemplateVersionId: Scalars['uuid']['output'];
  RecallReason?: Maybe<Scalars['String']['output']>;
  ResponseData: Scalars['jsonb']['output'];
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Status: Third_Party_Response_Status_Enum;
  /** An array relationship */
  ancestorContributors: Array<Ancestor_Contributor>;
  /** An array relationship */
  contributorGroups: Array<Contributor_Group>;
  /** An aggregate relationship */
  contributorGroups_aggregate: Contributor_Group_Aggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: Contributor_Aggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  files: Array<Relation_File>;
  /** An aggregate relationship */
  files_aggregate: Relation_File_Aggregate;
  /** An array relationship */
  invitees: Array<Questionnaire_Invite>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An array relationship */
  ownerGroups: Array<Owner_Group>;
  /** An aggregate relationship */
  ownerGroups_aggregate: Owner_Group_Aggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: Owner_Aggregate;
  /** An object relationship */
  questionnaireTemplateVersion?: Maybe<Questionnaire_Template_Version>;
  /** An object relationship */
  thirdParty?: Maybe<Third_Party>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type Third_Party_ResponseResponseDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type Third_Party_ResponseAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<Ancestor_Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ancestor_Contributor_Order_By>>;
  where?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type Third_Party_ResponseContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type Third_Party_ResponseContributorGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Group_Order_By>>;
  where?: InputMaybe<Contributor_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type Third_Party_ResponseContributorsArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type Third_Party_ResponseContributors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contributor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contributor_Order_By>>;
  where?: InputMaybe<Contributor_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type Third_Party_ResponseFilesArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type Third_Party_ResponseFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Relation_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Relation_File_Order_By>>;
  where?: InputMaybe<Relation_File_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type Third_Party_ResponseInviteesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Invite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Invite_Order_By>>;
  where?: InputMaybe<Questionnaire_Invite_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type Third_Party_ResponseOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type Third_Party_ResponseOwnerGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Group_Order_By>>;
  where?: InputMaybe<Owner_Group_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type Third_Party_ResponseOwnersArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type Third_Party_ResponseOwners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Owner_Order_By>>;
  where?: InputMaybe<Owner_Bool_Exp>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Third_Party_Response_Append_Input = {
  ResponseData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.third_party_response". All fields are combined with a logical 'AND'. */
export type Third_Party_Response_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  ExpiresAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  ParentId?: InputMaybe<Uuid_Comparison_Exp>;
  QuestionnaireTemplateVersionId?: InputMaybe<Uuid_Comparison_Exp>;
  RecallReason?: InputMaybe<String_Comparison_Exp>;
  ResponseData?: InputMaybe<Jsonb_Comparison_Exp>;
  StartDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Status?: InputMaybe<Third_Party_Response_Status_Enum_Comparison_Exp>;
  _and?: InputMaybe<Array<Third_Party_Response_Bool_Exp>>;
  _not?: InputMaybe<Third_Party_Response_Bool_Exp>;
  _or?: InputMaybe<Array<Third_Party_Response_Bool_Exp>>;
  ancestorContributors?: InputMaybe<Ancestor_Contributor_Bool_Exp>;
  contributorGroups?: InputMaybe<Contributor_Group_Bool_Exp>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Bool_Exp>;
  contributors?: InputMaybe<Contributor_Bool_Exp>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  files?: InputMaybe<Relation_File_Bool_Exp>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Bool_Exp>;
  invitees?: InputMaybe<Questionnaire_Invite_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  ownerGroups?: InputMaybe<Owner_Group_Bool_Exp>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Bool_Exp>;
  owners?: InputMaybe<Owner_Bool_Exp>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Bool_Exp>;
  questionnaireTemplateVersion?: InputMaybe<Questionnaire_Template_Version_Bool_Exp>;
  thirdParty?: InputMaybe<Third_Party_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.third_party_response" */
export const Third_Party_Response_Constraint = {
  /** unique or primary key constraint on columns "Id" */
  ThirdPartyResponsePkey: 'third_party_response_pkey'
} as const;

export type Third_Party_Response_Constraint = typeof Third_Party_Response_Constraint[keyof typeof Third_Party_Response_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Third_Party_Response_Delete_At_Path_Input = {
  ResponseData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Third_Party_Response_Delete_Elem_Input = {
  ResponseData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Third_Party_Response_Delete_Key_Input = {
  ResponseData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.third_party_response" */
export type Third_Party_Response_Insert_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  QuestionnaireTemplateVersionId?: InputMaybe<Scalars['uuid']['input']>;
  RecallReason?: InputMaybe<Scalars['String']['input']>;
  ResponseData?: InputMaybe<Scalars['jsonb']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<Third_Party_Response_Status_Enum>;
  contributorGroups?: InputMaybe<Contributor_Group_Arr_Rel_Insert_Input>;
  contributors?: InputMaybe<Contributor_Arr_Rel_Insert_Input>;
  files?: InputMaybe<Relation_File_Arr_Rel_Insert_Input>;
  invitees?: InputMaybe<Questionnaire_Invite_Arr_Rel_Insert_Input>;
  ownerGroups?: InputMaybe<Owner_Group_Arr_Rel_Insert_Input>;
  owners?: InputMaybe<Owner_Arr_Rel_Insert_Input>;
  questionnaireTemplateVersion?: InputMaybe<Questionnaire_Template_Version_Obj_Rel_Insert_Input>;
  thirdParty?: InputMaybe<Third_Party_Obj_Rel_Insert_Input>;
};

/** response of any mutation on the table "risksmart.third_party_response" */
export type Third_Party_Response_Mutation_Response = {
  __typename?: 'third_party_response_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Third_Party_Response>;
};

/** input type for inserting object relation for remote table "risksmart.third_party_response" */
export type Third_Party_Response_Obj_Rel_Insert_Input = {
  data: Third_Party_Response_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Third_Party_Response_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.third_party_response" */
export type Third_Party_Response_On_Conflict = {
  constraint: Third_Party_Response_Constraint;
  update_columns?: Array<Third_Party_Response_Update_Column>;
  where?: InputMaybe<Third_Party_Response_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.third_party_response". */
export type Third_Party_Response_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ExpiresAt?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  ParentId?: InputMaybe<Order_By>;
  QuestionnaireTemplateVersionId?: InputMaybe<Order_By>;
  RecallReason?: InputMaybe<Order_By>;
  ResponseData?: InputMaybe<Order_By>;
  StartDate?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  ancestorContributors_aggregate?: InputMaybe<Ancestor_Contributor_Aggregate_Order_By>;
  contributorGroups_aggregate?: InputMaybe<Contributor_Group_Aggregate_Order_By>;
  contributors_aggregate?: InputMaybe<Contributor_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  files_aggregate?: InputMaybe<Relation_File_Aggregate_Order_By>;
  invitees_aggregate?: InputMaybe<Questionnaire_Invite_Aggregate_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  ownerGroups_aggregate?: InputMaybe<Owner_Group_Aggregate_Order_By>;
  owners_aggregate?: InputMaybe<Owner_Aggregate_Order_By>;
  questionnaireTemplateVersion?: InputMaybe<Questionnaire_Template_Version_Order_By>;
  thirdParty?: InputMaybe<Third_Party_Order_By>;
};

/** primary key columns input for table: risksmart.third_party_response */
export type Third_Party_Response_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Third_Party_Response_Prepend_Input = {
  ResponseData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.third_party_response" */
export const Third_Party_Response_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  ExpiresAt: 'ExpiresAt',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  QuestionnaireTemplateVersionId: 'QuestionnaireTemplateVersionId',
  /** column name */
  RecallReason: 'RecallReason',
  /** column name */
  ResponseData: 'ResponseData',
  /** column name */
  StartDate: 'StartDate',
  /** column name */
  Status: 'Status'
} as const;

export type Third_Party_Response_Select_Column = typeof Third_Party_Response_Select_Column[keyof typeof Third_Party_Response_Select_Column];
/** input type for updating data in table "risksmart.third_party_response" */
export type Third_Party_Response_Set_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  QuestionnaireTemplateVersionId?: InputMaybe<Scalars['uuid']['input']>;
  RecallReason?: InputMaybe<Scalars['String']['input']>;
  ResponseData?: InputMaybe<Scalars['jsonb']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<Third_Party_Response_Status_Enum>;
};

/** columns and relationships of "risksmart.third_party_response_status" */
export type Third_Party_Response_Status = {
  __typename?: 'third_party_response_status';
  Comment: Scalars['String']['output'];
  Value: Scalars['String']['output'];
};

/** Boolean expression to filter rows from the table "risksmart.third_party_response_status". All fields are combined with a logical 'AND'. */
export type Third_Party_Response_Status_Bool_Exp = {
  Comment?: InputMaybe<String_Comparison_Exp>;
  Value?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Third_Party_Response_Status_Bool_Exp>>;
  _not?: InputMaybe<Third_Party_Response_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Third_Party_Response_Status_Bool_Exp>>;
};

export const Third_Party_Response_Status_Enum = {
  /** Awaiting review */
  AwaitingReview: 'awaiting_review',
  /** Completed */
  Completed: 'completed',
  /** Expired */
  Expired: 'expired',
  /** In progress */
  InProgress: 'in_progress',
  /** Not started */
  NotStarted: 'not_started',
  /** The response has been recalled by a user */
  Recalled: 'recalled',
  /** Rejected */
  Rejected: 'rejected'
} as const;

export type Third_Party_Response_Status_Enum = typeof Third_Party_Response_Status_Enum[keyof typeof Third_Party_Response_Status_Enum];
/** Boolean expression to compare columns of type "third_party_response_status_enum". All fields are combined with logical 'AND'. */
export type Third_Party_Response_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Third_Party_Response_Status_Enum>;
  _in?: InputMaybe<Array<Third_Party_Response_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Third_Party_Response_Status_Enum>;
  _nin?: InputMaybe<Array<Third_Party_Response_Status_Enum>>;
};

/** Ordering options when selecting data from "risksmart.third_party_response_status". */
export type Third_Party_Response_Status_Order_By = {
  Comment?: InputMaybe<Order_By>;
  Value?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.third_party_response_status" */
export const Third_Party_Response_Status_Select_Column = {
  /** column name */
  Comment: 'Comment',
  /** column name */
  Value: 'Value'
} as const;

export type Third_Party_Response_Status_Select_Column = typeof Third_Party_Response_Status_Select_Column[keyof typeof Third_Party_Response_Status_Select_Column];
/** Streaming cursor of the table "third_party_response_status" */
export type Third_Party_Response_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Third_Party_Response_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Third_Party_Response_Status_Stream_Cursor_Value_Input = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "third_party_response" */
export type Third_Party_Response_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Third_Party_Response_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Third_Party_Response_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  QuestionnaireTemplateVersionId?: InputMaybe<Scalars['uuid']['input']>;
  RecallReason?: InputMaybe<Scalars['String']['input']>;
  ResponseData?: InputMaybe<Scalars['jsonb']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<Third_Party_Response_Status_Enum>;
};

/** update columns of table "risksmart.third_party_response" */
export const Third_Party_Response_Update_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  ExpiresAt: 'ExpiresAt',
  /** column name */
  ParentId: 'ParentId',
  /** column name */
  QuestionnaireTemplateVersionId: 'QuestionnaireTemplateVersionId',
  /** column name */
  RecallReason: 'RecallReason',
  /** column name */
  ResponseData: 'ResponseData',
  /** column name */
  StartDate: 'StartDate',
  /** column name */
  Status: 'Status'
} as const;

export type Third_Party_Response_Update_Column = typeof Third_Party_Response_Update_Column[keyof typeof Third_Party_Response_Update_Column];
export type Third_Party_Response_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Third_Party_Response_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Third_Party_Response_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Third_Party_Response_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Third_Party_Response_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Third_Party_Response_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Third_Party_Response_Set_Input>;
  /** filter the rows which have to be updated */
  where: Third_Party_Response_Bool_Exp;
};

/** select columns of table "risksmart.third_party" */
export const Third_Party_Select_Column = {
  /** column name */
  Address: 'Address',
  /** column name */
  CityTown: 'CityTown',
  /** column name */
  CompaniesHouseNumber: 'CompaniesHouseNumber',
  /** column name */
  CompanyDomain: 'CompanyDomain',
  /** column name */
  CompanyName: 'CompanyName',
  /** column name */
  ContactEmail: 'ContactEmail',
  /** column name */
  ContactName: 'ContactName',
  /** column name */
  Country: 'Country',
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Criticality: 'Criticality',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Postcode: 'Postcode',
  /** column name */
  PrimaryContactName: 'PrimaryContactName',
  /** column name */
  SequentialId: 'SequentialId',
  /** column name */
  Status: 'Status',
  /** column name */
  Title: 'Title',
  /** column name */
  Type: 'Type'
} as const;

export type Third_Party_Select_Column = typeof Third_Party_Select_Column[keyof typeof Third_Party_Select_Column];
/** input type for updating data in table "risksmart.third_party" */
export type Third_Party_Set_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  CityTown?: InputMaybe<Scalars['String']['input']>;
  CompaniesHouseNumber?: InputMaybe<Scalars['String']['input']>;
  CompanyDomain?: InputMaybe<Scalars['String']['input']>;
  CompanyName?: InputMaybe<Scalars['String']['input']>;
  ContactEmail?: InputMaybe<Scalars['String']['input']>;
  ContactName?: InputMaybe<Scalars['String']['input']>;
  Country?: InputMaybe<Scalars['String']['input']>;
  Criticality?: InputMaybe<Scalars['Int']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Postcode?: InputMaybe<Scalars['String']['input']>;
  PrimaryContactName?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Third_Party_Status_Enum>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Third_Party_Type_Enum>;
};

export const Third_Party_Status_Enum = {
  /** Active */
  Active: 'active',
  /** Archived */
  Archived: 'archived',
  /** Pre-Contract */
  PreContract: 'pre_contract',
  /** Review Due */
  ReviewDue: 'review_due'
} as const;

export type Third_Party_Status_Enum = typeof Third_Party_Status_Enum[keyof typeof Third_Party_Status_Enum];
/** Boolean expression to compare columns of type "third_party_status_enum". All fields are combined with logical 'AND'. */
export type Third_Party_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Third_Party_Status_Enum>;
  _in?: InputMaybe<Array<Third_Party_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Third_Party_Status_Enum>;
  _nin?: InputMaybe<Array<Third_Party_Status_Enum>>;
};

/** Streaming cursor of the table "third_party" */
export type Third_Party_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Third_Party_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Third_Party_Stream_Cursor_Value_Input = {
  Address?: InputMaybe<Scalars['String']['input']>;
  CityTown?: InputMaybe<Scalars['String']['input']>;
  CompaniesHouseNumber?: InputMaybe<Scalars['String']['input']>;
  CompanyDomain?: InputMaybe<Scalars['String']['input']>;
  CompanyName?: InputMaybe<Scalars['String']['input']>;
  ContactEmail?: InputMaybe<Scalars['String']['input']>;
  ContactName?: InputMaybe<Scalars['String']['input']>;
  Country?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Criticality?: InputMaybe<Scalars['Int']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Postcode?: InputMaybe<Scalars['String']['input']>;
  PrimaryContactName?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Third_Party_Status_Enum>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Third_Party_Type_Enum>;
};

export const Third_Party_Type_Enum = {
  /** Consultant */
  Consultant: 'consultant',
  /** Managed Service */
  ManagedService: 'managed_service',
  /** Outsource */
  Outsource: 'outsource',
  /** Partner */
  Partner: 'partner',
  /** Supplier */
  Supplier: 'supplier'
} as const;

export type Third_Party_Type_Enum = typeof Third_Party_Type_Enum[keyof typeof Third_Party_Type_Enum];
/** Boolean expression to compare columns of type "third_party_type_enum". All fields are combined with logical 'AND'. */
export type Third_Party_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Third_Party_Type_Enum>;
  _in?: InputMaybe<Array<Third_Party_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Third_Party_Type_Enum>;
  _nin?: InputMaybe<Array<Third_Party_Type_Enum>>;
};

/** update columns of table "risksmart.third_party" */
export const Third_Party_Update_Column = {
  /** column name */
  Address: 'Address',
  /** column name */
  CityTown: 'CityTown',
  /** column name */
  CompaniesHouseNumber: 'CompaniesHouseNumber',
  /** column name */
  CompanyDomain: 'CompanyDomain',
  /** column name */
  CompanyName: 'CompanyName',
  /** column name */
  ContactEmail: 'ContactEmail',
  /** column name */
  ContactName: 'ContactName',
  /** column name */
  Country: 'Country',
  /** column name */
  Criticality: 'Criticality',
  /** column name */
  CustomAttributeData: 'CustomAttributeData',
  /** column name */
  Description: 'Description',
  /** column name */
  Postcode: 'Postcode',
  /** column name */
  PrimaryContactName: 'PrimaryContactName',
  /** column name */
  Status: 'Status',
  /** column name */
  Title: 'Title',
  /** column name */
  Type: 'Type'
} as const;

export type Third_Party_Update_Column = typeof Third_Party_Update_Column[keyof typeof Third_Party_Update_Column];
export type Third_Party_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Third_Party_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Third_Party_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Third_Party_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Third_Party_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Third_Party_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Third_Party_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Third_Party_Set_Input>;
  /** filter the rows which have to be updated */
  where: Third_Party_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

export const Unit_Of_Time_Enum = {
  /** Day */
  Day: 'day',
  /** Week */
  Week: 'week'
} as const;

export type Unit_Of_Time_Enum = typeof Unit_Of_Time_Enum[keyof typeof Unit_Of_Time_Enum];
/** Boolean expression to compare columns of type "unit_of_time_enum". All fields are combined with logical 'AND'. */
export type Unit_Of_Time_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Unit_Of_Time_Enum>;
  _in?: InputMaybe<Array<Unit_Of_Time_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Unit_Of_Time_Enum>;
  _nin?: InputMaybe<Array<Unit_Of_Time_Enum>>;
};

/** columns and relationships of "risksmart.user_view_active" */
export type User = {
  __typename?: 'user';
  AuthConnection?: Maybe<Scalars['String']['output']>;
  BusinessUnit_Id?: Maybe<Scalars['uuid']['output']>;
  Department?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FriendlyName?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  IsCustomerSupport?: Maybe<Scalars['Boolean']['output']>;
  JobTitle?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LastSeen?: Maybe<Scalars['timestamptz']['output']>;
  OfficeLocation?: Maybe<Scalars['String']['output']>;
  RoleKey?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  UserName?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  group_memberships: Array<User_Group_User>;
  /** An aggregate relationship */
  group_memberships_aggregate: User_Group_User_Aggregate;
};


/** columns and relationships of "risksmart.user_view_active" */
export type UserGroup_MembershipsArgs = {
  distinct_on?: InputMaybe<Array<User_Group_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Group_User_Order_By>>;
  where?: InputMaybe<User_Group_User_Bool_Exp>;
};


/** columns and relationships of "risksmart.user_view_active" */
export type UserGroup_Memberships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Group_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Group_User_Order_By>>;
  where?: InputMaybe<User_Group_User_Bool_Exp>;
};

/** aggregated selection of "risksmart.user_view_active" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "risksmart.user_view_active" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};


/** aggregate fields of "risksmart.user_view_active" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.user_view_active". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  AuthConnection?: InputMaybe<String_Comparison_Exp>;
  BusinessUnit_Id?: InputMaybe<Uuid_Comparison_Exp>;
  Department?: InputMaybe<String_Comparison_Exp>;
  Email?: InputMaybe<String_Comparison_Exp>;
  FirstName?: InputMaybe<String_Comparison_Exp>;
  FriendlyName?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<String_Comparison_Exp>;
  IsCustomerSupport?: InputMaybe<Boolean_Comparison_Exp>;
  JobTitle?: InputMaybe<String_Comparison_Exp>;
  LastName?: InputMaybe<String_Comparison_Exp>;
  LastSeen?: InputMaybe<Timestamptz_Comparison_Exp>;
  OfficeLocation?: InputMaybe<String_Comparison_Exp>;
  RoleKey?: InputMaybe<String_Comparison_Exp>;
  Status?: InputMaybe<String_Comparison_Exp>;
  UserName?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  group_memberships?: InputMaybe<User_Group_User_Bool_Exp>;
  group_memberships_aggregate?: InputMaybe<User_Group_User_Aggregate_Bool_Exp>;
};

/** columns and relationships of "risksmart.user_group" */
export type User_Group = {
  __typename?: 'user_group';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Description?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  OwnerContributor: Scalars['Boolean']['output'];
  /** An array relationship */
  approvers: Array<Approver>;
  /** An aggregate relationship */
  approvers_aggregate: Approver_Aggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: Auth_Organisation;
  /** An array relationship */
  users: Array<User_Group_User>;
  /** An aggregate relationship */
  users_aggregate: User_Group_User_Aggregate;
};


/** columns and relationships of "risksmart.user_group" */
export type User_GroupApproversArgs = {
  distinct_on?: InputMaybe<Array<Approver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Approver_Order_By>>;
  where?: InputMaybe<Approver_Bool_Exp>;
};


/** columns and relationships of "risksmart.user_group" */
export type User_GroupApprovers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Approver_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Approver_Order_By>>;
  where?: InputMaybe<Approver_Bool_Exp>;
};


/** columns and relationships of "risksmart.user_group" */
export type User_GroupUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Group_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Group_User_Order_By>>;
  where?: InputMaybe<User_Group_User_Bool_Exp>;
};


/** columns and relationships of "risksmart.user_group" */
export type User_GroupUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Group_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Group_User_Order_By>>;
  where?: InputMaybe<User_Group_User_Bool_Exp>;
};

/** columns and relationships of "risksmart.user_group_audit" */
export type User_Group_Audit = {
  __typename?: 'user_group_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Description?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name: Scalars['String']['output'];
  OwnerContributor?: Maybe<Scalars['Boolean']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.user_group_audit". All fields are combined with a logical 'AND'. */
export type User_Group_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Email?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  OwnerContributor?: InputMaybe<Boolean_Comparison_Exp>;
  _and?: InputMaybe<Array<User_Group_Audit_Bool_Exp>>;
  _not?: InputMaybe<User_Group_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<User_Group_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.user_group_audit". */
export type User_Group_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  OwnerContributor?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.user_group_audit" */
export const User_Group_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Description: 'Description',
  /** column name */
  Email: 'Email',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Name: 'Name',
  /** column name */
  OwnerContributor: 'OwnerContributor'
} as const;

export type User_Group_Audit_Select_Column = typeof User_Group_Audit_Select_Column[keyof typeof User_Group_Audit_Select_Column];
/** Streaming cursor of the table "user_group_audit" */
export type User_Group_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Group_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Group_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OwnerContributor?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.user_group". All fields are combined with a logical 'AND'. */
export type User_Group_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  Description?: InputMaybe<String_Comparison_Exp>;
  Email?: InputMaybe<String_Comparison_Exp>;
  Id?: InputMaybe<Uuid_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  Name?: InputMaybe<String_Comparison_Exp>;
  OwnerContributor?: InputMaybe<Boolean_Comparison_Exp>;
  _and?: InputMaybe<Array<User_Group_Bool_Exp>>;
  _not?: InputMaybe<User_Group_Bool_Exp>;
  _or?: InputMaybe<Array<User_Group_Bool_Exp>>;
  approvers?: InputMaybe<Approver_Bool_Exp>;
  approvers_aggregate?: InputMaybe<Approver_Aggregate_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  modifiedByUser?: InputMaybe<User_Bool_Exp>;
  organisation?: InputMaybe<Auth_Organisation_Bool_Exp>;
  users?: InputMaybe<User_Group_User_Bool_Exp>;
  users_aggregate?: InputMaybe<User_Group_User_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.user_group" */
export const User_Group_Constraint = {
  /** unique or primary key constraint on columns "Name", "OrgKey" */
  UniqueOrgkeyNameConstraint: 'unique_orgkey_name_constraint',
  /** unique or primary key constraint on columns "Id" */
  UserGroupPkey: 'user_group_pkey'
} as const;

export type User_Group_Constraint = typeof User_Group_Constraint[keyof typeof User_Group_Constraint];
/** input type for inserting data into table "risksmart.user_group" */
export type User_Group_Insert_Input = {
  Description?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OwnerContributor?: InputMaybe<Scalars['Boolean']['input']>;
  approvers?: InputMaybe<Approver_Arr_Rel_Insert_Input>;
  users?: InputMaybe<User_Group_User_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "risksmart.user_group" */
export type User_Group_Mutation_Response = {
  __typename?: 'user_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Group>;
};

/** input type for inserting object relation for remote table "risksmart.user_group" */
export type User_Group_Obj_Rel_Insert_Input = {
  data: User_Group_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Group_On_Conflict>;
};

/** on_conflict condition type for table "risksmart.user_group" */
export type User_Group_On_Conflict = {
  constraint: User_Group_Constraint;
  update_columns?: Array<User_Group_Update_Column>;
  where?: InputMaybe<User_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.user_group". */
export type User_Group_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  Description?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  Name?: InputMaybe<Order_By>;
  OwnerContributor?: InputMaybe<Order_By>;
  approvers_aggregate?: InputMaybe<Approver_Aggregate_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  modifiedByUser?: InputMaybe<User_Order_By>;
  organisation?: InputMaybe<Auth_Organisation_Order_By>;
  users_aggregate?: InputMaybe<User_Group_User_Aggregate_Order_By>;
};

/** primary key columns input for table: risksmart.user_group */
export type User_Group_Pk_Columns_Input = {
  Id: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.user_group" */
export const User_Group_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  Description: 'Description',
  /** column name */
  Email: 'Email',
  /** column name */
  Id: 'Id',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  Name: 'Name',
  /** column name */
  OwnerContributor: 'OwnerContributor'
} as const;

export type User_Group_Select_Column = typeof User_Group_Select_Column[keyof typeof User_Group_Select_Column];
/** input type for updating data in table "risksmart.user_group" */
export type User_Group_Set_Input = {
  Description?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OwnerContributor?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "user_group" */
export type User_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Group_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Group_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OwnerContributor?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "risksmart.user_group" */
export const User_Group_Update_Column = {
  /** column name */
  Description: 'Description',
  /** column name */
  Email: 'Email',
  /** column name */
  Name: 'Name',
  /** column name */
  OwnerContributor: 'OwnerContributor'
} as const;

export type User_Group_Update_Column = typeof User_Group_Update_Column[keyof typeof User_Group_Update_Column];
export type User_Group_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Group_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Group_Bool_Exp;
};

/** columns and relationships of "risksmart.user_group_user" */
export type User_Group_User = {
  __typename?: 'user_group_user';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  UserGroupId: Scalars['uuid']['output'];
  UserId: Scalars['String']['output'];
  /** An object relationship */
  authUsers: Auth_User;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  user?: Maybe<User>;
  /** An object relationship */
  userGroups: User_Group;
};

/** aggregated selection of "risksmart.user_group_user" */
export type User_Group_User_Aggregate = {
  __typename?: 'user_group_user_aggregate';
  aggregate?: Maybe<User_Group_User_Aggregate_Fields>;
  nodes: Array<User_Group_User>;
};

export type User_Group_User_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Group_User_Aggregate_Bool_Exp_Count>;
};

export type User_Group_User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Group_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Group_User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risksmart.user_group_user" */
export type User_Group_User_Aggregate_Fields = {
  __typename?: 'user_group_user_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Group_User_Max_Fields>;
  min?: Maybe<User_Group_User_Min_Fields>;
};


/** aggregate fields of "risksmart.user_group_user" */
export type User_Group_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Group_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.user_group_user" */
export type User_Group_User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Group_User_Max_Order_By>;
  min?: InputMaybe<User_Group_User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risksmart.user_group_user" */
export type User_Group_User_Arr_Rel_Insert_Input = {
  data: Array<User_Group_User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Group_User_On_Conflict>;
};

/** columns and relationships of "risksmart.user_group_user_audit" */
export type User_Group_User_Audit = {
  __typename?: 'user_group_user_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  UserGroupId: Scalars['uuid']['output'];
  UserId: Scalars['String']['output'];
};

/** Boolean expression to filter rows from the table "risksmart.user_group_user_audit". All fields are combined with a logical 'AND'. */
export type User_Group_User_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  UserGroupId?: InputMaybe<Uuid_Comparison_Exp>;
  UserId?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<User_Group_User_Audit_Bool_Exp>>;
  _not?: InputMaybe<User_Group_User_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<User_Group_User_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.user_group_user_audit". */
export type User_Group_User_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.user_group_user_audit" */
export const User_Group_User_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  UserGroupId: 'UserGroupId',
  /** column name */
  UserId: 'UserId'
} as const;

export type User_Group_User_Audit_Select_Column = typeof User_Group_User_Audit_Select_Column[keyof typeof User_Group_User_Audit_Select_Column];
/** Streaming cursor of the table "user_group_user_audit" */
export type User_Group_User_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Group_User_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Group_User_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.user_group_user". All fields are combined with a logical 'AND'. */
export type User_Group_User_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  UserGroupId?: InputMaybe<Uuid_Comparison_Exp>;
  UserId?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<User_Group_User_Bool_Exp>>;
  _not?: InputMaybe<User_Group_User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Group_User_Bool_Exp>>;
  authUsers?: InputMaybe<Auth_User_Bool_Exp>;
  createdByUser?: InputMaybe<User_Bool_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userGroups?: InputMaybe<User_Group_Bool_Exp>;
};

/** unique or primary key constraints on table "risksmart.user_group_user" */
export const User_Group_User_Constraint = {
  /** unique or primary key constraint on columns "UserGroupId", "UserId" */
  UserGroupUserPkey: 'user_group_user_pkey'
} as const;

export type User_Group_User_Constraint = typeof User_Group_User_Constraint[keyof typeof User_Group_User_Constraint];
/** input type for inserting data into table "risksmart.user_group_user" */
export type User_Group_User_Insert_Input = {
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
  userGroups?: InputMaybe<User_Group_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Group_User_Max_Fields = {
  __typename?: 'user_group_user_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.user_group_user" */
export type User_Group_User_Max_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Group_User_Min_Fields = {
  __typename?: 'user_group_user_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.user_group_user" */
export type User_Group_User_Min_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risksmart.user_group_user" */
export type User_Group_User_Mutation_Response = {
  __typename?: 'user_group_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Group_User>;
};

/** on_conflict condition type for table "risksmart.user_group_user" */
export type User_Group_User_On_Conflict = {
  constraint: User_Group_User_Constraint;
  update_columns?: Array<User_Group_User_Update_Column>;
  where?: InputMaybe<User_Group_User_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.user_group_user". */
export type User_Group_User_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  UserGroupId?: InputMaybe<Order_By>;
  UserId?: InputMaybe<Order_By>;
  authUsers?: InputMaybe<Auth_User_Order_By>;
  createdByUser?: InputMaybe<User_Order_By>;
  user?: InputMaybe<User_Order_By>;
  userGroups?: InputMaybe<User_Group_Order_By>;
};

/** select columns of table "risksmart.user_group_user" */
export const User_Group_User_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  UserGroupId: 'UserGroupId',
  /** column name */
  UserId: 'UserId'
} as const;

export type User_Group_User_Select_Column = typeof User_Group_User_Select_Column[keyof typeof User_Group_User_Select_Column];
/** Streaming cursor of the table "user_group_user" */
export type User_Group_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Group_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Group_User_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** placeholder for update columns of table "risksmart.user_group_user" (current role has no relevant permissions) */
export const User_Group_User_Update_Column = {
  /** placeholder (do not use) */
  Placeholder: '_PLACEHOLDER'
} as const;

export type User_Group_User_Update_Column = typeof User_Group_User_Update_Column[keyof typeof User_Group_User_Update_Column];
/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  AuthConnection?: Maybe<Scalars['String']['output']>;
  BusinessUnit_Id?: Maybe<Scalars['uuid']['output']>;
  Department?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FriendlyName?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  JobTitle?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LastSeen?: Maybe<Scalars['timestamptz']['output']>;
  OfficeLocation?: Maybe<Scalars['String']['output']>;
  RoleKey?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  UserName?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  AuthConnection?: Maybe<Scalars['String']['output']>;
  BusinessUnit_Id?: Maybe<Scalars['uuid']['output']>;
  Department?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FriendlyName?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  JobTitle?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LastSeen?: Maybe<Scalars['timestamptz']['output']>;
  OfficeLocation?: Maybe<Scalars['String']['output']>;
  RoleKey?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  UserName?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "risksmart.user_view_active". */
export type User_Order_By = {
  AuthConnection?: InputMaybe<Order_By>;
  BusinessUnit_Id?: InputMaybe<Order_By>;
  Department?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  FirstName?: InputMaybe<Order_By>;
  FriendlyName?: InputMaybe<Order_By>;
  Id?: InputMaybe<Order_By>;
  IsCustomerSupport?: InputMaybe<Order_By>;
  JobTitle?: InputMaybe<Order_By>;
  LastName?: InputMaybe<Order_By>;
  LastSeen?: InputMaybe<Order_By>;
  OfficeLocation?: InputMaybe<Order_By>;
  RoleKey?: InputMaybe<Order_By>;
  Status?: InputMaybe<Order_By>;
  UserName?: InputMaybe<Order_By>;
  group_memberships_aggregate?: InputMaybe<User_Group_User_Aggregate_Order_By>;
};

/** columns and relationships of "risksmart.user_search_preferences" */
export type User_Search_Preferences = {
  __typename?: 'user_search_preferences';
  FilterByActivePlatformUsers: Scalars['Boolean']['output'];
  RecentUserIds: Array<Scalars['String']['output']>;
  ShowArchivedUsers: Scalars['Boolean']['output'];
  ShowDirectoryDepartment: Scalars['Boolean']['output'];
  ShowGroups: Scalars['Boolean']['output'];
  ShowInheritedContributors: Scalars['Boolean']['output'];
  ShowUserEmail: Scalars['Boolean']['output'];
  ShowUserJobTitle: Scalars['Boolean']['output'];
  ShowUserLocation: Scalars['Boolean']['output'];
  ShowUserPlatformRole: Scalars['Boolean']['output'];
};

/** columns and relationships of "risksmart.user_search_preferences_audit" */
export type User_Search_Preferences_Audit = {
  __typename?: 'user_search_preferences_audit';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  FilterByActivePlatformUsers: Scalars['Boolean']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  RecentUserIds: Array<Scalars['String']['output']>;
  ShowArchivedUsers: Scalars['Boolean']['output'];
  ShowDirectoryDepartment: Scalars['Boolean']['output'];
  ShowGroups: Scalars['Boolean']['output'];
  ShowUserEmail: Scalars['Boolean']['output'];
  ShowUserJobTitle: Scalars['Boolean']['output'];
  ShowUserLocation: Scalars['Boolean']['output'];
  ShowUserPlatformRole: Scalars['Boolean']['output'];
};

/** Boolean expression to filter rows from the table "risksmart.user_search_preferences_audit". All fields are combined with a logical 'AND'. */
export type User_Search_Preferences_Audit_Bool_Exp = {
  CreatedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  CreatedByUser?: InputMaybe<String_Comparison_Exp>;
  FilterByActivePlatformUsers?: InputMaybe<Boolean_Comparison_Exp>;
  ModifiedAtTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  ModifiedByUser?: InputMaybe<String_Comparison_Exp>;
  RecentUserIds?: InputMaybe<String_Array_Comparison_Exp>;
  ShowArchivedUsers?: InputMaybe<Boolean_Comparison_Exp>;
  ShowDirectoryDepartment?: InputMaybe<Boolean_Comparison_Exp>;
  ShowGroups?: InputMaybe<Boolean_Comparison_Exp>;
  ShowUserEmail?: InputMaybe<Boolean_Comparison_Exp>;
  ShowUserJobTitle?: InputMaybe<Boolean_Comparison_Exp>;
  ShowUserLocation?: InputMaybe<Boolean_Comparison_Exp>;
  ShowUserPlatformRole?: InputMaybe<Boolean_Comparison_Exp>;
  _and?: InputMaybe<Array<User_Search_Preferences_Audit_Bool_Exp>>;
  _not?: InputMaybe<User_Search_Preferences_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<User_Search_Preferences_Audit_Bool_Exp>>;
};

/** Ordering options when selecting data from "risksmart.user_search_preferences_audit". */
export type User_Search_Preferences_Audit_Order_By = {
  CreatedAtTimestamp?: InputMaybe<Order_By>;
  CreatedByUser?: InputMaybe<Order_By>;
  FilterByActivePlatformUsers?: InputMaybe<Order_By>;
  ModifiedAtTimestamp?: InputMaybe<Order_By>;
  ModifiedByUser?: InputMaybe<Order_By>;
  RecentUserIds?: InputMaybe<Order_By>;
  ShowArchivedUsers?: InputMaybe<Order_By>;
  ShowDirectoryDepartment?: InputMaybe<Order_By>;
  ShowGroups?: InputMaybe<Order_By>;
  ShowUserEmail?: InputMaybe<Order_By>;
  ShowUserJobTitle?: InputMaybe<Order_By>;
  ShowUserLocation?: InputMaybe<Order_By>;
  ShowUserPlatformRole?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.user_search_preferences_audit" */
export const User_Search_Preferences_Audit_Select_Column = {
  /** column name */
  CreatedAtTimestamp: 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser: 'CreatedByUser',
  /** column name */
  FilterByActivePlatformUsers: 'FilterByActivePlatformUsers',
  /** column name */
  ModifiedAtTimestamp: 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser: 'ModifiedByUser',
  /** column name */
  RecentUserIds: 'RecentUserIds',
  /** column name */
  ShowArchivedUsers: 'ShowArchivedUsers',
  /** column name */
  ShowDirectoryDepartment: 'ShowDirectoryDepartment',
  /** column name */
  ShowGroups: 'ShowGroups',
  /** column name */
  ShowUserEmail: 'ShowUserEmail',
  /** column name */
  ShowUserJobTitle: 'ShowUserJobTitle',
  /** column name */
  ShowUserLocation: 'ShowUserLocation',
  /** column name */
  ShowUserPlatformRole: 'ShowUserPlatformRole'
} as const;

export type User_Search_Preferences_Audit_Select_Column = typeof User_Search_Preferences_Audit_Select_Column[keyof typeof User_Search_Preferences_Audit_Select_Column];
/** Streaming cursor of the table "user_search_preferences_audit" */
export type User_Search_Preferences_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Search_Preferences_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Search_Preferences_Audit_Stream_Cursor_Value_Input = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FilterByActivePlatformUsers?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  RecentUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ShowArchivedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  ShowDirectoryDepartment?: InputMaybe<Scalars['Boolean']['input']>;
  ShowGroups?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserEmail?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserJobTitle?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserLocation?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserPlatformRole?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.user_search_preferences". All fields are combined with a logical 'AND'. */
export type User_Search_Preferences_Bool_Exp = {
  FilterByActivePlatformUsers?: InputMaybe<Boolean_Comparison_Exp>;
  RecentUserIds?: InputMaybe<String_Array_Comparison_Exp>;
  ShowArchivedUsers?: InputMaybe<Boolean_Comparison_Exp>;
  ShowDirectoryDepartment?: InputMaybe<Boolean_Comparison_Exp>;
  ShowGroups?: InputMaybe<Boolean_Comparison_Exp>;
  ShowInheritedContributors?: InputMaybe<Boolean_Comparison_Exp>;
  ShowUserEmail?: InputMaybe<Boolean_Comparison_Exp>;
  ShowUserJobTitle?: InputMaybe<Boolean_Comparison_Exp>;
  ShowUserLocation?: InputMaybe<Boolean_Comparison_Exp>;
  ShowUserPlatformRole?: InputMaybe<Boolean_Comparison_Exp>;
  _and?: InputMaybe<Array<User_Search_Preferences_Bool_Exp>>;
  _not?: InputMaybe<User_Search_Preferences_Bool_Exp>;
  _or?: InputMaybe<Array<User_Search_Preferences_Bool_Exp>>;
};

/** unique or primary key constraints on table "risksmart.user_search_preferences" */
export const User_Search_Preferences_Constraint = {
  /** unique or primary key constraint on columns "CreatedByUser", "OrgKey" */
  RecentUsersPkey: 'recent_users_pkey'
} as const;

export type User_Search_Preferences_Constraint = typeof User_Search_Preferences_Constraint[keyof typeof User_Search_Preferences_Constraint];
/** input type for inserting data into table "risksmart.user_search_preferences" */
export type User_Search_Preferences_Insert_Input = {
  FilterByActivePlatformUsers?: InputMaybe<Scalars['Boolean']['input']>;
  RecentUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ShowArchivedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  ShowDirectoryDepartment?: InputMaybe<Scalars['Boolean']['input']>;
  ShowGroups?: InputMaybe<Scalars['Boolean']['input']>;
  ShowInheritedContributors?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserEmail?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserJobTitle?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserLocation?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserPlatformRole?: InputMaybe<Scalars['Boolean']['input']>;
};

/** response of any mutation on the table "risksmart.user_search_preferences" */
export type User_Search_Preferences_Mutation_Response = {
  __typename?: 'user_search_preferences_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Search_Preferences>;
};

/** on_conflict condition type for table "risksmart.user_search_preferences" */
export type User_Search_Preferences_On_Conflict = {
  constraint: User_Search_Preferences_Constraint;
  update_columns?: Array<User_Search_Preferences_Update_Column>;
  where?: InputMaybe<User_Search_Preferences_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.user_search_preferences". */
export type User_Search_Preferences_Order_By = {
  FilterByActivePlatformUsers?: InputMaybe<Order_By>;
  RecentUserIds?: InputMaybe<Order_By>;
  ShowArchivedUsers?: InputMaybe<Order_By>;
  ShowDirectoryDepartment?: InputMaybe<Order_By>;
  ShowGroups?: InputMaybe<Order_By>;
  ShowInheritedContributors?: InputMaybe<Order_By>;
  ShowUserEmail?: InputMaybe<Order_By>;
  ShowUserJobTitle?: InputMaybe<Order_By>;
  ShowUserLocation?: InputMaybe<Order_By>;
  ShowUserPlatformRole?: InputMaybe<Order_By>;
};

/** select columns of table "risksmart.user_search_preferences" */
export const User_Search_Preferences_Select_Column = {
  /** column name */
  FilterByActivePlatformUsers: 'FilterByActivePlatformUsers',
  /** column name */
  RecentUserIds: 'RecentUserIds',
  /** column name */
  ShowArchivedUsers: 'ShowArchivedUsers',
  /** column name */
  ShowDirectoryDepartment: 'ShowDirectoryDepartment',
  /** column name */
  ShowGroups: 'ShowGroups',
  /** column name */
  ShowInheritedContributors: 'ShowInheritedContributors',
  /** column name */
  ShowUserEmail: 'ShowUserEmail',
  /** column name */
  ShowUserJobTitle: 'ShowUserJobTitle',
  /** column name */
  ShowUserLocation: 'ShowUserLocation',
  /** column name */
  ShowUserPlatformRole: 'ShowUserPlatformRole'
} as const;

export type User_Search_Preferences_Select_Column = typeof User_Search_Preferences_Select_Column[keyof typeof User_Search_Preferences_Select_Column];
/** input type for updating data in table "risksmart.user_search_preferences" */
export type User_Search_Preferences_Set_Input = {
  FilterByActivePlatformUsers?: InputMaybe<Scalars['Boolean']['input']>;
  RecentUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ShowArchivedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  ShowDirectoryDepartment?: InputMaybe<Scalars['Boolean']['input']>;
  ShowGroups?: InputMaybe<Scalars['Boolean']['input']>;
  ShowInheritedContributors?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserEmail?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserJobTitle?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserLocation?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserPlatformRole?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "user_search_preferences" */
export type User_Search_Preferences_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Search_Preferences_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Search_Preferences_Stream_Cursor_Value_Input = {
  FilterByActivePlatformUsers?: InputMaybe<Scalars['Boolean']['input']>;
  RecentUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ShowArchivedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  ShowDirectoryDepartment?: InputMaybe<Scalars['Boolean']['input']>;
  ShowGroups?: InputMaybe<Scalars['Boolean']['input']>;
  ShowInheritedContributors?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserEmail?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserJobTitle?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserLocation?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserPlatformRole?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "risksmart.user_search_preferences" */
export const User_Search_Preferences_Update_Column = {
  /** column name */
  FilterByActivePlatformUsers: 'FilterByActivePlatformUsers',
  /** column name */
  RecentUserIds: 'RecentUserIds',
  /** column name */
  ShowArchivedUsers: 'ShowArchivedUsers',
  /** column name */
  ShowDirectoryDepartment: 'ShowDirectoryDepartment',
  /** column name */
  ShowGroups: 'ShowGroups',
  /** column name */
  ShowInheritedContributors: 'ShowInheritedContributors',
  /** column name */
  ShowUserEmail: 'ShowUserEmail',
  /** column name */
  ShowUserJobTitle: 'ShowUserJobTitle',
  /** column name */
  ShowUserLocation: 'ShowUserLocation',
  /** column name */
  ShowUserPlatformRole: 'ShowUserPlatformRole'
} as const;

export type User_Search_Preferences_Update_Column = typeof User_Search_Preferences_Update_Column[keyof typeof User_Search_Preferences_Update_Column];
export type User_Search_Preferences_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Search_Preferences_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Search_Preferences_Bool_Exp;
};

/** select columns of table "risksmart.user_view_active" */
export const User_Select_Column = {
  /** column name */
  AuthConnection: 'AuthConnection',
  /** column name */
  BusinessUnitId: 'BusinessUnit_Id',
  /** column name */
  Department: 'Department',
  /** column name */
  Email: 'Email',
  /** column name */
  FirstName: 'FirstName',
  /** column name */
  FriendlyName: 'FriendlyName',
  /** column name */
  Id: 'Id',
  /** column name */
  IsCustomerSupport: 'IsCustomerSupport',
  /** column name */
  JobTitle: 'JobTitle',
  /** column name */
  LastName: 'LastName',
  /** column name */
  LastSeen: 'LastSeen',
  /** column name */
  OfficeLocation: 'OfficeLocation',
  /** column name */
  RoleKey: 'RoleKey',
  /** column name */
  Status: 'Status',
  /** column name */
  UserName: 'UserName'
} as const;

export type User_Select_Column = typeof User_Select_Column[keyof typeof User_Select_Column];
export const User_Status_Enum = {
  /** Active */
  Active: 'active',
  /** Archived */
  Archived: 'archived'
} as const;

export type User_Status_Enum = typeof User_Status_Enum[keyof typeof User_Status_Enum];
/** Boolean expression to compare columns of type "user_status_enum". All fields are combined with logical 'AND'. */
export type User_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<User_Status_Enum>;
  _in?: InputMaybe<Array<User_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<User_Status_Enum>;
  _nin?: InputMaybe<Array<User_Status_Enum>>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  AuthConnection?: InputMaybe<Scalars['String']['input']>;
  BusinessUnit_Id?: InputMaybe<Scalars['uuid']['input']>;
  Department?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  FriendlyName?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['String']['input']>;
  IsCustomerSupport?: InputMaybe<Scalars['Boolean']['input']>;
  JobTitle?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  LastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  OfficeLocation?: InputMaybe<Scalars['String']['input']>;
  RoleKey?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  UserName?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.user_table_preferences" */
export type User_Table_Preferences = {
  __typename?: 'user_table_preferences';
  Preferences: Scalars['jsonb']['output'];
  TableId: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.user_table_preferences" */
export type User_Table_PreferencesPreferencesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Table_Preferences_Append_Input = {
  Preferences?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.user_table_preferences". All fields are combined with a logical 'AND'. */
export type User_Table_Preferences_Bool_Exp = {
  Preferences?: InputMaybe<Jsonb_Comparison_Exp>;
  TableId?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<User_Table_Preferences_Bool_Exp>>;
  _not?: InputMaybe<User_Table_Preferences_Bool_Exp>;
  _or?: InputMaybe<Array<User_Table_Preferences_Bool_Exp>>;
};

/** unique or primary key constraints on table "risksmart.user_table_preferences" */
export const User_Table_Preferences_Constraint = {
  /** unique or primary key constraint on columns "TableId", "CreatedByUser", "OrgKey" */
  UserTablePreferencesPkey: 'user_table_preferences_pkey'
} as const;

export type User_Table_Preferences_Constraint = typeof User_Table_Preferences_Constraint[keyof typeof User_Table_Preferences_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Table_Preferences_Delete_At_Path_Input = {
  Preferences?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Table_Preferences_Delete_Elem_Input = {
  Preferences?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Table_Preferences_Delete_Key_Input = {
  Preferences?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.user_table_preferences" */
export type User_Table_Preferences_Insert_Input = {
  Preferences?: InputMaybe<Scalars['jsonb']['input']>;
  TableId?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "risksmart.user_table_preferences" */
export type User_Table_Preferences_Mutation_Response = {
  __typename?: 'user_table_preferences_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Table_Preferences>;
};

/** on_conflict condition type for table "risksmart.user_table_preferences" */
export type User_Table_Preferences_On_Conflict = {
  constraint: User_Table_Preferences_Constraint;
  update_columns?: Array<User_Table_Preferences_Update_Column>;
  where?: InputMaybe<User_Table_Preferences_Bool_Exp>;
};

/** Ordering options when selecting data from "risksmart.user_table_preferences". */
export type User_Table_Preferences_Order_By = {
  Preferences?: InputMaybe<Order_By>;
  TableId?: InputMaybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Table_Preferences_Prepend_Input = {
  Preferences?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.user_table_preferences" */
export const User_Table_Preferences_Select_Column = {
  /** column name */
  Preferences: 'Preferences',
  /** column name */
  TableId: 'TableId'
} as const;

export type User_Table_Preferences_Select_Column = typeof User_Table_Preferences_Select_Column[keyof typeof User_Table_Preferences_Select_Column];
/** input type for updating data in table "risksmart.user_table_preferences" */
export type User_Table_Preferences_Set_Input = {
  Preferences?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "user_table_preferences" */
export type User_Table_Preferences_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Table_Preferences_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Table_Preferences_Stream_Cursor_Value_Input = {
  Preferences?: InputMaybe<Scalars['jsonb']['input']>;
  TableId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.user_table_preferences" */
export const User_Table_Preferences_Update_Column = {
  /** column name */
  Preferences: 'Preferences'
} as const;

export type User_Table_Preferences_Update_Column = typeof User_Table_Preferences_Update_Column[keyof typeof User_Table_Preferences_Update_Column];
export type User_Table_Preferences_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_Table_Preferences_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_Table_Preferences_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_Table_Preferences_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_Table_Preferences_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_Table_Preferences_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Table_Preferences_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Table_Preferences_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

export const Version_Status_Enum = {
  /** Archived */
  Archived: 'archived',
  /** Draft */
  Draft: 'draft',
  /** Pending Approval */
  PendingApproval: 'pending_approval',
  /** Published */
  Published: 'published'
} as const;

export type Version_Status_Enum = typeof Version_Status_Enum[keyof typeof Version_Status_Enum];
/** Boolean expression to compare columns of type "version_status_enum". All fields are combined with logical 'AND'. */
export type Version_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Version_Status_Enum>;
  _in?: InputMaybe<Array<Version_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Version_Status_Enum>;
  _nin?: InputMaybe<Array<Version_Status_Enum>>;
};

export type DeleteRelationFileByIdMutationVariables = Exact<{
  parentIds?: InputMaybe<Array<Scalars['uuid']['input']> | Scalars['uuid']['input']>;
  fileIds?: InputMaybe<Array<Scalars['uuid']['input']> | Scalars['uuid']['input']>;
}>;


export type DeleteRelationFileByIdMutation = { __typename?: 'mutation_root', delete_relation_file?: { __typename?: 'relation_file_mutation_response', affected_rows: number } | null | undefined };



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Boolean_comparison_exp: Boolean_Comparison_Exp;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  DataImportStartImportOutput: ResolverTypeWrapper<DataImportStartImportOutput>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  DataImportValidateOutput: ResolverTypeWrapper<DataImportValidateOutput>;
  DisconnectSlackOutput: ResolverTypeWrapper<DisconnectSlackOutput>;
  GenericMutationOutput: ResolverTypeWrapper<GenericMutationOutput>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  IdOutput: ResolverTypeWrapper<IdOutput>;
  InsertChildControlInput: InsertChildControlInput;
  InsertChildDocumentAssessmentResultOutput: ResolverTypeWrapper<InsertChildDocumentAssessmentResultOutput>;
  InsertChildDocumentInput: InsertChildDocumentInput;
  InsertChildIndicatorInput: InsertChildIndicatorInput;
  InsertChildObligationAssessmentResultOutput: ResolverTypeWrapper<InsertChildObligationAssessmentResultOutput>;
  InsertChildObligationInput: InsertChildObligationInput;
  InsertChildRiskAssessmentResultOutput: ResolverTypeWrapper<InsertChildRiskAssessmentResultOutput>;
  InsertChildRiskInput: InsertChildRiskInput;
  InsertImpactRatingPairInput: InsertImpactRatingPairInput;
  InsertInternalAuditInput: InsertInternalAuditInput;
  Int_comparison_exp: Int_Comparison_Exp;
  LinkItemRecordOutput: ResolverTypeWrapper<LinkItemRecordOutput>;
  LinkItemsOutput: ResolverTypeWrapper<LinkItemsOutput>;
  NotificationPreferencesOutput: ResolverTypeWrapper<NotificationPreferencesOutput>;
  RecalculateAppetitesOutput: ResolverTypeWrapper<RecalculateAppetitesOutput>;
  RecalculateRiskScoresOutput: ResolverTypeWrapper<RecalculateRiskScoresOutput>;
  ReportDataOutput: ResolverTypeWrapper<ReportDataOutput>;
  RoleOutput: ResolverTypeWrapper<RoleOutput>;
  ScheduleInput: ScheduleInput;
  SlackNotificationConnectionOutput: ResolverTypeWrapper<SlackNotificationConnectionOutput>;
  String_array_comparison_exp: String_Array_Comparison_Exp;
  String_comparison_exp: String_Comparison_Exp;
  UnlinkItemsOutput: ResolverTypeWrapper<UnlinkItemsOutput>;
  UpdateApproverResponsesInput: UpdateApproverResponsesInput;
  UpdateChildControlInput: UpdateChildControlInput;
  UpdateChildDocumentInput: UpdateChildDocumentInput;
  UpdateChildIndicatorInput: UpdateChildIndicatorInput;
  UpdateChildObligationInput: UpdateChildObligationInput;
  UpdateChildRiskInput: UpdateChildRiskInput;
  UpdateInternalAuditInput: UpdateInternalAuditInput;
  UpdateNotificationPreferencesInput: UpdateNotificationPreferencesInput;
  UpdateNotificationPreferencesOutput: ResolverTypeWrapper<UpdateNotificationPreferencesOutput>;
  UpdateTestResultInput: UpdateTestResultInput;
  UpdateUserRolesMutationOutput: ResolverTypeWrapper<UpdateUserRolesMutationOutput>;
  acceptance: ResolverTypeWrapper<Acceptance>;
  acceptance_aggregate: ResolverTypeWrapper<Acceptance_Aggregate>;
  acceptance_aggregate_fields: ResolverTypeWrapper<Acceptance_Aggregate_Fields>;
  acceptance_audit: ResolverTypeWrapper<Acceptance_Audit>;
  acceptance_audit_bool_exp: Acceptance_Audit_Bool_Exp;
  acceptance_audit_order_by: Acceptance_Audit_Order_By;
  acceptance_audit_select_column: Acceptance_Audit_Select_Column;
  acceptance_audit_stream_cursor_input: Acceptance_Audit_Stream_Cursor_Input;
  acceptance_audit_stream_cursor_value_input: Acceptance_Audit_Stream_Cursor_Value_Input;
  acceptance_avg_fields: ResolverTypeWrapper<Acceptance_Avg_Fields>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  acceptance_bool_exp: Acceptance_Bool_Exp;
  acceptance_constraint: Acceptance_Constraint;
  acceptance_insert_input: Acceptance_Insert_Input;
  acceptance_max_fields: ResolverTypeWrapper<Acceptance_Max_Fields>;
  acceptance_min_fields: ResolverTypeWrapper<Acceptance_Min_Fields>;
  acceptance_obj_rel_insert_input: Acceptance_Obj_Rel_Insert_Input;
  acceptance_on_conflict: Acceptance_On_Conflict;
  acceptance_order_by: Acceptance_Order_By;
  acceptance_parent: ResolverTypeWrapper<Acceptance_Parent>;
  acceptance_parent_aggregate: ResolverTypeWrapper<Acceptance_Parent_Aggregate>;
  acceptance_parent_aggregate_bool_exp: Acceptance_Parent_Aggregate_Bool_Exp;
  acceptance_parent_aggregate_bool_exp_count: Acceptance_Parent_Aggregate_Bool_Exp_Count;
  acceptance_parent_aggregate_fields: ResolverTypeWrapper<Acceptance_Parent_Aggregate_Fields>;
  acceptance_parent_aggregate_order_by: Acceptance_Parent_Aggregate_Order_By;
  acceptance_parent_arr_rel_insert_input: Acceptance_Parent_Arr_Rel_Insert_Input;
  acceptance_parent_audit: ResolverTypeWrapper<Acceptance_Parent_Audit>;
  acceptance_parent_audit_aggregate_order_by: Acceptance_Parent_Audit_Aggregate_Order_By;
  acceptance_parent_audit_bool_exp: Acceptance_Parent_Audit_Bool_Exp;
  acceptance_parent_audit_max_order_by: Acceptance_Parent_Audit_Max_Order_By;
  acceptance_parent_audit_min_order_by: Acceptance_Parent_Audit_Min_Order_By;
  acceptance_parent_audit_order_by: Acceptance_Parent_Audit_Order_By;
  acceptance_parent_audit_select_column: Acceptance_Parent_Audit_Select_Column;
  acceptance_parent_audit_stream_cursor_input: Acceptance_Parent_Audit_Stream_Cursor_Input;
  acceptance_parent_audit_stream_cursor_value_input: Acceptance_Parent_Audit_Stream_Cursor_Value_Input;
  acceptance_parent_bool_exp: Acceptance_Parent_Bool_Exp;
  acceptance_parent_constraint: Acceptance_Parent_Constraint;
  acceptance_parent_insert_input: Acceptance_Parent_Insert_Input;
  acceptance_parent_max_fields: ResolverTypeWrapper<Acceptance_Parent_Max_Fields>;
  acceptance_parent_max_order_by: Acceptance_Parent_Max_Order_By;
  acceptance_parent_min_fields: ResolverTypeWrapper<Acceptance_Parent_Min_Fields>;
  acceptance_parent_min_order_by: Acceptance_Parent_Min_Order_By;
  acceptance_parent_mutation_response: ResolverTypeWrapper<Acceptance_Parent_Mutation_Response>;
  acceptance_parent_on_conflict: Acceptance_Parent_On_Conflict;
  acceptance_parent_order_by: Acceptance_Parent_Order_By;
  acceptance_parent_select_column: Acceptance_Parent_Select_Column;
  acceptance_parent_stream_cursor_input: Acceptance_Parent_Stream_Cursor_Input;
  acceptance_parent_stream_cursor_value_input: Acceptance_Parent_Stream_Cursor_Value_Input;
  acceptance_parent_update_column: Acceptance_Parent_Update_Column;
  acceptance_select_column: Acceptance_Select_Column;
  acceptance_status_enum: Acceptance_Status_Enum;
  acceptance_status_enum_comparison_exp: Acceptance_Status_Enum_Comparison_Exp;
  acceptance_stddev_fields: ResolverTypeWrapper<Acceptance_Stddev_Fields>;
  acceptance_stddev_pop_fields: ResolverTypeWrapper<Acceptance_Stddev_Pop_Fields>;
  acceptance_stddev_samp_fields: ResolverTypeWrapper<Acceptance_Stddev_Samp_Fields>;
  acceptance_stream_cursor_input: Acceptance_Stream_Cursor_Input;
  acceptance_stream_cursor_value_input: Acceptance_Stream_Cursor_Value_Input;
  acceptance_sum_fields: ResolverTypeWrapper<Acceptance_Sum_Fields>;
  acceptance_update_column: Acceptance_Update_Column;
  acceptance_var_pop_fields: ResolverTypeWrapper<Acceptance_Var_Pop_Fields>;
  acceptance_var_samp_fields: ResolverTypeWrapper<Acceptance_Var_Samp_Fields>;
  acceptance_variance_fields: ResolverTypeWrapper<Acceptance_Variance_Fields>;
  access_type_enum: Access_Type_Enum;
  access_type_enum_comparison_exp: Access_Type_Enum_Comparison_Exp;
  action: ResolverTypeWrapper<Action>;
  action_aggregate: ResolverTypeWrapper<Action_Aggregate>;
  action_aggregate_fields: ResolverTypeWrapper<Action_Aggregate_Fields>;
  action_audit: ResolverTypeWrapper<Action_Audit>;
  action_audit_aggregate: ResolverTypeWrapper<Action_Audit_Aggregate>;
  action_audit_aggregate_fields: ResolverTypeWrapper<Action_Audit_Aggregate_Fields>;
  action_audit_avg_fields: ResolverTypeWrapper<Action_Audit_Avg_Fields>;
  action_audit_bool_exp: Action_Audit_Bool_Exp;
  action_audit_max_fields: ResolverTypeWrapper<Action_Audit_Max_Fields>;
  action_audit_min_fields: ResolverTypeWrapper<Action_Audit_Min_Fields>;
  action_audit_order_by: Action_Audit_Order_By;
  action_audit_select_column: Action_Audit_Select_Column;
  action_audit_stddev_fields: ResolverTypeWrapper<Action_Audit_Stddev_Fields>;
  action_audit_stddev_pop_fields: ResolverTypeWrapper<Action_Audit_Stddev_Pop_Fields>;
  action_audit_stddev_samp_fields: ResolverTypeWrapper<Action_Audit_Stddev_Samp_Fields>;
  action_audit_stream_cursor_input: Action_Audit_Stream_Cursor_Input;
  action_audit_stream_cursor_value_input: Action_Audit_Stream_Cursor_Value_Input;
  action_audit_sum_fields: ResolverTypeWrapper<Action_Audit_Sum_Fields>;
  action_audit_var_pop_fields: ResolverTypeWrapper<Action_Audit_Var_Pop_Fields>;
  action_audit_var_samp_fields: ResolverTypeWrapper<Action_Audit_Var_Samp_Fields>;
  action_audit_variance_fields: ResolverTypeWrapper<Action_Audit_Variance_Fields>;
  action_avg_fields: ResolverTypeWrapper<Action_Avg_Fields>;
  action_bool_exp: Action_Bool_Exp;
  action_constraint: Action_Constraint;
  action_insert_input: Action_Insert_Input;
  action_max_fields: ResolverTypeWrapper<Action_Max_Fields>;
  action_min_fields: ResolverTypeWrapper<Action_Min_Fields>;
  action_obj_rel_insert_input: Action_Obj_Rel_Insert_Input;
  action_on_conflict: Action_On_Conflict;
  action_order_by: Action_Order_By;
  action_parent: ResolverTypeWrapper<Action_Parent>;
  action_parent_aggregate: ResolverTypeWrapper<Action_Parent_Aggregate>;
  action_parent_aggregate_bool_exp: Action_Parent_Aggregate_Bool_Exp;
  action_parent_aggregate_bool_exp_count: Action_Parent_Aggregate_Bool_Exp_Count;
  action_parent_aggregate_fields: ResolverTypeWrapper<Action_Parent_Aggregate_Fields>;
  action_parent_aggregate_order_by: Action_Parent_Aggregate_Order_By;
  action_parent_arr_rel_insert_input: Action_Parent_Arr_Rel_Insert_Input;
  action_parent_audit: ResolverTypeWrapper<Action_Parent_Audit>;
  action_parent_audit_aggregate_order_by: Action_Parent_Audit_Aggregate_Order_By;
  action_parent_audit_bool_exp: Action_Parent_Audit_Bool_Exp;
  action_parent_audit_max_order_by: Action_Parent_Audit_Max_Order_By;
  action_parent_audit_min_order_by: Action_Parent_Audit_Min_Order_By;
  action_parent_audit_order_by: Action_Parent_Audit_Order_By;
  action_parent_audit_select_column: Action_Parent_Audit_Select_Column;
  action_parent_audit_stream_cursor_input: Action_Parent_Audit_Stream_Cursor_Input;
  action_parent_audit_stream_cursor_value_input: Action_Parent_Audit_Stream_Cursor_Value_Input;
  action_parent_bool_exp: Action_Parent_Bool_Exp;
  action_parent_constraint: Action_Parent_Constraint;
  action_parent_insert_input: Action_Parent_Insert_Input;
  action_parent_max_fields: ResolverTypeWrapper<Action_Parent_Max_Fields>;
  action_parent_max_order_by: Action_Parent_Max_Order_By;
  action_parent_min_fields: ResolverTypeWrapper<Action_Parent_Min_Fields>;
  action_parent_min_order_by: Action_Parent_Min_Order_By;
  action_parent_mutation_response: ResolverTypeWrapper<Action_Parent_Mutation_Response>;
  action_parent_on_conflict: Action_Parent_On_Conflict;
  action_parent_order_by: Action_Parent_Order_By;
  action_parent_select_column: Action_Parent_Select_Column;
  action_parent_stream_cursor_input: Action_Parent_Stream_Cursor_Input;
  action_parent_stream_cursor_value_input: Action_Parent_Stream_Cursor_Value_Input;
  action_parent_update_column: Action_Parent_Update_Column;
  action_select_column: Action_Select_Column;
  action_status_enum: Action_Status_Enum;
  action_status_enum_comparison_exp: Action_Status_Enum_Comparison_Exp;
  action_stddev_fields: ResolverTypeWrapper<Action_Stddev_Fields>;
  action_stddev_pop_fields: ResolverTypeWrapper<Action_Stddev_Pop_Fields>;
  action_stddev_samp_fields: ResolverTypeWrapper<Action_Stddev_Samp_Fields>;
  action_stream_cursor_input: Action_Stream_Cursor_Input;
  action_stream_cursor_value_input: Action_Stream_Cursor_Value_Input;
  action_sum_fields: ResolverTypeWrapper<Action_Sum_Fields>;
  action_update: ResolverTypeWrapper<Action_Update>;
  action_update_aggregate: ResolverTypeWrapper<Action_Update_Aggregate>;
  action_update_aggregate_bool_exp: Action_Update_Aggregate_Bool_Exp;
  action_update_aggregate_bool_exp_count: Action_Update_Aggregate_Bool_Exp_Count;
  action_update_aggregate_fields: ResolverTypeWrapper<Action_Update_Aggregate_Fields>;
  action_update_aggregate_order_by: Action_Update_Aggregate_Order_By;
  action_update_append_input: Action_Update_Append_Input;
  action_update_arr_rel_insert_input: Action_Update_Arr_Rel_Insert_Input;
  action_update_audit: ResolverTypeWrapper<Action_Update_Audit>;
  action_update_audit_bool_exp: Action_Update_Audit_Bool_Exp;
  action_update_audit_order_by: Action_Update_Audit_Order_By;
  action_update_audit_select_column: Action_Update_Audit_Select_Column;
  action_update_audit_stream_cursor_input: Action_Update_Audit_Stream_Cursor_Input;
  action_update_audit_stream_cursor_value_input: Action_Update_Audit_Stream_Cursor_Value_Input;
  action_update_bool_exp: Action_Update_Bool_Exp;
  action_update_column: Action_Update_Column;
  action_update_constraint: Action_Update_Constraint;
  action_update_delete_at_path_input: Action_Update_Delete_At_Path_Input;
  action_update_delete_elem_input: Action_Update_Delete_Elem_Input;
  action_update_delete_key_input: Action_Update_Delete_Key_Input;
  action_update_insert_input: Action_Update_Insert_Input;
  action_update_max_fields: ResolverTypeWrapper<Action_Update_Max_Fields>;
  action_update_max_order_by: Action_Update_Max_Order_By;
  action_update_min_fields: ResolverTypeWrapper<Action_Update_Min_Fields>;
  action_update_min_order_by: Action_Update_Min_Order_By;
  action_update_mutation_response: ResolverTypeWrapper<Action_Update_Mutation_Response>;
  action_update_on_conflict: Action_Update_On_Conflict;
  action_update_order_by: Action_Update_Order_By;
  action_update_pk_columns_input: Action_Update_Pk_Columns_Input;
  action_update_prepend_input: Action_Update_Prepend_Input;
  action_update_select_column: Action_Update_Select_Column;
  action_update_set_input: Action_Update_Set_Input;
  action_update_stream_cursor_input: Action_Update_Stream_Cursor_Input;
  action_update_stream_cursor_value_input: Action_Update_Stream_Cursor_Value_Input;
  action_update_summary: ResolverTypeWrapper<Action_Update_Summary>;
  action_update_summary_aggregate: ResolverTypeWrapper<Action_Update_Summary_Aggregate>;
  action_update_summary_aggregate_fields: ResolverTypeWrapper<Action_Update_Summary_Aggregate_Fields>;
  action_update_summary_avg_fields: ResolverTypeWrapper<Action_Update_Summary_Avg_Fields>;
  action_update_summary_bool_exp: Action_Update_Summary_Bool_Exp;
  action_update_summary_max_fields: ResolverTypeWrapper<Action_Update_Summary_Max_Fields>;
  action_update_summary_min_fields: ResolverTypeWrapper<Action_Update_Summary_Min_Fields>;
  action_update_summary_order_by: Action_Update_Summary_Order_By;
  action_update_summary_select_column: Action_Update_Summary_Select_Column;
  action_update_summary_stddev_fields: ResolverTypeWrapper<Action_Update_Summary_Stddev_Fields>;
  action_update_summary_stddev_pop_fields: ResolverTypeWrapper<Action_Update_Summary_Stddev_Pop_Fields>;
  action_update_summary_stddev_samp_fields: ResolverTypeWrapper<Action_Update_Summary_Stddev_Samp_Fields>;
  action_update_summary_stream_cursor_input: Action_Update_Summary_Stream_Cursor_Input;
  action_update_summary_stream_cursor_value_input: Action_Update_Summary_Stream_Cursor_Value_Input;
  action_update_summary_sum_fields: ResolverTypeWrapper<Action_Update_Summary_Sum_Fields>;
  action_update_summary_var_pop_fields: ResolverTypeWrapper<Action_Update_Summary_Var_Pop_Fields>;
  action_update_summary_var_samp_fields: ResolverTypeWrapper<Action_Update_Summary_Var_Samp_Fields>;
  action_update_summary_variance_fields: ResolverTypeWrapper<Action_Update_Summary_Variance_Fields>;
  action_update_update_column: Action_Update_Update_Column;
  action_update_updates: Action_Update_Updates;
  action_var_pop_fields: ResolverTypeWrapper<Action_Var_Pop_Fields>;
  action_var_samp_fields: ResolverTypeWrapper<Action_Var_Samp_Fields>;
  action_variance_fields: ResolverTypeWrapper<Action_Variance_Fields>;
  aggregation_org: ResolverTypeWrapper<Aggregation_Org>;
  aggregation_org_bool_exp: Aggregation_Org_Bool_Exp;
  aggregation_org_order_by: Aggregation_Org_Order_By;
  aggregation_org_select_column: Aggregation_Org_Select_Column;
  aggregation_org_stream_cursor_input: Aggregation_Org_Stream_Cursor_Input;
  aggregation_org_stream_cursor_value_input: Aggregation_Org_Stream_Cursor_Value_Input;
  ancestor_contributor: ResolverTypeWrapper<Ancestor_Contributor>;
  ancestor_contributor_aggregate_order_by: Ancestor_Contributor_Aggregate_Order_By;
  ancestor_contributor_bool_exp: Ancestor_Contributor_Bool_Exp;
  ancestor_contributor_max_order_by: Ancestor_Contributor_Max_Order_By;
  ancestor_contributor_min_order_by: Ancestor_Contributor_Min_Order_By;
  ancestor_contributor_order_by: Ancestor_Contributor_Order_By;
  ancestor_contributor_select_column: Ancestor_Contributor_Select_Column;
  ancestor_contributor_stream_cursor_input: Ancestor_Contributor_Stream_Cursor_Input;
  ancestor_contributor_stream_cursor_value_input: Ancestor_Contributor_Stream_Cursor_Value_Input;
  appetite: ResolverTypeWrapper<Appetite>;
  appetite_aggregate: ResolverTypeWrapper<Appetite_Aggregate>;
  appetite_aggregate_fields: ResolverTypeWrapper<Appetite_Aggregate_Fields>;
  appetite_append_input: Appetite_Append_Input;
  appetite_audit: ResolverTypeWrapper<Appetite_Audit>;
  appetite_audit_bool_exp: Appetite_Audit_Bool_Exp;
  appetite_audit_order_by: Appetite_Audit_Order_By;
  appetite_audit_select_column: Appetite_Audit_Select_Column;
  appetite_audit_stream_cursor_input: Appetite_Audit_Stream_Cursor_Input;
  appetite_audit_stream_cursor_value_input: Appetite_Audit_Stream_Cursor_Value_Input;
  appetite_avg_fields: ResolverTypeWrapper<Appetite_Avg_Fields>;
  appetite_bool_exp: Appetite_Bool_Exp;
  appetite_constraint: Appetite_Constraint;
  appetite_delete_at_path_input: Appetite_Delete_At_Path_Input;
  appetite_delete_elem_input: Appetite_Delete_Elem_Input;
  appetite_delete_key_input: Appetite_Delete_Key_Input;
  appetite_inc_input: Appetite_Inc_Input;
  appetite_insert_input: Appetite_Insert_Input;
  appetite_max_fields: ResolverTypeWrapper<Appetite_Max_Fields>;
  appetite_min_fields: ResolverTypeWrapper<Appetite_Min_Fields>;
  appetite_model_enum: Appetite_Model_Enum;
  appetite_model_enum_comparison_exp: Appetite_Model_Enum_Comparison_Exp;
  appetite_mutation_response: ResolverTypeWrapper<Appetite_Mutation_Response>;
  appetite_obj_rel_insert_input: Appetite_Obj_Rel_Insert_Input;
  appetite_on_conflict: Appetite_On_Conflict;
  appetite_order_by: Appetite_Order_By;
  appetite_parent: ResolverTypeWrapper<Appetite_Parent>;
  appetite_parent_aggregate: ResolverTypeWrapper<Appetite_Parent_Aggregate>;
  appetite_parent_aggregate_bool_exp: Appetite_Parent_Aggregate_Bool_Exp;
  appetite_parent_aggregate_bool_exp_count: Appetite_Parent_Aggregate_Bool_Exp_Count;
  appetite_parent_aggregate_fields: ResolverTypeWrapper<Appetite_Parent_Aggregate_Fields>;
  appetite_parent_aggregate_order_by: Appetite_Parent_Aggregate_Order_By;
  appetite_parent_arr_rel_insert_input: Appetite_Parent_Arr_Rel_Insert_Input;
  appetite_parent_audit: ResolverTypeWrapper<Appetite_Parent_Audit>;
  appetite_parent_audit_aggregate: ResolverTypeWrapper<Appetite_Parent_Audit_Aggregate>;
  appetite_parent_audit_aggregate_bool_exp: Appetite_Parent_Audit_Aggregate_Bool_Exp;
  appetite_parent_audit_aggregate_bool_exp_count: Appetite_Parent_Audit_Aggregate_Bool_Exp_Count;
  appetite_parent_audit_aggregate_fields: ResolverTypeWrapper<Appetite_Parent_Audit_Aggregate_Fields>;
  appetite_parent_audit_aggregate_order_by: Appetite_Parent_Audit_Aggregate_Order_By;
  appetite_parent_audit_bool_exp: Appetite_Parent_Audit_Bool_Exp;
  appetite_parent_audit_max_fields: ResolverTypeWrapper<Appetite_Parent_Audit_Max_Fields>;
  appetite_parent_audit_max_order_by: Appetite_Parent_Audit_Max_Order_By;
  appetite_parent_audit_min_fields: ResolverTypeWrapper<Appetite_Parent_Audit_Min_Fields>;
  appetite_parent_audit_min_order_by: Appetite_Parent_Audit_Min_Order_By;
  appetite_parent_audit_order_by: Appetite_Parent_Audit_Order_By;
  appetite_parent_audit_select_column: Appetite_Parent_Audit_Select_Column;
  appetite_parent_audit_stream_cursor_input: Appetite_Parent_Audit_Stream_Cursor_Input;
  appetite_parent_audit_stream_cursor_value_input: Appetite_Parent_Audit_Stream_Cursor_Value_Input;
  appetite_parent_bool_exp: Appetite_Parent_Bool_Exp;
  appetite_parent_constraint: Appetite_Parent_Constraint;
  appetite_parent_insert_input: Appetite_Parent_Insert_Input;
  appetite_parent_max_fields: ResolverTypeWrapper<Appetite_Parent_Max_Fields>;
  appetite_parent_max_order_by: Appetite_Parent_Max_Order_By;
  appetite_parent_min_fields: ResolverTypeWrapper<Appetite_Parent_Min_Fields>;
  appetite_parent_min_order_by: Appetite_Parent_Min_Order_By;
  appetite_parent_mutation_response: ResolverTypeWrapper<Appetite_Parent_Mutation_Response>;
  appetite_parent_on_conflict: Appetite_Parent_On_Conflict;
  appetite_parent_order_by: Appetite_Parent_Order_By;
  appetite_parent_select_column: Appetite_Parent_Select_Column;
  appetite_parent_stream_cursor_input: Appetite_Parent_Stream_Cursor_Input;
  appetite_parent_stream_cursor_value_input: Appetite_Parent_Stream_Cursor_Value_Input;
  appetite_parent_update_column: Appetite_Parent_Update_Column;
  appetite_pk_columns_input: Appetite_Pk_Columns_Input;
  appetite_prepend_input: Appetite_Prepend_Input;
  appetite_select_column: Appetite_Select_Column;
  appetite_set_input: Appetite_Set_Input;
  appetite_stddev_fields: ResolverTypeWrapper<Appetite_Stddev_Fields>;
  appetite_stddev_pop_fields: ResolverTypeWrapper<Appetite_Stddev_Pop_Fields>;
  appetite_stddev_samp_fields: ResolverTypeWrapper<Appetite_Stddev_Samp_Fields>;
  appetite_stream_cursor_input: Appetite_Stream_Cursor_Input;
  appetite_stream_cursor_value_input: Appetite_Stream_Cursor_Value_Input;
  appetite_sum_fields: ResolverTypeWrapper<Appetite_Sum_Fields>;
  appetite_type_enum: Appetite_Type_Enum;
  appetite_type_enum_comparison_exp: Appetite_Type_Enum_Comparison_Exp;
  appetite_update_column: Appetite_Update_Column;
  appetite_updates: Appetite_Updates;
  appetite_var_pop_fields: ResolverTypeWrapper<Appetite_Var_Pop_Fields>;
  appetite_var_samp_fields: ResolverTypeWrapper<Appetite_Var_Samp_Fields>;
  appetite_variance_fields: ResolverTypeWrapper<Appetite_Variance_Fields>;
  approval: ResolverTypeWrapper<Approval>;
  approval_aggregate_order_by: Approval_Aggregate_Order_By;
  approval_arr_rel_insert_input: Approval_Arr_Rel_Insert_Input;
  approval_audit: ResolverTypeWrapper<Approval_Audit>;
  approval_audit_bool_exp: Approval_Audit_Bool_Exp;
  approval_audit_order_by: Approval_Audit_Order_By;
  approval_audit_select_column: Approval_Audit_Select_Column;
  approval_audit_stream_cursor_input: Approval_Audit_Stream_Cursor_Input;
  approval_audit_stream_cursor_value_input: Approval_Audit_Stream_Cursor_Value_Input;
  approval_bool_exp: Approval_Bool_Exp;
  approval_constraint: Approval_Constraint;
  approval_in_flight_edit_rule_enum: Approval_In_Flight_Edit_Rule_Enum;
  approval_in_flight_edit_rule_enum_comparison_exp: Approval_In_Flight_Edit_Rule_Enum_Comparison_Exp;
  approval_insert_input: Approval_Insert_Input;
  approval_level: ResolverTypeWrapper<Approval_Level>;
  approval_level_aggregate_order_by: Approval_Level_Aggregate_Order_By;
  approval_level_arr_rel_insert_input: Approval_Level_Arr_Rel_Insert_Input;
  approval_level_audit: ResolverTypeWrapper<Approval_Level_Audit>;
  approval_level_audit_bool_exp: Approval_Level_Audit_Bool_Exp;
  approval_level_audit_order_by: Approval_Level_Audit_Order_By;
  approval_level_audit_select_column: Approval_Level_Audit_Select_Column;
  approval_level_audit_stream_cursor_input: Approval_Level_Audit_Stream_Cursor_Input;
  approval_level_audit_stream_cursor_value_input: Approval_Level_Audit_Stream_Cursor_Value_Input;
  approval_level_avg_order_by: Approval_Level_Avg_Order_By;
  approval_level_bool_exp: Approval_Level_Bool_Exp;
  approval_level_constraint: Approval_Level_Constraint;
  approval_level_inc_input: Approval_Level_Inc_Input;
  approval_level_insert_input: Approval_Level_Insert_Input;
  approval_level_max_order_by: Approval_Level_Max_Order_By;
  approval_level_min_order_by: Approval_Level_Min_Order_By;
  approval_level_mutation_response: ResolverTypeWrapper<Approval_Level_Mutation_Response>;
  approval_level_obj_rel_insert_input: Approval_Level_Obj_Rel_Insert_Input;
  approval_level_on_conflict: Approval_Level_On_Conflict;
  approval_level_order_by: Approval_Level_Order_By;
  approval_level_pk_columns_input: Approval_Level_Pk_Columns_Input;
  approval_level_select_column: Approval_Level_Select_Column;
  approval_level_set_input: Approval_Level_Set_Input;
  approval_level_stddev_order_by: Approval_Level_Stddev_Order_By;
  approval_level_stddev_pop_order_by: Approval_Level_Stddev_Pop_Order_By;
  approval_level_stddev_samp_order_by: Approval_Level_Stddev_Samp_Order_By;
  approval_level_stream_cursor_input: Approval_Level_Stream_Cursor_Input;
  approval_level_stream_cursor_value_input: Approval_Level_Stream_Cursor_Value_Input;
  approval_level_sum_order_by: Approval_Level_Sum_Order_By;
  approval_level_update_column: Approval_Level_Update_Column;
  approval_level_updates: Approval_Level_Updates;
  approval_level_var_pop_order_by: Approval_Level_Var_Pop_Order_By;
  approval_level_var_samp_order_by: Approval_Level_Var_Samp_Order_By;
  approval_level_variance_order_by: Approval_Level_Variance_Order_By;
  approval_max_order_by: Approval_Max_Order_By;
  approval_min_order_by: Approval_Min_Order_By;
  approval_mutation_response: ResolverTypeWrapper<Approval_Mutation_Response>;
  approval_obj_rel_insert_input: Approval_Obj_Rel_Insert_Input;
  approval_on_conflict: Approval_On_Conflict;
  approval_order_by: Approval_Order_By;
  approval_pk_columns_input: Approval_Pk_Columns_Input;
  approval_rule_type_enum: Approval_Rule_Type_Enum;
  approval_rule_type_enum_comparison_exp: Approval_Rule_Type_Enum_Comparison_Exp;
  approval_select_column: Approval_Select_Column;
  approval_set_input: Approval_Set_Input;
  approval_status_enum: Approval_Status_Enum;
  approval_status_enum_comparison_exp: Approval_Status_Enum_Comparison_Exp;
  approval_stream_cursor_input: Approval_Stream_Cursor_Input;
  approval_stream_cursor_value_input: Approval_Stream_Cursor_Value_Input;
  approval_update_column: Approval_Update_Column;
  approval_updates: Approval_Updates;
  approver: ResolverTypeWrapper<Approver>;
  approver_aggregate: ResolverTypeWrapper<Approver_Aggregate>;
  approver_aggregate_bool_exp: Approver_Aggregate_Bool_Exp;
  approver_aggregate_bool_exp_bool_and: Approver_Aggregate_Bool_Exp_Bool_And;
  approver_aggregate_bool_exp_bool_or: Approver_Aggregate_Bool_Exp_Bool_Or;
  approver_aggregate_bool_exp_count: Approver_Aggregate_Bool_Exp_Count;
  approver_aggregate_fields: ResolverTypeWrapper<Approver_Aggregate_Fields>;
  approver_aggregate_order_by: Approver_Aggregate_Order_By;
  approver_arr_rel_insert_input: Approver_Arr_Rel_Insert_Input;
  approver_audit: ResolverTypeWrapper<Approver_Audit>;
  approver_audit_bool_exp: Approver_Audit_Bool_Exp;
  approver_audit_order_by: Approver_Audit_Order_By;
  approver_audit_select_column: Approver_Audit_Select_Column;
  approver_audit_stream_cursor_input: Approver_Audit_Stream_Cursor_Input;
  approver_audit_stream_cursor_value_input: Approver_Audit_Stream_Cursor_Value_Input;
  approver_bool_exp: Approver_Bool_Exp;
  approver_constraint: Approver_Constraint;
  approver_insert_input: Approver_Insert_Input;
  approver_max_fields: ResolverTypeWrapper<Approver_Max_Fields>;
  approver_max_order_by: Approver_Max_Order_By;
  approver_min_fields: ResolverTypeWrapper<Approver_Min_Fields>;
  approver_min_order_by: Approver_Min_Order_By;
  approver_mutation_response: ResolverTypeWrapper<Approver_Mutation_Response>;
  approver_obj_rel_insert_input: Approver_Obj_Rel_Insert_Input;
  approver_on_conflict: Approver_On_Conflict;
  approver_order_by: Approver_Order_By;
  approver_pk_columns_input: Approver_Pk_Columns_Input;
  approver_response: ResolverTypeWrapper<Approver_Response>;
  approver_response_aggregate_order_by: Approver_Response_Aggregate_Order_By;
  approver_response_arr_rel_insert_input: Approver_Response_Arr_Rel_Insert_Input;
  approver_response_bool_exp: Approver_Response_Bool_Exp;
  approver_response_constraint: Approver_Response_Constraint;
  approver_response_insert_input: Approver_Response_Insert_Input;
  approver_response_max_order_by: Approver_Response_Max_Order_By;
  approver_response_min_order_by: Approver_Response_Min_Order_By;
  approver_response_on_conflict: Approver_Response_On_Conflict;
  approver_response_order_by: Approver_Response_Order_By;
  approver_response_select_column: Approver_Response_Select_Column;
  approver_response_update_column: Approver_Response_Update_Column;
  approver_select_column: Approver_Select_Column;
  approver_select_column_approver_aggregate_bool_exp_bool_and_arguments_columns: Approver_Select_Column_Approver_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  approver_select_column_approver_aggregate_bool_exp_bool_or_arguments_columns: Approver_Select_Column_Approver_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  approver_set_input: Approver_Set_Input;
  approver_stream_cursor_input: Approver_Stream_Cursor_Input;
  approver_stream_cursor_value_input: Approver_Stream_Cursor_Value_Input;
  approver_update_column: Approver_Update_Column;
  approver_updates: Approver_Updates;
  assessment: ResolverTypeWrapper<Assessment>;
  assessment_activity: ResolverTypeWrapper<Assessment_Activity>;
  assessment_activity_aggregate: ResolverTypeWrapper<Assessment_Activity_Aggregate>;
  assessment_activity_aggregate_bool_exp: Assessment_Activity_Aggregate_Bool_Exp;
  assessment_activity_aggregate_bool_exp_count: Assessment_Activity_Aggregate_Bool_Exp_Count;
  assessment_activity_aggregate_fields: ResolverTypeWrapper<Assessment_Activity_Aggregate_Fields>;
  assessment_activity_aggregate_order_by: Assessment_Activity_Aggregate_Order_By;
  assessment_activity_append_input: Assessment_Activity_Append_Input;
  assessment_activity_arr_rel_insert_input: Assessment_Activity_Arr_Rel_Insert_Input;
  assessment_activity_audit: ResolverTypeWrapper<Assessment_Activity_Audit>;
  assessment_activity_audit_bool_exp: Assessment_Activity_Audit_Bool_Exp;
  assessment_activity_audit_order_by: Assessment_Activity_Audit_Order_By;
  assessment_activity_audit_select_column: Assessment_Activity_Audit_Select_Column;
  assessment_activity_audit_stream_cursor_input: Assessment_Activity_Audit_Stream_Cursor_Input;
  assessment_activity_audit_stream_cursor_value_input: Assessment_Activity_Audit_Stream_Cursor_Value_Input;
  assessment_activity_bool_exp: Assessment_Activity_Bool_Exp;
  assessment_activity_constraint: Assessment_Activity_Constraint;
  assessment_activity_delete_at_path_input: Assessment_Activity_Delete_At_Path_Input;
  assessment_activity_delete_elem_input: Assessment_Activity_Delete_Elem_Input;
  assessment_activity_delete_key_input: Assessment_Activity_Delete_Key_Input;
  assessment_activity_insert_input: Assessment_Activity_Insert_Input;
  assessment_activity_max_fields: ResolverTypeWrapper<Assessment_Activity_Max_Fields>;
  assessment_activity_max_order_by: Assessment_Activity_Max_Order_By;
  assessment_activity_min_fields: ResolverTypeWrapper<Assessment_Activity_Min_Fields>;
  assessment_activity_min_order_by: Assessment_Activity_Min_Order_By;
  assessment_activity_mutation_response: ResolverTypeWrapper<Assessment_Activity_Mutation_Response>;
  assessment_activity_on_conflict: Assessment_Activity_On_Conflict;
  assessment_activity_order_by: Assessment_Activity_Order_By;
  assessment_activity_pk_columns_input: Assessment_Activity_Pk_Columns_Input;
  assessment_activity_prepend_input: Assessment_Activity_Prepend_Input;
  assessment_activity_select_column: Assessment_Activity_Select_Column;
  assessment_activity_set_input: Assessment_Activity_Set_Input;
  assessment_activity_status_enum: Assessment_Activity_Status_Enum;
  assessment_activity_status_enum_comparison_exp: Assessment_Activity_Status_Enum_Comparison_Exp;
  assessment_activity_stream_cursor_input: Assessment_Activity_Stream_Cursor_Input;
  assessment_activity_stream_cursor_value_input: Assessment_Activity_Stream_Cursor_Value_Input;
  assessment_activity_type_enum: Assessment_Activity_Type_Enum;
  assessment_activity_type_enum_comparison_exp: Assessment_Activity_Type_Enum_Comparison_Exp;
  assessment_activity_update_column: Assessment_Activity_Update_Column;
  assessment_activity_updates: Assessment_Activity_Updates;
  assessment_aggregate: ResolverTypeWrapper<Assessment_Aggregate>;
  assessment_aggregate_fields: ResolverTypeWrapper<Assessment_Aggregate_Fields>;
  assessment_append_input: Assessment_Append_Input;
  assessment_audit: ResolverTypeWrapper<Assessment_Audit>;
  assessment_audit_aggregate: ResolverTypeWrapper<Assessment_Audit_Aggregate>;
  assessment_audit_aggregate_fields: ResolverTypeWrapper<Assessment_Audit_Aggregate_Fields>;
  assessment_audit_avg_fields: ResolverTypeWrapper<Assessment_Audit_Avg_Fields>;
  assessment_audit_bool_exp: Assessment_Audit_Bool_Exp;
  assessment_audit_max_fields: ResolverTypeWrapper<Assessment_Audit_Max_Fields>;
  assessment_audit_min_fields: ResolverTypeWrapper<Assessment_Audit_Min_Fields>;
  assessment_audit_order_by: Assessment_Audit_Order_By;
  assessment_audit_select_column: Assessment_Audit_Select_Column;
  assessment_audit_stddev_fields: ResolverTypeWrapper<Assessment_Audit_Stddev_Fields>;
  assessment_audit_stddev_pop_fields: ResolverTypeWrapper<Assessment_Audit_Stddev_Pop_Fields>;
  assessment_audit_stddev_samp_fields: ResolverTypeWrapper<Assessment_Audit_Stddev_Samp_Fields>;
  assessment_audit_stream_cursor_input: Assessment_Audit_Stream_Cursor_Input;
  assessment_audit_stream_cursor_value_input: Assessment_Audit_Stream_Cursor_Value_Input;
  assessment_audit_sum_fields: ResolverTypeWrapper<Assessment_Audit_Sum_Fields>;
  assessment_audit_var_pop_fields: ResolverTypeWrapper<Assessment_Audit_Var_Pop_Fields>;
  assessment_audit_var_samp_fields: ResolverTypeWrapper<Assessment_Audit_Var_Samp_Fields>;
  assessment_audit_variance_fields: ResolverTypeWrapper<Assessment_Audit_Variance_Fields>;
  assessment_avg_fields: ResolverTypeWrapper<Assessment_Avg_Fields>;
  assessment_bool_exp: Assessment_Bool_Exp;
  assessment_constraint: Assessment_Constraint;
  assessment_delete_at_path_input: Assessment_Delete_At_Path_Input;
  assessment_delete_elem_input: Assessment_Delete_Elem_Input;
  assessment_delete_key_input: Assessment_Delete_Key_Input;
  assessment_inc_input: Assessment_Inc_Input;
  assessment_insert_input: Assessment_Insert_Input;
  assessment_max_fields: ResolverTypeWrapper<Assessment_Max_Fields>;
  assessment_min_fields: ResolverTypeWrapper<Assessment_Min_Fields>;
  assessment_mutation_response: ResolverTypeWrapper<Assessment_Mutation_Response>;
  assessment_obj_rel_insert_input: Assessment_Obj_Rel_Insert_Input;
  assessment_on_conflict: Assessment_On_Conflict;
  assessment_order_by: Assessment_Order_By;
  assessment_pk_columns_input: Assessment_Pk_Columns_Input;
  assessment_prepend_input: Assessment_Prepend_Input;
  assessment_result_parent: ResolverTypeWrapper<Assessment_Result_Parent>;
  assessment_result_parent_aggregate: ResolverTypeWrapper<Assessment_Result_Parent_Aggregate>;
  assessment_result_parent_aggregate_bool_exp: Assessment_Result_Parent_Aggregate_Bool_Exp;
  assessment_result_parent_aggregate_bool_exp_count: Assessment_Result_Parent_Aggregate_Bool_Exp_Count;
  assessment_result_parent_aggregate_fields: ResolverTypeWrapper<Assessment_Result_Parent_Aggregate_Fields>;
  assessment_result_parent_aggregate_order_by: Assessment_Result_Parent_Aggregate_Order_By;
  assessment_result_parent_arr_rel_insert_input: Assessment_Result_Parent_Arr_Rel_Insert_Input;
  assessment_result_parent_audit: ResolverTypeWrapper<Assessment_Result_Parent_Audit>;
  assessment_result_parent_audit_aggregate_order_by: Assessment_Result_Parent_Audit_Aggregate_Order_By;
  assessment_result_parent_audit_bool_exp: Assessment_Result_Parent_Audit_Bool_Exp;
  assessment_result_parent_audit_max_order_by: Assessment_Result_Parent_Audit_Max_Order_By;
  assessment_result_parent_audit_min_order_by: Assessment_Result_Parent_Audit_Min_Order_By;
  assessment_result_parent_audit_order_by: Assessment_Result_Parent_Audit_Order_By;
  assessment_result_parent_audit_select_column: Assessment_Result_Parent_Audit_Select_Column;
  assessment_result_parent_audit_stream_cursor_input: Assessment_Result_Parent_Audit_Stream_Cursor_Input;
  assessment_result_parent_audit_stream_cursor_value_input: Assessment_Result_Parent_Audit_Stream_Cursor_Value_Input;
  assessment_result_parent_bool_exp: Assessment_Result_Parent_Bool_Exp;
  assessment_result_parent_constraint: Assessment_Result_Parent_Constraint;
  assessment_result_parent_insert_input: Assessment_Result_Parent_Insert_Input;
  assessment_result_parent_max_fields: ResolverTypeWrapper<Assessment_Result_Parent_Max_Fields>;
  assessment_result_parent_max_order_by: Assessment_Result_Parent_Max_Order_By;
  assessment_result_parent_min_fields: ResolverTypeWrapper<Assessment_Result_Parent_Min_Fields>;
  assessment_result_parent_min_order_by: Assessment_Result_Parent_Min_Order_By;
  assessment_result_parent_mutation_response: ResolverTypeWrapper<Assessment_Result_Parent_Mutation_Response>;
  assessment_result_parent_on_conflict: Assessment_Result_Parent_On_Conflict;
  assessment_result_parent_order_by: Assessment_Result_Parent_Order_By;
  assessment_result_parent_select_column: Assessment_Result_Parent_Select_Column;
  assessment_result_parent_stream_cursor_input: Assessment_Result_Parent_Stream_Cursor_Input;
  assessment_result_parent_stream_cursor_value_input: Assessment_Result_Parent_Stream_Cursor_Value_Input;
  assessment_result_parent_update_column: Assessment_Result_Parent_Update_Column;
  assessment_select_column: Assessment_Select_Column;
  assessment_set_input: Assessment_Set_Input;
  assessment_status_enum: Assessment_Status_Enum;
  assessment_status_enum_comparison_exp: Assessment_Status_Enum_Comparison_Exp;
  assessment_stddev_fields: ResolverTypeWrapper<Assessment_Stddev_Fields>;
  assessment_stddev_pop_fields: ResolverTypeWrapper<Assessment_Stddev_Pop_Fields>;
  assessment_stddev_samp_fields: ResolverTypeWrapper<Assessment_Stddev_Samp_Fields>;
  assessment_stream_cursor_input: Assessment_Stream_Cursor_Input;
  assessment_stream_cursor_value_input: Assessment_Stream_Cursor_Value_Input;
  assessment_sum_fields: ResolverTypeWrapper<Assessment_Sum_Fields>;
  assessment_update_column: Assessment_Update_Column;
  assessment_updates: Assessment_Updates;
  assessment_var_pop_fields: ResolverTypeWrapper<Assessment_Var_Pop_Fields>;
  assessment_var_samp_fields: ResolverTypeWrapper<Assessment_Var_Samp_Fields>;
  assessment_variance_fields: ResolverTypeWrapper<Assessment_Variance_Fields>;
  attestation_config: ResolverTypeWrapper<Attestation_Config>;
  attestation_config_bool_exp: Attestation_Config_Bool_Exp;
  attestation_config_constraint: Attestation_Config_Constraint;
  attestation_config_insert_input: Attestation_Config_Insert_Input;
  attestation_config_mutation_response: ResolverTypeWrapper<Attestation_Config_Mutation_Response>;
  attestation_config_obj_rel_insert_input: Attestation_Config_Obj_Rel_Insert_Input;
  attestation_config_on_conflict: Attestation_Config_On_Conflict;
  attestation_config_order_by: Attestation_Config_Order_By;
  attestation_config_pk_columns_input: Attestation_Config_Pk_Columns_Input;
  attestation_config_select_column: Attestation_Config_Select_Column;
  attestation_config_set_input: Attestation_Config_Set_Input;
  attestation_config_stream_cursor_input: Attestation_Config_Stream_Cursor_Input;
  attestation_config_stream_cursor_value_input: Attestation_Config_Stream_Cursor_Value_Input;
  attestation_config_update_column: Attestation_Config_Update_Column;
  attestation_config_updates: Attestation_Config_Updates;
  attestation_group: ResolverTypeWrapper<Attestation_Group>;
  attestation_group_aggregate_order_by: Attestation_Group_Aggregate_Order_By;
  attestation_group_arr_rel_insert_input: Attestation_Group_Arr_Rel_Insert_Input;
  attestation_group_bool_exp: Attestation_Group_Bool_Exp;
  attestation_group_constraint: Attestation_Group_Constraint;
  attestation_group_insert_input: Attestation_Group_Insert_Input;
  attestation_group_max_order_by: Attestation_Group_Max_Order_By;
  attestation_group_min_order_by: Attestation_Group_Min_Order_By;
  attestation_group_mutation_response: ResolverTypeWrapper<Attestation_Group_Mutation_Response>;
  attestation_group_on_conflict: Attestation_Group_On_Conflict;
  attestation_group_order_by: Attestation_Group_Order_By;
  attestation_group_pk_columns_input: Attestation_Group_Pk_Columns_Input;
  attestation_group_select_column: Attestation_Group_Select_Column;
  attestation_group_set_input: Attestation_Group_Set_Input;
  attestation_group_stream_cursor_input: Attestation_Group_Stream_Cursor_Input;
  attestation_group_stream_cursor_value_input: Attestation_Group_Stream_Cursor_Value_Input;
  attestation_group_update_column: Attestation_Group_Update_Column;
  attestation_group_updates: Attestation_Group_Updates;
  attestation_record: ResolverTypeWrapper<Attestation_Record>;
  attestation_record_aggregate: ResolverTypeWrapper<Attestation_Record_Aggregate>;
  attestation_record_aggregate_bool_exp: Attestation_Record_Aggregate_Bool_Exp;
  attestation_record_aggregate_bool_exp_bool_and: Attestation_Record_Aggregate_Bool_Exp_Bool_And;
  attestation_record_aggregate_bool_exp_bool_or: Attestation_Record_Aggregate_Bool_Exp_Bool_Or;
  attestation_record_aggregate_bool_exp_count: Attestation_Record_Aggregate_Bool_Exp_Count;
  attestation_record_aggregate_fields: ResolverTypeWrapper<Attestation_Record_Aggregate_Fields>;
  attestation_record_aggregate_order_by: Attestation_Record_Aggregate_Order_By;
  attestation_record_arr_rel_insert_input: Attestation_Record_Arr_Rel_Insert_Input;
  attestation_record_bool_exp: Attestation_Record_Bool_Exp;
  attestation_record_constraint: Attestation_Record_Constraint;
  attestation_record_insert_input: Attestation_Record_Insert_Input;
  attestation_record_max_fields: ResolverTypeWrapper<Attestation_Record_Max_Fields>;
  attestation_record_max_order_by: Attestation_Record_Max_Order_By;
  attestation_record_min_fields: ResolverTypeWrapper<Attestation_Record_Min_Fields>;
  attestation_record_min_order_by: Attestation_Record_Min_Order_By;
  attestation_record_on_conflict: Attestation_Record_On_Conflict;
  attestation_record_order_by: Attestation_Record_Order_By;
  attestation_record_select_column: Attestation_Record_Select_Column;
  attestation_record_select_column_attestation_record_aggregate_bool_exp_bool_and_arguments_columns: Attestation_Record_Select_Column_Attestation_Record_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  attestation_record_select_column_attestation_record_aggregate_bool_exp_bool_or_arguments_columns: Attestation_Record_Select_Column_Attestation_Record_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  attestation_record_status_enum: Attestation_Record_Status_Enum;
  attestation_record_status_enum_comparison_exp: Attestation_Record_Status_Enum_Comparison_Exp;
  attestation_record_stream_cursor_input: Attestation_Record_Stream_Cursor_Input;
  attestation_record_stream_cursor_value_input: Attestation_Record_Stream_Cursor_Value_Input;
  attestation_record_update_column: Attestation_Record_Update_Column;
  audit_log_view: ResolverTypeWrapper<Audit_Log_View>;
  audit_log_view_aggregate: ResolverTypeWrapper<Audit_Log_View_Aggregate>;
  audit_log_view_aggregate_fields: ResolverTypeWrapper<Audit_Log_View_Aggregate_Fields>;
  audit_log_view_bool_exp: Audit_Log_View_Bool_Exp;
  audit_log_view_max_fields: ResolverTypeWrapper<Audit_Log_View_Max_Fields>;
  audit_log_view_min_fields: ResolverTypeWrapper<Audit_Log_View_Min_Fields>;
  audit_log_view_order_by: Audit_Log_View_Order_By;
  audit_log_view_select_column: Audit_Log_View_Select_Column;
  audit_log_view_stream_cursor_input: Audit_Log_View_Stream_Cursor_Input;
  audit_log_view_stream_cursor_value_input: Audit_Log_View_Stream_Cursor_Value_Input;
  auth_organisation: ResolverTypeWrapper<Auth_Organisation>;
  auth_organisation_bool_exp: Auth_Organisation_Bool_Exp;
  auth_organisation_order_by: Auth_Organisation_Order_By;
  auth_organisation_select_column: Auth_Organisation_Select_Column;
  auth_organisation_stream_cursor_input: Auth_Organisation_Stream_Cursor_Input;
  auth_organisation_stream_cursor_value_input: Auth_Organisation_Stream_Cursor_Value_Input;
  auth_organisationuser: ResolverTypeWrapper<Auth_Organisationuser>;
  auth_organisationuser_aggregate_order_by: Auth_Organisationuser_Aggregate_Order_By;
  auth_organisationuser_bool_exp: Auth_Organisationuser_Bool_Exp;
  auth_organisationuser_max_order_by: Auth_Organisationuser_Max_Order_By;
  auth_organisationuser_min_order_by: Auth_Organisationuser_Min_Order_By;
  auth_organisationuser_mutation_response: ResolverTypeWrapper<Auth_Organisationuser_Mutation_Response>;
  auth_organisationuser_order_by: Auth_Organisationuser_Order_By;
  auth_organisationuser_pk_columns_input: Auth_Organisationuser_Pk_Columns_Input;
  auth_organisationuser_select_column: Auth_Organisationuser_Select_Column;
  auth_organisationuser_set_input: Auth_Organisationuser_Set_Input;
  auth_organisationuser_stream_cursor_input: Auth_Organisationuser_Stream_Cursor_Input;
  auth_organisationuser_stream_cursor_value_input: Auth_Organisationuser_Stream_Cursor_Value_Input;
  auth_organisationuser_updates: Auth_Organisationuser_Updates;
  auth_user: ResolverTypeWrapper<Auth_User>;
  auth_user_aggregate: ResolverTypeWrapper<Auth_User_Aggregate>;
  auth_user_aggregate_fields: ResolverTypeWrapper<Auth_User_Aggregate_Fields>;
  auth_user_bool_exp: Auth_User_Bool_Exp;
  auth_user_max_fields: ResolverTypeWrapper<Auth_User_Max_Fields>;
  auth_user_min_fields: ResolverTypeWrapper<Auth_User_Min_Fields>;
  auth_user_mutation_response: ResolverTypeWrapper<Auth_User_Mutation_Response>;
  auth_user_order_by: Auth_User_Order_By;
  auth_user_pk_columns_input: Auth_User_Pk_Columns_Input;
  auth_user_select_column: Auth_User_Select_Column;
  auth_user_set_input: Auth_User_Set_Input;
  auth_user_stream_cursor_input: Auth_User_Stream_Cursor_Input;
  auth_user_stream_cursor_value_input: Auth_User_Stream_Cursor_Value_Input;
  auth_user_updates: Auth_User_Updates;
  bigint: ResolverTypeWrapper<Scalars['bigint']['output']>;
  bigint_comparison_exp: Bigint_Comparison_Exp;
  business_area: ResolverTypeWrapper<Business_Area>;
  business_area_aggregate: ResolverTypeWrapper<Business_Area_Aggregate>;
  business_area_aggregate_fields: ResolverTypeWrapper<Business_Area_Aggregate_Fields>;
  business_area_avg_fields: ResolverTypeWrapper<Business_Area_Avg_Fields>;
  business_area_bool_exp: Business_Area_Bool_Exp;
  business_area_constraint: Business_Area_Constraint;
  business_area_insert_input: Business_Area_Insert_Input;
  business_area_max_fields: ResolverTypeWrapper<Business_Area_Max_Fields>;
  business_area_min_fields: ResolverTypeWrapper<Business_Area_Min_Fields>;
  business_area_mutation_response: ResolverTypeWrapper<Business_Area_Mutation_Response>;
  business_area_obj_rel_insert_input: Business_Area_Obj_Rel_Insert_Input;
  business_area_on_conflict: Business_Area_On_Conflict;
  business_area_order_by: Business_Area_Order_By;
  business_area_pk_columns_input: Business_Area_Pk_Columns_Input;
  business_area_select_column: Business_Area_Select_Column;
  business_area_set_input: Business_Area_Set_Input;
  business_area_stddev_fields: ResolverTypeWrapper<Business_Area_Stddev_Fields>;
  business_area_stddev_pop_fields: ResolverTypeWrapper<Business_Area_Stddev_Pop_Fields>;
  business_area_stddev_samp_fields: ResolverTypeWrapper<Business_Area_Stddev_Samp_Fields>;
  business_area_stream_cursor_input: Business_Area_Stream_Cursor_Input;
  business_area_stream_cursor_value_input: Business_Area_Stream_Cursor_Value_Input;
  business_area_sum_fields: ResolverTypeWrapper<Business_Area_Sum_Fields>;
  business_area_update_column: Business_Area_Update_Column;
  business_area_updates: Business_Area_Updates;
  business_area_var_pop_fields: ResolverTypeWrapper<Business_Area_Var_Pop_Fields>;
  business_area_var_samp_fields: ResolverTypeWrapper<Business_Area_Var_Samp_Fields>;
  business_area_variance_fields: ResolverTypeWrapper<Business_Area_Variance_Fields>;
  cause: ResolverTypeWrapper<Cause>;
  cause_aggregate: ResolverTypeWrapper<Cause_Aggregate>;
  cause_aggregate_bool_exp: Cause_Aggregate_Bool_Exp;
  cause_aggregate_bool_exp_count: Cause_Aggregate_Bool_Exp_Count;
  cause_aggregate_fields: ResolverTypeWrapper<Cause_Aggregate_Fields>;
  cause_aggregate_order_by: Cause_Aggregate_Order_By;
  cause_append_input: Cause_Append_Input;
  cause_arr_rel_insert_input: Cause_Arr_Rel_Insert_Input;
  cause_audit: ResolverTypeWrapper<Cause_Audit>;
  cause_audit_bool_exp: Cause_Audit_Bool_Exp;
  cause_audit_order_by: Cause_Audit_Order_By;
  cause_audit_select_column: Cause_Audit_Select_Column;
  cause_audit_stream_cursor_input: Cause_Audit_Stream_Cursor_Input;
  cause_audit_stream_cursor_value_input: Cause_Audit_Stream_Cursor_Value_Input;
  cause_avg_fields: ResolverTypeWrapper<Cause_Avg_Fields>;
  cause_avg_order_by: Cause_Avg_Order_By;
  cause_bool_exp: Cause_Bool_Exp;
  cause_constraint: Cause_Constraint;
  cause_delete_at_path_input: Cause_Delete_At_Path_Input;
  cause_delete_elem_input: Cause_Delete_Elem_Input;
  cause_delete_key_input: Cause_Delete_Key_Input;
  cause_inc_input: Cause_Inc_Input;
  cause_insert_input: Cause_Insert_Input;
  cause_max_fields: ResolverTypeWrapper<Cause_Max_Fields>;
  cause_max_order_by: Cause_Max_Order_By;
  cause_min_fields: ResolverTypeWrapper<Cause_Min_Fields>;
  cause_min_order_by: Cause_Min_Order_By;
  cause_mutation_response: ResolverTypeWrapper<Cause_Mutation_Response>;
  cause_on_conflict: Cause_On_Conflict;
  cause_order_by: Cause_Order_By;
  cause_pk_columns_input: Cause_Pk_Columns_Input;
  cause_prepend_input: Cause_Prepend_Input;
  cause_select_column: Cause_Select_Column;
  cause_set_input: Cause_Set_Input;
  cause_stddev_fields: ResolverTypeWrapper<Cause_Stddev_Fields>;
  cause_stddev_order_by: Cause_Stddev_Order_By;
  cause_stddev_pop_fields: ResolverTypeWrapper<Cause_Stddev_Pop_Fields>;
  cause_stddev_pop_order_by: Cause_Stddev_Pop_Order_By;
  cause_stddev_samp_fields: ResolverTypeWrapper<Cause_Stddev_Samp_Fields>;
  cause_stddev_samp_order_by: Cause_Stddev_Samp_Order_By;
  cause_stream_cursor_input: Cause_Stream_Cursor_Input;
  cause_stream_cursor_value_input: Cause_Stream_Cursor_Value_Input;
  cause_sum_fields: ResolverTypeWrapper<Cause_Sum_Fields>;
  cause_sum_order_by: Cause_Sum_Order_By;
  cause_update_column: Cause_Update_Column;
  cause_updates: Cause_Updates;
  cause_var_pop_fields: ResolverTypeWrapper<Cause_Var_Pop_Fields>;
  cause_var_pop_order_by: Cause_Var_Pop_Order_By;
  cause_var_samp_fields: ResolverTypeWrapper<Cause_Var_Samp_Fields>;
  cause_var_samp_order_by: Cause_Var_Samp_Order_By;
  cause_variance_fields: ResolverTypeWrapper<Cause_Variance_Fields>;
  cause_variance_order_by: Cause_Variance_Order_By;
  change_request: ResolverTypeWrapper<Change_Request>;
  change_request_aggregate: ResolverTypeWrapper<Change_Request_Aggregate>;
  change_request_aggregate_bool_exp: Change_Request_Aggregate_Bool_Exp;
  change_request_aggregate_bool_exp_count: Change_Request_Aggregate_Bool_Exp_Count;
  change_request_aggregate_fields: ResolverTypeWrapper<Change_Request_Aggregate_Fields>;
  change_request_aggregate_order_by: Change_Request_Aggregate_Order_By;
  change_request_arr_rel_insert_input: Change_Request_Arr_Rel_Insert_Input;
  change_request_audit: ResolverTypeWrapper<Change_Request_Audit>;
  change_request_audit_bool_exp: Change_Request_Audit_Bool_Exp;
  change_request_audit_order_by: Change_Request_Audit_Order_By;
  change_request_audit_select_column: Change_Request_Audit_Select_Column;
  change_request_audit_stream_cursor_input: Change_Request_Audit_Stream_Cursor_Input;
  change_request_audit_stream_cursor_value_input: Change_Request_Audit_Stream_Cursor_Value_Input;
  change_request_avg_fields: ResolverTypeWrapper<Change_Request_Avg_Fields>;
  change_request_avg_order_by: Change_Request_Avg_Order_By;
  change_request_bool_exp: Change_Request_Bool_Exp;
  change_request_constraint: Change_Request_Constraint;
  change_request_contributor: ResolverTypeWrapper<Change_Request_Contributor>;
  change_request_contributor_aggregate_order_by: Change_Request_Contributor_Aggregate_Order_By;
  change_request_contributor_arr_rel_insert_input: Change_Request_Contributor_Arr_Rel_Insert_Input;
  change_request_contributor_bool_exp: Change_Request_Contributor_Bool_Exp;
  change_request_contributor_constraint: Change_Request_Contributor_Constraint;
  change_request_contributor_insert_input: Change_Request_Contributor_Insert_Input;
  change_request_contributor_max_order_by: Change_Request_Contributor_Max_Order_By;
  change_request_contributor_min_order_by: Change_Request_Contributor_Min_Order_By;
  change_request_contributor_on_conflict: Change_Request_Contributor_On_Conflict;
  change_request_contributor_order_by: Change_Request_Contributor_Order_By;
  change_request_contributor_select_column: Change_Request_Contributor_Select_Column;
  change_request_contributor_stream_cursor_input: Change_Request_Contributor_Stream_Cursor_Input;
  change_request_contributor_stream_cursor_value_input: Change_Request_Contributor_Stream_Cursor_Value_Input;
  change_request_contributor_update_column: Change_Request_Contributor_Update_Column;
  change_request_insert_input: Change_Request_Insert_Input;
  change_request_max_fields: ResolverTypeWrapper<Change_Request_Max_Fields>;
  change_request_max_order_by: Change_Request_Max_Order_By;
  change_request_min_fields: ResolverTypeWrapper<Change_Request_Min_Fields>;
  change_request_min_order_by: Change_Request_Min_Order_By;
  change_request_obj_rel_insert_input: Change_Request_Obj_Rel_Insert_Input;
  change_request_on_conflict: Change_Request_On_Conflict;
  change_request_order_by: Change_Request_Order_By;
  change_request_select_column: Change_Request_Select_Column;
  change_request_stddev_fields: ResolverTypeWrapper<Change_Request_Stddev_Fields>;
  change_request_stddev_order_by: Change_Request_Stddev_Order_By;
  change_request_stddev_pop_fields: ResolverTypeWrapper<Change_Request_Stddev_Pop_Fields>;
  change_request_stddev_pop_order_by: Change_Request_Stddev_Pop_Order_By;
  change_request_stddev_samp_fields: ResolverTypeWrapper<Change_Request_Stddev_Samp_Fields>;
  change_request_stddev_samp_order_by: Change_Request_Stddev_Samp_Order_By;
  change_request_stream_cursor_input: Change_Request_Stream_Cursor_Input;
  change_request_stream_cursor_value_input: Change_Request_Stream_Cursor_Value_Input;
  change_request_sum_fields: ResolverTypeWrapper<Change_Request_Sum_Fields>;
  change_request_sum_order_by: Change_Request_Sum_Order_By;
  change_request_update_column: Change_Request_Update_Column;
  change_request_var_pop_fields: ResolverTypeWrapper<Change_Request_Var_Pop_Fields>;
  change_request_var_pop_order_by: Change_Request_Var_Pop_Order_By;
  change_request_var_samp_fields: ResolverTypeWrapper<Change_Request_Var_Samp_Fields>;
  change_request_var_samp_order_by: Change_Request_Var_Samp_Order_By;
  change_request_variance_fields: ResolverTypeWrapper<Change_Request_Variance_Fields>;
  change_request_variance_order_by: Change_Request_Variance_Order_By;
  comment: ResolverTypeWrapper<Comment>;
  comment_aggregate_order_by: Comment_Aggregate_Order_By;
  comment_arr_rel_insert_input: Comment_Arr_Rel_Insert_Input;
  comment_audit: ResolverTypeWrapper<Comment_Audit>;
  comment_audit_bool_exp: Comment_Audit_Bool_Exp;
  comment_audit_order_by: Comment_Audit_Order_By;
  comment_audit_select_column: Comment_Audit_Select_Column;
  comment_audit_stream_cursor_input: Comment_Audit_Stream_Cursor_Input;
  comment_audit_stream_cursor_value_input: Comment_Audit_Stream_Cursor_Value_Input;
  comment_bool_exp: Comment_Bool_Exp;
  comment_constraint: Comment_Constraint;
  comment_insert_input: Comment_Insert_Input;
  comment_max_order_by: Comment_Max_Order_By;
  comment_min_order_by: Comment_Min_Order_By;
  comment_mutation_response: ResolverTypeWrapper<Comment_Mutation_Response>;
  comment_on_conflict: Comment_On_Conflict;
  comment_order_by: Comment_Order_By;
  comment_pk_columns_input: Comment_Pk_Columns_Input;
  comment_select_column: Comment_Select_Column;
  comment_set_input: Comment_Set_Input;
  comment_stream_cursor_input: Comment_Stream_Cursor_Input;
  comment_stream_cursor_value_input: Comment_Stream_Cursor_Value_Input;
  comment_update_column: Comment_Update_Column;
  comment_updates: Comment_Updates;
  compliance_monitoring_assessment: ResolverTypeWrapper<Compliance_Monitoring_Assessment>;
  compliance_monitoring_assessment_aggregate: ResolverTypeWrapper<Compliance_Monitoring_Assessment_Aggregate>;
  compliance_monitoring_assessment_aggregate_fields: ResolverTypeWrapper<Compliance_Monitoring_Assessment_Aggregate_Fields>;
  compliance_monitoring_assessment_append_input: Compliance_Monitoring_Assessment_Append_Input;
  compliance_monitoring_assessment_avg_fields: ResolverTypeWrapper<Compliance_Monitoring_Assessment_Avg_Fields>;
  compliance_monitoring_assessment_bool_exp: Compliance_Monitoring_Assessment_Bool_Exp;
  compliance_monitoring_assessment_constraint: Compliance_Monitoring_Assessment_Constraint;
  compliance_monitoring_assessment_delete_at_path_input: Compliance_Monitoring_Assessment_Delete_At_Path_Input;
  compliance_monitoring_assessment_delete_elem_input: Compliance_Monitoring_Assessment_Delete_Elem_Input;
  compliance_monitoring_assessment_delete_key_input: Compliance_Monitoring_Assessment_Delete_Key_Input;
  compliance_monitoring_assessment_inc_input: Compliance_Monitoring_Assessment_Inc_Input;
  compliance_monitoring_assessment_insert_input: Compliance_Monitoring_Assessment_Insert_Input;
  compliance_monitoring_assessment_max_fields: ResolverTypeWrapper<Compliance_Monitoring_Assessment_Max_Fields>;
  compliance_monitoring_assessment_min_fields: ResolverTypeWrapper<Compliance_Monitoring_Assessment_Min_Fields>;
  compliance_monitoring_assessment_mutation_response: ResolverTypeWrapper<Compliance_Monitoring_Assessment_Mutation_Response>;
  compliance_monitoring_assessment_obj_rel_insert_input: Compliance_Monitoring_Assessment_Obj_Rel_Insert_Input;
  compliance_monitoring_assessment_on_conflict: Compliance_Monitoring_Assessment_On_Conflict;
  compliance_monitoring_assessment_order_by: Compliance_Monitoring_Assessment_Order_By;
  compliance_monitoring_assessment_pk_columns_input: Compliance_Monitoring_Assessment_Pk_Columns_Input;
  compliance_monitoring_assessment_prepend_input: Compliance_Monitoring_Assessment_Prepend_Input;
  compliance_monitoring_assessment_select_column: Compliance_Monitoring_Assessment_Select_Column;
  compliance_monitoring_assessment_set_input: Compliance_Monitoring_Assessment_Set_Input;
  compliance_monitoring_assessment_stddev_fields: ResolverTypeWrapper<Compliance_Monitoring_Assessment_Stddev_Fields>;
  compliance_monitoring_assessment_stddev_pop_fields: ResolverTypeWrapper<Compliance_Monitoring_Assessment_Stddev_Pop_Fields>;
  compliance_monitoring_assessment_stddev_samp_fields: ResolverTypeWrapper<Compliance_Monitoring_Assessment_Stddev_Samp_Fields>;
  compliance_monitoring_assessment_stream_cursor_input: Compliance_Monitoring_Assessment_Stream_Cursor_Input;
  compliance_monitoring_assessment_stream_cursor_value_input: Compliance_Monitoring_Assessment_Stream_Cursor_Value_Input;
  compliance_monitoring_assessment_sum_fields: ResolverTypeWrapper<Compliance_Monitoring_Assessment_Sum_Fields>;
  compliance_monitoring_assessment_update_column: Compliance_Monitoring_Assessment_Update_Column;
  compliance_monitoring_assessment_updates: Compliance_Monitoring_Assessment_Updates;
  compliance_monitoring_assessment_var_pop_fields: ResolverTypeWrapper<Compliance_Monitoring_Assessment_Var_Pop_Fields>;
  compliance_monitoring_assessment_var_samp_fields: ResolverTypeWrapper<Compliance_Monitoring_Assessment_Var_Samp_Fields>;
  compliance_monitoring_assessment_variance_fields: ResolverTypeWrapper<Compliance_Monitoring_Assessment_Variance_Fields>;
  consequence: ResolverTypeWrapper<Consequence>;
  consequence_aggregate: ResolverTypeWrapper<Consequence_Aggregate>;
  consequence_aggregate_bool_exp: Consequence_Aggregate_Bool_Exp;
  consequence_aggregate_bool_exp_count: Consequence_Aggregate_Bool_Exp_Count;
  consequence_aggregate_fields: ResolverTypeWrapper<Consequence_Aggregate_Fields>;
  consequence_aggregate_order_by: Consequence_Aggregate_Order_By;
  consequence_append_input: Consequence_Append_Input;
  consequence_arr_rel_insert_input: Consequence_Arr_Rel_Insert_Input;
  consequence_audit: ResolverTypeWrapper<Consequence_Audit>;
  consequence_audit_aggregate: ResolverTypeWrapper<Consequence_Audit_Aggregate>;
  consequence_audit_aggregate_fields: ResolverTypeWrapper<Consequence_Audit_Aggregate_Fields>;
  consequence_audit_avg_fields: ResolverTypeWrapper<Consequence_Audit_Avg_Fields>;
  consequence_audit_bool_exp: Consequence_Audit_Bool_Exp;
  consequence_audit_max_fields: ResolverTypeWrapper<Consequence_Audit_Max_Fields>;
  consequence_audit_min_fields: ResolverTypeWrapper<Consequence_Audit_Min_Fields>;
  consequence_audit_order_by: Consequence_Audit_Order_By;
  consequence_audit_select_column: Consequence_Audit_Select_Column;
  consequence_audit_stddev_fields: ResolverTypeWrapper<Consequence_Audit_Stddev_Fields>;
  consequence_audit_stddev_pop_fields: ResolverTypeWrapper<Consequence_Audit_Stddev_Pop_Fields>;
  consequence_audit_stddev_samp_fields: ResolverTypeWrapper<Consequence_Audit_Stddev_Samp_Fields>;
  consequence_audit_stream_cursor_input: Consequence_Audit_Stream_Cursor_Input;
  consequence_audit_stream_cursor_value_input: Consequence_Audit_Stream_Cursor_Value_Input;
  consequence_audit_sum_fields: ResolverTypeWrapper<Consequence_Audit_Sum_Fields>;
  consequence_audit_var_pop_fields: ResolverTypeWrapper<Consequence_Audit_Var_Pop_Fields>;
  consequence_audit_var_samp_fields: ResolverTypeWrapper<Consequence_Audit_Var_Samp_Fields>;
  consequence_audit_variance_fields: ResolverTypeWrapper<Consequence_Audit_Variance_Fields>;
  consequence_avg_fields: ResolverTypeWrapper<Consequence_Avg_Fields>;
  consequence_avg_order_by: Consequence_Avg_Order_By;
  consequence_bool_exp: Consequence_Bool_Exp;
  consequence_constraint: Consequence_Constraint;
  consequence_delete_at_path_input: Consequence_Delete_At_Path_Input;
  consequence_delete_elem_input: Consequence_Delete_Elem_Input;
  consequence_delete_key_input: Consequence_Delete_Key_Input;
  consequence_inc_input: Consequence_Inc_Input;
  consequence_insert_input: Consequence_Insert_Input;
  consequence_max_fields: ResolverTypeWrapper<Consequence_Max_Fields>;
  consequence_max_order_by: Consequence_Max_Order_By;
  consequence_min_fields: ResolverTypeWrapper<Consequence_Min_Fields>;
  consequence_min_order_by: Consequence_Min_Order_By;
  consequence_mutation_response: ResolverTypeWrapper<Consequence_Mutation_Response>;
  consequence_on_conflict: Consequence_On_Conflict;
  consequence_order_by: Consequence_Order_By;
  consequence_pk_columns_input: Consequence_Pk_Columns_Input;
  consequence_prepend_input: Consequence_Prepend_Input;
  consequence_select_column: Consequence_Select_Column;
  consequence_set_input: Consequence_Set_Input;
  consequence_stddev_fields: ResolverTypeWrapper<Consequence_Stddev_Fields>;
  consequence_stddev_order_by: Consequence_Stddev_Order_By;
  consequence_stddev_pop_fields: ResolverTypeWrapper<Consequence_Stddev_Pop_Fields>;
  consequence_stddev_pop_order_by: Consequence_Stddev_Pop_Order_By;
  consequence_stddev_samp_fields: ResolverTypeWrapper<Consequence_Stddev_Samp_Fields>;
  consequence_stddev_samp_order_by: Consequence_Stddev_Samp_Order_By;
  consequence_stream_cursor_input: Consequence_Stream_Cursor_Input;
  consequence_stream_cursor_value_input: Consequence_Stream_Cursor_Value_Input;
  consequence_sum_fields: ResolverTypeWrapper<Consequence_Sum_Fields>;
  consequence_sum_order_by: Consequence_Sum_Order_By;
  consequence_type_enum: Consequence_Type_Enum;
  consequence_type_enum_comparison_exp: Consequence_Type_Enum_Comparison_Exp;
  consequence_update_column: Consequence_Update_Column;
  consequence_updates: Consequence_Updates;
  consequence_var_pop_fields: ResolverTypeWrapper<Consequence_Var_Pop_Fields>;
  consequence_var_pop_order_by: Consequence_Var_Pop_Order_By;
  consequence_var_samp_fields: ResolverTypeWrapper<Consequence_Var_Samp_Fields>;
  consequence_var_samp_order_by: Consequence_Var_Samp_Order_By;
  consequence_variance_fields: ResolverTypeWrapper<Consequence_Variance_Fields>;
  consequence_variance_order_by: Consequence_Variance_Order_By;
  contributor: ResolverTypeWrapper<Contributor>;
  contributor_aggregate: ResolverTypeWrapper<Contributor_Aggregate>;
  contributor_aggregate_bool_exp: Contributor_Aggregate_Bool_Exp;
  contributor_aggregate_bool_exp_count: Contributor_Aggregate_Bool_Exp_Count;
  contributor_aggregate_fields: ResolverTypeWrapper<Contributor_Aggregate_Fields>;
  contributor_aggregate_order_by: Contributor_Aggregate_Order_By;
  contributor_arr_rel_insert_input: Contributor_Arr_Rel_Insert_Input;
  contributor_audit: ResolverTypeWrapper<Contributor_Audit>;
  contributor_audit_aggregate: ResolverTypeWrapper<Contributor_Audit_Aggregate>;
  contributor_audit_aggregate_bool_exp: Contributor_Audit_Aggregate_Bool_Exp;
  contributor_audit_aggregate_bool_exp_count: Contributor_Audit_Aggregate_Bool_Exp_Count;
  contributor_audit_aggregate_fields: ResolverTypeWrapper<Contributor_Audit_Aggregate_Fields>;
  contributor_audit_aggregate_order_by: Contributor_Audit_Aggregate_Order_By;
  contributor_audit_bool_exp: Contributor_Audit_Bool_Exp;
  contributor_audit_max_fields: ResolverTypeWrapper<Contributor_Audit_Max_Fields>;
  contributor_audit_max_order_by: Contributor_Audit_Max_Order_By;
  contributor_audit_min_fields: ResolverTypeWrapper<Contributor_Audit_Min_Fields>;
  contributor_audit_min_order_by: Contributor_Audit_Min_Order_By;
  contributor_audit_order_by: Contributor_Audit_Order_By;
  contributor_audit_select_column: Contributor_Audit_Select_Column;
  contributor_bool_exp: Contributor_Bool_Exp;
  contributor_constraint: Contributor_Constraint;
  contributor_group: ResolverTypeWrapper<Contributor_Group>;
  contributor_group_aggregate: ResolverTypeWrapper<Contributor_Group_Aggregate>;
  contributor_group_aggregate_bool_exp: Contributor_Group_Aggregate_Bool_Exp;
  contributor_group_aggregate_bool_exp_count: Contributor_Group_Aggregate_Bool_Exp_Count;
  contributor_group_aggregate_fields: ResolverTypeWrapper<Contributor_Group_Aggregate_Fields>;
  contributor_group_aggregate_order_by: Contributor_Group_Aggregate_Order_By;
  contributor_group_arr_rel_insert_input: Contributor_Group_Arr_Rel_Insert_Input;
  contributor_group_audit: ResolverTypeWrapper<Contributor_Group_Audit>;
  contributor_group_audit_aggregate: ResolverTypeWrapper<Contributor_Group_Audit_Aggregate>;
  contributor_group_audit_aggregate_bool_exp: Contributor_Group_Audit_Aggregate_Bool_Exp;
  contributor_group_audit_aggregate_bool_exp_count: Contributor_Group_Audit_Aggregate_Bool_Exp_Count;
  contributor_group_audit_aggregate_fields: ResolverTypeWrapper<Contributor_Group_Audit_Aggregate_Fields>;
  contributor_group_audit_aggregate_order_by: Contributor_Group_Audit_Aggregate_Order_By;
  contributor_group_audit_bool_exp: Contributor_Group_Audit_Bool_Exp;
  contributor_group_audit_max_fields: ResolverTypeWrapper<Contributor_Group_Audit_Max_Fields>;
  contributor_group_audit_max_order_by: Contributor_Group_Audit_Max_Order_By;
  contributor_group_audit_min_fields: ResolverTypeWrapper<Contributor_Group_Audit_Min_Fields>;
  contributor_group_audit_min_order_by: Contributor_Group_Audit_Min_Order_By;
  contributor_group_audit_order_by: Contributor_Group_Audit_Order_By;
  contributor_group_audit_select_column: Contributor_Group_Audit_Select_Column;
  contributor_group_bool_exp: Contributor_Group_Bool_Exp;
  contributor_group_constraint: Contributor_Group_Constraint;
  contributor_group_insert_input: Contributor_Group_Insert_Input;
  contributor_group_max_fields: ResolverTypeWrapper<Contributor_Group_Max_Fields>;
  contributor_group_max_order_by: Contributor_Group_Max_Order_By;
  contributor_group_min_fields: ResolverTypeWrapper<Contributor_Group_Min_Fields>;
  contributor_group_min_order_by: Contributor_Group_Min_Order_By;
  contributor_group_mutation_response: ResolverTypeWrapper<Contributor_Group_Mutation_Response>;
  contributor_group_on_conflict: Contributor_Group_On_Conflict;
  contributor_group_order_by: Contributor_Group_Order_By;
  contributor_group_select_column: Contributor_Group_Select_Column;
  contributor_group_update_column: Contributor_Group_Update_Column;
  contributor_insert_input: Contributor_Insert_Input;
  contributor_max_fields: ResolverTypeWrapper<Contributor_Max_Fields>;
  contributor_max_order_by: Contributor_Max_Order_By;
  contributor_min_fields: ResolverTypeWrapper<Contributor_Min_Fields>;
  contributor_min_order_by: Contributor_Min_Order_By;
  contributor_mutation_response: ResolverTypeWrapper<Contributor_Mutation_Response>;
  contributor_on_conflict: Contributor_On_Conflict;
  contributor_order_by: Contributor_Order_By;
  contributor_select_column: Contributor_Select_Column;
  contributor_type_enum: Contributor_Type_Enum;
  contributor_type_enum_comparison_exp: Contributor_Type_Enum_Comparison_Exp;
  contributor_update_column: Contributor_Update_Column;
  control: ResolverTypeWrapper<Control>;
  control_aggregate: ResolverTypeWrapper<Control_Aggregate>;
  control_aggregate_fields: ResolverTypeWrapper<Control_Aggregate_Fields>;
  control_audit: ResolverTypeWrapper<Control_Audit>;
  control_audit_bool_exp: Control_Audit_Bool_Exp;
  control_audit_order_by: Control_Audit_Order_By;
  control_audit_select_column: Control_Audit_Select_Column;
  control_audit_stream_cursor_input: Control_Audit_Stream_Cursor_Input;
  control_audit_stream_cursor_value_input: Control_Audit_Stream_Cursor_Value_Input;
  control_avg_fields: ResolverTypeWrapper<Control_Avg_Fields>;
  control_bool_exp: Control_Bool_Exp;
  control_constraint: Control_Constraint;
  control_group: ResolverTypeWrapper<Control_Group>;
  control_group_aggregate: ResolverTypeWrapper<Control_Group_Aggregate>;
  control_group_aggregate_fields: ResolverTypeWrapper<Control_Group_Aggregate_Fields>;
  control_group_append_input: Control_Group_Append_Input;
  control_group_audit: ResolverTypeWrapper<Control_Group_Audit>;
  control_group_audit_bool_exp: Control_Group_Audit_Bool_Exp;
  control_group_audit_order_by: Control_Group_Audit_Order_By;
  control_group_audit_select_column: Control_Group_Audit_Select_Column;
  control_group_audit_stream_cursor_input: Control_Group_Audit_Stream_Cursor_Input;
  control_group_audit_stream_cursor_value_input: Control_Group_Audit_Stream_Cursor_Value_Input;
  control_group_bool_exp: Control_Group_Bool_Exp;
  control_group_constraint: Control_Group_Constraint;
  control_group_delete_at_path_input: Control_Group_Delete_At_Path_Input;
  control_group_delete_elem_input: Control_Group_Delete_Elem_Input;
  control_group_delete_key_input: Control_Group_Delete_Key_Input;
  control_group_insert_input: Control_Group_Insert_Input;
  control_group_max_fields: ResolverTypeWrapper<Control_Group_Max_Fields>;
  control_group_min_fields: ResolverTypeWrapper<Control_Group_Min_Fields>;
  control_group_mutation_response: ResolverTypeWrapper<Control_Group_Mutation_Response>;
  control_group_obj_rel_insert_input: Control_Group_Obj_Rel_Insert_Input;
  control_group_on_conflict: Control_Group_On_Conflict;
  control_group_order_by: Control_Group_Order_By;
  control_group_pk_columns_input: Control_Group_Pk_Columns_Input;
  control_group_prepend_input: Control_Group_Prepend_Input;
  control_group_select_column: Control_Group_Select_Column;
  control_group_set_input: Control_Group_Set_Input;
  control_group_stream_cursor_input: Control_Group_Stream_Cursor_Input;
  control_group_stream_cursor_value_input: Control_Group_Stream_Cursor_Value_Input;
  control_group_update_column: Control_Group_Update_Column;
  control_group_updates: Control_Group_Updates;
  control_insert_input: Control_Insert_Input;
  control_max_fields: ResolverTypeWrapper<Control_Max_Fields>;
  control_min_fields: ResolverTypeWrapper<Control_Min_Fields>;
  control_mutation_response: ResolverTypeWrapper<Control_Mutation_Response>;
  control_obj_rel_insert_input: Control_Obj_Rel_Insert_Input;
  control_on_conflict: Control_On_Conflict;
  control_order_by: Control_Order_By;
  control_parent: ResolverTypeWrapper<Control_Parent>;
  control_parent_aggregate: ResolverTypeWrapper<Control_Parent_Aggregate>;
  control_parent_aggregate_bool_exp: Control_Parent_Aggregate_Bool_Exp;
  control_parent_aggregate_bool_exp_count: Control_Parent_Aggregate_Bool_Exp_Count;
  control_parent_aggregate_fields: ResolverTypeWrapper<Control_Parent_Aggregate_Fields>;
  control_parent_aggregate_order_by: Control_Parent_Aggregate_Order_By;
  control_parent_arr_rel_insert_input: Control_Parent_Arr_Rel_Insert_Input;
  control_parent_audit: ResolverTypeWrapper<Control_Parent_Audit>;
  control_parent_audit_aggregate_order_by: Control_Parent_Audit_Aggregate_Order_By;
  control_parent_audit_bool_exp: Control_Parent_Audit_Bool_Exp;
  control_parent_audit_max_order_by: Control_Parent_Audit_Max_Order_By;
  control_parent_audit_min_order_by: Control_Parent_Audit_Min_Order_By;
  control_parent_audit_order_by: Control_Parent_Audit_Order_By;
  control_parent_audit_select_column: Control_Parent_Audit_Select_Column;
  control_parent_audit_stream_cursor_input: Control_Parent_Audit_Stream_Cursor_Input;
  control_parent_audit_stream_cursor_value_input: Control_Parent_Audit_Stream_Cursor_Value_Input;
  control_parent_bool_exp: Control_Parent_Bool_Exp;
  control_parent_constraint: Control_Parent_Constraint;
  control_parent_insert_input: Control_Parent_Insert_Input;
  control_parent_max_fields: ResolverTypeWrapper<Control_Parent_Max_Fields>;
  control_parent_max_order_by: Control_Parent_Max_Order_By;
  control_parent_min_fields: ResolverTypeWrapper<Control_Parent_Min_Fields>;
  control_parent_min_order_by: Control_Parent_Min_Order_By;
  control_parent_mutation_response: ResolverTypeWrapper<Control_Parent_Mutation_Response>;
  control_parent_on_conflict: Control_Parent_On_Conflict;
  control_parent_order_by: Control_Parent_Order_By;
  control_parent_select_column: Control_Parent_Select_Column;
  control_parent_stream_cursor_input: Control_Parent_Stream_Cursor_Input;
  control_parent_stream_cursor_value_input: Control_Parent_Stream_Cursor_Value_Input;
  control_parent_update_column: Control_Parent_Update_Column;
  control_select_column: Control_Select_Column;
  control_stddev_fields: ResolverTypeWrapper<Control_Stddev_Fields>;
  control_stddev_pop_fields: ResolverTypeWrapper<Control_Stddev_Pop_Fields>;
  control_stddev_samp_fields: ResolverTypeWrapper<Control_Stddev_Samp_Fields>;
  control_stream_cursor_input: Control_Stream_Cursor_Input;
  control_stream_cursor_value_input: Control_Stream_Cursor_Value_Input;
  control_sum_fields: ResolverTypeWrapper<Control_Sum_Fields>;
  control_type_enum: Control_Type_Enum;
  control_type_enum_comparison_exp: Control_Type_Enum_Comparison_Exp;
  control_update_column: Control_Update_Column;
  control_var_pop_fields: ResolverTypeWrapper<Control_Var_Pop_Fields>;
  control_var_samp_fields: ResolverTypeWrapper<Control_Var_Samp_Fields>;
  control_variance_fields: ResolverTypeWrapper<Control_Variance_Fields>;
  conversation: ResolverTypeWrapper<Conversation>;
  conversation_audit: ResolverTypeWrapper<Conversation_Audit>;
  conversation_audit_bool_exp: Conversation_Audit_Bool_Exp;
  conversation_audit_order_by: Conversation_Audit_Order_By;
  conversation_audit_select_column: Conversation_Audit_Select_Column;
  conversation_audit_stream_cursor_input: Conversation_Audit_Stream_Cursor_Input;
  conversation_audit_stream_cursor_value_input: Conversation_Audit_Stream_Cursor_Value_Input;
  conversation_bool_exp: Conversation_Bool_Exp;
  conversation_constraint: Conversation_Constraint;
  conversation_insert_input: Conversation_Insert_Input;
  conversation_mutation_response: ResolverTypeWrapper<Conversation_Mutation_Response>;
  conversation_obj_rel_insert_input: Conversation_Obj_Rel_Insert_Input;
  conversation_on_conflict: Conversation_On_Conflict;
  conversation_order_by: Conversation_Order_By;
  conversation_pk_columns_input: Conversation_Pk_Columns_Input;
  conversation_select_column: Conversation_Select_Column;
  conversation_set_input: Conversation_Set_Input;
  conversation_stream_cursor_input: Conversation_Stream_Cursor_Input;
  conversation_stream_cursor_value_input: Conversation_Stream_Cursor_Value_Input;
  conversation_update_column: Conversation_Update_Column;
  conversation_updates: Conversation_Updates;
  cost_type_enum: Cost_Type_Enum;
  cost_type_enum_comparison_exp: Cost_Type_Enum_Comparison_Exp;
  cursor_ordering: Cursor_Ordering;
  custom_attribute_schema: ResolverTypeWrapper<Custom_Attribute_Schema>;
  custom_attribute_schema_append_input: Custom_Attribute_Schema_Append_Input;
  custom_attribute_schema_audit: ResolverTypeWrapper<Custom_Attribute_Schema_Audit>;
  custom_attribute_schema_audit_bool_exp: Custom_Attribute_Schema_Audit_Bool_Exp;
  custom_attribute_schema_audit_order_by: Custom_Attribute_Schema_Audit_Order_By;
  custom_attribute_schema_audit_select_column: Custom_Attribute_Schema_Audit_Select_Column;
  custom_attribute_schema_audit_stream_cursor_input: Custom_Attribute_Schema_Audit_Stream_Cursor_Input;
  custom_attribute_schema_audit_stream_cursor_value_input: Custom_Attribute_Schema_Audit_Stream_Cursor_Value_Input;
  custom_attribute_schema_bool_exp: Custom_Attribute_Schema_Bool_Exp;
  custom_attribute_schema_constraint: Custom_Attribute_Schema_Constraint;
  custom_attribute_schema_delete_at_path_input: Custom_Attribute_Schema_Delete_At_Path_Input;
  custom_attribute_schema_delete_elem_input: Custom_Attribute_Schema_Delete_Elem_Input;
  custom_attribute_schema_delete_key_input: Custom_Attribute_Schema_Delete_Key_Input;
  custom_attribute_schema_insert_input: Custom_Attribute_Schema_Insert_Input;
  custom_attribute_schema_mutation_response: ResolverTypeWrapper<Custom_Attribute_Schema_Mutation_Response>;
  custom_attribute_schema_obj_rel_insert_input: Custom_Attribute_Schema_Obj_Rel_Insert_Input;
  custom_attribute_schema_on_conflict: Custom_Attribute_Schema_On_Conflict;
  custom_attribute_schema_order_by: Custom_Attribute_Schema_Order_By;
  custom_attribute_schema_pk_columns_input: Custom_Attribute_Schema_Pk_Columns_Input;
  custom_attribute_schema_prepend_input: Custom_Attribute_Schema_Prepend_Input;
  custom_attribute_schema_select_column: Custom_Attribute_Schema_Select_Column;
  custom_attribute_schema_set_input: Custom_Attribute_Schema_Set_Input;
  custom_attribute_schema_stream_cursor_input: Custom_Attribute_Schema_Stream_Cursor_Input;
  custom_attribute_schema_stream_cursor_value_input: Custom_Attribute_Schema_Stream_Cursor_Value_Input;
  custom_attribute_schema_update_column: Custom_Attribute_Schema_Update_Column;
  custom_attribute_schema_updates: Custom_Attribute_Schema_Updates;
  custom_ribbon: ResolverTypeWrapper<Custom_Ribbon>;
  custom_ribbon_append_input: Custom_Ribbon_Append_Input;
  custom_ribbon_audit: ResolverTypeWrapper<Custom_Ribbon_Audit>;
  custom_ribbon_audit_bool_exp: Custom_Ribbon_Audit_Bool_Exp;
  custom_ribbon_audit_order_by: Custom_Ribbon_Audit_Order_By;
  custom_ribbon_audit_select_column: Custom_Ribbon_Audit_Select_Column;
  custom_ribbon_audit_stream_cursor_input: Custom_Ribbon_Audit_Stream_Cursor_Input;
  custom_ribbon_audit_stream_cursor_value_input: Custom_Ribbon_Audit_Stream_Cursor_Value_Input;
  custom_ribbon_bool_exp: Custom_Ribbon_Bool_Exp;
  custom_ribbon_constraint: Custom_Ribbon_Constraint;
  custom_ribbon_delete_at_path_input: Custom_Ribbon_Delete_At_Path_Input;
  custom_ribbon_delete_elem_input: Custom_Ribbon_Delete_Elem_Input;
  custom_ribbon_delete_key_input: Custom_Ribbon_Delete_Key_Input;
  custom_ribbon_insert_input: Custom_Ribbon_Insert_Input;
  custom_ribbon_mutation_response: ResolverTypeWrapper<Custom_Ribbon_Mutation_Response>;
  custom_ribbon_on_conflict: Custom_Ribbon_On_Conflict;
  custom_ribbon_order_by: Custom_Ribbon_Order_By;
  custom_ribbon_pk_columns_input: Custom_Ribbon_Pk_Columns_Input;
  custom_ribbon_prepend_input: Custom_Ribbon_Prepend_Input;
  custom_ribbon_select_column: Custom_Ribbon_Select_Column;
  custom_ribbon_set_input: Custom_Ribbon_Set_Input;
  custom_ribbon_stream_cursor_input: Custom_Ribbon_Stream_Cursor_Input;
  custom_ribbon_stream_cursor_value_input: Custom_Ribbon_Stream_Cursor_Value_Input;
  custom_ribbon_update_column: Custom_Ribbon_Update_Column;
  custom_ribbon_updates: Custom_Ribbon_Updates;
  dashboard: ResolverTypeWrapper<Dashboard>;
  dashboard_audit: ResolverTypeWrapper<Dashboard_Audit>;
  dashboard_audit_bool_exp: Dashboard_Audit_Bool_Exp;
  dashboard_audit_order_by: Dashboard_Audit_Order_By;
  dashboard_audit_select_column: Dashboard_Audit_Select_Column;
  dashboard_audit_stream_cursor_input: Dashboard_Audit_Stream_Cursor_Input;
  dashboard_audit_stream_cursor_value_input: Dashboard_Audit_Stream_Cursor_Value_Input;
  dashboard_bool_exp: Dashboard_Bool_Exp;
  dashboard_constraint: Dashboard_Constraint;
  dashboard_insert_input: Dashboard_Insert_Input;
  dashboard_mutation_response: ResolverTypeWrapper<Dashboard_Mutation_Response>;
  dashboard_obj_rel_insert_input: Dashboard_Obj_Rel_Insert_Input;
  dashboard_on_conflict: Dashboard_On_Conflict;
  dashboard_order_by: Dashboard_Order_By;
  dashboard_select_column: Dashboard_Select_Column;
  dashboard_sharing_type_enum: Dashboard_Sharing_Type_Enum;
  dashboard_sharing_type_enum_action: Dashboard_Sharing_Type_Enum_Action;
  dashboard_sharing_type_enum_comparison_exp: Dashboard_Sharing_Type_Enum_Comparison_Exp;
  dashboard_stream_cursor_input: Dashboard_Stream_Cursor_Input;
  dashboard_stream_cursor_value_input: Dashboard_Stream_Cursor_Value_Input;
  dashboard_update_column: Dashboard_Update_Column;
  data_import: ResolverTypeWrapper<Data_Import>;
  data_import_aggregate: ResolverTypeWrapper<Data_Import_Aggregate>;
  data_import_aggregate_fields: ResolverTypeWrapper<Data_Import_Aggregate_Fields>;
  data_import_bool_exp: Data_Import_Bool_Exp;
  data_import_constraint: Data_Import_Constraint;
  data_import_error: ResolverTypeWrapper<Data_Import_Error>;
  data_import_error_aggregate: ResolverTypeWrapper<Data_Import_Error_Aggregate>;
  data_import_error_aggregate_fields: ResolverTypeWrapper<Data_Import_Error_Aggregate_Fields>;
  data_import_error_avg_fields: ResolverTypeWrapper<Data_Import_Error_Avg_Fields>;
  data_import_error_bool_exp: Data_Import_Error_Bool_Exp;
  data_import_error_constraint: Data_Import_Error_Constraint;
  data_import_error_insert_input: Data_Import_Error_Insert_Input;
  data_import_error_max_fields: ResolverTypeWrapper<Data_Import_Error_Max_Fields>;
  data_import_error_min_fields: ResolverTypeWrapper<Data_Import_Error_Min_Fields>;
  data_import_error_mutation_response: ResolverTypeWrapper<Data_Import_Error_Mutation_Response>;
  data_import_error_on_conflict: Data_Import_Error_On_Conflict;
  data_import_error_order_by: Data_Import_Error_Order_By;
  data_import_error_select_column: Data_Import_Error_Select_Column;
  data_import_error_stddev_fields: ResolverTypeWrapper<Data_Import_Error_Stddev_Fields>;
  data_import_error_stddev_pop_fields: ResolverTypeWrapper<Data_Import_Error_Stddev_Pop_Fields>;
  data_import_error_stddev_samp_fields: ResolverTypeWrapper<Data_Import_Error_Stddev_Samp_Fields>;
  data_import_error_stream_cursor_input: Data_Import_Error_Stream_Cursor_Input;
  data_import_error_stream_cursor_value_input: Data_Import_Error_Stream_Cursor_Value_Input;
  data_import_error_sum_fields: ResolverTypeWrapper<Data_Import_Error_Sum_Fields>;
  data_import_error_update_column: Data_Import_Error_Update_Column;
  data_import_error_var_pop_fields: ResolverTypeWrapper<Data_Import_Error_Var_Pop_Fields>;
  data_import_error_var_samp_fields: ResolverTypeWrapper<Data_Import_Error_Var_Samp_Fields>;
  data_import_error_variance_fields: ResolverTypeWrapper<Data_Import_Error_Variance_Fields>;
  data_import_insert_input: Data_Import_Insert_Input;
  data_import_max_fields: ResolverTypeWrapper<Data_Import_Max_Fields>;
  data_import_min_fields: ResolverTypeWrapper<Data_Import_Min_Fields>;
  data_import_mutation_response: ResolverTypeWrapper<Data_Import_Mutation_Response>;
  data_import_on_conflict: Data_Import_On_Conflict;
  data_import_order_by: Data_Import_Order_By;
  data_import_select_column: Data_Import_Select_Column;
  data_import_status_enum: Data_Import_Status_Enum;
  data_import_status_enum_comparison_exp: Data_Import_Status_Enum_Comparison_Exp;
  data_import_stream_cursor_input: Data_Import_Stream_Cursor_Input;
  data_import_stream_cursor_value_input: Data_Import_Stream_Cursor_Value_Input;
  data_import_update_column: Data_Import_Update_Column;
  department: ResolverTypeWrapper<Department>;
  department_aggregate_order_by: Department_Aggregate_Order_By;
  department_arr_rel_insert_input: Department_Arr_Rel_Insert_Input;
  department_audit: ResolverTypeWrapper<Department_Audit>;
  department_audit_aggregate_order_by: Department_Audit_Aggregate_Order_By;
  department_audit_bool_exp: Department_Audit_Bool_Exp;
  department_audit_max_order_by: Department_Audit_Max_Order_By;
  department_audit_min_order_by: Department_Audit_Min_Order_By;
  department_audit_order_by: Department_Audit_Order_By;
  department_audit_select_column: Department_Audit_Select_Column;
  department_audit_stream_cursor_input: Department_Audit_Stream_Cursor_Input;
  department_audit_stream_cursor_value_input: Department_Audit_Stream_Cursor_Value_Input;
  department_bool_exp: Department_Bool_Exp;
  department_constraint: Department_Constraint;
  department_insert_input: Department_Insert_Input;
  department_max_order_by: Department_Max_Order_By;
  department_min_order_by: Department_Min_Order_By;
  department_mutation_response: ResolverTypeWrapper<Department_Mutation_Response>;
  department_on_conflict: Department_On_Conflict;
  department_order_by: Department_Order_By;
  department_select_column: Department_Select_Column;
  department_type: ResolverTypeWrapper<Department_Type>;
  department_type_aggregate_order_by: Department_Type_Aggregate_Order_By;
  department_type_arr_rel_insert_input: Department_Type_Arr_Rel_Insert_Input;
  department_type_audit: ResolverTypeWrapper<Department_Type_Audit>;
  department_type_audit_bool_exp: Department_Type_Audit_Bool_Exp;
  department_type_audit_order_by: Department_Type_Audit_Order_By;
  department_type_audit_select_column: Department_Type_Audit_Select_Column;
  department_type_audit_stream_cursor_input: Department_Type_Audit_Stream_Cursor_Input;
  department_type_audit_stream_cursor_value_input: Department_Type_Audit_Stream_Cursor_Value_Input;
  department_type_bool_exp: Department_Type_Bool_Exp;
  department_type_constraint: Department_Type_Constraint;
  department_type_group: ResolverTypeWrapper<Department_Type_Group>;
  department_type_group_audit: ResolverTypeWrapper<Department_Type_Group_Audit>;
  department_type_group_audit_bool_exp: Department_Type_Group_Audit_Bool_Exp;
  department_type_group_audit_order_by: Department_Type_Group_Audit_Order_By;
  department_type_group_audit_select_column: Department_Type_Group_Audit_Select_Column;
  department_type_group_audit_stream_cursor_input: Department_Type_Group_Audit_Stream_Cursor_Input;
  department_type_group_audit_stream_cursor_value_input: Department_Type_Group_Audit_Stream_Cursor_Value_Input;
  department_type_group_bool_exp: Department_Type_Group_Bool_Exp;
  department_type_group_constraint: Department_Type_Group_Constraint;
  department_type_group_insert_input: Department_Type_Group_Insert_Input;
  department_type_group_mutation_response: ResolverTypeWrapper<Department_Type_Group_Mutation_Response>;
  department_type_group_obj_rel_insert_input: Department_Type_Group_Obj_Rel_Insert_Input;
  department_type_group_on_conflict: Department_Type_Group_On_Conflict;
  department_type_group_order_by: Department_Type_Group_Order_By;
  department_type_group_select_column: Department_Type_Group_Select_Column;
  department_type_group_set_input: Department_Type_Group_Set_Input;
  department_type_group_stream_cursor_input: Department_Type_Group_Stream_Cursor_Input;
  department_type_group_stream_cursor_value_input: Department_Type_Group_Stream_Cursor_Value_Input;
  department_type_group_update_column: Department_Type_Group_Update_Column;
  department_type_group_updates: Department_Type_Group_Updates;
  department_type_insert_input: Department_Type_Insert_Input;
  department_type_max_order_by: Department_Type_Max_Order_By;
  department_type_min_order_by: Department_Type_Min_Order_By;
  department_type_mutation_response: ResolverTypeWrapper<Department_Type_Mutation_Response>;
  department_type_obj_rel_insert_input: Department_Type_Obj_Rel_Insert_Input;
  department_type_on_conflict: Department_Type_On_Conflict;
  department_type_order_by: Department_Type_Order_By;
  department_type_pk_columns_input: Department_Type_Pk_Columns_Input;
  department_type_select_column: Department_Type_Select_Column;
  department_type_set_input: Department_Type_Set_Input;
  department_type_stream_cursor_input: Department_Type_Stream_Cursor_Input;
  department_type_stream_cursor_value_input: Department_Type_Stream_Cursor_Value_Input;
  department_type_update_column: Department_Type_Update_Column;
  department_type_updates: Department_Type_Updates;
  department_update_column: Department_Update_Column;
  document: ResolverTypeWrapper<Document>;
  document_assessment_result: ResolverTypeWrapper<Document_Assessment_Result>;
  document_assessment_result_aggregate: ResolverTypeWrapper<Document_Assessment_Result_Aggregate>;
  document_assessment_result_aggregate_fields: ResolverTypeWrapper<Document_Assessment_Result_Aggregate_Fields>;
  document_assessment_result_append_input: Document_Assessment_Result_Append_Input;
  document_assessment_result_audit: ResolverTypeWrapper<Document_Assessment_Result_Audit>;
  document_assessment_result_audit_aggregate: ResolverTypeWrapper<Document_Assessment_Result_Audit_Aggregate>;
  document_assessment_result_audit_aggregate_fields: ResolverTypeWrapper<Document_Assessment_Result_Audit_Aggregate_Fields>;
  document_assessment_result_audit_avg_fields: ResolverTypeWrapper<Document_Assessment_Result_Audit_Avg_Fields>;
  document_assessment_result_audit_bool_exp: Document_Assessment_Result_Audit_Bool_Exp;
  document_assessment_result_audit_max_fields: ResolverTypeWrapper<Document_Assessment_Result_Audit_Max_Fields>;
  document_assessment_result_audit_min_fields: ResolverTypeWrapper<Document_Assessment_Result_Audit_Min_Fields>;
  document_assessment_result_audit_order_by: Document_Assessment_Result_Audit_Order_By;
  document_assessment_result_audit_select_column: Document_Assessment_Result_Audit_Select_Column;
  document_assessment_result_audit_stddev_fields: ResolverTypeWrapper<Document_Assessment_Result_Audit_Stddev_Fields>;
  document_assessment_result_audit_stddev_pop_fields: ResolverTypeWrapper<Document_Assessment_Result_Audit_Stddev_Pop_Fields>;
  document_assessment_result_audit_stddev_samp_fields: ResolverTypeWrapper<Document_Assessment_Result_Audit_Stddev_Samp_Fields>;
  document_assessment_result_audit_stream_cursor_input: Document_Assessment_Result_Audit_Stream_Cursor_Input;
  document_assessment_result_audit_stream_cursor_value_input: Document_Assessment_Result_Audit_Stream_Cursor_Value_Input;
  document_assessment_result_audit_sum_fields: ResolverTypeWrapper<Document_Assessment_Result_Audit_Sum_Fields>;
  document_assessment_result_audit_var_pop_fields: ResolverTypeWrapper<Document_Assessment_Result_Audit_Var_Pop_Fields>;
  document_assessment_result_audit_var_samp_fields: ResolverTypeWrapper<Document_Assessment_Result_Audit_Var_Samp_Fields>;
  document_assessment_result_audit_variance_fields: ResolverTypeWrapper<Document_Assessment_Result_Audit_Variance_Fields>;
  document_assessment_result_avg_fields: ResolverTypeWrapper<Document_Assessment_Result_Avg_Fields>;
  document_assessment_result_bool_exp: Document_Assessment_Result_Bool_Exp;
  document_assessment_result_constraint: Document_Assessment_Result_Constraint;
  document_assessment_result_delete_at_path_input: Document_Assessment_Result_Delete_At_Path_Input;
  document_assessment_result_delete_elem_input: Document_Assessment_Result_Delete_Elem_Input;
  document_assessment_result_delete_key_input: Document_Assessment_Result_Delete_Key_Input;
  document_assessment_result_inc_input: Document_Assessment_Result_Inc_Input;
  document_assessment_result_insert_input: Document_Assessment_Result_Insert_Input;
  document_assessment_result_max_fields: ResolverTypeWrapper<Document_Assessment_Result_Max_Fields>;
  document_assessment_result_min_fields: ResolverTypeWrapper<Document_Assessment_Result_Min_Fields>;
  document_assessment_result_mutation_response: ResolverTypeWrapper<Document_Assessment_Result_Mutation_Response>;
  document_assessment_result_obj_rel_insert_input: Document_Assessment_Result_Obj_Rel_Insert_Input;
  document_assessment_result_on_conflict: Document_Assessment_Result_On_Conflict;
  document_assessment_result_order_by: Document_Assessment_Result_Order_By;
  document_assessment_result_pk_columns_input: Document_Assessment_Result_Pk_Columns_Input;
  document_assessment_result_prepend_input: Document_Assessment_Result_Prepend_Input;
  document_assessment_result_select_column: Document_Assessment_Result_Select_Column;
  document_assessment_result_set_input: Document_Assessment_Result_Set_Input;
  document_assessment_result_stddev_fields: ResolverTypeWrapper<Document_Assessment_Result_Stddev_Fields>;
  document_assessment_result_stddev_pop_fields: ResolverTypeWrapper<Document_Assessment_Result_Stddev_Pop_Fields>;
  document_assessment_result_stddev_samp_fields: ResolverTypeWrapper<Document_Assessment_Result_Stddev_Samp_Fields>;
  document_assessment_result_stream_cursor_input: Document_Assessment_Result_Stream_Cursor_Input;
  document_assessment_result_stream_cursor_value_input: Document_Assessment_Result_Stream_Cursor_Value_Input;
  document_assessment_result_sum_fields: ResolverTypeWrapper<Document_Assessment_Result_Sum_Fields>;
  document_assessment_result_update_column: Document_Assessment_Result_Update_Column;
  document_assessment_result_updates: Document_Assessment_Result_Updates;
  document_assessment_result_var_pop_fields: ResolverTypeWrapper<Document_Assessment_Result_Var_Pop_Fields>;
  document_assessment_result_var_samp_fields: ResolverTypeWrapper<Document_Assessment_Result_Var_Samp_Fields>;
  document_assessment_result_variance_fields: ResolverTypeWrapper<Document_Assessment_Result_Variance_Fields>;
  document_audit: ResolverTypeWrapper<Document_Audit>;
  document_audit_bool_exp: Document_Audit_Bool_Exp;
  document_audit_order_by: Document_Audit_Order_By;
  document_audit_select_column: Document_Audit_Select_Column;
  document_audit_stream_cursor_input: Document_Audit_Stream_Cursor_Input;
  document_audit_stream_cursor_value_input: Document_Audit_Stream_Cursor_Value_Input;
  document_bool_exp: Document_Bool_Exp;
  document_constraint: Document_Constraint;
  document_file: ResolverTypeWrapper<Document_File>;
  document_file_aggregate_order_by: Document_File_Aggregate_Order_By;
  document_file_arr_rel_insert_input: Document_File_Arr_Rel_Insert_Input;
  document_file_audit: ResolverTypeWrapper<Document_File_Audit>;
  document_file_audit_bool_exp: Document_File_Audit_Bool_Exp;
  document_file_audit_order_by: Document_File_Audit_Order_By;
  document_file_audit_select_column: Document_File_Audit_Select_Column;
  document_file_audit_stream_cursor_input: Document_File_Audit_Stream_Cursor_Input;
  document_file_audit_stream_cursor_value_input: Document_File_Audit_Stream_Cursor_Value_Input;
  document_file_bool_exp: Document_File_Bool_Exp;
  document_file_constraint: Document_File_Constraint;
  document_file_insert_input: Document_File_Insert_Input;
  document_file_max_order_by: Document_File_Max_Order_By;
  document_file_min_order_by: Document_File_Min_Order_By;
  document_file_mutation_response: ResolverTypeWrapper<Document_File_Mutation_Response>;
  document_file_obj_rel_insert_input: Document_File_Obj_Rel_Insert_Input;
  document_file_on_conflict: Document_File_On_Conflict;
  document_file_order_by: Document_File_Order_By;
  document_file_select_column: Document_File_Select_Column;
  document_file_stream_cursor_input: Document_File_Stream_Cursor_Input;
  document_file_stream_cursor_value_input: Document_File_Stream_Cursor_Value_Input;
  document_file_type_enum: Document_File_Type_Enum;
  document_file_type_enum_comparison_exp: Document_File_Type_Enum_Comparison_Exp;
  document_file_update_column: Document_File_Update_Column;
  document_insert_input: Document_Insert_Input;
  document_linked_document: ResolverTypeWrapper<Document_Linked_Document>;
  document_linked_document_aggregate_order_by: Document_Linked_Document_Aggregate_Order_By;
  document_linked_document_arr_rel_insert_input: Document_Linked_Document_Arr_Rel_Insert_Input;
  document_linked_document_audit: ResolverTypeWrapper<Document_Linked_Document_Audit>;
  document_linked_document_audit_bool_exp: Document_Linked_Document_Audit_Bool_Exp;
  document_linked_document_audit_order_by: Document_Linked_Document_Audit_Order_By;
  document_linked_document_audit_select_column: Document_Linked_Document_Audit_Select_Column;
  document_linked_document_audit_stream_cursor_input: Document_Linked_Document_Audit_Stream_Cursor_Input;
  document_linked_document_audit_stream_cursor_value_input: Document_Linked_Document_Audit_Stream_Cursor_Value_Input;
  document_linked_document_bool_exp: Document_Linked_Document_Bool_Exp;
  document_linked_document_constraint: Document_Linked_Document_Constraint;
  document_linked_document_insert_input: Document_Linked_Document_Insert_Input;
  document_linked_document_max_order_by: Document_Linked_Document_Max_Order_By;
  document_linked_document_min_order_by: Document_Linked_Document_Min_Order_By;
  document_linked_document_mutation_response: ResolverTypeWrapper<Document_Linked_Document_Mutation_Response>;
  document_linked_document_on_conflict: Document_Linked_Document_On_Conflict;
  document_linked_document_order_by: Document_Linked_Document_Order_By;
  document_linked_document_select_column: Document_Linked_Document_Select_Column;
  document_linked_document_stream_cursor_input: Document_Linked_Document_Stream_Cursor_Input;
  document_linked_document_stream_cursor_value_input: Document_Linked_Document_Stream_Cursor_Value_Input;
  document_linked_document_update_column: Document_Linked_Document_Update_Column;
  document_obj_rel_insert_input: Document_Obj_Rel_Insert_Input;
  document_on_conflict: Document_On_Conflict;
  document_order_by: Document_Order_By;
  document_select_column: Document_Select_Column;
  document_stream_cursor_input: Document_Stream_Cursor_Input;
  document_stream_cursor_value_input: Document_Stream_Cursor_Value_Input;
  document_update_column: Document_Update_Column;
  file: ResolverTypeWrapper<File>;
  file_aggregate: ResolverTypeWrapper<File_Aggregate>;
  file_aggregate_fields: ResolverTypeWrapper<File_Aggregate_Fields>;
  file_audit: ResolverTypeWrapper<File_Audit>;
  file_audit_bool_exp: File_Audit_Bool_Exp;
  file_audit_order_by: File_Audit_Order_By;
  file_audit_select_column: File_Audit_Select_Column;
  file_audit_stream_cursor_input: File_Audit_Stream_Cursor_Input;
  file_audit_stream_cursor_value_input: File_Audit_Stream_Cursor_Value_Input;
  file_avg_fields: ResolverTypeWrapper<File_Avg_Fields>;
  file_bool_exp: File_Bool_Exp;
  file_constraint: File_Constraint;
  file_insert_input: File_Insert_Input;
  file_max_fields: ResolverTypeWrapper<File_Max_Fields>;
  file_min_fields: ResolverTypeWrapper<File_Min_Fields>;
  file_mutation_response: ResolverTypeWrapper<File_Mutation_Response>;
  file_obj_rel_insert_input: File_Obj_Rel_Insert_Input;
  file_on_conflict: File_On_Conflict;
  file_order_by: File_Order_By;
  file_select_column: File_Select_Column;
  file_stddev_fields: ResolverTypeWrapper<File_Stddev_Fields>;
  file_stddev_pop_fields: ResolverTypeWrapper<File_Stddev_Pop_Fields>;
  file_stddev_samp_fields: ResolverTypeWrapper<File_Stddev_Samp_Fields>;
  file_stream_cursor_input: File_Stream_Cursor_Input;
  file_stream_cursor_value_input: File_Stream_Cursor_Value_Input;
  file_sum_fields: ResolverTypeWrapper<File_Sum_Fields>;
  file_update_column: File_Update_Column;
  file_var_pop_fields: ResolverTypeWrapper<File_Var_Pop_Fields>;
  file_var_samp_fields: ResolverTypeWrapper<File_Var_Samp_Fields>;
  file_variance_fields: ResolverTypeWrapper<File_Variance_Fields>;
  float8: ResolverTypeWrapper<Scalars['float8']['output']>;
  float8_comparison_exp: Float8_Comparison_Exp;
  form_configuration: ResolverTypeWrapper<Form_Configuration>;
  form_configuration_audit: ResolverTypeWrapper<Form_Configuration_Audit>;
  form_configuration_audit_bool_exp: Form_Configuration_Audit_Bool_Exp;
  form_configuration_audit_order_by: Form_Configuration_Audit_Order_By;
  form_configuration_audit_select_column: Form_Configuration_Audit_Select_Column;
  form_configuration_audit_stream_cursor_input: Form_Configuration_Audit_Stream_Cursor_Input;
  form_configuration_audit_stream_cursor_value_input: Form_Configuration_Audit_Stream_Cursor_Value_Input;
  form_configuration_bool_exp: Form_Configuration_Bool_Exp;
  form_configuration_constraint: Form_Configuration_Constraint;
  form_configuration_insert_input: Form_Configuration_Insert_Input;
  form_configuration_mutation_response: ResolverTypeWrapper<Form_Configuration_Mutation_Response>;
  form_configuration_obj_rel_insert_input: Form_Configuration_Obj_Rel_Insert_Input;
  form_configuration_on_conflict: Form_Configuration_On_Conflict;
  form_configuration_order_by: Form_Configuration_Order_By;
  form_configuration_select_column: Form_Configuration_Select_Column;
  form_configuration_set_input: Form_Configuration_Set_Input;
  form_configuration_stream_cursor_input: Form_Configuration_Stream_Cursor_Input;
  form_configuration_stream_cursor_value_input: Form_Configuration_Stream_Cursor_Value_Input;
  form_configuration_update_column: Form_Configuration_Update_Column;
  form_configuration_updates: Form_Configuration_Updates;
  form_field_configuration: ResolverTypeWrapper<Form_Field_Configuration>;
  form_field_configuration_aggregate_order_by: Form_Field_Configuration_Aggregate_Order_By;
  form_field_configuration_arr_rel_insert_input: Form_Field_Configuration_Arr_Rel_Insert_Input;
  form_field_configuration_audit: ResolverTypeWrapper<Form_Field_Configuration_Audit>;
  form_field_configuration_audit_bool_exp: Form_Field_Configuration_Audit_Bool_Exp;
  form_field_configuration_audit_order_by: Form_Field_Configuration_Audit_Order_By;
  form_field_configuration_audit_select_column: Form_Field_Configuration_Audit_Select_Column;
  form_field_configuration_audit_stream_cursor_input: Form_Field_Configuration_Audit_Stream_Cursor_Input;
  form_field_configuration_audit_stream_cursor_value_input: Form_Field_Configuration_Audit_Stream_Cursor_Value_Input;
  form_field_configuration_bool_exp: Form_Field_Configuration_Bool_Exp;
  form_field_configuration_constraint: Form_Field_Configuration_Constraint;
  form_field_configuration_insert_input: Form_Field_Configuration_Insert_Input;
  form_field_configuration_max_order_by: Form_Field_Configuration_Max_Order_By;
  form_field_configuration_min_order_by: Form_Field_Configuration_Min_Order_By;
  form_field_configuration_mutation_response: ResolverTypeWrapper<Form_Field_Configuration_Mutation_Response>;
  form_field_configuration_on_conflict: Form_Field_Configuration_On_Conflict;
  form_field_configuration_order_by: Form_Field_Configuration_Order_By;
  form_field_configuration_select_column: Form_Field_Configuration_Select_Column;
  form_field_configuration_set_input: Form_Field_Configuration_Set_Input;
  form_field_configuration_stream_cursor_input: Form_Field_Configuration_Stream_Cursor_Input;
  form_field_configuration_stream_cursor_value_input: Form_Field_Configuration_Stream_Cursor_Value_Input;
  form_field_configuration_update_column: Form_Field_Configuration_Update_Column;
  form_field_configuration_updates: Form_Field_Configuration_Updates;
  form_field_ordering: ResolverTypeWrapper<Form_Field_Ordering>;
  form_field_ordering_aggregate_order_by: Form_Field_Ordering_Aggregate_Order_By;
  form_field_ordering_arr_rel_insert_input: Form_Field_Ordering_Arr_Rel_Insert_Input;
  form_field_ordering_audit: ResolverTypeWrapper<Form_Field_Ordering_Audit>;
  form_field_ordering_audit_bool_exp: Form_Field_Ordering_Audit_Bool_Exp;
  form_field_ordering_audit_order_by: Form_Field_Ordering_Audit_Order_By;
  form_field_ordering_audit_select_column: Form_Field_Ordering_Audit_Select_Column;
  form_field_ordering_audit_stream_cursor_input: Form_Field_Ordering_Audit_Stream_Cursor_Input;
  form_field_ordering_audit_stream_cursor_value_input: Form_Field_Ordering_Audit_Stream_Cursor_Value_Input;
  form_field_ordering_avg_order_by: Form_Field_Ordering_Avg_Order_By;
  form_field_ordering_bool_exp: Form_Field_Ordering_Bool_Exp;
  form_field_ordering_constraint: Form_Field_Ordering_Constraint;
  form_field_ordering_inc_input: Form_Field_Ordering_Inc_Input;
  form_field_ordering_insert_input: Form_Field_Ordering_Insert_Input;
  form_field_ordering_max_order_by: Form_Field_Ordering_Max_Order_By;
  form_field_ordering_min_order_by: Form_Field_Ordering_Min_Order_By;
  form_field_ordering_mutation_response: ResolverTypeWrapper<Form_Field_Ordering_Mutation_Response>;
  form_field_ordering_on_conflict: Form_Field_Ordering_On_Conflict;
  form_field_ordering_order_by: Form_Field_Ordering_Order_By;
  form_field_ordering_select_column: Form_Field_Ordering_Select_Column;
  form_field_ordering_set_input: Form_Field_Ordering_Set_Input;
  form_field_ordering_stddev_order_by: Form_Field_Ordering_Stddev_Order_By;
  form_field_ordering_stddev_pop_order_by: Form_Field_Ordering_Stddev_Pop_Order_By;
  form_field_ordering_stddev_samp_order_by: Form_Field_Ordering_Stddev_Samp_Order_By;
  form_field_ordering_stream_cursor_input: Form_Field_Ordering_Stream_Cursor_Input;
  form_field_ordering_stream_cursor_value_input: Form_Field_Ordering_Stream_Cursor_Value_Input;
  form_field_ordering_sum_order_by: Form_Field_Ordering_Sum_Order_By;
  form_field_ordering_update_column: Form_Field_Ordering_Update_Column;
  form_field_ordering_updates: Form_Field_Ordering_Updates;
  form_field_ordering_var_pop_order_by: Form_Field_Ordering_Var_Pop_Order_By;
  form_field_ordering_var_samp_order_by: Form_Field_Ordering_Var_Samp_Order_By;
  form_field_ordering_variance_order_by: Form_Field_Ordering_Variance_Order_By;
  impact: ResolverTypeWrapper<Impact>;
  impact_aggregate: ResolverTypeWrapper<Impact_Aggregate>;
  impact_aggregate_fields: ResolverTypeWrapper<Impact_Aggregate_Fields>;
  impact_append_input: Impact_Append_Input;
  impact_audit: ResolverTypeWrapper<Impact_Audit>;
  impact_audit_bool_exp: Impact_Audit_Bool_Exp;
  impact_audit_order_by: Impact_Audit_Order_By;
  impact_audit_select_column: Impact_Audit_Select_Column;
  impact_audit_stream_cursor_input: Impact_Audit_Stream_Cursor_Input;
  impact_audit_stream_cursor_value_input: Impact_Audit_Stream_Cursor_Value_Input;
  impact_avg_fields: ResolverTypeWrapper<Impact_Avg_Fields>;
  impact_bool_exp: Impact_Bool_Exp;
  impact_constraint: Impact_Constraint;
  impact_delete_at_path_input: Impact_Delete_At_Path_Input;
  impact_delete_elem_input: Impact_Delete_Elem_Input;
  impact_delete_key_input: Impact_Delete_Key_Input;
  impact_inc_input: Impact_Inc_Input;
  impact_insert_input: Impact_Insert_Input;
  impact_max_fields: ResolverTypeWrapper<Impact_Max_Fields>;
  impact_min_fields: ResolverTypeWrapper<Impact_Min_Fields>;
  impact_mutation_response: ResolverTypeWrapper<Impact_Mutation_Response>;
  impact_obj_rel_insert_input: Impact_Obj_Rel_Insert_Input;
  impact_on_conflict: Impact_On_Conflict;
  impact_order_by: Impact_Order_By;
  impact_parent: ResolverTypeWrapper<Impact_Parent>;
  impact_parent_aggregate: ResolverTypeWrapper<Impact_Parent_Aggregate>;
  impact_parent_aggregate_bool_exp: Impact_Parent_Aggregate_Bool_Exp;
  impact_parent_aggregate_bool_exp_count: Impact_Parent_Aggregate_Bool_Exp_Count;
  impact_parent_aggregate_fields: ResolverTypeWrapper<Impact_Parent_Aggregate_Fields>;
  impact_parent_aggregate_order_by: Impact_Parent_Aggregate_Order_By;
  impact_parent_arr_rel_insert_input: Impact_Parent_Arr_Rel_Insert_Input;
  impact_parent_audit: ResolverTypeWrapper<Impact_Parent_Audit>;
  impact_parent_audit_bool_exp: Impact_Parent_Audit_Bool_Exp;
  impact_parent_audit_order_by: Impact_Parent_Audit_Order_By;
  impact_parent_audit_select_column: Impact_Parent_Audit_Select_Column;
  impact_parent_audit_stream_cursor_input: Impact_Parent_Audit_Stream_Cursor_Input;
  impact_parent_audit_stream_cursor_value_input: Impact_Parent_Audit_Stream_Cursor_Value_Input;
  impact_parent_bool_exp: Impact_Parent_Bool_Exp;
  impact_parent_constraint: Impact_Parent_Constraint;
  impact_parent_insert_input: Impact_Parent_Insert_Input;
  impact_parent_max_fields: ResolverTypeWrapper<Impact_Parent_Max_Fields>;
  impact_parent_max_order_by: Impact_Parent_Max_Order_By;
  impact_parent_min_fields: ResolverTypeWrapper<Impact_Parent_Min_Fields>;
  impact_parent_min_order_by: Impact_Parent_Min_Order_By;
  impact_parent_on_conflict: Impact_Parent_On_Conflict;
  impact_parent_order_by: Impact_Parent_Order_By;
  impact_parent_select_column: Impact_Parent_Select_Column;
  impact_parent_stream_cursor_input: Impact_Parent_Stream_Cursor_Input;
  impact_parent_stream_cursor_value_input: Impact_Parent_Stream_Cursor_Value_Input;
  impact_parent_update_column: Impact_Parent_Update_Column;
  impact_pk_columns_input: Impact_Pk_Columns_Input;
  impact_prepend_input: Impact_Prepend_Input;
  impact_rating: ResolverTypeWrapper<Impact_Rating>;
  impact_rating_aggregate: ResolverTypeWrapper<Impact_Rating_Aggregate>;
  impact_rating_aggregate_bool_exp: Impact_Rating_Aggregate_Bool_Exp;
  impact_rating_aggregate_bool_exp_count: Impact_Rating_Aggregate_Bool_Exp_Count;
  impact_rating_aggregate_fields: ResolverTypeWrapper<Impact_Rating_Aggregate_Fields>;
  impact_rating_aggregate_order_by: Impact_Rating_Aggregate_Order_By;
  impact_rating_arr_rel_insert_input: Impact_Rating_Arr_Rel_Insert_Input;
  impact_rating_audit: ResolverTypeWrapper<Impact_Rating_Audit>;
  impact_rating_audit_aggregate_order_by: Impact_Rating_Audit_Aggregate_Order_By;
  impact_rating_audit_avg_order_by: Impact_Rating_Audit_Avg_Order_By;
  impact_rating_audit_bool_exp: Impact_Rating_Audit_Bool_Exp;
  impact_rating_audit_max_order_by: Impact_Rating_Audit_Max_Order_By;
  impact_rating_audit_min_order_by: Impact_Rating_Audit_Min_Order_By;
  impact_rating_audit_order_by: Impact_Rating_Audit_Order_By;
  impact_rating_audit_select_column: Impact_Rating_Audit_Select_Column;
  impact_rating_audit_stddev_order_by: Impact_Rating_Audit_Stddev_Order_By;
  impact_rating_audit_stddev_pop_order_by: Impact_Rating_Audit_Stddev_Pop_Order_By;
  impact_rating_audit_stddev_samp_order_by: Impact_Rating_Audit_Stddev_Samp_Order_By;
  impact_rating_audit_stream_cursor_input: Impact_Rating_Audit_Stream_Cursor_Input;
  impact_rating_audit_stream_cursor_value_input: Impact_Rating_Audit_Stream_Cursor_Value_Input;
  impact_rating_audit_sum_order_by: Impact_Rating_Audit_Sum_Order_By;
  impact_rating_audit_var_pop_order_by: Impact_Rating_Audit_Var_Pop_Order_By;
  impact_rating_audit_var_samp_order_by: Impact_Rating_Audit_Var_Samp_Order_By;
  impact_rating_audit_variance_order_by: Impact_Rating_Audit_Variance_Order_By;
  impact_rating_avg_fields: ResolverTypeWrapper<Impact_Rating_Avg_Fields>;
  impact_rating_avg_order_by: Impact_Rating_Avg_Order_By;
  impact_rating_bool_exp: Impact_Rating_Bool_Exp;
  impact_rating_constraint: Impact_Rating_Constraint;
  impact_rating_insert_input: Impact_Rating_Insert_Input;
  impact_rating_max_fields: ResolverTypeWrapper<Impact_Rating_Max_Fields>;
  impact_rating_max_order_by: Impact_Rating_Max_Order_By;
  impact_rating_min_fields: ResolverTypeWrapper<Impact_Rating_Min_Fields>;
  impact_rating_min_order_by: Impact_Rating_Min_Order_By;
  impact_rating_mutation_response: ResolverTypeWrapper<Impact_Rating_Mutation_Response>;
  impact_rating_obj_rel_insert_input: Impact_Rating_Obj_Rel_Insert_Input;
  impact_rating_on_conflict: Impact_Rating_On_Conflict;
  impact_rating_order_by: Impact_Rating_Order_By;
  impact_rating_select_column: Impact_Rating_Select_Column;
  impact_rating_stddev_fields: ResolverTypeWrapper<Impact_Rating_Stddev_Fields>;
  impact_rating_stddev_order_by: Impact_Rating_Stddev_Order_By;
  impact_rating_stddev_pop_fields: ResolverTypeWrapper<Impact_Rating_Stddev_Pop_Fields>;
  impact_rating_stddev_pop_order_by: Impact_Rating_Stddev_Pop_Order_By;
  impact_rating_stddev_samp_fields: ResolverTypeWrapper<Impact_Rating_Stddev_Samp_Fields>;
  impact_rating_stddev_samp_order_by: Impact_Rating_Stddev_Samp_Order_By;
  impact_rating_stream_cursor_input: Impact_Rating_Stream_Cursor_Input;
  impact_rating_stream_cursor_value_input: Impact_Rating_Stream_Cursor_Value_Input;
  impact_rating_sum_fields: ResolverTypeWrapper<Impact_Rating_Sum_Fields>;
  impact_rating_sum_order_by: Impact_Rating_Sum_Order_By;
  impact_rating_update_column: Impact_Rating_Update_Column;
  impact_rating_var_pop_fields: ResolverTypeWrapper<Impact_Rating_Var_Pop_Fields>;
  impact_rating_var_pop_order_by: Impact_Rating_Var_Pop_Order_By;
  impact_rating_var_samp_fields: ResolverTypeWrapper<Impact_Rating_Var_Samp_Fields>;
  impact_rating_var_samp_order_by: Impact_Rating_Var_Samp_Order_By;
  impact_rating_variance_fields: ResolverTypeWrapper<Impact_Rating_Variance_Fields>;
  impact_rating_variance_order_by: Impact_Rating_Variance_Order_By;
  impact_select_column: Impact_Select_Column;
  impact_set_input: Impact_Set_Input;
  impact_stddev_fields: ResolverTypeWrapper<Impact_Stddev_Fields>;
  impact_stddev_pop_fields: ResolverTypeWrapper<Impact_Stddev_Pop_Fields>;
  impact_stddev_samp_fields: ResolverTypeWrapper<Impact_Stddev_Samp_Fields>;
  impact_stream_cursor_input: Impact_Stream_Cursor_Input;
  impact_stream_cursor_value_input: Impact_Stream_Cursor_Value_Input;
  impact_sum_fields: ResolverTypeWrapper<Impact_Sum_Fields>;
  impact_update_column: Impact_Update_Column;
  impact_updates: Impact_Updates;
  impact_var_pop_fields: ResolverTypeWrapper<Impact_Var_Pop_Fields>;
  impact_var_samp_fields: ResolverTypeWrapper<Impact_Var_Samp_Fields>;
  impact_variance_fields: ResolverTypeWrapper<Impact_Variance_Fields>;
  indicator: ResolverTypeWrapper<Indicator>;
  indicator_aggregate_order_by: Indicator_Aggregate_Order_By;
  indicator_audit: ResolverTypeWrapper<Indicator_Audit>;
  indicator_audit_bool_exp: Indicator_Audit_Bool_Exp;
  indicator_audit_order_by: Indicator_Audit_Order_By;
  indicator_audit_select_column: Indicator_Audit_Select_Column;
  indicator_audit_stream_cursor_input: Indicator_Audit_Stream_Cursor_Input;
  indicator_audit_stream_cursor_value_input: Indicator_Audit_Stream_Cursor_Value_Input;
  indicator_avg_order_by: Indicator_Avg_Order_By;
  indicator_bool_exp: Indicator_Bool_Exp;
  indicator_constraint: Indicator_Constraint;
  indicator_insert_input: Indicator_Insert_Input;
  indicator_max_order_by: Indicator_Max_Order_By;
  indicator_min_order_by: Indicator_Min_Order_By;
  indicator_mutation_response: ResolverTypeWrapper<Indicator_Mutation_Response>;
  indicator_obj_rel_insert_input: Indicator_Obj_Rel_Insert_Input;
  indicator_on_conflict: Indicator_On_Conflict;
  indicator_order_by: Indicator_Order_By;
  indicator_parent: ResolverTypeWrapper<Indicator_Parent>;
  indicator_parent_aggregate: ResolverTypeWrapper<Indicator_Parent_Aggregate>;
  indicator_parent_aggregate_bool_exp: Indicator_Parent_Aggregate_Bool_Exp;
  indicator_parent_aggregate_bool_exp_count: Indicator_Parent_Aggregate_Bool_Exp_Count;
  indicator_parent_aggregate_fields: ResolverTypeWrapper<Indicator_Parent_Aggregate_Fields>;
  indicator_parent_aggregate_order_by: Indicator_Parent_Aggregate_Order_By;
  indicator_parent_arr_rel_insert_input: Indicator_Parent_Arr_Rel_Insert_Input;
  indicator_parent_bool_exp: Indicator_Parent_Bool_Exp;
  indicator_parent_constraint: Indicator_Parent_Constraint;
  indicator_parent_insert_input: Indicator_Parent_Insert_Input;
  indicator_parent_max_fields: ResolverTypeWrapper<Indicator_Parent_Max_Fields>;
  indicator_parent_max_order_by: Indicator_Parent_Max_Order_By;
  indicator_parent_min_fields: ResolverTypeWrapper<Indicator_Parent_Min_Fields>;
  indicator_parent_min_order_by: Indicator_Parent_Min_Order_By;
  indicator_parent_mutation_response: ResolverTypeWrapper<Indicator_Parent_Mutation_Response>;
  indicator_parent_on_conflict: Indicator_Parent_On_Conflict;
  indicator_parent_order_by: Indicator_Parent_Order_By;
  indicator_parent_select_column: Indicator_Parent_Select_Column;
  indicator_parent_stream_cursor_input: Indicator_Parent_Stream_Cursor_Input;
  indicator_parent_stream_cursor_value_input: Indicator_Parent_Stream_Cursor_Value_Input;
  indicator_parent_update_column: Indicator_Parent_Update_Column;
  indicator_result: ResolverTypeWrapper<Indicator_Result>;
  indicator_result_aggregate_order_by: Indicator_Result_Aggregate_Order_By;
  indicator_result_append_input: Indicator_Result_Append_Input;
  indicator_result_arr_rel_insert_input: Indicator_Result_Arr_Rel_Insert_Input;
  indicator_result_audit: ResolverTypeWrapper<Indicator_Result_Audit>;
  indicator_result_audit_bool_exp: Indicator_Result_Audit_Bool_Exp;
  indicator_result_audit_order_by: Indicator_Result_Audit_Order_By;
  indicator_result_audit_select_column: Indicator_Result_Audit_Select_Column;
  indicator_result_audit_stream_cursor_input: Indicator_Result_Audit_Stream_Cursor_Input;
  indicator_result_audit_stream_cursor_value_input: Indicator_Result_Audit_Stream_Cursor_Value_Input;
  indicator_result_avg_order_by: Indicator_Result_Avg_Order_By;
  indicator_result_bool_exp: Indicator_Result_Bool_Exp;
  indicator_result_constraint: Indicator_Result_Constraint;
  indicator_result_delete_at_path_input: Indicator_Result_Delete_At_Path_Input;
  indicator_result_delete_elem_input: Indicator_Result_Delete_Elem_Input;
  indicator_result_delete_key_input: Indicator_Result_Delete_Key_Input;
  indicator_result_inc_input: Indicator_Result_Inc_Input;
  indicator_result_insert_input: Indicator_Result_Insert_Input;
  indicator_result_max_order_by: Indicator_Result_Max_Order_By;
  indicator_result_min_order_by: Indicator_Result_Min_Order_By;
  indicator_result_mutation_response: ResolverTypeWrapper<Indicator_Result_Mutation_Response>;
  indicator_result_on_conflict: Indicator_Result_On_Conflict;
  indicator_result_order_by: Indicator_Result_Order_By;
  indicator_result_pk_columns_input: Indicator_Result_Pk_Columns_Input;
  indicator_result_prepend_input: Indicator_Result_Prepend_Input;
  indicator_result_select_column: Indicator_Result_Select_Column;
  indicator_result_set_input: Indicator_Result_Set_Input;
  indicator_result_stddev_order_by: Indicator_Result_Stddev_Order_By;
  indicator_result_stddev_pop_order_by: Indicator_Result_Stddev_Pop_Order_By;
  indicator_result_stddev_samp_order_by: Indicator_Result_Stddev_Samp_Order_By;
  indicator_result_stream_cursor_input: Indicator_Result_Stream_Cursor_Input;
  indicator_result_stream_cursor_value_input: Indicator_Result_Stream_Cursor_Value_Input;
  indicator_result_sum_order_by: Indicator_Result_Sum_Order_By;
  indicator_result_update_column: Indicator_Result_Update_Column;
  indicator_result_updates: Indicator_Result_Updates;
  indicator_result_var_pop_order_by: Indicator_Result_Var_Pop_Order_By;
  indicator_result_var_samp_order_by: Indicator_Result_Var_Samp_Order_By;
  indicator_result_variance_order_by: Indicator_Result_Variance_Order_By;
  indicator_select_column: Indicator_Select_Column;
  indicator_stddev_order_by: Indicator_Stddev_Order_By;
  indicator_stddev_pop_order_by: Indicator_Stddev_Pop_Order_By;
  indicator_stddev_samp_order_by: Indicator_Stddev_Samp_Order_By;
  indicator_stream_cursor_input: Indicator_Stream_Cursor_Input;
  indicator_stream_cursor_value_input: Indicator_Stream_Cursor_Value_Input;
  indicator_sum_order_by: Indicator_Sum_Order_By;
  indicator_type: ResolverTypeWrapper<Indicator_Type>;
  indicator_type_bool_exp: Indicator_Type_Bool_Exp;
  indicator_type_enum: Indicator_Type_Enum;
  indicator_type_enum_comparison_exp: Indicator_Type_Enum_Comparison_Exp;
  indicator_type_order_by: Indicator_Type_Order_By;
  indicator_type_select_column: Indicator_Type_Select_Column;
  indicator_type_stream_cursor_input: Indicator_Type_Stream_Cursor_Input;
  indicator_type_stream_cursor_value_input: Indicator_Type_Stream_Cursor_Value_Input;
  indicator_update_column: Indicator_Update_Column;
  indicator_var_pop_order_by: Indicator_Var_Pop_Order_By;
  indicator_var_samp_order_by: Indicator_Var_Samp_Order_By;
  indicator_variance_order_by: Indicator_Variance_Order_By;
  insertChildImpactRatingOutput: ResolverTypeWrapper<InsertChildImpactRatingOutput>;
  insertControlTestResultOutput: ResolverTypeWrapper<InsertControlTestResultOutput>;
  internal_audit_entity: ResolverTypeWrapper<Internal_Audit_Entity>;
  internal_audit_entity_aggregate: ResolverTypeWrapper<Internal_Audit_Entity_Aggregate>;
  internal_audit_entity_aggregate_bool_exp: Internal_Audit_Entity_Aggregate_Bool_Exp;
  internal_audit_entity_aggregate_bool_exp_count: Internal_Audit_Entity_Aggregate_Bool_Exp_Count;
  internal_audit_entity_aggregate_fields: ResolverTypeWrapper<Internal_Audit_Entity_Aggregate_Fields>;
  internal_audit_entity_aggregate_order_by: Internal_Audit_Entity_Aggregate_Order_By;
  internal_audit_entity_arr_rel_insert_input: Internal_Audit_Entity_Arr_Rel_Insert_Input;
  internal_audit_entity_avg_fields: ResolverTypeWrapper<Internal_Audit_Entity_Avg_Fields>;
  internal_audit_entity_avg_order_by: Internal_Audit_Entity_Avg_Order_By;
  internal_audit_entity_bool_exp: Internal_Audit_Entity_Bool_Exp;
  internal_audit_entity_constraint: Internal_Audit_Entity_Constraint;
  internal_audit_entity_insert_input: Internal_Audit_Entity_Insert_Input;
  internal_audit_entity_max_fields: ResolverTypeWrapper<Internal_Audit_Entity_Max_Fields>;
  internal_audit_entity_max_order_by: Internal_Audit_Entity_Max_Order_By;
  internal_audit_entity_min_fields: ResolverTypeWrapper<Internal_Audit_Entity_Min_Fields>;
  internal_audit_entity_min_order_by: Internal_Audit_Entity_Min_Order_By;
  internal_audit_entity_mutation_response: ResolverTypeWrapper<Internal_Audit_Entity_Mutation_Response>;
  internal_audit_entity_obj_rel_insert_input: Internal_Audit_Entity_Obj_Rel_Insert_Input;
  internal_audit_entity_on_conflict: Internal_Audit_Entity_On_Conflict;
  internal_audit_entity_order_by: Internal_Audit_Entity_Order_By;
  internal_audit_entity_select_column: Internal_Audit_Entity_Select_Column;
  internal_audit_entity_stddev_fields: ResolverTypeWrapper<Internal_Audit_Entity_Stddev_Fields>;
  internal_audit_entity_stddev_order_by: Internal_Audit_Entity_Stddev_Order_By;
  internal_audit_entity_stddev_pop_fields: ResolverTypeWrapper<Internal_Audit_Entity_Stddev_Pop_Fields>;
  internal_audit_entity_stddev_pop_order_by: Internal_Audit_Entity_Stddev_Pop_Order_By;
  internal_audit_entity_stddev_samp_fields: ResolverTypeWrapper<Internal_Audit_Entity_Stddev_Samp_Fields>;
  internal_audit_entity_stddev_samp_order_by: Internal_Audit_Entity_Stddev_Samp_Order_By;
  internal_audit_entity_stream_cursor_input: Internal_Audit_Entity_Stream_Cursor_Input;
  internal_audit_entity_stream_cursor_value_input: Internal_Audit_Entity_Stream_Cursor_Value_Input;
  internal_audit_entity_sum_fields: ResolverTypeWrapper<Internal_Audit_Entity_Sum_Fields>;
  internal_audit_entity_sum_order_by: Internal_Audit_Entity_Sum_Order_By;
  internal_audit_entity_update_column: Internal_Audit_Entity_Update_Column;
  internal_audit_entity_var_pop_fields: ResolverTypeWrapper<Internal_Audit_Entity_Var_Pop_Fields>;
  internal_audit_entity_var_pop_order_by: Internal_Audit_Entity_Var_Pop_Order_By;
  internal_audit_entity_var_samp_fields: ResolverTypeWrapper<Internal_Audit_Entity_Var_Samp_Fields>;
  internal_audit_entity_var_samp_order_by: Internal_Audit_Entity_Var_Samp_Order_By;
  internal_audit_entity_variance_fields: ResolverTypeWrapper<Internal_Audit_Entity_Variance_Fields>;
  internal_audit_entity_variance_order_by: Internal_Audit_Entity_Variance_Order_By;
  internal_audit_report: ResolverTypeWrapper<Internal_Audit_Report>;
  internal_audit_report_aggregate: ResolverTypeWrapper<Internal_Audit_Report_Aggregate>;
  internal_audit_report_aggregate_bool_exp: Internal_Audit_Report_Aggregate_Bool_Exp;
  internal_audit_report_aggregate_bool_exp_count: Internal_Audit_Report_Aggregate_Bool_Exp_Count;
  internal_audit_report_aggregate_fields: ResolverTypeWrapper<Internal_Audit_Report_Aggregate_Fields>;
  internal_audit_report_aggregate_order_by: Internal_Audit_Report_Aggregate_Order_By;
  internal_audit_report_append_input: Internal_Audit_Report_Append_Input;
  internal_audit_report_arr_rel_insert_input: Internal_Audit_Report_Arr_Rel_Insert_Input;
  internal_audit_report_avg_fields: ResolverTypeWrapper<Internal_Audit_Report_Avg_Fields>;
  internal_audit_report_avg_order_by: Internal_Audit_Report_Avg_Order_By;
  internal_audit_report_bool_exp: Internal_Audit_Report_Bool_Exp;
  internal_audit_report_constraint: Internal_Audit_Report_Constraint;
  internal_audit_report_delete_at_path_input: Internal_Audit_Report_Delete_At_Path_Input;
  internal_audit_report_delete_elem_input: Internal_Audit_Report_Delete_Elem_Input;
  internal_audit_report_delete_key_input: Internal_Audit_Report_Delete_Key_Input;
  internal_audit_report_inc_input: Internal_Audit_Report_Inc_Input;
  internal_audit_report_insert_input: Internal_Audit_Report_Insert_Input;
  internal_audit_report_max_fields: ResolverTypeWrapper<Internal_Audit_Report_Max_Fields>;
  internal_audit_report_max_order_by: Internal_Audit_Report_Max_Order_By;
  internal_audit_report_min_fields: ResolverTypeWrapper<Internal_Audit_Report_Min_Fields>;
  internal_audit_report_min_order_by: Internal_Audit_Report_Min_Order_By;
  internal_audit_report_mutation_response: ResolverTypeWrapper<Internal_Audit_Report_Mutation_Response>;
  internal_audit_report_obj_rel_insert_input: Internal_Audit_Report_Obj_Rel_Insert_Input;
  internal_audit_report_on_conflict: Internal_Audit_Report_On_Conflict;
  internal_audit_report_order_by: Internal_Audit_Report_Order_By;
  internal_audit_report_pk_columns_input: Internal_Audit_Report_Pk_Columns_Input;
  internal_audit_report_prepend_input: Internal_Audit_Report_Prepend_Input;
  internal_audit_report_select_column: Internal_Audit_Report_Select_Column;
  internal_audit_report_set_input: Internal_Audit_Report_Set_Input;
  internal_audit_report_stddev_fields: ResolverTypeWrapper<Internal_Audit_Report_Stddev_Fields>;
  internal_audit_report_stddev_order_by: Internal_Audit_Report_Stddev_Order_By;
  internal_audit_report_stddev_pop_fields: ResolverTypeWrapper<Internal_Audit_Report_Stddev_Pop_Fields>;
  internal_audit_report_stddev_pop_order_by: Internal_Audit_Report_Stddev_Pop_Order_By;
  internal_audit_report_stddev_samp_fields: ResolverTypeWrapper<Internal_Audit_Report_Stddev_Samp_Fields>;
  internal_audit_report_stddev_samp_order_by: Internal_Audit_Report_Stddev_Samp_Order_By;
  internal_audit_report_stream_cursor_input: Internal_Audit_Report_Stream_Cursor_Input;
  internal_audit_report_stream_cursor_value_input: Internal_Audit_Report_Stream_Cursor_Value_Input;
  internal_audit_report_sum_fields: ResolverTypeWrapper<Internal_Audit_Report_Sum_Fields>;
  internal_audit_report_sum_order_by: Internal_Audit_Report_Sum_Order_By;
  internal_audit_report_update_column: Internal_Audit_Report_Update_Column;
  internal_audit_report_updates: Internal_Audit_Report_Updates;
  internal_audit_report_var_pop_fields: ResolverTypeWrapper<Internal_Audit_Report_Var_Pop_Fields>;
  internal_audit_report_var_pop_order_by: Internal_Audit_Report_Var_Pop_Order_By;
  internal_audit_report_var_samp_fields: ResolverTypeWrapper<Internal_Audit_Report_Var_Samp_Fields>;
  internal_audit_report_var_samp_order_by: Internal_Audit_Report_Var_Samp_Order_By;
  internal_audit_report_variance_fields: ResolverTypeWrapper<Internal_Audit_Report_Variance_Fields>;
  internal_audit_report_variance_order_by: Internal_Audit_Report_Variance_Order_By;
  interval: ResolverTypeWrapper<Scalars['interval']['output']>;
  interval_comparison_exp: Interval_Comparison_Exp;
  issue: ResolverTypeWrapper<Issue>;
  issue_aggregate: ResolverTypeWrapper<Issue_Aggregate>;
  issue_aggregate_fields: ResolverTypeWrapper<Issue_Aggregate_Fields>;
  issue_append_input: Issue_Append_Input;
  issue_assessment: ResolverTypeWrapper<Issue_Assessment>;
  issue_assessment_aggregate: ResolverTypeWrapper<Issue_Assessment_Aggregate>;
  issue_assessment_aggregate_fields: ResolverTypeWrapper<Issue_Assessment_Aggregate_Fields>;
  issue_assessment_audit: ResolverTypeWrapper<Issue_Assessment_Audit>;
  issue_assessment_audit_bool_exp: Issue_Assessment_Audit_Bool_Exp;
  issue_assessment_audit_order_by: Issue_Assessment_Audit_Order_By;
  issue_assessment_audit_select_column: Issue_Assessment_Audit_Select_Column;
  issue_assessment_audit_stream_cursor_input: Issue_Assessment_Audit_Stream_Cursor_Input;
  issue_assessment_audit_stream_cursor_value_input: Issue_Assessment_Audit_Stream_Cursor_Value_Input;
  issue_assessment_avg_fields: ResolverTypeWrapper<Issue_Assessment_Avg_Fields>;
  issue_assessment_bool_exp: Issue_Assessment_Bool_Exp;
  issue_assessment_constraint: Issue_Assessment_Constraint;
  issue_assessment_insert_input: Issue_Assessment_Insert_Input;
  issue_assessment_max_fields: ResolverTypeWrapper<Issue_Assessment_Max_Fields>;
  issue_assessment_min_fields: ResolverTypeWrapper<Issue_Assessment_Min_Fields>;
  issue_assessment_obj_rel_insert_input: Issue_Assessment_Obj_Rel_Insert_Input;
  issue_assessment_on_conflict: Issue_Assessment_On_Conflict;
  issue_assessment_order_by: Issue_Assessment_Order_By;
  issue_assessment_select_column: Issue_Assessment_Select_Column;
  issue_assessment_status_enum: Issue_Assessment_Status_Enum;
  issue_assessment_status_enum_comparison_exp: Issue_Assessment_Status_Enum_Comparison_Exp;
  issue_assessment_stddev_fields: ResolverTypeWrapper<Issue_Assessment_Stddev_Fields>;
  issue_assessment_stddev_pop_fields: ResolverTypeWrapper<Issue_Assessment_Stddev_Pop_Fields>;
  issue_assessment_stddev_samp_fields: ResolverTypeWrapper<Issue_Assessment_Stddev_Samp_Fields>;
  issue_assessment_stream_cursor_input: Issue_Assessment_Stream_Cursor_Input;
  issue_assessment_stream_cursor_value_input: Issue_Assessment_Stream_Cursor_Value_Input;
  issue_assessment_sum_fields: ResolverTypeWrapper<Issue_Assessment_Sum_Fields>;
  issue_assessment_update_column: Issue_Assessment_Update_Column;
  issue_assessment_var_pop_fields: ResolverTypeWrapper<Issue_Assessment_Var_Pop_Fields>;
  issue_assessment_var_samp_fields: ResolverTypeWrapper<Issue_Assessment_Var_Samp_Fields>;
  issue_assessment_variance_fields: ResolverTypeWrapper<Issue_Assessment_Variance_Fields>;
  issue_audit: ResolverTypeWrapper<Issue_Audit>;
  issue_audit_bool_exp: Issue_Audit_Bool_Exp;
  issue_audit_order_by: Issue_Audit_Order_By;
  issue_audit_select_column: Issue_Audit_Select_Column;
  issue_audit_stream_cursor_input: Issue_Audit_Stream_Cursor_Input;
  issue_audit_stream_cursor_value_input: Issue_Audit_Stream_Cursor_Value_Input;
  issue_avg_fields: ResolverTypeWrapper<Issue_Avg_Fields>;
  issue_bool_exp: Issue_Bool_Exp;
  issue_constraint: Issue_Constraint;
  issue_delete_at_path_input: Issue_Delete_At_Path_Input;
  issue_delete_elem_input: Issue_Delete_Elem_Input;
  issue_delete_key_input: Issue_Delete_Key_Input;
  issue_insert_input: Issue_Insert_Input;
  issue_max_fields: ResolverTypeWrapper<Issue_Max_Fields>;
  issue_min_fields: ResolverTypeWrapper<Issue_Min_Fields>;
  issue_mutation_response: ResolverTypeWrapper<Issue_Mutation_Response>;
  issue_obj_rel_insert_input: Issue_Obj_Rel_Insert_Input;
  issue_on_conflict: Issue_On_Conflict;
  issue_order_by: Issue_Order_By;
  issue_parent: ResolverTypeWrapper<Issue_Parent>;
  issue_parent_aggregate: ResolverTypeWrapper<Issue_Parent_Aggregate>;
  issue_parent_aggregate_bool_exp: Issue_Parent_Aggregate_Bool_Exp;
  issue_parent_aggregate_bool_exp_count: Issue_Parent_Aggregate_Bool_Exp_Count;
  issue_parent_aggregate_fields: ResolverTypeWrapper<Issue_Parent_Aggregate_Fields>;
  issue_parent_aggregate_order_by: Issue_Parent_Aggregate_Order_By;
  issue_parent_arr_rel_insert_input: Issue_Parent_Arr_Rel_Insert_Input;
  issue_parent_audit: ResolverTypeWrapper<Issue_Parent_Audit>;
  issue_parent_audit_aggregate_order_by: Issue_Parent_Audit_Aggregate_Order_By;
  issue_parent_audit_bool_exp: Issue_Parent_Audit_Bool_Exp;
  issue_parent_audit_max_order_by: Issue_Parent_Audit_Max_Order_By;
  issue_parent_audit_min_order_by: Issue_Parent_Audit_Min_Order_By;
  issue_parent_audit_order_by: Issue_Parent_Audit_Order_By;
  issue_parent_audit_select_column: Issue_Parent_Audit_Select_Column;
  issue_parent_audit_stream_cursor_input: Issue_Parent_Audit_Stream_Cursor_Input;
  issue_parent_audit_stream_cursor_value_input: Issue_Parent_Audit_Stream_Cursor_Value_Input;
  issue_parent_bool_exp: Issue_Parent_Bool_Exp;
  issue_parent_constraint: Issue_Parent_Constraint;
  issue_parent_insert_input: Issue_Parent_Insert_Input;
  issue_parent_max_fields: ResolverTypeWrapper<Issue_Parent_Max_Fields>;
  issue_parent_max_order_by: Issue_Parent_Max_Order_By;
  issue_parent_min_fields: ResolverTypeWrapper<Issue_Parent_Min_Fields>;
  issue_parent_min_order_by: Issue_Parent_Min_Order_By;
  issue_parent_on_conflict: Issue_Parent_On_Conflict;
  issue_parent_order_by: Issue_Parent_Order_By;
  issue_parent_select_column: Issue_Parent_Select_Column;
  issue_parent_stream_cursor_input: Issue_Parent_Stream_Cursor_Input;
  issue_parent_stream_cursor_value_input: Issue_Parent_Stream_Cursor_Value_Input;
  issue_parent_update_column: Issue_Parent_Update_Column;
  issue_pk_columns_input: Issue_Pk_Columns_Input;
  issue_prepend_input: Issue_Prepend_Input;
  issue_select_column: Issue_Select_Column;
  issue_set_input: Issue_Set_Input;
  issue_stddev_fields: ResolverTypeWrapper<Issue_Stddev_Fields>;
  issue_stddev_pop_fields: ResolverTypeWrapper<Issue_Stddev_Pop_Fields>;
  issue_stddev_samp_fields: ResolverTypeWrapper<Issue_Stddev_Samp_Fields>;
  issue_stream_cursor_input: Issue_Stream_Cursor_Input;
  issue_stream_cursor_value_input: Issue_Stream_Cursor_Value_Input;
  issue_sum_fields: ResolverTypeWrapper<Issue_Sum_Fields>;
  issue_update: ResolverTypeWrapper<Issue_Update>;
  issue_update_aggregate_order_by: Issue_Update_Aggregate_Order_By;
  issue_update_append_input: Issue_Update_Append_Input;
  issue_update_arr_rel_insert_input: Issue_Update_Arr_Rel_Insert_Input;
  issue_update_audit: ResolverTypeWrapper<Issue_Update_Audit>;
  issue_update_audit_bool_exp: Issue_Update_Audit_Bool_Exp;
  issue_update_audit_order_by: Issue_Update_Audit_Order_By;
  issue_update_audit_select_column: Issue_Update_Audit_Select_Column;
  issue_update_audit_stream_cursor_input: Issue_Update_Audit_Stream_Cursor_Input;
  issue_update_audit_stream_cursor_value_input: Issue_Update_Audit_Stream_Cursor_Value_Input;
  issue_update_bool_exp: Issue_Update_Bool_Exp;
  issue_update_column: Issue_Update_Column;
  issue_update_constraint: Issue_Update_Constraint;
  issue_update_delete_at_path_input: Issue_Update_Delete_At_Path_Input;
  issue_update_delete_elem_input: Issue_Update_Delete_Elem_Input;
  issue_update_delete_key_input: Issue_Update_Delete_Key_Input;
  issue_update_insert_input: Issue_Update_Insert_Input;
  issue_update_max_order_by: Issue_Update_Max_Order_By;
  issue_update_min_order_by: Issue_Update_Min_Order_By;
  issue_update_mutation_response: ResolverTypeWrapper<Issue_Update_Mutation_Response>;
  issue_update_obj_rel_insert_input: Issue_Update_Obj_Rel_Insert_Input;
  issue_update_on_conflict: Issue_Update_On_Conflict;
  issue_update_order_by: Issue_Update_Order_By;
  issue_update_pk_columns_input: Issue_Update_Pk_Columns_Input;
  issue_update_prepend_input: Issue_Update_Prepend_Input;
  issue_update_select_column: Issue_Update_Select_Column;
  issue_update_set_input: Issue_Update_Set_Input;
  issue_update_stream_cursor_input: Issue_Update_Stream_Cursor_Input;
  issue_update_stream_cursor_value_input: Issue_Update_Stream_Cursor_Value_Input;
  issue_update_update_column: Issue_Update_Update_Column;
  issue_update_updates: Issue_Update_Updates;
  issue_updates: Issue_Updates;
  issue_var_pop_fields: ResolverTypeWrapper<Issue_Var_Pop_Fields>;
  issue_var_samp_fields: ResolverTypeWrapper<Issue_Var_Samp_Fields>;
  issue_variance_fields: ResolverTypeWrapper<Issue_Variance_Fields>;
  json: ResolverTypeWrapper<Scalars['json']['output']>;
  json_comparison_exp: Json_Comparison_Exp;
  jsonb: ResolverTypeWrapper<Scalars['jsonb']['output']>;
  jsonb_cast_exp: Jsonb_Cast_Exp;
  jsonb_comparison_exp: Jsonb_Comparison_Exp;
  linked_item: ResolverTypeWrapper<Linked_Item>;
  linked_item_bool_exp: Linked_Item_Bool_Exp;
  linked_item_mutation_response: ResolverTypeWrapper<Linked_Item_Mutation_Response>;
  linked_item_order_by: Linked_Item_Order_By;
  linked_item_select_column: Linked_Item_Select_Column;
  linked_item_stream_cursor_input: Linked_Item_Stream_Cursor_Input;
  linked_item_stream_cursor_value_input: Linked_Item_Stream_Cursor_Value_Input;
  mutation_root: ResolverTypeWrapper<{}>;
  node: ResolverTypeWrapper<Node>;
  node_aggregate: ResolverTypeWrapper<Node_Aggregate>;
  node_aggregate_fields: ResolverTypeWrapper<Node_Aggregate_Fields>;
  node_avg_fields: ResolverTypeWrapper<Node_Avg_Fields>;
  node_bool_exp: Node_Bool_Exp;
  node_max_fields: ResolverTypeWrapper<Node_Max_Fields>;
  node_min_fields: ResolverTypeWrapper<Node_Min_Fields>;
  node_order_by: Node_Order_By;
  node_select_column: Node_Select_Column;
  node_stddev_fields: ResolverTypeWrapper<Node_Stddev_Fields>;
  node_stddev_pop_fields: ResolverTypeWrapper<Node_Stddev_Pop_Fields>;
  node_stddev_samp_fields: ResolverTypeWrapper<Node_Stddev_Samp_Fields>;
  node_stream_cursor_input: Node_Stream_Cursor_Input;
  node_stream_cursor_value_input: Node_Stream_Cursor_Value_Input;
  node_sum_fields: ResolverTypeWrapper<Node_Sum_Fields>;
  node_var_pop_fields: ResolverTypeWrapper<Node_Var_Pop_Fields>;
  node_var_samp_fields: ResolverTypeWrapper<Node_Var_Samp_Fields>;
  node_variance_fields: ResolverTypeWrapper<Node_Variance_Fields>;
  numeric: ResolverTypeWrapper<Scalars['numeric']['output']>;
  numeric_comparison_exp: Numeric_Comparison_Exp;
  obligation: ResolverTypeWrapper<Obligation>;
  obligation_aggregate: ResolverTypeWrapper<Obligation_Aggregate>;
  obligation_aggregate_bool_exp: Obligation_Aggregate_Bool_Exp;
  obligation_aggregate_bool_exp_count: Obligation_Aggregate_Bool_Exp_Count;
  obligation_aggregate_fields: ResolverTypeWrapper<Obligation_Aggregate_Fields>;
  obligation_aggregate_order_by: Obligation_Aggregate_Order_By;
  obligation_assessment_result: ResolverTypeWrapper<Obligation_Assessment_Result>;
  obligation_assessment_result_aggregate: ResolverTypeWrapper<Obligation_Assessment_Result_Aggregate>;
  obligation_assessment_result_aggregate_fields: ResolverTypeWrapper<Obligation_Assessment_Result_Aggregate_Fields>;
  obligation_assessment_result_append_input: Obligation_Assessment_Result_Append_Input;
  obligation_assessment_result_audit: ResolverTypeWrapper<Obligation_Assessment_Result_Audit>;
  obligation_assessment_result_audit_bool_exp: Obligation_Assessment_Result_Audit_Bool_Exp;
  obligation_assessment_result_audit_order_by: Obligation_Assessment_Result_Audit_Order_By;
  obligation_assessment_result_audit_select_column: Obligation_Assessment_Result_Audit_Select_Column;
  obligation_assessment_result_audit_stream_cursor_input: Obligation_Assessment_Result_Audit_Stream_Cursor_Input;
  obligation_assessment_result_audit_stream_cursor_value_input: Obligation_Assessment_Result_Audit_Stream_Cursor_Value_Input;
  obligation_assessment_result_avg_fields: ResolverTypeWrapper<Obligation_Assessment_Result_Avg_Fields>;
  obligation_assessment_result_bool_exp: Obligation_Assessment_Result_Bool_Exp;
  obligation_assessment_result_constraint: Obligation_Assessment_Result_Constraint;
  obligation_assessment_result_delete_at_path_input: Obligation_Assessment_Result_Delete_At_Path_Input;
  obligation_assessment_result_delete_elem_input: Obligation_Assessment_Result_Delete_Elem_Input;
  obligation_assessment_result_delete_key_input: Obligation_Assessment_Result_Delete_Key_Input;
  obligation_assessment_result_inc_input: Obligation_Assessment_Result_Inc_Input;
  obligation_assessment_result_insert_input: Obligation_Assessment_Result_Insert_Input;
  obligation_assessment_result_max_fields: ResolverTypeWrapper<Obligation_Assessment_Result_Max_Fields>;
  obligation_assessment_result_min_fields: ResolverTypeWrapper<Obligation_Assessment_Result_Min_Fields>;
  obligation_assessment_result_mutation_response: ResolverTypeWrapper<Obligation_Assessment_Result_Mutation_Response>;
  obligation_assessment_result_obj_rel_insert_input: Obligation_Assessment_Result_Obj_Rel_Insert_Input;
  obligation_assessment_result_on_conflict: Obligation_Assessment_Result_On_Conflict;
  obligation_assessment_result_order_by: Obligation_Assessment_Result_Order_By;
  obligation_assessment_result_pk_columns_input: Obligation_Assessment_Result_Pk_Columns_Input;
  obligation_assessment_result_prepend_input: Obligation_Assessment_Result_Prepend_Input;
  obligation_assessment_result_select_column: Obligation_Assessment_Result_Select_Column;
  obligation_assessment_result_set_input: Obligation_Assessment_Result_Set_Input;
  obligation_assessment_result_stddev_fields: ResolverTypeWrapper<Obligation_Assessment_Result_Stddev_Fields>;
  obligation_assessment_result_stddev_pop_fields: ResolverTypeWrapper<Obligation_Assessment_Result_Stddev_Pop_Fields>;
  obligation_assessment_result_stddev_samp_fields: ResolverTypeWrapper<Obligation_Assessment_Result_Stddev_Samp_Fields>;
  obligation_assessment_result_stream_cursor_input: Obligation_Assessment_Result_Stream_Cursor_Input;
  obligation_assessment_result_stream_cursor_value_input: Obligation_Assessment_Result_Stream_Cursor_Value_Input;
  obligation_assessment_result_sum_fields: ResolverTypeWrapper<Obligation_Assessment_Result_Sum_Fields>;
  obligation_assessment_result_update_column: Obligation_Assessment_Result_Update_Column;
  obligation_assessment_result_updates: Obligation_Assessment_Result_Updates;
  obligation_assessment_result_var_pop_fields: ResolverTypeWrapper<Obligation_Assessment_Result_Var_Pop_Fields>;
  obligation_assessment_result_var_samp_fields: ResolverTypeWrapper<Obligation_Assessment_Result_Var_Samp_Fields>;
  obligation_assessment_result_variance_fields: ResolverTypeWrapper<Obligation_Assessment_Result_Variance_Fields>;
  obligation_assessment_status: ResolverTypeWrapper<Obligation_Assessment_Status>;
  obligation_assessment_status_bool_exp: Obligation_Assessment_Status_Bool_Exp;
  obligation_assessment_status_order_by: Obligation_Assessment_Status_Order_By;
  obligation_assessment_status_select_column: Obligation_Assessment_Status_Select_Column;
  obligation_assessment_status_stream_cursor_input: Obligation_Assessment_Status_Stream_Cursor_Input;
  obligation_assessment_status_stream_cursor_value_input: Obligation_Assessment_Status_Stream_Cursor_Value_Input;
  obligation_audit: ResolverTypeWrapper<Obligation_Audit>;
  obligation_audit_aggregate: ResolverTypeWrapper<Obligation_Audit_Aggregate>;
  obligation_audit_aggregate_fields: ResolverTypeWrapper<Obligation_Audit_Aggregate_Fields>;
  obligation_audit_avg_fields: ResolverTypeWrapper<Obligation_Audit_Avg_Fields>;
  obligation_audit_bool_exp: Obligation_Audit_Bool_Exp;
  obligation_audit_max_fields: ResolverTypeWrapper<Obligation_Audit_Max_Fields>;
  obligation_audit_min_fields: ResolverTypeWrapper<Obligation_Audit_Min_Fields>;
  obligation_audit_order_by: Obligation_Audit_Order_By;
  obligation_audit_select_column: Obligation_Audit_Select_Column;
  obligation_audit_stddev_fields: ResolverTypeWrapper<Obligation_Audit_Stddev_Fields>;
  obligation_audit_stddev_pop_fields: ResolverTypeWrapper<Obligation_Audit_Stddev_Pop_Fields>;
  obligation_audit_stddev_samp_fields: ResolverTypeWrapper<Obligation_Audit_Stddev_Samp_Fields>;
  obligation_audit_stream_cursor_input: Obligation_Audit_Stream_Cursor_Input;
  obligation_audit_stream_cursor_value_input: Obligation_Audit_Stream_Cursor_Value_Input;
  obligation_audit_sum_fields: ResolverTypeWrapper<Obligation_Audit_Sum_Fields>;
  obligation_audit_var_pop_fields: ResolverTypeWrapper<Obligation_Audit_Var_Pop_Fields>;
  obligation_audit_var_samp_fields: ResolverTypeWrapper<Obligation_Audit_Var_Samp_Fields>;
  obligation_audit_variance_fields: ResolverTypeWrapper<Obligation_Audit_Variance_Fields>;
  obligation_avg_fields: ResolverTypeWrapper<Obligation_Avg_Fields>;
  obligation_avg_order_by: Obligation_Avg_Order_By;
  obligation_bool_exp: Obligation_Bool_Exp;
  obligation_constraint: Obligation_Constraint;
  obligation_impact: ResolverTypeWrapper<Obligation_Impact>;
  obligation_impact_aggregate_order_by: Obligation_Impact_Aggregate_Order_By;
  obligation_impact_append_input: Obligation_Impact_Append_Input;
  obligation_impact_arr_rel_insert_input: Obligation_Impact_Arr_Rel_Insert_Input;
  obligation_impact_audit: ResolverTypeWrapper<Obligation_Impact_Audit>;
  obligation_impact_audit_bool_exp: Obligation_Impact_Audit_Bool_Exp;
  obligation_impact_audit_order_by: Obligation_Impact_Audit_Order_By;
  obligation_impact_audit_select_column: Obligation_Impact_Audit_Select_Column;
  obligation_impact_audit_stream_cursor_input: Obligation_Impact_Audit_Stream_Cursor_Input;
  obligation_impact_audit_stream_cursor_value_input: Obligation_Impact_Audit_Stream_Cursor_Value_Input;
  obligation_impact_avg_order_by: Obligation_Impact_Avg_Order_By;
  obligation_impact_bool_exp: Obligation_Impact_Bool_Exp;
  obligation_impact_constraint: Obligation_Impact_Constraint;
  obligation_impact_delete_at_path_input: Obligation_Impact_Delete_At_Path_Input;
  obligation_impact_delete_elem_input: Obligation_Impact_Delete_Elem_Input;
  obligation_impact_delete_key_input: Obligation_Impact_Delete_Key_Input;
  obligation_impact_inc_input: Obligation_Impact_Inc_Input;
  obligation_impact_insert_input: Obligation_Impact_Insert_Input;
  obligation_impact_max_order_by: Obligation_Impact_Max_Order_By;
  obligation_impact_min_order_by: Obligation_Impact_Min_Order_By;
  obligation_impact_mutation_response: ResolverTypeWrapper<Obligation_Impact_Mutation_Response>;
  obligation_impact_on_conflict: Obligation_Impact_On_Conflict;
  obligation_impact_order_by: Obligation_Impact_Order_By;
  obligation_impact_pk_columns_input: Obligation_Impact_Pk_Columns_Input;
  obligation_impact_prepend_input: Obligation_Impact_Prepend_Input;
  obligation_impact_select_column: Obligation_Impact_Select_Column;
  obligation_impact_set_input: Obligation_Impact_Set_Input;
  obligation_impact_stddev_order_by: Obligation_Impact_Stddev_Order_By;
  obligation_impact_stddev_pop_order_by: Obligation_Impact_Stddev_Pop_Order_By;
  obligation_impact_stddev_samp_order_by: Obligation_Impact_Stddev_Samp_Order_By;
  obligation_impact_stream_cursor_input: Obligation_Impact_Stream_Cursor_Input;
  obligation_impact_stream_cursor_value_input: Obligation_Impact_Stream_Cursor_Value_Input;
  obligation_impact_sum_order_by: Obligation_Impact_Sum_Order_By;
  obligation_impact_update_column: Obligation_Impact_Update_Column;
  obligation_impact_updates: Obligation_Impact_Updates;
  obligation_impact_var_pop_order_by: Obligation_Impact_Var_Pop_Order_By;
  obligation_impact_var_samp_order_by: Obligation_Impact_Var_Samp_Order_By;
  obligation_impact_variance_order_by: Obligation_Impact_Variance_Order_By;
  obligation_insert_input: Obligation_Insert_Input;
  obligation_max_fields: ResolverTypeWrapper<Obligation_Max_Fields>;
  obligation_max_order_by: Obligation_Max_Order_By;
  obligation_min_fields: ResolverTypeWrapper<Obligation_Min_Fields>;
  obligation_min_order_by: Obligation_Min_Order_By;
  obligation_mutation_response: ResolverTypeWrapper<Obligation_Mutation_Response>;
  obligation_obj_rel_insert_input: Obligation_Obj_Rel_Insert_Input;
  obligation_on_conflict: Obligation_On_Conflict;
  obligation_order_by: Obligation_Order_By;
  obligation_select_column: Obligation_Select_Column;
  obligation_stddev_fields: ResolverTypeWrapper<Obligation_Stddev_Fields>;
  obligation_stddev_order_by: Obligation_Stddev_Order_By;
  obligation_stddev_pop_fields: ResolverTypeWrapper<Obligation_Stddev_Pop_Fields>;
  obligation_stddev_pop_order_by: Obligation_Stddev_Pop_Order_By;
  obligation_stddev_samp_fields: ResolverTypeWrapper<Obligation_Stddev_Samp_Fields>;
  obligation_stddev_samp_order_by: Obligation_Stddev_Samp_Order_By;
  obligation_stream_cursor_input: Obligation_Stream_Cursor_Input;
  obligation_stream_cursor_value_input: Obligation_Stream_Cursor_Value_Input;
  obligation_sum_fields: ResolverTypeWrapper<Obligation_Sum_Fields>;
  obligation_sum_order_by: Obligation_Sum_Order_By;
  obligation_type: ResolverTypeWrapper<Obligation_Type>;
  obligation_type_bool_exp: Obligation_Type_Bool_Exp;
  obligation_type_enum: Obligation_Type_Enum;
  obligation_type_enum_comparison_exp: Obligation_Type_Enum_Comparison_Exp;
  obligation_type_order_by: Obligation_Type_Order_By;
  obligation_type_select_column: Obligation_Type_Select_Column;
  obligation_type_stream_cursor_input: Obligation_Type_Stream_Cursor_Input;
  obligation_type_stream_cursor_value_input: Obligation_Type_Stream_Cursor_Value_Input;
  obligation_update_column: Obligation_Update_Column;
  obligation_var_pop_fields: ResolverTypeWrapper<Obligation_Var_Pop_Fields>;
  obligation_var_pop_order_by: Obligation_Var_Pop_Order_By;
  obligation_var_samp_fields: ResolverTypeWrapper<Obligation_Var_Samp_Fields>;
  obligation_var_samp_order_by: Obligation_Var_Samp_Order_By;
  obligation_variance_fields: ResolverTypeWrapper<Obligation_Variance_Fields>;
  obligation_variance_order_by: Obligation_Variance_Order_By;
  order_by: Order_By;
  owner: ResolverTypeWrapper<Owner>;
  owner_aggregate: ResolverTypeWrapper<Owner_Aggregate>;
  owner_aggregate_bool_exp: Owner_Aggregate_Bool_Exp;
  owner_aggregate_bool_exp_count: Owner_Aggregate_Bool_Exp_Count;
  owner_aggregate_fields: ResolverTypeWrapper<Owner_Aggregate_Fields>;
  owner_aggregate_order_by: Owner_Aggregate_Order_By;
  owner_arr_rel_insert_input: Owner_Arr_Rel_Insert_Input;
  owner_audit: ResolverTypeWrapper<Owner_Audit>;
  owner_audit_aggregate_order_by: Owner_Audit_Aggregate_Order_By;
  owner_audit_bool_exp: Owner_Audit_Bool_Exp;
  owner_audit_max_order_by: Owner_Audit_Max_Order_By;
  owner_audit_min_order_by: Owner_Audit_Min_Order_By;
  owner_audit_order_by: Owner_Audit_Order_By;
  owner_audit_select_column: Owner_Audit_Select_Column;
  owner_audit_stream_cursor_input: Owner_Audit_Stream_Cursor_Input;
  owner_audit_stream_cursor_value_input: Owner_Audit_Stream_Cursor_Value_Input;
  owner_bool_exp: Owner_Bool_Exp;
  owner_constraint: Owner_Constraint;
  owner_group: ResolverTypeWrapper<Owner_Group>;
  owner_group_aggregate: ResolverTypeWrapper<Owner_Group_Aggregate>;
  owner_group_aggregate_bool_exp: Owner_Group_Aggregate_Bool_Exp;
  owner_group_aggregate_bool_exp_count: Owner_Group_Aggregate_Bool_Exp_Count;
  owner_group_aggregate_fields: ResolverTypeWrapper<Owner_Group_Aggregate_Fields>;
  owner_group_aggregate_order_by: Owner_Group_Aggregate_Order_By;
  owner_group_arr_rel_insert_input: Owner_Group_Arr_Rel_Insert_Input;
  owner_group_audit: ResolverTypeWrapper<Owner_Group_Audit>;
  owner_group_audit_aggregate_order_by: Owner_Group_Audit_Aggregate_Order_By;
  owner_group_audit_bool_exp: Owner_Group_Audit_Bool_Exp;
  owner_group_audit_max_order_by: Owner_Group_Audit_Max_Order_By;
  owner_group_audit_min_order_by: Owner_Group_Audit_Min_Order_By;
  owner_group_audit_order_by: Owner_Group_Audit_Order_By;
  owner_group_audit_select_column: Owner_Group_Audit_Select_Column;
  owner_group_audit_stream_cursor_input: Owner_Group_Audit_Stream_Cursor_Input;
  owner_group_audit_stream_cursor_value_input: Owner_Group_Audit_Stream_Cursor_Value_Input;
  owner_group_bool_exp: Owner_Group_Bool_Exp;
  owner_group_constraint: Owner_Group_Constraint;
  owner_group_insert_input: Owner_Group_Insert_Input;
  owner_group_max_fields: ResolverTypeWrapper<Owner_Group_Max_Fields>;
  owner_group_max_order_by: Owner_Group_Max_Order_By;
  owner_group_min_fields: ResolverTypeWrapper<Owner_Group_Min_Fields>;
  owner_group_min_order_by: Owner_Group_Min_Order_By;
  owner_group_mutation_response: ResolverTypeWrapper<Owner_Group_Mutation_Response>;
  owner_group_on_conflict: Owner_Group_On_Conflict;
  owner_group_order_by: Owner_Group_Order_By;
  owner_group_select_column: Owner_Group_Select_Column;
  owner_group_update_column: Owner_Group_Update_Column;
  owner_insert_input: Owner_Insert_Input;
  owner_max_fields: ResolverTypeWrapper<Owner_Max_Fields>;
  owner_max_order_by: Owner_Max_Order_By;
  owner_min_fields: ResolverTypeWrapper<Owner_Min_Fields>;
  owner_min_order_by: Owner_Min_Order_By;
  owner_mutation_response: ResolverTypeWrapper<Owner_Mutation_Response>;
  owner_on_conflict: Owner_On_Conflict;
  owner_order_by: Owner_Order_By;
  owner_select_column: Owner_Select_Column;
  owner_update_column: Owner_Update_Column;
  parent_type_enum: Parent_Type_Enum;
  parent_type_enum_comparison_exp: Parent_Type_Enum_Comparison_Exp;
  query_root: ResolverTypeWrapper<{}>;
  questionnaire_invite: ResolverTypeWrapper<Questionnaire_Invite>;
  questionnaire_invite_aggregate_order_by: Questionnaire_Invite_Aggregate_Order_By;
  questionnaire_invite_arr_rel_insert_input: Questionnaire_Invite_Arr_Rel_Insert_Input;
  questionnaire_invite_bool_exp: Questionnaire_Invite_Bool_Exp;
  questionnaire_invite_constraint: Questionnaire_Invite_Constraint;
  questionnaire_invite_insert_input: Questionnaire_Invite_Insert_Input;
  questionnaire_invite_max_order_by: Questionnaire_Invite_Max_Order_By;
  questionnaire_invite_min_order_by: Questionnaire_Invite_Min_Order_By;
  questionnaire_invite_mutation_response: ResolverTypeWrapper<Questionnaire_Invite_Mutation_Response>;
  questionnaire_invite_on_conflict: Questionnaire_Invite_On_Conflict;
  questionnaire_invite_order_by: Questionnaire_Invite_Order_By;
  questionnaire_invite_select_column: Questionnaire_Invite_Select_Column;
  questionnaire_invite_stream_cursor_input: Questionnaire_Invite_Stream_Cursor_Input;
  questionnaire_invite_stream_cursor_value_input: Questionnaire_Invite_Stream_Cursor_Value_Input;
  questionnaire_invite_update_column: Questionnaire_Invite_Update_Column;
  questionnaire_template: ResolverTypeWrapper<Questionnaire_Template>;
  questionnaire_template_append_input: Questionnaire_Template_Append_Input;
  questionnaire_template_bool_exp: Questionnaire_Template_Bool_Exp;
  questionnaire_template_constraint: Questionnaire_Template_Constraint;
  questionnaire_template_delete_at_path_input: Questionnaire_Template_Delete_At_Path_Input;
  questionnaire_template_delete_elem_input: Questionnaire_Template_Delete_Elem_Input;
  questionnaire_template_delete_key_input: Questionnaire_Template_Delete_Key_Input;
  questionnaire_template_insert_input: Questionnaire_Template_Insert_Input;
  questionnaire_template_mutation_response: ResolverTypeWrapper<Questionnaire_Template_Mutation_Response>;
  questionnaire_template_obj_rel_insert_input: Questionnaire_Template_Obj_Rel_Insert_Input;
  questionnaire_template_on_conflict: Questionnaire_Template_On_Conflict;
  questionnaire_template_order_by: Questionnaire_Template_Order_By;
  questionnaire_template_pk_columns_input: Questionnaire_Template_Pk_Columns_Input;
  questionnaire_template_prepend_input: Questionnaire_Template_Prepend_Input;
  questionnaire_template_select_column: Questionnaire_Template_Select_Column;
  questionnaire_template_set_input: Questionnaire_Template_Set_Input;
  questionnaire_template_stream_cursor_input: Questionnaire_Template_Stream_Cursor_Input;
  questionnaire_template_stream_cursor_value_input: Questionnaire_Template_Stream_Cursor_Value_Input;
  questionnaire_template_update_column: Questionnaire_Template_Update_Column;
  questionnaire_template_updates: Questionnaire_Template_Updates;
  questionnaire_template_version: ResolverTypeWrapper<Questionnaire_Template_Version>;
  questionnaire_template_version_aggregate_order_by: Questionnaire_Template_Version_Aggregate_Order_By;
  questionnaire_template_version_append_input: Questionnaire_Template_Version_Append_Input;
  questionnaire_template_version_arr_rel_insert_input: Questionnaire_Template_Version_Arr_Rel_Insert_Input;
  questionnaire_template_version_bool_exp: Questionnaire_Template_Version_Bool_Exp;
  questionnaire_template_version_constraint: Questionnaire_Template_Version_Constraint;
  questionnaire_template_version_delete_at_path_input: Questionnaire_Template_Version_Delete_At_Path_Input;
  questionnaire_template_version_delete_elem_input: Questionnaire_Template_Version_Delete_Elem_Input;
  questionnaire_template_version_delete_key_input: Questionnaire_Template_Version_Delete_Key_Input;
  questionnaire_template_version_insert_input: Questionnaire_Template_Version_Insert_Input;
  questionnaire_template_version_max_order_by: Questionnaire_Template_Version_Max_Order_By;
  questionnaire_template_version_min_order_by: Questionnaire_Template_Version_Min_Order_By;
  questionnaire_template_version_mutation_response: ResolverTypeWrapper<Questionnaire_Template_Version_Mutation_Response>;
  questionnaire_template_version_obj_rel_insert_input: Questionnaire_Template_Version_Obj_Rel_Insert_Input;
  questionnaire_template_version_on_conflict: Questionnaire_Template_Version_On_Conflict;
  questionnaire_template_version_order_by: Questionnaire_Template_Version_Order_By;
  questionnaire_template_version_pk_columns_input: Questionnaire_Template_Version_Pk_Columns_Input;
  questionnaire_template_version_prepend_input: Questionnaire_Template_Version_Prepend_Input;
  questionnaire_template_version_select_column: Questionnaire_Template_Version_Select_Column;
  questionnaire_template_version_set_input: Questionnaire_Template_Version_Set_Input;
  questionnaire_template_version_status: ResolverTypeWrapper<Questionnaire_Template_Version_Status>;
  questionnaire_template_version_status_bool_exp: Questionnaire_Template_Version_Status_Bool_Exp;
  questionnaire_template_version_status_enum: Questionnaire_Template_Version_Status_Enum;
  questionnaire_template_version_status_enum_comparison_exp: Questionnaire_Template_Version_Status_Enum_Comparison_Exp;
  questionnaire_template_version_status_order_by: Questionnaire_Template_Version_Status_Order_By;
  questionnaire_template_version_status_select_column: Questionnaire_Template_Version_Status_Select_Column;
  questionnaire_template_version_status_stream_cursor_input: Questionnaire_Template_Version_Status_Stream_Cursor_Input;
  questionnaire_template_version_status_stream_cursor_value_input: Questionnaire_Template_Version_Status_Stream_Cursor_Value_Input;
  questionnaire_template_version_stream_cursor_input: Questionnaire_Template_Version_Stream_Cursor_Input;
  questionnaire_template_version_stream_cursor_value_input: Questionnaire_Template_Version_Stream_Cursor_Value_Input;
  questionnaire_template_version_update_column: Questionnaire_Template_Version_Update_Column;
  questionnaire_template_version_updates: Questionnaire_Template_Version_Updates;
  relation_file: ResolverTypeWrapper<Relation_File>;
  relation_file_aggregate: ResolverTypeWrapper<Relation_File_Aggregate>;
  relation_file_aggregate_bool_exp: Relation_File_Aggregate_Bool_Exp;
  relation_file_aggregate_bool_exp_count: Relation_File_Aggregate_Bool_Exp_Count;
  relation_file_aggregate_fields: ResolverTypeWrapper<Relation_File_Aggregate_Fields>;
  relation_file_aggregate_order_by: Relation_File_Aggregate_Order_By;
  relation_file_arr_rel_insert_input: Relation_File_Arr_Rel_Insert_Input;
  relation_file_audit: ResolverTypeWrapper<Relation_File_Audit>;
  relation_file_audit_bool_exp: Relation_File_Audit_Bool_Exp;
  relation_file_audit_order_by: Relation_File_Audit_Order_By;
  relation_file_audit_select_column: Relation_File_Audit_Select_Column;
  relation_file_audit_stream_cursor_input: Relation_File_Audit_Stream_Cursor_Input;
  relation_file_audit_stream_cursor_value_input: Relation_File_Audit_Stream_Cursor_Value_Input;
  relation_file_bool_exp: Relation_File_Bool_Exp;
  relation_file_constraint: Relation_File_Constraint;
  relation_file_insert_input: Relation_File_Insert_Input;
  relation_file_max_fields: ResolverTypeWrapper<Relation_File_Max_Fields>;
  relation_file_max_order_by: Relation_File_Max_Order_By;
  relation_file_min_fields: ResolverTypeWrapper<Relation_File_Min_Fields>;
  relation_file_min_order_by: Relation_File_Min_Order_By;
  relation_file_mutation_response: ResolverTypeWrapper<Relation_File_Mutation_Response>;
  relation_file_on_conflict: Relation_File_On_Conflict;
  relation_file_order_by: Relation_File_Order_By;
  relation_file_select_column: Relation_File_Select_Column;
  relation_file_stream_cursor_input: Relation_File_Stream_Cursor_Input;
  relation_file_stream_cursor_value_input: Relation_File_Stream_Cursor_Value_Input;
  relation_file_update_column: Relation_File_Update_Column;
  risk: ResolverTypeWrapper<Risk>;
  risk_aggregate: ResolverTypeWrapper<Risk_Aggregate>;
  risk_aggregate_bool_exp: Risk_Aggregate_Bool_Exp;
  risk_aggregate_bool_exp_count: Risk_Aggregate_Bool_Exp_Count;
  risk_aggregate_fields: ResolverTypeWrapper<Risk_Aggregate_Fields>;
  risk_aggregate_order_by: Risk_Aggregate_Order_By;
  risk_arr_rel_insert_input: Risk_Arr_Rel_Insert_Input;
  risk_assessment_result: ResolverTypeWrapper<Risk_Assessment_Result>;
  risk_assessment_result_aggregate: ResolverTypeWrapper<Risk_Assessment_Result_Aggregate>;
  risk_assessment_result_aggregate_fields: ResolverTypeWrapper<Risk_Assessment_Result_Aggregate_Fields>;
  risk_assessment_result_audit: ResolverTypeWrapper<Risk_Assessment_Result_Audit>;
  risk_assessment_result_audit_bool_exp: Risk_Assessment_Result_Audit_Bool_Exp;
  risk_assessment_result_audit_order_by: Risk_Assessment_Result_Audit_Order_By;
  risk_assessment_result_audit_select_column: Risk_Assessment_Result_Audit_Select_Column;
  risk_assessment_result_audit_stream_cursor_input: Risk_Assessment_Result_Audit_Stream_Cursor_Input;
  risk_assessment_result_audit_stream_cursor_value_input: Risk_Assessment_Result_Audit_Stream_Cursor_Value_Input;
  risk_assessment_result_avg_fields: ResolverTypeWrapper<Risk_Assessment_Result_Avg_Fields>;
  risk_assessment_result_bool_exp: Risk_Assessment_Result_Bool_Exp;
  risk_assessment_result_constraint: Risk_Assessment_Result_Constraint;
  risk_assessment_result_control_type_enum: Risk_Assessment_Result_Control_Type_Enum;
  risk_assessment_result_control_type_enum_comparison_exp: Risk_Assessment_Result_Control_Type_Enum_Comparison_Exp;
  risk_assessment_result_insert_input: Risk_Assessment_Result_Insert_Input;
  risk_assessment_result_max_fields: ResolverTypeWrapper<Risk_Assessment_Result_Max_Fields>;
  risk_assessment_result_min_fields: ResolverTypeWrapper<Risk_Assessment_Result_Min_Fields>;
  risk_assessment_result_mutation_response: ResolverTypeWrapper<Risk_Assessment_Result_Mutation_Response>;
  risk_assessment_result_obj_rel_insert_input: Risk_Assessment_Result_Obj_Rel_Insert_Input;
  risk_assessment_result_on_conflict: Risk_Assessment_Result_On_Conflict;
  risk_assessment_result_order_by: Risk_Assessment_Result_Order_By;
  risk_assessment_result_select_column: Risk_Assessment_Result_Select_Column;
  risk_assessment_result_stddev_fields: ResolverTypeWrapper<Risk_Assessment_Result_Stddev_Fields>;
  risk_assessment_result_stddev_pop_fields: ResolverTypeWrapper<Risk_Assessment_Result_Stddev_Pop_Fields>;
  risk_assessment_result_stddev_samp_fields: ResolverTypeWrapper<Risk_Assessment_Result_Stddev_Samp_Fields>;
  risk_assessment_result_stream_cursor_input: Risk_Assessment_Result_Stream_Cursor_Input;
  risk_assessment_result_stream_cursor_value_input: Risk_Assessment_Result_Stream_Cursor_Value_Input;
  risk_assessment_result_sum_fields: ResolverTypeWrapper<Risk_Assessment_Result_Sum_Fields>;
  risk_assessment_result_update_column: Risk_Assessment_Result_Update_Column;
  risk_assessment_result_var_pop_fields: ResolverTypeWrapper<Risk_Assessment_Result_Var_Pop_Fields>;
  risk_assessment_result_var_samp_fields: ResolverTypeWrapper<Risk_Assessment_Result_Var_Samp_Fields>;
  risk_assessment_result_variance_fields: ResolverTypeWrapper<Risk_Assessment_Result_Variance_Fields>;
  risk_audit: ResolverTypeWrapper<Risk_Audit>;
  risk_audit_aggregate: ResolverTypeWrapper<Risk_Audit_Aggregate>;
  risk_audit_aggregate_fields: ResolverTypeWrapper<Risk_Audit_Aggregate_Fields>;
  risk_audit_avg_fields: ResolverTypeWrapper<Risk_Audit_Avg_Fields>;
  risk_audit_bool_exp: Risk_Audit_Bool_Exp;
  risk_audit_max_fields: ResolverTypeWrapper<Risk_Audit_Max_Fields>;
  risk_audit_min_fields: ResolverTypeWrapper<Risk_Audit_Min_Fields>;
  risk_audit_order_by: Risk_Audit_Order_By;
  risk_audit_select_column: Risk_Audit_Select_Column;
  risk_audit_stddev_fields: ResolverTypeWrapper<Risk_Audit_Stddev_Fields>;
  risk_audit_stddev_pop_fields: ResolverTypeWrapper<Risk_Audit_Stddev_Pop_Fields>;
  risk_audit_stddev_samp_fields: ResolverTypeWrapper<Risk_Audit_Stddev_Samp_Fields>;
  risk_audit_stream_cursor_input: Risk_Audit_Stream_Cursor_Input;
  risk_audit_stream_cursor_value_input: Risk_Audit_Stream_Cursor_Value_Input;
  risk_audit_sum_fields: ResolverTypeWrapper<Risk_Audit_Sum_Fields>;
  risk_audit_var_pop_fields: ResolverTypeWrapper<Risk_Audit_Var_Pop_Fields>;
  risk_audit_var_samp_fields: ResolverTypeWrapper<Risk_Audit_Var_Samp_Fields>;
  risk_audit_variance_fields: ResolverTypeWrapper<Risk_Audit_Variance_Fields>;
  risk_avg_fields: ResolverTypeWrapper<Risk_Avg_Fields>;
  risk_avg_order_by: Risk_Avg_Order_By;
  risk_bool_exp: Risk_Bool_Exp;
  risk_constraint: Risk_Constraint;
  risk_insert_input: Risk_Insert_Input;
  risk_max_fields: ResolverTypeWrapper<Risk_Max_Fields>;
  risk_max_order_by: Risk_Max_Order_By;
  risk_min_fields: ResolverTypeWrapper<Risk_Min_Fields>;
  risk_min_order_by: Risk_Min_Order_By;
  risk_obj_rel_insert_input: Risk_Obj_Rel_Insert_Input;
  risk_on_conflict: Risk_On_Conflict;
  risk_order_by: Risk_Order_By;
  risk_score: ResolverTypeWrapper<Risk_Score>;
  risk_score_aggregate: ResolverTypeWrapper<Risk_Score_Aggregate>;
  risk_score_aggregate_fields: ResolverTypeWrapper<Risk_Score_Aggregate_Fields>;
  risk_score_avg_fields: ResolverTypeWrapper<Risk_Score_Avg_Fields>;
  risk_score_bool_exp: Risk_Score_Bool_Exp;
  risk_score_constraint: Risk_Score_Constraint;
  risk_score_insert_input: Risk_Score_Insert_Input;
  risk_score_max_fields: ResolverTypeWrapper<Risk_Score_Max_Fields>;
  risk_score_min_fields: ResolverTypeWrapper<Risk_Score_Min_Fields>;
  risk_score_obj_rel_insert_input: Risk_Score_Obj_Rel_Insert_Input;
  risk_score_on_conflict: Risk_Score_On_Conflict;
  risk_score_order_by: Risk_Score_Order_By;
  risk_score_select_column: Risk_Score_Select_Column;
  risk_score_stddev_fields: ResolverTypeWrapper<Risk_Score_Stddev_Fields>;
  risk_score_stddev_pop_fields: ResolverTypeWrapper<Risk_Score_Stddev_Pop_Fields>;
  risk_score_stddev_samp_fields: ResolverTypeWrapper<Risk_Score_Stddev_Samp_Fields>;
  risk_score_stream_cursor_input: Risk_Score_Stream_Cursor_Input;
  risk_score_stream_cursor_value_input: Risk_Score_Stream_Cursor_Value_Input;
  risk_score_sum_fields: ResolverTypeWrapper<Risk_Score_Sum_Fields>;
  risk_score_update_column: Risk_Score_Update_Column;
  risk_score_var_pop_fields: ResolverTypeWrapper<Risk_Score_Var_Pop_Fields>;
  risk_score_var_samp_fields: ResolverTypeWrapper<Risk_Score_Var_Samp_Fields>;
  risk_score_variance_fields: ResolverTypeWrapper<Risk_Score_Variance_Fields>;
  risk_scoring_model_enum: Risk_Scoring_Model_Enum;
  risk_scoring_model_enum_comparison_exp: Risk_Scoring_Model_Enum_Comparison_Exp;
  risk_select_column: Risk_Select_Column;
  risk_status_type_enum: Risk_Status_Type_Enum;
  risk_status_type_enum_comparison_exp: Risk_Status_Type_Enum_Comparison_Exp;
  risk_stddev_fields: ResolverTypeWrapper<Risk_Stddev_Fields>;
  risk_stddev_order_by: Risk_Stddev_Order_By;
  risk_stddev_pop_fields: ResolverTypeWrapper<Risk_Stddev_Pop_Fields>;
  risk_stddev_pop_order_by: Risk_Stddev_Pop_Order_By;
  risk_stddev_samp_fields: ResolverTypeWrapper<Risk_Stddev_Samp_Fields>;
  risk_stddev_samp_order_by: Risk_Stddev_Samp_Order_By;
  risk_stream_cursor_input: Risk_Stream_Cursor_Input;
  risk_stream_cursor_value_input: Risk_Stream_Cursor_Value_Input;
  risk_sum_fields: ResolverTypeWrapper<Risk_Sum_Fields>;
  risk_sum_order_by: Risk_Sum_Order_By;
  risk_treatment_type_enum: Risk_Treatment_Type_Enum;
  risk_treatment_type_enum_comparison_exp: Risk_Treatment_Type_Enum_Comparison_Exp;
  risk_update_column: Risk_Update_Column;
  risk_var_pop_fields: ResolverTypeWrapper<Risk_Var_Pop_Fields>;
  risk_var_pop_order_by: Risk_Var_Pop_Order_By;
  risk_var_samp_fields: ResolverTypeWrapper<Risk_Var_Samp_Fields>;
  risk_var_samp_order_by: Risk_Var_Samp_Order_By;
  risk_variance_fields: ResolverTypeWrapper<Risk_Variance_Fields>;
  risk_variance_order_by: Risk_Variance_Order_By;
  risksmart_indicator_parent_audit: ResolverTypeWrapper<Risksmart_Indicator_Parent_Audit>;
  risksmart_indicator_parent_audit_aggregate_order_by: Risksmart_Indicator_Parent_Audit_Aggregate_Order_By;
  risksmart_indicator_parent_audit_bool_exp: Risksmart_Indicator_Parent_Audit_Bool_Exp;
  risksmart_indicator_parent_audit_max_order_by: Risksmart_Indicator_Parent_Audit_Max_Order_By;
  risksmart_indicator_parent_audit_min_order_by: Risksmart_Indicator_Parent_Audit_Min_Order_By;
  risksmart_indicator_parent_audit_order_by: Risksmart_Indicator_Parent_Audit_Order_By;
  risksmart_indicator_parent_audit_select_column: Risksmart_Indicator_Parent_Audit_Select_Column;
  risksmart_indicator_parent_audit_stream_cursor_input: Risksmart_Indicator_Parent_Audit_Stream_Cursor_Input;
  risksmart_indicator_parent_audit_stream_cursor_value_input: Risksmart_Indicator_Parent_Audit_Stream_Cursor_Value_Input;
  risksmart_linked_item_audit: ResolverTypeWrapper<Risksmart_Linked_Item_Audit>;
  risksmart_linked_item_audit_bool_exp: Risksmart_Linked_Item_Audit_Bool_Exp;
  risksmart_linked_item_audit_order_by: Risksmart_Linked_Item_Audit_Order_By;
  risksmart_linked_item_audit_select_column: Risksmart_Linked_Item_Audit_Select_Column;
  risksmart_linked_item_audit_stream_cursor_input: Risksmart_Linked_Item_Audit_Stream_Cursor_Input;
  risksmart_linked_item_audit_stream_cursor_value_input: Risksmart_Linked_Item_Audit_Stream_Cursor_Value_Input;
  role_access: ResolverTypeWrapper<Role_Access>;
  role_access_bool_exp: Role_Access_Bool_Exp;
  role_access_order_by: Role_Access_Order_By;
  role_access_select_column: Role_Access_Select_Column;
  role_access_stream_cursor_input: Role_Access_Stream_Cursor_Input;
  role_access_stream_cursor_value_input: Role_Access_Stream_Cursor_Value_Input;
  schedule: ResolverTypeWrapper<Schedule>;
  schedule_bool_exp: Schedule_Bool_Exp;
  schedule_constraint: Schedule_Constraint;
  schedule_insert_input: Schedule_Insert_Input;
  schedule_obj_rel_insert_input: Schedule_Obj_Rel_Insert_Input;
  schedule_on_conflict: Schedule_On_Conflict;
  schedule_order_by: Schedule_Order_By;
  schedule_state: ResolverTypeWrapper<Schedule_State>;
  schedule_state_bool_exp: Schedule_State_Bool_Exp;
  schedule_state_order_by: Schedule_State_Order_By;
  schedule_update_column: Schedule_Update_Column;
  smallint: ResolverTypeWrapper<Scalars['smallint']['output']>;
  smallint_comparison_exp: Smallint_Comparison_Exp;
  subscription_root: ResolverTypeWrapper<{}>;
  tag: ResolverTypeWrapper<Tag>;
  tag_aggregate_order_by: Tag_Aggregate_Order_By;
  tag_arr_rel_insert_input: Tag_Arr_Rel_Insert_Input;
  tag_bool_exp: Tag_Bool_Exp;
  tag_constraint: Tag_Constraint;
  tag_insert_input: Tag_Insert_Input;
  tag_max_order_by: Tag_Max_Order_By;
  tag_min_order_by: Tag_Min_Order_By;
  tag_mutation_response: ResolverTypeWrapper<Tag_Mutation_Response>;
  tag_on_conflict: Tag_On_Conflict;
  tag_order_by: Tag_Order_By;
  tag_select_column: Tag_Select_Column;
  tag_type: ResolverTypeWrapper<Tag_Type>;
  tag_type_aggregate_order_by: Tag_Type_Aggregate_Order_By;
  tag_type_arr_rel_insert_input: Tag_Type_Arr_Rel_Insert_Input;
  tag_type_audit: ResolverTypeWrapper<Tag_Type_Audit>;
  tag_type_audit_bool_exp: Tag_Type_Audit_Bool_Exp;
  tag_type_audit_order_by: Tag_Type_Audit_Order_By;
  tag_type_audit_select_column: Tag_Type_Audit_Select_Column;
  tag_type_audit_stream_cursor_input: Tag_Type_Audit_Stream_Cursor_Input;
  tag_type_audit_stream_cursor_value_input: Tag_Type_Audit_Stream_Cursor_Value_Input;
  tag_type_bool_exp: Tag_Type_Bool_Exp;
  tag_type_constraint: Tag_Type_Constraint;
  tag_type_group: ResolverTypeWrapper<Tag_Type_Group>;
  tag_type_group_audit: ResolverTypeWrapper<Tag_Type_Group_Audit>;
  tag_type_group_audit_bool_exp: Tag_Type_Group_Audit_Bool_Exp;
  tag_type_group_audit_order_by: Tag_Type_Group_Audit_Order_By;
  tag_type_group_audit_select_column: Tag_Type_Group_Audit_Select_Column;
  tag_type_group_audit_stream_cursor_input: Tag_Type_Group_Audit_Stream_Cursor_Input;
  tag_type_group_audit_stream_cursor_value_input: Tag_Type_Group_Audit_Stream_Cursor_Value_Input;
  tag_type_group_bool_exp: Tag_Type_Group_Bool_Exp;
  tag_type_group_constraint: Tag_Type_Group_Constraint;
  tag_type_group_insert_input: Tag_Type_Group_Insert_Input;
  tag_type_group_mutation_response: ResolverTypeWrapper<Tag_Type_Group_Mutation_Response>;
  tag_type_group_obj_rel_insert_input: Tag_Type_Group_Obj_Rel_Insert_Input;
  tag_type_group_on_conflict: Tag_Type_Group_On_Conflict;
  tag_type_group_order_by: Tag_Type_Group_Order_By;
  tag_type_group_select_column: Tag_Type_Group_Select_Column;
  tag_type_group_set_input: Tag_Type_Group_Set_Input;
  tag_type_group_stream_cursor_input: Tag_Type_Group_Stream_Cursor_Input;
  tag_type_group_stream_cursor_value_input: Tag_Type_Group_Stream_Cursor_Value_Input;
  tag_type_group_update_column: Tag_Type_Group_Update_Column;
  tag_type_group_updates: Tag_Type_Group_Updates;
  tag_type_insert_input: Tag_Type_Insert_Input;
  tag_type_max_order_by: Tag_Type_Max_Order_By;
  tag_type_min_order_by: Tag_Type_Min_Order_By;
  tag_type_mutation_response: ResolverTypeWrapper<Tag_Type_Mutation_Response>;
  tag_type_obj_rel_insert_input: Tag_Type_Obj_Rel_Insert_Input;
  tag_type_on_conflict: Tag_Type_On_Conflict;
  tag_type_order_by: Tag_Type_Order_By;
  tag_type_pk_columns_input: Tag_Type_Pk_Columns_Input;
  tag_type_select_column: Tag_Type_Select_Column;
  tag_type_set_input: Tag_Type_Set_Input;
  tag_type_stream_cursor_input: Tag_Type_Stream_Cursor_Input;
  tag_type_stream_cursor_value_input: Tag_Type_Stream_Cursor_Value_Input;
  tag_type_update_column: Tag_Type_Update_Column;
  tag_type_updates: Tag_Type_Updates;
  tag_update_column: Tag_Update_Column;
  taxonomy: ResolverTypeWrapper<Taxonomy>;
  taxonomy_aggregate: ResolverTypeWrapper<Taxonomy_Aggregate>;
  taxonomy_aggregate_fields: ResolverTypeWrapper<Taxonomy_Aggregate_Fields>;
  taxonomy_append_input: Taxonomy_Append_Input;
  taxonomy_audit: ResolverTypeWrapper<Taxonomy_Audit>;
  taxonomy_audit_aggregate: ResolverTypeWrapper<Taxonomy_Audit_Aggregate>;
  taxonomy_audit_aggregate_fields: ResolverTypeWrapper<Taxonomy_Audit_Aggregate_Fields>;
  taxonomy_audit_bool_exp: Taxonomy_Audit_Bool_Exp;
  taxonomy_audit_max_fields: ResolverTypeWrapper<Taxonomy_Audit_Max_Fields>;
  taxonomy_audit_min_fields: ResolverTypeWrapper<Taxonomy_Audit_Min_Fields>;
  taxonomy_audit_order_by: Taxonomy_Audit_Order_By;
  taxonomy_audit_select_column: Taxonomy_Audit_Select_Column;
  taxonomy_audit_stream_cursor_input: Taxonomy_Audit_Stream_Cursor_Input;
  taxonomy_audit_stream_cursor_value_input: Taxonomy_Audit_Stream_Cursor_Value_Input;
  taxonomy_bool_exp: Taxonomy_Bool_Exp;
  taxonomy_constraint: Taxonomy_Constraint;
  taxonomy_delete_at_path_input: Taxonomy_Delete_At_Path_Input;
  taxonomy_delete_elem_input: Taxonomy_Delete_Elem_Input;
  taxonomy_delete_key_input: Taxonomy_Delete_Key_Input;
  taxonomy_insert_input: Taxonomy_Insert_Input;
  taxonomy_max_fields: ResolverTypeWrapper<Taxonomy_Max_Fields>;
  taxonomy_min_fields: ResolverTypeWrapper<Taxonomy_Min_Fields>;
  taxonomy_mutation_response: ResolverTypeWrapper<Taxonomy_Mutation_Response>;
  taxonomy_obj_rel_insert_input: Taxonomy_Obj_Rel_Insert_Input;
  taxonomy_on_conflict: Taxonomy_On_Conflict;
  taxonomy_order_by: Taxonomy_Order_By;
  taxonomy_org: ResolverTypeWrapper<Taxonomy_Org>;
  taxonomy_org_aggregate: ResolverTypeWrapper<Taxonomy_Org_Aggregate>;
  taxonomy_org_aggregate_bool_exp: Taxonomy_Org_Aggregate_Bool_Exp;
  taxonomy_org_aggregate_bool_exp_count: Taxonomy_Org_Aggregate_Bool_Exp_Count;
  taxonomy_org_aggregate_fields: ResolverTypeWrapper<Taxonomy_Org_Aggregate_Fields>;
  taxonomy_org_aggregate_order_by: Taxonomy_Org_Aggregate_Order_By;
  taxonomy_org_arr_rel_insert_input: Taxonomy_Org_Arr_Rel_Insert_Input;
  taxonomy_org_bool_exp: Taxonomy_Org_Bool_Exp;
  taxonomy_org_constraint: Taxonomy_Org_Constraint;
  taxonomy_org_insert_input: Taxonomy_Org_Insert_Input;
  taxonomy_org_max_fields: ResolverTypeWrapper<Taxonomy_Org_Max_Fields>;
  taxonomy_org_max_order_by: Taxonomy_Org_Max_Order_By;
  taxonomy_org_min_fields: ResolverTypeWrapper<Taxonomy_Org_Min_Fields>;
  taxonomy_org_min_order_by: Taxonomy_Org_Min_Order_By;
  taxonomy_org_mutation_response: ResolverTypeWrapper<Taxonomy_Org_Mutation_Response>;
  taxonomy_org_on_conflict: Taxonomy_Org_On_Conflict;
  taxonomy_org_order_by: Taxonomy_Org_Order_By;
  taxonomy_org_select_column: Taxonomy_Org_Select_Column;
  taxonomy_org_stream_cursor_input: Taxonomy_Org_Stream_Cursor_Input;
  taxonomy_org_stream_cursor_value_input: Taxonomy_Org_Stream_Cursor_Value_Input;
  taxonomy_org_update_column: Taxonomy_Org_Update_Column;
  taxonomy_pk_columns_input: Taxonomy_Pk_Columns_Input;
  taxonomy_prepend_input: Taxonomy_Prepend_Input;
  taxonomy_select_column: Taxonomy_Select_Column;
  taxonomy_set_input: Taxonomy_Set_Input;
  taxonomy_stream_cursor_input: Taxonomy_Stream_Cursor_Input;
  taxonomy_stream_cursor_value_input: Taxonomy_Stream_Cursor_Value_Input;
  taxonomy_update_column: Taxonomy_Update_Column;
  taxonomy_updates: Taxonomy_Updates;
  test_frequency_enum: Test_Frequency_Enum;
  test_frequency_enum_comparison_exp: Test_Frequency_Enum_Comparison_Exp;
  test_result: ResolverTypeWrapper<Test_Result>;
  test_result_aggregate: ResolverTypeWrapper<Test_Result_Aggregate>;
  test_result_aggregate_bool_exp: Test_Result_Aggregate_Bool_Exp;
  test_result_aggregate_bool_exp_count: Test_Result_Aggregate_Bool_Exp_Count;
  test_result_aggregate_fields: ResolverTypeWrapper<Test_Result_Aggregate_Fields>;
  test_result_aggregate_order_by: Test_Result_Aggregate_Order_By;
  test_result_arr_rel_insert_input: Test_Result_Arr_Rel_Insert_Input;
  test_result_audit: ResolverTypeWrapper<Test_Result_Audit>;
  test_result_audit_aggregate_order_by: Test_Result_Audit_Aggregate_Order_By;
  test_result_audit_avg_order_by: Test_Result_Audit_Avg_Order_By;
  test_result_audit_bool_exp: Test_Result_Audit_Bool_Exp;
  test_result_audit_max_order_by: Test_Result_Audit_Max_Order_By;
  test_result_audit_min_order_by: Test_Result_Audit_Min_Order_By;
  test_result_audit_order_by: Test_Result_Audit_Order_By;
  test_result_audit_select_column: Test_Result_Audit_Select_Column;
  test_result_audit_stddev_order_by: Test_Result_Audit_Stddev_Order_By;
  test_result_audit_stddev_pop_order_by: Test_Result_Audit_Stddev_Pop_Order_By;
  test_result_audit_stddev_samp_order_by: Test_Result_Audit_Stddev_Samp_Order_By;
  test_result_audit_stream_cursor_input: Test_Result_Audit_Stream_Cursor_Input;
  test_result_audit_stream_cursor_value_input: Test_Result_Audit_Stream_Cursor_Value_Input;
  test_result_audit_sum_order_by: Test_Result_Audit_Sum_Order_By;
  test_result_audit_var_pop_order_by: Test_Result_Audit_Var_Pop_Order_By;
  test_result_audit_var_samp_order_by: Test_Result_Audit_Var_Samp_Order_By;
  test_result_audit_variance_order_by: Test_Result_Audit_Variance_Order_By;
  test_result_avg_fields: ResolverTypeWrapper<Test_Result_Avg_Fields>;
  test_result_avg_order_by: Test_Result_Avg_Order_By;
  test_result_bool_exp: Test_Result_Bool_Exp;
  test_result_constraint: Test_Result_Constraint;
  test_result_insert_input: Test_Result_Insert_Input;
  test_result_max_fields: ResolverTypeWrapper<Test_Result_Max_Fields>;
  test_result_max_order_by: Test_Result_Max_Order_By;
  test_result_min_fields: ResolverTypeWrapper<Test_Result_Min_Fields>;
  test_result_min_order_by: Test_Result_Min_Order_By;
  test_result_mutation_response: ResolverTypeWrapper<Test_Result_Mutation_Response>;
  test_result_obj_rel_insert_input: Test_Result_Obj_Rel_Insert_Input;
  test_result_on_conflict: Test_Result_On_Conflict;
  test_result_order_by: Test_Result_Order_By;
  test_result_select_column: Test_Result_Select_Column;
  test_result_stddev_fields: ResolverTypeWrapper<Test_Result_Stddev_Fields>;
  test_result_stddev_order_by: Test_Result_Stddev_Order_By;
  test_result_stddev_pop_fields: ResolverTypeWrapper<Test_Result_Stddev_Pop_Fields>;
  test_result_stddev_pop_order_by: Test_Result_Stddev_Pop_Order_By;
  test_result_stddev_samp_fields: ResolverTypeWrapper<Test_Result_Stddev_Samp_Fields>;
  test_result_stddev_samp_order_by: Test_Result_Stddev_Samp_Order_By;
  test_result_stream_cursor_input: Test_Result_Stream_Cursor_Input;
  test_result_stream_cursor_value_input: Test_Result_Stream_Cursor_Value_Input;
  test_result_sum_fields: ResolverTypeWrapper<Test_Result_Sum_Fields>;
  test_result_sum_order_by: Test_Result_Sum_Order_By;
  test_result_update_column: Test_Result_Update_Column;
  test_result_var_pop_fields: ResolverTypeWrapper<Test_Result_Var_Pop_Fields>;
  test_result_var_pop_order_by: Test_Result_Var_Pop_Order_By;
  test_result_var_samp_fields: ResolverTypeWrapper<Test_Result_Var_Samp_Fields>;
  test_result_var_samp_order_by: Test_Result_Var_Samp_Order_By;
  test_result_variance_fields: ResolverTypeWrapper<Test_Result_Variance_Fields>;
  test_result_variance_order_by: Test_Result_Variance_Order_By;
  third_party: ResolverTypeWrapper<Third_Party>;
  third_party_append_input: Third_Party_Append_Input;
  third_party_bool_exp: Third_Party_Bool_Exp;
  third_party_constraint: Third_Party_Constraint;
  third_party_delete_at_path_input: Third_Party_Delete_At_Path_Input;
  third_party_delete_elem_input: Third_Party_Delete_Elem_Input;
  third_party_delete_key_input: Third_Party_Delete_Key_Input;
  third_party_inc_input: Third_Party_Inc_Input;
  third_party_insert_input: Third_Party_Insert_Input;
  third_party_mutation_response: ResolverTypeWrapper<Third_Party_Mutation_Response>;
  third_party_obj_rel_insert_input: Third_Party_Obj_Rel_Insert_Input;
  third_party_on_conflict: Third_Party_On_Conflict;
  third_party_order_by: Third_Party_Order_By;
  third_party_pk_columns_input: Third_Party_Pk_Columns_Input;
  third_party_prepend_input: Third_Party_Prepend_Input;
  third_party_response: ResolverTypeWrapper<Third_Party_Response>;
  third_party_response_append_input: Third_Party_Response_Append_Input;
  third_party_response_bool_exp: Third_Party_Response_Bool_Exp;
  third_party_response_constraint: Third_Party_Response_Constraint;
  third_party_response_delete_at_path_input: Third_Party_Response_Delete_At_Path_Input;
  third_party_response_delete_elem_input: Third_Party_Response_Delete_Elem_Input;
  third_party_response_delete_key_input: Third_Party_Response_Delete_Key_Input;
  third_party_response_insert_input: Third_Party_Response_Insert_Input;
  third_party_response_mutation_response: ResolverTypeWrapper<Third_Party_Response_Mutation_Response>;
  third_party_response_obj_rel_insert_input: Third_Party_Response_Obj_Rel_Insert_Input;
  third_party_response_on_conflict: Third_Party_Response_On_Conflict;
  third_party_response_order_by: Third_Party_Response_Order_By;
  third_party_response_pk_columns_input: Third_Party_Response_Pk_Columns_Input;
  third_party_response_prepend_input: Third_Party_Response_Prepend_Input;
  third_party_response_select_column: Third_Party_Response_Select_Column;
  third_party_response_set_input: Third_Party_Response_Set_Input;
  third_party_response_status: ResolverTypeWrapper<Third_Party_Response_Status>;
  third_party_response_status_bool_exp: Third_Party_Response_Status_Bool_Exp;
  third_party_response_status_enum: Third_Party_Response_Status_Enum;
  third_party_response_status_enum_comparison_exp: Third_Party_Response_Status_Enum_Comparison_Exp;
  third_party_response_status_order_by: Third_Party_Response_Status_Order_By;
  third_party_response_status_select_column: Third_Party_Response_Status_Select_Column;
  third_party_response_status_stream_cursor_input: Third_Party_Response_Status_Stream_Cursor_Input;
  third_party_response_status_stream_cursor_value_input: Third_Party_Response_Status_Stream_Cursor_Value_Input;
  third_party_response_stream_cursor_input: Third_Party_Response_Stream_Cursor_Input;
  third_party_response_stream_cursor_value_input: Third_Party_Response_Stream_Cursor_Value_Input;
  third_party_response_update_column: Third_Party_Response_Update_Column;
  third_party_response_updates: Third_Party_Response_Updates;
  third_party_select_column: Third_Party_Select_Column;
  third_party_set_input: Third_Party_Set_Input;
  third_party_status_enum: Third_Party_Status_Enum;
  third_party_status_enum_comparison_exp: Third_Party_Status_Enum_Comparison_Exp;
  third_party_stream_cursor_input: Third_Party_Stream_Cursor_Input;
  third_party_stream_cursor_value_input: Third_Party_Stream_Cursor_Value_Input;
  third_party_type_enum: Third_Party_Type_Enum;
  third_party_type_enum_comparison_exp: Third_Party_Type_Enum_Comparison_Exp;
  third_party_update_column: Third_Party_Update_Column;
  third_party_updates: Third_Party_Updates;
  timestamptz: ResolverTypeWrapper<Scalars['timestamptz']['output']>;
  timestamptz_comparison_exp: Timestamptz_Comparison_Exp;
  unit_of_time_enum: Unit_Of_Time_Enum;
  unit_of_time_enum_comparison_exp: Unit_Of_Time_Enum_Comparison_Exp;
  user: ResolverTypeWrapper<User>;
  user_aggregate: ResolverTypeWrapper<User_Aggregate>;
  user_aggregate_fields: ResolverTypeWrapper<User_Aggregate_Fields>;
  user_bool_exp: User_Bool_Exp;
  user_group: ResolverTypeWrapper<User_Group>;
  user_group_audit: ResolverTypeWrapper<User_Group_Audit>;
  user_group_audit_bool_exp: User_Group_Audit_Bool_Exp;
  user_group_audit_order_by: User_Group_Audit_Order_By;
  user_group_audit_select_column: User_Group_Audit_Select_Column;
  user_group_audit_stream_cursor_input: User_Group_Audit_Stream_Cursor_Input;
  user_group_audit_stream_cursor_value_input: User_Group_Audit_Stream_Cursor_Value_Input;
  user_group_bool_exp: User_Group_Bool_Exp;
  user_group_constraint: User_Group_Constraint;
  user_group_insert_input: User_Group_Insert_Input;
  user_group_mutation_response: ResolverTypeWrapper<User_Group_Mutation_Response>;
  user_group_obj_rel_insert_input: User_Group_Obj_Rel_Insert_Input;
  user_group_on_conflict: User_Group_On_Conflict;
  user_group_order_by: User_Group_Order_By;
  user_group_pk_columns_input: User_Group_Pk_Columns_Input;
  user_group_select_column: User_Group_Select_Column;
  user_group_set_input: User_Group_Set_Input;
  user_group_stream_cursor_input: User_Group_Stream_Cursor_Input;
  user_group_stream_cursor_value_input: User_Group_Stream_Cursor_Value_Input;
  user_group_update_column: User_Group_Update_Column;
  user_group_updates: User_Group_Updates;
  user_group_user: ResolverTypeWrapper<User_Group_User>;
  user_group_user_aggregate: ResolverTypeWrapper<User_Group_User_Aggregate>;
  user_group_user_aggregate_bool_exp: User_Group_User_Aggregate_Bool_Exp;
  user_group_user_aggregate_bool_exp_count: User_Group_User_Aggregate_Bool_Exp_Count;
  user_group_user_aggregate_fields: ResolverTypeWrapper<User_Group_User_Aggregate_Fields>;
  user_group_user_aggregate_order_by: User_Group_User_Aggregate_Order_By;
  user_group_user_arr_rel_insert_input: User_Group_User_Arr_Rel_Insert_Input;
  user_group_user_audit: ResolverTypeWrapper<User_Group_User_Audit>;
  user_group_user_audit_bool_exp: User_Group_User_Audit_Bool_Exp;
  user_group_user_audit_order_by: User_Group_User_Audit_Order_By;
  user_group_user_audit_select_column: User_Group_User_Audit_Select_Column;
  user_group_user_audit_stream_cursor_input: User_Group_User_Audit_Stream_Cursor_Input;
  user_group_user_audit_stream_cursor_value_input: User_Group_User_Audit_Stream_Cursor_Value_Input;
  user_group_user_bool_exp: User_Group_User_Bool_Exp;
  user_group_user_constraint: User_Group_User_Constraint;
  user_group_user_insert_input: User_Group_User_Insert_Input;
  user_group_user_max_fields: ResolverTypeWrapper<User_Group_User_Max_Fields>;
  user_group_user_max_order_by: User_Group_User_Max_Order_By;
  user_group_user_min_fields: ResolverTypeWrapper<User_Group_User_Min_Fields>;
  user_group_user_min_order_by: User_Group_User_Min_Order_By;
  user_group_user_mutation_response: ResolverTypeWrapper<User_Group_User_Mutation_Response>;
  user_group_user_on_conflict: User_Group_User_On_Conflict;
  user_group_user_order_by: User_Group_User_Order_By;
  user_group_user_select_column: User_Group_User_Select_Column;
  user_group_user_stream_cursor_input: User_Group_User_Stream_Cursor_Input;
  user_group_user_stream_cursor_value_input: User_Group_User_Stream_Cursor_Value_Input;
  user_group_user_update_column: User_Group_User_Update_Column;
  user_max_fields: ResolverTypeWrapper<User_Max_Fields>;
  user_min_fields: ResolverTypeWrapper<User_Min_Fields>;
  user_order_by: User_Order_By;
  user_search_preferences: ResolverTypeWrapper<User_Search_Preferences>;
  user_search_preferences_audit: ResolverTypeWrapper<User_Search_Preferences_Audit>;
  user_search_preferences_audit_bool_exp: User_Search_Preferences_Audit_Bool_Exp;
  user_search_preferences_audit_order_by: User_Search_Preferences_Audit_Order_By;
  user_search_preferences_audit_select_column: User_Search_Preferences_Audit_Select_Column;
  user_search_preferences_audit_stream_cursor_input: User_Search_Preferences_Audit_Stream_Cursor_Input;
  user_search_preferences_audit_stream_cursor_value_input: User_Search_Preferences_Audit_Stream_Cursor_Value_Input;
  user_search_preferences_bool_exp: User_Search_Preferences_Bool_Exp;
  user_search_preferences_constraint: User_Search_Preferences_Constraint;
  user_search_preferences_insert_input: User_Search_Preferences_Insert_Input;
  user_search_preferences_mutation_response: ResolverTypeWrapper<User_Search_Preferences_Mutation_Response>;
  user_search_preferences_on_conflict: User_Search_Preferences_On_Conflict;
  user_search_preferences_order_by: User_Search_Preferences_Order_By;
  user_search_preferences_select_column: User_Search_Preferences_Select_Column;
  user_search_preferences_set_input: User_Search_Preferences_Set_Input;
  user_search_preferences_stream_cursor_input: User_Search_Preferences_Stream_Cursor_Input;
  user_search_preferences_stream_cursor_value_input: User_Search_Preferences_Stream_Cursor_Value_Input;
  user_search_preferences_update_column: User_Search_Preferences_Update_Column;
  user_search_preferences_updates: User_Search_Preferences_Updates;
  user_select_column: User_Select_Column;
  user_status_enum: User_Status_Enum;
  user_status_enum_comparison_exp: User_Status_Enum_Comparison_Exp;
  user_stream_cursor_input: User_Stream_Cursor_Input;
  user_stream_cursor_value_input: User_Stream_Cursor_Value_Input;
  user_table_preferences: ResolverTypeWrapper<User_Table_Preferences>;
  user_table_preferences_append_input: User_Table_Preferences_Append_Input;
  user_table_preferences_bool_exp: User_Table_Preferences_Bool_Exp;
  user_table_preferences_constraint: User_Table_Preferences_Constraint;
  user_table_preferences_delete_at_path_input: User_Table_Preferences_Delete_At_Path_Input;
  user_table_preferences_delete_elem_input: User_Table_Preferences_Delete_Elem_Input;
  user_table_preferences_delete_key_input: User_Table_Preferences_Delete_Key_Input;
  user_table_preferences_insert_input: User_Table_Preferences_Insert_Input;
  user_table_preferences_mutation_response: ResolverTypeWrapper<User_Table_Preferences_Mutation_Response>;
  user_table_preferences_on_conflict: User_Table_Preferences_On_Conflict;
  user_table_preferences_order_by: User_Table_Preferences_Order_By;
  user_table_preferences_prepend_input: User_Table_Preferences_Prepend_Input;
  user_table_preferences_select_column: User_Table_Preferences_Select_Column;
  user_table_preferences_set_input: User_Table_Preferences_Set_Input;
  user_table_preferences_stream_cursor_input: User_Table_Preferences_Stream_Cursor_Input;
  user_table_preferences_stream_cursor_value_input: User_Table_Preferences_Stream_Cursor_Value_Input;
  user_table_preferences_update_column: User_Table_Preferences_Update_Column;
  user_table_preferences_updates: User_Table_Preferences_Updates;
  uuid: ResolverTypeWrapper<Scalars['uuid']['output']>;
  uuid_comparison_exp: Uuid_Comparison_Exp;
  version_status_enum: Version_Status_Enum;
  version_status_enum_comparison_exp: Version_Status_Enum_Comparison_Exp;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Boolean_comparison_exp: Boolean_Comparison_Exp;
  Boolean: Scalars['Boolean']['output'];
  DataImportStartImportOutput: DataImportStartImportOutput;
  String: Scalars['String']['output'];
  DataImportValidateOutput: DataImportValidateOutput;
  DisconnectSlackOutput: DisconnectSlackOutput;
  GenericMutationOutput: GenericMutationOutput;
  Int: Scalars['Int']['output'];
  IdOutput: IdOutput;
  InsertChildControlInput: InsertChildControlInput;
  InsertChildDocumentAssessmentResultOutput: InsertChildDocumentAssessmentResultOutput;
  InsertChildDocumentInput: InsertChildDocumentInput;
  InsertChildIndicatorInput: InsertChildIndicatorInput;
  InsertChildObligationAssessmentResultOutput: InsertChildObligationAssessmentResultOutput;
  InsertChildObligationInput: InsertChildObligationInput;
  InsertChildRiskAssessmentResultOutput: InsertChildRiskAssessmentResultOutput;
  InsertChildRiskInput: InsertChildRiskInput;
  InsertImpactRatingPairInput: InsertImpactRatingPairInput;
  InsertInternalAuditInput: InsertInternalAuditInput;
  Int_comparison_exp: Int_Comparison_Exp;
  LinkItemRecordOutput: LinkItemRecordOutput;
  LinkItemsOutput: LinkItemsOutput;
  NotificationPreferencesOutput: NotificationPreferencesOutput;
  RecalculateAppetitesOutput: RecalculateAppetitesOutput;
  RecalculateRiskScoresOutput: RecalculateRiskScoresOutput;
  ReportDataOutput: ReportDataOutput;
  RoleOutput: RoleOutput;
  ScheduleInput: ScheduleInput;
  SlackNotificationConnectionOutput: SlackNotificationConnectionOutput;
  String_array_comparison_exp: String_Array_Comparison_Exp;
  String_comparison_exp: String_Comparison_Exp;
  UnlinkItemsOutput: UnlinkItemsOutput;
  UpdateApproverResponsesInput: UpdateApproverResponsesInput;
  UpdateChildControlInput: UpdateChildControlInput;
  UpdateChildDocumentInput: UpdateChildDocumentInput;
  UpdateChildIndicatorInput: UpdateChildIndicatorInput;
  UpdateChildObligationInput: UpdateChildObligationInput;
  UpdateChildRiskInput: UpdateChildRiskInput;
  UpdateInternalAuditInput: UpdateInternalAuditInput;
  UpdateNotificationPreferencesInput: UpdateNotificationPreferencesInput;
  UpdateNotificationPreferencesOutput: UpdateNotificationPreferencesOutput;
  UpdateTestResultInput: UpdateTestResultInput;
  UpdateUserRolesMutationOutput: UpdateUserRolesMutationOutput;
  acceptance: Acceptance;
  acceptance_aggregate: Acceptance_Aggregate;
  acceptance_aggregate_fields: Acceptance_Aggregate_Fields;
  acceptance_audit: Acceptance_Audit;
  acceptance_audit_bool_exp: Acceptance_Audit_Bool_Exp;
  acceptance_audit_order_by: Acceptance_Audit_Order_By;
  acceptance_audit_stream_cursor_input: Acceptance_Audit_Stream_Cursor_Input;
  acceptance_audit_stream_cursor_value_input: Acceptance_Audit_Stream_Cursor_Value_Input;
  acceptance_avg_fields: Acceptance_Avg_Fields;
  Float: Scalars['Float']['output'];
  acceptance_bool_exp: Acceptance_Bool_Exp;
  acceptance_insert_input: Acceptance_Insert_Input;
  acceptance_max_fields: Acceptance_Max_Fields;
  acceptance_min_fields: Acceptance_Min_Fields;
  acceptance_obj_rel_insert_input: Acceptance_Obj_Rel_Insert_Input;
  acceptance_on_conflict: Acceptance_On_Conflict;
  acceptance_order_by: Acceptance_Order_By;
  acceptance_parent: Acceptance_Parent;
  acceptance_parent_aggregate: Acceptance_Parent_Aggregate;
  acceptance_parent_aggregate_bool_exp: Acceptance_Parent_Aggregate_Bool_Exp;
  acceptance_parent_aggregate_bool_exp_count: Acceptance_Parent_Aggregate_Bool_Exp_Count;
  acceptance_parent_aggregate_fields: Acceptance_Parent_Aggregate_Fields;
  acceptance_parent_aggregate_order_by: Acceptance_Parent_Aggregate_Order_By;
  acceptance_parent_arr_rel_insert_input: Acceptance_Parent_Arr_Rel_Insert_Input;
  acceptance_parent_audit: Acceptance_Parent_Audit;
  acceptance_parent_audit_aggregate_order_by: Acceptance_Parent_Audit_Aggregate_Order_By;
  acceptance_parent_audit_bool_exp: Acceptance_Parent_Audit_Bool_Exp;
  acceptance_parent_audit_max_order_by: Acceptance_Parent_Audit_Max_Order_By;
  acceptance_parent_audit_min_order_by: Acceptance_Parent_Audit_Min_Order_By;
  acceptance_parent_audit_order_by: Acceptance_Parent_Audit_Order_By;
  acceptance_parent_audit_stream_cursor_input: Acceptance_Parent_Audit_Stream_Cursor_Input;
  acceptance_parent_audit_stream_cursor_value_input: Acceptance_Parent_Audit_Stream_Cursor_Value_Input;
  acceptance_parent_bool_exp: Acceptance_Parent_Bool_Exp;
  acceptance_parent_insert_input: Acceptance_Parent_Insert_Input;
  acceptance_parent_max_fields: Acceptance_Parent_Max_Fields;
  acceptance_parent_max_order_by: Acceptance_Parent_Max_Order_By;
  acceptance_parent_min_fields: Acceptance_Parent_Min_Fields;
  acceptance_parent_min_order_by: Acceptance_Parent_Min_Order_By;
  acceptance_parent_mutation_response: Acceptance_Parent_Mutation_Response;
  acceptance_parent_on_conflict: Acceptance_Parent_On_Conflict;
  acceptance_parent_order_by: Acceptance_Parent_Order_By;
  acceptance_parent_stream_cursor_input: Acceptance_Parent_Stream_Cursor_Input;
  acceptance_parent_stream_cursor_value_input: Acceptance_Parent_Stream_Cursor_Value_Input;
  acceptance_status_enum_comparison_exp: Acceptance_Status_Enum_Comparison_Exp;
  acceptance_stddev_fields: Acceptance_Stddev_Fields;
  acceptance_stddev_pop_fields: Acceptance_Stddev_Pop_Fields;
  acceptance_stddev_samp_fields: Acceptance_Stddev_Samp_Fields;
  acceptance_stream_cursor_input: Acceptance_Stream_Cursor_Input;
  acceptance_stream_cursor_value_input: Acceptance_Stream_Cursor_Value_Input;
  acceptance_sum_fields: Acceptance_Sum_Fields;
  acceptance_var_pop_fields: Acceptance_Var_Pop_Fields;
  acceptance_var_samp_fields: Acceptance_Var_Samp_Fields;
  acceptance_variance_fields: Acceptance_Variance_Fields;
  access_type_enum_comparison_exp: Access_Type_Enum_Comparison_Exp;
  action: Action;
  action_aggregate: Action_Aggregate;
  action_aggregate_fields: Action_Aggregate_Fields;
  action_audit: Action_Audit;
  action_audit_aggregate: Action_Audit_Aggregate;
  action_audit_aggregate_fields: Action_Audit_Aggregate_Fields;
  action_audit_avg_fields: Action_Audit_Avg_Fields;
  action_audit_bool_exp: Action_Audit_Bool_Exp;
  action_audit_max_fields: Action_Audit_Max_Fields;
  action_audit_min_fields: Action_Audit_Min_Fields;
  action_audit_order_by: Action_Audit_Order_By;
  action_audit_stddev_fields: Action_Audit_Stddev_Fields;
  action_audit_stddev_pop_fields: Action_Audit_Stddev_Pop_Fields;
  action_audit_stddev_samp_fields: Action_Audit_Stddev_Samp_Fields;
  action_audit_stream_cursor_input: Action_Audit_Stream_Cursor_Input;
  action_audit_stream_cursor_value_input: Action_Audit_Stream_Cursor_Value_Input;
  action_audit_sum_fields: Action_Audit_Sum_Fields;
  action_audit_var_pop_fields: Action_Audit_Var_Pop_Fields;
  action_audit_var_samp_fields: Action_Audit_Var_Samp_Fields;
  action_audit_variance_fields: Action_Audit_Variance_Fields;
  action_avg_fields: Action_Avg_Fields;
  action_bool_exp: Action_Bool_Exp;
  action_insert_input: Action_Insert_Input;
  action_max_fields: Action_Max_Fields;
  action_min_fields: Action_Min_Fields;
  action_obj_rel_insert_input: Action_Obj_Rel_Insert_Input;
  action_on_conflict: Action_On_Conflict;
  action_order_by: Action_Order_By;
  action_parent: Action_Parent;
  action_parent_aggregate: Action_Parent_Aggregate;
  action_parent_aggregate_bool_exp: Action_Parent_Aggregate_Bool_Exp;
  action_parent_aggregate_bool_exp_count: Action_Parent_Aggregate_Bool_Exp_Count;
  action_parent_aggregate_fields: Action_Parent_Aggregate_Fields;
  action_parent_aggregate_order_by: Action_Parent_Aggregate_Order_By;
  action_parent_arr_rel_insert_input: Action_Parent_Arr_Rel_Insert_Input;
  action_parent_audit: Action_Parent_Audit;
  action_parent_audit_aggregate_order_by: Action_Parent_Audit_Aggregate_Order_By;
  action_parent_audit_bool_exp: Action_Parent_Audit_Bool_Exp;
  action_parent_audit_max_order_by: Action_Parent_Audit_Max_Order_By;
  action_parent_audit_min_order_by: Action_Parent_Audit_Min_Order_By;
  action_parent_audit_order_by: Action_Parent_Audit_Order_By;
  action_parent_audit_stream_cursor_input: Action_Parent_Audit_Stream_Cursor_Input;
  action_parent_audit_stream_cursor_value_input: Action_Parent_Audit_Stream_Cursor_Value_Input;
  action_parent_bool_exp: Action_Parent_Bool_Exp;
  action_parent_insert_input: Action_Parent_Insert_Input;
  action_parent_max_fields: Action_Parent_Max_Fields;
  action_parent_max_order_by: Action_Parent_Max_Order_By;
  action_parent_min_fields: Action_Parent_Min_Fields;
  action_parent_min_order_by: Action_Parent_Min_Order_By;
  action_parent_mutation_response: Action_Parent_Mutation_Response;
  action_parent_on_conflict: Action_Parent_On_Conflict;
  action_parent_order_by: Action_Parent_Order_By;
  action_parent_stream_cursor_input: Action_Parent_Stream_Cursor_Input;
  action_parent_stream_cursor_value_input: Action_Parent_Stream_Cursor_Value_Input;
  action_status_enum_comparison_exp: Action_Status_Enum_Comparison_Exp;
  action_stddev_fields: Action_Stddev_Fields;
  action_stddev_pop_fields: Action_Stddev_Pop_Fields;
  action_stddev_samp_fields: Action_Stddev_Samp_Fields;
  action_stream_cursor_input: Action_Stream_Cursor_Input;
  action_stream_cursor_value_input: Action_Stream_Cursor_Value_Input;
  action_sum_fields: Action_Sum_Fields;
  action_update: Action_Update;
  action_update_aggregate: Action_Update_Aggregate;
  action_update_aggregate_bool_exp: Action_Update_Aggregate_Bool_Exp;
  action_update_aggregate_bool_exp_count: Action_Update_Aggregate_Bool_Exp_Count;
  action_update_aggregate_fields: Action_Update_Aggregate_Fields;
  action_update_aggregate_order_by: Action_Update_Aggregate_Order_By;
  action_update_append_input: Action_Update_Append_Input;
  action_update_arr_rel_insert_input: Action_Update_Arr_Rel_Insert_Input;
  action_update_audit: Action_Update_Audit;
  action_update_audit_bool_exp: Action_Update_Audit_Bool_Exp;
  action_update_audit_order_by: Action_Update_Audit_Order_By;
  action_update_audit_stream_cursor_input: Action_Update_Audit_Stream_Cursor_Input;
  action_update_audit_stream_cursor_value_input: Action_Update_Audit_Stream_Cursor_Value_Input;
  action_update_bool_exp: Action_Update_Bool_Exp;
  action_update_delete_at_path_input: Action_Update_Delete_At_Path_Input;
  action_update_delete_elem_input: Action_Update_Delete_Elem_Input;
  action_update_delete_key_input: Action_Update_Delete_Key_Input;
  action_update_insert_input: Action_Update_Insert_Input;
  action_update_max_fields: Action_Update_Max_Fields;
  action_update_max_order_by: Action_Update_Max_Order_By;
  action_update_min_fields: Action_Update_Min_Fields;
  action_update_min_order_by: Action_Update_Min_Order_By;
  action_update_mutation_response: Action_Update_Mutation_Response;
  action_update_on_conflict: Action_Update_On_Conflict;
  action_update_order_by: Action_Update_Order_By;
  action_update_pk_columns_input: Action_Update_Pk_Columns_Input;
  action_update_prepend_input: Action_Update_Prepend_Input;
  action_update_set_input: Action_Update_Set_Input;
  action_update_stream_cursor_input: Action_Update_Stream_Cursor_Input;
  action_update_stream_cursor_value_input: Action_Update_Stream_Cursor_Value_Input;
  action_update_summary: Action_Update_Summary;
  action_update_summary_aggregate: Action_Update_Summary_Aggregate;
  action_update_summary_aggregate_fields: Action_Update_Summary_Aggregate_Fields;
  action_update_summary_avg_fields: Action_Update_Summary_Avg_Fields;
  action_update_summary_bool_exp: Action_Update_Summary_Bool_Exp;
  action_update_summary_max_fields: Action_Update_Summary_Max_Fields;
  action_update_summary_min_fields: Action_Update_Summary_Min_Fields;
  action_update_summary_order_by: Action_Update_Summary_Order_By;
  action_update_summary_stddev_fields: Action_Update_Summary_Stddev_Fields;
  action_update_summary_stddev_pop_fields: Action_Update_Summary_Stddev_Pop_Fields;
  action_update_summary_stddev_samp_fields: Action_Update_Summary_Stddev_Samp_Fields;
  action_update_summary_stream_cursor_input: Action_Update_Summary_Stream_Cursor_Input;
  action_update_summary_stream_cursor_value_input: Action_Update_Summary_Stream_Cursor_Value_Input;
  action_update_summary_sum_fields: Action_Update_Summary_Sum_Fields;
  action_update_summary_var_pop_fields: Action_Update_Summary_Var_Pop_Fields;
  action_update_summary_var_samp_fields: Action_Update_Summary_Var_Samp_Fields;
  action_update_summary_variance_fields: Action_Update_Summary_Variance_Fields;
  action_update_updates: Action_Update_Updates;
  action_var_pop_fields: Action_Var_Pop_Fields;
  action_var_samp_fields: Action_Var_Samp_Fields;
  action_variance_fields: Action_Variance_Fields;
  aggregation_org: Aggregation_Org;
  aggregation_org_bool_exp: Aggregation_Org_Bool_Exp;
  aggregation_org_order_by: Aggregation_Org_Order_By;
  aggregation_org_stream_cursor_input: Aggregation_Org_Stream_Cursor_Input;
  aggregation_org_stream_cursor_value_input: Aggregation_Org_Stream_Cursor_Value_Input;
  ancestor_contributor: Ancestor_Contributor;
  ancestor_contributor_aggregate_order_by: Ancestor_Contributor_Aggregate_Order_By;
  ancestor_contributor_bool_exp: Ancestor_Contributor_Bool_Exp;
  ancestor_contributor_max_order_by: Ancestor_Contributor_Max_Order_By;
  ancestor_contributor_min_order_by: Ancestor_Contributor_Min_Order_By;
  ancestor_contributor_order_by: Ancestor_Contributor_Order_By;
  ancestor_contributor_stream_cursor_input: Ancestor_Contributor_Stream_Cursor_Input;
  ancestor_contributor_stream_cursor_value_input: Ancestor_Contributor_Stream_Cursor_Value_Input;
  appetite: Appetite;
  appetite_aggregate: Appetite_Aggregate;
  appetite_aggregate_fields: Appetite_Aggregate_Fields;
  appetite_append_input: Appetite_Append_Input;
  appetite_audit: Appetite_Audit;
  appetite_audit_bool_exp: Appetite_Audit_Bool_Exp;
  appetite_audit_order_by: Appetite_Audit_Order_By;
  appetite_audit_stream_cursor_input: Appetite_Audit_Stream_Cursor_Input;
  appetite_audit_stream_cursor_value_input: Appetite_Audit_Stream_Cursor_Value_Input;
  appetite_avg_fields: Appetite_Avg_Fields;
  appetite_bool_exp: Appetite_Bool_Exp;
  appetite_delete_at_path_input: Appetite_Delete_At_Path_Input;
  appetite_delete_elem_input: Appetite_Delete_Elem_Input;
  appetite_delete_key_input: Appetite_Delete_Key_Input;
  appetite_inc_input: Appetite_Inc_Input;
  appetite_insert_input: Appetite_Insert_Input;
  appetite_max_fields: Appetite_Max_Fields;
  appetite_min_fields: Appetite_Min_Fields;
  appetite_model_enum_comparison_exp: Appetite_Model_Enum_Comparison_Exp;
  appetite_mutation_response: Appetite_Mutation_Response;
  appetite_obj_rel_insert_input: Appetite_Obj_Rel_Insert_Input;
  appetite_on_conflict: Appetite_On_Conflict;
  appetite_order_by: Appetite_Order_By;
  appetite_parent: Appetite_Parent;
  appetite_parent_aggregate: Appetite_Parent_Aggregate;
  appetite_parent_aggregate_bool_exp: Appetite_Parent_Aggregate_Bool_Exp;
  appetite_parent_aggregate_bool_exp_count: Appetite_Parent_Aggregate_Bool_Exp_Count;
  appetite_parent_aggregate_fields: Appetite_Parent_Aggregate_Fields;
  appetite_parent_aggregate_order_by: Appetite_Parent_Aggregate_Order_By;
  appetite_parent_arr_rel_insert_input: Appetite_Parent_Arr_Rel_Insert_Input;
  appetite_parent_audit: Appetite_Parent_Audit;
  appetite_parent_audit_aggregate: Appetite_Parent_Audit_Aggregate;
  appetite_parent_audit_aggregate_bool_exp: Appetite_Parent_Audit_Aggregate_Bool_Exp;
  appetite_parent_audit_aggregate_bool_exp_count: Appetite_Parent_Audit_Aggregate_Bool_Exp_Count;
  appetite_parent_audit_aggregate_fields: Appetite_Parent_Audit_Aggregate_Fields;
  appetite_parent_audit_aggregate_order_by: Appetite_Parent_Audit_Aggregate_Order_By;
  appetite_parent_audit_bool_exp: Appetite_Parent_Audit_Bool_Exp;
  appetite_parent_audit_max_fields: Appetite_Parent_Audit_Max_Fields;
  appetite_parent_audit_max_order_by: Appetite_Parent_Audit_Max_Order_By;
  appetite_parent_audit_min_fields: Appetite_Parent_Audit_Min_Fields;
  appetite_parent_audit_min_order_by: Appetite_Parent_Audit_Min_Order_By;
  appetite_parent_audit_order_by: Appetite_Parent_Audit_Order_By;
  appetite_parent_audit_stream_cursor_input: Appetite_Parent_Audit_Stream_Cursor_Input;
  appetite_parent_audit_stream_cursor_value_input: Appetite_Parent_Audit_Stream_Cursor_Value_Input;
  appetite_parent_bool_exp: Appetite_Parent_Bool_Exp;
  appetite_parent_insert_input: Appetite_Parent_Insert_Input;
  appetite_parent_max_fields: Appetite_Parent_Max_Fields;
  appetite_parent_max_order_by: Appetite_Parent_Max_Order_By;
  appetite_parent_min_fields: Appetite_Parent_Min_Fields;
  appetite_parent_min_order_by: Appetite_Parent_Min_Order_By;
  appetite_parent_mutation_response: Appetite_Parent_Mutation_Response;
  appetite_parent_on_conflict: Appetite_Parent_On_Conflict;
  appetite_parent_order_by: Appetite_Parent_Order_By;
  appetite_parent_stream_cursor_input: Appetite_Parent_Stream_Cursor_Input;
  appetite_parent_stream_cursor_value_input: Appetite_Parent_Stream_Cursor_Value_Input;
  appetite_pk_columns_input: Appetite_Pk_Columns_Input;
  appetite_prepend_input: Appetite_Prepend_Input;
  appetite_set_input: Appetite_Set_Input;
  appetite_stddev_fields: Appetite_Stddev_Fields;
  appetite_stddev_pop_fields: Appetite_Stddev_Pop_Fields;
  appetite_stddev_samp_fields: Appetite_Stddev_Samp_Fields;
  appetite_stream_cursor_input: Appetite_Stream_Cursor_Input;
  appetite_stream_cursor_value_input: Appetite_Stream_Cursor_Value_Input;
  appetite_sum_fields: Appetite_Sum_Fields;
  appetite_type_enum_comparison_exp: Appetite_Type_Enum_Comparison_Exp;
  appetite_updates: Appetite_Updates;
  appetite_var_pop_fields: Appetite_Var_Pop_Fields;
  appetite_var_samp_fields: Appetite_Var_Samp_Fields;
  appetite_variance_fields: Appetite_Variance_Fields;
  approval: Approval;
  approval_aggregate_order_by: Approval_Aggregate_Order_By;
  approval_arr_rel_insert_input: Approval_Arr_Rel_Insert_Input;
  approval_audit: Approval_Audit;
  approval_audit_bool_exp: Approval_Audit_Bool_Exp;
  approval_audit_order_by: Approval_Audit_Order_By;
  approval_audit_stream_cursor_input: Approval_Audit_Stream_Cursor_Input;
  approval_audit_stream_cursor_value_input: Approval_Audit_Stream_Cursor_Value_Input;
  approval_bool_exp: Approval_Bool_Exp;
  approval_in_flight_edit_rule_enum_comparison_exp: Approval_In_Flight_Edit_Rule_Enum_Comparison_Exp;
  approval_insert_input: Approval_Insert_Input;
  approval_level: Approval_Level;
  approval_level_aggregate_order_by: Approval_Level_Aggregate_Order_By;
  approval_level_arr_rel_insert_input: Approval_Level_Arr_Rel_Insert_Input;
  approval_level_audit: Approval_Level_Audit;
  approval_level_audit_bool_exp: Approval_Level_Audit_Bool_Exp;
  approval_level_audit_order_by: Approval_Level_Audit_Order_By;
  approval_level_audit_stream_cursor_input: Approval_Level_Audit_Stream_Cursor_Input;
  approval_level_audit_stream_cursor_value_input: Approval_Level_Audit_Stream_Cursor_Value_Input;
  approval_level_avg_order_by: Approval_Level_Avg_Order_By;
  approval_level_bool_exp: Approval_Level_Bool_Exp;
  approval_level_inc_input: Approval_Level_Inc_Input;
  approval_level_insert_input: Approval_Level_Insert_Input;
  approval_level_max_order_by: Approval_Level_Max_Order_By;
  approval_level_min_order_by: Approval_Level_Min_Order_By;
  approval_level_mutation_response: Approval_Level_Mutation_Response;
  approval_level_obj_rel_insert_input: Approval_Level_Obj_Rel_Insert_Input;
  approval_level_on_conflict: Approval_Level_On_Conflict;
  approval_level_order_by: Approval_Level_Order_By;
  approval_level_pk_columns_input: Approval_Level_Pk_Columns_Input;
  approval_level_set_input: Approval_Level_Set_Input;
  approval_level_stddev_order_by: Approval_Level_Stddev_Order_By;
  approval_level_stddev_pop_order_by: Approval_Level_Stddev_Pop_Order_By;
  approval_level_stddev_samp_order_by: Approval_Level_Stddev_Samp_Order_By;
  approval_level_stream_cursor_input: Approval_Level_Stream_Cursor_Input;
  approval_level_stream_cursor_value_input: Approval_Level_Stream_Cursor_Value_Input;
  approval_level_sum_order_by: Approval_Level_Sum_Order_By;
  approval_level_updates: Approval_Level_Updates;
  approval_level_var_pop_order_by: Approval_Level_Var_Pop_Order_By;
  approval_level_var_samp_order_by: Approval_Level_Var_Samp_Order_By;
  approval_level_variance_order_by: Approval_Level_Variance_Order_By;
  approval_max_order_by: Approval_Max_Order_By;
  approval_min_order_by: Approval_Min_Order_By;
  approval_mutation_response: Approval_Mutation_Response;
  approval_obj_rel_insert_input: Approval_Obj_Rel_Insert_Input;
  approval_on_conflict: Approval_On_Conflict;
  approval_order_by: Approval_Order_By;
  approval_pk_columns_input: Approval_Pk_Columns_Input;
  approval_rule_type_enum_comparison_exp: Approval_Rule_Type_Enum_Comparison_Exp;
  approval_set_input: Approval_Set_Input;
  approval_status_enum_comparison_exp: Approval_Status_Enum_Comparison_Exp;
  approval_stream_cursor_input: Approval_Stream_Cursor_Input;
  approval_stream_cursor_value_input: Approval_Stream_Cursor_Value_Input;
  approval_updates: Approval_Updates;
  approver: Approver;
  approver_aggregate: Approver_Aggregate;
  approver_aggregate_bool_exp: Approver_Aggregate_Bool_Exp;
  approver_aggregate_bool_exp_bool_and: Approver_Aggregate_Bool_Exp_Bool_And;
  approver_aggregate_bool_exp_bool_or: Approver_Aggregate_Bool_Exp_Bool_Or;
  approver_aggregate_bool_exp_count: Approver_Aggregate_Bool_Exp_Count;
  approver_aggregate_fields: Approver_Aggregate_Fields;
  approver_aggregate_order_by: Approver_Aggregate_Order_By;
  approver_arr_rel_insert_input: Approver_Arr_Rel_Insert_Input;
  approver_audit: Approver_Audit;
  approver_audit_bool_exp: Approver_Audit_Bool_Exp;
  approver_audit_order_by: Approver_Audit_Order_By;
  approver_audit_stream_cursor_input: Approver_Audit_Stream_Cursor_Input;
  approver_audit_stream_cursor_value_input: Approver_Audit_Stream_Cursor_Value_Input;
  approver_bool_exp: Approver_Bool_Exp;
  approver_insert_input: Approver_Insert_Input;
  approver_max_fields: Approver_Max_Fields;
  approver_max_order_by: Approver_Max_Order_By;
  approver_min_fields: Approver_Min_Fields;
  approver_min_order_by: Approver_Min_Order_By;
  approver_mutation_response: Approver_Mutation_Response;
  approver_obj_rel_insert_input: Approver_Obj_Rel_Insert_Input;
  approver_on_conflict: Approver_On_Conflict;
  approver_order_by: Approver_Order_By;
  approver_pk_columns_input: Approver_Pk_Columns_Input;
  approver_response: Approver_Response;
  approver_response_aggregate_order_by: Approver_Response_Aggregate_Order_By;
  approver_response_arr_rel_insert_input: Approver_Response_Arr_Rel_Insert_Input;
  approver_response_bool_exp: Approver_Response_Bool_Exp;
  approver_response_insert_input: Approver_Response_Insert_Input;
  approver_response_max_order_by: Approver_Response_Max_Order_By;
  approver_response_min_order_by: Approver_Response_Min_Order_By;
  approver_response_on_conflict: Approver_Response_On_Conflict;
  approver_response_order_by: Approver_Response_Order_By;
  approver_set_input: Approver_Set_Input;
  approver_stream_cursor_input: Approver_Stream_Cursor_Input;
  approver_stream_cursor_value_input: Approver_Stream_Cursor_Value_Input;
  approver_updates: Approver_Updates;
  assessment: Assessment;
  assessment_activity: Assessment_Activity;
  assessment_activity_aggregate: Assessment_Activity_Aggregate;
  assessment_activity_aggregate_bool_exp: Assessment_Activity_Aggregate_Bool_Exp;
  assessment_activity_aggregate_bool_exp_count: Assessment_Activity_Aggregate_Bool_Exp_Count;
  assessment_activity_aggregate_fields: Assessment_Activity_Aggregate_Fields;
  assessment_activity_aggregate_order_by: Assessment_Activity_Aggregate_Order_By;
  assessment_activity_append_input: Assessment_Activity_Append_Input;
  assessment_activity_arr_rel_insert_input: Assessment_Activity_Arr_Rel_Insert_Input;
  assessment_activity_audit: Assessment_Activity_Audit;
  assessment_activity_audit_bool_exp: Assessment_Activity_Audit_Bool_Exp;
  assessment_activity_audit_order_by: Assessment_Activity_Audit_Order_By;
  assessment_activity_audit_stream_cursor_input: Assessment_Activity_Audit_Stream_Cursor_Input;
  assessment_activity_audit_stream_cursor_value_input: Assessment_Activity_Audit_Stream_Cursor_Value_Input;
  assessment_activity_bool_exp: Assessment_Activity_Bool_Exp;
  assessment_activity_delete_at_path_input: Assessment_Activity_Delete_At_Path_Input;
  assessment_activity_delete_elem_input: Assessment_Activity_Delete_Elem_Input;
  assessment_activity_delete_key_input: Assessment_Activity_Delete_Key_Input;
  assessment_activity_insert_input: Assessment_Activity_Insert_Input;
  assessment_activity_max_fields: Assessment_Activity_Max_Fields;
  assessment_activity_max_order_by: Assessment_Activity_Max_Order_By;
  assessment_activity_min_fields: Assessment_Activity_Min_Fields;
  assessment_activity_min_order_by: Assessment_Activity_Min_Order_By;
  assessment_activity_mutation_response: Assessment_Activity_Mutation_Response;
  assessment_activity_on_conflict: Assessment_Activity_On_Conflict;
  assessment_activity_order_by: Assessment_Activity_Order_By;
  assessment_activity_pk_columns_input: Assessment_Activity_Pk_Columns_Input;
  assessment_activity_prepend_input: Assessment_Activity_Prepend_Input;
  assessment_activity_set_input: Assessment_Activity_Set_Input;
  assessment_activity_status_enum_comparison_exp: Assessment_Activity_Status_Enum_Comparison_Exp;
  assessment_activity_stream_cursor_input: Assessment_Activity_Stream_Cursor_Input;
  assessment_activity_stream_cursor_value_input: Assessment_Activity_Stream_Cursor_Value_Input;
  assessment_activity_type_enum_comparison_exp: Assessment_Activity_Type_Enum_Comparison_Exp;
  assessment_activity_updates: Assessment_Activity_Updates;
  assessment_aggregate: Assessment_Aggregate;
  assessment_aggregate_fields: Assessment_Aggregate_Fields;
  assessment_append_input: Assessment_Append_Input;
  assessment_audit: Assessment_Audit;
  assessment_audit_aggregate: Assessment_Audit_Aggregate;
  assessment_audit_aggregate_fields: Assessment_Audit_Aggregate_Fields;
  assessment_audit_avg_fields: Assessment_Audit_Avg_Fields;
  assessment_audit_bool_exp: Assessment_Audit_Bool_Exp;
  assessment_audit_max_fields: Assessment_Audit_Max_Fields;
  assessment_audit_min_fields: Assessment_Audit_Min_Fields;
  assessment_audit_order_by: Assessment_Audit_Order_By;
  assessment_audit_stddev_fields: Assessment_Audit_Stddev_Fields;
  assessment_audit_stddev_pop_fields: Assessment_Audit_Stddev_Pop_Fields;
  assessment_audit_stddev_samp_fields: Assessment_Audit_Stddev_Samp_Fields;
  assessment_audit_stream_cursor_input: Assessment_Audit_Stream_Cursor_Input;
  assessment_audit_stream_cursor_value_input: Assessment_Audit_Stream_Cursor_Value_Input;
  assessment_audit_sum_fields: Assessment_Audit_Sum_Fields;
  assessment_audit_var_pop_fields: Assessment_Audit_Var_Pop_Fields;
  assessment_audit_var_samp_fields: Assessment_Audit_Var_Samp_Fields;
  assessment_audit_variance_fields: Assessment_Audit_Variance_Fields;
  assessment_avg_fields: Assessment_Avg_Fields;
  assessment_bool_exp: Assessment_Bool_Exp;
  assessment_delete_at_path_input: Assessment_Delete_At_Path_Input;
  assessment_delete_elem_input: Assessment_Delete_Elem_Input;
  assessment_delete_key_input: Assessment_Delete_Key_Input;
  assessment_inc_input: Assessment_Inc_Input;
  assessment_insert_input: Assessment_Insert_Input;
  assessment_max_fields: Assessment_Max_Fields;
  assessment_min_fields: Assessment_Min_Fields;
  assessment_mutation_response: Assessment_Mutation_Response;
  assessment_obj_rel_insert_input: Assessment_Obj_Rel_Insert_Input;
  assessment_on_conflict: Assessment_On_Conflict;
  assessment_order_by: Assessment_Order_By;
  assessment_pk_columns_input: Assessment_Pk_Columns_Input;
  assessment_prepend_input: Assessment_Prepend_Input;
  assessment_result_parent: Assessment_Result_Parent;
  assessment_result_parent_aggregate: Assessment_Result_Parent_Aggregate;
  assessment_result_parent_aggregate_bool_exp: Assessment_Result_Parent_Aggregate_Bool_Exp;
  assessment_result_parent_aggregate_bool_exp_count: Assessment_Result_Parent_Aggregate_Bool_Exp_Count;
  assessment_result_parent_aggregate_fields: Assessment_Result_Parent_Aggregate_Fields;
  assessment_result_parent_aggregate_order_by: Assessment_Result_Parent_Aggregate_Order_By;
  assessment_result_parent_arr_rel_insert_input: Assessment_Result_Parent_Arr_Rel_Insert_Input;
  assessment_result_parent_audit: Assessment_Result_Parent_Audit;
  assessment_result_parent_audit_aggregate_order_by: Assessment_Result_Parent_Audit_Aggregate_Order_By;
  assessment_result_parent_audit_bool_exp: Assessment_Result_Parent_Audit_Bool_Exp;
  assessment_result_parent_audit_max_order_by: Assessment_Result_Parent_Audit_Max_Order_By;
  assessment_result_parent_audit_min_order_by: Assessment_Result_Parent_Audit_Min_Order_By;
  assessment_result_parent_audit_order_by: Assessment_Result_Parent_Audit_Order_By;
  assessment_result_parent_audit_stream_cursor_input: Assessment_Result_Parent_Audit_Stream_Cursor_Input;
  assessment_result_parent_audit_stream_cursor_value_input: Assessment_Result_Parent_Audit_Stream_Cursor_Value_Input;
  assessment_result_parent_bool_exp: Assessment_Result_Parent_Bool_Exp;
  assessment_result_parent_insert_input: Assessment_Result_Parent_Insert_Input;
  assessment_result_parent_max_fields: Assessment_Result_Parent_Max_Fields;
  assessment_result_parent_max_order_by: Assessment_Result_Parent_Max_Order_By;
  assessment_result_parent_min_fields: Assessment_Result_Parent_Min_Fields;
  assessment_result_parent_min_order_by: Assessment_Result_Parent_Min_Order_By;
  assessment_result_parent_mutation_response: Assessment_Result_Parent_Mutation_Response;
  assessment_result_parent_on_conflict: Assessment_Result_Parent_On_Conflict;
  assessment_result_parent_order_by: Assessment_Result_Parent_Order_By;
  assessment_result_parent_stream_cursor_input: Assessment_Result_Parent_Stream_Cursor_Input;
  assessment_result_parent_stream_cursor_value_input: Assessment_Result_Parent_Stream_Cursor_Value_Input;
  assessment_set_input: Assessment_Set_Input;
  assessment_status_enum_comparison_exp: Assessment_Status_Enum_Comparison_Exp;
  assessment_stddev_fields: Assessment_Stddev_Fields;
  assessment_stddev_pop_fields: Assessment_Stddev_Pop_Fields;
  assessment_stddev_samp_fields: Assessment_Stddev_Samp_Fields;
  assessment_stream_cursor_input: Assessment_Stream_Cursor_Input;
  assessment_stream_cursor_value_input: Assessment_Stream_Cursor_Value_Input;
  assessment_sum_fields: Assessment_Sum_Fields;
  assessment_updates: Assessment_Updates;
  assessment_var_pop_fields: Assessment_Var_Pop_Fields;
  assessment_var_samp_fields: Assessment_Var_Samp_Fields;
  assessment_variance_fields: Assessment_Variance_Fields;
  attestation_config: Attestation_Config;
  attestation_config_bool_exp: Attestation_Config_Bool_Exp;
  attestation_config_insert_input: Attestation_Config_Insert_Input;
  attestation_config_mutation_response: Attestation_Config_Mutation_Response;
  attestation_config_obj_rel_insert_input: Attestation_Config_Obj_Rel_Insert_Input;
  attestation_config_on_conflict: Attestation_Config_On_Conflict;
  attestation_config_order_by: Attestation_Config_Order_By;
  attestation_config_pk_columns_input: Attestation_Config_Pk_Columns_Input;
  attestation_config_set_input: Attestation_Config_Set_Input;
  attestation_config_stream_cursor_input: Attestation_Config_Stream_Cursor_Input;
  attestation_config_stream_cursor_value_input: Attestation_Config_Stream_Cursor_Value_Input;
  attestation_config_updates: Attestation_Config_Updates;
  attestation_group: Attestation_Group;
  attestation_group_aggregate_order_by: Attestation_Group_Aggregate_Order_By;
  attestation_group_arr_rel_insert_input: Attestation_Group_Arr_Rel_Insert_Input;
  attestation_group_bool_exp: Attestation_Group_Bool_Exp;
  attestation_group_insert_input: Attestation_Group_Insert_Input;
  attestation_group_max_order_by: Attestation_Group_Max_Order_By;
  attestation_group_min_order_by: Attestation_Group_Min_Order_By;
  attestation_group_mutation_response: Attestation_Group_Mutation_Response;
  attestation_group_on_conflict: Attestation_Group_On_Conflict;
  attestation_group_order_by: Attestation_Group_Order_By;
  attestation_group_pk_columns_input: Attestation_Group_Pk_Columns_Input;
  attestation_group_set_input: Attestation_Group_Set_Input;
  attestation_group_stream_cursor_input: Attestation_Group_Stream_Cursor_Input;
  attestation_group_stream_cursor_value_input: Attestation_Group_Stream_Cursor_Value_Input;
  attestation_group_updates: Attestation_Group_Updates;
  attestation_record: Attestation_Record;
  attestation_record_aggregate: Attestation_Record_Aggregate;
  attestation_record_aggregate_bool_exp: Attestation_Record_Aggregate_Bool_Exp;
  attestation_record_aggregate_bool_exp_bool_and: Attestation_Record_Aggregate_Bool_Exp_Bool_And;
  attestation_record_aggregate_bool_exp_bool_or: Attestation_Record_Aggregate_Bool_Exp_Bool_Or;
  attestation_record_aggregate_bool_exp_count: Attestation_Record_Aggregate_Bool_Exp_Count;
  attestation_record_aggregate_fields: Attestation_Record_Aggregate_Fields;
  attestation_record_aggregate_order_by: Attestation_Record_Aggregate_Order_By;
  attestation_record_arr_rel_insert_input: Attestation_Record_Arr_Rel_Insert_Input;
  attestation_record_bool_exp: Attestation_Record_Bool_Exp;
  attestation_record_insert_input: Attestation_Record_Insert_Input;
  attestation_record_max_fields: Attestation_Record_Max_Fields;
  attestation_record_max_order_by: Attestation_Record_Max_Order_By;
  attestation_record_min_fields: Attestation_Record_Min_Fields;
  attestation_record_min_order_by: Attestation_Record_Min_Order_By;
  attestation_record_on_conflict: Attestation_Record_On_Conflict;
  attestation_record_order_by: Attestation_Record_Order_By;
  attestation_record_status_enum_comparison_exp: Attestation_Record_Status_Enum_Comparison_Exp;
  attestation_record_stream_cursor_input: Attestation_Record_Stream_Cursor_Input;
  attestation_record_stream_cursor_value_input: Attestation_Record_Stream_Cursor_Value_Input;
  audit_log_view: Audit_Log_View;
  audit_log_view_aggregate: Audit_Log_View_Aggregate;
  audit_log_view_aggregate_fields: Audit_Log_View_Aggregate_Fields;
  audit_log_view_bool_exp: Audit_Log_View_Bool_Exp;
  audit_log_view_max_fields: Audit_Log_View_Max_Fields;
  audit_log_view_min_fields: Audit_Log_View_Min_Fields;
  audit_log_view_order_by: Audit_Log_View_Order_By;
  audit_log_view_stream_cursor_input: Audit_Log_View_Stream_Cursor_Input;
  audit_log_view_stream_cursor_value_input: Audit_Log_View_Stream_Cursor_Value_Input;
  auth_organisation: Auth_Organisation;
  auth_organisation_bool_exp: Auth_Organisation_Bool_Exp;
  auth_organisation_order_by: Auth_Organisation_Order_By;
  auth_organisation_stream_cursor_input: Auth_Organisation_Stream_Cursor_Input;
  auth_organisation_stream_cursor_value_input: Auth_Organisation_Stream_Cursor_Value_Input;
  auth_organisationuser: Auth_Organisationuser;
  auth_organisationuser_aggregate_order_by: Auth_Organisationuser_Aggregate_Order_By;
  auth_organisationuser_bool_exp: Auth_Organisationuser_Bool_Exp;
  auth_organisationuser_max_order_by: Auth_Organisationuser_Max_Order_By;
  auth_organisationuser_min_order_by: Auth_Organisationuser_Min_Order_By;
  auth_organisationuser_mutation_response: Auth_Organisationuser_Mutation_Response;
  auth_organisationuser_order_by: Auth_Organisationuser_Order_By;
  auth_organisationuser_pk_columns_input: Auth_Organisationuser_Pk_Columns_Input;
  auth_organisationuser_set_input: Auth_Organisationuser_Set_Input;
  auth_organisationuser_stream_cursor_input: Auth_Organisationuser_Stream_Cursor_Input;
  auth_organisationuser_stream_cursor_value_input: Auth_Organisationuser_Stream_Cursor_Value_Input;
  auth_organisationuser_updates: Auth_Organisationuser_Updates;
  auth_user: Auth_User;
  auth_user_aggregate: Auth_User_Aggregate;
  auth_user_aggregate_fields: Auth_User_Aggregate_Fields;
  auth_user_bool_exp: Auth_User_Bool_Exp;
  auth_user_max_fields: Auth_User_Max_Fields;
  auth_user_min_fields: Auth_User_Min_Fields;
  auth_user_mutation_response: Auth_User_Mutation_Response;
  auth_user_order_by: Auth_User_Order_By;
  auth_user_pk_columns_input: Auth_User_Pk_Columns_Input;
  auth_user_set_input: Auth_User_Set_Input;
  auth_user_stream_cursor_input: Auth_User_Stream_Cursor_Input;
  auth_user_stream_cursor_value_input: Auth_User_Stream_Cursor_Value_Input;
  auth_user_updates: Auth_User_Updates;
  bigint: Scalars['bigint']['output'];
  bigint_comparison_exp: Bigint_Comparison_Exp;
  business_area: Business_Area;
  business_area_aggregate: Business_Area_Aggregate;
  business_area_aggregate_fields: Business_Area_Aggregate_Fields;
  business_area_avg_fields: Business_Area_Avg_Fields;
  business_area_bool_exp: Business_Area_Bool_Exp;
  business_area_insert_input: Business_Area_Insert_Input;
  business_area_max_fields: Business_Area_Max_Fields;
  business_area_min_fields: Business_Area_Min_Fields;
  business_area_mutation_response: Business_Area_Mutation_Response;
  business_area_obj_rel_insert_input: Business_Area_Obj_Rel_Insert_Input;
  business_area_on_conflict: Business_Area_On_Conflict;
  business_area_order_by: Business_Area_Order_By;
  business_area_pk_columns_input: Business_Area_Pk_Columns_Input;
  business_area_set_input: Business_Area_Set_Input;
  business_area_stddev_fields: Business_Area_Stddev_Fields;
  business_area_stddev_pop_fields: Business_Area_Stddev_Pop_Fields;
  business_area_stddev_samp_fields: Business_Area_Stddev_Samp_Fields;
  business_area_stream_cursor_input: Business_Area_Stream_Cursor_Input;
  business_area_stream_cursor_value_input: Business_Area_Stream_Cursor_Value_Input;
  business_area_sum_fields: Business_Area_Sum_Fields;
  business_area_updates: Business_Area_Updates;
  business_area_var_pop_fields: Business_Area_Var_Pop_Fields;
  business_area_var_samp_fields: Business_Area_Var_Samp_Fields;
  business_area_variance_fields: Business_Area_Variance_Fields;
  cause: Cause;
  cause_aggregate: Cause_Aggregate;
  cause_aggregate_bool_exp: Cause_Aggregate_Bool_Exp;
  cause_aggregate_bool_exp_count: Cause_Aggregate_Bool_Exp_Count;
  cause_aggregate_fields: Cause_Aggregate_Fields;
  cause_aggregate_order_by: Cause_Aggregate_Order_By;
  cause_append_input: Cause_Append_Input;
  cause_arr_rel_insert_input: Cause_Arr_Rel_Insert_Input;
  cause_audit: Cause_Audit;
  cause_audit_bool_exp: Cause_Audit_Bool_Exp;
  cause_audit_order_by: Cause_Audit_Order_By;
  cause_audit_stream_cursor_input: Cause_Audit_Stream_Cursor_Input;
  cause_audit_stream_cursor_value_input: Cause_Audit_Stream_Cursor_Value_Input;
  cause_avg_fields: Cause_Avg_Fields;
  cause_avg_order_by: Cause_Avg_Order_By;
  cause_bool_exp: Cause_Bool_Exp;
  cause_delete_at_path_input: Cause_Delete_At_Path_Input;
  cause_delete_elem_input: Cause_Delete_Elem_Input;
  cause_delete_key_input: Cause_Delete_Key_Input;
  cause_inc_input: Cause_Inc_Input;
  cause_insert_input: Cause_Insert_Input;
  cause_max_fields: Cause_Max_Fields;
  cause_max_order_by: Cause_Max_Order_By;
  cause_min_fields: Cause_Min_Fields;
  cause_min_order_by: Cause_Min_Order_By;
  cause_mutation_response: Cause_Mutation_Response;
  cause_on_conflict: Cause_On_Conflict;
  cause_order_by: Cause_Order_By;
  cause_pk_columns_input: Cause_Pk_Columns_Input;
  cause_prepend_input: Cause_Prepend_Input;
  cause_set_input: Cause_Set_Input;
  cause_stddev_fields: Cause_Stddev_Fields;
  cause_stddev_order_by: Cause_Stddev_Order_By;
  cause_stddev_pop_fields: Cause_Stddev_Pop_Fields;
  cause_stddev_pop_order_by: Cause_Stddev_Pop_Order_By;
  cause_stddev_samp_fields: Cause_Stddev_Samp_Fields;
  cause_stddev_samp_order_by: Cause_Stddev_Samp_Order_By;
  cause_stream_cursor_input: Cause_Stream_Cursor_Input;
  cause_stream_cursor_value_input: Cause_Stream_Cursor_Value_Input;
  cause_sum_fields: Cause_Sum_Fields;
  cause_sum_order_by: Cause_Sum_Order_By;
  cause_updates: Cause_Updates;
  cause_var_pop_fields: Cause_Var_Pop_Fields;
  cause_var_pop_order_by: Cause_Var_Pop_Order_By;
  cause_var_samp_fields: Cause_Var_Samp_Fields;
  cause_var_samp_order_by: Cause_Var_Samp_Order_By;
  cause_variance_fields: Cause_Variance_Fields;
  cause_variance_order_by: Cause_Variance_Order_By;
  change_request: Change_Request;
  change_request_aggregate: Change_Request_Aggregate;
  change_request_aggregate_bool_exp: Change_Request_Aggregate_Bool_Exp;
  change_request_aggregate_bool_exp_count: Change_Request_Aggregate_Bool_Exp_Count;
  change_request_aggregate_fields: Change_Request_Aggregate_Fields;
  change_request_aggregate_order_by: Change_Request_Aggregate_Order_By;
  change_request_arr_rel_insert_input: Change_Request_Arr_Rel_Insert_Input;
  change_request_audit: Change_Request_Audit;
  change_request_audit_bool_exp: Change_Request_Audit_Bool_Exp;
  change_request_audit_order_by: Change_Request_Audit_Order_By;
  change_request_audit_stream_cursor_input: Change_Request_Audit_Stream_Cursor_Input;
  change_request_audit_stream_cursor_value_input: Change_Request_Audit_Stream_Cursor_Value_Input;
  change_request_avg_fields: Change_Request_Avg_Fields;
  change_request_avg_order_by: Change_Request_Avg_Order_By;
  change_request_bool_exp: Change_Request_Bool_Exp;
  change_request_contributor: Change_Request_Contributor;
  change_request_contributor_aggregate_order_by: Change_Request_Contributor_Aggregate_Order_By;
  change_request_contributor_arr_rel_insert_input: Change_Request_Contributor_Arr_Rel_Insert_Input;
  change_request_contributor_bool_exp: Change_Request_Contributor_Bool_Exp;
  change_request_contributor_insert_input: Change_Request_Contributor_Insert_Input;
  change_request_contributor_max_order_by: Change_Request_Contributor_Max_Order_By;
  change_request_contributor_min_order_by: Change_Request_Contributor_Min_Order_By;
  change_request_contributor_on_conflict: Change_Request_Contributor_On_Conflict;
  change_request_contributor_order_by: Change_Request_Contributor_Order_By;
  change_request_contributor_stream_cursor_input: Change_Request_Contributor_Stream_Cursor_Input;
  change_request_contributor_stream_cursor_value_input: Change_Request_Contributor_Stream_Cursor_Value_Input;
  change_request_insert_input: Change_Request_Insert_Input;
  change_request_max_fields: Change_Request_Max_Fields;
  change_request_max_order_by: Change_Request_Max_Order_By;
  change_request_min_fields: Change_Request_Min_Fields;
  change_request_min_order_by: Change_Request_Min_Order_By;
  change_request_obj_rel_insert_input: Change_Request_Obj_Rel_Insert_Input;
  change_request_on_conflict: Change_Request_On_Conflict;
  change_request_order_by: Change_Request_Order_By;
  change_request_stddev_fields: Change_Request_Stddev_Fields;
  change_request_stddev_order_by: Change_Request_Stddev_Order_By;
  change_request_stddev_pop_fields: Change_Request_Stddev_Pop_Fields;
  change_request_stddev_pop_order_by: Change_Request_Stddev_Pop_Order_By;
  change_request_stddev_samp_fields: Change_Request_Stddev_Samp_Fields;
  change_request_stddev_samp_order_by: Change_Request_Stddev_Samp_Order_By;
  change_request_stream_cursor_input: Change_Request_Stream_Cursor_Input;
  change_request_stream_cursor_value_input: Change_Request_Stream_Cursor_Value_Input;
  change_request_sum_fields: Change_Request_Sum_Fields;
  change_request_sum_order_by: Change_Request_Sum_Order_By;
  change_request_var_pop_fields: Change_Request_Var_Pop_Fields;
  change_request_var_pop_order_by: Change_Request_Var_Pop_Order_By;
  change_request_var_samp_fields: Change_Request_Var_Samp_Fields;
  change_request_var_samp_order_by: Change_Request_Var_Samp_Order_By;
  change_request_variance_fields: Change_Request_Variance_Fields;
  change_request_variance_order_by: Change_Request_Variance_Order_By;
  comment: Comment;
  comment_aggregate_order_by: Comment_Aggregate_Order_By;
  comment_arr_rel_insert_input: Comment_Arr_Rel_Insert_Input;
  comment_audit: Comment_Audit;
  comment_audit_bool_exp: Comment_Audit_Bool_Exp;
  comment_audit_order_by: Comment_Audit_Order_By;
  comment_audit_stream_cursor_input: Comment_Audit_Stream_Cursor_Input;
  comment_audit_stream_cursor_value_input: Comment_Audit_Stream_Cursor_Value_Input;
  comment_bool_exp: Comment_Bool_Exp;
  comment_insert_input: Comment_Insert_Input;
  comment_max_order_by: Comment_Max_Order_By;
  comment_min_order_by: Comment_Min_Order_By;
  comment_mutation_response: Comment_Mutation_Response;
  comment_on_conflict: Comment_On_Conflict;
  comment_order_by: Comment_Order_By;
  comment_pk_columns_input: Comment_Pk_Columns_Input;
  comment_set_input: Comment_Set_Input;
  comment_stream_cursor_input: Comment_Stream_Cursor_Input;
  comment_stream_cursor_value_input: Comment_Stream_Cursor_Value_Input;
  comment_updates: Comment_Updates;
  compliance_monitoring_assessment: Compliance_Monitoring_Assessment;
  compliance_monitoring_assessment_aggregate: Compliance_Monitoring_Assessment_Aggregate;
  compliance_monitoring_assessment_aggregate_fields: Compliance_Monitoring_Assessment_Aggregate_Fields;
  compliance_monitoring_assessment_append_input: Compliance_Monitoring_Assessment_Append_Input;
  compliance_monitoring_assessment_avg_fields: Compliance_Monitoring_Assessment_Avg_Fields;
  compliance_monitoring_assessment_bool_exp: Compliance_Monitoring_Assessment_Bool_Exp;
  compliance_monitoring_assessment_delete_at_path_input: Compliance_Monitoring_Assessment_Delete_At_Path_Input;
  compliance_monitoring_assessment_delete_elem_input: Compliance_Monitoring_Assessment_Delete_Elem_Input;
  compliance_monitoring_assessment_delete_key_input: Compliance_Monitoring_Assessment_Delete_Key_Input;
  compliance_monitoring_assessment_inc_input: Compliance_Monitoring_Assessment_Inc_Input;
  compliance_monitoring_assessment_insert_input: Compliance_Monitoring_Assessment_Insert_Input;
  compliance_monitoring_assessment_max_fields: Compliance_Monitoring_Assessment_Max_Fields;
  compliance_monitoring_assessment_min_fields: Compliance_Monitoring_Assessment_Min_Fields;
  compliance_monitoring_assessment_mutation_response: Compliance_Monitoring_Assessment_Mutation_Response;
  compliance_monitoring_assessment_obj_rel_insert_input: Compliance_Monitoring_Assessment_Obj_Rel_Insert_Input;
  compliance_monitoring_assessment_on_conflict: Compliance_Monitoring_Assessment_On_Conflict;
  compliance_monitoring_assessment_order_by: Compliance_Monitoring_Assessment_Order_By;
  compliance_monitoring_assessment_pk_columns_input: Compliance_Monitoring_Assessment_Pk_Columns_Input;
  compliance_monitoring_assessment_prepend_input: Compliance_Monitoring_Assessment_Prepend_Input;
  compliance_monitoring_assessment_set_input: Compliance_Monitoring_Assessment_Set_Input;
  compliance_monitoring_assessment_stddev_fields: Compliance_Monitoring_Assessment_Stddev_Fields;
  compliance_monitoring_assessment_stddev_pop_fields: Compliance_Monitoring_Assessment_Stddev_Pop_Fields;
  compliance_monitoring_assessment_stddev_samp_fields: Compliance_Monitoring_Assessment_Stddev_Samp_Fields;
  compliance_monitoring_assessment_stream_cursor_input: Compliance_Monitoring_Assessment_Stream_Cursor_Input;
  compliance_monitoring_assessment_stream_cursor_value_input: Compliance_Monitoring_Assessment_Stream_Cursor_Value_Input;
  compliance_monitoring_assessment_sum_fields: Compliance_Monitoring_Assessment_Sum_Fields;
  compliance_monitoring_assessment_updates: Compliance_Monitoring_Assessment_Updates;
  compliance_monitoring_assessment_var_pop_fields: Compliance_Monitoring_Assessment_Var_Pop_Fields;
  compliance_monitoring_assessment_var_samp_fields: Compliance_Monitoring_Assessment_Var_Samp_Fields;
  compliance_monitoring_assessment_variance_fields: Compliance_Monitoring_Assessment_Variance_Fields;
  consequence: Consequence;
  consequence_aggregate: Consequence_Aggregate;
  consequence_aggregate_bool_exp: Consequence_Aggregate_Bool_Exp;
  consequence_aggregate_bool_exp_count: Consequence_Aggregate_Bool_Exp_Count;
  consequence_aggregate_fields: Consequence_Aggregate_Fields;
  consequence_aggregate_order_by: Consequence_Aggregate_Order_By;
  consequence_append_input: Consequence_Append_Input;
  consequence_arr_rel_insert_input: Consequence_Arr_Rel_Insert_Input;
  consequence_audit: Consequence_Audit;
  consequence_audit_aggregate: Consequence_Audit_Aggregate;
  consequence_audit_aggregate_fields: Consequence_Audit_Aggregate_Fields;
  consequence_audit_avg_fields: Consequence_Audit_Avg_Fields;
  consequence_audit_bool_exp: Consequence_Audit_Bool_Exp;
  consequence_audit_max_fields: Consequence_Audit_Max_Fields;
  consequence_audit_min_fields: Consequence_Audit_Min_Fields;
  consequence_audit_order_by: Consequence_Audit_Order_By;
  consequence_audit_stddev_fields: Consequence_Audit_Stddev_Fields;
  consequence_audit_stddev_pop_fields: Consequence_Audit_Stddev_Pop_Fields;
  consequence_audit_stddev_samp_fields: Consequence_Audit_Stddev_Samp_Fields;
  consequence_audit_stream_cursor_input: Consequence_Audit_Stream_Cursor_Input;
  consequence_audit_stream_cursor_value_input: Consequence_Audit_Stream_Cursor_Value_Input;
  consequence_audit_sum_fields: Consequence_Audit_Sum_Fields;
  consequence_audit_var_pop_fields: Consequence_Audit_Var_Pop_Fields;
  consequence_audit_var_samp_fields: Consequence_Audit_Var_Samp_Fields;
  consequence_audit_variance_fields: Consequence_Audit_Variance_Fields;
  consequence_avg_fields: Consequence_Avg_Fields;
  consequence_avg_order_by: Consequence_Avg_Order_By;
  consequence_bool_exp: Consequence_Bool_Exp;
  consequence_delete_at_path_input: Consequence_Delete_At_Path_Input;
  consequence_delete_elem_input: Consequence_Delete_Elem_Input;
  consequence_delete_key_input: Consequence_Delete_Key_Input;
  consequence_inc_input: Consequence_Inc_Input;
  consequence_insert_input: Consequence_Insert_Input;
  consequence_max_fields: Consequence_Max_Fields;
  consequence_max_order_by: Consequence_Max_Order_By;
  consequence_min_fields: Consequence_Min_Fields;
  consequence_min_order_by: Consequence_Min_Order_By;
  consequence_mutation_response: Consequence_Mutation_Response;
  consequence_on_conflict: Consequence_On_Conflict;
  consequence_order_by: Consequence_Order_By;
  consequence_pk_columns_input: Consequence_Pk_Columns_Input;
  consequence_prepend_input: Consequence_Prepend_Input;
  consequence_set_input: Consequence_Set_Input;
  consequence_stddev_fields: Consequence_Stddev_Fields;
  consequence_stddev_order_by: Consequence_Stddev_Order_By;
  consequence_stddev_pop_fields: Consequence_Stddev_Pop_Fields;
  consequence_stddev_pop_order_by: Consequence_Stddev_Pop_Order_By;
  consequence_stddev_samp_fields: Consequence_Stddev_Samp_Fields;
  consequence_stddev_samp_order_by: Consequence_Stddev_Samp_Order_By;
  consequence_stream_cursor_input: Consequence_Stream_Cursor_Input;
  consequence_stream_cursor_value_input: Consequence_Stream_Cursor_Value_Input;
  consequence_sum_fields: Consequence_Sum_Fields;
  consequence_sum_order_by: Consequence_Sum_Order_By;
  consequence_type_enum_comparison_exp: Consequence_Type_Enum_Comparison_Exp;
  consequence_updates: Consequence_Updates;
  consequence_var_pop_fields: Consequence_Var_Pop_Fields;
  consequence_var_pop_order_by: Consequence_Var_Pop_Order_By;
  consequence_var_samp_fields: Consequence_Var_Samp_Fields;
  consequence_var_samp_order_by: Consequence_Var_Samp_Order_By;
  consequence_variance_fields: Consequence_Variance_Fields;
  consequence_variance_order_by: Consequence_Variance_Order_By;
  contributor: Contributor;
  contributor_aggregate: Contributor_Aggregate;
  contributor_aggregate_bool_exp: Contributor_Aggregate_Bool_Exp;
  contributor_aggregate_bool_exp_count: Contributor_Aggregate_Bool_Exp_Count;
  contributor_aggregate_fields: Contributor_Aggregate_Fields;
  contributor_aggregate_order_by: Contributor_Aggregate_Order_By;
  contributor_arr_rel_insert_input: Contributor_Arr_Rel_Insert_Input;
  contributor_audit: Contributor_Audit;
  contributor_audit_aggregate: Contributor_Audit_Aggregate;
  contributor_audit_aggregate_bool_exp: Contributor_Audit_Aggregate_Bool_Exp;
  contributor_audit_aggregate_bool_exp_count: Contributor_Audit_Aggregate_Bool_Exp_Count;
  contributor_audit_aggregate_fields: Contributor_Audit_Aggregate_Fields;
  contributor_audit_aggregate_order_by: Contributor_Audit_Aggregate_Order_By;
  contributor_audit_bool_exp: Contributor_Audit_Bool_Exp;
  contributor_audit_max_fields: Contributor_Audit_Max_Fields;
  contributor_audit_max_order_by: Contributor_Audit_Max_Order_By;
  contributor_audit_min_fields: Contributor_Audit_Min_Fields;
  contributor_audit_min_order_by: Contributor_Audit_Min_Order_By;
  contributor_audit_order_by: Contributor_Audit_Order_By;
  contributor_bool_exp: Contributor_Bool_Exp;
  contributor_group: Contributor_Group;
  contributor_group_aggregate: Contributor_Group_Aggregate;
  contributor_group_aggregate_bool_exp: Contributor_Group_Aggregate_Bool_Exp;
  contributor_group_aggregate_bool_exp_count: Contributor_Group_Aggregate_Bool_Exp_Count;
  contributor_group_aggregate_fields: Contributor_Group_Aggregate_Fields;
  contributor_group_aggregate_order_by: Contributor_Group_Aggregate_Order_By;
  contributor_group_arr_rel_insert_input: Contributor_Group_Arr_Rel_Insert_Input;
  contributor_group_audit: Contributor_Group_Audit;
  contributor_group_audit_aggregate: Contributor_Group_Audit_Aggregate;
  contributor_group_audit_aggregate_bool_exp: Contributor_Group_Audit_Aggregate_Bool_Exp;
  contributor_group_audit_aggregate_bool_exp_count: Contributor_Group_Audit_Aggregate_Bool_Exp_Count;
  contributor_group_audit_aggregate_fields: Contributor_Group_Audit_Aggregate_Fields;
  contributor_group_audit_aggregate_order_by: Contributor_Group_Audit_Aggregate_Order_By;
  contributor_group_audit_bool_exp: Contributor_Group_Audit_Bool_Exp;
  contributor_group_audit_max_fields: Contributor_Group_Audit_Max_Fields;
  contributor_group_audit_max_order_by: Contributor_Group_Audit_Max_Order_By;
  contributor_group_audit_min_fields: Contributor_Group_Audit_Min_Fields;
  contributor_group_audit_min_order_by: Contributor_Group_Audit_Min_Order_By;
  contributor_group_audit_order_by: Contributor_Group_Audit_Order_By;
  contributor_group_bool_exp: Contributor_Group_Bool_Exp;
  contributor_group_insert_input: Contributor_Group_Insert_Input;
  contributor_group_max_fields: Contributor_Group_Max_Fields;
  contributor_group_max_order_by: Contributor_Group_Max_Order_By;
  contributor_group_min_fields: Contributor_Group_Min_Fields;
  contributor_group_min_order_by: Contributor_Group_Min_Order_By;
  contributor_group_mutation_response: Contributor_Group_Mutation_Response;
  contributor_group_on_conflict: Contributor_Group_On_Conflict;
  contributor_group_order_by: Contributor_Group_Order_By;
  contributor_insert_input: Contributor_Insert_Input;
  contributor_max_fields: Contributor_Max_Fields;
  contributor_max_order_by: Contributor_Max_Order_By;
  contributor_min_fields: Contributor_Min_Fields;
  contributor_min_order_by: Contributor_Min_Order_By;
  contributor_mutation_response: Contributor_Mutation_Response;
  contributor_on_conflict: Contributor_On_Conflict;
  contributor_order_by: Contributor_Order_By;
  contributor_type_enum_comparison_exp: Contributor_Type_Enum_Comparison_Exp;
  control: Control;
  control_aggregate: Control_Aggregate;
  control_aggregate_fields: Control_Aggregate_Fields;
  control_audit: Control_Audit;
  control_audit_bool_exp: Control_Audit_Bool_Exp;
  control_audit_order_by: Control_Audit_Order_By;
  control_audit_stream_cursor_input: Control_Audit_Stream_Cursor_Input;
  control_audit_stream_cursor_value_input: Control_Audit_Stream_Cursor_Value_Input;
  control_avg_fields: Control_Avg_Fields;
  control_bool_exp: Control_Bool_Exp;
  control_group: Control_Group;
  control_group_aggregate: Control_Group_Aggregate;
  control_group_aggregate_fields: Control_Group_Aggregate_Fields;
  control_group_append_input: Control_Group_Append_Input;
  control_group_audit: Control_Group_Audit;
  control_group_audit_bool_exp: Control_Group_Audit_Bool_Exp;
  control_group_audit_order_by: Control_Group_Audit_Order_By;
  control_group_audit_stream_cursor_input: Control_Group_Audit_Stream_Cursor_Input;
  control_group_audit_stream_cursor_value_input: Control_Group_Audit_Stream_Cursor_Value_Input;
  control_group_bool_exp: Control_Group_Bool_Exp;
  control_group_delete_at_path_input: Control_Group_Delete_At_Path_Input;
  control_group_delete_elem_input: Control_Group_Delete_Elem_Input;
  control_group_delete_key_input: Control_Group_Delete_Key_Input;
  control_group_insert_input: Control_Group_Insert_Input;
  control_group_max_fields: Control_Group_Max_Fields;
  control_group_min_fields: Control_Group_Min_Fields;
  control_group_mutation_response: Control_Group_Mutation_Response;
  control_group_obj_rel_insert_input: Control_Group_Obj_Rel_Insert_Input;
  control_group_on_conflict: Control_Group_On_Conflict;
  control_group_order_by: Control_Group_Order_By;
  control_group_pk_columns_input: Control_Group_Pk_Columns_Input;
  control_group_prepend_input: Control_Group_Prepend_Input;
  control_group_set_input: Control_Group_Set_Input;
  control_group_stream_cursor_input: Control_Group_Stream_Cursor_Input;
  control_group_stream_cursor_value_input: Control_Group_Stream_Cursor_Value_Input;
  control_group_updates: Control_Group_Updates;
  control_insert_input: Control_Insert_Input;
  control_max_fields: Control_Max_Fields;
  control_min_fields: Control_Min_Fields;
  control_mutation_response: Control_Mutation_Response;
  control_obj_rel_insert_input: Control_Obj_Rel_Insert_Input;
  control_on_conflict: Control_On_Conflict;
  control_order_by: Control_Order_By;
  control_parent: Control_Parent;
  control_parent_aggregate: Control_Parent_Aggregate;
  control_parent_aggregate_bool_exp: Control_Parent_Aggregate_Bool_Exp;
  control_parent_aggregate_bool_exp_count: Control_Parent_Aggregate_Bool_Exp_Count;
  control_parent_aggregate_fields: Control_Parent_Aggregate_Fields;
  control_parent_aggregate_order_by: Control_Parent_Aggregate_Order_By;
  control_parent_arr_rel_insert_input: Control_Parent_Arr_Rel_Insert_Input;
  control_parent_audit: Control_Parent_Audit;
  control_parent_audit_aggregate_order_by: Control_Parent_Audit_Aggregate_Order_By;
  control_parent_audit_bool_exp: Control_Parent_Audit_Bool_Exp;
  control_parent_audit_max_order_by: Control_Parent_Audit_Max_Order_By;
  control_parent_audit_min_order_by: Control_Parent_Audit_Min_Order_By;
  control_parent_audit_order_by: Control_Parent_Audit_Order_By;
  control_parent_audit_stream_cursor_input: Control_Parent_Audit_Stream_Cursor_Input;
  control_parent_audit_stream_cursor_value_input: Control_Parent_Audit_Stream_Cursor_Value_Input;
  control_parent_bool_exp: Control_Parent_Bool_Exp;
  control_parent_insert_input: Control_Parent_Insert_Input;
  control_parent_max_fields: Control_Parent_Max_Fields;
  control_parent_max_order_by: Control_Parent_Max_Order_By;
  control_parent_min_fields: Control_Parent_Min_Fields;
  control_parent_min_order_by: Control_Parent_Min_Order_By;
  control_parent_mutation_response: Control_Parent_Mutation_Response;
  control_parent_on_conflict: Control_Parent_On_Conflict;
  control_parent_order_by: Control_Parent_Order_By;
  control_parent_stream_cursor_input: Control_Parent_Stream_Cursor_Input;
  control_parent_stream_cursor_value_input: Control_Parent_Stream_Cursor_Value_Input;
  control_stddev_fields: Control_Stddev_Fields;
  control_stddev_pop_fields: Control_Stddev_Pop_Fields;
  control_stddev_samp_fields: Control_Stddev_Samp_Fields;
  control_stream_cursor_input: Control_Stream_Cursor_Input;
  control_stream_cursor_value_input: Control_Stream_Cursor_Value_Input;
  control_sum_fields: Control_Sum_Fields;
  control_type_enum_comparison_exp: Control_Type_Enum_Comparison_Exp;
  control_var_pop_fields: Control_Var_Pop_Fields;
  control_var_samp_fields: Control_Var_Samp_Fields;
  control_variance_fields: Control_Variance_Fields;
  conversation: Conversation;
  conversation_audit: Conversation_Audit;
  conversation_audit_bool_exp: Conversation_Audit_Bool_Exp;
  conversation_audit_order_by: Conversation_Audit_Order_By;
  conversation_audit_stream_cursor_input: Conversation_Audit_Stream_Cursor_Input;
  conversation_audit_stream_cursor_value_input: Conversation_Audit_Stream_Cursor_Value_Input;
  conversation_bool_exp: Conversation_Bool_Exp;
  conversation_insert_input: Conversation_Insert_Input;
  conversation_mutation_response: Conversation_Mutation_Response;
  conversation_obj_rel_insert_input: Conversation_Obj_Rel_Insert_Input;
  conversation_on_conflict: Conversation_On_Conflict;
  conversation_order_by: Conversation_Order_By;
  conversation_pk_columns_input: Conversation_Pk_Columns_Input;
  conversation_set_input: Conversation_Set_Input;
  conversation_stream_cursor_input: Conversation_Stream_Cursor_Input;
  conversation_stream_cursor_value_input: Conversation_Stream_Cursor_Value_Input;
  conversation_updates: Conversation_Updates;
  cost_type_enum_comparison_exp: Cost_Type_Enum_Comparison_Exp;
  custom_attribute_schema: Custom_Attribute_Schema;
  custom_attribute_schema_append_input: Custom_Attribute_Schema_Append_Input;
  custom_attribute_schema_audit: Custom_Attribute_Schema_Audit;
  custom_attribute_schema_audit_bool_exp: Custom_Attribute_Schema_Audit_Bool_Exp;
  custom_attribute_schema_audit_order_by: Custom_Attribute_Schema_Audit_Order_By;
  custom_attribute_schema_audit_stream_cursor_input: Custom_Attribute_Schema_Audit_Stream_Cursor_Input;
  custom_attribute_schema_audit_stream_cursor_value_input: Custom_Attribute_Schema_Audit_Stream_Cursor_Value_Input;
  custom_attribute_schema_bool_exp: Custom_Attribute_Schema_Bool_Exp;
  custom_attribute_schema_delete_at_path_input: Custom_Attribute_Schema_Delete_At_Path_Input;
  custom_attribute_schema_delete_elem_input: Custom_Attribute_Schema_Delete_Elem_Input;
  custom_attribute_schema_delete_key_input: Custom_Attribute_Schema_Delete_Key_Input;
  custom_attribute_schema_insert_input: Custom_Attribute_Schema_Insert_Input;
  custom_attribute_schema_mutation_response: Custom_Attribute_Schema_Mutation_Response;
  custom_attribute_schema_obj_rel_insert_input: Custom_Attribute_Schema_Obj_Rel_Insert_Input;
  custom_attribute_schema_on_conflict: Custom_Attribute_Schema_On_Conflict;
  custom_attribute_schema_order_by: Custom_Attribute_Schema_Order_By;
  custom_attribute_schema_pk_columns_input: Custom_Attribute_Schema_Pk_Columns_Input;
  custom_attribute_schema_prepend_input: Custom_Attribute_Schema_Prepend_Input;
  custom_attribute_schema_set_input: Custom_Attribute_Schema_Set_Input;
  custom_attribute_schema_stream_cursor_input: Custom_Attribute_Schema_Stream_Cursor_Input;
  custom_attribute_schema_stream_cursor_value_input: Custom_Attribute_Schema_Stream_Cursor_Value_Input;
  custom_attribute_schema_updates: Custom_Attribute_Schema_Updates;
  custom_ribbon: Custom_Ribbon;
  custom_ribbon_append_input: Custom_Ribbon_Append_Input;
  custom_ribbon_audit: Custom_Ribbon_Audit;
  custom_ribbon_audit_bool_exp: Custom_Ribbon_Audit_Bool_Exp;
  custom_ribbon_audit_order_by: Custom_Ribbon_Audit_Order_By;
  custom_ribbon_audit_stream_cursor_input: Custom_Ribbon_Audit_Stream_Cursor_Input;
  custom_ribbon_audit_stream_cursor_value_input: Custom_Ribbon_Audit_Stream_Cursor_Value_Input;
  custom_ribbon_bool_exp: Custom_Ribbon_Bool_Exp;
  custom_ribbon_delete_at_path_input: Custom_Ribbon_Delete_At_Path_Input;
  custom_ribbon_delete_elem_input: Custom_Ribbon_Delete_Elem_Input;
  custom_ribbon_delete_key_input: Custom_Ribbon_Delete_Key_Input;
  custom_ribbon_insert_input: Custom_Ribbon_Insert_Input;
  custom_ribbon_mutation_response: Custom_Ribbon_Mutation_Response;
  custom_ribbon_on_conflict: Custom_Ribbon_On_Conflict;
  custom_ribbon_order_by: Custom_Ribbon_Order_By;
  custom_ribbon_pk_columns_input: Custom_Ribbon_Pk_Columns_Input;
  custom_ribbon_prepend_input: Custom_Ribbon_Prepend_Input;
  custom_ribbon_set_input: Custom_Ribbon_Set_Input;
  custom_ribbon_stream_cursor_input: Custom_Ribbon_Stream_Cursor_Input;
  custom_ribbon_stream_cursor_value_input: Custom_Ribbon_Stream_Cursor_Value_Input;
  custom_ribbon_updates: Custom_Ribbon_Updates;
  dashboard: Dashboard;
  dashboard_audit: Dashboard_Audit;
  dashboard_audit_bool_exp: Dashboard_Audit_Bool_Exp;
  dashboard_audit_order_by: Dashboard_Audit_Order_By;
  dashboard_audit_stream_cursor_input: Dashboard_Audit_Stream_Cursor_Input;
  dashboard_audit_stream_cursor_value_input: Dashboard_Audit_Stream_Cursor_Value_Input;
  dashboard_bool_exp: Dashboard_Bool_Exp;
  dashboard_insert_input: Dashboard_Insert_Input;
  dashboard_mutation_response: Dashboard_Mutation_Response;
  dashboard_obj_rel_insert_input: Dashboard_Obj_Rel_Insert_Input;
  dashboard_on_conflict: Dashboard_On_Conflict;
  dashboard_order_by: Dashboard_Order_By;
  dashboard_sharing_type_enum_comparison_exp: Dashboard_Sharing_Type_Enum_Comparison_Exp;
  dashboard_stream_cursor_input: Dashboard_Stream_Cursor_Input;
  dashboard_stream_cursor_value_input: Dashboard_Stream_Cursor_Value_Input;
  data_import: Data_Import;
  data_import_aggregate: Data_Import_Aggregate;
  data_import_aggregate_fields: Data_Import_Aggregate_Fields;
  data_import_bool_exp: Data_Import_Bool_Exp;
  data_import_error: Data_Import_Error;
  data_import_error_aggregate: Data_Import_Error_Aggregate;
  data_import_error_aggregate_fields: Data_Import_Error_Aggregate_Fields;
  data_import_error_avg_fields: Data_Import_Error_Avg_Fields;
  data_import_error_bool_exp: Data_Import_Error_Bool_Exp;
  data_import_error_insert_input: Data_Import_Error_Insert_Input;
  data_import_error_max_fields: Data_Import_Error_Max_Fields;
  data_import_error_min_fields: Data_Import_Error_Min_Fields;
  data_import_error_mutation_response: Data_Import_Error_Mutation_Response;
  data_import_error_on_conflict: Data_Import_Error_On_Conflict;
  data_import_error_order_by: Data_Import_Error_Order_By;
  data_import_error_stddev_fields: Data_Import_Error_Stddev_Fields;
  data_import_error_stddev_pop_fields: Data_Import_Error_Stddev_Pop_Fields;
  data_import_error_stddev_samp_fields: Data_Import_Error_Stddev_Samp_Fields;
  data_import_error_stream_cursor_input: Data_Import_Error_Stream_Cursor_Input;
  data_import_error_stream_cursor_value_input: Data_Import_Error_Stream_Cursor_Value_Input;
  data_import_error_sum_fields: Data_Import_Error_Sum_Fields;
  data_import_error_var_pop_fields: Data_Import_Error_Var_Pop_Fields;
  data_import_error_var_samp_fields: Data_Import_Error_Var_Samp_Fields;
  data_import_error_variance_fields: Data_Import_Error_Variance_Fields;
  data_import_insert_input: Data_Import_Insert_Input;
  data_import_max_fields: Data_Import_Max_Fields;
  data_import_min_fields: Data_Import_Min_Fields;
  data_import_mutation_response: Data_Import_Mutation_Response;
  data_import_on_conflict: Data_Import_On_Conflict;
  data_import_order_by: Data_Import_Order_By;
  data_import_status_enum_comparison_exp: Data_Import_Status_Enum_Comparison_Exp;
  data_import_stream_cursor_input: Data_Import_Stream_Cursor_Input;
  data_import_stream_cursor_value_input: Data_Import_Stream_Cursor_Value_Input;
  department: Department;
  department_aggregate_order_by: Department_Aggregate_Order_By;
  department_arr_rel_insert_input: Department_Arr_Rel_Insert_Input;
  department_audit: Department_Audit;
  department_audit_aggregate_order_by: Department_Audit_Aggregate_Order_By;
  department_audit_bool_exp: Department_Audit_Bool_Exp;
  department_audit_max_order_by: Department_Audit_Max_Order_By;
  department_audit_min_order_by: Department_Audit_Min_Order_By;
  department_audit_order_by: Department_Audit_Order_By;
  department_audit_stream_cursor_input: Department_Audit_Stream_Cursor_Input;
  department_audit_stream_cursor_value_input: Department_Audit_Stream_Cursor_Value_Input;
  department_bool_exp: Department_Bool_Exp;
  department_insert_input: Department_Insert_Input;
  department_max_order_by: Department_Max_Order_By;
  department_min_order_by: Department_Min_Order_By;
  department_mutation_response: Department_Mutation_Response;
  department_on_conflict: Department_On_Conflict;
  department_order_by: Department_Order_By;
  department_type: Department_Type;
  department_type_aggregate_order_by: Department_Type_Aggregate_Order_By;
  department_type_arr_rel_insert_input: Department_Type_Arr_Rel_Insert_Input;
  department_type_audit: Department_Type_Audit;
  department_type_audit_bool_exp: Department_Type_Audit_Bool_Exp;
  department_type_audit_order_by: Department_Type_Audit_Order_By;
  department_type_audit_stream_cursor_input: Department_Type_Audit_Stream_Cursor_Input;
  department_type_audit_stream_cursor_value_input: Department_Type_Audit_Stream_Cursor_Value_Input;
  department_type_bool_exp: Department_Type_Bool_Exp;
  department_type_group: Department_Type_Group;
  department_type_group_audit: Department_Type_Group_Audit;
  department_type_group_audit_bool_exp: Department_Type_Group_Audit_Bool_Exp;
  department_type_group_audit_order_by: Department_Type_Group_Audit_Order_By;
  department_type_group_audit_stream_cursor_input: Department_Type_Group_Audit_Stream_Cursor_Input;
  department_type_group_audit_stream_cursor_value_input: Department_Type_Group_Audit_Stream_Cursor_Value_Input;
  department_type_group_bool_exp: Department_Type_Group_Bool_Exp;
  department_type_group_insert_input: Department_Type_Group_Insert_Input;
  department_type_group_mutation_response: Department_Type_Group_Mutation_Response;
  department_type_group_obj_rel_insert_input: Department_Type_Group_Obj_Rel_Insert_Input;
  department_type_group_on_conflict: Department_Type_Group_On_Conflict;
  department_type_group_order_by: Department_Type_Group_Order_By;
  department_type_group_set_input: Department_Type_Group_Set_Input;
  department_type_group_stream_cursor_input: Department_Type_Group_Stream_Cursor_Input;
  department_type_group_stream_cursor_value_input: Department_Type_Group_Stream_Cursor_Value_Input;
  department_type_group_updates: Department_Type_Group_Updates;
  department_type_insert_input: Department_Type_Insert_Input;
  department_type_max_order_by: Department_Type_Max_Order_By;
  department_type_min_order_by: Department_Type_Min_Order_By;
  department_type_mutation_response: Department_Type_Mutation_Response;
  department_type_obj_rel_insert_input: Department_Type_Obj_Rel_Insert_Input;
  department_type_on_conflict: Department_Type_On_Conflict;
  department_type_order_by: Department_Type_Order_By;
  department_type_pk_columns_input: Department_Type_Pk_Columns_Input;
  department_type_set_input: Department_Type_Set_Input;
  department_type_stream_cursor_input: Department_Type_Stream_Cursor_Input;
  department_type_stream_cursor_value_input: Department_Type_Stream_Cursor_Value_Input;
  department_type_updates: Department_Type_Updates;
  document: Document;
  document_assessment_result: Document_Assessment_Result;
  document_assessment_result_aggregate: Document_Assessment_Result_Aggregate;
  document_assessment_result_aggregate_fields: Document_Assessment_Result_Aggregate_Fields;
  document_assessment_result_append_input: Document_Assessment_Result_Append_Input;
  document_assessment_result_audit: Document_Assessment_Result_Audit;
  document_assessment_result_audit_aggregate: Document_Assessment_Result_Audit_Aggregate;
  document_assessment_result_audit_aggregate_fields: Document_Assessment_Result_Audit_Aggregate_Fields;
  document_assessment_result_audit_avg_fields: Document_Assessment_Result_Audit_Avg_Fields;
  document_assessment_result_audit_bool_exp: Document_Assessment_Result_Audit_Bool_Exp;
  document_assessment_result_audit_max_fields: Document_Assessment_Result_Audit_Max_Fields;
  document_assessment_result_audit_min_fields: Document_Assessment_Result_Audit_Min_Fields;
  document_assessment_result_audit_order_by: Document_Assessment_Result_Audit_Order_By;
  document_assessment_result_audit_stddev_fields: Document_Assessment_Result_Audit_Stddev_Fields;
  document_assessment_result_audit_stddev_pop_fields: Document_Assessment_Result_Audit_Stddev_Pop_Fields;
  document_assessment_result_audit_stddev_samp_fields: Document_Assessment_Result_Audit_Stddev_Samp_Fields;
  document_assessment_result_audit_stream_cursor_input: Document_Assessment_Result_Audit_Stream_Cursor_Input;
  document_assessment_result_audit_stream_cursor_value_input: Document_Assessment_Result_Audit_Stream_Cursor_Value_Input;
  document_assessment_result_audit_sum_fields: Document_Assessment_Result_Audit_Sum_Fields;
  document_assessment_result_audit_var_pop_fields: Document_Assessment_Result_Audit_Var_Pop_Fields;
  document_assessment_result_audit_var_samp_fields: Document_Assessment_Result_Audit_Var_Samp_Fields;
  document_assessment_result_audit_variance_fields: Document_Assessment_Result_Audit_Variance_Fields;
  document_assessment_result_avg_fields: Document_Assessment_Result_Avg_Fields;
  document_assessment_result_bool_exp: Document_Assessment_Result_Bool_Exp;
  document_assessment_result_delete_at_path_input: Document_Assessment_Result_Delete_At_Path_Input;
  document_assessment_result_delete_elem_input: Document_Assessment_Result_Delete_Elem_Input;
  document_assessment_result_delete_key_input: Document_Assessment_Result_Delete_Key_Input;
  document_assessment_result_inc_input: Document_Assessment_Result_Inc_Input;
  document_assessment_result_insert_input: Document_Assessment_Result_Insert_Input;
  document_assessment_result_max_fields: Document_Assessment_Result_Max_Fields;
  document_assessment_result_min_fields: Document_Assessment_Result_Min_Fields;
  document_assessment_result_mutation_response: Document_Assessment_Result_Mutation_Response;
  document_assessment_result_obj_rel_insert_input: Document_Assessment_Result_Obj_Rel_Insert_Input;
  document_assessment_result_on_conflict: Document_Assessment_Result_On_Conflict;
  document_assessment_result_order_by: Document_Assessment_Result_Order_By;
  document_assessment_result_pk_columns_input: Document_Assessment_Result_Pk_Columns_Input;
  document_assessment_result_prepend_input: Document_Assessment_Result_Prepend_Input;
  document_assessment_result_set_input: Document_Assessment_Result_Set_Input;
  document_assessment_result_stddev_fields: Document_Assessment_Result_Stddev_Fields;
  document_assessment_result_stddev_pop_fields: Document_Assessment_Result_Stddev_Pop_Fields;
  document_assessment_result_stddev_samp_fields: Document_Assessment_Result_Stddev_Samp_Fields;
  document_assessment_result_stream_cursor_input: Document_Assessment_Result_Stream_Cursor_Input;
  document_assessment_result_stream_cursor_value_input: Document_Assessment_Result_Stream_Cursor_Value_Input;
  document_assessment_result_sum_fields: Document_Assessment_Result_Sum_Fields;
  document_assessment_result_updates: Document_Assessment_Result_Updates;
  document_assessment_result_var_pop_fields: Document_Assessment_Result_Var_Pop_Fields;
  document_assessment_result_var_samp_fields: Document_Assessment_Result_Var_Samp_Fields;
  document_assessment_result_variance_fields: Document_Assessment_Result_Variance_Fields;
  document_audit: Document_Audit;
  document_audit_bool_exp: Document_Audit_Bool_Exp;
  document_audit_order_by: Document_Audit_Order_By;
  document_audit_stream_cursor_input: Document_Audit_Stream_Cursor_Input;
  document_audit_stream_cursor_value_input: Document_Audit_Stream_Cursor_Value_Input;
  document_bool_exp: Document_Bool_Exp;
  document_file: Document_File;
  document_file_aggregate_order_by: Document_File_Aggregate_Order_By;
  document_file_arr_rel_insert_input: Document_File_Arr_Rel_Insert_Input;
  document_file_audit: Document_File_Audit;
  document_file_audit_bool_exp: Document_File_Audit_Bool_Exp;
  document_file_audit_order_by: Document_File_Audit_Order_By;
  document_file_audit_stream_cursor_input: Document_File_Audit_Stream_Cursor_Input;
  document_file_audit_stream_cursor_value_input: Document_File_Audit_Stream_Cursor_Value_Input;
  document_file_bool_exp: Document_File_Bool_Exp;
  document_file_insert_input: Document_File_Insert_Input;
  document_file_max_order_by: Document_File_Max_Order_By;
  document_file_min_order_by: Document_File_Min_Order_By;
  document_file_mutation_response: Document_File_Mutation_Response;
  document_file_obj_rel_insert_input: Document_File_Obj_Rel_Insert_Input;
  document_file_on_conflict: Document_File_On_Conflict;
  document_file_order_by: Document_File_Order_By;
  document_file_stream_cursor_input: Document_File_Stream_Cursor_Input;
  document_file_stream_cursor_value_input: Document_File_Stream_Cursor_Value_Input;
  document_file_type_enum_comparison_exp: Document_File_Type_Enum_Comparison_Exp;
  document_insert_input: Document_Insert_Input;
  document_linked_document: Document_Linked_Document;
  document_linked_document_aggregate_order_by: Document_Linked_Document_Aggregate_Order_By;
  document_linked_document_arr_rel_insert_input: Document_Linked_Document_Arr_Rel_Insert_Input;
  document_linked_document_audit: Document_Linked_Document_Audit;
  document_linked_document_audit_bool_exp: Document_Linked_Document_Audit_Bool_Exp;
  document_linked_document_audit_order_by: Document_Linked_Document_Audit_Order_By;
  document_linked_document_audit_stream_cursor_input: Document_Linked_Document_Audit_Stream_Cursor_Input;
  document_linked_document_audit_stream_cursor_value_input: Document_Linked_Document_Audit_Stream_Cursor_Value_Input;
  document_linked_document_bool_exp: Document_Linked_Document_Bool_Exp;
  document_linked_document_insert_input: Document_Linked_Document_Insert_Input;
  document_linked_document_max_order_by: Document_Linked_Document_Max_Order_By;
  document_linked_document_min_order_by: Document_Linked_Document_Min_Order_By;
  document_linked_document_mutation_response: Document_Linked_Document_Mutation_Response;
  document_linked_document_on_conflict: Document_Linked_Document_On_Conflict;
  document_linked_document_order_by: Document_Linked_Document_Order_By;
  document_linked_document_stream_cursor_input: Document_Linked_Document_Stream_Cursor_Input;
  document_linked_document_stream_cursor_value_input: Document_Linked_Document_Stream_Cursor_Value_Input;
  document_obj_rel_insert_input: Document_Obj_Rel_Insert_Input;
  document_on_conflict: Document_On_Conflict;
  document_order_by: Document_Order_By;
  document_stream_cursor_input: Document_Stream_Cursor_Input;
  document_stream_cursor_value_input: Document_Stream_Cursor_Value_Input;
  file: File;
  file_aggregate: File_Aggregate;
  file_aggregate_fields: File_Aggregate_Fields;
  file_audit: File_Audit;
  file_audit_bool_exp: File_Audit_Bool_Exp;
  file_audit_order_by: File_Audit_Order_By;
  file_audit_stream_cursor_input: File_Audit_Stream_Cursor_Input;
  file_audit_stream_cursor_value_input: File_Audit_Stream_Cursor_Value_Input;
  file_avg_fields: File_Avg_Fields;
  file_bool_exp: File_Bool_Exp;
  file_insert_input: File_Insert_Input;
  file_max_fields: File_Max_Fields;
  file_min_fields: File_Min_Fields;
  file_mutation_response: File_Mutation_Response;
  file_obj_rel_insert_input: File_Obj_Rel_Insert_Input;
  file_on_conflict: File_On_Conflict;
  file_order_by: File_Order_By;
  file_stddev_fields: File_Stddev_Fields;
  file_stddev_pop_fields: File_Stddev_Pop_Fields;
  file_stddev_samp_fields: File_Stddev_Samp_Fields;
  file_stream_cursor_input: File_Stream_Cursor_Input;
  file_stream_cursor_value_input: File_Stream_Cursor_Value_Input;
  file_sum_fields: File_Sum_Fields;
  file_var_pop_fields: File_Var_Pop_Fields;
  file_var_samp_fields: File_Var_Samp_Fields;
  file_variance_fields: File_Variance_Fields;
  float8: Scalars['float8']['output'];
  float8_comparison_exp: Float8_Comparison_Exp;
  form_configuration: Form_Configuration;
  form_configuration_audit: Form_Configuration_Audit;
  form_configuration_audit_bool_exp: Form_Configuration_Audit_Bool_Exp;
  form_configuration_audit_order_by: Form_Configuration_Audit_Order_By;
  form_configuration_audit_stream_cursor_input: Form_Configuration_Audit_Stream_Cursor_Input;
  form_configuration_audit_stream_cursor_value_input: Form_Configuration_Audit_Stream_Cursor_Value_Input;
  form_configuration_bool_exp: Form_Configuration_Bool_Exp;
  form_configuration_insert_input: Form_Configuration_Insert_Input;
  form_configuration_mutation_response: Form_Configuration_Mutation_Response;
  form_configuration_obj_rel_insert_input: Form_Configuration_Obj_Rel_Insert_Input;
  form_configuration_on_conflict: Form_Configuration_On_Conflict;
  form_configuration_order_by: Form_Configuration_Order_By;
  form_configuration_set_input: Form_Configuration_Set_Input;
  form_configuration_stream_cursor_input: Form_Configuration_Stream_Cursor_Input;
  form_configuration_stream_cursor_value_input: Form_Configuration_Stream_Cursor_Value_Input;
  form_configuration_updates: Form_Configuration_Updates;
  form_field_configuration: Form_Field_Configuration;
  form_field_configuration_aggregate_order_by: Form_Field_Configuration_Aggregate_Order_By;
  form_field_configuration_arr_rel_insert_input: Form_Field_Configuration_Arr_Rel_Insert_Input;
  form_field_configuration_audit: Form_Field_Configuration_Audit;
  form_field_configuration_audit_bool_exp: Form_Field_Configuration_Audit_Bool_Exp;
  form_field_configuration_audit_order_by: Form_Field_Configuration_Audit_Order_By;
  form_field_configuration_audit_stream_cursor_input: Form_Field_Configuration_Audit_Stream_Cursor_Input;
  form_field_configuration_audit_stream_cursor_value_input: Form_Field_Configuration_Audit_Stream_Cursor_Value_Input;
  form_field_configuration_bool_exp: Form_Field_Configuration_Bool_Exp;
  form_field_configuration_insert_input: Form_Field_Configuration_Insert_Input;
  form_field_configuration_max_order_by: Form_Field_Configuration_Max_Order_By;
  form_field_configuration_min_order_by: Form_Field_Configuration_Min_Order_By;
  form_field_configuration_mutation_response: Form_Field_Configuration_Mutation_Response;
  form_field_configuration_on_conflict: Form_Field_Configuration_On_Conflict;
  form_field_configuration_order_by: Form_Field_Configuration_Order_By;
  form_field_configuration_set_input: Form_Field_Configuration_Set_Input;
  form_field_configuration_stream_cursor_input: Form_Field_Configuration_Stream_Cursor_Input;
  form_field_configuration_stream_cursor_value_input: Form_Field_Configuration_Stream_Cursor_Value_Input;
  form_field_configuration_updates: Form_Field_Configuration_Updates;
  form_field_ordering: Form_Field_Ordering;
  form_field_ordering_aggregate_order_by: Form_Field_Ordering_Aggregate_Order_By;
  form_field_ordering_arr_rel_insert_input: Form_Field_Ordering_Arr_Rel_Insert_Input;
  form_field_ordering_audit: Form_Field_Ordering_Audit;
  form_field_ordering_audit_bool_exp: Form_Field_Ordering_Audit_Bool_Exp;
  form_field_ordering_audit_order_by: Form_Field_Ordering_Audit_Order_By;
  form_field_ordering_audit_stream_cursor_input: Form_Field_Ordering_Audit_Stream_Cursor_Input;
  form_field_ordering_audit_stream_cursor_value_input: Form_Field_Ordering_Audit_Stream_Cursor_Value_Input;
  form_field_ordering_avg_order_by: Form_Field_Ordering_Avg_Order_By;
  form_field_ordering_bool_exp: Form_Field_Ordering_Bool_Exp;
  form_field_ordering_inc_input: Form_Field_Ordering_Inc_Input;
  form_field_ordering_insert_input: Form_Field_Ordering_Insert_Input;
  form_field_ordering_max_order_by: Form_Field_Ordering_Max_Order_By;
  form_field_ordering_min_order_by: Form_Field_Ordering_Min_Order_By;
  form_field_ordering_mutation_response: Form_Field_Ordering_Mutation_Response;
  form_field_ordering_on_conflict: Form_Field_Ordering_On_Conflict;
  form_field_ordering_order_by: Form_Field_Ordering_Order_By;
  form_field_ordering_set_input: Form_Field_Ordering_Set_Input;
  form_field_ordering_stddev_order_by: Form_Field_Ordering_Stddev_Order_By;
  form_field_ordering_stddev_pop_order_by: Form_Field_Ordering_Stddev_Pop_Order_By;
  form_field_ordering_stddev_samp_order_by: Form_Field_Ordering_Stddev_Samp_Order_By;
  form_field_ordering_stream_cursor_input: Form_Field_Ordering_Stream_Cursor_Input;
  form_field_ordering_stream_cursor_value_input: Form_Field_Ordering_Stream_Cursor_Value_Input;
  form_field_ordering_sum_order_by: Form_Field_Ordering_Sum_Order_By;
  form_field_ordering_updates: Form_Field_Ordering_Updates;
  form_field_ordering_var_pop_order_by: Form_Field_Ordering_Var_Pop_Order_By;
  form_field_ordering_var_samp_order_by: Form_Field_Ordering_Var_Samp_Order_By;
  form_field_ordering_variance_order_by: Form_Field_Ordering_Variance_Order_By;
  impact: Impact;
  impact_aggregate: Impact_Aggregate;
  impact_aggregate_fields: Impact_Aggregate_Fields;
  impact_append_input: Impact_Append_Input;
  impact_audit: Impact_Audit;
  impact_audit_bool_exp: Impact_Audit_Bool_Exp;
  impact_audit_order_by: Impact_Audit_Order_By;
  impact_audit_stream_cursor_input: Impact_Audit_Stream_Cursor_Input;
  impact_audit_stream_cursor_value_input: Impact_Audit_Stream_Cursor_Value_Input;
  impact_avg_fields: Impact_Avg_Fields;
  impact_bool_exp: Impact_Bool_Exp;
  impact_delete_at_path_input: Impact_Delete_At_Path_Input;
  impact_delete_elem_input: Impact_Delete_Elem_Input;
  impact_delete_key_input: Impact_Delete_Key_Input;
  impact_inc_input: Impact_Inc_Input;
  impact_insert_input: Impact_Insert_Input;
  impact_max_fields: Impact_Max_Fields;
  impact_min_fields: Impact_Min_Fields;
  impact_mutation_response: Impact_Mutation_Response;
  impact_obj_rel_insert_input: Impact_Obj_Rel_Insert_Input;
  impact_on_conflict: Impact_On_Conflict;
  impact_order_by: Impact_Order_By;
  impact_parent: Impact_Parent;
  impact_parent_aggregate: Impact_Parent_Aggregate;
  impact_parent_aggregate_bool_exp: Impact_Parent_Aggregate_Bool_Exp;
  impact_parent_aggregate_bool_exp_count: Impact_Parent_Aggregate_Bool_Exp_Count;
  impact_parent_aggregate_fields: Impact_Parent_Aggregate_Fields;
  impact_parent_aggregate_order_by: Impact_Parent_Aggregate_Order_By;
  impact_parent_arr_rel_insert_input: Impact_Parent_Arr_Rel_Insert_Input;
  impact_parent_audit: Impact_Parent_Audit;
  impact_parent_audit_bool_exp: Impact_Parent_Audit_Bool_Exp;
  impact_parent_audit_order_by: Impact_Parent_Audit_Order_By;
  impact_parent_audit_stream_cursor_input: Impact_Parent_Audit_Stream_Cursor_Input;
  impact_parent_audit_stream_cursor_value_input: Impact_Parent_Audit_Stream_Cursor_Value_Input;
  impact_parent_bool_exp: Impact_Parent_Bool_Exp;
  impact_parent_insert_input: Impact_Parent_Insert_Input;
  impact_parent_max_fields: Impact_Parent_Max_Fields;
  impact_parent_max_order_by: Impact_Parent_Max_Order_By;
  impact_parent_min_fields: Impact_Parent_Min_Fields;
  impact_parent_min_order_by: Impact_Parent_Min_Order_By;
  impact_parent_on_conflict: Impact_Parent_On_Conflict;
  impact_parent_order_by: Impact_Parent_Order_By;
  impact_parent_stream_cursor_input: Impact_Parent_Stream_Cursor_Input;
  impact_parent_stream_cursor_value_input: Impact_Parent_Stream_Cursor_Value_Input;
  impact_pk_columns_input: Impact_Pk_Columns_Input;
  impact_prepend_input: Impact_Prepend_Input;
  impact_rating: Impact_Rating;
  impact_rating_aggregate: Impact_Rating_Aggregate;
  impact_rating_aggregate_bool_exp: Impact_Rating_Aggregate_Bool_Exp;
  impact_rating_aggregate_bool_exp_count: Impact_Rating_Aggregate_Bool_Exp_Count;
  impact_rating_aggregate_fields: Impact_Rating_Aggregate_Fields;
  impact_rating_aggregate_order_by: Impact_Rating_Aggregate_Order_By;
  impact_rating_arr_rel_insert_input: Impact_Rating_Arr_Rel_Insert_Input;
  impact_rating_audit: Impact_Rating_Audit;
  impact_rating_audit_aggregate_order_by: Impact_Rating_Audit_Aggregate_Order_By;
  impact_rating_audit_avg_order_by: Impact_Rating_Audit_Avg_Order_By;
  impact_rating_audit_bool_exp: Impact_Rating_Audit_Bool_Exp;
  impact_rating_audit_max_order_by: Impact_Rating_Audit_Max_Order_By;
  impact_rating_audit_min_order_by: Impact_Rating_Audit_Min_Order_By;
  impact_rating_audit_order_by: Impact_Rating_Audit_Order_By;
  impact_rating_audit_stddev_order_by: Impact_Rating_Audit_Stddev_Order_By;
  impact_rating_audit_stddev_pop_order_by: Impact_Rating_Audit_Stddev_Pop_Order_By;
  impact_rating_audit_stddev_samp_order_by: Impact_Rating_Audit_Stddev_Samp_Order_By;
  impact_rating_audit_stream_cursor_input: Impact_Rating_Audit_Stream_Cursor_Input;
  impact_rating_audit_stream_cursor_value_input: Impact_Rating_Audit_Stream_Cursor_Value_Input;
  impact_rating_audit_sum_order_by: Impact_Rating_Audit_Sum_Order_By;
  impact_rating_audit_var_pop_order_by: Impact_Rating_Audit_Var_Pop_Order_By;
  impact_rating_audit_var_samp_order_by: Impact_Rating_Audit_Var_Samp_Order_By;
  impact_rating_audit_variance_order_by: Impact_Rating_Audit_Variance_Order_By;
  impact_rating_avg_fields: Impact_Rating_Avg_Fields;
  impact_rating_avg_order_by: Impact_Rating_Avg_Order_By;
  impact_rating_bool_exp: Impact_Rating_Bool_Exp;
  impact_rating_insert_input: Impact_Rating_Insert_Input;
  impact_rating_max_fields: Impact_Rating_Max_Fields;
  impact_rating_max_order_by: Impact_Rating_Max_Order_By;
  impact_rating_min_fields: Impact_Rating_Min_Fields;
  impact_rating_min_order_by: Impact_Rating_Min_Order_By;
  impact_rating_mutation_response: Impact_Rating_Mutation_Response;
  impact_rating_obj_rel_insert_input: Impact_Rating_Obj_Rel_Insert_Input;
  impact_rating_on_conflict: Impact_Rating_On_Conflict;
  impact_rating_order_by: Impact_Rating_Order_By;
  impact_rating_stddev_fields: Impact_Rating_Stddev_Fields;
  impact_rating_stddev_order_by: Impact_Rating_Stddev_Order_By;
  impact_rating_stddev_pop_fields: Impact_Rating_Stddev_Pop_Fields;
  impact_rating_stddev_pop_order_by: Impact_Rating_Stddev_Pop_Order_By;
  impact_rating_stddev_samp_fields: Impact_Rating_Stddev_Samp_Fields;
  impact_rating_stddev_samp_order_by: Impact_Rating_Stddev_Samp_Order_By;
  impact_rating_stream_cursor_input: Impact_Rating_Stream_Cursor_Input;
  impact_rating_stream_cursor_value_input: Impact_Rating_Stream_Cursor_Value_Input;
  impact_rating_sum_fields: Impact_Rating_Sum_Fields;
  impact_rating_sum_order_by: Impact_Rating_Sum_Order_By;
  impact_rating_var_pop_fields: Impact_Rating_Var_Pop_Fields;
  impact_rating_var_pop_order_by: Impact_Rating_Var_Pop_Order_By;
  impact_rating_var_samp_fields: Impact_Rating_Var_Samp_Fields;
  impact_rating_var_samp_order_by: Impact_Rating_Var_Samp_Order_By;
  impact_rating_variance_fields: Impact_Rating_Variance_Fields;
  impact_rating_variance_order_by: Impact_Rating_Variance_Order_By;
  impact_set_input: Impact_Set_Input;
  impact_stddev_fields: Impact_Stddev_Fields;
  impact_stddev_pop_fields: Impact_Stddev_Pop_Fields;
  impact_stddev_samp_fields: Impact_Stddev_Samp_Fields;
  impact_stream_cursor_input: Impact_Stream_Cursor_Input;
  impact_stream_cursor_value_input: Impact_Stream_Cursor_Value_Input;
  impact_sum_fields: Impact_Sum_Fields;
  impact_updates: Impact_Updates;
  impact_var_pop_fields: Impact_Var_Pop_Fields;
  impact_var_samp_fields: Impact_Var_Samp_Fields;
  impact_variance_fields: Impact_Variance_Fields;
  indicator: Indicator;
  indicator_aggregate_order_by: Indicator_Aggregate_Order_By;
  indicator_audit: Indicator_Audit;
  indicator_audit_bool_exp: Indicator_Audit_Bool_Exp;
  indicator_audit_order_by: Indicator_Audit_Order_By;
  indicator_audit_stream_cursor_input: Indicator_Audit_Stream_Cursor_Input;
  indicator_audit_stream_cursor_value_input: Indicator_Audit_Stream_Cursor_Value_Input;
  indicator_avg_order_by: Indicator_Avg_Order_By;
  indicator_bool_exp: Indicator_Bool_Exp;
  indicator_insert_input: Indicator_Insert_Input;
  indicator_max_order_by: Indicator_Max_Order_By;
  indicator_min_order_by: Indicator_Min_Order_By;
  indicator_mutation_response: Indicator_Mutation_Response;
  indicator_obj_rel_insert_input: Indicator_Obj_Rel_Insert_Input;
  indicator_on_conflict: Indicator_On_Conflict;
  indicator_order_by: Indicator_Order_By;
  indicator_parent: Indicator_Parent;
  indicator_parent_aggregate: Indicator_Parent_Aggregate;
  indicator_parent_aggregate_bool_exp: Indicator_Parent_Aggregate_Bool_Exp;
  indicator_parent_aggregate_bool_exp_count: Indicator_Parent_Aggregate_Bool_Exp_Count;
  indicator_parent_aggregate_fields: Indicator_Parent_Aggregate_Fields;
  indicator_parent_aggregate_order_by: Indicator_Parent_Aggregate_Order_By;
  indicator_parent_arr_rel_insert_input: Indicator_Parent_Arr_Rel_Insert_Input;
  indicator_parent_bool_exp: Indicator_Parent_Bool_Exp;
  indicator_parent_insert_input: Indicator_Parent_Insert_Input;
  indicator_parent_max_fields: Indicator_Parent_Max_Fields;
  indicator_parent_max_order_by: Indicator_Parent_Max_Order_By;
  indicator_parent_min_fields: Indicator_Parent_Min_Fields;
  indicator_parent_min_order_by: Indicator_Parent_Min_Order_By;
  indicator_parent_mutation_response: Indicator_Parent_Mutation_Response;
  indicator_parent_on_conflict: Indicator_Parent_On_Conflict;
  indicator_parent_order_by: Indicator_Parent_Order_By;
  indicator_parent_stream_cursor_input: Indicator_Parent_Stream_Cursor_Input;
  indicator_parent_stream_cursor_value_input: Indicator_Parent_Stream_Cursor_Value_Input;
  indicator_result: Indicator_Result;
  indicator_result_aggregate_order_by: Indicator_Result_Aggregate_Order_By;
  indicator_result_append_input: Indicator_Result_Append_Input;
  indicator_result_arr_rel_insert_input: Indicator_Result_Arr_Rel_Insert_Input;
  indicator_result_audit: Indicator_Result_Audit;
  indicator_result_audit_bool_exp: Indicator_Result_Audit_Bool_Exp;
  indicator_result_audit_order_by: Indicator_Result_Audit_Order_By;
  indicator_result_audit_stream_cursor_input: Indicator_Result_Audit_Stream_Cursor_Input;
  indicator_result_audit_stream_cursor_value_input: Indicator_Result_Audit_Stream_Cursor_Value_Input;
  indicator_result_avg_order_by: Indicator_Result_Avg_Order_By;
  indicator_result_bool_exp: Indicator_Result_Bool_Exp;
  indicator_result_delete_at_path_input: Indicator_Result_Delete_At_Path_Input;
  indicator_result_delete_elem_input: Indicator_Result_Delete_Elem_Input;
  indicator_result_delete_key_input: Indicator_Result_Delete_Key_Input;
  indicator_result_inc_input: Indicator_Result_Inc_Input;
  indicator_result_insert_input: Indicator_Result_Insert_Input;
  indicator_result_max_order_by: Indicator_Result_Max_Order_By;
  indicator_result_min_order_by: Indicator_Result_Min_Order_By;
  indicator_result_mutation_response: Indicator_Result_Mutation_Response;
  indicator_result_on_conflict: Indicator_Result_On_Conflict;
  indicator_result_order_by: Indicator_Result_Order_By;
  indicator_result_pk_columns_input: Indicator_Result_Pk_Columns_Input;
  indicator_result_prepend_input: Indicator_Result_Prepend_Input;
  indicator_result_set_input: Indicator_Result_Set_Input;
  indicator_result_stddev_order_by: Indicator_Result_Stddev_Order_By;
  indicator_result_stddev_pop_order_by: Indicator_Result_Stddev_Pop_Order_By;
  indicator_result_stddev_samp_order_by: Indicator_Result_Stddev_Samp_Order_By;
  indicator_result_stream_cursor_input: Indicator_Result_Stream_Cursor_Input;
  indicator_result_stream_cursor_value_input: Indicator_Result_Stream_Cursor_Value_Input;
  indicator_result_sum_order_by: Indicator_Result_Sum_Order_By;
  indicator_result_updates: Indicator_Result_Updates;
  indicator_result_var_pop_order_by: Indicator_Result_Var_Pop_Order_By;
  indicator_result_var_samp_order_by: Indicator_Result_Var_Samp_Order_By;
  indicator_result_variance_order_by: Indicator_Result_Variance_Order_By;
  indicator_stddev_order_by: Indicator_Stddev_Order_By;
  indicator_stddev_pop_order_by: Indicator_Stddev_Pop_Order_By;
  indicator_stddev_samp_order_by: Indicator_Stddev_Samp_Order_By;
  indicator_stream_cursor_input: Indicator_Stream_Cursor_Input;
  indicator_stream_cursor_value_input: Indicator_Stream_Cursor_Value_Input;
  indicator_sum_order_by: Indicator_Sum_Order_By;
  indicator_type: Indicator_Type;
  indicator_type_bool_exp: Indicator_Type_Bool_Exp;
  indicator_type_enum_comparison_exp: Indicator_Type_Enum_Comparison_Exp;
  indicator_type_order_by: Indicator_Type_Order_By;
  indicator_type_stream_cursor_input: Indicator_Type_Stream_Cursor_Input;
  indicator_type_stream_cursor_value_input: Indicator_Type_Stream_Cursor_Value_Input;
  indicator_var_pop_order_by: Indicator_Var_Pop_Order_By;
  indicator_var_samp_order_by: Indicator_Var_Samp_Order_By;
  indicator_variance_order_by: Indicator_Variance_Order_By;
  insertChildImpactRatingOutput: InsertChildImpactRatingOutput;
  insertControlTestResultOutput: InsertControlTestResultOutput;
  internal_audit_entity: Internal_Audit_Entity;
  internal_audit_entity_aggregate: Internal_Audit_Entity_Aggregate;
  internal_audit_entity_aggregate_bool_exp: Internal_Audit_Entity_Aggregate_Bool_Exp;
  internal_audit_entity_aggregate_bool_exp_count: Internal_Audit_Entity_Aggregate_Bool_Exp_Count;
  internal_audit_entity_aggregate_fields: Internal_Audit_Entity_Aggregate_Fields;
  internal_audit_entity_aggregate_order_by: Internal_Audit_Entity_Aggregate_Order_By;
  internal_audit_entity_arr_rel_insert_input: Internal_Audit_Entity_Arr_Rel_Insert_Input;
  internal_audit_entity_avg_fields: Internal_Audit_Entity_Avg_Fields;
  internal_audit_entity_avg_order_by: Internal_Audit_Entity_Avg_Order_By;
  internal_audit_entity_bool_exp: Internal_Audit_Entity_Bool_Exp;
  internal_audit_entity_insert_input: Internal_Audit_Entity_Insert_Input;
  internal_audit_entity_max_fields: Internal_Audit_Entity_Max_Fields;
  internal_audit_entity_max_order_by: Internal_Audit_Entity_Max_Order_By;
  internal_audit_entity_min_fields: Internal_Audit_Entity_Min_Fields;
  internal_audit_entity_min_order_by: Internal_Audit_Entity_Min_Order_By;
  internal_audit_entity_mutation_response: Internal_Audit_Entity_Mutation_Response;
  internal_audit_entity_obj_rel_insert_input: Internal_Audit_Entity_Obj_Rel_Insert_Input;
  internal_audit_entity_on_conflict: Internal_Audit_Entity_On_Conflict;
  internal_audit_entity_order_by: Internal_Audit_Entity_Order_By;
  internal_audit_entity_stddev_fields: Internal_Audit_Entity_Stddev_Fields;
  internal_audit_entity_stddev_order_by: Internal_Audit_Entity_Stddev_Order_By;
  internal_audit_entity_stddev_pop_fields: Internal_Audit_Entity_Stddev_Pop_Fields;
  internal_audit_entity_stddev_pop_order_by: Internal_Audit_Entity_Stddev_Pop_Order_By;
  internal_audit_entity_stddev_samp_fields: Internal_Audit_Entity_Stddev_Samp_Fields;
  internal_audit_entity_stddev_samp_order_by: Internal_Audit_Entity_Stddev_Samp_Order_By;
  internal_audit_entity_stream_cursor_input: Internal_Audit_Entity_Stream_Cursor_Input;
  internal_audit_entity_stream_cursor_value_input: Internal_Audit_Entity_Stream_Cursor_Value_Input;
  internal_audit_entity_sum_fields: Internal_Audit_Entity_Sum_Fields;
  internal_audit_entity_sum_order_by: Internal_Audit_Entity_Sum_Order_By;
  internal_audit_entity_var_pop_fields: Internal_Audit_Entity_Var_Pop_Fields;
  internal_audit_entity_var_pop_order_by: Internal_Audit_Entity_Var_Pop_Order_By;
  internal_audit_entity_var_samp_fields: Internal_Audit_Entity_Var_Samp_Fields;
  internal_audit_entity_var_samp_order_by: Internal_Audit_Entity_Var_Samp_Order_By;
  internal_audit_entity_variance_fields: Internal_Audit_Entity_Variance_Fields;
  internal_audit_entity_variance_order_by: Internal_Audit_Entity_Variance_Order_By;
  internal_audit_report: Internal_Audit_Report;
  internal_audit_report_aggregate: Internal_Audit_Report_Aggregate;
  internal_audit_report_aggregate_bool_exp: Internal_Audit_Report_Aggregate_Bool_Exp;
  internal_audit_report_aggregate_bool_exp_count: Internal_Audit_Report_Aggregate_Bool_Exp_Count;
  internal_audit_report_aggregate_fields: Internal_Audit_Report_Aggregate_Fields;
  internal_audit_report_aggregate_order_by: Internal_Audit_Report_Aggregate_Order_By;
  internal_audit_report_append_input: Internal_Audit_Report_Append_Input;
  internal_audit_report_arr_rel_insert_input: Internal_Audit_Report_Arr_Rel_Insert_Input;
  internal_audit_report_avg_fields: Internal_Audit_Report_Avg_Fields;
  internal_audit_report_avg_order_by: Internal_Audit_Report_Avg_Order_By;
  internal_audit_report_bool_exp: Internal_Audit_Report_Bool_Exp;
  internal_audit_report_delete_at_path_input: Internal_Audit_Report_Delete_At_Path_Input;
  internal_audit_report_delete_elem_input: Internal_Audit_Report_Delete_Elem_Input;
  internal_audit_report_delete_key_input: Internal_Audit_Report_Delete_Key_Input;
  internal_audit_report_inc_input: Internal_Audit_Report_Inc_Input;
  internal_audit_report_insert_input: Internal_Audit_Report_Insert_Input;
  internal_audit_report_max_fields: Internal_Audit_Report_Max_Fields;
  internal_audit_report_max_order_by: Internal_Audit_Report_Max_Order_By;
  internal_audit_report_min_fields: Internal_Audit_Report_Min_Fields;
  internal_audit_report_min_order_by: Internal_Audit_Report_Min_Order_By;
  internal_audit_report_mutation_response: Internal_Audit_Report_Mutation_Response;
  internal_audit_report_obj_rel_insert_input: Internal_Audit_Report_Obj_Rel_Insert_Input;
  internal_audit_report_on_conflict: Internal_Audit_Report_On_Conflict;
  internal_audit_report_order_by: Internal_Audit_Report_Order_By;
  internal_audit_report_pk_columns_input: Internal_Audit_Report_Pk_Columns_Input;
  internal_audit_report_prepend_input: Internal_Audit_Report_Prepend_Input;
  internal_audit_report_set_input: Internal_Audit_Report_Set_Input;
  internal_audit_report_stddev_fields: Internal_Audit_Report_Stddev_Fields;
  internal_audit_report_stddev_order_by: Internal_Audit_Report_Stddev_Order_By;
  internal_audit_report_stddev_pop_fields: Internal_Audit_Report_Stddev_Pop_Fields;
  internal_audit_report_stddev_pop_order_by: Internal_Audit_Report_Stddev_Pop_Order_By;
  internal_audit_report_stddev_samp_fields: Internal_Audit_Report_Stddev_Samp_Fields;
  internal_audit_report_stddev_samp_order_by: Internal_Audit_Report_Stddev_Samp_Order_By;
  internal_audit_report_stream_cursor_input: Internal_Audit_Report_Stream_Cursor_Input;
  internal_audit_report_stream_cursor_value_input: Internal_Audit_Report_Stream_Cursor_Value_Input;
  internal_audit_report_sum_fields: Internal_Audit_Report_Sum_Fields;
  internal_audit_report_sum_order_by: Internal_Audit_Report_Sum_Order_By;
  internal_audit_report_updates: Internal_Audit_Report_Updates;
  internal_audit_report_var_pop_fields: Internal_Audit_Report_Var_Pop_Fields;
  internal_audit_report_var_pop_order_by: Internal_Audit_Report_Var_Pop_Order_By;
  internal_audit_report_var_samp_fields: Internal_Audit_Report_Var_Samp_Fields;
  internal_audit_report_var_samp_order_by: Internal_Audit_Report_Var_Samp_Order_By;
  internal_audit_report_variance_fields: Internal_Audit_Report_Variance_Fields;
  internal_audit_report_variance_order_by: Internal_Audit_Report_Variance_Order_By;
  interval: Scalars['interval']['output'];
  interval_comparison_exp: Interval_Comparison_Exp;
  issue: Issue;
  issue_aggregate: Issue_Aggregate;
  issue_aggregate_fields: Issue_Aggregate_Fields;
  issue_append_input: Issue_Append_Input;
  issue_assessment: Issue_Assessment;
  issue_assessment_aggregate: Issue_Assessment_Aggregate;
  issue_assessment_aggregate_fields: Issue_Assessment_Aggregate_Fields;
  issue_assessment_audit: Issue_Assessment_Audit;
  issue_assessment_audit_bool_exp: Issue_Assessment_Audit_Bool_Exp;
  issue_assessment_audit_order_by: Issue_Assessment_Audit_Order_By;
  issue_assessment_audit_stream_cursor_input: Issue_Assessment_Audit_Stream_Cursor_Input;
  issue_assessment_audit_stream_cursor_value_input: Issue_Assessment_Audit_Stream_Cursor_Value_Input;
  issue_assessment_avg_fields: Issue_Assessment_Avg_Fields;
  issue_assessment_bool_exp: Issue_Assessment_Bool_Exp;
  issue_assessment_insert_input: Issue_Assessment_Insert_Input;
  issue_assessment_max_fields: Issue_Assessment_Max_Fields;
  issue_assessment_min_fields: Issue_Assessment_Min_Fields;
  issue_assessment_obj_rel_insert_input: Issue_Assessment_Obj_Rel_Insert_Input;
  issue_assessment_on_conflict: Issue_Assessment_On_Conflict;
  issue_assessment_order_by: Issue_Assessment_Order_By;
  issue_assessment_status_enum_comparison_exp: Issue_Assessment_Status_Enum_Comparison_Exp;
  issue_assessment_stddev_fields: Issue_Assessment_Stddev_Fields;
  issue_assessment_stddev_pop_fields: Issue_Assessment_Stddev_Pop_Fields;
  issue_assessment_stddev_samp_fields: Issue_Assessment_Stddev_Samp_Fields;
  issue_assessment_stream_cursor_input: Issue_Assessment_Stream_Cursor_Input;
  issue_assessment_stream_cursor_value_input: Issue_Assessment_Stream_Cursor_Value_Input;
  issue_assessment_sum_fields: Issue_Assessment_Sum_Fields;
  issue_assessment_var_pop_fields: Issue_Assessment_Var_Pop_Fields;
  issue_assessment_var_samp_fields: Issue_Assessment_Var_Samp_Fields;
  issue_assessment_variance_fields: Issue_Assessment_Variance_Fields;
  issue_audit: Issue_Audit;
  issue_audit_bool_exp: Issue_Audit_Bool_Exp;
  issue_audit_order_by: Issue_Audit_Order_By;
  issue_audit_stream_cursor_input: Issue_Audit_Stream_Cursor_Input;
  issue_audit_stream_cursor_value_input: Issue_Audit_Stream_Cursor_Value_Input;
  issue_avg_fields: Issue_Avg_Fields;
  issue_bool_exp: Issue_Bool_Exp;
  issue_delete_at_path_input: Issue_Delete_At_Path_Input;
  issue_delete_elem_input: Issue_Delete_Elem_Input;
  issue_delete_key_input: Issue_Delete_Key_Input;
  issue_insert_input: Issue_Insert_Input;
  issue_max_fields: Issue_Max_Fields;
  issue_min_fields: Issue_Min_Fields;
  issue_mutation_response: Issue_Mutation_Response;
  issue_obj_rel_insert_input: Issue_Obj_Rel_Insert_Input;
  issue_on_conflict: Issue_On_Conflict;
  issue_order_by: Issue_Order_By;
  issue_parent: Issue_Parent;
  issue_parent_aggregate: Issue_Parent_Aggregate;
  issue_parent_aggregate_bool_exp: Issue_Parent_Aggregate_Bool_Exp;
  issue_parent_aggregate_bool_exp_count: Issue_Parent_Aggregate_Bool_Exp_Count;
  issue_parent_aggregate_fields: Issue_Parent_Aggregate_Fields;
  issue_parent_aggregate_order_by: Issue_Parent_Aggregate_Order_By;
  issue_parent_arr_rel_insert_input: Issue_Parent_Arr_Rel_Insert_Input;
  issue_parent_audit: Issue_Parent_Audit;
  issue_parent_audit_aggregate_order_by: Issue_Parent_Audit_Aggregate_Order_By;
  issue_parent_audit_bool_exp: Issue_Parent_Audit_Bool_Exp;
  issue_parent_audit_max_order_by: Issue_Parent_Audit_Max_Order_By;
  issue_parent_audit_min_order_by: Issue_Parent_Audit_Min_Order_By;
  issue_parent_audit_order_by: Issue_Parent_Audit_Order_By;
  issue_parent_audit_stream_cursor_input: Issue_Parent_Audit_Stream_Cursor_Input;
  issue_parent_audit_stream_cursor_value_input: Issue_Parent_Audit_Stream_Cursor_Value_Input;
  issue_parent_bool_exp: Issue_Parent_Bool_Exp;
  issue_parent_insert_input: Issue_Parent_Insert_Input;
  issue_parent_max_fields: Issue_Parent_Max_Fields;
  issue_parent_max_order_by: Issue_Parent_Max_Order_By;
  issue_parent_min_fields: Issue_Parent_Min_Fields;
  issue_parent_min_order_by: Issue_Parent_Min_Order_By;
  issue_parent_on_conflict: Issue_Parent_On_Conflict;
  issue_parent_order_by: Issue_Parent_Order_By;
  issue_parent_stream_cursor_input: Issue_Parent_Stream_Cursor_Input;
  issue_parent_stream_cursor_value_input: Issue_Parent_Stream_Cursor_Value_Input;
  issue_pk_columns_input: Issue_Pk_Columns_Input;
  issue_prepend_input: Issue_Prepend_Input;
  issue_set_input: Issue_Set_Input;
  issue_stddev_fields: Issue_Stddev_Fields;
  issue_stddev_pop_fields: Issue_Stddev_Pop_Fields;
  issue_stddev_samp_fields: Issue_Stddev_Samp_Fields;
  issue_stream_cursor_input: Issue_Stream_Cursor_Input;
  issue_stream_cursor_value_input: Issue_Stream_Cursor_Value_Input;
  issue_sum_fields: Issue_Sum_Fields;
  issue_update: Issue_Update;
  issue_update_aggregate_order_by: Issue_Update_Aggregate_Order_By;
  issue_update_append_input: Issue_Update_Append_Input;
  issue_update_arr_rel_insert_input: Issue_Update_Arr_Rel_Insert_Input;
  issue_update_audit: Issue_Update_Audit;
  issue_update_audit_bool_exp: Issue_Update_Audit_Bool_Exp;
  issue_update_audit_order_by: Issue_Update_Audit_Order_By;
  issue_update_audit_stream_cursor_input: Issue_Update_Audit_Stream_Cursor_Input;
  issue_update_audit_stream_cursor_value_input: Issue_Update_Audit_Stream_Cursor_Value_Input;
  issue_update_bool_exp: Issue_Update_Bool_Exp;
  issue_update_delete_at_path_input: Issue_Update_Delete_At_Path_Input;
  issue_update_delete_elem_input: Issue_Update_Delete_Elem_Input;
  issue_update_delete_key_input: Issue_Update_Delete_Key_Input;
  issue_update_insert_input: Issue_Update_Insert_Input;
  issue_update_max_order_by: Issue_Update_Max_Order_By;
  issue_update_min_order_by: Issue_Update_Min_Order_By;
  issue_update_mutation_response: Issue_Update_Mutation_Response;
  issue_update_obj_rel_insert_input: Issue_Update_Obj_Rel_Insert_Input;
  issue_update_on_conflict: Issue_Update_On_Conflict;
  issue_update_order_by: Issue_Update_Order_By;
  issue_update_pk_columns_input: Issue_Update_Pk_Columns_Input;
  issue_update_prepend_input: Issue_Update_Prepend_Input;
  issue_update_set_input: Issue_Update_Set_Input;
  issue_update_stream_cursor_input: Issue_Update_Stream_Cursor_Input;
  issue_update_stream_cursor_value_input: Issue_Update_Stream_Cursor_Value_Input;
  issue_update_updates: Issue_Update_Updates;
  issue_updates: Issue_Updates;
  issue_var_pop_fields: Issue_Var_Pop_Fields;
  issue_var_samp_fields: Issue_Var_Samp_Fields;
  issue_variance_fields: Issue_Variance_Fields;
  json: Scalars['json']['output'];
  json_comparison_exp: Json_Comparison_Exp;
  jsonb: Scalars['jsonb']['output'];
  jsonb_cast_exp: Jsonb_Cast_Exp;
  jsonb_comparison_exp: Jsonb_Comparison_Exp;
  linked_item: Linked_Item;
  linked_item_bool_exp: Linked_Item_Bool_Exp;
  linked_item_mutation_response: Linked_Item_Mutation_Response;
  linked_item_order_by: Linked_Item_Order_By;
  linked_item_stream_cursor_input: Linked_Item_Stream_Cursor_Input;
  linked_item_stream_cursor_value_input: Linked_Item_Stream_Cursor_Value_Input;
  mutation_root: {};
  node: Node;
  node_aggregate: Node_Aggregate;
  node_aggregate_fields: Node_Aggregate_Fields;
  node_avg_fields: Node_Avg_Fields;
  node_bool_exp: Node_Bool_Exp;
  node_max_fields: Node_Max_Fields;
  node_min_fields: Node_Min_Fields;
  node_order_by: Node_Order_By;
  node_stddev_fields: Node_Stddev_Fields;
  node_stddev_pop_fields: Node_Stddev_Pop_Fields;
  node_stddev_samp_fields: Node_Stddev_Samp_Fields;
  node_stream_cursor_input: Node_Stream_Cursor_Input;
  node_stream_cursor_value_input: Node_Stream_Cursor_Value_Input;
  node_sum_fields: Node_Sum_Fields;
  node_var_pop_fields: Node_Var_Pop_Fields;
  node_var_samp_fields: Node_Var_Samp_Fields;
  node_variance_fields: Node_Variance_Fields;
  numeric: Scalars['numeric']['output'];
  numeric_comparison_exp: Numeric_Comparison_Exp;
  obligation: Obligation;
  obligation_aggregate: Obligation_Aggregate;
  obligation_aggregate_bool_exp: Obligation_Aggregate_Bool_Exp;
  obligation_aggregate_bool_exp_count: Obligation_Aggregate_Bool_Exp_Count;
  obligation_aggregate_fields: Obligation_Aggregate_Fields;
  obligation_aggregate_order_by: Obligation_Aggregate_Order_By;
  obligation_assessment_result: Obligation_Assessment_Result;
  obligation_assessment_result_aggregate: Obligation_Assessment_Result_Aggregate;
  obligation_assessment_result_aggregate_fields: Obligation_Assessment_Result_Aggregate_Fields;
  obligation_assessment_result_append_input: Obligation_Assessment_Result_Append_Input;
  obligation_assessment_result_audit: Obligation_Assessment_Result_Audit;
  obligation_assessment_result_audit_bool_exp: Obligation_Assessment_Result_Audit_Bool_Exp;
  obligation_assessment_result_audit_order_by: Obligation_Assessment_Result_Audit_Order_By;
  obligation_assessment_result_audit_stream_cursor_input: Obligation_Assessment_Result_Audit_Stream_Cursor_Input;
  obligation_assessment_result_audit_stream_cursor_value_input: Obligation_Assessment_Result_Audit_Stream_Cursor_Value_Input;
  obligation_assessment_result_avg_fields: Obligation_Assessment_Result_Avg_Fields;
  obligation_assessment_result_bool_exp: Obligation_Assessment_Result_Bool_Exp;
  obligation_assessment_result_delete_at_path_input: Obligation_Assessment_Result_Delete_At_Path_Input;
  obligation_assessment_result_delete_elem_input: Obligation_Assessment_Result_Delete_Elem_Input;
  obligation_assessment_result_delete_key_input: Obligation_Assessment_Result_Delete_Key_Input;
  obligation_assessment_result_inc_input: Obligation_Assessment_Result_Inc_Input;
  obligation_assessment_result_insert_input: Obligation_Assessment_Result_Insert_Input;
  obligation_assessment_result_max_fields: Obligation_Assessment_Result_Max_Fields;
  obligation_assessment_result_min_fields: Obligation_Assessment_Result_Min_Fields;
  obligation_assessment_result_mutation_response: Obligation_Assessment_Result_Mutation_Response;
  obligation_assessment_result_obj_rel_insert_input: Obligation_Assessment_Result_Obj_Rel_Insert_Input;
  obligation_assessment_result_on_conflict: Obligation_Assessment_Result_On_Conflict;
  obligation_assessment_result_order_by: Obligation_Assessment_Result_Order_By;
  obligation_assessment_result_pk_columns_input: Obligation_Assessment_Result_Pk_Columns_Input;
  obligation_assessment_result_prepend_input: Obligation_Assessment_Result_Prepend_Input;
  obligation_assessment_result_set_input: Obligation_Assessment_Result_Set_Input;
  obligation_assessment_result_stddev_fields: Obligation_Assessment_Result_Stddev_Fields;
  obligation_assessment_result_stddev_pop_fields: Obligation_Assessment_Result_Stddev_Pop_Fields;
  obligation_assessment_result_stddev_samp_fields: Obligation_Assessment_Result_Stddev_Samp_Fields;
  obligation_assessment_result_stream_cursor_input: Obligation_Assessment_Result_Stream_Cursor_Input;
  obligation_assessment_result_stream_cursor_value_input: Obligation_Assessment_Result_Stream_Cursor_Value_Input;
  obligation_assessment_result_sum_fields: Obligation_Assessment_Result_Sum_Fields;
  obligation_assessment_result_updates: Obligation_Assessment_Result_Updates;
  obligation_assessment_result_var_pop_fields: Obligation_Assessment_Result_Var_Pop_Fields;
  obligation_assessment_result_var_samp_fields: Obligation_Assessment_Result_Var_Samp_Fields;
  obligation_assessment_result_variance_fields: Obligation_Assessment_Result_Variance_Fields;
  obligation_assessment_status: Obligation_Assessment_Status;
  obligation_assessment_status_bool_exp: Obligation_Assessment_Status_Bool_Exp;
  obligation_assessment_status_order_by: Obligation_Assessment_Status_Order_By;
  obligation_assessment_status_stream_cursor_input: Obligation_Assessment_Status_Stream_Cursor_Input;
  obligation_assessment_status_stream_cursor_value_input: Obligation_Assessment_Status_Stream_Cursor_Value_Input;
  obligation_audit: Obligation_Audit;
  obligation_audit_aggregate: Obligation_Audit_Aggregate;
  obligation_audit_aggregate_fields: Obligation_Audit_Aggregate_Fields;
  obligation_audit_avg_fields: Obligation_Audit_Avg_Fields;
  obligation_audit_bool_exp: Obligation_Audit_Bool_Exp;
  obligation_audit_max_fields: Obligation_Audit_Max_Fields;
  obligation_audit_min_fields: Obligation_Audit_Min_Fields;
  obligation_audit_order_by: Obligation_Audit_Order_By;
  obligation_audit_stddev_fields: Obligation_Audit_Stddev_Fields;
  obligation_audit_stddev_pop_fields: Obligation_Audit_Stddev_Pop_Fields;
  obligation_audit_stddev_samp_fields: Obligation_Audit_Stddev_Samp_Fields;
  obligation_audit_stream_cursor_input: Obligation_Audit_Stream_Cursor_Input;
  obligation_audit_stream_cursor_value_input: Obligation_Audit_Stream_Cursor_Value_Input;
  obligation_audit_sum_fields: Obligation_Audit_Sum_Fields;
  obligation_audit_var_pop_fields: Obligation_Audit_Var_Pop_Fields;
  obligation_audit_var_samp_fields: Obligation_Audit_Var_Samp_Fields;
  obligation_audit_variance_fields: Obligation_Audit_Variance_Fields;
  obligation_avg_fields: Obligation_Avg_Fields;
  obligation_avg_order_by: Obligation_Avg_Order_By;
  obligation_bool_exp: Obligation_Bool_Exp;
  obligation_impact: Obligation_Impact;
  obligation_impact_aggregate_order_by: Obligation_Impact_Aggregate_Order_By;
  obligation_impact_append_input: Obligation_Impact_Append_Input;
  obligation_impact_arr_rel_insert_input: Obligation_Impact_Arr_Rel_Insert_Input;
  obligation_impact_audit: Obligation_Impact_Audit;
  obligation_impact_audit_bool_exp: Obligation_Impact_Audit_Bool_Exp;
  obligation_impact_audit_order_by: Obligation_Impact_Audit_Order_By;
  obligation_impact_audit_stream_cursor_input: Obligation_Impact_Audit_Stream_Cursor_Input;
  obligation_impact_audit_stream_cursor_value_input: Obligation_Impact_Audit_Stream_Cursor_Value_Input;
  obligation_impact_avg_order_by: Obligation_Impact_Avg_Order_By;
  obligation_impact_bool_exp: Obligation_Impact_Bool_Exp;
  obligation_impact_delete_at_path_input: Obligation_Impact_Delete_At_Path_Input;
  obligation_impact_delete_elem_input: Obligation_Impact_Delete_Elem_Input;
  obligation_impact_delete_key_input: Obligation_Impact_Delete_Key_Input;
  obligation_impact_inc_input: Obligation_Impact_Inc_Input;
  obligation_impact_insert_input: Obligation_Impact_Insert_Input;
  obligation_impact_max_order_by: Obligation_Impact_Max_Order_By;
  obligation_impact_min_order_by: Obligation_Impact_Min_Order_By;
  obligation_impact_mutation_response: Obligation_Impact_Mutation_Response;
  obligation_impact_on_conflict: Obligation_Impact_On_Conflict;
  obligation_impact_order_by: Obligation_Impact_Order_By;
  obligation_impact_pk_columns_input: Obligation_Impact_Pk_Columns_Input;
  obligation_impact_prepend_input: Obligation_Impact_Prepend_Input;
  obligation_impact_set_input: Obligation_Impact_Set_Input;
  obligation_impact_stddev_order_by: Obligation_Impact_Stddev_Order_By;
  obligation_impact_stddev_pop_order_by: Obligation_Impact_Stddev_Pop_Order_By;
  obligation_impact_stddev_samp_order_by: Obligation_Impact_Stddev_Samp_Order_By;
  obligation_impact_stream_cursor_input: Obligation_Impact_Stream_Cursor_Input;
  obligation_impact_stream_cursor_value_input: Obligation_Impact_Stream_Cursor_Value_Input;
  obligation_impact_sum_order_by: Obligation_Impact_Sum_Order_By;
  obligation_impact_updates: Obligation_Impact_Updates;
  obligation_impact_var_pop_order_by: Obligation_Impact_Var_Pop_Order_By;
  obligation_impact_var_samp_order_by: Obligation_Impact_Var_Samp_Order_By;
  obligation_impact_variance_order_by: Obligation_Impact_Variance_Order_By;
  obligation_insert_input: Obligation_Insert_Input;
  obligation_max_fields: Obligation_Max_Fields;
  obligation_max_order_by: Obligation_Max_Order_By;
  obligation_min_fields: Obligation_Min_Fields;
  obligation_min_order_by: Obligation_Min_Order_By;
  obligation_mutation_response: Obligation_Mutation_Response;
  obligation_obj_rel_insert_input: Obligation_Obj_Rel_Insert_Input;
  obligation_on_conflict: Obligation_On_Conflict;
  obligation_order_by: Obligation_Order_By;
  obligation_stddev_fields: Obligation_Stddev_Fields;
  obligation_stddev_order_by: Obligation_Stddev_Order_By;
  obligation_stddev_pop_fields: Obligation_Stddev_Pop_Fields;
  obligation_stddev_pop_order_by: Obligation_Stddev_Pop_Order_By;
  obligation_stddev_samp_fields: Obligation_Stddev_Samp_Fields;
  obligation_stddev_samp_order_by: Obligation_Stddev_Samp_Order_By;
  obligation_stream_cursor_input: Obligation_Stream_Cursor_Input;
  obligation_stream_cursor_value_input: Obligation_Stream_Cursor_Value_Input;
  obligation_sum_fields: Obligation_Sum_Fields;
  obligation_sum_order_by: Obligation_Sum_Order_By;
  obligation_type: Obligation_Type;
  obligation_type_bool_exp: Obligation_Type_Bool_Exp;
  obligation_type_enum_comparison_exp: Obligation_Type_Enum_Comparison_Exp;
  obligation_type_order_by: Obligation_Type_Order_By;
  obligation_type_stream_cursor_input: Obligation_Type_Stream_Cursor_Input;
  obligation_type_stream_cursor_value_input: Obligation_Type_Stream_Cursor_Value_Input;
  obligation_var_pop_fields: Obligation_Var_Pop_Fields;
  obligation_var_pop_order_by: Obligation_Var_Pop_Order_By;
  obligation_var_samp_fields: Obligation_Var_Samp_Fields;
  obligation_var_samp_order_by: Obligation_Var_Samp_Order_By;
  obligation_variance_fields: Obligation_Variance_Fields;
  obligation_variance_order_by: Obligation_Variance_Order_By;
  owner: Owner;
  owner_aggregate: Owner_Aggregate;
  owner_aggregate_bool_exp: Owner_Aggregate_Bool_Exp;
  owner_aggregate_bool_exp_count: Owner_Aggregate_Bool_Exp_Count;
  owner_aggregate_fields: Owner_Aggregate_Fields;
  owner_aggregate_order_by: Owner_Aggregate_Order_By;
  owner_arr_rel_insert_input: Owner_Arr_Rel_Insert_Input;
  owner_audit: Owner_Audit;
  owner_audit_aggregate_order_by: Owner_Audit_Aggregate_Order_By;
  owner_audit_bool_exp: Owner_Audit_Bool_Exp;
  owner_audit_max_order_by: Owner_Audit_Max_Order_By;
  owner_audit_min_order_by: Owner_Audit_Min_Order_By;
  owner_audit_order_by: Owner_Audit_Order_By;
  owner_audit_stream_cursor_input: Owner_Audit_Stream_Cursor_Input;
  owner_audit_stream_cursor_value_input: Owner_Audit_Stream_Cursor_Value_Input;
  owner_bool_exp: Owner_Bool_Exp;
  owner_group: Owner_Group;
  owner_group_aggregate: Owner_Group_Aggregate;
  owner_group_aggregate_bool_exp: Owner_Group_Aggregate_Bool_Exp;
  owner_group_aggregate_bool_exp_count: Owner_Group_Aggregate_Bool_Exp_Count;
  owner_group_aggregate_fields: Owner_Group_Aggregate_Fields;
  owner_group_aggregate_order_by: Owner_Group_Aggregate_Order_By;
  owner_group_arr_rel_insert_input: Owner_Group_Arr_Rel_Insert_Input;
  owner_group_audit: Owner_Group_Audit;
  owner_group_audit_aggregate_order_by: Owner_Group_Audit_Aggregate_Order_By;
  owner_group_audit_bool_exp: Owner_Group_Audit_Bool_Exp;
  owner_group_audit_max_order_by: Owner_Group_Audit_Max_Order_By;
  owner_group_audit_min_order_by: Owner_Group_Audit_Min_Order_By;
  owner_group_audit_order_by: Owner_Group_Audit_Order_By;
  owner_group_audit_stream_cursor_input: Owner_Group_Audit_Stream_Cursor_Input;
  owner_group_audit_stream_cursor_value_input: Owner_Group_Audit_Stream_Cursor_Value_Input;
  owner_group_bool_exp: Owner_Group_Bool_Exp;
  owner_group_insert_input: Owner_Group_Insert_Input;
  owner_group_max_fields: Owner_Group_Max_Fields;
  owner_group_max_order_by: Owner_Group_Max_Order_By;
  owner_group_min_fields: Owner_Group_Min_Fields;
  owner_group_min_order_by: Owner_Group_Min_Order_By;
  owner_group_mutation_response: Owner_Group_Mutation_Response;
  owner_group_on_conflict: Owner_Group_On_Conflict;
  owner_group_order_by: Owner_Group_Order_By;
  owner_insert_input: Owner_Insert_Input;
  owner_max_fields: Owner_Max_Fields;
  owner_max_order_by: Owner_Max_Order_By;
  owner_min_fields: Owner_Min_Fields;
  owner_min_order_by: Owner_Min_Order_By;
  owner_mutation_response: Owner_Mutation_Response;
  owner_on_conflict: Owner_On_Conflict;
  owner_order_by: Owner_Order_By;
  parent_type_enum_comparison_exp: Parent_Type_Enum_Comparison_Exp;
  query_root: {};
  questionnaire_invite: Questionnaire_Invite;
  questionnaire_invite_aggregate_order_by: Questionnaire_Invite_Aggregate_Order_By;
  questionnaire_invite_arr_rel_insert_input: Questionnaire_Invite_Arr_Rel_Insert_Input;
  questionnaire_invite_bool_exp: Questionnaire_Invite_Bool_Exp;
  questionnaire_invite_insert_input: Questionnaire_Invite_Insert_Input;
  questionnaire_invite_max_order_by: Questionnaire_Invite_Max_Order_By;
  questionnaire_invite_min_order_by: Questionnaire_Invite_Min_Order_By;
  questionnaire_invite_mutation_response: Questionnaire_Invite_Mutation_Response;
  questionnaire_invite_on_conflict: Questionnaire_Invite_On_Conflict;
  questionnaire_invite_order_by: Questionnaire_Invite_Order_By;
  questionnaire_invite_stream_cursor_input: Questionnaire_Invite_Stream_Cursor_Input;
  questionnaire_invite_stream_cursor_value_input: Questionnaire_Invite_Stream_Cursor_Value_Input;
  questionnaire_template: Questionnaire_Template;
  questionnaire_template_append_input: Questionnaire_Template_Append_Input;
  questionnaire_template_bool_exp: Questionnaire_Template_Bool_Exp;
  questionnaire_template_delete_at_path_input: Questionnaire_Template_Delete_At_Path_Input;
  questionnaire_template_delete_elem_input: Questionnaire_Template_Delete_Elem_Input;
  questionnaire_template_delete_key_input: Questionnaire_Template_Delete_Key_Input;
  questionnaire_template_insert_input: Questionnaire_Template_Insert_Input;
  questionnaire_template_mutation_response: Questionnaire_Template_Mutation_Response;
  questionnaire_template_obj_rel_insert_input: Questionnaire_Template_Obj_Rel_Insert_Input;
  questionnaire_template_on_conflict: Questionnaire_Template_On_Conflict;
  questionnaire_template_order_by: Questionnaire_Template_Order_By;
  questionnaire_template_pk_columns_input: Questionnaire_Template_Pk_Columns_Input;
  questionnaire_template_prepend_input: Questionnaire_Template_Prepend_Input;
  questionnaire_template_set_input: Questionnaire_Template_Set_Input;
  questionnaire_template_stream_cursor_input: Questionnaire_Template_Stream_Cursor_Input;
  questionnaire_template_stream_cursor_value_input: Questionnaire_Template_Stream_Cursor_Value_Input;
  questionnaire_template_updates: Questionnaire_Template_Updates;
  questionnaire_template_version: Questionnaire_Template_Version;
  questionnaire_template_version_aggregate_order_by: Questionnaire_Template_Version_Aggregate_Order_By;
  questionnaire_template_version_append_input: Questionnaire_Template_Version_Append_Input;
  questionnaire_template_version_arr_rel_insert_input: Questionnaire_Template_Version_Arr_Rel_Insert_Input;
  questionnaire_template_version_bool_exp: Questionnaire_Template_Version_Bool_Exp;
  questionnaire_template_version_delete_at_path_input: Questionnaire_Template_Version_Delete_At_Path_Input;
  questionnaire_template_version_delete_elem_input: Questionnaire_Template_Version_Delete_Elem_Input;
  questionnaire_template_version_delete_key_input: Questionnaire_Template_Version_Delete_Key_Input;
  questionnaire_template_version_insert_input: Questionnaire_Template_Version_Insert_Input;
  questionnaire_template_version_max_order_by: Questionnaire_Template_Version_Max_Order_By;
  questionnaire_template_version_min_order_by: Questionnaire_Template_Version_Min_Order_By;
  questionnaire_template_version_mutation_response: Questionnaire_Template_Version_Mutation_Response;
  questionnaire_template_version_obj_rel_insert_input: Questionnaire_Template_Version_Obj_Rel_Insert_Input;
  questionnaire_template_version_on_conflict: Questionnaire_Template_Version_On_Conflict;
  questionnaire_template_version_order_by: Questionnaire_Template_Version_Order_By;
  questionnaire_template_version_pk_columns_input: Questionnaire_Template_Version_Pk_Columns_Input;
  questionnaire_template_version_prepend_input: Questionnaire_Template_Version_Prepend_Input;
  questionnaire_template_version_set_input: Questionnaire_Template_Version_Set_Input;
  questionnaire_template_version_status: Questionnaire_Template_Version_Status;
  questionnaire_template_version_status_bool_exp: Questionnaire_Template_Version_Status_Bool_Exp;
  questionnaire_template_version_status_enum_comparison_exp: Questionnaire_Template_Version_Status_Enum_Comparison_Exp;
  questionnaire_template_version_status_order_by: Questionnaire_Template_Version_Status_Order_By;
  questionnaire_template_version_status_stream_cursor_input: Questionnaire_Template_Version_Status_Stream_Cursor_Input;
  questionnaire_template_version_status_stream_cursor_value_input: Questionnaire_Template_Version_Status_Stream_Cursor_Value_Input;
  questionnaire_template_version_stream_cursor_input: Questionnaire_Template_Version_Stream_Cursor_Input;
  questionnaire_template_version_stream_cursor_value_input: Questionnaire_Template_Version_Stream_Cursor_Value_Input;
  questionnaire_template_version_updates: Questionnaire_Template_Version_Updates;
  relation_file: Relation_File;
  relation_file_aggregate: Relation_File_Aggregate;
  relation_file_aggregate_bool_exp: Relation_File_Aggregate_Bool_Exp;
  relation_file_aggregate_bool_exp_count: Relation_File_Aggregate_Bool_Exp_Count;
  relation_file_aggregate_fields: Relation_File_Aggregate_Fields;
  relation_file_aggregate_order_by: Relation_File_Aggregate_Order_By;
  relation_file_arr_rel_insert_input: Relation_File_Arr_Rel_Insert_Input;
  relation_file_audit: Relation_File_Audit;
  relation_file_audit_bool_exp: Relation_File_Audit_Bool_Exp;
  relation_file_audit_order_by: Relation_File_Audit_Order_By;
  relation_file_audit_stream_cursor_input: Relation_File_Audit_Stream_Cursor_Input;
  relation_file_audit_stream_cursor_value_input: Relation_File_Audit_Stream_Cursor_Value_Input;
  relation_file_bool_exp: Relation_File_Bool_Exp;
  relation_file_insert_input: Relation_File_Insert_Input;
  relation_file_max_fields: Relation_File_Max_Fields;
  relation_file_max_order_by: Relation_File_Max_Order_By;
  relation_file_min_fields: Relation_File_Min_Fields;
  relation_file_min_order_by: Relation_File_Min_Order_By;
  relation_file_mutation_response: Relation_File_Mutation_Response;
  relation_file_on_conflict: Relation_File_On_Conflict;
  relation_file_order_by: Relation_File_Order_By;
  relation_file_stream_cursor_input: Relation_File_Stream_Cursor_Input;
  relation_file_stream_cursor_value_input: Relation_File_Stream_Cursor_Value_Input;
  risk: Risk;
  risk_aggregate: Risk_Aggregate;
  risk_aggregate_bool_exp: Risk_Aggregate_Bool_Exp;
  risk_aggregate_bool_exp_count: Risk_Aggregate_Bool_Exp_Count;
  risk_aggregate_fields: Risk_Aggregate_Fields;
  risk_aggregate_order_by: Risk_Aggregate_Order_By;
  risk_arr_rel_insert_input: Risk_Arr_Rel_Insert_Input;
  risk_assessment_result: Risk_Assessment_Result;
  risk_assessment_result_aggregate: Risk_Assessment_Result_Aggregate;
  risk_assessment_result_aggregate_fields: Risk_Assessment_Result_Aggregate_Fields;
  risk_assessment_result_audit: Risk_Assessment_Result_Audit;
  risk_assessment_result_audit_bool_exp: Risk_Assessment_Result_Audit_Bool_Exp;
  risk_assessment_result_audit_order_by: Risk_Assessment_Result_Audit_Order_By;
  risk_assessment_result_audit_stream_cursor_input: Risk_Assessment_Result_Audit_Stream_Cursor_Input;
  risk_assessment_result_audit_stream_cursor_value_input: Risk_Assessment_Result_Audit_Stream_Cursor_Value_Input;
  risk_assessment_result_avg_fields: Risk_Assessment_Result_Avg_Fields;
  risk_assessment_result_bool_exp: Risk_Assessment_Result_Bool_Exp;
  risk_assessment_result_control_type_enum_comparison_exp: Risk_Assessment_Result_Control_Type_Enum_Comparison_Exp;
  risk_assessment_result_insert_input: Risk_Assessment_Result_Insert_Input;
  risk_assessment_result_max_fields: Risk_Assessment_Result_Max_Fields;
  risk_assessment_result_min_fields: Risk_Assessment_Result_Min_Fields;
  risk_assessment_result_mutation_response: Risk_Assessment_Result_Mutation_Response;
  risk_assessment_result_obj_rel_insert_input: Risk_Assessment_Result_Obj_Rel_Insert_Input;
  risk_assessment_result_on_conflict: Risk_Assessment_Result_On_Conflict;
  risk_assessment_result_order_by: Risk_Assessment_Result_Order_By;
  risk_assessment_result_stddev_fields: Risk_Assessment_Result_Stddev_Fields;
  risk_assessment_result_stddev_pop_fields: Risk_Assessment_Result_Stddev_Pop_Fields;
  risk_assessment_result_stddev_samp_fields: Risk_Assessment_Result_Stddev_Samp_Fields;
  risk_assessment_result_stream_cursor_input: Risk_Assessment_Result_Stream_Cursor_Input;
  risk_assessment_result_stream_cursor_value_input: Risk_Assessment_Result_Stream_Cursor_Value_Input;
  risk_assessment_result_sum_fields: Risk_Assessment_Result_Sum_Fields;
  risk_assessment_result_var_pop_fields: Risk_Assessment_Result_Var_Pop_Fields;
  risk_assessment_result_var_samp_fields: Risk_Assessment_Result_Var_Samp_Fields;
  risk_assessment_result_variance_fields: Risk_Assessment_Result_Variance_Fields;
  risk_audit: Risk_Audit;
  risk_audit_aggregate: Risk_Audit_Aggregate;
  risk_audit_aggregate_fields: Risk_Audit_Aggregate_Fields;
  risk_audit_avg_fields: Risk_Audit_Avg_Fields;
  risk_audit_bool_exp: Risk_Audit_Bool_Exp;
  risk_audit_max_fields: Risk_Audit_Max_Fields;
  risk_audit_min_fields: Risk_Audit_Min_Fields;
  risk_audit_order_by: Risk_Audit_Order_By;
  risk_audit_stddev_fields: Risk_Audit_Stddev_Fields;
  risk_audit_stddev_pop_fields: Risk_Audit_Stddev_Pop_Fields;
  risk_audit_stddev_samp_fields: Risk_Audit_Stddev_Samp_Fields;
  risk_audit_stream_cursor_input: Risk_Audit_Stream_Cursor_Input;
  risk_audit_stream_cursor_value_input: Risk_Audit_Stream_Cursor_Value_Input;
  risk_audit_sum_fields: Risk_Audit_Sum_Fields;
  risk_audit_var_pop_fields: Risk_Audit_Var_Pop_Fields;
  risk_audit_var_samp_fields: Risk_Audit_Var_Samp_Fields;
  risk_audit_variance_fields: Risk_Audit_Variance_Fields;
  risk_avg_fields: Risk_Avg_Fields;
  risk_avg_order_by: Risk_Avg_Order_By;
  risk_bool_exp: Risk_Bool_Exp;
  risk_insert_input: Risk_Insert_Input;
  risk_max_fields: Risk_Max_Fields;
  risk_max_order_by: Risk_Max_Order_By;
  risk_min_fields: Risk_Min_Fields;
  risk_min_order_by: Risk_Min_Order_By;
  risk_obj_rel_insert_input: Risk_Obj_Rel_Insert_Input;
  risk_on_conflict: Risk_On_Conflict;
  risk_order_by: Risk_Order_By;
  risk_score: Risk_Score;
  risk_score_aggregate: Risk_Score_Aggregate;
  risk_score_aggregate_fields: Risk_Score_Aggregate_Fields;
  risk_score_avg_fields: Risk_Score_Avg_Fields;
  risk_score_bool_exp: Risk_Score_Bool_Exp;
  risk_score_insert_input: Risk_Score_Insert_Input;
  risk_score_max_fields: Risk_Score_Max_Fields;
  risk_score_min_fields: Risk_Score_Min_Fields;
  risk_score_obj_rel_insert_input: Risk_Score_Obj_Rel_Insert_Input;
  risk_score_on_conflict: Risk_Score_On_Conflict;
  risk_score_order_by: Risk_Score_Order_By;
  risk_score_stddev_fields: Risk_Score_Stddev_Fields;
  risk_score_stddev_pop_fields: Risk_Score_Stddev_Pop_Fields;
  risk_score_stddev_samp_fields: Risk_Score_Stddev_Samp_Fields;
  risk_score_stream_cursor_input: Risk_Score_Stream_Cursor_Input;
  risk_score_stream_cursor_value_input: Risk_Score_Stream_Cursor_Value_Input;
  risk_score_sum_fields: Risk_Score_Sum_Fields;
  risk_score_var_pop_fields: Risk_Score_Var_Pop_Fields;
  risk_score_var_samp_fields: Risk_Score_Var_Samp_Fields;
  risk_score_variance_fields: Risk_Score_Variance_Fields;
  risk_scoring_model_enum_comparison_exp: Risk_Scoring_Model_Enum_Comparison_Exp;
  risk_status_type_enum_comparison_exp: Risk_Status_Type_Enum_Comparison_Exp;
  risk_stddev_fields: Risk_Stddev_Fields;
  risk_stddev_order_by: Risk_Stddev_Order_By;
  risk_stddev_pop_fields: Risk_Stddev_Pop_Fields;
  risk_stddev_pop_order_by: Risk_Stddev_Pop_Order_By;
  risk_stddev_samp_fields: Risk_Stddev_Samp_Fields;
  risk_stddev_samp_order_by: Risk_Stddev_Samp_Order_By;
  risk_stream_cursor_input: Risk_Stream_Cursor_Input;
  risk_stream_cursor_value_input: Risk_Stream_Cursor_Value_Input;
  risk_sum_fields: Risk_Sum_Fields;
  risk_sum_order_by: Risk_Sum_Order_By;
  risk_treatment_type_enum_comparison_exp: Risk_Treatment_Type_Enum_Comparison_Exp;
  risk_var_pop_fields: Risk_Var_Pop_Fields;
  risk_var_pop_order_by: Risk_Var_Pop_Order_By;
  risk_var_samp_fields: Risk_Var_Samp_Fields;
  risk_var_samp_order_by: Risk_Var_Samp_Order_By;
  risk_variance_fields: Risk_Variance_Fields;
  risk_variance_order_by: Risk_Variance_Order_By;
  risksmart_indicator_parent_audit: Risksmart_Indicator_Parent_Audit;
  risksmart_indicator_parent_audit_aggregate_order_by: Risksmart_Indicator_Parent_Audit_Aggregate_Order_By;
  risksmart_indicator_parent_audit_bool_exp: Risksmart_Indicator_Parent_Audit_Bool_Exp;
  risksmart_indicator_parent_audit_max_order_by: Risksmart_Indicator_Parent_Audit_Max_Order_By;
  risksmart_indicator_parent_audit_min_order_by: Risksmart_Indicator_Parent_Audit_Min_Order_By;
  risksmart_indicator_parent_audit_order_by: Risksmart_Indicator_Parent_Audit_Order_By;
  risksmart_indicator_parent_audit_stream_cursor_input: Risksmart_Indicator_Parent_Audit_Stream_Cursor_Input;
  risksmart_indicator_parent_audit_stream_cursor_value_input: Risksmart_Indicator_Parent_Audit_Stream_Cursor_Value_Input;
  risksmart_linked_item_audit: Risksmart_Linked_Item_Audit;
  risksmart_linked_item_audit_bool_exp: Risksmart_Linked_Item_Audit_Bool_Exp;
  risksmart_linked_item_audit_order_by: Risksmart_Linked_Item_Audit_Order_By;
  risksmart_linked_item_audit_stream_cursor_input: Risksmart_Linked_Item_Audit_Stream_Cursor_Input;
  risksmart_linked_item_audit_stream_cursor_value_input: Risksmart_Linked_Item_Audit_Stream_Cursor_Value_Input;
  role_access: Role_Access;
  role_access_bool_exp: Role_Access_Bool_Exp;
  role_access_order_by: Role_Access_Order_By;
  role_access_stream_cursor_input: Role_Access_Stream_Cursor_Input;
  role_access_stream_cursor_value_input: Role_Access_Stream_Cursor_Value_Input;
  schedule: Schedule;
  schedule_bool_exp: Schedule_Bool_Exp;
  schedule_insert_input: Schedule_Insert_Input;
  schedule_obj_rel_insert_input: Schedule_Obj_Rel_Insert_Input;
  schedule_on_conflict: Schedule_On_Conflict;
  schedule_order_by: Schedule_Order_By;
  schedule_state: Schedule_State;
  schedule_state_bool_exp: Schedule_State_Bool_Exp;
  schedule_state_order_by: Schedule_State_Order_By;
  smallint: Scalars['smallint']['output'];
  smallint_comparison_exp: Smallint_Comparison_Exp;
  subscription_root: {};
  tag: Tag;
  tag_aggregate_order_by: Tag_Aggregate_Order_By;
  tag_arr_rel_insert_input: Tag_Arr_Rel_Insert_Input;
  tag_bool_exp: Tag_Bool_Exp;
  tag_insert_input: Tag_Insert_Input;
  tag_max_order_by: Tag_Max_Order_By;
  tag_min_order_by: Tag_Min_Order_By;
  tag_mutation_response: Tag_Mutation_Response;
  tag_on_conflict: Tag_On_Conflict;
  tag_order_by: Tag_Order_By;
  tag_type: Tag_Type;
  tag_type_aggregate_order_by: Tag_Type_Aggregate_Order_By;
  tag_type_arr_rel_insert_input: Tag_Type_Arr_Rel_Insert_Input;
  tag_type_audit: Tag_Type_Audit;
  tag_type_audit_bool_exp: Tag_Type_Audit_Bool_Exp;
  tag_type_audit_order_by: Tag_Type_Audit_Order_By;
  tag_type_audit_stream_cursor_input: Tag_Type_Audit_Stream_Cursor_Input;
  tag_type_audit_stream_cursor_value_input: Tag_Type_Audit_Stream_Cursor_Value_Input;
  tag_type_bool_exp: Tag_Type_Bool_Exp;
  tag_type_group: Tag_Type_Group;
  tag_type_group_audit: Tag_Type_Group_Audit;
  tag_type_group_audit_bool_exp: Tag_Type_Group_Audit_Bool_Exp;
  tag_type_group_audit_order_by: Tag_Type_Group_Audit_Order_By;
  tag_type_group_audit_stream_cursor_input: Tag_Type_Group_Audit_Stream_Cursor_Input;
  tag_type_group_audit_stream_cursor_value_input: Tag_Type_Group_Audit_Stream_Cursor_Value_Input;
  tag_type_group_bool_exp: Tag_Type_Group_Bool_Exp;
  tag_type_group_insert_input: Tag_Type_Group_Insert_Input;
  tag_type_group_mutation_response: Tag_Type_Group_Mutation_Response;
  tag_type_group_obj_rel_insert_input: Tag_Type_Group_Obj_Rel_Insert_Input;
  tag_type_group_on_conflict: Tag_Type_Group_On_Conflict;
  tag_type_group_order_by: Tag_Type_Group_Order_By;
  tag_type_group_set_input: Tag_Type_Group_Set_Input;
  tag_type_group_stream_cursor_input: Tag_Type_Group_Stream_Cursor_Input;
  tag_type_group_stream_cursor_value_input: Tag_Type_Group_Stream_Cursor_Value_Input;
  tag_type_group_updates: Tag_Type_Group_Updates;
  tag_type_insert_input: Tag_Type_Insert_Input;
  tag_type_max_order_by: Tag_Type_Max_Order_By;
  tag_type_min_order_by: Tag_Type_Min_Order_By;
  tag_type_mutation_response: Tag_Type_Mutation_Response;
  tag_type_obj_rel_insert_input: Tag_Type_Obj_Rel_Insert_Input;
  tag_type_on_conflict: Tag_Type_On_Conflict;
  tag_type_order_by: Tag_Type_Order_By;
  tag_type_pk_columns_input: Tag_Type_Pk_Columns_Input;
  tag_type_set_input: Tag_Type_Set_Input;
  tag_type_stream_cursor_input: Tag_Type_Stream_Cursor_Input;
  tag_type_stream_cursor_value_input: Tag_Type_Stream_Cursor_Value_Input;
  tag_type_updates: Tag_Type_Updates;
  taxonomy: Taxonomy;
  taxonomy_aggregate: Taxonomy_Aggregate;
  taxonomy_aggregate_fields: Taxonomy_Aggregate_Fields;
  taxonomy_append_input: Taxonomy_Append_Input;
  taxonomy_audit: Taxonomy_Audit;
  taxonomy_audit_aggregate: Taxonomy_Audit_Aggregate;
  taxonomy_audit_aggregate_fields: Taxonomy_Audit_Aggregate_Fields;
  taxonomy_audit_bool_exp: Taxonomy_Audit_Bool_Exp;
  taxonomy_audit_max_fields: Taxonomy_Audit_Max_Fields;
  taxonomy_audit_min_fields: Taxonomy_Audit_Min_Fields;
  taxonomy_audit_order_by: Taxonomy_Audit_Order_By;
  taxonomy_audit_stream_cursor_input: Taxonomy_Audit_Stream_Cursor_Input;
  taxonomy_audit_stream_cursor_value_input: Taxonomy_Audit_Stream_Cursor_Value_Input;
  taxonomy_bool_exp: Taxonomy_Bool_Exp;
  taxonomy_delete_at_path_input: Taxonomy_Delete_At_Path_Input;
  taxonomy_delete_elem_input: Taxonomy_Delete_Elem_Input;
  taxonomy_delete_key_input: Taxonomy_Delete_Key_Input;
  taxonomy_insert_input: Taxonomy_Insert_Input;
  taxonomy_max_fields: Taxonomy_Max_Fields;
  taxonomy_min_fields: Taxonomy_Min_Fields;
  taxonomy_mutation_response: Taxonomy_Mutation_Response;
  taxonomy_obj_rel_insert_input: Taxonomy_Obj_Rel_Insert_Input;
  taxonomy_on_conflict: Taxonomy_On_Conflict;
  taxonomy_order_by: Taxonomy_Order_By;
  taxonomy_org: Taxonomy_Org;
  taxonomy_org_aggregate: Taxonomy_Org_Aggregate;
  taxonomy_org_aggregate_bool_exp: Taxonomy_Org_Aggregate_Bool_Exp;
  taxonomy_org_aggregate_bool_exp_count: Taxonomy_Org_Aggregate_Bool_Exp_Count;
  taxonomy_org_aggregate_fields: Taxonomy_Org_Aggregate_Fields;
  taxonomy_org_aggregate_order_by: Taxonomy_Org_Aggregate_Order_By;
  taxonomy_org_arr_rel_insert_input: Taxonomy_Org_Arr_Rel_Insert_Input;
  taxonomy_org_bool_exp: Taxonomy_Org_Bool_Exp;
  taxonomy_org_insert_input: Taxonomy_Org_Insert_Input;
  taxonomy_org_max_fields: Taxonomy_Org_Max_Fields;
  taxonomy_org_max_order_by: Taxonomy_Org_Max_Order_By;
  taxonomy_org_min_fields: Taxonomy_Org_Min_Fields;
  taxonomy_org_min_order_by: Taxonomy_Org_Min_Order_By;
  taxonomy_org_mutation_response: Taxonomy_Org_Mutation_Response;
  taxonomy_org_on_conflict: Taxonomy_Org_On_Conflict;
  taxonomy_org_order_by: Taxonomy_Org_Order_By;
  taxonomy_org_stream_cursor_input: Taxonomy_Org_Stream_Cursor_Input;
  taxonomy_org_stream_cursor_value_input: Taxonomy_Org_Stream_Cursor_Value_Input;
  taxonomy_pk_columns_input: Taxonomy_Pk_Columns_Input;
  taxonomy_prepend_input: Taxonomy_Prepend_Input;
  taxonomy_set_input: Taxonomy_Set_Input;
  taxonomy_stream_cursor_input: Taxonomy_Stream_Cursor_Input;
  taxonomy_stream_cursor_value_input: Taxonomy_Stream_Cursor_Value_Input;
  taxonomy_updates: Taxonomy_Updates;
  test_frequency_enum_comparison_exp: Test_Frequency_Enum_Comparison_Exp;
  test_result: Test_Result;
  test_result_aggregate: Test_Result_Aggregate;
  test_result_aggregate_bool_exp: Test_Result_Aggregate_Bool_Exp;
  test_result_aggregate_bool_exp_count: Test_Result_Aggregate_Bool_Exp_Count;
  test_result_aggregate_fields: Test_Result_Aggregate_Fields;
  test_result_aggregate_order_by: Test_Result_Aggregate_Order_By;
  test_result_arr_rel_insert_input: Test_Result_Arr_Rel_Insert_Input;
  test_result_audit: Test_Result_Audit;
  test_result_audit_aggregate_order_by: Test_Result_Audit_Aggregate_Order_By;
  test_result_audit_avg_order_by: Test_Result_Audit_Avg_Order_By;
  test_result_audit_bool_exp: Test_Result_Audit_Bool_Exp;
  test_result_audit_max_order_by: Test_Result_Audit_Max_Order_By;
  test_result_audit_min_order_by: Test_Result_Audit_Min_Order_By;
  test_result_audit_order_by: Test_Result_Audit_Order_By;
  test_result_audit_stddev_order_by: Test_Result_Audit_Stddev_Order_By;
  test_result_audit_stddev_pop_order_by: Test_Result_Audit_Stddev_Pop_Order_By;
  test_result_audit_stddev_samp_order_by: Test_Result_Audit_Stddev_Samp_Order_By;
  test_result_audit_stream_cursor_input: Test_Result_Audit_Stream_Cursor_Input;
  test_result_audit_stream_cursor_value_input: Test_Result_Audit_Stream_Cursor_Value_Input;
  test_result_audit_sum_order_by: Test_Result_Audit_Sum_Order_By;
  test_result_audit_var_pop_order_by: Test_Result_Audit_Var_Pop_Order_By;
  test_result_audit_var_samp_order_by: Test_Result_Audit_Var_Samp_Order_By;
  test_result_audit_variance_order_by: Test_Result_Audit_Variance_Order_By;
  test_result_avg_fields: Test_Result_Avg_Fields;
  test_result_avg_order_by: Test_Result_Avg_Order_By;
  test_result_bool_exp: Test_Result_Bool_Exp;
  test_result_insert_input: Test_Result_Insert_Input;
  test_result_max_fields: Test_Result_Max_Fields;
  test_result_max_order_by: Test_Result_Max_Order_By;
  test_result_min_fields: Test_Result_Min_Fields;
  test_result_min_order_by: Test_Result_Min_Order_By;
  test_result_mutation_response: Test_Result_Mutation_Response;
  test_result_obj_rel_insert_input: Test_Result_Obj_Rel_Insert_Input;
  test_result_on_conflict: Test_Result_On_Conflict;
  test_result_order_by: Test_Result_Order_By;
  test_result_stddev_fields: Test_Result_Stddev_Fields;
  test_result_stddev_order_by: Test_Result_Stddev_Order_By;
  test_result_stddev_pop_fields: Test_Result_Stddev_Pop_Fields;
  test_result_stddev_pop_order_by: Test_Result_Stddev_Pop_Order_By;
  test_result_stddev_samp_fields: Test_Result_Stddev_Samp_Fields;
  test_result_stddev_samp_order_by: Test_Result_Stddev_Samp_Order_By;
  test_result_stream_cursor_input: Test_Result_Stream_Cursor_Input;
  test_result_stream_cursor_value_input: Test_Result_Stream_Cursor_Value_Input;
  test_result_sum_fields: Test_Result_Sum_Fields;
  test_result_sum_order_by: Test_Result_Sum_Order_By;
  test_result_var_pop_fields: Test_Result_Var_Pop_Fields;
  test_result_var_pop_order_by: Test_Result_Var_Pop_Order_By;
  test_result_var_samp_fields: Test_Result_Var_Samp_Fields;
  test_result_var_samp_order_by: Test_Result_Var_Samp_Order_By;
  test_result_variance_fields: Test_Result_Variance_Fields;
  test_result_variance_order_by: Test_Result_Variance_Order_By;
  third_party: Third_Party;
  third_party_append_input: Third_Party_Append_Input;
  third_party_bool_exp: Third_Party_Bool_Exp;
  third_party_delete_at_path_input: Third_Party_Delete_At_Path_Input;
  third_party_delete_elem_input: Third_Party_Delete_Elem_Input;
  third_party_delete_key_input: Third_Party_Delete_Key_Input;
  third_party_inc_input: Third_Party_Inc_Input;
  third_party_insert_input: Third_Party_Insert_Input;
  third_party_mutation_response: Third_Party_Mutation_Response;
  third_party_obj_rel_insert_input: Third_Party_Obj_Rel_Insert_Input;
  third_party_on_conflict: Third_Party_On_Conflict;
  third_party_order_by: Third_Party_Order_By;
  third_party_pk_columns_input: Third_Party_Pk_Columns_Input;
  third_party_prepend_input: Third_Party_Prepend_Input;
  third_party_response: Third_Party_Response;
  third_party_response_append_input: Third_Party_Response_Append_Input;
  third_party_response_bool_exp: Third_Party_Response_Bool_Exp;
  third_party_response_delete_at_path_input: Third_Party_Response_Delete_At_Path_Input;
  third_party_response_delete_elem_input: Third_Party_Response_Delete_Elem_Input;
  third_party_response_delete_key_input: Third_Party_Response_Delete_Key_Input;
  third_party_response_insert_input: Third_Party_Response_Insert_Input;
  third_party_response_mutation_response: Third_Party_Response_Mutation_Response;
  third_party_response_obj_rel_insert_input: Third_Party_Response_Obj_Rel_Insert_Input;
  third_party_response_on_conflict: Third_Party_Response_On_Conflict;
  third_party_response_order_by: Third_Party_Response_Order_By;
  third_party_response_pk_columns_input: Third_Party_Response_Pk_Columns_Input;
  third_party_response_prepend_input: Third_Party_Response_Prepend_Input;
  third_party_response_set_input: Third_Party_Response_Set_Input;
  third_party_response_status: Third_Party_Response_Status;
  third_party_response_status_bool_exp: Third_Party_Response_Status_Bool_Exp;
  third_party_response_status_enum_comparison_exp: Third_Party_Response_Status_Enum_Comparison_Exp;
  third_party_response_status_order_by: Third_Party_Response_Status_Order_By;
  third_party_response_status_stream_cursor_input: Third_Party_Response_Status_Stream_Cursor_Input;
  third_party_response_status_stream_cursor_value_input: Third_Party_Response_Status_Stream_Cursor_Value_Input;
  third_party_response_stream_cursor_input: Third_Party_Response_Stream_Cursor_Input;
  third_party_response_stream_cursor_value_input: Third_Party_Response_Stream_Cursor_Value_Input;
  third_party_response_updates: Third_Party_Response_Updates;
  third_party_set_input: Third_Party_Set_Input;
  third_party_status_enum_comparison_exp: Third_Party_Status_Enum_Comparison_Exp;
  third_party_stream_cursor_input: Third_Party_Stream_Cursor_Input;
  third_party_stream_cursor_value_input: Third_Party_Stream_Cursor_Value_Input;
  third_party_type_enum_comparison_exp: Third_Party_Type_Enum_Comparison_Exp;
  third_party_updates: Third_Party_Updates;
  timestamptz: Scalars['timestamptz']['output'];
  timestamptz_comparison_exp: Timestamptz_Comparison_Exp;
  unit_of_time_enum_comparison_exp: Unit_Of_Time_Enum_Comparison_Exp;
  user: User;
  user_aggregate: User_Aggregate;
  user_aggregate_fields: User_Aggregate_Fields;
  user_bool_exp: User_Bool_Exp;
  user_group: User_Group;
  user_group_audit: User_Group_Audit;
  user_group_audit_bool_exp: User_Group_Audit_Bool_Exp;
  user_group_audit_order_by: User_Group_Audit_Order_By;
  user_group_audit_stream_cursor_input: User_Group_Audit_Stream_Cursor_Input;
  user_group_audit_stream_cursor_value_input: User_Group_Audit_Stream_Cursor_Value_Input;
  user_group_bool_exp: User_Group_Bool_Exp;
  user_group_insert_input: User_Group_Insert_Input;
  user_group_mutation_response: User_Group_Mutation_Response;
  user_group_obj_rel_insert_input: User_Group_Obj_Rel_Insert_Input;
  user_group_on_conflict: User_Group_On_Conflict;
  user_group_order_by: User_Group_Order_By;
  user_group_pk_columns_input: User_Group_Pk_Columns_Input;
  user_group_set_input: User_Group_Set_Input;
  user_group_stream_cursor_input: User_Group_Stream_Cursor_Input;
  user_group_stream_cursor_value_input: User_Group_Stream_Cursor_Value_Input;
  user_group_updates: User_Group_Updates;
  user_group_user: User_Group_User;
  user_group_user_aggregate: User_Group_User_Aggregate;
  user_group_user_aggregate_bool_exp: User_Group_User_Aggregate_Bool_Exp;
  user_group_user_aggregate_bool_exp_count: User_Group_User_Aggregate_Bool_Exp_Count;
  user_group_user_aggregate_fields: User_Group_User_Aggregate_Fields;
  user_group_user_aggregate_order_by: User_Group_User_Aggregate_Order_By;
  user_group_user_arr_rel_insert_input: User_Group_User_Arr_Rel_Insert_Input;
  user_group_user_audit: User_Group_User_Audit;
  user_group_user_audit_bool_exp: User_Group_User_Audit_Bool_Exp;
  user_group_user_audit_order_by: User_Group_User_Audit_Order_By;
  user_group_user_audit_stream_cursor_input: User_Group_User_Audit_Stream_Cursor_Input;
  user_group_user_audit_stream_cursor_value_input: User_Group_User_Audit_Stream_Cursor_Value_Input;
  user_group_user_bool_exp: User_Group_User_Bool_Exp;
  user_group_user_insert_input: User_Group_User_Insert_Input;
  user_group_user_max_fields: User_Group_User_Max_Fields;
  user_group_user_max_order_by: User_Group_User_Max_Order_By;
  user_group_user_min_fields: User_Group_User_Min_Fields;
  user_group_user_min_order_by: User_Group_User_Min_Order_By;
  user_group_user_mutation_response: User_Group_User_Mutation_Response;
  user_group_user_on_conflict: User_Group_User_On_Conflict;
  user_group_user_order_by: User_Group_User_Order_By;
  user_group_user_stream_cursor_input: User_Group_User_Stream_Cursor_Input;
  user_group_user_stream_cursor_value_input: User_Group_User_Stream_Cursor_Value_Input;
  user_max_fields: User_Max_Fields;
  user_min_fields: User_Min_Fields;
  user_order_by: User_Order_By;
  user_search_preferences: User_Search_Preferences;
  user_search_preferences_audit: User_Search_Preferences_Audit;
  user_search_preferences_audit_bool_exp: User_Search_Preferences_Audit_Bool_Exp;
  user_search_preferences_audit_order_by: User_Search_Preferences_Audit_Order_By;
  user_search_preferences_audit_stream_cursor_input: User_Search_Preferences_Audit_Stream_Cursor_Input;
  user_search_preferences_audit_stream_cursor_value_input: User_Search_Preferences_Audit_Stream_Cursor_Value_Input;
  user_search_preferences_bool_exp: User_Search_Preferences_Bool_Exp;
  user_search_preferences_insert_input: User_Search_Preferences_Insert_Input;
  user_search_preferences_mutation_response: User_Search_Preferences_Mutation_Response;
  user_search_preferences_on_conflict: User_Search_Preferences_On_Conflict;
  user_search_preferences_order_by: User_Search_Preferences_Order_By;
  user_search_preferences_set_input: User_Search_Preferences_Set_Input;
  user_search_preferences_stream_cursor_input: User_Search_Preferences_Stream_Cursor_Input;
  user_search_preferences_stream_cursor_value_input: User_Search_Preferences_Stream_Cursor_Value_Input;
  user_search_preferences_updates: User_Search_Preferences_Updates;
  user_status_enum_comparison_exp: User_Status_Enum_Comparison_Exp;
  user_stream_cursor_input: User_Stream_Cursor_Input;
  user_stream_cursor_value_input: User_Stream_Cursor_Value_Input;
  user_table_preferences: User_Table_Preferences;
  user_table_preferences_append_input: User_Table_Preferences_Append_Input;
  user_table_preferences_bool_exp: User_Table_Preferences_Bool_Exp;
  user_table_preferences_delete_at_path_input: User_Table_Preferences_Delete_At_Path_Input;
  user_table_preferences_delete_elem_input: User_Table_Preferences_Delete_Elem_Input;
  user_table_preferences_delete_key_input: User_Table_Preferences_Delete_Key_Input;
  user_table_preferences_insert_input: User_Table_Preferences_Insert_Input;
  user_table_preferences_mutation_response: User_Table_Preferences_Mutation_Response;
  user_table_preferences_on_conflict: User_Table_Preferences_On_Conflict;
  user_table_preferences_order_by: User_Table_Preferences_Order_By;
  user_table_preferences_prepend_input: User_Table_Preferences_Prepend_Input;
  user_table_preferences_set_input: User_Table_Preferences_Set_Input;
  user_table_preferences_stream_cursor_input: User_Table_Preferences_Stream_Cursor_Input;
  user_table_preferences_stream_cursor_value_input: User_Table_Preferences_Stream_Cursor_Value_Input;
  user_table_preferences_updates: User_Table_Preferences_Updates;
  uuid: Scalars['uuid']['output'];
  uuid_comparison_exp: Uuid_Comparison_Exp;
  version_status_enum_comparison_exp: Version_Status_Enum_Comparison_Exp;
};

export type CachedDirectiveArgs = {
  refresh?: Scalars['Boolean']['input'];
  ttl?: Scalars['Int']['input'];
};

export type CachedDirectiveResolver<Result, Parent, ContextType = any, Args = CachedDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type DataImportStartImportOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DataImportStartImportOutput'] = ResolversParentTypes['DataImportStartImportOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DataImportValidateOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DataImportValidateOutput'] = ResolversParentTypes['DataImportValidateOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DisconnectSlackOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DisconnectSlackOutput'] = ResolversParentTypes['DisconnectSlackOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenericMutationOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenericMutationOutput'] = ResolversParentTypes['GenericMutationOutput']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['IdOutput'] = ResolversParentTypes['IdOutput']> = {
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsertChildDocumentAssessmentResultOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['InsertChildDocumentAssessmentResultOutput'] = ResolversParentTypes['InsertChildDocumentAssessmentResultOutput']> = {
  Ids?: Resolver<Array<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsertChildObligationAssessmentResultOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['InsertChildObligationAssessmentResultOutput'] = ResolversParentTypes['InsertChildObligationAssessmentResultOutput']> = {
  Ids?: Resolver<Array<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsertChildRiskAssessmentResultOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['InsertChildRiskAssessmentResultOutput'] = ResolversParentTypes['InsertChildRiskAssessmentResultOutput']> = {
  Ids?: Resolver<Array<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkItemRecordOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['LinkItemRecordOutput'] = ResolversParentTypes['LinkItemRecordOutput']> = {
  RelationshipType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Source?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Target?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkItemsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['LinkItemsOutput'] = ResolversParentTypes['LinkItemsOutput']> = {
  Links?: Resolver<Array<ResolversTypes['LinkItemRecordOutput']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationPreferencesOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationPreferencesOutput'] = ResolversParentTypes['NotificationPreferencesOutput']> = {
  categories?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType>;
  channel_types?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workflows?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecalculateAppetitesOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecalculateAppetitesOutput'] = ResolversParentTypes['RecalculateAppetitesOutput']> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecalculateRiskScoresOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecalculateRiskScoresOutput'] = ResolversParentTypes['RecalculateRiskScoresOutput']> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportDataOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReportDataOutput'] = ResolversParentTypes['ReportDataOutput']> = {
  OrgKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoleOutput'] = ResolversParentTypes['RoleOutput']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SlackNotificationConnectionOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SlackNotificationConnectionOutput'] = ResolversParentTypes['SlackNotificationConnectionOutput']> = {
  connected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnlinkItemsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnlinkItemsOutput'] = ResolversParentTypes['UnlinkItemsOutput']> = {
  Ids?: Resolver<Maybe<Array<Maybe<ResolversTypes['uuid']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateNotificationPreferencesOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateNotificationPreferencesOutput'] = ResolversParentTypes['UpdateNotificationPreferencesOutput']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateUserRolesMutationOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateUserRolesMutationOutput'] = ResolversParentTypes['UpdateUserRolesMutationOutput']> = {
  roles?: Resolver<Array<Maybe<ResolversTypes['RoleOutput']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AcceptanceResolvers<ContextType = any, ParentType extends ResolversParentTypes['acceptance'] = ResolversParentTypes['acceptance']> = {
  ApprovedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ApprovedByUserGroup?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<AcceptanceCustomAttributeDataArgs>>;
  DateAcceptedFrom?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  DateAcceptedTo?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  Details?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  RequestedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RequestedByUserGroup?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Status?: Resolver<ResolversTypes['acceptance_status_enum'], ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<AcceptanceAncestorContributorsArgs>>;
  approvedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  approvedByUserGroup?: Resolver<Maybe<ResolversTypes['user_group']>, ParentType, ContextType>;
  changeRequests?: Resolver<Array<ResolversTypes['change_request']>, ParentType, ContextType, Partial<AcceptanceChangeRequestsArgs>>;
  changeRequests_aggregate?: Resolver<ResolversTypes['change_request_aggregate'], ParentType, ContextType, Partial<AcceptanceChangeRequests_AggregateArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  files?: Resolver<Array<ResolversTypes['relation_file']>, ParentType, ContextType, Partial<AcceptanceFilesArgs>>;
  files_aggregate?: Resolver<ResolversTypes['relation_file_aggregate'], ParentType, ContextType, Partial<AcceptanceFiles_AggregateArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parents?: Resolver<Array<ResolversTypes['acceptance_parent']>, ParentType, ContextType, Partial<AcceptanceParentsArgs>>;
  parents_aggregate?: Resolver<ResolversTypes['acceptance_parent_aggregate'], ParentType, ContextType, Partial<AcceptanceParents_AggregateArgs>>;
  requestedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  requestedByUserGroup?: Resolver<Maybe<ResolversTypes['user_group']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Acceptance_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['acceptance_aggregate'] = ResolversParentTypes['acceptance_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['acceptance_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['acceptance']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Acceptance_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['acceptance_aggregate_fields'] = ResolversParentTypes['acceptance_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['acceptance_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Acceptance_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['acceptance_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['acceptance_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['acceptance_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['acceptance_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['acceptance_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['acceptance_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['acceptance_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['acceptance_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['acceptance_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Acceptance_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['acceptance_audit'] = ResolversParentTypes['acceptance_audit']> = {
  ApprovedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ApprovedByUserGroup?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Acceptance_AuditCustomAttributeDataArgs>>;
  DateAcceptedFrom?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  DateAcceptedTo?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  Details?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RequestedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RequestedByUserGroup?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Acceptance_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['acceptance_avg_fields'] = ResolversParentTypes['acceptance_avg_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Acceptance_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['acceptance_max_fields'] = ResolversParentTypes['acceptance_max_fields']> = {
  ApprovedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ApprovedByUserGroup?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateAcceptedFrom?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  DateAcceptedTo?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Details?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RequestedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RequestedByUserGroup?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Acceptance_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['acceptance_min_fields'] = ResolversParentTypes['acceptance_min_fields']> = {
  ApprovedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ApprovedByUserGroup?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateAcceptedFrom?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  DateAcceptedTo?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Details?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RequestedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RequestedByUserGroup?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Acceptance_ParentResolvers<ContextType = any, ParentType extends ResolversParentTypes['acceptance_parent'] = ResolversParentTypes['acceptance_parent']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  acceptance?: Resolver<Maybe<ResolversTypes['acceptance']>, ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType>;
  risk?: Resolver<Maybe<ResolversTypes['risk']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Acceptance_Parent_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['acceptance_parent_aggregate'] = ResolversParentTypes['acceptance_parent_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['acceptance_parent_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['acceptance_parent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Acceptance_Parent_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['acceptance_parent_aggregate_fields'] = ResolversParentTypes['acceptance_parent_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Acceptance_Parent_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['acceptance_parent_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['acceptance_parent_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Acceptance_Parent_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['acceptance_parent_audit'] = ResolversParentTypes['acceptance_parent_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Acceptance_Parent_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['acceptance_parent_max_fields'] = ResolversParentTypes['acceptance_parent_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Acceptance_Parent_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['acceptance_parent_min_fields'] = ResolversParentTypes['acceptance_parent_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Acceptance_Parent_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['acceptance_parent_mutation_response'] = ResolversParentTypes['acceptance_parent_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['acceptance_parent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Acceptance_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['acceptance_stddev_fields'] = ResolversParentTypes['acceptance_stddev_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Acceptance_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['acceptance_stddev_pop_fields'] = ResolversParentTypes['acceptance_stddev_pop_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Acceptance_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['acceptance_stddev_samp_fields'] = ResolversParentTypes['acceptance_stddev_samp_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Acceptance_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['acceptance_sum_fields'] = ResolversParentTypes['acceptance_sum_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Acceptance_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['acceptance_var_pop_fields'] = ResolversParentTypes['acceptance_var_pop_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Acceptance_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['acceptance_var_samp_fields'] = ResolversParentTypes['acceptance_var_samp_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Acceptance_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['acceptance_variance_fields'] = ResolversParentTypes['acceptance_variance_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActionResolvers<ContextType = any, ParentType extends ResolversParentTypes['action'] = ResolversParentTypes['action']> = {
  ClosedDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<ActionCustomAttributeDataArgs>>;
  DateDue?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  DateRaised?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Status?: Resolver<ResolversTypes['action_status_enum'], ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  actionUpdateSummary?: Resolver<Maybe<ResolversTypes['action_update_summary']>, ParentType, ContextType>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<ActionAncestorContributorsArgs>>;
  contributorGroups?: Resolver<Array<ResolversTypes['contributor_group']>, ParentType, ContextType, Partial<ActionContributorGroupsArgs>>;
  contributorGroups_aggregate?: Resolver<ResolversTypes['contributor_group_aggregate'], ParentType, ContextType, Partial<ActionContributorGroups_AggregateArgs>>;
  contributors?: Resolver<Array<ResolversTypes['contributor']>, ParentType, ContextType, Partial<ActionContributorsArgs>>;
  contributors_aggregate?: Resolver<ResolversTypes['contributor_aggregate'], ParentType, ContextType, Partial<ActionContributors_AggregateArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  departments?: Resolver<Array<ResolversTypes['department']>, ParentType, ContextType, Partial<ActionDepartmentsArgs>>;
  files?: Resolver<Array<ResolversTypes['relation_file']>, ParentType, ContextType, Partial<ActionFilesArgs>>;
  files_aggregate?: Resolver<ResolversTypes['relation_file_aggregate'], ParentType, ContextType, Partial<ActionFiles_AggregateArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  ownerGroups?: Resolver<Array<ResolversTypes['owner_group']>, ParentType, ContextType, Partial<ActionOwnerGroupsArgs>>;
  ownerGroups_aggregate?: Resolver<ResolversTypes['owner_group_aggregate'], ParentType, ContextType, Partial<ActionOwnerGroups_AggregateArgs>>;
  owners?: Resolver<Array<ResolversTypes['owner']>, ParentType, ContextType, Partial<ActionOwnersArgs>>;
  owners_aggregate?: Resolver<ResolversTypes['owner_aggregate'], ParentType, ContextType, Partial<ActionOwners_AggregateArgs>>;
  parents?: Resolver<Array<ResolversTypes['action_parent']>, ParentType, ContextType, Partial<ActionParentsArgs>>;
  parents_aggregate?: Resolver<ResolversTypes['action_parent_aggregate'], ParentType, ContextType, Partial<ActionParents_AggregateArgs>>;
  tags?: Resolver<Array<ResolversTypes['tag']>, ParentType, ContextType, Partial<ActionTagsArgs>>;
  updates?: Resolver<Array<ResolversTypes['action_update']>, ParentType, ContextType, Partial<ActionUpdatesArgs>>;
  updates_aggregate?: Resolver<ResolversTypes['action_update_aggregate'], ParentType, ContextType, Partial<ActionUpdates_AggregateArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_aggregate'] = ResolversParentTypes['action_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['action_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['action']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_aggregate_fields'] = ResolversParentTypes['action_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['action_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Action_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['action_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['action_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['action_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['action_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['action_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['action_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['action_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['action_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['action_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_audit'] = ResolversParentTypes['action_audit']> = {
  ClosedDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Action_AuditCustomAttributeDataArgs>>;
  DateDue?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  DateRaised?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Audit_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_audit_aggregate'] = ResolversParentTypes['action_audit_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['action_audit_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['action_audit']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Audit_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_audit_aggregate_fields'] = ResolversParentTypes['action_audit_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['action_audit_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Action_Audit_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['action_audit_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['action_audit_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['action_audit_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['action_audit_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['action_audit_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['action_audit_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['action_audit_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['action_audit_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['action_audit_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Audit_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_audit_avg_fields'] = ResolversParentTypes['action_audit_avg_fields']> = {
  Priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Audit_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_audit_max_fields'] = ResolversParentTypes['action_audit_max_fields']> = {
  ClosedDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateDue?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  DateRaised?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Audit_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_audit_min_fields'] = ResolversParentTypes['action_audit_min_fields']> = {
  ClosedDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateDue?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  DateRaised?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Audit_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_audit_stddev_fields'] = ResolversParentTypes['action_audit_stddev_fields']> = {
  Priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Audit_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_audit_stddev_pop_fields'] = ResolversParentTypes['action_audit_stddev_pop_fields']> = {
  Priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Audit_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_audit_stddev_samp_fields'] = ResolversParentTypes['action_audit_stddev_samp_fields']> = {
  Priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Audit_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_audit_sum_fields'] = ResolversParentTypes['action_audit_sum_fields']> = {
  Priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Audit_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_audit_var_pop_fields'] = ResolversParentTypes['action_audit_var_pop_fields']> = {
  Priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Audit_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_audit_var_samp_fields'] = ResolversParentTypes['action_audit_var_samp_fields']> = {
  Priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Audit_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_audit_variance_fields'] = ResolversParentTypes['action_audit_variance_fields']> = {
  Priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_avg_fields'] = ResolversParentTypes['action_avg_fields']> = {
  Priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_max_fields'] = ResolversParentTypes['action_max_fields']> = {
  ClosedDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateDue?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  DateRaised?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_min_fields'] = ResolversParentTypes['action_min_fields']> = {
  ClosedDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateDue?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  DateRaised?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_ParentResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_parent'] = ResolversParentTypes['action_parent']> = {
  ActionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ParentType?: Resolver<ResolversTypes['parent_type_enum'], ParentType, ContextType>;
  action?: Resolver<Maybe<ResolversTypes['action']>, ParentType, ContextType>;
  assessment?: Resolver<Maybe<ResolversTypes['assessment']>, ParentType, ContextType>;
  complianceMonitoringAssessment?: Resolver<Maybe<ResolversTypes['compliance_monitoring_assessment']>, ParentType, ContextType>;
  control?: Resolver<Maybe<ResolversTypes['control']>, ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  document?: Resolver<Maybe<ResolversTypes['document']>, ParentType, ContextType>;
  internalAuditEntity?: Resolver<Maybe<ResolversTypes['internal_audit_entity']>, ParentType, ContextType>;
  internalAuditReport?: Resolver<Maybe<ResolversTypes['internal_audit_report']>, ParentType, ContextType>;
  issue?: Resolver<Maybe<ResolversTypes['issue']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  obligation?: Resolver<Maybe<ResolversTypes['obligation']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType>;
  risk?: Resolver<Maybe<ResolversTypes['risk']>, ParentType, ContextType>;
  thirdParty?: Resolver<Maybe<ResolversTypes['third_party']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Parent_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_parent_aggregate'] = ResolversParentTypes['action_parent_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['action_parent_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['action_parent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Parent_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_parent_aggregate_fields'] = ResolversParentTypes['action_parent_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Action_Parent_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['action_parent_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['action_parent_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Parent_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_parent_audit'] = ResolversParentTypes['action_parent_audit']> = {
  ActionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ParentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Parent_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_parent_max_fields'] = ResolversParentTypes['action_parent_max_fields']> = {
  ActionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Parent_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_parent_min_fields'] = ResolversParentTypes['action_parent_min_fields']> = {
  ActionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Parent_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_parent_mutation_response'] = ResolversParentTypes['action_parent_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['action_parent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_stddev_fields'] = ResolversParentTypes['action_stddev_fields']> = {
  Priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_stddev_pop_fields'] = ResolversParentTypes['action_stddev_pop_fields']> = {
  Priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_stddev_samp_fields'] = ResolversParentTypes['action_stddev_samp_fields']> = {
  Priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_sum_fields'] = ResolversParentTypes['action_sum_fields']> = {
  Priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_update'] = ResolversParentTypes['action_update']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Action_UpdateCustomAttributeDataArgs>>;
  Description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentActionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  action?: Resolver<Maybe<ResolversTypes['action']>, ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  files?: Resolver<Array<ResolversTypes['relation_file']>, ParentType, ContextType, Partial<Action_UpdateFilesArgs>>;
  files_aggregate?: Resolver<ResolversTypes['relation_file_aggregate'], ParentType, ContextType, Partial<Action_UpdateFiles_AggregateArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Update_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_update_aggregate'] = ResolversParentTypes['action_update_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['action_update_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['action_update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Update_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_update_aggregate_fields'] = ResolversParentTypes['action_update_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Action_Update_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['action_update_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['action_update_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Update_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_update_audit'] = ResolversParentTypes['action_update_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Action_Update_AuditCustomAttributeDataArgs>>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentActionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Update_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_update_max_fields'] = ResolversParentTypes['action_update_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentActionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Update_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_update_min_fields'] = ResolversParentTypes['action_update_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentActionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Update_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_update_mutation_response'] = ResolversParentTypes['action_update_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['action_update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Update_SummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_update_summary'] = ResolversParentTypes['action_update_summary']> = {
  ActionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Count?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  LatestCreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  LatestDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LatestTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Update_Summary_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_update_summary_aggregate'] = ResolversParentTypes['action_update_summary_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['action_update_summary_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['action_update_summary']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Update_Summary_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_update_summary_aggregate_fields'] = ResolversParentTypes['action_update_summary_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['action_update_summary_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Action_Update_Summary_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['action_update_summary_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['action_update_summary_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['action_update_summary_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['action_update_summary_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['action_update_summary_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['action_update_summary_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['action_update_summary_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['action_update_summary_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['action_update_summary_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Update_Summary_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_update_summary_avg_fields'] = ResolversParentTypes['action_update_summary_avg_fields']> = {
  Count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Update_Summary_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_update_summary_max_fields'] = ResolversParentTypes['action_update_summary_max_fields']> = {
  ActionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Count?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  LatestCreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  LatestDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LatestTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Update_Summary_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_update_summary_min_fields'] = ResolversParentTypes['action_update_summary_min_fields']> = {
  ActionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Count?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  LatestCreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  LatestDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LatestTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Update_Summary_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_update_summary_stddev_fields'] = ResolversParentTypes['action_update_summary_stddev_fields']> = {
  Count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Update_Summary_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_update_summary_stddev_pop_fields'] = ResolversParentTypes['action_update_summary_stddev_pop_fields']> = {
  Count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Update_Summary_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_update_summary_stddev_samp_fields'] = ResolversParentTypes['action_update_summary_stddev_samp_fields']> = {
  Count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Update_Summary_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_update_summary_sum_fields'] = ResolversParentTypes['action_update_summary_sum_fields']> = {
  Count?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Update_Summary_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_update_summary_var_pop_fields'] = ResolversParentTypes['action_update_summary_var_pop_fields']> = {
  Count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Update_Summary_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_update_summary_var_samp_fields'] = ResolversParentTypes['action_update_summary_var_samp_fields']> = {
  Count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Update_Summary_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_update_summary_variance_fields'] = ResolversParentTypes['action_update_summary_variance_fields']> = {
  Count?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_var_pop_fields'] = ResolversParentTypes['action_var_pop_fields']> = {
  Priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_var_samp_fields'] = ResolversParentTypes['action_var_samp_fields']> = {
  Priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Action_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['action_variance_fields'] = ResolversParentTypes['action_variance_fields']> = {
  Priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Aggregation_OrgResolvers<ContextType = any, ParentType extends ResolversParentTypes['aggregation_org'] = ResolversParentTypes['aggregation_org']> = {
  Appetite?: Resolver<Maybe<ResolversTypes['appetite_model_enum']>, ParentType, ContextType>;
  Config?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Aggregation_OrgConfigArgs>>;
  OrgKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  RiskScoringModel?: Resolver<Maybe<ResolversTypes['risk_scoring_model_enum']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Ancestor_ContributorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ancestor_contributor'] = ResolversParentTypes['ancestor_contributor']> = {
  AncestorId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ContributorType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserGroupId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  UserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  acceptance?: Resolver<Maybe<ResolversTypes['acceptance']>, ParentType, ContextType>;
  action?: Resolver<Maybe<ResolversTypes['action']>, ParentType, ContextType>;
  appetite?: Resolver<Maybe<ResolversTypes['appetite']>, ParentType, ContextType>;
  assessment?: Resolver<Maybe<ResolversTypes['assessment']>, ParentType, ContextType>;
  control?: Resolver<Maybe<ResolversTypes['control']>, ParentType, ContextType>;
  dashboard?: Resolver<Maybe<ResolversTypes['dashboard']>, ParentType, ContextType>;
  document?: Resolver<Maybe<ResolversTypes['document']>, ParentType, ContextType>;
  document_assessment_result?: Resolver<Maybe<ResolversTypes['document_assessment_result']>, ParentType, ContextType>;
  impact?: Resolver<Maybe<ResolversTypes['impact']>, ParentType, ContextType>;
  indicator?: Resolver<Maybe<ResolversTypes['indicator']>, ParentType, ContextType>;
  issue?: Resolver<Maybe<ResolversTypes['issue']>, ParentType, ContextType>;
  node?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType>;
  obligation?: Resolver<Maybe<ResolversTypes['obligation']>, ParentType, ContextType>;
  obligationAssessmentResult?: Resolver<Maybe<ResolversTypes['obligation_assessment_result']>, ParentType, ContextType>;
  risk?: Resolver<Maybe<ResolversTypes['risk']>, ParentType, ContextType>;
  risk_assessment_result?: Resolver<Maybe<ResolversTypes['risk_assessment_result']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  user_group?: Resolver<Maybe<ResolversTypes['user_group']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppetiteResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite'] = ResolversParentTypes['appetite']> = {
  AppetiteType?: Resolver<ResolversTypes['appetite_type_enum'], ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<AppetiteCustomAttributeDataArgs>>;
  EffectiveDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ImpactAppetite?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  LowerAppetite?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Statement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UpperAppetite?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<AppetiteAncestorContributorsArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  files?: Resolver<Array<ResolversTypes['relation_file']>, ParentType, ContextType, Partial<AppetiteFilesArgs>>;
  files_aggregate?: Resolver<ResolversTypes['relation_file_aggregate'], ParentType, ContextType, Partial<AppetiteFiles_AggregateArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parents?: Resolver<Array<ResolversTypes['appetite_parent']>, ParentType, ContextType, Partial<AppetiteParentsArgs>>;
  parents_aggregate?: Resolver<ResolversTypes['appetite_parent_aggregate'], ParentType, ContextType, Partial<AppetiteParents_AggregateArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_aggregate'] = ResolversParentTypes['appetite_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['appetite_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['appetite']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_aggregate_fields'] = ResolversParentTypes['appetite_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['appetite_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Appetite_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['appetite_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['appetite_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['appetite_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['appetite_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['appetite_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['appetite_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['appetite_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['appetite_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['appetite_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_audit'] = ResolversParentTypes['appetite_audit']> = {
  AppetiteType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Appetite_AuditCustomAttributeDataArgs>>;
  EffectiveDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ImpactAppetite?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  LowerAppetite?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Statement?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  UpperAppetite?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_avg_fields'] = ResolversParentTypes['appetite_avg_fields']> = {
  ImpactAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LowerAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UpperAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_max_fields'] = ResolversParentTypes['appetite_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EffectiveDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ImpactAppetite?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  LowerAppetite?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Statement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UpperAppetite?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_min_fields'] = ResolversParentTypes['appetite_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EffectiveDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ImpactAppetite?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  LowerAppetite?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Statement?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UpperAppetite?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_mutation_response'] = ResolversParentTypes['appetite_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['appetite']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_ParentResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_parent'] = ResolversParentTypes['appetite_parent']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  appetite?: Resolver<Maybe<ResolversTypes['appetite']>, ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  impact?: Resolver<Maybe<ResolversTypes['impact']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType>;
  risk?: Resolver<Maybe<ResolversTypes['risk']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_Parent_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_parent_aggregate'] = ResolversParentTypes['appetite_parent_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['appetite_parent_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['appetite_parent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_Parent_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_parent_aggregate_fields'] = ResolversParentTypes['appetite_parent_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Appetite_Parent_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['appetite_parent_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['appetite_parent_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_Parent_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_parent_audit'] = ResolversParentTypes['appetite_parent_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_Parent_Audit_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_parent_audit_aggregate'] = ResolversParentTypes['appetite_parent_audit_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['appetite_parent_audit_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['appetite_parent_audit']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_Parent_Audit_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_parent_audit_aggregate_fields'] = ResolversParentTypes['appetite_parent_audit_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Appetite_Parent_Audit_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['appetite_parent_audit_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['appetite_parent_audit_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_Parent_Audit_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_parent_audit_max_fields'] = ResolversParentTypes['appetite_parent_audit_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_Parent_Audit_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_parent_audit_min_fields'] = ResolversParentTypes['appetite_parent_audit_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_Parent_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_parent_max_fields'] = ResolversParentTypes['appetite_parent_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_Parent_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_parent_min_fields'] = ResolversParentTypes['appetite_parent_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_Parent_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_parent_mutation_response'] = ResolversParentTypes['appetite_parent_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['appetite_parent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_stddev_fields'] = ResolversParentTypes['appetite_stddev_fields']> = {
  ImpactAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LowerAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UpperAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_stddev_pop_fields'] = ResolversParentTypes['appetite_stddev_pop_fields']> = {
  ImpactAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LowerAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UpperAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_stddev_samp_fields'] = ResolversParentTypes['appetite_stddev_samp_fields']> = {
  ImpactAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LowerAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UpperAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_sum_fields'] = ResolversParentTypes['appetite_sum_fields']> = {
  ImpactAppetite?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  LowerAppetite?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  UpperAppetite?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_var_pop_fields'] = ResolversParentTypes['appetite_var_pop_fields']> = {
  ImpactAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LowerAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UpperAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_var_samp_fields'] = ResolversParentTypes['appetite_var_samp_fields']> = {
  ImpactAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LowerAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UpperAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Appetite_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['appetite_variance_fields'] = ResolversParentTypes['appetite_variance_fields']> = {
  ImpactAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  LowerAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  UpperAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApprovalResolvers<ContextType = any, ParentType extends ResolversParentTypes['approval'] = ResolversParentTypes['approval']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  InFlightEditRule?: Resolver<ResolversTypes['approval_in_flight_edit_rule_enum'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Workflow?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  levels?: Resolver<Array<ResolversTypes['approval_level']>, ParentType, ContextType, Partial<ApprovalLevelsArgs>>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  org?: Resolver<Maybe<ResolversTypes['auth_organisation']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Approval_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['approval_audit'] = ResolversParentTypes['approval_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  InFlightEditRule?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Workflow?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Approval_LevelResolvers<ContextType = any, ParentType extends ResolversParentTypes['approval_level'] = ResolversParentTypes['approval_level']> = {
  ApprovalId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ApprovalRuleType?: Resolver<ResolversTypes['approval_rule_type_enum'], ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  SequenceOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  approval?: Resolver<Maybe<ResolversTypes['approval']>, ParentType, ContextType>;
  approvers?: Resolver<Array<ResolversTypes['approver']>, ParentType, ContextType, Partial<Approval_LevelApproversArgs>>;
  approvers_aggregate?: Resolver<ResolversTypes['approver_aggregate'], ParentType, ContextType, Partial<Approval_LevelApprovers_AggregateArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Approval_Level_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['approval_level_audit'] = ResolversParentTypes['approval_level_audit']> = {
  ApprovalId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ApprovalRuleType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  SequenceOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Approval_Level_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['approval_level_mutation_response'] = ResolversParentTypes['approval_level_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['approval_level']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Approval_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['approval_mutation_response'] = ResolversParentTypes['approval_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['approval']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApproverResolvers<ContextType = any, ParentType extends ResolversParentTypes['approver'] = ResolversParentTypes['approver']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  LevelId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  OwnerApprover?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  UserGroupId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  UserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  group?: Resolver<Maybe<ResolversTypes['user_group']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['approval_level']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  org?: Resolver<Maybe<ResolversTypes['auth_organisation']>, ParentType, ContextType>;
  responses?: Resolver<Array<ResolversTypes['approver_response']>, ParentType, ContextType, Partial<ApproverResponsesArgs>>;
  user?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Approver_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['approver_aggregate'] = ResolversParentTypes['approver_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['approver_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['approver']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Approver_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['approver_aggregate_fields'] = ResolversParentTypes['approver_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Approver_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['approver_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['approver_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Approver_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['approver_audit'] = ResolversParentTypes['approver_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  LevelId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  OwnerApprover?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  UserGroupId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  UserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Approver_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['approver_max_fields'] = ResolversParentTypes['approver_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  LevelId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserGroupId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  UserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Approver_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['approver_min_fields'] = ResolversParentTypes['approver_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  LevelId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserGroupId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  UserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Approver_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['approver_mutation_response'] = ResolversParentTypes['approver_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['approver']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Approver_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['approver_response'] = ResolversParentTypes['approver_response']> = {
  Approved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ApprovedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ApprovedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  approvedBy?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  approver?: Resolver<ResolversTypes['approver'], ParentType, ContextType>;
  changeRequest?: Resolver<Maybe<ResolversTypes['change_request']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssessmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment'] = ResolversParentTypes['assessment']> = {
  ActualCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CompletedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<AssessmentCustomAttributeDataArgs>>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  NextTestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OriginatingItemId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Outcome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Status?: Resolver<ResolversTypes['assessment_status_enum'], ParentType, ContextType>;
  Summary?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  TargetCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<AssessmentAncestorContributorsArgs>>;
  assessmentActions?: Resolver<Array<ResolversTypes['action_parent']>, ParentType, ContextType, Partial<AssessmentAssessmentActionsArgs>>;
  assessmentActions_aggregate?: Resolver<ResolversTypes['action_parent_aggregate'], ParentType, ContextType, Partial<AssessmentAssessmentActions_AggregateArgs>>;
  assessmentActivities?: Resolver<Array<ResolversTypes['assessment_activity']>, ParentType, ContextType, Partial<AssessmentAssessmentActivitiesArgs>>;
  assessmentActivities_aggregate?: Resolver<ResolversTypes['assessment_activity_aggregate'], ParentType, ContextType, Partial<AssessmentAssessmentActivities_AggregateArgs>>;
  assessmentIssues?: Resolver<Array<ResolversTypes['issue_parent']>, ParentType, ContextType, Partial<AssessmentAssessmentIssuesArgs>>;
  assessmentIssues_aggregate?: Resolver<ResolversTypes['issue_parent_aggregate'], ParentType, ContextType, Partial<AssessmentAssessmentIssues_AggregateArgs>>;
  assessmentResults?: Resolver<Array<ResolversTypes['assessment_result_parent']>, ParentType, ContextType, Partial<AssessmentAssessmentResultsArgs>>;
  assessmentResults_aggregate?: Resolver<ResolversTypes['assessment_result_parent_aggregate'], ParentType, ContextType, Partial<AssessmentAssessmentResults_AggregateArgs>>;
  completedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  contributorGroups?: Resolver<Array<ResolversTypes['contributor_group']>, ParentType, ContextType, Partial<AssessmentContributorGroupsArgs>>;
  contributorGroups_aggregate?: Resolver<ResolversTypes['contributor_group_aggregate'], ParentType, ContextType, Partial<AssessmentContributorGroups_AggregateArgs>>;
  contributors?: Resolver<Array<ResolversTypes['contributor']>, ParentType, ContextType, Partial<AssessmentContributorsArgs>>;
  contributors_aggregate?: Resolver<ResolversTypes['contributor_aggregate'], ParentType, ContextType, Partial<AssessmentContributors_AggregateArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  departments?: Resolver<Array<ResolversTypes['department']>, ParentType, ContextType, Partial<AssessmentDepartmentsArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  originatingItem?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType>;
  ownerGroups?: Resolver<Array<ResolversTypes['owner_group']>, ParentType, ContextType, Partial<AssessmentOwnerGroupsArgs>>;
  ownerGroups_aggregate?: Resolver<ResolversTypes['owner_group_aggregate'], ParentType, ContextType, Partial<AssessmentOwnerGroups_AggregateArgs>>;
  owners?: Resolver<Array<ResolversTypes['owner']>, ParentType, ContextType, Partial<AssessmentOwnersArgs>>;
  owners_aggregate?: Resolver<ResolversTypes['owner_aggregate'], ParentType, ContextType, Partial<AssessmentOwners_AggregateArgs>>;
  tags?: Resolver<Array<ResolversTypes['tag']>, ParentType, ContextType, Partial<AssessmentTagsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_ActivityResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_activity'] = ResolversParentTypes['assessment_activity']> = {
  ActivityType?: Resolver<ResolversTypes['assessment_activity_type_enum'], ParentType, ContextType>;
  AssignedUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Assessment_ActivityCustomAttributeDataArgs>>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['assessment_activity_status_enum']>, ParentType, ContextType>;
  Summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assignedUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  files?: Resolver<Array<ResolversTypes['relation_file']>, ParentType, ContextType, Partial<Assessment_ActivityFilesArgs>>;
  files_aggregate?: Resolver<ResolversTypes['relation_file_aggregate'], ParentType, ContextType, Partial<Assessment_ActivityFiles_AggregateArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parentAssessment?: Resolver<Maybe<ResolversTypes['assessment']>, ParentType, ContextType>;
  parentComplianceMonitoringAssessment?: Resolver<Maybe<ResolversTypes['compliance_monitoring_assessment']>, ParentType, ContextType>;
  parentInternalAuditReport?: Resolver<Maybe<ResolversTypes['internal_audit_report']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Activity_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_activity_aggregate'] = ResolversParentTypes['assessment_activity_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['assessment_activity_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['assessment_activity']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Activity_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_activity_aggregate_fields'] = ResolversParentTypes['assessment_activity_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Assessment_Activity_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['assessment_activity_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['assessment_activity_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Activity_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_activity_audit'] = ResolversParentTypes['assessment_activity_audit']> = {
  ActivityType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  AssignedUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Assessment_Activity_AuditCustomAttributeDataArgs>>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Activity_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_activity_max_fields'] = ResolversParentTypes['assessment_activity_max_fields']> = {
  AssignedUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Activity_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_activity_min_fields'] = ResolversParentTypes['assessment_activity_min_fields']> = {
  AssignedUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Activity_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_activity_mutation_response'] = ResolversParentTypes['assessment_activity_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['assessment_activity']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_aggregate'] = ResolversParentTypes['assessment_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['assessment_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['assessment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_aggregate_fields'] = ResolversParentTypes['assessment_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['assessment_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Assessment_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['assessment_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['assessment_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['assessment_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['assessment_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['assessment_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['assessment_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['assessment_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['assessment_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['assessment_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_audit'] = ResolversParentTypes['assessment_audit']> = {
  ActualCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CompletedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Assessment_AuditCustomAttributeDataArgs>>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NextTestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OriginatingItemId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Outcome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Summary?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  TargetCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Audit_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_audit_aggregate'] = ResolversParentTypes['assessment_audit_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['assessment_audit_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['assessment_audit']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Audit_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_audit_aggregate_fields'] = ResolversParentTypes['assessment_audit_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['assessment_audit_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Assessment_Audit_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['assessment_audit_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['assessment_audit_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['assessment_audit_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['assessment_audit_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['assessment_audit_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['assessment_audit_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['assessment_audit_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['assessment_audit_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['assessment_audit_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Audit_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_audit_avg_fields'] = ResolversParentTypes['assessment_audit_avg_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Audit_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_audit_max_fields'] = ResolversParentTypes['assessment_audit_max_fields']> = {
  ActualCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CompletedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NextTestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OriginatingItemId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Outcome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Audit_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_audit_min_fields'] = ResolversParentTypes['assessment_audit_min_fields']> = {
  ActualCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CompletedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NextTestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OriginatingItemId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Outcome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Audit_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_audit_stddev_fields'] = ResolversParentTypes['assessment_audit_stddev_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Audit_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_audit_stddev_pop_fields'] = ResolversParentTypes['assessment_audit_stddev_pop_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Audit_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_audit_stddev_samp_fields'] = ResolversParentTypes['assessment_audit_stddev_samp_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Audit_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_audit_sum_fields'] = ResolversParentTypes['assessment_audit_sum_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Audit_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_audit_var_pop_fields'] = ResolversParentTypes['assessment_audit_var_pop_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Audit_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_audit_var_samp_fields'] = ResolversParentTypes['assessment_audit_var_samp_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Audit_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_audit_variance_fields'] = ResolversParentTypes['assessment_audit_variance_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_avg_fields'] = ResolversParentTypes['assessment_avg_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_max_fields'] = ResolversParentTypes['assessment_max_fields']> = {
  ActualCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CompletedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NextTestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OriginatingItemId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Outcome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_min_fields'] = ResolversParentTypes['assessment_min_fields']> = {
  ActualCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CompletedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NextTestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OriginatingItemId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Outcome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_mutation_response'] = ResolversParentTypes['assessment_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['assessment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Result_ParentResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_result_parent'] = ResolversParentTypes['assessment_result_parent']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ParentType?: Resolver<ResolversTypes['parent_type_enum'], ParentType, ContextType>;
  ResultType?: Resolver<ResolversTypes['parent_type_enum'], ParentType, ContextType>;
  assessment?: Resolver<Maybe<ResolversTypes['assessment']>, ParentType, ContextType>;
  assessment_result?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType>;
  complianceMonitoringAssessment?: Resolver<Maybe<ResolversTypes['compliance_monitoring_assessment']>, ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  document?: Resolver<Maybe<ResolversTypes['document']>, ParentType, ContextType>;
  documentAssessmentResult?: Resolver<Maybe<ResolversTypes['document_assessment_result']>, ParentType, ContextType>;
  impactRating?: Resolver<Maybe<ResolversTypes['impact_rating']>, ParentType, ContextType>;
  internalAuditReport?: Resolver<Maybe<ResolversTypes['internal_audit_report']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  node?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType>;
  obligation?: Resolver<Maybe<ResolversTypes['obligation']>, ParentType, ContextType>;
  obligationAssessmentResult?: Resolver<Maybe<ResolversTypes['obligation_assessment_result']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType>;
  risk?: Resolver<Maybe<ResolversTypes['risk']>, ParentType, ContextType>;
  riskAssessmentResult?: Resolver<Maybe<ResolversTypes['risk_assessment_result']>, ParentType, ContextType>;
  testResult?: Resolver<Maybe<ResolversTypes['test_result']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Result_Parent_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_result_parent_aggregate'] = ResolversParentTypes['assessment_result_parent_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['assessment_result_parent_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['assessment_result_parent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Result_Parent_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_result_parent_aggregate_fields'] = ResolversParentTypes['assessment_result_parent_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Assessment_Result_Parent_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['assessment_result_parent_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['assessment_result_parent_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Result_Parent_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_result_parent_audit'] = ResolversParentTypes['assessment_result_parent_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ParentType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ResultType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Result_Parent_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_result_parent_max_fields'] = ResolversParentTypes['assessment_result_parent_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Result_Parent_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_result_parent_min_fields'] = ResolversParentTypes['assessment_result_parent_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Result_Parent_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_result_parent_mutation_response'] = ResolversParentTypes['assessment_result_parent_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['assessment_result_parent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_stddev_fields'] = ResolversParentTypes['assessment_stddev_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_stddev_pop_fields'] = ResolversParentTypes['assessment_stddev_pop_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_stddev_samp_fields'] = ResolversParentTypes['assessment_stddev_samp_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_sum_fields'] = ResolversParentTypes['assessment_sum_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_var_pop_fields'] = ResolversParentTypes['assessment_var_pop_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_var_samp_fields'] = ResolversParentTypes['assessment_var_samp_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Assessment_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['assessment_variance_fields'] = ResolversParentTypes['assessment_variance_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Attestation_ConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['attestation_config'] = ResolversParentTypes['attestation_config']> = {
  AttestationTimeLimit?: Resolver<Maybe<ResolversTypes['interval']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  PromptText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RequireGlobalAttestation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  groups?: Resolver<Array<ResolversTypes['attestation_group']>, ParentType, ContextType, Partial<Attestation_ConfigGroupsArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parent?: Resolver<ResolversTypes['node'], ParentType, ContextType>;
  records?: Resolver<Array<ResolversTypes['attestation_record']>, ParentType, ContextType, Partial<Attestation_ConfigRecordsArgs>>;
  records_aggregate?: Resolver<ResolversTypes['attestation_record_aggregate'], ParentType, ContextType, Partial<Attestation_ConfigRecords_AggregateArgs>>;
  timeLimitMs?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Attestation_Config_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['attestation_config_mutation_response'] = ResolversParentTypes['attestation_config_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['attestation_config']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Attestation_GroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['attestation_group'] = ResolversParentTypes['attestation_group']> = {
  ConfigId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  GroupId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  config?: Resolver<ResolversTypes['attestation_config'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  group?: Resolver<ResolversTypes['user_group'], ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Attestation_Group_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['attestation_group_mutation_response'] = ResolversParentTypes['attestation_group_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['attestation_group']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Attestation_RecordResolvers<ContextType = any, ParentType extends ResolversParentTypes['attestation_record'] = ResolversParentTypes['attestation_record']> = {
  Active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  AttestationStatus?: Resolver<ResolversTypes['attestation_record_status_enum'], ParentType, ContextType>;
  AttestedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ConfigId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ExpiresAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  NodeId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  OrgKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  UserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  config?: Resolver<Maybe<ResolversTypes['attestation_config']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  node?: Resolver<ResolversTypes['node'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['auth_user'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Attestation_Record_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['attestation_record_aggregate'] = ResolversParentTypes['attestation_record_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['attestation_record_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['attestation_record']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Attestation_Record_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['attestation_record_aggregate_fields'] = ResolversParentTypes['attestation_record_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Attestation_Record_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['attestation_record_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['attestation_record_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Attestation_Record_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['attestation_record_max_fields'] = ResolversParentTypes['attestation_record_max_fields']> = {
  AttestedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ConfigId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExpiresAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NodeId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  OrgKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Attestation_Record_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['attestation_record_min_fields'] = ResolversParentTypes['attestation_record_min_fields']> = {
  AttestedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ConfigId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ExpiresAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NodeId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  OrgKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audit_Log_ViewResolvers<ContextType = any, ParentType extends ResolversParentTypes['audit_log_view'] = ResolversParentTypes['audit_log_view']> = {
  Action?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Item?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrgKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PerformedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audit_Log_View_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['audit_log_view_aggregate'] = ResolversParentTypes['audit_log_view_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['audit_log_view_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['audit_log_view']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audit_Log_View_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audit_log_view_aggregate_fields'] = ResolversParentTypes['audit_log_view_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Audit_Log_View_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['audit_log_view_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['audit_log_view_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audit_Log_View_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audit_log_view_max_fields'] = ResolversParentTypes['audit_log_view_max_fields']> = {
  Action?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Item?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrgKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Audit_Log_View_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['audit_log_view_min_fields'] = ResolversParentTypes['audit_log_view_min_fields']> = {
  Action?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Item?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ObjectType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrgKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Auth_OrganisationResolvers<ContextType = any, ParentType extends ResolversParentTypes['auth_organisation'] = ResolversParentTypes['auth_organisation']> = {
  Meta?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType, Partial<Auth_OrganisationMetaArgs>>;
  Name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  OrgKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organisationusers?: Resolver<Array<ResolversTypes['auth_organisationuser']>, ParentType, ContextType, Partial<Auth_OrganisationOrganisationusersArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Auth_OrganisationuserResolvers<ContextType = any, ParentType extends ResolversParentTypes['auth_organisationuser'] = ResolversParentTypes['auth_organisationuser']> = {
  AuthConnection?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  External_Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastSeen?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OrgKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  RoleKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  User_Id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['auth_user'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Auth_Organisationuser_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['auth_organisationuser_mutation_response'] = ResolversParentTypes['auth_organisationuser_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['auth_organisationuser']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Auth_UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['auth_user'] = ResolversParentTypes['auth_user']> = {
  AuthConnection?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AuthUser_Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BusinessUnit_Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedOn?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Department?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DisplayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FriendlyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  IsCustomerSupport?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  JobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastSeen?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OfficeLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RoleKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<ResolversTypes['user_status_enum'], ParentType, ContextType>;
  UserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organisationusers?: Resolver<Array<ResolversTypes['auth_organisationuser']>, ParentType, ContextType, Partial<Auth_UserOrganisationusersArgs>>;
  userGroupUsers?: Resolver<Array<ResolversTypes['user_group_user']>, ParentType, ContextType, Partial<Auth_UserUserGroupUsersArgs>>;
  userGroupUsers_aggregate?: Resolver<ResolversTypes['user_group_user_aggregate'], ParentType, ContextType, Partial<Auth_UserUserGroupUsers_AggregateArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Auth_User_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['auth_user_aggregate'] = ResolversParentTypes['auth_user_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['auth_user_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['auth_user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Auth_User_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['auth_user_aggregate_fields'] = ResolversParentTypes['auth_user_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Auth_User_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['auth_user_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['auth_user_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Auth_User_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['auth_user_max_fields'] = ResolversParentTypes['auth_user_max_fields']> = {
  AuthConnection?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AuthUser_Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BusinessUnit_Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedOn?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Department?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DisplayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FriendlyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastSeen?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OfficeLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RoleKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Auth_User_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['auth_user_min_fields'] = ResolversParentTypes['auth_user_min_fields']> = {
  AuthConnection?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AuthUser_Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BusinessUnit_Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedOn?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Department?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DisplayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FriendlyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastSeen?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OfficeLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RoleKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Auth_User_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['auth_user_mutation_response'] = ResolversParentTypes['auth_user_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['auth_user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface BigintScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['bigint'], any> {
  name: 'bigint';
}

export type Business_AreaResolvers<ContextType = any, ParentType extends ResolversParentTypes['business_area'] = ResolversParentTypes['business_area']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  SequentialId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  internalAuditEntities?: Resolver<Array<ResolversTypes['internal_audit_entity']>, ParentType, ContextType, Partial<Business_AreaInternalAuditEntitiesArgs>>;
  internalAuditEntities_aggregate?: Resolver<ResolversTypes['internal_audit_entity_aggregate'], ParentType, ContextType, Partial<Business_AreaInternalAuditEntities_AggregateArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Business_Area_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['business_area_aggregate'] = ResolversParentTypes['business_area_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['business_area_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['business_area']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Business_Area_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['business_area_aggregate_fields'] = ResolversParentTypes['business_area_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['business_area_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Business_Area_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['business_area_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['business_area_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['business_area_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['business_area_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['business_area_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['business_area_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['business_area_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['business_area_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['business_area_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Business_Area_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['business_area_avg_fields'] = ResolversParentTypes['business_area_avg_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Business_Area_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['business_area_max_fields'] = ResolversParentTypes['business_area_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Business_Area_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['business_area_min_fields'] = ResolversParentTypes['business_area_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Business_Area_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['business_area_mutation_response'] = ResolversParentTypes['business_area_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['business_area']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Business_Area_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['business_area_stddev_fields'] = ResolversParentTypes['business_area_stddev_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Business_Area_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['business_area_stddev_pop_fields'] = ResolversParentTypes['business_area_stddev_pop_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Business_Area_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['business_area_stddev_samp_fields'] = ResolversParentTypes['business_area_stddev_samp_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Business_Area_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['business_area_sum_fields'] = ResolversParentTypes['business_area_sum_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Business_Area_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['business_area_var_pop_fields'] = ResolversParentTypes['business_area_var_pop_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Business_Area_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['business_area_var_samp_fields'] = ResolversParentTypes['business_area_var_samp_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Business_Area_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['business_area_variance_fields'] = ResolversParentTypes['business_area_variance_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CauseResolvers<ContextType = any, ParentType extends ResolversParentTypes['cause'] = ResolversParentTypes['cause']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<CauseCustomAttributeDataArgs>>;
  Description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentIssueId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Significance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  issue?: Resolver<Maybe<ResolversTypes['issue']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Cause_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['cause_aggregate'] = ResolversParentTypes['cause_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['cause_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['cause']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Cause_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['cause_aggregate_fields'] = ResolversParentTypes['cause_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['cause_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Cause_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['cause_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['cause_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['cause_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['cause_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['cause_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['cause_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['cause_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['cause_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['cause_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Cause_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['cause_audit'] = ResolversParentTypes['cause_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Cause_AuditCustomAttributeDataArgs>>;
  Description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentIssueId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Significance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Cause_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['cause_avg_fields'] = ResolversParentTypes['cause_avg_fields']> = {
  Significance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Cause_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['cause_max_fields'] = ResolversParentTypes['cause_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentIssueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Significance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Cause_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['cause_min_fields'] = ResolversParentTypes['cause_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentIssueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Significance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Cause_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['cause_mutation_response'] = ResolversParentTypes['cause_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['cause']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Cause_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['cause_stddev_fields'] = ResolversParentTypes['cause_stddev_fields']> = {
  Significance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Cause_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['cause_stddev_pop_fields'] = ResolversParentTypes['cause_stddev_pop_fields']> = {
  Significance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Cause_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['cause_stddev_samp_fields'] = ResolversParentTypes['cause_stddev_samp_fields']> = {
  Significance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Cause_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['cause_sum_fields'] = ResolversParentTypes['cause_sum_fields']> = {
  Significance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Cause_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['cause_var_pop_fields'] = ResolversParentTypes['cause_var_pop_fields']> = {
  Significance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Cause_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['cause_var_samp_fields'] = ResolversParentTypes['cause_var_samp_fields']> = {
  Significance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Cause_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['cause_variance_fields'] = ResolversParentTypes['cause_variance_fields']> = {
  Significance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Change_RequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['change_request'] = ResolversParentTypes['change_request']> = {
  ActionUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ChangeRequestStatus?: Resolver<ResolversTypes['approval_status_enum'], ParentType, ContextType>;
  Comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  OrgKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  OverriddenAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OverriddenByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  RequestedChanges?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Change_RequestRequestedChangesArgs>>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  contributors?: Resolver<Array<ResolversTypes['change_request_contributor']>, ParentType, ContextType, Partial<Change_RequestContributorsArgs>>;
  createdBy?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  overriddenBy?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType>;
  parentOwnerAndContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<Change_RequestParentOwnerAndContributorsArgs>>;
  responses?: Resolver<Array<ResolversTypes['approver_response']>, ParentType, ContextType, Partial<Change_RequestResponsesArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Change_Request_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['change_request_aggregate'] = ResolversParentTypes['change_request_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['change_request_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['change_request']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Change_Request_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['change_request_aggregate_fields'] = ResolversParentTypes['change_request_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['change_request_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Change_Request_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['change_request_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['change_request_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['change_request_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['change_request_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['change_request_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['change_request_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['change_request_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['change_request_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['change_request_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Change_Request_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['change_request_audit'] = ResolversParentTypes['change_request_audit']> = {
  ChangeRequestStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  OverriddenAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OverriddenByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  RequestedChanges?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Change_Request_AuditRequestedChangesArgs>>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Change_Request_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['change_request_avg_fields'] = ResolversParentTypes['change_request_avg_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Change_Request_ContributorResolvers<ContextType = any, ParentType extends ResolversParentTypes['change_request_contributor'] = ResolversParentTypes['change_request_contributor']> = {
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  UserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  changeRequest?: Resolver<ResolversTypes['change_request'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Change_Request_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['change_request_max_fields'] = ResolversParentTypes['change_request_max_fields']> = {
  ActionUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrgKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OverriddenAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OverriddenByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Change_Request_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['change_request_min_fields'] = ResolversParentTypes['change_request_min_fields']> = {
  ActionUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrgKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OverriddenAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OverriddenByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Change_Request_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['change_request_stddev_fields'] = ResolversParentTypes['change_request_stddev_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Change_Request_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['change_request_stddev_pop_fields'] = ResolversParentTypes['change_request_stddev_pop_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Change_Request_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['change_request_stddev_samp_fields'] = ResolversParentTypes['change_request_stddev_samp_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Change_Request_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['change_request_sum_fields'] = ResolversParentTypes['change_request_sum_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Change_Request_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['change_request_var_pop_fields'] = ResolversParentTypes['change_request_var_pop_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Change_Request_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['change_request_var_samp_fields'] = ResolversParentTypes['change_request_var_samp_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Change_Request_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['change_request_variance_fields'] = ResolversParentTypes['change_request_variance_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommentResolvers<ContextType = any, ParentType extends ResolversParentTypes['comment'] = ResolversParentTypes['comment']> = {
  Content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ConversationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  conversation?: Resolver<Maybe<ResolversTypes['conversation']>, ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Comment_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['comment_audit'] = ResolversParentTypes['comment_audit']> = {
  Content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ConversationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Comment_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['comment_mutation_response'] = ResolversParentTypes['comment_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['comment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Compliance_Monitoring_AssessmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['compliance_monitoring_assessment'] = ResolversParentTypes['compliance_monitoring_assessment']> = {
  ActualCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CompletedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Compliance_Monitoring_AssessmentCustomAttributeDataArgs>>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  NextTestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OriginatingItemId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Outcome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Status?: Resolver<ResolversTypes['assessment_status_enum'], ParentType, ContextType>;
  Summary?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  TargetCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<Compliance_Monitoring_AssessmentAncestorContributorsArgs>>;
  assessmentActions?: Resolver<Array<ResolversTypes['action_parent']>, ParentType, ContextType, Partial<Compliance_Monitoring_AssessmentAssessmentActionsArgs>>;
  assessmentActions_aggregate?: Resolver<ResolversTypes['action_parent_aggregate'], ParentType, ContextType, Partial<Compliance_Monitoring_AssessmentAssessmentActions_AggregateArgs>>;
  assessmentActivities?: Resolver<Array<ResolversTypes['assessment_activity']>, ParentType, ContextType, Partial<Compliance_Monitoring_AssessmentAssessmentActivitiesArgs>>;
  assessmentActivities_aggregate?: Resolver<ResolversTypes['assessment_activity_aggregate'], ParentType, ContextType, Partial<Compliance_Monitoring_AssessmentAssessmentActivities_AggregateArgs>>;
  assessmentIssues?: Resolver<Array<ResolversTypes['issue_parent']>, ParentType, ContextType, Partial<Compliance_Monitoring_AssessmentAssessmentIssuesArgs>>;
  assessmentIssues_aggregate?: Resolver<ResolversTypes['issue_parent_aggregate'], ParentType, ContextType, Partial<Compliance_Monitoring_AssessmentAssessmentIssues_AggregateArgs>>;
  assessmentResults?: Resolver<Array<ResolversTypes['assessment_result_parent']>, ParentType, ContextType, Partial<Compliance_Monitoring_AssessmentAssessmentResultsArgs>>;
  assessmentResults_aggregate?: Resolver<ResolversTypes['assessment_result_parent_aggregate'], ParentType, ContextType, Partial<Compliance_Monitoring_AssessmentAssessmentResults_AggregateArgs>>;
  completedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  contributorGroups?: Resolver<Array<ResolversTypes['contributor_group']>, ParentType, ContextType, Partial<Compliance_Monitoring_AssessmentContributorGroupsArgs>>;
  contributorGroups_aggregate?: Resolver<ResolversTypes['contributor_group_aggregate'], ParentType, ContextType, Partial<Compliance_Monitoring_AssessmentContributorGroups_AggregateArgs>>;
  contributors?: Resolver<Array<ResolversTypes['contributor']>, ParentType, ContextType, Partial<Compliance_Monitoring_AssessmentContributorsArgs>>;
  contributors_aggregate?: Resolver<ResolversTypes['contributor_aggregate'], ParentType, ContextType, Partial<Compliance_Monitoring_AssessmentContributors_AggregateArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  departments?: Resolver<Array<ResolversTypes['department']>, ParentType, ContextType, Partial<Compliance_Monitoring_AssessmentDepartmentsArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  originatingItem?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType>;
  ownerGroups?: Resolver<Array<ResolversTypes['owner_group']>, ParentType, ContextType, Partial<Compliance_Monitoring_AssessmentOwnerGroupsArgs>>;
  ownerGroups_aggregate?: Resolver<ResolversTypes['owner_group_aggregate'], ParentType, ContextType, Partial<Compliance_Monitoring_AssessmentOwnerGroups_AggregateArgs>>;
  owners?: Resolver<Array<ResolversTypes['owner']>, ParentType, ContextType, Partial<Compliance_Monitoring_AssessmentOwnersArgs>>;
  owners_aggregate?: Resolver<ResolversTypes['owner_aggregate'], ParentType, ContextType, Partial<Compliance_Monitoring_AssessmentOwners_AggregateArgs>>;
  tags?: Resolver<Array<ResolversTypes['tag']>, ParentType, ContextType, Partial<Compliance_Monitoring_AssessmentTagsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Compliance_Monitoring_Assessment_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['compliance_monitoring_assessment_aggregate'] = ResolversParentTypes['compliance_monitoring_assessment_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['compliance_monitoring_assessment_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['compliance_monitoring_assessment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Compliance_Monitoring_Assessment_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['compliance_monitoring_assessment_aggregate_fields'] = ResolversParentTypes['compliance_monitoring_assessment_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['compliance_monitoring_assessment_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Compliance_Monitoring_Assessment_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['compliance_monitoring_assessment_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['compliance_monitoring_assessment_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['compliance_monitoring_assessment_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['compliance_monitoring_assessment_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['compliance_monitoring_assessment_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['compliance_monitoring_assessment_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['compliance_monitoring_assessment_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['compliance_monitoring_assessment_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['compliance_monitoring_assessment_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Compliance_Monitoring_Assessment_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['compliance_monitoring_assessment_avg_fields'] = ResolversParentTypes['compliance_monitoring_assessment_avg_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Compliance_Monitoring_Assessment_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['compliance_monitoring_assessment_max_fields'] = ResolversParentTypes['compliance_monitoring_assessment_max_fields']> = {
  ActualCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CompletedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NextTestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OriginatingItemId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Outcome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Compliance_Monitoring_Assessment_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['compliance_monitoring_assessment_min_fields'] = ResolversParentTypes['compliance_monitoring_assessment_min_fields']> = {
  ActualCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CompletedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NextTestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OriginatingItemId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Outcome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Compliance_Monitoring_Assessment_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['compliance_monitoring_assessment_mutation_response'] = ResolversParentTypes['compliance_monitoring_assessment_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['compliance_monitoring_assessment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Compliance_Monitoring_Assessment_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['compliance_monitoring_assessment_stddev_fields'] = ResolversParentTypes['compliance_monitoring_assessment_stddev_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Compliance_Monitoring_Assessment_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['compliance_monitoring_assessment_stddev_pop_fields'] = ResolversParentTypes['compliance_monitoring_assessment_stddev_pop_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Compliance_Monitoring_Assessment_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['compliance_monitoring_assessment_stddev_samp_fields'] = ResolversParentTypes['compliance_monitoring_assessment_stddev_samp_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Compliance_Monitoring_Assessment_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['compliance_monitoring_assessment_sum_fields'] = ResolversParentTypes['compliance_monitoring_assessment_sum_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Compliance_Monitoring_Assessment_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['compliance_monitoring_assessment_var_pop_fields'] = ResolversParentTypes['compliance_monitoring_assessment_var_pop_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Compliance_Monitoring_Assessment_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['compliance_monitoring_assessment_var_samp_fields'] = ResolversParentTypes['compliance_monitoring_assessment_var_samp_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Compliance_Monitoring_Assessment_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['compliance_monitoring_assessment_variance_fields'] = ResolversParentTypes['compliance_monitoring_assessment_variance_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConsequenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence'] = ResolversParentTypes['consequence']> = {
  CostType?: Resolver<ResolversTypes['cost_type_enum'], ParentType, ContextType>;
  CostValue?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Criticality?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<ConsequenceCustomAttributeDataArgs>>;
  Description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentIssueId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['consequence_type_enum']>, ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  issue?: Resolver<Maybe<ResolversTypes['issue']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_aggregate'] = ResolversParentTypes['consequence_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['consequence_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['consequence']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_aggregate_fields'] = ResolversParentTypes['consequence_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['consequence_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Consequence_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['consequence_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['consequence_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['consequence_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['consequence_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['consequence_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['consequence_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['consequence_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['consequence_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['consequence_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_audit'] = ResolversParentTypes['consequence_audit']> = {
  CostType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CostValue?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Criticality?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Consequence_AuditCustomAttributeDataArgs>>;
  Description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentIssueId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Audit_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_audit_aggregate'] = ResolversParentTypes['consequence_audit_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['consequence_audit_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['consequence_audit']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Audit_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_audit_aggregate_fields'] = ResolversParentTypes['consequence_audit_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['consequence_audit_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Consequence_Audit_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['consequence_audit_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['consequence_audit_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['consequence_audit_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['consequence_audit_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['consequence_audit_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['consequence_audit_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['consequence_audit_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['consequence_audit_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['consequence_audit_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Audit_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_audit_avg_fields'] = ResolversParentTypes['consequence_audit_avg_fields']> = {
  CostValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Criticality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Audit_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_audit_max_fields'] = ResolversParentTypes['consequence_audit_max_fields']> = {
  CostType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CostValue?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Criticality?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentIssueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Audit_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_audit_min_fields'] = ResolversParentTypes['consequence_audit_min_fields']> = {
  CostType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CostValue?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Criticality?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentIssueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Audit_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_audit_stddev_fields'] = ResolversParentTypes['consequence_audit_stddev_fields']> = {
  CostValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Criticality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Audit_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_audit_stddev_pop_fields'] = ResolversParentTypes['consequence_audit_stddev_pop_fields']> = {
  CostValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Criticality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Audit_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_audit_stddev_samp_fields'] = ResolversParentTypes['consequence_audit_stddev_samp_fields']> = {
  CostValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Criticality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Audit_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_audit_sum_fields'] = ResolversParentTypes['consequence_audit_sum_fields']> = {
  CostValue?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  Criticality?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Audit_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_audit_var_pop_fields'] = ResolversParentTypes['consequence_audit_var_pop_fields']> = {
  CostValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Criticality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Audit_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_audit_var_samp_fields'] = ResolversParentTypes['consequence_audit_var_samp_fields']> = {
  CostValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Criticality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Audit_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_audit_variance_fields'] = ResolversParentTypes['consequence_audit_variance_fields']> = {
  CostValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Criticality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_avg_fields'] = ResolversParentTypes['consequence_avg_fields']> = {
  CostValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Criticality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_max_fields'] = ResolversParentTypes['consequence_max_fields']> = {
  CostValue?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Criticality?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentIssueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_min_fields'] = ResolversParentTypes['consequence_min_fields']> = {
  CostValue?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Criticality?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentIssueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_mutation_response'] = ResolversParentTypes['consequence_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['consequence']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_stddev_fields'] = ResolversParentTypes['consequence_stddev_fields']> = {
  CostValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Criticality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_stddev_pop_fields'] = ResolversParentTypes['consequence_stddev_pop_fields']> = {
  CostValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Criticality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_stddev_samp_fields'] = ResolversParentTypes['consequence_stddev_samp_fields']> = {
  CostValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Criticality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_sum_fields'] = ResolversParentTypes['consequence_sum_fields']> = {
  CostValue?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  Criticality?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_var_pop_fields'] = ResolversParentTypes['consequence_var_pop_fields']> = {
  CostValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Criticality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_var_samp_fields'] = ResolversParentTypes['consequence_var_samp_fields']> = {
  CostValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Criticality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Consequence_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['consequence_variance_fields'] = ResolversParentTypes['consequence_variance_fields']> = {
  CostValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Criticality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContributorResolvers<ContextType = any, ParentType extends ResolversParentTypes['contributor'] = ResolversParentTypes['contributor']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  UserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parentAction?: Resolver<Maybe<ResolversTypes['action']>, ParentType, ContextType>;
  parentAssessment?: Resolver<Maybe<ResolversTypes['assessment']>, ParentType, ContextType>;
  parentInternalAuditEntity?: Resolver<Maybe<ResolversTypes['internal_audit_entity']>, ParentType, ContextType>;
  parentInternalAuditReport?: Resolver<Maybe<ResolversTypes['internal_audit_report']>, ParentType, ContextType>;
  parentIssue?: Resolver<Maybe<ResolversTypes['issue']>, ParentType, ContextType>;
  parentRisk?: Resolver<Maybe<ResolversTypes['risk']>, ParentType, ContextType>;
  parentThirdParty?: Resolver<Maybe<ResolversTypes['third_party']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contributor_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['contributor_aggregate'] = ResolversParentTypes['contributor_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['contributor_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['contributor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contributor_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['contributor_aggregate_fields'] = ResolversParentTypes['contributor_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Contributor_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['contributor_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['contributor_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contributor_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['contributor_audit'] = ResolversParentTypes['contributor_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  UserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contributor_Audit_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['contributor_audit_aggregate'] = ResolversParentTypes['contributor_audit_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['contributor_audit_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['contributor_audit']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contributor_Audit_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['contributor_audit_aggregate_fields'] = ResolversParentTypes['contributor_audit_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Contributor_Audit_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['contributor_audit_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['contributor_audit_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contributor_Audit_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['contributor_audit_max_fields'] = ResolversParentTypes['contributor_audit_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  UserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contributor_Audit_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['contributor_audit_min_fields'] = ResolversParentTypes['contributor_audit_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  UserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contributor_GroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['contributor_group'] = ResolversParentTypes['contributor_group']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  UserGroupId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  group?: Resolver<Maybe<ResolversTypes['user_group']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parentAction?: Resolver<Maybe<ResolversTypes['action']>, ParentType, ContextType>;
  parentAssessment?: Resolver<Maybe<ResolversTypes['assessment']>, ParentType, ContextType>;
  parentInternalAuditEntity?: Resolver<Maybe<ResolversTypes['internal_audit_entity']>, ParentType, ContextType>;
  parentInternalAuditReport?: Resolver<Maybe<ResolversTypes['internal_audit_report']>, ParentType, ContextType>;
  parentIssue?: Resolver<Maybe<ResolversTypes['issue']>, ParentType, ContextType>;
  parentRisk?: Resolver<Maybe<ResolversTypes['risk']>, ParentType, ContextType>;
  parentThirdParty?: Resolver<Maybe<ResolversTypes['third_party']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contributor_Group_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['contributor_group_aggregate'] = ResolversParentTypes['contributor_group_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['contributor_group_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['contributor_group']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contributor_Group_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['contributor_group_aggregate_fields'] = ResolversParentTypes['contributor_group_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Contributor_Group_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['contributor_group_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['contributor_group_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contributor_Group_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['contributor_group_audit'] = ResolversParentTypes['contributor_group_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  UserGroupId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contributor_Group_Audit_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['contributor_group_audit_aggregate'] = ResolversParentTypes['contributor_group_audit_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['contributor_group_audit_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['contributor_group_audit']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contributor_Group_Audit_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['contributor_group_audit_aggregate_fields'] = ResolversParentTypes['contributor_group_audit_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Contributor_Group_Audit_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['contributor_group_audit_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['contributor_group_audit_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contributor_Group_Audit_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['contributor_group_audit_max_fields'] = ResolversParentTypes['contributor_group_audit_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  UserGroupId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contributor_Group_Audit_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['contributor_group_audit_min_fields'] = ResolversParentTypes['contributor_group_audit_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  UserGroupId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contributor_Group_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['contributor_group_max_fields'] = ResolversParentTypes['contributor_group_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  UserGroupId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contributor_Group_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['contributor_group_min_fields'] = ResolversParentTypes['contributor_group_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  UserGroupId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contributor_Group_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['contributor_group_mutation_response'] = ResolversParentTypes['contributor_group_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['contributor_group']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contributor_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['contributor_max_fields'] = ResolversParentTypes['contributor_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  UserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contributor_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['contributor_min_fields'] = ResolversParentTypes['contributor_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  UserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Contributor_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['contributor_mutation_response'] = ResolversParentTypes['contributor_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['contributor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ControlResolvers<ContextType = any, ParentType extends ResolversParentTypes['control'] = ResolversParentTypes['control']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<ControlCustomAttributeDataArgs>>;
  Description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['control_type_enum']>, ParentType, ContextType>;
  actions?: Resolver<Array<ResolversTypes['action_parent']>, ParentType, ContextType, Partial<ControlActionsArgs>>;
  actions_aggregate?: Resolver<ResolversTypes['action_parent_aggregate'], ParentType, ContextType, Partial<ControlActions_AggregateArgs>>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<ControlAncestorContributorsArgs>>;
  contributorGroups?: Resolver<Array<ResolversTypes['contributor_group']>, ParentType, ContextType, Partial<ControlContributorGroupsArgs>>;
  contributorGroups_aggregate?: Resolver<ResolversTypes['contributor_group_aggregate'], ParentType, ContextType, Partial<ControlContributorGroups_AggregateArgs>>;
  contributors?: Resolver<Array<ResolversTypes['contributor']>, ParentType, ContextType, Partial<ControlContributorsArgs>>;
  contributors_aggregate?: Resolver<ResolversTypes['contributor_aggregate'], ParentType, ContextType, Partial<ControlContributors_AggregateArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  departments?: Resolver<Array<ResolversTypes['department']>, ParentType, ContextType, Partial<ControlDepartmentsArgs>>;
  indicators?: Resolver<Array<ResolversTypes['indicator_parent']>, ParentType, ContextType, Partial<ControlIndicatorsArgs>>;
  indicators_aggregate?: Resolver<ResolversTypes['indicator_parent_aggregate'], ParentType, ContextType, Partial<ControlIndicators_AggregateArgs>>;
  issues?: Resolver<Array<ResolversTypes['issue_parent']>, ParentType, ContextType, Partial<ControlIssuesArgs>>;
  issues_aggregate?: Resolver<ResolversTypes['issue_parent_aggregate'], ParentType, ContextType, Partial<ControlIssues_AggregateArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  ownerGroups?: Resolver<Array<ResolversTypes['owner_group']>, ParentType, ContextType, Partial<ControlOwnerGroupsArgs>>;
  ownerGroups_aggregate?: Resolver<ResolversTypes['owner_group_aggregate'], ParentType, ContextType, Partial<ControlOwnerGroups_AggregateArgs>>;
  owners?: Resolver<Array<ResolversTypes['owner']>, ParentType, ContextType, Partial<ControlOwnersArgs>>;
  owners_aggregate?: Resolver<ResolversTypes['owner_aggregate'], ParentType, ContextType, Partial<ControlOwners_AggregateArgs>>;
  parents?: Resolver<Array<ResolversTypes['control_parent']>, ParentType, ContextType, Partial<ControlParentsArgs>>;
  parents_aggregate?: Resolver<ResolversTypes['control_parent_aggregate'], ParentType, ContextType, Partial<ControlParents_AggregateArgs>>;
  schedule?: Resolver<Maybe<ResolversTypes['schedule']>, ParentType, ContextType>;
  scheduleState?: Resolver<Maybe<ResolversTypes['schedule_state']>, ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['tag']>, ParentType, ContextType, Partial<ControlTagsArgs>>;
  testResults?: Resolver<Array<ResolversTypes['test_result']>, ParentType, ContextType, Partial<ControlTestResultsArgs>>;
  testResults_aggregate?: Resolver<ResolversTypes['test_result_aggregate'], ParentType, ContextType, Partial<ControlTestResults_AggregateArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_aggregate'] = ResolversParentTypes['control_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['control_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['control']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_aggregate_fields'] = ResolversParentTypes['control_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['control_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Control_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['control_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['control_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['control_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['control_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['control_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['control_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['control_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['control_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['control_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_audit'] = ResolversParentTypes['control_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Control_AuditCustomAttributeDataArgs>>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  actionAudits?: Resolver<Array<ResolversTypes['action_parent_audit']>, ParentType, ContextType, Partial<Control_AuditActionAuditsArgs>>;
  contributorAudits?: Resolver<Array<ResolversTypes['contributor_audit']>, ParentType, ContextType, Partial<Control_AuditContributorAuditsArgs>>;
  contributorAudits_aggregate?: Resolver<ResolversTypes['contributor_audit_aggregate'], ParentType, ContextType, Partial<Control_AuditContributorAudits_AggregateArgs>>;
  contributorGroupAudits?: Resolver<Array<ResolversTypes['contributor_group_audit']>, ParentType, ContextType, Partial<Control_AuditContributorGroupAuditsArgs>>;
  contributorGroupAudits_aggregate?: Resolver<ResolversTypes['contributor_group_audit_aggregate'], ParentType, ContextType, Partial<Control_AuditContributorGroupAudits_AggregateArgs>>;
  departmentAudits?: Resolver<Array<ResolversTypes['department_audit']>, ParentType, ContextType, Partial<Control_AuditDepartmentAuditsArgs>>;
  indicatorAudits?: Resolver<Array<ResolversTypes['risksmart_indicator_parent_audit']>, ParentType, ContextType, Partial<Control_AuditIndicatorAuditsArgs>>;
  issueAudits?: Resolver<Array<ResolversTypes['issue_parent_audit']>, ParentType, ContextType, Partial<Control_AuditIssueAuditsArgs>>;
  ownerAudits?: Resolver<Array<ResolversTypes['owner_audit']>, ParentType, ContextType, Partial<Control_AuditOwnerAuditsArgs>>;
  ownerGroupAudits?: Resolver<Array<ResolversTypes['owner_group_audit']>, ParentType, ContextType, Partial<Control_AuditOwnerGroupAuditsArgs>>;
  testResultAudits?: Resolver<Array<ResolversTypes['test_result_audit']>, ParentType, ContextType, Partial<Control_AuditTestResultAuditsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_avg_fields'] = ResolversParentTypes['control_avg_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_GroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_group'] = ResolversParentTypes['control_group']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Control_GroupCustomAttributeDataArgs>>;
  Description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Owner?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<Control_GroupAncestorContributorsArgs>>;
  controls?: Resolver<Array<ResolversTypes['control_parent']>, ParentType, ContextType, Partial<Control_GroupControlsArgs>>;
  controls_aggregate?: Resolver<ResolversTypes['control_parent_aggregate'], ParentType, ContextType, Partial<Control_GroupControls_AggregateArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Group_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_group_aggregate'] = ResolversParentTypes['control_group_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['control_group_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['control_group']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Group_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_group_aggregate_fields'] = ResolversParentTypes['control_group_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Control_Group_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['control_group_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['control_group_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Group_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_group_audit'] = ResolversParentTypes['control_group_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Control_Group_AuditCustomAttributeDataArgs>>;
  Description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Owner?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Group_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_group_max_fields'] = ResolversParentTypes['control_group_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Owner?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Group_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_group_min_fields'] = ResolversParentTypes['control_group_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Owner?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Group_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_group_mutation_response'] = ResolversParentTypes['control_group_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['control_group']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_max_fields'] = ResolversParentTypes['control_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_min_fields'] = ResolversParentTypes['control_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_mutation_response'] = ResolversParentTypes['control_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['control']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_ParentResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_parent'] = ResolversParentTypes['control_parent']> = {
  ControlId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  control?: Resolver<Maybe<ResolversTypes['control']>, ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  group?: Resolver<Maybe<ResolversTypes['control_group']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  obligation?: Resolver<Maybe<ResolversTypes['obligation']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType>;
  risk?: Resolver<Maybe<ResolversTypes['risk']>, ParentType, ContextType>;
  thirdParty?: Resolver<Maybe<ResolversTypes['third_party']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Parent_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_parent_aggregate'] = ResolversParentTypes['control_parent_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['control_parent_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['control_parent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Parent_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_parent_aggregate_fields'] = ResolversParentTypes['control_parent_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Control_Parent_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['control_parent_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['control_parent_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Parent_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_parent_audit'] = ResolversParentTypes['control_parent_audit']> = {
  ControlId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Parent_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_parent_max_fields'] = ResolversParentTypes['control_parent_max_fields']> = {
  ControlId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Parent_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_parent_min_fields'] = ResolversParentTypes['control_parent_min_fields']> = {
  ControlId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Parent_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_parent_mutation_response'] = ResolversParentTypes['control_parent_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['control_parent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_stddev_fields'] = ResolversParentTypes['control_stddev_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_stddev_pop_fields'] = ResolversParentTypes['control_stddev_pop_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_stddev_samp_fields'] = ResolversParentTypes['control_stddev_samp_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_sum_fields'] = ResolversParentTypes['control_sum_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_var_pop_fields'] = ResolversParentTypes['control_var_pop_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_var_samp_fields'] = ResolversParentTypes['control_var_samp_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Control_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['control_variance_fields'] = ResolversParentTypes['control_variance_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConversationResolvers<ContextType = any, ParentType extends ResolversParentTypes['conversation'] = ResolversParentTypes['conversation']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  IsResolved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  comments?: Resolver<Array<ResolversTypes['comment']>, ParentType, ContextType, Partial<ConversationCommentsArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Conversation_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['conversation_audit'] = ResolversParentTypes['conversation_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  IsResolved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Conversation_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['conversation_mutation_response'] = ResolversParentTypes['conversation_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['conversation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Custom_Attribute_SchemaResolvers<ContextType = any, ParentType extends ResolversParentTypes['custom_attribute_schema'] = ResolversParentTypes['custom_attribute_schema']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Schema?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<Custom_Attribute_SchemaSchemaArgs>>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  UiSchema?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<Custom_Attribute_SchemaUiSchemaArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  form?: Resolver<Maybe<ResolversTypes['form_configuration']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Custom_Attribute_Schema_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['custom_attribute_schema_audit'] = ResolversParentTypes['custom_attribute_schema_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Schema?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<Custom_Attribute_Schema_AuditSchemaArgs>>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  UiSchema?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<Custom_Attribute_Schema_AuditUiSchemaArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Custom_Attribute_Schema_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['custom_attribute_schema_mutation_response'] = ResolversParentTypes['custom_attribute_schema_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['custom_attribute_schema']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Custom_RibbonResolvers<ContextType = any, ParentType extends ResolversParentTypes['custom_ribbon'] = ResolversParentTypes['custom_ribbon']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Filters?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<Custom_RibbonFiltersArgs>>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentType?: Resolver<ResolversTypes['parent_type_enum'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Custom_Ribbon_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['custom_ribbon_audit'] = ResolversParentTypes['custom_ribbon_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Filters?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<Custom_Ribbon_AuditFiltersArgs>>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Custom_Ribbon_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['custom_ribbon_mutation_response'] = ResolversParentTypes['custom_ribbon_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['custom_ribbon']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashboardResolvers<ContextType = any, ParentType extends ResolversParentTypes['dashboard'] = ResolversParentTypes['dashboard']> = {
  Content?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<DashboardContentArgs>>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Sharing?: Resolver<ResolversTypes['dashboard_sharing_type_enum'], ParentType, ContextType>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<DashboardAncestorContributorsArgs>>;
  contributorGroups?: Resolver<Array<ResolversTypes['contributor_group']>, ParentType, ContextType, Partial<DashboardContributorGroupsArgs>>;
  contributorGroups_aggregate?: Resolver<ResolversTypes['contributor_group_aggregate'], ParentType, ContextType, Partial<DashboardContributorGroups_AggregateArgs>>;
  contributors?: Resolver<Array<ResolversTypes['contributor']>, ParentType, ContextType, Partial<DashboardContributorsArgs>>;
  contributors_aggregate?: Resolver<ResolversTypes['contributor_aggregate'], ParentType, ContextType, Partial<DashboardContributors_AggregateArgs>>;
  ownerGroups?: Resolver<Array<ResolversTypes['owner_group']>, ParentType, ContextType, Partial<DashboardOwnerGroupsArgs>>;
  ownerGroups_aggregate?: Resolver<ResolversTypes['owner_group_aggregate'], ParentType, ContextType, Partial<DashboardOwnerGroups_AggregateArgs>>;
  owners?: Resolver<Array<ResolversTypes['owner']>, ParentType, ContextType, Partial<DashboardOwnersArgs>>;
  owners_aggregate?: Resolver<ResolversTypes['owner_aggregate'], ParentType, ContextType, Partial<DashboardOwners_AggregateArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Dashboard_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['dashboard_audit'] = ResolversParentTypes['dashboard_audit']> = {
  Content?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Dashboard_AuditContentArgs>>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Sharing?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Dashboard_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['dashboard_mutation_response'] = ResolversParentTypes['dashboard_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['dashboard']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Data_ImportResolvers<ContextType = any, ParentType extends ResolversParentTypes['data_import'] = ResolversParentTypes['data_import']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Status?: Resolver<ResolversTypes['data_import_status_enum'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  files?: Resolver<Array<ResolversTypes['relation_file']>, ParentType, ContextType, Partial<Data_ImportFilesArgs>>;
  files_aggregate?: Resolver<ResolversTypes['relation_file_aggregate'], ParentType, ContextType, Partial<Data_ImportFiles_AggregateArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Data_Import_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['data_import_aggregate'] = ResolversParentTypes['data_import_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['data_import_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['data_import']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Data_Import_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['data_import_aggregate_fields'] = ResolversParentTypes['data_import_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Data_Import_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['data_import_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['data_import_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Data_Import_ErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['data_import_error'] = ResolversParentTypes['data_import_error']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  DataImportId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ImportObject?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  RowNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Data_Import_Error_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['data_import_error_aggregate'] = ResolversParentTypes['data_import_error_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['data_import_error_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['data_import_error']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Data_Import_Error_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['data_import_error_aggregate_fields'] = ResolversParentTypes['data_import_error_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['data_import_error_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Data_Import_Error_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['data_import_error_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['data_import_error_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['data_import_error_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['data_import_error_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['data_import_error_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['data_import_error_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['data_import_error_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['data_import_error_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['data_import_error_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Data_Import_Error_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['data_import_error_avg_fields'] = ResolversParentTypes['data_import_error_avg_fields']> = {
  RowNumber?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Data_Import_Error_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['data_import_error_max_fields'] = ResolversParentTypes['data_import_error_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DataImportId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ImportObject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RowNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Data_Import_Error_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['data_import_error_min_fields'] = ResolversParentTypes['data_import_error_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DataImportId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ImportObject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RowNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Data_Import_Error_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['data_import_error_mutation_response'] = ResolversParentTypes['data_import_error_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['data_import_error']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Data_Import_Error_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['data_import_error_stddev_fields'] = ResolversParentTypes['data_import_error_stddev_fields']> = {
  RowNumber?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Data_Import_Error_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['data_import_error_stddev_pop_fields'] = ResolversParentTypes['data_import_error_stddev_pop_fields']> = {
  RowNumber?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Data_Import_Error_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['data_import_error_stddev_samp_fields'] = ResolversParentTypes['data_import_error_stddev_samp_fields']> = {
  RowNumber?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Data_Import_Error_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['data_import_error_sum_fields'] = ResolversParentTypes['data_import_error_sum_fields']> = {
  RowNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Data_Import_Error_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['data_import_error_var_pop_fields'] = ResolversParentTypes['data_import_error_var_pop_fields']> = {
  RowNumber?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Data_Import_Error_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['data_import_error_var_samp_fields'] = ResolversParentTypes['data_import_error_var_samp_fields']> = {
  RowNumber?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Data_Import_Error_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['data_import_error_variance_fields'] = ResolversParentTypes['data_import_error_variance_fields']> = {
  RowNumber?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Data_Import_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['data_import_max_fields'] = ResolversParentTypes['data_import_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Data_Import_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['data_import_min_fields'] = ResolversParentTypes['data_import_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Data_Import_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['data_import_mutation_response'] = ResolversParentTypes['data_import_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['data_import']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DepartmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['department'] = ResolversParentTypes['department']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepartmentTypeId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType>;
  parentAction?: Resolver<Maybe<ResolversTypes['action']>, ParentType, ContextType>;
  parentAssessment?: Resolver<Maybe<ResolversTypes['assessment']>, ParentType, ContextType>;
  parentIssue?: Resolver<Maybe<ResolversTypes['issue']>, ParentType, ContextType>;
  parentThirdParty?: Resolver<Maybe<ResolversTypes['third_party']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['department_type']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Department_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['department_audit'] = ResolversParentTypes['department_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepartmentTypeId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Department_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['department_mutation_response'] = ResolversParentTypes['department_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['department']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Department_TypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['department_type'] = ResolversParentTypes['department_type']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepartmentTypeGroupId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  DepartmentTypeId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  department_type_group?: Resolver<Maybe<ResolversTypes['department_type_group']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Department_Type_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['department_type_audit'] = ResolversParentTypes['department_type_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DepartmentTypeGroupId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  DepartmentTypeId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Department_Type_GroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['department_type_group'] = ResolversParentTypes['department_type_group']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  department_types?: Resolver<Array<ResolversTypes['department_type']>, ParentType, ContextType, Partial<Department_Type_GroupDepartment_TypesArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Department_Type_Group_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['department_type_group_audit'] = ResolversParentTypes['department_type_group_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Department_Type_Group_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['department_type_group_mutation_response'] = ResolversParentTypes['department_type_group_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['department_type_group']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Department_Type_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['department_type_mutation_response'] = ResolversParentTypes['department_type_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['department_type']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['document'] = ResolversParentTypes['document']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<DocumentCustomAttributeDataArgs>>;
  DocumentType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentDocument?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Purpose?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  actions?: Resolver<Array<ResolversTypes['action_parent']>, ParentType, ContextType, Partial<DocumentActionsArgs>>;
  actions_aggregate?: Resolver<ResolversTypes['action_parent_aggregate'], ParentType, ContextType, Partial<DocumentActions_AggregateArgs>>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<DocumentAncestorContributorsArgs>>;
  approvals?: Resolver<Array<ResolversTypes['approval']>, ParentType, ContextType, Partial<DocumentApprovalsArgs>>;
  assessmentResults?: Resolver<Array<ResolversTypes['assessment_result_parent']>, ParentType, ContextType, Partial<DocumentAssessmentResultsArgs>>;
  assessmentResults_aggregate?: Resolver<ResolversTypes['assessment_result_parent_aggregate'], ParentType, ContextType, Partial<DocumentAssessmentResults_AggregateArgs>>;
  attestationConfig?: Resolver<Maybe<ResolversTypes['attestation_config']>, ParentType, ContextType>;
  contributorGroups?: Resolver<Array<ResolversTypes['contributor_group']>, ParentType, ContextType, Partial<DocumentContributorGroupsArgs>>;
  contributorGroups_aggregate?: Resolver<ResolversTypes['contributor_group_aggregate'], ParentType, ContextType, Partial<DocumentContributorGroups_AggregateArgs>>;
  contributors?: Resolver<Array<ResolversTypes['contributor']>, ParentType, ContextType, Partial<DocumentContributorsArgs>>;
  contributors_aggregate?: Resolver<ResolversTypes['contributor_aggregate'], ParentType, ContextType, Partial<DocumentContributors_AggregateArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  departments?: Resolver<Array<ResolversTypes['department']>, ParentType, ContextType, Partial<DocumentDepartmentsArgs>>;
  documentFiles?: Resolver<Array<ResolversTypes['document_file']>, ParentType, ContextType, Partial<DocumentDocumentFilesArgs>>;
  issues?: Resolver<Array<ResolversTypes['issue_parent']>, ParentType, ContextType, Partial<DocumentIssuesArgs>>;
  issues_aggregate?: Resolver<ResolversTypes['issue_parent_aggregate'], ParentType, ContextType, Partial<DocumentIssues_AggregateArgs>>;
  linkedDocuments?: Resolver<Array<ResolversTypes['document_linked_document']>, ParentType, ContextType, Partial<DocumentLinkedDocumentsArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  ownerGroups?: Resolver<Array<ResolversTypes['owner_group']>, ParentType, ContextType, Partial<DocumentOwnerGroupsArgs>>;
  ownerGroups_aggregate?: Resolver<ResolversTypes['owner_group_aggregate'], ParentType, ContextType, Partial<DocumentOwnerGroups_AggregateArgs>>;
  owners?: Resolver<Array<ResolversTypes['owner']>, ParentType, ContextType, Partial<DocumentOwnersArgs>>;
  owners_aggregate?: Resolver<ResolversTypes['owner_aggregate'], ParentType, ContextType, Partial<DocumentOwners_AggregateArgs>>;
  parent?: Resolver<Maybe<ResolversTypes['document']>, ParentType, ContextType>;
  schedule?: Resolver<Maybe<ResolversTypes['schedule']>, ParentType, ContextType>;
  scheduleState?: Resolver<Maybe<ResolversTypes['schedule_state']>, ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['tag']>, ParentType, ContextType, Partial<DocumentTagsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_ResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result'] = ResolversParentTypes['document_assessment_result']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Document_Assessment_ResultCustomAttributeDataArgs>>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  RatingType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Rationale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<Document_Assessment_ResultAncestorContributorsArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  files?: Resolver<Array<ResolversTypes['relation_file']>, ParentType, ContextType, Partial<Document_Assessment_ResultFilesArgs>>;
  files_aggregate?: Resolver<ResolversTypes['relation_file_aggregate'], ParentType, ContextType, Partial<Document_Assessment_ResultFiles_AggregateArgs>>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parents?: Resolver<Array<ResolversTypes['assessment_result_parent']>, ParentType, ContextType, Partial<Document_Assessment_ResultParentsArgs>>;
  parents_aggregate?: Resolver<ResolversTypes['assessment_result_parent_aggregate'], ParentType, ContextType, Partial<Document_Assessment_ResultParents_AggregateArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_aggregate'] = ResolversParentTypes['document_assessment_result_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['document_assessment_result_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['document_assessment_result']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_aggregate_fields'] = ResolversParentTypes['document_assessment_result_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['document_assessment_result_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Document_Assessment_Result_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['document_assessment_result_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['document_assessment_result_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['document_assessment_result_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['document_assessment_result_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['document_assessment_result_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['document_assessment_result_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['document_assessment_result_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['document_assessment_result_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['document_assessment_result_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_audit'] = ResolversParentTypes['document_assessment_result_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Document_Assessment_Result_AuditCustomAttributeDataArgs>>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  RatingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rationale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Audit_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_audit_aggregate'] = ResolversParentTypes['document_assessment_result_audit_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['document_assessment_result_audit_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['document_assessment_result_audit']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Audit_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_audit_aggregate_fields'] = ResolversParentTypes['document_assessment_result_audit_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['document_assessment_result_audit_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Document_Assessment_Result_Audit_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['document_assessment_result_audit_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['document_assessment_result_audit_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['document_assessment_result_audit_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['document_assessment_result_audit_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['document_assessment_result_audit_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['document_assessment_result_audit_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['document_assessment_result_audit_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['document_assessment_result_audit_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['document_assessment_result_audit_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Audit_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_audit_avg_fields'] = ResolversParentTypes['document_assessment_result_audit_avg_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Audit_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_audit_max_fields'] = ResolversParentTypes['document_assessment_result_audit_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  RatingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rationale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Audit_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_audit_min_fields'] = ResolversParentTypes['document_assessment_result_audit_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  RatingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rationale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Audit_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_audit_stddev_fields'] = ResolversParentTypes['document_assessment_result_audit_stddev_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Audit_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_audit_stddev_pop_fields'] = ResolversParentTypes['document_assessment_result_audit_stddev_pop_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Audit_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_audit_stddev_samp_fields'] = ResolversParentTypes['document_assessment_result_audit_stddev_samp_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Audit_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_audit_sum_fields'] = ResolversParentTypes['document_assessment_result_audit_sum_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Audit_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_audit_var_pop_fields'] = ResolversParentTypes['document_assessment_result_audit_var_pop_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Audit_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_audit_var_samp_fields'] = ResolversParentTypes['document_assessment_result_audit_var_samp_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Audit_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_audit_variance_fields'] = ResolversParentTypes['document_assessment_result_audit_variance_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_avg_fields'] = ResolversParentTypes['document_assessment_result_avg_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_max_fields'] = ResolversParentTypes['document_assessment_result_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  RatingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rationale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_min_fields'] = ResolversParentTypes['document_assessment_result_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  RatingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rationale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_mutation_response'] = ResolversParentTypes['document_assessment_result_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['document_assessment_result']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_stddev_fields'] = ResolversParentTypes['document_assessment_result_stddev_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_stddev_pop_fields'] = ResolversParentTypes['document_assessment_result_stddev_pop_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_stddev_samp_fields'] = ResolversParentTypes['document_assessment_result_stddev_samp_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_sum_fields'] = ResolversParentTypes['document_assessment_result_sum_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_var_pop_fields'] = ResolversParentTypes['document_assessment_result_var_pop_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_var_samp_fields'] = ResolversParentTypes['document_assessment_result_var_samp_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Assessment_Result_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_assessment_result_variance_fields'] = ResolversParentTypes['document_assessment_result_variance_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_audit'] = ResolversParentTypes['document_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Document_AuditCustomAttributeDataArgs>>;
  DocumentType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentDocument?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Purpose?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_FileResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_file'] = ResolversParentTypes['document_file']> = {
  Content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Document_FileCustomAttributeDataArgs>>;
  FileId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  NextReviewDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ParentDocumentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  PublishedDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ReasonForReview?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReviewDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ReviewedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<ResolversTypes['version_status_enum'], ParentType, ContextType>;
  Summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<ResolversTypes['document_file_type_enum'], ParentType, ContextType>;
  Version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  attestations?: Resolver<Array<ResolversTypes['attestation_record']>, ParentType, ContextType, Partial<Document_FileAttestationsArgs>>;
  attestations_aggregate?: Resolver<ResolversTypes['attestation_record_aggregate'], ParentType, ContextType, Partial<Document_FileAttestations_AggregateArgs>>;
  changeRequests?: Resolver<Array<ResolversTypes['change_request']>, ParentType, ContextType, Partial<Document_FileChangeRequestsArgs>>;
  changeRequests_aggregate?: Resolver<ResolversTypes['change_request_aggregate'], ParentType, ContextType, Partial<Document_FileChangeRequests_AggregateArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  file?: Resolver<Maybe<ResolversTypes['file']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['document']>, ParentType, ContextType>;
  reviewedBy?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_File_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_file_audit'] = ResolversParentTypes['document_file_audit']> = {
  Content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Document_File_AuditCustomAttributeDataArgs>>;
  FileId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  NextReviewDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ParentDocumentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  PublishedDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ReasonForReview?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ReviewDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ReviewedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_File_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_file_mutation_response'] = ResolversParentTypes['document_file_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['document_file']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Linked_DocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_linked_document'] = ResolversParentTypes['document_linked_document']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  DocumentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  LinkedDocumentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  child?: Resolver<Maybe<ResolversTypes['document']>, ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['document']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Linked_Document_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_linked_document_audit'] = ResolversParentTypes['document_linked_document_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  DocumentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  LinkedDocumentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Document_Linked_Document_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['document_linked_document_mutation_response'] = ResolversParentTypes['document_linked_document_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['document_linked_document']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileResolvers<ContextType = any, ParentType extends ResolversParentTypes['file'] = ResolversParentTypes['file']> = {
  ContentType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  FileSize?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Meta?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType, Partial<FileMetaArgs>>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  documentFile?: Resolver<Maybe<ResolversTypes['document_file']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  relationFile?: Resolver<Array<ResolversTypes['relation_file']>, ParentType, ContextType, Partial<FileRelationFileArgs>>;
  relationFile_aggregate?: Resolver<ResolversTypes['relation_file_aggregate'], ParentType, ContextType, Partial<FileRelationFile_AggregateArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type File_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['file_aggregate'] = ResolversParentTypes['file_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['file_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['file']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type File_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['file_aggregate_fields'] = ResolversParentTypes['file_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['file_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<File_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['file_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['file_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['file_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['file_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['file_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['file_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['file_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['file_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['file_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type File_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['file_audit'] = ResolversParentTypes['file_audit']> = {
  ContentType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  FileSize?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Meta?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType, Partial<File_AuditMetaArgs>>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type File_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['file_avg_fields'] = ResolversParentTypes['file_avg_fields']> = {
  FileSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type File_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['file_max_fields'] = ResolversParentTypes['file_max_fields']> = {
  ContentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FileName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FileSize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type File_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['file_min_fields'] = ResolversParentTypes['file_min_fields']> = {
  ContentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FileName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FileSize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type File_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['file_mutation_response'] = ResolversParentTypes['file_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['file']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type File_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['file_stddev_fields'] = ResolversParentTypes['file_stddev_fields']> = {
  FileSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type File_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['file_stddev_pop_fields'] = ResolversParentTypes['file_stddev_pop_fields']> = {
  FileSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type File_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['file_stddev_samp_fields'] = ResolversParentTypes['file_stddev_samp_fields']> = {
  FileSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type File_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['file_sum_fields'] = ResolversParentTypes['file_sum_fields']> = {
  FileSize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type File_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['file_var_pop_fields'] = ResolversParentTypes['file_var_pop_fields']> = {
  FileSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type File_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['file_var_samp_fields'] = ResolversParentTypes['file_var_samp_fields']> = {
  FileSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type File_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['file_variance_fields'] = ResolversParentTypes['file_variance_fields']> = {
  FileSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface Float8ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['float8'], any> {
  name: 'float8';
}

export type Form_ConfigurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['form_configuration'] = ResolversParentTypes['form_configuration']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeSchemaId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentType?: Resolver<ResolversTypes['parent_type_enum'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  customAttributeSchema?: Resolver<Maybe<ResolversTypes['custom_attribute_schema']>, ParentType, ContextType>;
  fields_config?: Resolver<Array<ResolversTypes['form_field_configuration']>, ParentType, ContextType, Partial<Form_ConfigurationFields_ConfigArgs>>;
  fields_ordering?: Resolver<Array<ResolversTypes['form_field_ordering']>, ParentType, ContextType, Partial<Form_ConfigurationFields_OrderingArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Form_Configuration_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['form_configuration_audit'] = ResolversParentTypes['form_configuration_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeSchemaId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Form_Configuration_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['form_configuration_mutation_response'] = ResolversParentTypes['form_configuration_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['form_configuration']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Form_Field_ConfigurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['form_field_configuration'] = ResolversParentTypes['form_field_configuration']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  DefaultValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FieldId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  FormConfigurationParentType?: Resolver<ResolversTypes['parent_type_enum'], ParentType, ContextType>;
  Hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ReadOnly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  Required?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  form?: Resolver<Maybe<ResolversTypes['form_configuration']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Form_Field_Configuration_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['form_field_configuration_audit'] = ResolversParentTypes['form_field_configuration_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  FieldId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  FormConfigurationParentType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ReadOnly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  Required?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Form_Field_Configuration_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['form_field_configuration_mutation_response'] = ResolversParentTypes['form_field_configuration_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['form_field_configuration']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Form_Field_OrderingResolvers<ContextType = any, ParentType extends ResolversParentTypes['form_field_ordering'] = ResolversParentTypes['form_field_ordering']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  FieldId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  FormConfigurationParentType?: Resolver<ResolversTypes['parent_type_enum'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  form?: Resolver<Maybe<ResolversTypes['form_configuration']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Form_Field_Ordering_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['form_field_ordering_audit'] = ResolversParentTypes['form_field_ordering_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  FieldId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  FormConfigurationParentType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Form_Field_Ordering_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['form_field_ordering_mutation_response'] = ResolversParentTypes['form_field_ordering_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['form_field_ordering']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact'] = ResolversParentTypes['impact']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<ImpactCustomAttributeDataArgs>>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  RatingGuidance?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rationale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<ImpactAncestorContributorsArgs>>;
  appetites?: Resolver<Array<ResolversTypes['appetite_parent']>, ParentType, ContextType, Partial<ImpactAppetitesArgs>>;
  appetites_aggregate?: Resolver<ResolversTypes['appetite_parent_aggregate'], ParentType, ContextType, Partial<ImpactAppetites_AggregateArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  ownerGroups?: Resolver<Array<ResolversTypes['owner_group']>, ParentType, ContextType, Partial<ImpactOwnerGroupsArgs>>;
  ownerGroups_aggregate?: Resolver<ResolversTypes['owner_group_aggregate'], ParentType, ContextType, Partial<ImpactOwnerGroups_AggregateArgs>>;
  owners?: Resolver<Array<ResolversTypes['owner']>, ParentType, ContextType, Partial<ImpactOwnersArgs>>;
  owners_aggregate?: Resolver<ResolversTypes['owner_aggregate'], ParentType, ContextType, Partial<ImpactOwners_AggregateArgs>>;
  parents?: Resolver<Array<ResolversTypes['impact_parent']>, ParentType, ContextType, Partial<ImpactParentsArgs>>;
  parents_aggregate?: Resolver<ResolversTypes['impact_parent_aggregate'], ParentType, ContextType, Partial<ImpactParents_AggregateArgs>>;
  ratings?: Resolver<Array<ResolversTypes['impact_rating']>, ParentType, ContextType, Partial<ImpactRatingsArgs>>;
  ratings_aggregate?: Resolver<ResolversTypes['impact_rating_aggregate'], ParentType, ContextType, Partial<ImpactRatings_AggregateArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_aggregate'] = ResolversParentTypes['impact_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['impact_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['impact']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_aggregate_fields'] = ResolversParentTypes['impact_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['impact_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Impact_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['impact_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['impact_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['impact_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['impact_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['impact_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['impact_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['impact_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['impact_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['impact_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_audit'] = ResolversParentTypes['impact_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Impact_AuditCustomAttributeDataArgs>>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  RatingGuidance?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rationale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_avg_fields'] = ResolversParentTypes['impact_avg_fields']> = {
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_max_fields'] = ResolversParentTypes['impact_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RatingGuidance?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rationale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_min_fields'] = ResolversParentTypes['impact_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RatingGuidance?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rationale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_mutation_response'] = ResolversParentTypes['impact_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['impact']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_ParentResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_parent'] = ResolversParentTypes['impact_parent']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ImpactId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  impact?: Resolver<Maybe<ResolversTypes['impact']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Parent_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_parent_aggregate'] = ResolversParentTypes['impact_parent_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['impact_parent_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['impact_parent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Parent_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_parent_aggregate_fields'] = ResolversParentTypes['impact_parent_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Impact_Parent_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['impact_parent_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['impact_parent_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Parent_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_parent_audit'] = ResolversParentTypes['impact_parent_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ImpactId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Parent_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_parent_max_fields'] = ResolversParentTypes['impact_parent_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ImpactId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Parent_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_parent_min_fields'] = ResolversParentTypes['impact_parent_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ImpactId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_RatingResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_rating'] = ResolversParentTypes['impact_rating']> = {
  CompletedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Impact_RatingCustomAttributeDataArgs>>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ImpactId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Likelihood?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  RatedItemId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Rating?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  RatingType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  SequentialId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  TestDate?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  assessmentParents?: Resolver<Array<ResolversTypes['assessment_result_parent']>, ParentType, ContextType, Partial<Impact_RatingAssessmentParentsArgs>>;
  assessmentParents_aggregate?: Resolver<ResolversTypes['assessment_result_parent_aggregate'], ParentType, ContextType, Partial<Impact_RatingAssessmentParents_AggregateArgs>>;
  completedBy?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  impact?: Resolver<ResolversTypes['impact'], ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  ratedItem?: Resolver<ResolversTypes['node'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Rating_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_rating_aggregate'] = ResolversParentTypes['impact_rating_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['impact_rating_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['impact_rating']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Rating_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_rating_aggregate_fields'] = ResolversParentTypes['impact_rating_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['impact_rating_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Impact_Rating_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['impact_rating_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['impact_rating_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['impact_rating_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['impact_rating_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['impact_rating_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['impact_rating_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['impact_rating_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['impact_rating_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['impact_rating_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Rating_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_rating_audit'] = ResolversParentTypes['impact_rating_audit']> = {
  CompletedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Impact_Rating_AuditCustomAttributeDataArgs>>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ImpactId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Likelihood?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RatedItemId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Rating?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  RatingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  TestDate?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Rating_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_rating_avg_fields'] = ResolversParentTypes['impact_rating_avg_fields']> = {
  Likelihood?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Rating_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_rating_max_fields'] = ResolversParentTypes['impact_rating_max_fields']> = {
  CompletedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ImpactId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Likelihood?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RatedItemId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  RatingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  TestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Rating_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_rating_min_fields'] = ResolversParentTypes['impact_rating_min_fields']> = {
  CompletedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ImpactId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Likelihood?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RatedItemId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  RatingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  TestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Rating_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_rating_mutation_response'] = ResolversParentTypes['impact_rating_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['impact_rating']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Rating_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_rating_stddev_fields'] = ResolversParentTypes['impact_rating_stddev_fields']> = {
  Likelihood?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Rating_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_rating_stddev_pop_fields'] = ResolversParentTypes['impact_rating_stddev_pop_fields']> = {
  Likelihood?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Rating_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_rating_stddev_samp_fields'] = ResolversParentTypes['impact_rating_stddev_samp_fields']> = {
  Likelihood?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Rating_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_rating_sum_fields'] = ResolversParentTypes['impact_rating_sum_fields']> = {
  Likelihood?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Rating_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_rating_var_pop_fields'] = ResolversParentTypes['impact_rating_var_pop_fields']> = {
  Likelihood?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Rating_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_rating_var_samp_fields'] = ResolversParentTypes['impact_rating_var_samp_fields']> = {
  Likelihood?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Rating_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_rating_variance_fields'] = ResolversParentTypes['impact_rating_variance_fields']> = {
  Likelihood?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_stddev_fields'] = ResolversParentTypes['impact_stddev_fields']> = {
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_stddev_pop_fields'] = ResolversParentTypes['impact_stddev_pop_fields']> = {
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_stddev_samp_fields'] = ResolversParentTypes['impact_stddev_samp_fields']> = {
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_sum_fields'] = ResolversParentTypes['impact_sum_fields']> = {
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_var_pop_fields'] = ResolversParentTypes['impact_var_pop_fields']> = {
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_var_samp_fields'] = ResolversParentTypes['impact_var_samp_fields']> = {
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Impact_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['impact_variance_fields'] = ResolversParentTypes['impact_variance_fields']> = {
  LikelihoodAppetite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IndicatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['indicator'] = ResolversParentTypes['indicator']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<IndicatorCustomAttributeDataArgs>>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  LowerAppetiteNum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  LowerToleranceNum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  TargetValueTxt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Type?: Resolver<ResolversTypes['indicator_type_enum'], ParentType, ContextType>;
  Unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UpperAppetiteNum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  UpperToleranceNum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<IndicatorAncestorContributorsArgs>>;
  contributorGroups?: Resolver<Array<ResolversTypes['contributor_group']>, ParentType, ContextType, Partial<IndicatorContributorGroupsArgs>>;
  contributorGroups_aggregate?: Resolver<ResolversTypes['contributor_group_aggregate'], ParentType, ContextType, Partial<IndicatorContributorGroups_AggregateArgs>>;
  contributors?: Resolver<Array<ResolversTypes['contributor']>, ParentType, ContextType, Partial<IndicatorContributorsArgs>>;
  contributors_aggregate?: Resolver<ResolversTypes['contributor_aggregate'], ParentType, ContextType, Partial<IndicatorContributors_AggregateArgs>>;
  createdBy?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  departments?: Resolver<Array<ResolversTypes['department']>, ParentType, ContextType, Partial<IndicatorDepartmentsArgs>>;
  files?: Resolver<Array<ResolversTypes['relation_file']>, ParentType, ContextType, Partial<IndicatorFilesArgs>>;
  files_aggregate?: Resolver<ResolversTypes['relation_file_aggregate'], ParentType, ContextType, Partial<IndicatorFiles_AggregateArgs>>;
  indicator_type?: Resolver<ResolversTypes['indicator_type'], ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  org?: Resolver<Maybe<ResolversTypes['auth_organisation']>, ParentType, ContextType>;
  ownerGroups?: Resolver<Array<ResolversTypes['owner_group']>, ParentType, ContextType, Partial<IndicatorOwnerGroupsArgs>>;
  ownerGroups_aggregate?: Resolver<ResolversTypes['owner_group_aggregate'], ParentType, ContextType, Partial<IndicatorOwnerGroups_AggregateArgs>>;
  owners?: Resolver<Array<ResolversTypes['owner']>, ParentType, ContextType, Partial<IndicatorOwnersArgs>>;
  owners_aggregate?: Resolver<ResolversTypes['owner_aggregate'], ParentType, ContextType, Partial<IndicatorOwners_AggregateArgs>>;
  parents?: Resolver<Array<ResolversTypes['indicator_parent']>, ParentType, ContextType, Partial<IndicatorParentsArgs>>;
  parents_aggregate?: Resolver<ResolversTypes['indicator_parent_aggregate'], ParentType, ContextType, Partial<IndicatorParents_AggregateArgs>>;
  results?: Resolver<Array<ResolversTypes['indicator_result']>, ParentType, ContextType, Partial<IndicatorResultsArgs>>;
  schedule?: Resolver<Maybe<ResolversTypes['schedule']>, ParentType, ContextType>;
  scheduleState?: Resolver<Maybe<ResolversTypes['schedule_state']>, ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['tag']>, ParentType, ContextType, Partial<IndicatorTagsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Indicator_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['indicator_audit'] = ResolversParentTypes['indicator_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Indicator_AuditCustomAttributeDataArgs>>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  LowerAppetiteNum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  LowerToleranceNum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  TargetValueTxt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UpperAppetiteNum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  UpperToleranceNum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Indicator_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['indicator_mutation_response'] = ResolversParentTypes['indicator_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['indicator']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Indicator_ParentResolvers<ContextType = any, ParentType extends ResolversParentTypes['indicator_parent'] = ResolversParentTypes['indicator_parent']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  IndicatorId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  control?: Resolver<Maybe<ResolversTypes['control']>, ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  indicator?: Resolver<Maybe<ResolversTypes['indicator']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType>;
  risk?: Resolver<Maybe<ResolversTypes['risk']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Indicator_Parent_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['indicator_parent_aggregate'] = ResolversParentTypes['indicator_parent_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['indicator_parent_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['indicator_parent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Indicator_Parent_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['indicator_parent_aggregate_fields'] = ResolversParentTypes['indicator_parent_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Indicator_Parent_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['indicator_parent_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['indicator_parent_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Indicator_Parent_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['indicator_parent_max_fields'] = ResolversParentTypes['indicator_parent_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IndicatorId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Indicator_Parent_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['indicator_parent_min_fields'] = ResolversParentTypes['indicator_parent_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IndicatorId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Indicator_Parent_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['indicator_parent_mutation_response'] = ResolversParentTypes['indicator_parent_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['indicator_parent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Indicator_ResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['indicator_result'] = ResolversParentTypes['indicator_result']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Indicator_ResultCustomAttributeDataArgs>>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  IndicatorId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  OrgKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ResultDate?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  TargetValueNum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  TargetValueTxt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  files?: Resolver<Array<ResolversTypes['relation_file']>, ParentType, ContextType, Partial<Indicator_ResultFilesArgs>>;
  files_aggregate?: Resolver<ResolversTypes['relation_file_aggregate'], ParentType, ContextType, Partial<Indicator_ResultFiles_AggregateArgs>>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['indicator']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Indicator_Result_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['indicator_result_audit'] = ResolversParentTypes['indicator_result_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Indicator_Result_AuditCustomAttributeDataArgs>>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  IndicatorId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrgKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ResultDate?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  TargetValueNum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  TargetValueTxt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Indicator_Result_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['indicator_result_mutation_response'] = ResolversParentTypes['indicator_result_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['indicator_result']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Indicator_TypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['indicator_type'] = ResolversParentTypes['indicator_type']> = {
  Comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  indicator_type?: Resolver<Array<ResolversTypes['indicator']>, ParentType, ContextType, Partial<Indicator_TypeIndicator_TypeArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsertChildImpactRatingOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['insertChildImpactRatingOutput'] = ResolversParentTypes['insertChildImpactRatingOutput']> = {
  Ids?: Resolver<Array<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsertControlTestResultOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['insertControlTestResultOutput'] = ResolversParentTypes['insertControlTestResultOutput']> = {
  Ids?: Resolver<Array<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_EntityResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_entity'] = ResolversParentTypes['internal_audit_entity']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Internal_Audit_EntityCustomAttributeDataArgs>>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  actions?: Resolver<Array<ResolversTypes['action_parent']>, ParentType, ContextType, Partial<Internal_Audit_EntityActionsArgs>>;
  actions_aggregate?: Resolver<ResolversTypes['action_parent_aggregate'], ParentType, ContextType, Partial<Internal_Audit_EntityActions_AggregateArgs>>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<Internal_Audit_EntityAncestorContributorsArgs>>;
  businessArea?: Resolver<Maybe<ResolversTypes['business_area']>, ParentType, ContextType>;
  contributorGroups?: Resolver<Array<ResolversTypes['contributor_group']>, ParentType, ContextType, Partial<Internal_Audit_EntityContributorGroupsArgs>>;
  contributorGroups_aggregate?: Resolver<ResolversTypes['contributor_group_aggregate'], ParentType, ContextType, Partial<Internal_Audit_EntityContributorGroups_AggregateArgs>>;
  contributors?: Resolver<Array<ResolversTypes['contributor']>, ParentType, ContextType, Partial<Internal_Audit_EntityContributorsArgs>>;
  contributors_aggregate?: Resolver<ResolversTypes['contributor_aggregate'], ParentType, ContextType, Partial<Internal_Audit_EntityContributors_AggregateArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  departments?: Resolver<Array<ResolversTypes['department']>, ParentType, ContextType, Partial<Internal_Audit_EntityDepartmentsArgs>>;
  internalAuditReports?: Resolver<Array<ResolversTypes['internal_audit_report']>, ParentType, ContextType, Partial<Internal_Audit_EntityInternalAuditReportsArgs>>;
  internalAuditReports_aggregate?: Resolver<ResolversTypes['internal_audit_report_aggregate'], ParentType, ContextType, Partial<Internal_Audit_EntityInternalAuditReports_AggregateArgs>>;
  issues?: Resolver<Array<ResolversTypes['issue_parent']>, ParentType, ContextType, Partial<Internal_Audit_EntityIssuesArgs>>;
  issues_aggregate?: Resolver<ResolversTypes['issue_parent_aggregate'], ParentType, ContextType, Partial<Internal_Audit_EntityIssues_AggregateArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  ownerGroups?: Resolver<Array<ResolversTypes['owner_group']>, ParentType, ContextType, Partial<Internal_Audit_EntityOwnerGroupsArgs>>;
  ownerGroups_aggregate?: Resolver<ResolversTypes['owner_group_aggregate'], ParentType, ContextType, Partial<Internal_Audit_EntityOwnerGroups_AggregateArgs>>;
  owners?: Resolver<Array<ResolversTypes['owner']>, ParentType, ContextType, Partial<Internal_Audit_EntityOwnersArgs>>;
  owners_aggregate?: Resolver<ResolversTypes['owner_aggregate'], ParentType, ContextType, Partial<Internal_Audit_EntityOwners_AggregateArgs>>;
  tags?: Resolver<Array<ResolversTypes['tag']>, ParentType, ContextType, Partial<Internal_Audit_EntityTagsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Entity_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_entity_aggregate'] = ResolversParentTypes['internal_audit_entity_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['internal_audit_entity_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['internal_audit_entity']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Entity_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_entity_aggregate_fields'] = ResolversParentTypes['internal_audit_entity_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['internal_audit_entity_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Internal_Audit_Entity_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['internal_audit_entity_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['internal_audit_entity_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['internal_audit_entity_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['internal_audit_entity_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['internal_audit_entity_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['internal_audit_entity_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['internal_audit_entity_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['internal_audit_entity_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['internal_audit_entity_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Entity_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_entity_avg_fields'] = ResolversParentTypes['internal_audit_entity_avg_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Entity_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_entity_max_fields'] = ResolversParentTypes['internal_audit_entity_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Entity_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_entity_min_fields'] = ResolversParentTypes['internal_audit_entity_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Entity_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_entity_mutation_response'] = ResolversParentTypes['internal_audit_entity_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['internal_audit_entity']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Entity_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_entity_stddev_fields'] = ResolversParentTypes['internal_audit_entity_stddev_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Entity_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_entity_stddev_pop_fields'] = ResolversParentTypes['internal_audit_entity_stddev_pop_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Entity_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_entity_stddev_samp_fields'] = ResolversParentTypes['internal_audit_entity_stddev_samp_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Entity_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_entity_sum_fields'] = ResolversParentTypes['internal_audit_entity_sum_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Entity_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_entity_var_pop_fields'] = ResolversParentTypes['internal_audit_entity_var_pop_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Entity_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_entity_var_samp_fields'] = ResolversParentTypes['internal_audit_entity_var_samp_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Entity_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_entity_variance_fields'] = ResolversParentTypes['internal_audit_entity_variance_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_ReportResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_report'] = ResolversParentTypes['internal_audit_report']> = {
  ActualCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CompletedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Internal_Audit_ReportCustomAttributeDataArgs>>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  NextTestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OriginatingItemId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Outcome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Status?: Resolver<ResolversTypes['assessment_status_enum'], ParentType, ContextType>;
  Summary?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  TargetCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<Internal_Audit_ReportAncestorContributorsArgs>>;
  assessmentActions?: Resolver<Array<ResolversTypes['action_parent']>, ParentType, ContextType, Partial<Internal_Audit_ReportAssessmentActionsArgs>>;
  assessmentActions_aggregate?: Resolver<ResolversTypes['action_parent_aggregate'], ParentType, ContextType, Partial<Internal_Audit_ReportAssessmentActions_AggregateArgs>>;
  assessmentActivities?: Resolver<Array<ResolversTypes['assessment_activity']>, ParentType, ContextType, Partial<Internal_Audit_ReportAssessmentActivitiesArgs>>;
  assessmentActivities_aggregate?: Resolver<ResolversTypes['assessment_activity_aggregate'], ParentType, ContextType, Partial<Internal_Audit_ReportAssessmentActivities_AggregateArgs>>;
  assessmentIssues?: Resolver<Array<ResolversTypes['issue_parent']>, ParentType, ContextType, Partial<Internal_Audit_ReportAssessmentIssuesArgs>>;
  assessmentIssues_aggregate?: Resolver<ResolversTypes['issue_parent_aggregate'], ParentType, ContextType, Partial<Internal_Audit_ReportAssessmentIssues_AggregateArgs>>;
  assessmentResults?: Resolver<Array<ResolversTypes['assessment_result_parent']>, ParentType, ContextType, Partial<Internal_Audit_ReportAssessmentResultsArgs>>;
  assessmentResults_aggregate?: Resolver<ResolversTypes['assessment_result_parent_aggregate'], ParentType, ContextType, Partial<Internal_Audit_ReportAssessmentResults_AggregateArgs>>;
  completedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  contributorGroups?: Resolver<Array<ResolversTypes['contributor_group']>, ParentType, ContextType, Partial<Internal_Audit_ReportContributorGroupsArgs>>;
  contributorGroups_aggregate?: Resolver<ResolversTypes['contributor_group_aggregate'], ParentType, ContextType, Partial<Internal_Audit_ReportContributorGroups_AggregateArgs>>;
  contributors?: Resolver<Array<ResolversTypes['contributor']>, ParentType, ContextType, Partial<Internal_Audit_ReportContributorsArgs>>;
  contributors_aggregate?: Resolver<ResolversTypes['contributor_aggregate'], ParentType, ContextType, Partial<Internal_Audit_ReportContributors_AggregateArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  departments?: Resolver<Array<ResolversTypes['department']>, ParentType, ContextType, Partial<Internal_Audit_ReportDepartmentsArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  originatingItem?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType>;
  ownerGroups?: Resolver<Array<ResolversTypes['owner_group']>, ParentType, ContextType, Partial<Internal_Audit_ReportOwnerGroupsArgs>>;
  ownerGroups_aggregate?: Resolver<ResolversTypes['owner_group_aggregate'], ParentType, ContextType, Partial<Internal_Audit_ReportOwnerGroups_AggregateArgs>>;
  owners?: Resolver<Array<ResolversTypes['owner']>, ParentType, ContextType, Partial<Internal_Audit_ReportOwnersArgs>>;
  owners_aggregate?: Resolver<ResolversTypes['owner_aggregate'], ParentType, ContextType, Partial<Internal_Audit_ReportOwners_AggregateArgs>>;
  tags?: Resolver<Array<ResolversTypes['tag']>, ParentType, ContextType, Partial<Internal_Audit_ReportTagsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Report_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_report_aggregate'] = ResolversParentTypes['internal_audit_report_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['internal_audit_report_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['internal_audit_report']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Report_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_report_aggregate_fields'] = ResolversParentTypes['internal_audit_report_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['internal_audit_report_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Internal_Audit_Report_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['internal_audit_report_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['internal_audit_report_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['internal_audit_report_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['internal_audit_report_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['internal_audit_report_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['internal_audit_report_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['internal_audit_report_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['internal_audit_report_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['internal_audit_report_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Report_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_report_avg_fields'] = ResolversParentTypes['internal_audit_report_avg_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Report_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_report_max_fields'] = ResolversParentTypes['internal_audit_report_max_fields']> = {
  ActualCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CompletedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NextTestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OriginatingItemId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Outcome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Report_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_report_min_fields'] = ResolversParentTypes['internal_audit_report_min_fields']> = {
  ActualCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CompletedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  NextTestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OriginatingItemId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Outcome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetCompletionDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Report_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_report_mutation_response'] = ResolversParentTypes['internal_audit_report_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['internal_audit_report']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Report_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_report_stddev_fields'] = ResolversParentTypes['internal_audit_report_stddev_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Report_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_report_stddev_pop_fields'] = ResolversParentTypes['internal_audit_report_stddev_pop_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Report_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_report_stddev_samp_fields'] = ResolversParentTypes['internal_audit_report_stddev_samp_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Report_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_report_sum_fields'] = ResolversParentTypes['internal_audit_report_sum_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Report_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_report_var_pop_fields'] = ResolversParentTypes['internal_audit_report_var_pop_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Report_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_report_var_samp_fields'] = ResolversParentTypes['internal_audit_report_var_samp_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Internal_Audit_Report_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['internal_audit_report_variance_fields'] = ResolversParentTypes['internal_audit_report_variance_fields']> = {
  Outcome?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface IntervalScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['interval'], any> {
  name: 'interval';
}

export type IssueResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue'] = ResolversParentTypes['issue']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<IssueCustomAttributeDataArgs>>;
  DateIdentified?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  DateOccurred?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  Details?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ImpactsCustomer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IsExternalIssue?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Meta?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType, Partial<IssueMetaArgs>>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  RaisedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  actions?: Resolver<Array<ResolversTypes['action_parent']>, ParentType, ContextType, Partial<IssueActionsArgs>>;
  actions_aggregate?: Resolver<ResolversTypes['action_parent_aggregate'], ParentType, ContextType, Partial<IssueActions_AggregateArgs>>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<IssueAncestorContributorsArgs>>;
  assessment?: Resolver<Maybe<ResolversTypes['issue_assessment']>, ParentType, ContextType>;
  causes?: Resolver<Array<ResolversTypes['cause']>, ParentType, ContextType, Partial<IssueCausesArgs>>;
  causes_aggregate?: Resolver<ResolversTypes['cause_aggregate'], ParentType, ContextType, Partial<IssueCauses_AggregateArgs>>;
  consequences?: Resolver<Array<ResolversTypes['consequence']>, ParentType, ContextType, Partial<IssueConsequencesArgs>>;
  consequences_aggregate?: Resolver<ResolversTypes['consequence_aggregate'], ParentType, ContextType, Partial<IssueConsequences_AggregateArgs>>;
  contributorGroups?: Resolver<Array<ResolversTypes['contributor_group']>, ParentType, ContextType, Partial<IssueContributorGroupsArgs>>;
  contributorGroups_aggregate?: Resolver<ResolversTypes['contributor_group_aggregate'], ParentType, ContextType, Partial<IssueContributorGroups_AggregateArgs>>;
  contributors?: Resolver<Array<ResolversTypes['contributor']>, ParentType, ContextType, Partial<IssueContributorsArgs>>;
  contributors_aggregate?: Resolver<ResolversTypes['contributor_aggregate'], ParentType, ContextType, Partial<IssueContributors_AggregateArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  departments?: Resolver<Array<ResolversTypes['department']>, ParentType, ContextType, Partial<IssueDepartmentsArgs>>;
  files?: Resolver<Array<ResolversTypes['relation_file']>, ParentType, ContextType, Partial<IssueFilesArgs>>;
  files_aggregate?: Resolver<ResolversTypes['relation_file_aggregate'], ParentType, ContextType, Partial<IssueFiles_AggregateArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  ownerGroups?: Resolver<Array<ResolversTypes['owner_group']>, ParentType, ContextType, Partial<IssueOwnerGroupsArgs>>;
  ownerGroups_aggregate?: Resolver<ResolversTypes['owner_group_aggregate'], ParentType, ContextType, Partial<IssueOwnerGroups_AggregateArgs>>;
  owners?: Resolver<Array<ResolversTypes['owner']>, ParentType, ContextType, Partial<IssueOwnersArgs>>;
  owners_aggregate?: Resolver<ResolversTypes['owner_aggregate'], ParentType, ContextType, Partial<IssueOwners_AggregateArgs>>;
  parents?: Resolver<Array<ResolversTypes['issue_parent']>, ParentType, ContextType, Partial<IssueParentsArgs>>;
  parents_aggregate?: Resolver<ResolversTypes['issue_parent_aggregate'], ParentType, ContextType, Partial<IssueParents_AggregateArgs>>;
  tags?: Resolver<Array<ResolversTypes['tag']>, ParentType, ContextType, Partial<IssueTagsArgs>>;
  updates?: Resolver<Array<ResolversTypes['issue_update']>, ParentType, ContextType, Partial<IssueUpdatesArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_aggregate'] = ResolversParentTypes['issue_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['issue_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['issue']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_aggregate_fields'] = ResolversParentTypes['issue_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['issue_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Issue_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['issue_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['issue_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['issue_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['issue_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['issue_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['issue_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['issue_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['issue_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['issue_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_AssessmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_assessment'] = ResolversParentTypes['issue_assessment']> = {
  ActualCloseDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CertifiedIndividual?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Issue_AssessmentCustomAttributeDataArgs>>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  IssueCausedBySystemIssue?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IssueCausedByThirdParty?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IssueType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentIssueId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  PoliciesBreached?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PolicyBreach?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PolicyOwner?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PolicyOwnerCommentary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rationale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RegulationsBreached?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RegulatoryBreach?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Reportable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Severity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['issue_assessment_status_enum']>, ParentType, ContextType>;
  SystemResponsible?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetCloseDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ThirdPartyResponsible?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  certifiedIndividual?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  departments?: Resolver<Array<ResolversTypes['department']>, ParentType, ContextType, Partial<Issue_AssessmentDepartmentsArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['issue']>, ParentType, ContextType>;
  policyOwner?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Assessment_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_assessment_aggregate'] = ResolversParentTypes['issue_assessment_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['issue_assessment_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['issue_assessment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Assessment_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_assessment_aggregate_fields'] = ResolversParentTypes['issue_assessment_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['issue_assessment_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Issue_Assessment_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['issue_assessment_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['issue_assessment_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['issue_assessment_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['issue_assessment_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['issue_assessment_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['issue_assessment_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['issue_assessment_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['issue_assessment_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['issue_assessment_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Assessment_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_assessment_audit'] = ResolversParentTypes['issue_assessment_audit']> = {
  Action?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ActualCloseDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CertifiedIndividual?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Issue_Assessment_AuditCustomAttributeDataArgs>>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  IssueCausedBySystemIssue?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IssueCausedByThirdParty?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IssueType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentIssueId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  PoliciesBreached?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PolicyBreach?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  PolicyOwner?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PolicyOwnerCommentary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rationale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RegulationsBreached?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RegulatoryBreach?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Reportable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Severity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SystemResponsible?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetCloseDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ThirdPartyResponsible?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Assessment_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_assessment_avg_fields'] = ResolversParentTypes['issue_assessment_avg_fields']> = {
  Severity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Assessment_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_assessment_max_fields'] = ResolversParentTypes['issue_assessment_max_fields']> = {
  ActualCloseDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CertifiedIndividual?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  IssueType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentIssueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  PoliciesBreached?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PolicyOwner?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PolicyOwnerCommentary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rationale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RegulationsBreached?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Severity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SystemResponsible?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetCloseDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ThirdPartyResponsible?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Assessment_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_assessment_min_fields'] = ResolversParentTypes['issue_assessment_min_fields']> = {
  ActualCloseDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CertifiedIndividual?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  IssueType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentIssueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  PoliciesBreached?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PolicyOwner?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PolicyOwnerCommentary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rationale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RegulationsBreached?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Severity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SystemResponsible?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TargetCloseDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ThirdPartyResponsible?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Assessment_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_assessment_stddev_fields'] = ResolversParentTypes['issue_assessment_stddev_fields']> = {
  Severity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Assessment_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_assessment_stddev_pop_fields'] = ResolversParentTypes['issue_assessment_stddev_pop_fields']> = {
  Severity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Assessment_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_assessment_stddev_samp_fields'] = ResolversParentTypes['issue_assessment_stddev_samp_fields']> = {
  Severity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Assessment_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_assessment_sum_fields'] = ResolversParentTypes['issue_assessment_sum_fields']> = {
  Severity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Assessment_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_assessment_var_pop_fields'] = ResolversParentTypes['issue_assessment_var_pop_fields']> = {
  Severity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Assessment_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_assessment_var_samp_fields'] = ResolversParentTypes['issue_assessment_var_samp_fields']> = {
  Severity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Assessment_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_assessment_variance_fields'] = ResolversParentTypes['issue_assessment_variance_fields']> = {
  Severity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_audit'] = ResolversParentTypes['issue_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Issue_AuditCustomAttributeDataArgs>>;
  DateIdentified?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  DateOccurred?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  Details?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ImpactsCustomer?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  IsExternalIssue?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Meta?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType, Partial<Issue_AuditMetaArgs>>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RaisedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_avg_fields'] = ResolversParentTypes['issue_avg_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_max_fields'] = ResolversParentTypes['issue_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateIdentified?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  DateOccurred?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Details?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RaisedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_min_fields'] = ResolversParentTypes['issue_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DateIdentified?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  DateOccurred?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Details?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RaisedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_mutation_response'] = ResolversParentTypes['issue_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['issue']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_ParentResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_parent'] = ResolversParentTypes['issue_parent']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  IssueId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ParentType?: Resolver<ResolversTypes['parent_type_enum'], ParentType, ContextType>;
  assessment?: Resolver<Maybe<ResolversTypes['assessment']>, ParentType, ContextType>;
  complianceMonitoringAssessment?: Resolver<Maybe<ResolversTypes['compliance_monitoring_assessment']>, ParentType, ContextType>;
  control?: Resolver<Maybe<ResolversTypes['control']>, ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  document?: Resolver<Maybe<ResolversTypes['document']>, ParentType, ContextType>;
  internalAuditEntity?: Resolver<Maybe<ResolversTypes['internal_audit_entity']>, ParentType, ContextType>;
  internalAuditReport?: Resolver<Maybe<ResolversTypes['internal_audit_report']>, ParentType, ContextType>;
  issue?: Resolver<Maybe<ResolversTypes['issue']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  obligation?: Resolver<Maybe<ResolversTypes['obligation']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType>;
  thirdParty?: Resolver<Maybe<ResolversTypes['third_party']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Parent_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_parent_aggregate'] = ResolversParentTypes['issue_parent_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['issue_parent_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['issue_parent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Parent_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_parent_aggregate_fields'] = ResolversParentTypes['issue_parent_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Issue_Parent_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['issue_parent_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['issue_parent_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Parent_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_parent_audit'] = ResolversParentTypes['issue_parent_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  IssueId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ParentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Parent_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_parent_max_fields'] = ResolversParentTypes['issue_parent_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IssueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Parent_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_parent_min_fields'] = ResolversParentTypes['issue_parent_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IssueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_stddev_fields'] = ResolversParentTypes['issue_stddev_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_stddev_pop_fields'] = ResolversParentTypes['issue_stddev_pop_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_stddev_samp_fields'] = ResolversParentTypes['issue_stddev_samp_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_sum_fields'] = ResolversParentTypes['issue_sum_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_UpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_update'] = ResolversParentTypes['issue_update']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Issue_UpdateCustomAttributeDataArgs>>;
  Description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentIssueId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  files?: Resolver<Array<ResolversTypes['relation_file']>, ParentType, ContextType, Partial<Issue_UpdateFilesArgs>>;
  files_aggregate?: Resolver<ResolversTypes['relation_file_aggregate'], ParentType, ContextType, Partial<Issue_UpdateFiles_AggregateArgs>>;
  issue?: Resolver<Maybe<ResolversTypes['issue']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Update_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_update_audit'] = ResolversParentTypes['issue_update_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Issue_Update_AuditCustomAttributeDataArgs>>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentIssueId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Update_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_update_mutation_response'] = ResolversParentTypes['issue_update_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['issue_update']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_var_pop_fields'] = ResolversParentTypes['issue_var_pop_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_var_samp_fields'] = ResolversParentTypes['issue_var_samp_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Issue_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['issue_variance_fields'] = ResolversParentTypes['issue_variance_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['json'], any> {
  name: 'json';
}

export interface JsonbScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['jsonb'], any> {
  name: 'jsonb';
}

export type Linked_ItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['linked_item'] = ResolversParentTypes['linked_item']> = {
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  RelationshipType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Source?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Target?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  source_acceptance?: Resolver<Maybe<ResolversTypes['acceptance']>, ParentType, ContextType>;
  source_action?: Resolver<Maybe<ResolversTypes['action']>, ParentType, ContextType>;
  source_appetite?: Resolver<Maybe<ResolversTypes['appetite']>, ParentType, ContextType>;
  source_assessment?: Resolver<Maybe<ResolversTypes['assessment']>, ParentType, ContextType>;
  source_assessment_activity?: Resolver<Maybe<ResolversTypes['assessment_activity']>, ParentType, ContextType>;
  source_cause?: Resolver<Maybe<ResolversTypes['cause']>, ParentType, ContextType>;
  source_consequence?: Resolver<Maybe<ResolversTypes['consequence']>, ParentType, ContextType>;
  source_control?: Resolver<Maybe<ResolversTypes['control']>, ParentType, ContextType>;
  source_control_group?: Resolver<Maybe<ResolversTypes['control_group']>, ParentType, ContextType>;
  source_document?: Resolver<Maybe<ResolversTypes['document']>, ParentType, ContextType>;
  source_impact?: Resolver<Maybe<ResolversTypes['impact']>, ParentType, ContextType>;
  source_indicator?: Resolver<Maybe<ResolversTypes['indicator']>, ParentType, ContextType>;
  source_internal_audit_entity?: Resolver<Maybe<ResolversTypes['internal_audit_entity']>, ParentType, ContextType>;
  source_internal_audit_report?: Resolver<Maybe<ResolversTypes['internal_audit_report']>, ParentType, ContextType>;
  source_issue?: Resolver<Maybe<ResolversTypes['issue']>, ParentType, ContextType>;
  source_obligation?: Resolver<Maybe<ResolversTypes['obligation']>, ParentType, ContextType>;
  source_risk?: Resolver<Maybe<ResolversTypes['risk']>, ParentType, ContextType>;
  source_third_party?: Resolver<Maybe<ResolversTypes['third_party']>, ParentType, ContextType>;
  target_acceptance?: Resolver<Maybe<ResolversTypes['acceptance']>, ParentType, ContextType>;
  target_action?: Resolver<Maybe<ResolversTypes['action']>, ParentType, ContextType>;
  target_action_update?: Resolver<Maybe<ResolversTypes['action_update']>, ParentType, ContextType>;
  target_appetite?: Resolver<Maybe<ResolversTypes['appetite']>, ParentType, ContextType>;
  target_assessment?: Resolver<Maybe<ResolversTypes['assessment']>, ParentType, ContextType>;
  target_assessment_activity?: Resolver<Maybe<ResolversTypes['assessment_activity']>, ParentType, ContextType>;
  target_cause?: Resolver<Maybe<ResolversTypes['cause']>, ParentType, ContextType>;
  target_consequence?: Resolver<Maybe<ResolversTypes['consequence']>, ParentType, ContextType>;
  target_control?: Resolver<Maybe<ResolversTypes['control']>, ParentType, ContextType>;
  target_control_group?: Resolver<Maybe<ResolversTypes['control_group']>, ParentType, ContextType>;
  target_document?: Resolver<Maybe<ResolversTypes['document']>, ParentType, ContextType>;
  target_impact?: Resolver<Maybe<ResolversTypes['impact']>, ParentType, ContextType>;
  target_impact_rating?: Resolver<Maybe<ResolversTypes['impact_rating']>, ParentType, ContextType>;
  target_indicator?: Resolver<Maybe<ResolversTypes['indicator']>, ParentType, ContextType>;
  target_internal_audit_entity?: Resolver<Maybe<ResolversTypes['internal_audit_entity']>, ParentType, ContextType>;
  target_internal_audit_report?: Resolver<Maybe<ResolversTypes['internal_audit_report']>, ParentType, ContextType>;
  target_issue?: Resolver<Maybe<ResolversTypes['issue']>, ParentType, ContextType>;
  target_issue_update?: Resolver<Maybe<ResolversTypes['issue_update']>, ParentType, ContextType>;
  target_obligation?: Resolver<Maybe<ResolversTypes['obligation']>, ParentType, ContextType>;
  target_obligation_impact?: Resolver<Maybe<ResolversTypes['obligation_impact']>, ParentType, ContextType>;
  target_risk?: Resolver<Maybe<ResolversTypes['risk']>, ParentType, ContextType>;
  target_test_result?: Resolver<Maybe<ResolversTypes['test_result']>, ParentType, ContextType>;
  target_third_party?: Resolver<Maybe<ResolversTypes['third_party']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Linked_Item_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['linked_item_mutation_response'] = ResolversParentTypes['linked_item_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['linked_item']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mutation_RootResolvers<ContextType = any, ParentType extends ResolversParentTypes['mutation_root'] = ResolversParentTypes['mutation_root']> = {
  attestRecord?: Resolver<ResolversTypes['GenericMutationOutput'], ParentType, ContextType, RequireFields<Mutation_RootAttestRecordArgs, 'Id'>>;
  dataImportStartImport?: Resolver<Maybe<ResolversTypes['DataImportStartImportOutput']>, ParentType, ContextType, RequireFields<Mutation_RootDataImportStartImportArgs, 'Id'>>;
  dataImportValidate?: Resolver<Maybe<ResolversTypes['DataImportValidateOutput']>, ParentType, ContextType, RequireFields<Mutation_RootDataImportValidateArgs, 'Id'>>;
  deleteAcceptancesById?: Resolver<ResolversTypes['GenericMutationOutput'], ParentType, ContextType, RequireFields<Mutation_RootDeleteAcceptancesByIdArgs, 'Ids'>>;
  deleteActionsById?: Resolver<ResolversTypes['GenericMutationOutput'], ParentType, ContextType, RequireFields<Mutation_RootDeleteActionsByIdArgs, 'Ids'>>;
  deleteControlsById?: Resolver<ResolversTypes['GenericMutationOutput'], ParentType, ContextType, RequireFields<Mutation_RootDeleteControlsByIdArgs, 'Ids'>>;
  deleteDocumentById?: Resolver<ResolversTypes['GenericMutationOutput'], ParentType, ContextType, RequireFields<Mutation_RootDeleteDocumentByIdArgs, 'Id'>>;
  deleteIssuesById?: Resolver<ResolversTypes['GenericMutationOutput'], ParentType, ContextType, RequireFields<Mutation_RootDeleteIssuesByIdArgs, 'Ids'>>;
  deleteRiskById?: Resolver<ResolversTypes['GenericMutationOutput'], ParentType, ContextType, RequireFields<Mutation_RootDeleteRiskByIdArgs, 'Id'>>;
  deleteUserGroups?: Resolver<ResolversTypes['GenericMutationOutput'], ParentType, ContextType, RequireFields<Mutation_RootDeleteUserGroupsArgs, 'Ids'>>;
  delete_acceptance_parent?: Resolver<Maybe<ResolversTypes['acceptance_parent_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Acceptance_ParentArgs, 'where'>>;
  delete_acceptance_parent_by_pk?: Resolver<Maybe<ResolversTypes['acceptance_parent']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Acceptance_Parent_By_PkArgs, 'Id' | 'ParentId'>>;
  delete_action_parent?: Resolver<Maybe<ResolversTypes['action_parent_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Action_ParentArgs, 'where'>>;
  delete_action_parent_by_pk?: Resolver<Maybe<ResolversTypes['action_parent']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Action_Parent_By_PkArgs, 'ActionId' | 'ParentId'>>;
  delete_action_update?: Resolver<Maybe<ResolversTypes['action_update_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Action_UpdateArgs, 'where'>>;
  delete_action_update_by_pk?: Resolver<Maybe<ResolversTypes['action_update']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Action_Update_By_PkArgs, 'Id'>>;
  delete_appetite?: Resolver<Maybe<ResolversTypes['appetite_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_AppetiteArgs, 'where'>>;
  delete_appetite_by_pk?: Resolver<Maybe<ResolversTypes['appetite']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Appetite_By_PkArgs, 'Id'>>;
  delete_appetite_parent?: Resolver<Maybe<ResolversTypes['appetite_parent_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Appetite_ParentArgs, 'where'>>;
  delete_appetite_parent_by_pk?: Resolver<Maybe<ResolversTypes['appetite_parent']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Appetite_Parent_By_PkArgs, 'Id' | 'ParentId'>>;
  delete_approval?: Resolver<Maybe<ResolversTypes['approval_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ApprovalArgs, 'where'>>;
  delete_approval_by_pk?: Resolver<Maybe<ResolversTypes['approval']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Approval_By_PkArgs, 'Id'>>;
  delete_approval_level?: Resolver<Maybe<ResolversTypes['approval_level_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Approval_LevelArgs, 'where'>>;
  delete_approval_level_by_pk?: Resolver<Maybe<ResolversTypes['approval_level']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Approval_Level_By_PkArgs, 'Id'>>;
  delete_approver?: Resolver<Maybe<ResolversTypes['approver_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ApproverArgs, 'where'>>;
  delete_approver_by_pk?: Resolver<Maybe<ResolversTypes['approver']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Approver_By_PkArgs, 'Id'>>;
  delete_assessment?: Resolver<Maybe<ResolversTypes['assessment_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_AssessmentArgs, 'where'>>;
  delete_assessment_activity?: Resolver<Maybe<ResolversTypes['assessment_activity_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Assessment_ActivityArgs, 'where'>>;
  delete_assessment_activity_by_pk?: Resolver<Maybe<ResolversTypes['assessment_activity']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Assessment_Activity_By_PkArgs, 'Id'>>;
  delete_assessment_by_pk?: Resolver<Maybe<ResolversTypes['assessment']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Assessment_By_PkArgs, 'Id'>>;
  delete_assessment_result_parent?: Resolver<Maybe<ResolversTypes['assessment_result_parent_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Assessment_Result_ParentArgs, 'where'>>;
  delete_assessment_result_parent_by_pk?: Resolver<Maybe<ResolversTypes['assessment_result_parent']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Assessment_Result_Parent_By_PkArgs, 'Id' | 'ParentId'>>;
  delete_attestation_config?: Resolver<Maybe<ResolversTypes['attestation_config_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Attestation_ConfigArgs, 'where'>>;
  delete_attestation_config_by_pk?: Resolver<Maybe<ResolversTypes['attestation_config']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Attestation_Config_By_PkArgs, 'ParentId'>>;
  delete_attestation_group?: Resolver<Maybe<ResolversTypes['attestation_group_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Attestation_GroupArgs, 'where'>>;
  delete_attestation_group_by_pk?: Resolver<Maybe<ResolversTypes['attestation_group']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Attestation_Group_By_PkArgs, 'ConfigId' | 'GroupId'>>;
  delete_business_area?: Resolver<Maybe<ResolversTypes['business_area_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Business_AreaArgs, 'where'>>;
  delete_business_area_by_pk?: Resolver<Maybe<ResolversTypes['business_area']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Business_Area_By_PkArgs, 'Id'>>;
  delete_cause?: Resolver<Maybe<ResolversTypes['cause_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_CauseArgs, 'where'>>;
  delete_cause_by_pk?: Resolver<Maybe<ResolversTypes['cause']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Cause_By_PkArgs, 'Id'>>;
  delete_comment?: Resolver<Maybe<ResolversTypes['comment_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_CommentArgs, 'where'>>;
  delete_comment_by_pk?: Resolver<Maybe<ResolversTypes['comment']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Comment_By_PkArgs, 'Id'>>;
  delete_compliance_monitoring_assessment?: Resolver<Maybe<ResolversTypes['compliance_monitoring_assessment_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Compliance_Monitoring_AssessmentArgs, 'where'>>;
  delete_compliance_monitoring_assessment_by_pk?: Resolver<Maybe<ResolversTypes['compliance_monitoring_assessment']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Compliance_Monitoring_Assessment_By_PkArgs, 'Id'>>;
  delete_consequence?: Resolver<Maybe<ResolversTypes['consequence_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ConsequenceArgs, 'where'>>;
  delete_consequence_by_pk?: Resolver<Maybe<ResolversTypes['consequence']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Consequence_By_PkArgs, 'Id'>>;
  delete_contributor?: Resolver<Maybe<ResolversTypes['contributor_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ContributorArgs, 'where'>>;
  delete_contributor_by_pk?: Resolver<Maybe<ResolversTypes['contributor']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Contributor_By_PkArgs, 'ParentId' | 'UserId'>>;
  delete_contributor_group?: Resolver<Maybe<ResolversTypes['contributor_group_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Contributor_GroupArgs, 'where'>>;
  delete_contributor_group_by_pk?: Resolver<Maybe<ResolversTypes['contributor_group']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Contributor_Group_By_PkArgs, 'ParentId' | 'UserGroupId'>>;
  delete_control_group?: Resolver<Maybe<ResolversTypes['control_group_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Control_GroupArgs, 'where'>>;
  delete_control_group_by_pk?: Resolver<Maybe<ResolversTypes['control_group']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Control_Group_By_PkArgs, 'Id'>>;
  delete_control_parent?: Resolver<Maybe<ResolversTypes['control_parent_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Control_ParentArgs, 'where'>>;
  delete_control_parent_by_pk?: Resolver<Maybe<ResolversTypes['control_parent']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Control_Parent_By_PkArgs, 'ControlId' | 'ParentId'>>;
  delete_conversation?: Resolver<Maybe<ResolversTypes['conversation_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ConversationArgs, 'where'>>;
  delete_conversation_by_pk?: Resolver<Maybe<ResolversTypes['conversation']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Conversation_By_PkArgs, 'Id'>>;
  delete_custom_attribute_schema?: Resolver<Maybe<ResolversTypes['custom_attribute_schema_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Custom_Attribute_SchemaArgs, 'where'>>;
  delete_custom_attribute_schema_by_pk?: Resolver<Maybe<ResolversTypes['custom_attribute_schema']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Custom_Attribute_Schema_By_PkArgs, 'Id'>>;
  delete_dashboard?: Resolver<Maybe<ResolversTypes['dashboard_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_DashboardArgs, 'where'>>;
  delete_dashboard_by_pk?: Resolver<Maybe<ResolversTypes['dashboard']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Dashboard_By_PkArgs, 'Id'>>;
  delete_data_import?: Resolver<Maybe<ResolversTypes['data_import_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Data_ImportArgs, 'where'>>;
  delete_data_import_by_pk?: Resolver<Maybe<ResolversTypes['data_import']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Data_Import_By_PkArgs, 'Id'>>;
  delete_data_import_error?: Resolver<Maybe<ResolversTypes['data_import_error_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Data_Import_ErrorArgs, 'where'>>;
  delete_data_import_error_by_pk?: Resolver<Maybe<ResolversTypes['data_import_error']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Data_Import_Error_By_PkArgs, 'Id'>>;
  delete_department?: Resolver<Maybe<ResolversTypes['department_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_DepartmentArgs, 'where'>>;
  delete_department_by_pk?: Resolver<Maybe<ResolversTypes['department']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Department_By_PkArgs, 'DepartmentTypeId' | 'ParentId'>>;
  delete_department_type?: Resolver<Maybe<ResolversTypes['department_type_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Department_TypeArgs, 'where'>>;
  delete_department_type_by_pk?: Resolver<Maybe<ResolversTypes['department_type']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Department_Type_By_PkArgs, 'DepartmentTypeId'>>;
  delete_document_assessment_result?: Resolver<Maybe<ResolversTypes['document_assessment_result_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Document_Assessment_ResultArgs, 'where'>>;
  delete_document_assessment_result_by_pk?: Resolver<Maybe<ResolversTypes['document_assessment_result']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Document_Assessment_Result_By_PkArgs, 'Id'>>;
  delete_document_file?: Resolver<Maybe<ResolversTypes['document_file_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Document_FileArgs, 'where'>>;
  delete_document_file_by_pk?: Resolver<Maybe<ResolversTypes['document_file']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Document_File_By_PkArgs, 'Id'>>;
  delete_document_linked_document?: Resolver<Maybe<ResolversTypes['document_linked_document_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Document_Linked_DocumentArgs, 'where'>>;
  delete_document_linked_document_by_pk?: Resolver<Maybe<ResolversTypes['document_linked_document']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Document_Linked_Document_By_PkArgs, 'DocumentId' | 'LinkedDocumentId'>>;
  delete_file?: Resolver<Maybe<ResolversTypes['file_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_FileArgs, 'where'>>;
  delete_file_by_pk?: Resolver<Maybe<ResolversTypes['file']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_File_By_PkArgs, 'Id'>>;
  delete_form_configuration?: Resolver<Maybe<ResolversTypes['form_configuration_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Form_ConfigurationArgs, 'where'>>;
  delete_form_field_configuration?: Resolver<Maybe<ResolversTypes['form_field_configuration_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Form_Field_ConfigurationArgs, 'where'>>;
  delete_form_field_ordering?: Resolver<Maybe<ResolversTypes['form_field_ordering_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Form_Field_OrderingArgs, 'where'>>;
  delete_impact?: Resolver<Maybe<ResolversTypes['impact_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ImpactArgs, 'where'>>;
  delete_impact_by_pk?: Resolver<Maybe<ResolversTypes['impact']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Impact_By_PkArgs, 'Id'>>;
  delete_impact_rating?: Resolver<Maybe<ResolversTypes['impact_rating_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Impact_RatingArgs, 'where'>>;
  delete_impact_rating_by_pk?: Resolver<Maybe<ResolversTypes['impact_rating']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Impact_Rating_By_PkArgs, 'Id'>>;
  delete_indicator?: Resolver<Maybe<ResolversTypes['indicator_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_IndicatorArgs, 'where'>>;
  delete_indicator_by_pk?: Resolver<Maybe<ResolversTypes['indicator']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Indicator_By_PkArgs, 'Id'>>;
  delete_indicator_parent?: Resolver<Maybe<ResolversTypes['indicator_parent_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Indicator_ParentArgs, 'where'>>;
  delete_indicator_parent_by_pk?: Resolver<Maybe<ResolversTypes['indicator_parent']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Indicator_Parent_By_PkArgs, 'IndicatorId' | 'ParentId'>>;
  delete_indicator_result?: Resolver<Maybe<ResolversTypes['indicator_result_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Indicator_ResultArgs, 'where'>>;
  delete_indicator_result_by_pk?: Resolver<Maybe<ResolversTypes['indicator_result']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Indicator_Result_By_PkArgs, 'Id'>>;
  delete_internal_audit_entity?: Resolver<Maybe<ResolversTypes['internal_audit_entity_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Internal_Audit_EntityArgs, 'where'>>;
  delete_internal_audit_entity_by_pk?: Resolver<Maybe<ResolversTypes['internal_audit_entity']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Internal_Audit_Entity_By_PkArgs, 'Id'>>;
  delete_internal_audit_report?: Resolver<Maybe<ResolversTypes['internal_audit_report_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Internal_Audit_ReportArgs, 'where'>>;
  delete_internal_audit_report_by_pk?: Resolver<Maybe<ResolversTypes['internal_audit_report']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Internal_Audit_Report_By_PkArgs, 'Id'>>;
  delete_issue_update?: Resolver<Maybe<ResolversTypes['issue_update_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Issue_UpdateArgs, 'where'>>;
  delete_issue_update_by_pk?: Resolver<Maybe<ResolversTypes['issue_update']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Issue_Update_By_PkArgs, 'Id'>>;
  delete_linked_item?: Resolver<Maybe<ResolversTypes['linked_item_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Linked_ItemArgs, 'where'>>;
  delete_linked_item_by_pk?: Resolver<Maybe<ResolversTypes['linked_item']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Linked_Item_By_PkArgs, 'Source' | 'Target'>>;
  delete_obligation?: Resolver<Maybe<ResolversTypes['obligation_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_ObligationArgs, 'where'>>;
  delete_obligation_assessment_result?: Resolver<Maybe<ResolversTypes['obligation_assessment_result_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Obligation_Assessment_ResultArgs, 'where'>>;
  delete_obligation_assessment_result_by_pk?: Resolver<Maybe<ResolversTypes['obligation_assessment_result']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Obligation_Assessment_Result_By_PkArgs, 'Id'>>;
  delete_obligation_by_pk?: Resolver<Maybe<ResolversTypes['obligation']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Obligation_By_PkArgs, 'Id'>>;
  delete_obligation_impact?: Resolver<Maybe<ResolversTypes['obligation_impact_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Obligation_ImpactArgs, 'where'>>;
  delete_obligation_impact_by_pk?: Resolver<Maybe<ResolversTypes['obligation_impact']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Obligation_Impact_By_PkArgs, 'Id'>>;
  delete_owner?: Resolver<Maybe<ResolversTypes['owner_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_OwnerArgs, 'where'>>;
  delete_owner_by_pk?: Resolver<Maybe<ResolversTypes['owner']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Owner_By_PkArgs, 'ParentId' | 'UserId'>>;
  delete_owner_group?: Resolver<Maybe<ResolversTypes['owner_group_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Owner_GroupArgs, 'where'>>;
  delete_owner_group_by_pk?: Resolver<Maybe<ResolversTypes['owner_group']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Owner_Group_By_PkArgs, 'ParentId' | 'UserGroupId'>>;
  delete_questionnaire_invite?: Resolver<Maybe<ResolversTypes['questionnaire_invite_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Questionnaire_InviteArgs, 'where'>>;
  delete_questionnaire_invite_by_pk?: Resolver<Maybe<ResolversTypes['questionnaire_invite']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Questionnaire_Invite_By_PkArgs, 'Id'>>;
  delete_questionnaire_template?: Resolver<Maybe<ResolversTypes['questionnaire_template_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Questionnaire_TemplateArgs, 'where'>>;
  delete_questionnaire_template_by_pk?: Resolver<Maybe<ResolversTypes['questionnaire_template']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Questionnaire_Template_By_PkArgs, 'Id'>>;
  delete_questionnaire_template_version?: Resolver<Maybe<ResolversTypes['questionnaire_template_version_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Questionnaire_Template_VersionArgs, 'where'>>;
  delete_questionnaire_template_version_by_pk?: Resolver<Maybe<ResolversTypes['questionnaire_template_version']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Questionnaire_Template_Version_By_PkArgs, 'Id'>>;
  delete_relation_file?: Resolver<Maybe<ResolversTypes['relation_file_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Relation_FileArgs, 'where'>>;
  delete_relation_file_by_pk?: Resolver<Maybe<ResolversTypes['relation_file']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Relation_File_By_PkArgs, 'FileId' | 'ParentId'>>;
  delete_risk_assessment_result?: Resolver<Maybe<ResolversTypes['risk_assessment_result_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Risk_Assessment_ResultArgs, 'where'>>;
  delete_risk_assessment_result_by_pk?: Resolver<Maybe<ResolversTypes['risk_assessment_result']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Risk_Assessment_Result_By_PkArgs, 'Id'>>;
  delete_tag?: Resolver<Maybe<ResolversTypes['tag_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_TagArgs, 'where'>>;
  delete_tag_by_pk?: Resolver<Maybe<ResolversTypes['tag']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Tag_By_PkArgs, 'ParentId' | 'TagTypeId'>>;
  delete_tag_type?: Resolver<Maybe<ResolversTypes['tag_type_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Tag_TypeArgs, 'where'>>;
  delete_tag_type_by_pk?: Resolver<Maybe<ResolversTypes['tag_type']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Tag_Type_By_PkArgs, 'TagTypeId'>>;
  delete_taxonomy?: Resolver<Maybe<ResolversTypes['taxonomy_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_TaxonomyArgs, 'where'>>;
  delete_taxonomy_by_pk?: Resolver<Maybe<ResolversTypes['taxonomy']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Taxonomy_By_PkArgs, 'Id'>>;
  delete_taxonomy_org?: Resolver<Maybe<ResolversTypes['taxonomy_org_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Taxonomy_OrgArgs, 'where'>>;
  delete_taxonomy_org_by_pk?: Resolver<Maybe<ResolversTypes['taxonomy_org']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Taxonomy_Org_By_PkArgs, 'Id'>>;
  delete_test_result?: Resolver<Maybe<ResolversTypes['test_result_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Test_ResultArgs, 'where'>>;
  delete_test_result_by_pk?: Resolver<Maybe<ResolversTypes['test_result']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Test_Result_By_PkArgs, 'Id'>>;
  delete_third_party?: Resolver<Maybe<ResolversTypes['third_party_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Third_PartyArgs, 'where'>>;
  delete_third_party_by_pk?: Resolver<Maybe<ResolversTypes['third_party']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Third_Party_By_PkArgs, 'Id'>>;
  delete_third_party_response?: Resolver<Maybe<ResolversTypes['third_party_response_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Third_Party_ResponseArgs, 'where'>>;
  delete_third_party_response_by_pk?: Resolver<Maybe<ResolversTypes['third_party_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_Third_Party_Response_By_PkArgs, 'Id'>>;
  delete_user_group_user?: Resolver<Maybe<ResolversTypes['user_group_user_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_User_Group_UserArgs, 'where'>>;
  delete_user_group_user_by_pk?: Resolver<Maybe<ResolversTypes['user_group_user']>, ParentType, ContextType, RequireFields<Mutation_RootDelete_User_Group_User_By_PkArgs, 'UserGroupId' | 'UserId'>>;
  disconnectSlack?: Resolver<ResolversTypes['DisconnectSlackOutput'], ParentType, ContextType>;
  insertAssessmentActivityWithLinkedItems?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, RequireFields<Mutation_RootInsertAssessmentActivityWithLinkedItemsArgs, 'ActivityType' | 'LinkedItemIds' | 'ParentId' | 'Status' | 'Summary' | 'Title'>>;
  insertChildAcceptance?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, RequireFields<Mutation_RootInsertChildAcceptanceArgs, 'DateAcceptedFrom' | 'DateAcceptedTo' | 'ParentId'>>;
  insertChildAction?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, RequireFields<Mutation_RootInsertChildActionArgs, 'ContributorGroupIds' | 'ContributorUserIds' | 'DateDue' | 'DateRaised' | 'DepartmentTypeIds' | 'OwnerGroupIds' | 'OwnerUserIds' | 'Status' | 'TagTypeIds' | 'Title'>>;
  insertChildAppetite?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, RequireFields<Mutation_RootInsertChildAppetiteArgs, 'ParentIds'>>;
  insertChildControl?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, Partial<Mutation_RootInsertChildControlArgs>>;
  insertChildDashboard?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, RequireFields<Mutation_RootInsertChildDashboardArgs, 'Content' | 'ContributorGroupIds' | 'ContributorUserIds' | 'Name' | 'Sharing'>>;
  insertChildDocument?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, Partial<Mutation_RootInsertChildDocumentArgs>>;
  insertChildDocumentAssessmentResult?: Resolver<Maybe<ResolversTypes['InsertChildDocumentAssessmentResultOutput']>, ParentType, ContextType, RequireFields<Mutation_RootInsertChildDocumentAssessmentResultArgs, 'DocumentIds'>>;
  insertChildImpact?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, RequireFields<Mutation_RootInsertChildImpactArgs, 'Name' | 'OwnerGroupIds' | 'OwnerUserIds'>>;
  insertChildImpactRating?: Resolver<Maybe<ResolversTypes['insertChildImpactRatingOutput']>, ParentType, ContextType, RequireFields<Mutation_RootInsertChildImpactRatingArgs, 'RatedItemId' | 'Ratings' | 'TestDate'>>;
  insertChildIndicator?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, Partial<Mutation_RootInsertChildIndicatorArgs>>;
  insertChildIssue?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, RequireFields<Mutation_RootInsertChildIssueArgs, 'ContributorGroupIds' | 'ContributorUserIds' | 'DateIdentified' | 'DateOccurred' | 'DepartmentTypeIds' | 'Details' | 'OwnerGroupIds' | 'OwnerUserIds' | 'TagTypeIds' | 'Title'>>;
  insertChildIssueAssessment?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, RequireFields<Mutation_RootInsertChildIssueAssessmentArgs, 'AssociatedControlIds' | 'DepartmentTypeIds' | 'ParentIssueId' | 'PoliciesBreachedIds' | 'RegulationsBreachedIds' | 'TagTypeIds'>>;
  insertChildObligation?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, Partial<Mutation_RootInsertChildObligationArgs>>;
  insertChildObligationAssessmentResult?: Resolver<Maybe<ResolversTypes['InsertChildObligationAssessmentResultOutput']>, ParentType, ContextType, RequireFields<Mutation_RootInsertChildObligationAssessmentResultArgs, 'ObligationIds'>>;
  insertChildRisk?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, Partial<Mutation_RootInsertChildRiskArgs>>;
  insertChildRiskAssessmentResult?: Resolver<Maybe<ResolversTypes['InsertChildRiskAssessmentResultOutput']>, ParentType, ContextType, RequireFields<Mutation_RootInsertChildRiskAssessmentResultArgs, 'RiskIds'>>;
  insertControlTestResult?: Resolver<Maybe<ResolversTypes['insertControlTestResultOutput']>, ParentType, ContextType, RequireFields<Mutation_RootInsertControlTestResultArgs, 'ControlIds'>>;
  insertDocumentVersion?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, RequireFields<Mutation_RootInsertDocumentVersionArgs, 'ParentDocumentId'>>;
  insertInternalAudit?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, Partial<Mutation_RootInsertInternalAuditArgs>>;
  insertQuestionnaireInvites?: Resolver<ResolversTypes['GenericMutationOutput'], ParentType, ContextType, RequireFields<Mutation_RootInsertQuestionnaireInvitesArgs, 'QuestionnaireTemplateVersionIds' | 'ThirdPartyId' | 'UserEmails'>>;
  insertReferenceUser?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, RequireFields<Mutation_RootInsertReferenceUserArgs, 'Email'>>;
  insert_action_update?: Resolver<Maybe<ResolversTypes['action_update_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Action_UpdateArgs, 'objects'>>;
  insert_action_update_one?: Resolver<Maybe<ResolversTypes['action_update']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Action_Update_OneArgs, 'object'>>;
  insert_approval?: Resolver<Maybe<ResolversTypes['approval_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ApprovalArgs, 'objects'>>;
  insert_approval_level?: Resolver<Maybe<ResolversTypes['approval_level_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Approval_LevelArgs, 'objects'>>;
  insert_approval_level_one?: Resolver<Maybe<ResolversTypes['approval_level']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Approval_Level_OneArgs, 'object'>>;
  insert_approval_one?: Resolver<Maybe<ResolversTypes['approval']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Approval_OneArgs, 'object'>>;
  insert_approver?: Resolver<Maybe<ResolversTypes['approver_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ApproverArgs, 'objects'>>;
  insert_approver_one?: Resolver<Maybe<ResolversTypes['approver']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Approver_OneArgs, 'object'>>;
  insert_assessment?: Resolver<Maybe<ResolversTypes['assessment_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_AssessmentArgs, 'objects'>>;
  insert_assessment_activity?: Resolver<Maybe<ResolversTypes['assessment_activity_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Assessment_ActivityArgs, 'objects'>>;
  insert_assessment_activity_one?: Resolver<Maybe<ResolversTypes['assessment_activity']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Assessment_Activity_OneArgs, 'object'>>;
  insert_assessment_one?: Resolver<Maybe<ResolversTypes['assessment']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Assessment_OneArgs, 'object'>>;
  insert_attestation_config?: Resolver<Maybe<ResolversTypes['attestation_config_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Attestation_ConfigArgs, 'objects'>>;
  insert_attestation_config_one?: Resolver<Maybe<ResolversTypes['attestation_config']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Attestation_Config_OneArgs, 'object'>>;
  insert_attestation_group?: Resolver<Maybe<ResolversTypes['attestation_group_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Attestation_GroupArgs, 'objects'>>;
  insert_attestation_group_one?: Resolver<Maybe<ResolversTypes['attestation_group']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Attestation_Group_OneArgs, 'object'>>;
  insert_business_area?: Resolver<Maybe<ResolversTypes['business_area_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Business_AreaArgs, 'objects'>>;
  insert_business_area_one?: Resolver<Maybe<ResolversTypes['business_area']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Business_Area_OneArgs, 'object'>>;
  insert_cause?: Resolver<Maybe<ResolversTypes['cause_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_CauseArgs, 'objects'>>;
  insert_cause_one?: Resolver<Maybe<ResolversTypes['cause']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Cause_OneArgs, 'object'>>;
  insert_comment?: Resolver<Maybe<ResolversTypes['comment_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_CommentArgs, 'objects'>>;
  insert_comment_one?: Resolver<Maybe<ResolversTypes['comment']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Comment_OneArgs, 'object'>>;
  insert_compliance_monitoring_assessment?: Resolver<Maybe<ResolversTypes['compliance_monitoring_assessment_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Compliance_Monitoring_AssessmentArgs, 'objects'>>;
  insert_compliance_monitoring_assessment_one?: Resolver<Maybe<ResolversTypes['compliance_monitoring_assessment']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Compliance_Monitoring_Assessment_OneArgs, 'object'>>;
  insert_consequence?: Resolver<Maybe<ResolversTypes['consequence_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ConsequenceArgs, 'objects'>>;
  insert_consequence_one?: Resolver<Maybe<ResolversTypes['consequence']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Consequence_OneArgs, 'object'>>;
  insert_contributor?: Resolver<Maybe<ResolversTypes['contributor_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ContributorArgs, 'objects'>>;
  insert_contributor_group?: Resolver<Maybe<ResolversTypes['contributor_group_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Contributor_GroupArgs, 'objects'>>;
  insert_contributor_group_one?: Resolver<Maybe<ResolversTypes['contributor_group']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Contributor_Group_OneArgs, 'object'>>;
  insert_contributor_one?: Resolver<Maybe<ResolversTypes['contributor']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Contributor_OneArgs, 'object'>>;
  insert_control?: Resolver<Maybe<ResolversTypes['control_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ControlArgs, 'objects'>>;
  insert_control_group?: Resolver<Maybe<ResolversTypes['control_group_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Control_GroupArgs, 'objects'>>;
  insert_control_group_one?: Resolver<Maybe<ResolversTypes['control_group']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Control_Group_OneArgs, 'object'>>;
  insert_control_one?: Resolver<Maybe<ResolversTypes['control']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Control_OneArgs, 'object'>>;
  insert_control_parent?: Resolver<Maybe<ResolversTypes['control_parent_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Control_ParentArgs, 'objects'>>;
  insert_control_parent_one?: Resolver<Maybe<ResolversTypes['control_parent']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Control_Parent_OneArgs, 'object'>>;
  insert_conversation?: Resolver<Maybe<ResolversTypes['conversation_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_ConversationArgs, 'objects'>>;
  insert_conversation_one?: Resolver<Maybe<ResolversTypes['conversation']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Conversation_OneArgs, 'object'>>;
  insert_custom_attribute_schema?: Resolver<Maybe<ResolversTypes['custom_attribute_schema_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Custom_Attribute_SchemaArgs, 'objects'>>;
  insert_custom_attribute_schema_one?: Resolver<Maybe<ResolversTypes['custom_attribute_schema']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Custom_Attribute_Schema_OneArgs, 'object'>>;
  insert_custom_ribbon?: Resolver<Maybe<ResolversTypes['custom_ribbon_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Custom_RibbonArgs, 'objects'>>;
  insert_custom_ribbon_one?: Resolver<Maybe<ResolversTypes['custom_ribbon']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Custom_Ribbon_OneArgs, 'object'>>;
  insert_data_import?: Resolver<Maybe<ResolversTypes['data_import_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Data_ImportArgs, 'objects'>>;
  insert_data_import_error?: Resolver<Maybe<ResolversTypes['data_import_error_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Data_Import_ErrorArgs, 'objects'>>;
  insert_data_import_error_one?: Resolver<Maybe<ResolversTypes['data_import_error']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Data_Import_Error_OneArgs, 'object'>>;
  insert_data_import_one?: Resolver<Maybe<ResolversTypes['data_import']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Data_Import_OneArgs, 'object'>>;
  insert_department?: Resolver<Maybe<ResolversTypes['department_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_DepartmentArgs, 'objects'>>;
  insert_department_one?: Resolver<Maybe<ResolversTypes['department']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Department_OneArgs, 'object'>>;
  insert_department_type?: Resolver<Maybe<ResolversTypes['department_type_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Department_TypeArgs, 'objects'>>;
  insert_department_type_group?: Resolver<Maybe<ResolversTypes['department_type_group_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Department_Type_GroupArgs, 'objects'>>;
  insert_department_type_group_one?: Resolver<Maybe<ResolversTypes['department_type_group']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Department_Type_Group_OneArgs, 'object'>>;
  insert_department_type_one?: Resolver<Maybe<ResolversTypes['department_type']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Department_Type_OneArgs, 'object'>>;
  insert_document_linked_document?: Resolver<Maybe<ResolversTypes['document_linked_document_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Document_Linked_DocumentArgs, 'objects'>>;
  insert_document_linked_document_one?: Resolver<Maybe<ResolversTypes['document_linked_document']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Document_Linked_Document_OneArgs, 'object'>>;
  insert_file?: Resolver<Maybe<ResolversTypes['file_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_FileArgs, 'objects'>>;
  insert_file_one?: Resolver<Maybe<ResolversTypes['file']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_File_OneArgs, 'object'>>;
  insert_form_configuration?: Resolver<Maybe<ResolversTypes['form_configuration_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Form_ConfigurationArgs, 'objects'>>;
  insert_form_configuration_one?: Resolver<Maybe<ResolversTypes['form_configuration']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Form_Configuration_OneArgs, 'object'>>;
  insert_form_field_configuration?: Resolver<Maybe<ResolversTypes['form_field_configuration_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Form_Field_ConfigurationArgs, 'objects'>>;
  insert_form_field_configuration_one?: Resolver<Maybe<ResolversTypes['form_field_configuration']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Form_Field_Configuration_OneArgs, 'object'>>;
  insert_form_field_ordering?: Resolver<Maybe<ResolversTypes['form_field_ordering_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Form_Field_OrderingArgs, 'objects'>>;
  insert_form_field_ordering_one?: Resolver<Maybe<ResolversTypes['form_field_ordering']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Form_Field_Ordering_OneArgs, 'object'>>;
  insert_indicator_parent?: Resolver<Maybe<ResolversTypes['indicator_parent_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Indicator_ParentArgs, 'objects'>>;
  insert_indicator_parent_one?: Resolver<Maybe<ResolversTypes['indicator_parent']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Indicator_Parent_OneArgs, 'object'>>;
  insert_indicator_result?: Resolver<Maybe<ResolversTypes['indicator_result_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Indicator_ResultArgs, 'objects'>>;
  insert_indicator_result_one?: Resolver<Maybe<ResolversTypes['indicator_result']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Indicator_Result_OneArgs, 'object'>>;
  insert_internal_audit_report?: Resolver<Maybe<ResolversTypes['internal_audit_report_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Internal_Audit_ReportArgs, 'objects'>>;
  insert_internal_audit_report_one?: Resolver<Maybe<ResolversTypes['internal_audit_report']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Internal_Audit_Report_OneArgs, 'object'>>;
  insert_issue?: Resolver<Maybe<ResolversTypes['issue_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_IssueArgs, 'objects'>>;
  insert_issue_one?: Resolver<Maybe<ResolversTypes['issue']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Issue_OneArgs, 'object'>>;
  insert_issue_update?: Resolver<Maybe<ResolversTypes['issue_update_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Issue_UpdateArgs, 'objects'>>;
  insert_issue_update_one?: Resolver<Maybe<ResolversTypes['issue_update']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Issue_Update_OneArgs, 'object'>>;
  insert_obligation_impact?: Resolver<Maybe<ResolversTypes['obligation_impact_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Obligation_ImpactArgs, 'objects'>>;
  insert_obligation_impact_one?: Resolver<Maybe<ResolversTypes['obligation_impact']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Obligation_Impact_OneArgs, 'object'>>;
  insert_owner?: Resolver<Maybe<ResolversTypes['owner_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_OwnerArgs, 'objects'>>;
  insert_owner_group?: Resolver<Maybe<ResolversTypes['owner_group_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Owner_GroupArgs, 'objects'>>;
  insert_owner_group_one?: Resolver<Maybe<ResolversTypes['owner_group']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Owner_Group_OneArgs, 'object'>>;
  insert_owner_one?: Resolver<Maybe<ResolversTypes['owner']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Owner_OneArgs, 'object'>>;
  insert_questionnaire_invite?: Resolver<Maybe<ResolversTypes['questionnaire_invite_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Questionnaire_InviteArgs, 'objects'>>;
  insert_questionnaire_invite_one?: Resolver<Maybe<ResolversTypes['questionnaire_invite']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Questionnaire_Invite_OneArgs, 'object'>>;
  insert_questionnaire_template?: Resolver<Maybe<ResolversTypes['questionnaire_template_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Questionnaire_TemplateArgs, 'objects'>>;
  insert_questionnaire_template_one?: Resolver<Maybe<ResolversTypes['questionnaire_template']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Questionnaire_Template_OneArgs, 'object'>>;
  insert_questionnaire_template_version?: Resolver<Maybe<ResolversTypes['questionnaire_template_version_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Questionnaire_Template_VersionArgs, 'objects'>>;
  insert_questionnaire_template_version_one?: Resolver<Maybe<ResolversTypes['questionnaire_template_version']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Questionnaire_Template_Version_OneArgs, 'object'>>;
  insert_relation_file?: Resolver<Maybe<ResolversTypes['relation_file_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Relation_FileArgs, 'objects'>>;
  insert_relation_file_one?: Resolver<Maybe<ResolversTypes['relation_file']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Relation_File_OneArgs, 'object'>>;
  insert_tag?: Resolver<Maybe<ResolversTypes['tag_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_TagArgs, 'objects'>>;
  insert_tag_one?: Resolver<Maybe<ResolversTypes['tag']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Tag_OneArgs, 'object'>>;
  insert_tag_type?: Resolver<Maybe<ResolversTypes['tag_type_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Tag_TypeArgs, 'objects'>>;
  insert_tag_type_group?: Resolver<Maybe<ResolversTypes['tag_type_group_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Tag_Type_GroupArgs, 'objects'>>;
  insert_tag_type_group_one?: Resolver<Maybe<ResolversTypes['tag_type_group']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Tag_Type_Group_OneArgs, 'object'>>;
  insert_tag_type_one?: Resolver<Maybe<ResolversTypes['tag_type']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Tag_Type_OneArgs, 'object'>>;
  insert_taxonomy?: Resolver<Maybe<ResolversTypes['taxonomy_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_TaxonomyArgs, 'objects'>>;
  insert_taxonomy_one?: Resolver<Maybe<ResolversTypes['taxonomy']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Taxonomy_OneArgs, 'object'>>;
  insert_taxonomy_org?: Resolver<Maybe<ResolversTypes['taxonomy_org_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Taxonomy_OrgArgs, 'objects'>>;
  insert_taxonomy_org_one?: Resolver<Maybe<ResolversTypes['taxonomy_org']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Taxonomy_Org_OneArgs, 'object'>>;
  insert_third_party?: Resolver<Maybe<ResolversTypes['third_party_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Third_PartyArgs, 'objects'>>;
  insert_third_party_one?: Resolver<Maybe<ResolversTypes['third_party']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Third_Party_OneArgs, 'object'>>;
  insert_third_party_response?: Resolver<Maybe<ResolversTypes['third_party_response_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Third_Party_ResponseArgs, 'objects'>>;
  insert_third_party_response_one?: Resolver<Maybe<ResolversTypes['third_party_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_Third_Party_Response_OneArgs, 'object'>>;
  insert_user_group?: Resolver<Maybe<ResolversTypes['user_group_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_User_GroupArgs, 'objects'>>;
  insert_user_group_one?: Resolver<Maybe<ResolversTypes['user_group']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_User_Group_OneArgs, 'object'>>;
  insert_user_group_user?: Resolver<Maybe<ResolversTypes['user_group_user_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_User_Group_UserArgs, 'objects'>>;
  insert_user_group_user_one?: Resolver<Maybe<ResolversTypes['user_group_user']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_User_Group_User_OneArgs, 'object'>>;
  insert_user_search_preferences?: Resolver<Maybe<ResolversTypes['user_search_preferences_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_User_Search_PreferencesArgs, 'objects'>>;
  insert_user_search_preferences_one?: Resolver<Maybe<ResolversTypes['user_search_preferences']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_User_Search_Preferences_OneArgs, 'object'>>;
  insert_user_table_preferences?: Resolver<Maybe<ResolversTypes['user_table_preferences_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_User_Table_PreferencesArgs, 'objects'>>;
  insert_user_table_preferences_one?: Resolver<Maybe<ResolversTypes['user_table_preferences']>, ParentType, ContextType, RequireFields<Mutation_RootInsert_User_Table_Preferences_OneArgs, 'object'>>;
  linkItems?: Resolver<Maybe<ResolversTypes['LinkItemsOutput']>, ParentType, ContextType, RequireFields<Mutation_RootLinkItemsArgs, 'Targets'>>;
  overrideChangeRequest?: Resolver<ResolversTypes['IdOutput'], ParentType, ContextType, RequireFields<Mutation_RootOverrideChangeRequestArgs, 'Approved' | 'Id' | 'Rationale'>>;
  publishQuestionnaireTemplateVersion?: Resolver<ResolversTypes['GenericMutationOutput'], ParentType, ContextType, RequireFields<Mutation_RootPublishQuestionnaireTemplateVersionArgs, 'QuestionnaireTemplateId' | 'QuestionnaireTemplateVersionId'>>;
  recalculateAppetites?: Resolver<ResolversTypes['RecalculateAppetitesOutput'], ParentType, ContextType>;
  recalculateRiskScores?: Resolver<ResolversTypes['RecalculateRiskScoresOutput'], ParentType, ContextType, Partial<Mutation_RootRecalculateRiskScoresArgs>>;
  recallQuestionnaireInvites?: Resolver<ResolversTypes['GenericMutationOutput'], ParentType, ContextType, RequireFields<Mutation_RootRecallQuestionnaireInvitesArgs, 'ThirdPartyId'>>;
  unlinkItems?: Resolver<Maybe<ResolversTypes['UnlinkItemsOutput']>, ParentType, ContextType, RequireFields<Mutation_RootUnlinkItemsArgs, 'Ids'>>;
  updateApproverResponses?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, RequireFields<Mutation_RootUpdateApproverResponsesArgs, 'input'>>;
  updateAssessmentActivityWithLinkedItems?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, RequireFields<Mutation_RootUpdateAssessmentActivityWithLinkedItemsArgs, 'ActivityType' | 'Id' | 'LinkedItemIds' | 'OriginalTimestamp' | 'ParentId' | 'Status' | 'Summary' | 'Title'>>;
  updateChildAcceptance?: Resolver<ResolversTypes['GenericMutationOutput'], ParentType, ContextType, RequireFields<Mutation_RootUpdateChildAcceptanceArgs, 'DateAcceptedFrom' | 'DateAcceptedTo' | 'Id' | 'LatestModifiedAtTimestamp'>>;
  updateChildAction?: Resolver<Maybe<ResolversTypes['GenericMutationOutput']>, ParentType, ContextType, RequireFields<Mutation_RootUpdateChildActionArgs, 'ContributorGroupIds' | 'ContributorUserIds' | 'DateDue' | 'DateRaised' | 'DepartmentTypeIds' | 'Id' | 'OriginalTimestamp' | 'OwnerGroupIds' | 'OwnerUserIds' | 'Status' | 'TagTypeIds' | 'Title'>>;
  updateChildControl?: Resolver<Maybe<ResolversTypes['GenericMutationOutput']>, ParentType, ContextType, Partial<Mutation_RootUpdateChildControlArgs>>;
  updateChildDashboard?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, RequireFields<Mutation_RootUpdateChildDashboardArgs, 'Content' | 'ContributorGroupIds' | 'ContributorUserIds' | 'Id' | 'Name' | 'Sharing'>>;
  updateChildDocument?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, Partial<Mutation_RootUpdateChildDocumentArgs>>;
  updateChildIndicator?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, Partial<Mutation_RootUpdateChildIndicatorArgs>>;
  updateChildIssueAssessment?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, RequireFields<Mutation_RootUpdateChildIssueAssessmentArgs, 'AssociatedControlIds' | 'DepartmentTypeIds' | 'Id' | 'OriginalTimestamp' | 'PoliciesBreachedIds' | 'RegulationsBreachedIds' | 'TagTypeIds'>>;
  updateChildObligation?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, Partial<Mutation_RootUpdateChildObligationArgs>>;
  updateChildRisk?: Resolver<ResolversTypes['IdOutput'], ParentType, ContextType, Partial<Mutation_RootUpdateChildRiskArgs>>;
  updateChildRiskAssessmentResult?: Resolver<Maybe<ResolversTypes['GenericMutationOutput']>, ParentType, ContextType, RequireFields<Mutation_RootUpdateChildRiskAssessmentResultArgs, 'Id'>>;
  updateDocumentVersion?: Resolver<Maybe<ResolversTypes['GenericMutationOutput']>, ParentType, ContextType, RequireFields<Mutation_RootUpdateDocumentVersionArgs, 'Id' | 'LatestModifiedAtTimestamp' | 'Type' | 'Version'>>;
  updateInternalAudit?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, Partial<Mutation_RootUpdateInternalAuditArgs>>;
  updateNotificationPreferences?: Resolver<ResolversTypes['UpdateNotificationPreferencesOutput'], ParentType, ContextType, RequireFields<Mutation_RootUpdateNotificationPreferencesArgs, 'preferenceSet'>>;
  updateTestResultApi?: Resolver<Maybe<ResolversTypes['IdOutput']>, ParentType, ContextType, Partial<Mutation_RootUpdateTestResultApiArgs>>;
  update_action_update?: Resolver<Maybe<ResolversTypes['action_update_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Action_UpdateArgs, 'where'>>;
  update_action_update_by_pk?: Resolver<Maybe<ResolversTypes['action_update']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Action_Update_By_PkArgs, 'pk_columns'>>;
  update_action_update_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['action_update_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Action_Update_ManyArgs, 'updates'>>;
  update_appetite?: Resolver<Maybe<ResolversTypes['appetite_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_AppetiteArgs, 'where'>>;
  update_appetite_by_pk?: Resolver<Maybe<ResolversTypes['appetite']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Appetite_By_PkArgs, 'pk_columns'>>;
  update_appetite_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['appetite_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Appetite_ManyArgs, 'updates'>>;
  update_approval?: Resolver<Maybe<ResolversTypes['approval_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ApprovalArgs, 'where'>>;
  update_approval_by_pk?: Resolver<Maybe<ResolversTypes['approval']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Approval_By_PkArgs, 'pk_columns'>>;
  update_approval_level?: Resolver<Maybe<ResolversTypes['approval_level_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Approval_LevelArgs, 'where'>>;
  update_approval_level_by_pk?: Resolver<Maybe<ResolversTypes['approval_level']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Approval_Level_By_PkArgs, 'pk_columns'>>;
  update_approval_level_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['approval_level_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Approval_Level_ManyArgs, 'updates'>>;
  update_approval_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['approval_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Approval_ManyArgs, 'updates'>>;
  update_approver?: Resolver<Maybe<ResolversTypes['approver_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ApproverArgs, 'where'>>;
  update_approver_by_pk?: Resolver<Maybe<ResolversTypes['approver']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Approver_By_PkArgs, 'pk_columns'>>;
  update_approver_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['approver_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Approver_ManyArgs, 'updates'>>;
  update_assessment?: Resolver<Maybe<ResolversTypes['assessment_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_AssessmentArgs, 'where'>>;
  update_assessment_activity?: Resolver<Maybe<ResolversTypes['assessment_activity_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Assessment_ActivityArgs, 'where'>>;
  update_assessment_activity_by_pk?: Resolver<Maybe<ResolversTypes['assessment_activity']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Assessment_Activity_By_PkArgs, 'pk_columns'>>;
  update_assessment_activity_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['assessment_activity_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Assessment_Activity_ManyArgs, 'updates'>>;
  update_assessment_by_pk?: Resolver<Maybe<ResolversTypes['assessment']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Assessment_By_PkArgs, 'pk_columns'>>;
  update_assessment_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['assessment_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Assessment_ManyArgs, 'updates'>>;
  update_attestation_config?: Resolver<Maybe<ResolversTypes['attestation_config_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Attestation_ConfigArgs, 'where'>>;
  update_attestation_config_by_pk?: Resolver<Maybe<ResolversTypes['attestation_config']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Attestation_Config_By_PkArgs, 'pk_columns'>>;
  update_attestation_config_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['attestation_config_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Attestation_Config_ManyArgs, 'updates'>>;
  update_attestation_group?: Resolver<Maybe<ResolversTypes['attestation_group_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Attestation_GroupArgs, 'where'>>;
  update_attestation_group_by_pk?: Resolver<Maybe<ResolversTypes['attestation_group']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Attestation_Group_By_PkArgs, 'pk_columns'>>;
  update_attestation_group_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['attestation_group_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Attestation_Group_ManyArgs, 'updates'>>;
  update_auth_organisationuser?: Resolver<Maybe<ResolversTypes['auth_organisationuser_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Auth_OrganisationuserArgs, 'where'>>;
  update_auth_organisationuser_by_pk?: Resolver<Maybe<ResolversTypes['auth_organisationuser']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Auth_Organisationuser_By_PkArgs, 'pk_columns'>>;
  update_auth_organisationuser_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['auth_organisationuser_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Auth_Organisationuser_ManyArgs, 'updates'>>;
  update_auth_user?: Resolver<Maybe<ResolversTypes['auth_user_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Auth_UserArgs, 'where'>>;
  update_auth_user_by_pk?: Resolver<Maybe<ResolversTypes['auth_user']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Auth_User_By_PkArgs, 'pk_columns'>>;
  update_auth_user_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['auth_user_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Auth_User_ManyArgs, 'updates'>>;
  update_business_area?: Resolver<Maybe<ResolversTypes['business_area_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Business_AreaArgs, 'where'>>;
  update_business_area_by_pk?: Resolver<Maybe<ResolversTypes['business_area']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Business_Area_By_PkArgs, 'pk_columns'>>;
  update_business_area_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['business_area_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Business_Area_ManyArgs, 'updates'>>;
  update_cause?: Resolver<Maybe<ResolversTypes['cause_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_CauseArgs, 'where'>>;
  update_cause_by_pk?: Resolver<Maybe<ResolversTypes['cause']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Cause_By_PkArgs, 'pk_columns'>>;
  update_cause_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['cause_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Cause_ManyArgs, 'updates'>>;
  update_comment?: Resolver<Maybe<ResolversTypes['comment_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_CommentArgs, 'where'>>;
  update_comment_by_pk?: Resolver<Maybe<ResolversTypes['comment']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Comment_By_PkArgs, 'pk_columns'>>;
  update_comment_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['comment_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Comment_ManyArgs, 'updates'>>;
  update_compliance_monitoring_assessment?: Resolver<Maybe<ResolversTypes['compliance_monitoring_assessment_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Compliance_Monitoring_AssessmentArgs, 'where'>>;
  update_compliance_monitoring_assessment_by_pk?: Resolver<Maybe<ResolversTypes['compliance_monitoring_assessment']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Compliance_Monitoring_Assessment_By_PkArgs, 'pk_columns'>>;
  update_compliance_monitoring_assessment_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['compliance_monitoring_assessment_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Compliance_Monitoring_Assessment_ManyArgs, 'updates'>>;
  update_consequence?: Resolver<Maybe<ResolversTypes['consequence_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ConsequenceArgs, 'where'>>;
  update_consequence_by_pk?: Resolver<Maybe<ResolversTypes['consequence']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Consequence_By_PkArgs, 'pk_columns'>>;
  update_consequence_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['consequence_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Consequence_ManyArgs, 'updates'>>;
  update_control_group?: Resolver<Maybe<ResolversTypes['control_group_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Control_GroupArgs, 'where'>>;
  update_control_group_by_pk?: Resolver<Maybe<ResolversTypes['control_group']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Control_Group_By_PkArgs, 'pk_columns'>>;
  update_control_group_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['control_group_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Control_Group_ManyArgs, 'updates'>>;
  update_conversation?: Resolver<Maybe<ResolversTypes['conversation_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ConversationArgs, 'where'>>;
  update_conversation_by_pk?: Resolver<Maybe<ResolversTypes['conversation']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Conversation_By_PkArgs, 'pk_columns'>>;
  update_conversation_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['conversation_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Conversation_ManyArgs, 'updates'>>;
  update_custom_attribute_schema?: Resolver<Maybe<ResolversTypes['custom_attribute_schema_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Custom_Attribute_SchemaArgs, 'where'>>;
  update_custom_attribute_schema_by_pk?: Resolver<Maybe<ResolversTypes['custom_attribute_schema']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Custom_Attribute_Schema_By_PkArgs, 'pk_columns'>>;
  update_custom_attribute_schema_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['custom_attribute_schema_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Custom_Attribute_Schema_ManyArgs, 'updates'>>;
  update_custom_ribbon?: Resolver<Maybe<ResolversTypes['custom_ribbon_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Custom_RibbonArgs, 'where'>>;
  update_custom_ribbon_by_pk?: Resolver<Maybe<ResolversTypes['custom_ribbon']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Custom_Ribbon_By_PkArgs, 'pk_columns'>>;
  update_custom_ribbon_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['custom_ribbon_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Custom_Ribbon_ManyArgs, 'updates'>>;
  update_department_type?: Resolver<Maybe<ResolversTypes['department_type_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Department_TypeArgs, 'where'>>;
  update_department_type_by_pk?: Resolver<Maybe<ResolversTypes['department_type']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Department_Type_By_PkArgs, 'pk_columns'>>;
  update_department_type_group?: Resolver<Maybe<ResolversTypes['department_type_group_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Department_Type_GroupArgs, 'where'>>;
  update_department_type_group_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['department_type_group_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Department_Type_Group_ManyArgs, 'updates'>>;
  update_department_type_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['department_type_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Department_Type_ManyArgs, 'updates'>>;
  update_document_assessment_result?: Resolver<Maybe<ResolversTypes['document_assessment_result_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Document_Assessment_ResultArgs, 'where'>>;
  update_document_assessment_result_by_pk?: Resolver<Maybe<ResolversTypes['document_assessment_result']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Document_Assessment_Result_By_PkArgs, 'pk_columns'>>;
  update_document_assessment_result_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['document_assessment_result_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Document_Assessment_Result_ManyArgs, 'updates'>>;
  update_form_configuration?: Resolver<Maybe<ResolversTypes['form_configuration_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Form_ConfigurationArgs, 'where'>>;
  update_form_configuration_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['form_configuration_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Form_Configuration_ManyArgs, 'updates'>>;
  update_form_field_configuration?: Resolver<Maybe<ResolversTypes['form_field_configuration_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Form_Field_ConfigurationArgs, 'where'>>;
  update_form_field_configuration_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['form_field_configuration_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Form_Field_Configuration_ManyArgs, 'updates'>>;
  update_form_field_ordering?: Resolver<Maybe<ResolversTypes['form_field_ordering_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Form_Field_OrderingArgs, 'where'>>;
  update_form_field_ordering_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['form_field_ordering_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Form_Field_Ordering_ManyArgs, 'updates'>>;
  update_impact?: Resolver<Maybe<ResolversTypes['impact_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_ImpactArgs, 'where'>>;
  update_impact_by_pk?: Resolver<Maybe<ResolversTypes['impact']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Impact_By_PkArgs, 'pk_columns'>>;
  update_impact_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['impact_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Impact_ManyArgs, 'updates'>>;
  update_indicator_result?: Resolver<Maybe<ResolversTypes['indicator_result_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Indicator_ResultArgs, 'where'>>;
  update_indicator_result_by_pk?: Resolver<Maybe<ResolversTypes['indicator_result']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Indicator_Result_By_PkArgs, 'pk_columns'>>;
  update_indicator_result_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['indicator_result_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Indicator_Result_ManyArgs, 'updates'>>;
  update_internal_audit_report?: Resolver<Maybe<ResolversTypes['internal_audit_report_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Internal_Audit_ReportArgs, 'where'>>;
  update_internal_audit_report_by_pk?: Resolver<Maybe<ResolversTypes['internal_audit_report']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Internal_Audit_Report_By_PkArgs, 'pk_columns'>>;
  update_internal_audit_report_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['internal_audit_report_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Internal_Audit_Report_ManyArgs, 'updates'>>;
  update_issue?: Resolver<Maybe<ResolversTypes['issue_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_IssueArgs, 'where'>>;
  update_issue_by_pk?: Resolver<Maybe<ResolversTypes['issue']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Issue_By_PkArgs, 'pk_columns'>>;
  update_issue_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['issue_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Issue_ManyArgs, 'updates'>>;
  update_issue_update?: Resolver<Maybe<ResolversTypes['issue_update_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Issue_UpdateArgs, 'where'>>;
  update_issue_update_by_pk?: Resolver<Maybe<ResolversTypes['issue_update']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Issue_Update_By_PkArgs, 'pk_columns'>>;
  update_issue_update_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['issue_update_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Issue_Update_ManyArgs, 'updates'>>;
  update_obligation_assessment_result?: Resolver<Maybe<ResolversTypes['obligation_assessment_result_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Obligation_Assessment_ResultArgs, 'where'>>;
  update_obligation_assessment_result_by_pk?: Resolver<Maybe<ResolversTypes['obligation_assessment_result']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Obligation_Assessment_Result_By_PkArgs, 'pk_columns'>>;
  update_obligation_assessment_result_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['obligation_assessment_result_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Obligation_Assessment_Result_ManyArgs, 'updates'>>;
  update_obligation_impact?: Resolver<Maybe<ResolversTypes['obligation_impact_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Obligation_ImpactArgs, 'where'>>;
  update_obligation_impact_by_pk?: Resolver<Maybe<ResolversTypes['obligation_impact']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Obligation_Impact_By_PkArgs, 'pk_columns'>>;
  update_obligation_impact_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['obligation_impact_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Obligation_Impact_ManyArgs, 'updates'>>;
  update_questionnaire_template?: Resolver<Maybe<ResolversTypes['questionnaire_template_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Questionnaire_TemplateArgs, 'where'>>;
  update_questionnaire_template_by_pk?: Resolver<Maybe<ResolversTypes['questionnaire_template']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Questionnaire_Template_By_PkArgs, 'pk_columns'>>;
  update_questionnaire_template_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['questionnaire_template_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Questionnaire_Template_ManyArgs, 'updates'>>;
  update_questionnaire_template_version?: Resolver<Maybe<ResolversTypes['questionnaire_template_version_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Questionnaire_Template_VersionArgs, 'where'>>;
  update_questionnaire_template_version_by_pk?: Resolver<Maybe<ResolversTypes['questionnaire_template_version']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Questionnaire_Template_Version_By_PkArgs, 'pk_columns'>>;
  update_questionnaire_template_version_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['questionnaire_template_version_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Questionnaire_Template_Version_ManyArgs, 'updates'>>;
  update_tag_type?: Resolver<Maybe<ResolversTypes['tag_type_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Tag_TypeArgs, 'where'>>;
  update_tag_type_by_pk?: Resolver<Maybe<ResolversTypes['tag_type']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Tag_Type_By_PkArgs, 'pk_columns'>>;
  update_tag_type_group?: Resolver<Maybe<ResolversTypes['tag_type_group_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Tag_Type_GroupArgs, 'where'>>;
  update_tag_type_group_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['tag_type_group_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Tag_Type_Group_ManyArgs, 'updates'>>;
  update_tag_type_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['tag_type_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Tag_Type_ManyArgs, 'updates'>>;
  update_taxonomy?: Resolver<Maybe<ResolversTypes['taxonomy_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_TaxonomyArgs, 'where'>>;
  update_taxonomy_by_pk?: Resolver<Maybe<ResolversTypes['taxonomy']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Taxonomy_By_PkArgs, 'pk_columns'>>;
  update_taxonomy_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['taxonomy_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Taxonomy_ManyArgs, 'updates'>>;
  update_third_party?: Resolver<Maybe<ResolversTypes['third_party_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Third_PartyArgs, 'where'>>;
  update_third_party_by_pk?: Resolver<Maybe<ResolversTypes['third_party']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Third_Party_By_PkArgs, 'pk_columns'>>;
  update_third_party_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['third_party_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Third_Party_ManyArgs, 'updates'>>;
  update_third_party_response?: Resolver<Maybe<ResolversTypes['third_party_response_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Third_Party_ResponseArgs, 'where'>>;
  update_third_party_response_by_pk?: Resolver<Maybe<ResolversTypes['third_party_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Third_Party_Response_By_PkArgs, 'pk_columns'>>;
  update_third_party_response_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['third_party_response_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_Third_Party_Response_ManyArgs, 'updates'>>;
  update_user_group?: Resolver<Maybe<ResolversTypes['user_group_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_User_GroupArgs, 'where'>>;
  update_user_group_by_pk?: Resolver<Maybe<ResolversTypes['user_group']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_User_Group_By_PkArgs, 'pk_columns'>>;
  update_user_group_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['user_group_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_User_Group_ManyArgs, 'updates'>>;
  update_user_roles?: Resolver<ResolversTypes['UpdateUserRolesMutationOutput'], ParentType, ContextType, RequireFields<Mutation_RootUpdate_User_RolesArgs, 'roleIds' | 'userId'>>;
  update_user_search_preferences?: Resolver<Maybe<ResolversTypes['user_search_preferences_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_User_Search_PreferencesArgs, 'where'>>;
  update_user_search_preferences_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['user_search_preferences_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_User_Search_Preferences_ManyArgs, 'updates'>>;
  update_user_table_preferences?: Resolver<Maybe<ResolversTypes['user_table_preferences_mutation_response']>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_User_Table_PreferencesArgs, 'where'>>;
  update_user_table_preferences_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['user_table_preferences_mutation_response']>>>, ParentType, ContextType, RequireFields<Mutation_RootUpdate_User_Table_Preferences_ManyArgs, 'updates'>>;
};

export type NodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['node'] = ResolversParentTypes['node']> = {
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ObjectType?: Resolver<ResolversTypes['parent_type_enum'], ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  acceptance?: Resolver<Maybe<ResolversTypes['acceptance']>, ParentType, ContextType>;
  action?: Resolver<Maybe<ResolversTypes['action']>, ParentType, ContextType>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<NodeAncestorContributorsArgs>>;
  control?: Resolver<Maybe<ResolversTypes['control']>, ParentType, ContextType>;
  document?: Resolver<Maybe<ResolversTypes['document']>, ParentType, ContextType>;
  documentFile?: Resolver<Maybe<ResolversTypes['document_file']>, ParentType, ContextType>;
  indicator?: Resolver<Maybe<ResolversTypes['indicator']>, ParentType, ContextType>;
  issue?: Resolver<Maybe<ResolversTypes['issue']>, ParentType, ContextType>;
  issue_assessment?: Resolver<Maybe<ResolversTypes['issue_assessment']>, ParentType, ContextType>;
  obligation?: Resolver<Maybe<ResolversTypes['obligation']>, ParentType, ContextType>;
  risk?: Resolver<Maybe<ResolversTypes['risk']>, ParentType, ContextType>;
  thirdParty?: Resolver<Maybe<ResolversTypes['third_party']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Node_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['node_aggregate'] = ResolversParentTypes['node_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['node_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['node']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Node_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['node_aggregate_fields'] = ResolversParentTypes['node_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['node_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Node_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['node_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['node_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['node_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['node_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['node_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['node_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['node_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['node_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['node_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Node_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['node_avg_fields'] = ResolversParentTypes['node_avg_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Node_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['node_max_fields'] = ResolversParentTypes['node_max_fields']> = {
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Node_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['node_min_fields'] = ResolversParentTypes['node_min_fields']> = {
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Node_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['node_stddev_fields'] = ResolversParentTypes['node_stddev_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Node_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['node_stddev_pop_fields'] = ResolversParentTypes['node_stddev_pop_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Node_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['node_stddev_samp_fields'] = ResolversParentTypes['node_stddev_samp_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Node_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['node_sum_fields'] = ResolversParentTypes['node_sum_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Node_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['node_var_pop_fields'] = ResolversParentTypes['node_var_pop_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Node_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['node_var_samp_fields'] = ResolversParentTypes['node_var_samp_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Node_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['node_variance_fields'] = ResolversParentTypes['node_variance_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface NumericScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['numeric'], any> {
  name: 'numeric';
}

export type ObligationResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation'] = ResolversParentTypes['obligation']> = {
  Adherence?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<ObligationCustomAttributeDataArgs>>;
  Description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Interpretation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  OrgKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Type?: Resolver<ResolversTypes['obligation_type_enum'], ParentType, ContextType>;
  actions?: Resolver<Array<ResolversTypes['action_parent']>, ParentType, ContextType, Partial<ObligationActionsArgs>>;
  actions_aggregate?: Resolver<ResolversTypes['action_parent_aggregate'], ParentType, ContextType, Partial<ObligationActions_AggregateArgs>>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<ObligationAncestorContributorsArgs>>;
  assessmentResults?: Resolver<Array<ResolversTypes['assessment_result_parent']>, ParentType, ContextType, Partial<ObligationAssessmentResultsArgs>>;
  assessmentResults_aggregate?: Resolver<ResolversTypes['assessment_result_parent_aggregate'], ParentType, ContextType, Partial<ObligationAssessmentResults_AggregateArgs>>;
  contributorGroups?: Resolver<Array<ResolversTypes['contributor_group']>, ParentType, ContextType, Partial<ObligationContributorGroupsArgs>>;
  contributorGroups_aggregate?: Resolver<ResolversTypes['contributor_group_aggregate'], ParentType, ContextType, Partial<ObligationContributorGroups_AggregateArgs>>;
  contributors?: Resolver<Array<ResolversTypes['contributor']>, ParentType, ContextType, Partial<ObligationContributorsArgs>>;
  contributors_aggregate?: Resolver<ResolversTypes['contributor_aggregate'], ParentType, ContextType, Partial<ObligationContributors_AggregateArgs>>;
  controls?: Resolver<Array<ResolversTypes['control_parent']>, ParentType, ContextType, Partial<ObligationControlsArgs>>;
  controls_aggregate?: Resolver<ResolversTypes['control_parent_aggregate'], ParentType, ContextType, Partial<ObligationControls_AggregateArgs>>;
  createdBy?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  departments?: Resolver<Array<ResolversTypes['department']>, ParentType, ContextType, Partial<ObligationDepartmentsArgs>>;
  impacts?: Resolver<Array<ResolversTypes['obligation_impact']>, ParentType, ContextType, Partial<ObligationImpactsArgs>>;
  issues?: Resolver<Array<ResolversTypes['issue_parent']>, ParentType, ContextType, Partial<ObligationIssuesArgs>>;
  issues_aggregate?: Resolver<ResolversTypes['issue_parent_aggregate'], ParentType, ContextType, Partial<ObligationIssues_AggregateArgs>>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  obligation_type?: Resolver<ResolversTypes['obligation_type'], ParentType, ContextType>;
  org?: Resolver<Maybe<ResolversTypes['auth_organisation']>, ParentType, ContextType>;
  ownerGroups?: Resolver<Array<ResolversTypes['owner_group']>, ParentType, ContextType, Partial<ObligationOwnerGroupsArgs>>;
  ownerGroups_aggregate?: Resolver<ResolversTypes['owner_group_aggregate'], ParentType, ContextType, Partial<ObligationOwnerGroups_AggregateArgs>>;
  owners?: Resolver<Array<ResolversTypes['owner']>, ParentType, ContextType, Partial<ObligationOwnersArgs>>;
  owners_aggregate?: Resolver<ResolversTypes['owner_aggregate'], ParentType, ContextType, Partial<ObligationOwners_AggregateArgs>>;
  parent?: Resolver<Maybe<ResolversTypes['obligation']>, ParentType, ContextType>;
  parentNode?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType>;
  schedule?: Resolver<Maybe<ResolversTypes['schedule']>, ParentType, ContextType>;
  scheduleState?: Resolver<Maybe<ResolversTypes['schedule_state']>, ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['tag']>, ParentType, ContextType, Partial<ObligationTagsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_aggregate'] = ResolversParentTypes['obligation_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['obligation_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['obligation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_aggregate_fields'] = ResolversParentTypes['obligation_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['obligation_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Obligation_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['obligation_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['obligation_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['obligation_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['obligation_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['obligation_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['obligation_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['obligation_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['obligation_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['obligation_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Assessment_ResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_assessment_result'] = ResolversParentTypes['obligation_assessment_result']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Obligation_Assessment_ResultCustomAttributeDataArgs>>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  RatingType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Rationale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<Obligation_Assessment_ResultAncestorContributorsArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  files?: Resolver<Array<ResolversTypes['relation_file']>, ParentType, ContextType, Partial<Obligation_Assessment_ResultFilesArgs>>;
  files_aggregate?: Resolver<ResolversTypes['relation_file_aggregate'], ParentType, ContextType, Partial<Obligation_Assessment_ResultFiles_AggregateArgs>>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parents?: Resolver<Array<ResolversTypes['assessment_result_parent']>, ParentType, ContextType, Partial<Obligation_Assessment_ResultParentsArgs>>;
  parents_aggregate?: Resolver<ResolversTypes['assessment_result_parent_aggregate'], ParentType, ContextType, Partial<Obligation_Assessment_ResultParents_AggregateArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Assessment_Result_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_assessment_result_aggregate'] = ResolversParentTypes['obligation_assessment_result_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['obligation_assessment_result_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['obligation_assessment_result']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Assessment_Result_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_assessment_result_aggregate_fields'] = ResolversParentTypes['obligation_assessment_result_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['obligation_assessment_result_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Obligation_Assessment_Result_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['obligation_assessment_result_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['obligation_assessment_result_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['obligation_assessment_result_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['obligation_assessment_result_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['obligation_assessment_result_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['obligation_assessment_result_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['obligation_assessment_result_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['obligation_assessment_result_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['obligation_assessment_result_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Assessment_Result_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_assessment_result_audit'] = ResolversParentTypes['obligation_assessment_result_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Obligation_Assessment_Result_AuditCustomAttributeDataArgs>>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  RatingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rationale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Assessment_Result_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_assessment_result_avg_fields'] = ResolversParentTypes['obligation_assessment_result_avg_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Assessment_Result_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_assessment_result_max_fields'] = ResolversParentTypes['obligation_assessment_result_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  RatingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rationale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Assessment_Result_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_assessment_result_min_fields'] = ResolversParentTypes['obligation_assessment_result_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  RatingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rationale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Assessment_Result_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_assessment_result_mutation_response'] = ResolversParentTypes['obligation_assessment_result_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['obligation_assessment_result']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Assessment_Result_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_assessment_result_stddev_fields'] = ResolversParentTypes['obligation_assessment_result_stddev_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Assessment_Result_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_assessment_result_stddev_pop_fields'] = ResolversParentTypes['obligation_assessment_result_stddev_pop_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Assessment_Result_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_assessment_result_stddev_samp_fields'] = ResolversParentTypes['obligation_assessment_result_stddev_samp_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Assessment_Result_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_assessment_result_sum_fields'] = ResolversParentTypes['obligation_assessment_result_sum_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Assessment_Result_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_assessment_result_var_pop_fields'] = ResolversParentTypes['obligation_assessment_result_var_pop_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Assessment_Result_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_assessment_result_var_samp_fields'] = ResolversParentTypes['obligation_assessment_result_var_samp_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Assessment_Result_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_assessment_result_variance_fields'] = ResolversParentTypes['obligation_assessment_result_variance_fields']> = {
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Assessment_StatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_assessment_status'] = ResolversParentTypes['obligation_assessment_status']> = {
  Comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_audit'] = ResolversParentTypes['obligation_audit']> = {
  Adherence?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Obligation_AuditCustomAttributeDataArgs>>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Interpretation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrgKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Audit_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_audit_aggregate'] = ResolversParentTypes['obligation_audit_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['obligation_audit_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['obligation_audit']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Audit_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_audit_aggregate_fields'] = ResolversParentTypes['obligation_audit_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['obligation_audit_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Obligation_Audit_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['obligation_audit_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['obligation_audit_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['obligation_audit_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['obligation_audit_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['obligation_audit_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['obligation_audit_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['obligation_audit_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['obligation_audit_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['obligation_audit_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Audit_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_audit_avg_fields'] = ResolversParentTypes['obligation_audit_avg_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Audit_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_audit_max_fields'] = ResolversParentTypes['obligation_audit_max_fields']> = {
  Adherence?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Interpretation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrgKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Audit_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_audit_min_fields'] = ResolversParentTypes['obligation_audit_min_fields']> = {
  Adherence?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Interpretation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrgKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Audit_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_audit_stddev_fields'] = ResolversParentTypes['obligation_audit_stddev_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Audit_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_audit_stddev_pop_fields'] = ResolversParentTypes['obligation_audit_stddev_pop_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Audit_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_audit_stddev_samp_fields'] = ResolversParentTypes['obligation_audit_stddev_samp_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Audit_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_audit_sum_fields'] = ResolversParentTypes['obligation_audit_sum_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Audit_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_audit_var_pop_fields'] = ResolversParentTypes['obligation_audit_var_pop_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Audit_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_audit_var_samp_fields'] = ResolversParentTypes['obligation_audit_var_samp_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Audit_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_audit_variance_fields'] = ResolversParentTypes['obligation_audit_variance_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_avg_fields'] = ResolversParentTypes['obligation_avg_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_ImpactResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_impact'] = ResolversParentTypes['obligation_impact']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Obligation_ImpactCustomAttributeDataArgs>>;
  Description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ImpactRating?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  OrgKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentObligationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['obligation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Impact_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_impact_audit'] = ResolversParentTypes['obligation_impact_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Obligation_Impact_AuditCustomAttributeDataArgs>>;
  Description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ImpactRating?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrgKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentObligationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Impact_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_impact_mutation_response'] = ResolversParentTypes['obligation_impact_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['obligation_impact']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_max_fields'] = ResolversParentTypes['obligation_max_fields']> = {
  Adherence?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Interpretation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrgKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_min_fields'] = ResolversParentTypes['obligation_min_fields']> = {
  Adherence?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Interpretation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrgKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_mutation_response'] = ResolversParentTypes['obligation_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['obligation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_stddev_fields'] = ResolversParentTypes['obligation_stddev_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_stddev_pop_fields'] = ResolversParentTypes['obligation_stddev_pop_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_stddev_samp_fields'] = ResolversParentTypes['obligation_stddev_samp_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_sum_fields'] = ResolversParentTypes['obligation_sum_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_TypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_type'] = ResolversParentTypes['obligation_type']> = {
  Comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  obligations?: Resolver<Array<ResolversTypes['obligation']>, ParentType, ContextType, Partial<Obligation_TypeObligationsArgs>>;
  obligations_aggregate?: Resolver<ResolversTypes['obligation_aggregate'], ParentType, ContextType, Partial<Obligation_TypeObligations_AggregateArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_var_pop_fields'] = ResolversParentTypes['obligation_var_pop_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_var_samp_fields'] = ResolversParentTypes['obligation_var_samp_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Obligation_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['obligation_variance_fields'] = ResolversParentTypes['obligation_variance_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OwnerResolvers<ContextType = any, ParentType extends ResolversParentTypes['owner'] = ResolversParentTypes['owner']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  UserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parentAction?: Resolver<Maybe<ResolversTypes['action']>, ParentType, ContextType>;
  parentAssessment?: Resolver<Maybe<ResolversTypes['assessment']>, ParentType, ContextType>;
  parentDashboard?: Resolver<Maybe<ResolversTypes['dashboard']>, ParentType, ContextType>;
  parentInternalAuditEntity?: Resolver<Maybe<ResolversTypes['internal_audit_entity']>, ParentType, ContextType>;
  parentInternalAuditReport?: Resolver<Maybe<ResolversTypes['internal_audit_report']>, ParentType, ContextType>;
  parentIssue?: Resolver<Maybe<ResolversTypes['issue']>, ParentType, ContextType>;
  parentRisk?: Resolver<Maybe<ResolversTypes['risk']>, ParentType, ContextType>;
  parentThirdParty?: Resolver<Maybe<ResolversTypes['third_party']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Owner_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['owner_aggregate'] = ResolversParentTypes['owner_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['owner_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['owner']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Owner_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['owner_aggregate_fields'] = ResolversParentTypes['owner_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Owner_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['owner_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['owner_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Owner_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['owner_audit'] = ResolversParentTypes['owner_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  UserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Owner_GroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['owner_group'] = ResolversParentTypes['owner_group']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  UserGroupId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  group?: Resolver<Maybe<ResolversTypes['user_group']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parentAction?: Resolver<Maybe<ResolversTypes['action']>, ParentType, ContextType>;
  parentAssessment?: Resolver<Maybe<ResolversTypes['assessment']>, ParentType, ContextType>;
  parentInternalAuditEntity?: Resolver<Maybe<ResolversTypes['internal_audit_entity']>, ParentType, ContextType>;
  parentInternalAuditReport?: Resolver<Maybe<ResolversTypes['internal_audit_report']>, ParentType, ContextType>;
  parentIssue?: Resolver<Maybe<ResolversTypes['issue']>, ParentType, ContextType>;
  parentRisk?: Resolver<Maybe<ResolversTypes['risk']>, ParentType, ContextType>;
  parentThirdParty?: Resolver<Maybe<ResolversTypes['third_party']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Owner_Group_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['owner_group_aggregate'] = ResolversParentTypes['owner_group_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['owner_group_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['owner_group']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Owner_Group_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['owner_group_aggregate_fields'] = ResolversParentTypes['owner_group_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Owner_Group_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['owner_group_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['owner_group_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Owner_Group_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['owner_group_audit'] = ResolversParentTypes['owner_group_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  UserGroupId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Owner_Group_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['owner_group_max_fields'] = ResolversParentTypes['owner_group_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  UserGroupId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Owner_Group_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['owner_group_min_fields'] = ResolversParentTypes['owner_group_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  UserGroupId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Owner_Group_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['owner_group_mutation_response'] = ResolversParentTypes['owner_group_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['owner_group']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Owner_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['owner_max_fields'] = ResolversParentTypes['owner_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  UserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Owner_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['owner_min_fields'] = ResolversParentTypes['owner_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  UserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Owner_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['owner_mutation_response'] = ResolversParentTypes['owner_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['owner']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Query_RootResolvers<ContextType = any, ParentType extends ResolversParentTypes['query_root'] = ResolversParentTypes['query_root']> = {
  acceptance?: Resolver<Array<ResolversTypes['acceptance']>, ParentType, ContextType, Partial<Query_RootAcceptanceArgs>>;
  acceptance_aggregate?: Resolver<ResolversTypes['acceptance_aggregate'], ParentType, ContextType, Partial<Query_RootAcceptance_AggregateArgs>>;
  acceptance_audit?: Resolver<Array<ResolversTypes['acceptance_audit']>, ParentType, ContextType, Partial<Query_RootAcceptance_AuditArgs>>;
  acceptance_audit_by_pk?: Resolver<Maybe<ResolversTypes['acceptance_audit']>, ParentType, ContextType, RequireFields<Query_RootAcceptance_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  acceptance_by_pk?: Resolver<Maybe<ResolversTypes['acceptance']>, ParentType, ContextType, RequireFields<Query_RootAcceptance_By_PkArgs, 'Id'>>;
  acceptance_parent?: Resolver<Array<ResolversTypes['acceptance_parent']>, ParentType, ContextType, Partial<Query_RootAcceptance_ParentArgs>>;
  acceptance_parent_aggregate?: Resolver<ResolversTypes['acceptance_parent_aggregate'], ParentType, ContextType, Partial<Query_RootAcceptance_Parent_AggregateArgs>>;
  acceptance_parent_audit?: Resolver<Array<ResolversTypes['acceptance_parent_audit']>, ParentType, ContextType, Partial<Query_RootAcceptance_Parent_AuditArgs>>;
  acceptance_parent_audit_by_pk?: Resolver<Maybe<ResolversTypes['acceptance_parent_audit']>, ParentType, ContextType, RequireFields<Query_RootAcceptance_Parent_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp' | 'ParentId'>>;
  acceptance_parent_by_pk?: Resolver<Maybe<ResolversTypes['acceptance_parent']>, ParentType, ContextType, RequireFields<Query_RootAcceptance_Parent_By_PkArgs, 'Id' | 'ParentId'>>;
  action?: Resolver<Array<ResolversTypes['action']>, ParentType, ContextType, Partial<Query_RootActionArgs>>;
  action_aggregate?: Resolver<ResolversTypes['action_aggregate'], ParentType, ContextType, Partial<Query_RootAction_AggregateArgs>>;
  action_audit?: Resolver<Array<ResolversTypes['action_audit']>, ParentType, ContextType, Partial<Query_RootAction_AuditArgs>>;
  action_audit_aggregate?: Resolver<ResolversTypes['action_audit_aggregate'], ParentType, ContextType, Partial<Query_RootAction_Audit_AggregateArgs>>;
  action_audit_by_pk?: Resolver<Maybe<ResolversTypes['action_audit']>, ParentType, ContextType, RequireFields<Query_RootAction_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  action_by_pk?: Resolver<Maybe<ResolversTypes['action']>, ParentType, ContextType, RequireFields<Query_RootAction_By_PkArgs, 'Id'>>;
  action_parent?: Resolver<Array<ResolversTypes['action_parent']>, ParentType, ContextType, Partial<Query_RootAction_ParentArgs>>;
  action_parent_aggregate?: Resolver<ResolversTypes['action_parent_aggregate'], ParentType, ContextType, Partial<Query_RootAction_Parent_AggregateArgs>>;
  action_parent_audit?: Resolver<Array<ResolversTypes['action_parent_audit']>, ParentType, ContextType, Partial<Query_RootAction_Parent_AuditArgs>>;
  action_parent_audit_by_pk?: Resolver<Maybe<ResolversTypes['action_parent_audit']>, ParentType, ContextType, RequireFields<Query_RootAction_Parent_Audit_By_PkArgs, 'ActionId' | 'ModifiedAtTimestamp' | 'ParentId'>>;
  action_parent_by_pk?: Resolver<Maybe<ResolversTypes['action_parent']>, ParentType, ContextType, RequireFields<Query_RootAction_Parent_By_PkArgs, 'ActionId' | 'ParentId'>>;
  action_update?: Resolver<Array<ResolversTypes['action_update']>, ParentType, ContextType, Partial<Query_RootAction_UpdateArgs>>;
  action_update_aggregate?: Resolver<ResolversTypes['action_update_aggregate'], ParentType, ContextType, Partial<Query_RootAction_Update_AggregateArgs>>;
  action_update_audit?: Resolver<Array<ResolversTypes['action_update_audit']>, ParentType, ContextType, Partial<Query_RootAction_Update_AuditArgs>>;
  action_update_audit_by_pk?: Resolver<Maybe<ResolversTypes['action_update_audit']>, ParentType, ContextType, RequireFields<Query_RootAction_Update_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  action_update_by_pk?: Resolver<Maybe<ResolversTypes['action_update']>, ParentType, ContextType, RequireFields<Query_RootAction_Update_By_PkArgs, 'Id'>>;
  action_update_summary?: Resolver<Array<ResolversTypes['action_update_summary']>, ParentType, ContextType, Partial<Query_RootAction_Update_SummaryArgs>>;
  action_update_summary_aggregate?: Resolver<ResolversTypes['action_update_summary_aggregate'], ParentType, ContextType, Partial<Query_RootAction_Update_Summary_AggregateArgs>>;
  aggregation_org?: Resolver<Array<ResolversTypes['aggregation_org']>, ParentType, ContextType, Partial<Query_RootAggregation_OrgArgs>>;
  aggregation_org_by_pk?: Resolver<Maybe<ResolversTypes['aggregation_org']>, ParentType, ContextType, RequireFields<Query_RootAggregation_Org_By_PkArgs, 'OrgKey'>>;
  ancestor_contributor?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<Query_RootAncestor_ContributorArgs>>;
  appetite?: Resolver<Array<ResolversTypes['appetite']>, ParentType, ContextType, Partial<Query_RootAppetiteArgs>>;
  appetite_aggregate?: Resolver<ResolversTypes['appetite_aggregate'], ParentType, ContextType, Partial<Query_RootAppetite_AggregateArgs>>;
  appetite_audit?: Resolver<Array<ResolversTypes['appetite_audit']>, ParentType, ContextType, Partial<Query_RootAppetite_AuditArgs>>;
  appetite_audit_by_pk?: Resolver<Maybe<ResolversTypes['appetite_audit']>, ParentType, ContextType, RequireFields<Query_RootAppetite_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  appetite_by_pk?: Resolver<Maybe<ResolversTypes['appetite']>, ParentType, ContextType, RequireFields<Query_RootAppetite_By_PkArgs, 'Id'>>;
  appetite_parent?: Resolver<Array<ResolversTypes['appetite_parent']>, ParentType, ContextType, Partial<Query_RootAppetite_ParentArgs>>;
  appetite_parent_aggregate?: Resolver<ResolversTypes['appetite_parent_aggregate'], ParentType, ContextType, Partial<Query_RootAppetite_Parent_AggregateArgs>>;
  appetite_parent_audit?: Resolver<Array<ResolversTypes['appetite_parent_audit']>, ParentType, ContextType, Partial<Query_RootAppetite_Parent_AuditArgs>>;
  appetite_parent_audit_aggregate?: Resolver<ResolversTypes['appetite_parent_audit_aggregate'], ParentType, ContextType, Partial<Query_RootAppetite_Parent_Audit_AggregateArgs>>;
  appetite_parent_audit_by_pk?: Resolver<Maybe<ResolversTypes['appetite_parent_audit']>, ParentType, ContextType, RequireFields<Query_RootAppetite_Parent_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp' | 'ParentId'>>;
  appetite_parent_by_pk?: Resolver<Maybe<ResolversTypes['appetite_parent']>, ParentType, ContextType, RequireFields<Query_RootAppetite_Parent_By_PkArgs, 'Id' | 'ParentId'>>;
  approval?: Resolver<Array<ResolversTypes['approval']>, ParentType, ContextType, Partial<Query_RootApprovalArgs>>;
  approval_audit?: Resolver<Array<ResolversTypes['approval_audit']>, ParentType, ContextType, Partial<Query_RootApproval_AuditArgs>>;
  approval_audit_by_pk?: Resolver<Maybe<ResolversTypes['approval_audit']>, ParentType, ContextType, RequireFields<Query_RootApproval_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  approval_by_pk?: Resolver<Maybe<ResolversTypes['approval']>, ParentType, ContextType, RequireFields<Query_RootApproval_By_PkArgs, 'Id'>>;
  approval_level?: Resolver<Array<ResolversTypes['approval_level']>, ParentType, ContextType, Partial<Query_RootApproval_LevelArgs>>;
  approval_level_audit?: Resolver<Array<ResolversTypes['approval_level_audit']>, ParentType, ContextType, Partial<Query_RootApproval_Level_AuditArgs>>;
  approval_level_audit_by_pk?: Resolver<Maybe<ResolversTypes['approval_level_audit']>, ParentType, ContextType, RequireFields<Query_RootApproval_Level_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  approval_level_by_pk?: Resolver<Maybe<ResolversTypes['approval_level']>, ParentType, ContextType, RequireFields<Query_RootApproval_Level_By_PkArgs, 'Id'>>;
  approver?: Resolver<Array<ResolversTypes['approver']>, ParentType, ContextType, Partial<Query_RootApproverArgs>>;
  approver_aggregate?: Resolver<ResolversTypes['approver_aggregate'], ParentType, ContextType, Partial<Query_RootApprover_AggregateArgs>>;
  approver_audit?: Resolver<Array<ResolversTypes['approver_audit']>, ParentType, ContextType, Partial<Query_RootApprover_AuditArgs>>;
  approver_audit_by_pk?: Resolver<Maybe<ResolversTypes['approver_audit']>, ParentType, ContextType, RequireFields<Query_RootApprover_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  approver_by_pk?: Resolver<Maybe<ResolversTypes['approver']>, ParentType, ContextType, RequireFields<Query_RootApprover_By_PkArgs, 'Id'>>;
  assessment?: Resolver<Array<ResolversTypes['assessment']>, ParentType, ContextType, Partial<Query_RootAssessmentArgs>>;
  assessment_activity?: Resolver<Array<ResolversTypes['assessment_activity']>, ParentType, ContextType, Partial<Query_RootAssessment_ActivityArgs>>;
  assessment_activity_aggregate?: Resolver<ResolversTypes['assessment_activity_aggregate'], ParentType, ContextType, Partial<Query_RootAssessment_Activity_AggregateArgs>>;
  assessment_activity_audit?: Resolver<Array<ResolversTypes['assessment_activity_audit']>, ParentType, ContextType, Partial<Query_RootAssessment_Activity_AuditArgs>>;
  assessment_activity_audit_by_pk?: Resolver<Maybe<ResolversTypes['assessment_activity_audit']>, ParentType, ContextType, RequireFields<Query_RootAssessment_Activity_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  assessment_activity_by_pk?: Resolver<Maybe<ResolversTypes['assessment_activity']>, ParentType, ContextType, RequireFields<Query_RootAssessment_Activity_By_PkArgs, 'Id'>>;
  assessment_aggregate?: Resolver<ResolversTypes['assessment_aggregate'], ParentType, ContextType, Partial<Query_RootAssessment_AggregateArgs>>;
  assessment_audit?: Resolver<Array<ResolversTypes['assessment_audit']>, ParentType, ContextType, Partial<Query_RootAssessment_AuditArgs>>;
  assessment_audit_aggregate?: Resolver<ResolversTypes['assessment_audit_aggregate'], ParentType, ContextType, Partial<Query_RootAssessment_Audit_AggregateArgs>>;
  assessment_audit_by_pk?: Resolver<Maybe<ResolversTypes['assessment_audit']>, ParentType, ContextType, RequireFields<Query_RootAssessment_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  assessment_by_pk?: Resolver<Maybe<ResolversTypes['assessment']>, ParentType, ContextType, RequireFields<Query_RootAssessment_By_PkArgs, 'Id'>>;
  assessment_result_parent?: Resolver<Array<ResolversTypes['assessment_result_parent']>, ParentType, ContextType, Partial<Query_RootAssessment_Result_ParentArgs>>;
  assessment_result_parent_aggregate?: Resolver<ResolversTypes['assessment_result_parent_aggregate'], ParentType, ContextType, Partial<Query_RootAssessment_Result_Parent_AggregateArgs>>;
  assessment_result_parent_audit?: Resolver<Array<ResolversTypes['assessment_result_parent_audit']>, ParentType, ContextType, Partial<Query_RootAssessment_Result_Parent_AuditArgs>>;
  assessment_result_parent_audit_by_pk?: Resolver<Maybe<ResolversTypes['assessment_result_parent_audit']>, ParentType, ContextType, RequireFields<Query_RootAssessment_Result_Parent_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp' | 'ParentId'>>;
  assessment_result_parent_by_pk?: Resolver<Maybe<ResolversTypes['assessment_result_parent']>, ParentType, ContextType, RequireFields<Query_RootAssessment_Result_Parent_By_PkArgs, 'Id' | 'ParentId'>>;
  attestation_config?: Resolver<Array<ResolversTypes['attestation_config']>, ParentType, ContextType, Partial<Query_RootAttestation_ConfigArgs>>;
  attestation_config_by_pk?: Resolver<Maybe<ResolversTypes['attestation_config']>, ParentType, ContextType, RequireFields<Query_RootAttestation_Config_By_PkArgs, 'ParentId'>>;
  attestation_group?: Resolver<Array<ResolversTypes['attestation_group']>, ParentType, ContextType, Partial<Query_RootAttestation_GroupArgs>>;
  attestation_group_by_pk?: Resolver<Maybe<ResolversTypes['attestation_group']>, ParentType, ContextType, RequireFields<Query_RootAttestation_Group_By_PkArgs, 'ConfigId' | 'GroupId'>>;
  attestation_record?: Resolver<Array<ResolversTypes['attestation_record']>, ParentType, ContextType, Partial<Query_RootAttestation_RecordArgs>>;
  attestation_record_aggregate?: Resolver<ResolversTypes['attestation_record_aggregate'], ParentType, ContextType, Partial<Query_RootAttestation_Record_AggregateArgs>>;
  attestation_record_by_pk?: Resolver<Maybe<ResolversTypes['attestation_record']>, ParentType, ContextType, RequireFields<Query_RootAttestation_Record_By_PkArgs, 'Id'>>;
  audit_log_view?: Resolver<Array<ResolversTypes['audit_log_view']>, ParentType, ContextType, Partial<Query_RootAudit_Log_ViewArgs>>;
  audit_log_view_aggregate?: Resolver<ResolversTypes['audit_log_view_aggregate'], ParentType, ContextType, Partial<Query_RootAudit_Log_View_AggregateArgs>>;
  auth_organisation?: Resolver<Array<ResolversTypes['auth_organisation']>, ParentType, ContextType, Partial<Query_RootAuth_OrganisationArgs>>;
  auth_organisation_by_pk?: Resolver<Maybe<ResolversTypes['auth_organisation']>, ParentType, ContextType, RequireFields<Query_RootAuth_Organisation_By_PkArgs, 'OrgKey'>>;
  auth_organisationuser?: Resolver<Array<ResolversTypes['auth_organisationuser']>, ParentType, ContextType, Partial<Query_RootAuth_OrganisationuserArgs>>;
  auth_organisationuser_by_pk?: Resolver<Maybe<ResolversTypes['auth_organisationuser']>, ParentType, ContextType, RequireFields<Query_RootAuth_Organisationuser_By_PkArgs, 'OrgKey' | 'User_Id'>>;
  auth_user?: Resolver<Array<ResolversTypes['auth_user']>, ParentType, ContextType, Partial<Query_RootAuth_UserArgs>>;
  auth_user_aggregate?: Resolver<ResolversTypes['auth_user_aggregate'], ParentType, ContextType, Partial<Query_RootAuth_User_AggregateArgs>>;
  auth_user_by_pk?: Resolver<Maybe<ResolversTypes['auth_user']>, ParentType, ContextType, RequireFields<Query_RootAuth_User_By_PkArgs, 'Id'>>;
  available_roles?: Resolver<Array<ResolversTypes['RoleOutput']>, ParentType, ContextType>;
  business_area?: Resolver<Array<ResolversTypes['business_area']>, ParentType, ContextType, Partial<Query_RootBusiness_AreaArgs>>;
  business_area_aggregate?: Resolver<ResolversTypes['business_area_aggregate'], ParentType, ContextType, Partial<Query_RootBusiness_Area_AggregateArgs>>;
  business_area_by_pk?: Resolver<Maybe<ResolversTypes['business_area']>, ParentType, ContextType, RequireFields<Query_RootBusiness_Area_By_PkArgs, 'Id'>>;
  cause?: Resolver<Array<ResolversTypes['cause']>, ParentType, ContextType, Partial<Query_RootCauseArgs>>;
  cause_aggregate?: Resolver<ResolversTypes['cause_aggregate'], ParentType, ContextType, Partial<Query_RootCause_AggregateArgs>>;
  cause_audit?: Resolver<Array<ResolversTypes['cause_audit']>, ParentType, ContextType, Partial<Query_RootCause_AuditArgs>>;
  cause_audit_by_pk?: Resolver<Maybe<ResolversTypes['cause_audit']>, ParentType, ContextType, RequireFields<Query_RootCause_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  cause_by_pk?: Resolver<Maybe<ResolversTypes['cause']>, ParentType, ContextType, RequireFields<Query_RootCause_By_PkArgs, 'Id'>>;
  change_request?: Resolver<Array<ResolversTypes['change_request']>, ParentType, ContextType, Partial<Query_RootChange_RequestArgs>>;
  change_request_aggregate?: Resolver<ResolversTypes['change_request_aggregate'], ParentType, ContextType, Partial<Query_RootChange_Request_AggregateArgs>>;
  change_request_audit?: Resolver<Array<ResolversTypes['change_request_audit']>, ParentType, ContextType, Partial<Query_RootChange_Request_AuditArgs>>;
  change_request_audit_by_pk?: Resolver<Maybe<ResolversTypes['change_request_audit']>, ParentType, ContextType, RequireFields<Query_RootChange_Request_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  change_request_by_pk?: Resolver<Maybe<ResolversTypes['change_request']>, ParentType, ContextType, RequireFields<Query_RootChange_Request_By_PkArgs, 'Id'>>;
  change_request_contributor?: Resolver<Array<ResolversTypes['change_request_contributor']>, ParentType, ContextType, Partial<Query_RootChange_Request_ContributorArgs>>;
  change_request_contributor_by_pk?: Resolver<Maybe<ResolversTypes['change_request_contributor']>, ParentType, ContextType, RequireFields<Query_RootChange_Request_Contributor_By_PkArgs, 'Id'>>;
  comment?: Resolver<Array<ResolversTypes['comment']>, ParentType, ContextType, Partial<Query_RootCommentArgs>>;
  comment_audit?: Resolver<Array<ResolversTypes['comment_audit']>, ParentType, ContextType, Partial<Query_RootComment_AuditArgs>>;
  comment_audit_by_pk?: Resolver<Maybe<ResolversTypes['comment_audit']>, ParentType, ContextType, RequireFields<Query_RootComment_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  comment_by_pk?: Resolver<Maybe<ResolversTypes['comment']>, ParentType, ContextType, RequireFields<Query_RootComment_By_PkArgs, 'Id'>>;
  compliance_monitoring_assessment?: Resolver<Array<ResolversTypes['compliance_monitoring_assessment']>, ParentType, ContextType, Partial<Query_RootCompliance_Monitoring_AssessmentArgs>>;
  compliance_monitoring_assessment_aggregate?: Resolver<ResolversTypes['compliance_monitoring_assessment_aggregate'], ParentType, ContextType, Partial<Query_RootCompliance_Monitoring_Assessment_AggregateArgs>>;
  compliance_monitoring_assessment_by_pk?: Resolver<Maybe<ResolversTypes['compliance_monitoring_assessment']>, ParentType, ContextType, RequireFields<Query_RootCompliance_Monitoring_Assessment_By_PkArgs, 'Id'>>;
  consequence?: Resolver<Array<ResolversTypes['consequence']>, ParentType, ContextType, Partial<Query_RootConsequenceArgs>>;
  consequence_aggregate?: Resolver<ResolversTypes['consequence_aggregate'], ParentType, ContextType, Partial<Query_RootConsequence_AggregateArgs>>;
  consequence_audit?: Resolver<Array<ResolversTypes['consequence_audit']>, ParentType, ContextType, Partial<Query_RootConsequence_AuditArgs>>;
  consequence_audit_aggregate?: Resolver<ResolversTypes['consequence_audit_aggregate'], ParentType, ContextType, Partial<Query_RootConsequence_Audit_AggregateArgs>>;
  consequence_audit_by_pk?: Resolver<Maybe<ResolversTypes['consequence_audit']>, ParentType, ContextType, RequireFields<Query_RootConsequence_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  consequence_by_pk?: Resolver<Maybe<ResolversTypes['consequence']>, ParentType, ContextType, RequireFields<Query_RootConsequence_By_PkArgs, 'Id'>>;
  control?: Resolver<Array<ResolversTypes['control']>, ParentType, ContextType, Partial<Query_RootControlArgs>>;
  control_aggregate?: Resolver<ResolversTypes['control_aggregate'], ParentType, ContextType, Partial<Query_RootControl_AggregateArgs>>;
  control_audit?: Resolver<Array<ResolversTypes['control_audit']>, ParentType, ContextType, Partial<Query_RootControl_AuditArgs>>;
  control_audit_by_pk?: Resolver<Maybe<ResolversTypes['control_audit']>, ParentType, ContextType, RequireFields<Query_RootControl_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  control_by_pk?: Resolver<Maybe<ResolversTypes['control']>, ParentType, ContextType, RequireFields<Query_RootControl_By_PkArgs, 'Id'>>;
  control_group?: Resolver<Array<ResolversTypes['control_group']>, ParentType, ContextType, Partial<Query_RootControl_GroupArgs>>;
  control_group_aggregate?: Resolver<ResolversTypes['control_group_aggregate'], ParentType, ContextType, Partial<Query_RootControl_Group_AggregateArgs>>;
  control_group_audit?: Resolver<Array<ResolversTypes['control_group_audit']>, ParentType, ContextType, Partial<Query_RootControl_Group_AuditArgs>>;
  control_group_audit_by_pk?: Resolver<Maybe<ResolversTypes['control_group_audit']>, ParentType, ContextType, RequireFields<Query_RootControl_Group_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  control_group_by_pk?: Resolver<Maybe<ResolversTypes['control_group']>, ParentType, ContextType, RequireFields<Query_RootControl_Group_By_PkArgs, 'Id'>>;
  control_parent?: Resolver<Array<ResolversTypes['control_parent']>, ParentType, ContextType, Partial<Query_RootControl_ParentArgs>>;
  control_parent_aggregate?: Resolver<ResolversTypes['control_parent_aggregate'], ParentType, ContextType, Partial<Query_RootControl_Parent_AggregateArgs>>;
  control_parent_audit?: Resolver<Array<ResolversTypes['control_parent_audit']>, ParentType, ContextType, Partial<Query_RootControl_Parent_AuditArgs>>;
  control_parent_audit_by_pk?: Resolver<Maybe<ResolversTypes['control_parent_audit']>, ParentType, ContextType, RequireFields<Query_RootControl_Parent_Audit_By_PkArgs, 'ControlId' | 'ModifiedAtTimestamp' | 'ParentId'>>;
  control_parent_by_pk?: Resolver<Maybe<ResolversTypes['control_parent']>, ParentType, ContextType, RequireFields<Query_RootControl_Parent_By_PkArgs, 'ControlId' | 'ParentId'>>;
  conversation?: Resolver<Array<ResolversTypes['conversation']>, ParentType, ContextType, Partial<Query_RootConversationArgs>>;
  conversation_audit?: Resolver<Array<ResolversTypes['conversation_audit']>, ParentType, ContextType, Partial<Query_RootConversation_AuditArgs>>;
  conversation_audit_by_pk?: Resolver<Maybe<ResolversTypes['conversation_audit']>, ParentType, ContextType, RequireFields<Query_RootConversation_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  conversation_by_pk?: Resolver<Maybe<ResolversTypes['conversation']>, ParentType, ContextType, RequireFields<Query_RootConversation_By_PkArgs, 'Id'>>;
  custom_attribute_schema?: Resolver<Array<ResolversTypes['custom_attribute_schema']>, ParentType, ContextType, Partial<Query_RootCustom_Attribute_SchemaArgs>>;
  custom_attribute_schema_audit?: Resolver<Array<ResolversTypes['custom_attribute_schema_audit']>, ParentType, ContextType, Partial<Query_RootCustom_Attribute_Schema_AuditArgs>>;
  custom_attribute_schema_audit_by_pk?: Resolver<Maybe<ResolversTypes['custom_attribute_schema_audit']>, ParentType, ContextType, RequireFields<Query_RootCustom_Attribute_Schema_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  custom_attribute_schema_by_pk?: Resolver<Maybe<ResolversTypes['custom_attribute_schema']>, ParentType, ContextType, RequireFields<Query_RootCustom_Attribute_Schema_By_PkArgs, 'Id'>>;
  custom_ribbon?: Resolver<Array<ResolversTypes['custom_ribbon']>, ParentType, ContextType, Partial<Query_RootCustom_RibbonArgs>>;
  custom_ribbon_audit?: Resolver<Array<ResolversTypes['custom_ribbon_audit']>, ParentType, ContextType, Partial<Query_RootCustom_Ribbon_AuditArgs>>;
  custom_ribbon_audit_by_pk?: Resolver<Maybe<ResolversTypes['custom_ribbon_audit']>, ParentType, ContextType, RequireFields<Query_RootCustom_Ribbon_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  custom_ribbon_by_pk?: Resolver<Maybe<ResolversTypes['custom_ribbon']>, ParentType, ContextType, RequireFields<Query_RootCustom_Ribbon_By_PkArgs, 'Id'>>;
  dashboard?: Resolver<Array<ResolversTypes['dashboard']>, ParentType, ContextType, Partial<Query_RootDashboardArgs>>;
  dashboard_audit?: Resolver<Array<ResolversTypes['dashboard_audit']>, ParentType, ContextType, Partial<Query_RootDashboard_AuditArgs>>;
  dashboard_audit_by_pk?: Resolver<Maybe<ResolversTypes['dashboard_audit']>, ParentType, ContextType, RequireFields<Query_RootDashboard_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  dashboard_by_pk?: Resolver<Maybe<ResolversTypes['dashboard']>, ParentType, ContextType, RequireFields<Query_RootDashboard_By_PkArgs, 'Id'>>;
  data_import?: Resolver<Array<ResolversTypes['data_import']>, ParentType, ContextType, Partial<Query_RootData_ImportArgs>>;
  data_import_aggregate?: Resolver<ResolversTypes['data_import_aggregate'], ParentType, ContextType, Partial<Query_RootData_Import_AggregateArgs>>;
  data_import_by_pk?: Resolver<Maybe<ResolversTypes['data_import']>, ParentType, ContextType, RequireFields<Query_RootData_Import_By_PkArgs, 'Id'>>;
  data_import_error?: Resolver<Array<ResolversTypes['data_import_error']>, ParentType, ContextType, Partial<Query_RootData_Import_ErrorArgs>>;
  data_import_error_aggregate?: Resolver<ResolversTypes['data_import_error_aggregate'], ParentType, ContextType, Partial<Query_RootData_Import_Error_AggregateArgs>>;
  data_import_error_by_pk?: Resolver<Maybe<ResolversTypes['data_import_error']>, ParentType, ContextType, RequireFields<Query_RootData_Import_Error_By_PkArgs, 'Id'>>;
  department_audit?: Resolver<Array<ResolversTypes['department_audit']>, ParentType, ContextType, Partial<Query_RootDepartment_AuditArgs>>;
  department_audit_by_pk?: Resolver<Maybe<ResolversTypes['department_audit']>, ParentType, ContextType, RequireFields<Query_RootDepartment_Audit_By_PkArgs, 'DepartmentTypeId' | 'ModifiedAtTimestamp' | 'ParentId'>>;
  department_type?: Resolver<Array<ResolversTypes['department_type']>, ParentType, ContextType, Partial<Query_RootDepartment_TypeArgs>>;
  department_type_audit?: Resolver<Array<ResolversTypes['department_type_audit']>, ParentType, ContextType, Partial<Query_RootDepartment_Type_AuditArgs>>;
  department_type_audit_by_pk?: Resolver<Maybe<ResolversTypes['department_type_audit']>, ParentType, ContextType, RequireFields<Query_RootDepartment_Type_Audit_By_PkArgs, 'DepartmentTypeId' | 'ModifiedAtTimestamp'>>;
  department_type_by_pk?: Resolver<Maybe<ResolversTypes['department_type']>, ParentType, ContextType, RequireFields<Query_RootDepartment_Type_By_PkArgs, 'DepartmentTypeId'>>;
  department_type_group?: Resolver<Array<ResolversTypes['department_type_group']>, ParentType, ContextType, Partial<Query_RootDepartment_Type_GroupArgs>>;
  department_type_group_audit?: Resolver<Array<ResolversTypes['department_type_group_audit']>, ParentType, ContextType, Partial<Query_RootDepartment_Type_Group_AuditArgs>>;
  department_type_group_audit_by_pk?: Resolver<Maybe<ResolversTypes['department_type_group_audit']>, ParentType, ContextType, RequireFields<Query_RootDepartment_Type_Group_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  document?: Resolver<Array<ResolversTypes['document']>, ParentType, ContextType, Partial<Query_RootDocumentArgs>>;
  document_assessment_result?: Resolver<Array<ResolversTypes['document_assessment_result']>, ParentType, ContextType, Partial<Query_RootDocument_Assessment_ResultArgs>>;
  document_assessment_result_aggregate?: Resolver<ResolversTypes['document_assessment_result_aggregate'], ParentType, ContextType, Partial<Query_RootDocument_Assessment_Result_AggregateArgs>>;
  document_assessment_result_audit?: Resolver<Array<ResolversTypes['document_assessment_result_audit']>, ParentType, ContextType, Partial<Query_RootDocument_Assessment_Result_AuditArgs>>;
  document_assessment_result_audit_aggregate?: Resolver<ResolversTypes['document_assessment_result_audit_aggregate'], ParentType, ContextType, Partial<Query_RootDocument_Assessment_Result_Audit_AggregateArgs>>;
  document_assessment_result_audit_by_pk?: Resolver<Maybe<ResolversTypes['document_assessment_result_audit']>, ParentType, ContextType, RequireFields<Query_RootDocument_Assessment_Result_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  document_assessment_result_by_pk?: Resolver<Maybe<ResolversTypes['document_assessment_result']>, ParentType, ContextType, RequireFields<Query_RootDocument_Assessment_Result_By_PkArgs, 'Id'>>;
  document_audit?: Resolver<Array<ResolversTypes['document_audit']>, ParentType, ContextType, Partial<Query_RootDocument_AuditArgs>>;
  document_audit_by_pk?: Resolver<Maybe<ResolversTypes['document_audit']>, ParentType, ContextType, RequireFields<Query_RootDocument_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  document_by_pk?: Resolver<Maybe<ResolversTypes['document']>, ParentType, ContextType, RequireFields<Query_RootDocument_By_PkArgs, 'Id'>>;
  document_file?: Resolver<Array<ResolversTypes['document_file']>, ParentType, ContextType, Partial<Query_RootDocument_FileArgs>>;
  document_file_audit?: Resolver<Array<ResolversTypes['document_file_audit']>, ParentType, ContextType, Partial<Query_RootDocument_File_AuditArgs>>;
  document_file_audit_by_pk?: Resolver<Maybe<ResolversTypes['document_file_audit']>, ParentType, ContextType, RequireFields<Query_RootDocument_File_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  document_file_by_pk?: Resolver<Maybe<ResolversTypes['document_file']>, ParentType, ContextType, RequireFields<Query_RootDocument_File_By_PkArgs, 'Id'>>;
  document_linked_document?: Resolver<Array<ResolversTypes['document_linked_document']>, ParentType, ContextType, Partial<Query_RootDocument_Linked_DocumentArgs>>;
  document_linked_document_audit?: Resolver<Array<ResolversTypes['document_linked_document_audit']>, ParentType, ContextType, Partial<Query_RootDocument_Linked_Document_AuditArgs>>;
  document_linked_document_audit_by_pk?: Resolver<Maybe<ResolversTypes['document_linked_document_audit']>, ParentType, ContextType, RequireFields<Query_RootDocument_Linked_Document_Audit_By_PkArgs, 'DocumentId' | 'LinkedDocumentId' | 'ModifiedAtTimestamp'>>;
  document_linked_document_by_pk?: Resolver<Maybe<ResolversTypes['document_linked_document']>, ParentType, ContextType, RequireFields<Query_RootDocument_Linked_Document_By_PkArgs, 'DocumentId' | 'LinkedDocumentId'>>;
  file?: Resolver<Array<ResolversTypes['file']>, ParentType, ContextType, Partial<Query_RootFileArgs>>;
  file_aggregate?: Resolver<ResolversTypes['file_aggregate'], ParentType, ContextType, Partial<Query_RootFile_AggregateArgs>>;
  file_audit?: Resolver<Array<ResolversTypes['file_audit']>, ParentType, ContextType, Partial<Query_RootFile_AuditArgs>>;
  file_audit_by_pk?: Resolver<Maybe<ResolversTypes['file_audit']>, ParentType, ContextType, RequireFields<Query_RootFile_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  file_by_pk?: Resolver<Maybe<ResolversTypes['file']>, ParentType, ContextType, RequireFields<Query_RootFile_By_PkArgs, 'Id'>>;
  form_configuration?: Resolver<Array<ResolversTypes['form_configuration']>, ParentType, ContextType, Partial<Query_RootForm_ConfigurationArgs>>;
  form_configuration_audit?: Resolver<Array<ResolversTypes['form_configuration_audit']>, ParentType, ContextType, Partial<Query_RootForm_Configuration_AuditArgs>>;
  form_configuration_audit_by_pk?: Resolver<Maybe<ResolversTypes['form_configuration_audit']>, ParentType, ContextType, RequireFields<Query_RootForm_Configuration_Audit_By_PkArgs, 'ModifiedAtTimestamp' | 'ParentType'>>;
  form_field_configuration?: Resolver<Array<ResolversTypes['form_field_configuration']>, ParentType, ContextType, Partial<Query_RootForm_Field_ConfigurationArgs>>;
  form_field_configuration_audit?: Resolver<Array<ResolversTypes['form_field_configuration_audit']>, ParentType, ContextType, Partial<Query_RootForm_Field_Configuration_AuditArgs>>;
  form_field_configuration_audit_by_pk?: Resolver<Maybe<ResolversTypes['form_field_configuration_audit']>, ParentType, ContextType, RequireFields<Query_RootForm_Field_Configuration_Audit_By_PkArgs, 'FieldId' | 'FormConfigurationParentType' | 'ModifiedAtTimestamp'>>;
  form_field_ordering?: Resolver<Array<ResolversTypes['form_field_ordering']>, ParentType, ContextType, Partial<Query_RootForm_Field_OrderingArgs>>;
  form_field_ordering_audit?: Resolver<Array<ResolversTypes['form_field_ordering_audit']>, ParentType, ContextType, Partial<Query_RootForm_Field_Ordering_AuditArgs>>;
  form_field_ordering_audit_by_pk?: Resolver<Maybe<ResolversTypes['form_field_ordering_audit']>, ParentType, ContextType, RequireFields<Query_RootForm_Field_Ordering_Audit_By_PkArgs, 'FieldId' | 'FormConfigurationParentType' | 'ModifiedAtTimestamp'>>;
  impact?: Resolver<Array<ResolversTypes['impact']>, ParentType, ContextType, Partial<Query_RootImpactArgs>>;
  impact_aggregate?: Resolver<ResolversTypes['impact_aggregate'], ParentType, ContextType, Partial<Query_RootImpact_AggregateArgs>>;
  impact_audit?: Resolver<Array<ResolversTypes['impact_audit']>, ParentType, ContextType, Partial<Query_RootImpact_AuditArgs>>;
  impact_audit_by_pk?: Resolver<Maybe<ResolversTypes['impact_audit']>, ParentType, ContextType, RequireFields<Query_RootImpact_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  impact_by_pk?: Resolver<Maybe<ResolversTypes['impact']>, ParentType, ContextType, RequireFields<Query_RootImpact_By_PkArgs, 'Id'>>;
  impact_parent?: Resolver<Array<ResolversTypes['impact_parent']>, ParentType, ContextType, Partial<Query_RootImpact_ParentArgs>>;
  impact_parent_aggregate?: Resolver<ResolversTypes['impact_parent_aggregate'], ParentType, ContextType, Partial<Query_RootImpact_Parent_AggregateArgs>>;
  impact_parent_audit?: Resolver<Array<ResolversTypes['impact_parent_audit']>, ParentType, ContextType, Partial<Query_RootImpact_Parent_AuditArgs>>;
  impact_parent_audit_by_pk?: Resolver<Maybe<ResolversTypes['impact_parent_audit']>, ParentType, ContextType, RequireFields<Query_RootImpact_Parent_Audit_By_PkArgs, 'ImpactId' | 'ModifiedAtTimestamp' | 'ParentId'>>;
  impact_parent_by_pk?: Resolver<Maybe<ResolversTypes['impact_parent']>, ParentType, ContextType, RequireFields<Query_RootImpact_Parent_By_PkArgs, 'ImpactId' | 'ParentId'>>;
  impact_rating?: Resolver<Array<ResolversTypes['impact_rating']>, ParentType, ContextType, Partial<Query_RootImpact_RatingArgs>>;
  impact_rating_aggregate?: Resolver<ResolversTypes['impact_rating_aggregate'], ParentType, ContextType, Partial<Query_RootImpact_Rating_AggregateArgs>>;
  impact_rating_audit?: Resolver<Array<ResolversTypes['impact_rating_audit']>, ParentType, ContextType, Partial<Query_RootImpact_Rating_AuditArgs>>;
  impact_rating_audit_by_pk?: Resolver<Maybe<ResolversTypes['impact_rating_audit']>, ParentType, ContextType, RequireFields<Query_RootImpact_Rating_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  impact_rating_by_pk?: Resolver<Maybe<ResolversTypes['impact_rating']>, ParentType, ContextType, RequireFields<Query_RootImpact_Rating_By_PkArgs, 'Id'>>;
  indicator?: Resolver<Array<ResolversTypes['indicator']>, ParentType, ContextType, Partial<Query_RootIndicatorArgs>>;
  indicator_audit?: Resolver<Array<ResolversTypes['indicator_audit']>, ParentType, ContextType, Partial<Query_RootIndicator_AuditArgs>>;
  indicator_audit_by_pk?: Resolver<Maybe<ResolversTypes['indicator_audit']>, ParentType, ContextType, RequireFields<Query_RootIndicator_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  indicator_by_pk?: Resolver<Maybe<ResolversTypes['indicator']>, ParentType, ContextType, RequireFields<Query_RootIndicator_By_PkArgs, 'Id'>>;
  indicator_parent?: Resolver<Array<ResolversTypes['indicator_parent']>, ParentType, ContextType, Partial<Query_RootIndicator_ParentArgs>>;
  indicator_parent_aggregate?: Resolver<ResolversTypes['indicator_parent_aggregate'], ParentType, ContextType, Partial<Query_RootIndicator_Parent_AggregateArgs>>;
  indicator_parent_by_pk?: Resolver<Maybe<ResolversTypes['indicator_parent']>, ParentType, ContextType, RequireFields<Query_RootIndicator_Parent_By_PkArgs, 'IndicatorId' | 'ParentId'>>;
  indicator_result?: Resolver<Array<ResolversTypes['indicator_result']>, ParentType, ContextType, Partial<Query_RootIndicator_ResultArgs>>;
  indicator_result_audit?: Resolver<Array<ResolversTypes['indicator_result_audit']>, ParentType, ContextType, Partial<Query_RootIndicator_Result_AuditArgs>>;
  indicator_result_audit_by_pk?: Resolver<Maybe<ResolversTypes['indicator_result_audit']>, ParentType, ContextType, RequireFields<Query_RootIndicator_Result_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  indicator_result_by_pk?: Resolver<Maybe<ResolversTypes['indicator_result']>, ParentType, ContextType, RequireFields<Query_RootIndicator_Result_By_PkArgs, 'Id'>>;
  indicator_type?: Resolver<Array<ResolversTypes['indicator_type']>, ParentType, ContextType, Partial<Query_RootIndicator_TypeArgs>>;
  indicator_type_by_pk?: Resolver<Maybe<ResolversTypes['indicator_type']>, ParentType, ContextType, RequireFields<Query_RootIndicator_Type_By_PkArgs, 'Value'>>;
  internal_audit_entity?: Resolver<Array<ResolversTypes['internal_audit_entity']>, ParentType, ContextType, Partial<Query_RootInternal_Audit_EntityArgs>>;
  internal_audit_entity_aggregate?: Resolver<ResolversTypes['internal_audit_entity_aggregate'], ParentType, ContextType, Partial<Query_RootInternal_Audit_Entity_AggregateArgs>>;
  internal_audit_entity_by_pk?: Resolver<Maybe<ResolversTypes['internal_audit_entity']>, ParentType, ContextType, RequireFields<Query_RootInternal_Audit_Entity_By_PkArgs, 'Id'>>;
  internal_audit_report?: Resolver<Array<ResolversTypes['internal_audit_report']>, ParentType, ContextType, Partial<Query_RootInternal_Audit_ReportArgs>>;
  internal_audit_report_aggregate?: Resolver<ResolversTypes['internal_audit_report_aggregate'], ParentType, ContextType, Partial<Query_RootInternal_Audit_Report_AggregateArgs>>;
  internal_audit_report_by_pk?: Resolver<Maybe<ResolversTypes['internal_audit_report']>, ParentType, ContextType, RequireFields<Query_RootInternal_Audit_Report_By_PkArgs, 'Id'>>;
  issue?: Resolver<Array<ResolversTypes['issue']>, ParentType, ContextType, Partial<Query_RootIssueArgs>>;
  issue_aggregate?: Resolver<ResolversTypes['issue_aggregate'], ParentType, ContextType, Partial<Query_RootIssue_AggregateArgs>>;
  issue_assessment?: Resolver<Array<ResolversTypes['issue_assessment']>, ParentType, ContextType, Partial<Query_RootIssue_AssessmentArgs>>;
  issue_assessment_aggregate?: Resolver<ResolversTypes['issue_assessment_aggregate'], ParentType, ContextType, Partial<Query_RootIssue_Assessment_AggregateArgs>>;
  issue_assessment_audit?: Resolver<Array<ResolversTypes['issue_assessment_audit']>, ParentType, ContextType, Partial<Query_RootIssue_Assessment_AuditArgs>>;
  issue_assessment_audit_by_pk?: Resolver<Maybe<ResolversTypes['issue_assessment_audit']>, ParentType, ContextType, RequireFields<Query_RootIssue_Assessment_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  issue_assessment_by_pk?: Resolver<Maybe<ResolversTypes['issue_assessment']>, ParentType, ContextType, RequireFields<Query_RootIssue_Assessment_By_PkArgs, 'Id'>>;
  issue_audit?: Resolver<Array<ResolversTypes['issue_audit']>, ParentType, ContextType, Partial<Query_RootIssue_AuditArgs>>;
  issue_audit_by_pk?: Resolver<Maybe<ResolversTypes['issue_audit']>, ParentType, ContextType, RequireFields<Query_RootIssue_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  issue_by_pk?: Resolver<Maybe<ResolversTypes['issue']>, ParentType, ContextType, RequireFields<Query_RootIssue_By_PkArgs, 'Id'>>;
  issue_parent?: Resolver<Array<ResolversTypes['issue_parent']>, ParentType, ContextType, Partial<Query_RootIssue_ParentArgs>>;
  issue_parent_aggregate?: Resolver<ResolversTypes['issue_parent_aggregate'], ParentType, ContextType, Partial<Query_RootIssue_Parent_AggregateArgs>>;
  issue_parent_audit?: Resolver<Array<ResolversTypes['issue_parent_audit']>, ParentType, ContextType, Partial<Query_RootIssue_Parent_AuditArgs>>;
  issue_parent_audit_by_pk?: Resolver<Maybe<ResolversTypes['issue_parent_audit']>, ParentType, ContextType, RequireFields<Query_RootIssue_Parent_Audit_By_PkArgs, 'IssueId' | 'ModifiedAtTimestamp' | 'ParentId'>>;
  issue_parent_by_pk?: Resolver<Maybe<ResolversTypes['issue_parent']>, ParentType, ContextType, RequireFields<Query_RootIssue_Parent_By_PkArgs, 'IssueId' | 'ParentId'>>;
  issue_update?: Resolver<Array<ResolversTypes['issue_update']>, ParentType, ContextType, Partial<Query_RootIssue_UpdateArgs>>;
  issue_update_audit?: Resolver<Array<ResolversTypes['issue_update_audit']>, ParentType, ContextType, Partial<Query_RootIssue_Update_AuditArgs>>;
  issue_update_audit_by_pk?: Resolver<Maybe<ResolversTypes['issue_update_audit']>, ParentType, ContextType, RequireFields<Query_RootIssue_Update_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  issue_update_by_pk?: Resolver<Maybe<ResolversTypes['issue_update']>, ParentType, ContextType, RequireFields<Query_RootIssue_Update_By_PkArgs, 'Id'>>;
  linked_item?: Resolver<Array<ResolversTypes['linked_item']>, ParentType, ContextType, Partial<Query_RootLinked_ItemArgs>>;
  linked_item_by_pk?: Resolver<Maybe<ResolversTypes['linked_item']>, ParentType, ContextType, RequireFields<Query_RootLinked_Item_By_PkArgs, 'Source' | 'Target'>>;
  node?: Resolver<Array<ResolversTypes['node']>, ParentType, ContextType, Partial<Query_RootNodeArgs>>;
  node_aggregate?: Resolver<ResolversTypes['node_aggregate'], ParentType, ContextType, Partial<Query_RootNode_AggregateArgs>>;
  node_by_pk?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType, RequireFields<Query_RootNode_By_PkArgs, 'Id'>>;
  notificationPreferences?: Resolver<ResolversTypes['NotificationPreferencesOutput'], ParentType, ContextType>;
  obligation?: Resolver<Array<ResolversTypes['obligation']>, ParentType, ContextType, Partial<Query_RootObligationArgs>>;
  obligation_aggregate?: Resolver<ResolversTypes['obligation_aggregate'], ParentType, ContextType, Partial<Query_RootObligation_AggregateArgs>>;
  obligation_assessment_result?: Resolver<Array<ResolversTypes['obligation_assessment_result']>, ParentType, ContextType, Partial<Query_RootObligation_Assessment_ResultArgs>>;
  obligation_assessment_result_aggregate?: Resolver<ResolversTypes['obligation_assessment_result_aggregate'], ParentType, ContextType, Partial<Query_RootObligation_Assessment_Result_AggregateArgs>>;
  obligation_assessment_result_audit?: Resolver<Array<ResolversTypes['obligation_assessment_result_audit']>, ParentType, ContextType, Partial<Query_RootObligation_Assessment_Result_AuditArgs>>;
  obligation_assessment_result_audit_by_pk?: Resolver<Maybe<ResolversTypes['obligation_assessment_result_audit']>, ParentType, ContextType, RequireFields<Query_RootObligation_Assessment_Result_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  obligation_assessment_result_by_pk?: Resolver<Maybe<ResolversTypes['obligation_assessment_result']>, ParentType, ContextType, RequireFields<Query_RootObligation_Assessment_Result_By_PkArgs, 'Id'>>;
  obligation_assessment_status?: Resolver<Array<ResolversTypes['obligation_assessment_status']>, ParentType, ContextType, Partial<Query_RootObligation_Assessment_StatusArgs>>;
  obligation_assessment_status_by_pk?: Resolver<Maybe<ResolversTypes['obligation_assessment_status']>, ParentType, ContextType, RequireFields<Query_RootObligation_Assessment_Status_By_PkArgs, 'Value'>>;
  obligation_audit?: Resolver<Array<ResolversTypes['obligation_audit']>, ParentType, ContextType, Partial<Query_RootObligation_AuditArgs>>;
  obligation_audit_aggregate?: Resolver<ResolversTypes['obligation_audit_aggregate'], ParentType, ContextType, Partial<Query_RootObligation_Audit_AggregateArgs>>;
  obligation_audit_by_pk?: Resolver<Maybe<ResolversTypes['obligation_audit']>, ParentType, ContextType, RequireFields<Query_RootObligation_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  obligation_by_pk?: Resolver<Maybe<ResolversTypes['obligation']>, ParentType, ContextType, RequireFields<Query_RootObligation_By_PkArgs, 'Id'>>;
  obligation_impact?: Resolver<Array<ResolversTypes['obligation_impact']>, ParentType, ContextType, Partial<Query_RootObligation_ImpactArgs>>;
  obligation_impact_audit?: Resolver<Array<ResolversTypes['obligation_impact_audit']>, ParentType, ContextType, Partial<Query_RootObligation_Impact_AuditArgs>>;
  obligation_impact_audit_by_pk?: Resolver<Maybe<ResolversTypes['obligation_impact_audit']>, ParentType, ContextType, RequireFields<Query_RootObligation_Impact_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  obligation_impact_by_pk?: Resolver<Maybe<ResolversTypes['obligation_impact']>, ParentType, ContextType, RequireFields<Query_RootObligation_Impact_By_PkArgs, 'Id'>>;
  obligation_type?: Resolver<Array<ResolversTypes['obligation_type']>, ParentType, ContextType, Partial<Query_RootObligation_TypeArgs>>;
  obligation_type_by_pk?: Resolver<Maybe<ResolversTypes['obligation_type']>, ParentType, ContextType, RequireFields<Query_RootObligation_Type_By_PkArgs, 'Value'>>;
  owner_audit?: Resolver<Array<ResolversTypes['owner_audit']>, ParentType, ContextType, Partial<Query_RootOwner_AuditArgs>>;
  owner_audit_by_pk?: Resolver<Maybe<ResolversTypes['owner_audit']>, ParentType, ContextType, RequireFields<Query_RootOwner_Audit_By_PkArgs, 'ModifiedAtTimestamp' | 'ParentId' | 'UserId'>>;
  owner_group_audit?: Resolver<Array<ResolversTypes['owner_group_audit']>, ParentType, ContextType, Partial<Query_RootOwner_Group_AuditArgs>>;
  owner_group_audit_by_pk?: Resolver<Maybe<ResolversTypes['owner_group_audit']>, ParentType, ContextType, RequireFields<Query_RootOwner_Group_Audit_By_PkArgs, 'ModifiedAtTimestamp' | 'ParentId' | 'UserGroupId'>>;
  questionnaire_invite?: Resolver<Array<ResolversTypes['questionnaire_invite']>, ParentType, ContextType, Partial<Query_RootQuestionnaire_InviteArgs>>;
  questionnaire_invite_by_pk?: Resolver<Maybe<ResolversTypes['questionnaire_invite']>, ParentType, ContextType, RequireFields<Query_RootQuestionnaire_Invite_By_PkArgs, 'Id'>>;
  questionnaire_template?: Resolver<Array<ResolversTypes['questionnaire_template']>, ParentType, ContextType, Partial<Query_RootQuestionnaire_TemplateArgs>>;
  questionnaire_template_by_pk?: Resolver<Maybe<ResolversTypes['questionnaire_template']>, ParentType, ContextType, RequireFields<Query_RootQuestionnaire_Template_By_PkArgs, 'Id'>>;
  questionnaire_template_version?: Resolver<Array<ResolversTypes['questionnaire_template_version']>, ParentType, ContextType, Partial<Query_RootQuestionnaire_Template_VersionArgs>>;
  questionnaire_template_version_by_pk?: Resolver<Maybe<ResolversTypes['questionnaire_template_version']>, ParentType, ContextType, RequireFields<Query_RootQuestionnaire_Template_Version_By_PkArgs, 'Id'>>;
  questionnaire_template_version_status?: Resolver<Array<ResolversTypes['questionnaire_template_version_status']>, ParentType, ContextType, Partial<Query_RootQuestionnaire_Template_Version_StatusArgs>>;
  questionnaire_template_version_status_by_pk?: Resolver<Maybe<ResolversTypes['questionnaire_template_version_status']>, ParentType, ContextType, RequireFields<Query_RootQuestionnaire_Template_Version_Status_By_PkArgs, 'Value'>>;
  relation_file?: Resolver<Array<ResolversTypes['relation_file']>, ParentType, ContextType, Partial<Query_RootRelation_FileArgs>>;
  relation_file_aggregate?: Resolver<ResolversTypes['relation_file_aggregate'], ParentType, ContextType, Partial<Query_RootRelation_File_AggregateArgs>>;
  relation_file_audit?: Resolver<Array<ResolversTypes['relation_file_audit']>, ParentType, ContextType, Partial<Query_RootRelation_File_AuditArgs>>;
  relation_file_by_pk?: Resolver<Maybe<ResolversTypes['relation_file']>, ParentType, ContextType, RequireFields<Query_RootRelation_File_By_PkArgs, 'FileId' | 'ParentId'>>;
  reportingData?: Resolver<Maybe<Array<ResolversTypes['ReportDataOutput']>>, ParentType, ContextType>;
  risk?: Resolver<Array<ResolversTypes['risk']>, ParentType, ContextType, Partial<Query_RootRiskArgs>>;
  risk_aggregate?: Resolver<ResolversTypes['risk_aggregate'], ParentType, ContextType, Partial<Query_RootRisk_AggregateArgs>>;
  risk_assessment_result?: Resolver<Array<ResolversTypes['risk_assessment_result']>, ParentType, ContextType, Partial<Query_RootRisk_Assessment_ResultArgs>>;
  risk_assessment_result_aggregate?: Resolver<ResolversTypes['risk_assessment_result_aggregate'], ParentType, ContextType, Partial<Query_RootRisk_Assessment_Result_AggregateArgs>>;
  risk_assessment_result_audit?: Resolver<Array<ResolversTypes['risk_assessment_result_audit']>, ParentType, ContextType, Partial<Query_RootRisk_Assessment_Result_AuditArgs>>;
  risk_assessment_result_audit_by_pk?: Resolver<Maybe<ResolversTypes['risk_assessment_result_audit']>, ParentType, ContextType, RequireFields<Query_RootRisk_Assessment_Result_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  risk_assessment_result_by_pk?: Resolver<Maybe<ResolversTypes['risk_assessment_result']>, ParentType, ContextType, RequireFields<Query_RootRisk_Assessment_Result_By_PkArgs, 'Id'>>;
  risk_audit?: Resolver<Array<ResolversTypes['risk_audit']>, ParentType, ContextType, Partial<Query_RootRisk_AuditArgs>>;
  risk_audit_aggregate?: Resolver<ResolversTypes['risk_audit_aggregate'], ParentType, ContextType, Partial<Query_RootRisk_Audit_AggregateArgs>>;
  risk_audit_by_pk?: Resolver<Maybe<ResolversTypes['risk_audit']>, ParentType, ContextType, RequireFields<Query_RootRisk_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  risk_by_pk?: Resolver<Maybe<ResolversTypes['risk']>, ParentType, ContextType, RequireFields<Query_RootRisk_By_PkArgs, 'Id'>>;
  risk_score?: Resolver<Array<ResolversTypes['risk_score']>, ParentType, ContextType, Partial<Query_RootRisk_ScoreArgs>>;
  risk_score_aggregate?: Resolver<ResolversTypes['risk_score_aggregate'], ParentType, ContextType, Partial<Query_RootRisk_Score_AggregateArgs>>;
  risk_score_by_pk?: Resolver<Maybe<ResolversTypes['risk_score']>, ParentType, ContextType, RequireFields<Query_RootRisk_Score_By_PkArgs, 'RiskId'>>;
  risksmart_indicator_parent_audit?: Resolver<Array<ResolversTypes['risksmart_indicator_parent_audit']>, ParentType, ContextType, Partial<Query_RootRisksmart_Indicator_Parent_AuditArgs>>;
  risksmart_indicator_parent_audit_by_pk?: Resolver<Maybe<ResolversTypes['risksmart_indicator_parent_audit']>, ParentType, ContextType, RequireFields<Query_RootRisksmart_Indicator_Parent_Audit_By_PkArgs, 'IndicatorId' | 'ModifiedAtTimestamp' | 'ParentId'>>;
  risksmart_linked_item_audit?: Resolver<Array<ResolversTypes['risksmart_linked_item_audit']>, ParentType, ContextType, Partial<Query_RootRisksmart_Linked_Item_AuditArgs>>;
  risksmart_linked_item_audit_by_pk?: Resolver<Maybe<ResolversTypes['risksmart_linked_item_audit']>, ParentType, ContextType, RequireFields<Query_RootRisksmart_Linked_Item_Audit_By_PkArgs, 'ModifiedAtTimestamp' | 'Source' | 'Target'>>;
  role_access?: Resolver<Array<ResolversTypes['role_access']>, ParentType, ContextType, Partial<Query_RootRole_AccessArgs>>;
  slackNotificationConnection?: Resolver<ResolversTypes['SlackNotificationConnectionOutput'], ParentType, ContextType>;
  tag_type?: Resolver<Array<ResolversTypes['tag_type']>, ParentType, ContextType, Partial<Query_RootTag_TypeArgs>>;
  tag_type_audit?: Resolver<Array<ResolversTypes['tag_type_audit']>, ParentType, ContextType, Partial<Query_RootTag_Type_AuditArgs>>;
  tag_type_audit_by_pk?: Resolver<Maybe<ResolversTypes['tag_type_audit']>, ParentType, ContextType, RequireFields<Query_RootTag_Type_Audit_By_PkArgs, 'ModifiedAtTimestamp' | 'TagTypeId'>>;
  tag_type_by_pk?: Resolver<Maybe<ResolversTypes['tag_type']>, ParentType, ContextType, RequireFields<Query_RootTag_Type_By_PkArgs, 'TagTypeId'>>;
  tag_type_group?: Resolver<Array<ResolversTypes['tag_type_group']>, ParentType, ContextType, Partial<Query_RootTag_Type_GroupArgs>>;
  tag_type_group_audit?: Resolver<Array<ResolversTypes['tag_type_group_audit']>, ParentType, ContextType, Partial<Query_RootTag_Type_Group_AuditArgs>>;
  tag_type_group_audit_by_pk?: Resolver<Maybe<ResolversTypes['tag_type_group_audit']>, ParentType, ContextType, RequireFields<Query_RootTag_Type_Group_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  taxonomy?: Resolver<Array<ResolversTypes['taxonomy']>, ParentType, ContextType, Partial<Query_RootTaxonomyArgs>>;
  taxonomy_aggregate?: Resolver<ResolversTypes['taxonomy_aggregate'], ParentType, ContextType, Partial<Query_RootTaxonomy_AggregateArgs>>;
  taxonomy_audit?: Resolver<Array<ResolversTypes['taxonomy_audit']>, ParentType, ContextType, Partial<Query_RootTaxonomy_AuditArgs>>;
  taxonomy_audit_aggregate?: Resolver<ResolversTypes['taxonomy_audit_aggregate'], ParentType, ContextType, Partial<Query_RootTaxonomy_Audit_AggregateArgs>>;
  taxonomy_audit_by_pk?: Resolver<Maybe<ResolversTypes['taxonomy_audit']>, ParentType, ContextType, RequireFields<Query_RootTaxonomy_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  taxonomy_by_pk?: Resolver<Maybe<ResolversTypes['taxonomy']>, ParentType, ContextType, RequireFields<Query_RootTaxonomy_By_PkArgs, 'Id'>>;
  taxonomy_org?: Resolver<Array<ResolversTypes['taxonomy_org']>, ParentType, ContextType, Partial<Query_RootTaxonomy_OrgArgs>>;
  taxonomy_org_aggregate?: Resolver<ResolversTypes['taxonomy_org_aggregate'], ParentType, ContextType, Partial<Query_RootTaxonomy_Org_AggregateArgs>>;
  taxonomy_org_by_pk?: Resolver<Maybe<ResolversTypes['taxonomy_org']>, ParentType, ContextType, RequireFields<Query_RootTaxonomy_Org_By_PkArgs, 'Id'>>;
  test_result?: Resolver<Array<ResolversTypes['test_result']>, ParentType, ContextType, Partial<Query_RootTest_ResultArgs>>;
  test_result_aggregate?: Resolver<ResolversTypes['test_result_aggregate'], ParentType, ContextType, Partial<Query_RootTest_Result_AggregateArgs>>;
  test_result_audit?: Resolver<Array<ResolversTypes['test_result_audit']>, ParentType, ContextType, Partial<Query_RootTest_Result_AuditArgs>>;
  test_result_audit_by_pk?: Resolver<Maybe<ResolversTypes['test_result_audit']>, ParentType, ContextType, RequireFields<Query_RootTest_Result_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  test_result_by_pk?: Resolver<Maybe<ResolversTypes['test_result']>, ParentType, ContextType, RequireFields<Query_RootTest_Result_By_PkArgs, 'Id'>>;
  third_party?: Resolver<Array<ResolversTypes['third_party']>, ParentType, ContextType, Partial<Query_RootThird_PartyArgs>>;
  third_party_by_pk?: Resolver<Maybe<ResolversTypes['third_party']>, ParentType, ContextType, RequireFields<Query_RootThird_Party_By_PkArgs, 'Id'>>;
  third_party_response?: Resolver<Array<ResolversTypes['third_party_response']>, ParentType, ContextType, Partial<Query_RootThird_Party_ResponseArgs>>;
  third_party_response_by_pk?: Resolver<Maybe<ResolversTypes['third_party_response']>, ParentType, ContextType, RequireFields<Query_RootThird_Party_Response_By_PkArgs, 'Id'>>;
  third_party_response_status?: Resolver<Array<ResolversTypes['third_party_response_status']>, ParentType, ContextType, Partial<Query_RootThird_Party_Response_StatusArgs>>;
  third_party_response_status_by_pk?: Resolver<Maybe<ResolversTypes['third_party_response_status']>, ParentType, ContextType, RequireFields<Query_RootThird_Party_Response_Status_By_PkArgs, 'Value'>>;
  user?: Resolver<Array<ResolversTypes['user']>, ParentType, ContextType, Partial<Query_RootUserArgs>>;
  user_aggregate?: Resolver<ResolversTypes['user_aggregate'], ParentType, ContextType, Partial<Query_RootUser_AggregateArgs>>;
  user_group?: Resolver<Array<ResolversTypes['user_group']>, ParentType, ContextType, Partial<Query_RootUser_GroupArgs>>;
  user_group_audit?: Resolver<Array<ResolversTypes['user_group_audit']>, ParentType, ContextType, Partial<Query_RootUser_Group_AuditArgs>>;
  user_group_audit_by_pk?: Resolver<Maybe<ResolversTypes['user_group_audit']>, ParentType, ContextType, RequireFields<Query_RootUser_Group_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  user_group_by_pk?: Resolver<Maybe<ResolversTypes['user_group']>, ParentType, ContextType, RequireFields<Query_RootUser_Group_By_PkArgs, 'Id'>>;
  user_group_user?: Resolver<Array<ResolversTypes['user_group_user']>, ParentType, ContextType, Partial<Query_RootUser_Group_UserArgs>>;
  user_group_user_aggregate?: Resolver<ResolversTypes['user_group_user_aggregate'], ParentType, ContextType, Partial<Query_RootUser_Group_User_AggregateArgs>>;
  user_group_user_audit?: Resolver<Array<ResolversTypes['user_group_user_audit']>, ParentType, ContextType, Partial<Query_RootUser_Group_User_AuditArgs>>;
  user_group_user_audit_by_pk?: Resolver<Maybe<ResolversTypes['user_group_user_audit']>, ParentType, ContextType, RequireFields<Query_RootUser_Group_User_Audit_By_PkArgs, 'ModifiedAtTimestamp' | 'UserGroupId' | 'UserId'>>;
  user_group_user_by_pk?: Resolver<Maybe<ResolversTypes['user_group_user']>, ParentType, ContextType, RequireFields<Query_RootUser_Group_User_By_PkArgs, 'UserGroupId' | 'UserId'>>;
  user_search_preferences?: Resolver<Array<ResolversTypes['user_search_preferences']>, ParentType, ContextType, Partial<Query_RootUser_Search_PreferencesArgs>>;
  user_search_preferences_audit?: Resolver<Array<ResolversTypes['user_search_preferences_audit']>, ParentType, ContextType, Partial<Query_RootUser_Search_Preferences_AuditArgs>>;
  user_table_preferences?: Resolver<Array<ResolversTypes['user_table_preferences']>, ParentType, ContextType, Partial<Query_RootUser_Table_PreferencesArgs>>;
};

export type Questionnaire_InviteResolvers<ContextType = any, ParentType extends ResolversParentTypes['questionnaire_invite'] = ResolversParentTypes['questionnaire_invite']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  QuestionnaireTemplateVersionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ThirdPartyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  UserEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  UserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parent?: Resolver<ResolversTypes['third_party_response'], ParentType, ContextType>;
  questionnaireTemplateVersion?: Resolver<ResolversTypes['questionnaire_template_version'], ParentType, ContextType>;
  thirdParty?: Resolver<ResolversTypes['third_party'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['auth_user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Questionnaire_Invite_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['questionnaire_invite_mutation_response'] = ResolversParentTypes['questionnaire_invite_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['questionnaire_invite']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Questionnaire_TemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['questionnaire_template'] = ResolversParentTypes['questionnaire_template']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Questionnaire_TemplateCustomAttributeDataArgs>>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<Questionnaire_TemplateAncestorContributorsArgs>>;
  contributorGroups?: Resolver<Array<ResolversTypes['contributor_group']>, ParentType, ContextType, Partial<Questionnaire_TemplateContributorGroupsArgs>>;
  contributorGroups_aggregate?: Resolver<ResolversTypes['contributor_group_aggregate'], ParentType, ContextType, Partial<Questionnaire_TemplateContributorGroups_AggregateArgs>>;
  contributors?: Resolver<Array<ResolversTypes['contributor']>, ParentType, ContextType, Partial<Questionnaire_TemplateContributorsArgs>>;
  contributors_aggregate?: Resolver<ResolversTypes['contributor_aggregate'], ParentType, ContextType, Partial<Questionnaire_TemplateContributors_AggregateArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  departments?: Resolver<Array<ResolversTypes['department']>, ParentType, ContextType, Partial<Questionnaire_TemplateDepartmentsArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  ownerGroups?: Resolver<Array<ResolversTypes['owner_group']>, ParentType, ContextType, Partial<Questionnaire_TemplateOwnerGroupsArgs>>;
  ownerGroups_aggregate?: Resolver<ResolversTypes['owner_group_aggregate'], ParentType, ContextType, Partial<Questionnaire_TemplateOwnerGroups_AggregateArgs>>;
  owners?: Resolver<Array<ResolversTypes['owner']>, ParentType, ContextType, Partial<Questionnaire_TemplateOwnersArgs>>;
  owners_aggregate?: Resolver<ResolversTypes['owner_aggregate'], ParentType, ContextType, Partial<Questionnaire_TemplateOwners_AggregateArgs>>;
  tags?: Resolver<Array<ResolversTypes['tag']>, ParentType, ContextType, Partial<Questionnaire_TemplateTagsArgs>>;
  versions?: Resolver<Array<ResolversTypes['questionnaire_template_version']>, ParentType, ContextType, Partial<Questionnaire_TemplateVersionsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Questionnaire_Template_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['questionnaire_template_mutation_response'] = ResolversParentTypes['questionnaire_template_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['questionnaire_template']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Questionnaire_Template_VersionResolvers<ContextType = any, ParentType extends ResolversParentTypes['questionnaire_template_version'] = ResolversParentTypes['questionnaire_template_version']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Questionnaire_Template_VersionCustomAttributeDataArgs>>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Schema?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<Questionnaire_Template_VersionSchemaArgs>>;
  Status?: Resolver<ResolversTypes['questionnaire_template_version_status_enum'], ParentType, ContextType>;
  UISchema?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<Questionnaire_Template_VersionUiSchemaArgs>>;
  Version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  invitations?: Resolver<Array<ResolversTypes['questionnaire_invite']>, ParentType, ContextType, Partial<Questionnaire_Template_VersionInvitationsArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['questionnaire_template']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Questionnaire_Template_Version_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['questionnaire_template_version_mutation_response'] = ResolversParentTypes['questionnaire_template_version_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['questionnaire_template_version']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Questionnaire_Template_Version_StatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['questionnaire_template_version_status'] = ResolversParentTypes['questionnaire_template_version_status']> = {
  Comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  questionnaire_template_versions?: Resolver<Array<ResolversTypes['questionnaire_template_version']>, ParentType, ContextType, Partial<Questionnaire_Template_Version_StatusQuestionnaire_Template_VersionsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Relation_FileResolvers<ContextType = any, ParentType extends ResolversParentTypes['relation_file'] = ResolversParentTypes['relation_file']> = {
  FileId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  acceptance?: Resolver<Maybe<ResolversTypes['acceptance']>, ParentType, ContextType>;
  action?: Resolver<Maybe<ResolversTypes['action']>, ParentType, ContextType>;
  appetite?: Resolver<Maybe<ResolversTypes['appetite']>, ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  document?: Resolver<Maybe<ResolversTypes['document']>, ParentType, ContextType>;
  file?: Resolver<Maybe<ResolversTypes['file']>, ParentType, ContextType>;
  issue?: Resolver<Maybe<ResolversTypes['issue']>, ParentType, ContextType>;
  issueUpdate?: Resolver<Maybe<ResolversTypes['issue_update']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  riskAssessmentResult?: Resolver<Maybe<ResolversTypes['risk_assessment_result']>, ParentType, ContextType>;
  testResult?: Resolver<Maybe<ResolversTypes['test_result']>, ParentType, ContextType>;
  thirdParty?: Resolver<Maybe<ResolversTypes['third_party']>, ParentType, ContextType>;
  thirdPartyResponse?: Resolver<Maybe<ResolversTypes['third_party_response']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Relation_File_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['relation_file_aggregate'] = ResolversParentTypes['relation_file_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['relation_file_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['relation_file']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Relation_File_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['relation_file_aggregate_fields'] = ResolversParentTypes['relation_file_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Relation_File_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['relation_file_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['relation_file_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Relation_File_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['relation_file_audit'] = ResolversParentTypes['relation_file_audit']> = {
  FileId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Relation_File_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['relation_file_max_fields'] = ResolversParentTypes['relation_file_max_fields']> = {
  FileId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Relation_File_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['relation_file_min_fields'] = ResolversParentTypes['relation_file_min_fields']> = {
  FileId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ParentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Relation_File_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['relation_file_mutation_response'] = ResolversParentTypes['relation_file_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['relation_file']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RiskResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk'] = ResolversParentTypes['risk']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<RiskCustomAttributeDataArgs>>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentRiskId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['risk_status_type_enum']>, ParentType, ContextType>;
  Tier?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Treatment?: Resolver<Maybe<ResolversTypes['risk_treatment_type_enum']>, ParentType, ContextType>;
  acceptances?: Resolver<Array<ResolversTypes['acceptance_parent']>, ParentType, ContextType, Partial<RiskAcceptancesArgs>>;
  acceptances_aggregate?: Resolver<ResolversTypes['acceptance_parent_aggregate'], ParentType, ContextType, Partial<RiskAcceptances_AggregateArgs>>;
  actions?: Resolver<Array<ResolversTypes['action_parent']>, ParentType, ContextType, Partial<RiskActionsArgs>>;
  actions_aggregate?: Resolver<ResolversTypes['action_parent_aggregate'], ParentType, ContextType, Partial<RiskActions_AggregateArgs>>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<RiskAncestorContributorsArgs>>;
  appetites?: Resolver<Array<ResolversTypes['appetite_parent']>, ParentType, ContextType, Partial<RiskAppetitesArgs>>;
  appetites_aggregate?: Resolver<ResolversTypes['appetite_parent_aggregate'], ParentType, ContextType, Partial<RiskAppetites_AggregateArgs>>;
  assessmentResults?: Resolver<Array<ResolversTypes['assessment_result_parent']>, ParentType, ContextType, Partial<RiskAssessmentResultsArgs>>;
  assessmentResults_aggregate?: Resolver<ResolversTypes['assessment_result_parent_aggregate'], ParentType, ContextType, Partial<RiskAssessmentResults_AggregateArgs>>;
  childRisks?: Resolver<Array<ResolversTypes['risk']>, ParentType, ContextType, Partial<RiskChildRisksArgs>>;
  childRisks_aggregate?: Resolver<ResolversTypes['risk_aggregate'], ParentType, ContextType, Partial<RiskChildRisks_AggregateArgs>>;
  contributorGroups?: Resolver<Array<ResolversTypes['contributor_group']>, ParentType, ContextType, Partial<RiskContributorGroupsArgs>>;
  contributorGroups_aggregate?: Resolver<ResolversTypes['contributor_group_aggregate'], ParentType, ContextType, Partial<RiskContributorGroups_AggregateArgs>>;
  contributors?: Resolver<Array<ResolversTypes['contributor']>, ParentType, ContextType, Partial<RiskContributorsArgs>>;
  contributors_aggregate?: Resolver<ResolversTypes['contributor_aggregate'], ParentType, ContextType, Partial<RiskContributors_AggregateArgs>>;
  controls?: Resolver<Array<ResolversTypes['control_parent']>, ParentType, ContextType, Partial<RiskControlsArgs>>;
  controls_aggregate?: Resolver<ResolversTypes['control_parent_aggregate'], ParentType, ContextType, Partial<RiskControls_AggregateArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  departments?: Resolver<Array<ResolversTypes['department']>, ParentType, ContextType, Partial<RiskDepartmentsArgs>>;
  impactRatings?: Resolver<Array<ResolversTypes['impact_rating']>, ParentType, ContextType, Partial<RiskImpactRatingsArgs>>;
  impactRatings_aggregate?: Resolver<ResolversTypes['impact_rating_aggregate'], ParentType, ContextType, Partial<RiskImpactRatings_AggregateArgs>>;
  indicators?: Resolver<Array<ResolversTypes['indicator_parent']>, ParentType, ContextType, Partial<RiskIndicatorsArgs>>;
  indicators_aggregate?: Resolver<ResolversTypes['indicator_parent_aggregate'], ParentType, ContextType, Partial<RiskIndicators_AggregateArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  ownerGroups?: Resolver<Array<ResolversTypes['owner_group']>, ParentType, ContextType, Partial<RiskOwnerGroupsArgs>>;
  ownerGroups_aggregate?: Resolver<ResolversTypes['owner_group_aggregate'], ParentType, ContextType, Partial<RiskOwnerGroups_AggregateArgs>>;
  owners?: Resolver<Array<ResolversTypes['owner']>, ParentType, ContextType, Partial<RiskOwnersArgs>>;
  owners_aggregate?: Resolver<ResolversTypes['owner_aggregate'], ParentType, ContextType, Partial<RiskOwners_AggregateArgs>>;
  parent?: Resolver<Maybe<ResolversTypes['risk']>, ParentType, ContextType>;
  parentNode?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType>;
  riskScore?: Resolver<Maybe<ResolversTypes['risk_score']>, ParentType, ContextType>;
  schedule?: Resolver<Maybe<ResolversTypes['schedule']>, ParentType, ContextType>;
  scheduleState?: Resolver<Maybe<ResolversTypes['schedule_state']>, ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['tag']>, ParentType, ContextType, Partial<RiskTagsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_aggregate'] = ResolversParentTypes['risk_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['risk_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['risk']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_aggregate_fields'] = ResolversParentTypes['risk_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['risk_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Risk_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['risk_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['risk_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['risk_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['risk_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['risk_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['risk_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['risk_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['risk_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['risk_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Assessment_ResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_assessment_result'] = ResolversParentTypes['risk_assessment_result']> = {
  ControlType?: Resolver<ResolversTypes['risk_assessment_result_control_type_enum'], ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Risk_Assessment_ResultCustomAttributeDataArgs>>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Impact?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Likelihood?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  RatingType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Rationale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<Risk_Assessment_ResultAncestorContributorsArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  files?: Resolver<Array<ResolversTypes['relation_file']>, ParentType, ContextType, Partial<Risk_Assessment_ResultFilesArgs>>;
  files_aggregate?: Resolver<ResolversTypes['relation_file_aggregate'], ParentType, ContextType, Partial<Risk_Assessment_ResultFiles_AggregateArgs>>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parents?: Resolver<Array<ResolversTypes['assessment_result_parent']>, ParentType, ContextType, Partial<Risk_Assessment_ResultParentsArgs>>;
  parents_aggregate?: Resolver<ResolversTypes['assessment_result_parent_aggregate'], ParentType, ContextType, Partial<Risk_Assessment_ResultParents_AggregateArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Assessment_Result_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_assessment_result_aggregate'] = ResolversParentTypes['risk_assessment_result_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['risk_assessment_result_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['risk_assessment_result']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Assessment_Result_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_assessment_result_aggregate_fields'] = ResolversParentTypes['risk_assessment_result_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['risk_assessment_result_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Risk_Assessment_Result_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['risk_assessment_result_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['risk_assessment_result_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['risk_assessment_result_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['risk_assessment_result_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['risk_assessment_result_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['risk_assessment_result_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['risk_assessment_result_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['risk_assessment_result_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['risk_assessment_result_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Assessment_Result_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_assessment_result_audit'] = ResolversParentTypes['risk_assessment_result_audit']> = {
  ControlType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Risk_Assessment_Result_AuditCustomAttributeDataArgs>>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Impact?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Likelihood?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  RatingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rationale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Assessment_Result_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_assessment_result_avg_fields'] = ResolversParentTypes['risk_assessment_result_avg_fields']> = {
  Impact?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Likelihood?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Assessment_Result_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_assessment_result_max_fields'] = ResolversParentTypes['risk_assessment_result_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Impact?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Likelihood?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  RatingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rationale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Assessment_Result_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_assessment_result_min_fields'] = ResolversParentTypes['risk_assessment_result_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Impact?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Likelihood?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  RatingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rationale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Assessment_Result_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_assessment_result_mutation_response'] = ResolversParentTypes['risk_assessment_result_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['risk_assessment_result']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Assessment_Result_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_assessment_result_stddev_fields'] = ResolversParentTypes['risk_assessment_result_stddev_fields']> = {
  Impact?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Likelihood?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Assessment_Result_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_assessment_result_stddev_pop_fields'] = ResolversParentTypes['risk_assessment_result_stddev_pop_fields']> = {
  Impact?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Likelihood?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Assessment_Result_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_assessment_result_stddev_samp_fields'] = ResolversParentTypes['risk_assessment_result_stddev_samp_fields']> = {
  Impact?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Likelihood?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Assessment_Result_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_assessment_result_sum_fields'] = ResolversParentTypes['risk_assessment_result_sum_fields']> = {
  Impact?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Likelihood?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Assessment_Result_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_assessment_result_var_pop_fields'] = ResolversParentTypes['risk_assessment_result_var_pop_fields']> = {
  Impact?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Likelihood?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Assessment_Result_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_assessment_result_var_samp_fields'] = ResolversParentTypes['risk_assessment_result_var_samp_fields']> = {
  Impact?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Likelihood?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Assessment_Result_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_assessment_result_variance_fields'] = ResolversParentTypes['risk_assessment_result_variance_fields']> = {
  Impact?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Likelihood?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_audit'] = ResolversParentTypes['risk_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Risk_AuditCustomAttributeDataArgs>>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentRiskId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Tier?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Treatment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  acceptanceAudits?: Resolver<Array<ResolversTypes['acceptance_parent_audit']>, ParentType, ContextType, Partial<Risk_AuditAcceptanceAuditsArgs>>;
  actionAudits?: Resolver<Array<ResolversTypes['action_parent_audit']>, ParentType, ContextType, Partial<Risk_AuditActionAuditsArgs>>;
  appetiteAudits?: Resolver<Array<ResolversTypes['appetite_parent_audit']>, ParentType, ContextType, Partial<Risk_AuditAppetiteAuditsArgs>>;
  appetiteAudits_aggregate?: Resolver<ResolversTypes['appetite_parent_audit_aggregate'], ParentType, ContextType, Partial<Risk_AuditAppetiteAudits_AggregateArgs>>;
  assessmentResultAudits?: Resolver<Array<ResolversTypes['assessment_result_parent_audit']>, ParentType, ContextType, Partial<Risk_AuditAssessmentResultAuditsArgs>>;
  contributorAudits?: Resolver<Array<ResolversTypes['contributor_audit']>, ParentType, ContextType, Partial<Risk_AuditContributorAuditsArgs>>;
  contributorAudits_aggregate?: Resolver<ResolversTypes['contributor_audit_aggregate'], ParentType, ContextType, Partial<Risk_AuditContributorAudits_AggregateArgs>>;
  contributorGroupAudits?: Resolver<Array<ResolversTypes['contributor_group_audit']>, ParentType, ContextType, Partial<Risk_AuditContributorGroupAuditsArgs>>;
  contributorGroupAudits_aggregate?: Resolver<ResolversTypes['contributor_group_audit_aggregate'], ParentType, ContextType, Partial<Risk_AuditContributorGroupAudits_AggregateArgs>>;
  controlAudits?: Resolver<Array<ResolversTypes['control_parent_audit']>, ParentType, ContextType, Partial<Risk_AuditControlAuditsArgs>>;
  departmentAudits?: Resolver<Array<ResolversTypes['department_audit']>, ParentType, ContextType, Partial<Risk_AuditDepartmentAuditsArgs>>;
  impactRatingAudits?: Resolver<Array<ResolversTypes['impact_rating_audit']>, ParentType, ContextType, Partial<Risk_AuditImpactRatingAuditsArgs>>;
  indicatorAudits?: Resolver<Array<ResolversTypes['risksmart_indicator_parent_audit']>, ParentType, ContextType, Partial<Risk_AuditIndicatorAuditsArgs>>;
  ownerAudits?: Resolver<Array<ResolversTypes['owner_audit']>, ParentType, ContextType, Partial<Risk_AuditOwnerAuditsArgs>>;
  ownerGroupAudits?: Resolver<Array<ResolversTypes['owner_group_audit']>, ParentType, ContextType, Partial<Risk_AuditOwnerGroupAuditsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Audit_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_audit_aggregate'] = ResolversParentTypes['risk_audit_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['risk_audit_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['risk_audit']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Audit_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_audit_aggregate_fields'] = ResolversParentTypes['risk_audit_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['risk_audit_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Risk_Audit_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['risk_audit_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['risk_audit_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['risk_audit_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['risk_audit_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['risk_audit_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['risk_audit_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['risk_audit_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['risk_audit_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['risk_audit_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Audit_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_audit_avg_fields'] = ResolversParentTypes['risk_audit_avg_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Tier?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Audit_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_audit_max_fields'] = ResolversParentTypes['risk_audit_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentRiskId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Tier?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Treatment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Audit_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_audit_min_fields'] = ResolversParentTypes['risk_audit_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentRiskId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Tier?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Treatment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Audit_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_audit_stddev_fields'] = ResolversParentTypes['risk_audit_stddev_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Tier?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Audit_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_audit_stddev_pop_fields'] = ResolversParentTypes['risk_audit_stddev_pop_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Tier?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Audit_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_audit_stddev_samp_fields'] = ResolversParentTypes['risk_audit_stddev_samp_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Tier?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Audit_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_audit_sum_fields'] = ResolversParentTypes['risk_audit_sum_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Tier?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Audit_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_audit_var_pop_fields'] = ResolversParentTypes['risk_audit_var_pop_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Tier?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Audit_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_audit_var_samp_fields'] = ResolversParentTypes['risk_audit_var_samp_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Tier?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Audit_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_audit_variance_fields'] = ResolversParentTypes['risk_audit_variance_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Tier?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_avg_fields'] = ResolversParentTypes['risk_avg_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Tier?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_max_fields'] = ResolversParentTypes['risk_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentRiskId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Tier?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_min_fields'] = ResolversParentTypes['risk_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentRiskId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Tier?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_ScoreResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_score'] = ResolversParentTypes['risk_score']> = {
  InherentScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  OrgKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ResidualScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  RiskId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  risk?: Resolver<Maybe<ResolversTypes['risk']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Score_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_score_aggregate'] = ResolversParentTypes['risk_score_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['risk_score_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['risk_score']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Score_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_score_aggregate_fields'] = ResolversParentTypes['risk_score_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['risk_score_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Risk_Score_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['risk_score_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['risk_score_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['risk_score_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['risk_score_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['risk_score_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['risk_score_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['risk_score_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['risk_score_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['risk_score_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Score_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_score_avg_fields'] = ResolversParentTypes['risk_score_avg_fields']> = {
  InherentScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ResidualScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Score_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_score_max_fields'] = ResolversParentTypes['risk_score_max_fields']> = {
  InherentScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OrgKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResidualScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  RiskId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Score_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_score_min_fields'] = ResolversParentTypes['risk_score_min_fields']> = {
  InherentScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OrgKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResidualScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  RiskId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Score_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_score_stddev_fields'] = ResolversParentTypes['risk_score_stddev_fields']> = {
  InherentScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ResidualScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Score_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_score_stddev_pop_fields'] = ResolversParentTypes['risk_score_stddev_pop_fields']> = {
  InherentScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ResidualScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Score_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_score_stddev_samp_fields'] = ResolversParentTypes['risk_score_stddev_samp_fields']> = {
  InherentScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ResidualScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Score_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_score_sum_fields'] = ResolversParentTypes['risk_score_sum_fields']> = {
  InherentScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  ResidualScore?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Score_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_score_var_pop_fields'] = ResolversParentTypes['risk_score_var_pop_fields']> = {
  InherentScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ResidualScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Score_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_score_var_samp_fields'] = ResolversParentTypes['risk_score_var_samp_fields']> = {
  InherentScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ResidualScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Score_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_score_variance_fields'] = ResolversParentTypes['risk_score_variance_fields']> = {
  InherentScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ResidualScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_stddev_fields'] = ResolversParentTypes['risk_stddev_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Tier?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_stddev_pop_fields'] = ResolversParentTypes['risk_stddev_pop_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Tier?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_stddev_samp_fields'] = ResolversParentTypes['risk_stddev_samp_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Tier?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_sum_fields'] = ResolversParentTypes['risk_sum_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Tier?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_var_pop_fields'] = ResolversParentTypes['risk_var_pop_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Tier?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_var_samp_fields'] = ResolversParentTypes['risk_var_samp_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Tier?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risk_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['risk_variance_fields'] = ResolversParentTypes['risk_variance_fields']> = {
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Tier?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risksmart_Indicator_Parent_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['risksmart_indicator_parent_audit'] = ResolversParentTypes['risksmart_indicator_parent_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  IndicatorId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Risksmart_Linked_Item_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['risksmart_linked_item_audit'] = ResolversParentTypes['risksmart_linked_item_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RelationshipType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Source?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Target?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Role_AccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['role_access'] = ResolversParentTypes['role_access']> = {
  AccessType?: Resolver<ResolversTypes['access_type_enum'], ParentType, ContextType>;
  ContributorType?: Resolver<ResolversTypes['contributor_type_enum'], ParentType, ContextType>;
  ObjectType?: Resolver<ResolversTypes['parent_type_enum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['schedule'] = ResolversParentTypes['schedule']> = {
  Frequency?: Resolver<Maybe<ResolversTypes['test_frequency_enum']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ManualDueDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  StartDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  TimeToCompleteUnit?: Resolver<Maybe<ResolversTypes['unit_of_time_enum']>, ParentType, ContextType>;
  TimeToCompleteValue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Schedule_StateResolvers<ContextType = any, ParentType extends ResolversParentTypes['schedule_state'] = ResolversParentTypes['schedule_state']> = {
  DueDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  LatestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OverdueDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType>;
  schedule?: Resolver<Maybe<ResolversTypes['schedule']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface SmallintScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['smallint'], any> {
  name: 'smallint';
}

export type Subscription_RootResolvers<ContextType = any, ParentType extends ResolversParentTypes['subscription_root'] = ResolversParentTypes['subscription_root']> = {
  acceptance?: SubscriptionResolver<Array<ResolversTypes['acceptance']>, "acceptance", ParentType, ContextType, Partial<Subscription_RootAcceptanceArgs>>;
  acceptance_aggregate?: SubscriptionResolver<ResolversTypes['acceptance_aggregate'], "acceptance_aggregate", ParentType, ContextType, Partial<Subscription_RootAcceptance_AggregateArgs>>;
  acceptance_audit?: SubscriptionResolver<Array<ResolversTypes['acceptance_audit']>, "acceptance_audit", ParentType, ContextType, Partial<Subscription_RootAcceptance_AuditArgs>>;
  acceptance_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['acceptance_audit']>, "acceptance_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAcceptance_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  acceptance_audit_stream?: SubscriptionResolver<Array<ResolversTypes['acceptance_audit']>, "acceptance_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootAcceptance_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  acceptance_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['acceptance']>, "acceptance_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAcceptance_By_PkArgs, 'Id'>>;
  acceptance_parent?: SubscriptionResolver<Array<ResolversTypes['acceptance_parent']>, "acceptance_parent", ParentType, ContextType, Partial<Subscription_RootAcceptance_ParentArgs>>;
  acceptance_parent_aggregate?: SubscriptionResolver<ResolversTypes['acceptance_parent_aggregate'], "acceptance_parent_aggregate", ParentType, ContextType, Partial<Subscription_RootAcceptance_Parent_AggregateArgs>>;
  acceptance_parent_audit?: SubscriptionResolver<Array<ResolversTypes['acceptance_parent_audit']>, "acceptance_parent_audit", ParentType, ContextType, Partial<Subscription_RootAcceptance_Parent_AuditArgs>>;
  acceptance_parent_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['acceptance_parent_audit']>, "acceptance_parent_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAcceptance_Parent_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp' | 'ParentId'>>;
  acceptance_parent_audit_stream?: SubscriptionResolver<Array<ResolversTypes['acceptance_parent_audit']>, "acceptance_parent_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootAcceptance_Parent_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  acceptance_parent_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['acceptance_parent']>, "acceptance_parent_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAcceptance_Parent_By_PkArgs, 'Id' | 'ParentId'>>;
  acceptance_parent_stream?: SubscriptionResolver<Array<ResolversTypes['acceptance_parent']>, "acceptance_parent_stream", ParentType, ContextType, RequireFields<Subscription_RootAcceptance_Parent_StreamArgs, 'batch_size' | 'cursor'>>;
  acceptance_stream?: SubscriptionResolver<Array<ResolversTypes['acceptance']>, "acceptance_stream", ParentType, ContextType, RequireFields<Subscription_RootAcceptance_StreamArgs, 'batch_size' | 'cursor'>>;
  action?: SubscriptionResolver<Array<ResolversTypes['action']>, "action", ParentType, ContextType, Partial<Subscription_RootActionArgs>>;
  action_aggregate?: SubscriptionResolver<ResolversTypes['action_aggregate'], "action_aggregate", ParentType, ContextType, Partial<Subscription_RootAction_AggregateArgs>>;
  action_audit?: SubscriptionResolver<Array<ResolversTypes['action_audit']>, "action_audit", ParentType, ContextType, Partial<Subscription_RootAction_AuditArgs>>;
  action_audit_aggregate?: SubscriptionResolver<ResolversTypes['action_audit_aggregate'], "action_audit_aggregate", ParentType, ContextType, Partial<Subscription_RootAction_Audit_AggregateArgs>>;
  action_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['action_audit']>, "action_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAction_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  action_audit_stream?: SubscriptionResolver<Array<ResolversTypes['action_audit']>, "action_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootAction_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  action_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['action']>, "action_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAction_By_PkArgs, 'Id'>>;
  action_parent?: SubscriptionResolver<Array<ResolversTypes['action_parent']>, "action_parent", ParentType, ContextType, Partial<Subscription_RootAction_ParentArgs>>;
  action_parent_aggregate?: SubscriptionResolver<ResolversTypes['action_parent_aggregate'], "action_parent_aggregate", ParentType, ContextType, Partial<Subscription_RootAction_Parent_AggregateArgs>>;
  action_parent_audit?: SubscriptionResolver<Array<ResolversTypes['action_parent_audit']>, "action_parent_audit", ParentType, ContextType, Partial<Subscription_RootAction_Parent_AuditArgs>>;
  action_parent_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['action_parent_audit']>, "action_parent_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAction_Parent_Audit_By_PkArgs, 'ActionId' | 'ModifiedAtTimestamp' | 'ParentId'>>;
  action_parent_audit_stream?: SubscriptionResolver<Array<ResolversTypes['action_parent_audit']>, "action_parent_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootAction_Parent_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  action_parent_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['action_parent']>, "action_parent_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAction_Parent_By_PkArgs, 'ActionId' | 'ParentId'>>;
  action_parent_stream?: SubscriptionResolver<Array<ResolversTypes['action_parent']>, "action_parent_stream", ParentType, ContextType, RequireFields<Subscription_RootAction_Parent_StreamArgs, 'batch_size' | 'cursor'>>;
  action_stream?: SubscriptionResolver<Array<ResolversTypes['action']>, "action_stream", ParentType, ContextType, RequireFields<Subscription_RootAction_StreamArgs, 'batch_size' | 'cursor'>>;
  action_update?: SubscriptionResolver<Array<ResolversTypes['action_update']>, "action_update", ParentType, ContextType, Partial<Subscription_RootAction_UpdateArgs>>;
  action_update_aggregate?: SubscriptionResolver<ResolversTypes['action_update_aggregate'], "action_update_aggregate", ParentType, ContextType, Partial<Subscription_RootAction_Update_AggregateArgs>>;
  action_update_audit?: SubscriptionResolver<Array<ResolversTypes['action_update_audit']>, "action_update_audit", ParentType, ContextType, Partial<Subscription_RootAction_Update_AuditArgs>>;
  action_update_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['action_update_audit']>, "action_update_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAction_Update_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  action_update_audit_stream?: SubscriptionResolver<Array<ResolversTypes['action_update_audit']>, "action_update_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootAction_Update_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  action_update_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['action_update']>, "action_update_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAction_Update_By_PkArgs, 'Id'>>;
  action_update_stream?: SubscriptionResolver<Array<ResolversTypes['action_update']>, "action_update_stream", ParentType, ContextType, RequireFields<Subscription_RootAction_Update_StreamArgs, 'batch_size' | 'cursor'>>;
  action_update_summary?: SubscriptionResolver<Array<ResolversTypes['action_update_summary']>, "action_update_summary", ParentType, ContextType, Partial<Subscription_RootAction_Update_SummaryArgs>>;
  action_update_summary_aggregate?: SubscriptionResolver<ResolversTypes['action_update_summary_aggregate'], "action_update_summary_aggregate", ParentType, ContextType, Partial<Subscription_RootAction_Update_Summary_AggregateArgs>>;
  action_update_summary_stream?: SubscriptionResolver<Array<ResolversTypes['action_update_summary']>, "action_update_summary_stream", ParentType, ContextType, RequireFields<Subscription_RootAction_Update_Summary_StreamArgs, 'batch_size' | 'cursor'>>;
  aggregation_org?: SubscriptionResolver<Array<ResolversTypes['aggregation_org']>, "aggregation_org", ParentType, ContextType, Partial<Subscription_RootAggregation_OrgArgs>>;
  aggregation_org_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['aggregation_org']>, "aggregation_org_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAggregation_Org_By_PkArgs, 'OrgKey'>>;
  aggregation_org_stream?: SubscriptionResolver<Array<ResolversTypes['aggregation_org']>, "aggregation_org_stream", ParentType, ContextType, RequireFields<Subscription_RootAggregation_Org_StreamArgs, 'batch_size' | 'cursor'>>;
  ancestor_contributor?: SubscriptionResolver<Array<ResolversTypes['ancestor_contributor']>, "ancestor_contributor", ParentType, ContextType, Partial<Subscription_RootAncestor_ContributorArgs>>;
  ancestor_contributor_stream?: SubscriptionResolver<Array<ResolversTypes['ancestor_contributor']>, "ancestor_contributor_stream", ParentType, ContextType, RequireFields<Subscription_RootAncestor_Contributor_StreamArgs, 'batch_size' | 'cursor'>>;
  appetite?: SubscriptionResolver<Array<ResolversTypes['appetite']>, "appetite", ParentType, ContextType, Partial<Subscription_RootAppetiteArgs>>;
  appetite_aggregate?: SubscriptionResolver<ResolversTypes['appetite_aggregate'], "appetite_aggregate", ParentType, ContextType, Partial<Subscription_RootAppetite_AggregateArgs>>;
  appetite_audit?: SubscriptionResolver<Array<ResolversTypes['appetite_audit']>, "appetite_audit", ParentType, ContextType, Partial<Subscription_RootAppetite_AuditArgs>>;
  appetite_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['appetite_audit']>, "appetite_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAppetite_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  appetite_audit_stream?: SubscriptionResolver<Array<ResolversTypes['appetite_audit']>, "appetite_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootAppetite_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  appetite_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['appetite']>, "appetite_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAppetite_By_PkArgs, 'Id'>>;
  appetite_parent?: SubscriptionResolver<Array<ResolversTypes['appetite_parent']>, "appetite_parent", ParentType, ContextType, Partial<Subscription_RootAppetite_ParentArgs>>;
  appetite_parent_aggregate?: SubscriptionResolver<ResolversTypes['appetite_parent_aggregate'], "appetite_parent_aggregate", ParentType, ContextType, Partial<Subscription_RootAppetite_Parent_AggregateArgs>>;
  appetite_parent_audit?: SubscriptionResolver<Array<ResolversTypes['appetite_parent_audit']>, "appetite_parent_audit", ParentType, ContextType, Partial<Subscription_RootAppetite_Parent_AuditArgs>>;
  appetite_parent_audit_aggregate?: SubscriptionResolver<ResolversTypes['appetite_parent_audit_aggregate'], "appetite_parent_audit_aggregate", ParentType, ContextType, Partial<Subscription_RootAppetite_Parent_Audit_AggregateArgs>>;
  appetite_parent_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['appetite_parent_audit']>, "appetite_parent_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAppetite_Parent_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp' | 'ParentId'>>;
  appetite_parent_audit_stream?: SubscriptionResolver<Array<ResolversTypes['appetite_parent_audit']>, "appetite_parent_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootAppetite_Parent_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  appetite_parent_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['appetite_parent']>, "appetite_parent_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAppetite_Parent_By_PkArgs, 'Id' | 'ParentId'>>;
  appetite_parent_stream?: SubscriptionResolver<Array<ResolversTypes['appetite_parent']>, "appetite_parent_stream", ParentType, ContextType, RequireFields<Subscription_RootAppetite_Parent_StreamArgs, 'batch_size' | 'cursor'>>;
  appetite_stream?: SubscriptionResolver<Array<ResolversTypes['appetite']>, "appetite_stream", ParentType, ContextType, RequireFields<Subscription_RootAppetite_StreamArgs, 'batch_size' | 'cursor'>>;
  approval?: SubscriptionResolver<Array<ResolversTypes['approval']>, "approval", ParentType, ContextType, Partial<Subscription_RootApprovalArgs>>;
  approval_audit?: SubscriptionResolver<Array<ResolversTypes['approval_audit']>, "approval_audit", ParentType, ContextType, Partial<Subscription_RootApproval_AuditArgs>>;
  approval_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['approval_audit']>, "approval_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootApproval_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  approval_audit_stream?: SubscriptionResolver<Array<ResolversTypes['approval_audit']>, "approval_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootApproval_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  approval_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['approval']>, "approval_by_pk", ParentType, ContextType, RequireFields<Subscription_RootApproval_By_PkArgs, 'Id'>>;
  approval_level?: SubscriptionResolver<Array<ResolversTypes['approval_level']>, "approval_level", ParentType, ContextType, Partial<Subscription_RootApproval_LevelArgs>>;
  approval_level_audit?: SubscriptionResolver<Array<ResolversTypes['approval_level_audit']>, "approval_level_audit", ParentType, ContextType, Partial<Subscription_RootApproval_Level_AuditArgs>>;
  approval_level_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['approval_level_audit']>, "approval_level_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootApproval_Level_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  approval_level_audit_stream?: SubscriptionResolver<Array<ResolversTypes['approval_level_audit']>, "approval_level_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootApproval_Level_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  approval_level_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['approval_level']>, "approval_level_by_pk", ParentType, ContextType, RequireFields<Subscription_RootApproval_Level_By_PkArgs, 'Id'>>;
  approval_level_stream?: SubscriptionResolver<Array<ResolversTypes['approval_level']>, "approval_level_stream", ParentType, ContextType, RequireFields<Subscription_RootApproval_Level_StreamArgs, 'batch_size' | 'cursor'>>;
  approval_stream?: SubscriptionResolver<Array<ResolversTypes['approval']>, "approval_stream", ParentType, ContextType, RequireFields<Subscription_RootApproval_StreamArgs, 'batch_size' | 'cursor'>>;
  approver?: SubscriptionResolver<Array<ResolversTypes['approver']>, "approver", ParentType, ContextType, Partial<Subscription_RootApproverArgs>>;
  approver_aggregate?: SubscriptionResolver<ResolversTypes['approver_aggregate'], "approver_aggregate", ParentType, ContextType, Partial<Subscription_RootApprover_AggregateArgs>>;
  approver_audit?: SubscriptionResolver<Array<ResolversTypes['approver_audit']>, "approver_audit", ParentType, ContextType, Partial<Subscription_RootApprover_AuditArgs>>;
  approver_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['approver_audit']>, "approver_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootApprover_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  approver_audit_stream?: SubscriptionResolver<Array<ResolversTypes['approver_audit']>, "approver_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootApprover_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  approver_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['approver']>, "approver_by_pk", ParentType, ContextType, RequireFields<Subscription_RootApprover_By_PkArgs, 'Id'>>;
  approver_stream?: SubscriptionResolver<Array<ResolversTypes['approver']>, "approver_stream", ParentType, ContextType, RequireFields<Subscription_RootApprover_StreamArgs, 'batch_size' | 'cursor'>>;
  assessment?: SubscriptionResolver<Array<ResolversTypes['assessment']>, "assessment", ParentType, ContextType, Partial<Subscription_RootAssessmentArgs>>;
  assessment_activity?: SubscriptionResolver<Array<ResolversTypes['assessment_activity']>, "assessment_activity", ParentType, ContextType, Partial<Subscription_RootAssessment_ActivityArgs>>;
  assessment_activity_aggregate?: SubscriptionResolver<ResolversTypes['assessment_activity_aggregate'], "assessment_activity_aggregate", ParentType, ContextType, Partial<Subscription_RootAssessment_Activity_AggregateArgs>>;
  assessment_activity_audit?: SubscriptionResolver<Array<ResolversTypes['assessment_activity_audit']>, "assessment_activity_audit", ParentType, ContextType, Partial<Subscription_RootAssessment_Activity_AuditArgs>>;
  assessment_activity_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['assessment_activity_audit']>, "assessment_activity_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAssessment_Activity_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  assessment_activity_audit_stream?: SubscriptionResolver<Array<ResolversTypes['assessment_activity_audit']>, "assessment_activity_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootAssessment_Activity_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  assessment_activity_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['assessment_activity']>, "assessment_activity_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAssessment_Activity_By_PkArgs, 'Id'>>;
  assessment_activity_stream?: SubscriptionResolver<Array<ResolversTypes['assessment_activity']>, "assessment_activity_stream", ParentType, ContextType, RequireFields<Subscription_RootAssessment_Activity_StreamArgs, 'batch_size' | 'cursor'>>;
  assessment_aggregate?: SubscriptionResolver<ResolversTypes['assessment_aggregate'], "assessment_aggregate", ParentType, ContextType, Partial<Subscription_RootAssessment_AggregateArgs>>;
  assessment_audit?: SubscriptionResolver<Array<ResolversTypes['assessment_audit']>, "assessment_audit", ParentType, ContextType, Partial<Subscription_RootAssessment_AuditArgs>>;
  assessment_audit_aggregate?: SubscriptionResolver<ResolversTypes['assessment_audit_aggregate'], "assessment_audit_aggregate", ParentType, ContextType, Partial<Subscription_RootAssessment_Audit_AggregateArgs>>;
  assessment_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['assessment_audit']>, "assessment_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAssessment_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  assessment_audit_stream?: SubscriptionResolver<Array<ResolversTypes['assessment_audit']>, "assessment_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootAssessment_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  assessment_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['assessment']>, "assessment_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAssessment_By_PkArgs, 'Id'>>;
  assessment_result_parent?: SubscriptionResolver<Array<ResolversTypes['assessment_result_parent']>, "assessment_result_parent", ParentType, ContextType, Partial<Subscription_RootAssessment_Result_ParentArgs>>;
  assessment_result_parent_aggregate?: SubscriptionResolver<ResolversTypes['assessment_result_parent_aggregate'], "assessment_result_parent_aggregate", ParentType, ContextType, Partial<Subscription_RootAssessment_Result_Parent_AggregateArgs>>;
  assessment_result_parent_audit?: SubscriptionResolver<Array<ResolversTypes['assessment_result_parent_audit']>, "assessment_result_parent_audit", ParentType, ContextType, Partial<Subscription_RootAssessment_Result_Parent_AuditArgs>>;
  assessment_result_parent_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['assessment_result_parent_audit']>, "assessment_result_parent_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAssessment_Result_Parent_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp' | 'ParentId'>>;
  assessment_result_parent_audit_stream?: SubscriptionResolver<Array<ResolversTypes['assessment_result_parent_audit']>, "assessment_result_parent_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootAssessment_Result_Parent_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  assessment_result_parent_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['assessment_result_parent']>, "assessment_result_parent_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAssessment_Result_Parent_By_PkArgs, 'Id' | 'ParentId'>>;
  assessment_result_parent_stream?: SubscriptionResolver<Array<ResolversTypes['assessment_result_parent']>, "assessment_result_parent_stream", ParentType, ContextType, RequireFields<Subscription_RootAssessment_Result_Parent_StreamArgs, 'batch_size' | 'cursor'>>;
  assessment_stream?: SubscriptionResolver<Array<ResolversTypes['assessment']>, "assessment_stream", ParentType, ContextType, RequireFields<Subscription_RootAssessment_StreamArgs, 'batch_size' | 'cursor'>>;
  attestation_config?: SubscriptionResolver<Array<ResolversTypes['attestation_config']>, "attestation_config", ParentType, ContextType, Partial<Subscription_RootAttestation_ConfigArgs>>;
  attestation_config_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['attestation_config']>, "attestation_config_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAttestation_Config_By_PkArgs, 'ParentId'>>;
  attestation_config_stream?: SubscriptionResolver<Array<ResolversTypes['attestation_config']>, "attestation_config_stream", ParentType, ContextType, RequireFields<Subscription_RootAttestation_Config_StreamArgs, 'batch_size' | 'cursor'>>;
  attestation_group?: SubscriptionResolver<Array<ResolversTypes['attestation_group']>, "attestation_group", ParentType, ContextType, Partial<Subscription_RootAttestation_GroupArgs>>;
  attestation_group_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['attestation_group']>, "attestation_group_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAttestation_Group_By_PkArgs, 'ConfigId' | 'GroupId'>>;
  attestation_group_stream?: SubscriptionResolver<Array<ResolversTypes['attestation_group']>, "attestation_group_stream", ParentType, ContextType, RequireFields<Subscription_RootAttestation_Group_StreamArgs, 'batch_size' | 'cursor'>>;
  attestation_record?: SubscriptionResolver<Array<ResolversTypes['attestation_record']>, "attestation_record", ParentType, ContextType, Partial<Subscription_RootAttestation_RecordArgs>>;
  attestation_record_aggregate?: SubscriptionResolver<ResolversTypes['attestation_record_aggregate'], "attestation_record_aggregate", ParentType, ContextType, Partial<Subscription_RootAttestation_Record_AggregateArgs>>;
  attestation_record_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['attestation_record']>, "attestation_record_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAttestation_Record_By_PkArgs, 'Id'>>;
  attestation_record_stream?: SubscriptionResolver<Array<ResolversTypes['attestation_record']>, "attestation_record_stream", ParentType, ContextType, RequireFields<Subscription_RootAttestation_Record_StreamArgs, 'batch_size' | 'cursor'>>;
  audit_log_view?: SubscriptionResolver<Array<ResolversTypes['audit_log_view']>, "audit_log_view", ParentType, ContextType, Partial<Subscription_RootAudit_Log_ViewArgs>>;
  audit_log_view_aggregate?: SubscriptionResolver<ResolversTypes['audit_log_view_aggregate'], "audit_log_view_aggregate", ParentType, ContextType, Partial<Subscription_RootAudit_Log_View_AggregateArgs>>;
  audit_log_view_stream?: SubscriptionResolver<Array<ResolversTypes['audit_log_view']>, "audit_log_view_stream", ParentType, ContextType, RequireFields<Subscription_RootAudit_Log_View_StreamArgs, 'batch_size' | 'cursor'>>;
  auth_organisation?: SubscriptionResolver<Array<ResolversTypes['auth_organisation']>, "auth_organisation", ParentType, ContextType, Partial<Subscription_RootAuth_OrganisationArgs>>;
  auth_organisation_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['auth_organisation']>, "auth_organisation_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAuth_Organisation_By_PkArgs, 'OrgKey'>>;
  auth_organisation_stream?: SubscriptionResolver<Array<ResolversTypes['auth_organisation']>, "auth_organisation_stream", ParentType, ContextType, RequireFields<Subscription_RootAuth_Organisation_StreamArgs, 'batch_size' | 'cursor'>>;
  auth_organisationuser?: SubscriptionResolver<Array<ResolversTypes['auth_organisationuser']>, "auth_organisationuser", ParentType, ContextType, Partial<Subscription_RootAuth_OrganisationuserArgs>>;
  auth_organisationuser_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['auth_organisationuser']>, "auth_organisationuser_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAuth_Organisationuser_By_PkArgs, 'OrgKey' | 'User_Id'>>;
  auth_organisationuser_stream?: SubscriptionResolver<Array<ResolversTypes['auth_organisationuser']>, "auth_organisationuser_stream", ParentType, ContextType, RequireFields<Subscription_RootAuth_Organisationuser_StreamArgs, 'batch_size' | 'cursor'>>;
  auth_user?: SubscriptionResolver<Array<ResolversTypes['auth_user']>, "auth_user", ParentType, ContextType, Partial<Subscription_RootAuth_UserArgs>>;
  auth_user_aggregate?: SubscriptionResolver<ResolversTypes['auth_user_aggregate'], "auth_user_aggregate", ParentType, ContextType, Partial<Subscription_RootAuth_User_AggregateArgs>>;
  auth_user_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['auth_user']>, "auth_user_by_pk", ParentType, ContextType, RequireFields<Subscription_RootAuth_User_By_PkArgs, 'Id'>>;
  auth_user_stream?: SubscriptionResolver<Array<ResolversTypes['auth_user']>, "auth_user_stream", ParentType, ContextType, RequireFields<Subscription_RootAuth_User_StreamArgs, 'batch_size' | 'cursor'>>;
  business_area?: SubscriptionResolver<Array<ResolversTypes['business_area']>, "business_area", ParentType, ContextType, Partial<Subscription_RootBusiness_AreaArgs>>;
  business_area_aggregate?: SubscriptionResolver<ResolversTypes['business_area_aggregate'], "business_area_aggregate", ParentType, ContextType, Partial<Subscription_RootBusiness_Area_AggregateArgs>>;
  business_area_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['business_area']>, "business_area_by_pk", ParentType, ContextType, RequireFields<Subscription_RootBusiness_Area_By_PkArgs, 'Id'>>;
  business_area_stream?: SubscriptionResolver<Array<ResolversTypes['business_area']>, "business_area_stream", ParentType, ContextType, RequireFields<Subscription_RootBusiness_Area_StreamArgs, 'batch_size' | 'cursor'>>;
  cause?: SubscriptionResolver<Array<ResolversTypes['cause']>, "cause", ParentType, ContextType, Partial<Subscription_RootCauseArgs>>;
  cause_aggregate?: SubscriptionResolver<ResolversTypes['cause_aggregate'], "cause_aggregate", ParentType, ContextType, Partial<Subscription_RootCause_AggregateArgs>>;
  cause_audit?: SubscriptionResolver<Array<ResolversTypes['cause_audit']>, "cause_audit", ParentType, ContextType, Partial<Subscription_RootCause_AuditArgs>>;
  cause_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['cause_audit']>, "cause_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootCause_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  cause_audit_stream?: SubscriptionResolver<Array<ResolversTypes['cause_audit']>, "cause_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootCause_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  cause_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['cause']>, "cause_by_pk", ParentType, ContextType, RequireFields<Subscription_RootCause_By_PkArgs, 'Id'>>;
  cause_stream?: SubscriptionResolver<Array<ResolversTypes['cause']>, "cause_stream", ParentType, ContextType, RequireFields<Subscription_RootCause_StreamArgs, 'batch_size' | 'cursor'>>;
  change_request?: SubscriptionResolver<Array<ResolversTypes['change_request']>, "change_request", ParentType, ContextType, Partial<Subscription_RootChange_RequestArgs>>;
  change_request_aggregate?: SubscriptionResolver<ResolversTypes['change_request_aggregate'], "change_request_aggregate", ParentType, ContextType, Partial<Subscription_RootChange_Request_AggregateArgs>>;
  change_request_audit?: SubscriptionResolver<Array<ResolversTypes['change_request_audit']>, "change_request_audit", ParentType, ContextType, Partial<Subscription_RootChange_Request_AuditArgs>>;
  change_request_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['change_request_audit']>, "change_request_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootChange_Request_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  change_request_audit_stream?: SubscriptionResolver<Array<ResolversTypes['change_request_audit']>, "change_request_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootChange_Request_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  change_request_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['change_request']>, "change_request_by_pk", ParentType, ContextType, RequireFields<Subscription_RootChange_Request_By_PkArgs, 'Id'>>;
  change_request_contributor?: SubscriptionResolver<Array<ResolversTypes['change_request_contributor']>, "change_request_contributor", ParentType, ContextType, Partial<Subscription_RootChange_Request_ContributorArgs>>;
  change_request_contributor_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['change_request_contributor']>, "change_request_contributor_by_pk", ParentType, ContextType, RequireFields<Subscription_RootChange_Request_Contributor_By_PkArgs, 'Id'>>;
  change_request_contributor_stream?: SubscriptionResolver<Array<ResolversTypes['change_request_contributor']>, "change_request_contributor_stream", ParentType, ContextType, RequireFields<Subscription_RootChange_Request_Contributor_StreamArgs, 'batch_size' | 'cursor'>>;
  change_request_stream?: SubscriptionResolver<Array<ResolversTypes['change_request']>, "change_request_stream", ParentType, ContextType, RequireFields<Subscription_RootChange_Request_StreamArgs, 'batch_size' | 'cursor'>>;
  comment?: SubscriptionResolver<Array<ResolversTypes['comment']>, "comment", ParentType, ContextType, Partial<Subscription_RootCommentArgs>>;
  comment_audit?: SubscriptionResolver<Array<ResolversTypes['comment_audit']>, "comment_audit", ParentType, ContextType, Partial<Subscription_RootComment_AuditArgs>>;
  comment_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['comment_audit']>, "comment_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootComment_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  comment_audit_stream?: SubscriptionResolver<Array<ResolversTypes['comment_audit']>, "comment_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootComment_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  comment_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['comment']>, "comment_by_pk", ParentType, ContextType, RequireFields<Subscription_RootComment_By_PkArgs, 'Id'>>;
  comment_stream?: SubscriptionResolver<Array<ResolversTypes['comment']>, "comment_stream", ParentType, ContextType, RequireFields<Subscription_RootComment_StreamArgs, 'batch_size' | 'cursor'>>;
  compliance_monitoring_assessment?: SubscriptionResolver<Array<ResolversTypes['compliance_monitoring_assessment']>, "compliance_monitoring_assessment", ParentType, ContextType, Partial<Subscription_RootCompliance_Monitoring_AssessmentArgs>>;
  compliance_monitoring_assessment_aggregate?: SubscriptionResolver<ResolversTypes['compliance_monitoring_assessment_aggregate'], "compliance_monitoring_assessment_aggregate", ParentType, ContextType, Partial<Subscription_RootCompliance_Monitoring_Assessment_AggregateArgs>>;
  compliance_monitoring_assessment_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['compliance_monitoring_assessment']>, "compliance_monitoring_assessment_by_pk", ParentType, ContextType, RequireFields<Subscription_RootCompliance_Monitoring_Assessment_By_PkArgs, 'Id'>>;
  compliance_monitoring_assessment_stream?: SubscriptionResolver<Array<ResolversTypes['compliance_monitoring_assessment']>, "compliance_monitoring_assessment_stream", ParentType, ContextType, RequireFields<Subscription_RootCompliance_Monitoring_Assessment_StreamArgs, 'batch_size' | 'cursor'>>;
  consequence?: SubscriptionResolver<Array<ResolversTypes['consequence']>, "consequence", ParentType, ContextType, Partial<Subscription_RootConsequenceArgs>>;
  consequence_aggregate?: SubscriptionResolver<ResolversTypes['consequence_aggregate'], "consequence_aggregate", ParentType, ContextType, Partial<Subscription_RootConsequence_AggregateArgs>>;
  consequence_audit?: SubscriptionResolver<Array<ResolversTypes['consequence_audit']>, "consequence_audit", ParentType, ContextType, Partial<Subscription_RootConsequence_AuditArgs>>;
  consequence_audit_aggregate?: SubscriptionResolver<ResolversTypes['consequence_audit_aggregate'], "consequence_audit_aggregate", ParentType, ContextType, Partial<Subscription_RootConsequence_Audit_AggregateArgs>>;
  consequence_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['consequence_audit']>, "consequence_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootConsequence_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  consequence_audit_stream?: SubscriptionResolver<Array<ResolversTypes['consequence_audit']>, "consequence_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootConsequence_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  consequence_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['consequence']>, "consequence_by_pk", ParentType, ContextType, RequireFields<Subscription_RootConsequence_By_PkArgs, 'Id'>>;
  consequence_stream?: SubscriptionResolver<Array<ResolversTypes['consequence']>, "consequence_stream", ParentType, ContextType, RequireFields<Subscription_RootConsequence_StreamArgs, 'batch_size' | 'cursor'>>;
  control?: SubscriptionResolver<Array<ResolversTypes['control']>, "control", ParentType, ContextType, Partial<Subscription_RootControlArgs>>;
  control_aggregate?: SubscriptionResolver<ResolversTypes['control_aggregate'], "control_aggregate", ParentType, ContextType, Partial<Subscription_RootControl_AggregateArgs>>;
  control_audit?: SubscriptionResolver<Array<ResolversTypes['control_audit']>, "control_audit", ParentType, ContextType, Partial<Subscription_RootControl_AuditArgs>>;
  control_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['control_audit']>, "control_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootControl_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  control_audit_stream?: SubscriptionResolver<Array<ResolversTypes['control_audit']>, "control_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootControl_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  control_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['control']>, "control_by_pk", ParentType, ContextType, RequireFields<Subscription_RootControl_By_PkArgs, 'Id'>>;
  control_group?: SubscriptionResolver<Array<ResolversTypes['control_group']>, "control_group", ParentType, ContextType, Partial<Subscription_RootControl_GroupArgs>>;
  control_group_aggregate?: SubscriptionResolver<ResolversTypes['control_group_aggregate'], "control_group_aggregate", ParentType, ContextType, Partial<Subscription_RootControl_Group_AggregateArgs>>;
  control_group_audit?: SubscriptionResolver<Array<ResolversTypes['control_group_audit']>, "control_group_audit", ParentType, ContextType, Partial<Subscription_RootControl_Group_AuditArgs>>;
  control_group_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['control_group_audit']>, "control_group_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootControl_Group_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  control_group_audit_stream?: SubscriptionResolver<Array<ResolversTypes['control_group_audit']>, "control_group_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootControl_Group_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  control_group_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['control_group']>, "control_group_by_pk", ParentType, ContextType, RequireFields<Subscription_RootControl_Group_By_PkArgs, 'Id'>>;
  control_group_stream?: SubscriptionResolver<Array<ResolversTypes['control_group']>, "control_group_stream", ParentType, ContextType, RequireFields<Subscription_RootControl_Group_StreamArgs, 'batch_size' | 'cursor'>>;
  control_parent?: SubscriptionResolver<Array<ResolversTypes['control_parent']>, "control_parent", ParentType, ContextType, Partial<Subscription_RootControl_ParentArgs>>;
  control_parent_aggregate?: SubscriptionResolver<ResolversTypes['control_parent_aggregate'], "control_parent_aggregate", ParentType, ContextType, Partial<Subscription_RootControl_Parent_AggregateArgs>>;
  control_parent_audit?: SubscriptionResolver<Array<ResolversTypes['control_parent_audit']>, "control_parent_audit", ParentType, ContextType, Partial<Subscription_RootControl_Parent_AuditArgs>>;
  control_parent_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['control_parent_audit']>, "control_parent_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootControl_Parent_Audit_By_PkArgs, 'ControlId' | 'ModifiedAtTimestamp' | 'ParentId'>>;
  control_parent_audit_stream?: SubscriptionResolver<Array<ResolversTypes['control_parent_audit']>, "control_parent_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootControl_Parent_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  control_parent_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['control_parent']>, "control_parent_by_pk", ParentType, ContextType, RequireFields<Subscription_RootControl_Parent_By_PkArgs, 'ControlId' | 'ParentId'>>;
  control_parent_stream?: SubscriptionResolver<Array<ResolversTypes['control_parent']>, "control_parent_stream", ParentType, ContextType, RequireFields<Subscription_RootControl_Parent_StreamArgs, 'batch_size' | 'cursor'>>;
  control_stream?: SubscriptionResolver<Array<ResolversTypes['control']>, "control_stream", ParentType, ContextType, RequireFields<Subscription_RootControl_StreamArgs, 'batch_size' | 'cursor'>>;
  conversation?: SubscriptionResolver<Array<ResolversTypes['conversation']>, "conversation", ParentType, ContextType, Partial<Subscription_RootConversationArgs>>;
  conversation_audit?: SubscriptionResolver<Array<ResolversTypes['conversation_audit']>, "conversation_audit", ParentType, ContextType, Partial<Subscription_RootConversation_AuditArgs>>;
  conversation_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['conversation_audit']>, "conversation_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootConversation_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  conversation_audit_stream?: SubscriptionResolver<Array<ResolversTypes['conversation_audit']>, "conversation_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootConversation_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  conversation_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['conversation']>, "conversation_by_pk", ParentType, ContextType, RequireFields<Subscription_RootConversation_By_PkArgs, 'Id'>>;
  conversation_stream?: SubscriptionResolver<Array<ResolversTypes['conversation']>, "conversation_stream", ParentType, ContextType, RequireFields<Subscription_RootConversation_StreamArgs, 'batch_size' | 'cursor'>>;
  custom_attribute_schema?: SubscriptionResolver<Array<ResolversTypes['custom_attribute_schema']>, "custom_attribute_schema", ParentType, ContextType, Partial<Subscription_RootCustom_Attribute_SchemaArgs>>;
  custom_attribute_schema_audit?: SubscriptionResolver<Array<ResolversTypes['custom_attribute_schema_audit']>, "custom_attribute_schema_audit", ParentType, ContextType, Partial<Subscription_RootCustom_Attribute_Schema_AuditArgs>>;
  custom_attribute_schema_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['custom_attribute_schema_audit']>, "custom_attribute_schema_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootCustom_Attribute_Schema_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  custom_attribute_schema_audit_stream?: SubscriptionResolver<Array<ResolversTypes['custom_attribute_schema_audit']>, "custom_attribute_schema_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootCustom_Attribute_Schema_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  custom_attribute_schema_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['custom_attribute_schema']>, "custom_attribute_schema_by_pk", ParentType, ContextType, RequireFields<Subscription_RootCustom_Attribute_Schema_By_PkArgs, 'Id'>>;
  custom_attribute_schema_stream?: SubscriptionResolver<Array<ResolversTypes['custom_attribute_schema']>, "custom_attribute_schema_stream", ParentType, ContextType, RequireFields<Subscription_RootCustom_Attribute_Schema_StreamArgs, 'batch_size' | 'cursor'>>;
  custom_ribbon?: SubscriptionResolver<Array<ResolversTypes['custom_ribbon']>, "custom_ribbon", ParentType, ContextType, Partial<Subscription_RootCustom_RibbonArgs>>;
  custom_ribbon_audit?: SubscriptionResolver<Array<ResolversTypes['custom_ribbon_audit']>, "custom_ribbon_audit", ParentType, ContextType, Partial<Subscription_RootCustom_Ribbon_AuditArgs>>;
  custom_ribbon_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['custom_ribbon_audit']>, "custom_ribbon_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootCustom_Ribbon_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  custom_ribbon_audit_stream?: SubscriptionResolver<Array<ResolversTypes['custom_ribbon_audit']>, "custom_ribbon_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootCustom_Ribbon_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  custom_ribbon_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['custom_ribbon']>, "custom_ribbon_by_pk", ParentType, ContextType, RequireFields<Subscription_RootCustom_Ribbon_By_PkArgs, 'Id'>>;
  custom_ribbon_stream?: SubscriptionResolver<Array<ResolversTypes['custom_ribbon']>, "custom_ribbon_stream", ParentType, ContextType, RequireFields<Subscription_RootCustom_Ribbon_StreamArgs, 'batch_size' | 'cursor'>>;
  dashboard?: SubscriptionResolver<Array<ResolversTypes['dashboard']>, "dashboard", ParentType, ContextType, Partial<Subscription_RootDashboardArgs>>;
  dashboard_audit?: SubscriptionResolver<Array<ResolversTypes['dashboard_audit']>, "dashboard_audit", ParentType, ContextType, Partial<Subscription_RootDashboard_AuditArgs>>;
  dashboard_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['dashboard_audit']>, "dashboard_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDashboard_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  dashboard_audit_stream?: SubscriptionResolver<Array<ResolversTypes['dashboard_audit']>, "dashboard_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootDashboard_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  dashboard_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['dashboard']>, "dashboard_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDashboard_By_PkArgs, 'Id'>>;
  dashboard_stream?: SubscriptionResolver<Array<ResolversTypes['dashboard']>, "dashboard_stream", ParentType, ContextType, RequireFields<Subscription_RootDashboard_StreamArgs, 'batch_size' | 'cursor'>>;
  data_import?: SubscriptionResolver<Array<ResolversTypes['data_import']>, "data_import", ParentType, ContextType, Partial<Subscription_RootData_ImportArgs>>;
  data_import_aggregate?: SubscriptionResolver<ResolversTypes['data_import_aggregate'], "data_import_aggregate", ParentType, ContextType, Partial<Subscription_RootData_Import_AggregateArgs>>;
  data_import_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['data_import']>, "data_import_by_pk", ParentType, ContextType, RequireFields<Subscription_RootData_Import_By_PkArgs, 'Id'>>;
  data_import_error?: SubscriptionResolver<Array<ResolversTypes['data_import_error']>, "data_import_error", ParentType, ContextType, Partial<Subscription_RootData_Import_ErrorArgs>>;
  data_import_error_aggregate?: SubscriptionResolver<ResolversTypes['data_import_error_aggregate'], "data_import_error_aggregate", ParentType, ContextType, Partial<Subscription_RootData_Import_Error_AggregateArgs>>;
  data_import_error_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['data_import_error']>, "data_import_error_by_pk", ParentType, ContextType, RequireFields<Subscription_RootData_Import_Error_By_PkArgs, 'Id'>>;
  data_import_error_stream?: SubscriptionResolver<Array<ResolversTypes['data_import_error']>, "data_import_error_stream", ParentType, ContextType, RequireFields<Subscription_RootData_Import_Error_StreamArgs, 'batch_size' | 'cursor'>>;
  data_import_stream?: SubscriptionResolver<Array<ResolversTypes['data_import']>, "data_import_stream", ParentType, ContextType, RequireFields<Subscription_RootData_Import_StreamArgs, 'batch_size' | 'cursor'>>;
  department_audit?: SubscriptionResolver<Array<ResolversTypes['department_audit']>, "department_audit", ParentType, ContextType, Partial<Subscription_RootDepartment_AuditArgs>>;
  department_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['department_audit']>, "department_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDepartment_Audit_By_PkArgs, 'DepartmentTypeId' | 'ModifiedAtTimestamp' | 'ParentId'>>;
  department_audit_stream?: SubscriptionResolver<Array<ResolversTypes['department_audit']>, "department_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootDepartment_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  department_type?: SubscriptionResolver<Array<ResolversTypes['department_type']>, "department_type", ParentType, ContextType, Partial<Subscription_RootDepartment_TypeArgs>>;
  department_type_audit?: SubscriptionResolver<Array<ResolversTypes['department_type_audit']>, "department_type_audit", ParentType, ContextType, Partial<Subscription_RootDepartment_Type_AuditArgs>>;
  department_type_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['department_type_audit']>, "department_type_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDepartment_Type_Audit_By_PkArgs, 'DepartmentTypeId' | 'ModifiedAtTimestamp'>>;
  department_type_audit_stream?: SubscriptionResolver<Array<ResolversTypes['department_type_audit']>, "department_type_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootDepartment_Type_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  department_type_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['department_type']>, "department_type_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDepartment_Type_By_PkArgs, 'DepartmentTypeId'>>;
  department_type_group?: SubscriptionResolver<Array<ResolversTypes['department_type_group']>, "department_type_group", ParentType, ContextType, Partial<Subscription_RootDepartment_Type_GroupArgs>>;
  department_type_group_audit?: SubscriptionResolver<Array<ResolversTypes['department_type_group_audit']>, "department_type_group_audit", ParentType, ContextType, Partial<Subscription_RootDepartment_Type_Group_AuditArgs>>;
  department_type_group_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['department_type_group_audit']>, "department_type_group_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDepartment_Type_Group_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  department_type_group_audit_stream?: SubscriptionResolver<Array<ResolversTypes['department_type_group_audit']>, "department_type_group_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootDepartment_Type_Group_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  department_type_group_stream?: SubscriptionResolver<Array<ResolversTypes['department_type_group']>, "department_type_group_stream", ParentType, ContextType, RequireFields<Subscription_RootDepartment_Type_Group_StreamArgs, 'batch_size' | 'cursor'>>;
  department_type_stream?: SubscriptionResolver<Array<ResolversTypes['department_type']>, "department_type_stream", ParentType, ContextType, RequireFields<Subscription_RootDepartment_Type_StreamArgs, 'batch_size' | 'cursor'>>;
  document?: SubscriptionResolver<Array<ResolversTypes['document']>, "document", ParentType, ContextType, Partial<Subscription_RootDocumentArgs>>;
  document_assessment_result?: SubscriptionResolver<Array<ResolversTypes['document_assessment_result']>, "document_assessment_result", ParentType, ContextType, Partial<Subscription_RootDocument_Assessment_ResultArgs>>;
  document_assessment_result_aggregate?: SubscriptionResolver<ResolversTypes['document_assessment_result_aggregate'], "document_assessment_result_aggregate", ParentType, ContextType, Partial<Subscription_RootDocument_Assessment_Result_AggregateArgs>>;
  document_assessment_result_audit?: SubscriptionResolver<Array<ResolversTypes['document_assessment_result_audit']>, "document_assessment_result_audit", ParentType, ContextType, Partial<Subscription_RootDocument_Assessment_Result_AuditArgs>>;
  document_assessment_result_audit_aggregate?: SubscriptionResolver<ResolversTypes['document_assessment_result_audit_aggregate'], "document_assessment_result_audit_aggregate", ParentType, ContextType, Partial<Subscription_RootDocument_Assessment_Result_Audit_AggregateArgs>>;
  document_assessment_result_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['document_assessment_result_audit']>, "document_assessment_result_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDocument_Assessment_Result_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  document_assessment_result_audit_stream?: SubscriptionResolver<Array<ResolversTypes['document_assessment_result_audit']>, "document_assessment_result_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootDocument_Assessment_Result_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  document_assessment_result_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['document_assessment_result']>, "document_assessment_result_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDocument_Assessment_Result_By_PkArgs, 'Id'>>;
  document_assessment_result_stream?: SubscriptionResolver<Array<ResolversTypes['document_assessment_result']>, "document_assessment_result_stream", ParentType, ContextType, RequireFields<Subscription_RootDocument_Assessment_Result_StreamArgs, 'batch_size' | 'cursor'>>;
  document_audit?: SubscriptionResolver<Array<ResolversTypes['document_audit']>, "document_audit", ParentType, ContextType, Partial<Subscription_RootDocument_AuditArgs>>;
  document_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['document_audit']>, "document_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDocument_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  document_audit_stream?: SubscriptionResolver<Array<ResolversTypes['document_audit']>, "document_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootDocument_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  document_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['document']>, "document_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDocument_By_PkArgs, 'Id'>>;
  document_file?: SubscriptionResolver<Array<ResolversTypes['document_file']>, "document_file", ParentType, ContextType, Partial<Subscription_RootDocument_FileArgs>>;
  document_file_audit?: SubscriptionResolver<Array<ResolversTypes['document_file_audit']>, "document_file_audit", ParentType, ContextType, Partial<Subscription_RootDocument_File_AuditArgs>>;
  document_file_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['document_file_audit']>, "document_file_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDocument_File_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  document_file_audit_stream?: SubscriptionResolver<Array<ResolversTypes['document_file_audit']>, "document_file_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootDocument_File_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  document_file_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['document_file']>, "document_file_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDocument_File_By_PkArgs, 'Id'>>;
  document_file_stream?: SubscriptionResolver<Array<ResolversTypes['document_file']>, "document_file_stream", ParentType, ContextType, RequireFields<Subscription_RootDocument_File_StreamArgs, 'batch_size' | 'cursor'>>;
  document_linked_document?: SubscriptionResolver<Array<ResolversTypes['document_linked_document']>, "document_linked_document", ParentType, ContextType, Partial<Subscription_RootDocument_Linked_DocumentArgs>>;
  document_linked_document_audit?: SubscriptionResolver<Array<ResolversTypes['document_linked_document_audit']>, "document_linked_document_audit", ParentType, ContextType, Partial<Subscription_RootDocument_Linked_Document_AuditArgs>>;
  document_linked_document_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['document_linked_document_audit']>, "document_linked_document_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDocument_Linked_Document_Audit_By_PkArgs, 'DocumentId' | 'LinkedDocumentId' | 'ModifiedAtTimestamp'>>;
  document_linked_document_audit_stream?: SubscriptionResolver<Array<ResolversTypes['document_linked_document_audit']>, "document_linked_document_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootDocument_Linked_Document_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  document_linked_document_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['document_linked_document']>, "document_linked_document_by_pk", ParentType, ContextType, RequireFields<Subscription_RootDocument_Linked_Document_By_PkArgs, 'DocumentId' | 'LinkedDocumentId'>>;
  document_linked_document_stream?: SubscriptionResolver<Array<ResolversTypes['document_linked_document']>, "document_linked_document_stream", ParentType, ContextType, RequireFields<Subscription_RootDocument_Linked_Document_StreamArgs, 'batch_size' | 'cursor'>>;
  document_stream?: SubscriptionResolver<Array<ResolversTypes['document']>, "document_stream", ParentType, ContextType, RequireFields<Subscription_RootDocument_StreamArgs, 'batch_size' | 'cursor'>>;
  file?: SubscriptionResolver<Array<ResolversTypes['file']>, "file", ParentType, ContextType, Partial<Subscription_RootFileArgs>>;
  file_aggregate?: SubscriptionResolver<ResolversTypes['file_aggregate'], "file_aggregate", ParentType, ContextType, Partial<Subscription_RootFile_AggregateArgs>>;
  file_audit?: SubscriptionResolver<Array<ResolversTypes['file_audit']>, "file_audit", ParentType, ContextType, Partial<Subscription_RootFile_AuditArgs>>;
  file_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['file_audit']>, "file_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootFile_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  file_audit_stream?: SubscriptionResolver<Array<ResolversTypes['file_audit']>, "file_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootFile_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  file_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['file']>, "file_by_pk", ParentType, ContextType, RequireFields<Subscription_RootFile_By_PkArgs, 'Id'>>;
  file_stream?: SubscriptionResolver<Array<ResolversTypes['file']>, "file_stream", ParentType, ContextType, RequireFields<Subscription_RootFile_StreamArgs, 'batch_size' | 'cursor'>>;
  form_configuration?: SubscriptionResolver<Array<ResolversTypes['form_configuration']>, "form_configuration", ParentType, ContextType, Partial<Subscription_RootForm_ConfigurationArgs>>;
  form_configuration_audit?: SubscriptionResolver<Array<ResolversTypes['form_configuration_audit']>, "form_configuration_audit", ParentType, ContextType, Partial<Subscription_RootForm_Configuration_AuditArgs>>;
  form_configuration_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['form_configuration_audit']>, "form_configuration_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootForm_Configuration_Audit_By_PkArgs, 'ModifiedAtTimestamp' | 'ParentType'>>;
  form_configuration_audit_stream?: SubscriptionResolver<Array<ResolversTypes['form_configuration_audit']>, "form_configuration_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootForm_Configuration_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  form_configuration_stream?: SubscriptionResolver<Array<ResolversTypes['form_configuration']>, "form_configuration_stream", ParentType, ContextType, RequireFields<Subscription_RootForm_Configuration_StreamArgs, 'batch_size' | 'cursor'>>;
  form_field_configuration?: SubscriptionResolver<Array<ResolversTypes['form_field_configuration']>, "form_field_configuration", ParentType, ContextType, Partial<Subscription_RootForm_Field_ConfigurationArgs>>;
  form_field_configuration_audit?: SubscriptionResolver<Array<ResolversTypes['form_field_configuration_audit']>, "form_field_configuration_audit", ParentType, ContextType, Partial<Subscription_RootForm_Field_Configuration_AuditArgs>>;
  form_field_configuration_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['form_field_configuration_audit']>, "form_field_configuration_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootForm_Field_Configuration_Audit_By_PkArgs, 'FieldId' | 'FormConfigurationParentType' | 'ModifiedAtTimestamp'>>;
  form_field_configuration_audit_stream?: SubscriptionResolver<Array<ResolversTypes['form_field_configuration_audit']>, "form_field_configuration_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootForm_Field_Configuration_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  form_field_configuration_stream?: SubscriptionResolver<Array<ResolversTypes['form_field_configuration']>, "form_field_configuration_stream", ParentType, ContextType, RequireFields<Subscription_RootForm_Field_Configuration_StreamArgs, 'batch_size' | 'cursor'>>;
  form_field_ordering?: SubscriptionResolver<Array<ResolversTypes['form_field_ordering']>, "form_field_ordering", ParentType, ContextType, Partial<Subscription_RootForm_Field_OrderingArgs>>;
  form_field_ordering_audit?: SubscriptionResolver<Array<ResolversTypes['form_field_ordering_audit']>, "form_field_ordering_audit", ParentType, ContextType, Partial<Subscription_RootForm_Field_Ordering_AuditArgs>>;
  form_field_ordering_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['form_field_ordering_audit']>, "form_field_ordering_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootForm_Field_Ordering_Audit_By_PkArgs, 'FieldId' | 'FormConfigurationParentType' | 'ModifiedAtTimestamp'>>;
  form_field_ordering_audit_stream?: SubscriptionResolver<Array<ResolversTypes['form_field_ordering_audit']>, "form_field_ordering_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootForm_Field_Ordering_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  form_field_ordering_stream?: SubscriptionResolver<Array<ResolversTypes['form_field_ordering']>, "form_field_ordering_stream", ParentType, ContextType, RequireFields<Subscription_RootForm_Field_Ordering_StreamArgs, 'batch_size' | 'cursor'>>;
  impact?: SubscriptionResolver<Array<ResolversTypes['impact']>, "impact", ParentType, ContextType, Partial<Subscription_RootImpactArgs>>;
  impact_aggregate?: SubscriptionResolver<ResolversTypes['impact_aggregate'], "impact_aggregate", ParentType, ContextType, Partial<Subscription_RootImpact_AggregateArgs>>;
  impact_audit?: SubscriptionResolver<Array<ResolversTypes['impact_audit']>, "impact_audit", ParentType, ContextType, Partial<Subscription_RootImpact_AuditArgs>>;
  impact_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['impact_audit']>, "impact_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootImpact_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  impact_audit_stream?: SubscriptionResolver<Array<ResolversTypes['impact_audit']>, "impact_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootImpact_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  impact_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['impact']>, "impact_by_pk", ParentType, ContextType, RequireFields<Subscription_RootImpact_By_PkArgs, 'Id'>>;
  impact_parent?: SubscriptionResolver<Array<ResolversTypes['impact_parent']>, "impact_parent", ParentType, ContextType, Partial<Subscription_RootImpact_ParentArgs>>;
  impact_parent_aggregate?: SubscriptionResolver<ResolversTypes['impact_parent_aggregate'], "impact_parent_aggregate", ParentType, ContextType, Partial<Subscription_RootImpact_Parent_AggregateArgs>>;
  impact_parent_audit?: SubscriptionResolver<Array<ResolversTypes['impact_parent_audit']>, "impact_parent_audit", ParentType, ContextType, Partial<Subscription_RootImpact_Parent_AuditArgs>>;
  impact_parent_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['impact_parent_audit']>, "impact_parent_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootImpact_Parent_Audit_By_PkArgs, 'ImpactId' | 'ModifiedAtTimestamp' | 'ParentId'>>;
  impact_parent_audit_stream?: SubscriptionResolver<Array<ResolversTypes['impact_parent_audit']>, "impact_parent_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootImpact_Parent_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  impact_parent_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['impact_parent']>, "impact_parent_by_pk", ParentType, ContextType, RequireFields<Subscription_RootImpact_Parent_By_PkArgs, 'ImpactId' | 'ParentId'>>;
  impact_parent_stream?: SubscriptionResolver<Array<ResolversTypes['impact_parent']>, "impact_parent_stream", ParentType, ContextType, RequireFields<Subscription_RootImpact_Parent_StreamArgs, 'batch_size' | 'cursor'>>;
  impact_rating?: SubscriptionResolver<Array<ResolversTypes['impact_rating']>, "impact_rating", ParentType, ContextType, Partial<Subscription_RootImpact_RatingArgs>>;
  impact_rating_aggregate?: SubscriptionResolver<ResolversTypes['impact_rating_aggregate'], "impact_rating_aggregate", ParentType, ContextType, Partial<Subscription_RootImpact_Rating_AggregateArgs>>;
  impact_rating_audit?: SubscriptionResolver<Array<ResolversTypes['impact_rating_audit']>, "impact_rating_audit", ParentType, ContextType, Partial<Subscription_RootImpact_Rating_AuditArgs>>;
  impact_rating_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['impact_rating_audit']>, "impact_rating_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootImpact_Rating_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  impact_rating_audit_stream?: SubscriptionResolver<Array<ResolversTypes['impact_rating_audit']>, "impact_rating_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootImpact_Rating_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  impact_rating_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['impact_rating']>, "impact_rating_by_pk", ParentType, ContextType, RequireFields<Subscription_RootImpact_Rating_By_PkArgs, 'Id'>>;
  impact_rating_stream?: SubscriptionResolver<Array<ResolversTypes['impact_rating']>, "impact_rating_stream", ParentType, ContextType, RequireFields<Subscription_RootImpact_Rating_StreamArgs, 'batch_size' | 'cursor'>>;
  impact_stream?: SubscriptionResolver<Array<ResolversTypes['impact']>, "impact_stream", ParentType, ContextType, RequireFields<Subscription_RootImpact_StreamArgs, 'batch_size' | 'cursor'>>;
  indicator?: SubscriptionResolver<Array<ResolversTypes['indicator']>, "indicator", ParentType, ContextType, Partial<Subscription_RootIndicatorArgs>>;
  indicator_audit?: SubscriptionResolver<Array<ResolversTypes['indicator_audit']>, "indicator_audit", ParentType, ContextType, Partial<Subscription_RootIndicator_AuditArgs>>;
  indicator_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['indicator_audit']>, "indicator_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIndicator_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  indicator_audit_stream?: SubscriptionResolver<Array<ResolversTypes['indicator_audit']>, "indicator_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootIndicator_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  indicator_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['indicator']>, "indicator_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIndicator_By_PkArgs, 'Id'>>;
  indicator_parent?: SubscriptionResolver<Array<ResolversTypes['indicator_parent']>, "indicator_parent", ParentType, ContextType, Partial<Subscription_RootIndicator_ParentArgs>>;
  indicator_parent_aggregate?: SubscriptionResolver<ResolversTypes['indicator_parent_aggregate'], "indicator_parent_aggregate", ParentType, ContextType, Partial<Subscription_RootIndicator_Parent_AggregateArgs>>;
  indicator_parent_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['indicator_parent']>, "indicator_parent_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIndicator_Parent_By_PkArgs, 'IndicatorId' | 'ParentId'>>;
  indicator_parent_stream?: SubscriptionResolver<Array<ResolversTypes['indicator_parent']>, "indicator_parent_stream", ParentType, ContextType, RequireFields<Subscription_RootIndicator_Parent_StreamArgs, 'batch_size' | 'cursor'>>;
  indicator_result?: SubscriptionResolver<Array<ResolversTypes['indicator_result']>, "indicator_result", ParentType, ContextType, Partial<Subscription_RootIndicator_ResultArgs>>;
  indicator_result_audit?: SubscriptionResolver<Array<ResolversTypes['indicator_result_audit']>, "indicator_result_audit", ParentType, ContextType, Partial<Subscription_RootIndicator_Result_AuditArgs>>;
  indicator_result_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['indicator_result_audit']>, "indicator_result_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIndicator_Result_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  indicator_result_audit_stream?: SubscriptionResolver<Array<ResolversTypes['indicator_result_audit']>, "indicator_result_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootIndicator_Result_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  indicator_result_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['indicator_result']>, "indicator_result_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIndicator_Result_By_PkArgs, 'Id'>>;
  indicator_result_stream?: SubscriptionResolver<Array<ResolversTypes['indicator_result']>, "indicator_result_stream", ParentType, ContextType, RequireFields<Subscription_RootIndicator_Result_StreamArgs, 'batch_size' | 'cursor'>>;
  indicator_stream?: SubscriptionResolver<Array<ResolversTypes['indicator']>, "indicator_stream", ParentType, ContextType, RequireFields<Subscription_RootIndicator_StreamArgs, 'batch_size' | 'cursor'>>;
  indicator_type?: SubscriptionResolver<Array<ResolversTypes['indicator_type']>, "indicator_type", ParentType, ContextType, Partial<Subscription_RootIndicator_TypeArgs>>;
  indicator_type_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['indicator_type']>, "indicator_type_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIndicator_Type_By_PkArgs, 'Value'>>;
  indicator_type_stream?: SubscriptionResolver<Array<ResolversTypes['indicator_type']>, "indicator_type_stream", ParentType, ContextType, RequireFields<Subscription_RootIndicator_Type_StreamArgs, 'batch_size' | 'cursor'>>;
  internal_audit_entity?: SubscriptionResolver<Array<ResolversTypes['internal_audit_entity']>, "internal_audit_entity", ParentType, ContextType, Partial<Subscription_RootInternal_Audit_EntityArgs>>;
  internal_audit_entity_aggregate?: SubscriptionResolver<ResolversTypes['internal_audit_entity_aggregate'], "internal_audit_entity_aggregate", ParentType, ContextType, Partial<Subscription_RootInternal_Audit_Entity_AggregateArgs>>;
  internal_audit_entity_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['internal_audit_entity']>, "internal_audit_entity_by_pk", ParentType, ContextType, RequireFields<Subscription_RootInternal_Audit_Entity_By_PkArgs, 'Id'>>;
  internal_audit_entity_stream?: SubscriptionResolver<Array<ResolversTypes['internal_audit_entity']>, "internal_audit_entity_stream", ParentType, ContextType, RequireFields<Subscription_RootInternal_Audit_Entity_StreamArgs, 'batch_size' | 'cursor'>>;
  internal_audit_report?: SubscriptionResolver<Array<ResolversTypes['internal_audit_report']>, "internal_audit_report", ParentType, ContextType, Partial<Subscription_RootInternal_Audit_ReportArgs>>;
  internal_audit_report_aggregate?: SubscriptionResolver<ResolversTypes['internal_audit_report_aggregate'], "internal_audit_report_aggregate", ParentType, ContextType, Partial<Subscription_RootInternal_Audit_Report_AggregateArgs>>;
  internal_audit_report_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['internal_audit_report']>, "internal_audit_report_by_pk", ParentType, ContextType, RequireFields<Subscription_RootInternal_Audit_Report_By_PkArgs, 'Id'>>;
  internal_audit_report_stream?: SubscriptionResolver<Array<ResolversTypes['internal_audit_report']>, "internal_audit_report_stream", ParentType, ContextType, RequireFields<Subscription_RootInternal_Audit_Report_StreamArgs, 'batch_size' | 'cursor'>>;
  issue?: SubscriptionResolver<Array<ResolversTypes['issue']>, "issue", ParentType, ContextType, Partial<Subscription_RootIssueArgs>>;
  issue_aggregate?: SubscriptionResolver<ResolversTypes['issue_aggregate'], "issue_aggregate", ParentType, ContextType, Partial<Subscription_RootIssue_AggregateArgs>>;
  issue_assessment?: SubscriptionResolver<Array<ResolversTypes['issue_assessment']>, "issue_assessment", ParentType, ContextType, Partial<Subscription_RootIssue_AssessmentArgs>>;
  issue_assessment_aggregate?: SubscriptionResolver<ResolversTypes['issue_assessment_aggregate'], "issue_assessment_aggregate", ParentType, ContextType, Partial<Subscription_RootIssue_Assessment_AggregateArgs>>;
  issue_assessment_audit?: SubscriptionResolver<Array<ResolversTypes['issue_assessment_audit']>, "issue_assessment_audit", ParentType, ContextType, Partial<Subscription_RootIssue_Assessment_AuditArgs>>;
  issue_assessment_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['issue_assessment_audit']>, "issue_assessment_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIssue_Assessment_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  issue_assessment_audit_stream?: SubscriptionResolver<Array<ResolversTypes['issue_assessment_audit']>, "issue_assessment_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootIssue_Assessment_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  issue_assessment_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['issue_assessment']>, "issue_assessment_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIssue_Assessment_By_PkArgs, 'Id'>>;
  issue_assessment_stream?: SubscriptionResolver<Array<ResolversTypes['issue_assessment']>, "issue_assessment_stream", ParentType, ContextType, RequireFields<Subscription_RootIssue_Assessment_StreamArgs, 'batch_size' | 'cursor'>>;
  issue_audit?: SubscriptionResolver<Array<ResolversTypes['issue_audit']>, "issue_audit", ParentType, ContextType, Partial<Subscription_RootIssue_AuditArgs>>;
  issue_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['issue_audit']>, "issue_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIssue_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  issue_audit_stream?: SubscriptionResolver<Array<ResolversTypes['issue_audit']>, "issue_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootIssue_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  issue_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['issue']>, "issue_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIssue_By_PkArgs, 'Id'>>;
  issue_parent?: SubscriptionResolver<Array<ResolversTypes['issue_parent']>, "issue_parent", ParentType, ContextType, Partial<Subscription_RootIssue_ParentArgs>>;
  issue_parent_aggregate?: SubscriptionResolver<ResolversTypes['issue_parent_aggregate'], "issue_parent_aggregate", ParentType, ContextType, Partial<Subscription_RootIssue_Parent_AggregateArgs>>;
  issue_parent_audit?: SubscriptionResolver<Array<ResolversTypes['issue_parent_audit']>, "issue_parent_audit", ParentType, ContextType, Partial<Subscription_RootIssue_Parent_AuditArgs>>;
  issue_parent_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['issue_parent_audit']>, "issue_parent_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIssue_Parent_Audit_By_PkArgs, 'IssueId' | 'ModifiedAtTimestamp' | 'ParentId'>>;
  issue_parent_audit_stream?: SubscriptionResolver<Array<ResolversTypes['issue_parent_audit']>, "issue_parent_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootIssue_Parent_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  issue_parent_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['issue_parent']>, "issue_parent_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIssue_Parent_By_PkArgs, 'IssueId' | 'ParentId'>>;
  issue_parent_stream?: SubscriptionResolver<Array<ResolversTypes['issue_parent']>, "issue_parent_stream", ParentType, ContextType, RequireFields<Subscription_RootIssue_Parent_StreamArgs, 'batch_size' | 'cursor'>>;
  issue_stream?: SubscriptionResolver<Array<ResolversTypes['issue']>, "issue_stream", ParentType, ContextType, RequireFields<Subscription_RootIssue_StreamArgs, 'batch_size' | 'cursor'>>;
  issue_update?: SubscriptionResolver<Array<ResolversTypes['issue_update']>, "issue_update", ParentType, ContextType, Partial<Subscription_RootIssue_UpdateArgs>>;
  issue_update_audit?: SubscriptionResolver<Array<ResolversTypes['issue_update_audit']>, "issue_update_audit", ParentType, ContextType, Partial<Subscription_RootIssue_Update_AuditArgs>>;
  issue_update_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['issue_update_audit']>, "issue_update_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIssue_Update_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  issue_update_audit_stream?: SubscriptionResolver<Array<ResolversTypes['issue_update_audit']>, "issue_update_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootIssue_Update_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  issue_update_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['issue_update']>, "issue_update_by_pk", ParentType, ContextType, RequireFields<Subscription_RootIssue_Update_By_PkArgs, 'Id'>>;
  issue_update_stream?: SubscriptionResolver<Array<ResolversTypes['issue_update']>, "issue_update_stream", ParentType, ContextType, RequireFields<Subscription_RootIssue_Update_StreamArgs, 'batch_size' | 'cursor'>>;
  linked_item?: SubscriptionResolver<Array<ResolversTypes['linked_item']>, "linked_item", ParentType, ContextType, Partial<Subscription_RootLinked_ItemArgs>>;
  linked_item_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['linked_item']>, "linked_item_by_pk", ParentType, ContextType, RequireFields<Subscription_RootLinked_Item_By_PkArgs, 'Source' | 'Target'>>;
  linked_item_stream?: SubscriptionResolver<Array<ResolversTypes['linked_item']>, "linked_item_stream", ParentType, ContextType, RequireFields<Subscription_RootLinked_Item_StreamArgs, 'batch_size' | 'cursor'>>;
  node?: SubscriptionResolver<Array<ResolversTypes['node']>, "node", ParentType, ContextType, Partial<Subscription_RootNodeArgs>>;
  node_aggregate?: SubscriptionResolver<ResolversTypes['node_aggregate'], "node_aggregate", ParentType, ContextType, Partial<Subscription_RootNode_AggregateArgs>>;
  node_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['node']>, "node_by_pk", ParentType, ContextType, RequireFields<Subscription_RootNode_By_PkArgs, 'Id'>>;
  node_stream?: SubscriptionResolver<Array<ResolversTypes['node']>, "node_stream", ParentType, ContextType, RequireFields<Subscription_RootNode_StreamArgs, 'batch_size' | 'cursor'>>;
  obligation?: SubscriptionResolver<Array<ResolversTypes['obligation']>, "obligation", ParentType, ContextType, Partial<Subscription_RootObligationArgs>>;
  obligation_aggregate?: SubscriptionResolver<ResolversTypes['obligation_aggregate'], "obligation_aggregate", ParentType, ContextType, Partial<Subscription_RootObligation_AggregateArgs>>;
  obligation_assessment_result?: SubscriptionResolver<Array<ResolversTypes['obligation_assessment_result']>, "obligation_assessment_result", ParentType, ContextType, Partial<Subscription_RootObligation_Assessment_ResultArgs>>;
  obligation_assessment_result_aggregate?: SubscriptionResolver<ResolversTypes['obligation_assessment_result_aggregate'], "obligation_assessment_result_aggregate", ParentType, ContextType, Partial<Subscription_RootObligation_Assessment_Result_AggregateArgs>>;
  obligation_assessment_result_audit?: SubscriptionResolver<Array<ResolversTypes['obligation_assessment_result_audit']>, "obligation_assessment_result_audit", ParentType, ContextType, Partial<Subscription_RootObligation_Assessment_Result_AuditArgs>>;
  obligation_assessment_result_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['obligation_assessment_result_audit']>, "obligation_assessment_result_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootObligation_Assessment_Result_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  obligation_assessment_result_audit_stream?: SubscriptionResolver<Array<ResolversTypes['obligation_assessment_result_audit']>, "obligation_assessment_result_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootObligation_Assessment_Result_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  obligation_assessment_result_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['obligation_assessment_result']>, "obligation_assessment_result_by_pk", ParentType, ContextType, RequireFields<Subscription_RootObligation_Assessment_Result_By_PkArgs, 'Id'>>;
  obligation_assessment_result_stream?: SubscriptionResolver<Array<ResolversTypes['obligation_assessment_result']>, "obligation_assessment_result_stream", ParentType, ContextType, RequireFields<Subscription_RootObligation_Assessment_Result_StreamArgs, 'batch_size' | 'cursor'>>;
  obligation_assessment_status?: SubscriptionResolver<Array<ResolversTypes['obligation_assessment_status']>, "obligation_assessment_status", ParentType, ContextType, Partial<Subscription_RootObligation_Assessment_StatusArgs>>;
  obligation_assessment_status_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['obligation_assessment_status']>, "obligation_assessment_status_by_pk", ParentType, ContextType, RequireFields<Subscription_RootObligation_Assessment_Status_By_PkArgs, 'Value'>>;
  obligation_assessment_status_stream?: SubscriptionResolver<Array<ResolversTypes['obligation_assessment_status']>, "obligation_assessment_status_stream", ParentType, ContextType, RequireFields<Subscription_RootObligation_Assessment_Status_StreamArgs, 'batch_size' | 'cursor'>>;
  obligation_audit?: SubscriptionResolver<Array<ResolversTypes['obligation_audit']>, "obligation_audit", ParentType, ContextType, Partial<Subscription_RootObligation_AuditArgs>>;
  obligation_audit_aggregate?: SubscriptionResolver<ResolversTypes['obligation_audit_aggregate'], "obligation_audit_aggregate", ParentType, ContextType, Partial<Subscription_RootObligation_Audit_AggregateArgs>>;
  obligation_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['obligation_audit']>, "obligation_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootObligation_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  obligation_audit_stream?: SubscriptionResolver<Array<ResolversTypes['obligation_audit']>, "obligation_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootObligation_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  obligation_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['obligation']>, "obligation_by_pk", ParentType, ContextType, RequireFields<Subscription_RootObligation_By_PkArgs, 'Id'>>;
  obligation_impact?: SubscriptionResolver<Array<ResolversTypes['obligation_impact']>, "obligation_impact", ParentType, ContextType, Partial<Subscription_RootObligation_ImpactArgs>>;
  obligation_impact_audit?: SubscriptionResolver<Array<ResolversTypes['obligation_impact_audit']>, "obligation_impact_audit", ParentType, ContextType, Partial<Subscription_RootObligation_Impact_AuditArgs>>;
  obligation_impact_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['obligation_impact_audit']>, "obligation_impact_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootObligation_Impact_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  obligation_impact_audit_stream?: SubscriptionResolver<Array<ResolversTypes['obligation_impact_audit']>, "obligation_impact_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootObligation_Impact_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  obligation_impact_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['obligation_impact']>, "obligation_impact_by_pk", ParentType, ContextType, RequireFields<Subscription_RootObligation_Impact_By_PkArgs, 'Id'>>;
  obligation_impact_stream?: SubscriptionResolver<Array<ResolversTypes['obligation_impact']>, "obligation_impact_stream", ParentType, ContextType, RequireFields<Subscription_RootObligation_Impact_StreamArgs, 'batch_size' | 'cursor'>>;
  obligation_stream?: SubscriptionResolver<Array<ResolversTypes['obligation']>, "obligation_stream", ParentType, ContextType, RequireFields<Subscription_RootObligation_StreamArgs, 'batch_size' | 'cursor'>>;
  obligation_type?: SubscriptionResolver<Array<ResolversTypes['obligation_type']>, "obligation_type", ParentType, ContextType, Partial<Subscription_RootObligation_TypeArgs>>;
  obligation_type_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['obligation_type']>, "obligation_type_by_pk", ParentType, ContextType, RequireFields<Subscription_RootObligation_Type_By_PkArgs, 'Value'>>;
  obligation_type_stream?: SubscriptionResolver<Array<ResolversTypes['obligation_type']>, "obligation_type_stream", ParentType, ContextType, RequireFields<Subscription_RootObligation_Type_StreamArgs, 'batch_size' | 'cursor'>>;
  owner_audit?: SubscriptionResolver<Array<ResolversTypes['owner_audit']>, "owner_audit", ParentType, ContextType, Partial<Subscription_RootOwner_AuditArgs>>;
  owner_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['owner_audit']>, "owner_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootOwner_Audit_By_PkArgs, 'ModifiedAtTimestamp' | 'ParentId' | 'UserId'>>;
  owner_audit_stream?: SubscriptionResolver<Array<ResolversTypes['owner_audit']>, "owner_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootOwner_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  owner_group_audit?: SubscriptionResolver<Array<ResolversTypes['owner_group_audit']>, "owner_group_audit", ParentType, ContextType, Partial<Subscription_RootOwner_Group_AuditArgs>>;
  owner_group_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['owner_group_audit']>, "owner_group_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootOwner_Group_Audit_By_PkArgs, 'ModifiedAtTimestamp' | 'ParentId' | 'UserGroupId'>>;
  owner_group_audit_stream?: SubscriptionResolver<Array<ResolversTypes['owner_group_audit']>, "owner_group_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootOwner_Group_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  questionnaire_invite?: SubscriptionResolver<Array<ResolversTypes['questionnaire_invite']>, "questionnaire_invite", ParentType, ContextType, Partial<Subscription_RootQuestionnaire_InviteArgs>>;
  questionnaire_invite_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['questionnaire_invite']>, "questionnaire_invite_by_pk", ParentType, ContextType, RequireFields<Subscription_RootQuestionnaire_Invite_By_PkArgs, 'Id'>>;
  questionnaire_invite_stream?: SubscriptionResolver<Array<ResolversTypes['questionnaire_invite']>, "questionnaire_invite_stream", ParentType, ContextType, RequireFields<Subscription_RootQuestionnaire_Invite_StreamArgs, 'batch_size' | 'cursor'>>;
  questionnaire_template?: SubscriptionResolver<Array<ResolversTypes['questionnaire_template']>, "questionnaire_template", ParentType, ContextType, Partial<Subscription_RootQuestionnaire_TemplateArgs>>;
  questionnaire_template_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['questionnaire_template']>, "questionnaire_template_by_pk", ParentType, ContextType, RequireFields<Subscription_RootQuestionnaire_Template_By_PkArgs, 'Id'>>;
  questionnaire_template_stream?: SubscriptionResolver<Array<ResolversTypes['questionnaire_template']>, "questionnaire_template_stream", ParentType, ContextType, RequireFields<Subscription_RootQuestionnaire_Template_StreamArgs, 'batch_size' | 'cursor'>>;
  questionnaire_template_version?: SubscriptionResolver<Array<ResolversTypes['questionnaire_template_version']>, "questionnaire_template_version", ParentType, ContextType, Partial<Subscription_RootQuestionnaire_Template_VersionArgs>>;
  questionnaire_template_version_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['questionnaire_template_version']>, "questionnaire_template_version_by_pk", ParentType, ContextType, RequireFields<Subscription_RootQuestionnaire_Template_Version_By_PkArgs, 'Id'>>;
  questionnaire_template_version_status?: SubscriptionResolver<Array<ResolversTypes['questionnaire_template_version_status']>, "questionnaire_template_version_status", ParentType, ContextType, Partial<Subscription_RootQuestionnaire_Template_Version_StatusArgs>>;
  questionnaire_template_version_status_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['questionnaire_template_version_status']>, "questionnaire_template_version_status_by_pk", ParentType, ContextType, RequireFields<Subscription_RootQuestionnaire_Template_Version_Status_By_PkArgs, 'Value'>>;
  questionnaire_template_version_status_stream?: SubscriptionResolver<Array<ResolversTypes['questionnaire_template_version_status']>, "questionnaire_template_version_status_stream", ParentType, ContextType, RequireFields<Subscription_RootQuestionnaire_Template_Version_Status_StreamArgs, 'batch_size' | 'cursor'>>;
  questionnaire_template_version_stream?: SubscriptionResolver<Array<ResolversTypes['questionnaire_template_version']>, "questionnaire_template_version_stream", ParentType, ContextType, RequireFields<Subscription_RootQuestionnaire_Template_Version_StreamArgs, 'batch_size' | 'cursor'>>;
  relation_file?: SubscriptionResolver<Array<ResolversTypes['relation_file']>, "relation_file", ParentType, ContextType, Partial<Subscription_RootRelation_FileArgs>>;
  relation_file_aggregate?: SubscriptionResolver<ResolversTypes['relation_file_aggregate'], "relation_file_aggregate", ParentType, ContextType, Partial<Subscription_RootRelation_File_AggregateArgs>>;
  relation_file_audit?: SubscriptionResolver<Array<ResolversTypes['relation_file_audit']>, "relation_file_audit", ParentType, ContextType, Partial<Subscription_RootRelation_File_AuditArgs>>;
  relation_file_audit_stream?: SubscriptionResolver<Array<ResolversTypes['relation_file_audit']>, "relation_file_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootRelation_File_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  relation_file_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['relation_file']>, "relation_file_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRelation_File_By_PkArgs, 'FileId' | 'ParentId'>>;
  relation_file_stream?: SubscriptionResolver<Array<ResolversTypes['relation_file']>, "relation_file_stream", ParentType, ContextType, RequireFields<Subscription_RootRelation_File_StreamArgs, 'batch_size' | 'cursor'>>;
  risk?: SubscriptionResolver<Array<ResolversTypes['risk']>, "risk", ParentType, ContextType, Partial<Subscription_RootRiskArgs>>;
  risk_aggregate?: SubscriptionResolver<ResolversTypes['risk_aggregate'], "risk_aggregate", ParentType, ContextType, Partial<Subscription_RootRisk_AggregateArgs>>;
  risk_assessment_result?: SubscriptionResolver<Array<ResolversTypes['risk_assessment_result']>, "risk_assessment_result", ParentType, ContextType, Partial<Subscription_RootRisk_Assessment_ResultArgs>>;
  risk_assessment_result_aggregate?: SubscriptionResolver<ResolversTypes['risk_assessment_result_aggregate'], "risk_assessment_result_aggregate", ParentType, ContextType, Partial<Subscription_RootRisk_Assessment_Result_AggregateArgs>>;
  risk_assessment_result_audit?: SubscriptionResolver<Array<ResolversTypes['risk_assessment_result_audit']>, "risk_assessment_result_audit", ParentType, ContextType, Partial<Subscription_RootRisk_Assessment_Result_AuditArgs>>;
  risk_assessment_result_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['risk_assessment_result_audit']>, "risk_assessment_result_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRisk_Assessment_Result_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  risk_assessment_result_audit_stream?: SubscriptionResolver<Array<ResolversTypes['risk_assessment_result_audit']>, "risk_assessment_result_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootRisk_Assessment_Result_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  risk_assessment_result_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['risk_assessment_result']>, "risk_assessment_result_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRisk_Assessment_Result_By_PkArgs, 'Id'>>;
  risk_assessment_result_stream?: SubscriptionResolver<Array<ResolversTypes['risk_assessment_result']>, "risk_assessment_result_stream", ParentType, ContextType, RequireFields<Subscription_RootRisk_Assessment_Result_StreamArgs, 'batch_size' | 'cursor'>>;
  risk_audit?: SubscriptionResolver<Array<ResolversTypes['risk_audit']>, "risk_audit", ParentType, ContextType, Partial<Subscription_RootRisk_AuditArgs>>;
  risk_audit_aggregate?: SubscriptionResolver<ResolversTypes['risk_audit_aggregate'], "risk_audit_aggregate", ParentType, ContextType, Partial<Subscription_RootRisk_Audit_AggregateArgs>>;
  risk_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['risk_audit']>, "risk_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRisk_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  risk_audit_stream?: SubscriptionResolver<Array<ResolversTypes['risk_audit']>, "risk_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootRisk_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  risk_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['risk']>, "risk_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRisk_By_PkArgs, 'Id'>>;
  risk_score?: SubscriptionResolver<Array<ResolversTypes['risk_score']>, "risk_score", ParentType, ContextType, Partial<Subscription_RootRisk_ScoreArgs>>;
  risk_score_aggregate?: SubscriptionResolver<ResolversTypes['risk_score_aggregate'], "risk_score_aggregate", ParentType, ContextType, Partial<Subscription_RootRisk_Score_AggregateArgs>>;
  risk_score_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['risk_score']>, "risk_score_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRisk_Score_By_PkArgs, 'RiskId'>>;
  risk_score_stream?: SubscriptionResolver<Array<ResolversTypes['risk_score']>, "risk_score_stream", ParentType, ContextType, RequireFields<Subscription_RootRisk_Score_StreamArgs, 'batch_size' | 'cursor'>>;
  risk_stream?: SubscriptionResolver<Array<ResolversTypes['risk']>, "risk_stream", ParentType, ContextType, RequireFields<Subscription_RootRisk_StreamArgs, 'batch_size' | 'cursor'>>;
  risksmart_indicator_parent_audit?: SubscriptionResolver<Array<ResolversTypes['risksmart_indicator_parent_audit']>, "risksmart_indicator_parent_audit", ParentType, ContextType, Partial<Subscription_RootRisksmart_Indicator_Parent_AuditArgs>>;
  risksmart_indicator_parent_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['risksmart_indicator_parent_audit']>, "risksmart_indicator_parent_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRisksmart_Indicator_Parent_Audit_By_PkArgs, 'IndicatorId' | 'ModifiedAtTimestamp' | 'ParentId'>>;
  risksmart_indicator_parent_audit_stream?: SubscriptionResolver<Array<ResolversTypes['risksmart_indicator_parent_audit']>, "risksmart_indicator_parent_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootRisksmart_Indicator_Parent_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  risksmart_linked_item_audit?: SubscriptionResolver<Array<ResolversTypes['risksmart_linked_item_audit']>, "risksmart_linked_item_audit", ParentType, ContextType, Partial<Subscription_RootRisksmart_Linked_Item_AuditArgs>>;
  risksmart_linked_item_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['risksmart_linked_item_audit']>, "risksmart_linked_item_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootRisksmart_Linked_Item_Audit_By_PkArgs, 'ModifiedAtTimestamp' | 'Source' | 'Target'>>;
  risksmart_linked_item_audit_stream?: SubscriptionResolver<Array<ResolversTypes['risksmart_linked_item_audit']>, "risksmart_linked_item_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootRisksmart_Linked_Item_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  role_access?: SubscriptionResolver<Array<ResolversTypes['role_access']>, "role_access", ParentType, ContextType, Partial<Subscription_RootRole_AccessArgs>>;
  role_access_stream?: SubscriptionResolver<Array<ResolversTypes['role_access']>, "role_access_stream", ParentType, ContextType, RequireFields<Subscription_RootRole_Access_StreamArgs, 'batch_size' | 'cursor'>>;
  tag_type?: SubscriptionResolver<Array<ResolversTypes['tag_type']>, "tag_type", ParentType, ContextType, Partial<Subscription_RootTag_TypeArgs>>;
  tag_type_audit?: SubscriptionResolver<Array<ResolversTypes['tag_type_audit']>, "tag_type_audit", ParentType, ContextType, Partial<Subscription_RootTag_Type_AuditArgs>>;
  tag_type_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['tag_type_audit']>, "tag_type_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTag_Type_Audit_By_PkArgs, 'ModifiedAtTimestamp' | 'TagTypeId'>>;
  tag_type_audit_stream?: SubscriptionResolver<Array<ResolversTypes['tag_type_audit']>, "tag_type_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootTag_Type_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  tag_type_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['tag_type']>, "tag_type_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTag_Type_By_PkArgs, 'TagTypeId'>>;
  tag_type_group?: SubscriptionResolver<Array<ResolversTypes['tag_type_group']>, "tag_type_group", ParentType, ContextType, Partial<Subscription_RootTag_Type_GroupArgs>>;
  tag_type_group_audit?: SubscriptionResolver<Array<ResolversTypes['tag_type_group_audit']>, "tag_type_group_audit", ParentType, ContextType, Partial<Subscription_RootTag_Type_Group_AuditArgs>>;
  tag_type_group_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['tag_type_group_audit']>, "tag_type_group_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTag_Type_Group_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  tag_type_group_audit_stream?: SubscriptionResolver<Array<ResolversTypes['tag_type_group_audit']>, "tag_type_group_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootTag_Type_Group_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  tag_type_group_stream?: SubscriptionResolver<Array<ResolversTypes['tag_type_group']>, "tag_type_group_stream", ParentType, ContextType, RequireFields<Subscription_RootTag_Type_Group_StreamArgs, 'batch_size' | 'cursor'>>;
  tag_type_stream?: SubscriptionResolver<Array<ResolversTypes['tag_type']>, "tag_type_stream", ParentType, ContextType, RequireFields<Subscription_RootTag_Type_StreamArgs, 'batch_size' | 'cursor'>>;
  taxonomy?: SubscriptionResolver<Array<ResolversTypes['taxonomy']>, "taxonomy", ParentType, ContextType, Partial<Subscription_RootTaxonomyArgs>>;
  taxonomy_aggregate?: SubscriptionResolver<ResolversTypes['taxonomy_aggregate'], "taxonomy_aggregate", ParentType, ContextType, Partial<Subscription_RootTaxonomy_AggregateArgs>>;
  taxonomy_audit?: SubscriptionResolver<Array<ResolversTypes['taxonomy_audit']>, "taxonomy_audit", ParentType, ContextType, Partial<Subscription_RootTaxonomy_AuditArgs>>;
  taxonomy_audit_aggregate?: SubscriptionResolver<ResolversTypes['taxonomy_audit_aggregate'], "taxonomy_audit_aggregate", ParentType, ContextType, Partial<Subscription_RootTaxonomy_Audit_AggregateArgs>>;
  taxonomy_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['taxonomy_audit']>, "taxonomy_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTaxonomy_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  taxonomy_audit_stream?: SubscriptionResolver<Array<ResolversTypes['taxonomy_audit']>, "taxonomy_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootTaxonomy_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  taxonomy_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['taxonomy']>, "taxonomy_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTaxonomy_By_PkArgs, 'Id'>>;
  taxonomy_org?: SubscriptionResolver<Array<ResolversTypes['taxonomy_org']>, "taxonomy_org", ParentType, ContextType, Partial<Subscription_RootTaxonomy_OrgArgs>>;
  taxonomy_org_aggregate?: SubscriptionResolver<ResolversTypes['taxonomy_org_aggregate'], "taxonomy_org_aggregate", ParentType, ContextType, Partial<Subscription_RootTaxonomy_Org_AggregateArgs>>;
  taxonomy_org_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['taxonomy_org']>, "taxonomy_org_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTaxonomy_Org_By_PkArgs, 'Id'>>;
  taxonomy_org_stream?: SubscriptionResolver<Array<ResolversTypes['taxonomy_org']>, "taxonomy_org_stream", ParentType, ContextType, RequireFields<Subscription_RootTaxonomy_Org_StreamArgs, 'batch_size' | 'cursor'>>;
  taxonomy_stream?: SubscriptionResolver<Array<ResolversTypes['taxonomy']>, "taxonomy_stream", ParentType, ContextType, RequireFields<Subscription_RootTaxonomy_StreamArgs, 'batch_size' | 'cursor'>>;
  test_result?: SubscriptionResolver<Array<ResolversTypes['test_result']>, "test_result", ParentType, ContextType, Partial<Subscription_RootTest_ResultArgs>>;
  test_result_aggregate?: SubscriptionResolver<ResolversTypes['test_result_aggregate'], "test_result_aggregate", ParentType, ContextType, Partial<Subscription_RootTest_Result_AggregateArgs>>;
  test_result_audit?: SubscriptionResolver<Array<ResolversTypes['test_result_audit']>, "test_result_audit", ParentType, ContextType, Partial<Subscription_RootTest_Result_AuditArgs>>;
  test_result_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['test_result_audit']>, "test_result_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTest_Result_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  test_result_audit_stream?: SubscriptionResolver<Array<ResolversTypes['test_result_audit']>, "test_result_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootTest_Result_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  test_result_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['test_result']>, "test_result_by_pk", ParentType, ContextType, RequireFields<Subscription_RootTest_Result_By_PkArgs, 'Id'>>;
  test_result_stream?: SubscriptionResolver<Array<ResolversTypes['test_result']>, "test_result_stream", ParentType, ContextType, RequireFields<Subscription_RootTest_Result_StreamArgs, 'batch_size' | 'cursor'>>;
  third_party?: SubscriptionResolver<Array<ResolversTypes['third_party']>, "third_party", ParentType, ContextType, Partial<Subscription_RootThird_PartyArgs>>;
  third_party_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['third_party']>, "third_party_by_pk", ParentType, ContextType, RequireFields<Subscription_RootThird_Party_By_PkArgs, 'Id'>>;
  third_party_response?: SubscriptionResolver<Array<ResolversTypes['third_party_response']>, "third_party_response", ParentType, ContextType, Partial<Subscription_RootThird_Party_ResponseArgs>>;
  third_party_response_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['third_party_response']>, "third_party_response_by_pk", ParentType, ContextType, RequireFields<Subscription_RootThird_Party_Response_By_PkArgs, 'Id'>>;
  third_party_response_status?: SubscriptionResolver<Array<ResolversTypes['third_party_response_status']>, "third_party_response_status", ParentType, ContextType, Partial<Subscription_RootThird_Party_Response_StatusArgs>>;
  third_party_response_status_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['third_party_response_status']>, "third_party_response_status_by_pk", ParentType, ContextType, RequireFields<Subscription_RootThird_Party_Response_Status_By_PkArgs, 'Value'>>;
  third_party_response_status_stream?: SubscriptionResolver<Array<ResolversTypes['third_party_response_status']>, "third_party_response_status_stream", ParentType, ContextType, RequireFields<Subscription_RootThird_Party_Response_Status_StreamArgs, 'batch_size' | 'cursor'>>;
  third_party_response_stream?: SubscriptionResolver<Array<ResolversTypes['third_party_response']>, "third_party_response_stream", ParentType, ContextType, RequireFields<Subscription_RootThird_Party_Response_StreamArgs, 'batch_size' | 'cursor'>>;
  third_party_stream?: SubscriptionResolver<Array<ResolversTypes['third_party']>, "third_party_stream", ParentType, ContextType, RequireFields<Subscription_RootThird_Party_StreamArgs, 'batch_size' | 'cursor'>>;
  user?: SubscriptionResolver<Array<ResolversTypes['user']>, "user", ParentType, ContextType, Partial<Subscription_RootUserArgs>>;
  user_aggregate?: SubscriptionResolver<ResolversTypes['user_aggregate'], "user_aggregate", ParentType, ContextType, Partial<Subscription_RootUser_AggregateArgs>>;
  user_group?: SubscriptionResolver<Array<ResolversTypes['user_group']>, "user_group", ParentType, ContextType, Partial<Subscription_RootUser_GroupArgs>>;
  user_group_audit?: SubscriptionResolver<Array<ResolversTypes['user_group_audit']>, "user_group_audit", ParentType, ContextType, Partial<Subscription_RootUser_Group_AuditArgs>>;
  user_group_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['user_group_audit']>, "user_group_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUser_Group_Audit_By_PkArgs, 'Id' | 'ModifiedAtTimestamp'>>;
  user_group_audit_stream?: SubscriptionResolver<Array<ResolversTypes['user_group_audit']>, "user_group_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootUser_Group_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  user_group_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['user_group']>, "user_group_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUser_Group_By_PkArgs, 'Id'>>;
  user_group_stream?: SubscriptionResolver<Array<ResolversTypes['user_group']>, "user_group_stream", ParentType, ContextType, RequireFields<Subscription_RootUser_Group_StreamArgs, 'batch_size' | 'cursor'>>;
  user_group_user?: SubscriptionResolver<Array<ResolversTypes['user_group_user']>, "user_group_user", ParentType, ContextType, Partial<Subscription_RootUser_Group_UserArgs>>;
  user_group_user_aggregate?: SubscriptionResolver<ResolversTypes['user_group_user_aggregate'], "user_group_user_aggregate", ParentType, ContextType, Partial<Subscription_RootUser_Group_User_AggregateArgs>>;
  user_group_user_audit?: SubscriptionResolver<Array<ResolversTypes['user_group_user_audit']>, "user_group_user_audit", ParentType, ContextType, Partial<Subscription_RootUser_Group_User_AuditArgs>>;
  user_group_user_audit_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['user_group_user_audit']>, "user_group_user_audit_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUser_Group_User_Audit_By_PkArgs, 'ModifiedAtTimestamp' | 'UserGroupId' | 'UserId'>>;
  user_group_user_audit_stream?: SubscriptionResolver<Array<ResolversTypes['user_group_user_audit']>, "user_group_user_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootUser_Group_User_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  user_group_user_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['user_group_user']>, "user_group_user_by_pk", ParentType, ContextType, RequireFields<Subscription_RootUser_Group_User_By_PkArgs, 'UserGroupId' | 'UserId'>>;
  user_group_user_stream?: SubscriptionResolver<Array<ResolversTypes['user_group_user']>, "user_group_user_stream", ParentType, ContextType, RequireFields<Subscription_RootUser_Group_User_StreamArgs, 'batch_size' | 'cursor'>>;
  user_search_preferences?: SubscriptionResolver<Array<ResolversTypes['user_search_preferences']>, "user_search_preferences", ParentType, ContextType, Partial<Subscription_RootUser_Search_PreferencesArgs>>;
  user_search_preferences_audit?: SubscriptionResolver<Array<ResolversTypes['user_search_preferences_audit']>, "user_search_preferences_audit", ParentType, ContextType, Partial<Subscription_RootUser_Search_Preferences_AuditArgs>>;
  user_search_preferences_audit_stream?: SubscriptionResolver<Array<ResolversTypes['user_search_preferences_audit']>, "user_search_preferences_audit_stream", ParentType, ContextType, RequireFields<Subscription_RootUser_Search_Preferences_Audit_StreamArgs, 'batch_size' | 'cursor'>>;
  user_search_preferences_stream?: SubscriptionResolver<Array<ResolversTypes['user_search_preferences']>, "user_search_preferences_stream", ParentType, ContextType, RequireFields<Subscription_RootUser_Search_Preferences_StreamArgs, 'batch_size' | 'cursor'>>;
  user_stream?: SubscriptionResolver<Array<ResolversTypes['user']>, "user_stream", ParentType, ContextType, RequireFields<Subscription_RootUser_StreamArgs, 'batch_size' | 'cursor'>>;
  user_table_preferences?: SubscriptionResolver<Array<ResolversTypes['user_table_preferences']>, "user_table_preferences", ParentType, ContextType, Partial<Subscription_RootUser_Table_PreferencesArgs>>;
  user_table_preferences_stream?: SubscriptionResolver<Array<ResolversTypes['user_table_preferences']>, "user_table_preferences_stream", ParentType, ContextType, RequireFields<Subscription_RootUser_Table_Preferences_StreamArgs, 'batch_size' | 'cursor'>>;
};

export type TagResolvers<ContextType = any, ParentType extends ResolversParentTypes['tag'] = ResolversParentTypes['tag']> = {
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  TagTypeId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['node']>, ParentType, ContextType>;
  parentAction?: Resolver<Maybe<ResolversTypes['action']>, ParentType, ContextType>;
  parentAssessment?: Resolver<Maybe<ResolversTypes['assessment']>, ParentType, ContextType>;
  parentIssue?: Resolver<Maybe<ResolversTypes['issue']>, ParentType, ContextType>;
  parentThirdParty?: Resolver<Maybe<ResolversTypes['third_party']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['tag_type']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tag_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['tag_mutation_response'] = ResolversParentTypes['tag_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['tag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tag_TypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['tag_type'] = ResolversParentTypes['tag_type']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  TagTypeGroupId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  TagTypeId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  tag_type_group?: Resolver<Maybe<ResolversTypes['tag_type_group']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tag_Type_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['tag_type_audit'] = ResolversParentTypes['tag_type_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  TagTypeGroupId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  TagTypeId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tag_Type_GroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['tag_type_group'] = ResolversParentTypes['tag_type_group']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tag_types?: Resolver<Array<ResolversTypes['tag_type']>, ParentType, ContextType, Partial<Tag_Type_GroupTag_TypesArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tag_Type_Group_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['tag_type_group_audit'] = ResolversParentTypes['tag_type_group_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tag_Type_Group_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['tag_type_group_mutation_response'] = ResolversParentTypes['tag_type_group_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['tag_type_group']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tag_Type_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['tag_type_mutation_response'] = ResolversParentTypes['tag_type_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['tag_type']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxonomyResolvers<ContextType = any, ParentType extends ResolversParentTypes['taxonomy'] = ResolversParentTypes['taxonomy']> = {
  Common?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<TaxonomyCommonArgs>>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Library?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<TaxonomyLibraryArgs>>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Rating?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<TaxonomyRatingArgs>>;
  Taxonomy?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<TaxonomyTaxonomyArgs>>;
  organisations?: Resolver<Array<ResolversTypes['taxonomy_org']>, ParentType, ContextType, Partial<TaxonomyOrganisationsArgs>>;
  organisations_aggregate?: Resolver<ResolversTypes['taxonomy_org_aggregate'], ParentType, ContextType, Partial<TaxonomyOrganisations_AggregateArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Taxonomy_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['taxonomy_aggregate'] = ResolversParentTypes['taxonomy_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['taxonomy_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['taxonomy']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Taxonomy_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['taxonomy_aggregate_fields'] = ResolversParentTypes['taxonomy_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Taxonomy_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['taxonomy_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['taxonomy_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Taxonomy_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['taxonomy_audit'] = ResolversParentTypes['taxonomy_audit']> = {
  Common?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<Taxonomy_AuditCommonArgs>>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Library?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<Taxonomy_AuditLibraryArgs>>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Rating?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<Taxonomy_AuditRatingArgs>>;
  Taxonomy?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<Taxonomy_AuditTaxonomyArgs>>;
  organisations?: Resolver<Array<ResolversTypes['taxonomy_org']>, ParentType, ContextType, Partial<Taxonomy_AuditOrganisationsArgs>>;
  organisations_aggregate?: Resolver<ResolversTypes['taxonomy_org_aggregate'], ParentType, ContextType, Partial<Taxonomy_AuditOrganisations_AggregateArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Taxonomy_Audit_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['taxonomy_audit_aggregate'] = ResolversParentTypes['taxonomy_audit_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['taxonomy_audit_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['taxonomy_audit']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Taxonomy_Audit_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['taxonomy_audit_aggregate_fields'] = ResolversParentTypes['taxonomy_audit_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Taxonomy_Audit_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['taxonomy_audit_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['taxonomy_audit_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Taxonomy_Audit_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['taxonomy_audit_max_fields'] = ResolversParentTypes['taxonomy_audit_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Taxonomy_Audit_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['taxonomy_audit_min_fields'] = ResolversParentTypes['taxonomy_audit_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Taxonomy_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['taxonomy_max_fields'] = ResolversParentTypes['taxonomy_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Taxonomy_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['taxonomy_min_fields'] = ResolversParentTypes['taxonomy_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Taxonomy_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['taxonomy_mutation_response'] = ResolversParentTypes['taxonomy_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['taxonomy']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Taxonomy_OrgResolvers<ContextType = any, ParentType extends ResolversParentTypes['taxonomy_org'] = ResolversParentTypes['taxonomy_org']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  Locale?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  OrgKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  OrgName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  TaxonomyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  taxonomy?: Resolver<ResolversTypes['taxonomy'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Taxonomy_Org_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['taxonomy_org_aggregate'] = ResolversParentTypes['taxonomy_org_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['taxonomy_org_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['taxonomy_org']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Taxonomy_Org_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['taxonomy_org_aggregate_fields'] = ResolversParentTypes['taxonomy_org_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Taxonomy_Org_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['taxonomy_org_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['taxonomy_org_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Taxonomy_Org_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['taxonomy_org_max_fields'] = ResolversParentTypes['taxonomy_org_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrgKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrgName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxonomyId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Taxonomy_Org_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['taxonomy_org_min_fields'] = ResolversParentTypes['taxonomy_org_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrgKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OrgName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TaxonomyId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Taxonomy_Org_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['taxonomy_org_mutation_response'] = ResolversParentTypes['taxonomy_org_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['taxonomy_org']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_ResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['test_result'] = ResolversParentTypes['test_result']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Test_ResultCustomAttributeDataArgs>>;
  Description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  DesignEffectiveness?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  OverallEffectiveness?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ParentControlId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  PerformanceEffectiveness?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  RatingType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  SequentialId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Submitter?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  TestDate?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  TestType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assessmentParents?: Resolver<Array<ResolversTypes['assessment_result_parent']>, ParentType, ContextType, Partial<Test_ResultAssessmentParentsArgs>>;
  assessmentParents_aggregate?: Resolver<ResolversTypes['assessment_result_parent_aggregate'], ParentType, ContextType, Partial<Test_ResultAssessmentParents_AggregateArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  files?: Resolver<Array<ResolversTypes['relation_file']>, ParentType, ContextType, Partial<Test_ResultFilesArgs>>;
  files_aggregate?: Resolver<ResolversTypes['relation_file_aggregate'], ParentType, ContextType, Partial<Test_ResultFiles_AggregateArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['control']>, ParentType, ContextType>;
  submitter?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Result_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['test_result_aggregate'] = ResolversParentTypes['test_result_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['test_result_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['test_result']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Result_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['test_result_aggregate_fields'] = ResolversParentTypes['test_result_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['test_result_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Test_Result_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['test_result_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['test_result_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['test_result_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['test_result_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['test_result_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['test_result_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['test_result_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['test_result_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['test_result_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Result_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['test_result_audit'] = ResolversParentTypes['test_result_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Test_Result_AuditCustomAttributeDataArgs>>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DesignEffectiveness?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OverallEffectiveness?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ParentControlId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  PerformanceEffectiveness?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  RatingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Submitter?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  TestDate?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  TestType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Result_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['test_result_avg_fields'] = ResolversParentTypes['test_result_avg_fields']> = {
  DesignEffectiveness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OverallEffectiveness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PerformanceEffectiveness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Result_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['test_result_max_fields'] = ResolversParentTypes['test_result_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DesignEffectiveness?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OverallEffectiveness?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ParentControlId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  PerformanceEffectiveness?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  RatingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Submitter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  TestType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Result_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['test_result_min_fields'] = ResolversParentTypes['test_result_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DesignEffectiveness?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  OverallEffectiveness?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ParentControlId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  PerformanceEffectiveness?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  RatingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Submitter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TestDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  TestType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Result_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['test_result_mutation_response'] = ResolversParentTypes['test_result_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['test_result']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Result_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['test_result_stddev_fields'] = ResolversParentTypes['test_result_stddev_fields']> = {
  DesignEffectiveness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OverallEffectiveness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PerformanceEffectiveness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Result_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['test_result_stddev_pop_fields'] = ResolversParentTypes['test_result_stddev_pop_fields']> = {
  DesignEffectiveness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OverallEffectiveness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PerformanceEffectiveness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Result_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['test_result_stddev_samp_fields'] = ResolversParentTypes['test_result_stddev_samp_fields']> = {
  DesignEffectiveness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OverallEffectiveness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PerformanceEffectiveness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Result_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['test_result_sum_fields'] = ResolversParentTypes['test_result_sum_fields']> = {
  DesignEffectiveness?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  OverallEffectiveness?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  PerformanceEffectiveness?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Result_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['test_result_var_pop_fields'] = ResolversParentTypes['test_result_var_pop_fields']> = {
  DesignEffectiveness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OverallEffectiveness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PerformanceEffectiveness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Result_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['test_result_var_samp_fields'] = ResolversParentTypes['test_result_var_samp_fields']> = {
  DesignEffectiveness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OverallEffectiveness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PerformanceEffectiveness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Test_Result_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['test_result_variance_fields'] = ResolversParentTypes['test_result_variance_fields']> = {
  DesignEffectiveness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  OverallEffectiveness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PerformanceEffectiveness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Third_PartyResolvers<ContextType = any, ParentType extends ResolversParentTypes['third_party'] = ResolversParentTypes['third_party']> = {
  Address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CityTown?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompaniesHouseNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyDomain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ContactEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ContactName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Criticality?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  CustomAttributeData?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<Third_PartyCustomAttributeDataArgs>>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Postcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PrimaryContactName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SequentialId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Status?: Resolver<ResolversTypes['third_party_status_enum'], ParentType, ContextType>;
  Title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Type?: Resolver<ResolversTypes['third_party_type_enum'], ParentType, ContextType>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<Third_PartyAncestorContributorsArgs>>;
  contributorGroups?: Resolver<Array<ResolversTypes['contributor_group']>, ParentType, ContextType, Partial<Third_PartyContributorGroupsArgs>>;
  contributorGroups_aggregate?: Resolver<ResolversTypes['contributor_group_aggregate'], ParentType, ContextType, Partial<Third_PartyContributorGroups_AggregateArgs>>;
  contributors?: Resolver<Array<ResolversTypes['contributor']>, ParentType, ContextType, Partial<Third_PartyContributorsArgs>>;
  contributors_aggregate?: Resolver<ResolversTypes['contributor_aggregate'], ParentType, ContextType, Partial<Third_PartyContributors_AggregateArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  departments?: Resolver<Array<ResolversTypes['department']>, ParentType, ContextType, Partial<Third_PartyDepartmentsArgs>>;
  files?: Resolver<Array<ResolversTypes['relation_file']>, ParentType, ContextType, Partial<Third_PartyFilesArgs>>;
  files_aggregate?: Resolver<ResolversTypes['relation_file_aggregate'], ParentType, ContextType, Partial<Third_PartyFiles_AggregateArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  ownerGroups?: Resolver<Array<ResolversTypes['owner_group']>, ParentType, ContextType, Partial<Third_PartyOwnerGroupsArgs>>;
  ownerGroups_aggregate?: Resolver<ResolversTypes['owner_group_aggregate'], ParentType, ContextType, Partial<Third_PartyOwnerGroups_AggregateArgs>>;
  owners?: Resolver<Array<ResolversTypes['owner']>, ParentType, ContextType, Partial<Third_PartyOwnersArgs>>;
  owners_aggregate?: Resolver<ResolversTypes['owner_aggregate'], ParentType, ContextType, Partial<Third_PartyOwners_AggregateArgs>>;
  tags?: Resolver<Array<ResolversTypes['tag']>, ParentType, ContextType, Partial<Third_PartyTagsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Third_Party_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['third_party_mutation_response'] = ResolversParentTypes['third_party_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['third_party']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Third_Party_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['third_party_response'] = ResolversParentTypes['third_party_response']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ExpiresAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ParentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  QuestionnaireTemplateVersionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  RecallReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ResponseData?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<Third_Party_ResponseResponseDataArgs>>;
  StartDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  Status?: Resolver<ResolversTypes['third_party_response_status_enum'], ParentType, ContextType>;
  ancestorContributors?: Resolver<Array<ResolversTypes['ancestor_contributor']>, ParentType, ContextType, Partial<Third_Party_ResponseAncestorContributorsArgs>>;
  contributorGroups?: Resolver<Array<ResolversTypes['contributor_group']>, ParentType, ContextType, Partial<Third_Party_ResponseContributorGroupsArgs>>;
  contributorGroups_aggregate?: Resolver<ResolversTypes['contributor_group_aggregate'], ParentType, ContextType, Partial<Third_Party_ResponseContributorGroups_AggregateArgs>>;
  contributors?: Resolver<Array<ResolversTypes['contributor']>, ParentType, ContextType, Partial<Third_Party_ResponseContributorsArgs>>;
  contributors_aggregate?: Resolver<ResolversTypes['contributor_aggregate'], ParentType, ContextType, Partial<Third_Party_ResponseContributors_AggregateArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  files?: Resolver<Array<ResolversTypes['relation_file']>, ParentType, ContextType, Partial<Third_Party_ResponseFilesArgs>>;
  files_aggregate?: Resolver<ResolversTypes['relation_file_aggregate'], ParentType, ContextType, Partial<Third_Party_ResponseFiles_AggregateArgs>>;
  invitees?: Resolver<Array<ResolversTypes['questionnaire_invite']>, ParentType, ContextType, Partial<Third_Party_ResponseInviteesArgs>>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  ownerGroups?: Resolver<Array<ResolversTypes['owner_group']>, ParentType, ContextType, Partial<Third_Party_ResponseOwnerGroupsArgs>>;
  ownerGroups_aggregate?: Resolver<ResolversTypes['owner_group_aggregate'], ParentType, ContextType, Partial<Third_Party_ResponseOwnerGroups_AggregateArgs>>;
  owners?: Resolver<Array<ResolversTypes['owner']>, ParentType, ContextType, Partial<Third_Party_ResponseOwnersArgs>>;
  owners_aggregate?: Resolver<ResolversTypes['owner_aggregate'], ParentType, ContextType, Partial<Third_Party_ResponseOwners_AggregateArgs>>;
  questionnaireTemplateVersion?: Resolver<Maybe<ResolversTypes['questionnaire_template_version']>, ParentType, ContextType>;
  thirdParty?: Resolver<Maybe<ResolversTypes['third_party']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Third_Party_Response_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['third_party_response_mutation_response'] = ResolversParentTypes['third_party_response_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['third_party_response']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Third_Party_Response_StatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['third_party_response_status'] = ResolversParentTypes['third_party_response_status']> = {
  Comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface TimestamptzScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['timestamptz'], any> {
  name: 'timestamptz';
}

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['user'] = ResolversParentTypes['user']> = {
  AuthConnection?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BusinessUnit_Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Department?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FriendlyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsCustomerSupport?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  JobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastSeen?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OfficeLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RoleKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  group_memberships?: Resolver<Array<ResolversTypes['user_group_user']>, ParentType, ContextType, Partial<UserGroup_MembershipsArgs>>;
  group_memberships_aggregate?: Resolver<ResolversTypes['user_group_user_aggregate'], ParentType, ContextType, Partial<UserGroup_Memberships_AggregateArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_aggregate'] = ResolversParentTypes['user_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['user_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_aggregate_fields'] = ResolversParentTypes['user_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<User_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['user_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['user_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_GroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_group'] = ResolversParentTypes['user_group']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  OwnerContributor?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  approvers?: Resolver<Array<ResolversTypes['approver']>, ParentType, ContextType, Partial<User_GroupApproversArgs>>;
  approvers_aggregate?: Resolver<ResolversTypes['approver_aggregate'], ParentType, ContextType, Partial<User_GroupApprovers_AggregateArgs>>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  modifiedByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  organisation?: Resolver<ResolversTypes['auth_organisation'], ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['user_group_user']>, ParentType, ContextType, Partial<User_GroupUsersArgs>>;
  users_aggregate?: Resolver<ResolversTypes['user_group_user_aggregate'], ParentType, ContextType, Partial<User_GroupUsers_AggregateArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Group_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_group_audit'] = ResolversParentTypes['user_group_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  OwnerContributor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Group_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_group_mutation_response'] = ResolversParentTypes['user_group_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['user_group']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Group_UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_group_user'] = ResolversParentTypes['user_group_user']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  UserGroupId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  UserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  authUsers?: Resolver<ResolversTypes['auth_user'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['user']>, ParentType, ContextType>;
  userGroups?: Resolver<ResolversTypes['user_group'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Group_User_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_group_user_aggregate'] = ResolversParentTypes['user_group_user_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['user_group_user_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['user_group_user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Group_User_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_group_user_aggregate_fields'] = ResolversParentTypes['user_group_user_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<User_Group_User_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['user_group_user_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['user_group_user_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Group_User_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_group_user_audit'] = ResolversParentTypes['user_group_user_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserGroupId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  UserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Group_User_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_group_user_max_fields'] = ResolversParentTypes['user_group_user_max_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserGroupId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  UserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Group_User_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_group_user_min_fields'] = ResolversParentTypes['user_group_user_min_fields']> = {
  CreatedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  CreatedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ModifiedByUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserGroupId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  UserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Group_User_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_group_user_mutation_response'] = ResolversParentTypes['user_group_user_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['user_group_user']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_max_fields'] = ResolversParentTypes['user_max_fields']> = {
  AuthConnection?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BusinessUnit_Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Department?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FriendlyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastSeen?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OfficeLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RoleKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_min_fields'] = ResolversParentTypes['user_min_fields']> = {
  AuthConnection?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  BusinessUnit_Id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  Department?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FriendlyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  JobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastSeen?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  OfficeLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  RoleKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Search_PreferencesResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_search_preferences'] = ResolversParentTypes['user_search_preferences']> = {
  FilterByActivePlatformUsers?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  RecentUserIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  ShowArchivedUsers?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ShowDirectoryDepartment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ShowGroups?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ShowInheritedContributors?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ShowUserEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ShowUserJobTitle?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ShowUserLocation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ShowUserPlatformRole?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Search_Preferences_AuditResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_search_preferences_audit'] = ResolversParentTypes['user_search_preferences_audit']> = {
  CreatedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  CreatedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  FilterByActivePlatformUsers?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ModifiedAtTimestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ModifiedByUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  RecentUserIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  ShowArchivedUsers?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ShowDirectoryDepartment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ShowGroups?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ShowUserEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ShowUserJobTitle?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ShowUserLocation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ShowUserPlatformRole?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Search_Preferences_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_search_preferences_mutation_response'] = ResolversParentTypes['user_search_preferences_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['user_search_preferences']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Table_PreferencesResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_table_preferences'] = ResolversParentTypes['user_table_preferences']> = {
  Preferences?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<User_Table_PreferencesPreferencesArgs>>;
  TableId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Table_Preferences_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['user_table_preferences_mutation_response'] = ResolversParentTypes['user_table_preferences_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['user_table_preferences']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UuidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['uuid'], any> {
  name: 'uuid';
}

export type Resolvers<ContextType = any> = {
  DataImportStartImportOutput?: DataImportStartImportOutputResolvers<ContextType>;
  DataImportValidateOutput?: DataImportValidateOutputResolvers<ContextType>;
  DisconnectSlackOutput?: DisconnectSlackOutputResolvers<ContextType>;
  GenericMutationOutput?: GenericMutationOutputResolvers<ContextType>;
  IdOutput?: IdOutputResolvers<ContextType>;
  InsertChildDocumentAssessmentResultOutput?: InsertChildDocumentAssessmentResultOutputResolvers<ContextType>;
  InsertChildObligationAssessmentResultOutput?: InsertChildObligationAssessmentResultOutputResolvers<ContextType>;
  InsertChildRiskAssessmentResultOutput?: InsertChildRiskAssessmentResultOutputResolvers<ContextType>;
  LinkItemRecordOutput?: LinkItemRecordOutputResolvers<ContextType>;
  LinkItemsOutput?: LinkItemsOutputResolvers<ContextType>;
  NotificationPreferencesOutput?: NotificationPreferencesOutputResolvers<ContextType>;
  RecalculateAppetitesOutput?: RecalculateAppetitesOutputResolvers<ContextType>;
  RecalculateRiskScoresOutput?: RecalculateRiskScoresOutputResolvers<ContextType>;
  ReportDataOutput?: ReportDataOutputResolvers<ContextType>;
  RoleOutput?: RoleOutputResolvers<ContextType>;
  SlackNotificationConnectionOutput?: SlackNotificationConnectionOutputResolvers<ContextType>;
  UnlinkItemsOutput?: UnlinkItemsOutputResolvers<ContextType>;
  UpdateNotificationPreferencesOutput?: UpdateNotificationPreferencesOutputResolvers<ContextType>;
  UpdateUserRolesMutationOutput?: UpdateUserRolesMutationOutputResolvers<ContextType>;
  acceptance?: AcceptanceResolvers<ContextType>;
  acceptance_aggregate?: Acceptance_AggregateResolvers<ContextType>;
  acceptance_aggregate_fields?: Acceptance_Aggregate_FieldsResolvers<ContextType>;
  acceptance_audit?: Acceptance_AuditResolvers<ContextType>;
  acceptance_avg_fields?: Acceptance_Avg_FieldsResolvers<ContextType>;
  acceptance_max_fields?: Acceptance_Max_FieldsResolvers<ContextType>;
  acceptance_min_fields?: Acceptance_Min_FieldsResolvers<ContextType>;
  acceptance_parent?: Acceptance_ParentResolvers<ContextType>;
  acceptance_parent_aggregate?: Acceptance_Parent_AggregateResolvers<ContextType>;
  acceptance_parent_aggregate_fields?: Acceptance_Parent_Aggregate_FieldsResolvers<ContextType>;
  acceptance_parent_audit?: Acceptance_Parent_AuditResolvers<ContextType>;
  acceptance_parent_max_fields?: Acceptance_Parent_Max_FieldsResolvers<ContextType>;
  acceptance_parent_min_fields?: Acceptance_Parent_Min_FieldsResolvers<ContextType>;
  acceptance_parent_mutation_response?: Acceptance_Parent_Mutation_ResponseResolvers<ContextType>;
  acceptance_stddev_fields?: Acceptance_Stddev_FieldsResolvers<ContextType>;
  acceptance_stddev_pop_fields?: Acceptance_Stddev_Pop_FieldsResolvers<ContextType>;
  acceptance_stddev_samp_fields?: Acceptance_Stddev_Samp_FieldsResolvers<ContextType>;
  acceptance_sum_fields?: Acceptance_Sum_FieldsResolvers<ContextType>;
  acceptance_var_pop_fields?: Acceptance_Var_Pop_FieldsResolvers<ContextType>;
  acceptance_var_samp_fields?: Acceptance_Var_Samp_FieldsResolvers<ContextType>;
  acceptance_variance_fields?: Acceptance_Variance_FieldsResolvers<ContextType>;
  action?: ActionResolvers<ContextType>;
  action_aggregate?: Action_AggregateResolvers<ContextType>;
  action_aggregate_fields?: Action_Aggregate_FieldsResolvers<ContextType>;
  action_audit?: Action_AuditResolvers<ContextType>;
  action_audit_aggregate?: Action_Audit_AggregateResolvers<ContextType>;
  action_audit_aggregate_fields?: Action_Audit_Aggregate_FieldsResolvers<ContextType>;
  action_audit_avg_fields?: Action_Audit_Avg_FieldsResolvers<ContextType>;
  action_audit_max_fields?: Action_Audit_Max_FieldsResolvers<ContextType>;
  action_audit_min_fields?: Action_Audit_Min_FieldsResolvers<ContextType>;
  action_audit_stddev_fields?: Action_Audit_Stddev_FieldsResolvers<ContextType>;
  action_audit_stddev_pop_fields?: Action_Audit_Stddev_Pop_FieldsResolvers<ContextType>;
  action_audit_stddev_samp_fields?: Action_Audit_Stddev_Samp_FieldsResolvers<ContextType>;
  action_audit_sum_fields?: Action_Audit_Sum_FieldsResolvers<ContextType>;
  action_audit_var_pop_fields?: Action_Audit_Var_Pop_FieldsResolvers<ContextType>;
  action_audit_var_samp_fields?: Action_Audit_Var_Samp_FieldsResolvers<ContextType>;
  action_audit_variance_fields?: Action_Audit_Variance_FieldsResolvers<ContextType>;
  action_avg_fields?: Action_Avg_FieldsResolvers<ContextType>;
  action_max_fields?: Action_Max_FieldsResolvers<ContextType>;
  action_min_fields?: Action_Min_FieldsResolvers<ContextType>;
  action_parent?: Action_ParentResolvers<ContextType>;
  action_parent_aggregate?: Action_Parent_AggregateResolvers<ContextType>;
  action_parent_aggregate_fields?: Action_Parent_Aggregate_FieldsResolvers<ContextType>;
  action_parent_audit?: Action_Parent_AuditResolvers<ContextType>;
  action_parent_max_fields?: Action_Parent_Max_FieldsResolvers<ContextType>;
  action_parent_min_fields?: Action_Parent_Min_FieldsResolvers<ContextType>;
  action_parent_mutation_response?: Action_Parent_Mutation_ResponseResolvers<ContextType>;
  action_stddev_fields?: Action_Stddev_FieldsResolvers<ContextType>;
  action_stddev_pop_fields?: Action_Stddev_Pop_FieldsResolvers<ContextType>;
  action_stddev_samp_fields?: Action_Stddev_Samp_FieldsResolvers<ContextType>;
  action_sum_fields?: Action_Sum_FieldsResolvers<ContextType>;
  action_update?: Action_UpdateResolvers<ContextType>;
  action_update_aggregate?: Action_Update_AggregateResolvers<ContextType>;
  action_update_aggregate_fields?: Action_Update_Aggregate_FieldsResolvers<ContextType>;
  action_update_audit?: Action_Update_AuditResolvers<ContextType>;
  action_update_max_fields?: Action_Update_Max_FieldsResolvers<ContextType>;
  action_update_min_fields?: Action_Update_Min_FieldsResolvers<ContextType>;
  action_update_mutation_response?: Action_Update_Mutation_ResponseResolvers<ContextType>;
  action_update_summary?: Action_Update_SummaryResolvers<ContextType>;
  action_update_summary_aggregate?: Action_Update_Summary_AggregateResolvers<ContextType>;
  action_update_summary_aggregate_fields?: Action_Update_Summary_Aggregate_FieldsResolvers<ContextType>;
  action_update_summary_avg_fields?: Action_Update_Summary_Avg_FieldsResolvers<ContextType>;
  action_update_summary_max_fields?: Action_Update_Summary_Max_FieldsResolvers<ContextType>;
  action_update_summary_min_fields?: Action_Update_Summary_Min_FieldsResolvers<ContextType>;
  action_update_summary_stddev_fields?: Action_Update_Summary_Stddev_FieldsResolvers<ContextType>;
  action_update_summary_stddev_pop_fields?: Action_Update_Summary_Stddev_Pop_FieldsResolvers<ContextType>;
  action_update_summary_stddev_samp_fields?: Action_Update_Summary_Stddev_Samp_FieldsResolvers<ContextType>;
  action_update_summary_sum_fields?: Action_Update_Summary_Sum_FieldsResolvers<ContextType>;
  action_update_summary_var_pop_fields?: Action_Update_Summary_Var_Pop_FieldsResolvers<ContextType>;
  action_update_summary_var_samp_fields?: Action_Update_Summary_Var_Samp_FieldsResolvers<ContextType>;
  action_update_summary_variance_fields?: Action_Update_Summary_Variance_FieldsResolvers<ContextType>;
  action_var_pop_fields?: Action_Var_Pop_FieldsResolvers<ContextType>;
  action_var_samp_fields?: Action_Var_Samp_FieldsResolvers<ContextType>;
  action_variance_fields?: Action_Variance_FieldsResolvers<ContextType>;
  aggregation_org?: Aggregation_OrgResolvers<ContextType>;
  ancestor_contributor?: Ancestor_ContributorResolvers<ContextType>;
  appetite?: AppetiteResolvers<ContextType>;
  appetite_aggregate?: Appetite_AggregateResolvers<ContextType>;
  appetite_aggregate_fields?: Appetite_Aggregate_FieldsResolvers<ContextType>;
  appetite_audit?: Appetite_AuditResolvers<ContextType>;
  appetite_avg_fields?: Appetite_Avg_FieldsResolvers<ContextType>;
  appetite_max_fields?: Appetite_Max_FieldsResolvers<ContextType>;
  appetite_min_fields?: Appetite_Min_FieldsResolvers<ContextType>;
  appetite_mutation_response?: Appetite_Mutation_ResponseResolvers<ContextType>;
  appetite_parent?: Appetite_ParentResolvers<ContextType>;
  appetite_parent_aggregate?: Appetite_Parent_AggregateResolvers<ContextType>;
  appetite_parent_aggregate_fields?: Appetite_Parent_Aggregate_FieldsResolvers<ContextType>;
  appetite_parent_audit?: Appetite_Parent_AuditResolvers<ContextType>;
  appetite_parent_audit_aggregate?: Appetite_Parent_Audit_AggregateResolvers<ContextType>;
  appetite_parent_audit_aggregate_fields?: Appetite_Parent_Audit_Aggregate_FieldsResolvers<ContextType>;
  appetite_parent_audit_max_fields?: Appetite_Parent_Audit_Max_FieldsResolvers<ContextType>;
  appetite_parent_audit_min_fields?: Appetite_Parent_Audit_Min_FieldsResolvers<ContextType>;
  appetite_parent_max_fields?: Appetite_Parent_Max_FieldsResolvers<ContextType>;
  appetite_parent_min_fields?: Appetite_Parent_Min_FieldsResolvers<ContextType>;
  appetite_parent_mutation_response?: Appetite_Parent_Mutation_ResponseResolvers<ContextType>;
  appetite_stddev_fields?: Appetite_Stddev_FieldsResolvers<ContextType>;
  appetite_stddev_pop_fields?: Appetite_Stddev_Pop_FieldsResolvers<ContextType>;
  appetite_stddev_samp_fields?: Appetite_Stddev_Samp_FieldsResolvers<ContextType>;
  appetite_sum_fields?: Appetite_Sum_FieldsResolvers<ContextType>;
  appetite_var_pop_fields?: Appetite_Var_Pop_FieldsResolvers<ContextType>;
  appetite_var_samp_fields?: Appetite_Var_Samp_FieldsResolvers<ContextType>;
  appetite_variance_fields?: Appetite_Variance_FieldsResolvers<ContextType>;
  approval?: ApprovalResolvers<ContextType>;
  approval_audit?: Approval_AuditResolvers<ContextType>;
  approval_level?: Approval_LevelResolvers<ContextType>;
  approval_level_audit?: Approval_Level_AuditResolvers<ContextType>;
  approval_level_mutation_response?: Approval_Level_Mutation_ResponseResolvers<ContextType>;
  approval_mutation_response?: Approval_Mutation_ResponseResolvers<ContextType>;
  approver?: ApproverResolvers<ContextType>;
  approver_aggregate?: Approver_AggregateResolvers<ContextType>;
  approver_aggregate_fields?: Approver_Aggregate_FieldsResolvers<ContextType>;
  approver_audit?: Approver_AuditResolvers<ContextType>;
  approver_max_fields?: Approver_Max_FieldsResolvers<ContextType>;
  approver_min_fields?: Approver_Min_FieldsResolvers<ContextType>;
  approver_mutation_response?: Approver_Mutation_ResponseResolvers<ContextType>;
  approver_response?: Approver_ResponseResolvers<ContextType>;
  assessment?: AssessmentResolvers<ContextType>;
  assessment_activity?: Assessment_ActivityResolvers<ContextType>;
  assessment_activity_aggregate?: Assessment_Activity_AggregateResolvers<ContextType>;
  assessment_activity_aggregate_fields?: Assessment_Activity_Aggregate_FieldsResolvers<ContextType>;
  assessment_activity_audit?: Assessment_Activity_AuditResolvers<ContextType>;
  assessment_activity_max_fields?: Assessment_Activity_Max_FieldsResolvers<ContextType>;
  assessment_activity_min_fields?: Assessment_Activity_Min_FieldsResolvers<ContextType>;
  assessment_activity_mutation_response?: Assessment_Activity_Mutation_ResponseResolvers<ContextType>;
  assessment_aggregate?: Assessment_AggregateResolvers<ContextType>;
  assessment_aggregate_fields?: Assessment_Aggregate_FieldsResolvers<ContextType>;
  assessment_audit?: Assessment_AuditResolvers<ContextType>;
  assessment_audit_aggregate?: Assessment_Audit_AggregateResolvers<ContextType>;
  assessment_audit_aggregate_fields?: Assessment_Audit_Aggregate_FieldsResolvers<ContextType>;
  assessment_audit_avg_fields?: Assessment_Audit_Avg_FieldsResolvers<ContextType>;
  assessment_audit_max_fields?: Assessment_Audit_Max_FieldsResolvers<ContextType>;
  assessment_audit_min_fields?: Assessment_Audit_Min_FieldsResolvers<ContextType>;
  assessment_audit_stddev_fields?: Assessment_Audit_Stddev_FieldsResolvers<ContextType>;
  assessment_audit_stddev_pop_fields?: Assessment_Audit_Stddev_Pop_FieldsResolvers<ContextType>;
  assessment_audit_stddev_samp_fields?: Assessment_Audit_Stddev_Samp_FieldsResolvers<ContextType>;
  assessment_audit_sum_fields?: Assessment_Audit_Sum_FieldsResolvers<ContextType>;
  assessment_audit_var_pop_fields?: Assessment_Audit_Var_Pop_FieldsResolvers<ContextType>;
  assessment_audit_var_samp_fields?: Assessment_Audit_Var_Samp_FieldsResolvers<ContextType>;
  assessment_audit_variance_fields?: Assessment_Audit_Variance_FieldsResolvers<ContextType>;
  assessment_avg_fields?: Assessment_Avg_FieldsResolvers<ContextType>;
  assessment_max_fields?: Assessment_Max_FieldsResolvers<ContextType>;
  assessment_min_fields?: Assessment_Min_FieldsResolvers<ContextType>;
  assessment_mutation_response?: Assessment_Mutation_ResponseResolvers<ContextType>;
  assessment_result_parent?: Assessment_Result_ParentResolvers<ContextType>;
  assessment_result_parent_aggregate?: Assessment_Result_Parent_AggregateResolvers<ContextType>;
  assessment_result_parent_aggregate_fields?: Assessment_Result_Parent_Aggregate_FieldsResolvers<ContextType>;
  assessment_result_parent_audit?: Assessment_Result_Parent_AuditResolvers<ContextType>;
  assessment_result_parent_max_fields?: Assessment_Result_Parent_Max_FieldsResolvers<ContextType>;
  assessment_result_parent_min_fields?: Assessment_Result_Parent_Min_FieldsResolvers<ContextType>;
  assessment_result_parent_mutation_response?: Assessment_Result_Parent_Mutation_ResponseResolvers<ContextType>;
  assessment_stddev_fields?: Assessment_Stddev_FieldsResolvers<ContextType>;
  assessment_stddev_pop_fields?: Assessment_Stddev_Pop_FieldsResolvers<ContextType>;
  assessment_stddev_samp_fields?: Assessment_Stddev_Samp_FieldsResolvers<ContextType>;
  assessment_sum_fields?: Assessment_Sum_FieldsResolvers<ContextType>;
  assessment_var_pop_fields?: Assessment_Var_Pop_FieldsResolvers<ContextType>;
  assessment_var_samp_fields?: Assessment_Var_Samp_FieldsResolvers<ContextType>;
  assessment_variance_fields?: Assessment_Variance_FieldsResolvers<ContextType>;
  attestation_config?: Attestation_ConfigResolvers<ContextType>;
  attestation_config_mutation_response?: Attestation_Config_Mutation_ResponseResolvers<ContextType>;
  attestation_group?: Attestation_GroupResolvers<ContextType>;
  attestation_group_mutation_response?: Attestation_Group_Mutation_ResponseResolvers<ContextType>;
  attestation_record?: Attestation_RecordResolvers<ContextType>;
  attestation_record_aggregate?: Attestation_Record_AggregateResolvers<ContextType>;
  attestation_record_aggregate_fields?: Attestation_Record_Aggregate_FieldsResolvers<ContextType>;
  attestation_record_max_fields?: Attestation_Record_Max_FieldsResolvers<ContextType>;
  attestation_record_min_fields?: Attestation_Record_Min_FieldsResolvers<ContextType>;
  audit_log_view?: Audit_Log_ViewResolvers<ContextType>;
  audit_log_view_aggregate?: Audit_Log_View_AggregateResolvers<ContextType>;
  audit_log_view_aggregate_fields?: Audit_Log_View_Aggregate_FieldsResolvers<ContextType>;
  audit_log_view_max_fields?: Audit_Log_View_Max_FieldsResolvers<ContextType>;
  audit_log_view_min_fields?: Audit_Log_View_Min_FieldsResolvers<ContextType>;
  auth_organisation?: Auth_OrganisationResolvers<ContextType>;
  auth_organisationuser?: Auth_OrganisationuserResolvers<ContextType>;
  auth_organisationuser_mutation_response?: Auth_Organisationuser_Mutation_ResponseResolvers<ContextType>;
  auth_user?: Auth_UserResolvers<ContextType>;
  auth_user_aggregate?: Auth_User_AggregateResolvers<ContextType>;
  auth_user_aggregate_fields?: Auth_User_Aggregate_FieldsResolvers<ContextType>;
  auth_user_max_fields?: Auth_User_Max_FieldsResolvers<ContextType>;
  auth_user_min_fields?: Auth_User_Min_FieldsResolvers<ContextType>;
  auth_user_mutation_response?: Auth_User_Mutation_ResponseResolvers<ContextType>;
  bigint?: GraphQLScalarType;
  business_area?: Business_AreaResolvers<ContextType>;
  business_area_aggregate?: Business_Area_AggregateResolvers<ContextType>;
  business_area_aggregate_fields?: Business_Area_Aggregate_FieldsResolvers<ContextType>;
  business_area_avg_fields?: Business_Area_Avg_FieldsResolvers<ContextType>;
  business_area_max_fields?: Business_Area_Max_FieldsResolvers<ContextType>;
  business_area_min_fields?: Business_Area_Min_FieldsResolvers<ContextType>;
  business_area_mutation_response?: Business_Area_Mutation_ResponseResolvers<ContextType>;
  business_area_stddev_fields?: Business_Area_Stddev_FieldsResolvers<ContextType>;
  business_area_stddev_pop_fields?: Business_Area_Stddev_Pop_FieldsResolvers<ContextType>;
  business_area_stddev_samp_fields?: Business_Area_Stddev_Samp_FieldsResolvers<ContextType>;
  business_area_sum_fields?: Business_Area_Sum_FieldsResolvers<ContextType>;
  business_area_var_pop_fields?: Business_Area_Var_Pop_FieldsResolvers<ContextType>;
  business_area_var_samp_fields?: Business_Area_Var_Samp_FieldsResolvers<ContextType>;
  business_area_variance_fields?: Business_Area_Variance_FieldsResolvers<ContextType>;
  cause?: CauseResolvers<ContextType>;
  cause_aggregate?: Cause_AggregateResolvers<ContextType>;
  cause_aggregate_fields?: Cause_Aggregate_FieldsResolvers<ContextType>;
  cause_audit?: Cause_AuditResolvers<ContextType>;
  cause_avg_fields?: Cause_Avg_FieldsResolvers<ContextType>;
  cause_max_fields?: Cause_Max_FieldsResolvers<ContextType>;
  cause_min_fields?: Cause_Min_FieldsResolvers<ContextType>;
  cause_mutation_response?: Cause_Mutation_ResponseResolvers<ContextType>;
  cause_stddev_fields?: Cause_Stddev_FieldsResolvers<ContextType>;
  cause_stddev_pop_fields?: Cause_Stddev_Pop_FieldsResolvers<ContextType>;
  cause_stddev_samp_fields?: Cause_Stddev_Samp_FieldsResolvers<ContextType>;
  cause_sum_fields?: Cause_Sum_FieldsResolvers<ContextType>;
  cause_var_pop_fields?: Cause_Var_Pop_FieldsResolvers<ContextType>;
  cause_var_samp_fields?: Cause_Var_Samp_FieldsResolvers<ContextType>;
  cause_variance_fields?: Cause_Variance_FieldsResolvers<ContextType>;
  change_request?: Change_RequestResolvers<ContextType>;
  change_request_aggregate?: Change_Request_AggregateResolvers<ContextType>;
  change_request_aggregate_fields?: Change_Request_Aggregate_FieldsResolvers<ContextType>;
  change_request_audit?: Change_Request_AuditResolvers<ContextType>;
  change_request_avg_fields?: Change_Request_Avg_FieldsResolvers<ContextType>;
  change_request_contributor?: Change_Request_ContributorResolvers<ContextType>;
  change_request_max_fields?: Change_Request_Max_FieldsResolvers<ContextType>;
  change_request_min_fields?: Change_Request_Min_FieldsResolvers<ContextType>;
  change_request_stddev_fields?: Change_Request_Stddev_FieldsResolvers<ContextType>;
  change_request_stddev_pop_fields?: Change_Request_Stddev_Pop_FieldsResolvers<ContextType>;
  change_request_stddev_samp_fields?: Change_Request_Stddev_Samp_FieldsResolvers<ContextType>;
  change_request_sum_fields?: Change_Request_Sum_FieldsResolvers<ContextType>;
  change_request_var_pop_fields?: Change_Request_Var_Pop_FieldsResolvers<ContextType>;
  change_request_var_samp_fields?: Change_Request_Var_Samp_FieldsResolvers<ContextType>;
  change_request_variance_fields?: Change_Request_Variance_FieldsResolvers<ContextType>;
  comment?: CommentResolvers<ContextType>;
  comment_audit?: Comment_AuditResolvers<ContextType>;
  comment_mutation_response?: Comment_Mutation_ResponseResolvers<ContextType>;
  compliance_monitoring_assessment?: Compliance_Monitoring_AssessmentResolvers<ContextType>;
  compliance_monitoring_assessment_aggregate?: Compliance_Monitoring_Assessment_AggregateResolvers<ContextType>;
  compliance_monitoring_assessment_aggregate_fields?: Compliance_Monitoring_Assessment_Aggregate_FieldsResolvers<ContextType>;
  compliance_monitoring_assessment_avg_fields?: Compliance_Monitoring_Assessment_Avg_FieldsResolvers<ContextType>;
  compliance_monitoring_assessment_max_fields?: Compliance_Monitoring_Assessment_Max_FieldsResolvers<ContextType>;
  compliance_monitoring_assessment_min_fields?: Compliance_Monitoring_Assessment_Min_FieldsResolvers<ContextType>;
  compliance_monitoring_assessment_mutation_response?: Compliance_Monitoring_Assessment_Mutation_ResponseResolvers<ContextType>;
  compliance_monitoring_assessment_stddev_fields?: Compliance_Monitoring_Assessment_Stddev_FieldsResolvers<ContextType>;
  compliance_monitoring_assessment_stddev_pop_fields?: Compliance_Monitoring_Assessment_Stddev_Pop_FieldsResolvers<ContextType>;
  compliance_monitoring_assessment_stddev_samp_fields?: Compliance_Monitoring_Assessment_Stddev_Samp_FieldsResolvers<ContextType>;
  compliance_monitoring_assessment_sum_fields?: Compliance_Monitoring_Assessment_Sum_FieldsResolvers<ContextType>;
  compliance_monitoring_assessment_var_pop_fields?: Compliance_Monitoring_Assessment_Var_Pop_FieldsResolvers<ContextType>;
  compliance_monitoring_assessment_var_samp_fields?: Compliance_Monitoring_Assessment_Var_Samp_FieldsResolvers<ContextType>;
  compliance_monitoring_assessment_variance_fields?: Compliance_Monitoring_Assessment_Variance_FieldsResolvers<ContextType>;
  consequence?: ConsequenceResolvers<ContextType>;
  consequence_aggregate?: Consequence_AggregateResolvers<ContextType>;
  consequence_aggregate_fields?: Consequence_Aggregate_FieldsResolvers<ContextType>;
  consequence_audit?: Consequence_AuditResolvers<ContextType>;
  consequence_audit_aggregate?: Consequence_Audit_AggregateResolvers<ContextType>;
  consequence_audit_aggregate_fields?: Consequence_Audit_Aggregate_FieldsResolvers<ContextType>;
  consequence_audit_avg_fields?: Consequence_Audit_Avg_FieldsResolvers<ContextType>;
  consequence_audit_max_fields?: Consequence_Audit_Max_FieldsResolvers<ContextType>;
  consequence_audit_min_fields?: Consequence_Audit_Min_FieldsResolvers<ContextType>;
  consequence_audit_stddev_fields?: Consequence_Audit_Stddev_FieldsResolvers<ContextType>;
  consequence_audit_stddev_pop_fields?: Consequence_Audit_Stddev_Pop_FieldsResolvers<ContextType>;
  consequence_audit_stddev_samp_fields?: Consequence_Audit_Stddev_Samp_FieldsResolvers<ContextType>;
  consequence_audit_sum_fields?: Consequence_Audit_Sum_FieldsResolvers<ContextType>;
  consequence_audit_var_pop_fields?: Consequence_Audit_Var_Pop_FieldsResolvers<ContextType>;
  consequence_audit_var_samp_fields?: Consequence_Audit_Var_Samp_FieldsResolvers<ContextType>;
  consequence_audit_variance_fields?: Consequence_Audit_Variance_FieldsResolvers<ContextType>;
  consequence_avg_fields?: Consequence_Avg_FieldsResolvers<ContextType>;
  consequence_max_fields?: Consequence_Max_FieldsResolvers<ContextType>;
  consequence_min_fields?: Consequence_Min_FieldsResolvers<ContextType>;
  consequence_mutation_response?: Consequence_Mutation_ResponseResolvers<ContextType>;
  consequence_stddev_fields?: Consequence_Stddev_FieldsResolvers<ContextType>;
  consequence_stddev_pop_fields?: Consequence_Stddev_Pop_FieldsResolvers<ContextType>;
  consequence_stddev_samp_fields?: Consequence_Stddev_Samp_FieldsResolvers<ContextType>;
  consequence_sum_fields?: Consequence_Sum_FieldsResolvers<ContextType>;
  consequence_var_pop_fields?: Consequence_Var_Pop_FieldsResolvers<ContextType>;
  consequence_var_samp_fields?: Consequence_Var_Samp_FieldsResolvers<ContextType>;
  consequence_variance_fields?: Consequence_Variance_FieldsResolvers<ContextType>;
  contributor?: ContributorResolvers<ContextType>;
  contributor_aggregate?: Contributor_AggregateResolvers<ContextType>;
  contributor_aggregate_fields?: Contributor_Aggregate_FieldsResolvers<ContextType>;
  contributor_audit?: Contributor_AuditResolvers<ContextType>;
  contributor_audit_aggregate?: Contributor_Audit_AggregateResolvers<ContextType>;
  contributor_audit_aggregate_fields?: Contributor_Audit_Aggregate_FieldsResolvers<ContextType>;
  contributor_audit_max_fields?: Contributor_Audit_Max_FieldsResolvers<ContextType>;
  contributor_audit_min_fields?: Contributor_Audit_Min_FieldsResolvers<ContextType>;
  contributor_group?: Contributor_GroupResolvers<ContextType>;
  contributor_group_aggregate?: Contributor_Group_AggregateResolvers<ContextType>;
  contributor_group_aggregate_fields?: Contributor_Group_Aggregate_FieldsResolvers<ContextType>;
  contributor_group_audit?: Contributor_Group_AuditResolvers<ContextType>;
  contributor_group_audit_aggregate?: Contributor_Group_Audit_AggregateResolvers<ContextType>;
  contributor_group_audit_aggregate_fields?: Contributor_Group_Audit_Aggregate_FieldsResolvers<ContextType>;
  contributor_group_audit_max_fields?: Contributor_Group_Audit_Max_FieldsResolvers<ContextType>;
  contributor_group_audit_min_fields?: Contributor_Group_Audit_Min_FieldsResolvers<ContextType>;
  contributor_group_max_fields?: Contributor_Group_Max_FieldsResolvers<ContextType>;
  contributor_group_min_fields?: Contributor_Group_Min_FieldsResolvers<ContextType>;
  contributor_group_mutation_response?: Contributor_Group_Mutation_ResponseResolvers<ContextType>;
  contributor_max_fields?: Contributor_Max_FieldsResolvers<ContextType>;
  contributor_min_fields?: Contributor_Min_FieldsResolvers<ContextType>;
  contributor_mutation_response?: Contributor_Mutation_ResponseResolvers<ContextType>;
  control?: ControlResolvers<ContextType>;
  control_aggregate?: Control_AggregateResolvers<ContextType>;
  control_aggregate_fields?: Control_Aggregate_FieldsResolvers<ContextType>;
  control_audit?: Control_AuditResolvers<ContextType>;
  control_avg_fields?: Control_Avg_FieldsResolvers<ContextType>;
  control_group?: Control_GroupResolvers<ContextType>;
  control_group_aggregate?: Control_Group_AggregateResolvers<ContextType>;
  control_group_aggregate_fields?: Control_Group_Aggregate_FieldsResolvers<ContextType>;
  control_group_audit?: Control_Group_AuditResolvers<ContextType>;
  control_group_max_fields?: Control_Group_Max_FieldsResolvers<ContextType>;
  control_group_min_fields?: Control_Group_Min_FieldsResolvers<ContextType>;
  control_group_mutation_response?: Control_Group_Mutation_ResponseResolvers<ContextType>;
  control_max_fields?: Control_Max_FieldsResolvers<ContextType>;
  control_min_fields?: Control_Min_FieldsResolvers<ContextType>;
  control_mutation_response?: Control_Mutation_ResponseResolvers<ContextType>;
  control_parent?: Control_ParentResolvers<ContextType>;
  control_parent_aggregate?: Control_Parent_AggregateResolvers<ContextType>;
  control_parent_aggregate_fields?: Control_Parent_Aggregate_FieldsResolvers<ContextType>;
  control_parent_audit?: Control_Parent_AuditResolvers<ContextType>;
  control_parent_max_fields?: Control_Parent_Max_FieldsResolvers<ContextType>;
  control_parent_min_fields?: Control_Parent_Min_FieldsResolvers<ContextType>;
  control_parent_mutation_response?: Control_Parent_Mutation_ResponseResolvers<ContextType>;
  control_stddev_fields?: Control_Stddev_FieldsResolvers<ContextType>;
  control_stddev_pop_fields?: Control_Stddev_Pop_FieldsResolvers<ContextType>;
  control_stddev_samp_fields?: Control_Stddev_Samp_FieldsResolvers<ContextType>;
  control_sum_fields?: Control_Sum_FieldsResolvers<ContextType>;
  control_var_pop_fields?: Control_Var_Pop_FieldsResolvers<ContextType>;
  control_var_samp_fields?: Control_Var_Samp_FieldsResolvers<ContextType>;
  control_variance_fields?: Control_Variance_FieldsResolvers<ContextType>;
  conversation?: ConversationResolvers<ContextType>;
  conversation_audit?: Conversation_AuditResolvers<ContextType>;
  conversation_mutation_response?: Conversation_Mutation_ResponseResolvers<ContextType>;
  custom_attribute_schema?: Custom_Attribute_SchemaResolvers<ContextType>;
  custom_attribute_schema_audit?: Custom_Attribute_Schema_AuditResolvers<ContextType>;
  custom_attribute_schema_mutation_response?: Custom_Attribute_Schema_Mutation_ResponseResolvers<ContextType>;
  custom_ribbon?: Custom_RibbonResolvers<ContextType>;
  custom_ribbon_audit?: Custom_Ribbon_AuditResolvers<ContextType>;
  custom_ribbon_mutation_response?: Custom_Ribbon_Mutation_ResponseResolvers<ContextType>;
  dashboard?: DashboardResolvers<ContextType>;
  dashboard_audit?: Dashboard_AuditResolvers<ContextType>;
  dashboard_mutation_response?: Dashboard_Mutation_ResponseResolvers<ContextType>;
  data_import?: Data_ImportResolvers<ContextType>;
  data_import_aggregate?: Data_Import_AggregateResolvers<ContextType>;
  data_import_aggregate_fields?: Data_Import_Aggregate_FieldsResolvers<ContextType>;
  data_import_error?: Data_Import_ErrorResolvers<ContextType>;
  data_import_error_aggregate?: Data_Import_Error_AggregateResolvers<ContextType>;
  data_import_error_aggregate_fields?: Data_Import_Error_Aggregate_FieldsResolvers<ContextType>;
  data_import_error_avg_fields?: Data_Import_Error_Avg_FieldsResolvers<ContextType>;
  data_import_error_max_fields?: Data_Import_Error_Max_FieldsResolvers<ContextType>;
  data_import_error_min_fields?: Data_Import_Error_Min_FieldsResolvers<ContextType>;
  data_import_error_mutation_response?: Data_Import_Error_Mutation_ResponseResolvers<ContextType>;
  data_import_error_stddev_fields?: Data_Import_Error_Stddev_FieldsResolvers<ContextType>;
  data_import_error_stddev_pop_fields?: Data_Import_Error_Stddev_Pop_FieldsResolvers<ContextType>;
  data_import_error_stddev_samp_fields?: Data_Import_Error_Stddev_Samp_FieldsResolvers<ContextType>;
  data_import_error_sum_fields?: Data_Import_Error_Sum_FieldsResolvers<ContextType>;
  data_import_error_var_pop_fields?: Data_Import_Error_Var_Pop_FieldsResolvers<ContextType>;
  data_import_error_var_samp_fields?: Data_Import_Error_Var_Samp_FieldsResolvers<ContextType>;
  data_import_error_variance_fields?: Data_Import_Error_Variance_FieldsResolvers<ContextType>;
  data_import_max_fields?: Data_Import_Max_FieldsResolvers<ContextType>;
  data_import_min_fields?: Data_Import_Min_FieldsResolvers<ContextType>;
  data_import_mutation_response?: Data_Import_Mutation_ResponseResolvers<ContextType>;
  department?: DepartmentResolvers<ContextType>;
  department_audit?: Department_AuditResolvers<ContextType>;
  department_mutation_response?: Department_Mutation_ResponseResolvers<ContextType>;
  department_type?: Department_TypeResolvers<ContextType>;
  department_type_audit?: Department_Type_AuditResolvers<ContextType>;
  department_type_group?: Department_Type_GroupResolvers<ContextType>;
  department_type_group_audit?: Department_Type_Group_AuditResolvers<ContextType>;
  department_type_group_mutation_response?: Department_Type_Group_Mutation_ResponseResolvers<ContextType>;
  department_type_mutation_response?: Department_Type_Mutation_ResponseResolvers<ContextType>;
  document?: DocumentResolvers<ContextType>;
  document_assessment_result?: Document_Assessment_ResultResolvers<ContextType>;
  document_assessment_result_aggregate?: Document_Assessment_Result_AggregateResolvers<ContextType>;
  document_assessment_result_aggregate_fields?: Document_Assessment_Result_Aggregate_FieldsResolvers<ContextType>;
  document_assessment_result_audit?: Document_Assessment_Result_AuditResolvers<ContextType>;
  document_assessment_result_audit_aggregate?: Document_Assessment_Result_Audit_AggregateResolvers<ContextType>;
  document_assessment_result_audit_aggregate_fields?: Document_Assessment_Result_Audit_Aggregate_FieldsResolvers<ContextType>;
  document_assessment_result_audit_avg_fields?: Document_Assessment_Result_Audit_Avg_FieldsResolvers<ContextType>;
  document_assessment_result_audit_max_fields?: Document_Assessment_Result_Audit_Max_FieldsResolvers<ContextType>;
  document_assessment_result_audit_min_fields?: Document_Assessment_Result_Audit_Min_FieldsResolvers<ContextType>;
  document_assessment_result_audit_stddev_fields?: Document_Assessment_Result_Audit_Stddev_FieldsResolvers<ContextType>;
  document_assessment_result_audit_stddev_pop_fields?: Document_Assessment_Result_Audit_Stddev_Pop_FieldsResolvers<ContextType>;
  document_assessment_result_audit_stddev_samp_fields?: Document_Assessment_Result_Audit_Stddev_Samp_FieldsResolvers<ContextType>;
  document_assessment_result_audit_sum_fields?: Document_Assessment_Result_Audit_Sum_FieldsResolvers<ContextType>;
  document_assessment_result_audit_var_pop_fields?: Document_Assessment_Result_Audit_Var_Pop_FieldsResolvers<ContextType>;
  document_assessment_result_audit_var_samp_fields?: Document_Assessment_Result_Audit_Var_Samp_FieldsResolvers<ContextType>;
  document_assessment_result_audit_variance_fields?: Document_Assessment_Result_Audit_Variance_FieldsResolvers<ContextType>;
  document_assessment_result_avg_fields?: Document_Assessment_Result_Avg_FieldsResolvers<ContextType>;
  document_assessment_result_max_fields?: Document_Assessment_Result_Max_FieldsResolvers<ContextType>;
  document_assessment_result_min_fields?: Document_Assessment_Result_Min_FieldsResolvers<ContextType>;
  document_assessment_result_mutation_response?: Document_Assessment_Result_Mutation_ResponseResolvers<ContextType>;
  document_assessment_result_stddev_fields?: Document_Assessment_Result_Stddev_FieldsResolvers<ContextType>;
  document_assessment_result_stddev_pop_fields?: Document_Assessment_Result_Stddev_Pop_FieldsResolvers<ContextType>;
  document_assessment_result_stddev_samp_fields?: Document_Assessment_Result_Stddev_Samp_FieldsResolvers<ContextType>;
  document_assessment_result_sum_fields?: Document_Assessment_Result_Sum_FieldsResolvers<ContextType>;
  document_assessment_result_var_pop_fields?: Document_Assessment_Result_Var_Pop_FieldsResolvers<ContextType>;
  document_assessment_result_var_samp_fields?: Document_Assessment_Result_Var_Samp_FieldsResolvers<ContextType>;
  document_assessment_result_variance_fields?: Document_Assessment_Result_Variance_FieldsResolvers<ContextType>;
  document_audit?: Document_AuditResolvers<ContextType>;
  document_file?: Document_FileResolvers<ContextType>;
  document_file_audit?: Document_File_AuditResolvers<ContextType>;
  document_file_mutation_response?: Document_File_Mutation_ResponseResolvers<ContextType>;
  document_linked_document?: Document_Linked_DocumentResolvers<ContextType>;
  document_linked_document_audit?: Document_Linked_Document_AuditResolvers<ContextType>;
  document_linked_document_mutation_response?: Document_Linked_Document_Mutation_ResponseResolvers<ContextType>;
  file?: FileResolvers<ContextType>;
  file_aggregate?: File_AggregateResolvers<ContextType>;
  file_aggregate_fields?: File_Aggregate_FieldsResolvers<ContextType>;
  file_audit?: File_AuditResolvers<ContextType>;
  file_avg_fields?: File_Avg_FieldsResolvers<ContextType>;
  file_max_fields?: File_Max_FieldsResolvers<ContextType>;
  file_min_fields?: File_Min_FieldsResolvers<ContextType>;
  file_mutation_response?: File_Mutation_ResponseResolvers<ContextType>;
  file_stddev_fields?: File_Stddev_FieldsResolvers<ContextType>;
  file_stddev_pop_fields?: File_Stddev_Pop_FieldsResolvers<ContextType>;
  file_stddev_samp_fields?: File_Stddev_Samp_FieldsResolvers<ContextType>;
  file_sum_fields?: File_Sum_FieldsResolvers<ContextType>;
  file_var_pop_fields?: File_Var_Pop_FieldsResolvers<ContextType>;
  file_var_samp_fields?: File_Var_Samp_FieldsResolvers<ContextType>;
  file_variance_fields?: File_Variance_FieldsResolvers<ContextType>;
  float8?: GraphQLScalarType;
  form_configuration?: Form_ConfigurationResolvers<ContextType>;
  form_configuration_audit?: Form_Configuration_AuditResolvers<ContextType>;
  form_configuration_mutation_response?: Form_Configuration_Mutation_ResponseResolvers<ContextType>;
  form_field_configuration?: Form_Field_ConfigurationResolvers<ContextType>;
  form_field_configuration_audit?: Form_Field_Configuration_AuditResolvers<ContextType>;
  form_field_configuration_mutation_response?: Form_Field_Configuration_Mutation_ResponseResolvers<ContextType>;
  form_field_ordering?: Form_Field_OrderingResolvers<ContextType>;
  form_field_ordering_audit?: Form_Field_Ordering_AuditResolvers<ContextType>;
  form_field_ordering_mutation_response?: Form_Field_Ordering_Mutation_ResponseResolvers<ContextType>;
  impact?: ImpactResolvers<ContextType>;
  impact_aggregate?: Impact_AggregateResolvers<ContextType>;
  impact_aggregate_fields?: Impact_Aggregate_FieldsResolvers<ContextType>;
  impact_audit?: Impact_AuditResolvers<ContextType>;
  impact_avg_fields?: Impact_Avg_FieldsResolvers<ContextType>;
  impact_max_fields?: Impact_Max_FieldsResolvers<ContextType>;
  impact_min_fields?: Impact_Min_FieldsResolvers<ContextType>;
  impact_mutation_response?: Impact_Mutation_ResponseResolvers<ContextType>;
  impact_parent?: Impact_ParentResolvers<ContextType>;
  impact_parent_aggregate?: Impact_Parent_AggregateResolvers<ContextType>;
  impact_parent_aggregate_fields?: Impact_Parent_Aggregate_FieldsResolvers<ContextType>;
  impact_parent_audit?: Impact_Parent_AuditResolvers<ContextType>;
  impact_parent_max_fields?: Impact_Parent_Max_FieldsResolvers<ContextType>;
  impact_parent_min_fields?: Impact_Parent_Min_FieldsResolvers<ContextType>;
  impact_rating?: Impact_RatingResolvers<ContextType>;
  impact_rating_aggregate?: Impact_Rating_AggregateResolvers<ContextType>;
  impact_rating_aggregate_fields?: Impact_Rating_Aggregate_FieldsResolvers<ContextType>;
  impact_rating_audit?: Impact_Rating_AuditResolvers<ContextType>;
  impact_rating_avg_fields?: Impact_Rating_Avg_FieldsResolvers<ContextType>;
  impact_rating_max_fields?: Impact_Rating_Max_FieldsResolvers<ContextType>;
  impact_rating_min_fields?: Impact_Rating_Min_FieldsResolvers<ContextType>;
  impact_rating_mutation_response?: Impact_Rating_Mutation_ResponseResolvers<ContextType>;
  impact_rating_stddev_fields?: Impact_Rating_Stddev_FieldsResolvers<ContextType>;
  impact_rating_stddev_pop_fields?: Impact_Rating_Stddev_Pop_FieldsResolvers<ContextType>;
  impact_rating_stddev_samp_fields?: Impact_Rating_Stddev_Samp_FieldsResolvers<ContextType>;
  impact_rating_sum_fields?: Impact_Rating_Sum_FieldsResolvers<ContextType>;
  impact_rating_var_pop_fields?: Impact_Rating_Var_Pop_FieldsResolvers<ContextType>;
  impact_rating_var_samp_fields?: Impact_Rating_Var_Samp_FieldsResolvers<ContextType>;
  impact_rating_variance_fields?: Impact_Rating_Variance_FieldsResolvers<ContextType>;
  impact_stddev_fields?: Impact_Stddev_FieldsResolvers<ContextType>;
  impact_stddev_pop_fields?: Impact_Stddev_Pop_FieldsResolvers<ContextType>;
  impact_stddev_samp_fields?: Impact_Stddev_Samp_FieldsResolvers<ContextType>;
  impact_sum_fields?: Impact_Sum_FieldsResolvers<ContextType>;
  impact_var_pop_fields?: Impact_Var_Pop_FieldsResolvers<ContextType>;
  impact_var_samp_fields?: Impact_Var_Samp_FieldsResolvers<ContextType>;
  impact_variance_fields?: Impact_Variance_FieldsResolvers<ContextType>;
  indicator?: IndicatorResolvers<ContextType>;
  indicator_audit?: Indicator_AuditResolvers<ContextType>;
  indicator_mutation_response?: Indicator_Mutation_ResponseResolvers<ContextType>;
  indicator_parent?: Indicator_ParentResolvers<ContextType>;
  indicator_parent_aggregate?: Indicator_Parent_AggregateResolvers<ContextType>;
  indicator_parent_aggregate_fields?: Indicator_Parent_Aggregate_FieldsResolvers<ContextType>;
  indicator_parent_max_fields?: Indicator_Parent_Max_FieldsResolvers<ContextType>;
  indicator_parent_min_fields?: Indicator_Parent_Min_FieldsResolvers<ContextType>;
  indicator_parent_mutation_response?: Indicator_Parent_Mutation_ResponseResolvers<ContextType>;
  indicator_result?: Indicator_ResultResolvers<ContextType>;
  indicator_result_audit?: Indicator_Result_AuditResolvers<ContextType>;
  indicator_result_mutation_response?: Indicator_Result_Mutation_ResponseResolvers<ContextType>;
  indicator_type?: Indicator_TypeResolvers<ContextType>;
  insertChildImpactRatingOutput?: InsertChildImpactRatingOutputResolvers<ContextType>;
  insertControlTestResultOutput?: InsertControlTestResultOutputResolvers<ContextType>;
  internal_audit_entity?: Internal_Audit_EntityResolvers<ContextType>;
  internal_audit_entity_aggregate?: Internal_Audit_Entity_AggregateResolvers<ContextType>;
  internal_audit_entity_aggregate_fields?: Internal_Audit_Entity_Aggregate_FieldsResolvers<ContextType>;
  internal_audit_entity_avg_fields?: Internal_Audit_Entity_Avg_FieldsResolvers<ContextType>;
  internal_audit_entity_max_fields?: Internal_Audit_Entity_Max_FieldsResolvers<ContextType>;
  internal_audit_entity_min_fields?: Internal_Audit_Entity_Min_FieldsResolvers<ContextType>;
  internal_audit_entity_mutation_response?: Internal_Audit_Entity_Mutation_ResponseResolvers<ContextType>;
  internal_audit_entity_stddev_fields?: Internal_Audit_Entity_Stddev_FieldsResolvers<ContextType>;
  internal_audit_entity_stddev_pop_fields?: Internal_Audit_Entity_Stddev_Pop_FieldsResolvers<ContextType>;
  internal_audit_entity_stddev_samp_fields?: Internal_Audit_Entity_Stddev_Samp_FieldsResolvers<ContextType>;
  internal_audit_entity_sum_fields?: Internal_Audit_Entity_Sum_FieldsResolvers<ContextType>;
  internal_audit_entity_var_pop_fields?: Internal_Audit_Entity_Var_Pop_FieldsResolvers<ContextType>;
  internal_audit_entity_var_samp_fields?: Internal_Audit_Entity_Var_Samp_FieldsResolvers<ContextType>;
  internal_audit_entity_variance_fields?: Internal_Audit_Entity_Variance_FieldsResolvers<ContextType>;
  internal_audit_report?: Internal_Audit_ReportResolvers<ContextType>;
  internal_audit_report_aggregate?: Internal_Audit_Report_AggregateResolvers<ContextType>;
  internal_audit_report_aggregate_fields?: Internal_Audit_Report_Aggregate_FieldsResolvers<ContextType>;
  internal_audit_report_avg_fields?: Internal_Audit_Report_Avg_FieldsResolvers<ContextType>;
  internal_audit_report_max_fields?: Internal_Audit_Report_Max_FieldsResolvers<ContextType>;
  internal_audit_report_min_fields?: Internal_Audit_Report_Min_FieldsResolvers<ContextType>;
  internal_audit_report_mutation_response?: Internal_Audit_Report_Mutation_ResponseResolvers<ContextType>;
  internal_audit_report_stddev_fields?: Internal_Audit_Report_Stddev_FieldsResolvers<ContextType>;
  internal_audit_report_stddev_pop_fields?: Internal_Audit_Report_Stddev_Pop_FieldsResolvers<ContextType>;
  internal_audit_report_stddev_samp_fields?: Internal_Audit_Report_Stddev_Samp_FieldsResolvers<ContextType>;
  internal_audit_report_sum_fields?: Internal_Audit_Report_Sum_FieldsResolvers<ContextType>;
  internal_audit_report_var_pop_fields?: Internal_Audit_Report_Var_Pop_FieldsResolvers<ContextType>;
  internal_audit_report_var_samp_fields?: Internal_Audit_Report_Var_Samp_FieldsResolvers<ContextType>;
  internal_audit_report_variance_fields?: Internal_Audit_Report_Variance_FieldsResolvers<ContextType>;
  interval?: GraphQLScalarType;
  issue?: IssueResolvers<ContextType>;
  issue_aggregate?: Issue_AggregateResolvers<ContextType>;
  issue_aggregate_fields?: Issue_Aggregate_FieldsResolvers<ContextType>;
  issue_assessment?: Issue_AssessmentResolvers<ContextType>;
  issue_assessment_aggregate?: Issue_Assessment_AggregateResolvers<ContextType>;
  issue_assessment_aggregate_fields?: Issue_Assessment_Aggregate_FieldsResolvers<ContextType>;
  issue_assessment_audit?: Issue_Assessment_AuditResolvers<ContextType>;
  issue_assessment_avg_fields?: Issue_Assessment_Avg_FieldsResolvers<ContextType>;
  issue_assessment_max_fields?: Issue_Assessment_Max_FieldsResolvers<ContextType>;
  issue_assessment_min_fields?: Issue_Assessment_Min_FieldsResolvers<ContextType>;
  issue_assessment_stddev_fields?: Issue_Assessment_Stddev_FieldsResolvers<ContextType>;
  issue_assessment_stddev_pop_fields?: Issue_Assessment_Stddev_Pop_FieldsResolvers<ContextType>;
  issue_assessment_stddev_samp_fields?: Issue_Assessment_Stddev_Samp_FieldsResolvers<ContextType>;
  issue_assessment_sum_fields?: Issue_Assessment_Sum_FieldsResolvers<ContextType>;
  issue_assessment_var_pop_fields?: Issue_Assessment_Var_Pop_FieldsResolvers<ContextType>;
  issue_assessment_var_samp_fields?: Issue_Assessment_Var_Samp_FieldsResolvers<ContextType>;
  issue_assessment_variance_fields?: Issue_Assessment_Variance_FieldsResolvers<ContextType>;
  issue_audit?: Issue_AuditResolvers<ContextType>;
  issue_avg_fields?: Issue_Avg_FieldsResolvers<ContextType>;
  issue_max_fields?: Issue_Max_FieldsResolvers<ContextType>;
  issue_min_fields?: Issue_Min_FieldsResolvers<ContextType>;
  issue_mutation_response?: Issue_Mutation_ResponseResolvers<ContextType>;
  issue_parent?: Issue_ParentResolvers<ContextType>;
  issue_parent_aggregate?: Issue_Parent_AggregateResolvers<ContextType>;
  issue_parent_aggregate_fields?: Issue_Parent_Aggregate_FieldsResolvers<ContextType>;
  issue_parent_audit?: Issue_Parent_AuditResolvers<ContextType>;
  issue_parent_max_fields?: Issue_Parent_Max_FieldsResolvers<ContextType>;
  issue_parent_min_fields?: Issue_Parent_Min_FieldsResolvers<ContextType>;
  issue_stddev_fields?: Issue_Stddev_FieldsResolvers<ContextType>;
  issue_stddev_pop_fields?: Issue_Stddev_Pop_FieldsResolvers<ContextType>;
  issue_stddev_samp_fields?: Issue_Stddev_Samp_FieldsResolvers<ContextType>;
  issue_sum_fields?: Issue_Sum_FieldsResolvers<ContextType>;
  issue_update?: Issue_UpdateResolvers<ContextType>;
  issue_update_audit?: Issue_Update_AuditResolvers<ContextType>;
  issue_update_mutation_response?: Issue_Update_Mutation_ResponseResolvers<ContextType>;
  issue_var_pop_fields?: Issue_Var_Pop_FieldsResolvers<ContextType>;
  issue_var_samp_fields?: Issue_Var_Samp_FieldsResolvers<ContextType>;
  issue_variance_fields?: Issue_Variance_FieldsResolvers<ContextType>;
  json?: GraphQLScalarType;
  jsonb?: GraphQLScalarType;
  linked_item?: Linked_ItemResolvers<ContextType>;
  linked_item_mutation_response?: Linked_Item_Mutation_ResponseResolvers<ContextType>;
  mutation_root?: Mutation_RootResolvers<ContextType>;
  node?: NodeResolvers<ContextType>;
  node_aggregate?: Node_AggregateResolvers<ContextType>;
  node_aggregate_fields?: Node_Aggregate_FieldsResolvers<ContextType>;
  node_avg_fields?: Node_Avg_FieldsResolvers<ContextType>;
  node_max_fields?: Node_Max_FieldsResolvers<ContextType>;
  node_min_fields?: Node_Min_FieldsResolvers<ContextType>;
  node_stddev_fields?: Node_Stddev_FieldsResolvers<ContextType>;
  node_stddev_pop_fields?: Node_Stddev_Pop_FieldsResolvers<ContextType>;
  node_stddev_samp_fields?: Node_Stddev_Samp_FieldsResolvers<ContextType>;
  node_sum_fields?: Node_Sum_FieldsResolvers<ContextType>;
  node_var_pop_fields?: Node_Var_Pop_FieldsResolvers<ContextType>;
  node_var_samp_fields?: Node_Var_Samp_FieldsResolvers<ContextType>;
  node_variance_fields?: Node_Variance_FieldsResolvers<ContextType>;
  numeric?: GraphQLScalarType;
  obligation?: ObligationResolvers<ContextType>;
  obligation_aggregate?: Obligation_AggregateResolvers<ContextType>;
  obligation_aggregate_fields?: Obligation_Aggregate_FieldsResolvers<ContextType>;
  obligation_assessment_result?: Obligation_Assessment_ResultResolvers<ContextType>;
  obligation_assessment_result_aggregate?: Obligation_Assessment_Result_AggregateResolvers<ContextType>;
  obligation_assessment_result_aggregate_fields?: Obligation_Assessment_Result_Aggregate_FieldsResolvers<ContextType>;
  obligation_assessment_result_audit?: Obligation_Assessment_Result_AuditResolvers<ContextType>;
  obligation_assessment_result_avg_fields?: Obligation_Assessment_Result_Avg_FieldsResolvers<ContextType>;
  obligation_assessment_result_max_fields?: Obligation_Assessment_Result_Max_FieldsResolvers<ContextType>;
  obligation_assessment_result_min_fields?: Obligation_Assessment_Result_Min_FieldsResolvers<ContextType>;
  obligation_assessment_result_mutation_response?: Obligation_Assessment_Result_Mutation_ResponseResolvers<ContextType>;
  obligation_assessment_result_stddev_fields?: Obligation_Assessment_Result_Stddev_FieldsResolvers<ContextType>;
  obligation_assessment_result_stddev_pop_fields?: Obligation_Assessment_Result_Stddev_Pop_FieldsResolvers<ContextType>;
  obligation_assessment_result_stddev_samp_fields?: Obligation_Assessment_Result_Stddev_Samp_FieldsResolvers<ContextType>;
  obligation_assessment_result_sum_fields?: Obligation_Assessment_Result_Sum_FieldsResolvers<ContextType>;
  obligation_assessment_result_var_pop_fields?: Obligation_Assessment_Result_Var_Pop_FieldsResolvers<ContextType>;
  obligation_assessment_result_var_samp_fields?: Obligation_Assessment_Result_Var_Samp_FieldsResolvers<ContextType>;
  obligation_assessment_result_variance_fields?: Obligation_Assessment_Result_Variance_FieldsResolvers<ContextType>;
  obligation_assessment_status?: Obligation_Assessment_StatusResolvers<ContextType>;
  obligation_audit?: Obligation_AuditResolvers<ContextType>;
  obligation_audit_aggregate?: Obligation_Audit_AggregateResolvers<ContextType>;
  obligation_audit_aggregate_fields?: Obligation_Audit_Aggregate_FieldsResolvers<ContextType>;
  obligation_audit_avg_fields?: Obligation_Audit_Avg_FieldsResolvers<ContextType>;
  obligation_audit_max_fields?: Obligation_Audit_Max_FieldsResolvers<ContextType>;
  obligation_audit_min_fields?: Obligation_Audit_Min_FieldsResolvers<ContextType>;
  obligation_audit_stddev_fields?: Obligation_Audit_Stddev_FieldsResolvers<ContextType>;
  obligation_audit_stddev_pop_fields?: Obligation_Audit_Stddev_Pop_FieldsResolvers<ContextType>;
  obligation_audit_stddev_samp_fields?: Obligation_Audit_Stddev_Samp_FieldsResolvers<ContextType>;
  obligation_audit_sum_fields?: Obligation_Audit_Sum_FieldsResolvers<ContextType>;
  obligation_audit_var_pop_fields?: Obligation_Audit_Var_Pop_FieldsResolvers<ContextType>;
  obligation_audit_var_samp_fields?: Obligation_Audit_Var_Samp_FieldsResolvers<ContextType>;
  obligation_audit_variance_fields?: Obligation_Audit_Variance_FieldsResolvers<ContextType>;
  obligation_avg_fields?: Obligation_Avg_FieldsResolvers<ContextType>;
  obligation_impact?: Obligation_ImpactResolvers<ContextType>;
  obligation_impact_audit?: Obligation_Impact_AuditResolvers<ContextType>;
  obligation_impact_mutation_response?: Obligation_Impact_Mutation_ResponseResolvers<ContextType>;
  obligation_max_fields?: Obligation_Max_FieldsResolvers<ContextType>;
  obligation_min_fields?: Obligation_Min_FieldsResolvers<ContextType>;
  obligation_mutation_response?: Obligation_Mutation_ResponseResolvers<ContextType>;
  obligation_stddev_fields?: Obligation_Stddev_FieldsResolvers<ContextType>;
  obligation_stddev_pop_fields?: Obligation_Stddev_Pop_FieldsResolvers<ContextType>;
  obligation_stddev_samp_fields?: Obligation_Stddev_Samp_FieldsResolvers<ContextType>;
  obligation_sum_fields?: Obligation_Sum_FieldsResolvers<ContextType>;
  obligation_type?: Obligation_TypeResolvers<ContextType>;
  obligation_var_pop_fields?: Obligation_Var_Pop_FieldsResolvers<ContextType>;
  obligation_var_samp_fields?: Obligation_Var_Samp_FieldsResolvers<ContextType>;
  obligation_variance_fields?: Obligation_Variance_FieldsResolvers<ContextType>;
  owner?: OwnerResolvers<ContextType>;
  owner_aggregate?: Owner_AggregateResolvers<ContextType>;
  owner_aggregate_fields?: Owner_Aggregate_FieldsResolvers<ContextType>;
  owner_audit?: Owner_AuditResolvers<ContextType>;
  owner_group?: Owner_GroupResolvers<ContextType>;
  owner_group_aggregate?: Owner_Group_AggregateResolvers<ContextType>;
  owner_group_aggregate_fields?: Owner_Group_Aggregate_FieldsResolvers<ContextType>;
  owner_group_audit?: Owner_Group_AuditResolvers<ContextType>;
  owner_group_max_fields?: Owner_Group_Max_FieldsResolvers<ContextType>;
  owner_group_min_fields?: Owner_Group_Min_FieldsResolvers<ContextType>;
  owner_group_mutation_response?: Owner_Group_Mutation_ResponseResolvers<ContextType>;
  owner_max_fields?: Owner_Max_FieldsResolvers<ContextType>;
  owner_min_fields?: Owner_Min_FieldsResolvers<ContextType>;
  owner_mutation_response?: Owner_Mutation_ResponseResolvers<ContextType>;
  query_root?: Query_RootResolvers<ContextType>;
  questionnaire_invite?: Questionnaire_InviteResolvers<ContextType>;
  questionnaire_invite_mutation_response?: Questionnaire_Invite_Mutation_ResponseResolvers<ContextType>;
  questionnaire_template?: Questionnaire_TemplateResolvers<ContextType>;
  questionnaire_template_mutation_response?: Questionnaire_Template_Mutation_ResponseResolvers<ContextType>;
  questionnaire_template_version?: Questionnaire_Template_VersionResolvers<ContextType>;
  questionnaire_template_version_mutation_response?: Questionnaire_Template_Version_Mutation_ResponseResolvers<ContextType>;
  questionnaire_template_version_status?: Questionnaire_Template_Version_StatusResolvers<ContextType>;
  relation_file?: Relation_FileResolvers<ContextType>;
  relation_file_aggregate?: Relation_File_AggregateResolvers<ContextType>;
  relation_file_aggregate_fields?: Relation_File_Aggregate_FieldsResolvers<ContextType>;
  relation_file_audit?: Relation_File_AuditResolvers<ContextType>;
  relation_file_max_fields?: Relation_File_Max_FieldsResolvers<ContextType>;
  relation_file_min_fields?: Relation_File_Min_FieldsResolvers<ContextType>;
  relation_file_mutation_response?: Relation_File_Mutation_ResponseResolvers<ContextType>;
  risk?: RiskResolvers<ContextType>;
  risk_aggregate?: Risk_AggregateResolvers<ContextType>;
  risk_aggregate_fields?: Risk_Aggregate_FieldsResolvers<ContextType>;
  risk_assessment_result?: Risk_Assessment_ResultResolvers<ContextType>;
  risk_assessment_result_aggregate?: Risk_Assessment_Result_AggregateResolvers<ContextType>;
  risk_assessment_result_aggregate_fields?: Risk_Assessment_Result_Aggregate_FieldsResolvers<ContextType>;
  risk_assessment_result_audit?: Risk_Assessment_Result_AuditResolvers<ContextType>;
  risk_assessment_result_avg_fields?: Risk_Assessment_Result_Avg_FieldsResolvers<ContextType>;
  risk_assessment_result_max_fields?: Risk_Assessment_Result_Max_FieldsResolvers<ContextType>;
  risk_assessment_result_min_fields?: Risk_Assessment_Result_Min_FieldsResolvers<ContextType>;
  risk_assessment_result_mutation_response?: Risk_Assessment_Result_Mutation_ResponseResolvers<ContextType>;
  risk_assessment_result_stddev_fields?: Risk_Assessment_Result_Stddev_FieldsResolvers<ContextType>;
  risk_assessment_result_stddev_pop_fields?: Risk_Assessment_Result_Stddev_Pop_FieldsResolvers<ContextType>;
  risk_assessment_result_stddev_samp_fields?: Risk_Assessment_Result_Stddev_Samp_FieldsResolvers<ContextType>;
  risk_assessment_result_sum_fields?: Risk_Assessment_Result_Sum_FieldsResolvers<ContextType>;
  risk_assessment_result_var_pop_fields?: Risk_Assessment_Result_Var_Pop_FieldsResolvers<ContextType>;
  risk_assessment_result_var_samp_fields?: Risk_Assessment_Result_Var_Samp_FieldsResolvers<ContextType>;
  risk_assessment_result_variance_fields?: Risk_Assessment_Result_Variance_FieldsResolvers<ContextType>;
  risk_audit?: Risk_AuditResolvers<ContextType>;
  risk_audit_aggregate?: Risk_Audit_AggregateResolvers<ContextType>;
  risk_audit_aggregate_fields?: Risk_Audit_Aggregate_FieldsResolvers<ContextType>;
  risk_audit_avg_fields?: Risk_Audit_Avg_FieldsResolvers<ContextType>;
  risk_audit_max_fields?: Risk_Audit_Max_FieldsResolvers<ContextType>;
  risk_audit_min_fields?: Risk_Audit_Min_FieldsResolvers<ContextType>;
  risk_audit_stddev_fields?: Risk_Audit_Stddev_FieldsResolvers<ContextType>;
  risk_audit_stddev_pop_fields?: Risk_Audit_Stddev_Pop_FieldsResolvers<ContextType>;
  risk_audit_stddev_samp_fields?: Risk_Audit_Stddev_Samp_FieldsResolvers<ContextType>;
  risk_audit_sum_fields?: Risk_Audit_Sum_FieldsResolvers<ContextType>;
  risk_audit_var_pop_fields?: Risk_Audit_Var_Pop_FieldsResolvers<ContextType>;
  risk_audit_var_samp_fields?: Risk_Audit_Var_Samp_FieldsResolvers<ContextType>;
  risk_audit_variance_fields?: Risk_Audit_Variance_FieldsResolvers<ContextType>;
  risk_avg_fields?: Risk_Avg_FieldsResolvers<ContextType>;
  risk_max_fields?: Risk_Max_FieldsResolvers<ContextType>;
  risk_min_fields?: Risk_Min_FieldsResolvers<ContextType>;
  risk_score?: Risk_ScoreResolvers<ContextType>;
  risk_score_aggregate?: Risk_Score_AggregateResolvers<ContextType>;
  risk_score_aggregate_fields?: Risk_Score_Aggregate_FieldsResolvers<ContextType>;
  risk_score_avg_fields?: Risk_Score_Avg_FieldsResolvers<ContextType>;
  risk_score_max_fields?: Risk_Score_Max_FieldsResolvers<ContextType>;
  risk_score_min_fields?: Risk_Score_Min_FieldsResolvers<ContextType>;
  risk_score_stddev_fields?: Risk_Score_Stddev_FieldsResolvers<ContextType>;
  risk_score_stddev_pop_fields?: Risk_Score_Stddev_Pop_FieldsResolvers<ContextType>;
  risk_score_stddev_samp_fields?: Risk_Score_Stddev_Samp_FieldsResolvers<ContextType>;
  risk_score_sum_fields?: Risk_Score_Sum_FieldsResolvers<ContextType>;
  risk_score_var_pop_fields?: Risk_Score_Var_Pop_FieldsResolvers<ContextType>;
  risk_score_var_samp_fields?: Risk_Score_Var_Samp_FieldsResolvers<ContextType>;
  risk_score_variance_fields?: Risk_Score_Variance_FieldsResolvers<ContextType>;
  risk_stddev_fields?: Risk_Stddev_FieldsResolvers<ContextType>;
  risk_stddev_pop_fields?: Risk_Stddev_Pop_FieldsResolvers<ContextType>;
  risk_stddev_samp_fields?: Risk_Stddev_Samp_FieldsResolvers<ContextType>;
  risk_sum_fields?: Risk_Sum_FieldsResolvers<ContextType>;
  risk_var_pop_fields?: Risk_Var_Pop_FieldsResolvers<ContextType>;
  risk_var_samp_fields?: Risk_Var_Samp_FieldsResolvers<ContextType>;
  risk_variance_fields?: Risk_Variance_FieldsResolvers<ContextType>;
  risksmart_indicator_parent_audit?: Risksmart_Indicator_Parent_AuditResolvers<ContextType>;
  risksmart_linked_item_audit?: Risksmart_Linked_Item_AuditResolvers<ContextType>;
  role_access?: Role_AccessResolvers<ContextType>;
  schedule?: ScheduleResolvers<ContextType>;
  schedule_state?: Schedule_StateResolvers<ContextType>;
  smallint?: GraphQLScalarType;
  subscription_root?: Subscription_RootResolvers<ContextType>;
  tag?: TagResolvers<ContextType>;
  tag_mutation_response?: Tag_Mutation_ResponseResolvers<ContextType>;
  tag_type?: Tag_TypeResolvers<ContextType>;
  tag_type_audit?: Tag_Type_AuditResolvers<ContextType>;
  tag_type_group?: Tag_Type_GroupResolvers<ContextType>;
  tag_type_group_audit?: Tag_Type_Group_AuditResolvers<ContextType>;
  tag_type_group_mutation_response?: Tag_Type_Group_Mutation_ResponseResolvers<ContextType>;
  tag_type_mutation_response?: Tag_Type_Mutation_ResponseResolvers<ContextType>;
  taxonomy?: TaxonomyResolvers<ContextType>;
  taxonomy_aggregate?: Taxonomy_AggregateResolvers<ContextType>;
  taxonomy_aggregate_fields?: Taxonomy_Aggregate_FieldsResolvers<ContextType>;
  taxonomy_audit?: Taxonomy_AuditResolvers<ContextType>;
  taxonomy_audit_aggregate?: Taxonomy_Audit_AggregateResolvers<ContextType>;
  taxonomy_audit_aggregate_fields?: Taxonomy_Audit_Aggregate_FieldsResolvers<ContextType>;
  taxonomy_audit_max_fields?: Taxonomy_Audit_Max_FieldsResolvers<ContextType>;
  taxonomy_audit_min_fields?: Taxonomy_Audit_Min_FieldsResolvers<ContextType>;
  taxonomy_max_fields?: Taxonomy_Max_FieldsResolvers<ContextType>;
  taxonomy_min_fields?: Taxonomy_Min_FieldsResolvers<ContextType>;
  taxonomy_mutation_response?: Taxonomy_Mutation_ResponseResolvers<ContextType>;
  taxonomy_org?: Taxonomy_OrgResolvers<ContextType>;
  taxonomy_org_aggregate?: Taxonomy_Org_AggregateResolvers<ContextType>;
  taxonomy_org_aggregate_fields?: Taxonomy_Org_Aggregate_FieldsResolvers<ContextType>;
  taxonomy_org_max_fields?: Taxonomy_Org_Max_FieldsResolvers<ContextType>;
  taxonomy_org_min_fields?: Taxonomy_Org_Min_FieldsResolvers<ContextType>;
  taxonomy_org_mutation_response?: Taxonomy_Org_Mutation_ResponseResolvers<ContextType>;
  test_result?: Test_ResultResolvers<ContextType>;
  test_result_aggregate?: Test_Result_AggregateResolvers<ContextType>;
  test_result_aggregate_fields?: Test_Result_Aggregate_FieldsResolvers<ContextType>;
  test_result_audit?: Test_Result_AuditResolvers<ContextType>;
  test_result_avg_fields?: Test_Result_Avg_FieldsResolvers<ContextType>;
  test_result_max_fields?: Test_Result_Max_FieldsResolvers<ContextType>;
  test_result_min_fields?: Test_Result_Min_FieldsResolvers<ContextType>;
  test_result_mutation_response?: Test_Result_Mutation_ResponseResolvers<ContextType>;
  test_result_stddev_fields?: Test_Result_Stddev_FieldsResolvers<ContextType>;
  test_result_stddev_pop_fields?: Test_Result_Stddev_Pop_FieldsResolvers<ContextType>;
  test_result_stddev_samp_fields?: Test_Result_Stddev_Samp_FieldsResolvers<ContextType>;
  test_result_sum_fields?: Test_Result_Sum_FieldsResolvers<ContextType>;
  test_result_var_pop_fields?: Test_Result_Var_Pop_FieldsResolvers<ContextType>;
  test_result_var_samp_fields?: Test_Result_Var_Samp_FieldsResolvers<ContextType>;
  test_result_variance_fields?: Test_Result_Variance_FieldsResolvers<ContextType>;
  third_party?: Third_PartyResolvers<ContextType>;
  third_party_mutation_response?: Third_Party_Mutation_ResponseResolvers<ContextType>;
  third_party_response?: Third_Party_ResponseResolvers<ContextType>;
  third_party_response_mutation_response?: Third_Party_Response_Mutation_ResponseResolvers<ContextType>;
  third_party_response_status?: Third_Party_Response_StatusResolvers<ContextType>;
  timestamptz?: GraphQLScalarType;
  user?: UserResolvers<ContextType>;
  user_aggregate?: User_AggregateResolvers<ContextType>;
  user_aggregate_fields?: User_Aggregate_FieldsResolvers<ContextType>;
  user_group?: User_GroupResolvers<ContextType>;
  user_group_audit?: User_Group_AuditResolvers<ContextType>;
  user_group_mutation_response?: User_Group_Mutation_ResponseResolvers<ContextType>;
  user_group_user?: User_Group_UserResolvers<ContextType>;
  user_group_user_aggregate?: User_Group_User_AggregateResolvers<ContextType>;
  user_group_user_aggregate_fields?: User_Group_User_Aggregate_FieldsResolvers<ContextType>;
  user_group_user_audit?: User_Group_User_AuditResolvers<ContextType>;
  user_group_user_max_fields?: User_Group_User_Max_FieldsResolvers<ContextType>;
  user_group_user_min_fields?: User_Group_User_Min_FieldsResolvers<ContextType>;
  user_group_user_mutation_response?: User_Group_User_Mutation_ResponseResolvers<ContextType>;
  user_max_fields?: User_Max_FieldsResolvers<ContextType>;
  user_min_fields?: User_Min_FieldsResolvers<ContextType>;
  user_search_preferences?: User_Search_PreferencesResolvers<ContextType>;
  user_search_preferences_audit?: User_Search_Preferences_AuditResolvers<ContextType>;
  user_search_preferences_mutation_response?: User_Search_Preferences_Mutation_ResponseResolvers<ContextType>;
  user_table_preferences?: User_Table_PreferencesResolvers<ContextType>;
  user_table_preferences_mutation_response?: User_Table_Preferences_Mutation_ResponseResolvers<ContextType>;
  uuid?: GraphQLScalarType;
};

export type DirectiveResolvers<ContextType = any> = {
  cached?: CachedDirectiveResolver<any, any, ContextType>;
};


export const DeleteRelationFileByIdDocument = gql`
    mutation deleteRelationFileById($parentIds: [uuid!], $fileIds: [uuid!]) {
  delete_relation_file(
    where: {FileId: {_in: $fileIds}, ParentId: {_in: $parentIds}}
  ) {
    affected_rows
  }
}
    `;
export type DeleteRelationFileByIdMutationFn = Apollo.MutationFunction<DeleteRelationFileByIdMutation, DeleteRelationFileByIdMutationVariables>;

/**
 * __useDeleteRelationFileByIdMutation__
 *
 * To run a mutation, you first call `useDeleteRelationFileByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRelationFileByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRelationFileByIdMutation, { data, loading, error }] = useDeleteRelationFileByIdMutation({
 *   variables: {
 *      parentIds: // value for 'parentIds'
 *      fileIds: // value for 'fileIds'
 *   },
 * });
 */
export function useDeleteRelationFileByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRelationFileByIdMutation, DeleteRelationFileByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRelationFileByIdMutation, DeleteRelationFileByIdMutationVariables>(DeleteRelationFileByIdDocument, options);
      }
export type DeleteRelationFileByIdMutationHookResult = ReturnType<typeof useDeleteRelationFileByIdMutation>;
export type DeleteRelationFileByIdMutationResult = Apollo.MutationResult<DeleteRelationFileByIdMutation>;
export type DeleteRelationFileByIdMutationOptions = Apollo.BaseMutationOptions<DeleteRelationFileByIdMutation, DeleteRelationFileByIdMutationVariables>;
export const namedOperations = {
  Mutation: {
    deleteRelationFileById: 'deleteRelationFileById'
  }
}

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    